import React from "react";
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Heading from "components/Heading/Heading.jsx";
import Timeline from "components/Timeline/Timeline.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import { stories } from "variables/general.jsx";
import ReactDOM from "react-dom";
import CardHeader from "components/Card/CardHeader.jsx";
import SupervisedUserCircle from "@material-ui/icons/AccountCircle";
import CardIcon from "components/Card/CardIcon.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import ActiveDirectory from 'containers/ActiveDirectory/ActiveDirectory';
import checkPermissions from '../../shared/checkPermissions';

class UserAdmin extends React.Component {
  componentDidMount() {
    this.props.checkMaintenanceMode();
  }
  render() {
    if (!checkPermissions('UserAdmin')) {
      // window.location.href = '/pages/login-page/?o=UserAdmin';
      return <Redirect to={{
          pathname: '/dashboard' }
        } />;
    }
    if (this.props.maintenanceMode === 'yes') return <Redirect to="/components/maintenance_page" />

    return (
        <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <div className="hovered">
                  <SupervisedUserCircle
                    onClick={() => {
                      this.props.onFetchAD();
                    }}
                  />
                </div>
              </CardIcon>
            </CardHeader>
            <CardBody>
        <ActiveDirectory />
      </CardBody>
          </Card>
        </GridItem>
        </GridContainer>
    );
  }
}

const mapStateToProps = state => {
  return {
    maintenanceMode: state.userPerms.maintenanceMode
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onFetchAD: () => dispatch(actions.fetchAD()),
    checkMaintenanceMode: () => {
      dispatch(actions.checkMaintenanceMode());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserAdmin);
