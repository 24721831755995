import React from 'react';
import ReactTable from "react-table";
import moment from 'moment';
import GMPMultipleSelect from 'containers/GMP/GMPMultipleSelect';
import GMPSelect from 'containers/GMP/GMPSelect';

const LockedGIN = props => {
  return (
    <div className="table-adjust2 table-adjust3">
      <ReactTable
        minRows={0}
        data={
            props.data.map((item, key) => { // map users to table rows
                return ({
                    id: item.id,
                    db: item.db,
                    itemcode: item.itemcode,
                    timestamp: item.timestamp,
                    username: item.name
                })
            })
        }
        filterable
        defaultSorted={[
          {
            id: "cs1",
            desc: false
          }
        ]}
        columns={[
            {
                Header: "DB",
                accessor: "db",
                maxWidth:110,
                filterMethod: (filter, row) => {
                  const filterValue = filter.value ? filter.value.toLowerCase() : filter.value;
                  if (filterValue.length > 0) {
                      return row[filter.id].toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;
                  } else {
                      return true;
                  }
                }
            },
            {
                Header: "Item Code",
                accessor: "itemcode",
                filterMethod: (filter, row) => {
                  const filterValue = filter.value ? filter.value.toLowerCase() : filter.value;
                  if (filterValue.length > 0) {
                      return row[filter.id].toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;
                  } else {
                      return true;
                  }
                }
            },
            {
                id: 'cs1',
                Header: "Date/Time",
                accessor: "timestamp",
                Cell: cell => {
                  const d = cell.original;
                  return moment(d.timestamp).format("MM/DD/YYYY hh:mm a")
                },
                sortMethod: (a, b) => {
                  a = new Date(a).getTime();
                  b = new Date(b).getTime();
                     return b > a ? 1 : -1;
                },
                maxWidth: 150
            },
            {
                Header: "User",
                accessor: "username",
                Cell: cell => {
                  const d = cell.original;
                  return d.username;
                },
                filterMethod: (filter, row) => {
                  console.log('row', row, filter)
                  const filterValue = filter.value ? filter.value.toLowerCase() : filter.value;
                  if (filterValue.length > 0) {
                      return row[filter.id].toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;
                  } else {
                      return true;
                  }
                }
            }
        ]}
        defaultPageSize={10}
        showPaginationTop
        showPaginationBottom
        className="-striped -highlight"
    >
    {(state, makeTable, instance) => {
        let recordsInfoText = "";

        const { filtered, pageRows, pageSize, sortedData, page } = state;

        if (sortedData && sortedData.length > 0) {
          let isFiltered = filtered.length > 0;

          let totalRecords = sortedData.length;

          let recordsCountFrom = page * pageSize + 1;

          let recordsCountTo = recordsCountFrom + pageRows.length - 1;

          if (isFiltered)
            recordsInfoText = `Showing ${recordsCountFrom}-${recordsCountTo} of ${totalRecords.toLocaleString()} filtered locked BPs`;
          else
            recordsInfoText = `Showing ${recordsCountFrom}-${recordsCountTo} of ${totalRecords.toLocaleString()} locked BPs`;
        } else recordsInfoText = "No activity";

        return (
          <div className="main-grid">
            {makeTable()}
            <div style={{marginTop:'30px'}}>
              <div style={{textAlign:'center'}}>{recordsInfoText}</div>
            </div>
          </div>
        );
      }}
    </ReactTable>
  </div>
  );
}


export default LockedGIN;
