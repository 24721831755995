import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import withStyles from "@material-ui/core/styles/withStyles";
import {reset} from 'redux-form';

import ReactTable from "react-table";
import SweetAlert from "react-bootstrap-sweetalert";

import { FadeLoader } from 'react-spinners';
import Tooltip from "@material-ui/core/Tooltip";
import { css } from 'react-emotion';

import GridContainer from "../../components/Grid/GridContainer.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
import Button from "../../components/CustomButtons/Button.jsx";
import Edit from "@material-ui/icons/Edit";
import Lock from "@material-ui/icons/Lock";
import Close from "@material-ui/icons/Close";
import ADMultipleSelect from './ADMultipleSelect';
import ADSelect from './ADSelect';
import ADsyncModal from './ADsyncModal';

import {
    getCountryOptions,
    getOfficeOptions,
    getDepartmentOptions,
    getDepartmentDetails,
    getActiveUser
} from '../../store/selectors';

import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

import Modal from './ADModal/ADModal';
import PropTypes from 'prop-types';

class ActiveDirectory extends Component {
    state = {
        existing: false,
        formIsValid: true,
        departmentList: ['Accounting','Customer Support','IT','Logistics','Management',
        'Product Development',
        'Production',
        'Production Design',
        'Sales',
        'Ticketing'],
        departmentChosen: [],
        passwordEdit: false,
        syncModal: false,
        syncUserLength: 0,
        clickedUser: null
    }

    componentDidMount() {
        this.props.onFetchAD();
        this.props.loadDBs();
    }

    openSyncModal = (syncUserLength) => {
      this.setState({
        syncModal: true,
        syncUserLength
      });
    }

    closeSyncModal = () => {
      this.setState({
        syncModal: false
      });
    }

    miniModalDeleteOpenHandler = (key) => {
        this.props.onSetActiveUser(key);
        this.props.onSetMiniModalState('delete');
    }

    changePasswordEdit = (bool) => {
      this.setState({
        passwordEdit: bool
      });
    }

    deleteUser = () => {
        if (this.props.activeUser) {
            this.props.onDeleteUser(this.props.activeUser.dn);
        }
    }

    miniModalClosedHandler = () => {
        this.props.onSetMiniModalState('');
    }
    modalClosedHandler = () => {
        this.props.onSetModalState(false);
        this.props.onSetActiveUser(null);
    }
    modalOpenHandler = (user, index, password = false) => {
        this.props.onSetActiveUser(null);
        let existing = false;
        if (user) {
            this.props.onSetActiveUser(index);
            existing = true;
        }
        this.props.onSetModalState(true);
        this.setState({
            existing: existing,
            clickedUser: user
        });
    }

    setDepartmentChosen = (arr) => {
        this.setState({
            departmentChosen: arr
        });
    }

    handleMultiple = event => {
        this.setState({ multipleSelect: event.target.value });
      };

    submitHandler = values => {
        if (this.props.departmentDetails) {
            const oldValues = this.props.activeUser;
            if (JSON.stringify(values) !== JSON.stringify(oldValues)) {
                const newDN = `CN=${values.givenName} ${values.sn},${this.props.departmentDetails.ouDN}`
                if (this.state.existing === false) { //new newUser
                    const newUser = {
                        dn: newDN,
                        givenName: values.givenName,
                        sn: values.sn,
                        memberOf: this.props.departmentDetails.groupDN,
                        mail: values.givenName+'.'+values.sn+'@natcoglobal.com',
                        password: values.password,
                        userAccountControl: values.status ? 512 : 514
                    }
                    this.props.onAddADUser(newUser);
                } else { //edit existing user
                    console.log(oldValues);
                    const modifyUser = {
                        dn: oldValues.dn,
                        changes: [],
                    }
                    if (values.password) {
                        modifyUser.changes.push({
                            password: values.password
                        })
                    }
                    if (values.status !== oldValues.status) {
                        modifyUser.changes.push({
                            userAccountControl: values.status ? 512 : 514
                        })
                    }
                    if (values.givenName !== oldValues.givenName || values.sn !== oldValues.sn || values.country !== oldValues.country || values.office !== oldValues.office || values.department !== oldValues.department) { // if a new DN needs to be generated for the user
                        modifyUser.newDN = newDN;
                        if (values.country !== oldValues.country || values.office !== oldValues.office || values.department !== oldValues.department) {
                            let oldMember = '';
                            if (typeof oldValues.memberOf === 'string') {
                                oldMember = oldValues.memberOf;
                            } else if (typeof oldValues.memberOf === 'object') {
                                oldMember = oldValues.memberOf.find(x => x.indexOf(oldValues.department+',') > -1 && x.indexOf(oldValues.office) > -1 && x.indexOf(oldValues.country) > -1);
                            }
                            modifyUser.member = {
                                newMember: this.props.departmentDetails.groupDN,
                            }
                            if (oldMember !== '') {
                                modifyUser.member.oldMember = oldMember;
                            }
                        }
                        const acctName = `${values.givenName}.${values.sn}`;
                        if (values.givenName !== oldValues.givenName) {
                            modifyUser.changes.push({
                                givenName: values.givenName
                            });
                        }
                        if (values.sn !== oldValues.sn) {
                            modifyUser.changes.push({
                                sn: values.sn
                            })
                        }

                        if (values.sn !== oldValues.sn || values.givenName !== oldValues.givenName) {
                            modifyUser.changes.push({
                                sAMAccountName: acctName
                            });
                            modifyUser.changes.push({
                                userPrincipalName: acctName+'@NatcoGlobal.local'
                            });
                            modifyUser.changes.push({
                                mail: acctName+'@natcoglobal.com'
                            });
                        }

                    }
                    this.props.onModifyADUser(modifyUser);
                }
            }
        }
    }

    syncUsers = (users) => {
      let syncArr = [];
      this.props.users.forEach((u) => {
        syncArr.push({
          username: u.mail ? u.mail.split('@')[0] : 'none',
          name: u.name,
          office: u.office,
          department: u.department,
          mail: u.mail,
          active: u.status === 'Active' ? 1 : 0
        });
      });
      console.log(syncArr);
      this.openSyncModal(syncArr.length);
      // this.props.syncUsers(syncArr);
      this.props.bulkSync(syncArr);
    }

    getUserByName = (user) => {
      if (user.mail) {
        const username = user.mail.split('@')[0];
        this.props.getUserByName(username);
      }
    }

    render() {
        const optionsObj = {
            countryOptions: this.props.countryOptions,
            officeOptions: this.props.officeOptions,
            departmentOptions: this.props.departmentOptions
        }
        let miniModal = null;
        if (this.props.miniModal === 'delete' && this.props.activeUser) {
            const delTitle = `Are you sure you want to Delete ${this.props.activeUser.givenName} ${this.props.activeUser.sn}`;
            miniModal = (<SweetAlert
                warning
                style={{ display: "block" }}
                title={delTitle}
                onConfirm={() => this.deleteUser()}
                onCancel={() => this.miniModalClosedHandler()}
                confirmBtnCssClass={
                    this.props.classes.button + " " + this.props.classes.success
                }
                cancelBtnCssClass={
                    this.props.classes.button + " " + this.props.classes.danger
                }
                confirmBtnText="Yes, delete!"
                cancelBtnText="Cancel"
                showCancel
            >
                Deleting this user will permanently remove them from the Active Directory.
            </SweetAlert>)
        } else if (this.props.miniModal === 'successDeleted' || this.props.miniModal === 'successModified' || this.props.miniModal === 'successAdded') {
            const completedAction = this.props.miniModal.replace('success', '');
            miniModal = (<SweetAlert
                success
                style={{ display: "block" }}
                title={completedAction}
                onConfirm={() => this.miniModalClosedHandler()}
                onCancel={() => this.hideAlert()}
                confirmBtnCssClass={
                    this.props.classes.button + " " + this.props.classes.success
                }
            >
                The user has been {completedAction}. {completedAction == 'Added' ? <div><strong>NOTE :</strong> It can take up to 24 hours for the user mailbox to be created by the system.</div> : ''}
            </SweetAlert>
            )
        } else if (this.props.miniModal == 'failAdded') {
            miniModal = (<SweetAlert
                warning
                style={{ display: "block" }}
                title={'Failed to Add User'}
                onConfirm={() => this.miniModalClosedHandler()}
                onCancel={() => this.hideAlert()}
                confirmBtnCssClass={
                    this.props.classes.button + " " + this.props.classes.success
                }
            >
                May be a Duplicate User, Please use a different Firstname or Lastname
            </SweetAlert>)
        }
        const formElementsArray = [];
        for (let key in this.state.orderForm) {
            formElementsArray.push({
                id: key,
                config: this.state.orderForm[key]
            })
        }
        let office = [];
        this.props.users.forEach(function(u) {
            if (office.indexOf(u.office) == -1) {
                office.push(u.office);
            }
        });
            const table = <ReactTable
                data={
                    this.props.users.map((user, key) => { // map users to table rows
                        return ({
                            id: key,
                            name: user.name,
                            email: user.mail,
                            office: user.office,
                            department: user.department,
                            status: user.status,
                            actions: (
                                <div className="actions-right">
                                  <Tooltip
                                    id="tooltip-top-start1"
                                    title="Edit Password"
                                    placement="top"
                                    classes={{ tooltip: this.props.classes.tooltip }}
                                  >
                                    <Button
                                        justIcon
                                        round
                                        simple
                                        onClick={() => {
                                          this.changePasswordEdit(true);
                                          this.modalOpenHandler(user, key);
                                        }}
                                        color="primary"
                                    >
                                        <Lock />
                                    </Button>
                                  </Tooltip>
                                  <Tooltip
                                    id="tooltip-top-start2"
                                    title="Edit Details"
                                    placement="top"
                                    classes={{ tooltip: this.props.classes.tooltip }}
                                  >
                                    <Button
                                        justIcon
                                        round
                                        simple
                                        onClick={() => {
                                          this.changePasswordEdit(false);
                                          this.modalOpenHandler(user, key);
                                          this.getUserByName(user);
                                        }}
                                        color="warning"
                                    >
                                        <Edit />
                                    </Button>
                                  </Tooltip>
                                    {" "}
                                    { /* use this button to remove the data row */}
                                  <Tooltip
                                    id="tooltip-top-start3"
                                    title="Remove"
                                    placement="top"
                                    classes={{ tooltip: this.props.classes.tooltip }}
                                  >
                                    <Button
                                        justIcon
                                        round
                                        simple
                                        onClick={() => this.miniModalDeleteOpenHandler(key)}
                                        color="danger"
                                    >
                                        <Close />
                                    </Button>
                                  </Tooltip>
                                </div>
                            )
                        })
                    })
                }
                filterable
                columns={[
                    {
                        Header: "Name",
                        accessor: "name",
                        filterMethod: (filter, row) => {
                          var filterValue = filter.value.toLowerCase();
                          if (filterValue.length > 0) {
                              return row[filter.id].toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;
                          } else {
                              return true;
                          }
                        }
                    },
                    {
                        Header: "Email",
                        accessor: "email",
                        filterMethod: (filter, row) => {
                          var filterValue = filter.value.toLowerCase();
                          if (filterValue.length > 0 && row[filter.id]) {
                              return row[filter.id].toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;
                          } else if (filterValue.length > 0 && !row[filter.id]) {
                              return false;
                          } else {
                              return true;
                          }
                        }
                    },
                    {
                        Header: "Office",
                        accessor: "office",
                        filterMethod: (filter, row) => {
                            const chosen = filter.value;
                            if (chosen.length > 0) {
                                return chosen.indexOf(row[filter.id]) !== -1;
                            } else {
                                return true;
                            }
                        },
                        Filter: ({filter, onChange}) => {
                            return <div className="gmp-filter"><ADMultipleSelect
                                options={office}
                                label={''}
                                choose={onChange}
                            /></div>
                        }
                    },
                    {
                        Header: "Department",
                        accessor: "department",
                        filterMethod: (filter, row) => {
                            const chosen = filter.value;
                            if (chosen.length > 0) {
                                return chosen.indexOf(row[filter.id]) !== -1;
                            } else {
                                return true;
                            }
                        },
                        Filter: ({filter, onChange}) => {
                          return <div className="gmp-filter"><ADMultipleSelect
                                options={this.state.departmentList}
                                label={''}
                                choose={onChange}
                            /></div>
                        }

                    },
                    {
                        Header: "Status",
                        accessor: "status",
                        filterMethod: (filter, row) => {
                            const chosen = filter.value;
                            if (chosen && chosen !== 'All') {
                                return chosen == row[filter.id];
                            } else {
                                return true;
                            }
                        },
                        Filter: ({filter, onChange}) => {
                            return <div className="gmp-filter"><ADSelect
                                options={['All','Active','Inactive']}
                                label={''}
                                choose={onChange}
                            /></div>
                        }
                    },
                    {
                        Header: "Actions",
                        accessor: "actions",
                        sortable: false,
                        filterable: false,
                    }
                ]}
                defaultPageSize={10}
                showPaginationTop
                showPaginationBottom={false}
                className="-striped -highlight"
            />
        const override = css`
            display: block;
            margin: 0 auto;
            border-color: red;
        `;
        let layout = <FadeLoader
            className={override}
            sizeUnit={"px"}
            size={150}
            color={'#123abc'}
            loading={true}
        />

        if (!this.props.loading) { // if not loading then show layout
            layout = (
            <GridContainer justify="center">
                {this.state.syncModal && <ADsyncModal
                  open={this.state.syncModal}
                  close={this.closeSyncModal}
                  totalUserSync={this.state.syncUserLength}
                />}
                <GridItem xs={1} sm={1} md={1}></GridItem>
                <GridItem xs={12} sm={12} md={6}>
                    {miniModal}
                    <Button
                        color="rose"
                        round
                        onClick={() => this.modalOpenHandler()}>
                        Add User
                    </Button>

                    <Button
                        color="white"
                        round
                        onClick={() => {
                            this.syncUsers(this.props.users);
                        }}>
                        Sync Users
                    </Button>
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                    <Modal
                        passwordEdit={this.state.passwordEdit}
                        existing={this.state.existing}
                        optionsObj={optionsObj}
                        closeModal={this.modalClosedHandler}
                        open={this.props.modal}
                        submit={this.submitHandler}
                        change={this.inputChangedHandler}
                        activeIndex={this.state.activeIndex}
                        clickedUser={this.state.clickedUser}
                    />
                </GridItem>
                <GridItem xs={0} sm={0} md={1}></GridItem>
                <GridItem xs={12} sm={12} md={10}>
                    {table}
                </GridItem>
            </GridContainer>);
        }
        return layout;
    }
}


ActiveDirectory.propTypes = {
    users: PropTypes.array, //users from AD
    onFetchAD: PropTypes.func, // get users and directory from AD
    onSetActiveCountry: PropTypes.func, // set active country in Redux
    onSetActiveOffice: PropTypes.func, // set active office in redux
    onSetActiveUser: PropTypes.func, // set active user in redux
    onAddADUser: PropTypes.func, // add user to AD
    onModifyADUser: PropTypes.func, // modify user in AD
    onDeleteADUser: PropTypes.func,
    closeModal: PropTypes.func,
    countryOptions: PropTypes.array, // country options from redux selector
    officeOptions: PropTypes.array, // office options from redux selector
    departmentOptions: PropTypes.array, // department options from redux selector
    departmentDetails: PropTypes.object, // object w/ department ou and group DNs
    activeUser: PropTypes.object, // current active user
    onSetModalState: PropTypes.func, // set modal state in redux
    modal: PropTypes.bool, // modal state from redux
    miniModal: PropTypes.string, //miniModal state from redux
    loading: PropTypes.bool, // loading state from redux
    departmentList: PropTypes.array
};

const mapStateToProps = state => {
    return {
        users: state.ad.users,
        countryOptions: getCountryOptions(state),
        officeOptions: getOfficeOptions(state),
        departmentOptions: getDepartmentOptions(state),
        departmentDetails: getDepartmentDetails(state),
        activeUser: getActiveUser(state),
        modal: state.ad.modal,
        miniModal: state.ad.miniModal,
        loading: state.ad.loading,
        departmentList: state.departmentList
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadDBs: () => dispatch(actions.loadDBs()),
        onFetchAD: () => dispatch(actions.fetchAD()),
        onSetActiveCountry: country => dispatch(actions.setActiveCountry(country)),
        onSetActiveOffice: office => dispatch(actions.setActiveOffice(office)),
        onSetActiveUser: userIndex => dispatch(actions.setActiveUser(userIndex)),
        onAddADUser: user => {
            dispatch(actions.addADUser(user))
            dispatch(reset('adUser'))
        },
        onModifyADUser: modifyUser => {
            dispatch(actions.modifyADUser(modifyUser))
            dispatch(reset('adUser'))
        },
        onSetMiniModalState: modalState => dispatch(actions.setADMiniModalState(modalState)),
        onDeleteUser: userDN => dispatch(actions.deleteADUser(userDN)),
        onSetModalState: (isOpen) => dispatch(actions.setADModalState(isOpen)),
        syncUsers: (users) => dispatch(actions.usersSync(users)),
        bulkSync: (users) => dispatch(actions.bulkSync(users)),
        getUserByName: (username) => dispatch(actions.getUserByName(username))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(sweetAlertStyle)(ActiveDirectory));
