import React, { Component } from "react";
import { connect } from 'react-redux';
import ReactDOM from "react-dom";
import { PulseLoader } from 'react-spinners';

import withStyles from "@material-ui/core/styles/withStyles";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import combineStyles from "shared/combineStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import * as actions from 'store/actions';
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";
import { fetchGMPAddress, fetchGMPContact, fetchGMPRBO, changeActiveBillingCountry, changeActiveShippingCountry, createGMPBP, createGMPBPExisting, denyGMPBP, denyGMPBPExisting, fetchGSTType, fetchGMPSimilars, fetchGMPExistingSimilars } from "store/actions/GMP";
import { fetchDBList, fetchAddInfoList, fetchBPSAP, cancelGMPBPExistingPreview } from "store/actions/common";
import GMPProfileForm from "./GMPProfileForm";
import GMPProfileFormNewContact from "./GMPProfileFormNewContact";
import checkPermissionsActions from "shared/checkPermissionsActions";
import { normalizePhone, denormalizePhone, upperCase } from "shared/utility";

class GMPProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: '',
      errorModalOn: false,
      loading: false,
      loadingReview: false,
      reviewMode: false,
      reviewModeFormNewContact: false,
      currentBillingCountry: '',
      currentBillingStates: [],
      currentShippingCountry: '',
      currentBillingStates: [],
      currentDB: '',
      bpObj: null,
      rbo_name: null,
      billing_state_name: null,
      billing_country_name: null,
      shipping_state_name: null,
      shipping_country_name: null,
      approveSuccess: false,
      denySuccess: false,
      showAlertModal: false,
      duplicateContactName: '',
      similarsFound: false,
      similarsAlert: false,
      denyAlert: false,
      permActions: null
    }

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitNewContact = this.handleSubmitNewContact.bind(this);
    this.approveReviewNewContact = this.approveReviewNewContact.bind(this);
    this.miniModalClosedHandler = this.miniModalClosedHandler.bind(this);
    this.miniModalOpenHandler = this.miniModalOpenHandler.bind(this);
  }

  hideDenyAlert = () => {
    this.setState({denyAlert:false});
  }

  showDenyAlert = () => {
    this.setState({denyAlert:true});
  }

  miniModalClosedHandler = () => {
    this.setState({showAlertModal: false});
  }

  miniModalOpenHandler = () => {
    this.setState({showAlertModal: true});
  }

  getSimilars = (data) => {
    this.props.dispatch(fetchGMPSimilars(data));
  }

  setSimilars = () => {
    this.setState({
      similarsFound: true
    });
  }

  checkModalError = (errorAll) => {
    console.log('checking error here');
    if (errorAll) {
      this.setState({
        errorModalOn: true,
        loadingReview: false
      });
    }
  }

  checkModalErrorOff = () => {
    this.setState({
      errorModalOn: false,
      loadingReview: false
    });
  }

  getNameFromValues(value, source) {
    let name = null;
    source.forEach(function(s) {
      if (s.id == value) {
        name = s.name;
        return;
      }
    });
    return name;
  }

  handleLoadingReview = () => {
    this.setState({
      loadingReview: true
    });
  }

  handleSubmit(values) {
    console.log('clicked Review');
    setTimeout(() => {
      this.setState({
        bpObj: values,
        reviewMode: true,
        loadingReview: false
      });
    }, 500);
  }

  handleSubmitNewContact(values) {
    console.log('HANDLE', this.props);
    const existingNames = this.props.sapBPcontacts.map(c => c.name);
    if (existingNames.indexOf(values.new_name) == -1) {
      this.setState({ reviewModeFormNewContact: true, duplicateContactName: '' });
    } else {
      this.setState({ duplicateContactName: values.new_name });
    }
  }

  setLoading() {
    this.setState({ loading: true });
  }

  checkDBPermissions = () => {
    console.log('checking permissions');
    this.setState({
      permActions: checkPermissionsActions('GMP')
    });
  }

  cancelGMPBPExistingPreview = () => {
    this.props.dispatch(cancelGMPBPExistingPreview());
  };

  componentDidMount() {
    const bp = this.props.bp;
    console.log('component mounts', bp);
    this.checkDBPermissions();
    this.props.dispatch(fetchGMPAddress(bp.id));
    this.props.dispatch(fetchGMPContact(bp.id));
    this.props.dispatch(fetchDBList());
    this.props.dispatch(fetchAddInfoList(bp.DB));
    this.props.dispatch(fetchGSTType());

    if (bp.customer_num && bp.customer_name) {
      console.log('sapbpcontacts',this.props.sapBPcontacts);
      // this.props.dispatch(fetchBPSAP(this.props.bp.DB, this.props.bp.customer_name));
      //this.props.dispatch(fetchGMPContact(bp.id));
    }

    window.scrollTo(0, 0);
    ReactDOM.findDOMNode(this).scrollIntoView();
  }

  componentDidUpdate(prevProps) {
    //fetch similar company names, telephones, billing address, contact names ()
    const fieldsToCheck = ['company_name','gen_tel','billing_address1','accounts_payable_name','purchasing_name','other_name'];
    const bpObj = this.props.bpObj,
          bp = this.props.bp,
          prev_bpObj = prevProps.bpObj;
    let complete = true,
        modified = false;
    fieldsToCheck.forEach((f) => {
      if (!bpObj[f] && f !== 'other_name') {
        complete = false;
      }

      if (bpObj[f] !== prev_bpObj[f]) {
        modified = true;
      }
    });

    if (complete && modified) {
      console.log('getting similars');

      this.getSimilars({
        name: bpObj.company_name.substring(0, 5),
        tel: bpObj.gen_tel, //.substring(0, 5),
        address: bpObj.billing_address1.substring(0, 5),
        contact1: bpObj.accounts_payable_name.substring(0, 5),
        contact2: bpObj.purchasing_name.substring(0, 5),
        contact3: bpObj.other_name.substring(0, 5),
        db: bpObj.DB
      })
    }

    console.log('component did update', prevProps, this.props);

    //
    if ((prevProps.localContacts.length !== this.props.localContacts.length) && bpObj.customer_name) {
      if (this.props.localContacts[0]) {
        if (this.props.localContacts[0].hasOwnProperty('email')) {
          this.props.dispatch(fetchGMPExistingSimilars(bp.invoice_num,
            bp.customer_name,
            this.props.localContacts[0].email.toUpperCase(),
            bp.DB));
        }
      }
    }
  }

  changeDB(event) {
    console.log('changing DB to ', event.target.value);
    this.props.dispatch(fetchAddInfoList(event.target.value));
    // this.props.dispatch(changeDB(event.target.value));
  }

  changeBillingCountry(event) {
    console.log('changing Billing Country to - ', event.target.value);
    // this.props.dispatch(changeActiveBillingCountry(this.props.countries[event.target.value]));
    this.props.dispatch(changeActiveBillingCountry(event.target.value));
  }

  changeShippingCountry(event) {
    console.log('changing Shipping Country to - ', event.target.value);
    // this.props.dispatch(changeActiveShippingCountry(this.props.countries[event.target.value]));
    this.props.dispatch(changeActiveShippingCountry(event.target.value));
  }

  approveReview(values) {
    let wtaxcodesChosen = [];
    let bp = {...values};
    const telFields = ['accounts_payable_tel','other_tel','purchasing_tel','gen_tel','gen_fax'];
    telFields.forEach((f) => {
      bp[f] = denormalizePhone(bp[f]);
    });
    Object.keys(bp).forEach((b,i) => {
      if (b.indexOf('wtaxcode_') !== -1) {
        const wtCode = b.split('_')[2];
        if (bp[b] === true) {
          wtaxcodesChosen.push(wtCode);
        }
      }
    });
    bp.wtaxcode = wtaxcodesChosen.join(',');
    console.log('APPROVE', bp);
    this.props.dispatch(createGMPBP(bp));
  }

  denyReview = (values) => {
    console.log('DENY ', values);
    this.props.dispatch(denyGMPBP(values));
  }

  cancelReview() {
    ReactDOM.findDOMNode(this).scrollIntoView();
    this.setState({ reviewMode: false});
  }

  approveReviewNewContact(values) {
    let newObj = {};
    newObj.notes = values.notes;
    newObj.id = this.props.bp.id;
    newObj.DB = this.props.bpObj.DB;
    newObj.CardCode = this.props.sapBPGen.card_code;
    newObj.Name = values.new_name;
    newObj.Position = values.new_position;
    newObj.Tel1 = values.new_tel;
    newObj.E_MailL = values.new_email;
    newObj.VendorCode = this.props.bpObj.customer_num;
    this.props.dispatch(createGMPBPExisting(newObj));
  }

  denyReviewNewContact(values) {
    let obj = values;
    obj.id = this.props.bp.id;
    console.log('DENY ', values);
    this.props.dispatch(denyGMPBPExisting(values));
  }

  cancelReviewNewContact() {
    this.setState({ reviewModeFormNewContact: false});
  }

  setProfileLoadingOff() {
    this.setState({ profileLoading: false});
  }

  findState(state) {
    console.log(this.state.common);
  }

  checkIfExists(name, arr, itemCheck, id) {
    let exists = itemCheck;
    if (typeof itemCheck === 'number') { itemCheck = itemCheck.toString(); }
    //if (!isNaN(itemCheck) { itemCheck = +itemCheck} );
    arr.forEach((a) => {
      let aid = a.id,
          bid = a[id];
      if (typeof aid === 'number') { aid = aid.toString(); }
      if (typeof bid === 'number') { bid = bid.toString(); }
      const upperCased_a = bid.toUpperCase();
      const upperCased_b = itemCheck.toUpperCase();
      const upperCased_c = aid.toUpperCase();
      // console.log('checking if these 2 are the same', upperCased_b, upperCased_a);
      if (upperCased_a.includes(upperCased_b)) {
        exists = a.id;
        return;
      }
    });
    return exists;
  }

  checkIfStateExists = (stateToCheck, statesList) => {
    let stateFound = false;
    statesList.forEach((st) => {
      if (st.id == stateToCheck) {
        stateFound = true;
      }
    });

    if (!stateFound) { // try again, this time removing trailing 0
      stateToCheck = parseInt(stateToCheck).toString();
      statesList.forEach((st) => {
        if (st.id == stateToCheck) {
          stateFound = true;
        }
      });
    }

    return stateToCheck;
  }

  hideAlert = () => {
    this.setState({
      similarsAlert: null
    });
  }

  showAlert = (content, headers) => {
    const cont = content.map((c, i) => {
      // console.log(c);
      return (<div style={{paddingLeft:'20px',paddingRight:'20px'}} key={'oth'+i}><GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <div style={{textAlign:'left'}}>{c.split(',')[0]}</div>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <div style={{textAlign:'right'}}>{c.split(',')[1]}</div>
        </GridItem>
      </GridContainer></div>);
    });
    const fullContent = (
      <div style={{paddingLeft:'20px',paddingRight:'20px'}}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <div style={{textAlign:'left',marginLeft:'20px'}}><strong>{headers[0]}</strong></div>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <div style={{textAlign:'right',marginRight:'20px'}}><strong>{headers[1]}</strong></div>
          </GridItem>
        </GridContainer>
        <div style={{
            height:'120px',
            overflowY:'scroll',
            overflowX:'hidden',
            backgroundColor:'#fbfbfb',
            marginTop:'5px',
            paddingTop:'5px',
            paddingBottom:'5px'
          }}>
        {cont}
        </div>
      </div>
    );
    this.setState({
        similarsAlert: fullContent
    });
  }

  fetchChosenExistingBP = (bpname, db) => {
    this.props.dispatch(fetchBPSAP(db, bpname));
  }

  render() {
    console.log('the state is', this.state);
    console.log('the props is', this.props);
    const { bpObj, classes, cancelProfileView, billAddress, shipAddress, sap_dbs, rbos, changeDB, countries, states, currencies, sapBPGen, sapBPBillingAddresses, sapBPShippingAddresses, sapBPcontacts, existingBPfound, existingBPfoundMsg, activeBillingCountry, activeShippingCountry, permObj, gsttypes, existingSimilars } = this.props;

    let dbs = [];
    if (this.state.permActions) {
      dbs = sap_dbs.filter((d) => {
        if (this.state.permActions.approve.indexOf(d) !== -1) {
          return true;
        }
        return false;
      });
    }

    // check select option values if they are included with their respective options
    // DB, CURRENCY, BILLSTATE, BILLCOUNTRY, BILLGSTREGTYPE, SHIPSTATE, SHIPCOUNTRY, SHIPGSTREGTYPE

    let finalBPObj = {...bpObj};

    // if (countries && bpObj.billing_country) {
    //   if (finalBPObj.billing_country.length > 2) {
    //     finalBPObj.billing_country = this.checkIfExists('billing_country', countries, finalBPObj.billing_country, 'name');
    //   }
    //   if (finalBPObj.shipping_country.length > 2) {
    //     finalBPObj.shipping_country = this.checkIfExists('shipping_country', countries, finalBPObj.shipping_country, 'name');
    //   }
    // } else {
    //   finalBPObj.billing_country = '';
    //   finalBPObj.shipping_country = '';
    // }

    let shippingStates = null,
        billingStates = null,
        currentShipCountry = finalBPObj.shipping_country,
        currentBillCountry = finalBPObj.billing_country;

    if (countries && states) {
      if (activeBillingCountry) {
        if (activeBillingCountry.length == 2) {
          currentBillCountry = activeBillingCountry;
        }
      }
      billingStates = states[currentBillCountry] ? states[currentBillCountry] : null;

      if (activeShippingCountry) {
        if (activeShippingCountry.length == 2) {
          currentShipCountry = activeShippingCountry;
        }
      }
      shippingStates = states[currentShipCountry] ? states[currentShipCountry] : null;
    }

    if (billingStates && finalBPObj.billing_state) {
      if (finalBPObj.billing_state) {
        if (!isNaN(finalBPObj.billing_state)) {
          finalBPObj.billing_state = this.checkIfStateExists(finalBPObj.billing_state, billingStates);
        }
      }
    } else if (!billingStates && finalBPObj.billing_state) {
      finalBPObj.billing_state = '';
    } else {
      finalBPObj.billing_state = '';
    }

    if (shippingStates && finalBPObj.shipping_state) {
      if (finalBPObj.shipping_state) {
        if (!isNaN(finalBPObj.shipping_state)) {
          finalBPObj.shipping_state = this.checkIfStateExists(finalBPObj.shipping_state, shippingStates);
        }
      }
    } else if (!shippingStates && finalBPObj.shipping_state) {
      finalBPObj.shipping_state = '';
    }  else {
      finalBPObj.shipping_state = '';
    }

    if (this.props.gsttypes.length > 0 && bpObj.billing_gstregtype) {
      if (finalBPObj.billing_gstregtype) {
        finalBPObj.billing_gstregtype = this.checkIfExists('billing_gstregtype', this.props.gsttypes, finalBPObj.billing_gstregtype, 'type');
      }
    }

    if (this.props.gsttypes.length > 0 && bpObj.shipping_gstregtype) {
      if (finalBPObj.shipping_gstregtype) {
        finalBPObj.shipping_gstregtype = this.checkIfExists('shipping_gstregtype', this.props.gsttypes, finalBPObj.shipping_gstregtype, 'type');
      }
    }

    if (finalBPObj.DB === 'NIN_LIVE' || finalBPObj.DB === 'NIN_TEST') {
      finalBPObj.withholding = true;
      finalBPObj.threshold_overlook = "0";
      finalBPObj.surcharge_overlook = "0";
      finalBPObj.accrual_criteria = "0";
      finalBPObj.assessee_type = "0";
    }

    finalBPObj.dbs = dbs.join(',');
    finalBPObj.states = JSON.stringify(shippingStates);
    finalBPObj.currencies = JSON.stringify(currencies);
    finalBPObj.countries = JSON.stringify(countries);
    finalBPObj.gsttypes = JSON.stringify(gsttypes);

    finalBPObj.originals = {...finalBPObj};
    finalBPObj.originals.states = '';
    finalBPObj.originals.currencies = '';
    finalBPObj.originals.countries = '';
    finalBPObj.originals.gsttypes = '';

    console.log('Similars ',this.state.similarsAlert);
    let miniModalAlert = (<SweetAlert
        title={"Similar BP Fields"}
        style={{ display: "block" }}
        onConfirm={this.hideAlert}
        confirmBtnCssClass={
          this.props.classes.button + " " + this.props.classes.success + " this-just-in"
        }
        >
        {this.state.similarsAlert}
        </SweetAlert>);

    let gmpForm = (<div>
      <GMPProfileForm
        onSubmit={this.handleSubmit}
        loadingReview={this.state.loadingReview}
        handleLoadingReview={this.handleLoadingReview}
        checkModalError={this.checkModalError}
        checkModalErrorOff={this.checkModalErrorOff}
        errorModalOn={this.state.errorModalOn}
        bp={finalBPObj}
        sap_dbs={dbs}
        rbos={rbos}
        changeDB={this.changeDB.bind(this)}
        changeBillingCountry={this.changeBillingCountry.bind(this)}
        changeShippingCountry={this.changeShippingCountry.bind(this)}
        countries={countries}
        shippingStates={shippingStates}
        billingStates={billingStates}
        cancelProfileView={cancelProfileView}
        cancelProfileView2={this.props.cancelProfileView2}
        currencies={currencies}
        cancelReview={this.cancelReview.bind(this)}
        approveReview={this.approveReview.bind(this)}
        denyReview={this.denyReview}
        reviewMode={this.state.reviewMode}
        miniModalOpenHandler={this.miniModalOpenHandler}
        miniModalClosedHandler={this.miniModalClosedHandler}
        approveSuccess={this.state.approveSuccess}
        denySuccess={this.state.denySuccess}
        gsttypes={this.props.gsttypes}
        similars={this.props.similars}
        showAlert={this.showAlert}
        owhtopts={this.props.addinfo.owhtopts ? this.props.addinfo.owhtopts : null}
        denyAlert={this.state.denyAlert}
        showDenyAlert={this.showDenyAlert}
        hideDenyAlert={this.hideDenyAlert}
      />
      {this.state.similarsAlert && <div className="mini-mod-a">{miniModalAlert}</div>}
    </div>);

    if (bpObj.customer_num && this.props.localContacts.length > 0) {
      if (!existingBPfound) {
        gmpForm = (<GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <h2>Existing BPs</h2>
                  <hr />
                  <div><strong>Invoice # :</strong> {bpObj.invoice_num}</div>
                  <div><strong>BP Name :</strong> {bpObj.customer_name}</div>
                  <div><strong>E-mail :</strong> {bpObj.new_email}</div>
                  <div><strong>Database :</strong> {bpObj.DB}</div>
                  <hr />
                  {!existingSimilars && <PulseLoader />}
                </GridItem>
                  {
                    existingSimilars ?
                    <GridItem xs={12} sm={12} md={4}>
                      <List component="nav" dense={true}>
                        <ListItem><strong>{existingSimilars.invoice.length} found by Invoice #</strong></ListItem>
                        {
                          existingSimilars.invoice.map((inv, i) => {
                            return (
                              <ListItem
                                button
                                key={'invoice'+i}
                                onClick={() => {
                                  console.log(inv.CardName);
                                  this.fetchChosenExistingBP(inv.CardName, bpObj.DB);
                                }}
                              >
                                <span className="field-label">{inv.CardName}
                                </span>
                              </ListItem>
                            );
                          })
                        }
                      </List>
                    </GridItem> : ''
                  }
                  {
                    existingSimilars ?
                    <GridItem xs={12} sm={12} md={4}>
                      <List component="nav" dense={true}>
                        <ListItem><strong>{existingSimilars.bpname.length} found by BP Name</strong></ListItem>
                        {
                          existingSimilars.bpname.map((bpname, i) => {
                            return (
                              <ListItem
                                button
                                key={'bpname'+i}
                                onClick={() => {
                                  console.log(bpname.CardName);
                                  this.fetchChosenExistingBP(bpname.CardName, bpObj.DB);
                                }}
                              >
                                <span className="field-label">{bpname.CardName}
                                </span>
                              </ListItem>
                            );
                          })
                        }
                      </List>
                    </GridItem> : ''
                  }
                  {
                    existingSimilars ?
                    <GridItem xs={12} sm={12} md={4}>
                      <List component="nav" dense={true}>
                        <ListItem><strong>{existingSimilars.email.length} found by Email</strong></ListItem>
                        {
                          existingSimilars.email.map((email, i) => {
                            return (
                              <ListItem
                                button
                                key={'email'+i}
                                onClick={() => {
                                  console.log(email.CardName);
                                  this.fetchChosenExistingBP(email.CardName, bpObj.DB);
                                }}
                              >
                                <span className="field-label">{email.CardName}
                                </span>
                              </ListItem>
                            );
                          })
                        }
                      </List>
                    </GridItem> : ''
                  }
                  <GridItem xs={12} sm={12} md={12}>
                    <hr />
                    <div style={{float:'left'}}>
                      <Button
                        color="rose"
                        className={classes.updateProfileButton}
                        onClick={cancelProfileView}
                      >
                        BACK TO LIST
                      </Button>
                    </div>
                  </GridItem>
              </GridContainer>);
      } else {
        gmpForm = (<div>
                    <GMPProfileFormNewContact
                        onSubmit={this.handleSubmitNewContact}
                        bp={bpObj}
                        sapBPGen={sapBPGen}
                        sapBPBillingAddresses={sapBPBillingAddresses}
                        sapBPShippingAddresses={sapBPShippingAddresses}
                        sapBPcontacts={sapBPcontacts}
                        reviewModeFormNewContact={this.state.reviewModeFormNewContact}
                        cancelProfileView={cancelProfileView}
                        cancelProfileView2={this.props.cancelProfileView2}
                        cancelReviewNewContact={this.cancelReviewNewContact.bind(this)}
                        approveReviewNewContact={this.approveReviewNewContact.bind(this)}
                        denyReviewNewContact={this.denyReviewNewContact.bind(this)}
                        miniModalOpenHandler={this.miniModalOpenHandler}
                        miniModalClosedHandler={this.miniModalClosedHandler}
                        failSubmit={this.state.failSubmit}
                        duplicateContactName={this.state.duplicateContactName}
                        cancelGMPBPExistingPreview={this.cancelGMPBPExistingPreview}
                    />
                  </div>);
      }
    }

    // if (bpObj.customer_num && !existingBPfound) {
    //   gmpForm = <GridContainer>
    //               <GridItem xs={12} sm={12} md={12}>
    //                 <h4>BP {bpObj.customer_name ? <strong>{bpObj.customer_name.toUpperCase()} </strong> : ''}
    //                         {this.props.existingBPfoundMsg}
    //                         {this.props.existingBPfoundMsg === 'searching' ? <div><PulseLoader /></div> : ''}</h4>
    //               </GridItem>
    //               <div style={{marginTop:'20px'}} className="separate-btns">
    //                 <GridItem xs={12} sm={12} md={12}>
    //                   <Button
    //                     color="danger"
    //                     className={classes.updateProfileButton}
    //                     onClick={() => {
    //                       this.denyReview({
    //                         id:bpObj.id,
    //                         notes:'BP does not exist'
    //                       });
    //                       this.props.cancelProfileView2();
    //                     }}
    //                   >
    //                     DENY
    //                   </Button>
    //                   <Button
    //                     color="rose"
    //                     className={classes.updateProfileButton}
    //                     onClick={cancelProfileView}
    //                   >
    //                     BACK TO LIST
    //                   </Button>
    //                 </GridItem>
    //               </div>
    //             </GridContainer>
    // }


    return gmpForm;
  }
}

const mapStateToProps = (state, props) => {
  let obj = {};
  obj.company_name = upperCase(props.bp.company_name);
  obj.DB = props.bp.DB;
  obj.currency = props.bp.currency;
  obj.customer_num = props.bp.customer_num;
  obj.customer_name = upperCase(props.bp.customer_name);
  obj.date_added = props.bp.date_added;
  obj.date_modified = props.bp.date_modified;
  obj.gen_tel = normalizePhone(props.bp.gen_tel);
  obj.gen_fax = normalizePhone(props.bp.gen_fax);
  obj.panno = upperCase(props.bp.panno);
  obj.rfc_curp = upperCase(props.bp.rfc_curp);
  obj.gstin = upperCase(props.bp.gstin);
  obj.id = props.bp.id;
  obj.invoice_num = props.bp.invoice_num;
  obj.status = props.bp.status;
  obj.nit = upperCase(props.bp.nit);

  obj.billing_addressname = '';
  obj.billing_address1 = '';
  obj.billing_address2 = '';
  obj.billing_city = '';
  obj.billing_state = '';
  obj.billing_zipcode = '';
  obj.billing_country = '';

  obj.shipping_addressname = '';
  obj.shipping_address1 = '';
  obj.shipping_address2 = '';
  obj.shipping_city = '';
  obj.shipping_state = '';
  obj.shipping_zipcode = '';
  obj.shipping_country = '';

  obj.other_name = '';
  obj.other_position = '';
  obj.other_tel = '';
  obj.other_email = '';

  if (state.gmp.addresses.length > 0) {
    state.gmp.addresses.forEach(function(a) {
      if (a.type == 'B') {
        obj.billing_addressname = upperCase(a.name);
        obj.billing_address1 = upperCase(a.address1);
        obj.billing_address2 = upperCase(a.address2);
        obj.billing_city = upperCase(a.city);
        if (!isNaN(a.state)) {
          if (a.state.length === 1) {
            a.state = '0'+a.state;
          }
        }
        obj.billing_state = a.state;
        obj.billing_zipcode = a.postal_code;
        obj.billing_country = a.country;
        obj.billing_gstregno = upperCase(a.gstregno);
        obj.billing_gstregtype = a.gstregtype;
      } else {
        obj.shipping_addressname = upperCase(a.name);
        obj.shipping_address1 = upperCase(a.address1);
        obj.shipping_address2 = upperCase(a.address2);
        obj.shipping_city = upperCase(a.city);
        if (!isNaN(a.state)) {
          if (a.state.length === 1) {
            a.state = '0'+a.state;
          }
        }
        obj.shipping_state = a.state;
        obj.shipping_zipcode = a.postal_code;
        obj.shipping_country = a.country;
        obj.shipping_gstregno = upperCase(a.gstregno);
        obj.shipping_gstregtype = a.gstregtype;
      }
    });
  }

  if (state.gmp.contacts.length > 0) {
    state.gmp.contacts.forEach(function(c) {
      if (c.type == 'A') {
        obj.accounts_payable_name = upperCase(c.name);
        obj.accounts_payable_position = upperCase(c.position);
        obj.accounts_payable_tel = normalizePhone(c.telephone);
        obj.accounts_payable_email = upperCase(c.email);
      } else if (c.type == 'P') {
        obj.purchasing_name = upperCase(c.name);
        obj.purchasing_position = upperCase(c.position);
        obj.purchasing_tel = normalizePhone(c.telephone);
        obj.purchasing_email = upperCase(c.email);
      } else if (c.type == 'N') {
        obj.new_name = upperCase(c.name);
        obj.new_position = upperCase(c.position);
        obj.new_tel = normalizePhone(c.telephone);
        obj.new_email = upperCase(c.email);
      } else {
        obj.other_name = upperCase(c.name);
        obj.other_position = upperCase(c.position);
        obj.other_tel = normalizePhone(c.telephone);
        obj.other_email = upperCase(c.email);
      }
    });
  }

  let billingCountryCode = obj.billing_country,
      shippingCountryCode = obj.shipping_country;
  if (state.gmp.activeBillingCountry) {
    if (state.gmp.activeBillingCountry.length === 2) {
      obj.billing_country = state.gmp.activeBillingCountry
    }
    billingCountryCode = state.gmp.activeBillingCountry;
  }

  if (state.gmp.activeShippingCountry) {
    if (state.gmp.activeShippingCountry.length === 2) {
      obj.shipping_country = state.gmp.activeShippingCountry
    }
    shippingCountryCode = state.gmp.activeShippingCountry;
  }


  return {
    bpObj: obj,
    sap_dbs: state.common.dbs,
    sapBP: state.common.sapBP,
    rbos: state.gmp.rbos,
    addinfo: state.common.addinfo,
    countries: state.common.countries,
    states: state.common.states,
    currencies: state.common.currencies,
    formValues: state.form.hasOwnProperty('gmpProfileForm') ? state.form.gmpProfileForm : null,
    sapBPGen: state.common.sapBPGen,
    sapBPBillingAddresses: state.common.sapBPBillingAddresses,
    sapBPShippingAddresses: state.common.sapBPShippingAddresses,
    sapBPcontacts: state.common.sapBPcontacts,
    localContacts: state.gmp.contacts,
    gsttypes: state.gmp.gsttypes,
    existingBPfound: state.common.existingBPfound,
    existingBPfoundMsg: state.common.existingBPfoundMsg,
    activeBillingCountry: state.gmp.activeBillingCountry,
    activeShippingCountry: state.gmp.activeShippingCountry,
    similars: state.gmp.similars,
    existingSimilars: state.gmp.existingSimilars
  }
}

const combinedStyles = combineStyles(sweetAlertStyle, userProfileStyles);
export default connect(mapStateToProps,null)(withStyles(combinedStyles)(GMPProfile));
