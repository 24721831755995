import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import * as actions from '../../store/actions';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Table from "components/Table/Table2.jsx";
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import styles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import Datetime from "react-datetime";
import InputAdornment from '@material-ui/core/InputAdornment';
import GINSelect from "./GINselect";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';

import Button from '@material-ui/core/Button';
import Button2 from "components/CustomButtons/Button.jsx";
import { PulseLoader } from 'react-spinners';
import cloneDeep from 'lodash/cloneDeep';
import { isValidDate, numberWithCommas, checkDateOrder, dateToday, convertToFixed, checkMinMax } from 'shared/utility';
import { ranges } from "./GINranges";
import { checkOneYearEndDate, checkHighestPrice, checkExFactory } from "./GINhelpers";
import activeDBs from "shared/activeDBs";

const rangesOptions = ranges.map(r => {
  return { id: r.rangeA, name: numberWithCommas(r.rangeA) }
});

class GINaddPrice extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
    const dates = dateToday();
    this.state = {
      loading: false,
      validationMessage: '',

      // all customers pricing - flat price
      gterm: props.gterm[props.editTableData.DB] ? props.gterm[props.editTableData.DB] : '',
      gcur: props.gcur[props.editTableData.DB] ? props.gcur[props.editTableData.DB] : '',
      gprice: props.gprice[props.editTableData.DB] ? props.gprice[props.editTableData.DB] : '',
      gstart: props.gstart[props.editTableData.DB] ? props.gstart[props.editTableData.DB] : '',
      gend: props.gend[props.editTableData.DB] ? props.gend[props.editTableData.DB] : '',
      gcode: props.gcode[props.editTableData.DB] ? props.gcode[props.editTableData.DB] : '',

      // all customers pricing - other prices
      gqtysData: props.gqtys ? props.gqtys[props.editTableData.DB] : [],
      gtermsData: props.gterms ? props.gterms[props.editTableData.DB] : [],
      gcurrsData: props.gcurrs ? props.gcurrs[props.editTableData.DB] : [],
      gpricesData: props.gprices ? props.gprices[props.editTableData.DB] : [],
      gstartsData: props.gstarts ? props.gstarts[props.editTableData.DB] : [],
      gendsData: props.gends ? props.gends[props.editTableData.DB] : [],
      gcodesData: props.gcodes ? props.gcodes[props.editTableData.DB] : [],

      // all customers pricing, specific customers - input holders
      gqtys: props.minRange ? props.minRange : '',
      gterms: '',
      gcurrs: '',
      gprices: '',
      gstarts: dates[0],
      gends: dates[1],
      gcodes: '',

      mocObj: {},
      mocType: {},
      moc: {}
    }
  }

  getFactory = (db) => {
    let fac = '';
    activeDBs.forEach(d => {
      if (d.name === db) {
        fac = d.factory;
      }
    })
    return fac;
  }

  handleSelectChange = (value, name) => {
    this.setState({
      [name]: value,
      validationMessage: ''
    })
  }

  onIntegerChange = (target, e) => {
    if (!isNaN(e.target.value)) {
      this.setState({
        [target]: Math.round(e.target.value),
        validationMessage: ''
      })
    }
  }

  onFloatChange = (target, e) => {
    const re = /^[.0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
       this.setState({[target]: e.target.value, validationMessage: ''})
    }
  }

  onValueChange = e => {
    this.setState({ valueData: e.target.value.toUpperCase(), validationMessage: '' });
  }

  onDescChange = e => {
    this.setState({ descData: e.target.value.toUpperCase(), validationMessage: '' });
  }

  onValChange = (destination, e) => {
    this.setState({ [destination]: e.target.value, validationMessage: '' });
  }

  handleChange = name => value => {
    this.setState({
      [name]: value,
      validationMessage: ''
    });
  };

  handleDateChange = (date, target) => {
    this.setState({
      [target]: typeof date === 'string' ? date : date.format('MMM DD YYYY').toUpperCase(),
      validationMessage: ''
    });
  }

  handleClose = () => {
    this.props.close();
  }

  check = (val) => {
    if (val || val === 0) {
      return true;
    }
    return false;
  }

  handleSave = () => {
    console.log('pricingsData',this.props.pricingsData);
    const dataObj = {
      qty: this.state.gqtys,
      term: this.state.gterms,
      currency: this.state.gcurrs,
      price: this.state.gprices,
      db: this.props.editTableData.DB,
      prices: this.props.pricingsData
    }
    const checkHighest = checkHighestPrice(dataObj);
    if (!checkHighest) {
      return this.setState({
        validationMessage: `Base Price must always be the highest price for its shipping term (${this.state.gterms}) compared to other ranges. `
      });
    }

    const checkFactory = checkExFactory(dataObj, this.getFactory(this.props.editTableData.DB));
    if (!checkFactory) {
      return this.setState({
        validationMessage: `Ex-Factory price ${this.getFactory(this.props.editTableData.DB) ? this.getFactory(this.props.editTableData.DB) : ''}  should always be the lowest per DB per range. `
      });
    }
    let isComplete = true;
    ['gcurrs','gstarts','gends','gprices','gqtys','gterms'].forEach((g) => {
      if (!this.check(this.state[g])) {
        isComplete = false;
      }
    });

    const gstart = this.state.gstarts,
          gend = this.state.gends;

    if (!isValidDate(gstart) || !isValidDate(gend)) {
      return this.setState({
        validationMessage: 'Invalid Date. Please make sure Dates are MMM DD YYYY format. e.g. JAN 05 2019'
      });
    }

    if (!checkDateOrder(gstart, gend)) {
      return this.setState({
        validationMessage: 'Start Date must be before End Date'
      });
    }

    if (!checkOneYearEndDate(gstart, gend)) {
      return this.setState({
        validationMessage: 'End Date cannot be more than 1 year from Start Date'
      });
    }

    if (!isComplete) {
      return this.setState({
        validationMessage: 'Please complete all fields.'
      });
    }

    if (!checkMinMax(this.state.gprices, 5)) {
      console.log('gprices', this.state.gprices);
      return this.setState({
        validationMessage: 'Price needs to be between 0.00001 and maximum of 100,000. '
      });
    }

    const db = this.props.editTableData.DB;
    if (!this.props.gprice[db]) {
      console.log('gprice check',!this.props.gprice[db]);
      console.log('gqtys check', )
      // no required minimum pricing existing, add to single fields
      if (this.state.gqtys >= 1 && this.state.gqtys < 1000) {
        const equivalentArr = ['gcurrs', 'gstarts', 'gends', 'gprices', 'gterms'];
        ['gcur','gstart','gend','gprice','gterm'].forEach((field, i) => {
          if (field === 'gprice') {
            this.props.updateGINEdit({
              field,
              value: convertToFixed(this.state[equivalentArr[i]]),
              db
            });
          } else {
            this.props.updateGINEdit({
              field,
              value: this.state[equivalentArr[i]],
              db
            });
          }
        })
      } else {
        return this.setState({
          validationMessage: 'You currently do not have a Base Price (1 to 999 quantity range). Please add it first.'
        });
      }
    } else {
      ['gcurrs','gstarts','gends','gprices','gqtys','gterms','gcodes'].forEach((field) => {
        if (this.props[field][db]) {
          if (this.props[field][db].constructor === Array) {
            console.log('field',this.props[field][db]);
            const value = field === 'gprices' ?
                [ ...this.props[field][db], convertToFixed(this.state[field]) ] : [ ...this.props[field][db], this.state[field] ];
            this.props.updateGINEdit({
              field,
              value,
              db
            });
          }
        }
      });
    }
    if (this.state.moc) {
      let copySpecialMOC = cloneDeep(this.props.specialmoc);
      let forSpecialMOCDelete = [];
      Object.keys(this.state.moc).forEach(curr => {
        if (curr === this.state.gcurrs) {
          if (this.state.mocType[curr] === 'Standard') {
            // delete or change if in specialmoc
            if (this.props.specialmoc[db]) {
              this.props.specialmoc[db].forEach((currRow,i) => {
                if (currRow[0] === curr) {
                  forSpecialMOCDelete.push(i);
                }
              })
            }
          } else if (this.state.mocType[curr] === 'Special') {
            if (this.props.specialmoc[db]) {
              let existingMOCindex = -1;
              if (this.props.specialmoc[db].length > 0) {
                this.props.specialmoc[db].forEach((currRow,i) => {
                  if (currRow[0] === curr) {
                    existingMOCindex = i;
                  }
                })
              } 
              if (existingMOCindex === -1) {
                // add new specialmoc 
                copySpecialMOC[db].push([curr, this.state.moc[curr], '']);
              } else {
                // update specialmoc
                if (copySpecialMOC[db][existingMOCindex]) {
                  copySpecialMOC[db][existingMOCindex][1] = this.state.moc[curr];
                }
              }
            }
            this.props.updateGINEdit({
              db, field: 'specialmoc',
              value: copySpecialMOC[db]
            })
          }
        }
      })
      if (forSpecialMOCDelete.length > 0) {
        // delete specialmoc
        this.props.updateGINEdit({
          db,
          field: 'specialmoc',
          value: copySpecialMOC[db].filter((csm,i) => {
            if (forSpecialMOCDelete.indexOf(i) !== -1) {
              return false;
            }
            return true;
          })
        })
      }
    }
    this.props.changeCurrs(this.state.gcurrs);
    this.props.close();
  }

  onFloatChangeMOC = (target, e) => {
    let moc = cloneDeep(this.state.moc);
    let mocType = cloneDeep(this.state.mocType);
    if (e.target.value) {
      if (!isNaN(e.target.value)) {
        console.log('moc', e.target.value);
        moc[target] = parseFloat(e.target.value);
        mocType[target] = 'Special';
      }
    } else {
      moc[target] = '';
      mocType[target] = 'Standard';
    }
    this.setState({
      moc,
      mocType,
      validationMessage: ''
    });
  }

  componentDidMount() {
    const mocObj = {}, mocType = {}, moc = {}, db = this.props.editTableData.DB;
    console.log('mocObj', db, this.props.specialmoc)
    if (this.props.options) {
      if (this.props.options[db]) {
        if (this.props.options[db].currencies) {
          if (this.props.options[db].currencies.length > 0) {
            this.props.options[db].currencies.forEach(cc => {
              moc[cc.symb] = 0;
              mocObj[cc.symb] = 0;
              mocType[cc.symb] = 'Standard';
              if (this.props.mocs[db]) {
                if (this.props.mocs[db][cc.symb]) {
                  mocObj[cc.symb] = this.props.mocs[db][cc.symb];
                }
              }
            });
          }
        }
      }
    }
    const data = this.props.editTableData;
    if (this.props.specialmoc) {
      if (db) {
        if (this.props.specialmoc[db]) {
          this.props.specialmoc[db].forEach(smoc => {
            if (smoc[0] && smoc[1]) {
              moc[smoc[0]] = smoc[1];
              mocObj[smoc[0]] = smoc[1];
              mocType[smoc[0]] = 'Special';
            }
          });
        }
      }
    }

    this.setState({mocObj,mocType,moc});
  }

  changeToStandardMOC = (curr) => {
    let copyMOC = cloneDeep(this.state.moc);
    let copyMOCtype = cloneDeep(this.state.mocType);
    copyMOC[curr] = '';
    copyMOCtype[curr] = 'Standard'
    this.setState({
      moc: copyMOC,
      mocType: copyMOCtype
    });
  }

  changeToSpecialMOC = (curr) => {
    let copyMOCtype = cloneDeep(this.state.mocType);
    copyMOCtype[curr] = 'Special';
    this.setState({
      mocType: copyMOCtype
    })
  }

  render() {
    console.log('GINadd-price', this.props);
    const { classes } = this.props;
    const data = this.props.editTableData;
    let pricingTermsOptions = [];
    if (this.props.options[data.DB]) {
      pricingTermsOptions = this.props.options[data.DB].terms.pricing_terms;
    }
    let currencyOptions = [];
    if (this.props.options[data.DB]) {
      currencyOptions = this.props.options[data.DB].currencies;
    }

    console.log('states & props', this.state, this.props);
    const currentTerm = this.state.gterms,
          currentCurr = this.state.gcurrs,
          currentDB = data.DB,
          allPrices = this.props.allPrices[data.DB];

    let otherSimilarData = [];
    Object.keys(allPrices).forEach(range => {
      allPrices[range].forEach(pr => {
        console.log('comparing', pr.currency, currentCurr, pr.term, currentTerm);
        if (pr.currency === currentCurr && pr.term === currentTerm) {
          let totalPrice = pr.total;
          if (pr.total < pr.moc) {
            totalPrice = pr.moc;
          }
          otherSimilarData.push([
            range,
            pr.term,
            `${pr.currency} ${pr.price}`,
            pr.currency,
            `${pr.start} TO ${pr.end}`,
            `${pr.currency} ${totalPrice}`
          ]);
        }
      });
    });
    console.log('otherSimilarData',otherSimilarData);
    const allOpenedCurrs = [this.state.gcurrs];

    return (
      <Dialog
        open={this.props.open}
        keepMounted
        onClose={this.handleClose}
        maxWidth="md"
        fullWidth={true}
        className="dialog-overflow2"
      >
        <DialogTitle>
          <div style={{textAlign:'center'}}>ADD PRICING FOR ALL CUSTOMERS ({data.DB})</div>
          <div className="close-btn">
            <IconButton aria-label="Close" onClick={this.handleClose}>
              <Close />
            </IconButton>
          </div>
        </DialogTitle>
        <div style={{padding:'0 40px 20px 40px', overflowY:'scroll'}}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={3}>
              <div className="select-adjust">
                <GINSelect
                  label="Quantity"
                  options={rangesOptions}
                  choose={this.handleSelectChange}
                  name="gqtys"
                  indx="id"
                  desc="name"
                  default={this.state.gqtys}
                />
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <div className="select-adjust">
                <GINSelect
                  label="Shipping Terms"
                  options={pricingTermsOptions}
                  choose={this.handleSelectChange}
                  name="gterms"
                  indx=""
                  desc=""
                  default={this.state.gterms}
                />
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={2}>
              <div className="select-adjust">
                <GINSelect
                  label="Currency"
                  options={currencyOptions}
                  choose={this.handleSelectChange}
                  name="gcurrs"
                  indx="symb"
                  desc="symb"
                />
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <CustomInput
                labelText="Price"
                id="pricing_price"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: (e) => {
                    this.onFloatChange('gprices', e);
                  },
                  value: this.state.gprices,
                  autoFocus: true,
                  role: 'presentation',
                  autoComplete: 'nope'
                }}
              />
            </GridItem>
          </GridContainer>

          <GridContainer>
            <GridItem xs={12} sm={12} md={3}></GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <div className="calendar-adjust">
                <Datetime
                  inputProps={{ placeholder: "Start Date" }}
                  dateFormat="MMM DD YYYY"
                  timeFormat={false}
                  value={this.state.gstarts}
                  onChange={(date) => {
                    this.handleDateChange(date, 'gstarts');
                    if (!this.state.gends) {
                      if (typeof date !== 'string') {
                        let newDate = date.add('years', 1).format('MMM DD YYYY').toUpperCase();
                        this.setState({
                          gends: newDate
                        });
                      }
                    }
                  }}
                  closeOnSelect={true}
                />
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <div className="calendar-adjust">
                <Datetime
                  inputProps={{ placeholder: "End Date" }}
                  dateFormat="MMM DD YYYY"
                  timeFormat={false}
                  value={this.state.gends}
                  onChange={(date) => {
                    this.handleDateChange(date, 'gends')
                  }}
                  closeOnSelect={true}
                />
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={3}></GridItem>
          </GridContainer>

          <GridContainer>
            <GridItem xs={12} sm={12} md={4}></GridItem>
            <GridItem xs={12} sm={12} md={4}>
              {/* MOC object */}
              <div style={{marginTop:30}}></div>
              {
                Object.keys(this.state.mocObj).length > 0 && <div>
                  {
                    Object.keys(this.state.mocObj).map((curry,indd) => {
                      if (allOpenedCurrs.indexOf(curry) === -1) {
                        return null;
                      }
                      return <div className="select-adjust relative" style={{marginTop:'10px'}} key={`${indd}`}>
                        <div style={{position:'absolute',top:'-13px',left:'-50px'}}>
                          <Checkbox
                            tabIndex={-1}
                            checkedIcon={<Check className={classes.checkedIcon} />}
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{checked: classes.checked}}
                            checked={this.state.mocType[curry] === 'Standard' ? true : false}
                            onClick={() => {
                              console.log('you clicked standard', curry);
                              this.changeToStandardMOC(curry);
                            }}
                          />
                        </div>
                        <span className="gray-text">Standard MOC</span>
                        <span key={`a${curry}`} style={{marginLeft:'10px'}}>{curry} {numberWithCommas(this.props.mocs[data.DB][curry])}</span>
                      </div>
                    })
                  }
                </div>
              }
              {Object.keys(this.state.mocObj).map((curr,ii) => {
                  if (allOpenedCurrs.indexOf(curr) === -1) {
                    return null;
                  }
                  return (
                  <div className="select-adjust relative" style={{marginTop:'20px'}} key={`sd${ii}`}>
                    <div style={{position:'absolute',top:'-13px',left:'-50px'}}>
                      <Checkbox
                        tabIndex={-1}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{checked: classes.checked}}
                        checked={this.state.mocType[curr] === 'Special' ? true : false}
                        onClick={() => {
                          console.log('you clicked special', curr);
                          this.changeToSpecialMOC(curr);
                        }}
                      />
                    </div>
                    <span className="gray-text">Special MOC</span>
                      <CustomInput
                        key={`b${curr}`}
                        labelText=""
                        id={`bb${curr}`}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          onChange: (e) => {
                            this.onFloatChangeMOC(curr, e);
                          },
                          value: this.state.moc[curr],
                          role: 'presentation',
                          autoComplete: 'nope',
                          startAdornment: <InputAdornment position="start">{curr}</InputAdornment>
                        }}
                      />
                    </div>
                  )
                })}
            </GridItem>
            <GridItem xs={12} sm={12} md={4}></GridItem>
          </GridContainer>

          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              {this.state.loading && <PulseLoader />}
              <div style={{padding:'20px',textAlign:'center'}}>
                <Button2
                  color="success"
                  onClick={this.handleSave}
                >
                  SAVE
                </Button2>
                {this.state.validationMessage && <div className="red-text text-center" style={{marginTop:'20px'}}>{this.state.validationMessage}</div>}
              </div>
            </GridItem>
          </GridContainer>

          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              {otherSimilarData.length > 0 && <div style={{marginTop:'20px'}}>
                <h4 className="text-center">SIMILAR ITEMS</h4>
                <Table
                  tableHeaderColor="primary"
                  tableHead={["QTY","SHIP TERMS", "PRICE", "CURRENCY", "EFFECTIVE DATES", "TOTAL PRICE"]}
                  tableData={otherSimilarData}
                  coloredColls={[3]}
                  colorsColls={["primary"]}
                />
              </div>}
            </GridItem>
          </GridContainer>
        </div>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    gcur: state.gin.gcur,
    gstart: state.gin.gstart,
    gend: state.gin.gend,
    gprice: state.gin.gprice,
    gterm: state.gin.gterm,
    gcode: state.gin.gcode,

    gcurrs: state.gin.gcurrs,
    gstarts: state.gin.gstarts,
    gends: state.gin.gends,
    gprices: state.gin.gprices,
    gqtys: state.gin.gqtys,
    gterms: state.gin.gterms,
    gcodes: state.gin.gcodes,

    mocs: state.gin.mocs,
    specialmoc: state.gin.specialmoc,

    itemCodes: state.gin.itemCodes,
    openedDBs: state.gin.openedDBs,
    options: state.gin.options,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateGINEdit: (data) => {
      dispatch(actions.updateGINEdit(data));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GINaddPrice));
