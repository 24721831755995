import React from 'react';
import Table from 'components/Table/Table.jsx';
import sapMonitorData from './SAPMonitorLogic';
import { Check, Close } from '@material-ui/icons';
const { calculate } = sapMonitorData;

const getStyle = (val) => {
    if (val) {
        if (val.indexOf('Inconsistencies') !== -1 || 
            val.indexOf('Inconsistent') !== -1 || 
            val.indexOf('with errors') !== -1 ||
           val.indexOf('Error') !== -1) {
            return {
                color: 'red'
            }
        } else {
            return {
                color: 'green'
            }
        }
    }
    return {};
}

const getIcon = (val) => {
    if (val) {
        if (val.indexOf('Inconsistencies') !== -1 || 
            val.indexOf('Inconsistent') !== -1 || 
            val.indexOf('with errors') !== -1 ||
           val.indexOf('Error') !== -1) {
            return <Close />
        } else {
            return <Check />
        }
    }
}

const SAPMonitorScenario = props => {
    console.log('SAPMonitorScenario', props);
    const { orderData, sapBpDetails, orderDetails, scenario, sap_order_details, sapBpaDetails, 
        bp_details_magento,
        bp_details_sap,
        order_details_sap,
        order_details_magento,
        sap_bpa_item_details
    } = props;
    const scenarioDefinition = {
        1: 'PBP-Payment by CC or Paypal',
        2: 'PBP-Payment by Check or Wire',
        3: 'Payment by Credit'
    }
    const data = {
        sap_order_details,

        bp_details_magento, 
        bp_details_sap,

        order_details_sap,
        order_details_magento,

        orderDetails,
        sapBpDetails,
        orderData,
        sapBpaDetails,
        sap_bpa_item_details
    };
    const createScenario = (scenario) => {
        // scenario === 3
        let tableHead = [
            'Status',
            <div>Order is<br />Placed</div>,
            <div>Integration<br />(Order Import)</div>,
            'Order Posted',
            <div>
                Procurement Document<br />
                Posted against<br />
                SO
            </div>,
            <div>Integration<br />(Magento (In Production))</div>,
            'Invoice Posted',
            <div>Integration<br />(Completed not shipped)</div>,
            <div>
                Tracking<br />
                Added to<br />
                Invoice
            </div>,
            <div>Integration<br />(Magento (Shipped))</div>,
        ];

        if (scenario === 1) {
            tableHead = [
                'Status',
                <div>Order is<br />Placed</div>,
                <div>Integration<br />Posts PI, ARDP & PI/ARDP UDT</div>,
                'Order Posted',
                'ARDP Posted',
                <div>Incoming<br />
                    Payment Posted
                </div>,
                'Integration',
                'Invoice Posted',
                'Integration',
                <div>Tracking added<br />to invoice</div>,
                'Integration'
            ];
        } else if (scenario === 2) {
            tableHead = [
                'Status',
                <div>Order is<br />Placed</div>,
                'Integration',
                'PI Posted',
                <div>
                    Temporary ARDP<br />Posted
                </div>,
                <div>Writes to<br />
                    PI/ARDP relationship<br />
                    UDT
                </div>,
                <div>
                    Incoming Payment<br />
                    Posted
                </div>,
                'Integration',
                'ARDP Canceled',
                'IP Canceled',
                'SO Posted',
                <div>
                    Replacement ARDP<br />
                    Posted
                </div>,
                <div>
                    Replacement IP<br />
                    Posted 
                </div>,
                <div>
                    Update PI/ARDP<br />
                    relationship UDT
                </div>,
                <div>
                    Procurement Document<br />
                    Posted against SO
                </div>,
                'Integration',
                'Invoice Posted',
                'Integration',
                <div>
                    Tracking<br />
                    added to<br />
                    invoice
                </div>,
                'Integration'
            ]
        }

        // default 1st column values (labels only)
        let row1 = [
           <div>
               Consistency<br />
               Check
           </div> 
        ]
        let row2 = [
            'Result'
        ]
        let row3 = [
            'Location'
        ];
        // calculate other columns based on scenario
        tableHead.forEach((th,i) => {
            if (i > 0) {
                let b = calculate(scenario, 2, i, data)
                let a = b ? 
                    calculate(scenario, 1, i, data)
                : 
                    '';
                console.log('the a', a);
                if (a !== 'Error') {
                    if (a.indexOf('Inconsistencies') !== -1) {
                        b = b + ' with errors';
                    }
                }
                const c = calculate(scenario, 3, i, data)
                row1.push(<div className="hoverable" style={getStyle(a)} onClick={props.openOrder}>{getIcon(a)}</div>);
                row2.push(<div className="hoverable" style={getStyle(b)} onClick={props.openOrder}>{getIcon(b)}</div>);
                row3.push(<div>{c}</div>);
            }
        })
        let tableData = [row1, row2, row3];

        return {
            tableHead,
            tableData
        }
    }

    const scenarioObj = createScenario(props.scenario);

    return <div>
        <hr />
        <h4><em>Scenario {scenario} - {scenarioDefinition[scenario]}</em></h4>
        <Table 
            tableHead={scenarioObj.tableHead}
            tableData={scenarioObj.tableData}
        />
    </div>
}

export default SAPMonitorScenario;