import * as actionTypes from './actionTypes';

export const getUsers = () => {
    return {
        type: actionTypes.GET_USERS,
    }
}

export const getUsersStart = () => {
    return {
        type: actionTypes.GET_USERS_START,
    }
}

export const getUsersSuccess = (userList) => {
    return {
        type: actionTypes.GET_USERS_SUCCESS,
        userList
    }
}

export const getUsersFail = (err) => {
    return {
        type: actionTypes.GET_USERS_FAIL,
        err
    }
}

export const getUsersFiltered = (permsList) => {
    return {
        type: actionTypes.GET_USERS_FILTERED,
        permsList
    }
}

export const getUsersFilteredStart = () => {
    return {
        type: actionTypes.GET_USERS_FILTERED_START,
    }
}

export const getUsersFilteredSuccess = (userList) => {
    return {
        type: actionTypes.GET_USERS_FILTERED_SUCCESS,
        userList
    }
}

export const getUsersFilteredFail = (err) => {
    return {
        type: actionTypes.GET_USERS_FILTERED_FAIL,
        err
    }
}

export const getUser = (user_id) => {
    return {
        type: actionTypes.GET_USER,
        user_id
    }
}

export const getUserStart = () => {
    return {
        type: actionTypes.GET_USER_START,
    }
}

export const getUserSuccess = (userPerms) => {
    return {
        type: actionTypes.GET_USER_SUCCESS,
        userPerms
    }
}

export const getUserFail = (err) => {
    return {
        type: actionTypes.GET_USER_FAIL,
        err
    }
}

export const loadDBs = () => {
  return {
    type: actionTypes.LOAD_DBS
  }
}

export const getUserByName = (username) => {
  return {
    type: actionTypes.GET_USER_BY_NAME,
    username
  }
}

export const getUserByNameSuccess = (userPerms) => {
  return {
    type: actionTypes.GET_USER_BY_NAME_SUCCESS,
    userPerms
  }
}

export const getUserByNameStart = () => {
  return {
    type: actionTypes.GET_USER_BY_NAME_START
  }
}

export const getUserByNameFail = (err) => {
  return {
    type: actionTypes.GET_USER_BY_NAME_FAIL,
    err
  }
}

export const loadDBsStart = () => {
  return {
    type: actionTypes.LOAD_DBS_START
  }
}

export const loadDBsSuccess = (dbs) => {
  return {
    type: actionTypes.LOAD_DBS_SUCCESS,
    dbs
  }
}

export const loadDBsFail = (err) => {
  return {
    type: actionTypes.LOAD_DBS_FAIL,
    err
  }
}

export const saveUserPerms = (obj) => {
  return {
    type: actionTypes.SAVE_USER_PERMS,
    obj
  }
}

export const saveUserPermsStart = () => {
  return {
    type: actionTypes.SAVE_USER_PERMS_START
  }
}

export const saveUserPermsSuccess = (response) => {
  return {
    type: actionTypes.SAVE_USER_PERMS_SUCCESS,
    response
  }
}

export const saveUserPermsFail = (err) => {
  return {
    type: actionTypes.SAVE_USER_PERMS_FAIL,
    err
  }
}

export const chooseNewPerms = (newPerms) => {
  return {
    type: actionTypes.CHOOSE_NEW_PERMS,
    newPerms
  }
}

export const usersSync = (users) => {
    return {
        type: actionTypes.USERS_SYNC,
        users
    }
}

export const syncUserStart = () => {
  return {
    type: actionTypes.SYNC_USER_START
  }
}

export const syncUserSuccess = (data) => {
  return {
    type: actionTypes.SYNC_USER_SUCCESS,
    data
  }
}

export const syncUserFail = (err) => {
  return {
    type: actionTypes.SYNC_USER_FAIL,
    err
  }
}

export const bulkSync = (users) => {
  return {
    type: actionTypes.BULK_SYNC,
    users
  }
}

export const bulkSyncStart = () => {
  return {
    type: actionTypes.BULK_SYNC_START
  }
}

export const bulkSyncSuccess = (response) => {
  return {
    type: actionTypes.BULK_SYNC_SUCCESS,
    response
  }
}

export const bulkSyncFail = (err) => {
  return {
    type: actionTypes.BULK_SYNC_FAIL,
    err
  }
}

export const bulkAssign = (userList, perms, removes) => {
  return {
    type: actionTypes.BULK_ASSIGN,
    userList,
    perms,
    removes
  }
}

export const bulkAssignStart = () => {
  return {
    type: actionTypes.BULK_ASSIGN_START
  }
}

export const bulkAssignSuccess = (username) => {
  return {
    type: actionTypes.BULK_ASSIGN_SUCCESS,
    username
  }
}

export const bulkAssignFail = (err) => {
  return {
    type: actionTypes.BULK_ASSIGN_FAIL,
    err
  }
}

export const bulkAssignEnd = () => {
  return {
    type: actionTypes.BULK_ASSIGN_END
  }
}

export const maintenanceMode = (value) => {
  return {
    type: actionTypes.MAINTENANCE_MODE,
    value
  }
}

export const maintenanceModeSuccess = (response) => {
  console.log('response', response);
  return {
    type: actionTypes.MAINTENANCE_MODE_SUCCESS,
    response
  }
}

export const checkMaintenanceMode = () => {
  return {
    type: actionTypes.CHECK_MAINTENANCE_MODE
  }
}

export const checkMaintenanceModeSuccess = (response) => {
  return {
    type: actionTypes.CHECK_MAINTENANCE_MODE_SUCCESS,
    response
  }
}

export const notifyMail = (to,subject,message) => {
  return {
    type: actionTypes.NOTIFY_MAIL,
    to, subject, message
  }
}
