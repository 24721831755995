const createImage = url =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', error => reject(error))
    image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
    image.src = url
    image.onload = () => console.log('got here',image.width, image.height);
  })

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 */


export default async function getCroppedImg(imageSrc, pixelCrop, imageExt) {
  const image = await createImage(imageSrc)
  console.log('im',image)
  const canvas = document.createElement('canvas');
  canvas.width = 1000;
  canvas.height = 1000;

  // width should be constant 1000
  // height should adjust;

  // what is the ratio of the original image
  //
  // w / h = 1000 / x    =
  const ctx = canvas.getContext('2d')
  console.log('draw', pixelCrop.width, pixelCrop.height);
  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    1000,
    1000
  )

  // As Base64 string
  return canvas.toDataURL('image/png');

  // As a blob
  // return new Promise((resolve, reject) => {
  //   canvas.toBlob(file => {
  //     resolve(URL.createObjectURL(file))
  //   }, 'image/jpeg')
  // })
}

export async function getCroppedImg2(imageSrc, pixelCrop, imageExt) {
  const image = await createImage(imageSrc)
  const canvas = document.createElement('canvas');
  canvas.width = 1000;
  canvas.height = 1000;

  // width should be constant 1000
  // height should adjust;

  // what is the ratio of the original image
  //
  // w / h = 1000 / x    =
  const ctx = canvas.getContext('2d')
  console.log('draw', pixelCrop.width, pixelCrop.height);
  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    1000,
    1000
  )

  // As Base64 string
  console.log('converting');
  return canvas.toDataURL('image/jpeg', 0.5).toDataURL('image/png');
}

export async function convertJPG(imageSrc, w, h) {
  const image = await createImage(imageSrc)
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d')
  canvas.width = w;
  canvas.height = h;
  ctx.drawImage(
    image, 10, 10)

  // As Base64 string
  console.log('converting', canvas.toDataURL('image/png'));
  return canvas.toDataURL('image/png');
}
