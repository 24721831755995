import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as actions from "store/actions";
import Element from "./Element";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import { updateObject } from "../../shared/utility";
import dbList from "../../shared/activeDBs";
import { liveDBs } from "../../shared/activeDBs";
import Notification from "../../components/Notification/Notification";
import { ClipLoader } from "react-spinners";
import SAPMonitorModal from "./SAPMonitorModal";
import SAPMonitorList from "./SAPMonitorList";
import sapModel from "./SAPMonitorLogic";

const orderStatuses = sapModel.orderStatuses;

const theDBs = liveDBs; //TODO: uncomment when using test DBs, dbList.map(db => db.name);

const newFilterObj = {
	database: '',
	company_name: '',
	magento_order_number: '',
	order_date: '',
	order_status: '',
	magento_email: '',
	order_total_amount: '',
	sap_so_number: '',
	sap_pi_number: '',
	payment_method: ''
};

const SAPMonitor = (props) => {
	const [filter, setFilter] = useState(newFilterObj);
	const [notification, setNotification] = useState('');
	const [openedModal, setOpenedModal] = useState(false);
    const { getSmMagentoOrders, getSmMagentoCustomers, loading, getBpByName, 
		bps, loading_bps, magentoOrders, pi_list, getPiListFilter, setPI
	} = props;

    return <div style={{marginBottom: 20}}>
		<GridContainer>
			<GridItem xs={12} sm={12} md={12}>
				<h3>Filter / Search</h3>
			</GridItem>
			<GridItem xs={12} sm={12} md={3}>
				<Element 
					type="select"
					label="Database"
					value={filter.database}
					options={[''].concat(theDBs)}
					onChange={(e) => {
						setFilter(updateObject(filter, {
							database: e
						}));
					}}
				/>
			</GridItem>
			<GridItem xs={12} sm={12} md={3}>
				<Element 
					value={filter.company_name ? filter.company_name.split(')')[1] : ''}
					label="Company Name (BP Name)"
					onClick={() => {
						if (!filter.database) {
							setNotification('Please select a database first.');
						} else {
							setOpenedModal(true);
						}
					}}
					type="input"
				/>
			</GridItem>
			<GridItem xs={12} sm={12} md={3}>
				<Element 
					type="input"
					value={filter.magento_order_number}
					label="Magento Order Number"
					onChange={((e) => {
						setFilter(updateObject(filter, {
							magento_order_number: e.target.value
						}));
					})}
				/>
			</GridItem>
			<GridItem xs={12} sm={12} md={3}>
				<Element 
					type="date"
					value={filter.order_date}
					label="Order Date"
					onChange={((e) => {
						setFilter(updateObject(filter, {
							order_date: e
						}));
					})}
				/>
			</GridItem>
			<GridItem xs={12} sm={12} md={3}>
				<Element 
					type="select"
					value={filter.order_status}
					options={orderStatuses}
					indx="id"
					desc="desc"
					label="Order Status"
					onChange={((e) => {
						setFilter(updateObject(filter, {
							order_status: e
						}));
					})}
				/>
			</GridItem>
			<GridItem xs={12} sm={12} md={3}>
				<Element 
					type="input"
					value={filter.magento_email}
					label="Magento Email"
					onChange={((e) => {
						setFilter(updateObject(filter, {
							magento_email: e.target.value
						}));
					})}
				/>
			</GridItem>
			<GridItem xs={12} sm={12} md={3}>
				<Element 
					type="input"
					value={filter.order_total_amount}
					label="Order Total Amount"
					onChange={((e) => {
						setFilter(updateObject(filter, {
							order_total_amount: e.target.value
						}));
					})}
				/>
			</GridItem>
			<GridItem xs={12} sm={12} md={3}>
				<Element 
					type="input"
					value={filter.sap_so_number}
					label="SAP SO#"
					onChange={((e) => {
						setFilter(updateObject(filter, {
							sap_so_number: e.target.value
						}));
					})}
				/>
			</GridItem>
			<GridItem xs={12} sm={12} md={3}>
				<Element 
					type="input"
					value={filter.sap_pi_number}
					label="SAP PI#"
					onChange={((e) => {
						setFilter(updateObject(filter, {
							sap_pi_number: e.target.value
						}));
					})}
				/>
			</GridItem>
			<GridItem xs={12} sm={12} md={3}>
				<Element 
					type="select"
					value={filter.payment_method}
					label="Payment Method"
					options={[
						'',
						'PBP-Payment by CC or Paypal', 
						'PBP-Payment by Check or Wire',
						'Payment on Credit',
					]}
					onChange={((e) => {
						setFilter(updateObject(filter, {
							payment_method: e
						}));
					})}
				/>
			</GridItem>

			<GridItem xs={12} sm={12} md={12}>
				<hr />
				<div className="text-center">
					<Button
						className="mr-20"
						style={{marginRight: 20}}
						color="primary"
						onClick={() => {
							let count = 0;
							Object.keys(filter).forEach(field => {
								if (filter[field]) count++;
							})
							if (count === 0) {
								setNotification('Please enter at lease one filter / search term');
							} else {
								setPI(null);
								if (filter.sap_pi_number && !filter.database) {
									return setNotification('Please enter a database');
								}
								if (filter.sap_pi_number && filter.database) {
									getPiListFilter({
										pi: filter.sap_pi_number,
										dbname: filter.database
									});
								}
								getSmMagentoOrders(filter);
							}
						}}
					>GET ORDERS</Button>
					<Button
						color="white"
						onClick={() => {
							setFilter(newFilterObj);
						}}
					>CLEAR</Button>
				</div>

				{magentoOrders && <div>
					{magentoOrders.length > 0 && <SAPMonitorList 
						data={magentoOrders}
						filter={filter}
					/>}
				</div>
				}
			</GridItem>
		</GridContainer>

		{notification && <Notification 
			open={notification ? true : false}
			close={() => { setNotification('') }}
			message={notification}
			title="Alert!"
		/>}

		{loading && <Notification 
			open={loading ? true : false}
			message={<div style={{marginTop:40,marginBottom:40}} className="text-center"><ClipLoader /></div>}
			title="Loading... Please Wait."
			no_ok_button={true}
		/>}

		{openedModal && <SAPMonitorModal 
			open={openedModal}
			close={() => {
				setOpenedModal(false);
			}}
			choose={(card_code) => {
				setFilter(updateObject(filter, {
					company_name: card_code
				}))
			}}
			submit={getBpByName}
			bps={bps}
			db={filter.database}
			loading_bps={loading_bps}
		/>}
	</div>
}

const mapStateToProps = (state) => {
	return {
		magentoOrders: state.sap_monitor.magentoOrders,
		magentoCustomers: [],
		loading: state.sap_monitor.loading,
		loading_bps: state.sap_monitor.loading_bps,
		bps: state.sap_monitor.bps,
		success: state.sap_monitor.success,
		error: state.sap_monitor.error,
		pi_list: state.sap_monitor.pi_list
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getSmMagentoOrders: (data) => {
			dispatch(actions.getSmMagentoOrders(data));
		},
		getSmMagentoCustomers: (data) => {
			dispatch(actions.getSmMagentoCustomers(data));
		},
		getBpByName: (data) => {
			dispatch(actions.getBpByName(data));
		},
		getPiListFilter: (data) => {
            dispatch(actions.getPiListFilter(data))
        },
		setPI: (val) => {
			dispatch(actions.setPI(val))
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SAPMonitor);