import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import * as actions from '../../store/actions';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import Table from "components/Table/Table.jsx";
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import Datetime from "react-datetime";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
import InputAdornment from '@material-ui/core/InputAdornment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import cloneDeep from "lodash/cloneDeep";
import Button2 from "components/CustomButtons/Button.jsx";
import activeDBs from "shared/activeDBs";

class GINreviewDialogVariants extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      variant_description: props.data.variant_description,
      variant_code: props.data.variant_code,
      base_item_description: props.data.base_item_description,
      error: ''
    }
  }

  onInputChange = (target, e) => {
    const val = e.target.value ? e.target.value.toUpperCase() : '';
    let error = '';
    if (target === 'variant_code') {

    } else if (target === 'variant_description') {
      if (!val) {
        error += 'Please enter a value. ';
      }
      if (val.indexOf('_') !== -1) {
        error += 'No underscores allowed. ';
      }
      // check if full item description is > 100 chars
      const fullItemDesc = `${this.state.variant_code} ${this.state.base_item_description} - ${this.state.variant_description}`;
      if (fullItemDesc.length > 100) {
        error += 'Maximum Full Item Description is 100 characters. '
      }
    }
    this.setState({
      [target]: val,
      error
    });
  }

  update = () => {
    const obj = {};
    this.props.openedDBs.forEach(db => {
      if (this.props.variants[db]) {
        let found = false;
        this.props.variants[db].forEach((v,i) => {
          if (v[0] === this.state.variant_code) {
            found = true;
            if (this.props.variantsDesc[db]) {
              if (this.props.variantsDesc[db][i] === this.props.data.variant_description) {
                if (this.state.variant_description !== this.props.data.variant_description) {
                  let copy = cloneDeep(this.props.variantsDesc);
                  copy[db][i] = this.state.variant_description;
                  this.props.updateGINEdit({
                    db,
                    field: 'variantsDesc',
                    value: copy[db]
                  });

                  let variantsCheckedCopy = cloneDeep(this.props.variantsChecked);
                  const toDelete = `${this.state.variant_code}_${this.props.data.variant_description}`;
                  delete variantsCheckedCopy[toDelete];
                  const toAdd = `${this.state.variant_code}_${this.state.variant_description}`;
                  variantsCheckedCopy[toAdd] = 1;
                  this.props.setVariantsChecked(variantsCheckedCopy);
                }
              }
            }
          }
        });
        if (!found) {
          // change
          let variantsCheckedCopy = cloneDeep(this.props.variantsChecked);
          Object.keys(variantsCheckedCopy).forEach(combo => {
            const combo_arr = combo.split('_');
            if (combo_arr[0] === this.state.variant_code) {
              const new_combo = `${this.state.variant_code}_${this.state.variant_description}`;
              delete variantsCheckedCopy[combo];
              variantsCheckedCopy[new_combo] = 1;
            }
          })
          this.props.setVariantsChecked(variantsCheckedCopy);
        }
      }
    });
    this.props.close();
  }

  render() {
    console.log('st and props ---', this.state, this.props);
    const props = this.props;
    const mode = props.mode === 'new' ? 'new' : 'edit';
    const data = props.data;
    return (
    <form onSubmit={e => {
      e.preventDefault();
      this.update();
    }}>
      <Dialog
        open={this.props.open}
        keepMounted
        onClose={this.props.close}
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle>
          <div style={{textAlign:'center'}}>{data.title}</div>
          <div className="close-btn">
            <IconButton aria-label="Close" onClick={this.props.close}>
              <Close />
            </IconButton>
          </div>
        </DialogTitle>
        <div style={{padding:'0 40px 20px 40px'}}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={3}>
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <CustomInput
                labelText="Variant Code"
                id="new_variant_code"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: (e) => {

                  },
                  value: this.state.variant_code,
                  role: 'presentation',
                  autoComplete: 'nope',
                  disabled: true
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Variant Description"
                id="new_variant_description"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: (e) => {
                    this.onInputChange('variant_description', e);
                  },
                  value: this.state.variant_description,
                  role: 'presentation',
                  autoComplete: 'nope',
                  autoFocus: true
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div style={{padding:'20px',textAlign:'center'}}>
                <Button2
                  color="primary"
                  onClick={(e) => { props.close(); e.preventDefault(); }}
                  style={{marginRight:'20px'}}
                >
                  CANCEL
                </Button2>
                <Button2
                  color="success"
                  onClick={(e) => {
                      e.preventDefault();
                      if (!this.state.error) {
                        this.update();
                      }
                    }}
                >UPDATE
                </Button2>
                {this.state.error && <div style={{marginTop:20}} className="red-text">{this.state.error}</div>}
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Dialog>
    </form>
    );
  }
}

const mapStateToProps = state => {
  return {
    baseItemDescription: state.gin.base_item_description,
    variants: state.gin.variants,
    variantsDesc: state.gin.variantsDesc,
    variantsDeactivate: state.gin.variantsDeactivate,
    variantStatus: state.gin.variantStatus,
    variantsChecked: state.gin.variantsChecked,
    variantsToAdd: state.gin.variantsToAdd,
    itemCodes: state.gin.itemCodes,
    openedDBs: state.gin.openedDBs
  }
};

const mapDispatchToProps = dispatch => {
  return {
    updateGINEdit: (data) => {
      dispatch(actions.updateGINEdit(data));
    },
    setVariantStatus: (status) => {
      dispatch(actions.setVariantStatus(status));
    },
    setVariantsChecked: (checked) => {
      dispatch(actions.setVariantsChecked(checked));
    },
    addVariant: (variant) => {
      dispatch(actions.addVariant(variant));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GINreviewDialogVariants);
