import React from "react";
import PropTypes from "prop-types";
import { loginUser } from  '../../store/actions/user';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { FadeLoader } from 'react-spinners';
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import LockOpen from "@material-ui/icons/LockOpen";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import TextFieldGroup from "../Components/textFieldGroup";

import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      username: '',
      password: '',
      errors: {}
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value});
  }

  onSubmit(e) {
    e.preventDefault();
    const loginCredentials = {
      username: this.state.username,
      password: this.state.password
    }
    this.props.dispatch(loginUser(loginCredentials));
  }

  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      1
    );
    // this.setState({ cardAnimaton: "" });
    // localStorage.removeItem('token');
  }
  render() {
    const { classes, origin, loading, message, loggedInStatus } = this.props;
    if (origin && localStorage.getItem('token')) {
      if (loggedInStatus) {
        if (origin !== 'none') {
          return <Redirect to={`/${origin}`} />
        }
        return <Redirect to="/dashboard" />
      }
    }
    return (
      <div className={classes.content}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={6} md={4}>
              <form onSubmit={this.onSubmit}>
                <Card login className={classes[this.state.cardAnimaton]}>
                  <CardHeader
                    className={`${classes.cardHeader} ${classes.textCenter}`}
                    color='primary'
                  >
                    <h4 className={classes.cardTitle}>Log in</h4>
                    <h1 className={classes.cardTitle}>NATco Portal</h1>
                    <div className={classes.socialLine}>
                      {[
                      ].map((prop, key) => {
                        return (
                          <Button
                            color="transparent"
                            justIcon
                            key={key}
                            className={classes.customButtonClass}
                          >
                            <i className={prop} />
                          </Button>
                        );
                      })}
                    </div>
                  </CardHeader>
                  <CardBody>
                    <TextFieldGroup
                      placeholder="Username"
                      name="username"
                      value={this.state.username}
                      type="text"
                      onChange={this.onChange}
                    />
                    <TextFieldGroup
                      placeholder="Password"
                      name="password"
                      type="password"
                      value={this.state.password}
                      onChange={this.onChange}
                    />


                  </CardBody>
                  <CardFooter className={classes.justifyContentCenter}>
                    {loading && <FadeLoader
                        sizeUnit={"px"}
                        size={150}
                        color={'#123abc'}
                        loading={true}
                    />}

                    {!loading &&
                    <Button color="success" simple size="lg" block type="submit" onClick={this.onSubmit}>
                      LOG IN
                    </Button>
                    }

                     </CardFooter>
                     {
                       message &&
                       <div style={{marginLeft:'10px',marginRight:'10px'}}><br />
                         <SnackbarContent
                           message={message}
                           close
                           color="danger"
                         />
                       </div>
                     }
                                      <Button color="transparent" simple size="sm" block>
                      <LockOpen />
                  <a href="#pablo" onClick={e => e.preventDefault()}>
                    Forgot Password
                  </a>
                   </Button>
                </Card>
              </form>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  return {
    origin: state.auth.origin,
    loading: state.auth.loading,
    message: state.auth.message,
    loggedInStatus: state.auth.loggedInStatus
  }
}

export default compose(
  withStyles(loginPageStyle),
  connect(mapStateToProps, null)
)(LoginPage);
