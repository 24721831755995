import React, { Component } from 'react';
import ReactDOM from "react-dom";
import ReactTooltip from 'react-tooltip'
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import withStyles from "@material-ui/core/styles/withStyles";
import AttachMoney from "@material-ui/icons/AttachMoney";
import Delete from "@material-ui/icons/Delete";
import { numberWithCommas, convertDateStr } from 'shared/utility';
import dupesCalc from './dupesCalc';
import { cloneDeep } from 'lodash';

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ReactTable from "react-table";
import GridItem from "components/Grid/GridItem.jsx";
import Table from "components/Table/Table2.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GINpriceAllEdit from "./GINprice-all-edit";
import GINqtyAlert from "./GINqty-alert";
import GINaddPrice from "./GINadd-price";
import GINaddPriceSelect from "./GINadd-price-select";
import GINask from "./GINask";
import GINpriceMatrixCustomerSpecific from "./GINpriceMatrixCustomerSpecific";
import GINcopyPrice from "./GINcopyPrice";
import GMPMultipleSelect from "containers/GMP/GMPMultipleSelect";
import Button from "components/CustomButtons/Button.jsx";
import ContentCopy from '@material-ui/icons/ContentCopy';

import { ranges, rangesObj } from "./GINranges.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import activeDBs from "shared/activeDBs";

const comparator = (a, b) => {
   if (a[0] < b[0]) return -1;
   if (a[0] > b[0]) return 1;
   return 0;
 }

const style = {
  customCardContentClass: {
    paddingLeft: "0",
    paddingRight: "0"
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const DBlabel = props => {
  // props.name
  let backgroundColor = '';
  activeDBs.forEach((db) => {
    if (db.name === props.name) {
      backgroundColor = db.color;
    }
  });

  if (backgroundColor) {
    return (
      <div style={{backgroundColor}} className="db-pill">
        {props.name.split('_')[0]}
      </div>
    );
  }
  return null;
}

const QtySelect = props => {
  // props.selected, props.name
  const backgroundColor = props.selected ? 'orange' : 'rgba(0,0,0,0)',
        color = props.selected ? 'white' : 'black';
  return (
    <div style={{backgroundColor,color,fontWeight:'bold'}} className="pilled hovered" onClick={() => {
      props.choose(props.name); }}>
      {props.name}
    </div>
  );
}

const ColoredNum = props => {
  // 0 = green
  // 1-6 = orange < 50%
  // above 6 = red > 50%

  let color;
  if (props.number === 0) {
    color = 'green-text';
  } else if (props.number > 0 && props.number <= 6 ) {
    color = 'orange-text';
  } else {
    color = 'red-text';
  }
  return (
    <div className={`${color} text-center`}><strong>{props.number}</strong></div>
  );
}

const Field = props => {
  const isCurrentCurr = true;
  const color = isCurrentCurr ? 'black' : '#c8c8c8';
  return (
    <div
      style={{color}}
      onClick={() => {
        if (props.click) {
          props.click();
        }
      }}
      className="hovered relative hover-bold2"
    >{props.name}
    </div>
  )
}

class GINpriceMatrix extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showPricingForAll: true,
      quantityRange: 'Base Price',
      selectedCurrency: props.currencies ? props.currencies[0] : null,
      selectedDB: null,
      openPriceAllEdit: false,
      editData: null,
      openPriceAdd: false,
      openPriceAddSelect: false,
      openCopyPrice: false,
      editTableData: null,
      minRange: '',
      ginAsk: false,
      askRequest: '',
      rowData: null,
      pricingsData: null
    }
  }

  togglePricingType = () => {
    this.setState({
      showPricingForAll: !this.state.showPricingForAll
    });
  }

  closeGINask = () => {
    this.setState({
      ginAsk: false,
    });
  }

  openGINask = (rowData, askRequest) => {
    this.setState({
      ginAsk: true,
      rowData,
      askRequest
    });
  }


  openPriceAddSelect = (minRange, pricingsData) => {
    this.setState({
      openPriceAddSelect: true,
      minRange,
      pricingsData
    });
  }

  closePriceAddSelect = () => {
    this.setState({
      openPriceAddSelect: false,
      pricingsData: null
    });
  }

  closePriceAdd = () => {
    this.setState({
      openPriceAdd: false
    });
  }

  openPriceAdd = (DB, currency) => {
    this.setState({
      openPriceAdd: true,
      editTableData: {
        DB,
        currency
      }
    });
  }

  editPriceAllModeOff = () => {
    this.setState({
      openPriceAllEdit: false
    });
  }

  openPriceAllEdit = (editData) => {
    this.setState({
      openPriceAllEdit: true,
      editData
    });
  }

  selectQuantity = (name) => {
    this.setState({
      quantityRange: name
    });
  }

  setCurrency = (curr) => {
    this.setState({
      selectedCurrency: curr
    });
  }

  calculateMismatches = () => {
    // iterate DBs
    const pricings = { ...this.props.pricings };
    console.log('pricings to calculate mismatches from ', pricings);
    let mismatches = [],
        range_mismatches = [],
        shipterm_collection = {},
        pricing_collection = {},
        effective_dates_collection = {},
        currency_collection = {},
        total_price_collection = {};

    Object.keys(pricings).forEach((db) => {
      Object.keys(pricings[db]).forEach((rnge) => {
        if (range_mismatches.indexOf(rnge) === -1) {
          range_mismatches.push(rnge);
        }
        pricings[db][rnge].forEach((pdr,i) => {
          // SHIP TERMS
          if (shipterm_collection.hasOwnProperty(rnge)) {
            if (shipterm_collection[rnge].hasOwnProperty(pdr.term)) {
              shipterm_collection[rnge][pdr.term]++;
            } else {
              shipterm_collection[rnge][pdr.term] = 1;
            }
          } else {
            shipterm_collection[rnge] = { [pdr.term]: 1 };
          }

          // PRICING
          if (pricing_collection.hasOwnProperty(rnge)) {
            if (pricing_collection[rnge].hasOwnProperty(pdr.price)) {
              pricing_collection[rnge][pdr.price]++;
            } else {
              pricing_collection[rnge][pdr.price] = 1;
            }
          } else {
            pricing_collection[rnge] = { [pdr.price]: 1 };
          }

          // EFFECTIVE DATES
          const startEnd = pdr.start + ' to ' + pdr.end;
          if (effective_dates_collection.hasOwnProperty(rnge)) {
            if (effective_dates_collection[rnge].hasOwnProperty(startEnd)) {
              effective_dates_collection[rnge][startEnd]++;
            } else {
              effective_dates_collection[rnge][startEnd] = 1;
            }
          } else {
            effective_dates_collection[rnge] = { [startEnd]: 1 };
          }

          // CURRENCY
          if (currency_collection.hasOwnProperty(rnge)) {
            if (currency_collection[rnge].hasOwnProperty(pdr.currency)) {
              currency_collection[rnge][pdr.currency]++;
            } else {
              currency_collection[rnge][pdr.currency] = 1;
            }
          } else {
            currency_collection[rnge] = { [pdr.currency]: 1 };
          }

          // TOTAL PRICE
          if (total_price_collection.hasOwnProperty(rnge)) {
            if (total_price_collection[rnge].hasOwnProperty(pdr.total)) {
              total_price_collection[rnge][pdr.total]++;
            } else {
              total_price_collection[rnge][pdr.total] = 1;
            }
          } else {
            total_price_collection[rnge] = { [pdr.total]: 1 };
          }
        });
      });
    });

    // console.log('shipterm collection', shipterm_collection);
    // console.log('pricing collection', pricing_collection);
    // console.log('effective dates collection', effective_dates_collection);
    // console.log('currency collection', currency_collection);
    // console.log('total price collection', total_price_collection);
    // console.log('range mismatches', range_mismatches);
    let range_collection = [];
    range_mismatches.forEach((rm, i) => {
      range_collection.push(rm);
      mismatches.push([
        rm,
        Object.keys(shipterm_collection[rm]).length - 1,
        Object.keys(pricing_collection[rm]).length - 1,
        Object.keys(effective_dates_collection[rm]).length - 1,
        Object.keys(currency_collection[rm]).length - 1,
        Object.keys(total_price_collection[rm]).length - 1
      ]);
    })
    console.log('mismatches', mismatches);
    let missing_ranges = [];
    ranges.forEach((rnge) => {
      if (range_collection.indexOf(rnge.name) === -1) {
        missing_ranges.push([
          rnge.name,
          null,
          null,
          null,
          null,
          null
        ]);
      }
    });

    let finalArr = [];
    ranges.forEach((rnge, i) => {
      const name = rnge.name;
      mismatches.forEach((mism) => {
        if (mism[0] === name) {
          finalArr.push(mism);
        }
      });
      missing_ranges.forEach((misr) => {
        if (misr[0] === name) {
          finalArr.push(misr);
        }
      });
    });
    return finalArr;
  }

  getPrices = () => {
    const pricings = { ...this.props.pricings };
    console.log('these are the pricings -->', pricings);
    let pricingsArr = [];
    const qty = this.state.quantityRange;
    Object.keys(pricings).forEach((db) => {
      if (pricings[db].hasOwnProperty(qty)) {
        pricings[db][qty].forEach((prss) => {
          pricingsArr.push({
            db,
            qty: prss.qty,
            currency: prss.currency,
            start: prss.start,
            end: prss.end,
            price: prss.price,
            currency: prss.currency,
            term: prss.term,
            total: prss.total,
            itemName: prss.itemCode,
            indx: prss.indx,
            moc: prss.moc ? prss.moc : 0,
            mocType: prss.mocType
          });
        });
      }
    });
    return pricingsArr;
  }

  renderCurrencySelection = (prices, currd) => {
    let currencies = [];

    prices.forEach((pr) => {
      if (pr.currency) {
        if (currencies.indexOf(pr.currency) === -1) {
          currencies.push(pr.currency);
        }
      }
    });
    console.log(currencies);
    return currencies.map((curr) => {
      if (curr) {
        return <span
          className={currd === curr ? 'pilled orange-pill hovered' : 'pilled hovered'}
          key={`${Math.floor(Math.random() * 100) + 1}${curr}`}
          onClick={() => {
            this.setCurrency(curr);
          }}
          >{curr}</span>;
      }
      return null;
    });
  }

  getDescription = (value, options, valueField, descField) => {
    let descFound = null;
    if (options) {
      options.forEach((o) => {
        if (value === o[valueField]) {
          return descFound = o[descField]
        }
      });
    }
    return descFound;
  }

  chooseDB = (db) => {
    console.log(db);
    this.openPriceAdd(db, this.state.selectedCurrency);
    this.setState({
      openPriceAddSelect: false
    });
  }

  getRangeVals = (qty) => {
    let finalRange;
    ranges.forEach((r) => {
      if (qty < 10000000) {
        if (r.rangeA <= qty && r.rangeB >= qty) {
          finalRange = [r.rangeA, r.rangeB];
        }
      } else {
        finalRange = [10000000, null];
      }
    });
    return finalRange;
  }

  getMinQtyRange = (qtyRange) => {
    let min = '';
    ranges.forEach((rnge) => {
      if (rnge.name === qtyRange) {
        min = rnge.rangeA;
      }
    });
    return min;
  }

  deleteRow = (pr) => {
    console.log('deleting row', pr);
    // open ask modal
    this.openGINask(pr, 'deleteRow');
  }

  copyRow = (pr) => {
    console.log('copying cell', pr);

  }

  openCopyPrice = (pr) => {
    this.setState({
      openCopyPrice: true,
      editTableData: pr
    });
  }

  closeCopyPrice = () => {
    this.setState({
      openCopyPrice: false,
      editTableData: null
    });
  }

  handleUpdate = (toBeDeleted) => {
    const db = toBeDeleted.db,
          type = toBeDeleted.indx === -1 ? 'single' : 'multiple';
    if (type === 'multiple') {
      ['gcurrs','gstarts','gends','gprices','gqtys','gterms','gcodes'].forEach((field) => {
        const valuesCopy = [ ...this.props[field][db] ];
        this.props.updateGINEdit({
          field,
          value: valuesCopy.filter((f,i) => {
            if (i == toBeDeleted.indx) {
              console.log('deleting', toBeDeleted.indx);
              return false;
            }
            return true;
          }),
          db
        });
      });
    }

    // delete these, but look for gcurrs, gstarts, gends, gprices, gterms, gcodes and transfer theme here
    if (type === 'single') {
      let copyObj = {};
      const singleFields = ['gcur','gstart','gend','gprice','gterm','gcode'],
            multipleFields = ['gcurrs','gstarts','gends','gprices', 'gterms', 'gcodes'];
      singleFields.forEach((field) => {
        copyObj[field] = cloneDeep(this.props[field]);
        copyObj[field][db] = null;
        console.log('deleting', field, db);
      });

      // find gcurrs, gstarts
      copyObj.gcurrs = cloneDeep(this.props.gcurrs);
      copyObj.gstarts = cloneDeep(this.props.gstarts);
      copyObj.gends = cloneDeep(this.props.gends);
      copyObj.gprices = cloneDeep(this.props.gprices);
      copyObj.gterms = cloneDeep(this.props.gterms);
      copyObj.gqtys = cloneDeep(this.props.gqtys);
      copyObj.gcodes = cloneDeep(this.props.gcodes);

      if (this.props.gqtys[db]) {
        if (this.props.gqtys[db].length > 0) {
          // select whichever has quantity = 1-999
          let foundQty = -1, foundAlready = false;
          this.props.gqtys[db].forEach((qty,index) => {
            if (!foundAlready) {
              if (parseInt(qty)) {
                if (parseInt(qty) >= 1 && parseInt(qty) < 1000) {
                  foundQty = index;
                  foundAlready = true;
                }
              }
            }
          });

          if (foundQty !== -1) {
            // copy 1st row
            singleFields.forEach((field,i) => {
              let copy = { ...this.props[multipleFields[i]] };
              console.log(field, multipleFields, copy);
              copyObj[field][db] = copy[db][foundQty];
              copyObj[multipleFields[i]][db] = copy[db].filter((cc,j) => {
                if (j === foundQty) {
                  return false;
                }
                return true;
              });
            });

            let copy = { ...this.props.gqtys };
            copyObj.gqtys[db] = copyObj.gqtys[db].filter((gq,jj) => {
              if (jj === foundQty) {
                return false;
              }
              return true;
            });
          }
        }
      }

      this.props.updateGINMultiple(copyObj);
    }
  }

  changeCurrs = (curr) => {
    this.setState({
      selectedCurrency: curr
    })
  }

  checkPricingRequirements = () => {
    let missingDBs = [];
    this.props.openedDBs.forEach(db => {
      if (!this.props.gprice[db]) { // no general pricing
        missingDBs.push(db);
      }
    });
    return missingDBs;
  }

  render() {
    const { classes } = this.props;
    const mismatches = this.calculateMismatches();
    const prices = this.getPrices();
    console.log('this is the props pricing', this.props.pricings);
    const qtyRangeDupes = dupesCalc(this.props.pricings);
    const requiredDBs = this.checkPricingRequirements();
    console.log('prices...', prices, qtyRangeDupes);

    const curr = this.state.selectedCurrency ? this.state.selectedCurrency : prices[0] ? prices[0].currency : null;

    let dbsArr = [],
        currencyArr = [],
        effectiveDatesArr = [],
        termsArr = [];
    prices.forEach(pr => {
      if (dbsArr.indexOf(pr.db) === -1) {
        dbsArr.push(pr.db);
      }
      if (termsArr.indexOf(pr.term) === -1) {
        termsArr.push(pr.term);
      }
      if (currencyArr.indexOf(pr.currency) === -1) {
        currencyArr.push(pr.currency);
      }
      if (effectiveDatesArr.indexOf(`${pr.start} TO ${pr.end}`) === -1) {
        effectiveDatesArr.push(`${pr.start} TO ${pr.end}`);
      }
    });
    const table = <div className="spec-table scrolling-table no-blue-row"><ReactTable
        data={
            prices.map((pr, key) => { // map users to table rows
              // const isCurrentCurr = curr === pr.currency ? true : false;
              const isCurrentCurr = curr === pr.currency ? true : false;
              const qty = parseInt(pr.qty);
              const getRange = this.getRangeVals(qty);
              const minTotalPrice = (getRange[0] * pr.price) < pr.moc ? pr.moc : (getRange[0] * pr.price);
              const maxTotalPrice = getRange[1] ? (getRange[1] * pr.price) < pr.moc ? pr.moc : (getRange[1] * pr.price) : '';
              // const totalPriceRange = getRange[1] ? `${numberWithCommas(minTotalPrice)}-${numberWithCommas(maxTotalPrice)}` : `${numberWithCommas(minTotalPrice)}`;
              const totalPriceRange = `${numberWithCommas(minTotalPrice)}`;
              return ({
                id: key,
                db_name: pr.db,
                dbCell: (
                    <DBlabel name={pr.db} />
                ),
                term_name: pr.term,
                termCell: (
                  <Field
                    name={pr.term}
                    title="Change terms"
                    isCurrentCurr={isCurrentCurr}
                    click={() => {
                      this.openPriceAllEdit({
                        ...pr,
                        type: 'terms',
                        DB: pr.db,
                        value: pr.term,
                        itemName: pr.itemName,
                        allPrices: this.props.pricings
                      });
                    }} />
                ),
                price_name: `${pr.currency} ${numberWithCommas(parseFloat(pr.price))}`,
                priceCell: (
                  <Field
                    title="Change price"
                    name={`${pr.currency} ${numberWithCommas(parseFloat(pr.price))}`}
                    isCurrentCurr={isCurrentCurr}
                    click={() => {
                      this.openPriceAllEdit({
                        ...pr,
                        type: 'price',
                        DB: pr.db,
                        value: pr.price,
                        itemName: pr.itemName
                      });
                    }}
                  />
                ),
                effective_dates_name: `${pr.start} TO ${pr.end}`,
                effectiveDatesCell: (<Field
                      title="Change dates"
                      name={`${pr.start} TO ${pr.end}`}
                      isCurrentCurr={isCurrentCurr}
                      click={() => {
                        this.openPriceAllEdit({
                          ...pr,
                          type: 'effective-dates',
                          DB: pr.db,
                          value: `${pr.start},${pr.end}`,
                          itemName: pr.itemName
                        });
                      }}
                    />),
                currency: pr.currency,
                currencyCell: (<Field
                      title="Change currency"
                      name={pr.currency}
                      isCurrentCurr={isCurrentCurr}
                      click={() => {
                        this.openPriceAllEdit({
                          ...pr,
                          type: 'currency',
                          DB: pr.db,
                          value: pr.currency,
                          itemName: pr.itemName
                        });
                      }}
                    />),
                total_price: `${pr.currency} ${totalPriceRange}`,
                moc: pr.moc,
                mocType: pr.mocType,
                moc_name: `${pr.currency} ${numberWithCommas(parseFloat(pr.moc))} ${pr.mocType}`,
                mocCell: (
                  <Field
                    title="Change MOC"
                     name={`${pr.currency} ${numberWithCommas(parseFloat(pr.moc))} ${pr.mocType}`}
                     isCurrentCurr={isCurrentCurr}
                     click={() => {
                       this.openPriceAllEdit({
                         ...pr,
                         type: 'moc',
                         DB: pr.db,
                         value: pr.moc,
                         mocType: pr.mocType,
                         itemName: pr.itemName
                       });
                     }}
                   />
                ),
                deleteCell: (
                  <div
                    className="red-text hovered"
                    style={{paddingTop:'10px',paddingBottom:'10px'}}
                    onClick={() => {
                      this.deleteRow(pr);
                    }}>
                    <Delete />
                  </div>
                ),
                itemName: pr.itemName,
                pr: pr,
                copyCell: (
                <div>
                  {pr.currency === '$' &&
                        <div
                          data-tip="copy price"
                          className="green-text hovered"
                          style={{paddingTop:'10px',paddingBottom:'10px'}}
                          onClick={() => {
                            this.openCopyPrice(pr);
                          }}>
                          <ContentCopy />
                        </div>
                }
                </div>
                )
              })
            })
        }
        filterable
        columns={[
            {
                Header: "DB",
                accessor: "db_name",
                filterMethod: (filter, row) => {
                    const chosen = filter.value;
                    if (chosen.length > 0) {
                        return chosen.indexOf(row[filter.id]) !== -1;
                    } else {
                        return true;
                    }
                },
                Filter: ({filter, onChange}) => {
                    return <div className="gmp-filter"><GMPMultipleSelect
                        options={dbsArr}
                        label={''}
                        choose={onChange}
                    /></div>
                },
                Cell: row => {
                  return (
                    <div>{row.original.dbCell}</div>
                  )
                },
                maxWidth: 60
            },
            {
                Header: "SHIPPING TERMS",
                accessor: "term_name",
                filterMethod: (filter, row) => {
                    const chosen = filter.value;
                    if (chosen.length > 0) {
                        return chosen.indexOf(row[filter.id]) !== -1;
                    } else {
                        return true;
                    }
                },
                Filter: ({filter, onChange}) => {
                    return <div className="gmp-filter"><GMPMultipleSelect
                        options={termsArr}
                        label={''}
                        choose={onChange}
                    /></div>
                },
                Cell: row => {
                  return (
                    <div>{row.original.termCell}</div>
                  )
                }
            },
            {
                Header: "PRICE",
                accessor: "price_name",
                Cell: row => {
                  return (
                    <div>{row.original.priceCell}</div>
                  )
                },
                sortable: true,
                filterable: false,
            },
            {
                Header: "EFFECTIVE DATES",
                accessor: "effective_dates_name",
                filterMethod: (filter, row) => {
                    const chosen = filter.value;
                    if (chosen.length > 0) {
                        return chosen.indexOf(row[filter.id]) !== -1;
                    } else {
                        return true;
                    }
                },
                Filter: ({filter, onChange}) => {
                    return <div className="gmp-filter"><GMPMultipleSelect
                        options={effectiveDatesArr}
                        label={''}
                        choose={onChange}
                    /></div>
                },
                Cell: row => {
                  return (
                    <div>{row.original.effectiveDatesCell}</div>
                  )
                },
                style:{ whiteSpace: 'unset', fontSize: '12px'}
            },
            {
                Header: "CURRENCY",
                accessor: "currency",
                filterMethod: (filter, row) => {
                    const chosen = filter.value;
                    if (chosen.length > 0) {
                        return chosen.indexOf(row[filter.id]) !== -1;
                    } else {
                        return true;
                    }
                },
                Filter: ({filter, onChange}) => {
                    return <div className="gmp-filter"><GMPMultipleSelect
                        options={currencyArr}
                        label={''}
                        choose={onChange}
                    /></div>
                },
                Cell: row => {
                  return (
                    <div className="text-center">{row.original.currencyCell}</div>
                  )
                },
                maxWidth: 100
            },
            {
                Header: "TOTAL PRICE",
                accessor: "total_price",
                sortable: false,
                filterable: false,
            },
            {
                Header: "MOC",
                accessor: "moc",
                Cell: row => {
                  return (
                    <div>{row.original.mocCell}</div>
                  )
                },
                sortable: false,
                filterable: false,
            },
            {
                Header: "",
                accessor: "deleteCell",
                sortable: false,
                filterable: false,
                maxWidth: 80
            },
            {
              Header: "",
              accessor: "copyCell",
              sortable: false,
              filterable: false,
              maxWidth: 120
            },
        ]}
        minRows={0}
        defaultPageSize={1000}
        showPagination={false}
        className="-highlight"
    /></div>

    const mismatchData = mismatches.map((mism) => {
      return [
        <QtySelect
          name={mism[0]}
          choose={this.selectQuantity}
          selected={this.state.quantityRange === mism[0]}/>,
        // <ColoredNum number={mism[1]} />,
        // <ColoredNum number={mism[2]} />,
        // <ColoredNum number={mism[3]} />,
        // <ColoredNum number={mism[4]} />,
        // <ColoredNum number={mism[5]} />
      ]
    });

    return (
  <div>
    <Card style={{marginTop:10,marginBottom:10}}>
      <CardBody>
        <GridContainer>
          <GridItem xs={12} sm={12} md={1}>
            <div style={{margin:'15px 0 20px 10px'}}>
              <CardIcon color="rose">
                <AttachMoney style={{fill:'white'}} />
              </CardIcon>
            </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={11}>
            <div style={{marginBottom:'20px',fontSize:'18px'}}>
              <Button
                color={this.state.showPricingForAll ? 'rose' : 'white'}
                style={{marginRight:'20px'}}
                onClick={this.togglePricingType}
              >PRICING FOR ALL CUSTOMERS</Button>
              <Button
                color={this.state.showPricingForAll ? 'white' : 'rose'}
                style={{marginRight:'20px'}}
                onClick={this.togglePricingType}
              >CUSTOMER SPECIFIC PRICING</Button>
              {/*requiredDBs.length > 0 && <div className="red-text" style={{fontSize:10,marginTop:10}}>{requiredDBs.map((rdb,i) => {
                return <span key={`rdb${i}`} className="separate-span">{rdb} </span>
              })} missing minimum requirements</div>*/}
            </div>
          </GridItem>
        </GridContainer>
          {
           this.state.openCopyPrice && <GINcopyPrice open={this.state.openCopyPrice} close={this.closeCopyPrice} editTableData={this.state.editTableData} />
          }
          {this.state.ginAsk &&
            <GINask
              askRequest="deleteRow"
              close={this.closeGINask}
              command={() => {
                this.handleUpdate(this.state.rowData);
              }}
              content="Delete this Pricing row?"
            />
          }
          {
            this.state.openPriceAllEdit &&
              <GINpriceAllEdit
                data={this.state.editData}
                open={this.state.openPriceAllEdit}
                allCurrencies={this.props.currencies}
                editPriceAllModeOff={this.editPriceAllModeOff}
                allPrices={this.props.pricings}
              />
          }
          {
            qtyRangeDupes.length > 0 &&
              <GINqtyAlert
                open={qtyRangeDupes.length > 0}
                close={this.closeQtyAlert}
                duplicates={qtyRangeDupes}
              />
          }
          {
            this.state.openPriceAdd &&
              <GINaddPrice
                open={this.state.openPriceAdd}
                close={this.closePriceAdd}
                editTableData={this.state.editTableData}
                minRange={this.state.minRange}
                changeCurrs={this.changeCurrs}
                allPrices={this.props.pricings}
                pricingsData={this.state.pricingsData}
              />
          }
          {
            this.state.openPriceAddSelect &&
              <GINaddPriceSelect
                open={this.state.openPriceAddSelect}
                close={this.closePriceAddSelect}
                choose={this.chooseDB}
                dbOptions={this.props.openedDBs}
                minRange={this.state.minRange}
              />
          }

        {!this.state.showPricingForAll && <div><GINpriceMatrixCustomerSpecific /></div>}

        {this.state.showPricingForAll && <GridContainer>
          <GridItem xs={12} sm={12} md={1} lg={1}>
            <div className="outlined-blue" style={{float:'right',maxWidth:'200px',padding:'10px 0'}}>
              <span
                style={{
                  fontSize:'12px',
                  fontWeight:'bold',
                  position:'absolute',
                  marginTop: 0,
                  left:'11px',
                  display: 'none'
                }}
                className={classes.cardIconTitle}
              >QUANTITY</span>
              <span style={{
                fontSize:'18px',
                textAlign:'center',
                display:'none',
                margin:'0 auto'
              }} className={classes.cardIconTitle}>MISMATCHES</span>
              <div className="tiny-table">
                <Table
                  tableHeaderColor="primary"
                  tableHead={["QUANTITY"]}
                  tableData={mismatchData}
                  coloredColls={[3]}
                  colorsColls={["primary"]}
                />
              </div>
            </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={11} lg={11}>
              <div style={{marginTop:'20px'}}>
                <div className="font-12" style={{marginTop:'30px'}}>
                  <strong>DISPLAYING FOR QUANTITY: <span className="orange-text">{this.state.quantityRange}</span></strong>
                </div>
                <div className="relative">
                  <div className="price-add-btn">
                    <Button
                      color="rose"
                      size="sm"
                      onClick={() => {
                        console.log('prices',prices);
                        this.openPriceAddSelect(this.getMinQtyRange(this.state.quantityRange), this.props.pricings);
                      }}
                    >Add</Button>
                  </div>
                  {table}
                </div>

                {this.state.showCurrency && <div style={{textAlign:'center',margin:'40px'}}>
                  Currency
                  {/*this.renderCurrencySelection(prices, curr)*/}
                </div>}
              </div>
          </GridItem>
        </GridContainer>}
      </CardBody>
      <ReactTooltip />
    </Card>
  </div>
    )
  }
}

const mapStateToProps = (state) => {
  const gcur = state.gin.gcur,
        gstart = state.gin.gstart,
        gend = state.gin.gend,
        gprice = state.gin.gprice,
        gterm = state.gin.gterm,
        gcurrs = state.gin.gcurrs,
        gstarts = state.gin.gstarts,
        gends = state.gin.gends,
        gprices = state.gin.gprices,
        gqtys = state.gin.gqtys,
        gterms = state.gin.gterms,
        mocs = state.gin.mocs,
        itemCodes = state.gin.itemCodes,
        specialmoc = state.gin.specialmoc,
        openedDBs = state.gin.openedDBs;

  let groupedDBMultiQuantities = {}; // keys are DBs
  Object.keys(gqtys).forEach((db) => {
    if (openedDBs.indexOf(db) !== -1) {
      const groupObj = {};
      if (gqtys[db]) {
        gqtys[db].forEach((qty, index) => {
          console.log('quantity is ',qty)
          let name = '', rangeName = '';
          ranges.forEach((re) => {
            if (qty < 10000000) {
              if ((re.rangeA <= qty) && (qty <= re.rangeB)) {
                name = re.name;
                rangeName = `${numberWithCommas(re.rangeA)} - ${numberWithCommas(re.rangeB)}`;
              }
            } else {
              name = '10M+';
              rangeName = '10M+'
            }
          });
          let total = gprices[db][index] ? (qty * parseFloat(gprices[db][index])) : 0;
          if (mocs[db]) {
            if (mocs[db][gcurrs[db][index]]) {
              if (total < mocs[db][gcurrs[db][index]]) {
                total = mocs[db][gcurrs[db][index]] ? parseFloat(mocs[db][gcurrs[db][index]]) : 0;
              }
            }
          }
          let specialmocObj = null;
          if (specialmoc[db]) {
            specialmocObj = {};
            specialmoc[db].forEach((mdb) => {
              if (mdb) {
                specialmocObj[mdb[0]] = !isNaN(mdb[1]) ? parseFloat(mdb[1]) : mdb[1];
              }
            });
            if (Object.keys(specialmocObj).length > 0) {
              if (total < specialmocObj[gcurrs[db][index]]) {
                total = specialmocObj[gcurrs[db][index]];
              }
            }
          }
          const finObj = {
            indx: index,
            qty: gqtys[db][index],
            currency: gcurrs[db][index],
            price: gprices[db][index],
            start: gstarts[db][index],
            end: gends[db][index],
            term: gterms[db][index],
            itemCode: itemCodes[db],
            total,
            moc: specialmocObj[gcurrs[db][index]] || specialmocObj[gcurrs[db][index]] === 0 ? specialmocObj[gcurrs[db][index]] : mocs[db][gcurrs[db][index]],
            mocType: specialmocObj[gcurrs[db][index]] || specialmocObj[gcurrs[db][index]] === 0 ? 'Special' : 'Standard',
            rangeName
          };
          console.log('finObj',finObj, mocs[db], gcurrs[db][index]);
          if (groupObj.hasOwnProperty(name)) {
            groupObj[name].push(finObj);
          } else {
            groupObj[name] = [finObj];
          }
        });
      }
      groupedDBMultiQuantities[db] = groupObj;
    }
  });

  const dbsArr = Object.keys(gcur);
  const pricings = dbsArr.forEach((db) => {
    if (openedDBs.indexOf(db) !== -1) {
      if (gcur[db]) {
        let total = gprice[db] ? parseFloat(gprice[db]) : 0;
        if (mocs[db]) {
          if (mocs[db][gcur[db]]) {
            if (total < mocs[db][gcur[db]]) {
              total = mocs[db][gcur[db]] ? parseFloat(mocs[db][gcur[db]]) : 0;
            }
          }
        }

        let specialmocObj = {};
        if (specialmoc[db]) {
          specialmocObj = {};
          specialmoc[db].forEach((mdb) => {
            if (mdb) {
              specialmocObj[mdb[0]] = !isNaN(mdb[1]) ? parseFloat(mdb[1]) : mdb[1];
            }
          });

          if (Object.keys(specialmocObj).length > 0) {
            if (total < mocs[db][gcur[db]]) {
              total = mocs[db][gcur[db]];
            }
          }
        }

        let standard_moc = 0;
        if (mocs[db]) {
          if (mocs[db][gcur[db]]) {
            standard_moc = mocs[db][gcur[db]];
          }
        }
        const finObj2 = {
          indx: -1,
          qty: 1,
          currency: gcur[db],
          price: gprice[db],
          start: gstart[db],
          end: gend[db],
          term: gterm[db],
          itemCode: itemCodes[db],
          moc: specialmocObj[gcur[db]] || specialmocObj[gcur[db]] === 0 ? specialmocObj[gcur[db]] : standard_moc,
          mocType: specialmocObj[gcur[db]] || specialmocObj[gcur[db]] === 0 ? 'Special' : 'Standard',
          total,
        }
        console.log('finObj2',finObj2);
        if (groupedDBMultiQuantities[db].hasOwnProperty('Base Price')) {
          groupedDBMultiQuantities[db]['Base Price'].push(finObj2);
        } else {
          groupedDBMultiQuantities[db]['Base Price'] = [finObj2];
        }
      }
    }
  });

  let currencies = [];
  Object.keys(gcur).forEach((curr) => {
    if (currencies.indexOf(gcur[curr]) === -1) {
      currencies.push(gcur[curr]);
    }
  });

  console.log('groupedDBMultiQuantities', groupedDBMultiQuantities);
  return {
    openedDBs: state.gin.openedDBs,
    pricings: groupedDBMultiQuantities,
    currencies,

    gcur: state.gin.gcur,
    gstart: state.gin.gstart,
    gend: state.gin.gend,
    gprice: state.gin.gprice,
    gterm: state.gin.gterm,
    gcode: state.gin.gcode,

    gcurrs: state.gin.gcurrs,
    gstarts: state.gin.gstarts,
    gends: state.gin.gends,
    gprices: state.gin.gprices,
    gqtys: state.gin.gqtys,
    gterms: state.gin.gterms,
    gcodes: state.gin.gcodes,
    mocs: state.gin.mocs,
    itemCodes: state.gin.itemCodes,
    options: state.gin.options,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    editModeOn: () => {
      dispatch(actions.editModeOn());
    },
    setEdit: (data) => {
      dispatch(actions.setGINEdit(data));
    },
    updateGINEdit: (data) => {
      dispatch(actions.updateGINEdit(data));
    },
    updateGINMultiple: (data) => {
      dispatch(actions.updateGINMultiple(data));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(GINpriceMatrix));
