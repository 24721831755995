import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    magentoOrders: [],
    magentoCustomers: [],
    magentoOrderOther: null,
    loading: false,
    loading_bps: false,
    error: null,
    success: null,
    bps: [],
    sap_order_details: null,
    loading_order_details: false,

    loading_getSapBpDetails: false,
    sapBpDetails: null,

    loading_getSapBpaDetails: false,
    sapBpaDetails: null,

    pi_list: [],
    magento_bp_data: null
  }

// GET_SM_MAGENTO_ORDERS
const getSmMagentoOrdersStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null,
        loading: true
    })
}
const getSmMagentoOrdersSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Success',
        magentoOrders: action.response,
        loading: false
    })
}
const getSmMagentoOrdersFail = (state, action) => {
    return updateObject(state, {
        error: action.err,
        loading: false
    })
}

// GET_SM_MAGENTO_ORDERS_OTHERS
const getSmMagentoOrdersOthersStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    })
}
const getSmMagentoOrdersOthersSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Success',
        magentoOrderOther: action.response
    })
}
const getSmMagentoOrdersOthersFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// GET_SM_MAGENTO_CUSTOMERS
const getSmMagentoCustomersStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    })
}
const getSmMagentoCustomersSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Success',
        magentoCustomers: []
    })
}
const getSmMagentoCustomersFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// GET_BP_BY_NAME
const getBpByNameStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null,
        loading_bps: true
    })
}
const getBpByNameSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Success',
        loading_bps: false,
        bps: action.response
    })
}
const getBpByNameFail = (state, action) => {
    return updateObject(state, {
        error: action.err,
        loading_bps: false
    })
}

// GET_SAP_ORDER_DETAILS
const getSapOrderDetailsStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null,
        loading_order_details: true
    })
}
const getSapOrderDetailsSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Success',
        sap_order_details: action.response,
        loading_order_details: false
    })
}
const getSapOrderDetailsFail = (state, action) => {
    return updateObject(state, {
        error: action.err,
        loading_order_details: false
    })
}

// GET_SAP_BP_DETAILS
const getSapBpDetailsStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null,
        loading_getSapBpDetails: true
    })
}
const getSapBpDetailsSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Success',
        loading_getSapBpDetails: false,
        sapBpDetails: action.response
    })
}
const getSapBpDetailsFail = (state, action) => {
    return updateObject(state, {
        error: action.err,
        loading_getSapBpDetails: false
    })
}

// GET_SAP_BPA_DETAILS
const getSapBpaDetailsStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null,
        loading_getSapBpaDetails: true,
    })
}
const getSapBpaDetailsSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Success',
        loading_getSapBpaDetails: false,
        sapBpaDetails: action.response
    })
}
const getSapBpaDetailsFail = (state, action) => {
    return updateObject(state, {
        error: action.err,
        loading_getSapBpaDetails: false,
    })
}

// GET_PI_LIST_FILTER
const getPiListFilterStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    })
}
const getPiListFilterSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Success',
        pi_list: action.response
    })
}
const getPiListFilterFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

const setPI = (state, action) => {
    return updateObject(state, {
        pi_list: []
    })
}

// GET_SAP_BPA_ITEM_DETAILS
const getSapBpaItemDetailsStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    })
}
const getSapBpaItemDetailsSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Success',
        bpa_item_details: action.response
    })
}
const getSapBpaItemDetailsFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// GET_MAGENTO_BP_DETAILS
const getMagentoBpDetailsStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null,
        magento_bp_data: null
    })
}
const getMagentoBpDetailsSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Success',
        magento_bp_data: action.response
    })
}
const getMagentoBpDetailsFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_SM_MAGENTO_ORDERS_START:
            return getSmMagentoOrdersStart(state, action);
        case actionTypes.GET_SM_MAGENTO_ORDERS_SUCCESS:
            return getSmMagentoOrdersSuccess(state, action);
        case actionTypes.GET_SM_MAGENTO_ORDERS_FAIL:
            return getSmMagentoOrdersFail(state, action);

        case actionTypes.GET_SM_MAGENTO_ORDERS_OTHERS_START:
            return getSmMagentoOrdersOthersStart(state, action);
        case actionTypes.GET_SM_MAGENTO_ORDERS_OTHERS_SUCCESS:
            return getSmMagentoOrdersOthersSuccess(state, action);
        case actionTypes.GET_SM_MAGENTO_ORDERS_OTHERS_FAIL:
            return getSmMagentoOrdersOthersFail(state, action);

        case actionTypes.GET_SM_MAGENTO_CUSTOMERS_START:
            return getSmMagentoCustomersStart(state, action);
        case actionTypes.GET_SM_MAGENTO_CUSTOMERS_SUCCESS:
            return getSmMagentoCustomersSuccess(state, action);
        case actionTypes.GET_SM_MAGENTO_CUSTOMERS_FAIL:
            return getSmMagentoCustomersFail(state, action);

        case actionTypes.GET_BP_BY_NAME_START:
            return getBpByNameStart(state, action);
        case actionTypes.GET_BP_BY_NAME_SUCCESS:
            return getBpByNameSuccess(state, action);
        case actionTypes.GET_BP_BY_NAME_FAIL:
            return getBpByNameFail(state, action);

        case actionTypes.GET_SAP_ORDER_DETAILS_START:
            return getSapOrderDetailsStart(state, action);
        case actionTypes.GET_SAP_ORDER_DETAILS_SUCCESS:
            return getSapOrderDetailsSuccess(state, action);
        case actionTypes.GET_SAP_ORDER_DETAILS_FAIL:
            return getSapOrderDetailsFail(state, action);

        case actionTypes.GET_SAP_BP_DETAILS_START:
            return getSapBpDetailsStart(state, action);
        case actionTypes.GET_SAP_BP_DETAILS_SUCCESS:
            return getSapBpDetailsSuccess(state, action);
        case actionTypes.GET_SAP_BP_DETAILS_FAIL:
            return getSapBpDetailsFail(state, action);

        case actionTypes.GET_SAP_BPA_DETAILS_START:
            return getSapBpaDetailsStart(state, action);
        case actionTypes.GET_SAP_BPA_DETAILS_SUCCESS:
            return getSapBpaDetailsSuccess(state, action);
        case actionTypes.GET_SAP_BPA_DETAILS_FAIL:
            return getSapBpaDetailsFail(state, action);

        case actionTypes.GET_PI_LIST_FILTER_START:
            return getPiListFilterStart(state, action);
        case actionTypes.GET_PI_LIST_FILTER_SUCCESS:
            return getPiListFilterSuccess(state, action);
        case actionTypes.GET_PI_LIST_FILTER_FAIL:
            return getPiListFilterFail(state, action);

        case actionTypes.SET_PI:
            return setPI(state, action);

        case actionTypes.GET_SAP_BPA_ITEM_DETAILS_START:
            return getSapBpaItemDetailsStart(state, action);
        case actionTypes.GET_SAP_BPA_ITEM_DETAILS_SUCCESS:
            return getSapBpaItemDetailsSuccess(state, action);
        case actionTypes.GET_SAP_BPA_ITEM_DETAILS_FAIL:
            return getSapBpaItemDetailsFail(state, action);

        case actionTypes.GET_MAGENTO_BP_DETAILS_START:
            return getMagentoBpDetailsStart(state, action);
        case actionTypes.GET_MAGENTO_BP_DETAILS_SUCCESS:
            return getMagentoBpDetailsSuccess(state, action);
        case actionTypes.GET_MAGENTO_BP_DETAILS_FAIL:
            return getMagentoBpDetailsFail(state, action);
        
        default: return state;
    }
};

export default reducer;