import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import * as actions from '../../store/actions';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import Table from "components/Table/Table.jsx";
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import Datetime from "react-datetime";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
import InputAdornment from '@material-ui/core/InputAdornment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import cloneDeep from "lodash/cloneDeep";
import Button from "components/CustomButtons/Button.jsx";
import ReactTable from "react-table";
import activeDBs from "shared/activeDBs";

class GINreviewDialogPickValue extends React.Component {
  renderOptions = () => {
    const props = this.props, data = props.data, type = data.type;
    const list = Object.keys(data.options) ? Object.keys(data.options).map((dbname, key) => {
        const currentValue = data.value === dbname ? '- current value -' : '';
        let addedText = '';
        let option = data.options[dbname];
        if (type === 'rbo') {
          option = props.rboDict[dbname][data.options[dbname]];
        } else if (type === 'subgroup') {
          option = props.subgroupsDict[data.options[dbname]];
        } else if (type === 'brand') {
          option = props.brandsDict[data.options[dbname]];
        } else if (type === 'width' || type === 'ilength') {
          addedText = 'mm';
        } else if (type === 'weight') {
          addedText = 'g';
        }
        return (
          <ListItem
            key={'list2'+key}
            button
            onClick={e => {
              e.preventDefault();
              props.updateGINEdit({
                value: dbname,
                field: type
              });
              if (type === 'product_type' && !currentValue) {
                console.log('fetchMOC', option, props.openedDBs);
                props.fetchItemMOCsAll(option, props.openedDBs);
              }
              props.close();
            }}
            >
            {option} {addedText} ({dbname}) {currentValue}
          </ListItem>
        )
    }) : null;
    return list;
  }

  render() {
    const props = this.props, data = props.data;
    return (
      <Dialog
        open={this.props.open}
        keepMounted
        onClose={this.props.close}
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle>
          <div style={{textAlign:'center'}}>{data.title}</div>
          <div className="close-btn">
            <IconButton aria-label="Close" onClick={this.props.close}>
              <Close />
            </IconButton>
          </div>
        </DialogTitle>
        <div style={{margin:20}}>
          {this.renderOptions()}
        </div>
      </Dialog>
    );
  }
}

const mapStateToProps = state => {
  const brands = state.gin.brands,
        subgroups = state.gin.subgroups,
        options = state.gin.options;
  let brandsDict = {},
      subgroupsDict = {},
      rboDict = {};
  brands.forEach(b => {
    brandsDict[b.id.toString()] = b.name;
  });
  subgroups.forEach(s => {
    subgroupsDict[s.id.toString()] = s.name;
  });
  Object.keys(options).forEach(db => {
    rboDict[db] = {};
    const opts = options[db].rbos;
    if (opts) {
      opts.forEach(o => {
        rboDict[db][o.FirmCode.toString()] = o.FirmName;
      });
    }
  });

  return {
    picked: state.gin.picked,
    openedDBs: state.gin.openedDBs,
    options: state.gin.options,
    brandsDict,
    subgroupsDict,
    rboDict,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    updateGINEdit: (data) => {
      dispatch(actions.updateGINEdit(data));
    },
    fetchItemMOCsAll: (value, dbs) => {
      dispatch(actions.fetchItemMOCsAll(value, dbs));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GINreviewDialogPickValue);
