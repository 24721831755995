import React from "react";
import PropTypes from "prop-types";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// react plugin for creating vector maps
import { VectorMap } from "react-jvectormap";
import { Route, Redirect, Link } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import ContentCopy from "@material-ui/icons/ContentCopy";
import Store from "@material-ui/icons/Store";
import InfoOutline from "@material-ui/icons/InfoOutline";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Refresh from "@material-ui/icons/Refresh";
import Edit from "@material-ui/icons/Edit";
import Place from "@material-ui/icons/Place";
import ArtTrack from "@material-ui/icons/ArtTrack";
import Language from "@material-ui/icons/Language";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import Search from "@material-ui/icons/Search";
import Assignment from "@material-ui/icons/Assignment";
import Done from "@material-ui/icons/Done";
import Help from "@material-ui/icons/Help";
import Add from'@material-ui/icons/Add';

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Table from "components/Table/Table.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Danger from "components/Typography/Danger.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart
} from "variables/charts";

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";

import priceImage1 from "assets/img/card-2.jpeg";
import bpImage from "assets/img/bp-hands-shaking.jpg";
import ginImage from "assets/img/global-items.jpg";

const us_flag = require("assets/img/flags/US.png");
const de_flag = require("assets/img/flags/DE.png");
const au_flag = require("assets/img/flags/AU.png");
const gb_flag = require("assets/img/flags/GB.png");
const ro_flag = require("assets/img/flags/RO.png");
const br_flag = require("assets/img/flags/BR.png");
const cn_flag = require("assets/img/flags/CN.png");
const mx_flag = require("assets/img/flags/MX.png");

var mapData = {
  AU: 760,
  BR: 550,
  CA: 120,
  DE: 1300,
  FR: 540,
  GB: 690,
  GE: 200,
  IN: 200,
  RO: 600,
  RU: 300,
  US: 2920
};

class Dashboard extends React.Component {
  state = {
    value: 0
  };
  handleChange = (event, value) => {
    this.setState({ value });
  };
  handleChangeIndex = index => {
    this.setState({ value: index });
  };
  render() {
    const token = localStorage.getItem('token');
    console.log('the token', token);
    // if (!token) {
    //   return <Redirect to="/pages/login-page" />;
    // }

    const { classes } = this.props;
    const titleCSS = {
      textAlign: 'center',
      color: 'white',
      background: 'linear-gradient(60deg, #2a7bc3, #2463aa)',
      boxShadow: '0 12px 20px -10px rgba(71, 161, 218, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(71, 161, 218, 0.2)',
    }
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={6} md={12}>
            <div style={titleCSS}><strong><h2 style={{paddingTop:'30px',paddingBottom:'30px'}}>Welcome to the NATco Portal 2.0</h2></strong></div>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <Card product className={classes.cardHover}>
              <CardHeader image className={classes.cardHeaderHover}>
                <Link to="/BP?p=new">
                  <img src={bpImage} alt="..." />
                </Link>
              </CardHeader>
              <CardBody>
                <div className={classes.cardHoverUnder}>
                  <Tooltip
                    id="tooltip-top"
                    title="New BP"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Link to="/BP?p=new">
                      <Button color="success" simple justIcon>
                        <Add className={classes.underChartIcons} />
                      </Button>
                    </Link>
                  </Tooltip>
                  <Tooltip
                    id="tooltip-top"
                    title="Edit BP"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Link to="/BP?p=edit">
                      <Button color="warning" simple justIcon>
                        <Edit className={classes.underChartIcons} />
                      </Button>
                    </Link>
                  </Tooltip>
                  <Tooltip
                    id="tooltip-top"
                    title="BP History"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Link to="/History">
                      <Button color="transparent" simple justIcon>
                        <Search className={classes.underChartIcons} />
                      </Button>
                    </Link>
                  </Tooltip>
                </div>
                <h4 className={classes.cardProductTitle}>
                  <Link to="/BP?p=new">
                    Enter New Business Partner
                  </Link>
                </h4>
                <p className={classes.cardProductDesciprion}>
                  Enter a new business partner into the system
                </p>
              </CardBody>
              <CardFooter product>
                <div className={classes.price}>
                  <h4></h4>
                </div>
                <div className={`${classes.stats} ${classes.productStats}`}>
                    <Help />
                  <a href="#pablo" onClick={e => e.preventDefault()}>
                    Get Help with This
                  </a>
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <Card product className={classes.cardHover}>
              <CardHeader image className={classes.cardHeaderHover}>
                <Link to="/GIN">
                  <img src={ginImage} alt="..." />
                </Link>
              </CardHeader>
              <CardBody>
                <div className={classes.cardHoverUnder}>
                  <div style={{opacity:'none'}}>
                  <Tooltip
                    id="tooltip-top"
                    title="Find an Item"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button color="transparent" simple justIcon>
                      <Search className={classes.underChartIcons} />
                    </Button>
                  </Tooltip>
                  <Tooltip
                    id="tooltip-top"
                    title="Edit an Item"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button color="success" simple justIcon>
                      <Refresh className={classes.underChartIcons} />
                    </Button>
                  </Tooltip>
                  <Tooltip
                    id="tooltip-top"
                    title="Request Escalation"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button color="danger" simple justIcon>
                      <Edit className={classes.underChartIcons} />
                    </Button>
                  </Tooltip>
                  </div>
                </div>
                <h4 className={classes.cardProductTitle}>
                  <Link to="/GIN">
                    Enter an Item
                  </Link>
                </h4>
                <p className={classes.cardProductDesciprion}>
                  Enter a new item into the system
                </p>
              </CardBody>
              <CardFooter product>
                <div className={classes.price}>
                  <h4></h4>
                </div>
                <div className={`${classes.stats} ${classes.productStats}`}>
                    <Help />
                  <a href="#pablo" onClick={e => e.preventDefault()}>
                    Get Help with This
                  </a>
                </div>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <Card>
              <CardHeader color="warning" stats icon>
                <CardIcon color="warning">
                  <ShoppingCart />
                </CardIcon>
                <p className={classes.cardCategory}>Pending Orders</p>
                <h3 className={classes.cardTitle}>
                       14
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                 <Search />
                  <a href="#pablo" onClick={e => e.preventDefault()}>
                    View Pending Orders
                  </a>
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <Card>
              <CardHeader color="info" stats icon>
                <CardIcon color="info">
                  <Assignment />
                </CardIcon>
                <p className={classes.cardCategory}>Pending Tasks</p>
                <h3 className={classes.cardTitle}>87</h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                   <Search />
                  <a href="#pablo" onClick={e => e.preventDefault()}>
                    View Tasks
                  </a>
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <Card>
              <CardHeader color="danger" stats icon>
                <CardIcon color="danger">
                  <InfoOutline />
                </CardIcon>
                <p className={classes.cardCategory}>Notifications</p>
                <h3 className={classes.cardTitle}>15</h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                   <Search />
                  <a href="#pablo" onClick={e => e.preventDefault()}>
                    View Notifications
                  </a>
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon color="success">
                  <Done />
                </CardIcon>
                <p className={classes.cardCategory}>Completed Orders</p>
                <h3 className={classes.cardTitle}>245</h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <Search />
                  <a href="#pablo" onClick={e => e.preventDefault()}>
                    View Completed Orders
                  </a>
                </div>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="info" icon>
                <CardIcon color="info">
                  <Update />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  Top Task Updates
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer justify="space-between">
                  <GridItem xs={12} sm={12} md={5}>
                    <Table
                      tableData={[
                        [
                          <img src={us_flag} alt="us_flag" />,
                          "User: Carlos.Santillan submitted Tech Record for Order#: 40223",
                          "FashionNova",
                          "NLA"
                        ],
                        [
                          <img src={us_flag} alt="us_flag" />,
                          "Order#: 3009 Arriving today around 3:00pm PST",
                          "A&F",
                          "NLA"
                        ],
                        [
                          <img src={cn_flag} alt="us_flag" />,
                          "Sample Requests Processed in China for Order#: 03884",
                          "A&F",
                          "NSP"
                        ],
                        [
                          <img src={mx_flag} alt="us_flag" />,
                          "Items Submitted from Mexico have been Approved",
                          "By: Carlos",
                          "Mexico"
                        ],
                        [
                          <img src={us_flag} alt="us_flag" />,
                          "REMINDER: Fill out IC Allocation Sheet for August 2018 (DUE: August 15th 2018)",
                          "For: Shaunt",
                          "NLA"
                        ],
                        [
                          <img src={us_flag} alt="us_flag" />,
                          "Friday August 10th 2018 is a Holiday",
                          "Day Off",
                          "NLA"
                        ]
                      ]}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <VectorMap
                      map={"world_mill"}
                      backgroundColor="transparent"
                      zoomOnScroll={false}
                      containerStyle={{
                        width: "100%",
                        height: "280px"
                      }}
                      containerClassName="map"
                      regionStyle={{
                        initial: {
                          fill: "#e4e4e4",
                          "fill-opacity": 0.9,
                          stroke: "none",
                          "stroke-width": 0,
                          "stroke-opacity": 0
                        }
                      }}
                      series={{
                        regions: [
                          {
                            values: mapData,
                            scale: ["#AAAAAA", "#444444"],
                            normalizeFunction: "polynomial"
                          }
                        ]
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <Card chart className={classes.cardHover}>
              <CardHeader color="info" className={classes.cardHeaderHover}>
                <ChartistGraph
                  className="ct-chart-white-colors"
                  data={dailySalesChart.data}
                  type="Line"
                  options={dailySalesChart.options}
                  listener={dailySalesChart.animation}
                />
              </CardHeader>
              <CardBody>
                <div className={classes.cardHoverUnder}>
                  <Tooltip
                    id="tooltip-top"
                    title="Refresh"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button simple color="info" justIcon>
                      <Refresh className={classes.underChartIcons} />
                    </Button>
                  </Tooltip>
                  <Tooltip
                    id="tooltip-top"
                    title="Change Date"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button color="transparent" simple justIcon>
                      <Edit className={classes.underChartIcons} />
                    </Button>
                  </Tooltip>
                </div>
                <h4 className={classes.cardTitle}>Daily Sales</h4>
                <p className={classes.cardCategory}>
                  <span className={classes.successText}>
                    <ArrowUpward className={classes.upArrowCardCategory} /> 55%
                  </span>{" "}
                  increase in today sales.
                </p>
              </CardBody>
              <CardFooter chart>
                <div className={classes.stats}>
                  <AccessTime /> updated 4 minutes ago
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card chart className={classes.cardHover}>
              <CardHeader color="warning" className={classes.cardHeaderHover}>
                <ChartistGraph
                  className="ct-chart-white-colors"
                  data={emailsSubscriptionChart.data}
                  type="Bar"
                  options={emailsSubscriptionChart.options}
                  responsiveOptions={emailsSubscriptionChart.responsiveOptions}
                  listener={emailsSubscriptionChart.animation}
                />
              </CardHeader>
              <CardBody>
                <div className={classes.cardHoverUnder}>
                  <Tooltip
                    id="tooltip-top"
                    title="Refresh"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button simple color="info" justIcon>
                      <Refresh className={classes.underChartIcons} />
                    </Button>
                  </Tooltip>
                  <Tooltip
                    id="tooltip-top"
                    title="Change Date"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button color="transparent" simple justIcon>
                      <Edit className={classes.underChartIcons} />
                    </Button>
                  </Tooltip>
                </div>
                <h4 className={classes.cardTitle}>Completed Orders</h4>
                <p className={classes.cardCategory}>
                  Last Campaign Performance
                </p>
              </CardBody>
              <CardFooter chart>
                <div className={classes.stats}>
                  <AccessTime /> campaign sent 2 days ago
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card chart className={classes.cardHover}>
              <CardHeader color="danger" className={classes.cardHeaderHover}>
                <ChartistGraph
                  className="ct-chart-white-colors"
                  data={completedTasksChart.data}
                  type="Line"
                  options={completedTasksChart.options}
                  listener={completedTasksChart.animation}
                />
              </CardHeader>
              <CardBody>
                <div className={classes.cardHoverUnder}>
                  <Tooltip
                    id="tooltip-top"
                    title="Refresh"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button simple color="info" justIcon>
                      <Refresh className={classes.underChartIcons} />
                    </Button>
                  </Tooltip>
                  <Tooltip
                    id="tooltip-top"
                    title="Change Date"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button color="transparent" simple justIcon>
                      <Edit className={classes.underChartIcons} />
                    </Button>
                  </Tooltip>
                </div>
                <h4 className={classes.cardTitle}>Completed Tasks</h4>
                <p className={classes.cardCategory}>
                  Last Campaign Performance
                </p>
              </CardBody>
              <CardFooter chart>
                <div className={classes.stats}>
                  <AccessTime /> campaign sent 2 days ago
                </div>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
        <h3>Quick Actions</h3>
        <br />

      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(Dashboard);
