import React from 'react';
import moment from 'moment';
import FormControl from '@material-ui/core/FormControl';
import Datetime from "react-datetime";

const BPCalendar = (props) => {
  // takes
  // onChange
  // name
  // value
  // label
  return (
    <div style={{paddingTop:'23px'}}>
      <FormControl fullWidth>
        <Datetime
          closeOnSelect={true}
          timeFormat={false}
          inputProps={{
            placeholder: props.label
          }}
          onChange={param => {
            let dateString = '';
            if (typeof param.format === 'function') {
              dateString = param.format('MM/DD/YYYY');
            }
            if (moment(dateString, "MM/DD/YYYY", true).isValid()) {
              props.onChange(dateString);
            } else {
              props.onChange('');
            }
          }}
          name={props.name}
          value={props.value}
        />
      </FormControl>
    </div>
  );
}

export default BPCalendar;
