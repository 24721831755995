import React, { useEffect, useState } from 'react';
import { Dialog } from '@material-ui/core';
import CustomInput from 'components/CustomInput/CustomInputValidate.jsx';
import Button from '../../components/CustomButtons/Button';
import { ClipLoader } from 'react-spinners';

const SAPMonitorModal = props => {
    const [searchTerm, setSearchTerm] = useState('');
    const [bpArr, setBPArr] = useState([]);
    const { db, bps, choose, loading_bps } = props;
    useEffect(() => {
        setBPArr([]);
    }, []);

    useEffect(() => {
        if (bps) setBPArr(bps);
    }, [bps])

    console.log('bpArr', bpArr, props)

    return <div>
        <Dialog
            fullWidth={true}
            maxWidth="md"
            onClose={() => {
                props.close();
            }}
            open={props.open}
        >
            <div className="dialog-wrapper">
                <h3>Search BP</h3>
                <CustomInput 
                    value={searchTerm}
                    handleTextInputChange={(e) => {
                        setSearchTerm(e.target.value.toUpperCase());
                    }}
                    label="Enter Search Term"
                />
                <div className="mt-20 text-center">
                    <Button
                        onClick={() => {
                            props.submit({search_term: searchTerm, db});
                        }}
                        color="primary"
                    >SUBMIT</Button>
                </div>

                {loading_bps && <div className="text-center mt-20"><ClipLoader /></div>}

                {(bpArr.length > 0 && !loading_bps) && <div className="mt-20">
                        {bpArr.map((b,i) => {
                            return <div className="choice-box hoverable" 
                                key={`choice-${i}`}
                                onClick={() => {
                                    choose(`(${b.CardCode})${b.CardName}`);
                                    props.close();
                                }}
                            >
                                {b.CardCode} - {b.CardName}
                            </div>
                        })}
                </div>}
            </div>
        </Dialog>
    </div>
}

export default SAPMonitorModal;