import React, { useEffect, useState } from 'react';
import { FadeLoader } from 'react-spinners';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import { options } from "./TranslationsObj";
import Button from "components/CustomButtons/Button.jsx";
import TranslationsResults from "./TranslationsResults";

const TranslationsSearch = (props) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [rbo, setRBO] = useState('-1');
  const [category, setCategory] = useState(null);
  const handleTextInputChange = (e) => {
    if (e.target.value) {
        setSearchTerm(e.target.value.toUpperCase());
    } else {
        setSearchTerm(e.target.value);
    }
  }
  console.log('TranslationsSearch', props);
  return (
    <form onSubmit={(e) => {
        e.preventDefault();
        if (rbo && searchTerm) {
            // SEARCH FOR TRANSLATION
            if (searchTerm.trim()) {
                console.log('searching', searchTerm)
                props.searchTranslation({
                    searchTerm: searchTerm.trim(),
                    rbo,
                    category
                })
            }
        }
    }}>
      <GridContainer>
        <GridItem xs={6} sm={6} md={3}>
            <CustomInput
                labelText="Search Term"
                formControlProps={{
                    fullWidth: true
                }}
                inputProps={{
                    onChange: (e) => {
                        handleTextInputChange(e);
                    },
                    value: searchTerm,
                    role: 'presentation',
                    autoComplete: 'nope'
                }}
                error={!searchTerm? true : false}
            />
            {!searchTerm && <div className="red-text">Please enter search term</div>}
        </GridItem>
        <GridItem xs={6} sm={6} md={2}>
          {props.rbo_data && <CustomSelect
              label="Select RBO"
              options={[ {FirmCode:'-1',FirmName:'Default'}, ...props.rbo_data]}
              choose={(e) => {
                console.log('e', e);
                setRBO(e);
              }}
              name="RBO"
              indx="FirmCode"
              desc="FirmName"
              default={rbo}
              error={!rbo ? 'Required' : null}
            />}
        </GridItem>
        <GridItem xs={6} sm={6} md={2}>
            <CustomSelect
                label="Category (optional)"
                options={[ '',...options.categories_obj_choices]}
                indx="id"
                desc="desc"
                choose={(e, n) => {
                    console.log('e', e);
                    setCategory(e);
                }}
                default={category}
            />
        </GridItem>
        <GridItem xs={6} sm={6} md={5}>
          {(rbo && searchTerm) && <Button
            color="rose"
            onClick={() => {
                // SEARCH FOR TRANSLATION
                if (searchTerm.trim()) {
                    console.log('searching', searchTerm)
                    props.searchTranslation({
                        searchTerm: searchTerm.trim(),
                        rbo,
                        category
                    })
                }
            }}
          >SEARCH FOR MATCHES</Button>}
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
            {/*props.update_permitted && <Button
                            color="success"
                            onClick={() => {
                                props.create(props.title);
                            }}
                        >ADD NEW TRANSLATION</Button>*/}
            {<TranslationsResults 
                rbo_data={props.rbo_data}
                data={props.data}
                update_permitted={props.update_permitted}
                readTranslation={props.readTranslation}
                openModal={props.openModal}
                currentSearchParams={{
                    category, searchTerm, rbo
                }}
            />}
        </GridItem>
      </GridContainer>
    </form>
  );
}

export default TranslationsSearch;
