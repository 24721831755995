import * as actionTypes from './actionTypes';

// fetch AddInfo
// ----------
export const fetchAddInfoList = (db) => {
    return {
        type: actionTypes.FETCH_ADDINFO_LIST,
        db
    }
}

export const fetchAddInfoListSuccess = (addinfo,db) => {
    return {
        type: actionTypes.FETCH_ADDINFO_LIST_SUCCESS,
        addinfo,
        db
    }
}

export const fetchAddInfoListFail = (err) => {
    return {
        type: actionTypes.FETCH_ADDINFO_LIST_FAIL,
        err
    }
}

// fetch DB list
// ------------------
export const fetchDBList = () => {
    return {
        type: actionTypes.FETCH_DB_LIST
    }
}

export const fetchDBListSuccess = (dbs) => {
    return {
        type: actionTypes.FETCH_DB_LIST_SUCCESS,
        dbs
    }
}

export const fetchDBListFail = (err) => {
    return {
        type: actionTypes.FETCH_DB_LIST_FAIL,
        err
    }
}

// fetch BP info from SAP
// ----------------------
export const fetchBPSAP = (db, name, cardcode, origin_check, bp_type, reviewOnly) => {
  console.log('fetching BP SAP origin check', origin_check);
  return {
    type: actionTypes.FETCH_BP_SAP,
    db,
    name,
    cardcode,
    origin_check,
    bp_type,
    reviewOnly
  }
}

export const fetchBPSAPStart = () => {
  return {
    type: actionTypes.FETCH_BP_SAP_START
  }
}

export const fetchBPSAPFail = (err) => {
  console.log('error catch fetch BP SAP',err);
  return {
    type: actionTypes.FETCH_BP_SAP_FAIL,
    err
  }
}

export const fetchBPSAPSuccess = (bp, reviewOnly, lockedMsg) => {
  return {
    type: actionTypes.FETCH_BP_SAP_SUCCESS,
    bp,
    reviewOnly,
    lockedMsg
  }
}

export const emptySAPBPreview = () => {
  return {
    type: actionTypes.EMPTY_SAP_BP_REVIEW
  }
}

export const resetSAPBP = () => {
  return {
    type: actionTypes.RESET_SAP_BP
  }
}

export const cancelGMPBPExistingPreview = () => {
  return {
    type: actionTypes.CANCEL_GMP_BP_EXISTING_PREVIEW
  }
}

export const updateBPErrors = (errors) => {
  return {
    type: actionTypes.UPDATE_BP_ERRORS
  }
}

export const fetchAllBP = (bp_type, db) => {
  return {
    type: actionTypes.FETCH_ALL_BP,
    bp_type,
    db
  }
}

export const fetchAllBPStart = () => {
  return {
    type: actionTypes.FETCH_ALL_BP_START
  }
}

export const fetchAllBPSuccess = (results) => {
  return {
    type: actionTypes.FETCH_ALL_BP_SUCCESS,
    results
  }
}

export const fetchAllBPFail = (err) => {
  return {
    type: actionTypes.FETCH_ALL_BP_FAIL,
    err
  }
}

export const saveLocalBP = (data, file, filename) => {
  return {
    type: actionTypes.SAVE_LOCAL_BP,
    data,
    file,
    filename
  }
}

export const saveLocalBPStart = () => {
  return {
    type: actionTypes.SAVE_LOCAL_BP_START
  }
}

export const saveLocalBPSuccess = (res) => {
  return {
    type: actionTypes.SAVE_LOCAL_BP_SUCCESS,
    res
  }
}

export const saveLocalBPFail = (err) => {
  return {
    type: actionTypes.SAVE_LOCAL_BP_FAIL,
    err
  }
}

export const fetchLocalBP = (submission_id, origin) => {
  return {
    type: actionTypes.FETCH_LOCAL_BP,
    submission_id,
    origin
  }
}

export const fetchLocalBPStart = () => {
  return {
    type: actionTypes.FETCH_LOCAL_BP_START
  }
}

export const fetchLocalBPSuccess = (bp, origin) => {
  return {
    type: actionTypes.FETCH_LOCAL_BP_SUCCESS,
    bp,
    origin
  }
}

export const fetchLocalBPFail = (err) => {
  return {
    type: actionTypes.FETCH_LOCAL_BP_FAIL,
    err
  }
}

export const saveSAPBP = (data, file, filename, isTPP) => {
  return {
    type: actionTypes.SAVE_SAP_BP,
    data,
    file,
    filename,
    isTPP
  }
}

export const saveSAPBPStart = () => {
  return {
    type: actionTypes.SAVE_SAP_BP_START
  }
}

export const saveSAPBPSuccess = (response) => {
  return {
    type: actionTypes.SAVE_SAP_BP_SUCCESS,
    response
  }
}

export const saveSAPBPFail = (err) => {
  return {
    type: actionTypes.SAVE_SAP_BP_FAIL,
    err
  }
}

export const closeSaveSAPBPModal = () => {
  return {
    type: actionTypes.CLOSE_SAVE_SAP_BP_MODAL
  }
}

export const checkPendingBP = (bpname, bp_type, db) => {
  return {
    type: actionTypes.CHECK_PENDING_BP,
    bpname,
    bp_type,
    db
  }
}

export const checkPendingBPStart = () => {
  return {
    type: actionTypes.CHECK_PENDING_BP_START,
  }
}

export const checkPendingBPSuccess = (response) => {
  return {
    type: actionTypes.CHECK_PENDING_BP_SUCCESS,
    response
  }
}

export const checkPendingBPFail = (err) => {
  return {
    type: actionTypes.CHECK_PENDING_BP_FAIL,
    err
  }
}

export const resetCheckPendingBP = () => {
  return {
    type: actionTypes.RESET_CHECK_PENDING_BP
  }
}


export const sendResaleCertFile = (file, filename) => {
  return {
    type: actionTypes.SEND_RESALE_CERT_FILE,
    file,
    filename
  }
}

export const sendResaleCertFileStart = () => {
  return {
    type: actionTypes.SEND_RESALE_CERT_FILE_START
  }
}

export const sendResaleCertFileSuccess = (response) => {
  return {
    type: actionTypes.SEND_RESALE_CERT_FILE_SUCCESS,
    response
  }
}

export const sendResaleCertFileFail = err => {
  return {
    type: actionTypes.SEND_RESALE_CERT_FILE_FAIL,
    err
  }
}

export const getResaleCertFile = (file) => {
  return {
    type: actionTypes.GET_RESALE_CERT_FILE,
    file
  }
}

export const getResaleCertFileStart = () => {
  return {
    type: actionTypes.GET_RESALE_CERT_FILE
  }
}

export const getResaleCertFileSuccess = (file) => {
  return {
    type: actionTypes.GET_RESALE_CERT_FILE_SUCCESS,
    file
  }
}

export const getResaleCertFileFail = err => {
  return {
    type: actionTypes.GET_RESALE_CERT_FILE_FAIL,
    err
  }
}

export const sendResaleCertFileSAP = (file) => {
  return {
    type: actionTypes.SEND_RESALE_CERT_FILE_SAP,
    file
  }
}

export const sendResaleCertFileSAPStart = () => {
  return {
    type: actionTypes.SEND_RESALE_CERT_FILE_SAP_START
  }
}

export const sendResaleCertFileSAPSuccess = (response) => {
  return {
    type: actionTypes.SEND_RESALE_CERT_FILE_SAP_SUCCESS,
    response
  }
}

export const sendResaleCertFileSAPFail = (err) => {
  return {
    type: actionTypes.SEND_RESALE_CERT_FILE_SAP_FAIL,
    err
  }
}

export const setBPdata = (obj) => {
  return {
    type: actionTypes.SET_BP_DATA,
    obj
  }
}

export const resetCommon = () => {
  return {
    type: actionTypes.RESET_COMMON
  }
}

export const getBPLocks = () => {
  return {
    type: actionTypes.GET_BP_LOCKS
  }
}

export const getBPLocksStart = () => {
  return {
    type: actionTypes.GET_BP_LOCKS_START
  }
}

export const getBPLocksSuccess = (response) => {
  return {
    type: actionTypes.GET_BP_LOCKS_SUCCESS,
    response
  }
}

export const getBPLocksFail = (err) => {
  return {
    type: actionTypes.GET_BP_LOCKS_FAIL,
    err
  }
}

export const checkBPLock = (db, card_code) => {
  return {
    type: actionTypes.CHECK_BP_LOCK,
    db, card_code
  }
}

export const checkBPLockStart = () => {
  return {
    type: actionTypes.CHECK_BP_LOCK_START
  }
}

export const checkBPLockSuccess = (response) => {
  return {
    type: actionTypes.CHECK_BP_LOCK_SUCCESS,
    response
  }
}

export const checkBPLockFail = (err) => {
  return {
    type: actionTypes.CHECK_BP_LOCK_FAIL,
    err
  }
}

export const updateBPLock = (db, card_code, bpname, action) => {
  return {
    type: actionTypes.UPDATE_BP_LOCK,
    db, card_code, bpname, action
  }
}

export const updateBPLockStart = () => {
  return {
    type: actionTypes.UPDATE_BP_LOCK_START
  }
}

export const updateBPLockSuccess = (response) => {
  return {
    type: actionTypes.UPDATE_BP_LOCK_SUCCESS,
    response
  }
}

export const updateBPLockFail = (err) => {
  return {
    type: actionTypes.UPDATE_BP_LOCK_FAIL,
    err
  }
}

export const releaseUserLockedBPs = () => {
  return {
    type: actionTypes.RELEASE_USER_LOCKED_BPS
  }
}

export const closeReviewModal = () => {
  return {
    type: actionTypes.CLOSE_REVIEW_MODAL
  }
}

export const choosePrimary = (data) => {
  return {
    type: actionTypes.CHOOSE_PRIMARY,
    data
  }
}

export const choosePrimaryStart = () => {
  return {
    type: actionTypes.CHOOSE_PRIMARY_START
  }
}

export const choosePrimarySuccess = (data) => {
  return {
    type: actionTypes.CHOOSE_PRIMARY_SUCCESS,
    data
  }
}

export const choosePrimaryFail = (err) => {
  return {
    type: actionTypes.CHOOSE_PRIMARY_FAIL,
    err
  }
}
