import React, { Component } from 'react';
import ReactDOM from "react-dom";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import { withStyles } from '@material-ui/core/styles';
import styles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";

import dbList from "shared/activeDBs";

const dbs = dbList.map((db) => {
  return db.name;
});

const Single = props => {
  const { permObj, classes } = props;
  const actionsDivs = props.actions.map((ac,i) => {
    const id = props.actions[i].id,
          showLabel = props.actions[i].showLabel,
          label = props.actions[i].subCategory,
          unCheck = props.actions[i].unCheck,
          name = props.actions[i].name;
    return (
      <div style={{padding:'20px 0 0 0'}} key={`actions-${i}`}>
        {showLabel && <div className="pilled2-relative"><h4 className="pilled2">{label}</h4></div>}
        {props.actions[i].name}
        <Checkbox
          tabIndex={-1}
          checkedIcon={<Check className={classes.checkedIcon} />}
          icon={<Check className={classes.uncheckedIcon} />}
          classes={{checked: classes.checked}}
          checked={permObj.hasOwnProperty(id)}
          onClick={() => {
            console.log('clicked', name, id);
            if (permObj.hasOwnProperty(id)) {
              delete permObj[id];
            } else {
              permObj[id] = [null];
            };
            if (unCheck) {
              console.log('unCheck',unCheck, permObj);
              delete permObj[unCheck];
              props.choosePerm(permObj);
            } else {
              props.choosePerm(permObj);
            }
          }}
        />
      </div>
    )
  });
  return actionsDivs;
}

const HasDBs = props => {
  const { permObj, classes } = props;
  const showLabel = props.actions[0].showLabel,
        label = props.actions[0].subCategory == 'GMP' ? 'TPP' : props.actions[0].subCategory;
  return (
  <div style={{marginBottom:'40px'}}>
    {showLabel && <div className="pilled2-relative"><h4 className="pilled2">{label}</h4></div>}
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
          </TableCell>
          {dbList.map((db, i) => {
            return <TableCell align="right" key={`tcr${i}`}><div className="db-cell" style={{backgroundColor:db.color}}>{db.name.split('_')[0]}</div></TableCell>
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {props.actions.map((n, i) => {
            return (
              <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                key={`trr${i}`}
              >
                <TableCell align="left">
                  <div style={{textAlign:'left'}}>{n.name}</div>
                </TableCell>
                {dbList.map((db, ii) => {
                  const isSendToDirect = permObj[9] ? permObj[9].indexOf(db.name) !== -1 ? true : false : false;
                  let isChecked = permObj.hasOwnProperty(n.id) ? permObj[n.id].indexOf(db.name) !== -1 ? true : false : false;
                  if (isSendToDirect && n.id === 8) {
                    isChecked = true;
                  }
                  return (
                    <TableCell key={`tccb${ii}`}>
                      <Checkbox
                        tabIndex={-1}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{checked: classes.checked}}
                        disabled={isSendToDirect && n.id === 8}
                        checked={isChecked}
                        onClick={() => {
                          console.log('click', db.name, n.name, n.id);
                          let autoAdd_1 = false;
                          // if Approve BP & Direct to SAP autocheck Create BP
                          if (n.id === 9) {
                            autoAdd_1 = true;
                          }
                          if (permObj.hasOwnProperty(n.id)) {
                            if (permObj[n.id].length > 0) {
                              const indx = permObj[n.id].indexOf(db.name);
                              if (indx === -1) {
                                permObj[n.id].push(db.name);
                              } else {
                                permObj[n.id] = permObj[n.id].filter((d,i) => {
                                  if (i === indx) {
                                    return false;
                                  }
                                  return true;
                                });
                              }
                            }
                          } else {
                            permObj[n.id] = [db.name];
                          }

                          if (autoAdd_1) {
                            if (permObj.hasOwnProperty(8)) {
                              if (permObj[8].length > 0) {
                                const indx = permObj[8].indexOf(db.name);
                                if (indx === -1) {
                                  permObj[8].push(db.name);
                                }
                              }
                            } else {
                              permObj[8] = [db.name];
                            }
                          }

                          let newPermObj = {};
                          Object.keys(permObj).forEach((perm_id) => {
                            if (permObj[perm_id].length !== 0) {
                              newPermObj[perm_id] = permObj[perm_id];
                            }
                          });
                          props.choosePerm(newPermObj);
                          console.log('perm obj',permObj);
                        }}
                      />
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
          {props.actions.length > 1 && <TableRow>
            <TableCell>
              <div style={{textAlign:'left'}}><strong>Select ALL</strong></div>
            </TableCell>
            {dbList.map((db, ii) => {
              let isAllChecked = true;
              props.actions.forEach((n, i) => {
                if (!permObj.hasOwnProperty(n.id)) {
                  isAllChecked = false;
                } else {
                  if (permObj[n.id].indexOf(db.name) === -1) {
                    isAllChecked = false;
                  }
                }
              });
              return (
                <TableCell key={`tcceb${ii}`}>
                  <Checkbox
                    tabIndex={-1}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{checked: classes.checked}}
                    checked={isAllChecked}
                    onClick={() => {
                      console.log('you clicked the db', db.name, isAllChecked);
                      if (!isAllChecked) {
                        console.log('not checked');
                        props.actions.forEach((n, i) => {
                          if (permObj.hasOwnProperty(n.id)) {
                            if (permObj[n.id].length > 0) {
                              const indx = permObj[n.id].indexOf(db.name);
                              if (indx === -1) {
                                permObj[n.id].push(db.name);
                              }
                            }
                          } else {
                            permObj[n.id] = [db.name];
                          };
                          console.log('beforePermObj',permObj);
                          let newPermObj = {};
                          Object.keys(permObj).forEach((perm_id) => {
                            if (permObj[perm_id].length !== 0) {
                              newPermObj[perm_id] = permObj[perm_id];
                            }
                          });
                          console.log('newPermObj',newPermObj);
                          props.choosePerm(newPermObj);
                        });
                      } else {
                        props.actions.forEach((n, i) => {
                          console.log('permObj is',permObj);
                          if (permObj.hasOwnProperty(n.id)) {
                            console.log('to be deleteds',permObj[n.id]);
                            if (permObj[n.id].length > 0) {

                              const indx = permObj[n.id].indexOf(db.name);
                              console.log('index at not checked is',indx);
                              if (indx !== -1) {
                                console.log('found at', db.name);
                                permObj[n.id] = permObj[n.id].filter((r,i) => {
                                  if (i === indx) {
                                    return false;
                                  }
                                  return true;
                                });
                              }
                            }
                          }
                          let newPermObj = {};
                          Object.keys(permObj).forEach((perm_id) => {
                            if (permObj[perm_id].length !== 0) {
                              newPermObj[perm_id] = permObj[perm_id];
                            }
                          });
                          props.choosePerm(newPermObj);
                        });
                      }
                    }}
                  />
                </TableCell>
              );
            })}
          </TableRow>}
      </TableBody>
    </Table>
  </div>
  )
}

const UserMatrix = props => {
  console.log('these are the actions',props.actions);
  const { classes } = props;
  const id = props.actions[0].id,
        name = props.actions[0].name;
  let permObj = JSON.parse(JSON.stringify(props.permObj));

  // singles = no DBs
  // hasDBs = multiple DB checkboxes
  const singles = props.actions.filter(act => {
    if (!act.hasDBs) {
      return true;
    }
  });
  const hasDBs = props.actions.filter(act => {
    if (act.hasDBs) {
      return true;
    }
  });

  let singlesGrouped = {};
  singles.forEach(act => {
    if (act.subCategory) {
      if (singlesGrouped.hasOwnProperty(act.subCategory)) {
        singlesGrouped[act.subCategory].push(act);
      } else {
        singlesGrouped[act.subCategory] = [act];
      }
    }
  });

  let hasDBsGrouped = {};
  hasDBs.forEach(act => {
    if (act.subCategory) {
      if (hasDBsGrouped.hasOwnProperty(act.subCategory)) {
        hasDBsGrouped[act.subCategory].push(act);
      } else {
        hasDBsGrouped[act.subCategory] = [act];
      }
    }
  });

  console.log('groupeds', singlesGrouped, hasDBsGrouped);
  console.log('groupeds arr', Object.keys(singlesGrouped));
  const singleRenders = Object.keys(singlesGrouped).map((category, i) => {
    console.log('singles Grouped', singlesGrouped[category], category);
    return <Single
            key={`singles${i}`}
            permObj={permObj}
            classes={classes}
            choosePerm={props.choosePerm}
            actions={singlesGrouped[category]}
          />;
  });

  const hasDBsRenders = Object.keys(hasDBsGrouped).map((category, i) => {
    return <HasDBs
              key={`multiples${i}`}
              permObj={permObj}
              classes={classes}
              choosePerm={props.choosePerm}
              actions={hasDBsGrouped[category]}
            />
  });

  return (
    <div className="tiny-table2" style={{paddingLeft:'10px'}}>
    {hasDBsRenders}
    {singleRenders}
    </div>
  );
}

export default withStyles(styles)(UserMatrix);
