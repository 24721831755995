import { numberWithCommas, convertDateStr } from 'shared/utility';
import { ranges, rangesObj } from "./GINranges.js";
import activeDBs from "shared/activeDBs";
import dupesCalc from "./dupesCalc";
import moment from "moment";
import cloneDeep from "lodash/cloneDeep";

const priceRanges = {
  "Base Price": [],
  "1K": [],
  "2.5K": [],
  "5K": [],
  "10K": [],
  "25K": [],
  "50K": [],
  "100K": [],
  "250K": [],
  "500K": [],
  "1M": [],
  "2.5M": [],
  "5M": [],
  "10M+": []
};

const priceRangesDict = {
  "1000":"1K",
  "2500":"2.5K",
  "5000":"5K",
  "10000":"10K",
  "25000":"25K",
  "50000":"50K",
  "100000":"100K",
  "250000":"250K",
  "500000":"500K",
 "1000000":"1M",
 "2500000":"2.5M",
 "5000000":"5M",
"10000000":"10M+"
}

const getFactory = (db) => {
  let factory = '';
  activeDBs.forEach(d => {
    if (d.name === db) {
      factory = d.factory;
    }
  })
  return factory;
}

const getRangeField = (qty) => {
  let finalRange;
  ranges.forEach((r) => {
    if (qty < 10000000) {
      if (r.rangeA <= qty && r.rangeB >= qty) {
        finalRange = r.name;
      }
    }
  });
  return finalRange;
}

export const fieldList = [
  'base_item_description',
  'product_type',
  'rbo',
  'brand',
  'subgroup',
  'sales_unit',
  'basic_packaging_unit',
  'qty_per_unit',
  'ilength',
  'width',
  'weight'
];

export const fieldMainTab = {
  general: ['base_item_description','product_type','rbo','brand','subgroup'],
  measurements: ['sales_unit','basic_packaging_unit','qty_per_unit','ilength','width','weight'],
  variants: ['variants']
}

export const checkVariantExistence = (v) => {
  let variantsExist = true;
  Object.keys(v).forEach(vv => {
    if (v[vv]) {
      if (v[vv].length === 0) {
        variantsExist = false;
      }
    }
  });
  return variantsExist;
}

export const checkCode = (variantsChecked) => {
  let variantStatus = true, obj = {};
  if (Object.keys(variantsChecked).length === 0) {
    return true;
  }
  Object.keys(variantsChecked).forEach(vc => {
    const code = vc.split('_')[0];
    if (obj.hasOwnProperty(code)) {
      variantStatus = false;
    } else {
      obj[code] = 1;
    }
  });
  console.log('checking variant Status', variantsChecked, variantStatus);
  return variantStatus;
}

export const requirePicksDB = (db) => {
  const dbsplit = db.split('_')[0];
  const required = ['NSP','NHK','NTW','NIN','NDK','NAS'].indexOf(dbsplit) === -1;
  console.log(`picks on ${db} is required`, required);
  return required;
}

export const getDescription = (value, options, valueField, descField) => {
  let descFound = null;
  if (options) {
    options.forEach((o) => {
      if (value === o[valueField]) {
        return descFound = o[descField]
      }
    });
  }
  return descFound;
}

export const getPricingsForDupesCheck = (state) => {
  const gcur = state.gin.gcur,
        gstart = state.gin.gstart,
        gend = state.gin.gend,
        gprice = state.gin.gprice,
        gterm = state.gin.gterm,
        gcurrs = state.gin.gcurrs,
        gstarts = state.gin.gstarts,
        gends = state.gin.gends,
        gprices = state.gin.gprices,
        gqtys = state.gin.gqtys,
        gterms = state.gin.gterms,
        mocs = state.gin.mocs,
        itemCodes = state.gin.itemCodes,
        specialmoc = state.gin.specialmoc,
        openedDBs = state.gin.openedDBs;

  let groupedDBMultiQuantities = {}; // keys are DBs
  Object.keys(gqtys).forEach((db) => {
    if (openedDBs.indexOf(db) !== -1) {
      const groupObj = {};
      if (gqtys[db]) {
        gqtys[db].forEach((qty, index) => {
          console.log('quantity is ',qty)
          let name = '', rangeName = '';
          ranges.forEach((re) => {
            if (qty < 10000000) {
              if ((re.rangeA <= qty) && (qty <= re.rangeB)) {
                name = re.name;
                rangeName = `${numberWithCommas(re.rangeA)} - ${numberWithCommas(re.rangeB)}`;
              }
            } else {
              name = '10M+';
              rangeName = '10M+'
            }
          });
          let total = gprices[db][index] ? (qty * parseFloat(gprices[db][index])) : 0;
          if (mocs[db]) {
            if (mocs[db][gcurrs[db][index]]) {
              if (total < mocs[db][gcurrs[db][index]]) {
                total = mocs[db][gcurrs[db][index]] ? parseFloat(mocs[db][gcurrs[db][index]]) : 0;
              }
            }
          }
          let specialmocObj = null;
          if (specialmoc[db]) {
            specialmocObj = {};
            specialmoc[db].forEach((mdb) => {
              if (mdb) {
                specialmocObj[mdb[0]] = !isNaN(mdb[1]) ? parseFloat(mdb[1]) : mdb[1];
              }
            });
            if (Object.keys(specialmocObj).length > 0) {
              if (total < specialmocObj[gcurrs[db][index]]) {
                total = specialmocObj[gcurrs[db][index]];
              }
            }
          }
          const finObj = {
            indx: index,
            qty: gqtys[db][index],
            currency: gcurrs[db][index],
            price: gprices[db][index],
            start: gstarts[db][index],
            end: gends[db][index],
            term: gterms[db][index],
            itemCode: itemCodes[db],
            total,
            moc: specialmocObj[gcurrs[db][index]] || specialmocObj[gcurrs[db][index]] === 0 ? specialmocObj[gcurrs[db][index]] : mocs[db][gcurrs[db][index]],
            mocType: specialmocObj[gcurrs[db][index]] || specialmocObj[gcurrs[db][index]] === 0 ? 'Special' : 'Standard',
            rangeName
          };
          console.log('finObj',finObj, mocs[db], gcurrs[db][index]);
          if (groupObj.hasOwnProperty(name)) {
            groupObj[name].push(finObj);
          } else {
            groupObj[name] = [finObj];
          }
        });
      }
      groupedDBMultiQuantities[db] = groupObj;
    }
  });

  const dbsArr = Object.keys(gcur);
  const pricings = dbsArr.forEach((db) => {
    if (openedDBs.indexOf(db) !== -1) {
      if (gcur[db]) {
        let total = gprice[db] ? parseFloat(gprice[db]) : 0;
        if (mocs[db]) {
          if (mocs[db][gcur[db]]) {
            if (total < mocs[db][gcur[db]]) {
              total = mocs[db][gcur[db]] ? parseFloat(mocs[db][gcur[db]]) : 0;
            }
          }
        }

        let specialmocObj = {};
        if (specialmoc[db]) {
          specialmocObj = {};
          specialmoc[db].forEach((mdb) => {
            if (mdb) {
              specialmocObj[mdb[0]] = !isNaN(mdb[1]) ? parseFloat(mdb[1]) : mdb[1];
            }
          });

          if (Object.keys(specialmocObj).length > 0) {
            if (total < mocs[db][gcur[db]]) {
              total = mocs[db][gcur[db]];
            }
          }
        }

        let standard_moc = 0;
        if (mocs[db]) {
          if (mocs[db][gcur[db]]) {
            standard_moc = mocs[db][gcur[db]];
          }
        }
        const finObj2 = {
          indx: -1,
          qty: 1,
          currency: gcur[db],
          price: gprice[db],
          start: gstart[db],
          end: gend[db],
          term: gterm[db],
          itemCode: itemCodes[db],
          moc: specialmocObj[gcur[db]] || specialmocObj[gcur[db]] === 0 ? specialmocObj[gcur[db]] : standard_moc,
          mocType: specialmocObj[gcur[db]] || specialmocObj[gcur[db]] === 0 ? 'Special' : 'Standard',
          total,
        }
        console.log('finObj2',finObj2);
        if (groupedDBMultiQuantities[db].hasOwnProperty('Base Price')) {
          groupedDBMultiQuantities[db]['Base Price'].push(finObj2);
        } else {
          groupedDBMultiQuantities[db]['Base Price'] = [finObj2];
        }
      }
    }
  });

  let currencies = [];
  Object.keys(gcur).forEach((curr) => {
    if (currencies.indexOf(gcur[curr]) === -1) {
      currencies.push(gcur[curr]);
    }
  });
  return groupedDBMultiQuantities;
}

export const getCustomerPriceDupes = (state) => {
  // filter dbs to only Items that have Cost enabled - Both, Outsourced
  const dboptions = state.gin.openedDBs;
  let customerGroups = {},
      bmasterarray = state.gin.bmasterarray;

  dboptions.forEach((db) => {
    if (bmasterarray[db]) {
      Object.keys(bmasterarray[db]).forEach(customer => {
        customerGroups[customer] = {};
        if (bmasterarray[db][customer]) {
          if (bmasterarray[db][customer].constructor === Array) {
            bmasterarray[db][customer].forEach((cr,i) => {
              const qty = parseInt(cr[0]);
              const obj = {
                indx: i,
                qty,
                term: cr[1],
                price: cr[2],
                currency: cr[5],
                start: cr[6],
                end: cr[7],
                itemCode: state.gin.itemCodes[db]
              }

              if (qty) {
                const qtyField = getRangeField(qty);
                if (customerGroups[customer].hasOwnProperty(db)) {
                  if (customerGroups[customer][db].hasOwnProperty(qtyField)) {
                    customerGroups[customer][db][qtyField].push(obj);
                  } else {
                    customerGroups[customer][db][qtyField] = [obj];
                  }
                } else {
                  customerGroups[customer][db] = {
                    [qtyField]: [obj]
                  };
                }
              }
            });
          }
        }
      });
    }
  })

  let customerDupes = {};
  Object.keys(customerGroups).forEach(customer => {
    customerDupes[customer] = dupesCalc(customerGroups[customer]);
  });
  return customerDupes;
}

export const getSupplierCostDupes = (state) => {
  // filter dbs to only Items that have Cost enabled - Both, Outsourced
  const dboptions = state.gin.openedDBs.filter(db => {
    if (state.gin.item_is[db] === 'Both' || state.gin.item_is[db] === 'Outsourced') {
      return true;
    }
    return false;
  });
  let supplierGroups = {},
      smasterarray = state.gin.smasterarray;

  dboptions.forEach((db) => {
    if (smasterarray[db]) {
      Object.keys(smasterarray[db]).forEach(supplier => {
        supplierGroups[supplier] = {};
        if (smasterarray[db][supplier]) {
          if (smasterarray[db][supplier].constructor === Array) {
            smasterarray[db][supplier].forEach((cr,i) => {
              const qty = parseInt(cr[0]);
              const obj = {
                indx: i,
                qty,
                term: cr[1],
                cost: cr[2],
                currency: cr[5],
                start: cr[6],
                end: cr[7],
                itemCode: state.gin.itemCodes[db]
              }

              if (qty) {
                const qtyField = getRangeField(qty);
                if (supplierGroups[supplier].hasOwnProperty(db)) {
                  if (supplierGroups[supplier][db].hasOwnProperty(qtyField)) {
                    supplierGroups[supplier][db][qtyField].push(obj);
                  } else {
                    supplierGroups[supplier][db][qtyField] = [obj];
                  }
                } else {
                  supplierGroups[supplier][db] = {
                    [qtyField]: [obj]
                  };
                }
              }
            });
          }
        }
      });
    }
  })

  let supplierDupes = {};
  Object.keys(supplierGroups).forEach(supplier => {
    supplierDupes[supplier] = dupesCalc(supplierGroups[supplier]);
  });
  return supplierDupes;
}

export const checkOneYearEndDate = (start, end) => {
  const format = "MMM DD YYYY";
  const theStart = moment(start, format),
        theEnd = moment(end, format);
  const oneYearAfter = theStart.add(1, 'year');

  if (oneYearAfter.isBefore(theEnd)) {
    return false;
  }
  return true;
}

export const trimObjData = (data) => {
  console.log('about to trim data', data);
  let obj = cloneDeep(data);
  if (obj) {
    Object.keys(obj).forEach(field => {
      Object.keys(obj[field]).forEach(db => {
        if (obj[field][db]) {
          if (typeof obj[field][db] === 'string') {
            obj[field][db] = obj[field][db].trim();
          }
        }
      });
    });
  }
  return obj;
}

export const trimData = (data) => {
  console.log('about to trim data 2', data);
  let obj = cloneDeep(data);
  if (obj) {
    Object.keys(obj).forEach(db => {
      if (obj[db]) {
        if (typeof obj[db] === 'string') {
          obj[db] = obj[db].trim();
        }
      }
    });
  }
  return obj;
}

export const checkExFactoryAll = (data, db, customerName, defaultFactory, currencies, props) => {
  console.log('checkExFactoryAll', data, db, customerName, defaultFactory, currencies, props);
  const defaultFactoree = getFactory(db);
  let pass = true;
  // look for ex factory for this DB
  const exFactoryAll = {};
  currencies.forEach(curr => {
    exFactoryAll[curr] = JSON.parse(JSON.stringify(priceRanges));
    // gather 
    if (props.gterm) {
      const gterm = props.gterm[db];
      const gterms = props.gterms[db];
      const gcurr = props.gcur[db];
      const gcurrs = props.gcurrs[db];
      const gqtys = props.gqtys[db];
      const gprice = props.gprice[db];
      const gprices = props.gprices[db];
      if (gterm && gcurr) {
        if (gcurr === curr) {
          // check if factory
          if (defaultFactoree === gterm && gprice) {
            exFactoryAll[curr]['Base Price'] = [gprice];
          }
        }
      }
      if (gterms) {
        if (gterms.length > 0) {
          gterms.forEach((gg, i) => {
            if (gcurrs[i] === curr) {
              if (gg === defaultFactoree) {
                const key = priceRangesDict[gqtys[i]];
                if (key) {
                  if (exFactoryAll[curr][key]) {
                    exFactoryAll[curr][key].push(gprices[i]);
                  }
                }
              }
            }
          });
        }
      }
    }
  });
  console.log('exFactoryAll', exFactoryAll);
  currencies.forEach(curr => {
    Object.keys(data).forEach(range => {
      if (exFactoryAll[curr]) {
        if (exFactoryAll[curr][range]) {
          const theFactory = exFactoryAll[curr][range];
          if (theFactory.length > 0) {
            theFactory.sort();
            const lowestFactory = theFactory[0]; // lowest factory for all
            // check if there is any factory 
            if (lowestFactory) {
              let rangeCollection = [], exfactoryCollection = [];
              data[range].forEach(pr => {
                if (pr.db === db) {
                  if (pr.currency === curr && pr.price) {
                    if (pr.term === defaultFactory) {
                      exfactoryCollection.push(parseFloat(pr.price));
                    } else {
                      rangeCollection.push(parseFloat(pr.price));
                    }
                  }
                }
              })
              rangeCollection.sort();
              exfactoryCollection.sort();
              console.log('exfaccc 2', rangeCollection, exfactoryCollection, curr, range, defaultFactory);
              if (exfactoryCollection.length > 0 && rangeCollection.length > 0) {
                // if (exfactoryCollection[0] > rangeCollection[0]) {
                //   console.log('exfactoryCollection[0] > rangeCollection[0]');
                //     console.log('ex factory bigger than lowest in DB', exfactoryCollection[0], rangeCollection[0]);
                //     pass = false;
                // }
                if (exfactoryCollection[0] < lowestFactory) {
                  console.log('exfactoryCollection < lowestFactory');
                  pass = false;
                }
                if (rangeCollection[0] < lowestFactory) {
                  console.log('exfactoryCollection < lowestFactory');
                  pass = false;
                }
              }
            }
          }
          
        }
      }
      
    })

  })
  return pass;
}

export const checkExFactory = (data, defaultFactory) => {
  const { qty, price, currency, term, db, prices } = data;
  console.log('exfaccc 1', prices, defaultFactory, term, currency, data)
  if (((qty && price) && (term && db)) && currency) {
    let currentRange = getRangeField(qty);
    let rangeCollection = [], exfactoryCollection = [];
    if (prices[db]) {
      Object.keys(prices[db]).forEach(range => {
        if (range === currentRange) {
          prices[db][range].forEach(pr => {
            console.log('exfaccc 12', pr, currency);
            if ((pr.currency === currency) && pr.price) {
              if (pr.term === defaultFactory) {
                exfactoryCollection.push(parseFloat(pr.price));
              } else {
                rangeCollection.push(parseFloat(pr.price));
              }
            }
          })
        }
      })
    }

    rangeCollection.sort();
    exfactoryCollection.sort();
    console.log('exfaccc 2', rangeCollection, exfactoryCollection)
    if (term === defaultFactory) {
      if (rangeCollection.length > 0) {
        // compare with rangeCollection
        const cheapest = rangeCollection[0];
        console.log('exfaccc comparing 1', price, cheapest)
        if (price && cheapest) {
          if (price > cheapest) {
            return false;
          }
        }
      }
    } else {
      if (exfactoryCollection.length > 0) {
        // compare with exfactoryCollection
        const cheapest = exfactoryCollection[0];
        console.log('exfaccc comparing 2', price, cheapest)
        if (price && cheapest) {
          if (price < cheapest) {
            return false;
          }
        }
      }
    }
  }
  return true;
}

export const checkHighestAll = (data, db, customerName, currencies) => {
  let pass = true;
  currencies.forEach(curr => {
    let termCollection = {},
        basePriceCollection = {},
        allTerms = [];
    Object.keys(data).forEach(range => {
      if (range === 'Base Price') {
        data[range].forEach(pr => {
          if (pr.db === db && pr.customerName === customerName) {
            if (pr.price && pr.currency === curr) {
              const price = parseFloat(pr.price);
              if (allTerms.indexOf(pr.term) === -1) {
                allTerms.push(pr.term);
              }
              if (termCollection.hasOwnProperty(pr.term)) {
                basePriceCollection[pr.term].push(price);
              } else {
                basePriceCollection[pr.term] = [price];
              }
            }
          }
        })
      } else {
        data[range].forEach(pr => {
          if (pr.db === db && pr.customerName === customerName) {
            if (pr.price && pr.currency === curr) {
              const price = parseFloat(pr.price);
              if (allTerms.indexOf(pr.term) === -1) {
                allTerms.push(pr.term);
              }
              if (termCollection.hasOwnProperty(pr.term)) {
                termCollection[pr.term].push(price);
              } else {
                termCollection[pr.term] = [price];
              }
            }
          }
        })
      }
    })

    Object.keys(termCollection).forEach(term => {
      termCollection[term].sort();
    })
    Object.keys(basePriceCollection).forEach(term => {
      basePriceCollection[term].sort();
    })
    console.log('customer specific collection', termCollection, basePriceCollection, curr, allTerms);
    allTerms.forEach(term => {
      if (termCollection[term]) {
        if (termCollection[term].length > 0) {
          const highest = termCollection[term][termCollection[term].length - 1];
          console.log('customer specific collection HIGHEST', highest);
          if (basePriceCollection[term]) {
            if (basePriceCollection[term].length > 0 && highest) {
              const highestBase = basePriceCollection[term][basePriceCollection[term].length - 1];
              console.log('customer specific collection HIGHESTBASE', highestBase);
              if (highestBase && highest) {
                if (highestBase < highest) {
                  pass = false;
                }
              }
            }
          }
        }
      }
    })
  })

  return pass;
}

export const checkHighestPrice = (data) => {
  const { qty, price, currency, term, db, prices } = data;
  console.log('termCollection 1', prices)
  if (((qty && price) && (term && db)) && currency) {
    let termCollection = [],
        basePriceCollection = [];
    if (prices[db]) {
      Object.keys(prices[db]).forEach(range => {
        prices[db][range].forEach(pr => {
          if (pr.term === term && pr.currency === currency) {
            if (range === 'Base Price') {
              if (pr.price) {
                basePriceCollection.push(parseFloat(pr.price));
              }
            } else {
              if (pr.price) {
                termCollection.push(parseFloat(pr.price));
              }
            }
          }
        })
      })
    }

    console.log('termCollection', termCollection, basePriceCollection, price, qty);
    if (qty == 1) {
      // compare this price to highest termCollection
      termCollection.sort();
      if (termCollection.length > 0) {
        const highest = termCollection[termCollection.length - 1];
        console.log('termCollection length 1', termCollection.length, highest)
        if (highest) {
          if (parseFloat(price) < highest) {
            return false;
          }
        }
      }
    } else {
      // compare to basePriceCollection
      basePriceCollection.sort();
      if (basePriceCollection.length > 0) {
        const highest = basePriceCollection[basePriceCollection.length - 1];
        console.log('termCollection length 2', termCollection.length, highest)
        if (highest) {
          if (parseFloat(price) > highest) {
            return false;
          }
        }
      }
    }
  }
  return true;
}

export const getTargetCostDupes = (state) => {
  const gcur = state.gin.ccur,
        gstart = state.gin.cstart,
        gend = state.gin.cend,
        gprice = state.gin.cprice,
        gterm = state.gin.cterm,
        gcurrs = state.gin.ccurs,
        gstarts = state.gin.cstarts,
        gends = state.gin.cends,
        gprices = state.gin.cprices,
        gqtys = state.gin.cqtys,
        gterms = state.gin.cterms,
        itemCodes = state.gin.itemCodes,
        openedDBs = state.gin.openedDBs;

  let groupedDBMultiQuantities = {}; // keys are DBs
  Object.keys(gqtys).forEach((db) => {
    if (openedDBs.indexOf(db) !== -1) {
      const groupObj = {};
      if (gqtys[db]) {
        gqtys[db].forEach((qty, index) => {
          console.log('quantity is ',qty)
          let name = '', rangeName = '';
          ranges.forEach((re) => {
            if (qty < 10000000) {
              if ((re.rangeA <= qty) && (qty <= re.rangeB)) {
                name = re.name;
                rangeName = `${numberWithCommas(re.rangeA)} - ${numberWithCommas(re.rangeB)}`;
              }
            } else {
              name = '10M+';
              rangeName = '10M+'
            }
          });
          let total = gprices[db][index] ? (qty * parseFloat(gprices[db][index])) : 0;
          const finObj = {
            indx: index,
            qty: gqtys[db][index],
            currency: gcurrs[db][index],
            price: gprices[db][index],
            start: gstarts[db][index],
            end: gends[db][index],
            term: gterms[db][index],
            itemCode: itemCodes[db],
            total,
            rangeName
          };
          if (groupObj.hasOwnProperty(name)) {
            groupObj[name].push(finObj);
          } else {
            groupObj[name] = [finObj];
          }
        });
      }
      groupedDBMultiQuantities[db] = groupObj;
    }
  });

  const dbsArr = Object.keys(gcur);
  const pricings = dbsArr.forEach((db) => {
    if (openedDBs.indexOf(db) !== -1) {
      if (gcur[db]) {
        let total = gprice[db] ? parseFloat(gprice[db]) : 0;
        const finObj2 = {
          indx: -1,
          qty: 1,
          currency: gcur[db],
          price: gprice[db],
          start: gstart[db],
          end: gend[db],
          term: gterm[db],
          itemCode: itemCodes[db],
          total,
        }
        console.log('finObj2',finObj2);
        if (groupedDBMultiQuantities[db].hasOwnProperty('Base Price')) {
          groupedDBMultiQuantities[db]['Base Price'].push(finObj2);
        } else {
          groupedDBMultiQuantities[db]['Base Price'] = [finObj2];
        }
      }
    }
  });

  let currencies = [];
  Object.keys(gcur).forEach((curr) => {
    if (currencies.indexOf(gcur[curr]) === -1) {
      currencies.push(gcur[curr]);
    }
  });
  return groupedDBMultiQuantities;
}

export const checkIfRBOHasWalmart = (rboOptions, value) => {
  let hasWalmart = false;
  if (!value) return false;
  if (value === 'n/a') return false;
  if (rboOptions) {
    for (let i = 0; i < rboOptions.length; i++) {
      const rboo = rboOptions[i];
      if (rboo.id === value) {
        if (rboo.name) {
          if (rboo.name.indexOf('WALMART') !== -1) {
            hasWalmart = true;
          }
        }
        continue;
      }
    }
  }
  return hasWalmart;
}
