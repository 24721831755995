import React, { useEffect, useState } from 'react';
import Button from "components/CustomButtons/Button.jsx";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Table from "components/Table/TableSmall.jsx";
import { FadeLoader } from 'react-spinners';
import { findTitle, findData, options } from "containers/Translations/TranslationsObj";

const reformat = (str) => {
  if (str) {
    const str_arr = str.split('_');
    return options.categories_obj[str_arr[2]] + ' - ' + str_arr[3];
  }
  return str;
}

const checkForDuplicates = (existing_array, str) => {
  let has_duplicate = false;
  for (let i = 0; i < existing_array.length; i++) {
    if (existing_array[i] === str) {
      has_duplicate = true;
      break;
    }
  }  
  return has_duplicate;
}

const TranslationsModal = (props) => {
  console.log('TranslationsModal props', props);
  const tableName = props.single_data ? findTitle(props.single_data) : null;
  const title = props.single_data ? reformat(tableName) : null;
  const [tableData, setTableData] = useState(null);
  const [showSuggestion, setShowSuggestion] = useState([]);
  const [msg, setMsg] = useState('');
  const title_arr = tableName ? tableName.split('_') : [];
  const category = title_arr[2] ? title_arr[2] : null;
  const type = title_arr[3] ? title_arr[3] : null;
  const action_type = props.action ? props.action.type : null;

  const handleTextInputChange = (lang, val) => {
    const copy = JSON.parse(JSON.stringify(tableData));
    copy[lang] = val;
    setTableData(copy);
  }

  const handleSelectRBO = (e) => {
    const action = props.action ? props.action.type : null;
    let duplicate = false;
    console.log('select rbo',e, action, props.existing_keys);
    if (action === 'CREATE') {
      const existing_keys = props.existing_keys ? props.existing_keys : [];
      for (let i = 0; i < existing_keys.length; i++) {
        console.log(existing_keys[i].RBO);
        if (existing_keys[i]) {
          if (existing_keys[i].RBO === e) {
            duplicate = true;
            setMsg('Error, duplicate found');
            break;
          }
        }
      }
    }
    if (!duplicate) {
      const data = JSON.parse(JSON.stringify(tableData));
      data.RBO = e;
      setTableData(data);
    }
  }

  const formatSuggestion = (sdata, display) => {
    let elems = [];
    if (sdata) {
      sdata.forEach(sd => {
        elems.push(<div className="wrapp" style={display}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <span className="gray-text">{sd.term}</span>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <span className="black-text">{sd.value}</span>
            </GridItem>
          </GridContainer>
        </div>)
      })
    }
    return elems;
  }

  const action = props.action ? props.action.type : null;

  const formatData = (data, update_permitted) => {
    let tableDat = [];
    const missingLanguages = {};
    if (props.missingLanguages) {
      props.missingLanguages.forEach(lang => {
        missingLanguages[lang] = 1;
      })
    }
    if (data) {
        Object.keys(data).forEach((lang_name, i) => {
          const is_edit_mode = action_type ? action_type !== 'CREATE' ? true : false : false;
          const actionElem = (lang_name === 'RBO' && props.rbo_data) ?
          <span className={is_edit_mode ? 'unclickable' : ''}>
            <CustomSelect
              label="Select RBO"
              options={props.rbo_data}
              choose={handleSelectRBO}
              name="RBO"
              indx="FirmCode"
              desc="FirmName"
              default={data[lang_name]}
              error={!data[lang_name] ? 'Required' : null}
            />
          </span>
          :
          <span>
            <CustomInput
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: (e) => {
                  console.log('e',e.target.value)
                  if (update_permitted) {
                    if (action_type !== 'CREATE') {
                      if (lang_name == 'ENGLISH') {
                        return;
                      }
                    }
                    handleTextInputChange(lang_name, e.target.value);
                  }
                },
                value: data[lang_name] ? data[lang_name] : '',
                autoFocus: i === 0 ? true : false,
                role: 'presentation',
                autoComplete: 'nope',
                disabled: (action_type !== 'CREATE' && lang_name == 'ENGLISH') ? true : false
              }}
              error={lang_name === 'ENGLISH' ? !data[lang_name] ? true : false : false}
            />
          </span>;

          const lang_suggestions = 
          props.suggestions ? 
                            props.suggestions.hasOwnProperty(lang_name) ?
                                                                        formatSuggestion(props.suggestions[lang_name],
                                                                          showSuggestion.indexOf(lang_name) !== -1 ? {} : {display:'none'})
                                                                        :
                                                                        []
                            :
                            [];
          tableDat.push([
          <GridContainer>
            <GridItem xs={12} sm={2} md={2}>
              <div className="margin-topped"><strong>{i+1}. {lang_name}</strong></div>
            </GridItem>
            <GridItem xs={12} sm={10} md={10}>
              {actionElem}<br />
              {lang_suggestions.length > 0 && <div
                style={{marginTop:10,marginBottom:10,borderBottom:'1px solid #dadada',display:'inline-block'}}
                className="hoverable blue-text" 
                onClick={() => {
                  if (showSuggestion.indexOf(lang_name) !== -1) {
                    return setShowSuggestion(showSuggestion.filter(s => {
                      if (s === lang_name) return false;
                      return true;
                    }));
                  }
                  setShowSuggestion([...showSuggestion, lang_name]);
                }}
              ><strong>
                {showSuggestion.indexOf(lang_name) !== -1 ? `Hide ${lang_name} Suggestions` : `Show ${lang_name} Suggestions`}  
                </strong></div>}
              {lang_suggestions.map((ls,i) => {
                return <div key={i}>
                  {ls}
                </div>
              })}
              {missingLanguages[lang_name] && <div style={{marginTop:10}} class="gray-text">
                  0 suggested translations found
                </div>}
            </GridItem>
          </GridContainer>]);
        })
    }
    return tableDat;
  }

  useEffect(() => {
    if (props.single_data) {
      const dat = findData(props.single_data);
      setTableData(dat ? dat[0] : null);
    } else {
      setTableData(null);
    }
  }, [props.single_data]);

  // all primary keys need to be filled up
  // at least one language translation should be present
  let passed = false;
  let rbo_required = false;
  if (tableData) {
    let primary_key_count = 0;
    let languages_count = 0;
    let hasEnglish = false;
    Object.keys(tableData).forEach(lang => {
      const val = tableData[lang];
      const trimmed_val = val ? val.trim() : val;
      if (lang === 'ENGLISH' && trimmed_val) {
        hasEnglish = true;
      }
      if (lang === 'RBO' && type !== 'BASE') {
        if (trimmed_val) primary_key_count++;
      } else if (lang === 'ENGLISH' && type === 'BASE') {
        if (trimmed_val) primary_key_count++;
      }
      if (lang !== 'RBO') {
        if (trimmed_val) languages_count++;
      }
      if (lang === 'RBO') {
        if (!trimmed_val) rbo_required = true;
      }
    });
    console.log('primary count', primary_key_count);
    console.log('languages count', languages_count);
    if (primary_key_count > 0 && languages_count > 1) {
      if (hasEnglish) {
        passed = true;
      }
    }
  }

  const action_msg = props.action_msg ? 
              props.action_msg.indexOf('Successfully') !== -1 ?
                    <div style={{color:'green'}}>{props.action_msg}</div>
                    :
                    <div style={{color:'red'}}>{props.action_msg}</div>
              :
              null; 

  const tableDataView = tableData ? formatData(tableData, props.update_permitted) : [];

  console.log('TableData', tableData);

  let missing_languages = [];
  if (tableData) {
    Object.keys(tableData).forEach(lang => {
      const td = tableData[lang];
      const td_trimmed = td ? td.trim() : null;
      if (!td_trimmed) {
        missing_languages.push(lang);
      }
    });
  }
  const dialogStyle = {height:600,overflowY:'scroll'};
  return (
    <div className="makeHeightFull">
      <Dialog
        onClose={props.close}
        open={props.open}
        keepMounted
        maxWidth="lg"
        fullWidth={true}
        fullScreen={true}
      >
        <DialogTitle style={{paddingBottom: 0}} onClose={() => props.close()}>
          <IconButton edge="end" color="inherit" onClick={props.close} aria-label="Close" style={{float:'right'}}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent style={{overflowY:'unset'}}>
          <GridContainer>
            <GridItem sm={12} md={12}>
              {action === 'VIEW_ONLY' && <h3 className="bold text-center">TRANSLATIONS VIEW</h3>}

              {action !== 'VIEW_ONLY' && <h3 className="bold text-center">TRANSLATIONS {props.update_permitted ? '' : 'VIEW'}</h3>}
              <p className="text-center">{title}</p>
              {missing_languages.length > 0 && <div className="text-center">
                  {missing_languages.length} language(s) w/ no exact translations <br />
                  {missing_languages.map((ml,i) => {
                    return <span key={ml} className="bold">{ml}{i !== missing_languages.length - 1 ? ', ' : ''}</span>;
                  })}
                </div>}
            </GridItem>
            <GridItem sm={12} md={12}>
              {tableDataView.length > 0 &&
            <div style={{}}>
              <Table
                tableHeaderColor="primary"
                tableHead={["LANGUAGE"]}
                tableData={tableDataView}
              /></div>}
              {(!passed && tableDataView.length > 0) && <div
                className="text-center"
                style={{color:'red',marginTop:20,marginBottom:20}}
              >English translation and 1 other language translation is required. 
              {rbo_required ? 'Please select an RBO.' : ''}</div>}
              {action === 'VIEW_ONLY' && <div style={{marginTop:20}} className="text-center">
                    <Button
                      color="rose"
                      onClick={() => {
                        props.close();
                      }}
                    >CLOSE
                    </Button>
                </div>}
              {(tableDataView.length > 0 && action === 'CREATE') && <div style={{marginTop:20}} className="text-center">
                <Button
                  color="success"
                  onClick={() => {
                    // const primary_key = type === 'BASE' ? tableData['ENGLISH'] : tableData['RBO'];
                    // if (primary_key) {
                    //   const has_duplicate = checkForDuplicates(props.existing_keys, primary_key);
                    //   if (!has_duplicate) {
                        props.createTranslation({
                          category,
                          type,
                          data: tableData
                        });
                    //   }
                    // }
                  }}
                  style={{marginRight:20}}
                  disabled={passed ? false : true}
                >ADD
                </Button>
                <Button
                  color="rose"
                  onClick={() => {
                    props.close();
                  }}
                >CLOSE
                </Button>
              </div>}
              {(tableDataView.length > 0 && (action !== 'CREATE' && action !== 'VIEW_ONLY')) && <div style={{marginTop:20}} className="text-center">
                {props.update_permitted && <Button
                  color="warning"
                  onClick={() => {
                    props.update({
                      category,
                      type,
                      id: type === 'BASE' ? tableData['ENGLISH'] : tableData['RBO'], 
                      data:tableData
                    });
                  }}
                  style={{marginRight:20}}
                  disabled={passed ? false : true}    
                >UPDATE
                </Button>}
                {props.update_permitted && <Button
                  color="danger"
                  onClick={() => {
                    props.delete({
                      category,
                      type,
                      id: type === 'BASE' ? tableData.ENGLISH : tableData.RBO,
                      _english: tableData.ENGLISH
                    });
                  }}
                  style={{marginRight:20}}
                >DELETE
                </Button>}
                <Button
                  color="rose"
                  onClick={() => {
                    props.close();
                  }}
                >CLOSE
                </Button>
              </div>}
            {tableDataView.length === 0 && <div className="center-loader">
              <FadeLoader />
            </div>}
            {(tableDataView.length !== 0 && props.action_msg) && <div className="text-center mt-20 mb-20">{action_msg}</div>}
            {(tableDataView.length !== 0 && msg) && <div className="mt-20 mb-20 text-center">{msg}</div>}
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    </div>
  )
};

export default TranslationsModal;
