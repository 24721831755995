import React from "react";
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import ReactDOM from "react-dom";
import checkPermissions from '../../shared/checkPermissions';
import styles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import combineStyles from "shared/combineStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CustomInput from "components/CustomInput/CustomInput.jsx";
// @material-ui/icons
import Check from '@material-ui/icons/Check';
import AwesomeDebouncePromise from 'awesome-debounce-promise';

import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GINbox from "containers/GIN/GINbox";
import GINmodal from "containers/GIN/GINmodal";
import GINSource from "containers/GIN/GINSource";
import GINautocomplete from "containers/GIN/GINautocomplete";
import GINtable from "containers/GIN/GINtable";
import GINtable2 from "containers/GIN/GINtable2";
import GINpickValues from "containers/GIN/GINpickValues";
import GINbom from "containers/GIN/GINbom";
import GINcost from "containers/GIN/GINcost";
import GINimage from "containers/GIN/GINimage";
import GINreview from "containers/GIN/GINreview";
import GINsubmit from "containers/GIN/GINsubmit";
import GINalert from "containers/GIN/GINalert";
import Button from "../../components/CustomButtons/Button.jsx";
import GINpriceMatrix from "containers/GIN/GINpriceMatrix";
import GINask2 from "containers/GIN/GINask2";
import GINautosaveModal from "containers/GIN/GINautosaveModal";
import GINEditModal from "containers/GIN/GINedit-modal";
import getCroppedImg2 from 'containers/GIN/croppedImage';
import { requirePicksDB } from "containers/GIN/GINhelpers";
import SweetAlert from "react-bootstrap-sweetalert";
import { PulseLoader } from 'react-spinners';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

import dbList from "shared/activeDBs";
import devStatus from "shared/devStatus";
import { checkVariantExistence, fieldList, fieldMainTab, checkCode } from 'containers/GIN/GINhelpers';
import emailTemplate from "./GINemailTemplate";

import _ from 'lodash';
import CustomSelect from "../../components/CustomSelect/CustomSelect";

const log = (a, b, c, d, e) => {
  [a,b,c,d,e].forEach((s, i, x) => {
    if (!s) x = '';
  });
  console.log(a, b, c, d, e);
}

const navigation = ['Item Select','Source','Pick Values', 'Image', 'Pricing', 'Costing', 'BOM', 'Submit'];

const dbListObj = {};
dbList.forEach((d) => {
  dbListObj[d.name] = d.color;
})

const initialStateC = {
  loading: false,
  modalOpen: false,
  currentDB: null,
  alerted: false,
  alert: '',
  missing: [],
  ginnum: '',
  message: '',
  askDeny: false,
  askImage: false,
  askTPP: false,
  askUnlock: false,
  fromNextTab: false,

  currentTab: 'Item Select',
  activeMainTab: [''],
  showPricing: false,
  ask: false,
  showImageCropper: false,
  showAutoSaveModal: false,
  showEditModal: false,
}

class GINMain extends React.Component {
  constructor(props) {
    super(props);

    this.state = initialStateC;

    this.child = React.createRef();
    this.child2 = React.createRef();
    this.searchAPIDebounced = _.debounce(this.searchAPIDebounced, 500);
  }

  setNotExistent = () => {
    console.log('file does not exist', this.props.existingImageURL);
    if (this.props.existingImageURL) {
      if (this.props.existingImageURL.indexOf('.jpg') === -1) {
        this.props.loadExistingGINImage(`https://portal.natcoglobal.com/portal/api/item/ftp_gin_image.php?gin=${this.props.ginNum}`);

      }
    }
  }

  onLoad = () => {
    console.log('image file exists');
    this.props.setFinalCropped(this.props.existingImageURL);
  }

  openGINEdit = () => {
    this.setState({showEditModal:true})
  }

  closeGINEdit = () => {
    this.setState({showEditModal:false}, () => {
      if (!this.state.ginnum) this.props.closeView()
    })
  }

  setFromNextTab = (bool) => {
    this.setState({fromNextTab:bool});
  }

  searchAPIDebounced = (val) => {
    this.props.ginCheckExists(val);
  }

  setGINNumEdit = (ginnum) => {
    this.setState({ginnum});
    this.props.setGINNum(ginnum);
  }

  onGINnumChange = e => {
    let val = e.target.value;
    if (val || val === 0) {
      val = val.replace(/[^0-9a-zA-Z-\\\/]/g, "").toUpperCase();
    }
    if (val || val === 0) {
      if (val.length < 101) {
       this.setState({
         ginnum: val
       });
       this.props.setGINNum(val);
       this.searchAPIDebounced(val);
      }
    } else {
      this.setState({
        ginnum: ''
      });
      this.props.ginSetFinalCropNull();
      this.props.setGINNum('');
    }
  }

  openAutoSave = () => {
    this.setState({
      showAutoSaveModal: true,
      showEditModal: false
    });
  }

  closeAutoSave = () => {
    this.setState({
      showAutoSaveModal: false
    });
  }

  openAskUnlock = () => {
    this.setState({
      askUnlock: true
    });
  }

  closeAskUnlock = () => {
    this.setState({
      askUnlock: false
    });
  }

  openAsk = () => {
    this.setState({
      ask: true
    });
  }

  closeAsk = () => {
    this.setState({
      ask: false
    });
  }

  openAskTPP = () => {
    this.setState({
      askTPP: true
    })
  }

  closeAskTPP = () => {
    this.setState({
      askTPP: false
    })
  }

  openAskDeny = () => {
    this.setState({ askDeny: true })
  }

  closeAskDeny = () => {
    this.setState({ askDeny: false })
  }

  gatherEmailData = () => {
    const firstDB = this.props.openedDBs[0];
    console.log('got value', this.getValue('rbo', firstDB), firstDB, this.getValue('brand'), this.getValue('subgroup'));
    let emailData = {
      ginNum: this.props.ginNum,
      gmp: this.props.gmpCheckbox ? 'YES' : 'NO',
      base_item_description: this.getValue('base_item_description'), // base item description
      product_type: this.getValue('product_type'), // product type
      rboName: this.props.rboDict[firstDB][this.getValue('rbo', firstDB)], // RBO code
      brandName: this.getValue('brand') ? this.props.brandsDict[this.getValue('brand')] : 'n/a', // brand id
      subgroupName: this.getValue('subgroup') ? this.props.subgroupsDict[this.getValue('subgroup')] : 'n/a', // subgroup id
      itemCodes: this.props.itemCodes,
      ginType: this.props.ginType
    }
    return emailData;
  }

  getValue = (field, realDB) => {
    const { picked } = this.props;
    if (picked[field]) {
      if (field !== 'rbo') {
        const db = picked[field],
              value = this.props[field][db];
        if (value && value !== 'n/a') {
          return value;
        } else {
          return '';
        }
      } else {
        // look up rbo value
        const db = picked.rbo,
              value = this.props.rbo[db];
        if (value && this.props.rboDict[db]) {
          const chosenRBOname = this.props.rboDict[db][value];
          // get RBO FirmCode based on RBO name from chosen value;
          // realDB = the db you are copying to
          //
          console.log('rbo search is this',realDB, db, value, this.props.rboReverseDict[realDB]);
          console.log('chosen RBO name for', realDB, chosenRBOname);
          const realRBOcode = this.props.rboReverseDict[realDB][chosenRBOname];
          if (realRBOcode) {
            return realRBOcode;
          } else {
            // quick fix return random RBO
            const first = Object.keys(this.props.rboReverseDict[realDB])[5];
            const randomRBOcode = this.props.rboReverseDict[realDB][first];
            console.log('rbo chosen random is', randomRBOcode, first);
            return randomRBOcode;
          }
        } else {
          // quick fix return random RBO
          const first = Object.keys(this.props.rboReverseDict[realDB])[5];
          const randomRBOcode = this.props.rboReverseDict[realDB][first];
          console.log('rbo chosen random is', randomRBOcode, first);
          return randomRBOcode;
        }
      }
    }
  }

  selectTab = (tabName, fromNextTab) => {
    if (this.props.ginNum) {
      this.props.gatherAutoSaveData();
      setTimeout(() => {
        console.log('autosave ID', this.props.currentAutoSaveID);
        this.props.autoSave(this.props.saveData, this.props.currentAutoSaveID, this.props.ginNum);
      }, 1000);
    }
    const check = this.checkRequirements();
    console.log('checking2', check, this.props.loading)
    if (check && !this.props.loading) {
      if (!this.props.ginNum) {
        this.setState({
          alerted: true,
          missing: ['Please enter a GIN number before proceeding.']
        });
      } else {

        // check if Product Type - Machine Parts is existing on any DB Item
        const excludedProductTypes = ['Machine Parts'];
        let foundExcludedProductTypes = false,
            excludedProductTypesList = '',
            excludedProductTypesText = '';
        this.props.openedDBs.forEach((db) => {
          if (this.props.product_type[db]) {
            if (excludedProductTypes.indexOf(this.props.product_type[db]) !== -1) {
              foundExcludedProductTypes = true;
              excludedProductTypesText += `${db} `;
            }
          }
        })
        if (foundExcludedProductTypes) {
          excludedProductTypes.forEach((e) => {
            excludedProductTypesList += `${e} `;
          })
          this.openAlert(`These DBs (${excludedProductTypesText}) have one or more of these invalid product types (${excludedProductTypesList}). Please change them.`);
          return;
        }

        // check if at least one product type exists
        let foundOneProductType = false;
        this.props.openedDBs.forEach((db) => {
          if (this.props.product_type[db]) {
            if (this.props.product_type[db] !== 'n/a') {
              foundOneProductType = true;
            }
          }
        })
        if (!foundOneProductType) {
          this.openAlert(`At least one item with a product type is required.`);
          return;
        }

        if (this.props.ginType !== 'EXISTING') {
          this.setState({
            currentTab: tabName,
            fromNextTab: fromNextTab ? true : false
          });
        } else {
          // check if all items have been loaded if EXISTING
          let missingLoadedItems = [];
          this.props.openedDBs.forEach((db) => {
            const baseItemCheck = this.props.base_item_description[db];
            if (!baseItemCheck) {
              missingLoadedItems.push(db);
            }
          });
          if (missingLoadedItems.length === 0) {
            this.setState({
              currentTab: tabName,
              fromNextTab: fromNextTab ? true : false
            });
          } else {
            let dblistText = '';
            missingLoadedItems.forEach((db) => dblistText += `${db} `);
            this.openAlert(`Missing loaded items from (${dblistText}) DBs, the system will try again.
                    If the issue persists please call tech support`);
            // retry
            let missingItemsData = [];
            this.props.chosenGINDetails.forEach((dat) => {
              const arr = dat.split('[.]'),
                    db = arr[0];
              if (missingLoadedItems.indexOf(db) !== -1) {
                missingItemsData.push(dat);
              }
            })
            this.props.loadExisting(missingItemsData);
          }
        }
      }
    }
  }

  handleToggle = () => {
    this.setState({
      gmpCheck: !this.state.gmpCheck
    });
  }

  openModal = (db) => {
    this.setState({
      openModal: true,
      currentDB: db
    })
  }

  closeModal = () => {
    this.setState({
      openModal: false
    })
  }

  scrollTop = () => {
    ReactDOM.findDOMNode(this).scrollIntoView();
  }

  checkRequirements = () => {
    //ReactDOM.findDOMNode(this).scrollIntoView();
    let passed = true;
    if (this.props.openedDBs.length > 1) {
      passed = true;
    } else {
      passed = false;
    }
    return passed;
  }

  backReview = () => {
    this.setState({
      showPricingMatrix: false
    });
  }

  hideAlert = () => {
    this.setState({
      alerted: false
    });
  }

  handleReviewValue = (e) => {
    this.setState({
      gin: e.target.value
    })
  }

  checkPricingRequirements = () => {
    let requirementsMet = false;
    this.props.openedDBs.forEach(db => {
      if (this.props.gprice[db]) {
        if (!isNaN(this.props.gprice[db])) {
            requirementsMet = true; // at least 1 general pricing exists
        }
      }
    });
    return requirementsMet;
  }

  checkBOMRequirements = (db) => {
    let requirementsMet = true;
    if (this.props.rms[db]) {
      if (this.props.rms[db].length === 0) {
        requirementsMet = false;
      }
    } else {
      requirementsMet = false;
    }
    return requirementsMet;
  }

  checkCostingRequirements = (db) => {
    let requirementsMet = true;
    if (this.props.itemIs[db] === 'Both' || this.props.itemIs[db] === 'Outsourced') {
      if (this.props.smasterarray[db]) { // no general pricing
        if (Object.keys(this.props.smasterarray[db]).length === 0) {
          requirementsMet = false;
        } else {
          Object.keys(this.props.smasterarray[db]).forEach(supp => {
            const arr = this.props.smasterarray[db][supp];
            let costs = 0;
            arr.forEach(cost => {
              if (parseInt(cost[0]) !== 0) {
                costs++;
              }
            });
            if (costs === 0) {
              requirementsMet = false;
            }
          });
        }
      } else {
        requirementsMet = false;
      }
    }
    return requirementsMet;
  }

  checkCostingRequirementsAll = () => {
    let requirementsMet = true;
    this.props.openedDBs.forEach(db => {
      if (this.props.itemIs[db] === 'Both' || this.props.itemIs[db] === 'Outsourced') {
        if (this.props.smasterarray[db]) { // no general pricing
          if (Object.keys(this.props.smasterarray[db]).length === 0) {
            requirementsMet = false;
          } else {
            Object.keys(this.props.smasterarray[db]).forEach(supp => {
              const arr = this.props.smasterarray[db][supp];
              let costs = 0;
              arr.forEach(cost => {
                if (parseInt(cost[0]) !== 0) {
                  costs++;
                }
              });
              if (costs === 0) {
                requirementsMet = false;
              }
            });
          }
        } else {
          requirementsMet = false;
        }
      }
    });
    return requirementsMet;
  }

  checkTargetCost = () => {
    console.log('checking 1..');
    let requirementsMet = true;
    this.props.openedDBs.forEach(db => {
      if (this.props.itemIs[db] === 'Both' || this.props.itemIs[db] === 'Outsourced') {
        if (this.props.cprices[db]) {
          if (this.props.cprices[db].length > 0) {
            // check if base cost is present
            ['cprice',
            'cterm',
            'ccur',
            'cstart',
            'cend'].forEach(field => {
              console.log('checking 1..', this.props[field][db]);
              if (!this.props[field][db]) {
                requirementsMet = false;
              }
            })

            // check if all multiples are present
            this.props.cprices[db].forEach((cp,i) => {
              if (!cp) {
                requirementsMet = false;
              }
              ['cqtys','cterms','ccurs','cprices','cstarts','cends'].forEach(field => {
                if (this.props[field][db]) {
                  console.log('checking 1.....', this.props[field][db][i]);
                  if (!this.props[field][db][i]) {
                    requirementsMet = false;
                  }
                } else {
                  requirementsMet = false;
                }
              })
            })
          }
        }
      }
    });
    return requirementsMet;
  }

  openAlert = (msg) => {
    this.setState({
      alert: msg
    });
  }

  closeAlert = () => {
    this.setState({
      alert: ''
    });
  }

  closeLoading = () => {
    this.setState({
      loading: false
    });
  }

  loadImage = async (filename) => {
    this.props.ginLoadImage(filename);
  }

  resetGINNum = () => {
    this.setState(initialStateC);
  }

  openAskImage = (message) => {
    this.setState({
      askImage: true
    })
  }

  closeAskImage = () => {
    this.setState({
      askImage: false
    })
  }

  onCropImage = () => {
    //this.child.current.showCroppedImage();
    this.clickChild();
  };

  approve = () => {
    this.clickChild2();
  }

  setGIN = (ginnum) => {
    this.setState({ ginnum });
  }

  moveNavTab = (move, errorData, isFromNextTab) => {
    const allDBOptions = Object.keys(this.props.options);
    if (this.props.gmpCheckbox) {
      if (!this.props.tpp_item_type) {
        this.openAlert(`Please select a TPP Item Type`);
        return;
      }
    }
    // check if all itemcodes are entered
    if (this.props.itemCodes) {
      const itemdbs = Object.keys(this.props.itemCodes);
      if (itemdbs.length !== dbList.length) {
        this.openAlert(`Please enter an Item Code in each DB to continue`);
        return;
      }
    }
    if (allDBOptions.length !== dbList.length) {
      let dblistText = '';
      dbList.forEach((db) => {
        if (allDBOptions.indexOf(db.name) === -1) {
          this.props.fetchOptions(db.name);
          dblistText += `${db.name} `;
        }
      })
      this.openAlert(`Still loading DB (${dblistText}) options... Please try again.`);
      return;
    }
    const currentTab = this.state.currentTab;
    let targetTab = null;
    const pos = navigation.indexOf(currentTab);
    if (move === 'back' && currentTab !== 'Item Select') {
      targetTab = navigation[pos-1];
      if (targetTab === 'Costing' && !errorData.costingCondition) {
        targetTab = 'Pricing';
      }
      if (targetTab === 'BOM' && !errorData.bomCondition) {
        if (errorData.showCosting) {
          targetTab = 'Costing';
        } else {
          targetTab = 'Pricing';
        }
      }
    } else if (move === 'next' && currentTab !== 'Submit') {
      targetTab = navigation[pos+1];
      if (targetTab === 'Pricing' && (!this.props.ginFinalCrop && (this.props.ginImage && this.props.ginCrop
      ))) {
        console.log('Image cropping ?');
        this.openAskImage();
        return;
      }
      if (targetTab === 'Pricing' && (!this.props.ginFinalCrop && !this.props.ginImage)) {
        this.openAlert('An image with 1000px x 1000px dimensions is required. You may upload and edit/crop an image to fit this size.');
        return;
      }
      if (targetTab === 'Pricing' && (!this.props.ginFinalCrop && (this.props.ginImage && !this.props.ginCrop))) {
        this.openAlert('An image with 1000px x 1000px dimensions is required. Please click Edit and Crop Image to fit this size.');
        return;
      }
      console.log('comparing DBs', this.props.openedDBs, dbList);
      if ((this.props.openedDBs.length !== dbList.length) && devStatus.indexOf('live') !== -1) {
        this.openAlert('All DBs are required.');
        return;
      }
      if (targetTab === 'Costing' && !errorData.costingCondition) {
        targetTab = 'BOM';
      }
      if (targetTab === 'BOM' && !errorData.bomCondition) {
        targetTab = 'Submit';
      }
    }

    if (this.props.exists) {
      this.openAlert('The GIN already exists, please change it. ');
      return;
    }

    if (errorData[targetTab]) {
      this.openAlert(errorData[targetTab]);
    } else if (targetTab) {
      if (targetTab === 'Submit') {
        if (errorData.tabRequirements.length > 0) {
          const tabRequirements = errorData.tabRequirements;
          let msgMin = '';
          if (tabRequirements.indexOf('BOM') !== -1) {
            msgMin += 'BOM (Minimum 1 Raw Material). ';
          }
          if (tabRequirements.indexOf('Costing') !== -1) {
            msgMin += 'Costing (At least 1 Supplier Cost needed). ';
          }
          if (tabRequirements.indexOf('Pricing') !== -1) {
            msgMin += 'Pricing (At least 1 General Pricing needed). ';
          }
          if (!this.props.ginFinalCrop) {
            msgMin += 'Image Upload (An image with 1000px x 1000px dimensions is required. You may upload and edit/crop an image to fit this size.) ';
          }
          this.openAlert(`${msgMin}`);
        } else {
          const checkTargetCost = this.checkTargetCost();
          if (!checkTargetCost) {
            this.openAlert(`Target Cost is missing requirements, please check for Base Cost and completeness. `)
          } else {
            this.selectTab('Submit');
          }
        }
      } else {
        if (targetTab === 'Pick Values') {
          this.selectTab(targetTab, isFromNextTab);
          this.props.ginSetDonePickValues(true);
        } else {
          this.selectTab(targetTab);
        }
      }
    }
  }

  onBack = (errorData) => {
    console.log('back', errorData);
    this.moveNavTab('back', errorData);
  }

  onNext = (errorData) => {
    console.log('next', errorData);
    this.moveNavTab('next', errorData, 'isFromNextTab');
  }

  setShowImageCropper = () => {
    this.setState({ showImageCropper: false });
  }

  openMessage = (message) => {
    this.setState({ message });
  }

  closeMessage = () => {
    this.setState({ message: '' });
  }

  unlockItems = () => {
    this.openAskUnlock();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loading !== this.props.loading) {
      this.setState({ loading: this.props.loading });
    }
    if (prevProps.existingLoadFinal !== this.props.existingLoadFinal) {
      console.log('not the same 1');
      if (prevProps.existingLoadFinal === true && this.props.existingLoadFinal === false) {
        console.log('not the same 2');
        this.setState({ currentTab: 'Pick Values'}, () => {
          setTimeout(() => {
            this.setState({ currentTab: 'Item Select'})
          },500)
        })
      }
    }
    if (prevProps.autoSaveLoad !== this.props.autoSaveLoad) {
      if (this.props.ginSource === 'Pending') {

      }
    }
  }

  componentDidMount() {
    console.log('ginSource')
    this.setState(initialStateC);
    if (this.props.ginSource !== 'Pending') {
      this.props.ginRefresh();
    }
    this.props.fetchRBOs('NLA_LIVE');
    this.props.fetchBrandSubgroupList();
    this.props.checkMaintenanceMode();
    console.log('mounting...', this.props);
    if (this.props.ginType === 'EXISTING' && this.props.ginSource !== 'Pending') this.openGINEdit();
    if (this.props.autosaveData && this.props.ginSource === 'Pending') {
      const filename = `${this.props.autosaveData.id}_${this.props.autosaveData.gin}`;
      console.log('image to be loaded', filename);
      this.props.ginLoadImage(filename);
      this.setGIN(this.props.autosaveData.gin);
    }
    console.log('ginSource',this.props.ginSource);
    if (this.props.ginSource === 'Pending') {
      console.log('selecting submit');
      setTimeout(() => {
        this.selectTab('Submit');
      }, 2000)
    }
  }

  render() {
    if (!checkPermissions('GIN')) {
      return <Redirect to="/dashboard" />;
    }
    console.log('GINMain -',this.props)

    if (this.props.maintenanceMode === 'yes') return <Redirect to="/components/maintenance_page" />

    let errorData = {
      'Item Select': '',
      'Image': '',
      'Source': '',
      'Pick Values': '',
      'Pricing': '',
      'Costing': '',
      'BOM': '',
      'Submit': '',
    };

    // Walmart RBO Values Check
    let hasWalmart = false;
    const the_rbos = this.props.rbo;
    const the_picks = this.props.picked;
    if (the_picks && the_rbos) {
      if (the_picks.rbo) {
        const the_picked_rbo_db = the_picks.rbo;
        const the_rbo_value = the_rbos[the_picked_rbo_db];
        console.log('GINMain got here 0');
        if (the_rbo_value !== 'n/a') {
          console.log('GINMain got here 1');
          const rboNames = this.props.rboNames;
          for (let i = 0; i < rboNames.length; i++) {
            const rboo = rboNames[i];
            console.log('GINMain got here 2', rboo, the_rbo_value);
            if (rboo.id === the_rbo_value) {
              if (rboo.name) {
                if (rboo.name.indexOf('WALMART') !== -1) {
                  hasWalmart = true;
                }
              }
              continue;
            }
          }
        }
      }
    }
    console.log('GINMain has walmart', hasWalmart);
    if (hasWalmart) {
      // check if there are picked values for Subgroup and Brand, and Packaging Type - canceled 12/19/2022
      let completedWalmartRequirements = false;
      if (this.props.picked) {
        const the_picked = this.props.picked;
        const picked_subgroup_db = the_picked.subgroup;
        const picked_brand_db = the_picked.brand;
        const picked_ipackagingtypes_db = the_picked.ipackagingtypes;
        if (picked_subgroup_db && picked_brand_db && picked_ipackagingtypes_db) {
          if (this.props.brand && this.props.subgroup && this.props.ipackagingtypes) {
            const picked_brand_value = this.props.brand[picked_brand_db];
            const picked_subgroup_value = this.props.subgroup[picked_subgroup_db];
            const picked_packagingtype_value = this.props.ipackagingtypes[picked_ipackagingtypes_db];
            if (picked_brand_value && picked_subgroup_value && picked_packagingtype_value) {
              if (picked_brand_value !== 'n/a' && picked_subgroup_value !== 'n/a' && picked_packagingtype_value !== 'n/a') {
                completedWalmartRequirements = true;
              }
            }
          }
        }
      }
      console.log('completedWalmartRequirements', completedWalmartRequirements);
      if (!completedWalmartRequirements) {
        //errorData['Pick Values'] += `Your RBO contains "Walmart" on its description - Brand, Subgroup and Packaging Type are required, `;
      }
    }

    // Image Values Condition
    let imageCondition = false, sourceCondition = false, allMinimumRequired = true, oneCompleteDB = false,
        pickValuesCondition = false;;
    let requiredByDB = {};
    if (this.props.openedDBs.length > 1) {
      const requireds2 = ['base_item_description','product_type','rbo','sales_unit','basic_packaging_unit',
      'qty_per_unit','ilength','width','weight','item_is'];
      // check if an item has all of these

      this.props.openedDBs.forEach(db => {
        let countReqs = 0;
        requireds2.forEach(fld => {
          if (this.props[fld][db]) {
            countReqs++;
          }
        });
        if (countReqs === requireds2.length) {
          oneCompleteDB = true;
        }
      });

      this.props.openedDBs.forEach(db => {
        requiredByDB[db] = [];
        const requiredPicks = requirePicksDB(db);
        const requireds = [];
        let required = (this.props.product_type[db] === 'Woven Labels' && requiredPicks) ? requireds.concat(['picks']) : requireds;
        console.log('required picks ?', required)
        const required_final = (db.indexOf('NIN') !== -1) ? required.concat(['gst_relevant','material_type','hsn_code','gst_tax_category','service_acct_code']) : required;

        let requiredError = false;
        required_final.forEach(field => {
          if (this.props[field]) {
            console.log('checking field', db, field, this.props[field][db]);
            if (!this.props[field][db]) {
              allMinimumRequired = false;
              requiredError = true;
              if (requiredByDB[db].indexOf(field) === -1) {
                requiredByDB[db].push(field);
              }
            }

            if (field === 'picks' && this.props.picks[db] == 0) {
              allMinimumRequired = false;
              requiredError = true;
              if (requiredByDB[db].indexOf(field) === -1) {
                requiredByDB[db].push(field);
              }
            }
          }
        });

        if (requiredError === true) {
          if (errorData['Source'].indexOf('There are required fields, please check') === -1) {
            if (this.state.currentTab === 'Submit') {
              errorData['Source'] += 'Additional information required, please go to Select Items to enter missing information';
            } else {
              errorData['Source'] += 'There are required fields, please check for red marked Tabs with red marked Field Labels in the DB boxes. ';
            }
          }
        }
      });
    } else {
      allMinimumRequired = false;
      errorData['Source'] += 'All DBs are required. ';
    }
    console.log('requiredByDB', requiredByDB);
    if (this.props.openedDBs.length > 1 && (allMinimumRequired && oneCompleteDB)) {
      console.log('check allMinimumRequired', allMinimumRequired, oneCompleteDB);
      sourceCondition = true;
    }

    if (this.props.exists) {
      sourceCondition = false;
      pickValuesCondition = false;
    }

    // Pick Values Conditions
    let allSources = [];
    this.props.openedDBs.forEach(db => {
      if (this.props.item_is[db]) {
        allSources.push(this.props.item_is[db]);
      }
    });
    console.log('checked pick values', allSources);
    if (this.props.openedDBs.length === allSources) {
      pickValuesCondition = true;
    }

    // Source Condition
    if (imageCondition && (this.props.ginImage || this.props.ginFinalCrop)) {
      console.log('check if final image', this.props.ginFinalCrop);
      sourceCondition = true;
    }

    const itemIs = {...this.props.itemIs};
    console.log('item is ??',itemIs);
    let itemTypes = [];
    Object.keys(itemIs).forEach(db => {
      if(itemIs[db]) {
        if (itemTypes.indexOf(itemIs[db]) === -1) {
          itemTypes.push(itemIs[db]);
        }
      }
    });
    console.log('all Item Types Opened', itemTypes);
    console.log('same item is ?', itemTypes);
    if (this.props.openedDBs.length > 1) {
      pickValuesCondition = true;
      this.props.openedDBs.forEach(db => {
        if (!this.props.item_is[db]) {
          errorData['Pick Values'] += 'All DBs must have a Source';
          pickValuesCondition = false;
        }
      });
    } else {
      if (!this.props.ginImage) {
        errorData['Submit'] += 'An image with 1000px x 1000px dimensions is required. You may upload and edit/crop an image to fit this size. ';
      } else if (!this.props.ginFinalCrop) {
        errorData['Submit'] += 'Your image is not currently 1000px x 1000px. Please Edit/Crop the image to fit this size. ';
      }
    }

    // Pricing Conditions
    let pricingCondition = true;
    let generalAndMeasurementsComplete = false;
    let fieldCheck = [];
    fieldList.forEach(f => {
      if (this.props.picked) {
        if (this.props.picked.hasOwnProperty(f)) {
          fieldCheck.push(f);
        }
      }
    });
    console.log('fieldCheck', fieldCheck, fieldList, this.props.picked);
    if (fieldCheck.length === fieldList.length) {
      generalAndMeasurementsComplete = true;
    } else {
      errorData['Image'] += 'Pick Values are not yet completed. ';
    }
    if (this.props.variantStatus && generalAndMeasurementsComplete) {
      pricingCondition = true;
    } else {
      if (!this.props.variantStatus) {
        errorData['Image'] += 'There are duplicate Variant Codes. Please clear duplicates by unchecking them.';
      }
    }

    // if (!this.props.hasDonePicks) {
    //   if (this.props.ginFinalCrop && this.props.ginFinalCrop.length < 120) {
    //     pricingCondition = true;
    //   } else {
    //     console.log('pricing is not done?');
    //     pricingCondition = false;
    //     errorData['Image'] += 'Please click Pick Values tab. ';
    //   }
    // }


    // Costing Conditions
    let costingCondition = false;
    const pricingRequirementsMet = this.checkPricingRequirements();

    this.props.openedDBs.forEach(db => {
      if (this.props.bmasterarray[db]) {
        Object.keys(this.props.bmasterarray[db]).forEach(customer => {
          const cust = customer.split('[.]')[0],
                custName = customer.split('[.]')[1];
          let hasAll = false;
          this.props.bmasterarray[db][customer].forEach(cc => {
            if (cc[0] >= 1 && cc[0] < 1000) {
              hasAll = true;
            }
          });
          if (!hasAll) {
            errorData['Costing'] += `${db} - ${custName} requires an ALL(Quantity 1 to 999) price. `;
          }
        });
      }
    });

    console.log('pricing requirements met', pricingRequirementsMet, itemTypes, pickValuesCondition, pricingCondition);
    if (pickValuesCondition && pricingCondition) {
      if (itemTypes.indexOf('Both') !== -1) {
        costingCondition = true;
      }
      if (itemTypes.indexOf('Outsourced') !== -1) {
        costingCondition = true;
      }
    }

    // BOM Conditions
    let bomCondition = false;

    this.props.openedDBs.forEach(db => {
      if (this.props.smasterarray[db]) {
        Object.keys(this.props.smasterarray[db]).forEach(customer => {
          const cust = customer.split('[.]')[0],
                custName = customer.split('[.]')[1];
          let hasAll = false;
          this.props.smasterarray[db][customer].forEach(cc => {
            if (cc[0] >= 1 && cc[0] < 1000) {
              hasAll = true;
            }
          });
          if (!hasAll) {
            errorData['BOM'] += `${db} - ${custName} requires an ALL(Quantity 1 to 999) price. `;
          }
        });
      }
    });

    console.log('pick and price', pickValuesCondition, pricingCondition);
    if (pickValuesCondition && pricingCondition) {
      imageCondition = true;
      if (itemTypes.indexOf('Both') !== -1) {
        bomCondition = true;
        const costingRequirementsMet = this.checkCostingRequirementsAll();
        if (!costingRequirementsMet) {
          errorData['BOM'] += 'Costing Requirements not yet done. ';
        }
      }
      if (itemTypes.indexOf('Produced in House') !== -1) {
        bomCondition = true;
      }
    }

    // Submit Conditions
    const dbCountCondition = this.props.openedDBs.length < 2 ? false : true;
    let itemCondition = true,
        pricingComplete = false;

    this.props.openedDBs.forEach(db => {
      console.log('db price check', db, this.props.gprice[db], this.props.gprices[db], this.props.gprice);
      console.log('db price check 2', isNaN(this.props.gprice[db]));
      if (this.props.gprice[db]) {
        if (!isNaN(this.props.gprice[db])) {
          pricingComplete = true;
        }
      }
    });
    console.log('checking for all pricing', pricingComplete)

    let tabRequirements = [];
    if (!pricingComplete) {
      tabRequirements.push('Pricing');
      errorData['Submit'] += 'One DB with a Base Price is required.';
    }
    this.props.openedDBs.forEach(db => {
      const itemIs = this.props.itemIs[db];
      if (itemIs === 'Both') {
        const costingRequirementsMet2 = this.checkCostingRequirements(db);
        if (!costingRequirementsMet2) {
          itemCondition = false;
          tabRequirements.push('Costing');

        }

        const bomRequirementsMet = this.checkBOMRequirements(db);
        if (!bomRequirementsMet) {
          itemCondition = false;
          tabRequirements.push('BOM');
        }

      } else if (itemIs === 'Produced in House') {
        const bomRequirementsMet = this.checkBOMRequirements(db);
        if (!bomRequirementsMet) {
          itemCondition = false;
          tabRequirements.push('BOM');
        }
      } else if (itemIs === 'Outsourced') {
        const costingRequirementsMet2 = this.checkCostingRequirements(db);
        if (!costingRequirementsMet2) {
          itemCondition = false;
          tabRequirements.push('Costing');
        }
      }
    });

    console.log('tab Requirements are', tabRequirements);

    let miniModalAlert = (<SweetAlert
        warning
        title="Required!"
        style={{ display: "block" }}
        onConfirm={() => {
          this.hideAlert();
          ReactDOM.findDOMNode(this.container).scrollIntoView();
        }}
        confirmBtnCssClass={
          this.props.classes.button + " " + this.props.classes.success
        }
        >
        {this.state.missing ? this.state.missing.join(', ') : ''}
        </SweetAlert>);

    const { classes } = this.props;
    const gins = this.props.dbList.map((dbi, index) => {
      let db = dbi.name;
      return (
      <GridItem xs={12} sm={12} md={6} key={'griditem-'+index}>
        {!this.state.reload && <GINbox
          required={requiredByDB[db] ? requiredByDB[db] : []}
          db={dbi}
          key={db+index}
          setModal={this.openModal}
          currentItem={this.props.itemCodes[db] ? this.props.itemCodes[db] : ''}
          baseItemDescription={this.props.baseItemDescription[db]}
          baseItemDescriptions={this.props.baseItemDescription}
          productType={this.props.productType[db]}
          productTypes={this.props.productType}
          rbo={this.props.rbo[db]}
          rbos={this.props.rbo}
          rboNames={this.props.rboNames}
          brand={this.props.brand ? this.props.brand[db] : ''}
          brandSimilars={this.props.brand}
          subgroup={this.props.subgroup ? this.props.subgroup[db] : ''}
          subgroupSimilars={this.props.subgroup}
          brandNames={this.props.brandNames}
          subgroupNames={this.props.subgroupNames}
          ipackagingtypes={this.props.ipackagingtypes[db]}
          ipackagingtypesSimilars={this.props.ipackagingtypes}
          salesUnit={this.props.sales_unit[db]}
          salesUnits={this.props.sales_unit}
          basicPackagingUnit={this.props.basic_packaging_unit[db]}
          basicPackagingUnits={this.props.basic_packaging_unit}
          qtyPerUnit={this.props.qty_per_unit[db]}
          qtyPerUnits={this.props.qty_per_unit}
          ilength={this.props.ilength[db]}
          ilengths={this.props.ilength}
          width={this.props.width[db]}
          widths={this.props.width}
          weight={this.props.weight ? this.props.weight[db] : ''}
          weights={this.props.weight}
          picks={this.props.picks ? this.props.picks[db] : ''}
          picksSimilars={this.props.picks}
          variants={this.props.variants ? this.props.variants[db] : []}
          variantsDesc={this.props.variantsDesc ? this.props.variantsDesc[db] : []}
          cprice={this.props.cprice ? this.props.cprice[db] : null}
          cterm={this.props.cterm ? this.props.cterm[db] : null}
          ccur={this.props.ccur ? this.props.ccur[db] : null}
          cstart={this.props.cstart ? this.props.cstart[db] : null}
          cend={this.props.cend ? this.props.cend[db] : null}
          cqtys={this.props.cqtys ? this.props.cqtys[db] : null}
          cterms={this.props.cterms ? this.props.cterms[db] : null}
          ccurs={this.props.ccurs ? this.props.ccurs[db] : null}
          cprices={this.props.cprices ? this.props.cprices[db] : null}
          cstarts={this.props.cstarts ? this.props.cstarts[db] : null}
          cends={this.props.cends ? this.props.cends[db] : null}
          bmasterarray={this.props.bmasterarray ? this.props.bmasterarray[db] : []}
          smasterarray={this.props.smasterarray ? this.props.smasterarray[db] : []}
          rms={this.props.rms ? this.props.rms[db] : []}
          rmsd={this.props.rmsd ? this.props.rmsd[db] : []}
          rmsq={this.props.rmsq ? this.props.rmsq[db] : []}
          rmsw={this.props.rmsw ? this.props.rmsw[db] : []}
          gprice={this.props.gprice ? this.props.gprice[db] : []}
          gstart={this.props.gstart ? this.props.gstart[db] : []}
          gend={this.props.gend ? this.props.gend[db] : []}
          gcur={this.props.gcur ? this.props.gcur[db] : []}
          gterm={this.props.gterm ? this.props.gterm[db] : []}
          gterms={this.props.gterms ? this.props.gterms[db] : []}
          gstarts={this.props.gstarts ? this.props.gstarts[db] : []}
          gends={this.props.gends ? this.props.gends[db] : []}
          gprices={this.props.gprices ? this.props.gprices[db] : []}
          gcurrs={this.props.gcurrs ? this.props.gcurrs[db] : []}
          gqtys={this.props.gqtys ? this.props.gqtys[db] : []}
          mocs={this.props.mocs ? this.props.mocs[db] : []}
          specialmoc={this.props.specialmoc ? this.props.specialmoc[db] : []}
          BOMItemBaseQty={this.props.BOMItemBaseQty ? this.props.BOMItemBaseQty[db] : []}
          BOMItemBaseQtys={this.props.BOMItemBaseQty}
          comp={this.props.comp ? this.props.comp : null}
          itemIs={this.props.itemIs ? this.props.itemIs[db] : []}
          itemIsSimilars={this.props.itemIs}
          openModal={this.state.openModal}
          gst_relevant={this.props.gst_relevant[db]}
          material_type={this.props.material_type[db]}
          hsn_code={this.props.hsn_code[db]}
          gst_tax_category={this.props.gst_tax_category[db]}
          service_acct_code={this.props.service_acct_code[db]}
        />}
      </GridItem>
      );
    });
    console.log('submitCondition', (itemCondition && pickValuesCondition && dbCountCondition && pricingComplete));
    console.log('submitCondition2', itemCondition, pickValuesCondition, dbCountCondition, pricingComplete);
    let submitCondition = true;
    if (!pricingCondition) {
      submitCondition = false;
    }
    if (itemCondition === false) {
      submitCondition = false;
    }
    if (pickValuesCondition === false ) {
      submitCondition = false;
    }
    if (dbCountCondition === false) {
      submitCondition = false;
    }
    if (pricingComplete === false) {
      costingCondition = false;
      bomCondition = false;
      submitCondition = false;
    }
    if (bomCondition === false) {
      submitCondition = false;
    }
    const currentTab = this.state.currentTab;

    errorData.costingCondition = costingCondition;
    errorData.bomCondition = bomCondition;
    errorData.tabRequirements = tabRequirements;

    if (this.props.exists) {
      sourceCondition = false;
      pickValuesCondition = false;
    }

    const maxWidth = currentTab === 'Review' ? 'none' : 1600;

    console.log('conditions -', imageCondition, pricingCondition, costingCondition, bomCondition)

    let optionsAreComplete = false;
    if (this.props.options) {
      if (typeof this.props.options === 'object') {
        if (Object.keys(this.props.options).length >= this.props.openedDBs.length) {
          optionsAreComplete = true;
        }
      }
    }

    return (
  <div className="relative" style={{maxWidth}}>
    <GridContainer style={{marginLeft:0}}>
      <div id="tab-parent">
        <Button
          style={{zIndex:8}}
          onClick={() => this.selectTab('Item Select')}
          color={currentTab === 'Item Select' ? 'rose' : 'white'}
          arrow={currentTab === 'Item Select'}>Select Items</Button>
        <Button
          style={{zIndex:7}}
          disabled={sourceCondition ? false : true}
          onClick={() => {
            this.selectTab('Source');
          }}
          color={currentTab === 'Source' ? 'rose' : 'white'}
          arrow={currentTab === 'Source'}>Source</Button>
        <Button
          style={{zIndex:6}}
          disabled={pickValuesCondition ? false : true}
          onClick={() => {
            this.selectTab('Pick Values');
            this.props.ginSetDonePickValues(true);
          }}
          color={currentTab === 'Pick Values' ? 'rose' : 'white'}
          arrow={currentTab === 'Pick Values'}>Pick Values</Button>
        <Button
          style={{zIndex:5}}
          disabled={imageCondition ? false : true}
          onClick={() => this.selectTab('Image')}
          color={currentTab === 'Image' ? 'rose' : 'white'}
          arrow={currentTab === 'Image'}>Image Upload</Button>
        <Button
          style={{zIndex:4}}
          disabled={pricingCondition ? false : true}
          onClick={() => this.selectTab('Pricing')}
          color={currentTab === 'Pricing' ? 'rose' : 'white'}
          arrow={currentTab === 'Pricing'}>Pricing</Button>
        <Button
          style={{zIndex:3}}
          onClick={() => this.selectTab('Costing')}
          color={currentTab === 'Costing' ? 'rose' : 'white'}
          disabled={costingCondition ? false : true}
          arrow={currentTab === 'Costing'}>Costing</Button>
        <Button
          style={{zIndex:2}}
          onClick={() => this.selectTab('BOM')}
          color={currentTab === 'BOM' ? 'rose' : 'white'}
          disabled={bomCondition ? false : true}
          arrow={currentTab === 'BOM'}>BOM</Button>
        <Button
          style={{zIndex:1}}
          onClick={() => {
            let msgMin = '', msgArr = [];
            if (errorData) {
              Object.keys(errorData).forEach(tab => {
                if (errorData[tab]) {
                  if (typeof errorData[tab] === 'string') {
                    if (msgArr.indexOf(errorData[tab]) === -1) {
                      msgMin += errorData[tab];
                      msgArr.push(errorData[tab]);
                    }
                  }
                }
              });
            }

            if (tabRequirements.length > 0 || msgMin || !this.props.ginFinalCrop) {
              if (tabRequirements.indexOf('BOM') !== -1) {
                if (msgMin.indexOf('BOM') === -1) {
                  msgMin += 'BOM (Minimum 1 Raw Material). ';
                }
              }
              if (tabRequirements.indexOf('Costing') !== -1) {
                if (msgMin.indexOf('Cost') === -1) {
                  msgMin += 'Costing (At least 1 Supplier Cost needed). ';
                }
              }
              if (!pricingComplete) {
                if (msgMin.indexOf('Pric') === -1) {
                  msgMin += 'All Customers Base Price is required if there are existing prices in other quantities. ';
                }
              }

              if (!this.props.ginFinalCrop && msgMin.indexOf('Image') === -1) {
                msgMin += 'Image Upload. (An image with 1000px x 1000px dimensions is required. You may upload and edit/crop an image to fit this size.) ';
              }
              const msgArr = msgMin.split('. ');
              const msg_jsx = msgArr.map((m,key) => {
                return <div key={`msg-${key}`} style={{marginBottom:10}}>{m}. </div>
              });
              const msgFinal = <div style={{maxHeight:200,overflowY:'scroll'}}>{msg_jsx}</div>
              this.openAlert(msgFinal);
            } else {
              // this.openAsk();
              const checkTargetCost = this.checkTargetCost();
              if (!checkTargetCost) {
                this.openAlert(`Target Cost is missing requirements, please check for Base Cost and completeness. `)
              } else {
                this.selectTab('Submit');
              }
            }
          }}
          color={currentTab === 'Submit' ? 'rose' : 'white'}
          disabled={pricingCondition ? false : true}
          arrow={currentTab === 'Submit'}>Review</Button>
      </div>
    </GridContainer>
    <GridContainer>
      <GridItem xs={12} sm={4} md={4}>
        <Button
          color={currentTab === 'Item Select' ? 'white' : 'rose'}
          disabled={currentTab === 'Item Select' ? true : false}
          onClick={() => {
            this.onBack(errorData);
          }}
          style={{marginTop:7}}
        >
          BACK
        </Button>
        {(this.props.openedDBs ? this.props.openedDBs.length > 0 ? true : false : false) && <Button
          color="default"
          onClick={() => {
            this.unlockItems();
          }}
          style={{marginTop:7,marginLeft:10}}
        >
          EXIT/UNSAVE GIN
        </Button>}
      </GridItem>
      <GridItem xs={12} sm={2} md={2}>
        <div className="text-center" style={{marginTop:15,fontWeight:'bold'}}>{this.props.ginNum ? `GIN: ${this.props.ginNum}` : ''}</div>
      </GridItem>
      <GridItem xs={12} sm={6} md={6}>
        {currentTab !== 'Submit' && <Button
          color={currentTab === 'Submit' ? 'white' : 'rose'}
          disabled={currentTab === 'Submit' ? true : false}
          onClick={() => {
            this.onNext(errorData);
          }}
          style={{float:'right',marginTop:7}}
        >
          NEXT
        </Button>}
        {currentTab === 'Submit' && <Button
          color="success"
          onClick={() => {
            this.approve();
          }}
          style={{float:'right',marginTop:7}}
        >
          {this.props.ginSource === 'Pending' ? 'APPROVE' : 'SUBMIT'}
        </Button>}
        <Button
          color="rose"
          onClick={() => {
            if (this.props.ginNum) {
              this.props.gatherAutoSaveData();
              setTimeout(() => {
                console.log('autosave ID', this.props.currentAutoSaveID);
                this.props.autoSave(this.props.saveData, this.props.currentAutoSaveID, this.props.ginNum);
                this.openMessage(`GIN# ${this.props.ginNum} session has been saved`);
              }, 1000);
            } else {
              this.openAlert('Please enter a Global Identification Number (GIN) to save the session');
            }
          }}
          style={{float:'right',marginTop:7,marginRight:10}}
        >
          SAVE
        </Button>
        {(currentTab === 'Item Select' && this.props.ginSource !== 'Pending') && <Button
          color="warning"
          onClick={() => {
            this.openAutoSave();
          }}
          style={{float:'right',marginTop:7,marginRight:10}}
        >
          LOAD
        </Button>}
        {(currentTab === 'Item Select' && (this.props.ginType === 'EXISTING' && this.props.ginSource !== 'Pending')) && <Button
          color="white"
          onClick={() => {
            this.openGINEdit();
          }}
          style={{float:'right',marginTop:7,marginRight:10}}
        >
          EDIT EXISTING
        </Button>}
        {(this.props.ginSource === 'Pending' && this.props.autosaveData) && <Button
          color="danger"
          onClick={() => {
            this.openAskDeny();
          }}
          style={{float:'right',marginTop:7,marginRight:10}}
        >
          DENY
        </Button>}
        {(this.props.ginSource === 'Pending' && this.props.autosaveData) && <Button
          color="white"
          onClick={() => {
            this.props.closeView();
          }}
          style={{float:'right',marginTop:7,marginRight:10}}
        >
          BACK TO LIST
        </Button>}
      </GridItem>
    </GridContainer>
      {this.state.alerted && miniModalAlert}

      {/* 1st Tab - Select Items */}
      {currentTab === 'Item Select' &&
        <GridContainer>{gins}</GridContainer>}

      {/* 2nd Tab - Image Upload */}
      {currentTab === 'Image' &&
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GINimage
            showImageCropper={this.state.showImageCropper}
            setShowImageCropper={this.setShowImageCropper}
            ref={this.child}
            setClick={click => this.clickChild = click}
          />
      </GridItem></GridContainer>}

      {currentTab === 'Source' && <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <GINSource
              onSelectTab={this.selectTab.bind(this)}
            />
        </GridItem>
      </GridContainer>}

      {/* 3rd Tab - Pick Values */}
      {currentTab === 'Pick Values' && <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <GINpickValues
              ginSource={this.props.ginSource}
              fromNextTab={this.state.fromNextTab}
              setFromNextTab={this.setFromNextTab}
              onSelectTab={this.selectTab.bind(this)}
              moveTab={() => {
                this.selectTab('Image');
              }}
            />
        </GridItem>
      </GridContainer>}

      {/* 4th Tab - Pricing Matrix */}
      {currentTab === 'Pricing' && <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GINpriceMatrix />
        </GridItem>
      </GridContainer>}

      {/* 5th Tab - Costing */}
      {currentTab === 'Costing' && <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
            <GINcost />
        </GridItem>
      </GridContainer>}

      {/* 6th Tab - BOM */}
      {currentTab === 'BOM' && <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
            <GINbom />
        </GridItem>
      </GridContainer>}

      {/* 6th Tab - Review */}
      {currentTab === 'Submit' && <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
            <GINreview
              selectTab={this.selectTab}
              resetGINNum={this.resetGINNum}
              errorData={errorData}
              action={this.props.action}
              autosave_id={this.props.currentAutoSaveID}
              ginType={this.props.ginType}
              autosaveData={this.props.autosaveData}
              ginSource={this.props.ginSource}
              closeView={this.props.closeView}
              ref={this.child2}
              setClick={click => this.clickChild2 = click}
            />
        </GridItem>
      </GridContainer>}

      <GridContainer>
       <GridItem xs={12} sm={12} md={3}></GridItem>
       <GridItem xs={12} sm={12} md={6}>
         {currentTab === 'Item Select' && <Card style={{marginTop:10,marginBottom:10}}>
           <CardBody>
             <div className={classes.checkboxAndRadio + " darker-text " + classes.checkboxAndRadioHorizontal} ref={(container) => { this.container = container; }} >
               <FormControlLabel
                 control={
                   <Checkbox
                     tabIndex={-1}
                     onClick={() => this.openAskTPP() }
                     checkedIcon={<Check className={classes.checkedIcon} />}
                     icon={<Check className={classes.uncheckedIcon} />}
                     classes={{checked: classes.checked}}
                     checked={this.props.gmpCheckbox}
                   />
                 }
                 classes={{label: classes.label}}
                 label="TPP"
               />
             </div>
             {this.props.gmpCheckbox && <CustomSelect 
                default={this.props.tpp_item_type}
                choose={(tpp_item_type) => {
                  this.props.setTPPItemType(tpp_item_type);
                }}
                label="Select a TPP Item Type"
                options={['A','B','C']}
                style={{maxWidth:200}}
             />}
             <CustomInput
               labelText="Global Identification Number"
               id="gin"
               formControlProps={{
                 fullWidth: true
               }}
               inputProps={{
                 type: "text",
                 onChange: (e) => {
                  this.onGINnumChange(e);
                 },
                 value: this.state.ginnum
               }}
             />
             {this.props.exists && <div style={{marginTop:20}} className="red-text">The Global Identification Number already exists, please use another one.</div>}
      </CardBody>
     </Card>}
    </GridItem>
  <GridItem xs={12} sm={12} md={3}>
  </GridItem>
</GridContainer>
<GridContainer>
  <GridItem xs={12} sm={4} md={4}>
    <Button
      color={currentTab === 'Item Select' ? 'white' : 'rose'}
      disabled={currentTab === 'Item Select' ? true : false}
      onClick={() => {
        this.onBack(errorData);
      }}
      style={{marginTop:7}}
    >
      BACK
    </Button>
  </GridItem>
  <GridItem xs={12} sm={4} md={4}>
  </GridItem>
  <GridItem xs={12} sm={4} md={4}>
    <Button
      color={currentTab === 'Submit' ? 'white' : 'rose'}
      disabled={currentTab === 'Submit' ? true : false}
      onClick={() => {
        this.onNext(errorData);
      }}
      style={{float:'right',marginTop:7}}
    >
      NEXT
    </Button>
  </GridItem>
</GridContainer>
        {this.state.askUnlock && <GINask2
            command={() => {
              console.log('this', this.state, this.props);
              let arr = [];
              if (this.props.itemCodes) {
                Object.keys(this.props.itemCodes).forEach((db) => {
                  arr.push({db,itemcode:this.props.itemCodes[db]});
                });
              }
              this.props.unlockItems(arr, true);
            }}
            title="Exit GIN"
            content="WARNING! All saved progress for this GIN will be lost. Do You Want to Continue?"
            close={this.closeAskUnlock}
          />
        }
      {this.state.askDeny && <GINask2
          command={() => {
            const emailData = JSON.parse(JSON.stringify(this.gatherEmailData()));
            emailData.type = 'approver_denied';
            const email = emailTemplate.GIN_submit(emailData);

            console.log('email created is',email);
            this.props.denyPending(this.props.autosaveData.pending_id,
              this.props.autosaveData.submitter_id,
              this.props.autosaveData.gin,
              email.subject,
              email.msg,
              this.props.itemCodes
            );
            setTimeout(() => {
              this.props.closeView();
            }, 2000)
          }}
          title="Deny this pending GIN submission"
          content="Do You Want to Continue?"
          close={this.closeAskDeny}
        />
      }
      {this.state.ask && <GINask2
          command={() => this.selectTab('Submit')}
          title="Send Items to SAP"
          content="Do You Want to Continue?"
          close={this.closeAsk}
        />
      }
      {this.state.askImage && <GINask2
          command={() => {
            this.onCropImage();
            this.selectTab('Pricing');
          }}
          title="Image Uploads"
          content="'Do you want to save and crop the image you uploaded ?'"
          close={this.closeAskImage}
        />
      }
      {this.state.askTPP && <GINask2
        command={() => {
          this.props.setGMPCheckbox(!this.props.gmpCheckbox);
          this.closeAskTPP();
        }}
        title="TPP checkbox"
        content={`Do you really want to ${this.props.gmpCheckbox ? 'uncheck' : 'check'} this box?`}
        close={this.closeAskTPP}
        />

      }
      {this.state.openModal &&
        <GINmodal
          closeModal={this.closeModal}
          openModal={this.state.openModal}
          db={this.state.currentDB}
          fetchItemList={this.props.fetchItemList}
        />}
        {
          this.props.editMode &&
          <GINautocomplete
            openEdit={true}
            data={this.props.editData}
          />
        }
        {
          this.props.editTableMode &&
          <GINtable
            openEdit={true}
            data={this.props.editTableData}
          />
        }
        {
          this.props.editTable2Mode &&
          <GINtable2
            openEdit={true}
            data={this.props.editTable2Data}
          />
        }
        {
          this.state.alert &&
          <GINalert
            title="Required"
            content={this.state.alert}
            hide={this.closeAlert}
            command={() => {
              if (this.state.alert) {
                if (typeof this.state.alert === 'string') {
                  if (this.state.alert.indexOf('Your image is not currently 1000px x 1000px. Please Edit/Crop the image to fit this size. ') !== -1) {
                    this.setState({ showImageCropper: true });
                  }
                }
              }
            }}
          />
        }
        {
          this.state.message &&
          <GINalert
            title=""
            content={this.state.message}
            hide={this.closeMessage}
          />
        }
        {
          this.props.autoSaveLoad && <Dialog open={this.props.autoSaveLoad} onClose={() => {
            // this.closeLoading
          }}>
            <DialogTitle id="simple-dialog-title">
              <div className="text-center">Loading saved session, please wait...</div>
            </DialogTitle>
            <div style={{width:'400px',margin:40,textAlign:'center'}}>
              <PulseLoader />
            </div>
          </Dialog>
        }
        {
          this.props.loadingOptions && <Dialog open={this.props.loadingOptions} onClose={() => {
            // this.closeLoading
          }}>
            <DialogTitle id="simple-dialog-title">
              <div className="text-center">Loading {this.props.loadingOptionsDB && <span style={{
                    backgroundColor:dbListObj[this.props.loadingOptionsDB],
                    padding:5,
                    marginLeft:5
                  }}>{this.props.loadingOptionsDB}</span>} options, please wait...</div>
            </DialogTitle>
            <div style={{width:'400px',margin:40,textAlign:'center'}}>
              <PulseLoader />
            </div>
          </Dialog>
        }
        {
          (!optionsAreComplete && this.props.ginSource === 'Pending') && <Dialog open={!optionsAreComplete} onClose={() => {
            // this.closeLoading
          }}>
            <DialogTitle id="simple-dialog-title">
              <div className="text-center">Loading DB options, please wait...</div>
            </DialogTitle>
            <div style={{width:'400px',margin:40,textAlign:'center'}}>
              <PulseLoader />
            </div>
          </Dialog>
        }
        {
          ((this.state.loading || this.props.existingLoadFinal) && !this.state.openModal) && <Dialog open={this.state.loading || this.props.existingLoadFinal} onClose={() => {
            // this.closeLoading
          }}>
            <DialogTitle id="simple-dialog-title">
              <div className="text-center">Loading{this.props.existingLoadFinal ?
                <span>
                  <span style={{
                    backgroundColor:dbListObj[this.props.loadingDB],
                    padding:5,
                    marginLeft:5
                  }}>{this.props.loadingDB}</span> data
                </span>
                :
                ''}, please wait...</div>
            </DialogTitle>
            <div style={{width:'400px',margin:40,textAlign:'center'}}>
              <PulseLoader />
            </div>
          </Dialog>
        }
        {
          this.state.showAutoSaveModal && <GINautosaveModal
            open={this.state.showAutoSaveModal}
            close={this.closeAutoSave}
            setGIN={this.setGIN}
            scrollTop={this.scrollTop}
            loadImage={this.loadImage}
          />
        }
        {
          this.state.showEditModal && <GINEditModal
            open={this.state.showEditModal}
            close={this.closeGINEdit}
            openAutoSave={this.openAutoSave}
            closeView={() => {
              this.props.closeView();
            }}
            setGINNumEdit={this.setGINNumEdit}
          />
        }
        {
          this.props.existingImageURL && <img
            src={this.props.existingImageURL}
            onError={this.setNotExistent}
            onLoad={this.onLoad}
            style={{opacity:0,position:'absolute',top:-200,left:-10000}}
          />
        }
  </div>
    );
  }
}

const mapStateToProps = (state) => {
  const brands = state.gin.brands,
        subgroups = state.gin.subgroups;
  let brandsDict = {},
      subgroupsDict = {};
  brands.forEach(b => {
    brandsDict[b.id.toString()] = b.name;
  });
  subgroups.forEach(s => {
    subgroupsDict[s.id.toString()] = s.name;
  });
  const options = state.gin.options;
  let rboReverseDict = {}, rboDict = {};
  Object.keys(options).forEach(db => {
    rboReverseDict[db] = {};
    rboDict[db] = {};
    const opts = options[db].rbos;
    opts.forEach(o => {
      rboReverseDict[db][o.FirmName] = o.FirmCode;
      rboDict[db][o.FirmCode.toString()] = o.FirmName;
    });
  });

  let variantStatus = false;
  if (checkCode(state.gin.variantsChecked)) {
    variantStatus = true;
  }

  // if (!checkVariantExistence(state.gin.variants)) {
  //   variantStatus = true;
  // }
  return {
    options: state.gin.options,
    brandsDict,
    subgroupsDict,
    rboReverseDict,
    rboDict,
    exists: state.gin.exists,
    ginImage: state.gin.ginImage,
    ginCrop: state.gin.ginCrop,
    ginFinalCrop: state.gin.ginFinalCrop,
    dbList: state.gin.dbList,
    loading: state.gin.loading,
    loadingOptions: state.gin.loadingOptions,
    loadingOptionsDB: state.gin.loadingOptionsDB,
    itemCodes: state.gin.itemCodes,
    baseItemDescription: state.gin.base_item_description,
    base_item_description: state.gin.base_item_description,
    productType: state.gin.product_type,
    product_type: state.gin.product_type,
    rbo: state.gin.rbo,
    rboNames: state.gmp.rbos,
    brandNames: state.gin.brands,
    subgroupNames: state.gin.subgroups,
    brand: state.gin.brand,
    subgroup: state.gin.subgroup,
    ipackagingtypes: state.gin.ipackagingtypes,
    packagingtypes: state.gin.packagingtypes,
    sales_unit: state.gin.sales_unit,
    basic_packaging_unit: state.gin.basic_packaging_unit,
    qty_per_unit: state.gin.qty_per_unit,
    ilength: state.gin.ilength,
    width: state.gin.width,
    weight: state.gin.weight,
    picks: state.gin.picks,
    variants: state.gin.variants,
    variantsDesc: state.gin.variantsDesc,

    cprice: state.gin.cprice,
    cterm: state.gin.cterm,
    ccur: state.gin.ccur,
    cstart: state.gin.cstart,
    cend: state.gin.cend,

    cqtys: state.gin.cqtys,
    cterms: state.gin.cterms,
    ccurs: state.gin.ccurs,
    cprices: state.gin.cprices,
    cstarts: state.gin.cstarts,
    cends: state.gin.cends,

    bmasterarray: state.gin.bmasterarray,
    smasterarray: state.gin.smasterarray,
    rms: state.gin.rms,
    rmsd: state.gin.rmsd,
    rmsq: state.gin.rmsq,
    rmsw: state.gin.rmsw,
    comp: state.gin.comp,
    itemIs: state.gin.item_is,
    item_is: state.gin.item_is,
    gcur: state.gin.gcur,
    gstart: state.gin.gstart,
    gend: state.gin.gend,
    gprice: state.gin.gprice,
    gterm: state.gin.gterm,
    gterms: state.gin.gterms,
    gstarts: state.gin.gstarts,
    gends: state.gin.gends,
    gprices: state.gin.gprices,
    gcurrs: state.gin.gcurrs,
    gqtys: state.gin.gqtys,
    mocs: state.gin.mocs,
    specialmoc: state.gin.specialmoc,
    BOMItemBaseQty: state.gin.BOMItemBaseQty,
    gst_relevant: state.gin.gst_relevant,
    material_type: state.gin.material_type,
    hsn_code: state.gin.hsn_code,
    gst_tax_category: state.gin.gst_tax_category,
    service_acct_code: state.gin.service_acct_code,

    openedDBs: state.gin.openedDBs,
    editMode: state.gin.editMode,
    editTableMode: state.gin.editTableMode,
    editData: state.gin.editData,
    editTable2Mode: state.gin.editTable2Mode,
    editData2: state.gin.editTable2Data,
    picked: state.gin.picked,
    variantStatus,
    ginNum: state.gin.ginNum,
    gmpCheckbox: state.gin.gmpCheckbox,
    tpp_item_type: state.gin.tpp_item_type,
    hasDonePicks: state.gin.hasDonePicks,
    tableCount: state.gin.tableCount,
    saveData: state.gin.saveData,
    currentAutoSaveID: state.gin.currentAutoSaveID,
    autoSaveLoad: state.gin.autoSaveLoad,
    autoSaveLoadOptions: state.gin.autoSaveLoadOptions,
    existingImageURL: state.gin.existingImageURL,
    existingLoadFinal: state.gin.existingLoadFinal,
    loadingDB: state.gin.loadingDB,
    maintenanceMode: state.userPerms.maintenanceMode,
    pendingData: state.gin.pendingData,
    chosenGINDetails: state.gin.chosenGINDetails,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    ginRefresh: () => {
      dispatch(actions.ginRefresh());
    },
    ginCheckExists: (ginnum) => {
      dispatch(actions.ginCheckExists(ginnum));
    },
    fetchItemList: (itemcode, db) => {
      dispatch(actions.fetchItemList(itemcode, db));
    },
    fetchRBOs: (db) => {
      dispatch(actions.fetchGMPRBO(db));
    },
    fetchBrandSubgroupList: () => {
      dispatch(actions.fetchBrandSubgroupList());
    },
    fetchOptions: (db) => {
      dispatch(actions.fetchGINOptions(db));
    },
    setGINNum: (ginnum) => {
      dispatch(actions.ginSetGINNum(ginnum));
    },
    ginSetFinalCropNull: () => {
      dispatch(actions.ginSetFinalCrop(null));
    },
    setGMPCheckbox: (gmp) => {
      dispatch(actions.ginSetGMP(gmp));
    },
    cancelLoading: () => {
      dispatch(actions.cancelLoading());
    },
    ginSetDonePickValues: (bool) => {
      dispatch(actions.ginSetDonePickValues(bool));
    },
    ginCheckImporterTable: () => {
      dispatch(actions.ginCheckImporterTable());
    },
    autoSave: (data, id, gin) => {
      dispatch(actions.ginAutoSave(data, id, gin));
    },
    gatherAutoSaveData: () => {
      dispatch(actions.ginGatherAutoSaveData());
    },
    setFinalCropped: (crop) => {
      dispatch(actions.ginSetFinalCrop(crop));
    },
    ginLoadImage: (filename) => {
      dispatch(actions.ginLoadImage(filename));
    },
    loadExistingGINImage: (imageURL) => {
      dispatch(actions.loadExistingImage(imageURL));
    },
    checkMaintenanceMode: () => {
      dispatch(actions.checkMaintenanceMode());
    },
    loadAutoSave: (id) => {
      dispatch(actions.ginAutoSaveLoad(id));
    },
    loadExisting: (data, rbos) => {
      dispatch(actions.ginLoadExisting(data, rbos))
    },
    unlockItems: (arr, pending) => {
      console.log('items', arr);
      dispatch(actions.updateItemsLock(arr, pending));
    },
    setTPPItemType: (tpp_item_type) => {
      dispatch(actions.setTPPItemType(tpp_item_type));
    }
  }
}

GINMain.defaultProps = {
  picked: {}
}

const combinedStyles = combineStyles(sweetAlertStyle, styles);
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(combinedStyles)(GINMain));
