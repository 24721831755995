import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import * as actions from '../../store/actions';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import Button2 from "components/CustomButtons/Button.jsx";
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Typography from '@material-ui/core/Typography';
import Settings from "@material-ui/icons/Settings";
import Table from "components/Table/Table.jsx";
import moment from "moment";
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";

const DialogTitle = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500],
  },
}))(props => {
  const { children, classes, onClose, title, gin } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <div style={{fontSize:'18px',textAlign:'center'}}>
        <strong>GIN # {gin}</strong>
      </div>

      <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit,
  },
}))(MuiDialogActions);

const compare = ( a, b ) => {
  const a_date = new Date(a.timestamp),
        b_date = new Date(b.timestamp);

  const a_timestamp = a_date.getTime(),
        b_timestamp = b_date.getTime();

  if ( a_timestamp < b_timestamp ){
    return -1;
  }
  if ( a_timestamp > b_timestamp ){
    return 1;
  }
  return 0;
}

class GINgroupSubModal extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { bp, classes, subGroupData } = this.props;
    let tableData = [],
        sgCopy = [ ...subGroupData.data],
        gin = subGroupData.gin;
    sgCopy.sort(compare);
    sgCopy.forEach((dat, i) => {
      console.log('dat',dat);
      const date = moment(dat.timestamp).format("MM/DD/YYYY hh:mm A");
      tableData.push([
        date,
        dat.activity,
        dat.user,
        <Button2
          color="success"
          size="sm"
          onClick={() => {
            console.log('open history item',dat.id, dat.submission_id, dat);
            this.props.loadHistoryEntry(dat.id);
            if (dat.data_source) {
              this.props.loadImage(`${dat.data_source}_${gin}`);
            }
            setTimeout(() => {
              this.props.openReviewModal(dat.user, date, dat.activity);
            }, 500)
            console.log('select index',i)
            this.props.selectIndex(i);
          }}
        >VIEW</Button2>
      ]);
    });
    let content = <div>Loading</div>;
    const title = 'ACTIVITY';

    let table = (
      <Table
        tableHeaderColor="primary"
        tableHead={["Date/Time", "Activity", "User", "Action"]}
        tableData={tableData}
        coloredColls={[3]}
        colorsColls={["primary"]}
      />
    )
    return (
        <Dialog
          onClose={() => {
            this.props.closeHistorySubGroupModal();
          }}
          aria-labelledby="customized-dialog-title"
          open={this.props.open}
          fullWidth={true}
          maxWidth="md"
        >
          <DialogTitle
            id="customized-dialog-title"
            title={title}
            onClose={() => {
              this.props.closeHistorySubGroupModal();
            }}
            gin={gin}
          >
          </DialogTitle>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div style={{padding:'20px 20px 20px 40px',fontSize:'14px'}}>
                  {table}
              </div>
            </GridItem>
          </GridContainer>
        </Dialog>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    loadImage: (filename) => {
      dispatch(actions.ginLoadImage(filename))
    }
  }
}

export default connect(null, mapDispatchToProps)(withStyles(userProfileStyles)(GINgroupSubModal));
