import React from "react";
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import checkPermissions from '../../shared/checkPermissions';
import checkActions from '../../shared/checkPermissionsActions';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Cached from "@material-ui/icons/Cached";
import RBOTranslationsLookup from "containers/Translations/RBOTranslationsLookup";

class RBOLookupPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      msg: ''
    }
  }

  componentDidMount() {
    this.props.checkMaintenanceMode();
    this.props.loadRBOData();
  }

  render() {
    if (!checkPermissions('RBOTranslationLookup')) {
      return <Redirect to="/dashboard" />;
    }
    if (this.props.maintenanceMode === 'yes') return <Redirect to="/components/maintenance_page" />

    console.log('rbo translation st pr', this.state, this.props);

    return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <div className="pointed"><Cached /></div>
            </CardIcon>
          </CardHeader>
          <CardBody>
            <h1>RBO Translations Lookup</h1>
            <RBOTranslationsLookup
              rbo_data={this.props.rbo_data}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
    );
  }
}

const mapStateToProps = state => {
  return {
    maintenanceMode: state.userPerms.maintenanceMode,
    rbo_data: state.translations.rbo_data
  }
}

const mapDispatchToProps = dispatch => {
  return {
    checkMaintenanceMode: () => {
      dispatch(actions.checkMaintenanceMode());
    },
    loadRBOData: () => {
      dispatch(actions.loadRBOData());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RBOLookupPage);
