import React, { Component } from 'react';
import ReactDOM from "react-dom";
import { connect } from 'react-redux';
import ReactTable from "react-table";
import * as actions from '../../store/actions';
import { formatDate } from 'shared/utility';

import GridContainer from "../../components/Grid/GridContainer.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
import Button from "../../components/CustomButtons/Button.jsx";
import Dvr from "@material-ui/icons/Dvr";
import { FadeLoader, PulseLoader } from 'react-spinners';
import SweetAlert from "react-bootstrap-sweetalert";

import GMPMultipleSelect from './GMPMultipleSelect';
import GMPProfile from './GMPProfile';
// import Modal from './GMPModal';

class GMPList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedBP: null,
      showModal: false,
      loadingBP: false
    }

    this.cancelProfileView = this.cancelProfileView.bind(this);
  }

  componentDidMount() {
    ReactDOM.findDOMNode(this).scrollIntoView();
    this.props.onFetchGMP();
  }

  cancelProfileView(e) {
    ReactDOM.findDOMNode(this).scrollIntoView();
    this.props.onFetchGMP();
    this.setState({ showModal:false });
  }

  cancelProfileView2 = () => {
    ReactDOM.findDOMNode(this).scrollIntoView();
    this.props.onFetchGMP();
    this.setState({ showModal:false });
  }

  stopLoadingBP = (bp) => {
    setTimeout(() => {
      console.log('this is');
      this.setState({
        selectedBP: bp,
        showModal: true,
        loadingBP: false
      })
    }, 100);
  }

  render() {
    console.log('loading status',this.state.loadingBP, this.props.loading, this.state.showModal);
    const { loading } = this.props;
    const override = {
        display: 'block',
        margin: '0 auto',
        borderColor: 'red'
    };
    let layout = <div style={{textAlign:'center',margin:'0 auto'}} className="centered-loader">
    <FadeLoader
        style={override}
        sizeUnit={"px"}
        size={150}
        color={'#123abc'}
        loading={true}
    /></div>

    if (!loading && !this.state.loadingBP) {
      let dbList = [];
      const table = <div className="table-adjust"><ReactTable
          data={
              this.props.gmps.map((bp, key) => { // map users to table rows
                  if (dbList.indexOf(bp.DB) == -1) {
                    dbList.push(bp.DB);
                  }
                  let bpName = bp.company_name ? bp.company_name : 'n/a';
                  if (bp.customer_num) {
                    bpName = bp.customer_name ? bp.customer_name.toUpperCase() : 'n/a';
                  }
                  return ({
                      id: key,
                      bp_name: bpName,
                      // bp_code: bp.customer_num ? bp.customer_num : 'n/a',
                      db_name: bp.DB,
                      date_added: formatDate(bp.date_added),
                      type: bp.customer_num ? 'EXISTING' : 'NEW',
                      actions: (
                          <div className="actions-right">
                              <Button
                                  justIcon
                                  round
                                  simple
                                  onClick={() => {
                                    ReactDOM.findDOMNode(this).scrollIntoView();

                                    this.setState({
                                      loadingBP: true
                                    });
                                    this.stopLoadingBP(bp);
                                  }}
                                  color="warning"
                              >
                                  <Dvr />
                              </Button>
                          </div>
                      )
                  })
              })
          }
          filterable
          columns={[
              {
                  Header: "Type",
                  accessor: "type",
                  filterMethod: (filter, row) => {
                      const chosen = filter.value;
                      if (chosen.length > 0) {
                          return chosen.indexOf(row[filter.id]) !== -1;
                      } else {
                          return true;
                      }
                  },
                  Filter: ({filter, onChange}) => {
                      return <div className="gmp-filter"><GMPMultipleSelect
                          options={['NEW','EXISTING']}
                          label={''}
                          choose={onChange}
                      /></div>
                  },
                  maxWidth: 100
              },
              {
                  Header: "BP Name",
                  accessor: "bp_name",
                  filterMethod: (filter, row) => {
                    var filterValue = filter.value.toLowerCase();
                    if (filterValue.length > 0) {
                        return row[filter.id].toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;
                    } else {
                        return true;
                    }
                  },
                  minWidth: 150
              },
              // {
              //     Header: "BP Code",
              //     accessor: "bp_code",
              //     filterMethod: (filter, row) => {
              //       var filterValue = filter.value.toLowerCase();
              //       if (filterValue.length > 0 && row[filter.id]) {
              //           return row[filter.id].toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;
              //       } else if (filterValue.length > 0 && !row[filter.id]) {
              //           return false;
              //       } else {
              //           return true;
              //       }
              //     }
              // },
              {
                  Header: "DB Name",
                  accessor: "db_name",
                  filterMethod: (filter, row) => {
                      const chosen = filter.value;
                      if (chosen.length > 0) {
                          return chosen.indexOf(row[filter.id]) !== -1;
                      } else {
                          return true;
                      }
                  },
                  Filter: ({filter, onChange}) => {
                      return <div className="gmp-filter"><GMPMultipleSelect
                          options={dbList}
                          label={''}
                          choose={onChange}
                      /></div>
                  }
              },
              {
                  Header: "Date Added",
                  accessor: "date_added",
                  sortMethod: (a, b) => {
                    a = new Date(a).getTime();
                    b = new Date(b).getTime();
                       return b > a ? 1 : -1;
                  }
              },
              {
                  Header: "Actions",
                  accessor: "actions",
                  sortable: false,
                  filterable: false,
                  maxWidth: 80
              }
          ]}
          defaultPageSize={10}
          showPaginationTop
          showPaginationBottom={false}
          className="-striped -highlight"
      /></div>
      layout = (
      <GridContainer justify="center" style={{
        margin: 0,
        paddingBottom: '40px',
        width: '100%',
      }}>
          <GridItem xs={12} sm={12} md={10}>
              {!this.state.showModal && table}
              {this.state.showModal && <GMPProfile
                          bp={this.state.selectedBP}
                          cancelProfileView={this.cancelProfileView}
                          cancelProfileView2={this.cancelProfileView2}
                        />}
          </GridItem>
      </GridContainer>);
    } else if (!loading && this.state.loadingBP) {
      layout = (
      <GridContainer justify="center" style={{
        margin: 0,
        paddingBottom: '40px',
        width: '100%',
      }}>
          <GridItem xs={12} sm={12} md={10}>
              <div>
                {this.state.loadingBP && <div style={{textAlign:'center'}}>Loading BP<PulseLoader /></div>}
              </div>
          </GridItem>
      </GridContainer>);
      // layout = (
      // <GridContainer justify="center" style={{
      //   margin: 0,
      //   paddingBottom: '40px',
      //   width: '100%',
      // }}>
      //     <GridItem xs={12} sm={12} md={10}>
      //         <div>
      //           <GMPProfile
      //             bp={this.state.selectedBP}
      //             cancelProfileView={this.cancelProfileView}
      //             cancelProfileView2={this.cancelProfileView2}
      //             stopLoadingBP={this.stopLoadingBP}
      //           />
      //         </div>
      //     </GridItem>
      // </GridContainer>);
    }
    return layout;
  }
}

const mapStateToProps = (state) => {
  return {
    gmps: state.gmp.gmps,
    loading: state.gmp.loading
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchGMP: () => dispatch(actions.fetchGMP())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GMPList);
