import React from "react";
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import checkPermissions from '../../shared/checkPermissions';
import checkActions from '../../shared/checkPermissionsActions';
import { formatDate2, formatTime } from 'shared/utility';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Cached from "@material-ui/icons/Cached";

import MultipleSelect from "components/CustomSelect/CustomMultipleSelect.jsx";
import Button from "../../components/CustomButtons/Button.jsx";
import Dvr from "@material-ui/icons/Dvr";
import { FadeLoader, PulseLoader } from 'react-spinners';
import SweetAlert from "react-bootstrap-sweetalert";
import withStyles from "@material-ui/core/styles/withStyles";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import combineStyles from "shared/combineStyles";
import styles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import Datetime from 'react-datetime';
import ReactTable from "react-table";
import GINMain from "containers/GIN/GINMain";
import moment from "moment";

class GINPendingList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      view: 'Table',
      autosaveData: null,
      errorMsg: null,
      errorLoading: false
    }
  }

  closeErrorLoading = () => {
    this.props.loadPending();
    this.setState({
      errorLoading: false,
      errorMsg: null
    })
  }

  componentDidMount() {
    this.props.loadPending();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.autoSaveLoadMsg !== this.props.autoSaveLoadMsg) {
      this.setState({
        errorMsg: this.props.autoSaveLoadMsg,
        errorLoading: true,
        view: 'Table'
      })
    }

    if (prevProps.pendingData !== this.props.pendingData) {
      console.log('prevprops', prevProps.pendingData, this.props.pendingData)
      if (prevProps.pendingData && this.props.pendingData) {
        if (this.props.pendingItemAutoOpen) {
          if (prevProps.pendingData.length === 0 && this.props.pendingData.length > 0) {
            const pd = this.props.pendingData;
            for (let i = 0; i < pd.length; i++) {
              if (pd[i].gin === this.props.pendingItemAutoOpen) {
                this.setAutosaveData({
                  id:pd[i].autosave_id,
                  gin:pd[i].gin,
                  pending_id:pd[i].pending_id,
                  submitter_id:pd[i].submitter_id
                })
                break;
              }
            }
          }
        }
      }
    }
  }

  closeView = () => {
    this.setState({view:'Table'}, () => {
      this.props.loadPending();
    })
  }

  setView = (view) => {
    this.setState({view})
  }

  setAutosaveData = (autosaveData) => {
    this.setState({autosaveData}, () => {
      this.props.loadAutoSave(autosaveData.id, 'pending');
      this.setView('GINMain');
    });
  }

  denyPendingAction = (id, submitter_id, ginnum, subject, msg, itemCodes) => {
    this.props.denyPending(id, itemCodes);
    this.props.notifyMail(submitter_id, subject, msg);
  }

  renderTable = (data) => {
    console.log('datad',data)
    const table = <div className="table-adjust2"><ReactTable
        data={
            data.map((entry, key) => { // map users to table rows
                return ({
                    id: key,
                    type: entry.type === 1 ? 'NEW' : 'EXISTING',
                    gin: entry.gin,
                    date_added: moment(entry.timestamp).format("MM/DD/YYYY hh:mm a"),
                    submitter: entry.name,
                    autosave_id: entry.autosave_id,
                    pending_id: entry.id,
                    submitter_id: entry.submitter_id
                })
            })
        }
        filterable
        getTrProps={(state, rowInfo) => {
          if (rowInfo && rowInfo.row) {
            return {
              onClick: (e) => {
                console.log('clicked row info',rowInfo);
                this.setAutosaveData({
                  id:rowInfo.original.autosave_id,
                  gin:rowInfo.original.gin,
                  pending_id:rowInfo.original.pending_id,
                  submitter_id:rowInfo.original.submitter_id
                })
              }
            }
          } else {
            return {}
          }
        }}
        columns={[
            {
                Header: "Type",
                accessor: "type",
                filterMethod: (filter, row) => {
                    const chosen = filter.value;
                    if (chosen.length > 0) {
                        return chosen.indexOf(row[filter.id]) !== -1;
                    } else {
                        return true;
                    }
                },
                Filter: ({filter, onChange}) => {
                    return <div className="gmp-filter"><MultipleSelect
                        options={['NEW','EXISTING']}
                        label={''}
                        choose={onChange}
                    /></div>
                },
                maxWidth: 100
            },
            {
                Header: "GIN",
                accessor: "gin",
                filterMethod: (filter, row) => {
                  var filterValue = filter.value.toLowerCase();
                  if (filterValue.length > 0) {
                      return row[filter.id].toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;
                  } else {
                      return true;
                  }
                },
                minWidth: 150
            },
            {
                Header: "Date Added",
                accessor: "date_added",
                sortMethod: (a, b) => {
                  a = new Date(a).getTime();
                  b = new Date(b).getTime();
                     return b > a ? 1 : -1;
                },
                filterMethod: (filter, row) => {
                    const dateFilter = filter.value;
                    if (dateFilter && row.date_added) {
                      if (typeof dateFilter !== 'string') {
                        if (row.date_added.indexOf(dateFilter.format("MM/DD/YYYY")) !== -1) {
                          return true;
                        }
                      }
                    }
                },
                Filter: ({filter, onChange}) => {
                  return (
                  <div style={{width:'100%'}}>
                    <Datetime
                      closeOnSelect={true}
                      onChange={onChange}
                      timeFormat={false}
                    />
                  </div>);
                },
            },
            {
                Header: "Submitter",
                accessor: "submitter",
                filterMethod: (filter, row) => {
                  var filterValue = filter.value.toLowerCase();
                  if (filterValue.length > 0) {
                      return row[filter.id].toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;
                  } else {
                      return true;
                  }
                }
            },
        ]}
        defaultSorted={[
          {
            id: "date_added",
            desc: false
          }
        ]}
        minRows={0}
        defaultPageSize={10}
        showPaginationTop
        showPaginationBottom={false}
        className="-striped -highlight"
    /></div>
    return table;
  }

  render() {
    if (!checkPermissions('GIN')) {
      return <Redirect to="/dashboard" />;
    }

    let hasItemCodes = false;
    if (this.props.itemCodes) {
      if (typeof this.props.itemCodes === 'object') {
        if (Object.keys(this.props.itemCodes).length > 0) {
          hasItemCodes = true;
        }
      }
    }

    console.log('GIN check', this.state.view, this.props.autoSaveLoadMsg)
    console.log('GIN check 2', this.state.view, this.props.itemCodes, hasItemCodes)

    return (
  <div>
    {this.state.view === 'Table' && <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <div
                className="pointed"
                onClick={() => this.props.loadPending()}
              >
                <Cached />
              </div>
            </CardIcon>
          </CardHeader>
          <CardBody>
            {this.props.loadingPreview &&
              <div style="text-align:center">
                <PulseLoader />
              </div>}
            {!this.props.loadingPreview && <div>{this.renderTable(this.props.pendingData)}</div>}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>}

    {(this.state.view === 'GINMain' && hasItemCodes) &&
      <GINMain
        action="approve"
        ginType="EXISTING"
        closeView={this.closeView}
        ginSource="Pending"
        autosaveData={this.state.autosaveData}
        denyPending={this.denyPendingAction}
        errorLoadingPending={this.state.errorMsg}
      />
    }

    {
      this.state.errorLoading &&
      <SweetAlert
          warning
          title="Item is Locked"
          style={{ display: "block" }}
          onConfirm={() => {
            this.closeErrorLoading();
          }}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          >
          <div style={{color:'red'}}>{this.state.errorMsg}</div>
      </SweetAlert>
    }
  </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loadingPreview: state.gin.loadingPreview,
    pendingData: state.gin.pendingData,
    pendingError: state.gin.pendingError,
    autoSaveLoadMsg: state.gin.autoSaveLoadMsg,
    autoSaveLoad: state.gin.autoSaveLoad,
    itemCodes: state.gin.itemCodes
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadPending: () => {
      dispatch(actions.ginLoadPending());
    },
    denyPending: (id, itemCodes) => {
      dispatch(actions.ginDenyPending(id, itemCodes));
    },
    notifyMail: (to, subject, message) => {
      dispatch(actions.notifyMail(to, subject, message));
    },
    loadAutoSave: (id, origin) => {
      dispatch(actions.ginAutoSaveLoad(id, origin));
    },
  }
}

const combinedStyles = combineStyles(sweetAlertStyle, styles);
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(combinedStyles)(GINPendingList));
