import React from "react";
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import SweetAlert from "react-bootstrap-sweetalert";
import withStyles from "@material-ui/core/styles/withStyles";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import * as actions from '../../store/actions';
import checkPermissions from '../../shared/checkPermissions';
import checkActions from '../../shared/checkPermissionsActions';
// import querystring from 'query-string';
import { qs } from 'shared/utility.js';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Cached from "@material-ui/icons/Cached";

import BPNew from "containers/BP/BPNew";
import BPExisting from "containers/BP/BPExisting";
import BPPendingList from "containers/BP/BPPendingList";

class BP extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      create: [],
      approve: [],
      tpp_create: [],
      tpp_approve: [],
      showNew: false,
      showExisting: false,
      showPending: false,
      currentClick: '',
      preloadDB: null,
      preloadBP: null,
      notifyAlertFlag: false,
      notifyAlert: false,
      targetTab: '',
      currentDB: '',
      currentBPType: '',
    }
  }

  componentDidMount() {
    this.props.checkMaintenanceMode();
    console.log('this is the query string', this.props.location.search);
    let params = qs(this.props.location.search);
    console.log('params',params);
    const actions = checkActions('BP');
    const tpp_actions = checkActions('GMP');
    console.log('actions',actions, tpp_actions);
    if (!actions) return;
    if (actions.hasOwnProperty('create')) {
      this.setState({
        create: actions.create
      })
    }

    if (actions.hasOwnProperty('approve')) {
      this.setState({
        approve: actions.approve
      })
    }

    if (tpp_actions.hasOwnProperty('create')) {
      this.setState({
        tpp_create: tpp_actions.create
      })
    }

    if (tpp_actions.hasOwnProperty('approve')) {
      this.setState({
        tpp_approve: tpp_actions.approve
      })
    }

    if (params.p && actions.hasOwnProperty('create')) {
      if (params.p === 'existing') {
        if (params.db && params.cc) {
          if (actions.create) {
            if (actions.create.indexOf(params.db) !== -1) {
              this.setBPView('Existing', params.db, params.cc);
            }
          }
        } else {
          this.setBPView('Existing');
        }
      }

      if (params.p === 'new') {
        this.setBPView('New');
      }

      if (params.p === 'pending') {
        this.setBPView('Pending');
      }
    }
  }

  toggleAlertNotification = () => {
    this.setState({
      notifyAlert: !this.state.notifyAlert
    })
  }

  setTargetTab = (target) => {
    this.setState({
      targetTab: target
    })
  }

  setToPending = () => {
    this.setState({
      showPending: false
    }, () => {
      this.setBPView('Pending');
    });
    this.toggleAlertNotification();
  }

  setToExisting = () => {
    this.setBPView('Existing');
    this.toggleAlertNotification();
  }

  setToNew = () => {
    this.setBPView('New');
    this.toggleAlertNotification();
  }


  setBPView = (component, db, cardcode) => {
    const components = ['New','Existing','Pending'];
    components.forEach((c) => {
      if (c !== component) {
        this.setState({
          ['show'+c]:false,

        })
      }
    });
    if (component === 'Existing' && (db && cardcode)) {
      console.log('preloading existing', db, cardcode);
      this.setState({
        ['show'+component]: true,
        preloadDB: db,
        preloadBP: cardcode
      })
    } else {
      this.setState({
        ['show'+component]: true
      })
    }
    this.setState({
      notifyAlertFlag: true
    });
  }

  reportCurrentDB = (db) => {
    this.setState({
      currentDB: db
    });
  }

  reportCurrentBPType = (bp_type) => {
    this.setState({
      currentBPType: bp_type
    });
  }

  refreshNew = () => {
    this.setState({
      showNew: false
    }, () => {
      this.setState({
        showNew: true
      });
    });
  }

  refreshExisting = () => {
    this.setState({
      showExisting: false
    }, () => {
      this.setState({
        showExisting: true
      });
    });
  }

  render() {
    const regularBPPermitted = checkPermissions('BP');
    const gmpBPPermitted = checkPermissions('GMP');
    if (!regularBPPermitted && !gmpBPPermitted) {
      return <Redirect to="/dashboard" />;
    }
    if (this.props.maintenanceMode === 'yes') return <Redirect to="/components/maintenance_page" />
    const create = this.state.create ?
                      this.state.create.length > 0 ? true : false
                    : false;

    const approve = this.state.approve ?
                      this.state.approve.length > 0 ? true : false
                    : false;

    const tpp_approve = this.state.tpp_approve ?
                          this.state.tpp_approve.length > 0 ? true : false
                        : false;

    console.log('permss', create, approve, tpp_approve, this.state);
    return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card style={{backgroundColor:'#e5e5e5',maxWidth:'1400px'}}>
          <CardHeader color="rose" icon>
            {
              (create && regularBPPermitted) &&
              <CardIcon
                color={this.state.showNew ? 'success' : 'rose'}
                onClick={() => {
                  if (!this.state.currentDB && !this.state.currentBPType) {
                      this.setBPView('New');
                  } else {
                    this.setTargetTab('New');
                    this.toggleAlertNotification();
                  }
                }}
                className="pointed"
              >
                <strong>NEW</strong>
              </CardIcon>
            }
            {
              (create && regularBPPermitted) &&
              <CardIcon
                color={this.state.showExisting ? 'success' : 'rose'}
                onClick={() => {
                  if (!this.state.currentDB && !this.state.currentBPType) {
                      this.setBPView('Existing');
                  } else {
                    this.setTargetTab('Existing');
                    this.toggleAlertNotification();
                  }
                }}
                className="pointed bold-on-hover"
              >
                <strong>EXISTING</strong>
              </CardIcon>
            }
            {
              ((approve || tpp_approve) && (regularBPPermitted || gmpBPPermitted)) &&
              <CardIcon
                color={this.state.showPending ? 'success' : 'rose'}
                className="pointed bold-on-hover"
                onClick={() => {
                  if (!this.state.notifyAlertFlag) {
                    this.setBPView('Pending');
                  } else {
                    this.setTargetTab('Pending');
                    this.toggleAlertNotification();
                  }
                }}
              >
                <strong>PENDING REQUESTS</strong>
              </CardIcon>
            }
          </CardHeader>
          <CardBody>
            {this.state.showNew &&
              <BPNew
                reportCurrentDB={this.reportCurrentDB}
                reportCurrentBPType={this.reportCurrentBPType}
                refresh={this.refreshNew}
              />}
            {this.state.showExisting &&
              <BPExisting
                preloadBP={this.state.preloadBP}
                preloadDB={this.state.preloadDB}
                reportCurrentDB={this.reportCurrentDB}
                reportCurrentBPType={this.reportCurrentBPType}
                refresh={this.refreshExisting}
              />}
            {this.state.showPending &&
              <BPPendingList
                reportCurrentDB={this.reportCurrentDB}
                location={this.props.location}
              />}
          </CardBody>
        </Card>
      </GridItem>

      {this.state.notifyAlert && <SweetAlert
        warning
        showCancel
        confirmBtnText="Yes"
      	cancelBtnText="No"
        confirmBtnCssClass={
          this.props.classes.button + " " + this.props.classes.success
        }
        cancelBtnCssClass={
          this.props.classes.button + " " + this.props.classes.default
        }
        title="Are you sure?"
        onConfirm={() => {
          if (this.state.targetTab === 'Pending') {
            this.setToPending();
          } else if (this.state.targetTab === 'New') {
            this.setToNew();
          } else if (this.state.targetTab === 'Existing') {
            this.setToExisting();
          }
          this.props.releaseUserLockedBPs();
        }}
        onCancel={() => {
          this.toggleAlertNotification();
        }}
        >
        Any changes made will be discarded. Do you want to proceed?
      </SweetAlert>}
    </GridContainer>
    );
  }
}

const mapStateToProps = state => {
  return {
    maintenanceMode: state.userPerms.maintenanceMode
  }
}

const mapDispatchToProps = dispatch => {
  return {
    checkMaintenanceMode: () => {
      dispatch(actions.checkMaintenanceMode());
    },
    releaseUserLockedBPs: () => {
      dispatch(actions.releaseUserLockedBPs());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(sweetAlertStyle)(BP));
