import React from 'react';
import Table from 'components/Table/Table.jsx';
import { Dialog } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { Check } from '@material-ui/icons';
import sapMonitorData from 'containers/SAPMonitor/SAPMonitorLogic';
const { checkBPequals } = sapMonitorData;

const SAPMonitorBPDetailsTable = props => {
    const { bp_details_sap, bp_details_magento } = props;
    console.log('SAPMonitorBPDetailsTable', props);
    const tableHeadBlue = [
        <div className="tablehead-blue">Description</div>,
        <div className="tablehead-blue">SAP Value</div>,
        <div className="tablehead-blue">Magento Value</div>,
        <div className="tablehead-blue">Check</div>
    ];
    const checkIfEquals = checkBPequals({bp_details_magento, bp_details_sap});
    console.log('checkIfEquals', checkIfEquals);
    const checked = checkIfEquals.map(m => {
        m[3] = m[3] ? <div style={{color:'green'}}><Check /></div> : <div style={{color:'red'}}><Close /></div>;
        return m;
    }).concat([[
        <div className="tablehead-blue">Description</div>,
        <div className="tablehead-blue">SAP Value</div>,
        <div className="tablehead-blue">Magento Value</div>,
        <div className="tablehead-blue">Check</div>
    ]]);
    
    return <div>
        <Dialog 
            open={true}
            onClose={() => {
                props.close();
            }}
            fullWidth={true}
            maxWidth="md"
        >
            <div className="dialog-wrapper">
                <div className="dialog-closer" onClick={() => { props.close() }}>
                    <Close />
                </div>
                <h3><strong>BP Details</strong></h3>
                <Table 
                    tableHead={tableHeadBlue}
                    tableData={checked}
                />
            </div>
        </Dialog>
    </div>
}

export default SAPMonitorBPDetailsTable;