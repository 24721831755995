import devStatus from 'shared/devStatus';
let domain = 'staging.portal2.natcoglobal.com';
if (devStatus === 'live' || devStatus === 'local_live') {
  domain = 'portal2.natcoglobal.com';
}
const emailTemplates = {};

emailTemplates.GIN_submit = (data) => {
  // ginNum, gmp, base_item_description, product Type, itemCodes = { NVT_LIVE: 'itemcode' }
  let subject = '', header = '';
  if (data.type === 'submit') {
    subject = `Your submission of Global Item Number ${data.ginNum} has been received.`;
    header = `
    <p class=MsoNormal>
       <b>
          <span style='font-size:16.0pt'>
             Your submission of Global Item Number ${data.ginNum} has been received.
             <o:p></o:p>
          </span>
       </b>
    </p>
    <p class=MsoNormal>
       <o:p>&nbsp;</o:p>
    </p>
    <p class=MsoNormal>
       <b>
          Your data is currently being processed and you will receive a confirmation email once it is verified in SAP and ready to use.
          <o:p></o:p>
       </b>
    </p>
    <p class=MsoNormal>
       If there are any issues with submitting your Global Item, you will be notified and our support team will work on resolving it as soon as possible.
       <o:p></o:p>
    </p>
    `;
  } else if (data.type === 'success') {
    subject = `Your Global Item Number: ${data.ginNum} has been verified in SAP and is now READY TO USE!`
    header = `
      <p class=MsoNormal>
          <b>
             <span style='font-size:16.0pt'>
                Your Global Item Number: ${data.ginNum} has been verified in SAP and is now <span style='color:#00B050'>READY TO USE!</span>
                <o:p></o:p>
             </span>
          </b>
       </p>
    `;
  } else if (data.type === 'fail') {
    subject = `Global Item Number: ${data.ginNum} ERRORS FOUND!`;
    header = `
    <p class=MsoNormal>
          <b>
             <span style='font-size:16.0pt'>
                Your submission of Global Item Number ${data.ginNum} has encountered <span style='color:red'>ERRORS </span>while being verified!
                <o:p></o:p>
             </span>
          </b>
       </p>
       <p class=MsoNormal>
          <o:p>&nbsp;</o:p>
       </p>
       <p class=MsoNormal>
          Our support team is currently looking into this issue and will notify you once your Global Item has been successfully entered into SAP.
          <o:p></o:p>
       </p>
    `
  } else if (data.type === 'submitter_pending') {
    subject = `Your submission of Global Item Number ${data.ginNum} is pending approval.`;
    header = `
    <p class=MsoNormal>
       <b>
          <span style='font-size:16.0pt'>
             Your submission of Global Item Number ${data.ginNum} has been received and is pending approval.
             <o:p></o:p>
          </span>
       </b>
    </p>
    <p class=MsoNormal>
       <o:p>&nbsp;</o:p>
    </p>
    `;
  } else if (data.type === 'approver_pending') {
    subject = `Global Item Number ${data.ginNum} has been submitted for approval.`;
    header = `
    <p class=MsoNormal>
       <b>
          <span style='font-size:16.0pt'>
             Global Item Number ${data.ginNum} has been submitted for approval. Upon logging in, you can click this
             <a href="https://${domain}/GIN/?p=pending&q=${data.ginNum}">link</a> to view the pending GIN.
             <o:p></o:p>
          </span>
       </b>
    </p>
    <p class=MsoNormal>
       <o:p>&nbsp;</o:p>
    </p>
    `;
  } else if (data.type === 'approver_denied') {
    subject = `Global Item Number: ${data.ginNum} DENIED!`;
    header = `
    <p class=MsoNormal>
          <b>
             <span style='font-size:16.0pt'>
                Your submission of Global Item Number ${data.ginNum} has been <span style='color:red'>DENIED </span>.
                <o:p></o:p>
             </span>
          </b>
       </p>
       <p class=MsoNormal>
          <o:p>&nbsp;</o:p>
       </p>
       <p class=MsoNormal>
          Please email <a href="mailto:helpdesk@natcoglobal.com">helpdesk@natcoglobal.com</a> for any questions..
          <o:p></o:p>
       </p>
    `
  }
  let itemcodes = '';
  Object.keys(data.itemCodes).forEach(db => {
    const dbname = db.split('_')[0];
    itemcodes += `
    <tr>
       <td width=319 valign=top style='width:239.4pt;padding:0in 5.4pt 0in 5.4pt'>
          <p class=MsoNormal>
             <b>
                ${dbname}
                <o:p></o:p>
             </b>
          </p>
       </td>
       <td width=319 valign=top style='width:239.4pt;padding:0in 5.4pt 0in 5.4pt'>
          <p class=MsoNormal>
             ${data.itemCodes[db]}
             <o:p></o:p>
          </p>
       </td>
    </tr>
    `
  })
  return {
    subject,
    msg: `
    <html>
       <body lang=EN-US link=blue vlink=purple>
          <div class=WordSection1>
             ${header}
             <p class=MsoNormal>
                <o:p>&nbsp;</o:p>
             </p>
             <p class=MsoNormal>
                <o:p>&nbsp;</o:p>
             </p>
             <p class=MsoNormal>
                <o:p>&nbsp;</o:p>
             </p>
             <table class=MsoTableGrid border=0 cellspacing=0 cellpadding=0 style='border-collapse:collapse;border:none'>
                <tr>
                   <td width=319 valign=top style='width:239.4pt;background:#95B3D7;padding:0in 5.4pt 0in 5.4pt'>
                      <p class=MsoNormal>
                         <b>
                            <span style='color:white'>
                               ITEM SUMMARY
                               <o:p></o:p>
                            </span>
                         </b>
                      </p>
                   </td>
                   <td width=319 valign=top style='width:239.4pt;background:#95B3D7;padding:0in 5.4pt 0in 5.4pt'>
                      <p class=MsoNormal>
                         <b>
                            <span style='color:white'>
                               <o:p>&nbsp;</o:p>
                            </span>
                         </b>
                      </p>
                   </td>
                </tr>
                <tr>
                   <td width=319 valign=top style='width:239.4pt;padding:0in 5.4pt 0in 5.4pt'>
                      <p class=MsoNormal>
                         <b>
                            GLOBAL ITEM NUMBER
                            <o:p></o:p>
                         </b>
                      </p>
                   </td>
                   <td width=319 valign=top style='width:239.4pt;padding:0in 5.4pt 0in 5.4pt'>
                      <p class=MsoNormal>
                         ${data.ginNum}
                         <o:p></o:p>
                      </p>
                   </td>
                </tr>
                <tr>
                   <td width=319 valign=top style='width:239.4pt;padding:0in 5.4pt 0in 5.4pt'>
                      <p class=MsoNormal>
                         <b>
                            TPP ITEM
                            <o:p></o:p>
                         </b>
                      </p>
                   </td>
                   <td width=319 valign=top style='width:239.4pt;padding:0in 5.4pt 0in 5.4pt'>
                      <p class=MsoNormal>
                         ${data.gmp}
                         <o:p></o:p>
                      </p>
                   </td>
                </tr>
                <tr>
                   <td width=319 valign=top style='width:239.4pt;padding:0in 5.4pt 0in 5.4pt'>
                      <p class=MsoNormal>
                         <b>
                            BASE ITEM DESCRIPTION
                            <o:p></o:p>
                         </b>
                      </p>
                   </td>
                   <td width=319 valign=top style='width:239.4pt;padding:0in 5.4pt 0in 5.4pt'>
                      <p class=MsoNormal>
                         ${data.base_item_description}
                         <o:p></o:p>
                      </p>
                   </td>
                </tr>
                <tr>
                   <td width=319 valign=top style='width:239.4pt;padding:0in 5.4pt 0in 5.4pt'>
                      <p class=MsoNormal>
                         <b>
                            PRODUCT TYPE
                            <o:p></o:p>
                         </b>
                      </p>
                   </td>
                   <td width=319 valign=top style='width:239.4pt;padding:0in 5.4pt 0in 5.4pt'>
                      <p class=MsoNormal>
                         ${data.product_type}
                         <o:p></o:p>
                      </p>
                   </td>
                </tr>
                <tr>
                   <td width=319 valign=top style='width:239.4pt;padding:0in 5.4pt 0in 5.4pt'>
                      <p class=MsoNormal>
                         <b>
                            RBO
                            <o:p></o:p>
                         </b>
                      </p>
                   </td>
                   <td width=319 valign=top style='width:239.4pt;padding:0in 5.4pt 0in 5.4pt'>
                      <p class=MsoNormal>
                         ${data.rboName}
                         <o:p></o:p>
                      </p>
                   </td>
                </tr>
                <tr>
                   <td width=319 valign=top style='width:239.4pt;padding:0in 5.4pt 0in 5.4pt'>
                      <p class=MsoNormal>
                         <b>
                            BRAND
                            <o:p></o:p>
                         </b>
                      </p>
                   </td>
                   <td width=319 valign=top style='width:239.4pt;padding:0in 5.4pt 0in 5.4pt'>
                      <p class=MsoNormal>
                         ${data.brandName}
                         <o:p></o:p>
                      </p>
                   </td>
                </tr>
                <tr>
                   <td width=319 valign=top style='width:239.4pt;padding:0in 5.4pt 0in 5.4pt'>
                      <p class=MsoNormal>
                         <b>
                            SUBGROUP
                            <o:p></o:p>
                         </b>
                      </p>
                   </td>
                   <td width=319 valign=top style='width:239.4pt;padding:0in 5.4pt 0in 5.4pt'>
                      <p class=MsoNormal>
                         ${data.subgroupName}
                         <o:p></o:p>
                      </p>
                   </td>
                </tr>
             </table>
             <p class=MsoNormal>
                <o:p>&nbsp;</o:p>
             </p>
             <table class=MsoTableGrid border=0 cellspacing=0 cellpadding=0 style='border-collapse:collapse;border:none'>
                <tr>
                   <td width=319 valign=top style='width:239.4pt;background:#95B3D7;padding:0in 5.4pt 0in 5.4pt'>
                      <p class=MsoNormal>
                         <b>
                            <span style='color:white'>
                               DATABASE
                               <o:p></o:p>
                            </span>
                         </b>
                      </p>
                   </td>
                   <td width=319 valign=top style='width:239.4pt;background:#95B3D7;padding:0in 5.4pt 0in 5.4pt'>
                      <p class=MsoNormal>
                         <b>
                            <span style='color:white'>
                               ITEM CODE
                               <o:p></o:p>
                            </span>
                         </b>
                      </p>
                   </td>
                </tr>
                ${itemcodes}
             </table>
             <p class=MsoNormal>
                <o:p>&nbsp;</o:p>
             </p>
             <p class=MsoNormal>
                <o:p>&nbsp;</o:p>
             </p>
             <p class=MsoNormal>
                <o:p>&nbsp;</o:p>
             </p>
             <p class=MsoNormal></p>
             <p class=MsoNormal>
                <o:p>&nbsp;</o:p>
             </p>
          </div>
       </body>
    </html>
    `
  }
}

export default emailTemplates;
