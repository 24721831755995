import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import * as actions from '../../store/actions';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import Table from "components/Table/Table.jsx";
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import Datetime from "react-datetime";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
import InputAdornment from '@material-ui/core/InputAdornment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import cloneDeep from "lodash/cloneDeep";
import Button from "components/CustomButtons/Button.jsx";
import ReactTable from "react-table";
import activeDBs from "shared/activeDBs";
import { ranges, rangesObj } from "./GINranges";
import { getDescription } from "./GINhelpers";
import { numberWithCommas } from 'shared/utility';
import GINreviewDialogEdit from "./GINreview-dialog-edit";

const orangeObj = {color:'orange'};

const getRange = (qty) => {
  let name = '';
  ranges.forEach((re) => {
    if (qty < 10000000) {
      if ((re.rangeA <= qty) && (qty <= re.rangeB)) {
        name = re.name;
      }
    } else {
      name = '10M+';
    }
  });
  return name;
}

const DBlabel = props => {
  // props.name
  let backgroundColor = '';
  activeDBs.forEach((db) => {
    if (db.name === props.name) {
      backgroundColor = db.color;
    }
  });

  if (backgroundColor) {
    return (
      <span style={{backgroundColor}} className="db-pill">
        {props.name.split('_')[0]}
      </span>
    );
  }
  return null;
}

class GINreviewDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      edit: false,
      data: null
    }
  }

  openEdit = (data) => {
    if (this.props.isPreview) return
    this.setState({
      edit: true,
      data
    })
  }

  closeEdit = () => {
    this.setState({
      edit: false, data: null
    })
  }

  renderAllPricing = () => {
    const props = this.props,
          db = props.data.db,
          mocs = props.mocs[db],
          specialmoc = props.specialmoc[db];
    let prices = [],
        finalMOC = mocs.hasOwnProperty(props.gcur[db]) ? numberWithCommas(mocs[props.gcur[db]]) : 0,
        finalMOCtype = 'STANDARD',
        finalMOCnum = mocs[props.gcur[db]] ? mocs[props.gcur[db]] : 0,
        isSingleNew = false;
    // single value pricings
    if (props.gprice[db]) {
      if (!props.gprice_original[db]) {
        isSingleNew = true;
      }
      if (specialmoc) {
        if (specialmoc.length > 0) {
          specialmoc.forEach(s => {
            if (s[0] === props.gcur[db]) {
                finalMOC = s[1] ? numberWithCommas([1]) : 0;
                finalMOCtype = 'SPECIAL';
                finalMOCnum = s[1] ? s[1] : 0;
            }
          });
        }
      }
      const theMOC1 = finalMOCnum ? numberWithCommas(finalMOCnum) : 0;
      prices.push({
        qty: 1,
        price: props.gprice[db],
        priceCompared: props.compare2(props.gprice[db], props.gprice_original[db], true),
        currency: props.gcur[db],
        currencyCompared: props.compare2(props.gcur[db], props.gcur_original[db]),
        term: props.gterm[db],
        termCompared: props.compare2(props.gterm[db], props.gterm_original[db]),
        start: props.gstart[db],
        startCompared: props.compare2(props.gstart[db], props.gstart_original[db]),
        end: props.gend[db],
        endCompared: props.compare2(props.gend[db], props.gend_original[db]),
        moc: finalMOC,
        mocType: finalMOCtype,
        finalMOC: `${props.gcur[db]}${theMOC1} ${finalMOCtype}`,
        mocNum: finalMOCnum,
        index: -1,
        isNew: isSingleNew
      })
    }

    // array prices
    if (props.gprices[db]) {
      if (props.gprices[db].length > 0) {
        props.gprices[db].forEach((pr,i) => {
          if (props.gqtys[db][i]) {
            if (parseInt(props.gqtys[db][i]) != 0) {
              let theMOC = props.mocs.hasOwnProperty(props.gcurrs[db][i]) ? numberWithCommas(mocs[props.gcurrs[db][i]]) : 0,
                  theMOCtype = 'STANDARD',
                  mocNum = mocs[props.gcurrs[db][i]] ? mocs[props.gcurrs[db][i]] : 0,
                  isNew = false;
              if (props.specialmoc[db]) {
                props.specialmoc[db].forEach(sm => {
                  console.log('searching', sm, props.gcurrs[db][i]);
                  if (sm[0] === props.gcurrs[db][i]) {
                    theMOC = sm[1] ? numberWithCommas(sm[1]) : 0;
                    theMOCtype = 'SPECIAL';
                    mocNum = sm[1] ? sm[1] : 0;
                  }
                })
              }

              let priceCompared = '';
              if (props.gprices_original[db]) {
                if (props.gprices_original[db][i]) {
                  priceCompared = this.props.compare2(props.gprices[db][i], props.gprices_original[db][i]);
                }
              }

              let currencyCompared = '';
              if (props.gcurrs_original[db]) {
                if (props.gcurrs_original[db][i]) {
                  currencyCompared = this.props.compare2(props.gprices[db][i], props.gprices_original[db][i]);
                }
              }

              let termCompared = '';
              if (props.gterms_original[db]) {
                if (props.gterms_original[db][i]) {
                  termCompared = this.props.compare2(props.gterms[db][i], props.gterms_original[db][i]);
                }
              }

              let startCompared = '';
              if (props.gstarts_original[db]) {
                if (props.gstarts_original[db][i]) {
                  startCompared = this.props.compare2(props.gstarts[db][i], props.gstarts_original[db][i]);
                }
              }

              let endCompared = '';
              if (props.gends_original[db]) {
                if (props.gends_original[db][i]) {
                  endCompared = this.props.compare2(props.gends[db][i], props.gends_original[db][i]);
                }
              }

              if (props.gcodes_original[db]) {
                if (props.gcodes_original[db][i] && props.gcodes) {
                  if (props.gcodes[db][i]) {
                    if (props.gcodes_original[db][i] === props.gcodes[db][i]) {
                        isNew = true;
                    }
                  }
                } else {
                  isNew = true;
                }
              }

              prices.push({
                qty: parseInt(props.gqtys[db][i]),
                price: props.gprices[db][i],
                priceCompared,
                currency: props.gcurrs[db][i],
                currencyCompared,
                term: props.gterms[db][i],
                termCompared,
                start: props.gstarts[db][i],
                startCompared,
                end: props.gends[db][i],
                endCompared,
                moc: theMOC,
                mocNum: mocNum,
                mocType: theMOCtype,
                finalMOC: `${props.gcurrs[db][i]}${theMOC} ${theMOCtype}`,
                index: i,
                isNew
              });
            }
          }
        });
      }
    }
    console.log('these are the all pricings', prices)
    // array pricings
    const table = <div className="spec-table scrolling-table no-blue-row boxed-table1 header-review">
    <h4><strong>PRICING FOR ALL CUSTOMERS</strong></h4>
    <ReactTable
        data={
            prices.map((pr, key) => {
              let price = '';
              if (pr.price) {
                if (parseFloat(pr.price)) {
                  price = parseFloat(pr.price);
                }
              }
              const colorNew = pr.isNew;
              console.log('all prices', pr);
              return ({
                id: key,
                qty: pr.qty,
                qtyCell: (
                  <span className="hovered hover-label" onClick={() => {
                    console.log('cell', pr);
                    this.openEdit({
                      title: 'EDIT PRICE QUANTITY',
                      type: 'all-price',
                      subType: 'quantity',
                      db,
                      value: pr.qty,
                      index: pr.index
                    });
                  }}>
                    <span>{getRange(pr.qty)}</span>
                  </span>
                ),
                qtyRange: getRange(pr.qty),
                price: `${pr.currency}${price}`,
                priceCell: (
                  <span className="hovered hover-label" onClick={() => {
                    this.openEdit({
                      title: 'EDIT PRICE',
                      type: 'all-price',
                      subType: 'price',
                      db,
                      value: pr.price,
                      index: pr.index,
                      currency: pr.currency
                    });
                  }}>
                    <span style={pr.priceCompared ? {color:'orange'} : colorNew ? {color:'green'} : {}}>{`${pr.currency}${price}`}</span>
                  </span>
                ),
                originalPrice: pr.price,
                currency: pr.currency,
                currencyCell: (
                  <span className="hovered hover-label" onClick={() => {
                    this.openEdit({
                      title: 'EDIT CURRENCY',
                      type: 'all-price',
                      subType: 'currency',
                      db,
                      value: pr.currency,
                      index: pr.index
                    });
                  }}>
                    <span style={pr.currencyCompared ? {color:"orange"} : colorNew ? {color:'green'} : {}}>{pr.currency}</span>
                  </span>
                ),
                term: pr.term,
                termCell: (
                  <span className="hovered hover-label" onClick={() => {
                    this.openEdit({
                      title: 'EDIT SHIPPING TERM',
                      type: 'all-price',
                      subType: 'term',
                      db,
                      value: pr.term,
                      index: pr.index
                    });
                  }}>
                    <span style={pr.termCompared ? {color:'orange'} : colorNew ? {color:'green'} : {}}>{pr.term}</span>
                  </span>
                ),
                start: pr.start,
                end: pr.end,
                effective_dates: `${pr.start} TO ${pr.end}`,
                effectiveDatesCell: (
                  <span className="hovered hover-label" onClick={() => {
                    this.openEdit({
                      title: 'EDIT EFFECTIVE DATES',
                      type: 'all-price',
                      subType: 'date',
                      db,
                      value: `${pr.start} TO ${pr.end}`,
                      index: pr.index
                    });
                  }}>
                    <span style={(pr.startCompared || pr.endCompared) ? {color:'orange'} : colorNew ? {color:'green'} : {}}>{`${pr.start} TO ${pr.end}`}</span>
                  </span>
                ),
                moc: pr.finalMOC,
                mocCell: (
                  <span className={pr.status !== 'REMOVED' ? `hovered hover-label` : ''} onClick={() => {
                    if (pr.status === 'REMOVED') return;
                    this.openEdit({
                      title: 'EDIT MOC',
                      type: 'all-price',
                      subType: 'moc',
                      db,
                      value: pr.mocNum ? pr.mocNum : 0,
                      index: pr.index,
                      currency: pr.currency,
                    });
                  }}>
                    <span style={colorNew ? {color:'green'} : {}}>{pr.finalMOC}</span>
                  </span>
                ),
                index: pr.index
              })
            })
        }
        filterable
        columns={[
            {
              Header: "Qty",
              accessor: "qtyRange",
              sortable: true,
              filterable: false,
              maxWidth: 40,
              Cell: row => {
                return (
                  <div>{row.original.qtyRange}</div>
                )
              },
            },
            {
              Header: "Shipping Terms",
              accessor: "term",
              sortable: true,
              filterable: false,
              Cell: row => {
                return (
                  <div>{row.original.termCell}</div>
                )
              },
            },
            {
              Header: "Price",
              accessor: "price",
              sortable: true,
              filterable: false,
              Cell: row => {
                return (
                  <div>{row.original.priceCell}</div>
                )
              },
            },
            {
              Header: "Effective Dates",
              accessor: "effective_dates",
              sortable: true,
              filterable: false,
              minWidth: 180,
              Cell: row => {
                return (
                  <div>{row.original.effectiveDatesCell}</div>
                )
              },
            },
            {
              Header: "Currency",
              accessor: "currency",
              sortable: true,
              filterable: false,
              Cell: row => {
                return (
                  <div>{row.original.currencyCell}</div>
                )
              },
            },
            {
              Header: "MOC",
              accessor: "moc",
              sortable: true,
              filterable: false,
              Cell: row => {
                return (
                  <div>{row.original.mocCell}</div>
                )
              },
            },
        ]}
        minRows={0}
        defaultPageSize={1000}
        showPagination={false}
        className="-highlight"
    /></div>
    return table;
  }

  renderCustomerPricing = () => {
    const props = this.props, db = props.data.db;
    let prices = [];
    if (props.bmasterarray[db]) {
      if (Object.keys(props.bmasterarray[db]).length > 0) {
        for (let combo in props.bmasterarray[db]) {
          const bp_name = combo.split('[.]')[1],
                cardcode = combo.split('[.]')[0];
          let mocs = {}, pricings = [];
          if (props.bmasterarray[db][combo]) {
            if (props.bmasterarray[db][combo].length > 0) {
              props.bmasterarray[db][combo].forEach((bp,i) => {
                if (bp[0]) {
                  if (parseInt(bp[0])) {
                    let priceCompared = '',
                        currencyCompared = '',
                        termCompared = '',
                        startCompared = '',
                        endCompared = '';
                    if (props.bmasterarray_original[db]) {
                      if (props.bmasterarray_original[db][combo]) {
                        if (props.bmasterarray_original[db][combo][i]) {
                          priceCompared = props.compare2(bp[2], props.bmasterarray_original[db][combo][i][2])
                          currencyCompared = props.compare2(bp[5], props.bmasterarray_original[db][combo][i][5])
                          termCompared = props.compare2(bp[1], props.bmasterarray_original[db][combo][i][1])
                          startCompared = props.compare2(bp[6], props.bmasterarray_original[db][combo][i][6])
                          endCompared = props.compare2(bp[7], props.bmasterarray_original[db][combo][i][7])
                        }
                      }
                    }

                    pricings.push({
                      bp_name,
                      cardcode,
                      qty: parseInt(bp[0]),
                      price: bp[2],
                      priceCompared,
                      currency: bp[5],
                      currencyCompared,
                      term: bp[1],
                      termCompared,
                      start: bp[6],
                      startCompared,
                      end: bp[7],
                      endCompared,
                      index: i
                    })
                  } else {
                    mocs[bp[5]] = bp[2]
                  }
                }
              });
            }
          }
          prices = prices.concat(pricings.map(p => {
            // concatenate MOCs
            let moctype = 'STANDARD', moc = 0;
            if (mocs.hasOwnProperty(p.currency)) {
              moctype = 'SPECIAL';
              moc = mocs[p.currency] ? numberWithCommas(mocs[p.currency]) : mocs[p.currency];
            } else {
              if (this.props.mocs[db].hasOwnProperty(p.currency)) {
                moc = this.props.mocs[db][p.currency];
              }
            }
            const mocNum = mocs[p.currency] ? mocs[p.currency] : 0;
            p.mocNum = mocNum;
            p.moc = `${p.currency}${moc} ${moctype}`;
            return p;
          }));
        }
      }
    }
    // array pricings
    const table = <div className="spec-table scrolling-table no-blue-row boxed-table2 header-review">
    <h4><strong>CUSTOMER SPECIFIC PRICING</strong></h4>
    <ReactTable
        data={
            prices.map((pr, key) => {
              let price = '';
              if (pr.price) {
                if (parseFloat(pr.price)) {
                  price = parseFloat(pr.price);
                }
              }
              return ({
                id: key,
                qty: pr.qty,
                qtyRange: getRange(pr.qty),
                price: `${pr.currency}${price}`,
                priceCell: (
                  <span className="hovered hover-label" onClick={() => {
                    this.openEdit({
                      title: 'EDIT PRICE',
                      type: 'customer-price',
                      subType: 'price',
                      cardcode: pr.cardcode,
                      db,
                      value: pr.price,
                      index: pr.index,
                      currency: pr.currency
                    });
                  }}>
                    <span style={pr.priceCompared ? {color:'orange'}:{}}>{`${pr.currency}${price}`}</span>
                  </span>
                ),
                real_price: pr.price,
                currency: pr.currency,
                currencyCell: (
                  <span className="hovered hover-label" onClick={() => {
                    this.openEdit({
                      title: 'EDIT CURRENCY',
                      type: 'customer-price',
                      subType: 'currency',
                      cardcode: pr.cardcode,
                      db,
                      value: pr.currency,
                      index: pr.index,
                    });
                  }}>
                    <span style={pr.currencyCompared ? {color:'orange'}:{}}>{pr.currency}</span>
                  </span>
                ),
                term: pr.term,
                termCell: (
                  <span className="hovered hover-label" onClick={() => {
                    this.openEdit({
                      title: 'EDIT TERM',
                      type: 'customer-price',
                      subType: 'term',
                      cardcode: pr.cardcode,
                      db,
                      value: pr.term,
                      index: pr.index,
                    });
                  }}>
                    <span style={pr.termCompared ? {color:'orange'}:{}}>{pr.term}</span>
                  </span>
                ),
                start: pr.start,
                end: pr.end,
                effective_dates: `${pr.start} TO ${pr.end}`,
                dateCell: (
                  <span className="hovered hover-label" onClick={() => {
                    this.openEdit({
                      title: 'EDIT DATE',
                      type: 'customer-price',
                      subType: 'date',
                      cardcode: pr.cardcode,
                      db,
                      value: `${pr.start} TO ${pr.end}`,
                      index: pr.index,
                    });
                  }}>
                    <span style={(pr.startCompared || pr.endCompared) ? {color:'orange'}:{}}>{`${pr.start} TO ${pr.end}`}</span>
                  </span>
                ),
                index: pr.index,
                cardcode: pr.cardcode,
                bp_name: pr.bp_name,
                moc: pr.moc,
                mocCell: (
                  <span className={pr.status !== 'REMOVED' ? `hovered hover-label` : ''} onClick={() => {
                    if (pr.status === 'REMOVED') return;
                    console.log('mocNum?',pr.mocNum);
                    this.openEdit({
                      title: 'EDIT MOC',
                      type: 'customer-price',
                      subType: 'moc',
                      db,
                      value: pr.mocNum ? parseFloat(pr.mocNum) : 0,
                      index: pr.index,
                      currency: pr.currency,
                      cardcode: pr.cardcode,
                      start: pr.start,
                      end: pr.end
                    });
                  }}>
                    <span>{pr.moc}</span>
                  </span>
                ),
              })
            })
        }
        filterable
        columns={[
            {
              Header: "Qty",
              accessor: "qtyRange",
              sortable: true,
              filterable: false,
              maxWidth: 40
            },
            {
              Header: "Customer Name",
              accessor: "bp_name",
              sortable: true,
              filterable: false,
            },
            {
              Header: "Shipping Terms",
              accessor: "term",
              sortable: true,
              filterable: false,
              Cell: row => {
                return (
                  <div>{row.original.termCell}</div>
                )
              },
            },
            {
              Header: "Price",
              accessor: "price",
              sortable: true,
              filterable: false,
              Cell: row => {
                return (
                  <div>{row.original.priceCell}</div>
                )
              },
            },
            {
              Header: "Effective Dates",
              accessor: "effective_dates",
              sortable: true,
              filterable: false,
              minWidth: 180,
              Cell: row => {
                return (
                  <div>{row.original.dateCell}</div>
                )
              },
            },
            {
              Header: "Currency",
              accessor: "currency",
              sortable: true,
              filterable: false,
              Cell: row => {
                return (
                  <div>{row.original.currencyCell}</div>
                )
              },
              maxWidth:40
            },
            {
              Header: "MOC",
              accessor: "moc",
              sortable: true,
              filterable: false,
              Cell: row => {
                return (
                  <div>{row.original.mocCell}</div>
                )
              },
            },
        ]}
        minRows={0}
        defaultPageSize={1000}
        showPagination={false}
        className="-highlight"
    /></div>
    return table;
  }

  renderSupplierCost = () => {
    const props = this.props, db = props.data.db, classes = props.classes;
    let prices = [];
    if (props.smasterarray[db]) {
      if (Object.keys(props.smasterarray[db]).length > 0) {
        for (let combo in props.smasterarray[db]) {
          const sup_name = combo.split('[.]')[1],
                cardcode = combo.split('[.]')[0];
          let mocs = {}, pricings = [];
          if (props.smasterarray[db][combo]) {
            if (props.smasterarray[db][combo].length > 0) {
              props.smasterarray[db][combo].forEach((sup,i) => {
                if (sup[0]) {
                  if (parseInt(sup[0])) {
                    let priceCompared = '',
                        currencyCompared = '',
                        termCompared = '',
                        startCompared = '',
                        endCompared = '';

                    if (props.smasterarray_original[db]) {
                      if (props.smasterarray_original[db][combo]) {
                        if (props.smasterarray_original[db][combo][i]) {
                          const smo = props.smasterarray_original[db][combo][i];
                          priceCompared = props.compare2(sup[2], smo[2])
                          currencyCompared = props.compare2(sup[5], smo[5])
                          termCompared = props.compare2(sup[1], smo[1])
                          startCompared = props.compare2(sup[6], smo[6])
                          endCompared = props.compare2(sup[7], smo[7])
                        }
                      }
                    }
                    pricings.push({
                      sup_name,
                      cardcode,
                      qty: parseInt(sup[0]),
                      price: sup[2],
                      priceCompared,
                      currency: sup[5],
                      currencyCompared,
                      term: sup[1],
                      termCompared,
                      start: sup[6],
                      startCompared,
                      end: sup[7],
                      endCompared,
                      index: i
                    })
                  } else {
                    mocs[sup[5]] = sup[2]
                  }
                }
              });
            }
          }
          prices = prices.concat(pricings.map(p => {
            // concatenate MOCs
            let moctype = 'STANDARD', moc = 0;
            if (mocs.hasOwnProperty(p.currency)) {
              moctype = 'SPECIAL';
              moc = mocs[p.currency] ? numberWithCommas(mocs[p.currency]) : 0;
              p.mocNum = mocs[p.currency];
            } else {
              // if (this.props.mocs[db].hasOwnProperty(p.currency)) {
              //   moc = this.props.mocs[db][p.currency] ? numberWithCommas(this.props.mocs[db][p.currency]) : 0;
              //   p.mocNum = this.props.mocs[db][p.currency];
              // }
            }
            p.moc = `${p.currency}${moc} ${moctype}`;
            return p;
          }));
        }
      }
    }
    // array pricings
    const pricesDat = props.item_is[db] === 'Produced in House' ? [] : prices;
    const table = <div className="spec-table scrolling-table no-blue-row boxed-table3 header-review">
    <h4><strong>VENDOR COSTING</strong></h4>
    <ReactTable
        data={
            pricesDat.map((pr, key) => {
              let price = '';
              if (pr.price) {
                if (parseFloat(pr.price)) {
                  price = parseFloat(pr.price);
                }
              }
              return ({
                id: key,
                qty: pr.qty,
                qtyRange: getRange(pr.qty),
                price: `${pr.currency}${price}`,
                real_price: pr.price,
                priceCell: (
                  <span className="hovered hover-label" onClick={() => {
                    this.openEdit({
                      title: 'EDIT PRICE',
                      type: 'supplier-cost',
                      subType: 'price',
                      cardcode: pr.cardcode,
                      db,
                      value: pr.price,
                      index: pr.index,
                      currency: pr.currency
                    });
                  }}>
                    <span style={pr.priceCompared ? orangeObj : {} }>{`${pr.currency}${price}`}</span>
                  </span>
                ),
                currency: pr.currency,
                currencyCell: (
                  <span className="hovered hover-label" onClick={() => {
                    this.openEdit({
                      title: 'EDIT CURRENCY',
                      type: 'supplier-cost',
                      subType: 'currency',
                      cardcode: pr.cardcode,
                      db,
                      value: pr.currency,
                      index: pr.index,
                    });
                  }}>
                    <span style={pr.currencyCompared ? orangeObj : {} }>{pr.currency}</span>
                  </span>
                ),
                term: pr.term,
                termCell: (
                  <span className="hovered hover-label" onClick={() => {
                    this.openEdit({
                      title: 'EDIT TERM',
                      type: 'supplier-cost',
                      subType: 'term',
                      cardcode: pr.cardcode,
                      db,
                      value: pr.term,
                      index: pr.index,
                    });
                  }}>
                    <span style={pr.termCompared ? orangeObj : {} }>{pr.term}</span>
                  </span>
                ),
                start: pr.start,
                end: pr.end,
                effective_dates: `${pr.start} TO ${pr.end}`,
                dateCell: (
                  <span className="hovered hover-label" onClick={() => {
                    this.openEdit({
                      title: 'EDIT DATE',
                      type: 'supplier-cost',
                      subType: 'date',
                      cardcode: pr.cardcode,
                      db,
                      value: `${pr.start} TO ${pr.end}`,
                      index: pr.index,
                    });
                  }}>
                    <span style={(pr.startCompared || pr.endCompared) ? orangeObj : {} }>{`${pr.start} TO ${pr.end}`}</span>
                  </span>
                ),
                index: pr.index,
                cardcode: pr.cardcode,
                sup_name: pr.sup_name,
                moc: pr.moc,
                mocCell: (
                  <span className={pr.status !== 'REMOVED' ? `hovered hover-label` : ''} onClick={() => {
                    if (pr.status === 'REMOVED') return;
                    console.log('mocNum?',pr.mocNum);
                    this.openEdit({
                      title: 'EDIT MOC',
                      type: 'supplier-cost',
                      subType: 'moc',
                      db,
                      value: pr.mocNum ? parseFloat(pr.mocNum) : 0,
                      index: pr.index,
                      currency: pr.currency,
                      cardcode: pr.cardcode,
                      start: pr.start,
                      end: pr.end
                    });
                  }}>
                    <span>{pr.moc}</span>
                  </span>
                ),
                preferredCell: (
                  <Checkbox
                      tabIndex={-1}
                      onClick={(e) => {
                        if (this.props.isPreview) return
                        if (props.preferredVendor[db] !== pr.cardcode) {
                          this.props.updateGINEdit({
                            db,
                            field: 'preferredVendor',
                            value: pr.cardcode
                          })
                        }
                      }}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                          checked: classes.checked
                      }}
                      checked={props.preferredVendor[db] === pr.cardcode}
                  />
                )
              })
            })
        }
        filterable
        columns={[
            {
              Header: "Qty",
              accessor: "qtyRange",
              sortable: true,
              filterable: false,
              maxWidth: 40
            },
            {
              Header: "Supplier Name",
              accessor: "sup_name",
              sortable: true,
              filterable: false,
            },
            {
              Header: "Shipping Terms",
              accessor: "term",
              sortable: true,
              filterable: false,
              Cell: row => {
                return (
                  <div>{row.original.termCell}</div>
                )
              },
            },
            {
              Header: "Cost",
              accessor: "price",
              sortable: true,
              filterable: false,
              Cell: row => {
                return (
                  <div>{row.original.priceCell}</div>
                )
              },
              maxWidth: 60
            },
            {
              Header: "Effective Dates",
              accessor: "effective_dates",
              sortable: true,
              filterable: false,
              minWidth: 180,
              Cell: row => {
                return (
                  <div>{row.original.dateCell}</div>
                )
              },
              maxWidth: 100
            },
            {
              Header: "Currency",
              accessor: "currency",
              sortable: true,
              filterable: false,
              Cell: row => {
                return (
                  <div>{row.original.currencyCell}</div>
                )
              },
              maxWidth: 40
            },
            {
              Header: "MOC",
              accessor: "moc",
              sortable: true,
              filterable: false,
              Cell: row => {
                return (
                  <div>{row.original.mocCell}</div>
                )
              },
            },
            {
              Header: "Preferred Vendor",
              accessor: "preferred_vendor",
              sortable: false,
              filterable: false,
              Cell: row => {
                return (
                  <div>{row.original.preferredCell}</div>
                )
              },
            }
        ]}
        minRows={0}
        defaultPageSize={1000}
        showPagination={false}
        className="-highlight"
    /></div>
    return table;
  }

  renderTargetCost = () => {
    const props = this.props, db = props.data.db;
    let prices = [];
    // single value pricings
    if (props.cprice[db]) {
      let priceCompared = ''
      if (props.cprice_original[db]) {
        priceCompared = props.compare2(props.cprice[db], props.cprice_original[db], true)
      }
      let currencyCompared = ''
      if (props.ccur_original[db]) {
        currencyCompared = props.compare2(props.ccur[db], props.ccur_original[db])
      }
      let termCompared = ''
      if (props.cterm_original[db]) {
        termCompared = props.compare2(props.cterm[db], props.cterm_original[db])
      }
      let startCompared = ''
      if (props.cstart_original[db]) {
        startCompared = props.compare2(props.cstart[db], props.cstart_original[db])
      }
      let endCompared = ''
      if (props.cend_original[db]) {
        endCompared = props.compare2(props.cend[db], props.cend_original[db])
      }
      prices.push({
        qty: 1,
        price: props.cprice[db],
        priceCompared,
        currency: props.ccur[db],
        currencyCompared,
        term: props.cterm[db],
        termCompared,
        start: props.cstart[db],
        startCompared,
        end: props.cend[db],
        endCompared,
        index: -1
      })
    }

    // array prices
    if (props.cprices[db]) {
      if (props.cprices[db].length > 0) {
        props.cprices[db].forEach((pr,i) => {
          if (props.cqtys[db][i]) {
            if (parseInt(props.cqtys[db][i]) != 0) {
              let priceCompared = ''
              if (props.cprices_original[db] && props.cprices[db]) {
                if (props.cprices_original[db][i]) {
                  priceCompared = props.compare2(props.cprices[db][i], props.cprices_original[db][i], true)
                }
              }
              let currencyCompared = ''
              if (props.ccurs_original[db] && props.ccurs[db]) {
                if (props.ccurs_original[db][i]) {
                  currencyCompared = props.compare2(props.ccurs[db][i], props.ccurs_original[db][i])
                }
              }
              let termCompared = ''
              if (props.cterms_original[db]) {
                termCompared = props.compare2(props.cterms[db][i], props.cterms_original[db][i])
              }
              let startCompared = ''
              if (props.cstarts_original[db]) {
                startCompared = props.compare2(props.cstarts[db][i], props.cstarts_original[db][i])
              }
              let endCompared = ''
              if (props.cends_original[db]) {
                endCompared = props.compare2(props.cends[db][i], props.cends_original[db][i])
              }
              prices.push({
                qty: parseInt(props.cqtys[db][i]),
                price: props.cprices[db][i],
                priceCompared,
                currency: props.ccurs[db][i],
                currencyCompared,
                term: props.cterms[db][i],
                termCompared,
                start: props.cstarts[db][i],
                startCompared,
                end: props.cends[db][i],
                endCompared,
                index: i
              });
            }
          }
        });
      }
    }
    // array pricings
    const pricesDat = props.item_is[db] === 'Produced in House' ? [] : prices;
    const table = <div className="spec-table scrolling-table no-blue-row boxed-table4 header-review">
    <h4><strong>TARGET COSTING</strong></h4>
    <ReactTable
        data={
            pricesDat.map((pr, key) => {
              let price = '';
              if (pr.price) {
                if (parseFloat(pr.price)) {
                  price = parseFloat(pr.price);
                }
              }
              return ({
                id: key,
                qty: pr.qty,
                qtyRange: getRange(pr.qty),
                price: `${pr.currency}${price}`,
                priceCell: (
                  <span className="hovered hover-label" onClick={() => {
                    this.openEdit({
                      title: 'EDIT PRICE',
                      type: 'target-cost',
                      subType: 'price',
                      db,
                      value: pr.price,
                      index: pr.index,
                      currency: pr.currency
                    });
                  }}>
                    <span style={pr.priceCompared ? {color:'orange'} : {}}>{`${pr.currency}${price}`}</span>
                  </span>
                ),
                originalPrice: pr.price,
                currency: pr.currency,
                currencyCell: (
                  <span className="hovered hover-label" onClick={() => {
                    this.openEdit({
                      title: 'EDIT CURRENCY',
                      type: 'target-cost',
                      subType: 'currency',
                      db,
                      value: pr.currency,
                      index: pr.index
                    });
                  }}>
                    <span style={pr.currencyCompared ? {color:'orange'} : {}}>{pr.currency}</span>
                  </span>
                ),
                term: pr.term,
                termCell: (
                  <span className="hovered hover-label" onClick={() => {
                    this.openEdit({
                      title: 'EDIT SHIPPING TERM',
                      type: 'target-cost',
                      subType: 'term',
                      db,
                      value: pr.term,
                      index: pr.index
                    });
                  }}>
                    <span style={pr.termCompared ? {color:'orange'} : {}}>{pr.term}</span>
                  </span>
                ),
                start: pr.start,
                end: pr.end,
                effective_dates: `${pr.start} TO ${pr.end}`,
                effectiveDatesCell: (
                  <span className="hovered hover-label" onClick={() => {
                    this.openEdit({
                      title: 'EDIT EFFECTIVE DATES',
                      type: 'target-cost',
                      subType: 'date',
                      db,
                      value: `${pr.start} TO ${pr.end}`,
                      index: pr.index
                    });
                  }}>
                    <span style={(pr.startCompared || pr.endCompared) ? {color:'orange'} : {}}>{`${pr.start} TO ${pr.end}`}</span>
                  </span>
                ),
                index: pr.index
              })
            })
        }
        filterable
        columns={[
            {
              Header: "Qty",
              accessor: "qtyRange",
              sortable: true,
              filterable: false,
              maxWidth: 40
            },
            {
              Header: "Shipping Terms",
              accessor: "term",
              sortable: true,
              filterable: false,
              Cell: row => {
                return (
                  <div>{row.original.termCell}</div>
                )
              },
            },
            {
              Header: "Cost",
              accessor: "price",
              sortable: true,
              filterable: false,
              Cell: row => {
                return (
                  <div>{row.original.priceCell}</div>
                )
              },
            },
            {
              Header: "Effective Dates",
              accessor: "effective_dates",
              sortable: true,
              filterable: false,
              minWidth: 180,
              Cell: row => {
                return (
                  <div>{row.original.effectiveDatesCell}</div>
                )
              },
            },
            {
              Header: "Currency",
              accessor: "currency",
              sortable: true,
              filterable: false,
              Cell: row => {
                return (
                  <div>{row.original.currencyCell}</div>
                )
              },
            },
        ]}
        minRows={0}
        defaultPageSize={1000}
        showPagination={false}
        className="-highlight"
    /></div>
    return table;
  }

  renderPricingCosting = () => {
    return <div className="surround">
    <GridContainer classes={{container:'notpadded'}}>
      <GridItem xs={12} sm={6} md={6} classes={{item:'nopad'}}>{this.renderAllPricing()}</GridItem>
      <GridItem xs={12} sm={6} md={6} classes={{item:'nopad'}}>{this.renderSupplierCost()}</GridItem>
      <GridItem xs={12} sm={6} md={6} classes={{item:'nopad'}}>{this.renderCustomerPricing()}</GridItem>
      <GridItem xs={12} sm={6} md={6} classes={{item:'nopad'}}>{this.renderTargetCost()}</GridItem>
    </GridContainer></div>
  }

  generateInputData = (o) => {
    const valueField = o.valueField ? o.valueField : null;
    const descField = o.descField ? o.descField : null;
    let desc = null;
    if (valueField) {
      desc = getDescription(o.value, o.options, o.valueField, o.descField);
    }
    let addText = '';
    if (o.addText) {
      addText = o.addText;
    }
    return (<span
            className="hovered hover-label"
            onClick={() => {
                if (this.props.isPreview) return;
                this.props.editModeOn();
                this.props.setEdit({
                  itemName: o.name,
                  db: o.db,
                  field: o.field,
                  value: o.value,
                  desc,
                  type: o.type,
                  options: o.options,
                  valueField,
                  descField,
                  addText,
                  similars: o.similars,
                  validations: o.validations
                });
            }}
          >
            {!valueField && <span>{o.value ? o.value+addText : '- none -'}</span>}
            {(valueField && desc) && <span>{desc}</span>}
            {(valueField && !desc) && <span>n/a</span>}
          </span>);
  }

  renderTax = () => {
    const db = this.props.data.db;
    const gstrel_modal = this.generateInputData({
      name: 'GST Relevant',
      db,
      field: 'gst_relevant',
      value: this.props.gst_relevant[db] ? this.props.gst_relevant[db] : '',
      options: this.props.nin_tax.gstrel,
      type: 'option2',
      valueField: 'id',
      descField: 'name',
    });

    const mattype_modal = this.generateInputData({
      name: 'Material Type',
      db,
      field: 'material_type',
      value: this.props.material_type[db] || this.props.material_type[db] == 0 ? this.props.material_type[db] : '',
      options: this.props.nin_tax.mattype,
      type: 'option2',
      valueField: 'id',
      descField: 'name',
    })

    const hsn_code_modal = this.generateInputData({
      name: 'HSN Code',
      db,
      field: 'hsn_code',
      value: this.props.hsn_code[db] ? this.props.hsn_code[db] : '',
      type: 'input'
    });

    const gst_tax_category_modal = this.generateInputData({
      name: 'GST Tax Category',
      db,
      field: 'gst_tax_category',
      value: this.props.gst_tax_category[db] ? this.props.gst_tax_category[db] : '',
      options: this.props.nin_tax.gstcat,
      type: 'option2',
      valueField: 'id',
      descField: 'name',
    });

    const service_acct_code_modal = this.generateInputData({
      name: 'Service Accounting Code',
      db,
      field: 'service_acct_code',
      value: this.props.service_acct_code[db] ? this.props.service_acct_code[db] : '',
      type: 'input'
    });

    return <div>
    <List dense={true}>
      <ListItem>
        <span className="field-label">
          <ListItemText primary="GST Relevant" />
        </span>
        {gstrel_modal}
      </ListItem>
      <ListItem>
        <span className="field-label">
          <ListItemText primary="Material Type" />
        </span>
        {mattype_modal}
      </ListItem>
      <ListItem>
        <span className="field-label">
          <ListItemText primary="HSN Code" />
        </span>
        <span className="hovered hover-label">
          <span>
            {hsn_code_modal}
          </span>
        </span>
      </ListItem>
      <ListItem>
        <span className="field-label">
          <ListItemText primary="GST Tax Category" />
        </span>
        {gst_tax_category_modal}
      </ListItem>
      <ListItem>
        <span className="field-label">
          <ListItemText primary="Service Accounting Code" />
        </span>
        {service_acct_code_modal}
      </ListItem>
    </List>
    </div>
  }

  renderBOM = () => {
    console.log('thiss',this.props,this.props.rms);
    const db = this.props.data.db;
    let bom_info = '';
    if (this.props.rms) {
      bom_info = this.props.rms[db].map((rms,i) => {
        const desc = this.props.rmsd[db][i];
        if (rms && desc) {
          const rm_code = rms.split(':')[0] === 'RM' ? rms.substring(3) : rms;
          const rm_description = desc.split(':')[0] === 'RM' ? desc.substring(3) : desc;
          const rmsqStyle = {};
          if (this.props.rmsq_original[db]) {
            if (this.props.compare2(this.props.rmsq[db][i], this.props.rmsq_original[db][i], true)) {
              console.log('orange', this.props.rmsq[db][i], this.props.rmsq_original[db][i], true);
              rmsqStyle.color = 'orange'
            }
          }

          const rmswStyle = {};
          if (this.props.rmsw_original[db]) {
            if (this.props.compare2(this.props.rmsw[db][i], this.props.rmsw_original[db][i])) {
              rmswStyle.color = 'orange'
            }
          }
          console.log('found rmsq', this.props.rmsq[db][i])
          return [
            rm_code,
            rm_description,
            <span className="hovered hover-label" onClick={(e) => {
              e.preventDefault();
              if (this.props.isPreview) return
              this.openEdit({
                title: 'EDIT Raw Material Quantity',
                db,
                value: this.props.rmsq[db][i],
                type: 'rmsq',
                index: i
              });
            }}>
              <span style={rmsqStyle}>{this.props.rmsq[db][i]}</span>
            </span>,
            <span className="hovered hover-label" onClick={(e) => {
              e.preventDefault();
              if (this.props.isPreview) return
              this.openEdit({
                title: 'EDIT Raw Material Warehouse',
                db,
                value: this.props.rmsw[db][i],
                type: 'rmsw',
                index: i,
                rm_code, rm_description
              });
            }}>
              <span style={rmswStyle}>{this.props.rmsw[db][i]}</span>
            </span>
          ]
        }
        return '';
      });
    }

    const tableHead = ["Raw Material Code","Raw Material Description", "Qty. Required to Produce", "Raw Material Warehouse"].map(h => <div className="bold">{h}</div>);
    const bomItemBaseQtyStyle = {};
    if (this.props.compare2(this.props.BOMItemBaseQty[db], this.props.BOMItemBaseQty_original[db])) {
      bomItemBaseQtyStyle.color = 'orange'
    }
    return <div>
    <div style={{marginTop:20,marginBottom:20,marginLeft:8}}>
      <strong>BOM Item Base Qty : </strong>
      <span className="hovered hover-label" onClick={() => {
        if (this.props.isPreview) return
        this.openEdit({
          title: 'EDIT BOM Item Base Quantity',
          db,
          value: this.props.BOMItemBaseQty[db],
          type: 'BOMItemBaseQty'
        });
      }}>
        <span style={bomItemBaseQtyStyle}>{this.props.BOMItemBaseQty[db]}</span>
      </span>
    </div>
    <Table
      tableHeaderColor="primary"
      tableHead={tableHead}
      tableData={bom_info}
    />
    </div>
  }

  render() {
    console.log('st and props ---', this.state, this.props);
    const { data } = this.props;
    let maxWidth = 'lg';
    if (data.type === 'bom') {
      maxWidth = 'md'
    }
    if (data.type === 'tax') {
      maxWidth = 'sm'
    }
    return (
      <Dialog
        open={this.props.open}
        keepMounted
        onClose={this.props.close}
        maxWidth={maxWidth}
        fullWidth={true}
        classes={{paper:'maxDialog'}}
      >
        <DialogTitle>
          {(data.type === 'pricing_costing' && !this.props.isPreview) && <div className="parentable">
            <div className="review-popup-1">
              <Button color="rose" onClick={() => {
                this.props.goToDetailed(data.db);
              }}>VIEW DETAILED SUMMARY</Button>
            </div>
          </div>}
          <div style={{textAlign:'center'}}>{data.title} <DBlabel name={data.db} /></div>
          <div className="close-btn">
            <IconButton aria-label="Close" onClick={this.props.close}>
              <Close />
            </IconButton>
          </div>
        </DialogTitle>
        <div style={{margin:20}}>
          {data.type === 'bom' && <div>{this.renderBOM()}</div>}
          {data.type === 'pricing_costing' && <div>{this.renderPricingCosting()}</div>}
          {data.type === 'tax' && <div>{this.renderTax()}</div>}
        </div>
        {
          this.state.edit && <GINreviewDialogEdit
            open={this.state.edit}
            close={this.closeEdit}
            data={this.state.data}
            checkDupes={this.props.checkDupes}
            checkDupesSupplierCost={this.props.checkDupesSupplierCost}
            checkDupesTargetCost={this.props.checkDupesTargetCost}
            checkDupesCustomerPrice={this.props.checkDupesCustomerPrice}
          />
        }
      </Dialog>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  if (ownProps.isPreview && !ownProps.isHistory) {
    console.log('previewData', ownProps.previewData);
    return ownProps.previewData;
  }
  return {
    // BOM
    rms: state.gin.rms,
    rmsd: state.gin.rmsd,
    rmsq: state.gin.rmsq,
    rmsw: state.gin.rmsw,
    rms_original: state.gin.rms_original,
    rmsd_original: state.gin.rmsd_original,
    rmsq_original: state.gin.rmsq_original,
    rmsw_original: state.gin.rmsw_original,
    BOMItemBaseQty: state.gin.BOMItemBaseQty,
    BOMItemBaseQty_original: state.gin.BOMItemBaseQty_original,

    // PRICING FOR ALL
    gcur: state.gin.gcur,
    gstart: state.gin.gstart,
    gend: state.gin.gend,
    gprice: state.gin.gprice,
    gterm: state.gin.gterm,

    gterms: state.gin.gterms,
    gstarts: state.gin.gstarts,
    gends: state.gin.gends,
    gprices: state.gin.gprices,
    gcurrs: state.gin.gcurrs,
    gqtys: state.gin.gqtys,
    mocs: state.gin.mocs,
    specialmoc: state.gin.specialmoc,

    gcodes_original: state.gin.gcodes_original,
    gcode_original: state.gin.gcode_original,
    gcur_original: state.gin.gcur_original,
    gstart_original: state.gin.gstart_original,
    gend_original: state.gin.gend_original,
    gprice_original: state.gin.gprice_original,
    gterm_original: state.gin.gterm_original,
    gterms_original: state.gin.gterms_original,
    gstarts_original: state.gin.gstarts_original,
    gends_original: state.gin.gends_original,
    gprices_original: state.gin.gprices_original,
    gcurrs_original: state.gin.gcurrs_original,
    gqtys_original: state.gin.gqtys_original,

    // CUSTOMER SPECIFIC PRICING
    bmasterarray: state.gin.bmasterarray,
    bmasterarray_original: state.gin.bmasterarray_original,

    // TARGET COST
    cprice: state.gin.cprice,
    cterm: state.gin.cterm,
    ccur: state.gin.ccur,
    cstart: state.gin.cstart,
    cend: state.gin.cend,

    cqtys: state.gin.cqtys,
    cterms: state.gin.cterms,
    ccurs: state.gin.ccurs,
    cprices: state.gin.cprices,
    cstarts: state.gin.cstarts,
    cends: state.gin.cends,

    cprice_original: state.gin.cprice_original,
    cterm_original: state.gin.cterm_original,
    ccur_original: state.gin.ccur_original,
    cstart_original: state.gin.cstart_original,
    cend_original: state.gin.cend_original,

    cqtys_original: state.gin.cqtys_original,
    cterms_original: state.gin.cterms_original,
    ccurs_original: state.gin.ccurs_original,
    cprices_original: state.gin.cprices_original,
    cstarts_original: state.gin.cstarts_original,
    cends_original: state.gin.cends_original,

    ccode_original: state.gin.ccode_original,
    ccodes_original: state.gin.ccodes_original,

    // SUPPLIER COST
    smasterarray: state.gin.smasterarray,
    smasterarray_original: state.gin.smasterarray_original,
    preferredVendor: state.gin.preferredVendor,

    // TAX
    gst_relevant: state.gin.gst_relevant,
    material_type: state.gin.material_type,
    hsn_code: state.gin.hsn_code,
    gst_tax_category: state.gin.gst_tax_category,
    service_acct_code: state.gin.service_acct_code,
    nin_tax: state.gin.nin_tax,

    options: state.gin.options,
    item_is: state.gin.item_is
  }
}

const mapDispatchToProps = dispatch => {
  return {
    editModeOn: () => {
      dispatch(actions.editModeOn());
    },
    setEdit: (data) => {
      dispatch(actions.setGINEdit(data));
    },
    updateGINEdit: (data) => {
      dispatch(actions.updateGINEdit(data));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(regularFormsStyle)(GINreviewDialog));
