import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import SweetAlert from "react-bootstrap-sweetalert";

const GINask = props => {
  return (
    <SweetAlert
      warning
      showCancel
      confirmBtnText="Yes"
      cancelBtnText="No"
      confirmBtnCssClass={
        props.classes.button + " " + props.classes.success
      }
      cancelBtnCssClass={
        props.classes.button + " " + props.classes.default
      }
      title="Are you sure?"
      onConfirm={() => {
        if (props.askRequest === 'deleteRow') {
          props.command();
        }
        props.close();
      }}
      onCancel={props.close}
      >
      {props.askRequest === 'deleteRow' && <div>{props.content}</div>}
    </SweetAlert>
  );
}

export default withStyles(sweetAlertStyle)(GINask);
