import React from "react";
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import checkPermissions from '../../shared/checkPermissions';
import checkPermissionsActions from '../../shared/checkPermissionsActions';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Cached from "@material-ui/icons/Cached";
import Translations from "containers/Translations/Translations";
import TranslationsModal from "containers/Translations/TranslationsModal";
import TranslationsSearch from "containers/Translations/TranslationsSearch";
import SweetAlert from "react-bootstrap-sweetalert";
import styles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import combineStyles from "shared/combineStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import { options, findData, findTitle } from "containers/Translations/TranslationsObj";
import { FadeLoader } from 'react-spinners';

const convertRBOData = (arr) => {
  if (!arr) return null;
  const obj = {};
  arr.forEach(a => {
    obj[a.FirmCode] = a.FirmName;
  })
  return obj;
}

class TranslationsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: 'SEARCH',
      rbo_data: null,
      single_data: null,
      action_msg: null,
      openModal: false,
      openAskModal: false,
      currentActionData: null,
      existing_keys: null,
      searchModal: false
    }
  }

  setMode = (mode) => {
    this.setState({mode})
  }

  openSearch = () => {
    this.setState({searchModal:true});
  }

  closeSearch = () => {
    this.setState({searchModal:false});
  }

  openAskModal = () => {
    this.setState({openAskModal:true});
  }

  closeAskModal = () => {
    this.setState({openAskModal:false});
  }

  delete = (data) => {
    this.setState({
      currentActionData: {
        type: 'DELETE',
        data
      },
      openAskModal: true
    })
  }

  update = (data) => {
    this.setState({
      currentActionData: {
        type: 'UPDATE',
        data
      },
      openAskModal: true
    })
  }

  create = (table_name) => {
    if (table_name) {
      const table_name_arr = table_name.split('_');
      if (table_name_arr[3]) {
        const type = table_name_arr[3];
        const new_single_data = {};
        if (this.props.data) {
          Object.keys(this.props.data).forEach((table) => {
            const tableData = this.props.data[table];
            if (tableData) {
              if (tableData.length > 0) {
                const new_data = type === 'RBOSpecific' ? {RBO:''} : {};
                options.lang.forEach((lang) => {
                  new_data[lang] = '';
                });
                new_single_data[table] = [new_data];
                this.setState({
                  single_data:new_single_data,
                  currentActionData: {
                    type: 'CREATE',
                    data: null
                  }
                });
                this.openModal();
              }
            }
          });
        } else {
          const new_data = type === 'RBOSpecific' ? {RBO:''} : {};
          options.lang.forEach((lang) => {
            new_data[lang] = '';
          });
          new_single_data[table_name] = [new_data];
          this.setState({
            single_data:new_single_data,
            currentActionData: {
              type: 'CREATE',
              data: null
            }
          });
          this.openModal();
        }
      }
    }

  }

  openModal = (currentActionData) => {
    if (currentActionData) {
      this.setState({openModal:true, currentActionData});
    } else {
      this.setState({openModal:true});
    }
  }

  closeModal = () => {
    this.setState({openModal:false, action_msg: ''});
  }

  clearMsg = () => {
    this.setState({action_msg: null});
  }

  componentDidMount() {
    this.props.clearTranslations();
    this.props.checkMaintenanceMode();
    this.props.loadRBOData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.rbo_data !== this.props.rbo_data) {
      this.setState({
        rbo_data: convertRBOData(this.props.rbo_data)
      })
    }

    if (prevProps.single_data !== this.props.single_data) {
      this.setState({
        single_data: this.props.single_data
      })
    }

    if (prevProps.action_msg !== this.props.action_msg) {
        this.setState({
          action_msg: this.props.action_msg ? this.props.action_msg : ''
        }, () => {
          setTimeout(() => {
            this.setState({
              action_msg: ''
            })
          }, 4000)
        });
        if (this.props.action_msg) {
          if (this.props.action_msg.indexOf('Success') !== -1) {
            if (this.state.currentActionData) {
              if (this.state.currentActionData.type === 'CREATE') {
                this.closeModal();
              }
            }
          }
        }
    }

    if (prevProps.data !== this.props.data) {
      if (this.props.data) {
        if (typeof this.props.data === 'object') {
          const title = findTitle(this.props.data);
          if (title) {
            const title_arr = title.split('_');
            if (title_arr[3]) {
              let primary_key = 'RBO';
              if (title_arr[3] === 'BASE') {
                primary_key = 'ENGLISH';
              }
              this.setState({
                existing_keys: findData(this.props.data).map(d => {
                  return d[primary_key];
                })
              });
            }
          }
      
        }
      }
    }
  }

  render() {
    if (!checkPermissions('TranslationsCRUD')) {
      return <Redirect to="/dashboard" />;
    }
    if (this.props.maintenanceMode === 'yes') return <Redirect to="/components/maintenance_page" />
    const actionPerms = checkPermissionsActions('TranslationsCRUD');
    const update_permitted = actionPerms ? actionPerms.hasOwnProperty('update') ? true : false : false;

    console.log('st pp', this.state, this.props, actionPerms);

    return (
  <div>
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <div className="pointed" onClick={() => { 
                this.props.clearTranslations();
                this.props.loadRBOData();
              }}><Cached /></div>
            </CardIcon>
            <CardIcon
              color={this.state.mode === 'SEARCH' ? 'success' : 'rose'}
              className="pointed bold-on-hover"
              onClick={() => {
                this.setMode('SEARCH');
              }}
            >
              <strong>SEARCH</strong>
            </CardIcon>
            {update_permitted && <CardIcon
              color={this.state.mode === 'TABLES' ? 'success' : 'rose'}
              className="pointed bold-on-hover"
              onClick={() => {
                this.setMode('TABLES');
              }}
            >
              <strong>ADD / UPDATE</strong>
            </CardIcon>}
          </CardHeader>
          <CardBody>
            {this.props.loading && <div className="center-loader">
              <FadeLoader />
            </div>}
            {this.state.mode === 'SEARCH' && <div>
              <h2>Translations Search</h2>
              <TranslationsSearch 
                rbo_data={this.props.rbo_data}
                searchTranslation={this.props.searchTranslation}
                data={this.props.searchResults}
                openModal={this.openModal}
                readTranslation={this.props.readTranslation}
                update_permitted={update_permitted}
              />
            </div>}
            {this.state.mode === 'TABLES' && <div>
              <h2>Translations Add / Update</h2>
              <Translations
                readTranslations={this.props.readTranslations}
                readTranslation={this.props.readTranslation}
                data={this.props.data}
                rbo_data={this.state.rbo_data}
                single_data={this.props.single_data}
                openModal={this.openModal}
                create={this.create}
                update_permitted={update_permitted}
                clearTranslations={this.props.clearTranslations}
              />
            </div>}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
    <TranslationsModal
      open={this.state.openModal}
      close={this.closeModal}
      single_data={this.state.single_data} // single entry
      delete={this.delete}
      update={this.update}
      loading={this.props.loading}
      createTranslation={this.props.createTranslation}
      update_permitted={update_permitted}
      action={this.state.currentActionData}
      suggestions={this.props.suggestions}
      missingLanguages={this.props.missingLanguages}
      rbo_data={this.props.rbo_data}
      existing_keys={this.state.existing_keys}
      action_msg={this.state.action_msg}
    />
    {this.state.openAskModal && <SweetAlert
        warning
        title="Are you sure ?"
        style={{ display: "block" }}
        showCancel={true}
        confirmBtnText="OK"
        confirmBtnCssClass={
          this.props.classes.button + " " + this.props.classes.success
        }
        cancelBtnCssClass={
          this.props.classes.button + " " + this.props.classes.rose
        }
        cancelBtnText="CANCEL"
        onCancel={() => {
          this.closeAskModal();
        }}
        onConfirm={() => {
          console.log('doing action', this.state.currentActionData);
          if (this.state.currentActionData) {
            if (this.state.currentActionData.type === 'DELETE') {
              this.props.deleteTranslation(this.state.currentActionData.data);
              this.closeAskModal();
              this.closeModal();
              if (this.state.mode === 'SEARCH') {
                setTimeout(() => {
                  this.setMode('TABLES');
                  setTimeout(() => {
                    this.setMode('SEARCH');
                  }, 500);
                }, 1000);
                
              }
            }
            if (this.state.currentActionData.type === 'UPDATE') {
              this.props.updateTranslation(this.state.currentActionData.data);
              this.closeAskModal();
              this.closeModal();
            }
          }
        }}
        >
          {this.state.currentActionData && <div>
              {this.state.currentActionData.type === 'DELETE' ?
                <div>Do you want to delete this translation entry ?</div> : null
              }
              {this.state.currentActionData.type === 'UPDATE' ?
                <div>Do you want to update this translation entry ?</div> : null
              }
            </div>}
        </SweetAlert>}
  </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    maintenanceMode: state.userPerms.maintenanceMode,
    data: state.translations.data ?
      typeof state.translations.data === 'string' ? null : state.translations.data
      :
      null,
    rbo_data: state.translations.rbo_data,
    single_data: state.translations.single_data,
    loading: state.translations.loading,
    action_msg: state.translations.action_msg,
    searchResults: state.translations.searchResults,
    suggestions: state.translations.suggestions,
    missingLanguages: state.translations.missingLanguages
  }
}

const mapDispatchToProps = dispatch => {
  return {
    checkMaintenanceMode: () => {
      dispatch(actions.checkMaintenanceMode());
    },
    readTranslations: (options) => {
      dispatch(actions.readTranslations(options));
    },
    readTranslation: (options) => {
      dispatch(actions.readTranslation(options));
    },
    createTranslation: (data) => {
      dispatch(actions.createTranslation(data));
    },
    updateTranslation: (data) => {
      dispatch(actions.updateTranslation(data));
    },
    deleteTranslation: (data) => {
      dispatch(actions.deleteTranslation(data));
    },
    loadRBOData: () => {
      dispatch(actions.loadRBOData());
    },
    clearTranslations: (except_rbo) => {
      dispatch(actions.clearTranslations(except_rbo));
    },
    searchTranslation: (data) => {
      console.log('searching', data)
      dispatch(actions.searchTranslation(data));
    }
  }
}

const combinedStyles = combineStyles(sweetAlertStyle, styles);
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(combinedStyles)(TranslationsPage));
