import { createSelector } from 'reselect'

const getCountry = state => state.ad.activeCountry;

const getOffice = state => state.ad.activeOffice;

const getDepartment = state => state.ad.activeDepartment;

const getDirectory = state => state.ad.directory;

export const getCountryOptions = state => state.ad.directory.map(country => country.name);

/*
    Extracting Country object from Directory Structure
*/
export const getDirectoryCountry = createSelector(
    [getCountry, getDirectory],
    (country, directory) => {
        if (country) {
            return directory.find(d => d.name === country);
        }
        return {};
    }
);

/*
    Extracting Office object from Directory Structure
*/
export const getDirectoryOffice = createSelector(
    [getDirectoryCountry, getOffice],
    (country, office) => {
        if (country.offices && office) {
            console.log('aqui');
            return country.offices.find(o => o.name === office);
        }
        return {};
    }
);


export const getOfficeOptions = createSelector(
    [getDirectoryCountry],
    (country) => {
        if (country.offices) {
            return country.offices.map(office => office.name);
        }
        return []
    }
);

export const getDepartmentOptions = createSelector(
    [getDirectoryOffice],
    (office) => {
        console.log('whoa', office);
        if (office && office.departments) {
            console.log('hai');
            return office.departments.map(dept => dept.name);
        }
        return [];
    }
);

/*
    Extracting department Group and OU DNs from Directory structure
*/
export const getDepartmentDetails = createSelector(
    [getDirectoryOffice, getDepartment],
    (office, department) => {
        if (office && department) {
            return office.departments.find(dept => dept.name === department);
        }
        return {};
    }
)

const getActiveUserIndex = state => state.ad.activeUser;

const getUsers = state => state.ad.users;

export const getActiveUser = createSelector(
    [getActiveUserIndex, getUsers],
    (userIndex, users) => {
        const activeUser = {
            dn: '',
            givenName: '',
            sn: '',
            mail: '',
            country: '',
            office: '',
            department: '',
            memberOf: '',
            activeSecurityGroup: '',
            status: true
        }
        if (Number.isInteger(userIndex) && users[userIndex]) {
            const user = users[userIndex];
            activeUser.dn = user.dn;
            activeUser.givenName = user.givenName;
            activeUser.sn = user.sn;
            activeUser.mail = user.mail;
            activeUser.country = user.country;
            activeUser.office = user.office;
            activeUser.department = user.department;
            activeUser.memberOf = user.memberOf
            activeUser.activeSecurityGroup = user.memberOf;
            activeUser.status = user.status === 'Active';
        }
        return activeUser;
    }
);

