import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import * as actions from '../../store/actions';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import Button2 from "components/CustomButtons/Button.jsx";
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Typography from '@material-ui/core/Typography';
import Settings from "@material-ui/icons/Settings";
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";
import combineStyles from "shared/combineStyles";
import moment from "moment";

const dialogStyles = {
  paper: {
    overflowX: 'hidden'
  }
};

const DialogTitle = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500],
  },
}))(props => {
  const { children, classes, onClose, title, isFromSubGroup, subGroupData, modalMetaData } = props;
  console.log('titleprops', subGroupData, modalMetaData, props);
  let lastPosition = false,
      firstPosition = false,
      isSingle = true,
      pos = null;
  if (isFromSubGroup) {
    if (subGroupData.length > 1) {
      isSingle = false;
    }
    const id = modalMetaData.id;
    if (subGroupData[0].id === id) {
      firstPosition = true;
    } else if (subGroupData[subGroupData.length-1].id === id) {
      lastPosition = true;
    }
    subGroupData.forEach((sgd, i) => {
      if (sgd.id == id) {
        pos = i;
      }
    });
  }
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <div style={{textAlign:'center'}}>
        {
          (isFromSubGroup && !isSingle) &&
          <Button
            color="white"
            onClick={() => {
              const dat = subGroupData[pos-1];
              console.log('backwards', pos, dat);
              props.closeHistoryModal();
              props.openHistoryModal({
                id: dat.id,
                activity: dat.activity,
                approver: dat.name,
                date_added: dat.timestamp
              });
              props.onFetchGMPHistoryItem(dat.id, dat.submission_id);
              props.fetchAddInfoList(dat.DB);
            }}
            disabled={firstPosition}
          >
            <ArrowBack />
          </Button>
        }
        <span style={{fontSize:'18px'}}><strong>{title}</strong></span>
        {
          (isFromSubGroup && !isSingle) &&
          <Button
            color="white"
            onClick={() => {
              const dat = subGroupData[pos+1];
              console.log('forwards', dat);
              props.closeHistoryModal();
              props.openHistoryModal({
                id: dat.id,
                activity: dat.activity,
                approver: dat.name,
                date_added: dat.timestamp
              });
              props.onFetchGMPHistoryItem(dat.id, dat.submission_id);
              props.fetchAddInfoList(dat.DB);
            }}
            disabled={lastPosition}
          >
            <ArrowForward />
          </Button>
        }
      </div>
      <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit,
  },
}))(MuiDialogActions);

class GMPHistoryModal extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.gsttypes) {
      if (this.props.gsttypes.length === 0) {
        this.props.fetchGSTType();
      }
    }
  }

  renderLabel = (label, content, type, additionalInfo) => {
    const bp = this.props.bp;
    let previousContent = '';
    let finalContent = bp[content];
    let isGreen = false;
    if (bp.changes) {
      if (bp.changes[content]) {
        finalContent = bp.changes[content];
        previousContent = bp[content];
        isGreen = true;
      }
    }

    if (type) {
      if (type === 'withholding') {
        finalContent = finalContent ? 'Yes' : 'No';
      }
      if (type === 'accrual_criteria') {
        finalContent = finalContent === '1' ? 'Accrual' : 'Cash'
      }
      if (type === 'surcharge_overlook') {
        finalContent = finalContent === '1' ? 'Yes' : 'No'
      }
      if (type === 'threshold_overlook') {
        finalContent = finalContent === '1' ? 'Yes' : 'No'
      }
      if (type === 'assessee_type') {
        finalContent = finalContent === '0' ? 'Company' : 'Others'
      }
      if (type === 'wtaxcodes') {
        let wtCodeNames = [];
        if (bp.changes) {
          const wtaxcodes = bp.changes.wtaxcodes;
          if (wtaxcodes && this.props.addinfo) {
            if (wtaxcodes.length > 0) {
              if (this.props.addinfo.owhtopts) {
                if (this.props.addinfo.owhtopts.length > 0) {
                  wtaxcodes.forEach((wtx) => {
                    this.props.addinfo.owhtopts.forEach((o) => {
                      if (o.WTCode === wtx) {
                        wtCodeNames.push(o.WTName);
                      }
                    });
                  });
                }
              }
            }
          }
        }
        finalContent = wtCodeNames.map((wtc,i) => {
          return (
            <div key={wtc}>{wtc}</div>
          )
        });
      }
      if (type === 'country') {
        if (this.props.countries) {
          this.props.countries.forEach((c) => {
            if (c.id === finalContent) {
              finalContent = c.name;
            }

            if (previousContent) {
              if (c.id === previousContent) {
                previousContent = c.name;
              }
            }
          });
        }
      }

      if (type === 'state') {
        if (Object.keys(this.props.states).length > 0) {
          console.log('state search', this.props.states, additionalInfo);
          if (this.props.states.hasOwnProperty(additionalInfo)) {
            this.props.states[additionalInfo].forEach((c) => {
              if (c.id === finalContent) {
                finalContent = c.name;
              }

              if (previousContent) {
                if (c.id === previousContent) {
                  previousContent = c.name;
                }
              }
            });
          }
        }
      }

      if (type === 'currency') {
        if (this.props.currencies) {
          this.props.currencies.forEach((c) => {
            if (c.id === finalContent) {
              finalContent = c.name;
            }

            if (previousContent) {
              if (c.id === previousContent) {
                previousContent = c.name;
              }
            }
          });
        }
      }
    }

    if (type === 'gsttype') {
      if (this.props.gsttypes) {
        if (this.props.gsttypes.length > 0) {
          this.props.gsttypes.forEach((c) => {
            if (c.id === finalContent) {
              finalContent = c.type;
            }

            if (previousContent) {
              if (c.id === previousContent) {
                previousContent = c.type;
              }
            }
          });
        }
      }
    }

    if (type === 'notes') {
      if (!finalContent) {
        finalContent = 'n/a';
      }
      return <div style={{fontSize:'12px'}}>{finalContent}</div>;
    }

    if (finalContent) {
      if (previousContent || isGreen) {
        finalContent = <span style={{color:'green'}}>{finalContent}</span>
      }
    } else {
      finalContent = 'n/a';
    }

    if (previousContent) {
      previousContent = <span style={{color:'red'}}>( changed from {previousContent} )</span>
    }

    return <div style={{fontSize:'12px'}}><strong>{label} : </strong> {finalContent} {previousContent}</div>;
  }

  render() {
    let { bp, classes } = this.props;
    console.log('historymodalprops',this.props);
    let content = <div>Loading</div>;
    let title = '';
    console.log(bp);
    if (bp) {
      let isNMX = false,
          isNGT = false,
          isWithholding = false,
          isNIN = false;

      if (bp.DB === 'NMX_TEST' || bp.DB === 'NMX_LIVE') {
        isNMX = true;
      }

      if (bp.DB === 'NGT_TEST' || bp.DB === 'NGT_LIVE') {
        isNGT = true;
      }

      if (bp.DB === 'NIN_TEST' || bp.DB === 'NIN_LIVE') {
        isNIN = true;
      }

      if (bp.changes) {
        if (bp.changes.withholding) {
          isWithholding = true;
        }
      }

      const metaData = this.props.modalMetaData;
      const activity = metaData.activity,
            user = metaData.user,
            date_added = metaData.date_added ? moment(metaData.date_added).format("MM-DD-YYYY HH:mm") : '',
            type = bp.customer_num ? 'EXISTING BP' : 'NEW BP';
      title = `${activity} (${type}) by ${user} on ${date_added}`;

      let hasOtherContact = false;
      if (bp.other_name) {
        hasOtherContact = true;
        bp.other_tel = bp.other_telephone;
      }
      if (bp.changes) {
        if (bp.changes.other_name) {
          hasOtherContact = true;
        }
      }

      bp.accounts_payable_tel = bp.accounts_payable_telephone;
      bp.purchasing_tel = bp.purchasing_telephone;

      content = (
        <div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h4>General Info</h4>
              {bp.company_name && <div>
                {this.renderLabel('Card Code', 'card_code')}
                {this.renderLabel('Company Name', 'company_name')}
                {this.renderLabel('Database', 'DB')}
                {isNMX && this.renderLabel('RFC CURP','rfc_curp')}
                {isNMX && this.renderLabel('Group Code','groupcode')}
                {isNGT && this.renderLabel('NIT','nit')}
                {isNIN && this.renderLabel('PANNo.','panno')}
                {isNIN && this.renderLabel('GSTINo.','tinno')}
                {isWithholding && this.renderLabel('Assessee Type', 'assessee_type', 'assessee_type')}
                {isWithholding && this.renderLabel('WTax Codes', 'wtaxcodes', 'wtaxcodes')}
              </div>}
              {!bp.company_name && <div>
                {this.renderLabel('Customer Name', 'customer_name')}
                {this.renderLabel('Database', 'DB')}
                {this.renderLabel('Customer Number','customer_num')}
                {this.renderLabel('Invoice Number','invoice_num')}
              </div>}
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <h4 style={{opacity:0}}>General Info</h4>
              {bp.company_name && <div>
                {this.renderLabel('General Telephone', 'gen_tel')}
                {this.renderLabel('General Fax', 'gen_fax')}
                {this.renderLabel('Invoice Number', 'invoice_num')}
                {this.renderLabel('Currency', 'currency', 'currency')}
                {isNIN && this.renderLabel('Withholding', 'withholding', 'withholding')}
                {isWithholding && this.renderLabel('Threshold Overlook', 'threshold_overlook', 'threshold_overlook')}
                {isWithholding && this.renderLabel('Surcharge Overlook', 'surcharge_overlook', 'surcharge_overlook')}
                {isWithholding && this.renderLabel('Accrual Criteria', 'accrual_criteria', 'accrual_criteria')}
                {isWithholding && this.renderLabel('Expiration Date', 'expiration_date')}
                {isWithholding && this.renderLabel('Certificate No.', 'certificate_num')}
                {isWithholding && this.renderLabel('NI Number', 'ni_num')}
              </div>}
            </GridItem>
          </GridContainer>
        {bp.company_name && <div>
          <hr />
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h4>Billing Address</h4>
              {this.renderLabel('Name','billing_addressname')}
              {this.renderLabel('Address 1','billing_address1')}
              {this.renderLabel('Address 2','billing_address2')}
              {this.renderLabel('City','billing_city')}
              {this.renderLabel('State','billing_state', 'state', bp.billing_country)}
              {this.renderLabel('Country','billing_country', 'country')}
              {isNIN && this.renderLabel('GST Reg. No.','billing_gstregno')}
              {isNIN && this.renderLabel('GST Reg. Type','billing_gstregtype', 'gsttype')}
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <h4>Shipping Address</h4>
              {this.renderLabel('Name','shipping_addressname')}
              {this.renderLabel('Address 1','shipping_address1')}
              {this.renderLabel('Address 2','shipping_address2')}
              {this.renderLabel('City','shipping_city')}
              {this.renderLabel('State','shipping_state', 'state', bp.shipping_country)}
              {this.renderLabel('Country','shipping_country', 'country')}
              {isNIN && this.renderLabel('GST Reg. No.','shipping_gstregno')}
              {isNIN && this.renderLabel('GST Reg. Type','shipping_gstregtype', 'gsttype')}
            </GridItem>
          </GridContainer>
        </div>}
          <hr />
          <GridContainer>
            {bp.company_name && <GridItem xs={12} sm={12} md={6}>
              <h4>Accounts Payable Contact</h4>
              {this.renderLabel('First Name','accounts_payable_firstname')}
              {this.renderLabel('Last Name','accounts_payable_lastname')}
              {this.renderLabel('Position','accounts_payable_position')}
              {this.renderLabel('Telephone','accounts_payable_tel')}
              {this.renderLabel('Email','accounts_payable_email')}
            </GridItem>}
            {bp.company_name && <GridItem xs={12} sm={12} md={6}>
            <h4>Purchasing Contact</h4>
            {this.renderLabel('First Name','purchasing_firstname')}
            {this.renderLabel('Last Name','purchasing_lastname')}
            {this.renderLabel('Position','purchasing_position')}
            {this.renderLabel('Telephone','purchasing_tel')}
            {this.renderLabel('Email','purchasing_email')}
            </GridItem>}
            {!bp.company_name && <GridItem xs={12} sm={12} md={6}>
            <h4>New Contact</h4>
            {this.renderLabel('First Name','new_firstname')}
            {this.renderLabel('Last Name','new_lastname')}
            {this.renderLabel('Position','new_position')}
            {this.renderLabel('Telephone','new_tel')}
            {this.renderLabel('Email','new_email')}
            </GridItem>}
          </GridContainer>
          {hasOtherContact &&
            <div>
              <br />
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                    <h4>Other Contact</h4>
                    {this.renderLabel('First Name','other_firstname')}
                    {this.renderLabel('Last Name','other_lastname')}
                    {this.renderLabel('Position','other_position')}
                    {this.renderLabel('Telephone','other_tel')}
                    {this.renderLabel('Email','other_email')}
                </GridItem>
              </GridContainer>
            </div>
          }
          <hr />
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <h4>Notes</h4>
              {this.renderLabel('','notes', 'notes')}
            </GridItem>
          </GridContainer>
        </div>
      )
    }
    return (
      <div className="dialog-overflow">
        <Dialog
          onClose={() => {
            this.props.closeHistoryModal();
            if (this.props.isFromSubGroup) {
              this.props.openHistorySubGroupModal(this.props.subGroupData)
            }
          }}
          aria-labelledby="customized-dialog-title"
          open={this.props.open}
          fullWidth={true}
          maxWidth="md"
        >
          <DialogTitle
            id="customized-dialog-title"
            title={title}
            onClose={() => {
              this.props.closeHistoryModal();
              if (this.props.isFromSubGroup) {
                this.props.openHistorySubGroupModal(this.props.subGroupData)
              }
            }}
            isFromSubGroup={this.props.isFromSubGroup}
            modalMetaData={this.props.modalMetaData}
            subGroupData={this.props.subGroupData}
            onFetchGMPHistoryItem={this.props.onFetchGMPHistoryItem}
            fetchAddInfoList={this.props.fetchAddInfoList}
            closeHistoryModal={this.props.closeHistoryModal}
            openHistoryModal={this.props.openHistoryModal}
          >
          </DialogTitle>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div style={{padding:'20px 20px 40px 40px',width:'100%'}}>
                  {content}
              </div>
            </GridItem>
          </GridContainer>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    bp: state.gmp.historyItemData,
    states: state.common.states,
    countries: state.common.countries,
    currencies: state.common.currencies,
    gsttypes: state.gmp.gsttypes,
    addinfo: state.common.addinfo
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchGSTType: () => dispatch(actions.fetchGSTType()),
    onFetchGMPHistoryItem: (id, submission_id) => dispatch(actions.fetchGMPHistoryItem(id, submission_id)),
    fetchAddInfoList: (db) => dispatch(actions.fetchAddInfoList(db))
  }
}

const combinedStyle = combineStyles(dialogStyles, userProfileStyles);
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(combinedStyle)(GMPHistoryModal));
