import React from "react";
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import checkPermissions from '../../shared/checkPermissions';
import checkActions from '../../shared/checkPermissionsActions';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { formatDate, generateExcel, generateExcel2, commatize } from 'shared/utility';
import moment from 'moment';
import Datetime from 'react-datetime';
import Button from "../../components/CustomButtons/Button.jsx";
import { FadeLoader, PulseLoader } from 'react-spinners';
import ReactTable from "react-table";
import { returnActivity } from "./helpers";
import GINgroupSubModal from "./GINgroupSubModal";

const override = {
    display: 'block',
    margin: '0 auto',
    borderColor: 'red'
};

function compare( a, b ) {
  if ( a.getTime < b.getTime ){
    return -1;
  }
  if ( a.getTime > b.getTime ){
    return 1;
  }
  return 0;
}

function findLast(arr, type) {
  if (type === 'submitted') {
    let submitted = '';
    arr.forEach(a => {
      if (a.activity.indexOf('Sent to Pending') !== -1 || a.activity.indexOf('Direct') !== -1) {
        submitted = a.timestamp;
      }
    })
    return submitted;
  } else if (type === 'approved'){
    let approved = '';
    arr.forEach(a => {
      if (a.activity.indexOf('Approve') !== -1) {
        approved = a.timestamp;
      }
    })
    return approved;
  }
}

class GINgroup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openSubGroup: false,
      openSubGroupData: null
    }
  }

  openSubGroupModal = (data, gin) => {
    this.setState({openSubGroup:true,openSubGroupData: {
      data,
      gin
    }})
  }

  closeSubGroupModal = () => {
    this.setState({openSubGroup:false,openSubGroupData:null})
  }

  renderGroup = (data) => {
    const gins = {},
          ginsMetaData = {};
    data.forEach(d => {
      const dataObj = {
        getTime: new Date(Date.parse(d.timestamp)).getTime(),
        timestamp: d.timestamp,
        activity: returnActivity('activity', d.activity),
        user: d.name,
        id: d.id,
        data_source: d.data_source
      }
      if (gins.hasOwnProperty(d.gin)) {
        gins[d.gin].data.push(dataObj);
      } else {
        gins[d.gin] = {
          data: [dataObj]
        };
      }
    })
    Object.keys(gins).forEach(g => {
      var sorted = gins[g].data.sort(compare);
      gins[g].last_submitted = findLast(sorted,'submitted');
      gins[g].last_approved = findLast(sorted,'approved');
      gins[g].last_user = sorted[sorted.length-1].user;
    })
    return gins;
  }

  handleDateFilterClick = () => {
    this.props.openFilter();
  }

  renderTable = (groupedDat) => {
    const table =
(<div>
  <GridContainer className="padbottom10">
    <GridItem xs={12} sm={12} md={12}>
      <div className="history-group-datefilter">
        <div
          onClick={() => {
          this.handleDateFilterClick();
        }}>
          {
            (this.props.historyStartDate || this.props.historyEndDate) ?
            <div className="datetime-box-enclosure">
              <div style={{fontSize:'11px',textAlign:'center',color:'#585757'}}>Date / Time</div>
              <div style={{fontSize:'11px',color:'#5e5e5e'}} className="datetime-box">
                <div style={{lineHeight:'1',marginBottom:'5px'}}>
                  {this.props.historyStartDate ? this.props.historyStartDate : 'before'} -</div>
                <div style={{lineHeight:'1',marginTop:'5px'}}>
                  {this.props.historyEndDate ? this.props.historyEndDate : 'to most recent'}
                </div>
              </div>
            </div>
             :
            <Button
              color="white"
            >Filter by Date</Button>
          }
        </div>
      </div>
      <div className="history-download-csv-1">
        <Button
          color="warning"
          onClick={() => {
            const currentRecords = this.selectTable.getResolvedState().sortedData;
            console.log(currentRecords, this.props.historyData);
            const groupedData = this.props.historyData;
            let data = [
              ['GIN','Type','Activity','Date/Time','User']
            ];
            currentRecords.forEach((cr) => {
              console.log(cr)
              const gdata = groupedDat[cr._original.gin];
              console.log('gdata', groupedDat, cr._original.gin);
              if (gdata) {
                gdata.data.forEach((dt) => {
                  console.log('dt',dt);
                  data.push([
                    cr._original.gin,
                    dt.activity.indexOf('EXIST') === -1 ? 'NEW' : 'EXISTING',
                    dt.activity,
                    moment(dt.timestamp).format("MM-DD-YYYY hh:mm a"),
                    dt.user
                  ]);
                });
              }
            });
            const cols = [
                {wch:30}, // GIN
                {wch:10}, // type
                {wch:10}, // activity
                {wch:20}, // date time
                {wch:25}, // user
            ]
            generateExcel2({cols,data}, 'GIN_history_group_view.xlsx');
          }}
        >
          Download Excel
        </Button>
      </div>
    </GridItem>
  </GridContainer>
  <div className="table-adjust2 table-adjust3">
    <ReactTable
        onPageChange={page => this.setState({ page })}
        onPageSizeChange={(pageSize, page) =>
          this.setState({ page, pageSize })}
        minRows={0}
        ref={(r) => {
          this.selectTable = r;
        }}
        getTrProps={(state, rowInfo) => {
          if (rowInfo && rowInfo.row) {
            return {
              onClick: (e) => {
                this.openSubGroupModal(groupedDat[rowInfo.original.gin].data, rowInfo.original.gin);
                this.props.setGroupData({
                  data: groupedDat[rowInfo.original.gin].data,
                  gin: rowInfo.original.gin
                })
              }
            }
          } else {
            return {}
          }
        }}
        data={
            Object.keys(groupedDat).map((g, key) => { // map bp_id
                return ({
                    id: key,
                    gin: g,
                    count: groupedDat[g].data.length,
                    last_submitted: groupedDat[g].last_submitted,
                    last_approved: groupedDat[g].last_approved,
                    last_user: groupedDat[g].last_user
                })
            })
        }
        filterable
        columns={[
            {
                Header: "GIN",
                accessor: "gin",
                filterMethod: (filter, row) => {
                  var filterValue = filter.value.toLowerCase();
                  if (filterValue.length > 0) {
                      return row[filter.id].toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;
                  } else {
                      return true;
                  }
                },
                Cell: cell => {
                  const d = cell.original;
                  return <div>{d.gin}<span style={{float:'right',color:'red',paddingRight:'10px'}}>{d.count}</span></div>
                },
                minWidth:200
            },
            {
                id: 'c2',
                Header: "Last Submitted",
                accessor: d => {
                    const md = moment(d.last_submitted);
                    if (md.isValid()) {
                      return md.format("MM/DD/YYYY hh:mm a");
                    }
                    return '';
                },
                sortMethod: (a, b) => {
                  a = new Date(a).getTime();
                  b = new Date(b).getTime();
                     return b > a ? 1 : -1;
                },
                filterMethod: (filter, row) => {
                  console.log(filter, row);
                    const dateFilter = filter.value;
                    if (dateFilter && row.c2) {
                      if (typeof dateFilter !== 'string') {
                        if (dateFilter.format("MM/DD/YYYY") === row.c2.split(' ')[0]) {
                          return true;
                        }
                      }
                    }
                },
                Filter: ({filter, onChange}) => {
                  return (
                  <div style={{width:'100%'}}>
                    <Datetime
                      closeOnSelect={true}
                      onChange={onChange}
                      timeFormat={false}
                    />
                  </div>);
                },
                maxWidth: 150
            },
            {
                id: 'c3',
                Header: "Last Approved",
                accessor: d => {
                    const md = moment(d.last_approved);
                    if (md.isValid()) {
                      return md.format("MM/DD/YYYY hh:mm a");
                    }
                    return '';
                },
                sortMethod: (a, b) => {
                  a = new Date(a).getTime();
                  b = new Date(b).getTime();
                     return b > a ? 1 : -1;
                },
                filterMethod: (filter, row) => {
                  console.log(filter, row);
                    const dateFilter = filter.value;
                    if (dateFilter && row.c3) {
                      if (typeof dateFilter !== 'string') {
                        if (dateFilter.format("MM/DD/YYYY") === row.c3.split(' ')[0]) {
                          return true;
                        }
                      }
                    }
                },
                Filter: ({filter, onChange}) => {
                  return (
                  <div style={{width:'100%'}}>
                    <Datetime
                      closeOnSelect={true}
                      onChange={onChange}
                      timeFormat={false}
                    />
                  </div>);
                },
                maxWidth: 150
            },
            {
                Header: "Last User",
                accessor: "last_user",
                filterMethod: (filter, row) => {
                  var filterValue = filter.value.toLowerCase();
                  if (filterValue.length > 0) {
                      return row[filter.id].toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;
                  } else {
                      return true;
                  }
                }
            }
        ]}
        defaultPageSize={10}
        showPaginationTop
        showPaginationBottom
        className="-striped -highlight"
        filtered={this.props.historyFiltered}
        onFilteredChange={filtered => {
            console.log('this is the filtered',filtered);
            //this.props.updateGMPHistoryFilter(filtered);
          }}
    >
    {(state, makeTable, instance) => {
        let recordsInfoText = "";

        const { filtered, pageRows, pageSize, sortedData, page } = state;

        if (sortedData && sortedData.length > 0) {
          let isFiltered = filtered.length > 0;

          let totalRecords = sortedData.length;

          let recordsCountFrom = page * pageSize + 1;

          let recordsCountTo = recordsCountFrom + pageRows.length - 1;

          if (isFiltered)
            recordsInfoText = `Showing ${recordsCountFrom}-${recordsCountTo} of ${totalRecords.toLocaleString()} filtered submissions`;
          else
            recordsInfoText = `Showing ${recordsCountFrom}-${recordsCountTo} of ${totalRecords.toLocaleString()} submissions`;
        } else recordsInfoText = "No activity";

        return (
          <div className="main-grid">
            {makeTable()}
            <div style={{marginTop:'30px'}}>
              <div style={{textAlign:'center'}}>{recordsInfoText}</div>
            </div>
          </div>
        );
      }}
    </ReactTable>
</div>
</div>);

  return table;
  }

  render() {
    const data = this.renderGroup(this.props.historyData);
    console.log('groupedHistory Data',data);

    let loader = <div style={{textAlign:'center',margin:'0 auto'}} className="centered-loader">
    <FadeLoader
        style={override}
        sizeUnit={"px"}
        size={150}
        color={'#123abc'}
        loading={true}
    /></div>

    return (
    <div>
      {this.props.historyData.length > 0 ? <div>{this.renderTable(data)}</div> : <div></div> }
      {this.props.loading && <div>
        {loader}
      </div>}
      {this.state.openSubGroup && <GINgroupSubModal
        open={this.state.openSubGroup}
        subGroupData={this.state.openSubGroupData}
        closeHistorySubGroupModal={this.closeSubGroupModal}
        loadHistoryEntry={this.props.loadHistoryEntry}
        openReviewModal={this.props.openReviewModal}
        selectIndex={this.props.selectIndex}
      />}
    </div>
    );
  }
}

export default connect(null, null)(GINgroup);
