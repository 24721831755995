import React from "react";
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import checkPermissions from '../../shared/checkPermissions';
import checkActions from '../../shared/checkPermissionsActions';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Cached from "@material-ui/icons/Cached";
import GMPList from "containers/GMP/GMPList";

class GMP extends React.Component {
  constructor(props) {
    super(props);

  }

  render() {
    if (!checkPermissions('GMP')) {
      return <Redirect to="/dashboard" />;
    }

    return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <div className="pointed"><Cached onClick={this.props.reloadList} /></div>
            </CardIcon>
          </CardHeader>
          <CardBody>
            <GMPList />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    reloadList: () => dispatch(actions.fetchGMP())
  }
}

export default connect(null, mapDispatchToProps)(GMP);
