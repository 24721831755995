import React from 'react';
import { connect } from 'react-redux';
import GridContainer from "components/Grid/GridContainer.jsx";
import Search from "@material-ui/icons/Search";
import { Card, CardContent } from '@material-ui/core';
import GridItem from "components/Grid/GridItem.jsx";
import Table from "components/Table/TableSmall.jsx";

import Table2 from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import Button from "../../components/CustomButtons/Button.jsx";
import activeDBs from "shared/activeDBs";
import { showPicksList } from "shared/activeDBs";
import { getDescription } from "./GINhelpers";
import GINreviewDialog from "./GINreview-dialog";
import GINreviewDialogPickValue from "./GINreview-dialog-pickvalue";
import GINreviewDialogVariants from "./GINreview-dialog-variants";
import GINreviewDialogEdit from "./GINreview-dialog-edit";
import * as actions from '../../store/actions';

const tableHeadStyle = {
  fontSize:12,
  color:'#11aae3'
}

const DBlabel = props => {
  // props.name
  let backgroundColor = '';
  activeDBs.forEach((db) => {
    if (db.name === props.name) {
      backgroundColor = db.color;
    }
  });

  if (backgroundColor) {
    return (
      <div style={{backgroundColor}} className="db-pill">
        {props.name.split('_')[0]}
      </div>
    );
  }
  return null;
}

class GINreviewShort extends React.Component{

    state = {
      edit: false,
      popup: false,
      data: null,
      pickEdit: false,
      pickData: null,
      variantEdit: false,
      variantData: null,
      dbs: activeDBs.map(db => db.name),
      imageURL: this.props.imageURL
    };

    setNotExistent = () => {
      console.log('file does not exist');
      if (this.state.imageURL) {
        if (this.state.imageURL.indexOf('.jpg') === -1) {
          this.setState({
            imageURL: `https://portal.natcoglobal.com/portal/api/item/ftp_gin_image.php?gin=${this.props.ginNum}`
          })
        }
      } else {
        this.setState({
          imageURL: `https://portal.natcoglobal.com/portal/api/item/ftp_gin_image.php?gin=${this.props.ginNum}`
        })
      }
    }

    onLoad = () => {
      console.log('image file exists');
    }

    openEdit = (data) => {
      this.setState({
        edit: true,
        data
      })
    }

    closeEdit = () => {
      this.setState({
        edit: false, data: null
      })
    }

    openVariantEdit = (variantData) => {
      this.setState({variantEdit:true,variantData})
    }

    closeVariantEdit = () => {
      this.setState({variantEdit:false,variantData:null})
    }

    openPickEdit = (pickData) => {
      this.setState({pickEdit:true,pickData})
    }

    closePickEdit = () => {
      this.setState({pickEdit:false,pickData:null})
    }

    openPopup = (data) => {
      this.setState({popup:true,data})
    }

    closePopup = () => {
      console.log('closing popup');
      this.setState({popup:false,data:null})
    }

    generateInputData = (o) => {
      const valueField = o.valueField ? o.valueField : null;
      const descField = o.descField ? o.descField : null;
      let desc = null;
      if (valueField) {
        desc = getDescription(o.value, o.options, o.valueField, o.descField);
      }
      let addText = '';
      if (o.addText) {
        addText = o.addText;
      }
      let inputStyle = {};
      if (o.color) {
        inputStyle.color = o.color;
      }
      return (<span
              className="hovered hover-label"
              style={inputStyle}
              onClick={() => {
                if (!this.props.isPreview) {
                  this.props.editModeOn();
                  this.props.setEdit({
                    itemName: o.name,
                    db: o.db,
                    field: o.field,
                    value: o.value,
                    desc,
                    type: o.type,
                    options: o.options,
                    valueField,
                    descField,
                    addText,
                    similars: o.similars,
                    validations: o.validations,
                    applyToAll: true
                  });
                }
              }}
            >
              {!valueField && <span>{o.value ? o.value+addText : '- none -'}</span>}
              {(valueField && desc) && <span>{desc}</span>}
              {(valueField && !desc) && <span>n/a</span>}
            </span>);
    }

    generateImage = () => {
      if (this.props.ginFinalCrop) {
        console.log('there is an image 1')
        return (
          <div>
            <img src={this.props.ginFinalCrop} className="img-responsive" />
          </div>
        )
      } else if (this.props.isPreview) {
        console.log('there is an image 2', this.state.imageURL)
        const image = this.state.imageURL ? this.state.imageURL : `https://portal.natcoglobal.com/portal/api/item/ftp_gin_image.php?gin=${this.props.ginNum}`;
        return (
          <div>
            <img
              src={image}
              className="img-responsive"
              onError={this.setNotExistent}
              onLoad={this.onLoad}
            />
          </div>
        )
      }
      return null
    }

    generateRBO = (firmCode, db) => {
      if (this.props.options[db]) {
        if (this.props.options[db].rbos) {
          if (this.props.options[db].rbos.length > 0) {
            const rbos = this.props.options[db].rbos;
            for (let rbo of rbos) {
              if (rbo.FirmCode === firmCode) {
                return rbo.FirmName;
                break;
              }
            }
          }
        }
      }
      return '';
    }

    generateBrand = (brand, db) => {
      if (this.props.brands) {
        if (this.props.brands.length > 0) {
          for (let b of this.props.brands) {
            if (b.id === brand) {
              return b.name;
              break;
            }
          }
        }
      }
      return ''
    }

    generateSubgroup = (subgroup, db) => {
      if (this.props.subgroups) {
        if (this.props.subgroups.length > 0) {
          for (let s of this.props.subgroups) {
            if (s.id === subgroup) {
              return s.name;
              break;
            }
          }
        }
      }
      return ''
    }

    compare = (newVal, oldVal, isNumber) => {
      if (oldVal === undefined) {
        return '';
      }
      console.log('comparing', newVal, oldVal, isNumber);
      let nv = newVal, ov = oldVal;
      if (typeof newVal === 'string') {
        nv = newVal.trim();
      }
      if (typeof oldVal === 'string') {
        ov = oldVal.trim();
      }
      if (isNumber) {
        nv = parseFloat(newVal);
        ov = parseFloat(oldVal);
      }
      if (nv != ov && oldVal !== undefined) {
        if (typeof oldVal !== 'string') {
          return '';
        }
        if (oldVal.indexOf('undefined') !== -1) {
          return '';
        }
        if (oldVal === '' && newVal) {
          ov = 'n/a';
        }
        return <span className="orange-text">{`(Previously ${ov})`}</span>
      } else {
        return '';
      }
    }

    // only returns true or false, true = there was a change
    compare2 = (newVal, oldVal, isNumber) => {
      if (oldVal === undefined) {
        return false;
      }
      console.log('comparing2', newVal, oldVal, isNumber);
      let nv = newVal, ov = oldVal;
      if (typeof newVal === 'string') {
        nv = newVal.trim();
      }
      if (typeof oldVal === 'string') {
        ov = oldVal.trim();
      }
      if (isNumber) {
        nv = parseFloat(newVal);
        ov = parseFloat(oldVal);
      }
      if (nv != ov && oldVal !== undefined) {
        if (typeof oldVal !== 'string') {
          if (ov) {
            return true;
          }
          return false;
        }
        if (oldVal.indexOf('undefined') !== -1) {
          return false;
        }
        return true;
      } else {
        return false;
      }
    }

    renderBaseItemInfo = () => {
      const pickedProductTypeDB = this.props.picked.product_type;
      const pickedProductType = this.props.product_type[pickedProductTypeDB];
      const base_item_info = this.props.openedDBs.map(db => {
        let special_fields = '';
        const hasPicks = this.props.picks[db] ? true : false;
        if (showPicksList(db, pickedProductType)) {
          special_fields = <span className="hovered hover-label" onClick={() => {
            const o = {
              name: 'Picks',
              db,
              field: 'picks',
              value: this.props.picks[db] ? this.props.picks[db].toUpperCase() : '',
              type: 'input',
              addText: '',
              similars: null,
              validations: {
                numOnly: true,
                wholeNumOnly: true,
                greaterThanOne: true,
                maxNum: 100000
              }
            }
            if (!this.props.openModal && !this.props.isPreview) {
              this.props.editModeOn();
              this.props.setEdit({
                itemName: o.name,
                db: o.db,
                field: o.field,
                value: o.value,
                desc: '',
                type: o.type,
                options: o.options,
                valueField: '',
                descField: '',
                addText: '',
                similars: o.similars,
                validations: o.validations
              });
            }
          }}>
                              <span style={!hasPicks ? {
                                color: 'red'
                              } : {}}>{`PICKS: ${this.props.picks[db]}`}</span>
                              <span>{this.compare(this.props.picks[db], this.props.picks_original[db])}</span>
                            </span>;
        }
        let nin_tax = '';
        if (db.indexOf('NIN') !== -1) {
          nin_tax = <div
                      className="hovered orange-text"
                      onClick={() => {

                        if (!this.props.isPreview) {
                          this.openPopup({
                            title: 'TAX DETAILS',
                            type: 'tax',
                            db
                          })
                        } else {
                          this.openPopup({
                            title: 'TAX DETAILS',
                            type: 'tax',
                            db,
                            isPreview: true,
                            ownProps: this.props
                          })
                        }
                      }}
                    >VIEW TAX DETAILS</div>
        }
        let special_fields_combined = <div>{special_fields}<div>{nin_tax}</div></div>;
        return [
          <DBlabel name={db} />,
          this.props.itemCodes[db],
          this.props.newItemList.indexOf(db) === -1 ? 'EXISTING' : 'NEW',
          special_fields_combined,
          <div>
            <span className="hovered hover-label" onClick={() => {
              console.log('isPreview',this.props.isPreview);
              if (!this.props.isPreview) {
                this.openEdit({
                  title: 'EDIT Source',
                  db,
                  value: this.props.item_is[db],
                  type: 'item_is'
                });
              }
            }}>
              <span>{this.props.item_is[db]}</span>
            </span>
            <span>{this.compare(this.props.item_is[db], this.props.item_is_original[db])}</span>
          </div>
        ];
      });
      return <div className="review-box1">
      <Table
        tableHeaderColor="primary"
        tableHead={["DB", "ITEM CODE", "TYPE", "SPECIAL FIELDS", "SOURCE"]}
        tableData={base_item_info}
        tableHeadStyle={tableHeadStyle}
      /></div>
    }

    renderSelectedValues = () => {
      console.log('renderSelectedValues', this.props.picked, this.props);
      const props = this.props, picked = props.picked, options = props.options;
      const hasChangedStyle = this.compare2(props.base_item_description[picked.base_item_description], props.base_item_description_original[picked.base_item_description]) ? { color: 'orange'} : {}
      console.log('hasChangedStyle',hasChangedStyle)
      const general_info = [
        [
          <div className="bold">BASE ITEM DESCRIPTION</div>,
          picked.base_item_description ?
        <div>
          <span style={hasChangedStyle}>
            {this.generateInputData({
              name: 'Base Item Description',
              db: picked.base_item_description,
              field: 'base_item_description',
              value: props.base_item_description[picked.base_item_description] ? props.base_item_description[picked.base_item_description].toUpperCase() : '',
              type: 'input',
              similars: this.props.baseItemDescriptions,
              validations: {
                maxLength: 75,
                notAllowedChars: 'aCharms',
                whitelist: true
              }
            })}
          </span>
        </div>
          : ''
        ],
        [
          <div className="bold">PRODUCT TYPE</div>,
          picked.product_type ?
          <span>
            <span>
            {this.generateInputData({
              name: 'Product Type',
              db: picked.product_type,
              field: 'product_type',
              value: props.product_type[picked.product_type] ? props.product_type[picked.product_type] : '',
              options: options.hasOwnProperty(picked.product_type) ? options[picked.product_type].product_types : [],
              type: 'option1',
              similars: this.props.product_type,
              color: this.compare2(props.product_type[picked.product_type], props.product_type_original[picked.product_type]) ? 'orange' : ''
            })}
            </span>
          </span> : ''
        ],
        [
          <div className="bold">RBO NAME</div>,
          picked.rbo ?
          <span>
            <span>
            {this.generateInputData({
              name: 'RBO',
              db:picked.rbo,
              field: 'rbo',
              value: props.rbo[picked.rbo] ? props.rbo[picked.rbo] : '',
              options: options.hasOwnProperty(picked.rbo) ? options[picked.rbo].rbos : [],
              type: 'option2',
              valueField: 'FirmCode',
              descField: 'FirmName',
              similars: null,
              color: this.compare2(props.rbo[picked.rbo], props.rbo_original[picked.rbo]) ? 'orange' : ''
            })}
            </span>
          </span> : ''
        ],
        [
          <div className="bold">BRAND</div>,
          picked.brand ?
          <span>
            {this.generateInputData({
              name: 'Brand',
              db:picked.brand,
              field: 'brand',
              value: props.brand[picked.brand] ? props.brand[picked.brand] : '',
              options: this.props.brandNames,
              type: 'option2',
              valueField: 'id',
              descField: 'name',
              similars: null,
              color: this.compare2(props.brand[picked.brand], props.brand_original[picked.brand]) ? 'orange' : ''
            })}
          </span> : ''
        ],
        [
          <div className="bold">SUBGROUP</div>,
          picked.subgroup ?
          <span>
          {this.generateInputData({
            name: 'Subgroup',
            db:picked.subgroup,
            field: 'subgroup',
            value: props.subgroup[picked.subgroup] ? props.subgroup[picked.subgroup] : '',
            options: this.props.subgroupNames,
            type: 'option2',
            valueField: 'id',
            descField: 'name',
            similars: null,
            color: this.compare2(props.subgroup[picked.subgroup], props.sub_group_original[picked.subgroup]) ? 'orange' : ''
          })}
          </span> : ''
        ],
        [
          <div className="bold">PACKAGING TYPE</div>,
          picked.ipackagingtypes ?
          <span>
          {this.generateInputData({
            name: 'Packaging Type',
            db:picked.ipackagingtypes,
            field: 'ipackagingtypes',
            value: props.ipackagingtypes[picked.ipackagingtypes] ? props.ipackagingtypes[picked.ipackagingtypes] : '',
            options: this.props.ipackagingtypesNames,
            type: 'option2',
            valueField: 'id',
            descField: 'name',
            similars: null,
            color: this.compare2(props.ipackagingtypes[picked.ipackagingtypes], props.ipackagingtypes_original[picked.ipackagingtypes]) ? 'orange' : ''
          })}
          </span> : ''
        ],
      ];
      const measurements_info = [
        [
          <div className="bold">SALES UNIT</div>,
          picked.sales_unit ?
          <span>
          {this.generateInputData({
            name: 'Sales Unit',
            db:picked.sales_unit,
            field: 'sales_unit',
            value: props.sales_unit[picked.sales_unit] ? props.sales_unit[picked.sales_unit] : '',
            options: ['PIECE','DOZEN','GROSS','THOUSAND','METER','TARD','ROLL'],
            type: 'option1',
            similars: null,
            color: this.compare2(props.sales_unit[picked.sales_unit], props.sales_unit_original[picked.sales_unit]) ? 'orange' : ''
          })}
          </span> : ''
        ],
        [
          <div className="bold">BASIC PACKAGING UNIT</div>,
          picked.basic_packaging_unit ?
          <span>
          {this.generateInputData({
            name: 'Basic Packaging Unit',
            db:picked.basic_packaging_unit,
            field: 'basic_packaging_unit',
            value: props.basic_packaging_unit[picked.basic_packaging_unit] ? props.basic_packaging_unit[picked.basic_packaging_unit] : '',
            options: ['TRAY','CARTON','BUNDLE','ROLL'],
            type: 'option1',
            similars: null,
            color: this.compare2(props.basic_packaging_unit[picked.basic_packaging_unit], props.basic_packaging_unit_original[picked.basic_packaging_unit]) ? 'orange' : ''
          })}
          </span> : ''
        ],
        [
          <div className="bold">QTY PER UNIT</div>,
          picked.qty_per_unit ?
          <span>
          {this.generateInputData({
            name: 'Qty per Packing UOM',
            db:picked.qty_per_unit,
            field: 'qty_per_unit',
            value: props.qty_per_unit[picked.qty_per_unit] ? props.qty_per_unit[picked.qty_per_unit] : '',
            type: 'input',
            similars: null,
            validations: {
              numOnly: true,
              wholeNumOnly: true,
              maxNum: 100000
            },
            color: this.compare2(props.qty_per_unit[picked.qty_per_unit], props.qty_per_unit_original[picked.qty_per_unit]) ? 'orange' : ''
          })}
          </span> : ''
        ],
        [
          <div className="bold">LENGTH</div>,
          picked.ilength ?
          <span>
          {this.generateInputData({
            name: 'Length',
            db:picked.ilength,
            field: 'ilength',
            value: props.ilength[picked.ilength] ? props.ilength[picked.ilength] : '',
            type: 'input',
            addText: ' mm',
            similars: null,
            validations: {
              numOnly: true,
              wholeNumOnly: true,
              maxNum: 100000
            },
            color: this.compare2(props.ilength[picked.ilength], props.ilength_original[picked.ilength]) ? 'orange' : ''
          })}
          </span> : ''
        ],
        [
          <div className="bold">WIDTH</div>,
          picked.width ?
          <span>
          {this.generateInputData({
            name: 'Width',
            db:picked.width,
            field: 'width',
            value: props.width[picked.width] ? props.width[picked.width] : '',
            type: 'input',
            addText: ' mm',
            similars: null,
            validations: {
              numOnly: true,
              wholeNumOnly: true,
              maxNum: 100000
            },
            color: this.compare2(props.width[picked.width], props.width_original[picked.width]) ? 'orange' : ''
          })}
          </span> : ''
        ],
        [
          <div className="bold">WEIGHT</div>,
          picked.weight ?
          <span>
          {this.generateInputData({
            name: 'Weight',
            db:picked.weight,
            field: 'weight',
            value: props.weight[picked.weight] ? props.weight[picked.weight] : '',
            type: 'input',
            addText: ' g',
            similars: null,
            validations: {
              numOnly: true,
              minMax3Decimals: true,
              maxNum: 100000
            },
            color: this.compare2(props.weight[picked.weight], props.weight_original[picked.weight]) ? 'orange' : ''
          })}
          </span> : ''
        ],
      ];
      return <div className="review-box1">
      <Table
        tableHeaderColor="primary"
        tableHead={["GENERAL",""]}
        tableData={general_info}
        tableHeadStyle={tableHeadStyle}
      />
      <Table
        tableHeaderColor="primary"
        tableHead={["MEASUREMENTS", ""]}
        tableData={measurements_info}
        tableHeadStyle={tableHeadStyle}
      />
      </div>

    }

    editVD = (vc,vd) => {
      let base_item_description = '';
      if (this.props.picked.base_item_description) {
        base_item_description = this.props.base_item_description[this.props.picked.base_item_description];
      }
      this.openVariantEdit({
        title: 'EDIT VARIANT DESCRIPTION',
        variant_code: vc,
        variant_description: vd,
        base_item_description
      })
    }

    renderVariants = () => {
      let variantsData = {}, differentDesc = [];
      if (this.props.variants && this.props.variantsDesc) {
        this.props.openedDBs.forEach(db => {
          if (this.props.variants[db]) {
            this.props.variants[db].forEach((v,i) => {
              const combo = `${v[0]}_${this.props.variantsDesc[db][i]}`;
              variantsData[combo] = 0;

              if (this.props.originalVariantsDesc[db]) {
                if (this.props.originalVariantsDesc[db][i] || this.props.originalVariantsDesc[db][i] === '') {
                  if (this.props.originalVariantsDesc[db][i] !== this.props.variantsDesc[db][i]) {
                    differentDesc.push(combo);
                  }
                }
              }
            });
          }
        })
      }
      if (this.props.variantsChecked) {
        if (Object.keys(this.props.variantsChecked).length > 0) {
          Object.keys(this.props.variantsChecked).forEach(combo => {
            variantsData[combo] = 1;
          });
        }
      }
      if (Object.keys(variantsData).length > 0) {
        let sorted = [].concat(Object.keys(variantsData))
                      .sort();
        const variants_info = sorted.map(combo => {
          const vc = combo.split('_')[0],
                vd = combo.split('_')[1];
          let status = 'UNCHANGED';
          if (variantsData[combo] === 0) {
            status = 'REMOVED';
          } else {
            if (this.props.variantsToAdd) {
              if (this.props.variantsToAdd.indexOf(vc) !== -1) {
                status = 'NEW'
              }
              if (differentDesc.length > 0) {
                if (differentDesc.indexOf(combo) !== -1) {
                  status = 'NEW DESCRIPTION';
                }
              }
            }
          }

          let vArr = [
            <div className={status === 'REMOVED' ? 'red-text' : ''}>{vc}</div>,
            <div className={status === 'REMOVED' ? 'red-text' : ''}>
              {status !== 'REMOVED' && <span className="hovered hover-label" onClick={() => {
                  if (!this.props.isPreview) {
                    this.editVD(vc,vd);
                  }
              }}>
                <span>{vd}</span>
              </span>}
              {status === 'REMOVED' && <span>{vd}</span>}
            </div>
          ];
          if (!this.props.isPreview) {
            vArr.push(<div className={status === 'REMOVED' ? 'red-text'
                                                            : status === 'NEW DESCRIPTION' ? 'orange-text'
                                                                                            : status === 'NEW' ? 'green-text' : ''}>{status}</div>);
          }
          return vArr;
        });
        const tableHead = this.props.isPreview ? ["VARIANT CODE", "VARIANT DESCRIPTION"] : ["VARIANT CODE", "VARIANT DESCRIPTION", "STATUS"];
        return <div className="review-box1">
        <Table
          tableHeaderColor="primary"
          tableHead={tableHead}
          tableData={variants_info}
          tableHeadStyle={tableHeadStyle}
        />
      </div>
      } else {
        return <div style={{margin:20}} className="review-box1">- none -</div>
      }
    }

    renderRMs = () => {
      return [<div><strong># of RM'S:</strong></div>].concat(this.state.dbs.map(db => {
        if (this.props.item_is[db] === 'Outsourced' || this.props.item_is[db] === 'None') {
          return '';
        }
        if (this.props.rms[db]) {
          if (this.props.rms[db].length > 0) {
            return this.props.rms[db].length;
          }
          return 0
        }
        return ''
      }))
    }

    renderBaseQty = () => {
      const baseQtyStyle = {};
      return [<div><strong>ITEM BASE QTY:</strong></div>].concat(this.state.dbs.map(db => {
        if (this.props.item_is[db] === 'Outsourced' || this.props.item_is[db] === 'None') {
          return '';
        }
        if (this.props.BOMItemBaseQty[db]) {
          if (this.compare2(this.props.BOMItemBaseQty[db], this.props.BOMItemBaseQty_original[db])) {
            baseQtyStyle.color = 'orange';
          }
          return <div style={baseQtyStyle}>{this.props.BOMItemBaseQty[db]}</div>;
        }
        return ''
      }))
    }

    renderWarehouse = () => {
      return [<div><strong># of WAREHOUSES:</strong></div>].concat(this.state.dbs.map(db => {
        if (this.props.item_is[db] === 'Outsourced' || this.props.item_is[db] === 'None') {
          return '';
        }
        if (this.props.rmsw[db]) {
          if (this.props.rmsw[db].length > 0) {
            return this.props.rmsw[db].length;
          } else {
            return 0
          }
        }
        return ''
      }))
    }

    renderBOM = () => {
      const dbHeaders = [<strong style={{color:'#212121'}}>DATABASE</strong>].concat(this.state.dbs.map(db => {
        return <DBlabel name={db} />
      }));
      const details = [<div className="blue-text bold">VIEW DETAILS</div>].concat(this.state.dbs.map(db => {
        if (this.props.openedDBs.indexOf(db) !== -1) {
          if (this.props.item_is[db] === 'Outsourced' || this.props.item_is[db] === 'None') {
            return ''
          }
          return <div className="blue-text hovered" onClick={() => {
            this.openPopup({
              title: 'BOM SUMMARY',
              type: 'bom',
              db
            })
          }}><Search /></div>
        } else {
          return ''
        }

      }));
      const bom_info = [
        this.renderRMs(),
        this.renderBaseQty(),
        this.renderWarehouse(),
        details
      ]
      return <div className="review-table2">
      <Table2>
        <colgroup>
            <col className="review-table2-col-1" />
            {this.state.dbs.map(dbs => {
              return <col key={`dbbs${dbs}`} className="review-table2-col-2" />
            })}
         </colgroup>
        <TableHead>
          <TableRow>
            {dbHeaders.map((d,key) => {
              return <TableCell key={`dh${key}`} classes={{
                root: 'text-center'
              }}>{d}</TableCell>
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {
            bom_info.map((bom,key) => {
              return <TableRow key={`db${key}`}>
                {bom.map((b,k) => {
                  return <TableCell key={`dc${k}`} classes={{
                    root: k !== 0 ? `review-table2-cell text-center` : 'review-table2-cell'
                  }}>{b}</TableCell>
                })}
              </TableRow>
            })
          }
        </TableBody>
      </Table2>
    </div>
    }

    renderPricingForAll = () => {
      return [<div><strong># OF PRICING FOR ALL:</strong></div>].concat(this.state.dbs.map(db => {
        let count = 0;
        if (this.props.gprice[db]) {
          count++;
        }
        if (this.props.gprices[db]) {
          if (this.props.gprices[db].length > 0) {
            count = count + this.props.gprices[db].length;
          }
        }
        if (count) {
          return count;
        }
        return '';
      }))
    }

    renderCustomerPricing = () => {
      return [<div className="bold"># OF CUSTOMER PRICING:</div>].concat(this.state.dbs.map(db => {
        let count = 0;
        if (this.props.bmasterarray[db]) {
          if (Object.keys(this.props.bmasterarray[db]).length > 0) {
            Object.keys(this.props.bmasterarray[db]).forEach(supplier => {
              if (this.props.bmasterarray[db][supplier]) {
                this.props.bmasterarray[db][supplier].forEach(pricing => {
                  if (pricing[0]) {
                    if (parseFloat(pricing[0])) {
                      count++;
                    }
                  }
                })
              }
            });
          }
        }
        if (count) return count;
        return '';
      }))
    }

    renderVendorCosts = () => {
      return [<div className="bold"># OF VENDOR COSTS:</div>].concat(this.state.dbs.map(db => {
        if (this.props.item_is[db] === 'Produced in House' || this.props.item_is[db] === 'None') {
          return ''
        }
        let count = 0;
        if (this.props.smasterarray[db]) {
          if (Object.keys(this.props.smasterarray[db]).length > 0) {
            Object.keys(this.props.smasterarray[db]).forEach(supplier => {
              if (this.props.smasterarray[db][supplier]) {
                this.props.smasterarray[db][supplier].forEach(pricing => {
                  if (pricing[0]) {
                    if (parseFloat(pricing[0])) {
                      count++;
                    }
                  }
                })
              }
            });
          }
        }
        if (count) return count;
        return '';
      }))
    }

    renderTargetCosts = () => {
      return [<div><strong># OF TARGET COSTS:</strong></div>].concat(this.state.dbs.map(db => {
        if (this.props.item_is[db] === 'Produced in House' || this.props.item_is[db] === 'None') {
          return ''
        }
        let count = 0;
        if (this.props.cprice[db]) {
          count++;
        }
        if (this.props.cprices[db]) {
          if (this.props.cprices[db].length > 0) {
            count = count + this.props.cprices[db].length;
          }
        }
        if (count) return count;
        return '';
      }))
    }

    renderPricingCosting = () => {
      const dbHeaders = [<strong style={{color:'#212121'}}>DATABASE</strong>].concat(this.state.dbs.map(db => {
        return <DBlabel name={db} />
      }));
      const details = [<div className="blue-text bold">VIEW DETAILS</div>].concat(this.state.dbs.map(db => {
        if (this.props.openedDBs.indexOf(db) === -1) {
          return '';
        }
        return <div className="blue-text hovered" onClick={() => {
          this.openPopup({
            title: 'PRICING AND COSTING SUMMARY',
            type: 'pricing_costing',
            db
          })
        }}><Search /></div>
      }));
      const pc_info = [
        this.renderPricingForAll(),
        this.renderCustomerPricing(),
        this.renderVendorCosts(),
        this.renderTargetCosts(),
        details
      ]
      return <div className="review-table2">
      <Table2>
        <colgroup>
            <col className="review-table2-col-3" />
            {this.state.dbs.map(dbs => {
              return <col key={`dbbs${dbs}`} className="review-table2-col-2" />
            })}
         </colgroup>
        <TableHead>
          <TableRow>
            {dbHeaders.map((d,key) => {
              return <TableCell key={`dh${key}`} classes={{
                root: 'text-center'
              }}>{d}</TableCell>
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {
            pc_info.map((pc,key) => {
              return <TableRow key={`db${key}`}>
                {pc.map((b,k) => {
                  return <TableCell key={`dc${k}`} classes={{
                    root: k !== 0 ? `review-table2-cell text-center` : 'review-table2-cell'
                  }}>{b}</TableCell>
                })}
              </TableRow>
            })
          }
        </TableBody>
      </Table2>
    </div>
    }

    render() {
      console.log('gin review 1', this.props, this.state);
      let totalVariants = 0;
      if (this.props.variantsChecked) {
        Object.keys(this.props.variantsChecked).forEach(vchecked => {
          if (this.props.variantsChecked[vchecked] === 1) {
            totalVariants += 1;
          }
        });
      }

      let oneItemIsLocked = false;
      const lockedItems = {};
      if (this.props.lockedElementsData) {
        if (typeof this.props.lockedElementsData === 'object') {
          this.props.lockedElementsData.forEach((led) => {
            if (led.status === 'LOCKED') {
              lockedItems[led.db] = led.itemcode;
            }
          });
        }
      }
      if (this.props.itemCodes) {
        if (typeof this.props.itemCodes === 'object') {
          Object.keys(this.props.itemCodes).forEach((db) => {
            if (lockedItems[db]) {
              if (lockedItems[db] === this.props.itemCodes[db]) {
                oneItemIsLocked = true;
              }
            }
          });
        }
      }
        return (
        <div>
          <GridContainer>
            <GridItem xs={12} sm={5} md={5}>
              <div className="marginBottom10"><strong>BASE ITEM INFORMATION</strong></div>
              <Card>
                {this.renderBaseItemInfo()}
              </Card>
            </GridItem>
            <GridItem xs={12} sm={3} md={3}>
              <div className="marginBottom10"><strong>SELECTED VALUES</strong></div>
              <Card>
                {this.renderSelectedValues()}
              </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <div className="marginBottom10"><strong>{totalVariants} VARIANTS</strong></div>
              <Card>
                {this.renderVariants()}
              </Card>
            </GridItem>
          </GridContainer>
          <GridContainer style={{marginTop:10}}>
            <GridItem xs={12} sm={2} md={2}>
              <div className="marginBottom10" style={{marginTop:10}}><strong>IMAGE</strong></div>
              <Card>
                <div style={{height:260}}>
                {this.generateImage()}
                </div>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={5} md={5}>
              <div className="marginBottom10" style={{marginTop:10}}><strong>BOM</strong></div>
              <Card>
                {this.renderBOM()}
              </Card>
            </GridItem>
            <GridItem xs={12} sm={5} md={5}>
              <div className="marginBottom10" style={{marginTop:10}}><strong>PRICING & COSTING</strong></div>
              <Card>
                {this.renderPricingCosting()}
              </Card>
            </GridItem>
          </GridContainer>
          <GridContainer>
            {!this.props.isHistory && <GridItem xs={12} sm={12} md={12}>
              {!this.props.isPreview && <div className="text-center" style={{marginTop:40}}>
                <Button color="success" onClick={() => {
                  this.props.submit();
                }}>{this.props.ginSource === 'Pending' ? 'APPROVE' : 'SUBMIT'}</Button>
              </div>}
              {this.props.isPreview && <div className="text-center" style={{marginTop:40}}>
                {(!this.props.isPending && !oneItemIsLocked) && <Button style={{marginRight:10}} color="success" onClick={() => {
                  this.props.loadGIN();
                }}>LOAD</Button>}
                <Button color="rose" onClick={() => {
                  this.props.close();
                }}>CLOSE</Button>
              </div>}
            </GridItem>}
          </GridContainer>
          {
            this.state.edit && <GINreviewDialogEdit
              open={this.state.edit}
              close={this.closeEdit}
              data={this.state.data}
              checkDupes={this.props.checkDupes}
              checkDupesSupplierCost={this.props.checkDupesSupplierCost}
              checkDupesTargetCost={this.props.checkDupesTargetCost}
              checkDupesCustomerPrice={this.props.checkDupesCustomerPrice}
            />
          }
          {
            this.state.popup && <GINreviewDialog
              open={this.state.popup}
              close={this.closePopup}
              data={this.state.data}
              goToDetailed={this.props.goToDetailed}
              checkDupes={this.props.checkDupes}
              checkDupesSupplierCost={this.props.checkDupesSupplierCost}
              checkDupesTargetCost={this.props.checkDupesTargetCost}
              checkDupesCustomerPrice={this.props.checkDupesCustomerPrice}
              isPreview={this.props.isPreview}
              isHistory={this.props.isHistory}
              previewData={this.props.previewData}
              compare={this.compare}
              compare2={this.compare2}
            />
          }
          {
            this.state.pickEdit && <GINreviewDialogPickValue
              open={this.state.pickEdit}
              close={this.closePickEdit}
              data={this.state.pickData}
            />
          }
          {
            this.state.variantEdit && <GINreviewDialogVariants
              open={this.state.variantEdit}
              close={this.closeVariantEdit}
              data={this.state.variantData}
            />
          }
        </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
  if (ownProps.previewData) {
    console.log('found preview data', ownProps);
    const comb = Object.assign({},
        ownProps.previewData,
        { brandNames: state.gin.brands,
          subgroupNames: state.gin.subgroups,
          ipackagingtypesNames: state.gin.packagingtypes,
          // TAX
          nin_tax: state.gin.nin_tax,
          previewData: ownProps.previewData
        });
    const combined = Object.assign({},
        ownProps.previewData,
        { brandNames: state.gin.brands,
          subgroupNames: state.gin.subgroups,
          ipackagingtypesNames: state.gin.packagingtypes,
          // TAX
          nin_tax: state.gin.nin_tax,
          previewData: comb
        });
    return combined;
  }
  return {
    dbList: state.gin.dbList,
    loading: state.gin.loading,
    itemCodes: state.gin.itemCodes,
    openedDBs: state.gin.openedDBs,
    editMode: state.gin.editMode,
    picked: state.gin.picked,
    newItemList: state.gin.newItemList,
    options: state.gin.options,
    brandNames: state.gin.brands,
    subgroupNames: state.gin.subgroups,
    ipackagingtypesNames: state.gin.packagingtypes,

    // IMAGE
    ginFinalCrop: state.gin.ginFinalCrop,

    // GENERAL AND MEASUREMENTS
    base_item_description: state.gin.base_item_description,
    base_item_description_original: state.gin.base_item_description_original,
    product_type: state.gin.product_type,
    product_type_original: state.gin.product_type_original,
    rbo: state.gin.rbo,
    rbo_original: state.gin.rbo_original,
    brand: state.gin.brand,
    brand_original: state.gin.brand_original,
    subgroup: state.gin.subgroup,
    subgroup_original: state.gin.sub_group_original,
    sub_group_original: state.gin.sub_group_original,
    ipackagingtypes: state.gin.ipackagingtypes,
    ipackagingtypes_original: state.gin.ipackagingtypes_original,
    sales_unit: state.gin.sales_unit,
    sales_unit_original: state.gin.sales_unit_original,
    basic_packaging_unit: state.gin.basic_packaging_unit,
    basic_packaging_unit_original: state.gin.basic_packaging_unit_original,
    qty_per_unit: state.gin.qty_per_unit,
    qty_per_unit_original: state.gin.qty_per_unit_original,
    ilength: state.gin.ilength,
    ilength_original: state.gin.ilength_original,
    width: state.gin.width,
    width_original: state.gin.width_original,
    weight: state.gin.weight,
    weight_original: state.gin.weight_original,

    rbos: state.gmp.rbos, // options
    brands: state.gin.brands,// options
    subgroups: state.gin.subgroups, // options

    // SPECIAL FIELDS
    picks: state.gin.picks,
    picks_original: state.gin.picks_original,
    // NIN DB - Tax
    gst_relevant: state.gin.gst_relevant,
    material_type: state.gin.material_type,
    hsn_code: state.gin.hsn_code,
    gst_tax_category: state.gin.gst_tax_category,
    service_acct_code: state.gin.service_acct_code,

    // PRICING FOR ALL
    gcur: state.gin.gcur,
    gstart: state.gin.gstart,
    gend: state.gin.gend,
    gprice: state.gin.gprice,
    gterm: state.gin.gterm,
    gterms: state.gin.gterms,
    gstarts: state.gin.gstarts,
    gends: state.gin.gends,
    gprices: state.gin.gprices,
    gcurrs: state.gin.gcurrs,
    gqtys: state.gin.gqtys,
    mocs: state.gin.mocs,
    specialmoc: state.gin.specialmoc,

    // CUSTOMER SPECIFIC PRICING
    bmasterarray: state.gin.bmasterarray,

    // TARGET COST
    cprice: state.gin.cprice,
    cterm: state.gin.cterm,
    ccur: state.gin.ccur,
    cstart: state.gin.cstart,
    cend: state.gin.cend,
    cqtys: state.gin.cqtys,
    cterms: state.gin.cterms,
    ccurs: state.gin.ccurs,
    cprices: state.gin.cprices,
    cstarts: state.gin.cstarts,
    cends: state.gin.cends,

    // SUPPLIER COST
    smasterarray: state.gin.smasterarray,

    // BOM
    rms: state.gin.rms,
    rmsd: state.gin.rmsd,
    rmsq: state.gin.rmsq,
    rmsw: state.gin.rmsw,
    BOMItemBaseQty: state.gin.BOMItemBaseQty,
    BOMItemBaseQty_original: state.gin.BOMItemBaseQty_original,
    comp: state.gin.comp,
    item_is: state.gin.item_is,
    item_is_original: state.gin.item_is_original,

    // VARIANTS
    variants: state.gin.variants,
    variantsDesc: state.gin.variantsDesc,
    variantsChecked: state.gin.variantsChecked,
    variantsToAdd: state.gin.variantsToAdd,
    originalVariantsDesc: state.gin.originalVariantsDesc,

    ginNum: state.gin.ginNum
  }
}

const mapDispatchToProps = dispatch => {
  return {
    editModeOn: () => {
      dispatch(actions.editModeOn());
    },
    setEdit: (data) => {
      dispatch(actions.setGINEdit(data));
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GINreviewShort);
