import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from 'react-redux';
import SweetAlert from "react-bootstrap-sweetalert";
import { PulseLoader } from 'react-spinners';

import withStyles from "@material-ui/core/styles/withStyles";
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import combineStyles from "shared/combineStyles";
// @material-ui/icons
import Settings from "@material-ui/icons/Settings";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Clearfix from "components/Clearfix/Clearfix.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";

import { eraseGMPAlertMsg } from "store/actions/GMP";
import { normalizePhone } from "shared/utility";

const getName = (arr, id, type) => {
  let name = '';
  arr.forEach(a => {
    if (id == a.id) {
      name = a[type];
    }
  })
  return name;
}

class GMPProfileReview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      justLoaded: true,
      changes: {}
    }
  }

  componentDidMount() {
    this.setState({
      justLoaded: false
    });
    ReactDOM.findDOMNode(this).scrollIntoView();
  }

  hideAlert = () => {
    ReactDOM.findDOMNode(this).scrollIntoView();
    this.props.eraseGMPAlertMsg();
    this.props.cancelReview();
  }

  hideAlertReturn = () => {
    ReactDOM.findDOMNode(this).scrollIntoView();
    this.props.eraseGMPAlertMsg();
    this.props.cancelProfileView();
  }

  renderLabel = (field, value, fieldName) => {
      let addedText = '',
          bpSource = this.props.finalValues;
      if (fieldName && !bpSource) {
        const initialValue = this.props.beforeValues[fieldName];
        if (initialValue !== value) {
          addedText = 'edited';
        }

        if (initialValue === null && value === 'n/a') {
          addedText = '';
        }

        if (initialValue === undefined && value === 'n/a') {
          addedText = '';
        }
      }

      if (bpSource) {
        const initialValue = this.props.beforeValues[fieldName];
        if (initialValue !== bpSource[fieldName]) {
          addedText = 'edited';
        }
      }
      return (
        <div style={{marginBottom:'10px'}}>
          <div style={{color:'#aaa',fontSize:'10px',fontWeight:'400'}}>{field}:</div>
          <div>{value} {addedText ? <span className="blue-btn">{addedText}</span> : ''}</div>
        </div>
      );
  }

  renderPhoneLabel = (field, value, fieldName) => {
      let addedText = '',
          bpSource = this.props.finalValues;
      if (fieldName && !bpSource) {
        const initialValue = this.props.beforeValues[fieldName];
        if (initialValue !== value) {
          addedText = 'edited';
        }

        if (initialValue === null && value === 'n/a') {
          addedText = '';
        }

        if (initialValue === undefined && value === 'n/a') {
          addedText = '';
        }
      }

      if (bpSource) {
        const initialValue = this.props.beforeValues[fieldName];
        if (initialValue !== bpSource[fieldName]) {
          addedText = 'edited';
        }
      }
      return (
        <div style={{marginBottom:'10px'}}>
          <div style={{color:'#aaa',fontSize:'10px',fontWeight:'400'}}>{field}:</div>
          <div>{normalizePhone(value)} {addedText ? <span className="blue-btn">{addedText}</span> : ''}</div>
        </div>
      );
  }

  renderWTaxCodes = () => {
    const bp = this.props.finalValues,
          opts = this.props.owhtopts;
    let wtaxcodesChosen = [];
    console.log(Object.keys(bp));
    Object.keys(bp).forEach((b,i) => {
      if (b.indexOf('wtaxcode_') !== -1) {
        const wtCode = b.split('_')[2];
        console.log('found wtaxcode', b, wtCode);
        opts.forEach((o) => {
          if (wtCode === o.WTCode && bp[b] === true) {
            wtaxcodesChosen.push(o.WTName);
          }
        });
      }
    });
    if (wtaxcodesChosen.length > 0) {
      return wtaxcodesChosen.map(wtcode => <div key={wtcode}>{wtcode}</div>);
    } else {
      return <div>- none -</div>
    }
  }

  handleApprove = (bpData) => {
    this.compareChangesAndApprove(bpData);
  }

  compareChangesAndApprove = (bpData) => {
    console.log('other initial values',this.props.originals);
    let changes = {},
        withholding = this.props.finalValues['withholding'],
        extraFields = ['withholding','threshold_overlook','surcharge_overlook','accrual_criteria',
                        'assessee_type','certificate_num','expiration_date','ni_num'];
    Object.keys(this.props.originals).forEach((key) => {
      if (this.props.originals[key] !== this.props.finalValues[key]) {
        changes[key] = this.props.finalValues[key];
      }
    });

    delete changes.countries;
    delete changes.currencies;
    delete changes.gsttypes;
    delete changes.states;

    if (this.props.finalValues.hasOwnProperty('withholding')) {
      if (withholding) {
        extraFields.forEach((eF) => {
          if (this.props.finalValues.hasOwnProperty(eF)) {
            changes[eF] = this.props.finalValues[eF];
          }
        });
        // wtaxcode
        const finalValuesFields = Object.keys(this.props.finalValues);
        let wtaxcodes = [];
        finalValuesFields.forEach((fV) => {
          if (fV.indexOf('wtaxcode_') !== -1) {
            if (this.props.finalValues[fV]) {
              wtaxcodes.push(fV.split('_')[2]);
            }
          }
        });

        if (wtaxcodes.length > 0) {
          changes.wtaxcodes = wtaxcodes;
        }
      }
    }

    if (this.props.finalValues.groupcode) {
      changes.groupcode = this.props.finalValues.groupcode;
    }

    if (this.props.finalValues.notes) {
      changes.notes = this.props.finalValues.notes;
    }

    console.log('these are the changes', bpData, changes);
    const data = {changes:JSON.stringify(changes), ...bpData};
    this.props.approveReview(data);
  }

  render() {
    console.log('review changes', this.state.changes);
    console.log('review state',this.props.initialValues);
    const { classes, cancelReview, approveReview, approveSuccess, denySuccess, showAlertModal, miniModalClosedHandler, failSubmit, createdGMPBP, messaged } = this.props;
    const bp = this.props.finalValues;
    const props = this.props;
    const isDisabled = true;
    let rbo_name = 'n/a';
    if (bp.rbo) {
      this.props.rboNames.forEach(function(r) {
        if (r.id == bp.rbo) {
          return rbo_name = r.name;
        }
      });
    }
    const billing_state = props.billingStates ? getName(props.billingStates, bp.billing_state, 'name') : 'n/a';
    const billing_country = getName(props.countries, bp.billing_country, 'name');
    const shipping_state = props.shippingStates ? getName(props.shippingStates, bp.shipping_state, 'name') : 'n/a';
    const shipping_country = getName(props.countries, bp.shipping_country, 'name');
    const billing_gstregtype = props.gsttypes && bp.billing_gstregtype ? getName(props.gsttypes, bp.billing_gstregtype, 'type') : 'n/a';
    const shipping_gstregtype = props.gsttypes && bp.shipping_gstregtype ? getName(props.gsttypes, bp.shipping_gstregtype, 'type') : 'n/a';
    console.log('add',props.gsttypes,bp.shipping_gstregtype)

    let miniModalAlert = (<SweetAlert
        success
        title="Success!"
        style={{ display: "block" }}
        onConfirm={this.hideAlertReturn}
        confirmBtnCssClass={
            classes.button + " " + classes.success
        }
        >
        <div className="">
        {messaged}
        </div>
        </SweetAlert>);

    if (messaged !== 'SUCCESSFULLY SUBMITTED' && messaged !== 'DENY SUBMITTED') {
    miniModalAlert = (<SweetAlert
           warning
           style={{ display: "block" }}
           title="Warning!"
           onConfirm={this.hideAlert}
           confirmBtnCssClass={
               classes.button + " " + classes.success
           }
           >
           {messaged}
           </SweetAlert>);
    }

    let isNIN = false;
    if (bp.DB === 'NIN_TEST' || bp.DB === 'NIN_LIVE') {
      isNIN = true;
    }

    let isNMX = false;
    if (bp.DB === 'NMX_TEST' || bp.DB === 'NMX_LIVE') {
      if (bp.billing_country === 'MX') {
        isNMX = true;
      }
    }

    let isNKR = false;
    if (bp.DB === 'NKR_TEST' || bp.DB === 'NKR_LIVE') {
      isNKR = true;
    }

    let isNGT = false;
    if (bp.DB === 'NGT_TEST' || bp.DB === 'NGT_LIVE') {
      if (bp.billing_country === 'GT') {
        isNGT = true;
      }
    }

    let isWithhold = false;
    if (bp.withholding) {
      isWithhold = true;
    }

    return (
      <div>
      {(messaged && !this.state.justLoaded) && miniModalAlert}
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <Settings />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  GMP BP Profile Review
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={5}>
                    {this.renderLabel('Company Name', bp.company_name, 'company_name')}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    {this.renderLabel('Database',bp.DB, 'DB')}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    {this.renderLabel('BP Code','NEW')}
                  </GridItem>
                  <hr />
                  <GridItem xs={12} sm={12} md={5}>
                    {this.renderPhoneLabel('General Telephone',bp.gen_tel, 'gen_tel')}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    {this.renderPhoneLabel('General Fax',bp.gen_fax ? bp.gen_fax : 'n/a', 'gen_fax', bp)}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    {this.renderLabel('Currency',bp.currency, 'currency')}
                  </GridItem>
                  <hr />
                  <GridItem xs={12} sm={12} md={5}>
                  {
                    isNIN &&
                    <div>{this.renderLabel('P.A.N. No', bp.panno ? bp.panno : 'n/a', 'panno', bp)}</div>
                  }
                  {
                    isNIN &&
                    <div>{this.renderLabel('G.S.T.I.N.', bp.gstin ? bp.gstin : 'n/a', 'gstin', bp)}</div>
                  }
                  {
                    (isNIN && isWithhold) &&
                    <div>{this.renderLabel('Certificate No.', bp.certificate_num ? bp.certificate_num : 'n/a', 'certificate_num', bp)}</div>
                  }
                  {
                    (isNIN && isWithhold) &&
                    <div>{this.renderLabel('N.I. no.', bp.ni_num ? bp.ni_num : 'n/a', 'ni_num', bp)}</div>
                  }
                  {
                    (isNIN && isWithhold) &&
                    <div>{this.renderLabel('Accrual Criteria', bp.accrual_criteria == "1" ? 'Accrual' : 'Cash', 'accrual_criteria', bp)}</div>
                  }
                  {
                    (isNIN && isWithhold) &&
                    <div>{this.renderLabel('Threshold Overlook', bp.threshold_overlook == "1" ? 'Yes' : 'No', 'threshold_overlook', bp)}</div>
                  }
                  {
                    (isNIN && isWithhold) &&
                    <div>{this.renderLabel('Surcharge Overlook', bp.surcharge_overlook == "1" ? 'Yes' : 'No', 'surcharge_overlook', bp)}</div>
                  }
                  </GridItem>
                  <GridItem xs={12} sm={12} md={7}>
                  {
                    isNIN &&
                    <div>{this.renderLabel('Subject to Withholding Tax', bp.withholding ? 'Yes' : 'No', 'withholding', bp)}</div>
                  }
                  {
                    (isNIN && isWithhold) &&
                    <div>{this.renderLabel('Expiration Date', bp.expiration_date ? bp.expiration_date : 'n/a', 'expiration_date', bp)}</div>
                  }
                  {
                    (isNIN && isWithhold) &&
                    <div>{this.renderLabel('Assessee Type', bp.assessee_type == "0" ? 'Company' : 'Others', 'assessee_type', bp)}</div>
                  }
                  {
                    (isNIN && isWithhold) &&
                    <div>
                      <strong>WTAX Codes Allowed :</strong>
                        {this.renderWTaxCodes()}
                    </div>
                  }
                  </GridItem>
                  <GridItem xs={12} sm={12} md={5}>
                  {
                    isNMX &&
                    <div>{this.renderLabel('RFC/CURP', bp.rfc_curp ? bp.rfc_curp : 'n/a', 'rfc_curp', bp)}</div>
                  }
                  {
                    isNGT &&
                    <div>{this.renderLabel('NIT', bp.nit ? bp.nit : 'n/a', 'NIT', bp)}</div>
                  }
                  </GridItem>
                  <GridItem xs={12} sm={12} md={7}>
                  {
                    (isNMX || isNKR) &&
                    <div>{this.renderLabel('Group Code', bp.groupcode ? bp.groupcode : 'n/a', 'groupcode', bp)}</div>
                  }
                  </GridItem>
                </GridContainer>
                <hr />
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <h4>Billing Address</h4>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    {this.renderLabel('Name', bp.billing_addressname, 'billing_addressname')}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    {this.renderLabel('Address 1', bp.billing_address1, 'billing_address1')}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    {this.renderLabel('Address 2', bp.billing_address2, 'billing_address2')}
                  </GridItem>

                  <GridItem xs={12} sm={12} md={3}>
                    {this.renderLabel('City', bp.billing_city, 'billing_city')}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    {this.renderLabel('Country', billing_country, 'billing_country', bp)}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    {this.renderLabel('State', billing_state, 'billing_state', bp)}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    {this.renderLabel('Postal Code', bp.billing_zipcode, 'billing_zipcode')}
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                  {
                    bp.billing_gstregno &&
                    <div>{this.renderLabel('GST Reg\'n No.', bp.billing_gstregno, 'billing_gstregno')}</div>
                  }
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                  {
                    (billing_gstregtype && isNIN) &&
                    <div>{this.renderLabel('GST Reg\'n Type.', billing_gstregtype, 'billing_gstregtype', bp)}</div>
                  }
                  </GridItem>
                </GridContainer>
                <hr />
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <h4>Shipping Address</h4>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    {this.renderLabel('Name', bp.shipping_addressname, 'shipping_addressname')}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    {this.renderLabel('Address 1', bp.shipping_address1, 'shipping_address1')}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    {this.renderLabel('Address 2', bp.shipping_address2, 'shipping_address2')}
                  </GridItem>

                  <GridItem xs={12} sm={12} md={3}>
                    {this.renderLabel('City', bp.shipping_city, 'shipping_city')}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    {this.renderLabel('Country', shipping_country, 'shipping_country', bp)}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    {this.renderLabel('State', shipping_state, 'shipping_state', bp)}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    {this.renderLabel('Postal Code', bp.shipping_zipcode, 'shipping_zipcode')}
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    {
                      bp.shipping_gstregno &&
                      <div>{this.renderLabel('GST Reg\'n No.', bp.shipping_gstregno, 'shipping_gstregno')}</div>
                    }
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    {
                      (shipping_gstregtype && isNIN) &&
                      <div>{this.renderLabel('GST Reg\'n Type', shipping_gstregtype, 'shipping_gstregtype')}</div>
                    }
                  </GridItem>
                </GridContainer>
                <hr />
                <GridContainer>
                <GridItem xs={12} sm={12} md={12}><h4>Contacts</h4></GridItem>

                <GridItem xs={12} sm={12} md={3}>
                  {this.renderLabel('Accounts Payable Name', bp.accounts_payable_name, 'accounts_payable_name')}
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  {this.renderLabel('Position', bp.accounts_payable_position, 'accounts_payable_position')}
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  {this.renderPhoneLabel('AP Telephone', bp.accounts_payable_tel, 'accounts_payable_tel')}
                </GridItem>
                <GridItem xs={12} sm={12} md={5}>
                  {this.renderLabel('Accounts Payable Email', bp.accounts_payable_email, 'accounts_payable_email')}
                </GridItem>

                <GridItem xs={12} sm={12} md={3}>
                  {this.renderLabel('Purchasing Name', bp.purchasing_name, 'purchasing_name')}
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  {this.renderLabel('Position', bp.purchasing_position, 'purchasing_position')}
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  {this.renderPhoneLabel('Telephone', bp.purchasing_tel, 'purchasing_tel')}
                </GridItem>
                <GridItem xs={12} sm={12} md={5}>
                  {this.renderLabel('Purchasing Email', bp.purchasing_email, 'purchasing_email')}
                </GridItem>

                <GridItem xs={12} sm={12} md={3}>
                  {bp.other_name && this.renderLabel('Other Name', bp.other_name, 'other_name')}
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  {bp.other_position && this.renderLabel('Position', bp.other_position, 'other_position')}
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  {bp.other_tel && this.renderPhoneLabel('Telephone.', bp.other_tel, 'other_tel')}
                </GridItem>
                <GridItem xs={12} sm={12} md={5}>
                  {bp.other_email && this.renderLabel('Other Email', bp.other_email, 'other_email')}
                </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                  {bp.notes && <div><h4>Notes</h4>
                  <p>{bp.notes}</p></div>}
                  </GridItem>
                </GridContainer>
                <hr />
                <GridContainer>
                  <div style={{marginTop:'20px'}} className="separate-btns">
                  <GridItem xs={12} sm={12} md={12}>
                    {!this.props.loadingReview && <div>
                      <Button
                        color="success"
                        className={classes.updateProfileButton}
                        onClick={() => {
                          this.handleApprove(this.props.finalValues);
                        }}>
                        APPROVE
                      </Button>
                      <Button
                        color="rose"
                        className={classes.updateProfileButton}
                        onClick={cancelReview}
                      >
                        BACK TO EDIT
                      </Button>
                    </div>}

                    {this.props.loadingReview && <div>Please Wait <PulseLoader /></div>}
                  </GridItem>
                  </div>
                </GridContainer>
                <Clearfix />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    initialValues: props.bp,
    createdGMPBP: state.gmp.createdGMPBP,
    messaged: state.gmp.message,
    loadingReview: state.gmp.loadingReview,
    beforeValues: state.form.gmpProfileForm.initial,
    owhtopts: state.common.addinfo.owhtopts,
    finalValues: state.form.gmpProfileForm.values
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    eraseGMPAlertMsg: () => {
      dispatch(eraseGMPAlertMsg())
    }
  }
}

const combinedStyle = combineStyles(sweetAlertStyle, userProfileStyles);
export default connect(mapStateToProps,mapDispatchToProps)(withStyles(combinedStyle)(GMPProfileReview));
