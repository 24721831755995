import React, { Component } from 'react';
// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
import Delete from '@material-ui/icons/Delete';
// core components
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyleNoOverflow2.jsx";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

const coloredSelect = {fontSize:'12px !important'};

class BPDropdown extends Component  {
    constructor(props) {
      super(props);
      const obj = props.dropdownObject;

      this.state = {
        value: obj.dict[obj.value] ? obj.dict[obj.value] : '',
        error: '',
      }
    }

    handleTextInputChange = e => {
      this.setState({ value: e.target.value.toUpperCase() });
    }

    render() {
      console.log('modalclasses', this.props.classes);
      const props = this.props,
            { classes } = props,
            { indx, desc, field, options, title, choose } = props.dropdownObject;
      let list = [], count = 0;

      if (options.length > 0) {
        list = options.map((o,key) => {
          const value = o[indx],
                label = o[desc];

          const aa = label.substring(0, this.state.value.length);
          const bb = this.state.value;
          if (aa && bb) {
            if (aa.toUpperCase() !== bb.toUpperCase()) {
              return null;
            }
          }
          count++;
          return (
            <ListItem
              key={'key'+key}
              button
              onClick={() => {
                choose(value, field);
                props.close();
              }}
              style={coloredSelect}
              >
              <div style={{marginLeft:'15px'}}>{label}</div>
            </ListItem>
          )
        });
      }

      return (
          <div>
            <div className="dialog-overflow">
              <Dialog
                  classes={{
                      root: classes.center,
                      paper: classes.modal
                  }}
                  maxWidth="xs"
                  style={{overflow:'hidden !important'}}
                  open={props.open}
                  keepMounted
                  fullWidth={true}
                  onClose={() => {
                    props.close()
                  }}
                  aria-labelledby="modal-slide-title"
                  aria-describedby="modal-slide-description">
                  <DialogTitle
                      id="classic-modal-slide-title"
                      disableTypography
                      className={classes.modalHeader}>
                      <Button
                          justIcon
                          className={classes.modalCloseButton}
                          key="close"
                          aria-label="Close"
                          color="transparent"
                          onClick={() => {
                            props.close();
                          }}
                      >
                          <Close className={classes.modalClose} />
                      </Button>
                      <h4 className={classes.modalTitle}><strong>{title}</strong></h4>
                  </DialogTitle>
                <GridContainer style={{overflow:'hidden'}}>
                  <GridItem xs={12} sm={12} md={12}>
                      <div style={{padding:'0 20px 10px 20px'}} className="parentable">
                          <CustomInput
                            labelText="Filter"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              onChange:this.handleTextInputChange,
                              value: this.state.value,
                              autoFocus: true,
                              role: 'presentation',
                              autoComplete: 'nope'
                            }}
                          />
                          <div className="child-input-close">
                            <Button
                                justIcon
                                className={classes.modalCloseButton}
                                key="close"
                                aria-label="Close"
                                color="transparent"
                                onClick={() => {
                                  this.setState({ value: '' });
                                }}
                            >
                                <Delete />
                            </Button>
                          </div>
                      </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <div style={{height:'215px',overflowY:'scroll',overflowX:'hidden',marginBottom:20,marginLeft:20,marginTop:10}}>
                      {!this.state.value && <ListItem
                        button
                        onClick={() => {
                          choose('', field);
                          props.close();
                        }}
                        style={coloredSelect}
                        >
                        <div style={{marginLeft:'15px'}}>- none -</div>
                      </ListItem>}
                      {list}
                    </div>
                    <div className="text-center" style={{fontSize:10,color:'5e5e5e',marginBottom:10}}>* Click <Delete style={{fontSize:14}}/> to clear filter and see options</div>
                  </GridItem>
                </GridContainer>
            </Dialog>
            </div>
          </div>
      );
    }
}

export default withStyles(modalStyle)(BPDropdown);
