import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import * as actions from '../../store/actions';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import ReactTable from "react-table";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import styles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import cloneDeep from "lodash/cloneDeep";

import Button from '@material-ui/core/Button';
import Button2 from "components/CustomButtons/Button.jsx";
import { PulseLoader } from 'react-spinners';

class GINqtyAlert extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: this.props.duplicates,
      selected: [],
      toBeDeleted: {},
      validationMessage: '',
      loading: false
    }
  }

  handleUpdate2 = (toBeDeleted, singleArrToBeDeletedMain) => {
    console.log('for deletion -->' ,toBeDeleted, singleArrToBeDeletedMain);
    let copyObj = {};

    ['ccurs','cstarts','cends','cprices','cqtys','cterms','ccodes'].forEach((field) => {
      let copy = cloneDeep(this.props[field]);
      Object.keys(toBeDeleted).forEach((db) => {
        copy[db] = copy[db].filter((f,i) => {
          if (toBeDeleted[db].indexOf(i) !== -1) {
            return false;
          }
          return true;
        });
      });
      copyObj[field] = copy;
    });

    Object.keys(singleArrToBeDeletedMain).forEach((db) => {
      if (singleArrToBeDeletedMain[db].length > 0) {
        const singleArrToBeDeleted = singleArrToBeDeletedMain[db];

        const singleFields = ['ccur','cstart','cend','cprice','cterm','ccode'],
              multipleFields = ['ccurs','cstarts','cends','cprices', 'cterms','ccodes'];
        singleFields.forEach((field) => {
          if (!copyObj.hasOwnProperty(field)) {
            copyObj[field] = cloneDeep(this.props[field]);
          }
          copyObj[field][db] = null;
          console.log('deleting', field, db);
        });

        multipleFields.forEach(field => {
          if (!copyObj.hasOwnProperty(field)) {
            copyObj[field] = cloneDeep(this.props[field]);
          }
        });

        if (copyObj.cqtys[db]) {
          if (copyObj.cqtys[db].length > 0) {
            console.log('looking for quantity 1-999');
            // select whichever has quantity = 1-999
            let foundQty = -1, foundAlready = false;
            copyObj.cqtys[db].forEach((qty,index) => {
              if (!foundAlready) {
                if (parseInt(qty)) {
                  if (parseInt(qty) >= 1 && parseInt(qty) < 1000) {
                    foundQty = index;
                    foundAlready = true;
                  }
                }
              }
            });

            if (foundQty !== -1) {
              // copy 1st row
              console.log('will copy to first row');
              singleFields.forEach((field,i) => {
                let copy = cloneDeep(copyObj[multipleFields[i]])
                copyObj[field][db] = copy[db][foundQty];
                copyObj[multipleFields[i]][db] = copy[db].filter((cc,j) => {
                  if (j === foundQty) {
                    return false;
                  }
                  return true;
                });
              });

              copyObj.cqtys[db] = copyObj.cqtys[db].filter((gq,jj) => {
                if (jj === foundQty) {
                  return false;
                }
                return true;
              });
            }
          }
        }
      }
    });

    this.props.updateGINMultiple(copyObj);
  }

  handleUpdate = (toBeDeleted, db, type) => {
    console.log('updating', toBeDeleted, db, type);
    if (type === 'multiple' && toBeDeleted.length > 0) {
      ['ccurs','cstarts','cends','cprices','cqtys','cterms','ccodes'].forEach((field) => {
        const valuesCopy = [ ...this.props[field][db] ];
        this.props.updateGINEdit({
          field,
          value: valuesCopy.filter((f,i) => {
            if (toBeDeleted.indexOf(i) !== -1) {
              return false;
            }
            return true;
          }),
          db
        });
      });
    }

    if (type === 'single' && toBeDeleted.length > 0) {

      // ['ccur','cstart','cend','cprice','cterm','ccode'].forEach((field) => {
      //   const valueCopy = cloneDeep(this.props[field][db]);
      //   console.log('deleting', field, db);
      //   if (toBeDeleted.length > 0) {
      //     this.props.updateGINEdit({
      //       field,
      //       value: null,
      //       db
      //     });
      //   }
      // });

      let copyObj = {};
      const singleFields = ['ccur','cstart','cend','cprice','cterm','ccode'],
            multipleFields = ['ccurs','cstarts','cends','cprices', 'cterms', 'ccodes'];
      singleFields.forEach((field) => {
        copyObj[field] = cloneDeep(this.props[field]);
        copyObj[field][db] = null;
        console.log('deleting', field, db);
      });

      // find gcurrs, gstarts
      copyObj.ccurs = cloneDeep(this.props.ccurs);
      copyObj.cstarts = cloneDeep(this.props.cstarts);
      copyObj.cends = cloneDeep(this.props.cends);
      copyObj.cprices = cloneDeep(this.props.cprices);
      copyObj.cterms = cloneDeep(this.props.cterms);
      copyObj.cqtys = cloneDeep(this.props.cqtys);
      copyObj.ccodes = cloneDeep(this.props.ccodes);

      if (this.props.cqtys[db]) {
        if (this.props.cqtys[db].length > 0) {
          // select whichever has quantity = 1-999
          let foundQty = -1, foundAlready = false;
          this.props.cqtys[db].forEach((qty,index) => {
            if (!foundAlready) {
              if (parseInt(qty)) {
                if (parseInt(qty) >= 1 && parseInt(qty) < 1000) {
                  foundQty = index;
                  foundAlready = true;
                }
              }
            }
          });

          if (foundQty !== -1) {
            // copy 1st row
            singleFields.forEach((field,i) => {
              let copy = { ...this.props[multipleFields[i]] };
              console.log(field, multipleFields, copy);
              copyObj[field][db] = copy[db][foundQty];
              copyObj[multipleFields[i]][db] = copy[db].filter((cc,j) => {
                if (j === foundQty) {
                  return false;
                }
                return true;
              });
            });

            let copy = { ...this.props.cqtys };
            copyObj.cqtys[db] = copyObj.cqtys[db].filter((gq,jj) => {
              if (jj === foundQty) {
                return false;
              }
              return true;
            });
          }
        }
      }

      this.props.updateGINMultiple(copyObj);

    }
  }

  handleSave = () => {
    this.setState({
      loading:true
    });
    // check if all have at least one
    console.log('dupes', this.props.duplicates);
    console.log('selected', this.state.selected);

    let selectedComplete = true;
    this.props.duplicates.forEach((dupe) => {
      const db = dupe.DB,
            range = dupe.range,
            currency = dupe.obj[0].currency;
      let index = null;
      this.state.selected.forEach((sel) => {
        if (sel.db === db && (sel.range === range && sel.currency === currency)) {
          index = sel.index;
        }
      });
      if (index === null) {
        selectedComplete = false;
      }
    });
    console.log('selectedComplete', selectedComplete);
    if (!selectedComplete) {
      this.setState({
        validationMessage: 'Please make sure all listed Ranges have at least one Price',
        loading: false
      });
      return;
    }

    // collect all indexes for each DB
    let collection = {};
    // find array of indexes and get to be deleted indexes
    this.state.data.forEach((dat) => {
      const db = dat.DB;
      dat.obj.forEach((dob) => {
        if (collection.hasOwnProperty(db)) {
          collection[db].push(dob.indx);
        } else {
          collection[db] = [dob.indx];
        }
      });
    });

    let selecteds = {};
    this.state.selected.forEach((n) => {
      console.log(n);
      const db = n.db,
            range = n.range,
            currency = n.currency,
            indexSelect = n.index;

      if (selecteds.hasOwnProperty(n.db)) {
        selecteds[n.db].push(n.index);
      } else {
        selecteds[n.db] = [n.index];
      }
    });

    console.log('collection is', collection);
    console.log('selecteds is', selecteds);
    let toBeDeleted = {};
    let singleArrToBeDeleted = {};
    Object.keys(collection).forEach((db) => {
      toBeDeleted[db] = collection[db].filter((ind) => {
        if (selecteds[db].indexOf(ind) === -1 && ind !== -1) {
          return true;
        } else {
          return false;
        }
      });

      singleArrToBeDeleted[db] = collection[db].filter((ind) => {
        if (selecteds[db].indexOf(ind) === -1 && ind === -1) {
          return true;
        } else {
          return false;
        }
      });
    });

    console.log('these are the dynamic indexes to be deleted', toBeDeleted);
    console.log('these are the single indexes to be deleted', singleArrToBeDeleted);

    // Object.keys(toBeDeleted).forEach((db) => {
    //   if (toBeDeleted[db].length > 0) {
    //     this.handleUpdate(toBeDeleted[db], db, 'multiple');
    //   }
    // });
    // Object.keys(singleArrToBeDeleted).forEach((db) => {
    //   if (singleArrToBeDeleted[db].length > 0) {
    //     this.handleUpdate(singleArrToBeDeleted[db], db, 'single');
    //   }
    // });
    this.handleUpdate2(toBeDeleted, singleArrToBeDeleted);

    // transfer
    this.setState({
      loading: false
    })

    if (this.props.close) {
      this.props.close();
    }
  }

  handleClick = (event, n) => {
    let selected = [ ...this.state.selected ];
    const checkIfSelected = this.checkIfSelected(n);
    if (!checkIfSelected) {
      const copySelected = selected.filter((ss) => {
        if (ss.table === n.table) {
          return false;
        }
        return true;
      });
      const selectedNow = [ ...copySelected, {
        range: n.range,
        db: n.db,
        index: n.index,
        currency: n.currency,
        table: n.table,
        jj: n.jj
      }];
      this.setState({
        selected: selectedNow
      });
    } else {
      const deselected = selected.filter((ss) => {
        if (ss.table === n.table) {
          return false;
        }
        return true;
      });
      this.setState({
        selected: deselected
      });
    }
  }

  checkIfSelected = (n) => {
    const range = n.range,
          db = n.db,
          currency = n.currency,
          jj = n.jj,
          index = n.index;
    let selected = false;
    this.state.selected.forEach((ss) => {
      if ((ss.table === n.table && ss.jj === n.jj)) {
        selected = true;
      }
    });
    return selected;
  }

  renderTables = (classes) => {
    if (this.state.data.length > 0) {
      console.log('this is the crux of the data', this.state.data, this.state.selected);
      return this.state.data.map((dupe, i) => {
        let dupesData = [];
        let currency;
        dupe.obj.forEach((d) => {
          currency = d.currency;
          let fieldName = 'cost';
          if (!d.cost) {
            fieldName = 'price';
          }
          const totall = parseFloat(d[fieldName]) * parseInt(d.qty);
          dupesData.push({
            range: dupe.range,
            index: d.indx,
            db: dupe.DB,
            qty: d.qty,
            term: d.term,
            cost: `${d.currency} ${parseFloat(d[fieldName]).toLocaleString()}`,
            effective_dates: `${d.start} TO ${d.end}`,
            currency: d.currency,
            total: `${d.currency} ${totall.toLocaleString()}`,
            table: i
          });
        });
        return (
        <div key={`tableqty${i}`} style={{paddingBottom:'30px'}}>
          <h4>Item {dupe.itemName} from <strong>{dupe.DB}</strong>, <strong>{dupe.range}</strong> Range for <strong>{currency}</strong></h4>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                </TableCell>
                <TableCell align="right">Qty</TableCell>
                <TableCell align="right">Term</TableCell>
                <TableCell align="right">Cost</TableCell>
                <TableCell align="right">Effective Dates</TableCell>
                <TableCell align="right">Currency</TableCell>
                <TableCell align="right">Total Cost</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dupesData.map((n, jj) => {
                  const isSelected = this.checkIfSelected({ ...n, jj });
                  return (
                    <TableRow
                      hover
                      onClick={event => this.handleClick(event, { ...n, jj })}
                      role="checkbox"
                      tabIndex={-1}
                      key={`trr${n.index}`}
                      selected={isSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          tabIndex={-1}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{checked: classes.checked}}
                          checked={isSelected}
                        />
                      </TableCell>
                      <TableCell align="right">{n.qty}</TableCell>
                      <TableCell align="right">{n.term}</TableCell>
                      <TableCell align="right">{n.cost}</TableCell>
                      <TableCell align="right">{n.effective_dates}</TableCell>
                      <TableCell align="right">{n.currency}</TableCell>
                      <TableCell align="right">{n.total}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </div>
        )
      });
    }
  }

  render() {
    const { classes } = this.props;
    console.log('ginqtyalertcost st and props', this.state, this.props);
    return (
      <Dialog
        open={this.props.open}
        keepMounted
        onClose={this.handleClose}
        maxWidth="md"
        fullWidth={true}
      >
        <DialogTitle>
          <div style={{textAlign:'center',fontSize:18}}>Only 1 Quantity for each Cost Range/Currency/Dates/Terms combination is allowed.</div>
          {this.props.close &&
        <div className="float-right">
          <IconButton aria-label="Close" onClick={this.props.close}>
            <Close />
          </IconButton></div>}
        </DialogTitle>
        <div style={{padding:'0 40px 20px 40px'}}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div style={{textAlign:'center'}}>Please select the Quantity you want to Keep</div>
              {this.renderTables(classes)}
              {this.state.loading && <PulseLoader />}
              <div style={{padding:'20px'}}>
                <Button2
                  color="success"
                  onClick={this.handleSave}
                >
                  SAVE
                </Button2>
                {this.state.validationMessage && <div className="red-text text-center">{this.state.validationMessage}</div>}
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ccur: state.gin.ccur,
    cstart: state.gin.cstart,
    cend: state.gin.cend,
    cprice: state.gin.cprice,
    cterm: state.gin.cterm,
    ccode: state.gin.ccode,

    ccurs: state.gin.ccurs,
    cstarts: state.gin.cstarts,
    cends: state.gin.cends,
    cprices: state.gin.cprices,
    cqtys: state.gin.cqtys,
    cterms: state.gin.cterms,
    ccodes: state.gin.ccodes,

    itemCodes: state.gin.itemCodes,
    openedDBs: state.gin.openedDBs,
    options: state.gin.options,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateGINEdit: (data) => {
      dispatch(actions.updateGINEdit(data));
    },
    updateGINMultiple: (data) => {
      dispatch(actions.updateGINMultiple(data));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GINqtyAlert));
