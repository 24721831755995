import React, { Component } from 'react';
import ReactDOM from "react-dom";
import { connect } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import { withStyles } from '@material-ui/core/styles';
import styles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";

import dbList from "shared/activeDBs";

const dbs = dbList.map((db) => {
  return db.name;
});

const Single = props => {
  const { permObj, classes } = props;
  const id = props.actions[0].id,
        showLabel = props.actions[0].showLabel,
        label = props.actions[0].subCategory,
        name = props.actions[0].name;
  return (
    <div style={{padding:'20px 0'}}>
      {showLabel && <div className="pilled2-relative"><h4 className="pilled2">{label}</h4></div>}
      {props.actions[0].name}
      <Checkbox
        tabIndex={-1}
        checkedIcon={<Check className={classes.checkedIcon} />}
        icon={<Check className={classes.uncheckedIcon} />}
        classes={{checked: classes.checked}}
        checked={permObj.hasOwnProperty(id)}
        onClick={() => {
          console.log('clicked', name, id);
          if (permObj.hasOwnProperty(id)) {
            delete permObj[id];
          } else {
            permObj[id] = [null];
          };
          props.choosePerm(permObj);
        }}
      />
    </div>
  )
}

const SingleRemove = props => {
  const { permObj, classes } = props;
  const id = props.actions[0].id,
        showLabel = props.actions[0].showLabel,
        label = props.actions[0].subCategory,
        name = props.actions[0].name;
  return (
    <div style={{padding:'20px 0'}}>
      {showLabel && <div className="pilled2-relative"><h4 className="pilled2">{label}</h4></div>}
      {props.actions[0].name}
      <Checkbox
        tabIndex={-1}
        checkedIcon={<Check className={classes.checkedIcon} />}
        icon={<Check className={classes.uncheckedIcon} />}
        classes={{checked: classes.checked}}
        checked={permObj.hasOwnProperty(id)}
        onClick={() => {
          console.log('clicked', name, id);
          if (permObj.hasOwnProperty(id)) {
            delete permObj[id];
          } else {
            permObj[id] = [null];
          };
          props.choosePerm(permObj);
        }}
      />
    </div>
  )
}

const HasDBs = props => {
  const { permObj, classes } = props;
  const showLabel = props.actions[0].showLabel,
        label = props.actions[0].subCategory;
  return (
  <div style={{marginBottom:'40px'}}>
    {showLabel && <div className="pilled2-relative"><h4 className="pilled2">{label}</h4></div>}
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
          </TableCell>
          {dbList.map((db, i) => {
            return <TableCell align="right" key={`tcr${i}`}><div className="db-cell" style={{backgroundColor:db.color}}>{db.name.split('_')[0]}</div></TableCell>
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {props.actions.map((n, i) => {
            return (
              <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                key={`trr${i}`}
              >
                <TableCell align="left">
                  <div style={{textAlign:'left'}}>{n.name}</div>
                </TableCell>
                {dbList.map((db, ii) => {
                  const isSendToDirect = permObj[9] ? permObj[9].indexOf(db.name) !== -1 ? true : false : false;
                  let isChecked = permObj.hasOwnProperty(n.id) ? permObj[n.id].indexOf(db.name) !== -1 ? true : false : false;
                  if (isSendToDirect && n.id === 8) {
                    isChecked = true;
                  }
                  return (
                    <TableCell key={`tccb${ii}`}>
                      <Checkbox
                        tabIndex={-1}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{checked: classes.checked}}
                        disabled={isSendToDirect && n.id === 8}
                        checked={isChecked}
                        onClick={() => {
                          console.log('click', db.name, n.name, n.id);
                          let autoAdd_1 = false;
                          // if Approve BP & Direct to SAP autocheck Create BP
                          if (n.id === 9) {
                            autoAdd_1 = true;
                          }
                          if (permObj.hasOwnProperty(n.id)) {
                            if (permObj[n.id].length > 0) {
                              const indx = permObj[n.id].indexOf(db.name);
                              if (indx === -1) {
                                permObj[n.id].push(db.name);
                              } else {
                                permObj[n.id] = permObj[n.id].filter((d,i) => {
                                  if (i === indx) {
                                    return false;
                                  }
                                  return true;
                                });
                              }
                            }
                          } else {
                            permObj[n.id] = [db.name];
                          }

                          if (autoAdd_1) {
                            if (permObj.hasOwnProperty(8)) {
                              if (permObj[8].length > 0) {
                                const indx = permObj[8].indexOf(db.name);
                                if (indx === -1) {
                                  permObj[8].push(db.name);
                                }
                              }
                            } else {
                              permObj[8] = [db.name];
                            }
                          }

                          let newPermObj = {};
                          Object.keys(permObj).forEach((perm_id) => {
                            if (permObj[perm_id].length !== 0) {
                              newPermObj[perm_id] = permObj[perm_id];
                            }
                          });
                          props.choosePerm(newPermObj);
                          console.log('perm obj',permObj);
                        }}
                      />
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
      </TableBody>
    </Table>
  </div>
  )
}

const HasDBsRemove = props => {
  const { removeObj, classes, filterObj } = props;
  const showLabel = props.actions[0].showLabel,
        label = props.actions[0].subCategory;
  return (
  <div style={{marginBottom:'40px'}}>
    {showLabel && <div className="pilled2-relative"><h4 className="pilled2">{label}</h4></div>}
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
          </TableCell>
          {dbList.map((db, i) => {
            return <TableCell align="right" key={`tcr${i}`}><div className="db-cell" style={{backgroundColor:db.color}}>{db.name.split('_')[0]}</div></TableCell>
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {props.actions.map((n, i) => {
            return (
              <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                key={`trr${i}`}
              >
                <TableCell align="left">
                  <div style={{textAlign:'left'}}>{n.name}</div>
                </TableCell>
                {dbList.map((db, ii) => {
                  const isChecked = removeObj.hasOwnProperty(n.id) ? removeObj[n.id].indexOf(db.name) !== -1 ? true : false : false;
                  const isDisabled = filterObj.hasOwnProperty(n.id) ? filterObj[n.id].indexOf(db.name) !== -1 ? true : false : false;
                  return (
                    <TableCell key={`tccb${ii}`}>
                      {isDisabled && <Checkbox
                        tabIndex={-1}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{checked: classes.checked}}
                        checked={isChecked}
                        onClick={() => {
                          console.log('click', db.name, n.name, n.id);
                          let autoAdd_1 = false;
                          // if Approve BP & Direct to SAP autocheck Create BP
                          if (n.id === 9) {
                            autoAdd_1 = true;
                          }
                          if (removeObj.hasOwnProperty(n.id)) {
                            if (removeObj[n.id].length > 0) {
                              const indx = removeObj[n.id].indexOf(db.name);
                              if (indx === -1) {
                                removeObj[n.id].push(db.name);
                              } else {
                                removeObj[n.id] = removeObj[n.id].filter((d,i) => {
                                  if (i === indx) {
                                    return false;
                                  }
                                  return true;
                                });
                              }
                            }
                          } else {
                            removeObj[n.id] = [db.name];
                          }

                          let newPermObj = {};
                          Object.keys(removeObj).forEach((perm_id) => {
                            if (removeObj[perm_id].length !== 0) {
                              newPermObj[perm_id] = removeObj[perm_id];
                            }
                          });
                          props.choosePerm(newPermObj);
                          console.log('remove perm obj',removeObj);
                        }}
                      />}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
      </TableBody>
    </Table>
  </div>
  )
}

const UserSubBulkMatrix = props => {
  const { classes } = props;
  const id = props.actions[0].id,
        name = props.actions[0].name;
  console.log('bulk and filter', props.bulkObj, props.filterObj, props.removeObj);
  let permObj = JSON.parse(JSON.stringify(props.filterObj));
  let bulkObj = JSON.parse(JSON.stringify(props.bulkObj));
  let removeObj = JSON.parse(JSON.stringify(props.removeObj));

  // singles = no DBs
  // hasDBs = multiple DB checkboxes
  const singles = props.actions.filter(act => {
    if (!act.hasDBs) {
      return true;
    }
  });
  const hasDBs = props.actions.filter(act => {
    if (act.hasDBs) {
      return true;
    }
  });

  let singlesGrouped = {};
  singles.forEach(act => {
    if (act.subCategory) {
      if (singlesGrouped.hasOwnProperty(act.subCategory)) {
        singlesGrouped[act.subCategory].push(act);
      } else {
        singlesGrouped[act.subCategory] = [act];
      }
    }
  });

  let hasDBsGrouped = {};
  hasDBs.forEach(act => {
    if (act.subCategory) {
      if (hasDBsGrouped.hasOwnProperty(act.subCategory)) {
        hasDBsGrouped[act.subCategory].push(act);
      } else {
        hasDBsGrouped[act.subCategory] = [act];
      }
    }
  });

  console.log('grouped', singlesGrouped, hasDBsGrouped);

  // FILTERS
  const singleFilterRender = Object.keys(singlesGrouped).map((category, i) => {
    return <Single
            key={`singlesFilter${i}`}
            permObj={permObj}
            classes={classes}
            choosePerm={props.chooseFilter}
            actions={singlesGrouped[category]}
          />;
  });
  const hasDBsFilterRender = Object.keys(hasDBsGrouped).map((category, i) => {
    return <HasDBs
              key={`multiplesFilter${i}`}
              permObj={permObj}
              classes={classes}
              choosePerm={props.chooseFilter}
              actions={hasDBsGrouped[category]}
            />
  });

  // BULK ASSIGN
  const singleBulkAssignRender = Object.keys(singlesGrouped).map((category, i) => {
    return <Single
            key={`singlesBulkAssign${i}`}
            permObj={bulkObj}
            classes={classes}
            choosePerm={props.chooseBulk}
            actions={singlesGrouped[category]}
          />;
  });
  const hasDBsBulkAssignRender = Object.keys(hasDBsGrouped).map((category, i) => {
    return <HasDBs
              key={`multiplesBulkAssign${i}`}
              permObj={bulkObj}
              classes={classes}
              choosePerm={props.chooseBulk}
              actions={hasDBsGrouped[category]}
            />
  });

  // BULK REMOVE
  const singleBulkRemoveRender = Object.keys(singlesGrouped).map((category, i) => {
    return <Single
            key={`singlesBulkRemove${i}`}
            permObj={removeObj}
            classes={classes}
            choosePerm={props.chooseRemove}
            actions={singlesGrouped[category]}
          />;
  });
  const hasDBsBulkRemoveRender = Object.keys(hasDBsGrouped).map((category, i) => {
    return <HasDBsRemove
              key={`multiplesBulkRemove${i}`}
              removeObj={removeObj}
              filterObj={permObj}
              classes={classes}
              choosePerm={props.chooseRemove}
              actions={hasDBsGrouped[category]}
            />
  });

  return (
  <div style={{height:'1000px',overflowY:'scroll'}}>
    <div className="tiny-table2">
      <div>
        <div style={{paddingLeft:'10px'}} className="bold"><h3>Filters</h3></div>
        {hasDBsFilterRender}
        {singleFilterRender}
      </div>
      <div>
        <div style={{paddingLeft:'10px'}} className="bold green-text"><h3>Bulk Assign</h3></div>
        {hasDBsBulkAssignRender}
        {singleBulkAssignRender}
      </div>

      {Object.keys(permObj).length > 0 && <div>
        <div style={{paddingLeft:'10px'}} className="bold red-text"><h3>Bulk Remove</h3></div>
        {hasDBsBulkRemoveRender}
        {singleBulkRemoveRender}
      </div>}
  </div>
</div>
  );
}

const mapStateToProps = state => {
  return {
    userBulkFilterList: state.userPerms.userBulkFilterList
  }
}

export default connect(mapStateToProps, null)(withStyles(styles)(UserSubBulkMatrix));
