/*
    Handling getting the Active Directory
*/
export const FETCH_AD_START = 'FETCH_AD_START';
export const FETCH_AD_SUCCESS = 'FETCH_AD_SUCCESS';
export const FETCH_AD_FAIL = 'FETCH_AD_FAIL';
export const FETCH_AD = 'FETCH_AD';

/*
    Handling getting the GMP BPs
*/
export const FETCH_GMP_START = 'FETCH_GMP_START';
export const FETCH_GMP_SUCCESS = 'FETCH_GMP_SUCCESS';
export const FETCH_GMP_FAIL = 'FETCH_GMP_FAIL';
export const FETCH_GMP = 'FETCH_GMP';

export const FETCH_GMP_ADDRESS_START = 'FETCH_GMP_ADDRESS_START';
export const FETCH_GMP_ADDRESS_SUCCESS = 'FETCH_GMP__ADDRESS_SUCCESS';
export const FETCH_GMP_ADDRESS_FAIL = 'FETCH_GMP_ADDRESS_FAIL';
export const FETCH_GMP_ADDRESS = 'FETCH_GMP_ADDRESS';

export const FETCH_GMP_CONTACT_START = 'FETCH_GMP_CONTACT_START';
export const FETCH_GMP_CONTACT_SUCCESS = 'FETCH_GMP__CONTACT_SUCCESS';
export const FETCH_GMP_CONTACT_FAIL = 'FETCH_GMP_CONTACT_FAIL';
export const FETCH_GMP_CONTACT = 'FETCH_GMP_CONTACT';

export const FETCH_GMP_RBO_START = 'FETCH_GMP_RBO_START';
export const FETCH_GMP_RBO_SUCCESS = 'FETCH_GMP_RBO_SUCCESS';
export const FETCH_GMP_RBO_FAIL = 'FETCH_GMP_RBO_FAIL';
export const FETCH_GMP_RBO = 'FETCH_GMP_RBO';

export const CREATE_GMP = 'CREATE_GMP';
export const CREATE_GMP_START = 'CREATE_GMP_START';
export const CREATE_GMP_SUCCESS = 'CREATE_GMP_SUCCESS';
export const CREATE_GMP_FAIL = 'CREATE_GMP_FAIL';

export const CHANGE_ACTIVE_DB = 'CHANGE_ACTIVE_DB';
export const CHANGE_ACTIVE_BILLING_COUNTRY = 'CHANGE_ACTIVE_BILLING_COUNTRY';
export const CHANGE_ACTIVE_SHIPPING_COUNTRY = 'CHANGE_ACTIVE_SHIPPING_COUNTRY';

export const CREATE_GMP_BP = 'CREATE_GMP_BP';
export const CREATE_GMP_BP_START = 'CREATE_GMP_START';
export const CREATE_GMP_BP_SUCCESS = 'CREATE_GMP_BP_SUCCESS';
export const CREATE_GMP_BP_FAIL = 'CREATE_GMP_BP_FAIL';

export const CREATE_GMP_BP_EXISTING = 'CREATE_GMP_BP_EXISTING';
export const CREATE_GMP_BP_EXISTING_START = 'CREATE_GMP_BP_EXISTING_START';
export const CREATE_GMP_BP_EXISTING_SUCCESS = 'CREATE_GMP_BP_EXISTING_SUCCESS';
export const CREATE_GMP_BP_EXISTING_FAIL = 'CREATE_GMP_BP_EXISTING_FAIL';

export const DENY_GMP_BP = 'DENY_GMP_BP';
export const DENY_GMP_BP_START = 'DENY_GMP_BP_START';
export const DENY_GMP_BP_SUCCESS = 'DENY_GMP_BP_SUCCESS';
export const DENY_GMP_BP_FAIL = 'DENY_GMP_BP_FAIL';

export const DENY_GMP_BP_EXISTING = 'DENY_GMP_BP_EXISTING';
export const DENY_GMP_BP_EXISTING_START = 'DENY_GMP_BP_EXISTING_START';
export const DENY_GMP_BP_EXISTING_SUCCESS = 'DENY_GMP_BP_EXISTING_SUCCESS';
export const DENY_GMP_BP_EXISTING_FAIL = 'DENY_GMP_BP_EXISTING_FAIL';

export const ERASE_GMP_ALERT_MSG = 'ERASE_GMP_ALERT_MSG';
export const FETCH_GSTTYPE = 'FETCH_GSTTYPE';
export const FETCH_GSTTYPE_START = 'FETCH_GSTTYPE_START';
export const FETCH_GSTTYPE_SUCCESS = 'FETCH_GSTTYPE_SUCCESS';
export const FETCH_GSTTYPE_FAIL = 'FETCH_GSTTYPE_FAIL';

export const FETCH_GMP_SIMILARS = 'FETCH_GMP_SIMILARS';
export const FETCH_GMP_SIMILARS_START = 'FETCH_GMP_SIMILARS_START';
export const FETCH_GMP_SIMILARS_SUCCESS = 'FETCH_GMP_SIMILARS_SUCCESS';
export const FETCH_GMP_SIMILARS_FAIL = 'FETCH_GMP_SIMILARS_FAIL';
export const CLEAR_SIMILARS = 'CLEAR_SIMILARS';

export const FETCH_GMP_HISTORY = 'FETCH_GMP_HISTORY';
export const FETCH_GMP_HISTORY_START = 'FETCH_GMP_HISTORY_START';
export const FETCH_GMP_HISTORY_SUCCESS = 'FETCH_GMP_HISTORY_SUCCESS';
export const FETCH_GMP_HISTORY_FAIL = 'FETCH_GMP_HISTORY_FAIL';

export const FETCH_GMP_HISTORY_ITEM = 'FETCH_GMP_HISTORY_ITEM';
export const FETCH_GMP_HISTORY_ITEM_START = 'FETCH_GMP_HISTORY_ITEM_START';
export const FETCH_GMP_HISTORY_ITEM_SUCCESS = 'FETCH_GMP_HISTORY_ITEM_SUCCESS';
export const FETCH_GMP_HISTORY_ITEM_FAIL = 'FETCH_GMP_HISTORY_ITEM_FAIL';

export const FILTER_GMP_HISTORY_DATE = 'FILTER_GMP_HISTORY_DATE';
export const UPDATE_GMP_HISTORY_FILTERED = 'UPDATE_GMP_HISTORY_FILTERED';
export const UPDATE_GMP_SHARED_FILTERS = 'UPDATE_GMP_SHARED_FILTERS';

export const FETCH_GMP_EXISTING_SIMILARS = 'FETCH_GMP_EXISTING_SIMILARS';
export const FETCH_GMP_EXISTING_SIMILARS_START = 'FETCH_GMP_EXISTING_SIMILARS_START';
export const FETCH_GMP_EXISTING_SIMILARS_SUCCESS = 'FETCH_GMP_EXISTING_SIMILARS_SUCCESS';
export const FETCH_GMP_EXISTING_SIMILARS_FAIL = 'FETCH_GMP_EXISTING_SIMILARS_FAIL';

export const FETCH_EDI_PROGRAMS = 'FETCH_EDI_PROGRAMS';
export const FETCH_EDI_PROGRAMS_SUCCESS = 'FETCH_EDI_PROGRAMS_SUCCESS';
export const FETCH_EDI_PROGRAMS_FAIL = 'FETCH_EDI_PROGRAMS_FAIL';

export const CHECK_VENDOR_CODE = 'CHECK_VENDOR_CODE';
export const CHECK_VENDOR_CODE_START = 'CHECK_VENDOR_CODE_START';
export const CHECK_VENDOR_CODE_SUCCESS = 'CHECK_VENDOR_CODE_SUCCESS';
export const CHECK_VENDOR_CODE_FAIL = 'CHECK_VENDOR_CODE_FAIL';

export const CHECK_VENDOR_CODES = 'CHECK_VENDOR_CODES';
export const CHECK_VENDOR_CODES_SUCCESS = 'CHECK_VENDOR_CODES_SUCCESS';
export const CHECK_VENDOR_CODES_FAIL = 'CHECK_VENDOR_CODES_FAIL';

export const SET_LOADING_GMPBP = 'SET_LOADING_GMPBP';

export const RESET_GMP_BP = 'RESET_GMP_BP';

export const CONVERT_NEW_TO_EXISTING = 'CONVERT_NEW_TO_EXISTING';
export const CONVERT_NEW_TO_EXISTING_START = 'CONVERT_NEW_TO_EXISTING_START';
export const CONVERT_NEW_TO_EXISTING_SUCCESS = 'CONVERT_NEW_TO_EXISTING_SUCCESS';
export const CONVERT_NEW_TO_EXISTING_FAIL = 'CONVERT_NEW_TO_EXISTING_FAIL';

export const CONVERT_EXISTING_TO_NEW = 'CONVERT_EXISTING_TO_NEW';
export const CONVERT_EXISTING_TO_NEW_START = 'CONVERT_EXISTING_TO_NEW_START';
export const CONVERT_EXISTING_TO_NEW_SUCCESS = 'CONVERT_EXISTING_TO_NEW_SUCCESS';
export const CONVERT_EXISTING_TO_NEW_FAIL = 'CONVERT_EXISTING_TO_NEW_FAIL';

/*
    Handling setting active values
*/
export const SET_ACTIVE_COUNTRY = 'SET_ACTIVE_COUNTRY';
export const SET_ACTIVE_OFFICE = 'SET_ACTIVE_OFFICE';
export const SET_ACTIVE_DEPARTMENT = 'SET_ACTIVE_DEPARTMENT';
export const SET_ACTIVE_USER = 'SET_ACTIVE_USER';

export const SET_AD_MODAL_STATE = 'SET_AD_MODAL_STATE';
export const SET_AD_MINI_MODAL_STATE = 'SET_AD_MINI_MODAL_STATE';

/*
    Handling form submission
*/
export const ADD_AD_USER = 'ADD_AD_USER';
export const ADD_AD_USER_START = 'ADD_AD_USER_START';
export const ADD_AD_USER_SUCCESS = 'ADD_AD_USER_SUCCESS';
export const ADD_AD_USER_FAIL = 'ADD_AD_USER_FAIL';
export const MODIFY_AD_USER = 'MODIFY_AD_USER';
export const MODIFY_AD_USER_START = 'MODIFY_AD_USER_START';
export const MODIFY_AD_USER_SUCCESS = 'MODIFY_AD_USER_SUCCESS';
export const MODIFY_AD_USER_FAIL = 'MODIFY_AD_USER_FAIL';
export const DELETE_AD_USER = 'DELETE_AD_USER';
export const DELETE_AD_USER_START = 'DELETE_AD_USER_START';
export const DELETE_AD_USER_SUCCESS = 'DELETE_AD_USER_SUCCESS';
export const DELETE_AD_USER_FAIL = 'DELETE_AD_USER_FAIL';

/*
    Handling User Auth
*/
export const AUTH_REQUEST = 'AUTH_REQUEST';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAILURE = 'AUTH_FAILURE';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

/*
    Handling Common
*/
export const FETCH_ADDINFO_LIST = 'FETCH_ADDINFO_LIST';
export const FETCH_ADDINFO_LIST_SUCCESS = 'FETCH_ADDINFO_LIST_SUCCESS';
export const FETCH_ADDINFO_LIST_FAIL = 'FETCH_ADDINFO_LIST_FAIL';

export const FETCH_DB_LIST = 'FETCH_DB_LIST';
export const FETCH_DB_LIST_SUCCESS = 'FETCH_DB_LIST_SUCCESS';
export const FETCH_DB_LIST_FAIL = 'FETCH_DB_LIST_FAIL';

export const FETCH_BP_SAP = 'FETCH_BP_SAP';
export const FETCH_BP_SAP_START = 'FETCH_BP_SAP_START';
export const FETCH_BP_SAP_SUCCESS = 'FETCH_BP_SAP_SUCCESS';
export const FETCH_BP_SAP_FAIL = 'FETCH_BP_SAP_FAIL';
export const CANCEL_GMP_BP_EXISTING_PREVIEW = 'CANCEL_GMP_BP_EXISTING_PREVIEW';
export const RESET_SAP_BP = 'RESET_SAP_BP';

export const UPDATE_BP_ERRORS = 'UPDATE_BP_ERRORS';
export const FETCH_ALL_BP = 'FETCH_ALL_BP';
export const FETCH_ALL_BP_START = 'FETCH_ALL_BP_START';
export const FETCH_ALL_BP_SUCCESS = 'FETCH_ALL_BP_SUCCESS';
export const FETCH_ALL_BP_FAIL = 'FETCH_ALL_BP_FAIL';

export const SAVE_LOCAL_BP = 'SAVE_LOCAL_BP';
export const SAVE_LOCAL_BP_START = 'SAVE_LOCAL_BP_START';
export const SAVE_LOCAL_BP_SUCCESS = 'SAVE_LOCAL_BP_SUCCESS';
export const SAVE_LOCAL_BP_FAIL = 'SAVE_LOCAL_BP_FAIL';

export const FETCH_LOCAL_BP = 'FETCH_LOCAL_BP';
export const FETCH_LOCAL_BP_START = 'FETCH_LOCAL_BP_START';
export const FETCH_LOCAL_BP_SUCCESS = 'FETCH_LOCAL_BP_SUCCESS';
export const FETCH_LOCAL_BP_FAIL = 'FETCH_LOCAL_BP_FAIL';

export const SAVE_SAP_BP = 'SAVE_SAP_BP';
export const SAVE_SAP_BP_START = 'SAVE_SAP_BP_START';
export const SAVE_SAP_BP_SUCCESS = 'SAVE_SAP_BP_SUCCESS';
export const SAVE_SAP_BP_FAIL = 'SAVE_SAP_BP_FAIL';
export const CLOSE_SAVE_SAP_BP_MODAL = 'CLOSE_SAVE_SAP_BP_MODAL';

export const CHECK_PENDING_BP = 'CHECK_PENDING_BP';
export const CHECK_PENDING_BP_START = 'CHECK_PENDING_BP_START';
export const CHECK_PENDING_BP_SUCCESS = 'CHECK_PENDING_BP_SUCCESS';
export const CHECK_PENDING_BP_FAIL = 'CHECK_PENDING_BP_FAIL';
export const RESET_CHECK_PENDING_BP = 'RESET_CHECK_PENDING_BP';

export const SEND_RESALE_CERT_FILE = 'SEND_RESALE_CERT_FILE';
export const SEND_RESALE_CERT_FILE_START = 'SEND_RESALE_CERT_FILE_START';
export const SEND_RESALE_CERT_FILE_SUCCESS = 'SEND_RESALE_CERT_FILE_SUCCESS';
export const SEND_RESALE_CERT_FILE_FAIL = 'SEND_RESALE_CERT_FILE_FAIL';

export const GET_RESALE_CERT_FILE = 'GET_RESALE_CERT_FILE';
export const GET_RESALE_CERT_FILE_START = 'GET_RESALE_CERT_FILE_START';
export const GET_RESALE_CERT_FILE_SUCCESS = 'GET_RESALE_CERT_FILE_SUCCESS';
export const GET_RESALE_CERT_FILE_FAIL = 'GET_RESALE_CERT_FILE_FAIL';

export const SEND_RESALE_CERT_FILE_SAP = 'SEND_RESALE_CERT_FILE_SAP';
export const SEND_RESALE_CERT_FILE_SAP_START = 'SEND_RESALE_CERT_FILE_SAP_START';
export const SEND_RESALE_CERT_FILE_SAP_SUCCESS = 'SEND_RESALE_CERT_FILE_SAP_SUCCESS';
export const SEND_RESALE_CERT_FILE_SAP_FAIL = 'SEND_RESALE_CERT_FILE_SAP_FAIL';

export const SET_BP_DATA = 'SET_BP_DATA';
export const RESET_COMMON = 'RESET_COMMON';

export const LOAD_DBS = 'LOAD_DBS';
export const LOAD_DBS_START = 'LOAD_DBS_START';
export const LOAD_DBS_SUCCESS = 'LOAD_DBS_SUCCESS';
export const LOAD_DBS_FAIL = 'LOAD_DBS_FAIL';

export const UPDATE_BP_LOCK = 'UPDATE_BP_LOCK';
export const UPDATE_BP_LOCK_START = 'UPDATE_BP_LOCK_START';
export const UPDATE_BP_LOCK_SUCCESS = 'UPDATE_BP_LOCK_SUCCESS';
export const UPDATE_BP_LOCK_FAIL = 'UPDATE_BP_LOCK_FAIL';

export const CHECK_BP_LOCK = 'CHECK_BP_LOCK';
export const CHECK_BP_LOCK_START = 'CHECK_BP_LOCK_START';
export const CHECK_BP_LOCK_SUCCESS = 'CHECK_BP_LOCK_SUCCESS';
export const CHECK_BP_LOCK_FAIL = 'CHECK_BP_LOCK_FAIL';

export const GET_BP_LOCKS = 'GET_BP_LOCKS';
export const GET_BP_LOCKS_SUCCESS = 'GET_BP_LOCKS_SUCCESS';
export const GET_BP_LOCKS_FAIL = 'GET_BP_LOCKS_FAIL';
export const GET_BP_LOCKS_START = 'GET_BP_LOCKS_START';

export const EMPTY_SAP_BP_REVIEW = 'EMPTY_SAP_BP_REVIEW';

export const RELEASE_USER_LOCKED_BPS = 'RELEASE_USER_LOCKED_BPS';

export const CLOSE_REVIEW_MODAL = 'CLOSE_REVIEW_MODAL';

export const CHOOSE_PRIMARY = 'CHOOSE_PRIMARY';
export const CHOOSE_PRIMARY_START = 'CHOOSE_PRIMARY_START';
export const CHOOSE_PRIMARY_SUCCESS = 'CHOOSE_PRIMARY_SUCCESS';
export const CHOOSE_PRIMARY_FAIL = 'CHOOSE_PRIMARY_FAIL';

/*
    Handling GIN
*/
export const SET_ALL_GIN = 'SET_ALL_GIN';
export const FETCH_ITEMLIST = 'FETCH_ITEMLIST';
export const FETCH_ITEMLIST_START = 'FETCH_ITEMLIST_START';
export const FETCH_ITEMLIST_SUCCESS = 'FETCH_ITEMLIST_SUCCESS';
export const FETCH_ITEMLIST_FAIL = 'FETCH_ITEMLIST_FAIL';

export const FETCH_ITEM = 'FETCH_ITEM';
export const FETCH_ITEM_START = 'FETCH_ITEM_START';
export const FETCH_ITEM_SUCCESS = 'FETCH_ITEM_SUCCESS';
export const FETCH_ITEM_FAIL = 'FETCH_ITEM_FAIL';
export const FETCH_ITEM_COMPARE = 'FETCH_ITEM_COMPARE';

export const SAVE_CHOSEN_GIN_DETAILS = 'SAVE_CHOSEN_GIN_DETAILS';
export const ERASE_CHOSEN_GIN_DETAILS = 'ERASE_CHOSEN_GIN_DETAILS';

export const FETCH_BRAND_SUBGROUPLIST = 'FETCH_BRAND_SUBGROUPLIST';
export const FETCH_BRAND_SUBGROUPLIST_START = 'FETCH_BRAND_SUBGROUPLIST_START';
export const FETCH_BRAND_SUBGROUPLIST_SUCCESS = 'FETCH_BRAND_SUBGROUPLIST_SUCCESS';
export const FETCH_BRAND_SUBGROUPLIST_FAIL = 'FETCH_BRAND_SUBGROUPLIST_FAIL';

export const FETCH_BPNAMEBYCODE = 'FETCH_BPNAMEBYCODE';
export const FETCH_BPNAMEBYCODE_START = 'FETCH_BPNAMEBYCODE_START';
export const FETCH_BPNAMEBYCODE_SUCCESS = 'FETCH_BPNAMEBYCODE_SUCCESS';
export const FETCH_BPNAMEBYCODE_FAIL = 'FETCH_BPNAMEBYCODE_FAIL';

export const EMPTY_ITEM_LIST = 'EMPTY_ITEM_LIST';
export const EMPTY_ITEM_BY_DB = 'EMPTY_ITEM_BY_DB';
export const SET_ADD_NEW_GIN_ITEM = 'SET_ADD_NEW_GIN_ITEM';
export const ADD_NEW_GIN_ITEM = 'ADD_NEW_GIN_ITEM';
export const ADD_TO_OPENEDDBS_LIST = 'ADD_TO_OPENEDDBS_LIST';
export const REMOVE_FROM_OPENEDDBS_LIST = 'REMOVE_FROM_OPENEDDBS_LIST';

export const EDIT_MODE_ON = 'EDIT_MODE_ON';
export const EDIT_MODE_OFF = 'EDIT_MODE_OFF';
export const SET_GIN_EDIT = 'SET_GIN_EDIT';
export const UPDATE_GIN_EDIT = 'UPDATE_GIN_EDIT';
export const UPDATE_GIN_MULTIPLE = 'UPDATE_GIN_MULTIPLE';
export const SET_TPP_ITEM_TYPE = 'SET_TPP_ITEM_TYPE';

export const EDIT_TABLE_MODE_ON = 'EDIT_TABLE_MODE_ON';
export const EDIT_TABLE_MODE_OFF = 'EDIT_TABLE_MODE_OFF';
export const EDIT_TABLE_TWO_MODE_ON = 'EDIT_TABLE_TWO_MODE_ON';
export const EDIT_TABLE_TWO_MODE_OFF = 'EDIT_TABLE_TWO_MODE_OFF';

export const SET_EDIT_TABLE = 'SET_EDIT_TABLE';
export const SET_EDIT_TABLE_TWO = 'SET_EDIT_TABLE_TWO';

export const UPDATE_EDIT_TABLE = 'UPDATE_EDIT_TABLE';
export const UPDATE_EDIT_TABLE_TWO = 'UPDATE_EDIT_TABLE_TWO';

export const FETCH_GIN_OPTIONS = 'FETCH_GIN_OPTIONS';
export const FETCH_GIN_OPTIONS_START = 'FETCH_GIN_OPTIONS_START';
export const FETCH_GIN_OPTIONS_SUCCESS = 'FETCH_GIN_OPTIONS_SUCCESS';
export const FETCH_GIN_OPTIONS_FAIL = 'FETCH_GIN_OPTIONS_FAIL';

export const FETCH_ITEM_MOCS = 'FETCH_ITEM_MOCS';
export const FETCH_ITEM_MOCS_START = 'FETCH_ITEM_MOCS_START';
export const FETCH_ITEM_MOCS_SUCCESS = 'FETCH_ITEM_MOCS_SUCCESS';
export const FETCH_ITEM_MOCS_FAIL = 'FETCH_ITEM_MOCS_FAIL';

export const FETCH_ITEM_MOCS_ALL = 'FETCH_ITEM_MOCS_ALL';
export const FETCH_ITEM_MOCS_ALL_START = 'FETCH_ITEM_MOCS_ALL_START';
export const FETCH_ITEM_MOCS_ALL_SUCCESS = 'FETCH_ITEM_MOCS_SUCCESS_ALL';
export const FETCH_ITEM_MOCS_ALL_FAIL = 'FETCH_ITEM_MOCS_FAIL_ALL';

export const SET_VARIANT_STATUS = 'SET_VARIANT_STATUS';
export const SET_VARIANTS_CHECKED = 'SET_VARIANTS_CHECKED';

export const ADD_VARIANT = 'ADD_VARIANT';
export const ADD_VARIANT_DELETE = 'ADD_VARIANT_DELETE';
export const ADD_VARIANT_EDIT = 'ADD_VARIANT_EDIT';

export const GIN_SET_IMAGE = 'GIN_SET_IMAGE';
export const GIN_SET_CROP = 'GIN_SET_CROP';
export const GIN_SET_FINALCROP = 'GIN_SET_FINALCROP';
export const GIN_SET_IMAGE_EXT = 'GIN_SET_IMAGE_EXT';
export const GIN_SEND_IMAGE = 'GIN_SEND_IMAGE';
export const GIN_SEND_IMAGE_START = 'GIN_SEND_IMAGE_START';
export const GIN_SEND_IMAGE_SUCCESS = 'GIN_SEND_IMAGE_SUCCESS';
export const GIN_SEND_IMAGE_FAIL = 'GIN_SEND_IMAGE_FAIL';

export const GIN_SEND_ITEM = 'GIN_SEND_ITEM';
export const GIN_SEND_ITEM_START = 'GIN_SEND_ITEM_START';
export const GIN_SEND_ITEM_SUCCESS = 'GIN_SEND_ITEM_SUCCESS';
export const GIN_SEND_ITEM_FAIL = 'GIN_SEND_ITEM_FAIL';

export const GIN_SEND_ITEM_NOSTART = 'GIN_SEND_ITEM_NOSTART';
export const GIN_SEND_ITEM_NOSTART_SUCCESS = 'GIN_SEND_ITEM_NOSTART_SUCCESS';
export const GIN_SEND_ITEM_NOSTART_FAIL = 'GIN_SEND_ITEM_NOSTART_FAIL';
export const GIN_SEND_ITEM_NOSTART_SET = 'GIN_SEND_ITEM_NOSTART_SET';

export const GIN_START_IMPORTER = 'GIN_START_IMPORTER';
export const GIN_START_IMPORTER_SUCCESS = 'GIN_START_IMPORTER_SUCCESS';
export const GIN_START_IMPORTER_FAIL = 'GIN_START_IMPORTER_FAIL';
export const GIN_START_IMPORTER_START = 'GIN_START_IMPORTER_START';

export const GIN_NEW_ITEM_ADD = 'GIN_NEW_ITEM_ADD';
export const GIN_NEW_ITEM_REMOVE = 'GIN_NEW_ITEM_REMOVE';

export const GIN_SET_GINNUM = 'GIN_SET_GINNUM';
export const GIN_SET_GMP = 'GIN_SET_GMP';

export const GIN_CHECK_EXISTS = 'GIN_CHECK_EXISTS';
export const GIN_CHECK_EXISTS_START = 'GIN_CHECK_EXISTS_START';
export const GIN_CHECK_EXISTS_SUCCESS = 'GIN_CHECK_EXISTS_SUCCESS';
export const GIN_CHECK_EXISTS_FAIL = 'GIN_CHECK_EXISTS_FAIL';

export const GIN_CANCEL_LOADING = 'GIN_CANCEL_LOADING';

export const GIN_FINISH_IMPORTING = 'GIN_FINISH_IMPORTING';

export const GIN_REFRESH = 'GIN_REFRESH';

export const CLEAR_GIN_MESSAGE = 'CLEAR_GIN_MESSAGE';

export const GIN_SET_ERROR_MESSAGE = 'GIN_SET_ERROR_MESSAGE';

export const GIN_SET_DONE_PICK_VALUES = 'GIN_SET_DONE_PICK_VALUES';

export const GIN_CHECK_IMPORTER_TABLE = 'GIN_CHECK_IMPORTER_TABLE';
export const GIN_CHECK_IMPORTER_TABLE_SUCCESS = 'GIN_CHECK_IMPORTER_TABLE_SUCCESS';
export const GIN_CHECK_IMPORTER_TABLE_FAIL = 'GIN_CHECK_IMPORTER_TABLE_FAIL';
export const GIN_SET_TABLE_COUNT = 'GIN_SET_TABLE_COUNT';

export const GIN_SAVE_FAILED_IMPORT = 'GIN_SAVE_FAILED_IMPORT';
export const GIN_SAVE_FAILED_IMPORT_START = 'GIN_SAVE_FAILED_IMPORT_START';
export const GIN_SAVE_FAILED_IMPORT_SUCCESS = 'GIN_SAVE_FAILED_IMPORT_SUCCESS';
export const GIN_SAVE_FAILED_IMPORT_FAIL = 'GIN_SAVE_FAILED_IMPORT_FAIL';

export const GIN_GET_FAILED_IMPORT = 'GIN_GET_FAILED_IMPORT';
export const GIN_GET_FAILED_IMPORT_SUCCESS = 'GIN_GET_FAILED_IMPORT_SUCCESS';
export const GIN_GET_FAILED_IMPORT_FAIL = 'GIN_GET_FAILED_IMPORT_FAIL';

export const GIN_SET_FAILED_IMPORT = 'GIN_SET_FAILED_IMPORT';
export const GIN_SET_FAILED_IMPORT_SUCCESS = 'GIN_SET_FAILED_IMPORT_SUCCESS';
export const GIN_SET_FAILED_IMPORT_FAIL = 'GIN_SET_FAILED_IMPORT_FAIL';

export const GIN_CHECK_TABLE = 'GIN_CHECK_TABLE';

export const GIN_START_Q = 'GIN_START_Q';
export const GIN_START_Q_SUCCESS = 'GIN_START_Q_SUCCESS';
export const GIN_START_Q_FAIL = 'GIN_START_Q_FAIL';

export const GIN_DEACTIVATE = 'GIN_DEACTIVATE';
export const GIN_DEACTIVATE_START = 'GIN_DEACTIVATE_START';
export const GIN_DEACTIVATE_SUCCESS = 'GIN_DEACTIVATE_SUCCESS';
export const GIN_DEACTIVATE_FAIL = 'GIN_DEACTIVATE_FAIL';

export const GIN_REACTIVATE = 'GIN_REACTIVATE';
export const GIN_REACTIVATE_START = 'GIN_REACTIVATE_START';
export const GIN_REACTIVATE_SUCCESS = 'GIN_REACTIVATE_SUCCESS';
export const GIN_REACTIVATE_FAIL = 'GIN_REACTIVATE_FAIL';

export const GIN_GATHER_AUTOSAVE_DATA = 'GIN_GATHER_AUTOSAVE_DATA';

export const GIN_AUTOSAVE = 'GIN_AUTOSAVE';
export const GIN_AUTOSAVE_START = 'GIN_AUTOSAVE_START';
export const GIN_AUTOSAVE_SUCCESS = 'GIN_AUTOSAVE_SUCCESS';
export const GIN_AUTOSAVE_FAIL = 'GIN_AUTOSAVE_FAIL';

export const GIN_AUTOSAVE_LOAD = 'GIN_AUTOSAVE_LOAD';
export const GIN_AUTOSAVE_LOAD_START = 'GIN_AUTOSAVE_LOAD_START';
export const GIN_AUTOSAVE_LOAD_END = 'GIN_AUTOSAVE_LOAD_END';
export const GIN_AUTOSAVE_LOAD_SUCCESS = 'GIN_AUTOSAVE_LOAD_SUCCESS';
export const GIN_AUTOSAVE_LOAD_FAIL = 'GIN_AUTOSAVE_LOAD_FAIL';

export const GIN_AUTOSAVE_LOAD_ALL = 'GIN_AUTOSAVE_LOAD_ALL';
export const GIN_AUTOSAVE_LOAD_ALL_START = 'GIN_AUTOSAVE_LOAD_ALL_START';
export const GIN_AUTOSAVE_LOAD_ALL_SUCCESS = 'GIN_AUTOSAVE_LOAD_ALL_SUCCESS';
export const GIN_AUTOSAVE_LOAD_ALL_FAIL = 'GIN_AUTOSAVE_LOAD_ALL_FAIL';

export const GIN_AUTOSAVE_DELETE = 'GIN_AUTOSAVE_DELETE';
export const GIN_AUTOSAVE_DELETE_SUCCESS = 'GIN_AUTOSAVE_DELETE_SUCCESS';
export const GIN_AUTOSAVE_DELETE_FAIL = 'GIN_AUTOSAVE_DELETE_FAIL';

export const GIN_AUTOSAVE_IMAGE = 'GIN_AUTOSAVE_IMAGE';

export const GIN_LOAD_IMAGE = 'GIN_LOAD_IMAGE';
export const GIN_LOAD_IMAGE_SUCCESS = 'GIN_LOAD_IMAGE_SUCCESS';

export const GIN_NEW_VALIDATE_ENTRY = 'GIN_NEW_VALIDATE_ENTRY';
export const GIN_NEW_VALIDATE_ENTRY_START = 'GIN_NEW_VALIDATE_ENTRY_START';
export const GIN_NEW_VALIDATE_ENTRY_SUCCESS = 'GIN_NEW_VALIDATE_ENTRY_SUCCESS';
export const GIN_NEW_VALIDATE_ENTRY_FAIL = 'GIN_NEW_VALIDATE_ENTRY_FAIL';

export const GIN_REMOVE_BOM = 'GIN_REMOVE_BOM';
export const GIN_REMOVE_BOM_START = 'GIN_REMOVE_BOM_START';
export const GIN_REMOVE_BOM_SUCCESS = 'GIN_REMOVE_BOM_SUCCESS';
export const GIN_REMOVE_BOM_FAIL = 'GIN_REMOVE_BOM_FAIL';

export const GIN_REMOVE_COST = 'GIN_REMOVE_COST';
export const GIN_REMOVE_COST_START = 'GIN_REMOVE_COST_START';
export const GIN_REMOVE_COST_SUCCESS = 'GIN_REMOVE_COST_SUCCESS';
export const GIN_REMOVE_COST_FAIL = 'GIN_REMOVE_COST_FAIL';

// gets List of existing only
export const GIN_GET_EXISTING = 'GIN_GET_EXISTING';
export const GIN_GET_EXISTING_START = 'GIN_GET_EXISTING_START';
export const GIN_GET_EXISTING_SUCCESS = 'GIN_GET_EXISTING_SUCCESS';
export const GIN_GET_EXISTING_FAIL = 'GIN_GET_EXISTING_FAIL';

export const GIN_LOAD_EXISTING = 'GIN_LOAD_EXISTING';
export const GIN_LOAD_EXISTING_START = 'GIN_LOAD_EXISTING_START';
export const GIN_LOAD_EXISTING_SUCCESS = 'GIN_LOAD_EXISTING_SUCCESS';
export const GIN_LOAD_EXISTING_FAIL = 'GIN_LOAD_EXISTING_FAIL';

export const SET_GIN_LOADING = 'SET_GIN_LOADING';

export const LOAD_EXISTING_IMAGE = 'LOAD_EXISTING_IMAGE';
export const LOAD_EXISTING_IMAGE_START = 'LOAD_EXISTING_IMAGE_START';
export const LOAD_EXISTING_IMAGE_SUCCESS = 'LOAD_EXISTING_IMAGE_SUCCESS';
export const LOAD_EXISTING_IMAGE_FAIL = 'LOAD_EXISTING_IMAGE_FAIL';

export const LOAD_PREVIEW_DATA = 'LOAD_PREVIEW_DATA';
export const LOAD_PREVIEW_DATA_START = 'LOAD_PREVIEW_DATA_START';
export const LOAD_PREVIEW_DATA_SUCCESS = 'LOAD_PREVIEW_DATA_SUCCESS';
export const LOAD_PREVIEW_DATA_FAIL = 'LOAD_PREVIEW_DATA_FAIL';

export const REMOVE_GIN = 'REMOVE_GIN';
export const REMOVE_GIN_START = 'REMOVE_GIN_START';
export const REMOVE_GIN_SUCCESS = 'REMOVE_GIN_SUCCESS';
export const REMOVE_GIN_FAIL = 'REMOVE_GIN_FAIL';

export const SET_LOADING_GIN = 'SET_LOADING_GIN';
export const SET_EXISTING_IMAGE_URL = 'SET_EXISTING_IMAGE_URL';

export const GIN_RENAME_IMAGE = 'GIN_RENAME_IMAGE';
export const GIN_RENAME_IMAGE_START = 'GIN_RENAME_IMAGE_START';
export const GIN_RENAME_IMAGE_SUCCESS = 'GIN_RENAME_IMAGE_SUCCESS';
export const GIN_RENAME_IMAGE_FAIL = 'GIN_RENAME_IMAGE_FAIL';

export const GIN_SAVE_PENDING = 'GIN_SAVE_PENDING';
export const GIN_SAVE_PENDING_START = 'GIN_SAVE_PENDING_START';
export const GIN_SAVE_PENDING_SUCCESS = 'GIN_SAVE_PENDING_SUCCESS';
export const GIN_SAVE_PENDING_FAIL = 'GIN_SAVE_PENDING_FAIL';

export const GIN_LOAD_PENDING = 'GIN_LOAD_PENDING';
export const GIN_LOAD_PENDING_START = 'GIN_LOAD_PENDING_START';
export const GIN_LOAD_PENDING_SUCCESS = 'GIN_LOAD_PENDING_SUCCESS';
export const GIN_LOAD_PENDING_FAIL = 'GIN_LOAD_PENDING_FAIL';

export const GIN_SET_TYPE = 'GIN_SET_TYPE';

export const GIN_CHECK_PENDING = 'GIN_CHECK_PENDING';
export const GIN_CHECK_PENDING_START = 'GIN_CHECK_PENDING_START';
export const GIN_CHECK_PENDING_SUCCESS = 'GIN_CHECK_PENDING_SUCCESS';
export const GIN_CHECK_PENDING_FAIL = 'GIN_CHECK_PENDING_FAIL';

export const GIN_DENY_PENDING = 'GIN_DENY_PENDING';
export const GIN_DENY_PENDING_SUCCESS = 'GIN_DENY_PENDING_SUCCESS';
export const GIN_DENY_PENDING_FAIL = 'GIN_DENY_PENDING_FAIL';

export const GIN_APPROVE_PENDING = 'GIN_APPROVE_PENDING';
export const GIN_APPROVE_PENDING_SUCCESS = 'GIN_APPROVE_PENDING_SUCCESS';
export const GIN_APPROVE_PENDING_FAIL = 'GIN_APPROVE_PENDING_FAIL';

export const GIN_SAVE_HISTORY = 'GIN_SAVE_HISTORY';
export const GIN_SAVE_HISTORY_START = 'GIN_SAVE_HISTORY_START';
export const GIN_SAVE_HISTORY_SUCCESS = 'GIN_SAVE_HISTORY_SUCCESS';
export const GIN_SAVE_HISTORY_FAIL = 'GIN_SAVE_HISTORY_FAIL';

export const GIN_LOAD_HISTORY = 'GIN_LOAD_HISTORY';
export const GIN_LOAD_HISTORY_START = 'GIN_LOAD_HISTORY_START';
export const GIN_LOAD_HISTORY_SUCCESS = 'GIN_LOAD_HISTORY_SUCCESS';
export const GIN_LOAD_HISTORY_FAIL = 'GIN_LOAD_HISTORY_FAIL';

export const GIN_FILTER_HISTORY_DATE = 'GIN_FILTER_HISTORY_DATE';

export const GIN_LOAD_HISTORY_ENTRY = 'GIN_LOAD_HISTORY_ENTRY';
export const GIN_LOAD_HISTORY_ENTRY_START = 'GIN_LOAD_HISTORY_ENTRY_START';
export const GIN_LOAD_HISTORY_ENTRY_SUCCESS = 'GIN_LOAD_HISTORY_ENTRY_SUCCESS';
export const GIN_LOAD_HISTORY_ENTRY_FAIL = 'GIN_LOAD_HISTORY_ENTRY_FAIL';

export const GET_ITEM_LOCKS = 'GET_ITEM_LOCKS';
export const GET_ITEM_LOCKS_START = 'GET_ITEM_LOCKS_START';
export const GET_ITEM_LOCKS_SUCCESS = 'GET_ITEM_LOCKS_SUCCESS';
export const GET_ITEM_LOCKS_FAIL = 'GET_ITEM_LOCKS_FAIL';

export const UPDATE_ITEM_LOCK = 'UPDATE_ITEM_LOCK';
export const UPDATE_ITEM_LOCK_START = 'UPDATE_ITEM_LOCK_START';
export const UPDATE_ITEM_LOCK_SUCCESS = 'UPDATE_ITEM_LOCK_SUCCESS';
export const UPDATE_ITEM_LOCK_FAIL = 'UPDATE_ITEM_LOCK_FAIL';

export const CHECK_ITEM_LOCK = 'CHECK_ITEM_LOCK';
export const CHECK_ITEM_LOCK_START = 'CHECK_ITEM_LOCK_START';
export const CHECK_ITEM_LOCK_SUCCESS = 'CHECK_ITEM_LOCK_SUCCESS';
export const CHECK_ITEM_LOCK_FAIL = 'CHECK_ITEM_LOCK_FAIL';

export const CHECK_ITEMS_LOCK = 'CHECK_ITEMS_LOCK';
export const CHECK_ITEMS_LOCK_START = 'CHECK_ITEMS_LOCK_START';
export const CHECK_ITEMS_LOCK_SUCCESS = 'CHECK_ITEMS_LOCK_SUCCESS';
export const CHECK_ITEMS_LOCK_FAIL = 'CHECK_ITEMS_LOCK_FAIL';

export const UPDATE_ITEMS_LOCK = 'UPDATE_ITEMS_LOCK';

export const RELEASE_USER_LOCKED_GIN = 'RELEASE_USER_LOCKED_GIN';

/*
    Handling UserPerms
*/
export const GET_USERS = 'GET_USERS';
export const GET_USERS_START = 'GET_USERS_START';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAIL = 'GET_USERS_FAIL';

export const GET_USERS_FILTERED = 'GET_USERS_FILTERED';
export const GET_USERS_FILTERED_START = 'GET_USERS_FILTERED_START';
export const GET_USERS_FILTERED_SUCCESS = 'GET_USERS_FILTERED_SUCCESS';
export const GET_USERS_FILTERED_FAIL = 'GET_USERS_FILTERED_FAIL';

export const GET_USER = 'GET_USER';
export const GET_USER_START = 'GET_USER_START';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAIL = 'GET_USER_FAIL';

export const GET_USER_BY_NAME = 'GET_USER_BY_NAME';
export const GET_USER_BY_NAME_START = 'GET_USER_BY_NAME_START';
export const GET_USER_BY_NAME_SUCCESS = 'GET_USER_BY_NAME_SUCCESS';
export const GET_USER_BY_NAME_FAIL = 'GET_USER_BY_NAME_FAIL';

export const GET_USER_BULK = 'GET_USER_BULK';
export const GET_USER_BULK_START = 'GET_USER_BULK_START';
export const GET_USER_BULK_SUCCESS = 'GET_USER_BULK_SUCCESS';
export const GET_USER_BULK_FAIL = 'GET_USER_BULK_FAIL';

export const SAVE_USER_PERMS = 'SAVE_USER_PERMS';
export const SAVE_USER_PERMS_START = 'SAVE_USER_PERMS_START';
export const SAVE_USER_PERMS_SUCCESS = 'SAVE_USER_PERMS_SUCCESS';
export const SAVE_USER_PERMS_FAIL = 'SAVE_USER_PERMS_FAIL';

export const CHOOSE_NEW_PERMS = 'CHOOSE_NEW_PERMS';

export const USERS_SYNC = 'USERS_SYNC';
export const SYNC_USER_START = 'SYNC_USER_START';
export const SYNC_USER_SUCCESS = 'SYNC_USER_SUCCESS';
export const SYNC_USER_FAIL = 'SYNC_USER_FAIL';

export const BULK_SYNC = 'BULK_SYNC';
export const BULK_SYNC_START = 'BULK_SYNC_START';
export const BULK_SYNC_SUCCESS = 'BULK_SYNC_SUCCESS';
export const BULK_SYNC_FAIL = 'BULK_SYNC_FAIL';

export const BULK_ASSIGN = 'BULK_ASSIGN';
export const BULK_ASSIGN_START = 'BULK_ASSIGN_START';
export const BULK_ASSIGN_SUCCESS = 'BULK_ASSIGN_SUCCESS';
export const BULK_ASSIGN_FAIL = 'BULK_ASSIGN_FAIL';
export const BULK_ASSIGN_END = 'BULK_ASSIGN_END';

export const NOTIFY_MAIL = 'NOTIFY_MAIL';

/*
    Handling RFID
*/
export const LOAD_RFID_PREFIX = 'LOAD_RFID_PREFIX';
export const LOAD_RFID_PREFIX_SUCCESS = 'LOAD_RFID_PREFIX_SUCCESS';
export const LOAD_RFID_PREFIX_FAIL = 'LOAD_RFID_PREFIX_FAIL';

export const LOAD_RFID_COMPANIES = 'LOAD_RFID_COMPANIES';
export const LOAD_RFID_COMPANIES_SUCCESS = 'LOAD_RFID_COMPANIES_SUCCESS';
export const LOAD_RFID_COMPANIES_FAIL = 'LOAD_RFID_COMPANIES_FAIL';

export const RFID_EXPORT_EPC = 'RFID_EXPORT_EPC';
export const RFID_EXPORT_EPC_START = 'RFID_EXPORT_EPC_START';
export const RFID_EXPORT_EPC_SUCCESS = 'RFID_EXPORT_EPC_SUCCESS';
export const RFID_EXPORT_EPC_FAIL = 'RFID_EXPORT_EPC_FAIL';

export const RFID_CLEAR_DATA = 'RFID_CLEAR_DATA';

export const RFID_LOAD_HISTORY = 'RFID_LOAD_HISTORY';
export const RFID_LOAD_HISTORY_START = 'RFID_LOAD_HISTORY_START';
export const RFID_LOAD_HISTORY_SUCCESS = 'RFID_LOAD_HISTORY_SUCCESS';
export const RFID_LOAD_HISTORY_FAIL = 'RFID_LOAD_HISTORY_FAIL';

export const LOAD_GS1COMPANYPREFIX = 'LOAD_GS1COMPANYPREFIX';
export const LOAD_GS1COMPANYPREFIX_START = 'LOAD_GS1COMPANYPREFIX_START';
export const LOAD_GS1COMPANYPREFIX_SUCCESS = 'LOAD_GS1COMPANYPREFIX_SUCCESS';
export const LOAD_GS1COMPANYPREFIX_FAIL = 'LOAD_GS1COMPANYPREFIX_FAIL';
export const RESET_GS1 = 'RESET_GS1';
export const SET_GS1API_IS_WORKING = 'SET_GS1API_IS_WORKING';

export const GENERATE_RFID_EXCEL = 'GENERATE_RFID_EXCEL';
export const GENERATE_RFID_EXCEL_START = 'GENERATE_RFID_EXCEL_START';
export const GENERATE_RFID_EXCEL_SUCCESS = 'GENERATE_RFID_EXCEL_SUCCESS';
export const GENERATE_RFID_EXCEL_FAIL = 'GENERATE_RFID_EXCEL_FAIL';

export const VERIFY_RFID_EXCEL = 'VERIFY_RFID_EXCEL';
export const VERIFY_RFID_EXCEL_START = 'VERIFY_RFID_EXCEL_START';
export const VERIFY_RFID_EXCEL_UPDATE = 'VERIFY_RFID_EXCEL_UPDATE';
export const VERIFY_RFID_EXCEL_SUCCESS = 'VERIFY_RFID_EXCEL_SUCCESS';
export const VERIFY_RFID_EXCEL_FAIL = 'VERIFY_RFID_EXCEL_FAIL';

export const CHECK_GS1_API = 'CHECK_GS1_API';
export const CHECK_GS1_API_START = 'CHECK_GS1_API_START';
export const CHECK_GS1_API_SUCCESS = 'CHECK_GS1_API_SUCCESS';
export const CHECK_GS1_API_FAIL = 'CHECK_GS1_API_FAIL';

export const GET_RFID_JCDATA = 'GET_RFID_JCDATA';
export const GET_RFID_JCDATA_START = 'GET_RFID_JCDATA_START';
export const GET_RFID_JCDATA_SUCCESS = 'GET_RFID_JCDATA_SUCCESS';
export const GET_RFID_JCDATA_FAIL = 'GET_RFID_JCDATA_FAIL';

export const SET_RFID_JCDATA = 'SET_RFID_JCDATA';

export const GET_RFID_ALLBIRDS = 'GET_RFID_ALLBIRDS';
export const GET_RFID_ALLBIRDS_START = 'GET_RFID_ALLBIRDS_START';
export const GET_RFID_ALLBIRDS_SUCCESS = 'GET_RFID_ALLBIRDS_SUCCESS';
export const GET_RFID_ALLBIRDS_SUCCESS_FINAL = 'GET_RFID_ALLBIRDS_SUCCESS_FINAL';
export const GET_RFID_ALLBIRDS_FAIL = 'GET_RFID_ALLBIRDS_FAIL';

export const SET_RFID_ALLBIRDS = 'SET_RFID_ALLBIRDS';

export const GET_RFID_IMPORTER_HISTORY = 'GET_RFID_IMPORTER_HISTORY';
export const GET_RFID_IMPORTER_HISTORY_START = 'GET_RFID_IMPORTER_HISTORY_START';
export const GET_RFID_IMPORTER_HISTORY_SUCCESS = 'GET_RFID_IMPORTER_HISTORY_SUCCESS';
export const GET_RFID_IMPORTER_HISTORY_FAIL = 'GET_RFID_IMPORTER_HISTORY_FAIL';

export const GET_RFID_IMPORTER_EXCEL = 'GET_RFID_IMPORTER_EXCEL';
export const GET_RFID_IMPORTER_EXCEL_START = 'GET_RFID_IMPORTER_EXCEL_START';
export const GET_RFID_IMPORTER_EXCEL_SUCCESS = 'GET_RFID_IMPORTER_EXCEL_SUCCESS';
export const GET_RFID_IMPORTER_EXCEL_FAIL = 'GET_RFID_IMPORTER_EXCEL_FAIL';

/*
  Handling SO
*/

export const LOAD_SO_DATA = 'LOAD_SO_DATA';
export const LOAD_SO_DATA_START = 'LOAD_SO_DATA_START';
export const LOAD_SO_DATA_SUCCESS = 'LOAD_SO_DATA_SUCCESS';
export const LOAD_SO_DATA_FAIL = 'LOAD_SO_DATA_FAIL';
export const CLEAR_SO_DATA = 'CLEAR_SO_DATA';

export const START_IMPOSITIONS = 'START_IMPOSITIONS';
export const START_IMPOSITIONS_START = 'START_IMPOSITIONS_START';
export const START_IMPOSITIONS_SUCCESS = 'START_IMPOSITIONS_SUCCESS';
export const START_IMPOSITIONS_FAIL = 'START_IMPOSITIONS_FAIL';

/*
  Settings
*/

export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';
export const UPDATE_SETTINGS_START = 'UPDATE_SETTINGS_START';
export const UPDATE_SETTINGS_SUCCESS = 'UPDATE_SETTINGS_SUCCESS';
export const UPDATE_SETTINGS_FAIL = 'UPDATE_SETTINGS_FAIL';

export const READ_SETTINGS = 'READ_SETTINGS';
export const READ_SETTINGS_START = 'READ_SETTINGS_START';
export const READ_SETTINGS_SUCCESS = 'READ_SETTINGS_SUCCESS';
export const READ_SETTINGS_FAIL = 'READ_SETTINGS_FAIL';

export const MAINTENANCE_MODE = 'MAINTENANCE_MODE';
export const MAINTENANCE_MODE_SUCCESS = 'MAINTENANCE_MODE_SUCCESS';
export const CHECK_MAINTENANCE_MODE = 'CHECK_MAINTENANCE_MODE';
export const CHECK_MAINTENANCE_MODE_SUCCESS = 'CHECK_MAINTENANCE_MODE_SUCCESS';

export const TPP_MAINTENANCE = 'TPP_MAINTENANCE';
export const TPP_MAINTENANCE_START = 'TPP_MAINTENANCE_START';
export const TPP_MAINTENANCE_SUCCESS = 'TPP_MAINTENANCE_SUCCESS';
export const TPP_MAINTENANCE_FAIL = 'TPP_MAINTENANCE_FAIL';

export const TPP_MAINTENANCE_CHECK = 'TPP_MAINTENANCE_CHECK';
export const TPP_MAINTENANCE_CHECK_START = 'TPP_MAINTENANCE_CHECK_START';
export const TPP_MAINTENANCE_CHECK_SUCCESS = 'TPP_MAINTENANCE_CHECK_SUCCESS';
export const TPP_MAINTENANCE_CHECK_FAIL = 'TPP_MAINTENANCE_CHECK_FAIL';

/*
  Translations
*/
export const LOAD_RBO_DATA = 'LOAD_RBO_DATA';
export const LOAD_RBO_DATA_SUCCESS = 'LOAD_RBO_DATA_SUCCESS';
export const LOAD_RBO_DATA_FAIL = 'LOAD_RBO_DATA_FAIL';
export const LOAD_RBO_DATA_START = 'LOAD_RBO_DATA_START';

export const READ_TRANSLATIONS = 'READ_TRANSLATIONS';
export const READ_TRANSLATIONS_SUCCESS = 'READ_TRANSLATIONS_SUCCESS';
export const READ_TRANSLATIONS_FAIL = 'READ_TRANSLATIONS_FAIL';
export const READ_TRANSLATIONS_START = 'READ_TRANSLATIONS_START';

export const READ_TRANSLATION = 'READ_TRANSLATION';
export const READ_TRANSLATION_SUCCESS = 'READ_TRANSLATION_SUCCESS';
export const READ_TRANSLATION_FAIL = 'READ_TRANSLATION_FAIL';
export const READ_TRANSLATION_START = 'READ_TRANSLATION_START';

export const CREATE_TRANSLATION = 'CREATE_TRANSLATION';
export const CREATE_TRANSLATION_START = 'CREATE_TRANSLATION_START';
export const CREATE_TRANSLATION_SUCCESS = 'CREATE_TRANSLATION_SUCCESS';
export const CREATE_TRANSLATION_FAIL = 'CREATE_TRANSLATION_FAIL';

export const UPDATE_TRANSLATION = 'UPDATE_TRANSLATION';
export const UPDATE_TRANSLATION_START = 'UPDATE_TRANSLATION_START';
export const UPDATE_TRANSLATION_SUCCESS = 'UPDATE_TRANSLATION_SUCCESS';
export const UPDATE_TRANSLATION_FAIL = 'UPDATE_TRANSLATION_FAIL';

export const DELETE_TRANSLATION = 'DELETE_TRANSLATION';
export const DELETE_TRANSLATION_START = 'DELETE_TRANSLATION_START';
export const DELETE_TRANSLATION_SUCCESS = 'DELETE_TRANSLATION_SUCCESS';
export const DELETE_TRANSLATION_FAIL = 'DELETE_TRANSLATION_FAIL';

export const CLEAR_TRANSLATIONS = 'CLEAR_TRANSLATIONS';

export const SEARCH_TRANSLATION = 'SEARCH_TRANSLATION';
export const SEARCH_TRANSLATION_START = 'SEARCH_TRANSLATION_START';
export const SEARCH_TRANSLATION_SUCCESS = 'SEARCH_TRANSLATION_SUCCESS';
export const SEARCH_TRANSLATION_FAIL = 'SEARCH_TRANSLATION_FAIL';

export const GET_SUGGESTIONS_SUCCESS = 'GET_SUGGESTIONS_SUCCESS';

/*
  SAP Monitor
*/

export const GET_SM_MAGENTO_ORDERS = 'GET_SM_MAGENTO_ORDERS';
export const GET_SM_MAGENTO_ORDERS_START = 'GET_SM_MAGENTO_ORDERS_START';
export const GET_SM_MAGENTO_ORDERS_SUCCESS = 'GET_SM_MAGENTO_ORDERS_SUCCESS';
export const GET_SM_MAGENTO_ORDERS_FAIL = 'GET_SM_MAGENTO_ORDERS_FAIL';

export const GET_SM_MAGENTO_ORDERS_OTHERS = 'GET_SM_MAGENTO_ORDERS_OTHERS';
export const GET_SM_MAGENTO_ORDERS_OTHERS_START = 'GET_SM_MAGENTO_ORDERS_OTHERS_START';
export const GET_SM_MAGENTO_ORDERS_OTHERS_SUCCESS = 'GET_SM_MAGENTO_ORDERS_OTHERS_SUCCESS';
export const GET_SM_MAGENTO_ORDERS_OTHERS_FAIL = 'GET_SM_MAGENTO_ORDERS_OTHERS_FAIL';

export const GET_SM_MAGENTO_CUSTOMERS = 'GET_SM_MAGENTO_CUSTOMERS';
export const GET_SM_MAGENTO_CUSTOMERS_START = 'GET_SM_MAGENTO_CUSTOMERS_START';
export const GET_SM_MAGENTO_CUSTOMERS_SUCCESS = 'GET_SM_MAGENTO_CUSTOMERS_SUCCESS';
export const GET_SM_MAGENTO_CUSTOMERS_FAIL = 'GET_SM_MAGENTO_CUSTOMERS_FAIL';

export const GET_BP_BY_NAME = 'GET_BP_BY_NAME';
export const GET_BP_BY_NAME_START = 'GET_BP_BY_NAME_START';
export const GET_BP_BY_NAME_SUCCESS = 'GET_BP_BY_NAME_SUCCESS';
export const GET_BP_BY_NAME_FAIL = 'GET_BP_BY_NAME_FAIL';

export const GET_SAP_ORDER_DETAILS = 'GET_SAP_ORDER_DETAILS';
export const GET_SAP_ORDER_DETAILS_START = 'GET_SAP_ORDER_DETAILS_START';
export const GET_SAP_ORDER_DETAILS_SUCCESS = 'GET_SAP_ORDER_DETAILS_SUCCESS';
export const GET_SAP_ORDER_DETAILS_FAIL = 'GET_SAP_ORDER_DETAILS_FAIL';

export const GET_SAP_BP_DETAILS = 'GET_SAP_BP_DETAILS';
export const GET_SAP_BP_DETAILS_START = 'GET_SAP_BP_DETAILS_START';
export const GET_SAP_BP_DETAILS_SUCCESS = 'GET_SAP_BP_DETAILS_SUCCESS';
export const GET_SAP_BP_DETAILS_FAIL = 'GET_SAP_BP_DETAILS_FAIL';

export const GET_SAP_BPA_DETAILS = 'GET_SAP_BPA_DETAILS';
export const GET_SAP_BPA_DETAILS_START = 'GET_SAP_BPA_DETAILS_START';
export const GET_SAP_BPA_DETAILS_SUCCESS = 'GET_SAP_BPA_DETAILS_SUCCESS';
export const GET_SAP_BPA_DETAILS_FAIL = 'GET_SAP_BPA_DETAILS_FAIL';

export const GET_PI_LIST_FILTER = 'GET_PI_LIST_FILTER';
export const GET_PI_LIST_FILTER_START = 'GET_PI_LIST_FILTER_START';
export const GET_PI_LIST_FILTER_SUCCESS = 'GET_PI_LIST_FILTER_SUCCESS';
export const GET_PI_LIST_FILTER_FAIL = 'GET_PI_LIST_FILTER_FAIL';

export const SET_PI = 'SET_PI';

export const GET_SAP_BPA_ITEM_DETAILS = 'GET_SAP_BPA_ITEM_DETAILS';
export const GET_SAP_BPA_ITEM_DETAILS_START = 'GET_SAP_BPA_ITEM_DETAILS_START';
export const GET_SAP_BPA_ITEM_DETAILS_SUCCESS = 'GET_SAP_BPA_ITEM_DETAILS_SUCCESS';
export const GET_SAP_BPA_ITEM_DETAILS_FAIL = 'GET_SAP_BPA_ITEM_DETAILS_FAIL';

export const GET_MAGENTO_BP_DETAILS = 'GET_MAGENTO_BP_DETAILS';
export const GET_MAGENTO_BP_DETAILS_START = 'GET_MAGENTO_BP_DETAILS_START';
export const GET_MAGENTO_BP_DETAILS_SUCCESS = 'GET_MAGENTO_BP_DETAILS_SUCCESS';
export const GET_MAGENTO_BP_DETAILS_FAIL = 'GET_MAGENTO_BP_DETAILS_FAIL';

