import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from 'store/actions';
import ReactDOM from "react-dom";

import withStyles from "@material-ui/core/styles/withStyles";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";
import combineStyles from "shared/combineStyles";
import checkPermissionsActions from "shared/checkPermissionsActions";
import { normalizePhone, commatize, cleanAllPhones } from "shared/utility";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { PulseLoader } from 'react-spinners';

import SweetAlert from "react-bootstrap-sweetalert";
import BPFormReview from 'containers/BP/BPFormReview';
import BPForm from 'containers/BP/BPForm';
import BPmodal from 'containers/BP/BPmodal';

class BPExistingGMP extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reviewModalStatus: false,
      openForm: false,
      sapBPMixed: null,
      loadingModal: false,
      askModal: false,
      askRequest: '',
      searchModal: false,
      company_name: ''
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.sapBPreview !== this.props.sapBPreview) {
      if (this.props.sapBPreview) {
        this.setState({
          reviewModalStatus: true
        });
      }
    }
  }

  openAskModal = (askRequest) => {
    this.setState({
      askModal: true,
      askRequest
    });
  }

  closeAskModal = () => {
    this.setState({
      askModal: false
    });
  }

  toggleLoadingModal = () => {
    this.setState({
      loadingModal: !this.state.loadingModal
    });
  }

  toggleReviewModal = () => {
    this.setState({
      reviewModalStatus: !this.state.reviewModalStatus
    });
  }

  fetchChosenExistingBP = (bpname, db, cardcode) => {
    console.log('fetching bp', bpname, db, cardcode);
    this.props.fetchSAPBP(bpname, db, cardcode, 'pending', true);
    // Review the BP
  }

  handleDBChange = (db) => {
    console.log('fetching info from ', db);
    this.setState({
      currentDB: db,
      load: false
    });
    setTimeout(() => {
        this.setState({
          load : true
        })
      },100);
    this.props.fetchAddInfoList(db);
    if (db.indexOf('NIN') !== -1) {
      this.props.fetchGSTType();
    }
  }

  handleBPTypeChange = (bp_type) => {
    this.setState({
      currentBPType: bp_type,
      load: false
    });
    setTimeout(() => {
        this.setState({
          load : true
        })
      },100);
  }

  handleDeny = (values) => {
    console.log('denying submission');
  }

  handleApprove = (finalValues, file, filename, isTPP) => {
    const submittedDB = finalValues.DB;
    const actionPerms = checkPermissionsActions('BP');
    let directToSAP = false;
    if (actionPerms.hasOwnProperty('approve')) {
      actionPerms.approve.forEach((db) => {
        if (submittedDB === db) {
          directToSAP = true;
        }
      });
    }

    this.setState({
      currentDB: '',
      currentBPType: '',
      loadingModal: true
    });
    if (finalValues.card_code) {
        finalValues.submission_type = 'existing';
    }
    finalValues.origin = 'pending';
    if (directToSAP) {
      if (file && filename) {
        // Send file directly to SAP Server
        this.props.sendResaleCertFile(file, filename);
      }
      this.props.saveSAPBP(finalValues, null, null, isTPP);
    } else {
      if (file && filename) {
        // this.props.sendResaleCertFile(file, filename);
        this.props.saveLocalBP(finalValues, file, filename);
      } else {
        this.props.saveLocalBP(finalValues);
      }
    }
  }

  toggleFormLoad = () => {
    this.setState({
      openForm: !this.state.openForm,
    });
    ReactDOM.findDOMNode(this).scrollIntoView();
  }

  openSearchModal = () => {
    this.props.fetchAllBPs('Customer', this.props.bp.DB);
    this.setState({
      searchModal: true
    });
  }

  closeSearchModal = () => {
    this.setState({
      searchModal: false
    });
  }

  componentDidMount() {
    ReactDOM.findDOMNode(this).scrollIntoView();
    const bp = this.props.bp;
    console.log('props.bp',this.props.bp)
    this.props.fetchGMPExistingSimilars(bp.invoice_num, bp.customer_name, bp.new_contact_email, bp.DB);
    this.props.lockBP(bp.DB, '', bp.customer_name);
  }

  render() {
    console.log('BPExistingGMP', this.props, this.state);
    let allowedDBs = [];
    const actionPerms = checkPermissionsActions('BP');
    console.log(actionPerms);
    if (actionPerms.hasOwnProperty('create')) {
      actionPerms.create.forEach((db) => {
        if (allowedDBs.indexOf(db) === -1) {
          allowedDBs.push(db);
        }
      });
    }
    allowedDBs.sort();

    const { classes, cancelProfileView, existingSimilars, existingSimilarsLoading } = this.props;
    const bpObj = this.props.bp;
    console.log('bpObj',bpObj);

    // check if current entry used to be a new TPP submission
    const hasOldId = bpObj ? bpObj.original_id ? true : false : false;
    const hasInvoice = (bpObj.invoice_num && bpObj.invoice_num != 1) ? true : false;

    return (
        <div>
            {((!this.props.sapBPreview && !this.props.sapBP) && (this.state.openForm && !existingSimilarsLoading)) && <div><PulseLoader /></div>}
            {!this.state.openForm &&
            <Card>
              <CardBody>
                <GridContainer>
                {
                  this.state.searchModal && <BPmodal
                    changeCompanyName={this.changeCompanyName}
                    open={this.state.searchModal}
                    close={this.closeSearchModal}
                    currentDB={bpObj.DB}
                    currentBPType="Customer"
                    value={this.state.company_name}
                    BPaction="NEW"
                    loadBPForReview={this.props.loadBPForReview2}
                    beforeValues={null}
                    changeKey={null}
                    enterPayTerms={this.props.enterPayTerms}
                  />
                }
                  { !this.state.openForm &&
                  <GridItem xs={12} sm={12} md={6}>
                    <div></div>
                    <h4><strong>Existing TPP BP Submission</strong></h4>
                    <hr />

                    <div><strong>Invoice # :</strong> {hasInvoice ?
                      bpObj.invoice_num : 'N/A' }</div>
                    <div><strong>Customer Name :</strong> {bpObj.customer_name}</div>
                    <div><strong>E-mail :</strong> {bpObj.new_contact_email}</div>
                    <div><strong>Database :</strong> {bpObj.DB}</div>
                    <hr />
                    {existingSimilarsLoading && <PulseLoader />}
                  </GridItem>}
                { !this.state.openForm &&
                  <GridItem xs={12} sm={12} md={6}>
                    <h4 style={{opacity:0}}><strong>Existing TPP BPs</strong></h4>
                    <hr />
                    <div><strong>Firstname :</strong> {bpObj.new_contact_firstname}</div>
                    <div><strong>Lastname :</strong> {bpObj.new_contact_lastname}</div>
                    <div><strong>Position :</strong> {bpObj.new_contact_position}</div>
                    <div><strong>Telephone :</strong> {bpObj.new_contact_tel}</div>
                    <hr />

                  </GridItem>}
                  <GridItem xs={12} sm={12} md={12}>
                    {!existingSimilarsLoading && <span style={{color:'#45abe3'}}>
                      Select a BP from the list below or click the green search button to find a different BP
                    </span>}
                  </GridItem>
                    {
                      (hasInvoice && (existingSimilars && !this.state.openForm)) ?
                      <GridItem xs={12} sm={12} md={3}>
                        <List component="nav" dense={true}>
                          <ListItem><strong>{existingSimilars.invoice.length} found by Invoice #</strong></ListItem>
                          {
                            existingSimilars.invoice.map((inv, i) => {
                              if (inv.CardCode) {
                                if (inv.CardCode[0] !== 'C') {
                                  return null;
                                }
                              }
                              return (
                                <ListItem
                                  button
                                  key={'invoice'+i}
                                  onClick={() => {
                                    console.log('trying to get',inv.CardName, bpObj.DB, inv);
                                    this.fetchChosenExistingBP(inv.CardName, bpObj.DB, inv.CardCode);
                                  }}
                                >
                                  <span className="field-label">{inv.CardName}
                                  </span>
                                </ListItem>
                              );
                            })
                          }
                        </List>
                      </GridItem> : ''
                    }
                    {
                      (existingSimilars && !this.state.openForm) ?
                      <GridItem xs={12} sm={12} md={3}>
                        <List component="nav" dense={true}>
                          <ListItem><strong>{existingSimilars.bpname.length} found by BP Name</strong></ListItem>
                          {
                            existingSimilars.bpname.map((bpname, i) => {
                              if (bpname.CardCode) {
                                if (bpname.CardCode[0] !== 'C') {
                                  return null;
                                }
                              }
                              return (
                                <ListItem
                                  button
                                  key={'bpname'+i}
                                  onClick={() => {
                                    console.log(bpname.CardName);
                                    this.fetchChosenExistingBP(bpname.CardName, bpObj.DB, bpname.CardCode);
                                  }}
                                >
                                  <span className="field-label">{bpname.CardName}
                                  </span>
                                </ListItem>
                              );
                            })
                          }
                        </List>
                      </GridItem> : ''
                    }
                    {
                      (existingSimilars && !this.state.openForm) ?
                      <GridItem xs={12} sm={12} md={4}>
                        <List component="nav" dense={true}>
                          <ListItem><strong>{existingSimilars.email.length} found by Email</strong></ListItem>
                          {
                            existingSimilars.email.map((email, i) => {
                              if (email.CardCode) {
                                if (email.CardCode[0] !== 'C') {
                                  return null;
                                }
                              }
                              return (
                                <ListItem
                                  button
                                  key={'email'+i}
                                  onClick={() => {
                                    console.log(email.CardName);
                                    this.fetchChosenExistingBP(email.CardName, bpObj.DB, email.CardCode);
                                  }}
                                >
                                  <span className="field-label">{email.CardName}
                                  </span>
                                </ListItem>
                              );
                            })
                          }
                        </List>
                      </GridItem> : ''
                    }
                    <GridItem xs={12} sm={12} md={12}>
                      <hr />
                      <div style={{float:'left'}}>
                        {hasOldId && <Button
                          style={{marginLeft:'13px',float:'right'}}
                          color="warning"
                          onClick={() => {
                            this.props.convertExistingToNew(bpObj.original_id, bpObj.id, bpObj.DB);
                          }}
                          >CONVERT BACK TO NEW</Button>}
                        <Button
                          style={{marginLeft:'13px',float:'right'}}
                          color="danger"
                          onClick={() => {
                            this.openAskModal(`denyExistingGMP,${this.props.bp.id}`);
                          }}
                        >
                        DENY TPP
                        </Button>
                        <Button
                          style={{marginLeft:'13px',float:'right'}}
                          color="success"
                          onClick={this.openSearchModal}
                        >SEARCH
                        </Button>
                        <Button
                          color="rose"
                          className={classes.updateProfileButton}
                          onClick={cancelProfileView}
                        >
                          BACK TO PENDING REQUESTS LIST
                        </Button>
                      </div>
                    </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
            }
            {
              (this.state.reviewModalStatus && this.props.sapBPreview) &&
              <BPFormReview
                finalValues={this.props.sapBPreview}
                beforeValues={this.props.sapBPreview}
                open={this.state.reviewModalStatus}
                close={this.toggleReviewModal}
                countries={this.props.countriesDictionary}
                states={this.props.statesDictionary}
                gsttypes={this.props.gsttypesDictionary}
                owhtopts={this.props.owhtoptsDictionary}
                BPaction="EXISTING"
                directToSAP={this.props.directToSAP}
                currentDB={this.props.bp.DB}
                currentBPType={this.props.sapBPreview.bp_type}
                onGMPExistingLoad={() => {
                  console.log('this-->', this.props, this.state);
                  if (this.props.sapBPreview && this.props.localBP) {
                    if (this.props.sapBPreview.company_name && this.props.localBP.DB) {
                      const db = this.props.localBP.DB;
                      const card_code = this.props.sapBPreview.card_code;
                      const bpname = this.props.sapBPreview.company_name;
                      console.log('this--> locking BP', this.props.localBP.DB, this.props.sapBPreview.card_code, this.props.sapBPreview.company_name);
                      this.props.lockBP(db, card_code, bpname);
                      this.props.fetchSAPBP(bpname, db, card_code, 'pending');
                      this.props.emptySAPBPreview();
                    }
                  }
                  this.toggleFormLoad();

                }}
                closeShowGMPExistingModal={this.props.closeShowGMPExistingModal}
                enterPayTerms={this.props.enterPayTerms}
              />
            }
            { (this.props.sapBP && (this.state.openForm)) &&
              <BPForm
                handleDBChange={this.handleDBChange}
                handleBPTypeChange={this.handleBPTypeChange}
                allowedDBs={allowedDBs}
                currentDB={this.props.bp.DB}
                currentBPType="Customer"
                enterPayTerms={this.props.enterPayTerms}
                data={this.props.sapBP}
                localBP={this.props.localBP}
                additionalContact={this.props.bp}
                BPaction="EXISTING"
                isExistingGMP={true}
                updateMagento={true}
                disableDB={true}
                disableBPType={true}
                handleApprove={this.handleApprove}
                handleDeny={this.handleDeny}
                directToSAP={this.props.directToSAP}
                loadBPForReview={this.props.loadBPForReview}
                cancelFormView={this.toggleFormLoad}
                submissionType="existing"
                closeShowGMPExistingModal={this.props.closeShowGMPExistingModal}
                isFromPending={true}
              />
            }
            {
              this.state.loadingModal &&
              <SweetAlert
                  title={"Existing TPP BP"}
                  style={{ display: "block" }}
                  onConfirm={() => {
                    this.toggleLoadingModal();
                    this.props.closeShowGMPExistingModal();
                    ReactDOM.findDOMNode(this).scrollIntoView();
                  }}
                  confirmBtnCssClass={
                    this.props.classes.button + " " + this.props.classes.success
                  }
                  >
                  <div style={{fontSize:'14px'}}>
                    {!this.props.loadingSAPBPMsg && <div>Submitting, Please Wait<PulseLoader /></div>}
                    {this.props.loadingSAPBPMsg}
                  </div>
                </SweetAlert>
            }
            {
              this.state.askModal &&
              <SweetAlert
                warning
                showCancel
                confirmBtnText="Yes"
                cancelBtnText="No"
                confirmBtnCssClass={
                  this.props.classes.button + " " + this.props.classes.success
                }
                cancelBtnCssClass={
                  this.props.classes.button + " " + this.props.classes.default
                }
                title="Are you sure?"
                onConfirm={() => {
                  if (this.state.askRequest.indexOf('deny') !== -1) {
                    const deny_id = this.state.askRequest.split(',')[1];
                    console.log('deny?',deny_id, this.props.bp.DB, this.props.bp.customer_name);
                    this.props.handleDenyExistingGMP({
                      id: deny_id,
                      DB: this.props.bp.DB,
                      company_name: this.props.bp.customer_name,
                      bp_type: 'Customer',
                      denyMsg: 'Deny(EXISTING)'
                    });
                  }
                  this.closeAskModal();
                  this.props.closeShowGMPExistingModal();
                }}
                onCancel={this.closeAskModal}
                >
                {this.state.askRequest.indexOf('deny') !== -1 && <div>Do you want to Deny this Existing TPP BP ?</div>}
              </SweetAlert>
            }
          </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    existingSimilars: state.gmp.existingSimilars,
    existingSimilarsLoading: state.gmp.existingSimilarsLoading,
    localBP: state.common.localBP,
    sapBP: state.common.sapBP,
    sapBPreview: state.common.sapBPreview,
    loadingSAPBPMsg: state.common.loadingSAPBPMsg,

    loadingBP: state.common.loadingBP,
    dbs: state.common.dbs,
    currencies: state.common.currencies,
    statesDictionary: state.common.statesDictionary,
    countriesDictionary: state.common.countriesDictionary,
    paytermsDictionary: state.common.paytermsDictionary,
    defaultPBP: state.common.defaultPBP,
    defaultSubgroup: state.common.defaultSubgroup,
    gsttypesDictionary: state.gmp.gsttypesDictionary,
    maincurr: state.common.maincurr,
    groupcodesDictionary: state.common.groupcodesDictionary,
    owhtoptsDictionary: state.common.owhtoptsDictionary
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    sendResaleCertFile: (file, filename) => {
      dispatch(actions.sendResaleCertFile(file, filename));
    },
    loadBPForReview2: (bpName, db, cardcode, bp_type, reviewOnly) => {
      dispatch(actions.fetchBPSAP(db, bpName, cardcode, 'pending', bp_type, reviewOnly));
    },
    onFetchGMP: () => dispatch(actions.fetchGMP()),
    fetchDBList: () => {
      dispatch(actions.fetchDBList());
    },
    fetchAddInfoList: (db) => {
      dispatch(actions.fetchAddInfoList(db));
    },
    fetchGSTType: () => {
      dispatch(actions.fetchGSTType());
    },
    fetchSAPBP: (bpName, db, cardcode, origin_check, reviewOnly) => {
      console.log('fetching cardcode', bpName, db, cardcode, reviewOnly);
      dispatch(actions.fetchBPSAP(db, bpName, cardcode, origin_check, 'Customer', reviewOnly));
    },
    saveLocalBP: (data, file, filename) => {
      dispatch(actions.saveLocalBP(data, file, filename));
    },
    fetchLocalBP: (submission_id) => {
      dispatch(actions.fetchLocalBP(submission_id));
    },
    fetchGMPExistingSimilars: (invoice_num, customer_name, email, DB) => {
      dispatch(actions.fetchGMPExistingSimilars(invoice_num, customer_name, email, DB));
    },
    fetchAllBPs: (bp_type, db) => {
      dispatch(actions.fetchAllBP(bp_type, db));
    },
    saveSAPBP: (data) => {
      dispatch(actions.saveSAPBP(data, null, null, true));
    },
    denyGMPBP: (values) => {
      dispatch(actions.denyGMPBP(values));
    },
    lockBP: (db, card_code, bpname) => {
      dispatch(actions.updateBPLock(db, card_code, bpname, 'LOCK'));
    },
    emptySAPBPreview: () => {
      dispatch(actions.emptySAPBPreview());
    },
    convertExistingToNew: (old_id, new_id, db) => {
      dispatch(actions.convertExistingToNew(old_id, new_id, db));
    }
  }
}

const combinedStyles = combineStyles(sweetAlertStyle, userProfileStyles);
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(combinedStyles)(BPExistingGMP));
