import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import * as actions from '../../store/actions';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import Table from "components/Table/Table.jsx";
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import styles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import Datetime from "react-datetime";
import GINSelect from "./GINselect";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
import InputAdornment from '@material-ui/core/InputAdornment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import BPFormReview from 'containers/BP/BPFormReview';
import cloneDeep from "lodash/cloneDeep";
import { checkOneYearEndDate } from "./GINhelpers";
import Button from '@material-ui/core/Button';
import Button2 from "components/CustomButtons/Button.jsx";
import { PulseLoader } from 'react-spinners';
import { isValidDate, numberWithCommas, checkDateOrder, dateToday, convertToFixed, checkMinMax } from 'shared/utility';
import { ranges } from "./GINranges";
import dupesCalc from "./dupesCalc";
import fieldsList from "./fieldsList";

const fieldsObj = fieldsList.cost;

const rangesOptions = ranges.map(r => {
  return { id: r.rangeA, name: numberWithCommas(r.rangeA) }
});

let rangesDict = {};
ranges.forEach(r => {
  rangesDict[r.name] = r.rangeA;
});

const createRangeBlankObj = () => {
  let obj = {};
  ranges.forEach(r => {
    obj[r.name] = '';
  });
  return obj;
}

const rangesBlankObj = createRangeBlankObj();

const checkRangeName = (qty) => {
  ranges.forEach(r => {
    if (qty >= r.rangeA && qty <= r.rangeB) {
      return r.name;
    }
  });
}

const createObj = (qty, val) => {
  const rbObj = createRangeBlankObj();
  rbObj[qty] = val;
  return rbObj;
}

const coloredSelect = {backgroundColor:'gray',color:'white',fontSize:'12px !important'};

const CustomerList = props => {
  const { customers, previewCardCode } = props;
  return customers.map((c,i) => {
    return (
        <ListItem
          key={'key'+i}
          button
          onClick={() => {
            props.choose(c.CardCode, c.CardName);
          }}
          style={c.CardCode === previewCardCode ? coloredSelect : {}}
          >
          <div><strong>{c.CardCode}</strong></div><br />
          <div style={{marginLeft:'15px'}}>{c.CardName}</div>
        </ListItem>
      )
  });
}

const SelectPop = props => {
  return (
    <Dialog
      onClose={props.onClose}
      open={props.open}
      keepMounted
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle style={{paddingBottom: 0}} onClose={props.onClose}>
        Please select {props.title}
      </DialogTitle>

      <DialogContent style={{overflowY:'unset'}}>
        <GINSelect
          open={true}
          label=""
          options={props.options}
          choose={props.choose}
          name={props.name}
          indx={props.indx}
          desc={props.desc}
          default={props.default}
        />
      </DialogContent>
    </Dialog>
  )
}

class GINaddCostTarget extends React.Component {
  constructor(props) {
    super(props);
    const data = props.editTableData;
    const qty = data.qty ? data.qtyField : 'Base Price';
    const dates = dateToday();
    this.state = {
      singleView: true,
      applyToAll: false,

      // input / select values
      index: data.index || data.index === 0 ? createObj(qty, data.index) : rangesBlankObj,
      qty: data.qty ? createObj(qty, data.qty) : rangesBlankObj,
      term: data.term ? createObj(qty, data.term) : rangesBlankObj,
      cost: data.cost ? createObj(qty, data.cost) : rangesBlankObj,
      code: data.code ? createObj(qty, data.code) : rangesBlankObj,
      currency: data.currency ? createObj(qty, data.currency) : rangesBlankObj,
      start: data.start ? createObj(qty, data.start) : createObj(qty, dates[0]),
      end: data.end ? createObj(qty, data.end) : createObj(qty, dates[1]),

      termPop: false,
      currencyPop: false,
      popQuantity: '',
      popValue: '',
    }
  }

  handleCurrencyClose = () => {
    this.setState({
      currencyPop: false
    });
  }

  handleCurrencyOpen = (qty, value) => {
    this.setState({
      currencyPop: true,
      popQuantity: qty,
      popValue: value
    });
  }

  handleTermClose = () => {
    this.setState({
      termPop: false
    });
  }

  handleTermOpen = (qty, value) => {
    this.setState({
      termPop: true,
      popQuantity: qty,
      popValue: value
    });
  }

  handleSelectChange = (value, name) => {
    this.setState({
      [name]: value
    })
  }

  handleObjectSelectChange = (value, name, key) => {
    if (this.state.applyToAll) {
      this.setState((prevState) => {
        let copy = JSON.parse(JSON.stringify(prevState[name]));
        Object.keys(rangesDict).forEach(r => {
          copy[r] = value;
        });
        console.log('setting all', copy);
        return {
          [name]: copy,
          validationMessage: ''
        }
      })
    } else {
      let copy = JSON.parse(JSON.stringify(this.state[name]));
      copy[key] = value;
      this.setState({
        [name]: copy,
        validationMessage: ''
      })
    }
  }

  onIntegerChange = (target, e) => {
    if (!isNaN(e.target.value)) {
      this.setState({
        [target]: Math.round(e.target.value)
      })
    }
  }

  onFloatChange = (target, e) => {
    const re = /^[.0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
       this.setState({[target]: e.target.value})
    }
  }

  onFloatChangeMOC = (target, e) => {
    let moc = cloneDeep(this.state.moc);
    let mocType = cloneDeep(this.state.mocType);
    if (e.target.value) {
      if (!isNaN(e.target.value)) {
        moc[target] = parseFloat(e.target.value);
        mocType[target] = 'Special';
      }
    } else {
      moc[target] = '';
      mocType[target] = 'Standard';
    }
    this.setState({
      moc,
      mocType
    });
  }

  onFloatObjChange = (target, e, key) => {
    const re = /^[.0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      if (this.state.applyToAll) {
        let copy = JSON.parse(JSON.stringify(this.state[target]));
        Object.keys(rangesDict).forEach(r => {
          copy[r] = e.target.value;
        });
        this.setState({
          [target]: copy,
          validationMessage: ''
        })
      } else {
        let copy = JSON.parse(JSON.stringify(this.state[target]));
        copy[key] = e.target.value;
        this.setState({
          [target]: copy,
          validationMessage: ''
        })
      }
    }
  }

  onValueChange = e => {
    this.setState({ valueData: e.target.value.toUpperCase() });
  }

  onDescChange = e => {
    this.setState({ descData: e.target.value.toUpperCase() });
  }

  onValChange = (destination, e) => {
    this.setState({ [destination]: e.target.value });
  }

  handleChange = name => value => {
    this.setState({
      [name]: value,
    });
  };

  handleDateChange = (date, target) => {
    this.setState({
      [target]: typeof date === 'string' ? date : date.format('MMM DD YYYY').toUpperCase()
    });
  }

  handleObjDateChange = (date, target, key) => {
    let copy = JSON.parse(JSON.stringify(this.state[target]));
    if (this.state.applyToAll) {
      Object.keys(rangesDict).forEach(r => {
        copy[r] = typeof date === 'string' ? date : date.format('MMM DD YYYY').toUpperCase();
      });
    } else {
      copy[key] = typeof date === 'string' ? date : date.format('MMM DD YYYY').toUpperCase();
    }
    this.setState({
      [target]: copy,
      validationMessage: ''
    });
  }

  chooseDB = (db) => {
    console.log(db);
    this.openPriceAdd(db, this.state.selectedCurrency);
    this.setState({
      openPriceAddSelect: false
    });
  }

  handleClose = () => {
    this.props.close();
  }

  check = (val) => {
    if (val || val === 0) {
      return true;
    }
    return false;
  }

  updateGIN = (collection, indexes) => {
    console.log('here is the collection', collection, indexes);
    const data = this.props.editTableData,
          db = data.DB;

    let obj = {}, addFlag = false;
    fieldsObj.single.forEach((field,j) => {
      if (field) { obj[field] = cloneDeep(this.props[field]); }
    });
    fieldsObj.multiple.forEach((field,j) => {
      if (field) { obj[field] = cloneDeep(this.props[field]); }
    });
    collection.forEach((c,i) => {
      if (indexes[i] === '') {
        // ADD
        // check if existing
        if (!obj.ccur[db]) {
          if (c[0]) {
            if (parseInt(c[0]) >= 1 && parseInt(c[0]) < 1000) {
              // insert into single fields
              console.log('adding to single fields');
              fieldsObj.single.forEach((field,j) => {
                if (field) {
                  console.log('field n', field, this.props[field]);
                  obj[field][db] = c[j+1];
                  addFlag = true;
                }
              });
            }
          }
        } else {
          // insert into multiples fields
          fieldsObj.multiple.forEach((field,j) => {
            if (field) {
              if (!obj[field][db]) {
                obj[field][db] = [];
              }
              obj[field][db].push(c[j]);
              addFlag = true;
            }
          });
        }

      } else if (indexes[i] === -1) {
        console.log('edit first rows');
        fieldsObj.single.forEach((field,j) => {
          console.log('field',field);
          if (field) {
            let copy = cloneDeep(this.props[field]);
            console.log('copy',copy);
            copy[db] = c[j+1];
            this.props.updateGINEdit({
              db,
              field,
              value: copy[db]
            });
          }
        });
      } else {
        fieldsObj.multiple.forEach((field,j) => {
          if (field) {
            console.log('multiple edit field', field);
            let copy = cloneDeep(this.props[field]);
            copy[db][indexes[i]] = c[j];
            console.log('multiple result field', copy);
            this.props.updateGINEdit({
              db,
              field,
              value: copy[db]
            });
          }
        });
      }
    });

    if (addFlag) {
      this.props.updateGINMultiple(obj);
    }
  }

  handleSave = () => {
    console.log('stprps', this.state, this.props);

    // check
    let isComplete = true, msg = '', withinMinMax = true, isWithinOneYear = true, validDate = true;

    let indexes = [];
    const collection = Object.keys(this.state.cost).map((qty, i) => {
      const term = this.state.term[qty],
            cost = convertToFixed(this.state.cost[qty]),
            code = this.state.code[qty],
            currency = this.state.currency[qty],
            start = this.state.start[qty],
            end = this.state.end[qty],
            index = this.state.index[qty],
            numericQty = rangesDict[qty];

      const all = ((!term && !cost) && (!currency && ( !start && !end)));
      if (start && end) {
        if (!checkOneYearEndDate(start, end)) {
          isWithinOneYear = false;
        }

        if (!isValidDate(start) || !isValidDate(end)) {
          validDate = false;
        }
      }
      if (!term || !cost || !currency || !start || !end) {
        if (!all) {
            isComplete = false;
            msg += 'Please complete all fields. '
        }
        return null;
      } else {
        if (!checkMinMax(cost, 5)) {
          withinMinMax = false;
        }
        indexes.push(index);
        return [
          numericQty.toString(),
          term,
          cost,
          code,
          '',
          currency,
          start,
          end
        ];
      }
    }).filter(arr => {
      if (arr) return true;
    });

    if (collection) {
      if (collection.length === 0) {
        msg += 'Please complete all fields. ';
        isComplete = false;
      }
    }

    console.log('checking ---> ', this.state.cost['Base Price'], this.props.cprice, !this.state.cost['Base Price'] && !this.props.cprice);
    if (!this.state.cost['Base Price'] && !this.props.cprice[this.props.editTableData.DB]) {
      msg += 'You currently don\'t have a Base Price (Quantity 1 to 999) Cost. Please enter it before adding other quantities';
      isComplete = false;
    }

    console.log('isComplete', isComplete);
    console.log('collect',collection, isComplete);
    if (isComplete && withinMinMax) {
      if (!isWithinOneYear) {
        msg += 'End Date must be within one year of Start Date. ';
        this.setState({
          validationMessage: msg
        })
      } else {
        if (!validDate) {
          msg += 'Invalid Date. ';
          this.setState({
            validationMessage: msg
          })
        } else {
          this.updateGIN(collection, indexes);
          this.props.close();
        }
      }
    } else {
      if (!withinMinMax) {
        msg += 'Cost needs to be between 0.00001 and maximum of 100,000. ';
      }
      this.setState({
        validationMessage: msg
      })
    }

  }

  renderElem = (qty, terms, currencies, type) => {
    const realQty = rangesDict[qty];
    const realPrice = this.state.cost[qty] ? parseFloat(this.state.cost[qty]) : 0;
    let total = (realPrice && realQty) ? numberWithCommas(realPrice * realQty) : 0;
    let moc = 0;
    let isMOC = '';
    if (this.state.moc) {
      if (this.state.moc[this.state.currency[qty]]) {
        moc = this.state.moc[this.state.currency[qty]];
      }
    }

    if (total < moc) {
      total = moc;
      isMOC = this.state.mocType[this.state.currency[qty]];
    }
    return [
      qty,
      <div className="adjust-1-input">
        <CustomInput
          labelText=""
          id="cost"
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            onChange: (e) => {

            },
            onClick: () => {
              this.handleTermOpen(qty, this.state.term[qty]);
            },
            value: this.state.term[qty],
            role: 'presentation',
            autoComplete: 'nope'
          }}
        />
      </div>,
    <div className="adjust-1-input">
      <CustomInput
        labelText=""
        id="cost"
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          onChange: (e) => {
            this.onFloatObjChange('cost', e, qty);
          },
          value: this.state.cost[qty],
          role: 'presentation',
          autoComplete: 'nope'
        }}
      />
    </div>,
    <div className="adjust-1-input">
      <CustomInput
        labelText=""
        id="currency"
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          onChange: (e) => {

          },
          onClick: () => {
            this.handleCurrencyOpen(qty, this.state.currency[qty]);
          },
          value: this.state.currency[qty],
          role: 'presentation',
          autoComplete: 'nope'
        }}
      />
    </div>,
      <div className={type === 'single' ? 'calendar-adjust adjust-1-calendar movetop-calendar2' : 'calendar-adjust adjust-1-calendar movetop-calendar'}>
        <Datetime
          inputProps={{ placeholder: "" }}
          dateFormat="MMM DD YYYY"
          timeFormat={false}
          value={this.state.start[qty]}
          onChange={(date) => {
            this.handleObjDateChange(date, 'start', qty);
            if (!this.state.end[qty]) {
              if (typeof date !== 'string') {
                let newDate = date.add('years', 1).format('MMM DD YYYY').toUpperCase();
                let copy = JSON.parse(JSON.stringify(this.state.end));
                if (this.state.applyToAll) {
                  Object.keys(rangesDict).forEach(r => {
                    copy[r] = newDate;
                  });
                } else {
                  copy[qty] = newDate;
                }
                this.setState({
                  end: copy
                });
              }
            }
          }}
          closeOnSelect={true}
        />
      </div>,
      <div className={type === 'single' ? 'calendar-adjust adjust-1-calendar movetop-calendar2' : 'calendar-adjust adjust-1-calendar movetop-calendar'}>
        <Datetime
          inputProps={{ placeholder: "" }}
          dateFormat="MMM DD YYYY"
          timeFormat={false}
          value={this.state.end[qty]}
          onChange={(date) => {
            this.handleObjDateChange(date, 'end', qty);
          }}
          closeOnSelect={true}
        />
      </div>,
      `${this.state.currency[qty]} ${total} ${isMOC}`
    ];
  }

  getOptions = () => {
    console.log('getting options from ', this.props.editTableData.DB);
    let terms = [], currencies = [];
    if (this.props.options[this.props.editTableData.DB]) {
      if (this.props.options[this.props.editTableData.DB].terms) {
        if (this.props.options[this.props.editTableData.DB].terms.costing_terms) {
          terms = this.props.options[this.props.editTableData.DB].terms.costing_terms;
        }
      }

      if (this.props.options[this.props.editTableData.DB].currencies) {
        currencies = this.props.options[this.props.editTableData.DB].currencies;
      }
    }
    return {
      terms, currencies
    }
  }

  renderSingleInputs = () => {
    const opts = this.getOptions();
    const terms = opts.terms,
          currencies = opts.currencies;
    const qty = this.props.editTableData.qtyField;
    return [this.renderElem(qty, terms, currencies, 'single')];
  }

  renderMultipleInputs = () => {
    const opts = this.getOptions(),
          terms = opts.terms,
          currencies = opts.currencies;
    return ranges.map(r => {
      return this.renderElem(r.name, terms, currencies, 'multiple');
    });
  }

  toggleSingleView = () => {
    this.setState({
      singleView: !this.state.singleView
    });
  }

  changeToStandardMOC = (curr) => {
    let copyMOC = cloneDeep(this.state.moc);
    let copyMOCtype = cloneDeep(this.state.mocType);
    copyMOC[curr] = '';
    copyMOCtype[curr] = 'Standard'
    this.setState({
      moc: copyMOC,
      mocType: copyMOCtype
    });
  }

  changeToSpecialMOC = (curr) => {
    let copyMOCtype = cloneDeep(this.state.mocType);
    copyMOCtype[curr] = 'Special';
    this.setState({
      mocType: copyMOCtype
    })
  }

  closeBPFormReview = () => {
    this.setState({
      previewBP: false
    });
  }

  getCustomerName = (ccode, db) => {
    let customerName = '';
    if (this.props.options[db]) {
      this.props.options[db].pricing_customers.forEach(o => {
        if (ccode === o.CardCode) {
          customerName = o.CardName;
        }
      })
    }
    return customerName;
  }

  render() {
    console.log('st and props ---', this.state, this.props);
    const { classes } = this.props;
    const data = this.props.editTableData;
    const action = data.action ? 'EDIT' : 'ADD';
    const supplier = '';
    const singleInputs = this.renderSingleInputs(),
          multiInputs = this.renderMultipleInputs(),
          headers = ["QTY","SHIPPING TERMS", "COST", "CURRENCY", "EFFECTIVE DATES", "", "TOTAL COST"];

    let tterms = [], ccurrencies = [];
    if (this.props.options[this.props.editTableData.DB]) {
      if (this.props.options[this.props.editTableData.DB].terms) {
        if (this.props.options[this.props.editTableData.DB].terms.costing_terms) {
          tterms = this.props.options[this.props.editTableData.DB].terms.costing_terms;
        }
      }

      if (this.props.options[this.props.editTableData.DB].currencies) {
        ccurrencies = this.props.options[this.props.editTableData.DB].currencies;
      }
    }

    return (
      <Dialog
        open={this.props.open}
        keepMounted
        onClose={this.handleClose}
        maxWidth="md"
        fullWidth={true}
        className="dialog-overflow2"
      >
        <DialogTitle>
          <div style={{textAlign:'center'}}>{action} {supplier} COSTING ({data.DB})</div>
          <div className="close-btn">
            <IconButton aria-label="Close" onClick={this.handleClose}>
              <Close />
            </IconButton>
          </div>
        </DialogTitle>
        {this.state.termPop &&
          <SelectPop
            title="Term"
            options={tterms}
            choose={(value, name) => {
                this.handleObjectSelectChange(value, name, this.state.popQuantity);
                this.handleTermClose();
              }}
            name="term"
            indx=""
            desc=""
            open={this.state.termPop}
            onClose={this.handleTermClose}
            default={this.state.popValue}
          />
        }
        {this.state.currencyPop &&
          <SelectPop
            title="Currency"
            options={ccurrencies}
            choose={(value, name) => {
              this.handleObjectSelectChange(value, name, this.state.popQuantity);
              this.handleCurrencyClose();
            }}
            name="currency"
            indx="symb"
            desc="symb"
            open={this.state.currencyPop}
            onClose={this.handleCurrencyClose}
            default={this.state.popValue}
          />
        }
        <div style={{padding:'0 40px 40px 40px',overflowY:'scroll'}}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
            {(action !== 'EDIT' && !this.state.singleView) && <div>
              <Checkbox
                tabIndex={-1}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{checked: classes.checked}}
                checked={this.state.applyToAll}
                onClick={() => {
                  this.setState((prevState) => ({ applyToAll: !prevState.applyToAll }));
                }}
              />
              <span>Copy to All Quantities</span>
            </div>}
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              {action !== 'EDIT' && <div style={{marginTop:'20px'}}>
                <div
                  className={this.state.singleView ? 'orange-bg small-btn' : 'white-bg small-btn'}
                  onClick={this.toggleSingleView}
                  style={{marginRight:'20px',width:'151px'}}
                >SINGLE COST</div>
                <div
                  className={this.state.singleView ? 'white-bg small-btn' : 'orange-bg small-btn'}
                  onClick={this.toggleSingleView}
                  style={{marginRight:'20px',width:'151px'}}
                >COSTING TABLE</div>
              </div>}
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <div style={{marginTop:'20px'}} className="small-headers">
                {this.state.singleView && <Table
                tableHeaderColor="primary"
                tableHead={headers}
                tableData={singleInputs}
                coloredColls={[3]}
                colorsColls={["primary"]}
              />}

              {!this.state.singleView &&
              <div>
                <Table
                  tableHeaderClasses={['table-sticky-header']}
                  tableHeaderColor="primary"
                  tableHead={headers}
                  showTableBody={false}
                />
                <div style={{height:'400px',overflowY:'scroll'}} className="dense-table">
                  <Table
                    tableData={multiInputs}
                    coloredColls={[3]}
                    colorsColls={["primary"]}
                  />
                </div>
              </div>
            }
            </div>
            </GridItem>
          </GridContainer>

          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div style={{padding:'20px',textAlign:'center'}}>
                <Button2
                  color="success"
                  onClick={this.handleSave}
                >
                  SAVE
                </Button2>
                {this.state.validationMessage && <div className="red-text text-center" style={{marginTop:'20px'}}>{this.state.validationMessage}</div>}
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ccur: state.gin.ccur,
    cprice: state.gin.cprice,
    cterm: state.gin.cterm,
    cstart: state.gin.cstart,
    cend: state.gin.cend,
    ccode: state.gin.ccode,

    ccurs: state.gin.ccurs,
    cprices: state.gin.cprices,
    cterms: state.gin.cterms,
    cstarts: state.gin.cstarts,
    cends: state.gin.cends,
    cqtys: state.gin.cqtys,
    ccodes: state.gin.ccodes,

    itemCodes: state.gin.itemCodes,
    options: state.gin.options,
    mocs: state.gin.mocs
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateGINEdit: (data) => {
      dispatch(actions.updateGINEdit(data));
    },
    updateGINMultiple: (data) => {
      dispatch(actions.updateGINMultiple(data));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GINaddCostTarget));
