import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import * as actions from '../../store/actions';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Delete from '@material-ui/icons/Delete';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import Button2 from "components/CustomButtons/Button.jsx";
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Typography from '@material-ui/core/Typography';
import { PulseLoader } from 'react-spinners';
import GINbox from 'containers/GIN/GINbox';
import GINnew from 'containers/GIN/GINnew';
import GINask from 'containers/GIN/GINask2';
import { dynamicSort, checkMinMax } from 'shared/utility';
import { portal_charvalidations, alphanum_whitelist } from 'shared/validation';
import { requirePicksDB } from 'containers/GIN/GINhelpers';
import dbList from 'shared/activeDBs';

const changeAlls = ['base_item_description', 'qty_per_unit', 'ilength', 'width', 'weight',
        'product_type', 'item_is', 'sales_unit', 'basic_packaging_unit', 'brand', 'subgroup', 'rbo'];

const no_na_fields = ['gst_relevant','material_type', 'hsn_code', 'gst_tax_category', 'service_acct_code'];

const DialogTitle = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500],
  },
}))(props => {
  const { children, classes, onClose, editData } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      {!editData.applyToAll && <span style={{fontSize:'18px'}}>Edit {editData.itemName} for {editData.db}</span>}
      {editData.applyToAll && <span style={{fontSize:'18px'}}>Edit {editData.itemName}</span>}
      {onClose ? (
        <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon style={{top:4}}/>
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit,
  },
}))(MuiDialogActions);

// Popup Editor
class GINautocomplete extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      valueData: this.props.editData.value,
      filterData: '',
      descData: this.props.editData.desc,
      errors: '',
      ask: false,
      askPick: false,
      loading: false,
    }
  }

  openAsk = () => {
    this.setState({
      ask: true
    });
  }

  closeAsk = () => {
    this.setState({
      ask: false
    });
  }

  openAskPick = () => {
    this.setState({
      askPick: true
    });
  }

  closeAskPick = () => {
    this.setState({
      askPick: false
    });
  }

  checkLength = () => {
    const ed = this.props.editData;
    if (this.state.valueData) {
      if (ed.validations) {
        if (ed.validations.maxLength) {
          if (this.state.valueData.length > ed.validations.maxLength) {
            this.setState((prevState) => ({
              errors: prevState.errors + 'Maximum character length is ' + ed.validations.maxLength
            }));
          }
        }
      }
    }
  }

  checkChars = () => {
    const ed = this.props.editData,
          value = this.state.valueData,
          valueArr = this.state.valueData.split('');
    if (this.state.valueData) {
      if (ed.validations) {
        if (ed.validations.notAllowedChars) {
          if (ed.validations.notAllowedChars === 'aCharms') {
            console.log('testing chars');
            const forTest = portal_charvalidations.aCharms.split('');
            let hasChar = false;
            forTest.forEach((tchar) => {
              valueArr.forEach((vchar) => {
                if (tchar === vchar) {
                  hasChar = true;
                }
              });
            });
            if (hasChar) {
              this.setState((prevState) => ({
                errors: prevState.errors + 'Characters '+portal_charvalidations.aCharms+' are not allowed. '
              }));
            }
          }
        }

        if (ed.validations.alphaNum) {
          const err = alphanum_whitelist(value);
          if (err) {
            this.setState((prevState) => ({
              errors: prevState.errors + 'Alphanumeric characters only. '
            }));
          }
        }

        if (ed.validations.numOnly) {
          if (isNaN(value)) {
            this.setState((prevState) => ({
              errors: prevState.errors + 'Valid numbers only. '
            }));
          }
        }

        if (ed.validations.wholeNumOnly) {
          if (!isNaN(value)) {
            if ((value % 1) !== 0) {
              this.setState((prevState) => ({
                errors: prevState.errors + 'Whole numbers only. '
              }));
            }
          }
        }

        if (ed.validations.greaterThanOne) {
          if (!isNaN(value)) {
            if (value < 2) {
              this.setState((prevState) => ({
                errors: prevState.errors + 'Number should be greater than one. '
              }));
            }
          }
        }

        if (ed.validations.minMax3Decimals) {
          if (!isNaN(value)) {
            if (!checkMinMax(value, 3)) {
              this.setState((prevState) => ({
                errors: prevState.errors + 'Number should be between 0.001 and 100,000. '
              }));
            }
          }
        }

        if (ed.validations.maxNum) {
          if (!isNaN(value)) {
            if (parseFloat(value) > ed.validations.maxNum) {
              this.setState((prevState) => ({
                errors: prevState.errors + `Maximum number is ${ed.validations.maxNum}`
              }));
            }
          }
        }
      }
    }
  }

  checkValidations = () => {
    this.checkChars();
    this.checkLength();
  }

  onFieldNameChange = e => {
    this.setState({
      valueData: e.target.value.toUpperCase(),
      errors:''
    }, () => this.checkValidations());
  }

  onFieldNameChangeFilter = e => {
    this.setState({
      filterData: e.target.value,
      errors:''
    }, () => this.checkValidations());
  }

  convertToDecimals = (val, decimalNum) => {
    return val ? parseFloat(val).toFixed(decimalNum) : val;
  }

  convertToWholeNum = (val) => {
    return val ? parseInt(val).toString() : val;
  }

  handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (!this.state.errors) {
      const field = this.props.editData.field;
      let value = this.state.valueData;
      if (field === 'weight') {
        value = this.convertToDecimals(value, 2);
      }
      if (field === 'width' || field === 'ilength' || field === 'qty_per_unit') {
        value = this.convertToWholeNum(value);
      }
      const newEditedData = {
        field,
        value,
        db: this.props.editData.db
      }
      console.log('submitted edit...',this.props, newEditedData);
      this.props.updateGINEdit(newEditedData);
      if (this.props.editData.field === 'product_type') {
        this.props.fetchItemMOCs(this.state.valueData, this.props.editData.db);
      }
      if (this.props.editData.setPicked) {
        console.log('setting picked', field, this.props.editData.db);
        this.props.updateGINEdit({
          value: this.props.editData.db,
          field
        });
      }
      this.props.editModeOff();
      this.props.compareAll();
    }
  }

  handleValueSelect = (value) => {
    if (this.props.editData.applyToAll) {
      this.setState({
        valueData: value
      });
    } else {
      if (value !== this.state.valueData) {
        this.setState({
          valueData: value
        });
      } else {
        this.handleSubmit();
      }
    }
  }

  handleSubmitApplyAll = () => {
    const value = this.state.valueData,
          original_db = this.props.editData.db,
          field = this.props.editData.field;
    let changedProductTypes = [];

    this.props.openedDBs.forEach((db,i) => {
      if (field === 'product_type') {
        if (db !== original_db) {
          changedProductTypes.push(db);
        }
      }

      if (field === 'rbo') {
        const rbo_name = this.props.rboDict[original_db][value];
        const new_rbo_code = this.props.rboReverseDict[db][rbo_name];
        this.props.updateGINEdit({
          db,
          field,
          value: new_rbo_code
        });
      } else {
        this.props.updateGINEdit({
          db,
          field,
          value
        });
      }
    });
    this.props.compareAll();
    this.props.editModeOff();
    if (field === 'product_type' && changedProductTypes.length > 0) {
      console.log('trying to fetch ITEM MOCs', value, changedProductTypes);
      this.props.fetchItemMOCsAll(value, changedProductTypes);
    }
  }

  handleSubmitApplyAllPick = () => {
    const value = this.state.valueData,
          field = this.props.editData.field;

    this.props.openedDBs.forEach((db,i) => {
      if (requirePicksDB(db)) {
        if (field === 'picks') {
          this.props.updateGINEdit({
            db,
            field: 'picks',
            value
          });
        }
      }
    });
    this.props.compareAll();
    this.props.editModeOff();
  }

  render() {
    const data = this.props.editData;
    console.log('mystate', this.state, data);
    console.log('EDITING', data.field, data.type);
    let list = null;
    let coloredSelect = null;
    if (data.type === 'option1') {
      const similars = data.similars ? data.similars : {};
      list = data.options ? data.options.map((item, key) => {
        let selected = false;
        if (this.state.valueData) {
          if (item === this.state.valueData) {
            console.log('item is the same!', item);
            coloredSelect = {backgroundColor:'gray !important',color:'white !important',fontSize:'12px !important'};
            selected = true;
          } else {
            coloredSelect = {fontSize:'12px !important'};
          }
        }

        let similarText = '';
        Object.keys(similars).forEach((dbname, i) => {
          if (similars[dbname]) {
            if (similars[dbname] === item) {
              similarText += `(${dbname}) `;
            }
          }
        });

        const aa = item.substring(0, this.state.filterData.length);
        const bb = this.state.filterData;
        if (aa && bb) {
          if (aa.toUpperCase() !== bb.toUpperCase()) {
            return null;
          }
        }

        return (
          <ListItem
            key={'list'+key}
            selected={selected}
            button
            onClick={event => this.handleValueSelect(item)}
            style={coloredSelect}
            >
            {item}{data.addText} {similarText}
          </ListItem>
        )
      }) : null;
    }

    if (data.type === 'option2') {
      const similars = data.similars ? data.similars : {};
      // sort by descField
      let options = JSON.parse(JSON.stringify(data.options));
      options.sort(dynamicSort(data.descField));
      console.log('data type option2', options, data);
      list = options ? options.map((item, key) => {
        let coloredSelect = null;
        let selected = false;
        if (this.state.selectedValue) {
          if (item[data.valueField] === this.state.selectedValue) {
            selected = true;
            coloredSelect = {backgroundColor:'gray !important',color:'white !important',fontSize:'12px !important'};
          } else {
            coloredSelect = {fontSize:'12px !important'};
          }
        }

        let similarText = '';
        Object.keys(similars).forEach((dbname, i) => {
          if (similars[dbname]) {
            console.log('finding', similars[dbname], item[data.valueField]);
            if (similars[dbname] === item[data.valueField]) {
              similarText += `(${dbname}) `;
              console.log('found a similar', dbname, item[data.valueField]);
            }
          }
        });

        const aa = item[data.descField].substring(0, this.state.filterData.length);
        const bb = this.state.filterData;
        if (aa && bb) {
          if (aa.toUpperCase() !== bb.toUpperCase()) {
            return null;
          }
        }

        return (
          <ListItem
            key={'list2'+key}
            selected={selected}
            button
            onClick={event => {
              this.handleValueSelect(item[data.valueField]);
              this.setState({
                descData: item[data.descField]
              });
            }}
            style={coloredSelect}
            >
            {item[data.descField]} {similarText}
          </ListItem>
        )
      }) : null;
    }

    if (data.type === 'input' && data.field !== 'picks') {
      const similars = data.similars ? data.similars : {};
      console.log('data similars', data.similars);
      list = Object.keys(similars) ? Object.keys(similars).map((dbname, key) => {
        let coloredSelect = null;
        if (this.state.selectedValue) {
          if (similars[dbname] === this.state.selectedValue) {
            coloredSelect = {backgroundColor:'gray !important',color:'white !important',fontSize:'12px !important'};
          } else {
            coloredSelect = {fontSize:'12px !important'};
          }
        }

        if (similars[dbname] && dbname !== data.db) {
          return (
            <ListItem
              key={'list2'+key}
              button
              onClick={event => {
                this.handleValueSelect(similars[dbname]);
                this.setState({
                  descData: similars[dbname]
                });
              }}
              style={coloredSelect}
              >
              {similars[dbname]} ({dbname})
            </ListItem>
          )
        } else {
          return null;
        }
      }) : null;
    }

    return (
      <div id="gin-modal">
        {this.state.ask && <GINask
            command={() => {
              this.setState({loading:true});
              setTimeout(() => {
                this.handleSubmitApplyAll();
              }, 500);
            }}
            title="Are you Sure?"
            content="This will apply the selected Value to All DBs in this field"
            close={this.closeAsk}
          />
        }
        {this.state.askPick && <GINask
            command={() => {
              this.setState({loading:true});
              setTimeout(() => {
                this.handleSubmitApplyAllPick();
              }, 500);
            }}
            title="Are you Sure?"
            content="This will apply the selected Value to Applicable DBs in this field"
            close={this.closeAsk}
          />

        }
        <Dialog
          onClose={() => {
            this.props.editModeOff();
          }}
          aria-labelledby="customized-dialog-title"
          open={this.props.openEdit}
        >
          <DialogTitle id="customized-dialog-title"
            onClose={() => {
              this.props.editModeOff();
            }}
            editData={this.props.editData}
          >
          </DialogTitle>
          <div className={data.type === 'input' ? 'item-input-margin1' : 'item-input-margin'} style={{backgroundColor:'#fcfcfc'}}>

                    {
                      data.type === 'input' &&
                    <form onSubmit={(e) => {
                      e.preventDefault();
                    }} style={{width:'100%'}}>
                    <GridContainer>
                      <GridItem xs={8} sm={8} md={8}>
                            <CustomInput
                              labelText={'Edit'}
                              id="item_name"
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                onChange: this.onFieldNameChange,
                                value: this.state.valueData,
                                autoFocus: true,
                                role: 'presentation',
                                autoComplete: 'nope'
                              }}
                            />
                        </GridItem>
                        <GridItem xs={4} sm={4} md={4}>
                        {data.field === 'picks' && <div className="text-center">
                            <Button2
                              type="button"
                              color="success"
                              onClick={() => {
                                console.log('ddd input', data.field)
                                this.openAskPick();
                              }}
                              style={{marginTop:10,marginRight:10}}
                            >Apply to DBs</Button2>
                          </div>}
                        {!data.applyToAll && <div>
                          {(changeAlls.indexOf(data.field) !== -1 && this.props.openedDBs.length > 1) && <div className="text-center">
                            <Button2
                              type="button"
                              color="success"
                              onClick={() => {
                                console.log('ddd input', data.field)
                                this.openAsk();
                              }}
                              style={{maxWidth:120,marginTop:10}}
                            >Apply to All DBs</Button2></div>}
                          </div>}
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                        {this.state.errors && <div className="red-text">
                          {this.state.errors ? this.state.errors.split(' .').map((err,i) => <div key={`numb${i}`}>{err}</div>) : ''}
                        </div>}
                        </GridItem>
                      </GridContainer>
                      </form>
                    }
                    {
                      (data.type === 'input' && list) &&
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        {list.length > 0 && <div className="box-the-list">
                        {list}
                        </div>}
                      </GridItem>
                    </GridContainer>
                    }

                    {
                      data.type === 'option1' &&
                  <div>
                    <form onSubmit={(e) => {
                      e.preventDefault();
                    }}>
                      <GridContainer>
                        <GridItem xs={8} sm={8} md={8}>
                          <div className="parentable">
                            <CustomInput
                              labelText={'Search'}
                              id="item_name"
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                value: this.state.filterData ? this.state.filterData : '',
                                onChange: this.onFieldNameChangeFilter,
                                autoFocus: true,
                                role: 'presentation',
                                autoComplete: 'nope'
                              }}
                            />
                            <span onClick={() => {this.setState({filterData:''})}} className="del-p"><Delete /></span>
                          </div>
                        </GridItem>
                        <GridItem xs={4} sm={4} md={4}>
                          {!data.applyToAll && <div>
                          {(changeAlls.indexOf(data.field) !== -1 && this.props.openedDBs.length > 1) && <div className="text-center">
                            <Button2
                              type="button"
                              color="success"
                              onClick={() => {
                                console.log('ddd option1', data.field)
                                this.openAsk();
                              }}
                              style={{maxWidth:120,marginTop:10}}
                            >Apply to All DBs</Button2></div>}
                            </div>}
                        </GridItem>
                      </GridContainer>
                    </form>

                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <div className="box-the-list">
                        {list}
                        </div>

                        {data.field === 'item_is' && <div className="text-center green-text" style={{fontSize:12,fontWeight:'bold'}}>NOTE : Changing from OUTSOURCED to PRODUCED IN HOUSE or PRODUCED IN HOUSE to OUTSOURCED with existing Costs or existing BOM will make this field BOTH</div>}
                      </GridItem>
                    </GridContainer>
                  </div>
                    }

                    {
                      data.type === 'option2' &&
                      <div>
                        <form onSubmit={(e) => {
                          e.preventDefault();
                        }}>
                        <GridContainer>
                          <GridItem xs={8} sm={8} md={8}>
                            <div className="parentable">
                              <CustomInput
                                labelText={'Search'}
                                id="item_name"
                                formControlProps={{
                                  fullWidth: true
                                }}
                                inputProps={{
                                  onChange: this.onFieldNameChangeFilter,
                                  value: this.state.filterData ? this.state.filterData : '',
                                  autoFocus: true,
                                  role: 'presentation',
                                  autoComplete: 'nope'
                                }}
                              />
                              <span onClick={() => {this.setState({filterData:''})}} className="del-p"><Delete /></span>
                            </div>
                          </GridItem>
                          <GridItem xs={4} sm={4} md={4}>
                        {!data.applyToAll && <div>
                          {(changeAlls.indexOf(data.field) !== -1 && this.props.openedDBs.length > 1) && <div className="text-center">
                            <Button2
                              type="button"
                              color="success"
                              onClick={() => {
                                console.log('ddd option2', data.field);
                                this.openAsk();
                              }}
                              style={{maxWidth:120,marginTop:10}}
                            >Apply to All DBs</Button2></div>}
                          </div>}
                          </GridItem>
                        </GridContainer>
                      </form>
                        {no_na_fields.indexOf(data.field) === -1 && <ListItem
                          key={'list2a'}
                          button
                          onClick={event => {
                            this.handleValueSelect('n/a');
                            this.setState({
                              descData: 'n/a'
                            });
                          }}
                          >
                          n/a
                        </ListItem>}
                        <div className="box-the-list">
                        {list}
                        </div>
                      </div>
                    }
          </div>
          <DialogActions>
            {this.state.loading && <div className="text-center">Applying changes, please wait...<PulseLoader /></div>}
            {!this.state.loading && <div>
              {data.type === 'option1' && <div>Selected Value : <strong>{this.state.valueData}</strong></div>}
              {data.type === 'option2' && <div>Selected Value : <strong>{this.state.descData ? this.state.descData : 'none'}</strong></div>}
              {!data.applyToAll && <Button2
                type="submit"
                color="rose"
                onClick={this.handleSubmit}
                style={{margin:'20px auto'}}
              >Submit</Button2>}
              {data.applyToAll && <div className="text-center">
                <Button2
                  type="button"
                  color="success"
                  onClick={() => {
                    console.log('ddd option2', data.field);
                    this.openAsk();
                  }}
                  style={{maxWidth:120,marginTop:10}}
                >Apply to All DBs</Button2>
              </div>}
            </div>}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let rboDict = {}, rboReverseDict = {};
  const options = state.gin.options;
  Object.keys(options).forEach(db => {
    rboDict[db] = {};
    rboReverseDict[db] = {};
    const opts = options[db].rbos;
    if (opts) {
      opts.forEach(o => {
        rboDict[db][o.FirmCode.toString()] = o.FirmName;
        rboReverseDict[db][o.FirmName] = o.FirmCode.toString();
      });
    }
  });
  return {
    rboDict,
    rboReverseDict,
    editData: state.gin.editData,
    openedDBs: state.gin.openedDBs,
    productType: state.gin.product_type
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    editModeOff: () => {
      console.log('edit mode off');
      dispatch(actions.editModeOff());
    },
    updateGINEdit: (data) => {
      dispatch(actions.updateGINEdit(data));
    },
    compareAll: () => {
      dispatch(actions.fetchItemCompare());
    },
    fetchItemMOCs: (igc, db) => {
      dispatch(actions.fetchItemMOCs(igc, db));
    },
    fetchItemMOCsAll: (igc, dbs) => {
      dispatch(actions.fetchItemMOCsAll(igc, dbs));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GINautocomplete);
