import React, { Component } from 'react';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import AttachMoney from "@material-ui/icons/AttachMoney";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import { connect } from 'react-redux';
import GINalert from './GINalert';
import GINcostBySupplier from './GINcostBySupplier';
import GINcostTarget from './GINcostTarget';

class GINcost extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showCostingTarget: false
    }
  }

  toggleCostingType = () => {
    this.setState({
      showCostingTarget: !this.state.showCostingTarget
    });
  }

  checkCostingRequirementsAll = () => {
    let dbsMissing = [];
    this.props.openedDBs.forEach(db => {
      if (this.props.itemIs[db] === 'Both' || this.props.itemIs[db] === 'Outsourced') {
        if (this.props.smasterarray[db]) {
          // check if there is one supplier
          if (Object.keys(this.props.smasterarray[db]).length === 0) {
            console.log('0 cost missing');
            if (dbsMissing.indexOf(db) === -1) { dbsMissing.push(db); }
          } else {
            Object.keys(this.props.smasterarray[db]).forEach(supp => {
              const arr = this.props.smasterarray[db][supp];
              let costs = 0;
              arr.forEach(cost => {
                if (parseInt(cost[0]) !== 0) {
                  costs++;
                }
              });
              if (costs === 0) {
                console.log('cost is missing');
                if (dbsMissing.indexOf(db) === -1) { dbsMissing.push(db); }
              }
            });
          }
        } else {
          console.log('no smasterarray at all');
          if (dbsMissing.indexOf(db) === -1) { dbsMissing.push(db); }
        }
      }
    });
    return dbsMissing;
  }

  render() {
    const requiredDBs = this.checkCostingRequirementsAll();
    return (
  <div>
    <Card style={{marginTop:10,marginBottom:10}}>
      <CardBody>
        <GridContainer>
          <GridItem xs={12} sm={12} md={1}>
            <div style={{margin:'15px 0 20px 10px'}}>
              <CardIcon color="rose">
                <AttachMoney style={{fill:'white'}} />
              </CardIcon>
            </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={11}>
            <div style={{marginBottom:'20px',fontSize:'18px'}}>
              <Button
                color={!this.state.showCostingTarget ? 'rose' : 'white'}
                style={{marginRight:'20px'}}
                onClick={this.toggleCostingType}
              >COSTING BY SUPPLIER</Button>
              <Button
                color={this.state.showCostingTarget ? 'rose' : 'white'}
                style={{marginRight:'20px'}}
                onClick={this.toggleCostingType}
              >TARGET COSTS</Button>
              {requiredDBs.length > 0 && <div className="red-text" style={{fontSize:10,marginTop:10}}>{requiredDBs.map((rdb,i) => {
                return <span key={`rdb${i}`} className="separate-span">{rdb} </span>
              })} missing minimum requirements</div>}
            </div>
          </GridItem>
        </GridContainer>
        {!this.state.showCostingTarget && <div><GINcostBySupplier /></div>}
        {this.state.showCostingTarget && <div><GINcostTarget /></div>}
      </CardBody>
    </Card>
  </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    openedDBs: state.gin.openedDBs,
    smasterarray: state.gin.smasterarray,
    itemIs: state.gin.item_is
  }
}

export default connect(mapStateToProps, null)(GINcost);
