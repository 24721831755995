import React from "react";
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import { PulseLoader } from 'react-spinners';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import Button from "components/CustomButtons/Button.jsx";
import ReactTable from "react-table";
import InputAdornment from '@material-ui/core/InputAdornment';
import RFIDdownloadPopup from 'containers/RFID/RFIDdownloadPopup';
import AvailableRangePopup from "./AvailableRangePopup";
import SerialsPopup from "./SerialsPopup";
import InputPopup from "./InputPopup";
import InputUPC from "./InputUPC";
import barcoder from "barcoder";
import { num_whitelist } from "shared/validation";
import { convertToCommas, convertToSerial, clone, decConvert } from "shared/utility";
import { dropdownRequired, no_prefix_companies } from "containers/RFID/RFIDobj";
import { debounce } from 'lodash';
import { checkIfWithinSerials, logic } from "./RFIDlogic";
import checkPermissionsActions from 'shared/checkPermissionsActions';

const defaultState = {
  upcCode: '',
  openUPC: false,
  productionQty: '',
  error: {
    upc: '',
    production: '',
  },
  rfidType: 'C',
  manufacturerID: '',
  startingSerial: '',
  gtin14: '',
  gs1_prefix: null,
  isFinal: false,
  processed: false,

  openModal: false,
  modalType: null,
  modalContent: null,
  modalTitle: null,
  modalInput: '',
  modalInputError: null,
  modalInputError2: null,

  downloadPopup: false,
  downloads: [],

  currentPage: 0,
  currentStartingSerial: 0,

  similarSerials: [],
  showSimilarSerials: false,

  showAvailableRange: false,

  loading3: false,
  isWithinSerials: null,

  c128: '',
  jc_sub: '', // 3 digits
  jc_lot: '', // 4 digits
  jc_line: '', // 4 digits
  jc_sku: '', // 2 digits

  canOverrideGS1: false
}

class ExportEPC extends React.Component {
  constructor(props) {
    super(props);

    this.state = clone(defaultState);

    this.searchAPIDebounced = debounce(this.searchAPIDebounced, 500);
  }

  addDownloads = (file) => {
    this.setState({downloads:[...this.state.downloads, file]})
  }

  openDownloadPopup = () => {
    this.setState({downloadPopup:true,downloads:[]})
  }

  closeDownloadPopup = () => {
    this.setState({downloadPopup:false})
  }

  openModal = (modalTitle) => {
    this.setState({
      openModal:true,
      modalTitle,
      modalInput: modalTitle === 'Production Quantity' ? this.state.productionQty : this.state.startingSerial
    })
  }

  closeModal = () => {
    this.setState({
      openModal:false,
      modalTitle: null,
      modalInput: '',
      modalInputError: null,
      modalInputError2: null
    })
  }

  checkForExtraZero = (upcCode) => {
    console.log('checking upcCode', upcCode);
    if (upcCode) {
      // if (upcCode[0] == 0 && upcCode[1] == 0) {
      //   return upcCode.slice(1);
      // }
      if (upcCode.length === 13) {
        if (upcCode[0] === '0') {
          console.log('slice', upcCode.slice(1));
          return upcCode.slice(1);
        }
      }
    }
    return clone(upcCode);
  }

  searchAPIDebounced = () => {
    const gs1_prefix = this.props.gs1_prefix ? clone(this.props.gs1_prefix) : {};
    if (this.props.shouldOverrideGS1Length && this.props.overrideGS1) {
      gs1_prefix.prefix = logic.retrieveGS1Prefix({
        length: this.props.shouldOverrideGS1Length,
        gtin14: this.checkForExtraZero(this.state.upcCode)
      });
      gs1_prefix.gtin14 = decConvert(this.state.upcCode, 14);
    }
    const obj = {
      upc_code: this.checkForExtraZero(clone(this.state.upcCode)),
      production_qty: 1,
      company_id: clone(this.props.company_ids[this.props.company]),
      manufacturerID: clone(this.state.manufacturerID),
      rfidType: clone(this.state.rfidType),
      startingSerial: clone(this.state.startingSerial),
      gs1_prefix,
      final: false,
      jc_line: this.state.jc_line,
      jc_lot: this.state.jc_lot,
      jc_sku: this.state.jc_sku,
      jc_sub: this.state.jc_sub
    };
    setTimeout(() => {
      console.log('getting EPC 1')
      this.props.rfidExportEPC(clone(obj));
      this.setState({isFinal:false});
    }, 300);
  }

  handleSelectChange = (value, name) => {
    this.setState({
      [name]: value,
      rfidType: 'B'
    })
  }

  clear = () => {
    window.location.reload();
  }

  exportToExcel = () => {
    const data = {
      upcCode: this.state.upcCode,
      productionQty: this.state.productionQty,
      serial: this.state.startingSerial,
      manufacturerID: this.state.manufacturerID,
      rfidType: 'C',
      gs1_prefix: this.props.gs1_prefix ? clone(this.props.gs1_prefix) : null,
      brand: this.props.company ? this.props.company.toUpperCase() : '',
      company_id: this.props.company_ids[this.props.company]
    }
    if (this.props.overrideGS1 && this.props.shouldOverrideGS1Length) {
      const gtin14 = decConvert(this.state.upcCode, 14);
      const gs1_prefix = logic.retrieveGS1Prefix({
        gtin14,
        length: this.props.shouldOverrideGS1Length
      });
      data.gs1_prefix = {
        prefix: gs1_prefix,
        gtin14
      }
    }
    console.log('exporting to excel', data);
    if (data.company_id === 4) {
      data.upcCode = `${this.state.jc_sub}${this.state.jc_lot}${this.state.jc_line}${this.state.jc_sku}`;
    }
    console.log('the data', data);
    this.props.generateRFIDExcel(data);
  }

  showSimilarSerials = () => {
    this.setState({showSimilarSerials:true, similarSerials: checkIfWithinSerials(this.props.queryResults, {
      productionQty: this.state.productionQty,
      startingSerial: this.state.startingSerial,
      manufacturerID: this.state.manufacturerID
    })})
  }

  hideSimilarSerials = () => {
    this.setState({showSimilarSerials:false, similarSerials: []})
  }

  process = () => {
    const obj = {
      upc_code: this.checkForExtraZero(this.state.upcCode),
      production_qty: this.state.productionQty,
      company_id: this.props.company_ids[this.props.company],
      manufacturerID: this.state.manufacturerID,
      rfidType: this.state.rfidType,
      gs1_prefix: this.props.gs1_prefix ? clone(this.props.gs1_prefix) : {},
      final: true,
      startingSerial: this.state.startingSerial,
    };
    
    if (this.props.shouldOverrideGS1Length && this.props.overrideGS1) {
      obj.gs1_prefix.prefix = logic.retrieveGS1Prefix({
        length: this.props.shouldOverrideGS1Length,
        gtin14: this.checkForExtraZero(this.state.upcCode)
      });
      obj.gs1_prefix.gtin14 = decConvert(this.state.upcCode, 14);
    }

    const jc_arr = ['sub','lot','line','sku'];
    jc_arr.forEach(s => {
      obj[`jc_${s}`] = this.state[`jc_${s}`];
    });
    console.log('setting final', obj)
    this.setState({
      loading: true,
      isFinal: true,
      currentStartingSerial: clone(this.state.startingSerial),
      processed: true
    }, () => {
      console.log('getting EPC 2', obj)
      this.props.rfidExportEPC(clone(obj))
    });
  }

  processTable = (production_qty, startingSerial) => {
    const obj = {
      upc_code: this.checkForExtraZero(this.state.upcCode),
      production_qty,
      company_id: this.props.company_ids[this.props.company],
      manufacturerID: this.state.manufacturerID,
      rfidType: this.state.rfidType,
      gs1_prefix: this.props.gs1_prefix,
      final: false,
      startingSerial,
      noSerialChange: true
    }
    console.log('setting final 2', obj)
    this.setState({
      loading: true,
      isFinal: true
    }, () => {
      console.log('getting EPC 3')
      this.props.rfidExportEPC(clone(obj))
    });
  }

  handleModalInputChange = (e, target) => {
    let finalValue = '';
    if (target === 'productionQty' || target === 'startingSerial') {
      if (!num_whitelist(e.target.value)) {

        if (target === 'startingSerial') {
          let modalInputError = null;
          const int = parseInt(e.target.value);

          if (no_prefix_companies.indexOf(this.props.company) !== -1) {
            // ALLBIRDS
            if (this.props.company === 'allbirds') {
              // 9,999,999,999
              if (int > 19999999999) {
                modalInputError = 'Value cannot be more than 19,999,999,999';
              }
              // 20,000,000,000
              console.log('add up 1', int + this.state.productionQty, int, this.state.productionQty);
              if ((int + this.state.productionQty) > 20000000000) {
                modalInputError = 'Value plus production qty. serial cannot be more than 20,000,000,000';
              }
              if ((int + this.state.productionQty) < 10000000000) {
                modalInputError = 'Value plus production qty. serial cannot be less than 10,000,000,000';
              }
            } else if (this.props.company === 'jcpenny1' || this.props.company === 'jcpenny2') {
              // 35,000,000,000
              if (int > 35000000000) {
                modalInputError = 'Value cannot be more than 35,000,000,000';
              }
              // range
              console.log('add up 2', int + this.state.productionQty, int, this.state.productionQty);
              if ((int + this.state.productionQty) > 60000000000) {
                modalInputError = 'Value plus production qty. serial cannot be more than 60,000,000,000';
              }
              if ((int + this.state.productionQty) < 25000000000) {
                modalInputError = 'Value plus production qty. serial cannot be less than 25,000,000,000';
              }
            }
          } else {
            // WALMART 135
            if (int > 999999999) {
              console.log('this is more than 1', int);
              modalInputError = 'Value cannot be more than 999,999,999';
            }
            if ((int + this.state.productionQty) > 1000000000) {
              console.log('this is more than 2', int + this.state.productionQty);
              modalInputError = 'Value plus production qty. cannot be more than 999,999,999';
            }
          }

          finalValue = e.target.value ? parseInt(e.target.value) : 0;
          this.setState({modalInputError});
        }

        if (target === 'productionQty') {
          let modalInputError2 = null;
          const int = parseInt(e.target.value);

          if (no_prefix_companies.indexOf(this.props.company) !== -1) {
            if (this.props.company === 'allbirds') {
              // ALLBIRDS
              if ((int + this.state.startingSerial) > 20000000000) {
                console.log('int', int + this.state.startingSerial);
                modalInputError2 = 'Quantity plus starting serial qty. cannot be more than 20,000,000,000';
              }
            } else {
              // JC PENNY 1 and JC PENNY 2
              if ((int + this.state.startingSerial) > 60000000000) {
                console.log('int', int + this.state.startingSerial);
                modalInputError2 = 'Quantity plus starting serial qty. cannot be more than 60,000,000,000';
              }
            }
          } else {
            // WALMART
            if ((int + this.state.startingSerial) >= 1000000000) {
              modalInputError2 = 'Quantity plus starting serial qty. cannot be more than 999,999,999';
            }
          }
          finalValue = e.target.value ? parseInt(e.target.value) : 0;
          this.setState({modalInputError2});
        }

      } else {
        return;
      }
    }
    this.setState({modalInput:finalValue});
  }

  handleSet = (val, target) => {
    this.setState({[target]:val});
  }

  handleTextInputChange = (e, target) => {
    let upc = this.state.error.upc,
        production = this.state.error.production,
        finalValue = '',
        gtin14 = JSON.parse(JSON.stringify(this.state.gtin14));
    if (target === 'upcCode') {
      if (e.target.value.length === 12) {
        console.log('sss',e.target.value.substring(0,11))
        //upc = eanCheckDigit(e.target.value.substring(0,11));
        if (!barcoder.validate(e.target.value)) {
          upc = 'Invalid UPC';
        } else {
          gtin14 = decConvert(e.target.value, 14);
          upc = '';
        }
      } else if (e.target.value.length === 13) {
        if (!barcoder.validate(e.target.value)) {
          upc = 'Invalid UPC';
        } else {
          gtin14 = decConvert(e.target.value, 14);
          upc = '';
        }
      } else {
        if (e.target.value) {
          upc = 'Invalid Length';
        }
      }
      finalValue = e.target.value;
    }
    console.log('target', target);
    if (target === 'productionQty' || target === 'startingSerial') {

      if (!num_whitelist(e.target.value)) {
        finalValue = e.target.value;
      } else {
        return;
      }
    }
    this.setState({
      [target]: finalValue,
      gtin14,
      error: {
        upc,
        production: this.state.error.production
      }
    })
  }

  renderTable = () => {
    const totalPages = Math.ceil(this.state.productionQty / 10);
    const qty = this.state.currentPage === (totalPages - 1) ? (this.state.productionQty % 10) : 10;
    console.log('qtyy',qty, this.state.currentPage, totalPages);
    const codes = this.props.results.map((r,i) => {
      if (qty !== 10 && qty !== 0) {
        if (i > (qty - 1)) {
          console.log('returning null');
          return null;
        }
      }
      return r;
    }).filter(f => f ? true : false);
    const table = (<div>
    <div className="spec-table left-table table-f16"><ReactTable
        data={
            codes.map((code, key) => {
              return ({
                id: key,
                epcCode: code.epcCode,
                serialNum: code.serialNum,
                serialNumCell: <div className="spaced-10">{code.serialNum}</div>
              })
            })
        }
        filterable={false}
        columns={[
          {
              Header: "Serial #",
              accessor: "serialNum",
              sortable: true,
              filterable: false,
              Cell: row => {
                return (
                  <div>{row.original.serialNumCell}</div>
                )
              }
          },
          {
              Header: "EPC Code",
              accessor: "epcCode",
              sortable: true,
              filterable: false,
          },
        ]}
        minRows={0}
        showPagination={false}
        className="-highlight"
        defaultPageSize={10}
    /></div>
      <div style={{margin:20}}>
        Page {(this.state.currentPage + 1).toLocaleString()} of {totalPages.toLocaleString()}
      </div>
      <Button
        style={{marginTop:20,marginRight:20}}
        color={this.state.currentPage === 0 ? 'white' : 'rose'}
        disabled={this.state.currentPage === 0 ? true : false}
        onClick={() => {
          this.setState({currentPage:this.state.currentPage-1, currentStartingSerial: this.state.currentStartingSerial - 10})
          this.processTable(qty, this.state.currentStartingSerial - 10)
        }}
      >BACK</Button>
      <Button
        style={{marginTop:20,marginRight:20}}
        onClick={() => {
          this.setState({
            currentPage:this.state.currentPage+1,
            currentStartingSerial: clone(this.state.currentStartingSerial) + 10
          })
          this.processTable(qty, clone(this.state.currentStartingSerial) + 10)
        }}
        color={this.state.currentPage === (totalPages - 1) ? 'white' : 'rose'}
        disabled={this.state.currentPage === (totalPages - 1) ? true : false}
      >FORWARD</Button>
    </div>)

    return table;
  }

  setPrefix = () => {
    if (dropdownRequired.indexOf(this.props.company) === -1) {
      this.setState({
        rfidType: 'C' // where manufacturerID / prefix is built in to Serial #
      })
    } else {
      if (this.props.prefixes) {
        if (this.props.prefixes.hasOwnProperty(this.props.company)) {
          this.setState({
            manufacturerID: this.props.prefixes[this.props.company].prefix,
            rfidType: 'B' // where manufacturerID / prefix is not built in to Serial #
          })
        }
      }
    }
  }

  componentDidMount() {
    const actionPerms = checkPermissionsActions('RFID');
    if (actionPerms.hasOwnProperty('override')) {
      this.setState({
        canOverrideGS1: true
      });
    }
    if (this.props.company === 'jcpenny2') {
      // no need to check GS1 API
    } else {
      if (!this.props.overrideGS1){
        this.props.checkGS1API(this.props.forceError);
      }
      this.props.clearData();
      this.setPrefix();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevState) !== JSON.stringify(this.state) || (JSON.stringify(prevProps) !== JSON.stringify(this.props))) {

      // JC PENNY 2 ONLY
      if (this.props.company === 'jcpenny2') {
        let pass = true;
        const requiredLength = {
          sub: 3,
          lot: 4,
          line: 4,
          sku: 2
        };
        ['sub','lot','line','sku'].forEach(o => {
          const x = this.state[`jc_${o}`];
          if (!x) {
            pass = false;
          } else {
            if (x.length !== requiredLength[o]) {
              pass = false;
            }
          }
        })
        if (pass) {
          console.log('searching again??', this.state.startingSerial, prevState.startingSerial)
          if (this.state.productionQty && !this.state.isFinal) {
            this.searchAPIDebounced();
            return;
          }
        }
      }

      // ALL OTHER
      if ((this.state.upcCode && this.state.productionQty) && (!this.state.error.upc)) {
        if (!this.state.isFinal) {
          console.log('searching again...', this.state.upcCode, this.state.productionQty, this.state.startingSerial);
          this.searchAPIDebounced();
        }
      }

      if (this.state.upcCode && (!this.state.error.upc)) {
        if (barcoder.validate(this.state.upcCode)) {
          if (this.props.gs1_prefix) {
            const data = this.props.gs1_prefix;
          }
        }
      }

      if (this.state.gtin14 !== prevState.gtin14) {
        if (barcoder.validate(this.state.upcCode)) {
          if (this.props.shouldOverrideGS1Length && this.props.overrideGS1) {
            this.props.loadGS1CompanyPrefix(this.state.gtin14, this.props.shouldOverrideGS1Length, this.props.forceError);
          } else if (!this.props.overrideGS1) {
            this.props.loadGS1CompanyPrefix(this.state.gtin14);
          }
        }
      }
    }

    console.log('ExportEPC props', this.props, prevProps.last_serial, this.props.last_serial);
    if ((prevProps.last_serial !== this.props.last_serial) && !this.props.noSerialChange) {
      if (this.props.last_serial || this.props.last_serial === 0) {
        let str = (this.props.last_serial+1).toString();
        console.log('setting starting serial', str);
        if (no_prefix_companies.indexOf(this.props.company) === -1) {
          str = str.slice(3,str.length);
        }
        if (str) {
          console.log('final starting serial is', parseInt(str))
          this.setState({startingSerial:parseInt(str)})
        }
      }
    }

    if (prevProps.prefix !== this.props.prefix) {
      this.setPrefix();
    }

    if (prevProps.filename !== this.props.filename) {
      if (this.props.filename) {
        const filesArr = this.props.filename.split(',');
        this.openDownloadPopup();
        this.props.verifyRFIDExcel(filesArr);
      }
    }

    if (prevState.startingSerial !== this.state.startingSerial) {
      if (this.props.queryResults) {
        this.findIfWithinSerials();
        return;
      }
    }

    let gs1_prefix_error = false;
    if (this.props.company !== 'jcpenny2') {
      if (this.props.gs1_prefix && !this.props.overrideGS1) {
        if (this.props.gs1_prefix.prefix) {
          if (isNaN(this.props.gs1_prefix.prefix)) {
            gs1_prefix_error = true;
          }
        } else {
          gs1_prefix_error = true;
        }
      }
    }
    if (gs1_prefix_error) {
      
    }
  }

  extractNumbers = (rangesArr, startingSerial, qty) => {
    const included = {};
    rangesArr.forEach(ra => {
      const start = ra.start,
            end = ra.end;
      for (let i = startingSerial; i < startingSerial+qty; i++) {
        if (i >= start && i <= end) {
          if (!included.hasOwnProperty(i)) {
            included[i] = '';
          }
        }
      }
    })
    let recordedSorted = Object.keys(included).map(r => parseInt(r)).sort((a,b) => a -b);
    console.log('recordedSorted', recordedSorted);

    let ranges = {};
    let started = recordedSorted[0];
    ranges[recordedSorted[0]] = {
      start: recordedSorted[0],
      end: null
    };
    recordedSorted.forEach((now,i) => {
      if (i !== 0) {
        const next = recordedSorted[i+1];
        if (next) {
          if (now+1 !== next) {
            console.log('--+', now+1, next);
            ranges[started].end = now;
            started = next;
            ranges[next] = {
              start: next,
              end: null
            }
          }
        } else {
          ranges[started].end = now;
          console.log('next',now,next, i);
        }
      }
    })

    console.log('second ranges', ranges, startingSerial, qty);
    return Object.values(ranges);
  }

  findAvailableSerialRange = () => {
    console.log('find available serial range');
    this.openAvailableRange();
  }

  openAvailableRange = () => {
    this.setState({showAvailableRange:true});
  }

  closeAvailableRange = () => {
    this.setState({showAvailableRange:false});
  }

  processSerial = (startingSerial) => {
    if (no_prefix_companies.indexOf(this.props.company) !== -1) {
      return startingSerial;
    }
    return convertToCommas(convertToSerial(startingSerial));
  }

  findIfWithinSerials = () => {
    let isWithinSerials = false;
    if (this.props.queryResults) {
      isWithinSerials = checkIfWithinSerials(this.props.queryResults, {
        productionQty: this.state.productionQty,
        startingSerial: this.state.startingSerial,
        manufacturerID: this.state.manufacturerID
      });
      this.setState({loading3:true});
      console.log('isWithinSerials', isWithinSerials);
    }
    this.setState({isWithinSerials, loading3:false});
  }

  render() {
    console.log('st-pp', this.state, this.props);
    if (this.props.gs1_api_is_working && !this.props.gs1_prefix_loading) {
      if (!this.props.gs1_prefix && this.state.upcCode && !this.props.overrideGS1 && !this.state.error.upc) {
        return (
          <div>
            {!this.state.canOverrideGS1 && <div style={{color:'red',textAlign:'center'}}>GS1 API is reporting an error with this UPC.</div>}
            {this.state.canOverrideGS1 && <div style={{color:'red',textAlign:'center'}}>GS1 API is reporting an error with this UPC, would you like to bypass the GS1 API?
              <br />
              <Button
                color="rose"
                onClick={() => {
                  this.props.setOpenOverrideBox(true);
                  this.props.setOverrideGS1(true);
                  this.props.set_gs1_api_is_working(true);
                }}
              >OVERRIDE GS1 API</Button>
            </div>}
          </div>
        )
      }
    }
    if (this.props.gs1_api_is_working === false && !this.props.loading2) {
      return (
        <div>
          {!this.state.canOverrideGS1 && <div style={{color:'red',textAlign:'center'}}>GS1 API currently not available, please try again later.</div>}
          {this.state.canOverrideGS1 && <div style={{color:'red',textAlign:'center'}}>GS1 API currently not available, would you like to bypass the GS1 API?
            <br />
            <Button
              color="rose"
              onClick={() => {
                this.props.setOpenOverrideBox(true);
                this.props.setOverrideGS1(true);
                this.props.set_gs1_api_is_working(true);
              }}
            >OVERRIDE GS1 API</Button>
          </div>}
        </div>
      )
    } else if (!this.props.gs1_api_is_working && this.props.loading2) {
      return (
        <div>
          <div style={{textAlign:'center'}}>
            <PulseLoader />
            <span>Checking GS1 Server</span>
          </div>
        </div>
      )
    }
    if (this.state.loading3) {
      return (
        <div>
          <div style={{textAlign:'center'}}>
            <PulseLoader />
            <span>Calculating</span>
          </div>
        </div>
      )
    }
    if (this.props.gs1_prefix_loading) {
      return (
        <div>
          <div style={{textAlign:'center'}}>
            <PulseLoader />
            <span>Loading GS1 Prefix</span>
          </div>
        </div>
      )
    }
    const isWithinSerials = this.state.isWithinSerials;
    const props = this.props;
    let isComplete = ((!this.state.error.upc && !this.state.error.production) 
      && (this.state.upcCode && this.state.productionQty)) ? true : false;
    if (this.props.company === 'jcpenny2') {
      let completeJCvalues = false;
      let pass = true;
      const requiredLength = {
        sub: 3,
        lot: 4,
        line: 4,
        sku: 2
      };
      ['sub','lot','line','sku'].forEach(o => {
        const x = this.state[`jc_${o}`];
        if (!x) {
          pass = false;
        } else {
          if (x.length !== requiredLength[o]) {
            pass = false;
          }
        }
      })
      if (pass) completeJCvalues = true;
      isComplete = ((!this.state.error.upc && !this.state.error.production) && (completeJCvalues && this.state.productionQty)) ? true : false;
    }
    const selectManufacturerID = dropdownRequired.indexOf(this.props.company) !== -1 ? true : false;
    const showInputs = !selectManufacturerID || (selectManufacturerID && this.state.manufacturerID)
    let hasResults = false;
    if (this.props.results) {
      if (this.props.results.length > 0) {
        hasResults = true;
      }
    }
    const sts = parseInt(this.state.startingSerial);
    console.log('sts', sts, this.state.productionQty);

    // MIN - MAX CHECK
    let errorMessage = '';
    if (this.props.company === 'allbirds') {
      // check if max 10 billion is reached
      if (this.state.startingSerial && this.state.productionQty) {
        const add_up = this.state.startingSerial + this.state.productionQty;
        console.log('add_up', add_up)
        if (add_up > 19999999999) {
          isComplete = false;
          errorMessage = 'Starting serial plus production quantity cannot be more than 19,999,999,999.';
        }
        if (this.state.startingSerial) {
          const numVal = parseInt(this.state.startingSerial);
          if (numVal) {
            console.log('looking at numVal', numVal)
            if (this.props.company === 'allbirds') {
              if (numVal < 10000000001 || numVal > 20000000000) {
                isComplete = false;
                errorMessage = 'Number must be between 10,000,000,001 to 20,000,000,000.';
              }
            }
          }
        }
      }
    }
    if (this.props.company === 'jcpenny1' || this.props.company === 'jcpenny2') {
      // check if max 60 billion is reached 60000000000
      if (this.state.startingSerial && this.state.productionQty) {
        const add_up = this.state.startingSerial + this.state.productionQty;
        if (add_up > 274877906943) {
          errorMessage = 'Maximum serial number is 274,877,906,943';
        }
        console.log('add_up', add_up)
        if (add_up > 35000000000) {
          isComplete = false;
          errorMessage = 'Starting serial plus production quantity cannot be more than 35,000,000,000.';
        }
        if (this.state.startingSerial) {
          const numVal = parseInt(this.state.startingSerial);
          if (numVal) {
            console.log('looking at numVal', numVal)
            if (this.props.company === 'jcpenny1') {
              if (numVal < 25000000000 || numVal > 60000000000) {
                isComplete = false;
                errorMessage = 'Number must be between 25,000,000,000 and 60,000,000,000';
              }
            }
          }
        }
      }
    }
    if (this.props.company === 'walmart') {
      // set max to 135,999,999,99
      let num_val = 0;
      if (this.state.startingSerial && this.state.manufacturerID) {
        num_val = parseInt(this.state.manufacturerID + decConvert(this.state.startingSerial))
      }
      if (this.state.startingSerial && this.state.productionQty) {
        const add_up = num_val + this.state.productionQty;
        if (add_up > 135999999999) {
          errorMessage = "Max serial is 135,999,999,99";
        }
      } else {
        if (this.state.startingSerial) {
          if (num_val > 135999999999) {
            errorMessage = "Max serial is 135,999,999,99";
          }
        }
      }
    }

    console.log('is complete ?', isComplete)
    console.log('ExportEPC props', this.state, this.props);

    let gs1_prefix_error = false;
    if (this.props.company !== 'jcpenny2') {
      if (this.props.gs1_prefix && !this.props.overrideGS1) {
        if (this.props.gs1_prefix.prefix) {
          if (isNaN(this.props.gs1_prefix.prefix)) {
            gs1_prefix_error = true;
          }
        } else {
          gs1_prefix_error = true;
        }
      }
    }

    if (!this.props.shouldOverrideGS1Length && this.props.overrideGS1) {
      console.log('gs1_prefix_error2');
      // Length is required when overriding
      gs1_prefix_error = true;
    }

    console.log('gs1_prefix_error', gs1_prefix_error, this.props);

    return (
    <div>
      {this.state.showAvailableRange && <AvailableRangePopup
          open={this.state.showAvailableRange}
          close={this.closeAvailableRange}
          queryResults={this.props.queryResults}
          productionQty={this.state.productionQty}
          startingSerial={this.state.startingSerial}
          manufacturerID={this.state.manufacturerID}
          upcCode={this.state.upcCode}
          handleSet={this.handleSet}
          company={this.props.company}
        />}
      {this.state.downloadPopup && <RFIDdownloadPopup
          files={this.props.filename}
          close={this.closeDownloadPopup}
          open={this.state.downloadPopup}
          downloads={this.state.downloads}
          addDownloads={this.addDownloads}
          verifyExcelsStatus={this.props.verifyExcelsStatus}
          verifyUpdatePercentage={this.props.verifyUpdatePercentage}
          filenamesData={this.props.filenamesData}
          startingEPC={this.props.results ? this.props.results[0] ? this.props.results[0].epcCode : null : null}
          lastSerial={this.props.last_serial}
          showOverride={true}
          isOverriden={isWithinSerials && !this.props.noSerialChange}
        />}
      {this.state.openModal && <InputPopup
          title={this.state.modalTitle}
          modalInput={this.state.modalInput}
          handleModalInputChange={this.handleModalInputChange}
          modalInputError={this.state.modalInputError}
          modalInputError2={this.state.modalInputError2}
          manufacturerID={this.state.manufacturerID}
          company={this.props.company}
          handleSet={this.handleSet}
          close={this.closeModal}
          open={this.state.openModal}
          lastSerial={this.props.last_serial}

        />}
      {this.state.openUPC && <InputUPC 
          open={this.state.openUPC}
          close={() => { this.setState({openUPC:false}) }}
          upcCode={this.state.upcCode}
          setUpcCode={(upcCode) => {
            this.setState({
              upcCode
            })
          }}
      />}
      {showInputs && <div>
        {this.props.company === 'jcpenny2' && <GridContainer
            className={(hasResults && this.state.isFinal) ? `no-click` : ''}
          >
          <GridItem xs={12} sm={6} md={6}>
            <CustomInput
                labelText="C128"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: (e) => {
                    if (e.target.value.length < 14) {
                      if (e.target.value.length === 13) {
                        const str = e.target.value;
                        const jc_sub = str.substr(0, 3);
                        const jc_lot = str.substr(3, 4);
                        const jc_line = str.substr(7, 4);
                        const jc_sku = str.substr(11, 2);
                        this.setState({
                          upcCode: e.target.value,
                          c128: e.target.value,
                          jc_sub,
                          jc_lot,
                          jc_line,
                          jc_sku
                        })
                      } else {
                        this.setState({
                          upcCode: e.target.value,
                          c128: e.target.value,
                          jc_sub: '', // 3 digits
                          jc_lot: '', // 4 digits
                          jc_line: '', // 4 digits
                          jc_sku: '' // 2 digits
                        })
                      }
                    }
                  },
                  onClick: () => { },
                  value: this.state.c128
                }}
                error={(this.state.c128.length !== 13 ? true : false)}
              />
              {this.state.c128.length !== 13 && <div className="red-text">13 digits required</div>}
          </GridItem>
        </GridContainer>}
      <GridContainer>
        {this.props.company !== 'jcpenny2' && <GridItem xs={12} sm={3} md={3}>
          <div>
            <CustomInput
              labelText="12/13 Digit UPC/EAN"
              id="upcCode"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: (e) => {
                  this.handleTextInputChange(e, 'upcCode');
                },
                onClick: () => { 
                  //this.setState({ openUPC: true });
                },
                value: this.state.upcCode,
                autoFocus: true,
                role: 'presentation',
                autoComplete: 'nope',
                disabled: hasResults
              }}
              error={this.state.error.upc ? true : false}
            />
          </div>
          {this.state.error.upc && <div style={{color:'red'}}>{this.state.error.upc}</div>}
          {!this.state.error.upc && <div style={{opacity:0}}>error</div>}
        </GridItem>}
        {this.state.showSimilarSerials && <SerialsPopup
              open={this.state.showSimilarSerials}
              close={this.hideSimilarSerials}
              similarSerials={this.state.similarSerials}
              startingSerial={this.state.startingSerial}
              productionQty={this.state.productionQty}
              upcCode={this.state.upcCode}
              manufacturerID={this.state.manufacturerID}
              extractNumbers={this.extractNumbers}
          />}
        <GridItem xs={12} sm={3} md={3}>
          <div>
            <CustomInput
              labelText="Production Qty"
              id="productionQty"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: (e) => {
                  //this.handleTextInputChange(e, 'productionQty');
                },
                onClick: () => {
                  if (!this.state.processed) {
                    this.openModal('Production Quantity');
                  }
                },
                onFocus: () => {
                  console.log('af');
                  if (!this.state.processed) {
                    this.openModal('Production Quantity');
                  }
                },
                value: this.state.productionQty,
                autoFocus: false,
                role: 'presentation',
                autoComplete: 'nope',
                disabled: this.state.processed ? true : false
              }}
              error={this.state.error.production ? true : false}
            />
          </div>
        </GridItem>
        <GridItem xs={12} sm={3} md={3}>
        <div>
          <CustomInput
            labelText="Starting Serial #"
            id="serialid"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: (e) => {
                //this.handleTextInputChange(e, 'startingSerial');
              },
              onClick: () => {
                if (!this.state.processed) {
                  this.openModal('Starting Serial #');
                }
              },
              onFocus: () => {
                if (!this.state.processed) {
                  this.openModal('Starting Serial #');
                }
              },
              value: this.state.startingSerial ? this.processSerial(this.state.startingSerial) : '',
              autoFocus: false,
              role: 'presentation',
              autoComplete: 'nope',
              disabled: this.state.processed ? true : false,
              startAdornment: <InputAdornment position="start">{this.state.startingSerial ?
              <span style={{color:'rgb(174,174,174)'}}>{no_prefix_companies.indexOf(this.props.company) === -1 ? this.state.manufacturerID : ''}</span> : ''}</InputAdornment>
            }}
            error={false}
          />
          {this.state.startingSerial && <div>
            <div style={{color:'rgb(174,174,174)',fontSize:9,height:12}}>Manufacturer</div>
            <div style={{color:'rgb(174,174,174)',fontSize:9}}>ID</div>
          </div>}
          {(isWithinSerials && !this.props.noSerialChange) && <div style={{color:'orange'}}>A serial number in the range specified has been previously used. <Button
          color="warning"
          size="sm"
          onClick={() => {
            this.showSimilarSerials();
          }}>View used Serials in Range</Button></div>}
        </div>
        </GridItem>
        
        {/*<div>
          <CustomInput
            labelText="Manufacturer ID"
            id="manid"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: (e) => {
              },
              onClick: () => { },
              value: this.state.manufacturerID,
              autoFocus: false,
              role: 'presentation',
              autoComplete: 'nope',
              disabled: true
            }}
            error={false}
          />
        </div>*/}
        {gs1_prefix_error && <GridItem xs={12} sm={12} md={12}>
          {!this.props.overrideGS1 && <div className="red-text">GS1 API is reporting an error with this UPC</div>}
          {this.props.overrideGS1 && <div className="red-text">GS1 Prefix Length is Required</div>}
          {this.state.canOverrideGS1 && <Button
            color="white"
            style={{marginRight:10}}
            onClick={() => {
              this.props.setOpenOverrideBox(true);
              this.props.setOverrideGS1(true);
            }}
          >OVERRIDE GS1 PREFIX</Button>}
          <Button
            style={{marginRight:10}}
            color="rose"
            onClick={this.clear}>CLEAR</Button>
          </GridItem>
        }
        {((hasResults && !gs1_prefix_error) && (this.state.startingSerial && !this.state.isFinal)) && <GridItem xs={12} sm={12} md={3}>
          <div>
            <CustomInput
              labelText="EPC Code"
              id="epcid"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: (e) => {
                },
                onClick: () => { },
                value: this.props.results[0] ? 
                      this.props.results[0].epcCode ? 
                        this.props.results[0].epcCode === 'ERROR' ?
                          'Please wait...'
                      :
                        this.props.results[0].epcCode
                    : 
                      ''
                  : '',
                autoFocus: false,
                role: 'presentation',
                autoComplete: 'nope',
                disabled: true
              }}
              error={false}
            />
          </div>
        </GridItem>}
        {hasResults && <GridItem xs={12} sm={12} md={3}>
        </GridItem>}
        {hasResults && <GridItem xs={12} sm={12} md={3}>
        </GridItem>}
        {hasResults && <GridItem xs={12} sm={12} md={3}>
        </GridItem>}
        <GridItem xs={12} sm={12} md={12}>
          {(!this.state.isFinal && !gs1_prefix_error) && <div style={{marginTop:20}}>
            <Button
            style={{marginRight:10}}
            disabled={isComplete ? false : true}
            color="success"
            onClick={this.process}>PROCESS</Button>
            {hasResults && <Button
            style={{marginRight:10}}
            color="rose"
            onClick={this.clear}>CLEAR</Button>}
            {(this.state.upcCode && this.state.productionQty) && <Button
                color="warning"
                onClick={() => {
                  this.findAvailableSerialRange();
                }}
              >FIND AVAILABLE SERIAL RANGE</Button>}
          </div>}
          {(hasResults && this.state.isFinal && !gs1_prefix_error) && <div style={{marginTop:20}}>
            <Button
            style={{marginRight:10}}
            color="rose"
            onClick={this.clear}>CLEAR</Button>
            <Button
            style={{marginRight:10}}
            color="success"
            onClick={this.exportToExcel}>GENERATE EXCEL</Button>
          </div>}
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          {errorMessage && <div>
            <div style={{color:'red',textAlign:'center',marginTop:20}}>{errorMessage}</div>
          </div>}

          {this.props.filename === 0 && <div>
            <div style={{color:'red',textAlign:'center',marginTop:20}}>There is an issue generating an Excel file, please try again or contact Tech Support if the issue persists.</div>
          </div>}
        </GridItem>
        {this.props.loading && <GridItem xs={12} sm={12} md={12}>
          <div className="text-center" style={{marginTop:20,marginBottom:20}}>
            <PulseLoader />
            {this.props.percent && <div style={{marginTop:20}}>{Math.round(this.props.percent * 100)} % processing</div>}
          </div>
        </GridItem>}
      </GridContainer></div>}

      {(this.props.results && this.state.isFinal) && <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <h3><strong>Results</strong></h3>
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
        {this.renderTable()}
        </GridItem>
        <GridItem xs={12} sm={6} md={6}></GridItem>
      </GridContainer>}

      {(!showInputs && this.props.prefixes) && <GridContainer>
        <GridItem xs={12} sm={3} md={3}>
          <CustomSelect
            label="Select Manufacturer ID"
            options={this.props.prefixes[this.props.company].map(pf => {
              return {
                prefix: pf.prefix,
                rbo: `${pf.rbo} - ${pf.prefix}`
              }
            })}
            choose={this.handleSelectChange}
            name="manufacturerID"
            indx="prefix"
            desc="rbo"
            default={null}
          />
        </GridItem>
      </GridContainer>}
    </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    prefixes: state.rfid.prefixes,
    serial_combos: state.rfid.serial_combos,
    company_ids: state.rfid.company_ids,
    results: clone(state.rfid.results),
    queryResults: state.rfid.queryResults,
    loading: state.rfid.loading,
    loading2: state.rfid.loading2,
    last_serial: state.rfid.last_serial,
    gs1_prefix: state.rfid.gs1_prefix,
    gs1_prefix_loading: state.rfid.gs1_prefix_loading,
    filename: state.rfid.filename,
    percent: state.rfid.percent,
    noSerialChange: state.rfid.noSerialChange,
    gs1_api_is_working: state.rfid.gs1_api_is_working,
    filenamesData: state.rfid.filenamesData,
    verifyExcelsStatus: state.rfid.verifyExcels,
    verifyUpdatePercentage: state.rfid.verifyUpdatePercentage
  }
}

const mapDispatchToProps = dispatch => {
  return {
    set_gs1_api_is_working: (gs1_api_status) => {
      dispatch(actions.setGS1APIisWorking(gs1_api_status));
    },
    rfidExportEPC: (data) => {
      console.log('exporting rfid');
      dispatch(actions.rfidExportEPC(clone(data)));
    },
    clearData: () => {
      dispatch(actions.rfidClearData());
    },
    loadGS1CompanyPrefix: (gtin14, length, forceError) => {
      dispatch(actions.loadGS1CompanyPrefix(gtin14, length, forceError));
    },
    generateRFIDExcel: (data) => {
      dispatch(actions.generateRFIDExcel(data));
    },
    checkGS1API: (forceError) => {
      dispatch(actions.checkGS1API(forceError));
    },
    verifyRFIDExcel: (filesArr) => {
      dispatch(actions.verifyRFIDExcel(filesArr));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExportEPC);
