import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import * as actions from '../../store/actions';
import DialogTitle2 from '@material-ui/core/DialogTitle';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Table from "components/Table/Table.jsx";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import Button2 from "components/CustomButtons/Button.jsx";
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Typography from '@material-ui/core/Typography';
import { ClipLoader } from 'react-spinners';
import Fade from '@material-ui/core/Fade';
import { debounce } from 'lodash';
import GINradio from "./GINradio";
import GINreviewShort from "./GINreview-short";
import SweetAlert from "react-bootstrap-sweetalert";
import { portal_charvalidations } from 'shared/validation';
import activeDBs from "shared/activeDBs";

const Popup = props => {
  return (
    <Dialog
      open={props.open}
      keepMounted
      onClose={props.close}
      fullWidth={true}
      maxWidth="false"
    >
      <DialogTitle2>
        <div className="text-center">
          <span style={{marginRight:20}}><strong>GIN: </strong>{props.ginNum}</span>
          <span><strong>TPP: </strong>{props.gmp_checkbox}</span></div>
        <div className="close-btn">
          <IconButton aria-label="Close" onClick={props.close}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle2>
      <div style={{padding:10}}>
        {props.content}
      </div>
    </Dialog>
  );
}

const DBlabel = props => {
  // props.name
  let backgroundColor = '';
  activeDBs.forEach((db) => {
    if (db.name === props.name) {
      backgroundColor = db.color;
    }
  });

  if (backgroundColor) {
    return (
      <span style={{backgroundColor,marginRight:5}} className="db-pill">
        {props.name.split('_')[0]}
      </span>
    );
  }
  return null;
}

const DialogTitle = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500],
  },
}))(props => {
  const { classes, onClose, selectedGIN, onSelectGIN, isPending, loading, lockedItems } = props;
  const cannot = lockedItems.length > 0 ? 'CANNOT' : '';
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <span style={{fontSize:'18px',marginRight:10}}>Search Existing Global Item Number</span>
    <span>
      <Button2 style={{float:'right',marginRight:40}} color="warning" onClick={() => {
        console.log('props --', props);
        props.openAutoSave();
      }}>LOAD SESSION</Button2>
      {selectedGIN && <Button2
            disabled={(loading || cannot) || isPending ? true : false}
            color={(loading || cannot) || isPending ? 'white' : 'rose'}
            onClick={onSelectGIN}
          >{loading ? 'Searching...' : `${cannot} LOAD GIN# ${selectedGIN}`}</Button2>}</span>
      {isPending && <div style={{color:'red',margin:20}}>You cannot select {selectedGIN}, it is pending approval</div>}
      {onClose ? (
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit,
  },
}))(MuiDialogActions);

// Load Existing GINs
class GINEditModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchField: 'gin',
      gincode: '',
      itemcode: '',
      loadingHover: false,
      selectedGIN: '',
      loadedGIN: '',
      clickedItem: '',
      newItem: false,
      showAddBtn: true,
      alertMsg: '',
      errors: '',
      showPreview: false,
      DBfilter: [],
    }

    this.searchAPIDebounced = debounce(this.searchAPIDebounced, 500);
  }

  closePreview = () => {
    this.setState({showPreview:false})
  }

  hideAlert = () => {
    this.setState({
      alertMsg: ''
    })
  }

  checkIfExists = () => {
    if (this.state.itemcode) {
      if (this.props.itemList) {
        if (this.props.itemList.length === 1) {
          const itemcode = this.props.itemList[0].label.split(' | ')[0];
          const itemc = itemcode.split('_')[0]
          if (itemc === this.state.itemcode) {
            return true;
          }
        } else if (this.props.itemList.length > 1) {
          let itemsObj = {};
          this.props.itemList.forEach(item => {
            const itemcode = item.label.split(' | ')[0];
            const itemc = itemcode.split('_')[0];
            itemsObj[itemc] = 1;
            console.log('check item',itemcode, itemc);
          });
          if (itemsObj.hasOwnProperty(this.state.itemcode)) {
            return true;
          }
        }
      }
    }
  }

  checkLength = () => {
    if (this.state.itemcode) {
      if (this.state.itemcode.length > 15) {
        this.setState((prevState) => ({
          errors: prevState.errors + 'Maximum character length is 15. '
        }));
      }
    }
  }

  checkChars = () => {
    if (this.state.itemcode) {
      const forTest = portal_charvalidations.aCharms.split('');
      const valueArr = this.state.itemcode.split('');
      let hasChar = false;
      forTest.forEach((tchar) => {
        valueArr.forEach((vchar) => {
          if (tchar === vchar) {
            hasChar = true;
          }
        });
      });
      if (hasChar) {
        this.setState((prevState) => ({
          errors: prevState.errors + 'Characters '+portal_charvalidations.aCharms+' are not allowed. '
        }));
      }
    }
  }

  checkValidations = () => {
    this.checkLength();
    this.checkChars();
  }

  handleClose = (db) => {
    this.props.close();
  };

  handleSubmit = e => {
    e.preventDefault();
    const val = e.target.value.toUpperCase();
    this.setState({ itemcode: val });
    if (val.length > 1 && !this.state.errors) {
      this.searchAPIDebounced(val);
    }
  }

  searchAPIDebounced = (val) => {
    this.props.fetchGINlist(val, this.state.searchField);
  }

  hideButton = () => {
    this.setState({
      showAddBtn: false
    });
  }

  generateRandom = () => {
    return Math.floor(Math.random() * 200) + 1;
  }

  loadGIN = (selectedGIN) => {
    const dat = this.props.ginExistingData.ginObj;
    this.props.setGINNumEdit(selectedGIN);
    this.props.loadExistingGINImage(`https://portal.natcoglobal.com/portal/api/item/ftp_gin_image.php?gin=${selectedGIN}`);
    this.props.saveChosenGINDetails(dat[selectedGIN]);
    this.props.loadExisting(dat[selectedGIN], this.props.rbos);
    this.props.close();
  }

  handleGINSelect = (selectedGIN) => {
    if (this.state.selectedGIN === selectedGIN) {
      console.log('ginselect 1')
      this.openPreview();
    } else {
      console.log('ginselect 2', this.props, this.state);
      this.setState({selectedGIN});
      // collect all DB, itemcode
      let arr = [];
      if (this.props.ginExistingData) {
        if (this.props.ginExistingData.ginObj) {
          const ginObj = this.props.ginExistingData.ginObj;
          if (ginObj[selectedGIN]) {
            ginObj[selectedGIN].forEach((o) => {
              const split = o.split('[.]');
              if (split[1].indexOf('_') === -1) {
                const db = split[0];
                const itemcode = split[1];
                arr.push({db,itemcode});
              }
            });
          }
        }
      }
      this.props.checkItemsLock(arr);
    }
  }

  onFieldNameChange = e => {
    //const val = e.target.value.toUpperCase();
    const val = e.target.value || e.target.value === 0 ? e.target.value.replace(/[^0-9a-zA-Z-\\\/]/g, "").toUpperCase() : '';
    this.setState({
      [this.state.searchField === 'gin' ? 'gincode' : 'itemcode']: val,
      errors: ''
    }, () => {
      this.checkValidations();
    });
    if (e.target.value.length > 1) {
      this.setState({selectedGIN:''})
      this.searchAPIDebounced(val);
    }
  }

  openPreview = () => {
    const selectedGIN = this.state.selectedGIN;
    const dat = this.props.ginExistingData.ginObj;
    console.log('collection loading preview data', this.props.rbos);
    if (this.state.loadedGIN !== this.state.selectedGIN) {
      this.props.loadPreviewData(dat[selectedGIN], this.props.rbos);
    }
    this.setState({
      showPreview: true,
      loadedGIN: this.state.selectedGIN
    });
  }

  handleRadioChange = (e, value, db) => {
    this.setState({
      searchField: value,
      [this.state.searchField === 'gin' ? 'itemcode' : 'gincode']: ''
    })
    console.log(e, value, db)
    if (e === 'gin') {
      this.restoreAllFilter();
    }
  }

  restoreAllFilter = () => {
    this.setState({
      DBfilter: this.state.DBfilter.map(db => db.name)
    })
  }

  toggleFilter = (db) => {
    if (this.state.DBfilter.indexOf(db) === -1) {
      this.setState({
        DBfilter: this.state.DBfilter.concat([db])
      })
    } else {
      this.setState({
        DBfilter: this.state.DBfilter.filter(d => d !== db)
      })
    }
  }

  renderDBfilters = () => {
    const filters = activeDBs.map(db => {
      let opacity = 0.3;
      if (this.state.DBfilter.indexOf(db.name) !== -1) {
        opacity = 1;
      }
      return <span style={{opacity,display:'inline-block',marginBottom:5}} onClick={() => this.toggleFilter(db.name)} key={`d-${db.name}`} className="hovered"><DBlabel name={db.name} /></span>
    })
    return <div>
    <div style={{marginBottom:10}}>Filter by DB:</div>
    {filters}
    </div>;
  }

  checkPending = (gin) => {
    if (this.props.pendingData) {
      if (this.props.pendingData.length > 0) {
        let found = false;
        this.props.pendingData.forEach(p => {
          if (p.gin === gin) {
            found = true;
          }
        })
        if (found) return true;
        return false;
      }
    }
  }

  componentDidMount() {
    console.log(this.textInput);
    this.props.loadPending();
    this.setState({
      newItem: false,
      showAddBtn: true,
      DBfilter: activeDBs.map(db => db.name)
    });
  }

  render() {
    console.log('the ginedit props', this.props);
    let list = null;
    let coloredSelect = null;
    let itemList = null;

    let removeVariantless = [];
    let isPending = false;
    if (this.state.selectedGIN) {
      isPending = this.checkPending(this.state.selectedGIN)
    }

    let lockedItems = [];
    let lockedMsg = <div></div>;
    if (this.props.lockedElementsData) {
      if (this.props.lockedElementsData.length > 0) {
        this.props.lockedElementsData.forEach((led) => {
          if (led.status === 'LOCKED') {
            lockedItems.push({db:led.db,itemcode:led.itemcode,name:led.name});
          }
        });
        lockedMsg = this.props.lockedElementsData
          .filter((led) => {
            if (led.name) return true;
          })
          .map((led, i) => {
            return (
              <div key={`led${i}`} style={{color:'red'}}>{led.db} {led.itemcode} is locked by {led.name}</div>
            );
          })
      }
    }
    console.log('locked items', lockedItems);

    if (this.props.ginExistingData) {
      const dat = this.props.ginExistingData.ginObj;
      if (this.state.selectedGIN) {
        if (dat[this.state.selectedGIN]) {
          if (dat[this.state.selectedGIN].length > 0) {
            let collection = {};
            dat[this.state.selectedGIN].forEach((sg,key) => {
              const arr = sg.split('[.]');
              const dbname = arr[0],
                    itemcode = arr[1];
              if (!collection.hasOwnProperty(dbname)) {
                collection[dbname] = [itemcode];
              } else {
                collection[dbname].push(itemcode);
              }
            })

            itemList = Object.keys(collection).map((dbname,key) => {
              const itemcode = collection[dbname].map((d,i) => {
                return <div key={`${dbname}-${d}-${i}`}>{d}</div>
              })
              return [<DBlabel name={dbname} />, <div>{itemcode}</div>];
            })
          }
        }
      }
    }

    if (this.props.ginExistingData && (this.state.searchField === 'gin' && this.state.gincode)) {
      const dat = this.props.ginExistingData.ginObj;
      console.log('dat',dat);
      if (dat) {
        const datList = Object.keys(dat);
        if (datList.length > 0) {
          list = datList.map((ginCode, key) => {
            if (this.state.selectedGIN) {
              if (ginCode == this.state.selectedGIN) {
                coloredSelect = {backgroundColor:'gray',color:'white',fontSize:'12px !important'};
              } else {
                coloredSelect = {fontSize:'12px !important'};
              }
            }
            return (
              <ListItem
                key={'key'+key}
                button
                onClick={event => this.handleGINSelect(ginCode)}
                style={coloredSelect}
                >
                <div><strong>{ginCode}</strong></div><br />
              </ListItem>
            )
          });
        }
      }
    }

    if (this.props.ginExistingData && (this.state.searchField !== 'gin' && this.state.itemcode)) {
      const dat = this.props.ginExistingData.ginObj;
      console.log('dat',dat);
      if (dat) {
        const datList = Object.keys(dat);
        if (datList.length > 0) {
          let finalList = [];
          datList.forEach((ginCode, key) => {
            if (dat[ginCode]) {
              if (dat[ginCode].length > 0) {
                dat[ginCode].forEach((dg,i) => {
                  if (dat[ginCode][i]) {
                    const comb = dat[ginCode][i].split('[.]')[1];
                    const dbb = dat[ginCode][i].split('[.]')[0];
                    const itemCode = comb.indexOf('_') === -1 ? comb : comb.split('_')[0];
                    const combo = `${ginCode}+++${itemCode}`;
                    if (finalList.indexOf(combo) === -1 && itemCode.indexOf(this.state.itemcode) !== -1) {
                      if (this.state.DBfilter.indexOf(dbb) !== -1) {
                        finalList.push(combo);
                      }
                    }
                  }
                })
              }
            }
          });

          list = finalList.map((combo,key) => {
            console.log('combo',combo);
            const arr = combo.split('+++');
            const itemCode = arr[1];
            const ginCode = arr[0];
            if (this.state.selectedGIN) {
              if (ginCode == this.state.selectedGIN) {
                coloredSelect = {backgroundColor:'gray',color:'white',fontSize:'12px !important'};
              } else {
                coloredSelect = {fontSize:'12px !important'};
              }
            }
            return (
              <ListItem
                key={'key'+key}
                button
                onClick={event => this.handleGINSelect(ginCode)}
                style={coloredSelect}
                >
                <div><strong>{itemCode}</strong></div><br />
              </ListItem>
            )
          })
        }
      }
    }

    return (
      <div id="gin-modal">
        <Dialog
          onClose={() => {
            this.props.close();
          }}
          aria-labelledby="customized-dialog-title"
          open={this.props.open}
          fullScreen={true}
          fullWidth={true}
        >
          <DialogTitle id="customized-dialog-title"
            onClose={() => {
              this.props.close();
            }}
            onSelectGIN={() => {
              if (lockedItems.length === 0) {
                this.loadGIN(this.state.selectedGIN);
              }
            }}
            lockedItems={lockedItems}
            closeView={this.props.closeView}
            selectedGIN={this.state.selectedGIN}
            loading={this.props.loading}
            isPending={isPending}
            openAutoSave={this.props.openAutoSave}
          >
          </DialogTitle>
          <div className="item-input-margin" style={{backgroundColor:'#fcfcfc'}}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={1}></GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <p>Search by</p>
                    <GINradio
                      label="Search by,GIN,Item Code"
                      options={['gin','itemcode']}
                      onChange={this.handleRadioChange}
                      value={this.state.searchField}
                    />
                    {this.state.searchField === 'gin' && <CustomInput
                      labelText="Enter GIN"
                      id="item_name"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: this.onFieldNameChange,
                        value: this.state.gincode,
                        autoFocus: true,
                        role: 'presentation',
                        autoComplete: 'nope'
                      }}
                    />}
                    {this.state.searchField === 'itemcode' &&
                  <div>
                    <CustomInput
                      labelText="Enter Item Code"
                      id="item_name"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: this.onFieldNameChange,
                        value: this.state.itemcode,
                        autoFocus: true,
                        role: 'presentation',
                        autoComplete: 'nope'
                      }}
                    />
                    {this.renderDBfilters()}
                  </div>}
                    {this.state.errors && <div className="red-text" style={{marginTop:'10px'}}>{this.state.errors}</div>}
                    <div style={{height:'400px',overflowY:'scroll'}}>
                      <List component="nav">
                        {list}
                      </List>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                  <div>
                    <div style={{textAlign:'center',marginTop:'10px', height:'400px', overflowY:'scroll'}}>
                      {this.props.loading  &&
                        <Card>
                          <div style={{padding:'20px'}}>
                            <div>Loading</div>
                            <div className="text-center" style={{marginTop:20}}><ClipLoader /></div>
                          </div>
                        </Card>
                      }
                      {itemList &&
                        <Card>
                          {lockedItems.length > 0 && <div>
                              {lockedMsg}
                            </div>}
                          <div style={{padding:20}}>
                            {this.props.lockedElementsLoading && <div className="text-center" style={{marginTop:20}}><ClipLoader /></div>}
                            <Table
                              tableHeaderColor="primary"
                              tableHead={["DB", "Item Code"]}
                              tableData={itemList}
                              coloredColls={[3]}
                              colorsColls={["primary"]}
                            />
                          </div>
                        </Card>
                      }
                      {/* Preview */}
                    </div>
                    {itemList && <div style={{padding:20}} className="text-center">
                      {this.props.loadPreview && <div className="text-center" style={{marginTop:10,marginBottom:10}}>
                        <ClipLoader />
                        <div>Loading preview data...</div>
                      </div>}
                      {!this.props.loadPreview && <Button2 color="rose"
                        onClick={() => {
                          this.openPreview();
                        }}
                      >
                        OPEN PREVIEW
                      </Button2>}
                    </div>}
                  </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={1}></GridItem>
                </GridContainer>
          </div>
          <DialogActions>
          {(this.state.showPreview && this.props.previewData) && <Popup
            open={this.state.showPreview}
            close={this.closePreview}
            content={<GINreviewShort
                isPreview={true}
                previewData={this.props.previewData}
                imageURL={`https://portal.natcoglobal.com/portal/api/item/ftp_gin_image.php?gin=${this.state.selectedGIN}`}
                ginNum={this.state.selectedGIN}
                loadGIN={() => { this.loadGIN(this.state.selectedGIN); }}
                close={this.closePreview}
                isPending={isPending}
                lockedElementsData={this.props.lockedElementsData}
              />}
            ginNum={this.state.selectedGIN}
            gmp_checkbox={this.props.previewData.gmp}
          />}
            <Button color="white" size="lg" onClick={() => this.props.close()}>CLOSE</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pendingData: state.gin.pendingData,
    openedDBs: state.gin.openedDBs,
    loading: state.gin.ginExistingLoad,
    ginExistingData: state.gin.ginExistingData,
    loadPreview: state.gin.loadPreview,
    previewData: state.gin.loadPreviewData,
    rbos: state.gmp.rbos ? state.gmp.rbos.map(rbo => {
      return {
        FirmCode: rbo.id,
        FirmName: rbo.name
      }
    }) : state.gmp.rbos,
    lockedElementsLoading: state.lockedElements.lockedElementsLoading,
    lockedElementsData: state.lockedElements.lockedElementsData,
    lockedMsg: state.lockedElements.lockedMsg
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchGINlist: (term, searchField) => {
      console.log('term search', term);
      let gincode = '_', itemcode = '_';
      if (searchField === 'gin') {
        gincode = term;
      } else {
        itemcode = term;
      }
      dispatch(actions.ginGetExisting(gincode, itemcode))
    },
    loadExisting: (data, rbos) => {
      dispatch(actions.ginLoadExisting(data, rbos))
    },
    loadExistingGINImage: (imageURL) => {
      dispatch(actions.loadExistingImage(imageURL));
    },
    loadPreviewData: (data, rbos) => {
      dispatch(actions.loadPreviewData(data, rbos));
    },
    checkPending: (gin) => {
      dispatch(actions.ginCheckPending(gin));
    },
    loadPending: () => {
      dispatch(actions.ginLoadPending());
    },
    saveChosenGINDetails: (details) => {
      dispatch(actions.saveChosenGINDetails(details));
    },
    checkItemsLock: (arr) => {
      dispatch(actions.checkItemsLock(arr));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GINEditModal);
