import { clone } from 'shared/utility';

// CONSTANTS
const tableObj = {
  gs1: [
    {
      bits: 40,
      digits: 12,
      partition_val: 0,
      item_ref_digits: 1,
      item_ref_bits: 4,
    },
    {
      bits: 37,
      digits: 11,
      partition_val: 1,
      item_ref_digits: 2,
      item_ref_bits: 7
    },
    {
      bits: 34,
      digits: 10,
      partition_val: 2,
      item_ref_digits: 3,
      item_ref_bits: 10
    },
    {
      bits: 30,
      digits: 9,
      partition_val: 3,
      item_ref_digits: 4,
      item_ref_bits: 14
    },
    {
      bits: 27,
      digits: 8,
      partition_val: 4,
      item_ref_digits: 5,
      item_ref_bits: 17
    },
    {
      bits: 24,
      digits: 7,
      partition_val: 5,
      item_ref_digits: 6,
      item_ref_bits: 20
    },
    {
      bits: 20,
      digits: 6,
      partition_val: 6,
      item_ref_digits: 7,
      item_ref_bits: 24
    },
  ],
  indicator: [
    {
      bits: 4,
      digits: 1,
      partition_val: 0
    },
    {
      bits: 7,
      digits: 2,
      partition_val: 1
    },
    {
      bits: 10,
      digits: 3,
      partition_val: 2
    },
    {
      bits: 14,
      digits: 4,
      partition_val: 3
    },
    {
      bits: 17,
      digits: 5,
      partition_val: 4
    },
    {
      bits: 20,
      digits: 6,
      partition_val: 5
    },
    {
      bits: 24,
      digits: 7,
      partition_val: 6
    },
  ],
};

const hexDict = {
  '0': '0000',
  '1': '0001',
  '2': '0010',
  '3': '0011',
  '4': '0100',
  '5': '0101',
  '6': '0110',
  '7': '0111',
  '8': '1000',
  '9': '1001',
  'A': '1010',
  'B': '1011',
  'C': '1100',
  'D': '1101',
  'E': '1110',
  'F': '1111'
}

const lookupBits = (bits) => {
  let partitionVal = 0;
  tableObj.gs1.forEach(g => {
    if (bits === g.bits) {
      partitionVal = g.partition_val;
    }
  });
  return partitionVal;
}

const lookupBits2 = (bits) => {
  let partitionVal = 0;
  tableObj.gs1.forEach(g => {
    if (bits === g.item_ref_bits) {
      partitionVal = g.partition_val;
    }
  });
  return partitionVal;
}

const lookupPartition = (partition_val) => {
  let bits = 0;
  tableObj.gs1.forEach(g => {
    if (parseInt(partition_val) === g.partition_val) {
      bits = g.bits;
    }
  });
  return bits;
}

// HELPER FUNCTIONS
export const decConvert = (int, type) => {
  let str = int.toString(),
      num = type ? type : 9;
  if (str.length < num) {
    const zeroes = num - str.length;
    let add = '';
    for (let i = 0; i < zeroes; i++) {
      add += '0';
    }
    str = add + str;
  }
  return str;
}

const removePrefix = (num) => {
  if (num) {
    num = num.toString();
    return num.substring(3,num.length);
  }
}

const dec2bin = (number, length) => {
  let converted = [];
  while(number>=1) {
    converted.unshift(number%2);
    number = Math.floor(number/2);
  }
  if (converted.length < length) {
    const diff = length - converted.length;
    for (let i = 0; i < diff; i++) {
      converted.unshift('0');
    }
  }
  return converted.join('');
}

const bin2hex = (number) => {
  return parseInt(number, 2).toString(16).toUpperCase();
}

const hex2bin = (hex) =>
{
    var bytes = [], str;

    for(var i=0; i< hex.length-1; i+=2)
        bytes.push(parseInt(hex.substr(i, 2), 16));

    return String.fromCharCode.apply(String, bytes);
}

const convert2bin = (arr) => {
  var str = '';
  arr.forEach(function(a) {
    str += bin2hex(a);
  })
  return str;
}

const getPartitionVal = (sg1_co_prefix_length) => {
  var result = '';
  tableObj.gs1.forEach(function(obj) {
    if (obj.digits == sg1_co_prefix_length) {
      result = obj.partition_val;
    }
  })
  return result;
}

const getDEC = (last, bits, totalLength) => {
  var rows = [], decs = [];
  var reversed = last.split('').reverse();
  for (let i = 1; i < bits; i++) {
    var power = Math.pow(2,i-1);
    rows.push(power);
    decs.push(power*reversed[i-1]);
  }
  var total = 0;
  for (var i = 0; i < decs.length; i++) {
    total += decs[i];
  }
  var totalString = total.toString().split('');
  if (totalLength) {
    if (totalLength !== totalString.length) {
      var zeroes = totalLength - totalString.length;
      for (var i = 0; i < zeroes; i++) {
        totalString.unshift('0');
      }
    }
  }
  return totalString.join('');
}

const getBIN = (last, bits) => {
  var rows = [], remainders = [], bins = [];
  for (let i = 1; i < bits; i++) {
    var remainder = (last % 2);
    last = ~~(last/2);
    rows.push(Math.abs(last));
    remainders.unshift(remainder);
    bins.push(remainders.join(''));
  }
  var lookupIndex = -1;
  for (var i = 0; i < rows.length; i++) {
    if (rows[i] == 0) {
      lookupIndex = i+1;
      break;
    }
  }
  var theBin = bins[lookupIndex-1];
  var remainingZeroes = bits-theBin.length;
  var added = '';
  for (var i = 0; i < remainingZeroes; i++) {
    added += '0';
  }
  var finalBin = added + theBin;
  return finalBin;
}

const divideBy = (str, n) => {
  let chars = [];
  if (str.length % n != 0) {
    return null;
  }
  var part_size = str.length / n;
  for (var i = 0; i < str.length+1; i++) {
    if (i % part_size == 0) {
      if (i !== 0) {
        chars.push(str[i-4]+str[i-3]+str[i-2]+str[i-1]);
      }
    }
  }
  return clone(chars);
}

const calculateEPC = (starting_serial, 
    epc_header_bin, 
    filter_val_bin, 
    partition_val_bin, 
    co_prefix6_bin, 
    item_ref_bin
  ) => {
  var serial_bin = dec2bin(starting_serial, 38);

  var fullBIN = epc_header_bin + filter_val_bin + 
                partition_val_bin + co_prefix6_bin + 
                item_ref_bin + serial_bin;
  var divided = divideBy(fullBIN, fullBIN.length / 4);
  var final = convert2bin(clone(divided));
  return final;
}

const calculateEPC2 = (starting_serial, epc_header_bin, filter_val_bin, partition_val_bin, co_prefix6_bin, item_ref_bin) => {
  var serial_bin = dec2bin(starting_serial, 38);
  var fullBIN = epc_header_bin + filter_val_bin + partition_val_bin + co_prefix6_bin
                + item_ref_bin + serial_bin;

  var divided = divideBy(fullBIN, fullBIN.length / 4);
  return convert2bin(divided);
}

const calculateEPC3 = (starting_serial, epc_header_bin, filter_val_bin, partition_val_bin, co_prefix6_bin, item_ref_bin) => {
  var serial_bin = dec2bin(starting_serial, 38);
  var fullBIN = epc_header_bin + filter_val_bin + partition_val_bin + co_prefix6_bin
                + item_ref_bin + serial_bin;

  var divided = divideBy(fullBIN, fullBIN.length / 4);
  return convert2bin(divided);
}

const calculateUPC = (hexArr, co_prefix) => {
  var fullBIN = hexArr.join('');
  var epc_header_bin = fullBIN.substring(0,8);
  var epc_header = parseInt(epc_header_bin, 2);

  var filter_val_bin = fullBIN.substring(8,11);
  var filter_val = parseInt(filter_val_bin, 2);

  var partition_val_bin = fullBIN.substring(11,14);
  var partition_val = parseInt(filter_val_bin, 2);

  var co_prefix6_bin = fullBIN.substring(14,38);
  var co_prefix6 = getDEC(co_prefix6_bin, 24);

  var item_ref_bin = fullBIN.substring(38,58);
  var item_ref = getDEC(item_ref_bin, 20, 6);

  var chkEven = [2,4,6,8,10];
  var chkOdd = [1,3,5,7,9,11];
  var combined = co_prefix6 + item_ref;
  var arrsEven = [], evenTotal = 0, arrsOdd = [], oddTotal = 0;
  chkEven.forEach(function(a,i) {
    var num = parseInt(combined.substring(a-1,a));
    arrsEven.push(num);
    evenTotal += num;
  })
  chkOdd.forEach(function(a,i) {
    var num = parseInt(combined.substring(a-1,a));
    arrsOdd.push(num);
    oddTotal += num;
  })
  var tot = (oddTotal*3) + evenTotal;
  var mod = tot % 10;
  var chkdgt = 0;
  if (mod !== 0) {
    chkdgt = 10 - mod;
  }

  var pkg_prefix_bin = fullBIN.substring(58,66);
  var prefixy = parseInt(pkg_prefix_bin, 2);

  var serial_bin = fullBIN.substring(66,96);
  var serial = getDEC(serial_bin, 24, 9);
  var serial2 = parseInt(serial_bin, 2);

  var fullUPC = co_prefix6 + item_ref;
  return {
    fullUPC,
    serial,
    prefix: prefixy,
    serial2
  };
}

const calculateUPC2 = (hexArr) => {
  var fullBIN = hexArr.join('');
  var epc_header_bin = fullBIN.substring(0,8);
  var epc_header = parseInt(epc_header_bin, 2);

  var filter_val_bin = fullBIN.substring(8,11);
  var filter_val = parseInt(filter_val_bin, 2);

  var partition_val_bin = fullBIN.substring(11,14);
  var partition_val = parseInt(filter_val_bin, 2);

  var co_prefix6_bin = fullBIN.substring(14,38);
  var co_prefix6 = getDEC(co_prefix6_bin, 24);

  var item_ref_bin = fullBIN.substring(38,58);
  var item_ref = getDEC(item_ref_bin, 20, 5);

  var chkEven = [2,4,6,8,10];
  var chkOdd = [1,3,5,7,9,11];
  var combined = co_prefix6 + item_ref;
  var arrsEven = [], evenTotal = 0, arrsOdd = [], oddTotal = 0;
  chkEven.forEach(function(a,i) {
    var num = parseInt(combined.substring(a-1,a));
    arrsEven.push(num);
    evenTotal += num;
  })
  chkOdd.forEach(function(a,i) {
    var num = parseInt(combined.substring(a-1,a));
    arrsOdd.push(num);
    oddTotal += num;
  })
  var tot = (oddTotal*3) + evenTotal;
  var mod = tot % 10;
  var chkdgt = 0;
  if (mod !== 0) {
    chkdgt = 10 - mod;
  }

  var serial_bin = fullBIN.substring(58,96);
  var serial = getDEC(serial_bin, 24, 12);
  var serial2 = parseInt(serial_bin, 2);
  var fullUPC = co_prefix6 + item_ref + chkdgt;
  return {
    fullUPC,
    serial,
    prefix: '',
    serial2
  };
}

function bin2decc(num){
  return num.split('').reverse().reduce(function(x, y, i){
    return (y === '1') ? x + Math.pow(2, i) : x;
  }, 0);
}

function binaryConverter(str) {
        var num=str.split("");
        var powers=[];
        var sum=0;
        var numlength=num.length;

        for(var i=0;i<num.length;i++){
            powers.push(i);
        }

        for(var i=powers.length-1;i>=0;i--){

            sum+=Math.pow(2,i)*num[numlength-i-1];

        }
        return sum;
   };

const calculateUPC3 = (hexArr, co_prefix6) => {
  var fullBIN = hexArr.join('');
  var epc_header_bin = fullBIN.substring(0,8);
  var epc_header = parseInt(epc_header_bin, 2);

  var filter_val_bin = fullBIN.substring(8,11);
  var filter_val = parseInt(filter_val_bin, 2);

  var partition_val_bin = fullBIN.substring(11,14);
  var partition_val = parseInt(decConvert(bin2decc(partition_val_bin), 3));

  const maxCo = lookupPartition(partition_val);
  const maxItem = 44 - maxCo;
  var co_prefix6_bin = fullBIN.substring(14,14+maxCo);
  if (!co_prefix6) {
    var co_prefix6 = decConvert(bin2decc(co_prefix6_bin), lookupBits2(co_prefix6_bin.length));
    if (co_prefix6.length < 7) {
      co_prefix6 = decConvert(bin2decc(co_prefix6_bin), 7);
    }
  }

  var item_ref_bin = fullBIN.substring(14+maxCo,(14+maxCo)+maxItem);
  var item_ref = decConvert(binaryConverter(item_ref_bin), lookupBits2(item_ref_bin.length));

  var combined = co_prefix6 + item_ref;
  let chkdgt = eanCheckDigit(combined);

  var serial_bin = fullBIN.substring(58,96);
  var serial = getDEC(serial_bin, 24, 12);
  var serial2 = parseInt(serial_bin, 2);

  var fullUPC = co_prefix6 + item_ref + chkdgt;
  if (fullUPC[0] === '0' && fullUPC.length === 13) {
    fullUPC = fullUPC.substring(1,fullUPC.length);
  }
  return {
    fullUPC,
    serial,
    prefix: '',
    serial2
  };
}

const sleep = ms => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

const loop = (starting_serial, epc_header_bin, filter_val_bin, partition_val_bin, gs1_prefix_bin, gs1_item_reference_bin, num) => {
  return new Promise(resolve => {
    let arr = []
    for (let i = 0; i < num; i++) {
      starting_serial++;
      let final_epc = calculateEPC2(starting_serial, epc_header_bin, filter_val_bin, partition_val_bin, gs1_prefix_bin, gs1_item_reference_bin);
      arr.push({
        epcCode: final_epc,
        serialNum: decConvert(starting_serial)
      });

      if (i === num - 1) {
        resolve(arr);
      }
    }
  });
}

const isUPCEqual = (u1, u2) => {
  const upc1 = parseInt(u1),
        upc2 = parseInt(u2);
  console.log('comparing --', u1, u2, upc1, upc2, upc1 === upc2, u1 === u2);
  return upc1 === upc2;
}

export const logic = {
  retrieveGS1Prefix: (inputs) => {
    // {
    //    gtin14: <required>
    //    length: <required>
    // }
    let gtin14 = inputs.gtin14;
    if (gtin14.length !== 14) {
      gtin14 = decConvert(gtin14, 14);
    }
    const gs1_prefix_length = parseInt(inputs.length);
    const gs1_other = 14 - gs1_prefix_length - 2;

    const gs1_item_reference = '0' + gtin14.substring(gs1_prefix_length+1,
        (gs1_prefix_length+1) + gs1_other);
    
    const check = gs1_item_reference.substr(gs1_item_reference.length-(gs1_item_reference.length-1)) + gtin14.substr(gtin14.length-1);
    let sliced = gtin14.slice(0, -(check.length));
    const gs1_prefix = sliced.slice(1);
    console.log('calculating gs1prefix - gs1_item_reference', gs1_item_reference, gtin14, inputs, check, gs1_prefix);
    return gs1_prefix;
  },
  UPCtoEPC: (inputs) => {
    console.log('inputs', inputs);
    if (inputs.company_id === 4) {
        const upc_code = inputs.upcCode;
        const header = '100';
        const filter = '1';
        const partition = '5';
        const co_prefix = upc_code.substr(0, 7);
        const item_ref = upc_code.substr(7, 6);
        console.log('co_prefix', co_prefix);
        console.log('item_ref', item_ref);

        const header_bin = dec2bin(header, 8);
        const filter_bin = dec2bin(filter, 3);
        const partition_bin = dec2bin(partition, 3);
        const co_prefix_bin = dec2bin(co_prefix, 24);
        const item_ref_bin = dec2bin(item_ref, 20);

        // SERIAL NUMBER
        let starting_serial = inputs.serial ? parseInt(inputs.serial) : 25000000000;
        let epc_list = [];

        let isGood = true;

        const num = inputs.productionQty;
        for (let i = 0; i < num; i++) {
          const final_epc = calculateEPC(starting_serial, 
            header_bin, 
            filter_bin, 
            partition_bin, 
            co_prefix_bin, 
            item_ref_bin,
            null,
            4
          );
          console.log('final epc', final_epc, starting_serial)
          const checkUPC = true; //isUPCEqual(calc.fullUPC, inputs.upcCode);
          epc_list.push({
            upc_code,
            // upcCode: calculateUPC(bins),
            epcCode: isGood ? checkUPC ? final_epc : 'UPC ERROR' : 'ERROR',
            epcCode2: final_epc,
            serialNum: decConvert(starting_serial),
            doublecheck: null,
            // calculateUPC: calc.fullUPC
          });
          starting_serial++;
        }
        console.log('this is the epc list', epc_list);
        return epc_list;
    } else if (inputs.rfidType === 'C') {
      // NO MANUFACTURER ID
      if (inputs.company_id === 1) {
        // Allbirds
        if (inputs.serial === 0) {
          // 10 000 000 001
          inputs.serial = 10000000000;
        }
      }
      if (inputs.company_id === 3) {
        // JCPenny
        if (inputs.serial === 0) {
          // 25 000 000 000
          inputs.serial = 25000000000;
        }
      }
      const upc_code = inputs.upcCode;
      // GS1 Company Prefix
      const gs1_prefix = inputs.gs1_prefix ? inputs.gs1_prefix.prefix : '',
          gtin14 = inputs.gs1_prefix ? inputs.gs1_prefix.gtin14 : '',
          gs1_prefix_length = gs1_prefix.length,
          gs1_other = 14 - gs1_prefix_length - 2;

      const gs1_item_reference = '0' + gtin14.substring(gs1_prefix_length+1,
        (gs1_prefix_length+1) + gs1_other);

      console.log('gs1', gs1_item_reference);

      const check_digit = gtin14.substring(gtin14.length-1,gtin14.length);

      const sgtin96_header = 48,
            sgtin96_filter = 1;

      const sgtin96_partition = getPartitionVal(gs1_prefix_length);

      const epc_header_bin = dec2bin(sgtin96_header, 8);

      const filter_val_bin = dec2bin(sgtin96_filter, 3);

      const partition_val_bin = dec2bin(sgtin96_partition, 3);

      const gs1_prefix_bin = dec2bin(gs1_prefix, lookupPartition(sgtin96_partition));

      const gs1_item_reference_bin = dec2bin(gs1_item_reference, 44-gs1_prefix_bin.length);

      const doublecheck = '0' + gs1_prefix 
        + gs1_item_reference.substr(gs1_item_reference.length-(gs1_item_reference.length-1)) 
        + gtin14.substr(gtin14.length-1);

      let isGood = true;
      if (doublecheck !== gtin14) {
        isGood = false;
      }
      console.log('isGood', isGood, gtin14, doublecheck)
      // SERIAL NUMBER
      let starting_serial = inputs.serial,
          epc_list = [],
          num = inputs.productionQty;
      for (let i = 0; i < num; i++) {
        let final_epc = calculateEPC2(starting_serial, epc_header_bin, filter_val_bin, partition_val_bin, gs1_prefix_bin, gs1_item_reference_bin);
        var epc = final_epc;
        var chars = epc.split('');
        var bins = chars.map(function(ch) {
          return hexDict[ch];
        });
        var calc = calculateUPC3(bins, gs1_prefix);
        console.log('calc', calc.fullUPC, inputs.upcCode)
        const checkUPC = isUPCEqual(calc.fullUPC, inputs.upcCode);
        epc_list.push({
          epcCode: isGood ? 
            checkUPC ? 
              final_epc 
            : 
              'UPC ERROR' 
          : 
            'ERROR',
          epcCode2: final_epc,
          serialNum: decConvert(starting_serial),
          doublecheck,
          gtin14
        });
        starting_serial++;
      }
      console.log('this is the epc list', epc_list);
      return epc_list;
    } else if (inputs.rfidType === 'B') {
      // WALMART
      const upc_code = inputs.upcCode;
      // GS1 Company Prefix
      const gs1_prefix = inputs.gs1_prefix ? inputs.gs1_prefix.prefix : '',
          gtin14 = inputs.gs1_prefix ? inputs.gs1_prefix.gtin14 : '',
          gs1_prefix_length = gs1_prefix.length,
          gs1_other = 14 - gs1_prefix_length - 2;

      const gs1_item_reference = '0' + gtin14.substring(gs1_prefix_length+1,
        (gs1_prefix_length+1) + gs1_other);
      
      console.log('reference', gs1_item_reference, gs1_prefix);

      const check_digit = gtin14.substring(gtin14.length-1,gtin14.length);

      const sgtin96_header = 48,
            sgtin96_filter = 1;

      const sgtin96_partition = getPartitionVal(gs1_prefix_length);

      const epc_header_bin = dec2bin(sgtin96_header, 8);

      const filter_val_bin = dec2bin(sgtin96_filter, 3);

      const partition_val_bin = dec2bin(sgtin96_partition, 3);

      const gs1_prefix_bin = dec2bin(gs1_prefix, lookupPartition(sgtin96_partition));

      const gs1_item_reference_bin = dec2bin(gs1_item_reference, 44-gs1_prefix_bin.length);

      const doublecheck = '0' + gs1_prefix + gs1_item_reference.substr(gs1_item_reference.length-(gs1_item_reference.length-1)) + gtin14.substr(gtin14.length-1);

      let isGood = true;
      if (doublecheck !== gtin14) {
        isGood = false;
      }
      // SERIAL NUMBER
      let starting_serial = inputs.serial,
          epc_list = [],
          num = inputs.productionQty;
      for (let i = 0; i < num; i++) {
        let final_epc = calculateEPC3(starting_serial, epc_header_bin, filter_val_bin, partition_val_bin, gs1_prefix_bin, gs1_item_reference_bin);
        var epc = final_epc;
        var chars = epc.split('');
        var bins = chars.map(function(ch) {
          return hexDict[ch];
        });
        var calc = calculateUPC3(bins, gs1_prefix);
        const checkUPC = isUPCEqual(calc.fullUPC, inputs.upcCode);
        epc_list.push({
          epcCode: isGood ? checkUPC ? final_epc : 'UPC ERROR' : 'ERROR',
          epcCode2: final_epc,
          serialNum: decConvert(starting_serial),
          doublecheck,
          gtin14
        });
        starting_serial++;
      }
      return epc_list;
    }
  },
  EPCtoUPC: (inputs) => {
    console.log('EPC to UPC inputs', inputs);
    if (inputs.prefix) {
      var epc = inputs.epcCode;
      var chars = epc.split('');
      var bins = chars.map(function(ch) {
        return hexDict[ch];
      });
      var calc = calculateUPC3(bins, inputs.prefix);
      return {
        fullUPC: calc.fullUPC,
        serial: calc.serial,
        serial2: decConvert(calc.serial2),
        prefix: calc.prefix
      }
    } else {
      var epc = inputs.epcCode;
      var chars = epc.split('');
      var bins = chars.map(function(ch) {
        return hexDict[ch];
      });
      var calc;
      if (inputs.company_id === 4) {
        console.log('calculated company id is 4');
        calc = calculateUPC(bins);
      } else {
        console.log('calculated company id is not 4');
        calc = calculateUPC3(bins);
      }
      const calculated = {
        fullUPC: calc.fullUPC,
        serial: calc.serial,
        serial2: decConvert(calc.serial2),
        prefix: calc.prefix
      };
      console.log('calculated', calculated);
      return calculated
    }
  }
}

export const eanCheckDigit = (s) => {
  let result = 0;
  for (let counter = s.length-1; counter >=0; counter--) {
      result = result + parseInt(s.charAt(counter)) * (1+(2*(counter % 2)));
  }
  return (10 - (result % 10)) % 10;
};

export const checkIfWithinSerials = (results, obj) => {
  const { manufacturerID, startingSerial, productionQty } = obj;
  let similarSerials = [];
  if (results) {
    if (results.length > 0) {
      results.forEach(r => {
        if (r.start && r.end) {
          let isWithin = false;
          const serial = parseInt(manufacturerID + decConvert(startingSerial)),
                serialEnd = (serial + productionQty) - 1;
          const start = parseInt(r.start),
                end = parseInt(r.end);
          console.log('serial 11', start, end, 'compare to', serial, serialEnd);
          for (let i = serial; i < serialEnd+1; i++) {
            if (i >= start && i <= end) {
              isWithin = true;
              break;
            }
          }
          if (isWithin) similarSerials.push({
            start,end
          })

        }
      })
    }
  }
  return similarSerials.length > 0 ? clone(similarSerials) : null;
}
