// list all companies requiring the dropdown
export const dropdownRequired = [
  'walmart'
]

export const no_prefix_companies_int = [
  1
]

export const no_prefix_companies = [
  'allbirds',
  'jcpenny1',
  'jcpenny2'
]
