import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import withStyles from "@material-ui/core/styles/withStyles";
import styles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from "components/CustomButtons/Button.jsx";
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import activeDBs from "shared/activeDBs";
import { getDescription, trimObjData } from "./GINhelpers";

import { Input, InputAdornment, Icon, TableSortLabel } from '@material-ui/core';

import _ from 'lodash';
import GINalert from './GINalert';
import GINask2 from './GINask2';

const fields = [
  {
    key: 'base_item_description',
    name: 'BASE ITEM DESCRIPTION',
    size: {
      xs: 12,
      sm: 12,
      md: 2
    },
    value: ''
  },
  {
    key: 'product_type',
    name: 'PRODUCT TYPE',
    size: {
      xs: 12,
      sm: 12,
      md: 2
    },
    value: ''
  },
  {
    key: 'rbo',
    name: 'RBO NAME',
    size: {
      xs: 12,
      sm: 12,
      md: 2
    },
    value: ''
  },
  {
    key: 'brand',
    name: 'BRAND',
    size: {
      xs: 12,
      sm: 12,
      md: 2
    },
    value: ''
  },
  {
    key: 'subgroup',
    name: 'SUBGROUP',
    size: {
      xs: 12,
      sm: 12,
      md: 2
    },
    value: ''
  },
  {
    key: 'ipackagingtypes',
    name: 'PACKAGING TYPE',
    size: {
      xs: 12,
      sm: 12,
      md: 2
    },
    value: ''
  }
];

const DBlabel = props => {
  // props.name
  let backgroundColor = '';
  activeDBs.forEach((db) => {
    if (db.name === props.name) {
      backgroundColor = db.color;
    }
  });

  if (backgroundColor) {
    return (
      <div style={{backgroundColor}} className="db-pill">
        {props.name.split('_')[0]}
      </div>
    );
  }
  return null;
}

class GINpickValuesGeneral extends Component {
  state = {
    fields: fields,
    activeTab: 'base_item_description',
    filter: '',
    order: {
      direction: 'desc',
      active: 'db'
    },
    modalAsk: false,
    modalAlert: '',
    fieldsForSave: null
  }

  generateInputData = (o) => {
    const valueField = o.valueField ? o.valueField : null;
    const descField = o.descField ? o.descField : null;
    let desc = null;
    if (valueField) {
      desc = getDescription(o.value, o.options, o.valueField, o.descField);
    }
    let addText = '';
    if (o.addText) {
      addText = o.addText;
    }
    return (<span
            className="hovered hover-label"
            onClick={() => {
                this.props.editModeOn();
                this.props.setEdit({
                  itemName: o.name,
                  db: o.db,
                  field: o.field,
                  value: o.value,
                  desc,
                  type: o.type,
                  options: o.options,
                  valueField,
                  descField,
                  addText,
                  similars: o.similars,
                  validations: o.validations,
                  setPicked: true
                });
            }}
          >
            {!valueField && <span>{o.value ? o.value+addText : '- none -'}</span>}
            {(valueField && desc) && <span>{desc}</span>}
            {(valueField && !desc) && <span>n/a</span>}
          </span>);
  }

  componentDidMount() {
    this.props.onRef(this);

    let {fields} = this.state;

    // checking all tabs for completed data

    fields = _.map(fields, (tab) => {
      let dataToGroup = _.map(_.pickBy(this.props.data[tab.key], _.identity), (v, k) => ({k, v}));

      const data = _.map(_.values(_.groupBy(dataToGroup, 'v')), v => _.map(v, g => g.k));

      if(data.length === 1){
        this.props.onUpdateField(tab.key, this.props.data[tab.key][data[0][0]]);
        tab.value = data[0][0];

        this.props.updateGINEdit({
          value: tab.value,
          field: tab.key
        });
      }

      return tab;
    })

    this.setState({fields});
  }

  sort(field){
    const { order } = this.state;

    if(order.active === field){
      order.direction = order.direction === 'desc' ? 'asc' : 'desc';
    }else{
      order.active = field;
      order.direction = 'desc';
    }

    this.setState({order});
  }

  orderBy(data){
    if(this.state.order.active === 'db'){
      data = _.sortBy(data);
    }else{
      let dataToSort = _.map(_.pickBy(this.props.data[this.state.activeTab], _.identity), (v, k) => ({k, v}));
      data = _.map(_.orderBy(dataToSort, 'v'), v => v.k);
    }

    if(this.state.filter !== ''){
      let dataToFilter = _.map(_.pickBy(this.props.data[this.state.activeTab], (v) => v && v.match(new RegExp(this.state.filter, 'gi'))), (v, k) => k);

      data = dataToFilter;
    }

    // grouping
    let dataToGroup = _.map(_.pickBy(this.props.data[this.state.activeTab], _.identity), (v, k) => ({k, v}));

    data = _.map(_.values(_.groupBy(dataToGroup, 'v')), v => _.map(v, g => g.k));

    return this.state.order.direction === 'desc' ? data : _.reverse(data);
  }

  _renderCheckBox(db, index, group, isChecked = false){
    const currentField = _.find(this.state.fields, {key: this.state.activeTab});
    const { classes } = this.props;
    const label = this.props.data[this.state.activeTab][db];
    if(index !== 0){
      return;
    }

    console.log('checked v', currentField.value, group, db, this.props.picked);
    const checked = this.props.picked[currentField.key] ? group.indexOf(this.props.picked[currentField.key]) !== -1 ? true : false : false;
    return (
      <TableCell rowSpan={group.length}>
        <Checkbox
          tabIndex={-1}
          checkedIcon={<Check className={classes.checkedIcon} />}
          icon={<Check className={classes.uncheckedIcon} />}
          classes={{checked: classes.checked}}
          checked={checked}
          onClick={(e) => {
            e.preventDefault();
            const fields = _.map(Array.prototype.slice.call(this.state.fields), (v) => {
              if(v.key === this.state.activeTab){
                v.value = db
              }

              return v;
            })

            this.props.onUpdateField(this.state.activeTab, label);
            console.log('clicked', fields);
            this.setState({fields}, () => {
              this.save();
            });
          }}
        />
      </TableCell>
    )
  }

  save = () => {
    _.forEach(this.state.fields, (field) => {
      if (field.key === this.state.activeTab) {
        const value = this.props.data[field.key][field.value];
        console.log('value clicked', field.key, field.value, value);
        if (value) {
          this.props.updateGINEdit({
            value: field.value,
            field: field.key
          });

          if (field.key === 'product_type') {
            const dbs = this.props.openedDBs.filter(db => {
              if (db !== field.key) {
                return true;
              }
            });
            console.log('fetching MOCs', value, dbs);
            this.props.fetchItemMOCsAll(value, dbs);
          }
        }
      }
    })
  }

  generateObj = (db, type) => {
    console.log('generating', db, type);
    const props = this.props;
    if (type === 'base_item_description') {
      return {
          name: 'Base Item Description',db,
          field: 'base_item_description',
          value: (props.base_item_description[db] && props.base_item_description[db] !== 'N/A') ?
          props.base_item_description[db].toUpperCase() : '',
          type: 'input',
          validations: {
            maxLength: 75,
            notAllowedChars: 'aCharms',
            whitelist: true
          }
        }
    } else if (type === 'product_type') {
      return {
        name: 'Product Type',db,
        field: 'product_type',
        value: props.product_type[db] ? props.product_type[db] : '',
        options: props.options.hasOwnProperty(db) ? props.options[db].product_types : [],
        type: 'option1',
      }
    } else if (type === 'rbo') {
      return {
        name: 'RBO', db,
        field: 'rbo',
        value: props.rbo[db] ? props.rbo[db] : '',
        options: props.options.hasOwnProperty(db) ? props.options[db].rbos : [],
        type: 'option2',
        valueField: 'FirmCode',
        descField: 'FirmName'
      }
    } else if (type === 'brand') {
      return {
        name: 'Brand',db,
        field: 'brand',
        value: props.brand[db] ? props.brand[db] : '',
        options: this.props.brands,
        type: 'option2',
        valueField: 'id',
        descField: 'name'
      }
    } else if (type === 'subgroup') {
      return {
        name: 'Subgroup',db,
        field: 'subgroup',
        value: props.subgroup[db] ? props.subgroup[db] : '',
        options: this.props.subgroups,
        type: 'option2',
        valueField: 'id',
        descField: 'name'
      }
    } else if (type === 'ipackagingtypes') {
      return {
        name: 'Packaging Type',db,
        field: 'ipackagingtypes',
        value: props.ipackagingtypes[db] ? props.ipackagingtypes[db] : '',
        options: this.props.packagingtypes,
        type: 'option2',
        valueField: 'id',
        descField: 'name'
      }
    }
  }

  render() {
    console.log('GINpickValuesGeneral st and pr', this.state, this.props);

    let hasWalmart = false;
    const the_picked = this.props.picked;
    const the_rbo = this.props.rbo;
    const rboDict = this.props.rboDict;
    if (the_picked && the_rbo) {
      if (the_picked.rbo) {
        const the_picked_rbo_db = the_picked.rbo;
        const rbo_picked_value = the_rbo[the_picked_rbo_db];
        if (rboDict[the_picked_rbo_db]) {
          if (rboDict[the_picked_rbo_db][rbo_picked_value]) {
            const rbo_name = rboDict[the_picked_rbo_db][rbo_picked_value];
            if (rbo_name) {
              if (rbo_name.indexOf('WALMART') !== -1) {
                hasWalmart = true;
              }
            }
          }
        }
      }
    }

    if(!this.props.active){
      return <div />;
    }

    const data = this.orderBy(this.props.openedDBs),
          props = this.props,
          picked = props.picked;
    console.log('data ->', data);

    return (
      <div style={{marginTop:'20px'}}>
        {!!this.state.modalAlert && <GINalert
          hide={() => this.setState({modalAlert: ''})}
          content={this.state.modalAlert}
        />
        }
        {!!this.state.modalAsk && <GINask2
          command={() => {
            this.setState({
              fields: this.state.fieldsForSave
            }, () => {
              this.save();
            });
          }}
          title="Update to checked value?"
          content="NOTE: This action will not save to SAP yet."
          close={() => this.setState({modalAsk: false})}
        />
        }
        <GridContainer>
          {
            _.map(this.state.fields, (v, i) => {
              console.log('v->', v.value, v);
              if (v.key === 'ipackagingtypes' && !hasWalmart) {
                return null;
              }
              return (
                <GridItem key={i} xs={v.size.xs} sm={v.size.sm} md={v.size.md}>
                  <div className="text-center">
                    {this.props.picked[v.key]
                    ? <div className="blue-text">Completed</div>
                    : <div className="red-text">Incomplete</div>
                    }
                  </div>
                  <div className="text-center">
                    <div
                      className={this.state.activeTab === v.key ? "small-btn orange-bg" : "small-btn white-bg"}
                      onClick={(e) => {
                        e.preventDefault();

                        this.setState({activeTab: v.key})
                      }}
                    >
                      {v.name}
                    </div>
                  </div>
                </GridItem>
              )
            })
          }
        </GridContainer>

        {/* RENDER DATA */}
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="text-center">
                    <TableSortLabel
                      active={this.state.order.active === 'db'}
                      direction={this.state.order.direction}
                      onClick={() => this.sort('db')}
                    >
                      DB
                    </TableSortLabel>
                  </TableCell>
                  <TableCell colSpan={2}>
                    <TableSortLabel
                      active={this.state.order.active === this.state.activeTab}
                      direction={this.state.order.direction}
                      onClick={() => this.sort(this.state.activeTab)}
                    >
                      {(_.find(this.state.fields, {key: this.state.activeTab}).name)}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell style={{paddingRight: 0}}>
                    {/* HIDE FOR NOW */}
                    <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', opacity:0}}>
                      <Button
                        size="sm"
                        color="success"
                        style={this.props.nextButtonCompleted ? {} : {opacity: .65}}
                        onClick={() => this.props.onNextClick()}>NEXT</Button>
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  _.map(data, (group, groupId) => group.map((db, i) => {
                    console.log('group',group, this.state.activeTab);
                    const tab = this.state.activeTab;
                    const value = this.props[tab][db];
                    const generatedObj = this.generateObj(db, tab);
                    const inputData = this.generateInputData(generatedObj);
                    return (
                        <TableRow key={`row_${groupId}_${i}`}>
                          <TableCell style={group.length > 1 && i !== group.length - 1 ? {borderBottom: 'none'} : {}} align="left">
                            <DBlabel name={db} />
                          </TableCell>
                          <TableCell style={group.length > 1 && i !== group.length - 1 ? {borderBottom: 'none'} : {}}>
                            <div style={{whiteSpace: 'nowrap'}}>
                              {inputData}
                            </div>
                          </TableCell>
                            {(value !== 'n/a' || (this.state.activeTab === 'brand' || this.state.activeTab === 'subgroup')) &&
                              this._renderCheckBox(db, i, group, data.length === 1)
                            }
                          <TableCell style={group.length > 1 && i !== group.length - 1 ? {borderBottom: 'none', width: '100%'} : {width: '100%'}} />
                        </TableRow>
                      )
                  })
                  )
                }
              </TableBody>
            </Table>
          </GridItem>
        </GridContainer>
      </div>
    )
  }
}
 // to prevent calling undefined,
GINpickValuesGeneral.defaultProps = {
  onUpdateField: () => {},
  onRef: () => {},
  onNextClick: () => {}
}

const mapStateToProps = state => {
  const brands = state.gin.brands,
        subgroups = state.gin.subgroups,
        packagingtypes = state.gin.packagingtypes,
        options = state.gin.options;
  let brandsDict = {},
      subgroupsDict = {},
      packagingtypesDict = {},
      rboReverseDict = {},
      rboDict = {};
  brands.forEach(b => {
    brandsDict[b.id.toString()] = b.name;
  });
  subgroups.forEach(s => {
    subgroupsDict[s.id.toString()] = s.name;
  });
  packagingtypes.forEach(p => {
    packagingtypesDict[p.id.toString()] = p.name;
  });
  Object.keys(options).forEach(db => {
    rboDict[db] = {};
    rboReverseDict[db] = {};
    const opts = options[db].rbos;
    if (opts) {
      opts.forEach(o => {
        rboDict[db][o.FirmCode.toString()] = o.FirmName;
        rboReverseDict[db][o.FirmName] = o.FirmCode.toString();
      });
    }
  });

  let stateGINclone = _.cloneDeep(state.gin);
  Object.keys(stateGINclone.rbo).forEach(dbb => {
    console.log(dbb, stateGINclone.rbo);
    if (stateGINclone.rbo[dbb]) {
      if (rboDict[dbb]) {
        stateGINclone.rbo[dbb] = rboDict[dbb][stateGINclone.rbo[dbb]];
      }
    }
  });
  const data = _.pick(stateGINclone, _.map(fields, v => v.key));
  return {
    picked: state.gin.picked,
    openedDBs: state.gin.openedDBs,
    options: state.gin.options,
    base_item_description: state.gin.base_item_description,
    product_type: state.gin.product_type,
    rbo: state.gin.rbo,
    brand: state.gin.brand,
    subgroup: state.gin.subgroup,
    ipackagingtypes: state.gin.ipackagingtypes,
    brands: state.gin.brands,
    subgroups: state.gin.subgroups,
    packagingtypes: state.gin.packagingtypes,
    brandsDict,
    subgroupsDict,
    packagingtypesDict,
    rboDict,
    rboReverseDict,
    // data: _.pick(state.gin, _.map(fields, v => v.key))
    data: trimObjData(data)
  }
};

const mapDispatchToProps = dispatch => {
  return {
    updateGINEdit: (data) => {
      dispatch(actions.updateGINEdit(data));
    },
    fetchItemMOCsAll: (value, dbs) => {
      dispatch(actions.fetchItemMOCsAll(value, dbs));
    },
    editModeOn: () => {
      dispatch(actions.editModeOn());
    },
    setEdit: (data) => {
      dispatch(actions.setGINEdit(data));
    },
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(GINpickValuesGeneral));
