import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import * as actions from '../../store/actions';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import Button2 from "components/CustomButtons/Button.jsx";
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Typography from '@material-ui/core/Typography';
import Settings from "@material-ui/icons/Settings";
import Table from "components/Table/Table.jsx";
import moment from "moment";
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";

const DialogTitle = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500],
  },
}))(props => {
  const { children, classes, onClose, title, bp } = props;
  let companyName = '',
      db = '';
  if (bp) {
    console.log('disbp',bp);
    companyName = bp.company_name ? bp.company_name : bp.customer_name;
    db = bp.DB;
  }
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <div style={{fontSize:'18px',textAlign:'center'}}>
        <strong>{title}</strong> - {companyName} - {db}
      </div>

      <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit,
  },
}))(MuiDialogActions);

const compare = ( b, a ) => {
  const a_date = new Date(a.timestamp),
        b_date = new Date(b.timestamp);

  const a_timestamp = a_date.getTime(),
        b_timestamp = b_date.getTime();

  if ( a_timestamp > b_timestamp ){
    return -1;
  }
  if ( a_timestamp < b_timestamp ){
    return 1;
  }
  return 0;
}

class GMPHistorySubGroup extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { bp, classes, subGroupData } = this.props;
    let tableData = [],
        sgCopy = [ ...subGroupData],
        companyName = '',
        dbName = '';
    sgCopy.sort(compare);
    sgCopy.forEach((dat) => {
      console.log('dat',dat);
      companyName = dat.company_name ? dat.company_name : dat.customer_name;
      dbName = dat.DB;
      tableData.push([
        moment(dat.timestamp).format("MM/DD/YYYY hh:mm A"),
        dat.activity,
        dat.name === 'GMP' ? '' : dat.name,
        <Button2
          color="success"
          size="sm"
          onClick={() => {
            console.log('open history item',dat.id, dat.submission_id, dat);
            //this.props.closeHistorySubGroupModal();
            this.props.openReviewModal({
              id: dat.id,
              activity: dat.activity,
              user: dat.name,
              date_added: dat.timestamp,
              type: dat.bp_type,
              isFromSubGroup: true
            });
            this.props.onFetchGMPHistoryItem(dat.id, dat.submission_id);
            this.props.fetchAddInfoList(dat.DB);
          }}
        >VIEW</Button2>
      ]);
    });
    let content = <div>Loading</div>;
    const title = 'ACTIVITY';

    let table = (
      <Table
        tableHeaderColor="primary"
        tableHead={["Date/Time", "Activity", "User", "Action"]}
        tableData={tableData}
        coloredColls={[3]}
        colorsColls={["primary"]}
      />
    )
    return (
        <Dialog
          onClose={() => {
            this.props.closeHistorySubGroupModal();
          }}
          aria-labelledby="customized-dialog-title"
          open={this.props.open}
          fullWidth={true}
          maxWidth="md"
        >
          <DialogTitle
            id="customized-dialog-title"
            title={title}
            onClose={() => {
              this.props.closeHistorySubGroupModal();
            }}
            bp={{
              company_name: companyName,
              DB: dbName
            }}
          >
          </DialogTitle>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div style={{padding:'20px 20px 20px 40px',fontSize:'14px'}}>
                  {table}
              </div>
            </GridItem>
          </GridContainer>
        </Dialog>
    );
  }
}

const mapStateToProps = state => {
  return {
    bp: state.gmp.historyItemData,
    states: state.common.states,
    countries: state.common.countries
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchGMPHistoryItem: (id, submission_id) => dispatch(actions.fetchGMPHistoryItem(id, submission_id)),
    fetchAddInfoList: (db) => dispatch(actions.fetchAddInfoList(db))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(userProfileStyles)(GMPHistorySubGroup));
