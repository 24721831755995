import { put, call } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import axios from '../axios/axios-so';
import axios2 from 'axios';
import * as actions from '../actions/SO';
import test_so_txtfile from 'containers/SO/test_so_txtfile';
import devStatus from 'shared/devStatus';

export function* loadSODataSaga(action) {
  yield put(actions.loadSODataStart());
  const searchTerm = action.searchTerm ? action.searchTerm : 'none';
  try {
    const response = yield axios.get('/data/'+action.dbname+'/'+searchTerm);
    console.log('SO Data success',response.data);
    yield put(actions.loadSODataSuccess(response.data));
  } catch (err) {
    console.log('SO Data error',err);
    yield put(actions.loadSODataFail(err));
  }
}

function makeid(length) {
    let result           = [];
    let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
      result.push(characters.charAt(Math.floor(Math.random() *
      charactersLength)));
     }
   return result.join('');
}

export function* startImpositionsSaga(action) {
  yield put(actions.startImpositionsStart());
  try {
    const response = action.filename !== 'TESTfile.txt' ? yield axios.get('/download/'+action.filename, {
      responseType: 'text'
    }) : { data: test_so_txtfile() };
    console.log('SO Data success',response.data);
    const allText = response.data;
    if (allText) {
      let lines;

      if (action.filename !== 'TESTfile.txt') {
        let allTextLines = allText.split(/\r\n|\n/);
        console.log('allTextLines', allTextLines);
        let headers = allTextLines[0].split(',').map(m => m.trim());
        lines = [headers];

        for (let i = 1; i < allTextLines.length; i++) {
            const data = allTextLines[i].split(',');
            if (data.length == headers.length) {
                let tarr = [];
                for (let j = 0; j < headers.length; j++) {
                  tarr.push(data[j].trim());
                }
                lines.push(tarr);
            }
        }
      } else {
        if (action.test_data) {
          if (action.test_data.ups && action.test_data.variants) {
            lines = [["FIELD1","FIELD2","FIELD3","FIELD4","FIELD5","FIELD6","FIELD7",
            "FIELD8","FIELD9","FIELD10","FIELD11","FIELD12","FIELD13","FIELD14",
            "FIELD15","FIELD16","FIELD17","FIELD18","FIELD19","FIELD0","FIELD00",
            "FIELD000","FIELD0000","FIELD00000","FIELD000000"]];
            for (let x = 1; x < action.test_data.variants.length + 1; x++) {
              const arr = ["1006","AP6226F","Black",makeid(5),"2102010",
                          "WOMEN'S","271158","Original","","",
                          "","","","","",
                          "","","","",action.test_data.ups,
                          "1","symbols",action.test_data.variants[x-1],"271158","FN-CL-01"];
              lines.push(arr);
            }
          }
        }
      }

      console.log('lines', lines);
      console.log('lines 2', JSON.stringify(lines));

      if (lines[0]) {
        if (lines[0].length === 25) {
          // send to SO backend
          const response = yield axios.post('/impositions/', {
            filename: action.filename,
            data: lines
          });
          if (response.data) {
            if (response.data.msg === 'OK') {
              yield put(actions.startImpositionsSuccess(lines, response.data.filename));
              return;
            }
          }
          yield put(actions.startImpositionsFail('Source Processing Error'));
          return;
        }
      }
      yield put(actions.startImpositionsFail('Source Formatting Error'));
    } else {
      yield put(actions.startImpositionsFail('Error processing data'))
    }
  } catch(err) {
    console.log('SO Impositions error', err);
    yield put(actions.startImpositionsFail('Something happened, failed to process impositions'))
  }
}
