import * as actionTypes from './actionTypes';

export const loginUser = (user) => {
    return {
        type: actionTypes.AUTH_REQUEST,
        user
    }
}

export const loginUserSuccess = (user) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        user
    }
}

export const loginUserFail = (err) => {
    return {
        type: actionTypes.AUTH_FAILURE,
        err
    }
}

export const logoutUser = () => {
  return {
    type: actionTypes.AUTH_LOGOUT
  }
}
