import devStatus from 'shared/devStatus';

let dbList = {};

if (devStatus.indexOf('stage') === -1) {
  // live
  dbList = [
   { name:'NLA_LIVE', color:'rgb(234, 242, 253)', label:'NATco Los Angeles', production: 1, factory:'EXW-LOSANGELES'},
   { name:'NMX_LIVE', color:'rgb(211, 247, 213)', label:'NATco Mexico', production: 1, factory:'EXW NATCO MX'},
   { name:'NGT_LIVE', color:'rgb(225, 245, 249)', label:'NATco Guatemala', production: 1, factory:'EXW NATCO GT'},
   { name:'NSP_LIVE', color:'rgb(255, 208, 200)', label:'NATco Shanghai Packaging', production: 0, factory:'EXW-SHANGHAI'},
   { name:'NSM_LIVE', color:'rgb(255, 220, 220)', label:'NATco Shanghai Manufacturing', production: 1, factory:'EXW-SHANGHAI'},
   { name:'NAS_LIVE', color:'rgb(242, 228, 255)', label:'NATco Asia', production: 0, factory:'EXW-SHANGHAI'},
   { name:'NHK_LIVE', color:'rgb(236, 207, 207)', label:'NATco Hongkong', production: 0, factory:'EXW-SHANGHAI'},
   { name:'NTW_LIVE', color:'rgb(253, 231, 255)', label:'NATco Taiwan', production: 0, factory:'EXW-SHANGHAI'},
   { name:'NKR_LIVE', color:'rgb(224, 227, 245)', label:'NATco South Korea', production: 0, factory:'EXW-SEOUL'},
   { name:'NVT_LIVE', color:'rgb(249, 146, 146)', label:'NATco Vietnam', production: 1, factory: 'EXW-VIETNAM'},
   { name:'NIN_LIVE', color:'#ffeed2', label:'NATco India', production: 1, factory:'EXW-INDIA'},
   { name:'NDK_LIVE', color:'rgb(230, 255, 216)', label:'NATco Dhaka', production: 1, factory: 'EXW-DHAKA'},
   // { name:'NBD_LIVE', color:'#ced4ce', label:'Natco Bangladesh', production: 1, factory: 'EXW-DHAKA'},
 ];
} else {
  // stage
  dbList = [
  { name:'NLA_TEST', color:'rgb(234, 242, 253)', label:'NATco Los Angeles (TEST)', production: 1, factory:'EXW-LOSANGELES' },
  { name:'NMX_TEST', color:'rgb(211, 247, 213)', label:'NATco Mexico (TEST)', production: 1, factory:'EXW NATCO MX'},
  { name:'NGT_TEST', color:'rgb(225, 245, 249)', label:'NATco Guatemala (TEST)', production: 1, factory: 'EXW NATCO GT'},
  { name:'NSP_TEST', color:'rgb(255, 208, 200)', label:'NATco Shanghai Packaging (TEST)', production: 0, factory: 'EXW-SHANGHAI'},
  { name:'NSM_TEST', color:'rgb(255, 220, 220)', label:'NATco Shanghai Manufacturing (TEST)', production: 1, factory: 'EXW-SHANGHAI'},
  { name:'NAS_TEST', color:'rgb(242, 228, 255)', label:'NATco Asia (TEST)', production: 0, factory: 'EXW-SHANGHAI'},
  { name:'NHK_TEST', color:'rgb(236, 207, 207)', label:'NATco Hongkong (TEST)', production: 0, factory: 'EXW-SHANGHAI'},
  { name:'NTW_TEST', color:'rgb(253, 231, 255)', label:'NATco Taiwan (TEST)', production: 0, factory: 'EXW-SHANGHAI'},
  { name:'NKR_TEST', color:'rgb(224, 227, 245)', label:'NATco South Korea (TEST)', production: 0, factory: 'EXW-SEOUL'},
  { name:'NVT_TEST', color:'rgb(249, 146, 146)', label:'NATco Vietnam (TEST)', production: 1, factory: 'EXW-VIETNAM'},
  { name:'NIN_TEST', color:'#ffeed2', label:'NATco India (TEST)', production: 1, factory: 'EXW-INDIA'},
  { name:'NDK_TEST', color:'rgb(230, 255, 216)', label:'NATco Dhaka (TEST)', production: 1, factory: 'EXW-DHAKA'},
 ]
}

let so_dbs;

if (devStatus.indexOf('stage') === -1) {
  // live
  so_dbs = [
   { name:'NLA_LIVE', color:'rgb(234, 242, 253)', label:'NATco Los Angeles', production: 1, factory:'EXW-LOSANGELES'},
   { name:'NMX_LIVE', color:'rgb(211, 247, 213)', label:'NATco Mexico', production: 1, factory:'EXW NATCO MX'},
   { name:'NGT_LIVE', color:'rgb(225, 245, 249)', label:'NATco Guatemala', production: 1, factory:'EXW NATCO GT'},
   { name:'NSP_LIVE', color:'rgb(255, 208, 200)', label:'NATco Shanghai Packaging', production: 0, factory:'EXW-SHANGHAI'},
   { name:'NSM_LIVE', color:'rgb(255, 220, 220)', label:'NATco Shanghai Manufacturing', production: 1, factory:'EXW-SHANGHAI'},
   { name:'NAS_LIVE', color:'rgb(242, 228, 255)', label:'NATco Asia', production: 0, factory:'EXW-SHANGHAI'},
   { name:'NHK_LIVE', color:'rgb(236, 207, 207)', label:'NATco Hongkong', production: 0, factory:'EXW-SHANGHAI'},
   { name:'NTW_LIVE', color:'rgb(253, 231, 255)', label:'NATco Taiwan', production: 0, factory:'EXW-SHANGHAI'},
   { name:'NKR_LIVE', color:'rgb(224, 227, 245)', label:'NATco South Korea', production: 0, factory:'EXW-SEOUL'},
   { name:'NVT_LIVE', color:'rgb(249, 146, 146)', label:'NATco Vietnam', production: 1, factory: 'EXW-VIETNAM'},
   { name:'NIN_LIVE', color:'#ffeed2', label:'NATco India', production: 1, factory:'EXW-INDIA'},
   { name:'NDK_LIVE', color:'rgb(230, 255, 216)', label:'NATco Dhaka', production: 1, factory: 'EXW-DHAKA'},
   // { name:'NBD_LIVE', color:'#ced4ce', label:'Natco Bangladesh', production: 1, factory: 'EXW-DHAKA'},
 ];
} else {
  // stage
  so_dbs = [
  { name:'NLA_TEST', color:'rgb(234, 242, 253)', label:'NATco Los Angeles (TEST)', production: 1, factory:'EXW-LOSANGELES' },
  { name:'NMX_TEST', color:'rgb(211, 247, 213)', label:'NATco Mexico (TEST)', production: 1, factory:'EXW NATCO MX'},
  { name:'NGT_TEST', color:'rgb(225, 245, 249)', label:'NATco Guatemala (TEST)', production: 1, factory: 'EXW NATCO GT'},
  { name:'NSP_TEST', color:'rgb(255, 208, 200)', label:'NATco Shanghai Packaging (TEST)', production: 0, factory: 'EXW-SHANGHAI'},
  { name:'NSM_TEST', color:'rgb(255, 220, 220)', label:'NATco Shanghai Manufacturing (TEST)', production: 1, factory: 'EXW-SHANGHAI'},
  { name:'NAS_TEST', color:'rgb(242, 228, 255)', label:'NATco Asia (TEST)', production: 0, factory: 'EXW-SHANGHAI'},
  { name:'NHK_TEST', color:'rgb(236, 207, 207)', label:'NATco Hongkong (TEST)', production: 0, factory: 'EXW-SHANGHAI'},
  { name:'NTW_TEST', color:'rgb(253, 231, 255)', label:'NATco Taiwan (TEST)', production: 0, factory: 'EXW-SHANGHAI'},
  { name:'NKR_TEST', color:'rgb(224, 227, 245)', label:'NATco South Korea (TEST)', production: 0, factory: 'EXW-SEOUL'},
  { name:'NVT_TEST', color:'rgb(249, 146, 146)', label:'NATco Vietnam (TEST)', production: 1, factory: 'EXW-VIETNAM'},
  { name:'NIN_TEST', color:'#ffeed2', label:'NATco India (TEST)', production: 1, factory: 'EXW-INDIA'},
  { name:'NDK_TEST', color:'rgb(230, 255, 216)', label:'NATco Dhaka (TEST)', production: 1, factory: 'EXW-DHAKA'},
 ]
}

export const so_dbList = so_dbs;

export default dbList;

export const showPicksList = (db, product_type) => {
  const dbList = ['NSP','NHK','NTW','NIN','NDK','NAS'];
  return (product_type === 'Woven Labels' && (dbList.indexOf(db.split('_')[0]) === -1));
}

export const liveDBs = [
  'NLA_LIVE',
   'NMX_LIVE',
   'NGT_LIVE',
   'NSP_LIVE',
   'NSM_LIVE',
   'NAS_LIVE',
   'NHK_LIVE',
   'NTW_LIVE',
   'NKR_LIVE',
   'NVT_LIVE',
   'NIN_LIVE',
   'NDK_LIVE',
];

// export const db_extension = devStatus.indexOf('stage') === -1 ? '_LIVE' : '_TEST';
export const db_extension = devStatus.indexOf('stage') === -1 ? '_LIVE' : '_LIVE';