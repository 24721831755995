import React from "react";
import ReactTable from "react-table";
import download from 'downloadjs';
import { domain } from 'shared/devStatus';
import reactStringReplace from 'react-string-replace';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Button from "components/CustomButtons/Button.jsx";
import Dvr from "@material-ui/icons/Dvr";

const SOLookupMain = props => {
  // SO number and PI, File Number
  const data = props.data.filter((f) => {
    if (f.TxtFile) return true;
  });
  if (data.length === 0 || !data) return <div className="text-center" style={{marginBottom:20}}>- No matching S.O. found -</div>;
  return (
  <div className="table-clickable-rows table-centered-headers table-bottom-padded" style={{paddingBottom:20}}>
    <ReactTable
      minRows={0}
      getTrProps={(state, rowInfo) => {
        if (rowInfo && rowInfo.row) {
          return {
            onClick: (e) => {
              console.log('row', rowInfo);
            }
          }
        } else {
          return {}
        }
      }}
      data={
        (domain.indexOf('stage') === -1 && props.isTester) ?
          data.map((s, key) => { // map users to table rows
              return ({
                id: s.ID ? parseInt(s.ID) : 0,
                pi: s.PI ? s.PI : 'n/a',
                so: s.SO,
                mo: s.MagnetoOrder,
                filename: s.TxtFile
              })
          }).concat([{
            id: 0,
            pi: 'n/a',
            so: 'TEST so',
            mo: 'TEST mo',
            filename: 'TESTfile.txt'
          }])
        :
          data.map((s, key) => { // map users to table rows
              return ({
                id: s.ID ? parseInt(s.ID) : 0,
                pi: s.PI ? s.PI : 'n/a',
                so: s.SO,
                mo: s.MagnetoOrder,
                filename: s.TxtFile
              })
          })
      }
      filterable
      columns={[
          {
              Header: "ID",
              accessor: "id"
          },
          {
              Header: "SO",
              accessor: "so",
              filterMethod: (filter, row) => {
                let filterValue = filter.value.toLowerCase();
                filterValue = filterValue ? filterValue.toString().trim() : filterValue;
                if (filterValue.length > 0) {
                  let passed = false;
                  if (filterValue.length > row[filter.id].length) return false;
                  let val = row[filter.id].substring(0,filterValue.length);
                  if (val === filterValue) return true;
                  return false;
                } else {
                  return true;
                }
              },
              Cell: cell => {
                console.log('cell', cell);
                const d = cell.original.so;
                const soHighlighted = reactStringReplace(d, props.searchTerm, (match, i) => (
                                  <span key={`dd-${i}`} style={{color:'#0464e1'}}>{props.searchTerm}</span>
                                ))
                return <div style={{marginTop:20,marginBottom:20}}>{soHighlighted}</div>
              }
          },
          {
              Header: "Magento Order",
              accessor: "mo",
              filterMethod: (filter, row) => {
                let filterValue = filter.value.toLowerCase();
                filterValue = filterValue ? filterValue.toString().trim() : filterValue;
                if (filterValue.length > 0) {
                  let passed = false;
                  if (filterValue.length > row[filter.id].length) return false;
                  let val = row[filter.id].substring(0,filterValue.length);
                  if (val === filterValue) return true;
                  return false;
                } else {
                  return true;
                }
              }
          },
          {
              Header: "Filename",
              accessor: "filename",
              filterMethod: (filter, row) => {
                var filterValue = filter.value.toLowerCase();
                if (filterValue.length > 0) {
                  if (row[filter.id]) {
                    return row[filter.id].toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;
                  }
                  return false;
                } else {
                  return true;
                }
              },
              Cell: cell => {
                console.log('cell', cell);
                const d = cell.original.filename;
                return <div style={{marginTop:20,marginBottom:20}}>{d}</div>
              },
              minWidth: 500
          },
          {
            Header: "Download",
            accessor: "download",
            maxWidth:80,
            Cell: cell => {
              const d = cell.original;
              return <div>
                      <Button
                          justIcon
                          round
                          simple
                          onClick={() => {
                            console.log('cell.original',cell.original);
                            const dat = cell.original;
                            if (dat) {
                              if (dat.filename) {
                                if (dat.filename !== 'TEST filename') {
                                  const filepath = `${domain}/api/so/download/${dat.filename}`;
                                  download(filepath);
                                }
                              }
                            }
                          }}
                          color="primary"
                      >
                        <ArrowDownward style={{color:'green'}}/>
                      </Button>
                    </div>
                }
            },
            {
              Header: "Impositions",
              accessor: "imposition",
              maxWidth:80,
              Cell: cell => {
                const d = cell.original;
                return <div>
                        <Button
                            justIcon
                            round
                            simple
                            onClick={() => {
                              console.log('cell.original',cell.original);
                              const dat = cell.original;
                              if (dat) {
                                if (dat.filename) {
                                  let test_data = {};
                                  let ups = null;
                                  let variants = [];
                                  if (props.testData) {
                                    const ups_arr = props.testData.split('-');
                                    if (ups_arr[0]) ups = ups_arr[0];
                                    const varr = ups_arr[1] ? ups_arr[1] : null;
                                    if (varr) {
                                      variants = varr.split(',').filter(f => { if (f) return true; });
                                    }
                                    test_data.ups = ups;
                                    test_data.variants = variants;
                                  }
                                  props.startImpositions(dat.filename, test_data);
                                }
                              }
                            }}
                            color="primary"
                        >
                          <Dvr style={{color:'black'}}/>
                        </Button>
                      </div>
                }
              }
      ]}
      defaultPageSize={10}
      showPaginationTop
      showPaginationBottom
      className="-striped -highlight"
  >
  {(state, makeTable, instance) => {
      let recordsInfoText = "";

      const { filtered, pageRows, pageSize, sortedData, page } = state;

      if (sortedData && sortedData.length > 0) {
        let isFiltered = filtered.length > 0;

        let totalRecords = sortedData.length;

        let recordsCountFrom = page * pageSize + 1;

        let recordsCountTo = recordsCountFrom + pageRows.length - 1;

        if (isFiltered)
          recordsInfoText = `Showing ${recordsCountFrom}-${recordsCountTo} of ${totalRecords.toLocaleString()} SO entries`;
        else
          recordsInfoText = `Showing ${recordsCountFrom}-${recordsCountTo} of ${totalRecords.toLocaleString()} SO entries`;
      } else recordsInfoText = "No SO entries";

      return (
        <div className="main-grid">
          {makeTable()}
          <div style={{marginTop:'30px'}}>
            <div style={{textAlign:'center'}}>{recordsInfoText}</div>
          </div>
        </div>
      );
    }}
  </ReactTable>
</div>
  )
}

export default SOLookupMain;
