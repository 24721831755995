import { put } from 'redux-saga/effects';
import axios from '../axios/axios-ad';

import * as actions from '../actions/';


export function* fetchADSaga() { // saga for getting data from AD endpoint
    yield put(actions.fetchADStart());

    try {
        const response = yield axios.get();
        const fetchedUsers = response.data.users;
        const fetchedDirectory = response.data.directory;
        console.log('fetched', response.data, fetchedUsers, fetchedDirectory);
        yield put(actions.fetchADSuccess(fetchedUsers, fetchedDirectory));
    }
    catch (err) {
        console.log(err);
        yield put(actions.fetchADFail(err));
    }
}

export function* addADUserSaga(action) {
    yield put(actions.addADUserStart());
    try {
        const response = yield axios.post('user', { user: action.user });
        const fetchedUsers = response.data;
        yield put(actions.addADUserSuccess(fetchedUsers));
    } catch (err) {
        console.log('ERR', err);
        yield put(actions.addADUserFail(err));
    }
}

export function* modifyADUserSaga(action) {
    yield put(actions.modifyADUserStart());
    try {
        const response = yield axios.put('user', { user: action.user });
        const fetchedUsers = response.data;
        yield put(actions.modifyADUserSuccess(fetchedUsers));
    } catch (err) {
        yield put(actions.modifyADUserFail(err));
    }
}
export function* deleteADUserSaga(action) {
    yield put(actions.deleteADUserStart());
    try {
        const response = yield axios.put('user/delete', { userDN: action.userDN });
        const fetchedUsers = response.data;
        yield put(actions.deleteADUserSuccess(fetchedUsers));
    } catch (err) {
        console.log(err);
        yield put(actions.deleteADUserFail(err));
    }
}
