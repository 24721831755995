import * as actionTypes from './actionTypes';

export const fetchGMP = () => {
    return {
        type: actionTypes.FETCH_GMP,
    }
}

export const fetchGMPStart = () => {
    return {
        type: actionTypes.FETCH_GMP_START,
    }
}

export const fetchGMPFail = () => {
    return {
        type: actionTypes.FETCH_GMP_FAIL,
    }
}

export const fetchGMPSuccess = (gmps) => {
    return {
        type: actionTypes.FETCH_GMP_SUCCESS,
        gmps
    }
}

// fetch BP addresses
// ------------------
export const fetchGMPAddress = (id) => {
    return {
        type: actionTypes.FETCH_GMP_ADDRESS,
        id
    }
}

export const fetchGMPAddressStart = () => {
    return {
        type: actionTypes.FETCH_GMP_ADDRESS_START,
    }
}

export const fetchGMPAddressFail = () => {
    return {
        type: actionTypes.FETCH_GMP_ADDRESS_FAIL,
    }
}

export const fetchGMPAddressSuccess = (addresses) => {
    return {
        type: actionTypes.FETCH_GMP_ADDRESS_SUCCESS,
        addresses
    }
}

// fetch BP contacts
// ------------------
export const fetchGMPContact = (id) => {
    return {
        type: actionTypes.FETCH_GMP_CONTACT,
        id
    }
}

export const fetchGMPContactStart = () => {
    return {
        type: actionTypes.FETCH_GMP_CONTACT_START,
    }
}

export const fetchGMPContactFail = () => {
    return {
        type: actionTypes.FETCH_GMP_CONTACT_FAIL,
    }
}

export const fetchGMPContactSuccess = (contacts) => {
    return {
        type: actionTypes.FETCH_GMP_CONTACT_SUCCESS,
        contacts
    }
}

// fetch RBO
// ------------------
export const fetchGMPRBO = (db) => {
    return {
        type: actionTypes.FETCH_GMP_RBO,
        db
    }
}

export const fetchGMPRBOStart = () => {
    return {
        type: actionTypes.FETCH_GMP_RBO_START,
    }
}

export const fetchGMPRBOFail = (err) => {
    return {
        type: actionTypes.FETCH_GMP_RBO_FAIL,
        err
    }
}

export const fetchGMPRBOSuccess = (rbos) => {
    return {
        type: actionTypes.FETCH_GMP_RBO_SUCCESS,
        rbos
    }
}

export const changeActiveDB = (db) => {
  return {
    type: actionTypes.CHANGE_ACTIVE_DB,
    db
  }
}

export const changeActiveBillingCountry = (country) => {
  return {
    type: actionTypes.CHANGE_ACTIVE_BILLING_COUNTRY,
    country
  }
}

export const changeActiveShippingCountry = (country) => {
  return {
    type: actionTypes.CHANGE_ACTIVE_SHIPPING_COUNTRY,
    country
  }
}

export const createGMP = (values) => {
  return {
    type: actionTypes.CREATE_GMP,
    values
  }
}

export const createGMPStart = () => {
  return {
    type: actionTypes.CREATE_GMP_START
  }
}

export const createGMPSuccess = (id) => {
  return {
    type: actionTypes.CREATE_GMP_SUCCESS,
    id
  }
}

export const createGMPFail= (err) => {
  return {
    type: actionTypes.CREATE_GMP_FAIL,
    err
  }
}

export const createGMPBP = (bp) => {
  return {
    type: actionTypes.CREATE_GMP_BP,
    bp
  }
}

export const createGMPBPStart = () => {
  return {
    type: actionTypes.CREATE_GMP_BP_START
  }
}

export const createGMPBPSuccess = (msg) => {
  return {
    type: actionTypes.CREATE_GMP_BP_SUCCESS,
    msg
  }
}

export const createGMPBPFail = (err) => {
  return {
    type: actionTypes.CREATE_GMP_BP_FAIL,
    err
  }
}

export const createGMPBPExisting = (values) => {
  return {
    type: actionTypes.CREATE_GMP_BP_EXISTING,
    values
  }
}

export const createGMPBPExistingStart = () => {
  return {
    type: actionTypes.CREATE_GMP_BP_EXISTING_START
  }
}

export const createGMPBPExistingSuccess = (msg) => {
  return {
    type: actionTypes.CREATE_GMP_BP_EXISTING_SUCCESS,
    msg
  }
}

export const createGMPBPExistingFail = (err) => {
  return {
    type: actionTypes.CREATE_GMP_BP_EXISTING_FAIL
  }
}

export const eraseGMPAlertMsg = () => {
  return {
    type: actionTypes.ERASE_GMP_ALERT_MSG
  }
}

export const denyGMPBP = (values) => {
  return {
    type: actionTypes.DENY_GMP_BP,
    values
  }
}

export const denyGMPBPStart = () => {
  return {
    type: actionTypes.DENY_GMP_BP_START
  }
}

export const denyGMPBPSuccess = () => {
  return {
    type: actionTypes.DENY_GMP_BP_SUCCESS
  }
}

export const denyGMPBPFail = () => {
  return {
    type: actionTypes.DENY_GMP_BP_FAIL
  }
}

export const denyGMPBPExisting = (values) => {
  return {
    type: actionTypes.DENY_GMP_BP_EXISTING,
    values
  }
}

export const denyGMPBPExistingStart = () => {
  return {
    type: actionTypes.DENY_GMP_BP_EXISTING_START
  }
}

export const denyGMPBPExistingSuccess = () => {
  return {
    type: actionTypes.DENY_GMP_BP_EXISTING_SUCCESS
  }
}

export const denyGMPBPExistingFail = () => {
  return {
    type: actionTypes.DENY_GMP_BP_EXISTING_FAIL
  }
}

export const fetchGSTType = () => {
  return {
    type: actionTypes.FETCH_GSTTYPE
  }
}

export const fetchGSTTypeStart = () => {
  return {
    type: actionTypes.FETCH_GSTTYPE_START
  }
}

export const fetchGSTTypeSuccess = (gsttypes) => {
  return {
    type: actionTypes.FETCH_GSTTYPE_SUCCESS,
    gsttypes
  }
}

export const fetchGSTTypeFail = (err) => {
  return {
    type: actionTypes.FETCH_GSTTYPE_FAIL,
    err
  }
}

export const fetchGMPSimilars = (data) => {
  return {
    type: actionTypes.FETCH_GMP_SIMILARS,
    data
  }
}

export const fetchGMPSimilarsStart = () => {
  return {
    type: actionTypes.FETCH_GMP_SIMILARS_START
  }
}

export const fetchGMPSimilarsSuccess = (data) => {
  return {
    type: actionTypes.FETCH_GMP_SIMILARS_SUCCESS,
    data
  }
}

export const fetchGMPSimilarsFail = (err) => {
  return {
    type: actionTypes.FETCH_GMP_SIMILARS_FAIL,
    err
  }
}

export const fetchGMPHistory = () => {
  return {
    type: actionTypes.FETCH_GMP_HISTORY
  }
}

export const fetchGMPHistoryStart = () => {
  return {
    type: actionTypes.FETCH_GMP_HISTORY_START
  }
}

export const fetchGMPHistorySuccess = (data) => {
  return {
    type: actionTypes.FETCH_GMP_HISTORY_SUCCESS,
    data
  }
}

export const fetchGMPHistoryFail = (err) => {
  return {
    type: actionTypes.FETCH_GMP_HISTORY_FAIL,
    err
  }
}

export const fetchGMPHistoryItem = (id, submission_id) => {
  return {
    type: actionTypes.FETCH_GMP_HISTORY_ITEM,
    id,
    submission_id
  }
}

export const fetchGMPHistoryItemStart = () => {
  return {
    type: actionTypes.FETCH_GMP_HISTORY_ITEM_START
  }
}

export const fetchGMPHistoryItemSuccess = (data) => {
  return {
    type: actionTypes.FETCH_GMP_HISTORY_ITEM_SUCCESS,
    data
  }
}

export const fetchGMPHistoryItemFail = (err) => {
  return {
    type: actionTypes.FETCH_GMP_HISTORY_ITEM_FAIL,
    err
  }
}

export const filterGMPHistoryDate = (startDate, endDate) => {
  return {
    type: actionTypes.FILTER_GMP_HISTORY_DATE,
    startDate,
    endDate
  }
}

export const updateGMPHistoryFiltered = (historyFiltered) => {
  return {
    type: actionTypes.UPDATE_GMP_HISTORY_FILTERED,
    historyFiltered
  }
}

export const updateGMPSharedFilters = (obj) => {
  return {
    type: actionTypes.UPDATE_GMP_SHARED_FILTERS,
    obj
  }
}

export const fetchGMPExistingSimilars = (invoice_num, bpname, email, db) => {
  return {
    type: actionTypes.FETCH_GMP_EXISTING_SIMILARS,
    invoice_num,
    bpname,
    email,
    db
  }
}

export const fetchGMPExistingSimilarsStart = () => {
  return {
    type: actionTypes.FETCH_GMP_EXISTING_SIMILARS_START
  }
}

export const fetchGMPExistingSimilarsSuccess = (data) => {
  return {
    type: actionTypes.FETCH_GMP_EXISTING_SIMILARS_SUCCESS,
    data
  }
}

export const fetchGMPExistingSimilarsFail = (err) => {
  return {
    type: actionTypes.FETCH_GMP_EXISTING_SIMILARS_FAIL,
    err
  }
}

export const clearSimilars = () => {
  return {
    type: actionTypes.CLEAR_SIMILARS
  }
}

export const fetchEDIPrograms = () => {
  return {
    type: actionTypes.FETCH_EDI_PROGRAMS
  }
}

export const fetchEDIProgramsSuccess = (edi_programs) => {
  return {
    type: actionTypes.FETCH_EDI_PROGRAMS_SUCCESS,
    edi_programs
  }
}

export const fetchEDIProgramsFail = (err) => {
  return {
    type: actionTypes.FETCH_EDI_PROGRAMS_FAIL,
    err
  }
}

export const checkVendorCode = (code, edi) => {
  return {
    type: actionTypes.CHECK_VENDOR_CODE,
    code,
    edi
  }
}

export const checkVendorCodeStart = () => {
  return {
    type: actionTypes.CHECK_VENDOR_CODE_START
  }
}

export const checkVendorCodeSuccess = (response) => {
  return {
    type: actionTypes.CHECK_VENDOR_CODE_SUCCESS,
    response
  }
}

export const checkVendorCodeFail = (err) => {
  return {
    type: actionTypes.CHECK_VENDOR_CODE_FAIL,
    err
  }
}

export const checkVendorCodes = (codesArr, edisArr) => {
  return {
    type: actionTypes.CHECK_VENDOR_CODES,
    codesArr,
    edisArr
  }
}

export const checkVendorCodesSuccess = (data, edi, last) => {
  return {
    type: actionTypes.CHECK_VENDOR_CODES_SUCCESS,
    data,
    edi,
    last
  }
}

export const checkVendorCodesFail = (err) => {
  return {
    type: actionTypes.CHECK_VENDOR_CODES_FAIL,
    err
  }
}

export const setLoadingGMPBP = (bool) => {
  return {
    type: actionTypes.SET_LOADING_GMPBP,
    bool
  }
}

export const resetGMPBP = () => {
  return {
    type: actionTypes.RESET_GMP_BP
  }
}

/* Conversion */

export const convertNewToExisting = (id, db) => {
  return {
    type: actionTypes.CONVERT_NEW_TO_EXISTING,
    id, db
  }
}

export const convertNewToExistingStart = () => {
  return {
    type: actionTypes.CONVERT_NEW_TO_EXISTING_START
  }
}

export const convertNewToExistingSuccess = (response) => {
  return {
    type: actionTypes.CONVERT_NEW_TO_EXISTING_SUCCESS,
    response
  }
}

export const convertNewToExistingFail = (err) => {
  return {
    type: actionTypes.CONVERT_NEW_TO_EXISTING_FAIL,
    err
  }
}

export const convertExistingToNew = (old_id, new_id, db) => {
  return {
    type: actionTypes.CONVERT_EXISTING_TO_NEW,
    old_id,
    new_id,
    db
  }
}

export const convertExistingToNewStart = () => {
  return {
    type: actionTypes.CONVERT_EXISTING_TO_NEW_START
  }
}

export const convertExistingToNewSuccess = (response) => {
  return {
    type: actionTypes.CONVERT_EXISTING_TO_NEW_SUCCESS,
    response
  }
}

export const convertExistingToNewFail = (err) => {
  return {
    type: actionTypes.CONVERT_EXISTING_TO_NEW_FAIL,
    err
  }
}
