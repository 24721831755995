import React, { Component } from 'react';
import ReactDOM from "react-dom";
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import withStyles from "@material-ui/core/styles/withStyles";
import checkBoxStyles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import AttachMoney from "@material-ui/icons/AttachMoney";
import Delete from "@material-ui/icons/Delete";
import ContentCopy from '@material-ui/icons/ContentCopy';
import ReactTooltip from 'react-tooltip'
import { numberWithCommas, convertDateStr, dateRangeOverlaps } from 'shared/utility';
import cloneDeep from "lodash/cloneDeep";
import dupesCalc from "./dupesCalc";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ReactTable from "react-table";
import GridItem from "components/Grid/GridItem.jsx";
import Table from "components/Table/Table2.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';

import GINalert from './GINalert';
import GINpriceAllEdit from "./GINprice-all-edit";
import GINqtyAlert from "./GINqty-alert";
import GINaddCostSupplier from "./GINadd-cost-supplier";
import GINaddPriceSelect from "./GINadd-price-select";
import GINask from "./GINask2";
import GMPMultipleSelect from "containers/GMP/GMPMultipleSelect";
import GINqtyAlertCostSuppliers from "./GINqty-alert-cost-suppliers";

import { ranges, rangesObj } from "./GINranges.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import activeDBs from "shared/activeDBs";
import combineStyles from "shared/combineStyles";

const comparator = (a, b) => {
   if (a[0] < b[0]) return -1;
   if (a[0] > b[0]) return 1;
   return 0;
 }

const style = {
  customCardContentClass: {
    paddingLeft: "0",
    paddingRight: "0"
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const getRangeVals = (qty) => {
  let finalRange;
  ranges.forEach((r) => {
    if (qty < 10000000) {
      if (r.rangeA <= qty && r.rangeB >= qty) {
        finalRange = [r.rangeA, r.rangeB];
      }
    }
  });
  return finalRange;
}

const getRangeField = (qty) => {
  let finalRange;
  ranges.forEach((r) => {
    if (qty < 10000000) {
      if (r.rangeA <= qty && r.rangeB >= qty) {
        finalRange = r.name;
      }
    }
  });
  return finalRange;
}

const getMinQtyRange = (qtyRange) => {
 let min = '';
 ranges.forEach((rnge) => {
   if (rnge.name === qtyRange) {
     min = rnge.rangeA;
   }
 });
 return min;
}

const DBlabel = props => {
  // props.name
  let backgroundColor = '';
  activeDBs.forEach((db) => {
    if (db.name === props.name) {
      backgroundColor = db.color;
    }
  });

  if (backgroundColor) {
    return (
      <div style={{backgroundColor}} className="db-pill">
        {props.name.split('_')[0]}
      </div>
    );
  }
  return null;
}

const QtySelect = props => {
  // props.selected, props.name
  const backgroundColor = props.selected ? 'orange' : 'rgba(0,0,0,0)',
        color = props.selected ? 'white' : 'black';
  return (
    <div style={{backgroundColor,color,fontWeight:'bold'}} className="pilled hovered" onClick={() => {
      props.choose(props.name); }}>
      {props.name}
    </div>
  );
}

const Field = props => {
  const color = 'black';
  return (
    <div
      style={{color}}
      onClick={() => {
        if (props.click) {
          props.click();
        }
      }}
      className="hovered relative"
    >{props.name}
    </div>
  )
}

class GINcostBySupplier extends Component {
  constructor(props) {
    super(props);

    this.state = {
      quantityRange: 'Base Price',
      openPriceAddSelect: false,
      openCostAllEdit: false,
      editTableData: null,
      ginAsk: false,
      rowData: null,
      askRequest: null,
      copyModal: false,
      alert: ''
    }
  }

  copyCost = (pr) => {
    console.log('pushing', pr);
    const obj = {};
    const db = pr.db;
    obj.cqtys = pr.qty ? pr.qty.toString() : '';
    obj.cstart = pr.start;
    obj.cstarts = pr.start;
    obj.cend = pr.end;
    obj.cends = pr.end;
    obj.cprice = pr.cost;
    obj.cprices = pr.cost;
    obj.cterm = pr.term;
    obj.cterms = pr.term;
    obj.ccur = pr.currency;
    obj.ccurs = pr.currency;
    console.log('pushing checking cprice', this.props.cprice[db])
    if (!this.props.cprice[db]) {
      // add to cprice
      ['cterm','ccur','cstart','ccode','cstart','cend','cprice'].forEach(field => {
        const value = field === 'ccode' ? '' : obj[field];
        console.log('pushing values 1', db, field, value);
        this.props.updateGINEdit({
          db, field, value
        })
      });
    } else {
      // add to cprices
      ['cqtys','cterms','ccurs','ccodes','cprices','cstarts','cends'].forEach(field => {
        const value = field === 'ccodes' ? '' : obj[field];
        let copy = cloneDeep(this.props[field]);
        if (copy[db]) {
          copy[db].push(value);
          console.log('pushing values 2', db, field, copy[db])
          this.props.updateGINEdit({
            db, field, value: copy[db]
          })
        }
      })
    }
    this.openAlert('Cost data successfully copied');
  }

  openAlert = (msg) => {
    this.setState({alert:msg})
  }

  closeAlert = () => {
    this.setState({alert:''})
  }

  openCopyCost = (copyData) => {
    this.setState({copyData,copyModal:true})
  }

  closeCopyCost = () => {
    this.setState({copyData:null,copyModal:false})
  }

  closeAsk = () => {
    this.setState({
      ginAsk: false,
    });
  }

  openAsk = (rowData, askRequest) => {
    this.setState({
      ginAsk: true,
      rowData,
      askRequest
    });
  }

  groupByQuantity = () => {
    let rangeCollection = {}, mocCollection = {}, supps = this.props.suppliers;
    Object.keys(supps).forEach(db => {
        if (this.props.dboptions.indexOf(db) !== -1) {
          console.log('customers to be grouped',supps[db]);
          Object.keys(supps[db]).forEach(supplier => {
            const supplierName = supplier.split('[.]')[1];
            // find special moc if any
            let mocObj = {};
            supps[db][supplier].forEach((pr,i) => {
              if (parseInt(pr[0]) === 0) {
                const curr = pr[5];
                mocObj[curr] = {
                  term: pr[1],
                  moc: pr[2] ? parseFloat(pr[2]) : 0,
                  code: pr[3],
                  ccode: pr[4],
                  start: pr[6],
                  end: pr[7],
                  index: i
                }
              }
            });
            supps[db][supplier].forEach((pr, indx) => {
              let qty = parseInt(pr[0]),
                    term = pr[1],
                    cost = parseFloat(pr[2]),
                    code = pr[3],
                    ccode = pr[4],
                    currency = pr[5],
                    start = pr[6],
                    itemName = this.props.itemCodes[db],
                    moc = mocObj[currency] ? parseInt(mocObj[currency].moc) : 0,
                    mocType = mocObj[currency] ? 'Special' : 'Standard',
                    end = pr[7],
                    mocSpecial = mocObj,
                    combo = supplier,
                    index = indx;

              if (mocType === 'Special' && moc === 0) {
                moc = 0;
                mocType = 'Standard';
              }

              const obj = {
                combo, qty, term, cost, code, ccode, currency, start, end, db, itemName, supplierName, moc, mocType, mocSpecial, index
              };

              ranges.forEach(range => {
                if (qty >= range.rangeA && qty <= range.rangeB) {
                  if (rangeCollection.hasOwnProperty(range.name)) {
                    rangeCollection[range.name].push(obj);
                  } else {
                    rangeCollection[range.name] = [obj];
                  }
                } else {
                  if (!rangeCollection.hasOwnProperty(range.name)) {
                    rangeCollection[range.name] = [];
                  }
                }
              });
            });
          });
        }
    });
    if (Object.keys(rangeCollection).length === 0) {
      ranges.forEach(range => {
        rangeCollection[range.name] = [];
      });
    }
    return rangeCollection;
  }

  selectQuantity = (name) => {
    if (name === 'Base Cost') {
      name = 'Base Price'
    }
    this.setState({
      quantityRange: name
    });
  }

  renderRanges = () => {
    const rangeData = ranges.map((mism) => {
      return [
        <QtySelect
          name={mism.name === 'Base Price' ? 'Base Cost' : mism.name}
          choose={this.selectQuantity}
          selected={this.state.quantityRange === mism.name}/>
      ]
    });

    return rangeData;
  }

  openPriceAddSelect = (minRange) => {
    this.setState({
      openPriceAddSelect: true,
      minRange
    });
  }

  closePriceAddSelect = () => {
    this.setState({
      openPriceAddSelect: false
    });
  }

  chooseDB = (db) => {
    this.openCostAllEdit({
      qty: getMinQtyRange(this.state.quantityRange),
      qtyField: this.state.quantityRange,
      DB:db
    });
    this.setState({
      openPriceAddSelect: false
    });
  }

  openCostAllEdit = (editTableData) => {
    this.setState({
      openCostAllEdit: true,
      editTableData: editTableData ? editTableData : {}
    });
  }

  closeCostAllEdit = () => {
    this.setState({
      openCostAllEdit: false
    });
  }

  deleteRow = (pr) => {
    console.log('deleting',pr);
    const code = pr.ccode,
          supplierName = pr.supplierName,
          db = pr.db,
          combo = `${code}[.]${supplierName}`;
    let copy = cloneDeep(this.props.smasterarray);
    if (copy[db][combo]) {
      copy[db][combo] = copy[db][combo].filter((c,i) => {
        if (i === pr.index) {
          return false;
        }
        return true;
      });

      // check if what is left from deleting are mocs
      let costsCount = 0;
      if (copy[db][combo]) {
        if (copy[db][combo].length > 0) {
          copy[db][combo].forEach(c => {
            // count costs and mocs
            if (parseInt(c[0]) !== 0) {
              costsCount++;
            }
          });
          if (costsCount === 0) {
            delete copy[db][combo];
          }
        } else {
          delete copy[db][combo];
        }
      }

      if (this.props.preferredVendor[db] === code) {
        // transfer the preferred vendor autaomatically to the next smaster
        if (copy[db]) {
          if (Object.keys(copy[db]).length > 0) {
            let preferredVendor = '';
            Object.keys(copy[db]).forEach((comb,i) => {
              const firstSupplierCode = comb.split('[.]')[0];
              if (!preferredVendor) {
                preferredVendor = firstSupplierCode;
              }
            });
            this.props.updateGINEdit({
              db,
              field: 'preferredVendor',
              value: preferredVendor
            });
          }
        }
      }
      this.props.updateGINEdit({
        db,
        field: 'smasterarray',
        value: copy[db]
      });
    }
  }

  choosePreferred = (pr) => {
    console.log(pr);
    if (pr.ccode) {
      if (pr.ccode !== this.props.preferredVendor[pr.db]) {
        this.props.updateGINEdit({
          db: pr.db,
          field: 'preferredVendor',
          value: pr.ccode
        });
      }
    }
  }

  render() {
    const { classes } = this.props;
    console.log('state & props SUPPLIER-SPECIFIC', this.state, this.props);
    const rangeCollection = this.groupByQuantity();
    console.log('rangeCollection',rangeCollection);
    let dupesExist = false;
    Object.keys(this.props.supplierDupes).forEach(supp => {
      if (this.props.supplierDupes[supp]) {
        if (this.props.supplierDupes[supp].length > 0) {
          dupesExist = true;
        }
      }
    });

    let dbsArr = [],
        currencyArr = [],
        effectiveDatesArr = [],
        supplierNameArr = [],
        termsArr = [];
    if (rangeCollection[this.state.quantityRange]) {
      rangeCollection[this.state.quantityRange].forEach(pr => {
        if (dbsArr.indexOf(pr.db) === -1) {
          dbsArr.push(pr.db);
        }
        if (termsArr.indexOf(pr.term) === -1) {
          termsArr.push(pr.term);
        }
        if (currencyArr.indexOf(pr.currency) === -1) {
          currencyArr.push(pr.currency);
        }
        if (effectiveDatesArr.indexOf(`${pr.start} TO ${pr.end}`) === -1) {
          effectiveDatesArr.push(`${pr.start} TO ${pr.end}`);
        }
        if (supplierNameArr.indexOf(pr.supplierName) === -1) {
          supplierNameArr.push(pr.supplierName);
        }
      });
    }

    const table = <div className="spec-table scrolling-table"><ReactTable
        data={
            rangeCollection[this.state.quantityRange].map((pr, key) => { // map users to table rows
              const preferredVendor = this.props.preferredVendor[pr.db];
              console.log('prr', preferredVendor === pr.combo.split('[.]')[0], preferredVendor, pr.combo.split('[.]')[0]);
              const qty = pr.qty;
              const getRange = getRangeVals(qty);
              const minTotalPrice = (getRange[0] * pr.cost) < pr.moc ? pr.moc : (getRange[0] * pr.cost);
              const maxTotalPrice = (getRange[1] * pr.cost) < pr.moc ? pr.moc : (getRange[1] * pr.cost);
              const totalPriceRange = getRange ? `${numberWithCommas(minTotalPrice)}` : `10M+`;
              return ({
                id: key,
                db_name: pr.db,
                supplierName: pr.supplierName,
                dbCell: (
                    <DBlabel name={pr.db} />
                ),
                term_name: pr.term,
                termCell: (
                  <Field
                    name={pr.term}
                    click={() => {
                      this.openCostAllEdit({
                        ...pr,
                        type: 'terms',
                        DB: pr.db,
                        value: pr.term,
                        qtyField: this.state.quantityRange,
                        index: pr.index,
                        action: 'edit'
                      });
                    }} />
                ),
                cost_name: `${pr.currency} ${numberWithCommas(parseFloat(pr.cost))}`,
                priceCell: (
                  <Field
                    name={`${pr.currency} ${numberWithCommas(parseFloat(pr.cost))}`}
                    click={() => {
                      this.openCostAllEdit({
                        ...pr,
                        type: 'cost',
                        DB: pr.db,
                        value: pr.cost,
                        itemName: pr.itemName,
                        qtyField: this.state.quantityRange,
                        index: pr.index,
                        action: 'edit'
                      });
                    }}
                  />
                ),
                effective_dates_name: `${pr.start} TO ${pr.end}`,
                effectiveDatesCell: (<Field
                      name={`${pr.start} TO ${pr.end}`}
                      click={() => {
                        this.openCostAllEdit({
                          ...pr,
                          type: 'effective-dates',
                          DB: pr.db,
                          value: `${pr.start},${pr.end}`,
                          itemName: pr.itemName,
                          qtyField: this.state.quantityRange,
                          index: pr.index,
                          action: 'edit'
                        });
                      }}
                    />),
                currency: pr.currency,
                currencyCell: (<Field
                      name={pr.currency}
                      click={() => {
                        this.openCostAllEdit({
                          ...pr,
                          type: 'currency',
                          DB: pr.db,
                          value: pr.currency,
                          itemName: pr.itemName,
                          qtyField: this.state.quantityRange,
                          index: pr.index,
                          action: 'edit'
                        });
                      }}
                    />),
                total_cost: `${pr.currency} ${totalPriceRange}`,
                moc: pr.moc,
                mocType: pr.mocType,
                moc_name: `${pr.currency} ${numberWithCommas(parseFloat(pr.moc))} ${pr.mocType}`,
                mocCell: (
                  <Field
                     name={`${pr.currency} ${numberWithCommas(parseFloat(pr.moc))} ${pr.mocType}`}
                     click={() => {
                       this.openCostAllEdit({
                         ...pr,
                         type: 'moc',
                         DB: pr.db,
                         value: pr.moc,
                         itemName: pr.itemName,
                         qtyField: this.state.quantityRange,
                         index: pr.index,
                         action: 'edit'
                       });
                     }}
                   />
                ),
                deleteCell: (
                  <div
                    className="red-text hovered"
                    style={{paddingTop:'10px',paddingBottom:'10px'}}
                    onClick={() => {
                      this.openAsk(pr, 'deleteRow');
                    }}>
                    <Delete />
                  </div>
                ),
                itemName: pr.itemName,
                pr: pr,
                preferredVendor: (
                  <Checkbox
                    tabIndex={-1}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{checked: classes.checked}}
                    checked={preferredVendor === pr.combo.split('[.]')[0]}
                    onClick={() => {
                      console.log('choosing pr',pr);
                      this.choosePreferred(pr);
                    }}
                  />
                ),
                copyCell: (
                  <div>
                    <div
                      data-tip="copy cost"
                      className="green-text hovered"
                      style={{paddingTop:'10px',paddingBottom:'10px'}}
                      onClick={() => {
                        this.copyCost(pr);
                      }}>
                      <ContentCopy />
                    </div>
                  </div>
                )
              })
            })
        }
        filterable
        columns={[
            {
                Header: "DB",
                accessor: "db_name",
                filterMethod: (filter, row) => {
                    const chosen = filter.value;
                    if (chosen.length > 0) {
                        return chosen.indexOf(row[filter.id]) !== -1;
                    } else {
                        return true;
                    }
                },
                Filter: ({filter, onChange}) => {
                    return <div className="gmp-filter"><GMPMultipleSelect
                        options={dbsArr}
                        label={''}
                        choose={onChange}
                    /></div>
                },
                Cell: row => {
                  return (
                    <div>{row.original.dbCell}</div>
                  )
                },
                maxWidth: 60,
                style:{ fontSize: '12px'}
            },
            {
                Header: "SUPPLIER NAME",
                accessor: "supplierName",
                filterMethod: (filter, row) => {
                    const chosen = filter.value;
                    if (chosen.length > 0) {
                        return chosen.indexOf(row[filter.id]) !== -1;
                    } else {
                        return true;
                    }
                },
                Filter: ({filter, onChange}) => {
                    return <div className="gmp-filter"><GMPMultipleSelect
                        options={supplierNameArr}
                        label={''}
                        choose={onChange}
                    /></div>
                },
                style: { whiteSpace: 'unset', fontSize:'12px' }
            },
            {
                Header: "SHIPPING TERMS",
                accessor: "term_name",
                filterMethod: (filter, row) => {
                    const chosen = filter.value;
                    if (chosen.length > 0) {
                        return chosen.indexOf(row[filter.id]) !== -1;
                    } else {
                        return true;
                    }
                },
                Filter: ({filter, onChange}) => {
                    return <div className="gmp-filter"><GMPMultipleSelect
                        options={termsArr}
                        label={''}
                        choose={onChange}
                    /></div>
                },
                Cell: row => {
                  return (
                    <div>{row.original.termCell}</div>
                  )
                },
                style:{ fontSize: '12px'}
            },
            {
                Header: "COST",
                accessor: "cost_name",
                Cell: row => {
                  return (
                    <div>{row.original.priceCell}</div>
                  )
                },
                sortable: true,
                filterable: false,
                style:{ fontSize: '12px'}
            },
            {
                Header: "EFFECTIVE DATES",
                accessor: "effective_dates_name",
                filterMethod: (filter, row) => {
                    const chosen = filter.value;
                    if (chosen.length > 0) {
                        return chosen.indexOf(row[filter.id]) !== -1;
                    } else {
                        return true;
                    }
                },
                Filter: ({filter, onChange}) => {
                    return <div className="gmp-filter"><GMPMultipleSelect
                        options={effectiveDatesArr}
                        label={''}
                        choose={onChange}
                    /></div>
                },
                Cell: row => {
                  return (
                    <div>{row.original.effectiveDatesCell}</div>
                  )
                },
                style: { whiteSpace: 'unset', fontSize: '12px' }
            },
            {
                Header: "CURRENCY",
                accessor: "currency",
                filterMethod: (filter, row) => {
                    const chosen = filter.value;
                    if (chosen.length > 0) {
                        return chosen.indexOf(row[filter.id]) !== -1;
                    } else {
                        return true;
                    }
                },
                Filter: ({filter, onChange}) => {
                    return <div className="gmp-filter"><GMPMultipleSelect
                        options={currencyArr}
                        label={''}
                        choose={onChange}
                    /></div>
                },
                Cell: row => {
                  return (
                    <div className="text-center">{row.original.currencyCell}</div>
                  )
                },
                maxWidth: 80,
                style:{ fontSize: '12px'}
            },
            {
                Header: "TOTAL COST",
                accessor: "total_cost",
                sortable: false,
                filterable: false,
                style:{ fontSize: '12px'}
            },
            {
                Header: "MOC",
                accessor: "moc",
                Cell: row => {
                  return (
                    <div>{row.original.mocCell}</div>
                  )
                },
                sortable: false,
                filterable: false,
                style:{ fontSize: '12px'}
            },
            {
                Header: <div style={{lineHeight:1.5}}>Preferred<br/>Vendor</div>,
                accessor: "preferredVendor",
                sortable: false,
                filterable: false,
                maxWidth: 50,
                style:{ whiteSpace: 'unset', fontSize: '12px'}
            },
            {
                Header: "",
                accessor: "deleteCell",
                sortable: false,
                filterable: false,
                maxWidth: 40
            },
            {
              Header: "",
              accessor: "copyCell",
              sortable: false,
              filterable: false,
              maxWidth: 40
            },
        ]}
        minRows={0}
        defaultPageSize={1000}
        showPagination={false}
        className="-highlight"
        resizable={false}
    /></div>

    return (
      <div>
        {
          this.state.openCostAllEdit &&
            <GINaddCostSupplier
              open={this.state.openCostAllEdit}
              close={this.closeCostAllEdit}
              editTableData={this.state.editTableData}
            />
        }
        {
          this.state.openPriceAddSelect &&
            <GINaddPriceSelect
              open={this.state.openPriceAddSelect}
              close={this.closePriceAddSelect}
              choose={this.chooseDB}
              dbOptions={this.props.dboptions}
              minRange={this.state.minRange}
            />
        }
        {
          this.state.ginAsk &&
          <GINask
            askRequest="deleteRow"
            close={this.closeAsk}
            command={() => {
              this.deleteRow(this.state.rowData);
              this.closeAsk();
            }}
            content="Delete this Costing row?"
            title="Are you sure?"
          />
        }
        {dupesExist &&
          <GINqtyAlertCostSuppliers
            duplicates={this.props.supplierDupes}
            open={dupesExist}
          />
        }
        <GridContainer>
          <GridItem xs={12} sm={12} md={1}>
            <div className="outlined-blue" style={{float:'right',maxWidth:'200px',padding:'10px 0'}}>
              <div className="tiny-table">
                <Table
                  tableHeaderColor="primary"
                  tableHead={["QUANTITY"]}
                  tableData={this.renderRanges()}
                  coloredColls={[3]}
                  colorsColls={["primary"]}
                />
              </div>
            </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={11}>
          <div style={{marginTop:'20px'}}>
            <div className="font-12" style={{marginTop:'30px'}}>
              <strong>DISPLAYING FOR QUANTITY: <span className="orange-text">{this.state.quantityRange === 'Base Price' ? 'Base Cost' : this.state.quantityRange}</span></strong>
            </div>
            <div className="relative">
              <div className="price-add-btn">
                <Button
                  color="rose"
                  size="sm"
                  onClick={() => {
                    this.openPriceAddSelect();
                  }}
                >Add</Button>
              </div>
              {table}
            </div>
          </div>
          </GridItem>
        </GridContainer>
        <ReactTooltip />
        {this.state.alert && <GINalert
            hide={this.closeAlert}
            content={this.state.alert}
            title="Success"
            />
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  // filter dbs to only Items that have Cost enabled - Both, Outsourced
  const dboptions = state.gin.openedDBs.filter(db => {
    if (state.gin.item_is[db] === 'Both' || state.gin.item_is[db] === 'Outsourced') {
      return true;
    }
    return false;
  });
  let supplierGroups = {},
      smasterarray = state.gin.smasterarray;

  dboptions.forEach((db) => {
    if (smasterarray[db]) {
      Object.keys(smasterarray[db]).forEach(supplier => {
        supplierGroups[supplier] = {};
        if (smasterarray[db][supplier]) {
          if (smasterarray[db][supplier].constructor === Array) {
            smasterarray[db][supplier].forEach((cr,i) => {
              const qty = parseInt(cr[0]);
              const obj = {
                indx: i,
                qty,
                term: cr[1],
                cost: cr[2],
                currency: cr[5],
                start: cr[6],
                end: cr[7],
                itemCode: state.gin.itemCodes[db]
              }

              if (qty) {
                const qtyField = getRangeField(qty);
                if (supplierGroups[supplier].hasOwnProperty(db)) {
                  if (supplierGroups[supplier][db].hasOwnProperty(qtyField)) {
                    supplierGroups[supplier][db][qtyField].push(obj);
                  } else {
                    supplierGroups[supplier][db][qtyField] = [obj];
                  }
                } else {
                  supplierGroups[supplier][db] = {
                    [qtyField]: [obj]
                  };
                }
              }
            });
          }
        }
      });
    }
  })

  let supplierDupes = {};
  Object.keys(supplierGroups).forEach(supplier => {
    supplierDupes[supplier] = dupesCalc(supplierGroups[supplier]);
  });

  return {
    supplierGroups,
    supplierDupes,
    smasterarray,
    itemIs: state.gin.item_is,
    preferredVendor: state.gin.preferredVendor,
    openedDBs: state.gin.openedDBs,
    suppliers: state.gin.smasterarray,
    itemCodes: state.gin.itemCodes,
    options: state.gin.options,
    mocs: state.gin.mocs,

    ccur: state.gin.ccur,
    cprice: state.gin.cprice,
    cterm: state.gin.cterm,
    cstart: state.gin.cstart,
    cend: state.gin.cend,
    ccode: state.gin.ccode,

    ccodes: state.gin.ccodes,
    ccurs: state.gin.ccurs,
    cprices: state.gin.cprices,
    cterms: state.gin.cterms,
    cstarts: state.gin.cstarts,
    cends: state.gin.cends,
    cqtys: state.gin.cqtys,
    dboptions
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    editModeOn: () => {
      dispatch(actions.editModeOn());
    },
    setEdit: (data) => {
      dispatch(actions.setGINEdit(data));
    },
    updateGINEdit: (data) => {
      dispatch(actions.updateGINEdit(data));
    }
  }
}

const combinedStyles = combineStyles(checkBoxStyles, style);
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(combinedStyles)(GINcostBySupplier));
