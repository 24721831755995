import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from 'store/actions';
import SweetAlert from "react-bootstrap-sweetalert";
import { PulseLoader } from 'react-spinners';
import withStyles from "@material-ui/core/styles/withStyles";
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import combineStyles from "shared/combineStyles";
import moment from "moment";
// @material-ui/icons
import Settings from "@material-ui/icons/Settings";
import BPDialog from "./BPDialog";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Button2 from '@material-ui/core/Button';
import Clearfix from "components/Clearfix/Clearfix.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';

import { eraseGMPAlertMsg } from "store/actions/GMP";
import { normalizePhone, commatize } from "shared/utility";
import devStatus from "shared/devStatus";
import helpers from "./BPhelpers";

const compareStr = (val1, val2, field) => {
  if (!val1) val1 = '';
  if (!val2) val2 = '';
  console.log('field check',field, val1, val2, val1 == val2);
  if (val1 === val2) {
    return true;
  }
  return false;
}

const getName = (arr, id, type) => {
  let name = '';
  arr.forEach(a => {
    if (id == a.id) {
      name = a[type];
    }
  })
  return name;
}

const compareAddressName = (a,b) => {
  if (a.shipname < b.shipname)
    return -1;
  if (a.shipname > b.shipname)
    return 1;
  return 0;
}

const compareContactName = (a,b) => {
  if (a.name < b.name)
    return -1;
  if (a.name > b.name)
    return 1;
  return 0;
}

const convertIfString = (dat) => {
  if (typeof dat === 'string') {
    return JSON.parse(dat);
  }
  return dat;
}

const normalizeWTcodes = (valueA, owhtopts) => {
  console.log('normalizing wtcodes', valueA, owhtopts);
  let valueArr = [];
  if (typeof valueA === 'string') {
    valueArr = valueA.split(',');
  }
  const value = valueArr.length > 0 ? valueArr.map((wt,i) => {
    if (owhtopts.hasOwnProperty(wt)) {
      return <span key={`a-wtt-${i}`}>{owhtopts[wt]}, </span>
    } else {
      return null;
    }
  }) : null;
  return value;
}

const CustomField = (props) => {
  return (
    <div style={{marginBottom:'10px'}}>
      <div style={{color:'#aaa',fontSize:'10px',fontWeight:'400'}}>{props.field}:</div>
      <div>{props.value} {props.addedText ? <span className="blue-btnn"></span> : ''}</div>
    </div>
  )
}

const CustomDynamicField = props => {
  const { value, changedFrom, type, field, hasChanged } = props;
  if (field === 'Name') {
    console.log('name', value, changedFrom, value == changedFrom, type);
  }
  return (
    <div style={{marginBottom:'10px'}}>
      <div style={{color:'#aaa',fontSize:'10px',fontWeight:'400'}}>{field}:</div>

      {(value && type === 'deleted') && <div>{value} <span className="red-box">DELETED</span></div>}

      {(value && type === 'new') && <div className="green-new">{value}</div>}

      {(value && !type) && <div>{value}</div>}

      {
        type === 'edit' &&
      <div>
        {(value && (value == changedFrom)) && <div>{value}</div>}
        {(!value && (value == changedFrom)) && <div>{value}</div>}
        {(value && (value != changedFrom)) &&
          <div>
            <span className="green-new">{value}</span> <span style={{color:'red'}}>{changedFrom && <span>( Changed from {changedFrom} )</span>}</span>
          </div>}
        {((!value && changedFrom)) &&
        <div>
          <span className="red-box">DELETED</span> <span style={{color:'red'}}>( Changed from {changedFrom} )</span>
        </div>}
      </div>
      }
    </div>
  );
}

class BPReview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showImage: false,
      showImageURL: '',
      showPDF: false,
      showPDFURL: '',
      justLoaded: true,
      changes: !props.isFromSubGroup ? props.changes : props.historyItemData ? props.historyItemData.changes : null,
      before: !props.isFromSubGroup ? props.before : props.historyItemData ? props.historyItemData.before : null
    }
  }

  componentDidMount() {
    if (this.props.rbosDict) {
      if (Object.keys(this.props.rbosDict).length == 0) {
        this.props.fetchGMPRBO('NLA_TEST');
      }
    }
    if (this.props.edi_programsDict) {
      if (Object.keys(this.props.edi_programsDict).length == 0) {
        this.props.fetchEDIPrograms();
      }
    }
    this.setState({
      justLoaded: false
    });
  }

  componentDidUpdate(prevProps) {
    const props = this.props;
    if (prevProps.historyItemData !== this.props.historyItemData){
      console.log('changed');
      this.setState({
        changes: !props.isFromSubGroup ? props.changes : props.historyItemData ? props.historyItemData.changes : null,
        before: !props.isFromSubGroup ? props.before : props.historyItemData ? props.historyItemData.before : null
      });
    }
  }

  showImage = (showImageURL) => {
    this.setState({ showImage: true, showImageURL });
  }

  hideImage = () => {
    this.setState({ showImage: false, showImageURL: '' });
  }

  showPDF = (showPDFURL) => {
    this.setState({ showPDF: true, showPDFURL });
  }

  hidePDF = () => {
    this.setState({ showPDF: false, showPDFURL: '' });
  }

  hideAlert = () => {
    this.props.eraseGMPAlertMsg();
    this.props.cancelReview();
  }

  hideAlertReturn = () => {
    this.props.eraseGMPAlertMsg();
    this.props.cancelProfileView();
  }

  renderDynamicAddress = () => {
    if (!this.state.before || !this.state.changes) {
      return null;
    }

    // collection of new addresses SHOW THESE
    let newAddresses = [];
    // collection of deleted addresses
    let deletedAddresses = [];
    // no changes collection;
    let noChangeAddresses = [];

    let shipBeforeAdds = this.state.before.additionalShippingAddresses ?
              helpers.removeAddressColonArr(convertIfString(this.state.before.additionalShippingAddresses)) : [];
    let beforeOnames = {};
    if (shipBeforeAdds.length > 0) {
      shipBeforeAdds.forEach((sa,i) => {
        beforeOnames[sa.oname] = sa;
      });
    }

    let shipChangeAdds = this.state.changes.additionalShippingAddresses ?
              helpers.removeAddressColonArr(convertIfString(this.state.changes.additionalShippingAddresses)) : [];
    let changeOnames = {};
    if (shipChangeAdds.length > 0) {
      shipChangeAdds.forEach((sa) => {
        if (!sa.oname) {
          newAddresses.push(sa);
        } else {
          changeOnames[sa.oname] = sa;
        }
      });
    }

    let changedAddresses = {};

    Object.keys(beforeOnames).forEach((f) => {
      if (!changeOnames.hasOwnProperty(f)) {
        // deleted
        deletedAddresses.push(beforeOnames[f]);
      } else {
        if (JSON.stringify(beforeOnames[f]) == JSON.stringify(changeOnames[f])) {
          // no changes
          noChangeAddresses.push(beforeOnames[f]);
        } else {
          // there are changes, compare these
          changedAddresses[f] = {
            before: beforeOnames[f],
            changes: changeOnames[f]
          };
        }
      }
    });

    console.log('----', this.state.changes, this.state.before);
    const afterDefaultShipping = this.state.changes ? this.state.changes.default_shipping : '';
    const beforeDefaultShipping = this.state.before ? this.state.before.default_shipping : '';
    const defShipStatus = afterDefaultShipping !== beforeDefaultShipping ? <span className="red-text">(Previous Default Shipping)</span> : ``;

    console.log('addresses BP Form');
    console.log(shipBeforeAdds);
    console.log(shipChangeAdds);
    console.log('no change addresses', noChangeAddresses);
    console.log('changed addresses', changedAddresses);
    console.log('new addresses', newAddresses);
    console.log('deleted addresses', deletedAddresses);

    let addresses = {},
        shipCount = 1;

    addresses.noChange = noChangeAddresses.map((add,i) => {
      shipCount++;
      return (
        <div key={`nhadd-${i}`}>
          <hr />
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <h4><strong>Shipping Address {shipCount} {afterDefaultShipping === add.shipname ? <span className={defShipStatus ? 'green-text' : ''}>(Default)</span> : ''}</strong></h4>
              {beforeDefaultShipping === add.shipname && <p>{defShipStatus}</p>}
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <CustomDynamicField
                value={add.shipname}
                field="Name"
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <CustomDynamicField
                value={add.add1}
                field="Address 1"
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <CustomDynamicField
                value={add.add2}
                field="Address 2"
              />
            </GridItem>

            <GridItem xs={12} sm={12} md={3}>
              <CustomDynamicField
                value={this.props.countries[add.country] ? this.props.countries[add.country] : ''}
                field="Country"
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <CustomDynamicField
                value={this.props.states[add.country] ?
                          this.props.states[add.country][add.state] ? this.props.states[add.country][add.state] : ''
                        : ''}
                field="State"
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <CustomDynamicField
                value={add.city}
                field="City"
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <CustomDynamicField
                value={add.zipcode}
                field="Postal Code"
              />
            </GridItem>

            <GridItem xs={12} sm={12} md={6}>
              {
                add.gstregno &&
                <CustomDynamicField
                  value={add.gstregno}
                  field="GST Reg.No."
                />
              }
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              {
                add.gstregtype &&
                <CustomDynamicField
                  value={this.props.gsttypes[add.gstregtype] ? this.props.gsttypes[add.gstregtype] : ''}
                  field="GST Reg. Type"
                />
              }
            </GridItem>
          </GridContainer>
        </div>
        )
    });

    addresses.changed = [];
    Object.keys(changedAddresses).forEach((oname) => {
      if (changedAddresses[oname].before && changedAddresses[oname].changes) {
        const before = changedAddresses[oname].before,
              changes = changedAddresses[oname].changes;
        shipCount++;
        console.log('changed ?', changes.shipname, before.shipname, changes.shipname == before.shipname);
        if (this.props.states && this.props.countries) {
          addresses.changed.push(
            <div key={`awadd-${oname}`}>
              <hr />
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <h4><strong>Shipping Address {shipCount} {afterDefaultShipping === changes.shipname ? <span className={defShipStatus ? 'green-text' : ''}>(Default)</span> : ''}</strong></h4>
                  {beforeDefaultShipping === changes.shipname && <p>{defShipStatus}</p>}
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomDynamicField
                    value={changes.shipname}
                    changedFrom={before.shipname}
                    type="edit"
                    field="Name"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomDynamicField
                    value={changes.add1}
                    changedFrom={before.add1}
                    type="edit"
                    field="Address 1"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomDynamicField
                    value={changes.add2}
                    changedFrom={before.add2}
                    type="edit"
                    field="Address 2"
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={3}>
                  <CustomDynamicField
                    value={this.props.countries[changes.country] ? this.props.countries[changes.country] : ''}
                    changedFrom={this.props.countries[before.country] ? this.props.countries[before.country] : ''}
                    type="edit"
                    field="Country"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomDynamicField
                    value={changes.state ? this.props.states[changes.country] ? this.props.states[changes.country][changes.state] : '' : ''}
                    changedFrom={before.state ? this.props.states[before.country] ? this.props.states[before.country][before.state] : '' : ''}
                    type="edit"
                    field="State"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomDynamicField
                    value={changes.city}
                    changedFrom={before.city}
                    type="edit"
                    field="City"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomDynamicField
                    value={changes.zipcode}
                    changedFrom={before.zipcode}
                    type="edit"
                    field="Postal Code"
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
                  {
                    (changes.gstregno || before.gstregno) &&
                    <CustomDynamicField
                      value={changes.gstregno}
                      changedFrom={before.gstregno}
                      type="edit"
                      field="GST Reg.No."
                    />
                  }
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  {
                    (changes.gstregtype || before.gstregtype) &&
                    <CustomDynamicField
                      value={this.props.gsttypes[changes.gstregtype] ? this.props.gsttypes[changes.gstregtype] : ''}
                      changedFrom={this.props.gsttypes[before.gstregtype] ? this.props.gsttypes[before.gstregtype] : ''}
                      type="edit"
                      field="GST Reg. Type"
                    />
                  }
                </GridItem>
              </GridContainer>
            </div>
          )
        }
      }
    });

    addresses.new = newAddresses.map((add,i) => {
      shipCount++;
      return (
        <div key={`nhadd-${i}`}>
          <hr />
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <h4><strong>Shipping Address {shipCount} {afterDefaultShipping === add.shipname ? <span className={defShipStatus ? 'green-text' : ''}>(Default)</span> : ''}</strong></h4>
              {beforeDefaultShipping === add.shipname && <p>{defShipStatus}</p>}
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <CustomDynamicField
                value={add.shipname}
                type="new"
                field="Name"
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <CustomDynamicField
                value={add.add1}
                type="new"
                field="Address 1"
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <CustomDynamicField
                value={add.add2}
                type="new"
                field="Address 2"
              />
            </GridItem>

            <GridItem xs={12} sm={12} md={3}>
              <CustomDynamicField
                value={this.props.countries[add.country] ? this.props.countries[add.country] : ''}
                type="new"
                field="Country"
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <CustomDynamicField
                value={this.props.states[add.country] ?
                          this.props.states[add.country][add.state] ? this.props.states[add.country][add.state] : ''
                        : ''}
                type="new"
                field="State"
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <CustomDynamicField
                value={add.city}
                type="new"
                field="City"
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <CustomDynamicField
                value={add.zipcode}
                type="new"
                field="Postal Code"
              />
            </GridItem>

            <GridItem xs={12} sm={12} md={6}>
              {
                add.gstregno &&
                <CustomDynamicField
                  value={add.gstregno}
                  type="new"
                  field="GST Reg.No."
                />
              }
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              {
                add.gstregtype &&
                <CustomDynamicField
                  value={this.props.gsttypes[add.gstregtype] ? this.props.gsttypes[add.gstregtype] : ''}
                  type="new"
                  field="GST Reg. Type"
                />
              }
            </GridItem>
          </GridContainer>
        </div>
        )
    });

    addresses.deleted = deletedAddresses.map((add, i) => {
        shipCount++;
        return (
          <div key={`dhadd-${i}`}>
            <hr />
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <h4><strong>Shipping Address {shipCount} {afterDefaultShipping === add.shipname ? '(Default)' : ''}</strong></h4>
                {beforeDefaultShipping === add.shipname && <p>{defShipStatus}</p>}
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <CustomDynamicField
                  value={add.shipname}
                  type="deleted"
                  field="Name"
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <CustomDynamicField
                  value={add.add1}
                  type="deleted"
                  field="Address 1"
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomDynamicField
                  value={add.add2}
                  type="deleted"
                  field="Address 2"
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={3}>
                <CustomDynamicField
                  value={this.props.countries[add.country] ? this.props.countries[add.country] : ''}
                  type="deleted"
                  field="Country"
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <CustomDynamicField
                  value={this.props.states[add.country] ?
                            this.props.states[add.country][add.state] ? this.props.states[add.country][add.state] : ''
                          : ''}
                  type="deleted"
                  field="State"
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <CustomDynamicField
                  value={add.city}
                  type="deleted"
                  field="City"
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <CustomDynamicField
                  value={add.zipcode}
                  type="deleted"
                  field="Postal Code"
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
                {
                  add.gstregno &&
                  <CustomDynamicField
                    value={add.gstregno}
                    type="deleted"
                    field="GST Reg.No."
                  />
                }
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                {
                  add.gstregtype &&
                  <CustomDynamicField
                    value={this.props.gsttypes[add.gstregtype] ? this.props.gsttypes[add.gstregtype] : ''}
                    type="deleted"
                    field="GST Reg. Type"
                  />
                }
              </GridItem>
            </GridContainer>
          </div>
          )
        });

      return addresses;
}

check2Contacts = (changes, before) => {
  let isEqual = true;
  if (changes.length !== before.length) {
    return false;
  }
  let objBefore = {}, objChanges = {},
      beforeArr = [], changesArr = [];
  changes.forEach((c) => {
    objChanges[c.name] = c;
    changesArr.push({
      name: c.name ? c.name : '',
      firstname: c.firstname ? c.firstname : '',
      lastname: c.lastname ? c.lastname : '',
      position: c.position ? c.position : '',
      email: c.email ? c.email : '',
      tel: c.tel ? c.tel : '',
      alttel: c.alttel ? c.alttel : '',
      mobile: c.mobile ? c.mobile : '',
      fax: c.fax ? c.fax : ''
    });
  });
  before.forEach((c) => {
    objBefore[c.name] = c;
    beforeArr.push({
      name: c.name ? c.name : '',
      firstname: c.firstname ? c.firstname : '',
      lastname: c.lastname ? c.lastname : '',
      position: c.position ? c.position : '',
      email: c.email ? c.email : '',
      tel: c.tel ? c.tel : '',
      alttel: c.alttel ? c.alttel : '',
      mobile: c.mobile ? c.mobile : '',
      fax: c.fax ? c.fax : ''
    });
  });
  if (Object.keys(objBefore).length !== Object.keys(objChanges).length) {
    return false;
  }
  if (JSON.stringify(beforeArr) !== JSON.stringify(changesArr)) {
    return false;
  }
  return isEqual;
}

renderDynamicContacts = () => {
  if (!this.state.before || !this.state.changes) {
    return null;
  }

  // collection of new addresses SHOW THESE
  let newContacts = [];
  // collection of deleted addresses
  let deletedContacts = [];
  // no changes collection;
  let noChangeContacts = [];
  let contBefore = this.state.before.additionalContacts ? convertIfString(this.state.before.additionalContacts) : [];
  let contChange = this.state.changes.additionalContacts ? convertIfString(this.state.changes.additionalContacts) : [];
  let contacts = {},
      contCount = 2;

  console.log('contchanges', contChange, contBefore);
  if (this.check2Contacts(contChange,contBefore)) {
    const noChange = contChange.map((cont, i) => {
      contCount++;
      let contHeader = `Contact ${contCount}`;
      const contGMP = cont.gmp ? cont.gmp : '';
      if (cont.name) {
        if (cont.name.indexOf('GMP AP') !== -1) { contHeader = 'TPP Accounts Payable Contact' }
        if (cont.name.indexOf('GMP PURCHASING') !== -1) { contHeader = 'TPP Purchasing Contact' }
        if (cont.name.indexOf('GMP OTHER') !== -1) { contHeader = 'TPP Other Contact' }
      }
          return (
        <GridContainer key={`accsonc-${i}`}>
          <GridItem xs={12} sm={12} md={12}>
            <h4><strong>{contHeader}</strong> {contGMP && <span> (TPP User ID: # {contGMP})</span>}</h4>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <CustomDynamicField
              value={cont.firstname}
              field="Firstname"
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={2}>
            <CustomField
              value={cont.position}
              field="Position"
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={2}>
            <CustomField
              value={cont.tel}
              field="Telephone"
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={2}>
            <CustomField
              value={cont.alttel}
              field="Alt Telephone"
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={2}>
            <CustomField
              value={cont.mobile}
              field="Mobile"
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <CustomDynamicField
              value={cont.lastname}
              field="Lastname"
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={2}>
            <CustomField
              value={cont.fax}
              field="Fax"
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <CustomField
              value={cont.email}
              field="E-mail"
            />
          </GridItem>
        </GridContainer>
        )
      });

    return {
      noChange,
      changed: null,
      new: null,
      deleted: null
    }
  }

  let beforeOnames = {};
  if (contBefore.length > 0) {
    contBefore.forEach((sa,i) => {
      if (sa.oname) {
        beforeOnames[sa.oname] = sa;
      }
    });
  }
  let changeOnames = {};
  if (contChange.length > 0) {
    contChange.forEach((sa) => {
      if (sa.oname) {
        changeOnames[sa.oname] = sa;
      } else {
        newContacts.push(sa);
      }
    });
  }

  let changedContacts = {};

  Object.keys(beforeOnames).forEach((f) => {
    if (!changeOnames.hasOwnProperty(f)) {
      // deleted
      deletedContacts.push(beforeOnames[f]);
    } else {
      if (JSON.stringify(beforeOnames[f]) == JSON.stringify(changeOnames[f])) {
        // no changes
        noChangeContacts.push(beforeOnames[f]);
      } else {
        // there are changes, compare these
        changedContacts[f] = {
          before: beforeOnames[f],
          changes: changeOnames[f]
        };
      }
    }
  });

  console.log('contacts BP Form');
  console.log(contBefore);
  console.log(contChange);
  console.log('no change contacts', noChangeContacts);
  console.log('changed contacts', changedContacts);
  console.log('new contacts', newContacts);
  console.log('deleted contacts', deletedContacts);

  contacts.noChange = noChangeContacts.map((cont, i) => {
    contCount++;
    let contHeader = `Contact ${contCount}`;
    const contGMP = cont.gmp ? cont.gmp : '';
    if (cont.name) {
      if (cont.name.indexOf('GMP AP') !== -1) { contHeader = 'TPP Accounts Payable Contact' }
      if (cont.name.indexOf('GMP PURCHASING') !== -1) { contHeader = 'TPP Purchasing Contact' }
      if (cont.name.indexOf('GMP OTHER') !== -1) { contHeader = 'TPP Other Contact' }
    }
        return (
      <GridContainer key={`ccsonc-${i}`}>
        <GridItem xs={12} sm={12} md={12}>
          <h4><strong>{contHeader}</strong> {contGMP && <span> (TPP User ID: # {contGMP})</span>}</h4>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <CustomDynamicField
            value={cont.firstname}
            field="Firstname"
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={2}>
          <CustomField
            value={cont.position}
            field="Position"
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={2}>
          <CustomField
            value={cont.tel}
            field="Telephone"
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={2}>
          <CustomField
            value={cont.alttel}
            field="Alt Telephone"
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={2}>
          <CustomField
            value={cont.mobile}
            field="Mobile"
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <CustomDynamicField
            value={cont.lastname}
            field="Lastname"
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={2}>
          <CustomField
            value={cont.fax}
            field="Fax"
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <CustomField
            value={cont.email}
            field="E-mail"
          />
        </GridItem>
      </GridContainer>
      )
    });

    contacts.changed = [];
    Object.keys(changedContacts).forEach((oname) => {
      if (changedContacts[oname].before && changedContacts[oname].changes) {
        const before = changedContacts[oname].before,
              changes = changedContacts[oname].changes;
        contCount++;
        let contHeader = `Contact ${contCount}`;
        if (changes.name) {
          if (changes.name.indexOf('GMP AP') !== -1) { contHeader = 'TPP Accounts Payable Contact' }
          if (changes.name.indexOf('GMP PURCHASING') !== -1) { contHeader = 'TPP Purchasing Contact' }
          if (changes.name.indexOf('GMP OTHER') !== -1) { contHeader = 'TPP Other Contact' }
        }

        contacts.changed.push(
          <div key={`ccaonc-${oname}`}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <h4><strong>{contHeader}</strong></h4>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <CustomDynamicField
                  value={changes.firstname}
                  changedFrom={before.firstname}
                  type="edit"
                  field="Name"
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={2}>
                <CustomDynamicField
                  value={changes.position}
                  changedFrom={before.position}
                  field="Position"
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={2}>
                <CustomDynamicField
                  value={changes.tel}
                  changedFrom={before.tel}
                  type="edit"
                  field="Telephone"
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={2}>
                <CustomDynamicField
                  value={changes.alttel}
                  changedFrom={before.alttel}
                  field="Alt Telephone"
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={2}>
                <CustomDynamicField
                  value={changes.mobile}
                  changedFrom={before.mobile}
                  field="Mobile"
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <CustomDynamicField
                  value={changes.lastname}
                  changedFrom={before.lastname}
                  type="edit"
                  field="Lastname"
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={2}>
                <CustomDynamicField
                  value={changes.fax}
                  changedFrom={before.fax}
                  type="edit"
                  field="Fax"
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomDynamicField
                  value={changes.email}
                  changedFrom={before.email}
                  type="edit"
                  field="E-mail"
                />
              </GridItem>
            </GridContainer>
          </div>
        );
      }
    });

    contacts.new = newContacts.map((cont, i) => {
      contCount++;
      let contHeader = `Contact ${contCount}`;
      const contGMP = cont.gmp ? cont.gmp : '';
      if (cont.name) {
        if (cont.name.indexOf('GMP AP') !== -1) { contHeader = 'TPP Accounts Payable Contact' }
        if (cont.name.indexOf('GMP PURCHASING') !== -1) { contHeader = 'TPP Purchasing Contact' }
        if (cont.name.indexOf('GMP OTHER') !== -1) { contHeader = 'TPP Other Contact' }
      }
          return (
        <GridContainer key={`ccconc-${i}`}>
          <GridItem xs={12} sm={12} md={12}>
            <h4><strong>{contHeader}</strong> {contGMP && <span> (TPP User ID: # {contGMP})</span>}</h4>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <CustomDynamicField
              value={cont.firstname}
              type="new"
              field="Firstname"
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={2}>
            <CustomDynamicField
              value={cont.position}
              type="new"
              field="Position"
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={2}>
            <CustomDynamicField
              value={cont.tel}
              type="new"
              field="Telephone"
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={2}>
            <CustomDynamicField
              value={cont.alttel}
              type="new"
              field="Alt Telephone"
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={2}>
            <CustomDynamicField
              value={cont.mobile}
              type="new"
              field="Mobile"
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <CustomDynamicField
              value={cont.lastname}
              type="new"
              field="Lastname"
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={2}>
            <CustomDynamicField
              value={cont.fax}
              type="new"
              field="Fax"
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <CustomDynamicField
              value={cont.email}
              type="new"
              field="E-mail"
            />
          </GridItem>
        </GridContainer>
        )
      });

      contacts.deleted = deletedContacts.map((cont, i) => {
        contCount++;
        let contHeader = `Contact ${contCount}`;
        const contGMP = cont.gmp ? cont.gmp : '';
        if (cont.name) {
          if (cont.name.indexOf('GMP AP') !== -1) { contHeader = 'TPP Accounts Payable Contact' }
          if (cont.name.indexOf('GMP PURCHASING') !== -1) { contHeader = 'TPP Purchasing Contact' }
          if (cont.name.indexOf('GMP OTHER') !== -1) { contHeader = 'TPP Other Contact' }
        }
            return (
          <GridContainer key={`ccconc-${i}`}>
            <GridItem xs={12} sm={12} md={12}>
              <h4><strong>{contHeader}</strong> {contGMP && <span> (TPP User ID: # {contGMP})</span>}</h4>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <CustomDynamicField
                value={cont.firstname}
                type="deleted"
                field="Firstname"
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={2}>
              <CustomDynamicField
                value={cont.position}
                type="deleted"
                field="Position"
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={2}>
              <CustomDynamicField
                value={cont.tel}
                type="deleted"
                field="Telephone"
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={2}>
              <CustomDynamicField
                value={cont.alttel}
                type="deleted"
                field="Alt Telephone"
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={2}>
              <CustomDynamicField
                value={cont.mobile}
                type="deleted"
                field="Mobile"
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <CustomDynamicField
                value={cont.lastname}
                type="deleted"
                field="Lastname"
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={2}>
              <CustomDynamicField
                value={cont.fax}
                type="deleted"
                field="Fax"
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <CustomDynamicField
                value={cont.email}
                type="deleted"
                field="E-mail"
              />
            </GridItem>
          </GridContainer>
          )
        });

    return contacts;
}

  renderBooleanLabel = (field, fieldName) => {
    const { changes, before } = this.props;

    let value = typeof changes[fieldName] === 'boolean' ? changes[fieldName] ? 'Yes' : 'No' : '',
        changedFrom = typeof before[fieldName] === 'boolean' ? before[fieldName] ? 'Yes' : 'No' : '';

    return (
      <div style={{marginBottom:'10px'}}>
        <div style={{color:'#aaa',fontSize:'10px',fontWeight:'400'}}>{field}:</div>
        {(!value && changedFrom) && <div><span className="red-box">DELETED</span> ( was {changedFrom})</div>}
        {((value && changedFrom) && (value != changedFrom)) && <div>
          <span className="green-new">{value}</span> <span style={{color:'red'}}>( Changed from {changedFrom} )</span>
        </div>}
        {(value && !changedFrom) && <div className="green-new">{value}</div>}
        {((value && changedFrom) && (value == changedFrom)) && <div>{value}</div>}
      </div>
    );
  }

  renderZeroLabel = (field, fieldName) => {
    const { changes, before } = this.props;

    let value = (changes[fieldName] !== null && changes[fieldName] !== undefined) ? changes[fieldName].toString() : '',
        changedFrom = (before[fieldName] !== null && before[fieldName] !== undefined) ? before[fieldName].toString() : '';
    console.log(field, value, changedFrom);
        if (fieldName === 'threshold_overlook' || fieldName === 'surcharge_overlook') {
          if (value == '0' || value) {
            if (value == '0') {
              value = 'No';
            } else if (value == '1') {
              value = 'Yes';
            } else {
              value = '';
            }
          }

          if (changedFrom == '0' || changedFrom) {
            if (changedFrom == '0') {
              changedFrom = 'No';
            } else if (changedFrom == '1') {
              changedFrom = 'Yes';
            } else {
              changedFrom = '';
            }
          } else {
            changedFrom = '';
          }
        }

        if (fieldName === 'assessee_type') {
          if (value == '0' || value) {
            if (value == '0') {
              value = 'Company';
            } else if (value == '1') {
              value = 'Others';
            } else {
              value = '';
            }
          } else {
            value = '';
          }

          if (changedFrom == '0' || changedFrom) {
            if (changedFrom == '0') {
              changedFrom = 'Company';
            } else if (changedFrom == '1') {
              changedFrom = 'Others';
            } else {
              changedFrom = '';
            }
          } else {
            changedFrom = '';
          }
        }

        if (fieldName === 'accrual_criteria') {
          if (value == '0' || value) {
            if (value == '0') {
              value = 'Cash';
            } else if (value == '1') {
              value = 'Accrual';
            } else {
              value = '';
            }
          }

          if (changedFrom == '0' || changedFrom) {
            if (changedFrom == '0') {
              changedFrom = 'Cash';
            } else if (changedFrom == '1') {
              changedFrom = 'Accrual';
            } else {
              changedFrom = '';
            }
          } else {
            changedFrom = '';
          }
        }

        console.log(field, value, changedFrom);
        return (
          <div style={{marginBottom:'10px'}}>
            <div style={{color:'#aaa',fontSize:'10px',fontWeight:'400'}}>{field}:</div>
            {(!value && changedFrom) && <div><span className="red-box">DELETED</span> ( was {changedFrom})</div>}
            {((value && changedFrom) && (value != changedFrom)) && <div>
              <span className="green-new">{value}</span> <span style={{color:'red'}}>( Changed from {changedFrom} )</span>
            </div>}
            {(value && !changedFrom) && <div className="green-new">{value}</div>}
            {((value && changedFrom) && (value == changedFrom)) && <div>{value}</div>}
          </div>
        );
  }

  renderLabel = (field, fieldName) => {
    const { changes, before, payterms, countries, gsttypes } = this.props;

    if (changes && before) {
      let value = changes[fieldName] ? typeof changes[fieldName] === 'string' ? changes[fieldName].trim() : changes[fieldName] : '',
          changedFrom = before[fieldName] ? typeof before[fieldName] === 'string' ? before[fieldName].trim() : before[fieldName] : '';

      if (fieldName === 'billing_addressname' || fieldName === 'shipping_addressname') {
        console.log('removing value address', value, fieldName);
        value = helpers.removeAddressColon(value);
        changedFrom = helpers.removeAddressColon(changedFrom);
      }

      if (fieldName === 'credit_limit') {
        console.log('credit limit is this one ->', value);
        if (value && value !== 0) {
          value = commatize(value);
        }

        if (changedFrom && changedFrom !== 0) {
          changedFrom = commatize(changedFrom);
        }
      }

      if (fieldName === 'payterms') {
        if (payterms) {
          if (value) {
            value = payterms[value];
          }

          if (changedFrom) {
            changedFrom = payterms[changedFrom];
          }
        }
      }

      if (fieldName === 'billing_country' || fieldName === 'shipping_country') {
        if (countries) {
          if (value && countries[value]) {
            value = countries[value];
          }

          if (changedFrom && countries[changedFrom]) {
            changedFrom = countries[changedFrom];
          }
        }
      }

      if (fieldName === 'billing_gstregtype' || fieldName === 'shipping_gstregtype') {
        if (gsttypes) {
          if (value) {
            value = gsttypes[value];
          }

          if (changedFrom) {
            changedFrom = gsttypes[changedFrom];
          }
        }
      }

      return (
        <div style={{marginBottom:'10px'}}>
          <div style={{color:'#aaa',fontSize:'10px',fontWeight:'400'}}>{field}:</div>
          {(!value && changedFrom) && <div><span className="red-box">DELETED</span> ( was {changedFrom})</div>}
          {((value && changedFrom) && (value !== changedFrom)) && <div>
            <span className="green-new">{value}</span> <span style={{color:'red'}}>( Changed from {changedFrom} )</span>
          </div>}
          {(value && !changedFrom) && <div className="green-new">{value}</div>}
          {((value && changedFrom) && (value === changedFrom)) && <div>{value}</div>}
        </div>
      );
    }

  }

  renderSubgroupLabel = (field, fieldName) => {
    let { changes, before, groupcodes } = this.props;

    if (changes && before) {
      if (groupcodes) {
        let value = changes[fieldName] ? changes[fieldName] : '',
            changedFrom = before[fieldName] ? before[fieldName] : '';

        if (value) {
          value = groupcodes[value] ? groupcodes[value] : '';
        }

        if (changedFrom) {
          changedFrom = groupcodes[changedFrom] ? groupcodes[changedFrom] : '';
        }

        return (
          <div style={{marginBottom:'10px'}}>
            <div style={{color:'#aaa',fontSize:'10px',fontWeight:'400'}}>{field}:</div>
            {(!value && changedFrom) && <div><span className="red-box">DELETED</span> ( was {changedFrom})</div>}
            {((value && changedFrom) && (value !== changedFrom)) && <div>
              <span className="green-new">{value}</span> <span style={{color:'red'}}>( Changed from {changedFrom} )</span>
            </div>}
            {(value && !changedFrom) && <div className="green-new">{value}</div>}
            {((value && changedFrom) && (value === changedFrom)) && <div>{value}</div>}
          </div>
        );
      }
    }
    return null;
  }

  renderStateLabel = (field, fieldName) => {
    let { changes, before, states } = this.props;
    if (!states) {
      return null;
    }

    if (changes && before) {
      let value = changes[fieldName] ? changes[fieldName] : '',
          changedFrom = before[fieldName] ? before[fieldName] : '';

      if (value) {
        let type = 'shipping';
        if (fieldName === 'billing_state') {
          type = 'billing';
        }
        let country;
        if (changes[`${type}_country`]) {
          country = changes[`${type}_country`];
        }
        if (states[country]) {
          value = states[country][value];
        }
      }

      if (changedFrom) {
        let type = 'shipping';
        if (fieldName === 'billing_state') {
          type = 'billing';
        }
        let country;
        country = before[`${type}_country`];
        if (states[country]) {
          changedFrom = states[country][changedFrom];
        }
      }

      return (
        <div style={{marginBottom:'10px'}}>
          <div style={{color:'#aaa',fontSize:'10px',fontWeight:'400'}}>{field}:</div>
          {(!value && changedFrom) && <div><span className="red-box">DELETED</span> ( was {changedFrom})</div>}
          {((value && changedFrom) && (value !== changedFrom)) && <div>
            <span className="green-new">{value}</span> <span style={{color:'red'}}>( Changed from {changedFrom} )</span>
          </div>}
          {(value && !changedFrom) && <div className="green-new">{value}</div>}
          {((value && changedFrom) && (value === changedFrom)) && <div>{value}</div>}
        </div>
      );
    }
  }

  renderPhoneLabel = (field, fieldName) => {
    const { changes, before } = this.props;

    if (changes && before) {
      let value = changes[fieldName] ? changes[fieldName] : '',
          changedFrom = before[fieldName] ? before[fieldName] : '';

      return (
        <div style={{marginBottom:'10px'}}>
          <div style={{color:'#aaa',fontSize:'10px',fontWeight:'400'}}>{field}:</div>
          {(!value && changedFrom) && <div><span className="red-box">DELETED</span> ( was {normalizePhone(changedFrom)})</div>}
          {((value && changedFrom) && (value !== changedFrom)) && <div>
            <span className="green-new">{normalizePhone(value)}</span> <span style={{color:'red'}}>( Changed from {normalizePhone(changedFrom)} )</span>
          </div>}
          {(value && !changedFrom) && <div className="green-new">{normalizePhone(value)}</div>}
          {((value && changedFrom) && (value === changedFrom)) && <div>{normalizePhone(value)}</div>}
        </div>
      );
    }
  }

  renderWTaxCodes = () => {
    let { changes, before } = this.props;

    if (changes && before) {
      const value = changes.wtcodes ? typeof changes.wtcodes === 'string' ? changes.wtcodes : changes.wtcodes.join(',') : '';
      const changedFrom = before.wtcodes ? typeof before.wtcodes === 'string' ? before.wtcodes : before.wtcodes.join(',') : '';

      console.log('owhtopts ? ',this.props.owhtopts);
      if (this.props.owhtopts) {
        const owhtopts = this.props.owhtopts;

        return (
          <div style={{marginBottom:'10px'}}>
            {(!value && changedFrom) && <div><span className="red-box">DELETED</span> ( was {normalizeWTcodes(changedFrom, owhtopts)})</div>}
            {((value && changedFrom) && (value !== changedFrom)) && <div>
              <span className="green-new">{normalizeWTcodes(value, owhtopts)}</span> <span style={{color:'red'}}>( Changed from {normalizeWTcodes(changedFrom, owhtopts)} )</span>
            </div>}
            {(value && !changedFrom) && <div className="green-new">{normalizeWTcodes(value, owhtopts)}</div>}
            {((value && changedFrom) && (value === changedFrom)) && <div>{normalizeWTcodes(value, owhtopts)}</div>}
          </div>
        );
      }
      return 'n/a';
    }
  }

  renderChecks = (field) => {
    if (field === 'rbosChecked') {
      const rbos = this.state.changes[field],
            rbosChecked = Object.values(rbos);
      const rbosArrDat = rbos ? Object.keys(rbos).map(rr => {
        const rro = rr.split('_');
        return {
          id: rro[0],
          rbo: rro[1],
          gmp_id: rro[2],
          a_id: rro[3],
          name: this.props.rbosDict[rro[1]]
        }
      }) : [];

      const rbosArr = this.props.rbosDict ? [].concat(rbosArrDat)
      .sort((a, b) => (a.name > b.name) ? 1 : -1) : rbosArrDat;

      const rboHTML = rbosArr.map((rbo,i) => {
        const rbo_id = rbo.id ? rbo.id : 0,
              gmp_id = rbo.gmp_id ? rbo.gmp_id : 0,
              a_id = rbo.a_id ? rbo.a_id : 0;
        const combo = `${rbo_id}_${rbo.rbo}_${gmp_id}_${a_id}`;
        let status = '';
        if (a_id && a_id != 0) {
          status = 'Active';
        } else if ((rbo_id && rbo_id != 0) && (gmp_id && gmp_id != 0)) {
          status = 'Pending';
        } else if ((a_id == 0 && rbo_id == 0) || (gmp_id == 0 && a_id == 0)) {
          status = 'New';
        }
        let action = '';
        if (rbos[combo]) {
          if (status === 'New') {
            action = '';
          }
          if (status === 'Pending') {
            action = '';
          }
          if (status === 'Active') {
            action = '';
          }
        } else {
          if (status === 'New') {
            status = 'Deleted';
          }
          if (status === 'Pending') {
            status = 'Pending Deleted';
          }
          if (status === 'Active') {
            status = 'Active Deleted';
          }
        }

        let colorClass = '';
        if (status.indexOf('Delete') !== -1) {
          colorClass = 'red-text';
        }
        if (status.indexOf('New') !== -1) {
          colorClass = 'green-text';
        }
        return <div key={`rboss${i}`} className={colorClass}>
          ({status}) {this.props.rbosDict[rbo.rbo]} {action}
        </div>
      });

      return rboHTML;
    }

    if (field === 'ediProgramsChecked') {
      const edis = this.state.changes[field],
            vcs = this.state.changes.vendorCodes,
            ediProgramsChecked = Object.values(edis);
      const edisArrDat = edis ? Object.keys(edis).map(rr => {
        const rro = rr.split('_');
        return {
          id: rro[0],
          edi_program: rro[1].toUpperCase(),
          gmp_id: rro[2],
          a_id: rro[3],
          new_id: rro[4] ? rro[4] : '',
          name: this.props.edi_programsDict[rro[1].toUpperCase()]
        }
      }) : [];

      const edisArr = this.props.edi_programsDict ? [].concat(edisArrDat)
      .sort((a, b) => (a.name > b.name) ? 1 : -1) : edisArrDat;

      const edisHTML = edisArr.map((edi,i) => {
        if (edi.edi_program) {
          const gmp_id = edi.gmp_id ? edi.gmp_id : 0,
                a_id = edi.a_id ? edi.a_id : 0,
                edi_id = edi.id ? edi.id : 0,
                new_id = edi.new_id ? edi.new_id : '';
          const combo = !new_id ? `${edi_id}_${edi.edi_program}_${gmp_id}_${a_id}` : `${edi_id}_${edi.edi_program}_${gmp_id}_${a_id}_${new_id}`;
          const code = vcs[combo];
          let status = '';
          if (a_id && a_id != 0) {
            status = 'Active';
          } else if ((edi_id && edi_id != 0) && (gmp_id && gmp_id != 0)) {
            status = 'Pending';
          } else if (a_id == 0 && edi_id == 0 || (gmp_id == 0 && a_id == 0)) {
            status = 'New';
          }
          let action = '';
          if (edis[combo]) {
            if (status === 'New') {
              action = '';
            }
            if (status === 'Pending') {
              action = '';
            }
            if (status === 'Active') {
              action = '';
            }
          } else {
            if (status === 'New') {
              status = 'Deleted';
            }
            if (status === 'Pending') {
              status = 'Pending Deleted';
            }
            if (status === 'Active') {
              status = 'Active Deleted';
            }
          }
          let colorClass = '';
          if (status.indexOf('Delete') !== -1) {
            colorClass = 'red-text';
          }
          if (status.indexOf('New') !== -1) {
            colorClass = 'green-text';
          }
          if (action !== 'will be rejected' && action !== 'not in here') {
            return <div key={`rboss${i}`} className={colorClass}>
              ({status}) {this.props.edi_programsDict[edi.edi_program.toUpperCase()]} / {code} {action}
            </div>
          } else {
            return null
          }
        }
        return null;
      });

      return edisHTML;
    }

  }

  checkForChanges = () => {
    let hasChanged = false;
    const b = this.state.before;
    const a = this.state.changes;
    const checks = [
      [b.bp_subgroup, a.bp_subgroup, 'subgroup'],
      [b.currency, a.currency, 'currency'],
      [b.gen_tel, a.gen_tel, 'gen.tel'],
      [b.gen_fax, a.gen_fax, 'gen.fax'],
      [b.payterms, a.payterms, 'pay terms'],
      [b.credit_limit, a.credit_limit, 'credit limit'],
      [b.notes, a.notes, 'notes'],
      [b.rfc_curp, a.rfc_curp, 'rfc_curp'],
      [b.panno, a.panno, 'panno'],
      [b.gstin, a.gstin, 'gstin'],
      [b.nit, a.nit, 'nit'],
      [b.old_code, a.old_code, 'old code'],
      [b.certificate_num, a.certificate_num, 'certificate_num'],
      [b.resale_cert, a.resale_cert, 'resale_cert'],
      [b.resale_cert_filename, a.resale_cert_filename, 'resale_cert_filename'],
      [b.resale_cert_paths, a.resale_cert_paths, 'resale_cert_paths'],
      [b.resale_certificate_entry, a.resale_certificate_entry, 'resale_certificate_entry'],
      [b.resale_certificate_path, a.resale_certificate_path, 'resale_certificate_path'],

      [helpers.removeAddressColon(b.billing_addressname), helpers.removeAddressColon(a.billing_addressname), 'billing addressname'],
      [b.billing_address1, a.billing_address1, 'billing address1'],
      [b.billing_address2, a.billing_address2, 'billing address2'],
      [b.billing_city, a.billing_city, 'billing city'],
      [b.billing_state, a.billing_state, 'billing state'],
      [b.billing_country, a.billing_country, 'billing country'],
      [b.billing_gstregno, a.billing_gstregno, 'billing gstregno'],
      [b.billing_gstregtype, a.billing_gstregtype, 'billing gstregtype'],

      [helpers.removeAddressColon(b.shipping_addressname), helpers.removeAddressColon(a.shipping_addressname), 'shipping addressname'],
      [b.shipping_address1, a.shipping_address1, 'shipping address1'],
      [b.shipping_address2, a.shipping_address2, 'shipping address2'],
      [b.shipping_city, a.shipping_city, 'shipping city'],
      [b.shipping_state, a.shipping_state, 'shipping state'],
      [b.shipping_country, a.shipping_country, 'shipping country'],
      [b.shipping_gstregno, a.shipping_gstregno, 'shipping gstregno'],
      [b.shipping_gstregtype, a.shipping_gstregtype, 'shipping gstregtype'],

      [b.accounts_payable_name, a.accounts_payable_name, 'ap name'],
      [b.accounts_payable_position, a.accounts_payable_position, 'ap position'],
      [b.accounts_payable_tel, a.accounts_payable_tel, 'ap tel'],
      [b.accounts_payable_alttel, a.accounts_payable_alttel, 'ap alttel'],
      [b.accounts_payable_mobile, a.accounts_payable_mobile, 'ap mobile'],
      [b.accounts_payable_fax, a.accounts_payable_fax, 'ap fax'],
      [b.accounts_payable_email, a.accounts_payable_email, 'ap email'],

      [b.purchasing_name, a.purchasing_name, 'ap name'],
      [b.purchasing_position, a.purchasing_position, 'ap position'],
      [b.purchasing_tel, a.purchasing_tel, 'ap tel'],
      [b.purchasing_alttel, a.purchasing_alttel, 'ap alttel'],
      [b.purchasing_mobile, a.purchasing_mobile, 'ap mobile'],
      [b.purchasing_fax, a.purchasing_fax, 'ap fax'],
      [b.purchasing_email, a.purchasing_email, 'ap email'],

    ];
    checks.forEach(function(c) {
      if (!compareStr(c[0], c[1], c[2])) hasChanged = true;
    });

    // shipping addresses
    const fieldsToCheck2 = ['add1','add2','city','country','gstregno','gstregtype',
          'state','zipcode'];
    if (b.additionalShippingAddresses && a.additionalShippingAddresses) {
      if (typeof b.additionalShippingAddresses !== 'string' &&
      typeof a.additionalShippingAddresses !== 'string') {
        if (b.additionalShippingAddresses.length != a.additionalShippingAddresses) {
          console.log('changed 1');
          hasChanged = true;
        }
        if (b.additionalShippingAddresses.length > 0 && a.additionalShippingAddresses.length > 0) {
          const beforeAddress = {};
          const afterAddress = {};
          b.additionalShippingAddresses.forEach((baa) => {
            beforeAddress[baa.shipname] = baa;
          });
          a.additionalShippingAddresses.forEach((aaa) => {
            afterAddress[aaa.shipname] = aaa;
          });
          Object.keys(beforeAddress).forEach((bname) => {
            if (afterAddress.hasOwnProperty(bname)) {
              fieldsToCheck2.forEach((f) => {
                if (beforeAddress[bname][f] !== afterAddress[bname][f]) {
                  console.log('changed 2');
                  hasChanged = true;
                }
              });
            } else {
              console.log('changed 3');
              hasChanged = true;
            }
          });
        }
      }
    }

    // contacts
    const fieldsToCheck = ['alttel','email','fax','firstname','lastname','mobile','position','tel'];
    if (b.additionalContacts && a.additionalContacts) {
      if (typeof b.additionalContacts !== 'string' && typeof a.additionalContacts !== 'string') {
        if (b.additionalContacts.length !== a.additionalContacts.length) {
          console.log('changed 4');
          hasChanged = true;
        }
        if (b.additionalContacts.length > 0 && a.additionalContacts.length > 0) {
          const beforeContacts = {};
          const afterContacts = {};
          b.additionalContacts.forEach((bac) => {
            beforeContacts[bac.name] = bac;
          });
          a.additionalContacts.forEach((aac) => {
            afterContacts[aac.name] = aac;
          });
          Object.keys(beforeContacts).forEach((bname) => {
            if (afterContacts.hasOwnProperty(bname)) {
              fieldsToCheck.forEach((f) => {
                if (beforeContacts[bname][f] !== afterContacts[bname][f]) {
                  console.log('changed 5');
                  hasChanged = true;
                }
              });
            } else {
              console.log('changed 6');
              hasChanged = true;
            }
          });
        }
      }
    }

    // edi/vcs - rbos
    if (b.vendorCodes && a.vendorCodes) {
      const bvc = JSON.stringify(b.vendorCodes);
      const avc = JSON.stringify(a.vendorCodes);
      if (bvc !== avc) {
        console.log('changed 7', bvc, avc);
        hasChanged = true;
      }
    }

    if (b.ediProgramsChecked && a.ediProgramsChecked) {
      const bep = JSON.stringify(b.ediProgramsChecked);
      const aep = JSON.stringify(a.ediProgramsChecked);
      if (bep !== aep) {
        console.log('changed 8', bep, aep);
        hasChanged = true;
      }
    }

    if (b.rbosChecked && a.rbosChecked) {
      const brc = JSON.stringify(b.rbosChecked);
      const arc = JSON.stringify(a.rbosChecked);
      if (brc !== arc) {
        console.log('changed 9', brc, arc);
        hasChanged = true;
      }
    }

    return hasChanged;
  }

  render() {
    console.log('BPReview, bp changed',this.state,this.props);
    if (!this.state.changes || !this.state.before || this.props.states === {} || this.props.countries === {}) {
      return null;
    }

    const {
      classes,
      handleApprove,
      handleDeny,

      owhtopts,
      countries,
      states,
      payterms,
      currencies,
      gsttypes,
      groupcodes,
      currentBPType,
      changes,

      isFromSubGroup,
      subGroupData,
      modalMetaData

    } = this.props;
    let bp = { ...this.state.changes };
    if (!bp) {
      return null;
    }

    const currentDB = this.props.currentDB ? this.props.currentDB : bp.DB ? bp.DB : bp.currentDB;
    let hasChanged = currentDB.indexOf('NIN') !== -1 ? true : this.checkForChanges();
    if (this.props.BPaction === 'PENDING') {
      hasChanged = true;
    }

    let gmpStatus = '';
    const metaData = this.props.modalMetaData;
    let title = 'Business Partner Entry Review';
    if (metaData) {
      console.log('meta-date',metaData.date_added);
      const activity = metaData.activity,
            user = metaData.user,
            date_added = metaData.date_added ? moment(metaData.date_added).format("MM/DD/YYYY hh:mm A") : '',
            submission_id = metaData.submission_id;
      title = `${activity} by ${user} on ${date_added}`;
    }

    const billing_country = countries[bp.billing_country] ? countries[bp.billing_country] : '';
    const shipping_country = countries[bp.shipping_country] ? countries[bp.shipping_country] : '';
    const billing_state = states[bp.billing_country] ?
                            states[bp.billing_country][bp.billing_state] ? states[bp.billing_country][bp.billing_state] : ''
                          : '';
    const shipping_state = states[bp.shipping_country] ?
                            states[bp.shipping_country][bp.shipping_state] ? states[bp.shipping_country][bp.shipping_state] : ''
                          : '';

    console.log(billing_country, shipping_country, billing_state, shipping_state, this.props);
    let billing_gstregtype = '',
        shipping_gstregtype = '';
    if (gsttypes) {
      if (gsttypes.length > 0) {
        gsttypes.forEach((g) => {
          if (g.id === bp.billing_gstregtype) {
            billing_gstregtype = g.type;
          }
          if (g.id === bp.shipping_gstregtype) {
            shipping_gstregtype = g.type;
          }
        });
      }
    }
    const props = this.props;
    const isDisabled = true;

    let isNIN = false;
    if (currentDB === 'NIN_TEST' || currentDB === 'NIN_LIVE') {
      isNIN = true;
    }

    let isNMX = false;
    if (currentDB === 'NMX_TEST' || currentDB === 'NMX_LIVE') {
      if (bp.billing_country === 'MX') {
        isNMX = true;
      }
    }

    let isNKR = false;
    if (currentDB === 'NKR_TEST' || currentDB === 'NKR_LIVE') {
      isNKR = true;
    }

    let isNGT = false;
    if (currentDB === 'NGT_TEST' || currentDB === 'NGT_LIVE') {
      if (bp.billing_country === 'GT') {
        isNGT = true;
      }
    }

    let isNAS = false;
    if (currentDB === 'NAS_TEST' || currentDB === 'NAS_LIVE') {
      isNAS = true;
    }

    let isWithhold = false;
    if (bp.withholding) {
      isWithhold = true;
    }

    let payterm = '';
    if (bp.payterms) {
      if (this.props.ptts) {
        payterm = this.props.ptts[bp.payterms];
      }
    }
    const defaultShipping = bp.default_shipping;
    const afterDefaultShipping = this.state.changes ? this.state.changes.default_shipping : '';
    const beforeDefaultShipping = this.state.before ? this.state.before.default_shipping : '';
    const defShipStatus = afterDefaultShipping !== beforeDefaultShipping ? <span className="red-text">(Previous Default Shipping)</span> : ``;

    const additionalAddresses = this.renderDynamicAddress();
    const deletedAddresses = additionalAddresses.deleted;
    const noChangeAddresses = additionalAddresses.noChange;
    const newAddresses = additionalAddresses.new;
    const changedAddresses = additionalAddresses.changed;

    const additionalContacts = this.renderDynamicContacts();
    const deletedContacts = additionalContacts.deleted;
    const noChangeContacts = additionalContacts.noChange;
    const newContacts = additionalContacts.new;
    const changedContacts = additionalContacts.changed;

    let accountsPayableHeader = 'Contact 1';
    if (bp.accounts_payable_name.indexOf('GMP AP') !== -1) { accountsPayableHeader = 'TPP Accounts Payable Contact' }
    if (bp.accounts_payable_name.indexOf('GMP PURCHASING') !== -1) { accountsPayableHeader = 'TPP Purchasing Contact' }
    if (bp.accounts_payable_name.indexOf('GMP OTHER') !== -1) { accountsPayableHeader = 'TPP Other Contact' }
    const accountsPayableGMP = bp.accounts_payable_gmp ? bp.accounts_payable_gmp : '';

    let purchasingHeader = 'Contact 2';
    if (bp.purchasing_name) {
      if (bp.purchasing_name.indexOf('GMP AP') !== -1) { purchasingHeader = 'TPP Accounts Payable Contact' }
      if (bp.purchasing_name.indexOf('GMP PURCHASING') !== -1) { purchasingHeader = 'TPP Purchasing Contact' }
      if (bp.purchasing_name.indexOf('GMP OTHER') !== -1) { purchasingHeader = 'TPP Other Contact' }
    }
    const purchasingGMP = bp.purchasing_gmp ? bp.purchasing_gmp : '';


    let lastPosition = false,
        firstPosition = false,
        isSingle = true,
        pos = null;
    if (isFromSubGroup && subGroupData) {
      if (subGroupData.length > 1) {
        isSingle = false;
      }
      const id = modalMetaData.id;
      if (subGroupData[0].id === id) {
        firstPosition = true;
      } else if (subGroupData[subGroupData.length-1].id === id) {
        lastPosition = true;
      }
      subGroupData.forEach((sgd, i) => {
        if (sgd.id == id) {
          pos = i;
        }
      });
    }

    let notInPending = false;
    if (this.props.isPending) {
      if (this.props.isPending.status) {
        if (this.props.isPending.status === 'ok') {
          notInPending = true;
        }
      }
    }
    console.log('is pending ?', notInPending, this.props.isPending);

    let current_resale_filepath = '', sap_resale_link = '';
    if (bp.resale_certificate_path) {
      current_resale_filepath = bp.resale_certificate_path.split('\\SAP\\B1_SHR2\\ATTACHMENTS\\RESALECERTS\\')[1];
      sap_resale_link = devStatus === 'live' ? `https://portal2.natcoglobal.com/api/common/rsc/${current_resale_filepath}` : `https://staging.portal2.natcoglobal.com/api/common/rsc/${current_resale_filepath}`;
    }

    let rbosChecked = false,
        ediProgramsChecked = false;
    if (bp.ediProgramsChecked) {
      if (Object.keys(bp.ediProgramsChecked).length > 0) {
        ediProgramsChecked = true;
      }
    }
    if (bp.rbosChecked) {
      if (Object.keys(bp.rbosChecked).length > 0) {
        rbosChecked = true;
      }
    }

    return (
  <div style={{textAlign:'left'}} className="dialog-bgg">
    <Dialog
        classes={{
            root: classes.center,
            paper: classes.modal
        }}
        style={{overflow:'hidden !important'}}
        open={props.open}
        keepMounted
        fullWidth={true}
        maxWidth="md"
        onClose={() => {
          this.props.close()
        }}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description">
        <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}>

            <div style={{textAlign:'center',marginTop:'20px'}}>
              {
                (isFromSubGroup && !isSingle) &&
                <Button2
                  color="default"
                  onClick={() => {
                    const dat = subGroupData[pos-1];
                    console.log('backwards', pos, dat);
                    //props.close();
                    props.openReviewModal({
                      id: dat.id,
                      activity: dat.activity,
                      user: dat.name,
                      date_added: dat.timestamp,
                      type: dat.bp_type,
                      isFromSubGroup: true
                    });
                    props.onFetchGMPHistoryItem(dat.id, dat.submission_id);
                  }}
                  disabled={firstPosition}
                >
                  <ArrowBack />
                </Button2>
              }
              <span style={{fontSize:'18px',fontWeight:'bold'}}>{title}</span>
              {
                (isFromSubGroup && !isSingle) &&
                <Button2
                  color="default"
                  onClick={() => {
                    const dat = subGroupData[pos+1];
                    console.log('forwards', dat);
                    //props.close();
                    props.openReviewModal({
                      id: dat.id,
                      activity: dat.activity,
                      user: dat.name,
                      date_added: dat.timestamp,
                      type: dat.bp_type,
                      isFromSubGroup: true
                    });
                    props.onFetchGMPHistoryItem(dat.id, dat.submission_id);
                  }}
                  disabled={lastPosition}
                >
                  <ArrowForward />
                </Button2>
              }
            </div>

            <Button
                justIcon
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
                color="transparent"
                onClick={() => {
                  this.props.close();
                }}
                style={{position:'absolute',top:'15px',right:'26px'}}
            >
                <Close className={classes.modalClose} />
            </Button>
        </DialogTitle>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card style={{textAlign:'left',marginLeft:'15px',marginTop:'0',marginBottom:'10px'}}>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <h4><strong>General Information</strong></h4>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    {bp.company_name && <div>{this.renderLabel('Company Name','company_name')}</div>}
                    {bp.customer_name && <div>{this.renderLabel('Company Name','customer_name')}</div>}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    {this.renderLabel('Database','DB')}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    {bp.bp_type && <div>{this.renderLabel('BP Type','bp_type')}</div>}
                    {(!bp.bp_type && gmpStatus) &&
                      <div><div style={{color:'#aaa',fontSize:'10px',fontWeight:'400'}}>BP Type:</div>
                    <div>Customer</div></div>}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    {!gmpStatus && this.renderSubgroupLabel('BP Subgroup','bp_subgroup')}
                    {gmpStatus && <div style={{marginBottom:'10px'}}>
                      <div style={{color:'#aaa',fontSize:'10px',fontWeight:'400'}}>BP Subgroup:</div>
                      <div>Customer</div>
                    </div>}
                  </GridItem>
                  <hr />
                  <GridItem xs={12} sm={12} md={3}>
                    {this.renderPhoneLabel('General Telephone','gen_tel')}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    {this.renderPhoneLabel('General Fax','gen_fax')}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    {this.renderLabel('Currency','currency')}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    {bp.card_code && <div style={{marginBottom:'10px'}}>
                      <div style={{color:'#aaa',fontSize:'10px',fontWeight:'400'}}>Card Code:</div>
                      <div>{bp.card_code}</div>
                    </div>}
                  </GridItem>
                  <hr />
                  <GridItem xs={12} sm={12} md={3}>
                  {
                    isNIN &&
                    <div>{this.renderLabel('P.A.N. No','panno')}</div>
                  }
                  {
                    isNIN &&
                    <div>{this.renderLabel('G.S.T.I.N.','tinno')}</div>
                  }
                  {
                    (isNIN && isWithhold) &&
                    <div>{this.renderLabel('Certificate No.','certificate_num')}</div>
                  }
                  {
                    (isNIN && isWithhold) &&
                    <div>{this.renderLabel('N.I. no.','ni_num')}</div>
                  }
                  {
                    (isNIN && isWithhold) &&
                    <div>{this.renderZeroLabel('Accrual Criteria','accrual_criteria')}</div>
                  }
                  {
                    (isNIN && isWithhold) &&
                    <div>{this.renderZeroLabel('Threshold Overlook','threshold_overlook')}</div>
                  }
                  {
                    (isNIN && isWithhold) &&
                    <div>{this.renderZeroLabel('Surcharge Overlook','surcharge_overlook')}</div>
                  }
                  </GridItem>
                  <GridItem xs={12} sm={12} md={7}>
                  {
                    isNIN &&
                    <div>{this.renderBooleanLabel('Subject to Withholding Tax','withholding')}</div>
                  }
                  {
                    (isNIN && isWithhold) &&
                    <div>{this.renderLabel('Expiration Date','expiration_date')}</div>
                  }
                  {
                    (isNIN && isWithhold) &&
                    <div>{this.renderZeroLabel('Assessee Type','assessee_type')}</div>
                  }
                  {
                    (isNIN && isWithhold) &&
                    <div>
                      <strong>WTAX Codes Allowed :</strong>
                        {this.renderWTaxCodes()}
                    </div>
                  }
                  </GridItem>
                  { isNAS &&
                    <GridItem xs={12} sm={12} md={5}>
                      {this.renderLabel('Old Code','old_code')}
                    </GridItem>
                  }
                  <GridItem xs={12} sm={12} md={5}>
                  {
                    isNMX &&
                    <div>{this.renderLabel('RFC/CURP','rfc_curp')}</div>
                  }
                  {
                    isNGT &&
                    <div>{this.renderLabel('NIT','nit')}</div>
                  }
                  </GridItem>
                  <GridItem xs={12} sm={12} md={7}>

                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>

    {(bp.resale_certificate || bp.resale_certificate_path) && <Card style={{textAlign:'left',marginLeft:'15px',marginTop:'0',marginBottom:'10px'}}>
      <CardBody>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            {bp.resale_certificate_path && <h4><strong>Current SAP Resale Certificate</strong></h4>}
            {bp.resale_certificate_path && <a
              href={sap_resale_link}
              target="_blank"
              onClick={(e) => {
                e.preventDefault();
                if (current_resale_filepath.split('.')[1] === 'pdf') {
                  this.showPDF(sap_resale_link);
                } else if ((current_resale_filepath.split('.')[1] === 'jpg' || current_resale_filepath.split('.')[1] === 'jpeg') || current_resale_filepath.split('.')[1] === 'png') {
                  this.showImage(sap_resale_link);
                }
              }}
            >{current_resale_filepath}</a>}
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            {(bp.resale_certificate && this.props.reviewType === 'submission') && <div>
              <h4><strong>New Uploaded Resale Certificate</strong></h4>
              {bp.resale_certificate.split(',').map((rc,i) => {
                        const link = <div>{rc.split('/api/common/rsc/')[1]}</div>;
                        return <div key={`rsc${i}`}>{link}</div>
                      })}
            </div>}
            {(bp.resale_certificate && this.props.reviewType !== 'submission') && <div>
              <h4><strong>New Uploaded Resale Certificate</strong></h4>
              {bp.resale_certificate.split(',').map((rc,i) => {
                        const link = <a
                        href=""
                        onClick={(e) => {
                          e.preventDefault();
                          const filename = rc.split('/api/common/rsc/')[1];
                          if (filename.split('.')[1] === 'pdf') {
                            this.showPDF(rc);
                          } else if ((filename.split('.')[1] === 'jpg' || filename.split('.')[1] === 'jpeg') || filename.split('.')[1] === 'png') {
                            this.showImage(rc);
                          }
                        }}
                        >{rc.split('/api/common/rsc/')[1]}</a>;
                        return <div key={`rsc${i}`}>{link}</div>
                      })}
            </div>}
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>}

    {
      this.state.showImage &&
      <BPDialog
        open={this.state.showImage}
        close={this.hideImage}
        type="image"
        image={this.state.showImageURL}
      />
    }
    {
      this.state.showPDF &&
      <BPDialog
        open={this.state.showPDF}
        close={this.hidePDF}
        type="pdf"
        pdf={this.state.showPDFURL}
      />
    }

    {this.props.enterPayTerms && <Card style={{textAlign:'left',marginLeft:'15px',marginTop:'0',marginBottom:'10px'}}>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <h4><strong>Accounting Information</strong></h4>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
        {(!gmpStatus && bp.payterms) && this.renderLabel('Pay Terms','payterms')}
        {(gmpStatus || !bp.payterms) && <div style={{marginBottom:'10px'}}>
          <div style={{color:'#aaa',fontSize:'10px',fontWeight:'400'}}>Pay Terms:</div>
          <div>Pay Before Production</div>
        </div>}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={1}>
        {<div style={{textAlign:'right'}}>{this.renderLabel('','currency')}</div>}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
        {!gmpStatus && this.renderLabel('Credit Limit','credit_limit')}
        {gmpStatus && <div style={{marginBottom:'10px'}}>
          <div style={{color:'#aaa',fontSize:'10px',fontWeight:'400'}}>Credit Limit:</div>
          <div>0</div>
        </div>}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
        {(bp.acctname && bp.bp_type !== 'Customer') && this.renderLabel('Account Name','acctname')}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>}
            {bp.billing_addressname && <Card style={{textAlign:'left',marginLeft:'15px',marginTop:'0',marginBottom:'10px'}}>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <h4><strong>Billing Address</strong></h4>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    {this.renderLabel('Name','billing_addressname')}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    {this.renderLabel('Address 1','billing_address1')}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    {this.renderLabel('Address 2','billing_address2')}
                  </GridItem>

                  <GridItem xs={12} sm={12} md={3}>
                    {this.renderLabel('Country','billing_country')}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    {this.renderStateLabel('State','billing_state')}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    {this.renderLabel('City','billing_city')}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    {this.renderLabel('Postal Code','billing_zipcode')}
                  </GridItem>

                  <GridItem xs={12} sm={12} md={3}>
                  {
                    bp.billing_gstregno &&
                    <div>{this.renderLabel('GST Reg\'n No.','billing_gstregno')}</div>
                  }
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                  {
                    bp.billing_gstregtype &&
                    <div>{this.renderLabel('GST Reg\'n Type.','billing_gstregtype')}</div>
                  }
                  </GridItem>
                </GridContainer>
                <hr />
                {bp.shipping_addressname && <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <h4><strong>Shipping Address {afterDefaultShipping === bp.shipping_addressname ? <span className={defShipStatus ? 'green-text' : ''}>(Default)</span> : ''}</strong></h4>
                    {beforeDefaultShipping === bp.shipping_addressname && <p>{defShipStatus}</p>}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    {this.renderLabel('Name','shipping_addressname')}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    {this.renderLabel('Address 1','shipping_address1')}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    {this.renderLabel('Address 2','shipping_address2')}
                  </GridItem>

                  <GridItem xs={12} sm={12} md={3}>
                    {this.renderLabel('Country','shipping_country')}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    {this.renderStateLabel('State','shipping_state')}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    {this.renderLabel('City','shipping_city')}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    {this.renderLabel('Postal Code','shipping_zipcode')}
                  </GridItem>

                  <GridItem xs={12} sm={12} md={3}>
                    {
                      bp.shipping_gstregno &&
                      <div>{this.renderLabel('GST Reg\'n No.','shipping_gstregno')}</div>
                    }
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    {
                      bp.shipping_gstregtype &&
                      <div>{this.renderLabel('GST Reg\'n Type','shipping_gstregtype')}</div>
                    }
                  </GridItem>
                </GridContainer>}
                {noChangeAddresses}
                {changedAddresses}
                {newAddresses}
                {deletedAddresses}
              </CardBody>
            </Card>}
            <Card style={{textAlign:'left',marginLeft:'15px',marginTop:'0',marginBottom:'10px'}}>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <h4><strong>{accountsPayableHeader}</strong>{accountsPayableGMP && <span> (TPP User ID: # {accountsPayableGMP})</span>}</h4>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    {this.renderLabel('Firstname','accounts_payable_firstname')}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    {this.renderLabel('Position','accounts_payable_position')}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    {this.renderPhoneLabel('Telephone','accounts_payable_tel')}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    {this.renderPhoneLabel('Alt Telephone','accounts_payable_alttel')}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    {this.renderPhoneLabel('Mobile','accounts_payable_mobile')}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    {this.renderLabel('Lastname','accounts_payable_lastname')}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    {this.renderPhoneLabel('Fax','accounts_payable_fax')}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    {this.renderLabel('Email','accounts_payable_email')}
                  </GridItem>
            </GridContainer>
            {
              (bp.purchasing_email || this.state.changes.purchasing_email) &&
              <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <h4><strong>{purchasingHeader}</strong>{purchasingGMP && <span> (TPP User ID: # {purchasingGMP})</span>}</h4>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    {this.renderLabel('Firstname','purchasing_firstname')}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    {this.renderLabel('Position','purchasing_position')}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    {this.renderPhoneLabel('Telephone','purchasing_tel')}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    {this.renderLabel('Alt. Telephone','purchasing_alttel')}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    {this.renderLabel('Mobile','purchasing_mobile')}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    {this.renderLabel('Lastname','purchasing_lastname')}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    {this.renderPhoneLabel('Fax','purchasing_fax')}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={5}>
                    {this.renderLabel('Email','purchasing_email')}
                  </GridItem>
              </GridContainer>
            }
            {noChangeContacts}
            {changedContacts}
            {newContacts}
            {deletedContacts}
            </CardBody>
          </Card>

        {(rbosChecked || ediProgramsChecked) && <Card style={{textAlign:'left',marginLeft:'15px',marginTop:'0',marginBottom:'10px'}}>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <div>
                    <h4><strong>RBOs</strong></h4>
                    {this.renderChecks('rbosChecked')}
                  </div>
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <div>
                    <h4><strong>EDI Programs and Vendor Code</strong></h4>
                    {this.renderChecks('ediProgramsChecked')}
                  </div>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>}

        {bp.notes && <Card style={{textAlign:'left',marginLeft:'15px',marginTop:'0',marginBottom:'10px'}}>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <div>
                    <h4><strong>Notes</strong></h4>
                    {this.renderLabel('','notes')}
                  </div>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>}
          <Card style={{textAlign:'left',marginLeft:'15px',marginTop:'0px'}}>
            <CardBody>
                <GridContainer>
                  <div style={{marginTop:'20px',marginLeft:'20px'}} className="separate-btns">
                    <GridItem xs={12} sm={12} md={12}>
                      <Button
                        color="rose"
                        onClick={() => {
                          this.props.close();
                        }}
                      >BACK</Button>
                      {
                (hasChanged && this.props.onApprove)  &&
                          <Button
                            color="success"
                            onClick={() => {
                              this.props.onApprove();
                              this.props.close();
                            }}
                          >{this.props.directToSAP.indexOf(currentDB) !== -1 ? 'APPROVE' : 'SUBMIT TO PENDING'}</Button>
                      }
                      {
                (!hasChanged && this.props.onApprove) &&
                          <Button
                            color="white"
                            disabled
                            onClick={() => {

                            }}
                          >SUBMISSION ONLY AVAILABLE IF DATA HAS CHANGED</Button>
                      }
                      {
                        this.props.onGMPExistingLoad &&
                          <Button
                          color="success"
                          onClick={() => {
                            this.props.onGMPExistingLoad();
                            this.props.close();
                          }}
                          >CHOOSE</Button>
                      }
                    </GridItem>
                  </div>
                </GridContainer>
                <Clearfix />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    payterms: state.common.paytermsDictionary,
    groupcodes: state.common.groupcodesDictionary,
    countries: state.common.countriesDictionary,
    states: state.common.statesDictionary,
    currencies: state.common.currencies,
    owhtopts: state.common.owhtoptsDictionary,
    historyItemData: state.gmp.historyItemData,
    gsttypes: state.gmp.gsttypesDictionary,
    isPending: state.common.isPending,
    rbosDict: state.gmp.rbosDict,
    edi_programsDict: state.gmp.edi_programsDict,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchGMPRBO: (db) => {
      dispatch(actions.fetchGMPRBO(db));
    },
    fetchEDIPrograms: () => {
      console.log('fetching EDI programs');
      dispatch(actions.fetchEDIPrograms());
    },
  }
}

const combinedStyle = combineStyles(sweetAlertStyle, userProfileStyles);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(combinedStyle)(BPReview));
