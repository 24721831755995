// set devStatus to indicate if the app is in live(production) or stage mode
// options :
// ---------
// local_live, local_stage, live, stage (NOTE: local means turning on Chrome Redux Tools)

const devStatus = 'stage';
export const domain = 'https://staging.portal2.natcoglobal.com';
export const phpPortal = 'https://portal.natcoglobal.com/portal-stage/sap_api_b/view/itemgather_api.php';

// const devStatus = 'live';
// export const domain = 'https://portal2.natcoglobal.com';
// export const phpPortal = 'https://portal.natcoglobal.com/portal-live/sap_api_b/view/itemgather_api.php';

export default devStatus;
