import Dashboard from "views/Dashboard/Dashboard2.jsx";
import Buttons from "views/Components/Buttons.jsx";
import GridSystem from "views/Components/GridSystem.jsx";
import Panels from "views/Components/Panels.jsx";
import SweetAlert from "views/Components/SweetAlert.jsx";
import Notifications from "views/Components/Notifications.jsx";
import Icons from "views/Components/Icons.jsx";
import Typography from "views/Components/Typography.jsx";
import RegularForms from "views/Forms/RegularForms.jsx";
import ExtendedForms from "views/Forms/ExtendedForms.jsx";
import ValidationForms from "views/Forms/ValidationForms.jsx";
import Wizard from "views/Forms/Wizard.jsx";
import RegularTables from "views/Tables/RegularTables.jsx";
import ExtendedTables from "views/Tables/ExtendedTables.jsx";
import ReactTables from "views/Tables/ReactTables.jsx";
import GoogleMaps from "views/Maps/GoogleMaps.jsx";
import FullScreenMap from "views/Maps/FullScreenMap.jsx";
import VectorMap from "views/Maps/VectorMap.jsx";
import Charts from "views/Charts/Charts.jsx";
import Calendar from "views/Calendar/Calendar.jsx";
import Widgets from "views/Widgets/Widgets.jsx";
import UserProfile from "views/Pages/UserProfile.jsx";
import TimelinePage from "views/Pages/Timeline.jsx";
import RTLSupport from "views/Pages/RTLSupport.jsx";
import SAPPage from "views/Pages/SAP.jsx";
import UserAdmin from "views/Pages/UserAdmin.jsx";
import PortalPermissions from "views/Pages/PortalPermissions.jsx";
import GMP from "views/Pages/GMP.jsx";
import GMPHistory from "views/Pages/GMPHistory";
import GIN from "views/Pages/GIN.jsx";
import GINHistory from "views/Pages/GINHistory";
import BP from "views/Pages/BP.jsx";
import ItemAdmin from "views/Pages/ItemAdmin.jsx";
import RFID from "views/Pages/RFID.jsx";
import RFIDHistory from "views/Pages/RFIDHistory.jsx";
import Maintenance from "views/Pages/Maintenance.jsx";
import MaintenancePage from "views/Pages/MaintenancePage.jsx";
import SettingsPage from "views/Pages/SettingsPage.jsx";
import SOLookup from "views/Pages/SOLookup.jsx";
import LockedElements from "views/Pages/LockedElements.jsx";
import RBOLookupPage from "views/Pages/RBOLookupPage.jsx";
import TranslationsPage from "views/Pages/TranslationsPage.jsx";
import userIsLoggedIn from "shared/authValidation";
import SAPMonitorPage from "views/Pages/SAPMonitorPage";
import pagesRoutes from "./pages.jsx";

// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import Build from "@material-ui/icons/Build";
import Image from "@material-ui/icons/Image";
import Apps from "@material-ui/icons/Apps";
import ContentPaste from "@material-ui/icons/ContentPaste";
import GridOn from "@material-ui/icons/GridOn";
import Place from "@material-ui/icons/Place";
import WidgetsIcon from "@material-ui/icons/Widgets";
import Timeline from "@material-ui/icons/Timeline";
import DateRange from "@material-ui/icons/DateRange";
import checkPermissions from "shared/checkPermissions";

const dashRoutes = () => {
  // const gmpPermitted = checkPermissions('GMP');
  const gmpHistoryPermitted = checkPermissions('GMPHistory');
  const ginHistoryPermitted = checkPermissions('GINHistory');
  const userAdminPermitted = checkPermissions('UserAdmin');
  const userPermissionsPermitted = checkPermissions('UserPerms');
  const ginPermitted = checkPermissions('GIN');
  const developerPermitted = checkPermissions('Developer');
  const bpPermitted = checkPermissions('BP');
  const tppPermitted = checkPermissions('GMP');
  const rfidPermitted = checkPermissions('RFID');
  const rfidHistoryPermitted = checkPermissions('RFIDhistory');
  const soLookupPermitted = checkPermissions('SOLookup');
  const tppMaintenancePermitted = checkPermissions('TPPmaintenance');
  const lockedElementsPermitted = checkPermissions('LockedElements');
  const impositionsSettingsPermitted = checkPermissions('ImpositionsSettings');
  const rboLookupPermitted = checkPermissions('RBOTranslationLookup');
  const translationsPermitted = checkPermissions('TranslationsCRUD');
  const sapMonitorPermitted = checkPermissions('SAPMonitor');

  var sapPages = [
    {
      path: "/GIN",
      name: "Global Item Number",
      mini: "GIN",
      component: GIN,
      permitted2: ginPermitted
    },
    {
      path: "/GNHistory",
      name: "GIN History",
      mini: "GNH",
      component: GINHistory,
      permitted2: ginHistoryPermitted
    },
    {
      path: "/History",
      name: "BP History",
      mini: "BH",
      component: GMPHistory,
      permitted2: gmpHistoryPermitted
    },
    {
      path: "/BP",
      name: "Business Partners",
      mini: "BP",
      component: BP,
      permitted2: bpPermitted || tppPermitted
    }
  ];

  var pages = sapPages.concat(pagesRoutes);

  return [
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: DashboardIcon,
      component: Dashboard
    },
    {
      collapse: true,
      path: "-page",
      name: "SAP",
      state: "openPages",
      icon: Image,
      views: sapPages,
      permitted: (ginPermitted || bpPermitted || tppPermitted),
    },
    {
      collapse: true,
      path: "/tools",
      name: "Tools",
      state: "openTools",
      icon: Build,
      permitted: (rfidPermitted || rfidHistoryPermitted || soLookupPermitted
        || rboLookupPermitted || translationsPermitted || sapMonitorPermitted),
      views: [
        {
           path: "/rfid",
           name: "RFID",
           mini: "RF",
           component: RFID,
           permitted2: rfidPermitted
         },
         {
           path: "/rfhistory",
           name: "RFID History",
           mini: "RH",
           component: RFIDHistory,
           permitted2: rfidHistoryPermitted
         },
         {
           path: "/solookup",
           name: "SO Lookup",
           mini: "SO",
           component: SOLookup,
           permitted2: soLookupPermitted
         },
        //  {
        //    path: "/rbo_translations_lookup",
        //    name: "RBO Translations Lookup",
        //    mini: "RBT",
        //    component: RBOLookupPage,
        //    permitted2: rboLookupPermitted
        //  },
         {
           path: "/translations_crud",
           name: "Translations",
           mini: "TR",
           component: TranslationsPage,
           permitted2: translationsPermitted
         },
         {
           path: "/sap_monitor",
           name: "SAP Monitor",
           mini: "SM",
           component: SAPMonitorPage,
           permitted2: sapMonitorPermitted
         }
      ]
    },
    {
      collapse: true,
      path: "/components",
      name: "Settings",
      state: "openComponents",
      icon: Apps,
      permitted: (developerPermitted || tppMaintenancePermitted || impositionsSettingsPermitted),
      views: [
        {
          path: "/components/portal_settings",
          name: "Portal Settings",
          mini: "PM",
          permitted2: (developerPermitted || tppMaintenancePermitted || impositionsSettingsPermitted),
          component: SettingsPage
        },
        {
          path: "/components/tool_maintenance",
          name: "Tool Maintenance",
          mini: "MA",
          permitted2: developerPermitted,
          component: Maintenance
        },
        {
          path: "/components/item_admin",
          name: "Item Admin",
          mini: "IA",
          permitted2: developerPermitted,
          component: ItemAdmin
        },
        {
          path: "/components/maintenance_page",
          name: "Maintenance Page",
          mini: "MP",
          permitted2: true,
          component: MaintenancePage,
          display: false
        },
        {
          path: "/components/panels",
          name: "Panels",
          mini: "P",
          permitted2: developerPermitted,
          component: Panels
        },
        {
          path: "/components/sweet-alert",
          name: "Sweet Alert",
          mini: "SA",
          permitted2: developerPermitted,
          component: SweetAlert
        },
        {
          path: "/components/notifications",
          name: "Notifications",
          mini: "N",
          permitted2: developerPermitted,
          component: Notifications

        },
        {
          path: "/forms/regular-forms",
          name: "Regular Forms",
          mini: "RF",
          permitted2: developerPermitted,
          component: RegularForms
        },
        {
          path: "/forms/extended-forms",
          name: "Extended Forms",
          mini: "EF",
          permitted2: developerPermitted,
          component: ExtendedForms
        },
        {
          path: "/forms/validation-forms",
          name: "Validation Forms",
          mini: "VF",
          permitted2: developerPermitted,
          component: ValidationForms
        },
        { path: "/forms/wizard", name: "Wizard", mini: "W", permitted2: developerPermitted, component: Wizard },
        {
          path: "/tables/regular-tables",
          name: "Regular Tables",
          mini: "RT",
          permitted2: developerPermitted,
          component: RegularTables
        },
        {
          path: "/tables/extended-tables",
          name: "Extended Tables",
          mini: "ET",
          permitted2: developerPermitted,
          component: ExtendedTables
        },
        {
          path: "/tables/react-tables",
          name: "React Tables",
          mini: "RT",
          permitted2: developerPermitted,
          component: ReactTables
        },
        {
          path: "/maps/google-maps",
          name: "Google Maps",
          mini: "GM",
          permitted2: developerPermitted,
          component: GoogleMaps
        },
        {
          path: "/maps/full-screen-maps",
          name: "Full Screen Map",
          mini: "FSM",
          permitted2: developerPermitted,
          component: FullScreenMap
        },
        {
          path: "/maps/vector-maps",
          name: "Vector Map",
          mini: "VM",
          permitted2: developerPermitted,
          component: VectorMap
        },
        { path: "/widgets", name: "Widgets", icon: WidgetsIcon, permitted2: developerPermitted, component: Widgets },
        { path: "/charts", name: "Charts", icon: Timeline, permitted2: developerPermitted, component: Charts },
        { path: "/calendar", name: "Calendar", icon: DateRange, permitted2: developerPermitted, component: Calendar },
        {
          collapse: true,
          path: "/tables",
          name: "Tables",
          state: "openTables",
          icon: GridOn,
          permitted2: developerPermitted,
          views: [
          ]
        },
        {
          collapse: true,
          path: "/maps",
          name: "Maps",
          state: "openMaps",
          icon: Place,
          permitted2: developerPermitted,
          views: [
          ]
        },
        {
          path: "/rtl/rtl-support-page",
          name: "RTL Support",
          mini: "RS",
          permitted2: developerPermitted,
          component: RTLSupport
        },
        {
          path: "/timeline-page",
          name: "Timeline Page",
          mini: "TP",
          component: TimelinePage
        },
        {
          path: "/SAP",
          name: "SAP",
          mini: "SAP",
          permitted2: developerPermitted,
          component: SAPPage
        },
      ]
    },
    {
      collapse: true,
      path: "/admin",
      name: "Admin",
      state: "openForms",
      icon: ContentPaste,
      permitted: (userAdminPermitted || lockedElementsPermitted),
      views: [
        {
           path: "/UserAdmin",
           name: "User Admin",
           mini: "UA",
           component: UserAdmin,
           permitted2: userAdminPermitted
         },
         {
            path: "/UserPermissions",
            name: "User Permissions",
            mini: "UP",
            component: PortalPermissions,
            permitted2: userPermissionsPermitted
          },
          {
            path: "/LockedElements",
            name: "Locked Elements",
            mini: "LE",
            component: LockedElements,
            permitted2: lockedElementsPermitted
          },
         {
           path: "/user-page",
           name: "User Profile",
           mini: "UR",
           component: UserProfile,
           permitted2: true
         },
      ]
    },
    { redirect: true, path: "/", pathTo: "/dashboard", name: "Dashboard" }
  ];
}
export default dashRoutes;
