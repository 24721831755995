import { parseXmlToJson, makeNum, mt, compareNumbersSpecial } from 'shared/utility';
import moment from 'moment';
export const compareSpecial = ['total_by_items', 'discount'];
const convertDate = (d) => {
    if (d) {
        const darr = d.split('/');
        if (darr.length === 3) return darr[2]+'-'+darr[0]+'-'+darr[1];
    }
    return '';
}

const locations = {
    1: [
        'Magento',
        'BPA',
        'SAP',
        'SAP',
        'SAP',
        'SAP',
        'BPA',
        'SAP',
        'BPA',
        'SAP',
        'BPA'
    ],
    2: [
        'Magento',
        'BPA',
        'SAP',
        'SAP',
        'SAP',
        'SAP',
        'BPA',
        'SAP',
        'SAP',
        'SAP',
        'SAP',
        'SAP',
        'SAP',
        'SAP',
        'BPA',
        'SAP',
        'BPA',
        'SAP',
        'BPA'
    ],
    3: [
        'Magento',
        'BPA',
        'SAP',
        'SAP',
        'BPA',
        'SAP',
        'BPA',
        'SAP',
        'BPA'
    ]
}

const integration_statuses = {
    'in_production': '', 
    'Payment Pending': '', 
    'Submitted': '', 
    'canceled': '', 
    'Processing': ''
}

const compare = (sap, magento) => {
    let pass = true;

    return pass;
}

const sapMonitorData = {
    paymentMethods: {
        'PBP-Payment by CC or Paypal': [
            'paypal_express',
            'wps_express',
            'wps_express_bml',
            'paypal_express_bml',
            'paypal_direct',
            'paypal_payment_pro',
            'payflow_express',
            'payflow_express_bml',
            'payflowpro',
            'payflow_link',
            'payflow_advanced',
            'hosted_pro',
            'paypal_billing_agreement',
            'authorizenetcim',
            'authorizenet_directpost',
            'authorizenet_acceptjs'
        ],
        'PBP-Payment by Check or Wire': [
            'banktransfer'
        ],
        'Payment on Credit': [
            'sap'
        ],
    },
    orderStatuses: [
        { id:'', desc:''},
        { id:'canceled', desc:'Canceled'},
        { id:'submitted', desc:'Submitted'},
        { id:'ready_for_pickup', desc:'Ready for Pickup'},
        { id:'pending_payment', desc:'Pending Payment'},
        { id:'in_production', desc:'In Production'}
    ],
    extractXMLData: (pd) => {
        if (pd.shipping_info) {
            if (pd.shipping_info.length > 0) {
                if (pd.shipping_info[0].comments) {
                    if (pd.shipping_info[0].comments.length > 0) {
                        if (pd.shipping_info[0].comments[0]) {
                            const c = pd.shipping_info[0].comments[0];
                            const comment = c.comment;
                            try {
                                const commentJSON = parseXmlToJson(comment);
                                console.log('commentJSON', commentJSON);
                                return commentJSON;
                            } catch(e) {
                                return null;
                            }
                        }
                    }
                }
            }
        }
        return null;
    },
    extractSOPI: (pd) => {
        let SO = '';
        if (pd) {
            if (pd.U_Sales_Order_Num) {
                SO = pd.U_Sales_Order_Num;
            }
        }
        if (SO) {
            return SO;
        }
        return null;
    },
    defaultDetails: {
        bp_info: {
            database: '',
            company_name: '',
            bp_code: '',
            mag_user_id: '',
            phone: '',
            fax: '',
            email: '',
            contact_name: '',
            contact_info: '',
            billing_address_name: '',
            billing_address: '',
            shipping_location_names: '',
            ship_to_address: '',
            bp_currency: '',
            tpp_assigned_rbo: '',
            tpp_assigned_edi_program: '',
            tpp_assigned_edit_vendor_code: '',
            payment_term: '',
            credit_limit: '',
            account_balance: '',
            open_order_amount: '',
        },
        order_info: {
            database: '',
            company_name: '',
            bp_code: '',
            email: '',
            contact_name: '',
            mag_order_number: '',
            order_date: '',
            order_status: '',
            bill_to_address: '',
            shipping_method: '',
            ship_to_address: '',
            payment_method: '',
            items_ordered: '',
            items_qty: '',
            items_unit_price: '',
            total_by_items: '',
            subtotal: '',
            discount: '',
            shipping_charge_amount: '',
            order_total_amount: '',
            sap_so_number: '',
            customer_ref_number: '',
            sap_pi_number: ''
        }
    },
    defaultMagentoDetails: {
        bp_info: {
            database: '',
            company_name: '',
            bp_code: '',
            mag_user_id: '',
            phone: '',
            fax: '',
            email: '',
            contact_name: '',
            contact_info: '',
            billing_address_name: '',
            billing_address: '',
            shipping_location_names: '',
            ship_to_address: '',
            bp_currency: '',
            tpp_assigned_rbo: '',
            tpp_assigned_edi_program: '',
            tpp_assigned_edit_vendor_code: '',
            payment_term: '',
            credit_limit: '',
            account_balance: '',
            open_order_amount: '',
        },
        order_info: {
            database: '',
            company_name: '',
            bp_code: '',
            email: '',
            contact_name: '',
            mag_order_number: '',
            order_date: '',
            order_status: '',
            bill_to_address: '',
            shipping_method: '',
            ship_to_address: '',
            payment_method: '',
            items_ordered: '',
            items_qty: '',
            items_unit_price: '',
            total_by_items: '',
            subtotal: '',
            discount: '',
            shipping_charge_amount: '',
            order_total_amount: '',
            sap_so_number: '',
            customer_ref_number: '',
            sap_pi_number: ''
        }
    },
    calculate: (scenario, row, index, data) => {
        console.log('calculate', scenario, row, index, data);
        const { 
            sap_order_details,
            magentoDetails,
            orderDetails,
            sapBpDetails,
            sapBpaDetails,
            orderData,

            bp_details_magento, 
            bp_details_sap,

            order_details_sap,
            order_details_magento,
            sap_bpa_item_details
        } = data;

        // TRACKING NUMBERS
        let tracking_data_magento = '';
        let tracking_data_sap = '';
        if (orderData) {
            if (orderData.orderData) {
                if (orderData.orderData.shipping_info) {
                    if (orderData.orderData.shipping_info.length > 0) {
                        const ordship = orderData.orderData.shipping_info[0];
                        if (ordship.tracks) {
                            if (ordship.tracks.length > 0) {
                                const the_ordship = ordship.tracks[0];
                                if (the_ordship.track_number) {
                                    tracking_data_magento = the_ordship.track_number;
                                }
                            }
                        }
                    }
                }
            }
        }
        if (sap_order_details) {
            if (sap_order_details.ODLN) {
                if (sap_order_details.ODLN.length > 0) {
                    tracking_data_sap = sap_order_details.ODLN[0].TrackNo;
                }
            }
        }


        if (!sap_order_details) return '';
        const { ORCT } = sapBpDetails;

        // BPA importer check 
        // ==================
        let importer_error = false;
        if (sapBpaDetails) {
            // check for any errors 
            if (sapBpaDetails.eCom_OrderHeaderStaging) {
                sapBpaDetails.eCom_OrderHeaderStaging.forEach(ohs => {
                    if (ohs.Status !== 'Success') {
                        console.log('ohs', ohs.status);
                        importer_error = true;
                    }
                })
            }
        }

        // CONSISTENCY CHECK
        // =================

        const magento_order_number = orderData.magento_order_number;

        let bpa_checks = {
            order_import: false, //
            magento_to_sap_customer: false, //
            gmp_status_shipments: false, //
            items: false, //
            // sap_to_magento_customer: false,
            // process_paid_down_payments: false,
            // moc: false,
            edi_mapping: false, //
            // edi_programs: false,
            // rbo_vendor: false,
            // active_rbo: false,
            pricing: false //
        }

        // BPA INFO 
        let GMPStatus = [];
        const shipped_statuses = [
            'P_Ship',
            'Shipped',
            'ready_for_pickup'
        ]
        if (magento_order_number) {
            if (sapBpaDetails && sap_bpa_item_details) {
                const bpa = sapBpaDetails;
                const itm = sap_bpa_item_details;

                // Order Import & GMP Status/Shipments
                if (bpa.eCom_OrderHeaderStaging) {
                    const t = bpa.eCom_OrderHeaderStaging;
                    t.forEach(tt => {
                        if (tt.NumAtCard == magento_order_number) {
                            if (tt.Status === 'Success') {
                                bpa_checks.order_import = true;
                            }
                            if (tt.GMPSyncedStatus === 'Success') {
                                bpa_checks.gmp_status_shipments = true;
                            } 
                            GMPStatus.push(tt.GMPStatus);

                        }
                    })
                }

                //  Magento to SAP Customer
                if (bpa.eCom_Customer_Addresses) {
                    const t = bpa.eCom_Customer_Addresses;
                    t.forEach(tt => {
                        if (tt.Status === 'Success') {
                            bpa_checks.magento_to_sap_customer = true;
                        }
                    })
                }

                // Items
                const item_codes = Object.keys(itm);
                if (item_codes.length > 0) {

                    let items_passed = true;
                    let edi_mapping_passed = true;
                    let aa_pricing_staging_passed = true;

                    item_codes.forEach(ic => {
                        const i = itm[ic];
                        if (i.ERP_ItemStaging) {
                            i.ERP_ItemStaging.forEach(eis => {
                                if (eis.Status !== 'Success') items_passed = false;
                            })
                        }
                        if (i.AA_EDI_Mapping) {
                            i.AA_EDI_Mapping.forEach(aem => {
                                if (aem.SyncStatus !== 'Synced') edi_mapping_passed = false;
                            })
                        }
                        if (i['ERP_@AAPricing_Staging']) {
                            i['ERP_@AAPricing_Staging'].forEach(ias => {
                                if (ias.Status !== 'Success') aa_pricing_staging_passed = false;
                            })
                        }
                    })

                    bpa_checks.items = items_passed;
                    bpa_checks.edi_mapping = edi_mapping_passed;
                    bpa_checks.pricing = aa_pricing_staging_passed;
                }

                // SAP to Magento Customer
                if (bpa.ERP_CustomerStaging) {

                }

                // Process Paid Down Payments
                if (bpa.ERP_CreateSO_DPI_IP_Log) {
                    
                }

                // MOC
                if (bpa.AA_MOC) {

                }

                // EDI Programs 
                if (bpa.AA_EDI_Programs_Staging) {

                }
            }
        }
        let all_bp_checks_passed = true;
        const bpa_checks_fields = Object.keys(bpa_checks);
        for (let i = 0; i < bpa_checks_fields.length; i++) {
            const field = bpa_checks_fields[i];
            if (!bpa_checks[field]) {
                console.log('bpa_check failed', field);
                all_bp_checks_passed = false;
            }
        }

        // BP INFO

        // ORDERS
        const order_compare_obj = {
            // database: '',
            // company_name: '',
            // bp_code: '',
            // email: '',
            // contact_name: '',
            // mag_order_number: '',

            order_date: '',
            // order_status: '',
            bill_to_address: '',
            shipping_method: '',
            ship_to_address: '',
            // payment_method: '',
            items_ordered_val: '',
            items_qty: '',
            items_unit_price: '',
            total_by_items: '',
            subtotal: '',
            discount: '',
            shipping_charge_amount: '',
            order_total_amount: '',
            sap_so_number: '',
            customer_ref_number: '',
            sap_pi_number: ''
        }
        // Check if data from Magento is in SAP and matches
        let order_is_consistent = true;
        const exceptions = [];
        if (order_details_sap && order_details_magento) {
            Object.keys(order_compare_obj).forEach(field => {
                console.log('field', field);
                if (exceptions.indexOf(field) === -1) {
                    if (compareSpecial.indexOf(field) !== -1) {
                        const bool = compareNumbersSpecial(order_details_sap[field], order_details_magento[field]);
                        if (!bool) {
                            console.log('consistency check NOT 1', field);
                            order_is_consistent = false;
                        }
                    } else {
                        if (order_details_sap[field] == order_details_magento[field]) {
                            order_compare_obj[field] = order_details_magento[field];
                        } else {
                            if (field === 'order_date') {
                                // if within 1 day 
                                if (order_details_sap[field] && order_details_magento[field]) {
                                    var start = moment(convertDate(order_details_sap[field])); 
                                    var end = moment(convertDate(order_details_magento[field]));
                                    var duration = moment.duration(start.diff(end));
                                    var days = duration.asDays();
                                    console.log('days difference', days);
                                    if (days > 1) {
                                        order_is_consistent = false;
                                    } 
                                } else {
                                    order_is_consistent = false;
                                }
                            } else {
                                console.log('consistency check NOT 2', field);
                                order_is_consistent = false;
                            }
                        }
                    }
                }
            })
        }

        // SAP 
        const { ORDR, OPOR, ODLN, OINV, ODPI, ORIN, PI } = sap_order_details;
        console.log('GMPStatus', GMPStatus);
        if (scenario === 1) {
            // ==========================
            // scenario 1 - CC or Paypal
            // ==========================
            if (row === 1) {
                if (index === 1) {
                    return 'Information Consistent';
                }
                if (index === 2) {
                    if (order_is_consistent) {
                        return 'Information Consistent';
                    }
                }
                if (index === 3) {
                    // If the SO in SAP matches the SO in GMP then checkmark, otherwise error
                    if (order_details_sap && order_details_magento) {
                        if (order_details_sap.sap_so_number === order_details_magento.sap_so_number) {
                            return 'Information Consistent';
                        }
                    }
                }
                if (index === 4) {
                    return '';
                }
                if (index === 5) {
                    // If there is a payment posted in Magento that matches SAP  then checkmark, if not then error
                    return '';
                }
                if (index === 6) {
                    // 'check if magento status says in production
                    if (GMPStatus.indexOf('in_production') !== -1) return 'Information Consistent';
                }
                if (index === 7) {
                    return '';
                }
                if (index === 8) {
                    return '';
                }
                if (index === 9) {
                    // Invoice data matches SAP and Magento
                    if (OINV.length > 0) {
                        return 'Information Consistent';
                    }
                }
                if (index === 10) {
                    return '';
                }
                if (index === 11) {
                    return '';
                }
                return 'Information Inconsistent';
            }
            if (row === 2) {
                if (index === 1) {
                    // Order is placed In Magento
                    return 'Completed';
                }
                if (index === 2) {
                    // Integration 
                    // - Check integration statuses and that all data from Magento is in SAP and matches
                    // if (all_bp_checks_passed && order_is_consistent) {
                    //     return 'Completed';
                    // }
                    if (!importer_error) {
                        return 'Completed';
                    }
                }
                if (index === 3) {
                    // Order posted 
                    // the existence of the SO
                    if (ORDR.DocNum) return 'Completed';
                }
                if (index === 4) {
                    // ARDP posted - ODPI
                    if (ODPI) {
                        if (ODPI.length > 0) return 'Completed';
                    }
                }
                if (index === 5) {
                    // Incoming Payment posted - the existence of the payment - ORCT
                    if (ORCT) {
                        if (ORCT.length > 0) {
                            return 'Completed';
                        }
                    }
                }
                if (index === 6) {
                    // Procurement Document Posted against SO - existence of the purchase order or production request
                    if (OPOR) {
                        if (OPOR.length > 0) {
                            return 'Completed';
                        }
                    }
                }
                if (index === 7) {
                    // Integration - In Magento (In Production) ??
                    // if (GMPStatus.indexOf('in_production') !== -1) return 'Completed';
                    if (!importer_error) {
                        return 'Completed';
                    }
                }
                if (index === 8) {
                    // Invoice posted - SAP - invoice exists in SAP, OINV. Proforma Invoice : OQUT
                    if (OINV.length > 0) {
                        return 'Completed';
                    }
                }
                if (index === 9) {
                    // Integration - BPA - Update Status - Internal Status only, completed not shipped ??
                    // if (GMPStatus.indexOf('in_production') !== -1) return 'Completed';
                    if (!importer_error) {
                        return 'Completed';
                    }
                }
                if (index === 10) {
                    // Tracking added to invoice - SAP - Tracking number exists - ODLN.TrackNo
                    if (ODLN.length > 0) {
                        if (ODLN[0].TrackNo) return 'Completed';
                    }
                }
                if (index === 11) {
                    // Integration - BPA - Update Status - In Magento (Shipped) ??
                    // if (GMPStatus.length > 0) {
                    //     for (let x = 0; x < shipped_statuses.length; x++) {
                    //         const shipped_status = shipped_statuses[x];
                    //         if (GMPStatus.indexOf(shipped_status) !== -1) {
                    //             return 'Completed';
                    //         }
                    //     }
                    // }
                    if (!importer_error) {
                        return 'Completed';
                    }
                }
            }
            if (row === 3) {
                return locations[1][index-1];
            }
        } else if (scenario === 2) {
            // ==========================
            // scenario 2 - Pay by Check or Wire 
            // ==========================
            if (row === 1) {

                if (index === 1) {
                    return 'Information Consistent';
                }
                if (index === 2) {
                    if (order_is_consistent) {
                        return 'Information Consistent';
                    }
                }
                if (index === 3) {
                    // If the PI in SAP matches the PI in GMP then checkmark, otherwise error
                    if (order_details_sap && order_details_magento) {
                        if (order_details_sap.sap_pi_number && order_details_magento.sap_pi_number) {
                            if (order_details_sap.sap_pi_number === order_details_magento.sap_pi_number) {
                                return 'Information Consistent';
                            }
                        }
                    }
                }
                if (index === 4) {
                    return '';
                }
                if (index === 5) {
                    // TO DO - Relationship table PI/ARDP has matching Temp ARDP and PI
                    if (sap_order_details.PI) {
                        if (sap_order_details.PI.length > 0) {
                            const PI1 = sap_order_details.PI[0];
                            if (PI1.U_SAP_ARDPI_ORIG && PI1.U_SAP_PI) {
                                return 'Information Consistent';
                            }
                        }
                    }
                }
                if (index === 6) {
                    return '';
                }
                if (index === 7) {
                    // If it posted the information to SAP 
                    if (sap_order_details.PI) {
                        return 'Information Consistent';
                    }
                    return '';
                }
                if (index === 8) {
                    return '';
                }
                if (index === 9) {
                    return '';
                }
                if (index === 10) {
                    // If the SO in SAP matches the SO in GMP then checkmark, otherwise error
                    if (order_details_sap && order_details_magento) {
                        if (order_details_sap.sap_so_number === order_details_magento.sap_so_number) {
                            return 'Information Consistent';
                        }
                    }
                }
                if (index === 11) {
                    return '';
                }
                if (index === 12) {
                    return '';
                }
                if (index === 13) {
                    // Relationship table PI/ARDP has matching New ARDP and PI
                    if (PI) {
                        if (PI.length > 0) {
                            if (PI[0].U_SAP_ARDPI_LINK && PI[0].U_SAP_LINKED_IP) {
                                return 'Information Consistent';
                            }
                        }
                    }
                }
                if (index === 14) {
                    return '';
                }
                if (index === 15) {
                    return '';
                }
                if (index === 16) {
                    // Invoice data matches SAP and Magento
                    if (OINV.length > 0) {
                        return 'Information Consistent';
                    }
                }
                if (index === 17) {
                    return '';
                }
                if (index === 18) {
                    return '';
                }
                if (index === 19) {
                    // Tracking number in Magento matches SAP
                    console.log('tracking data', tracking_data_magento, tracking_data_sap);
                    if (tracking_data_magento && tracking_data_sap) {
                        if (tracking_data_magento === tracking_data_sap) {
                            return 'Information Consistent';
                        }
                    }
                }
                return 'Information Inconsistent';
            }

            if (row === 2) {
                if (index === 1) {
                    // Order is placed In Magento
                    return 'Completed';
                }
                if (index === 2) {
                    // Integration - Check integration statuses and all data from Magento is in SAP and matches
                    // if (bpa_checks.order_import) {
                    //     return 'Completed';
                    // }
                    if (!importer_error) {
                        return 'Completed';
                    }
                }
                if (index === 3) {
                    // PI posted 
                    // - existence of the PI - @GMP_PIDPI_RLT.U_SAP_PI
                    if (sap_order_details.PI) {
                        if (sap_order_details.PI.length > 0) {
                            const sappi = sap_order_details.PI[0];
                            if (sappi.U_SAP_PI) {
                                return 'Completed';
                            }
                        }
                    }
                }
                if (index === 4) {
                    // Temporary ARDP posted 
                    // - ARDP Invoice is posted with temporary info
                    if (sap_order_details.ODPI) {
                        return 'Completed';
                    }
                }
                if (index === 5) {
                    // Writes to PI/ARDP relationship UDT 
                    // - writes to relationship table @GMP_PIDPI_RLT
                    if (sap_order_details.PI) {
                        if (sap_order_details.PI.length > 0) {
                            return 'Completed';
                        }
                    }
                }
                if (index === 6) {
                    // Incoming Payment posted 
                    // - existence of the payment - ORCT - ??
                    if (ORCT) {
                        if (ORCT.length > 0) {
                            return 'Completed';
                        }
                    }
                }
                if (index === 7) {
                    // Integration 
                    // Process paid down payments 
                    // Posts SO, ARDP and PI to SAP 
                    // Cancels temp ARDP and IP 
                    // Updates PI/ARDP relationship UDT
                    // if (sap_order_details.downpayments) {
                    //     if (sap_order_details.downpayments.length > 0) {
                    //         let downpayments = [];
                    //         sap_order_details.downpayments.forEach(d => {
                    //             if (d.NumAtCard === magento_order_number) {
                    //                 downpayments.push(d);
                    //             }
                    //         })
                    //         if (downpayments.length > 0) {
                    //             return 'Completed';
                    //         }
                    //     }
                    // }
                    if (!importer_error) {
                        return 'Completed';
                    }
                }
                if (index === 8) {
                    // ARDP Canceled 
                    // The Temp ARDP is cancelled 
                    
                    // To see if an ARDPI has been reversed you would need to check to see that 
                    // all the lines (DPI1) on the ARPDI exist in the Credit Memo lines tables (RIN1)
 
                    // DPI1.DocEntry = RIN1.BaseEntry
                    // DPI1.LineNum = RIN1.BaseLine
                    // RIN1.BaseType = 203
                    let DPIS = [];
                    let RINS = [];
                    if (ODPI) {
                        if (ODPI) {
                            if (ODPI.DPI1) {
                                if (ODPI.DPI1.length > 0) {
                                    const DPI_S = ODPI.DPI1;
                                    DPI_S.forEach(dpi => {
                                        DPIS.push({
                                            DocEntry: dpi.DocEntry,
                                            LineNum: dpi.LineNum
                                        });
                                    })
                                }
                            }
                        }
                    }
                    if (ORIN) {
                        if (ORIN.length > 0) {
                            if (ORIN[0]) {
                                if (ORIN[0].RIN1) {
                                    if (ORIN[0].RIN1.length > 0) {
                                        const RIN_S = ORIN[0].RIN1;
                                        RIN_S.forEach(rin => {
                                            RINS.push({
                                                BaseLine: rin.BaseLine,
                                                BaseType: rin.BaseType,
                                                BaseEntry: rin.BaseEntry
                                            })
                                        })
                                    }
                                }
                            }
                        }
                    }
                    console.log('DPI_S - RINS', RINS);
                    console.log('DPI_S - DPIS', DPIS);
                    let credit_memo_exists = 0;
                    DPIS.forEach(dpi => {
                        if (dpi.DocEntry) {
                            // check if exists 
                            RINS.forEach(rin => {
                                if (rin.BaseEntry === dpi.DocEntry) {
                                    credit_memo_exists++;
                                }
                            })
                        }
                    })
                    if (credit_memo_exists > 0 && credit_memo_exists === DPIS.length) {
                        return 'Completed';
                    }
                }
                if (index === 9) {
                    // IP Canceled 
                    // The Temp IP is cancelled 
                    // ORCT.Canceled = 'Y'
                    if (ORCT) {
                        if (ORCT.length > 0) {
                            // get last one ??
                            const last_ORCT = ORCT[ORCT.length - 1];
                            if (last_ORCT.Canceled === 'Y') {
                                return 'Completed';
                            }
                        }
                    }
                }
                if (index === 10) {
                    // SO Posted 
                    // SAP - existence of the SO - ORDR.DocNum
                    if (ORDR.DocNum) return 'Completed';
                }
                if (index === 11) {
                    // Replacement RDP posted - new ARDP exists
                    if (PI) {
                        if (PI.length > 0) {
                            if (PI[0].U_SAP_ARDPI_LINK) {
                                return 'Completed';
                            }
                        }
                    }
                }
                if (index === 12) {
                    // Replacement IP posted - new IP exists
                    if (PI) {
                        if (PI.length > 0) {
                            if (PI[0].U_SAP_LINKED_IP) {
                                return 'Completed';
                            }
                        }
                    }
                }
                if (index === 13) {
                    // Update PI/ARDP relationship UDT
                    // - The relationship table is updated
                    if (PI) {
                        if (PI.length > 0) {
                            if (PI[0].U_SAP_ARDPI_LINK && PI[0].U_SAP_LINKED_IP) {
                                return 'Completed';
                            }
                        }
                    }
                }
                if (index === 14) {
                    // Procurement Document Posted against SO 
                    // the existence of the Purchase Order or Production Request
                    if (OPOR) {
                        if (OPOR.length > 0) {
                            return 'Completed';
                        }
                    }
                }
                if (index === 15) {
                    // Integration 
                    // Update Status - In Magento (in production)
                    // if (GMPStatus.length > 0) {
                    //     return 'Completed';
                    // }
                    if (!importer_error) {
                        return 'Completed';
                    }
                }
                if (index === 16) {
                    // Invoice posted 
                    // SAP - invoice exists in SAP - OINV. Proforma Invoice
                    if (OINV.length > 0) {
                        return 'Completed';
                    }
                }
                if (index === 17) {
                    // Integration 
                    // BPA - Update Status - Internal Status only - completed not shipped
                    // if (GMPStatus.length > 0 && !bpa_checks.gmp_status_shipments) {
                    //     return 'Completed';
                    // }
                    if (!importer_error) {
                        return 'Completed';
                    }
                }
                if (index === 18) {
                    // Tracking added to invoice 
                    // SAP - Tracking number exists 
                    // ODLN.TrackNo
                    if (ODLN.length > 0) {
                        if (ODLN[0].TrackNo) return 'Completed';
                    }
                }
                if (index === 19) {
                    // Integration 
                    // BPA - Update Status - In Magento (Shipped)
                    // if (GMPStatus.length > 0) {
                    //     for (let x = 0; x < shipped_statuses.length; x++) {
                    //         const shipped_status = shipped_statuses[x];
                    //         if (GMPStatus.indexOf(shipped_status) !== -1) {
                    //             return 'Completed';
                    //         }
                    //     }
                    // }
                    if (!importer_error) {
                        return 'Completed';
                    }
                }
            }
            if (row === 3) {
                return locations[2][index-1];
            }
        } else {
            // ==========================
            // scenario 3 - Pay by Credit 
            // ==========================
            if (row === 1) {
                console.log('information--> 1')
                if (index === 1) {
                    console.log('information--> 2')
                    return 'Information Consistent';
                }
                if (index === 2) {
                    if (order_is_consistent) {
                        return 'Information Consistent';
                    }
                }
                if (index === 3) {
                    // If the SO in SAP matches the SO in GMP then checkmark, otherwise error
                    if (order_details_sap && order_details_magento) {
                        if (order_details_sap.sap_so_number === order_details_magento.sap_so_number) {
                            return 'Information Consistent';
                        }
                    }
                }
                if (index === 4) {
                    return '';
                }
                if (index === 5) {
                    return '';
                }
                if (index === 6) {
                    // Invoice data matches SAP and Magento
                    if (OINV.length > 0) {
                        return 'Information Consistent';
                    }
                }
                if (index === 7) {
                    return '';
                }
                if (index === 8) {
                    return '';
                }
                if (index === 9) {
                    // TO DO - Tracking number in Magento matches SAP
                    console.log('tracking data comparing', tracking_data_magento, tracking_data_sap);
                    if (tracking_data_magento && tracking_data_sap) {
                        if (tracking_data_magento === tracking_data_sap) {
                            return 'Information Consistent';
                        }
                    }
                }
                return 'Information Inconsistent';
            }
            if (row === 2) {
                if (index === 1) {
                    // Order is placed In Magento
                    return 'Completed'
                }
                if (index === 2) {
                    // Integration - BPA - Order Import - Posts SO to SAP
                    // If there is an error in the importer log then show error, otherwise show checkmark even if there are inconsistencies. This is just to show if the Integration ran successfully or not, not if it imported the data properly.
                    if (!importer_error) {
                        return 'Completed';
                    }
                    // if (sap_order_details) {
                    //     if (sap_order_details.ORDR) {
                    //         return 'Completed'
                    //     }
                    // }
                }
                if (index === 3) {
                    // Order Posted - existence of the SO
                    // If there is an SO on the order then checkmark, otherwise error
                    if (ORDR.DocNum) return 'Completed';
                }
                if (index === 4) {
                    // Procurement Document Posted against SO 
                    if (sap_order_details) {
                        if (sap_order_details.OPOR) {
                            if (sap_order_details.OPOR.length > 0) {
                                return 'Completed';
                            }
                        }
                    }
                }
                if (index === 5) {
                    // Integration - BPA - Update Status - In Magento (In Production)
                    // if (OPOR.length > 0) {
                    //     return 'Completed';
                    // }
                    // if (GMPStatus.length > 0) {
                    //     if (GMPStatus.indexOf('in_production') !== -1) {
                    //         return 'Completed';
                    //     }
                    // }
                    if (!importer_error) {
                        return 'Completed';
                    }

                }
                if (index === 6) {
                    // Invoice posted - Invoice exists in SAP - OINV. Proforma Invoice - OQUT
                    if (OINV.length > 0) {
                        return 'Completed';
                    }
                }
                if (index === 7) {
                    // Integration - Update Status - Internal Status only (completed not shipped)
                    // if (GMPStatus.length > 0) {
                    //     return 'Completed';
                    // }
                    if (!importer_error) {
                        return 'Completed';
                    }
                }
                if (index === 8) {
                    // Tracking added to Invoice - Tracking number exists - ODLN.TrackNo
                    if (ODLN.length > 0) {
                        if (ODLN[0].TrackNo) return 'Completed';
                    }
                }
                if (index === 9) {
                    // Integration - In Magento (Shipped)
                    // if (GMPStatus.length > 0) {
                    //     for (let x = 0; x < shipped_statuses.length; x++) {
                    //         const shipped_status = shipped_statuses[x];
                    //         if (GMPStatus.indexOf(shipped_status) !== -1) {
                    //             return 'Completed';
                    //         }
                    //     }
                    // }
                    if (!importer_error) {
                        return 'Completed';
                    }
                }
            }
            if (row === 3) {
                return locations[3][index-1];
            }
        }
        return 'Error';
    }
}

const { paymentMethods } = sapMonitorData;
const paymentMethodsDict = {};
Object.keys(paymentMethods).forEach(pm => {
    paymentMethods[pm].forEach(field => {
        paymentMethodsDict[field] = pm;
    })
})
sapMonitorData.getPaymentMethod = (order) => {
    let payment_method = '';
    if (order) {
        if (order.payment) {
            if (order.payment.method) {
                console.log('order payment method', order.payment.method);
                payment_method = paymentMethodsDict[order.payment.method];
            }
        }
    }
    return payment_method;
}

sapMonitorData.getShipping = (ord) => {
    let value = '';
    if (ord) {
        if (ord.extension_attributes) {
            if (ord.extension_attributes.multishipping) {
                if (ord.extension_attributes.multishipping[0]) {
                    if (ord.extension_attributes.multishipping[0].method_title) {
                        value = ord.extension_attributes.multishipping[0].method_title;  
                    }
                }
            }
        }
    }
    return value;
}

sapMonitorData.getShippingZipcode = (ord) => {
    let value = '';
    if (ord) {
        if (ord.extension_attributes) {
            if (ord.extension_attributes.shipping_assignments) {
                if (ord.extension_attributes.shipping_assignments.length > 0) {
                    if (ord.extension_attributes.shipping_assignments[0].shipping) {
                        if (ord.extension_attributes.shipping_assignments[0].shipping.address) {
                            const o = ord.extension_attributes.shipping_assignments[0].shipping.address;
                            value = o.postcode;
                        }
                    }
                }
            }
        }
    }
    return value;
}

sapMonitorData.getCustomerRefNumber = (ord) => {
    let value = '';
    if (ord) {
        if (ord.extension_attributes) {
            if (ord.extension_attributes.amasty_order_attributes) {
                if (ord.extension_attributes.amasty_order_attributes.length > 0) {
                    ord.extension_attributes.amasty_order_attributes.forEach(oo => {
                        if (oo.attribute_code === 'po_number') {
                            if (oo.value) {
                                value = oo.value;
                            }
                        }
                    })
                }
            }
        }
    }
    return value;
}

sapMonitorData.getOCPR = (c, magento_custom_email) => {
    console.log('getting ocpr', c, magento_custom_email);
    if (magento_custom_email) {
        if (c) {
            for (let i = 0; i < c.length; i++) {
                const cc = c[i];
                if (cc.E_MailL === magento_custom_email && cc.U_GMPID) {
                    return cc;
                }
            }
        }
    }
    return {}
}

sapMonitorData.getGMPID = (contacts, magento_custom_email) => {
    let value = '';
    if (contacts) {
        if (contacts.length > 0) {
            contacts.forEach(c => {
                if (c.E_MailL === magento_custom_email && c.U_GMPID) {
                    value = c.U_GMPID;
                }
            })
        }
    }
    return value;
}

sapMonitorData.getAccountBalance = (det) => {
    const t01 = det.account_balance ? det.account_balance : {};
    const OADM = det.OADM ? det.OADM : {};
    let balance = 0;

    const currency = t01.Currency;
    const main_currency = OADM.MainCurncy;
    if (currency === main_currency) {
        balance += makeNum(t01.OrdersBal) + makeNum(t01.DNotesBal) + makeNum(t01.Balance);
    } else {
        balance += makeNum(t01.BalanceFC) + makeNum(t01.DNoteBalFC) + makeNum(t01.OrderBalFC);
    }

    // CASE WHEN T1.Currency = T3.MainCurncy THEN T1.OrdersBal + T1.DNotesBal + T1.Balance ELSE T1.BalanceFC + t1.DNoteBalFC + t1.OrderBalFC END AS NVARCHAR(50
    return balance;
}

sapMonitorData.getRBO = (arv, type, activeRBOs) => {
    console.log('arvs', arv);
    if (!arv) return '';
    let a = [];
    for (let i = 0; i < arv.length; i++) {
        const ar = arv[i];
        if (type === 'rbo') {
            if (ar.ActiveRBOID) {
                let name = '';
                if (activeRBOs) {
                    activeRBOs.forEach(arbo => {
                        if (arbo.active_rbo_id === ar.ActiveRBOID) {
                            name = arbo.active_rbo_name;
                        }
                    })
                }
                a.push(name);
            }
        } else if (type === 'edi') {
            // EDI
            if (ar.EDIProgramID) a.push(ar.EDIProgramID);
        } else {
            if (ar.VendorCode) a.push(ar.VendorCode);
        }
    }
    return a.join(', ');
}

sapMonitorData.getAddress = (adds, type) => {
    if (!adds) return {};
    for (let i = 0; i < adds.length; i++) {
        const add = adds[i];
        if (add.AdresType === type) {
            return add;
        }
    }
    return {}
}

sapMonitorData.getBillingAddressName = (ord) => {
    let value = '';
    if (ord) {
        if (ord.billing_address) {
            if (ord.billing_address.extension_attributes) {
                if (ord.billing_address.extension_attributes.sap_address) {
                    value = ord.billing_address.extension_attributes.sap_address;
                }
            }
        }
    }
    return value;
}

sapMonitorData.getBillingPhone = (ord) => {
    let value = '';
    if (ord) {
        if (ord.billing_address) {
            if (ord.billing_address.telephone) {
                if (ord.billing_address.telephone) {
                    value = ord.billing_address.telephone;
                }
            }
        }
    }
    return value;
}

sapMonitorData.getEDIVendorCodes = (arr, card_code, db_name, type) => {
    console.log('getting edi vendor codes', arr, card_code, db_name);
    let value = [];
    if (arr) {
        arr.forEach(a => {
            if (!a.active_rbo_name) {
                if (type === 'EDI') {
                    if (a.sap_card_code === card_code && a.db_name === db_name) {
                        value.push(`${a.edi_program_id}`);
                    }
                } else {
                    if (a.sap_card_code === card_code && a.db_name === db_name) {
                        value.push(`${a.vendor_code}`);
                    }
                }
            }
        })
    }
    return value.join(', ');
}

sapMonitorData.getBillingAddress = (ord) => {
    let value = '';
    if (ord) {
        if (ord.billing_address) {
            const o = ord.billing_address;
            const street = o.street.length > 0 ? o.street[0] : '';
            value = `${mt(street)} ${mt(o.city)} ${mt(o.country_id)} ${mt(o.postcode)}`;
        }
    }
    return value;
}

sapMonitorData.getShippingAddress = (ord) => {
    let value = '';
    if (ord) {
        if (ord.extension_attributes) {
            if (ord.extension_attributes.shipping_assignments) {
                if (ord.extension_attributes.shipping_assignments.length > 0) {
                    if (ord.extension_attributes.shipping_assignments[0].shipping) {
                        if (ord.extension_attributes.shipping_assignments[0].shipping.address) {
                            const o = ord.extension_attributes.shipping_assignments[0].shipping.address;
                            const street = o.street.length > 0 ? o.street[0] : '';
                            value = `${mt(street)} ${mt(o.city)} ${mt(o.country_id)} ${mt(o.postcode)}`;
                        }
                    }
                }
            }
        }
    }
    return value;
}

sapMonitorData.getSO = (ord) => {
    let value = '';
    if (ord) {
        if (ord.shippingData) {
            if (ord.shippingData.U_Sales_Order_Num) {
                value = ord.shippingData.U_Sales_Order_Num;
            }
        }
    }
    return value;
}

sapMonitorData.getMagentoRBOVendorCodes = (arr, card_code, db_name) => {
    console.log('getting rbo vendor codes', arr, card_code, db_name);
    let value = [];
    if (arr) {
        arr.forEach(a => {
            if (a.active_rbo_name) {
                // RBO 
                if (a.sap_card_code === card_code && a.db_name === db_name) {
                    value.push(`${a.active_rbo_name}`);
                }
            }
        })
    }
    return value.join(', ');
}

sapMonitorData.getCustomAttribute = (field, data) => {
    let value = '';
    if (data) {
        if (data.custom_attributes) {
            data.custom_attributes.forEach(dca => {
                if (dca.attribute_code === field) {
                    value = dca.value;
                }
            })
        }
    }
    return value;
}

sapMonitorData.getShippingLocationNames = (ord) => {
    let value = [];
    if (ord) {
        if (ord.extension_attributes) {
            if (ord.extension_attributes.shipping_assignments) {
                if (ord.extension_attributes.shipping_assignments.length > 0) {
                    ord.extension_attributes.shipping_assignments.forEach(saa => {
                        if (saa.shipping) {
                            const sa = saa.shipping;
                            if (sa.address) {
                                if (sa.address.extension_attributes) {
                                    if (sa.address.extension_attributes.sap_address) {
                                        value.push(sa.address.extension_attributes.sap_address);
                                    }
                                }
                            }
                        }
                    })
                }
            }
        }
    }
    return value.join(', ');
}

sapMonitorData.checkBPequals = (obj) => {
    const { bp_details_magento, bp_details_sap } = obj;
    if (bp_details_magento && bp_details_sap) {
        return Object.keys(bp_details_sap).map(fieldy => {
            let sap_val = bp_details_sap[fieldy];
            if (fieldy === 'contact_name') {
                if (sap_val.indexOf('GMP') !== -1 && sap_val.indexOf(' -') !== -1) {
                    sap_val = sap_val.split(' -')[1];
                }
            }
            if (fieldy === 'bp_currency') {
                sap_val = convert_currency(bp_details_sap[fieldy]);
            }
            let mag_val = bp_details_magento[fieldy];
            if ((fieldy === 'billing_address' || fieldy === 'ship_to_address') && (sap_val && mag_val)) {
                sap_val = sap_val.replace(/\s+/g, " ").trim();
                mag_val = mag_val.replace(/\s+/g, " ").trim();
                console.log('comparing billing address', sap_val, mag_val, sap_val == mag_val);
            }
            const check = sap_val == mag_val ?
                true
            : 
                false
            return [
                fieldy.toUpperCase().replace(/_/g,' '), 
                sap_val,
                mag_val,
                check
            ];
        });
    }
    return [];
}

export default sapMonitorData;

export const convert_currency = (str) => {
    const dict = {
        '$': 'USD'
    }
    if (dict[str]) {
        return dict[str];
    }
    return '';
}