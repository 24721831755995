import React from "react";
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import checkPermissions from '../../shared/checkPermissions';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import History from "@material-ui/icons/History";
import GMPHistoryList from "containers/GMPBPHistory/GMPHistoryList";
import GMPHistoryGroupedList from "containers/GMPBPHistory/GMPHistoryGroupedList";
import GMPHistorySubGroup from "containers/GMPBPHistory/GMPHistorySubGroup";
import GMPHistoryModal from "containers/GMPBPHistory/GMPHistoryModal";
import GMPHistoryFilterModal from "containers/GMPBPHistory/GMPHistoryFilterModal";
import BPReview from "containers/BP/BPReview";
import Button from "components/CustomButtons/Button.jsx";

class GMPHistory extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSingleView: false,
      openModal: false,
      openModal2: false,
      openSubGroupModal: false,
      subGroupData: null,
      isFromSubGroup: false,
      openFilterModal: false,
      modalMetaData: null,

      nonGMPData: null
    }
  }

  openReviewModal = (modalMetaData) => {
    console.log('opening modal 2');
    this.setState({
      openModal2: true,
      modalMetaData,
      isFromSubGroup: modalMetaData.isFromSubGroup
    });
  }

  updateReviewModal = (modalMetaData) => {
    console.log('updating modal 2');
    this.setState({
      modalMetaData,
      isFromSubGroup: modalMetaData.isFromSubGroup
    });
  }

  closeReviewModal = () => {
    this.setState({
      openModal2: false
    });
  }

  setNonGMPData = (nonGMPData) => {
    this.setState({
      nonGMPData
    });
  }

  toggleHistoryView = () => {
    this.setState({
      isSingleView: !this.state.isSingleView,
      isFromSubGroup: !this.state.isFromSubGroup
    });
  }

  openHistoryModal = (modalMetaData) => {
    this.setState({
      openModal: true,
      modalMetaData
    });
  }

  closeHistoryModal = () => {
    this.setState({
      openModal: false
    });
  }

  openHistorySubGroupModal = (subGroupData) => {
    this.setState({
      openSubGroupModal: true,
      subGroupData,
      isFromSubGroup: true
    });
  }

  closeHistorySubGroupModal = () => {
    this.setState({
      openSubGroupModal: false
    });
  }

  openFilter = () => {
    this.setState({
      openFilterModal: true
    });
  }

  closeFilter = () => {
    this.setState({
      openFilterModal: false
    });
  }

  componentDidMount() {
    this.props.checkMaintenanceMode();
    this.props.onFetchGMPHistory();
  }

  render() {
    if (!checkPermissions('GMPHistory')) {
      return <Redirect to="/dashboard" />;
    }

    if (this.props.maintenanceMode === 'yes') return <Redirect to="/components/maintenance_page" />

    return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <div className="pointed" onClick={() => {
                this.props.onFetchGMPHistory();
              }}><History /></div>
            </CardIcon>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <div className="centered-btns">
                  <Button
                    color={this.state.isSingleView ? 'rose' : 'white'}
                    onClick={() => {
                      this.toggleHistoryView();
                    }}
                  >Single View</Button>
                  <Button
                    color={this.state.isSingleView ? 'white' : 'rose'}
                    onClick={() => {
                      this.toggleHistoryView();
                    }}
                  >Group View</Button>
                </div>
              </GridItem>
            </GridContainer>
            {this.state.isSingleView && <GMPHistoryList
                                          toggleHistoryView={this.toggleHistoryView}
                                          openHistoryModal={this.openHistoryModal}
                                          openFilter={this.openFilter}
                                          setNonGMPData={this.setNonGMPData}
                                          openReviewModal={this.openReviewModal}
                                          closeBPReview={this.closeBPReview}
                                          />}
            {!this.state.isSingleView && <GMPHistoryGroupedList
                                          toggleHistoryView={this.toggleHistoryView}
                                          openHistoryModal={this.openHistoryModal}
                                          openHistorySubGroupModal={this.openHistorySubGroupModal}
                                          openFilter={this.openFilter}
                                          />}
            {this.state.openSubGroupModal && <GMPHistorySubGroup
                                          closeHistorySubGroupModal={this.closeHistorySubGroupModal}
                                          open={this.state.openSubGroupModal}
                                          subGroupData={this.state.subGroupData}
                                          openReviewModal={this.openReviewModal}
                                          openFilter={this.openFilter}
                                          />}
            {this.state.openModal && <GMPHistoryModal
                                          closeHistoryModal={this.closeHistoryModal}
                                          open={this.state.openModal}
                                          openHistorySubGroupModal={this.openHistorySubGroupModal}
                                          openHistoryModal={this.openHistoryModal}
                                          subGroupData={this.state.subGroupData}
                                          isFromSubGroup={this.state.isFromSubGroup}
                                          modalMetaData={this.state.modalMetaData}
                                          />}
            {(this.state.openModal2 && (Object.keys(this.props.states).length > 0 && Object.keys(this.props.countries).length > 0)) && <BPReview
                                          changes={this.props.changes}
                                          before={this.props.before}
                                          gmpData={this.props.gmpData}
                                          modalMetaData={this.state.modalMetaData}
                                          isFromSubGroup={this.state.isFromSubGroup}
                                          subGroupData={this.state.subGroupData}
                                          openReviewModal={this.openReviewModal}
                                          updateReviewModal={this.updateReviewModal}
                                          onFetchGMPHistoryItem={this.props.onFetchGMPHistoryItem}
                                          open={this.state.openModal2}
                                          close={this.closeReviewModal}
                                          enterPayTerms={true}
                                      />}
            {this.state.openFilterModal && <GMPHistoryFilterModal
                                          closeFilter={this.closeFilter}
                                          open={this.state.openFilterModal}
                                          />}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    before: state.gmp.historyItemData ? state.gmp.historyItemData.before : null,
    changes: state.gmp.historyItemData ? state.gmp.historyItemData.changes : null,
    gmpData: state.gmp.historyItemData,
    states: state.common.statesDictionary,
    countries: state.common.countriesDictionary,
    maintenanceMode: state.userPerms.maintenanceMode
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchGMPHistory: () => dispatch(actions.fetchGMPHistory()),
    onFetchGMPHistoryItem: (id, submission_id) => dispatch(actions.fetchGMPHistoryItem(id, submission_id)),
    checkMaintenanceMode: () => {
      dispatch(actions.checkMaintenanceMode());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GMPHistory);
