const defaultBP = {
  id: '', // submission id
  gmp_id: null, // if coming from GMP, if null not from GMP

  // SAP general
  card_code: '',
  bp_type: '',
  bp_subgroup: '',
  payterms: '',
  credit_limit: '0',
  acctname: '',
  company_name: '',
  currency: '',
  gen_tel: '',
  gen_fax: '',

  // Resale Certificate - for NLA, Bill to State California
  resale_cert: null, // placeholder for file upload
  resale_certificate: '', // name of file paths

  nit: '', // NGT only
  rfc_curp: '', // NMX and billing country MX
  old_code: '', // NAS only

  // NIN only
  panno: '',
  tinno: '',
  withholding: false,
  // NIN and withholding true
  certificate_num: '',
  ni_num: '',
  expiration_date: '',
  assessee_type: '',
  accrual_criteria: '',
  threshold_overlook: '',
  surcharge_overlook: '',
  wtcodes: [],

  // Billing Address (only 1)
  billing_addressname: '',
  billing_address1: '',
  billing_address2: '',
  billing_country: '',
  billing_state: '',
  billing_city: '',
  billing_zipcode: '',
  billing_gstregno: '',
  billing_gstregtype: '',

  // Shipping Address 1
  shipping_addressname: '',
  shipping_address1: '',
  shipping_address2: '',
  shipping_country: '',
  shipping_state: '',
  shipping_city: '',
  shipping_zipcode: '',
  shipping_gstregno: '',
  shipping_gstregtype: '',
  default_shipping: '-1',
  additionalShippingAddresses: [],

  // Contact 1
  accounts_payable_name: '',
  accounts_payable_oname: '', // fill this up when you need to edit the name
  accounts_payable_position: '',
  accounts_payable_tel: '',
  accounts_payable_alttel: '',
  accounts_payable_mobile: '',
  accounts_payable_fax: '',
  accounts_payable_email: '',
  accounts_payable_firstname: '',
  accounts_payable_lastname: '',
  accounts_payable_gmp: '',

  // Contact 2
  purchasing_name: '',
  purchasing_oname: '',
  purchasing_position: '',
  purchasing_tel: '',
  purchasing_alttel: '',
  purchasing_mobile: '',
  purchasing_fax: '',
  purchasing_email: '',
  purchasing_firstname: '',
  purchasing_lastname: '',
  purchasing_gmp: '',

  // Contacts
  additionalContacts: [],

  notes: '',
  rbos: null,
  edi_vendor: null
}

export default defaultBP;
