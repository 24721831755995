import React, { Component } from "react";
import { connect } from 'react-redux';
import ReactDOM from "react-dom";
import { PulseLoader } from 'react-spinners';
import * as actions from 'store/actions';

import withStyles from "@material-ui/core/styles/withStyles";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import combineStyles from "shared/combineStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import SweetAlert from "react-bootstrap-sweetalert";

import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";
import checkPermissionsActions from "shared/checkPermissionsActions";
import { normalizePhone, denormalizePhone, upperCase, cleanAllPhones } from "shared/utility";
import defaultBP from "shared/bpReference";
import { cloneDeep } from "lodash";

import BPForm from "containers/BP/BPForm";

class BPNew extends Component {
  constructor(props) {
    super(props);
    props.reportCurrentDB('');
    props.reportCurrentBPType('');
    this.state = {
      currentDB: null,
      currentBPType: '',
      load: true,
      loadingModal: false,
      data: defaultBP,
      key: 12345,
      askModal: false,
      askRequest: ''
    }

  }

  resetDBBPType = () => {
    this.setState({
      currentDB: null,
      currentBPType: ''
    });
  }

  onSubmit = (values) => {
    // console.log('submitted',values);
  }

  handleBPTypeChange = (bp_type, company_name) => {
    if (this.state.currentBPType !== bp_type) {
       if (this.state.currentBPType && company_name) {
         this.openAskModal(`BPType,${bp_type}`);
       } else {
         this.changeBPType(bp_type);
       }
    }
  }

  handleDBChange = (db) => {
    if (this.state.currentDB !== db) {
       if (this.state.currentDB) {
         this.openAskModal(`DB,${db}`);
       } else {
         this.changeDB(db);
       }
    }
  }

  changeBPType = (bp_type) => {
    this.setState({
      currentBPType: bp_type,
      load: true
    });
    this.changeKey();
    this.props.reportCurrentBPType(bp_type);
  }

  changeDB = (db) => {
    this.setState({
      load:false
    },() => {
      this.setState({
        currentDB: db,
        load: true,
        currentBPType: 'Customer'
      });
    });
    this.changeKey();
    this.props.fetchAddInfoList(db);
    if (db.indexOf('NIN') !== -1) {
      this.props.fetchGSTType();
    }
    this.props.reportCurrentDB(db);
  }

  openAskModal = (askRequest) => {
    this.setState({
      askModal: true,
      askRequest
    });
  }

  closeAskModal = () => {
    this.setState({
      askModal: false
    });
  }

  changeKey = () => {
    this.setState({
      key: Math.floor(Math.random()*90000) + 10000,
      data: defaultBP
    });
  }

  handleDeny = (values) => {
    // console.log('denying submission');
  }

  handleApprove = (finalValues, file, filename, isTPP) => {
    const submittedDB = finalValues.DB;
    const actionPerms = checkPermissionsActions('BP');
    let directToSAP = false;
    if (actionPerms.hasOwnProperty('approve')) {
      actionPerms.approve.forEach((db) => {
        if (submittedDB === db) {
          directToSAP = true;
        }
      });
    }

    this.changeKey();
    this.setState({
      currentDB: '',
      currentBPType: '',
      loadingModal: true
    });
    if (!finalValues.card_code) {
        finalValues.submission_type = 'new';
    }
    finalValues.origin = 'other';
    if (directToSAP) {
      if (file && filename) {
        // Send file directly to SAP Server
        this.props.saveSAPBP(finalValues, file, filename, isTPP);
      } else {
        this.props.saveSAPBP(finalValues, null, null, isTPP);
      }
    } else {
      if (file && filename) {
        // this.props.sendResaleCertFile(file, filename);
        this.props.saveLocalBP(finalValues, file, filename);
      } else {
        this.props.saveLocalBP(finalValues);
      }
    }
  }

  toggleLoadingModal = () => {
    this.setState({
      loadingModal: !this.state.loadingModal
    });
  }

  componentDidMount() {
    this.props.resetCommon();
    this.props.resetGMPBP();
    this.props.fetchDBList();
    this.props.fetchGSTType();
  }

  render() {
    let allowedDBs = [];
    const actionPerms = checkPermissionsActions('BP');
    console.log('actionPerms', actionPerms);
    // console.log('BPNew state/props', this.state, this.props);
    if (actionPerms.hasOwnProperty('create')) {
      actionPerms.create.forEach((db) => {
        if (allowedDBs.indexOf(db) === -1) {
          allowedDBs.push(db);
        }
      });
    }
    allowedDBs.sort();

    let directToSAP = [];
    if (actionPerms.hasOwnProperty('approve')) {
      actionPerms.approve.forEach((db) => {
        directToSAP.push(db);
      });
    }

    let enterPayTerms = false;
    if (actionPerms.hasOwnProperty('enter-accounting')) {
      enterPayTerms = true;
    }

    return (
    <div>
    {
      (this.props.dbs.length > 0 && this.state.load && !this.props.loading) &&
      <BPForm
        key={this.state.key}
        onSubmit={this.onSubmit}
        handleDBChange={this.handleDBChange}
        handleBPTypeChange={this.handleBPTypeChange}
        allowedDBs={allowedDBs}
        currentDB={this.state.currentDB}
        currentBPType={this.state.currentBPType}
        enterPayTerms={enterPayTerms}
        data={this.state.data}
        BPaction="NEW"
        handleApprove={this.handleApprove}
        directToSAP={directToSAP}
        loadBPForReview={this.props.loadBPForReview}
        submissionType="new"
      />
    }
    {
      this.state.loadingModal &&
      <SweetAlert
          title={"New BP"}
          style={{ display: "block" }}
          onConfirm={() => {
            //this.toggleLoadingModal();
            this.props.refresh();
            //window.location.href = '/bp/?p=new';
            this.resetDBBPType();
          }}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          showConfirm={!this.props.loadingSAPBPMsg ? false : true}
          >
          <div style={{fontSize:'14px'}}>
            {!this.props.loadingSAPBPMsg && <div className="min-box">Submitting, Please Wait<PulseLoader /></div>}
            {this.props.loadingSAPBPMsg}
          </div>
        </SweetAlert>
    }
    {
      this.state.askModal &&
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Yes"
        cancelBtnText="No"
        confirmBtnCssClass={
          this.props.classes.button + " " + this.props.classes.success
        }
        cancelBtnCssClass={
          this.props.classes.button + " " + this.props.classes.default
        }
        title="Are you sure?"
        onConfirm={() => {
          if (this.state.askRequest.indexOf('DB') !== -1) {
            const db = this.state.askRequest.split(',')[1];
            this.changeDB(db);
          }
          if (this.state.askRequest.indexOf('BPType') !== -1) {
            const bp_type = this.state.askRequest.split(',')[1];
            this.changeBPType(bp_type);
          }
          this.closeAskModal();
        }}
        onCancel={this.closeAskModal}
        >
        {this.state.askRequest.indexOf('DB') !== -1 && <div>Selecting a different DB will discard all changes, Proceed ?</div>}
        {this.state.askRequest.indexOf('BPType') !== -1 && <div>Selecting a different BP Type will discard all changes, Proceed ?</div>}
      </SweetAlert>
    }
  </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dbs: state.common.dbs,
    loadingSAPBP: state.common.loadingSAPBP,
    loadingSAPBPMsg: state.common.loadingSAPBPMsg
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    sendResaleCertFile: (file, filename) => {
      dispatch(actions.sendResaleCertFile(file, filename));
    },
    fetchDBList: () => {
      dispatch(actions.fetchDBList());
    },
    fetchAddInfoList: (db) => {
      dispatch(actions.fetchAddInfoList(db));
    },
    fetchGSTType: () => {
      dispatch(actions.fetchGSTType());
    },
    loadBPForReview: (bpName, db, cardcode, bp_type, reviewOnly) => {
      dispatch(actions.fetchBPSAP(db, bpName, cardcode, '', bp_type, reviewOnly));
    },
    saveLocalBP: (data, file, filename) => {
      dispatch(actions.saveLocalBP(data, file, filename));
    },
    saveSAPBP: (data, file, filename, isTPP) => {
      dispatch(actions.saveSAPBP(data, file, filename, isTPP));
    },
    resetSAPBP: (data) => {
      dispatch(actions.resetSAPBP());
    },
    closeSaveSAPBPModal: () => {
      dispatch(actions.closeSaveSAPBPModal());
    },
    resetGMPBP: () => {
      dispatch(actions.resetGMPBP());
    },
    resetCommon: () => {
      dispatch(actions.resetCommon());
    }
  }
}

const combinedStyles = combineStyles(sweetAlertStyle, userProfileStyles);
export default connect(mapStateToProps,mapDispatchToProps)(withStyles(combinedStyles)(BPNew));
