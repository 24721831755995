import React from 'react';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import Button from "components/CustomButtons/Button.jsx";
import { decConvert, commatize, compare } from '../../shared/utility';

const AvailableRangePopup = props => {
    let available = [], final = [], lastArr = [],
        recorded = {}, ranges = {}, endOfArr = null;
    const productionQty = props.productionQty,
          startingSerial = props.startingSerial,
          manufacturerID = props.manufacturerID,
          upcCode = props.upcCode;
    if (props.queryResults) {
      // gather all currently used up RFIDs
      if (props.queryResults.length > 0) {
        props.queryResults.forEach(qr => {
          const start = (props.company !== 'allbirds' && props.company !== 'jcpenny1') ? qr.start.substring(3) : qr.start,
                end = (props.company !== 'allbirds' && props.company !== 'jcpenny1') ? qr.end.substring(3) : qr.end;
          const intStart = parseInt(start),
                intEnd = parseInt(end);
          for (let i = intStart; i < intEnd+1; i++) {
            if (!recorded.hasOwnProperty(i)) recorded[i] = '';
          }
        })
        console.log('recorded',recorded);
        const recordedSorted = Object.keys(recorded).map(r => parseInt(r)).sort((a, b) => a - b);;
        console.log('recorded array', recordedSorted);
        let started = recordedSorted[0];
        ranges[recordedSorted[0]] = {
          start: recordedSorted[0],
          end: null
        };
        recordedSorted.forEach((now,i) => {
          if (i !== 0) {
            const next = recordedSorted[i+1];
            console.log('next', next);
            if (next) {
              if (now+1 !== next) {
                console.log('--+', now+1, next);
                ranges[started].end = now;
                started = next;
                ranges[next] = {
                  start: next,
                  end: null
                }
              }
            } else {
              ranges[started].end = now;
              console.log('next --',now,next, i);
            }
          }
        })
        console.log('next ranges', ranges);
        Object.keys(ranges).forEach(r => {
          available.push(ranges[r]);
        })
      }
      console.log('available range 1', available);
      available.sort(compare);
      console.log('available range 2', available);
      if (available[0]) {
        const avail = available[0];
        if (props.company === 'allbirds') {
          // ALLBIRDS
          if (avail.start !== 10000000001) {
            if (avail.start > (10000000000 + productionQty)) {
              final.push({
                available_start: 10000000001,
                available_end: 10000000000 + productionQty
              });
            }
          }
        } else if (props.company === 'jcpenny1') {
          // JCPENNY 25000000000
          if (avail.start !== 25000000001) {
            if (avail.start > (25000000000 + productionQty)) {
              final.push({
                available_start: 25000000001,
                available_end: 25000000000 + productionQty
              });
            }
          }
        } else {
          // WALMART
          if (avail.start !== 1) {
            if (avail.start > productionQty) {
              final.push({
                available_start: 1,
                available_end: productionQty
              });
            }
          }
        }
  
      }
      console.log('available - ', available);
      available.forEach((av,i) => {
        const next = available[i+1]
        console.log('checking for next', next)
        if (next) {
          const difference = next.start - av.end;
          console.log('difference', difference);
          if (Math.sign(difference) === 1) {
            const start = av.start,
                  end = av.end,
                  available_start = end + 1,
                  available_end = end + productionQty,
                  diff_start = next.start - available_start,
                  diff_end = next.start - available_end;
            console.log('ms', Math.sign(diff_start), Math.sign(diff_end));
            if ((Math.sign(diff_start) !== -1 && Math.sign(diff_start) !== 0) && (Math.sign(diff_end) !== -1 && Math.sign(diff_start) !== 0)) {
              final.push({
                start,
                end,
                available_start,
                available_end,
                diff_start,
                diff_end
              });
            }
          }
        }
      })
      lastArr = final;
      if (props.company === 'allbirds') {
        endOfArr = available[available.length-1] ? available[available.length-1].end : 10000000000;
        if (endOfArr || endOfArr === 0) {
          endOfArr += 1;
        }
      }
      if (props.company === 'jcpenny1') {
        endOfArr = available[available.length-1] ? available[available.length-1].end : 25000000000;
        if (endOfArr || endOfArr === 0) {
          endOfArr += 1;
        }
      }
      if (props.company === 'walmart') {
        endOfArr = available[available.length-1] ? available[available.length-1].end : 0;
        if (endOfArr || endOfArr === 0) {
          endOfArr += 1;
        }
      }
  
      console.log('final available', final, lastArr, endOfArr);
    }
  
    return (
      <Dialog
        onClose={props.close}
        open={props.open}
        keepMounted
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle style={{paddingBottom: 0}} onClose={() => props.close()}>
          Available Serial Range for UPC # {upcCode}
        </DialogTitle>
  
        <DialogContent style={{overflowY:'unset'}}>
          <div style={{margin:20}}>
            <div>Based on a production quantity of <strong>{productionQty.toLocaleString()}</strong>, this is the lowest available serial range</div>
            <hr />
            {props.company === 'allbirds' || props.company === 'jcpenny1' &&
              <div>
                {lastArr.length > 0 && [lastArr[0]].map((f,i) => {
                  return <div key={`${i}-final`}>{commatize(manufacturerID + decConvert(f.available_start))} - {commatize(manufacturerID + decConvert(f.available_end))}</div>
                })}
                {lastArr.length === 0 && <div>{commatize(decConvert(endOfArr))} - {props.startingSerial ? commatize(decConvert(endOfArr + (productionQty - 1))) : ''}</div>}
              </div>
            }
  
            {(props.company === 'walmart') &&
              <div>
                {lastArr.length > 0 && [lastArr[0]].map((f,i) => {
                  return <div key={`${i}-final`}>{commatize(manufacturerID + decConvert(f.available_start))} - {commatize(manufacturerID + decConvert(f.available_end))}</div>
                })}
                {lastArr.length === 0 && <div>{commatize(manufacturerID + decConvert(endOfArr))} - {props.startingSerial ? commatize(manufacturerID + decConvert(endOfArr + (productionQty - 1))) : ''}</div>}
              </div>
            }
  
  
            <Button
              style={{marginTop:20,marginRight:20}}
              color="success"
              onClick={() => {
                const e1 = {
                  target: {
                    value: ''
                  }
                };
                const e2 = {
                  target: {
                    value: ''
                  }
                };
                if (lastArr.length > 0) {
                  const f = lastArr[0];
                  props.handleSet(f.available_start, 'startingSerial');
                  props.close();
                } else {
                  props.handleSet(endOfArr, 'startingSerial');
                  props.close();
                }
              }}
            >USE THIS RANGE</Button>
            <Button
              style={{marginTop:20}}
              color="primary"
              onClick={() => {
                props.close();
              }}
            >CLOSE</Button>
          </div>
        </DialogContent>
      </Dialog>
    )
  }

  export default AvailableRangePopup;