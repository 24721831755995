import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import InputAdornment from '@material-ui/core/InputAdornment';
import { convertToCommas, convertToSerial } from "shared/utility";
import Button from "components/CustomButtons/Button.jsx";

const InputUPC = props => {
    const [val, setVal] = useState('');
    useEffect(() => {
        setVal(props.upcCode);
    }, []);
    return (
      <Dialog
        onClose={() => { props.close(); }}
        open={props.open}
        keepMounted
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle style={{paddingBottom: 0}} onClose={() => props.close()}>
          Please enter UPC
        </DialogTitle>
  
        <DialogContent style={{overflowY:'unset'}}>
          <form onSubmit={(e) => {
            e.preventDefault();
            props.setUpcCode(val);
            props.close();
          }}>
          <GridItem xs={12} sm={3} md={3}>
          </GridItem>
          <GridItem xs={12} sm={9} md={9}>
            <CustomInput
              labelText="UPC"
              id="input2"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: (e) => {
                  console.log('e',e.target.value)
                  setVal(e.target.value);
                },
                value: val,
                autoFocus: true,
                role: 'presentation',
                autoComplete: 'nope',
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            {<Button
              color="success"
              style={{marginRight:20,marginTop:10}}
              onClick={() => {
                props.setUpcCode(val);
                props.close();
              }}
            >ENTER</Button>}
            <Button
              type="button"
              style={{marginRight:20,marginTop:10}}
              color="primary"
              onClick={() => {
                props.close();
              }}
            >CANCEL</Button>
          </GridItem>
          </form>
        </DialogContent>
      </Dialog>
    )
  }

export default InputUPC;