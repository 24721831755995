import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import * as actions from '../../store/actions';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import Table from "components/Table/Table.jsx";
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import Datetime from "react-datetime";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
import InputAdornment from '@material-ui/core/InputAdornment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import cloneDeep from "lodash/cloneDeep";
import Button from "components/CustomButtons/Button.jsx";
import ReactTable from "react-table";
import GINSelect from "./GINselect";
import activeDBs from "shared/activeDBs";
import { checkOneYearEndDate } from "./GINhelpers";
import { isValidDate, numberWithCommas, checkDateOrder, dateToday, convertToFixed, checkMinMax } from 'shared/utility';

const DBlabel = props => {
  // props.name
  let backgroundColor = '';
  activeDBs.forEach((db) => {
    if (db.name === props.name) {
      backgroundColor = db.color;
    }
  });

  if (backgroundColor) {
    return (
      <span style={{backgroundColor}} className="db-pill">
        {props.name.split('_')[0]}
      </span>
    );
  }
  return null;
}

const Options = props => {
  const list = props.options.map((warehouse,key) => {
    return (
      <ListItem
        key={'listoptions'+key}
        button
        onClick={() => {
          props.choose(warehouse,props.index);
        }}>
        {warehouse}
      </ListItem>
    )
  });
  return <div>{list}</div>
}

class GINreviewDialogEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.data.value ? props.data.value : '',
      error: props.data.error ? props.data.error : '',
      field: ''
    }
  }

  onInputChange = (target, val) => {
    this.setState({
      [target]: val
    })
  }

  handleBOMItemBaseQty = () => {
    const db = this.props.data.db;
    if (!this.state.error) {
      this.props.updateGINEdit({
        db,
        field: 'BOMItemBaseQty',
        value: this.state.value
      })
      this.props.close();
    }
  }

  renderBOMItemBaseQty = () => {
    return <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CustomInput
            labelText="BOM Item Base Quantity"
            id="bomItem"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: (e) => {
                if (!isNaN(e.target.value)) {
                  if (parseInt(e.target.value) > 1000000) {
                    this.setState({error:'Please enter qty. below 1,000,000'});
                  } else {
                    this.setState({error:''});
                  }
                  this.onInputChange('value', e.target.value);
                }
              },
              value: this.state.value,
              role: 'presentation',
              autoComplete: 'nope',
              autoFocus: true
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <div style={{marginTop:20}}>
            <Button color="success" onClick={this.handleBOMItemBaseQty}>ENTER</Button>
          </div>
          <div style={{marginTop:10}}>
            <div className="red-text">{this.state.error}</div>
          </div>
        </GridItem>
      </GridContainer>
    </div>
  }

  handleRMSQ = () => {
    const db = this.props.data.db, i = this.props.data.index;
    if (!this.state.error) {
      let copy = cloneDeep(this.props.rmsq);
      console.log('rmsq', i, copy[db]);
      copy[db][i] = this.state.value;
      this.props.updateGINEdit({
        db,
        field: 'rmsq',
        value: copy[db]
      })
      this.props.close();
    }
  }

  renderRMSQ = () => {
    return <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CustomInput
            labelText="Raw Material Quantity"
            id="rmq"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: (e) => {
                if (!isNaN(e.target.value)) {
                  if (parseInt(e.target.value) > 1000000) {
                    this.setState({error:'Please enter qty. below 1,000,000'});
                  }
                  this.onInputChange('value', e.target.value);
                }
              },
              value: this.state.value,
              role: 'presentation',
              autoComplete: 'nope',
              autoFocus: true
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <div style={{marginTop:20}}>
            <Button color="success" onClick={this.handleRMSQ}>ENTER</Button>
          </div>
          <div style={{marginTop:10}}>
            <div className="red-text">{this.state.error}</div>
          </div>
        </GridItem>
      </GridContainer>
    </div>
  }

  handleRMSW = (warehouse, index) => {
    const db = this.props.data.db;
    let copy = cloneDeep(this.props.rmsw);
    copy[db][index] = warehouse;
      this.props.updateGINEdit({
        db,
        field: 'rmsw',
        value: copy[db]
      })
    this.props.close();
  }

  renderRMSW = () => {
    const db = this.props.data.db;
    return <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CustomInput
            labelText="RM Description"
            id="rmqc"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: (e) => {

              },
              value: this.props.data.rm_description,
              role: 'presentation',
              autoComplete: 'nope',
              autoFocus: true,
              disabled: true
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
          <CustomInput
            labelText="Current RM Warehouse"
            id="rmq"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: (e) => {

              },
              value: this.state.value,
              role: 'presentation',
              autoComplete: 'nope',
              autoFocus: true,
              disabled: true
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
          <CustomInput
            labelText="RM Code"
            id="rmqc"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: (e) => {

              },
              value: this.props.data.rm_code,
              role: 'presentation',
              autoComplete: 'nope',
              autoFocus: true,
              disabled: true
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <div className="text-center" style={{marginTop:20}}>Select one</div>
          <div style={{marginTop:20}}>
            <Options
              options={this.props.options[db].rm_warehouse}
              index={this.props.data.index}
              choose={this.handleRMSW}
            />
          </div>
        </GridItem>
      </GridContainer>
    </div>
  }

  renderInputContent = (label, target, currency) => {
    return <CustomInput
      labelText={label}
      id={target}
      formControlProps={{
        fullWidth: true
      }}
      inputProps={{
        onChange: this.handleFloatChange,
        value: this.state.value,
        role: 'presentation',
        autoComplete: 'nope',
        autoFocus: true,
        disabled: false,
        startAdornment: (
          <InputAdornment position="start">
            {currency}
          </InputAdornment>
        )
      }}
    />
  }

  renderDateContent = () => {
    return <GridContainer>
      <GridItem xs={12} sm={12} md={6}>
        <div className="calendar-adjust" style={{overflow:'visible',padding:'0px 20px 40px 40px',width:'100%'}}>
          <Datetime
            inputProps={{ placeholder: "Start Date" }}
            dateFormat="MMM DD YYYY"
            timeFormat={false}
            value={this.state.value.split(' TO ')[0]}
            onChange={(date) => {
              this.handleDateChange(date, 'start')
            }}
            closeOnSelect={true}
          />
        </div>
      </GridItem>
      <GridItem xs={12} sm={12} md={6}>
        <div className="calendar-adjust" style={{overflow:'visible',padding:'0px 20px 40px 40px',width:'100%'}}>
          <Datetime
            inputProps={{ placeholder: "End Date" }}
            dateFormat="MMM DD YYYY"
            timeFormat={false}
            value={this.state.value.split(' TO ')[1]}
            onChange={(date) => {
              this.handleDateChange(date, 'end')
            }}
            closeOnSelect={true}
          />
        </div>
      </GridItem>
    </GridContainer>
  }

  updateItemIs = () => {
    this.props.updateGINEdit({
      db: this.props.data.db,
      field: 'item_is',
      value: this.state.value
    });
  }

  handleFloatChange = (e) => {
    const value = e.target.value;
    if (!isNaN(value)) {
      if (!checkMinMax(value, 5)) {
        if (this.props.data.subType !== 'moc' && value == 0) {
          this.setState({error:'', value});
          return;
        }
        this.setState({error:'Price needs to be between 0.00001 and maximum of 100,000. ', value});
      } else {
        this.setState({error:'', value});
      }
    }
  }

  handleDateChange = (date, field) => {
    const valueArr = this.state.value.split(' TO ');
    let start = valueArr[0],
        end = valueArr[1];
    if (typeof date === 'string') {
      return this.setState({error:'Invalid Date.'});
    }
    if (field === 'start') {
      start = date.format('MMM DD YYYY');
    } else {
      end = date.format('MMM DD YYYY');
    }
    if (checkDateOrder(start, end)) {
      this.setState({
        value: `${start} TO ${end}`.toUpperCase(),
        error: ''
      });
    } else {
      this.setState({error:'Start Date must be before End Date.'});
    }
    if (!checkOneYearEndDate(start, end)) {
      this.setState({error:'End Date must be within one year of Start Date.'});
    }
  }

  updateDate = (value, field, index) => {
    const db = this.props.data.db;
    if (index === -1) {
      this.props.updateGINEdit({
        db,
        field,
        value
      })
    } else {
      let copy = cloneDeep(this.props[field]);
      copy[db][index] = value;
      this.props.updateGINEdit({
        db,
        field,
        value: copy[db]
      })
    }
  }

  handleSelectUpdate2 = (target, target_index) => {
    const props = this.props, data = props.data, subType = data.subType,
          db = data.db, type = data.type, cardcode = data.cardcode, currency = data.currency,
          start = data.start, end = data.end;
    let combo = null;
    const value = subType === 'price' ? convertToFixed(this.state.value) : this.state.value;
    console.log('update2 params', target, target_index, cardcode);
    if (target === 'specialmoc') {
      let tgt = 'bmasterarray';
      if (type === 'supplier-cost') {
        tgt = 'smasterarray';
      }
      let copy = cloneDeep(this.props[tgt]);
      if (copy[db]) {
        Object.keys(copy[db]).forEach(comb => {
          if (comb.split('[.]')[0] === cardcode) {
            combo = comb;
          }
        })
      }
      console.log('found combo', combo, cardcode);
      if (combo) {
        let found = false;
        copy[db][combo].forEach((row,i) => {
          if (row[0]) {
            if (!parseInt(row[0])) {
              console.log('found combo 1', row);
              if (row[5] === currency) {
                copy[db][combo][i][2] = this.state.value;
                console.log('found combo 2', copy[db]);
                found = true;
              }
            }
          }
        });
        if (!found) {
          copy[db][combo].push([
            '0','',this.state.value,'',cardcode,currency,start,end
          ]);
        }
        props.updateGINEdit({
          db, field: tgt, value: copy[db]
        });
      }
      props.close();
      if (this.props.checkDupesSupplierCost && data.type === 'supplier-cost') {
        console.log('checking dupes smasterarray 1');
        setTimeout(() => {
          this.props.checkDupesSupplierCost();
        }, 500);
      }
      if (this.props.checkDupesCustomerPrice && data.type === 'customer-price') {
        console.log('checking dupes bmasterarray 2');
        setTimeout(() => {
          this.props.checkDupesCustomerPrice();
        }, 500);
      }
      return;
    }
    if (target_index === -1) {
      // date
      const arr = this.state.value.split(' TO '),
            start = arr[0],
            end = arr[1];
      // check if valid
      let copy = cloneDeep(this.props[target]);
      if (copy[db]) {
        Object.keys(copy[db]).forEach(comb => {
          if (comb.split('[.]')[0] === cardcode) {
            combo = comb;
          }
        })
      }
      if (combo) {
        copy[db][combo][data.index][6] = start;
        copy[db][combo][data.index][7] = end;
        props.updateGINEdit({
          db, field: target, value: copy[db]
        });
      }
    } else {
      let copy = cloneDeep(this.props[target]);
      if (copy[db]) {
        Object.keys(copy[db]).forEach(comb => {
          if (comb.split('[.]')[0] === cardcode) {
            combo = comb;
          }
        })
      }
      if (combo) {
        copy[db][combo][data.index][target_index] = value;
        props.updateGINEdit({
          db, field: target, value: copy[db]
        });
      }
    }
    this.props.close();
    if (this.props.checkDupesSupplierCost && data.type === 'supplier-cost') {
      console.log('checking dupes smasterarray 2');
      setTimeout(() => {
        this.props.checkDupesSupplierCost();
      }, 500);
    }
    if (this.props.checkDupesCustomerPrice && data.type === 'customer-price') {
      console.log('checking dupes bmasterarray 2');
      setTimeout(() => {
        this.props.checkDupesCustomerPrice();
      }, 500);
    }
    return;
  }

  handleSelectUpdate = (target) => {
    const props = this.props, data = props.data, subType = data.subType,
          db = data.db, type = data.type;
    const value = subType === 'price' ? convertToFixed(this.state.value) : this.state.value;
    if (subType === 'moc') {
      const currency = data.currency;
      let copy = cloneDeep(this.props.specialmoc);
      if (copy[db]) {
        if (copy[db].length > 0) {
          let found = false;
          copy[db].forEach((c,i) => {
            if (c[0] === currency) {
              copy[db][i] = [currency, this.state.value, ''];
              found = true;
            }
          });
          if (!found) {
            // add moc
            copy[db].push([currency, this.state.value, '']);
          }
        } else {
          // add moc
          copy[db].push([currency, this.state.value, '']);
        }
      }
      props.updateGINEdit({
        db,
        field: 'specialmoc',
        value: copy[db]
      });
      props.close();
      return;
    }
    if (subType !== 'date') {
      if (data.index === -1) {
        props.updateGINEdit({
          db,
          field: target,
          value
        })
      } else {
        let copy = cloneDeep(this.props[target]);
        copy[db][data.index] = value;
        props.updateGINEdit({
          db,
          field: target,
          value: copy[db]
        })
      }
    } else {
      let prefix = 'c';
      if (type === 'all-price') {
        prefix = 'g';
      }
     const date_arr = this.state.value.split(' TO '),
           start = date_arr[0],
           end = date_arr[1];
      if (data.index === -1) {
        this.updateDate(start, `${prefix}start`, data.index);
        this.updateDate(end, `${prefix}end`, data.index);
      } else {
        this.updateDate(start, `${prefix}starts`, data.index);
        this.updateDate(end, `${prefix}ends`, data.index);
      }
    }
    props.close()
    if (this.props.checkDupes && type === 'all-price') {
      console.log('checking dupes all price');
      setTimeout(() => {
        this.props.checkDupes();
      }, 500);
    }
    if (this.props.checkDupes && type === 'target-cost') {
      console.log('checking dupes target-cost');
      setTimeout(() => {
        this.props.checkDupesTargetCost();
      }, 500);
    }
  }

  handleChoose = (value, field) => {
    this.setState({value,field});
  }

  renderAllPricing = () => {
    const props = this.props, data = props.data, subType = data.subType,
          db = data.db;
    let label = '', choose = null, options = [], target = '', contentType = '', currency = '';
    if (subType === 'quantity') {
      label = 'Quantity';
    } else if (subType === 'term') {
      label = 'Term';
      options = this.props.options[db].terms.pricing_terms;
      contentType = 'select';
      target = 'gterms';
      if (data.index === -1) target = 'gterm';
    } else if (subType === 'price') {
      label = 'Price';
      contentType = 'input';
      currency = data.currency;
      target = 'gprices';
      if (data.index === -1) target = 'gprice';
    } else if (subType === 'currency') {
      label = 'Currency';
      contentType = 'select';
      options = this.props.options[db].currencies.map(c => c.symb);
      target = 'gcurrs';
      if (data.index === -1) target = 'gcur';
    } else if (subType === 'date') {
      label = 'Date';
      contentType = 'date';
      target = 'gstarts';
      if (data.index === -1) target = 'gstart';
    } else if (subType === 'moc') {
      label = 'MOC';
      contentType = 'input';
      currency = data.currency;
      target = 'specialmoc';
    }
    const content = contentType === 'select' ?
    <div className="select-adjust">
      <GINSelect
        label={label}
        options={options}
        choose={this.handleChoose}
        name={subType}
        indx=""
        desc=""
        default={this.state.value}
        open={true}
      />
    </div> :
    contentType === 'input' ?
    <div>
      {this.renderInputContent(label, target, currency)}
    </div> :
    <div>
      {this.renderDateContent()}
    </div>
    return <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        {content}
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <div style={{marginTop:20}} className="text-center">
          <Button color="success" onClick={() => {
            if (!this.state.error) {
              this.handleSelectUpdate(target);
            }
          }}>UPDATE</Button>
        </div>
        <div style={{marginTop:10}}>
          <div className="red-text text-center">{this.state.error}</div>
        </div>
      </GridItem>
    </GridContainer>
  }

  renderCustomerPricing = () => {
    const props = this.props, data = props.data, subType = data.subType,
          db = data.db;
    let label = '', choose = null, options = [], target = 'bmasterarray', contentType = '', currency = '',
        target_index = -1;
    if (subType === 'quantity') {
      label = 'Quantity';
      target_index = 0;
    } else if (subType === 'term') {
      label = 'Term';
      options = this.props.options[db].terms.pricing_terms;
      contentType = 'select';
      target_index = 1;
    } else if (subType === 'price') {
      label = 'Price';
      contentType = 'input';
      currency = data.currency;
      target_index = 2;
    } else if (subType === 'currency') {
      label = 'Currency';
      contentType = 'select';
      options = this.props.options[db].currencies.map(c => c.symb);
      target_index = 5;
    } else if (subType === 'date') {
      label = 'Date';
      contentType = 'date';
    } else if (subType === 'moc') {
      label = 'MOC';
      contentType = 'input';
      currency = data.currency;
      target = 'specialmoc';
    }
    const content = contentType === 'select' ?
    <div className="select-adjust">
      <GINSelect
        label={label}
        options={options}
        choose={this.handleChoose}
        name={subType}
        indx=""
        desc=""
        default={this.state.value}
        open={true}
      />
    </div> :
    contentType === 'input' ?
    <div>
      {this.renderInputContent(label, target, currency)}
    </div> :
    <div>
      {this.renderDateContent()}
    </div>
    return <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        {content}
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <div style={{marginTop:20}} className="text-center">
          <Button color="success" onClick={() => {
            if (!this.state.error) {
              this.handleSelectUpdate2(target, target_index);
            }
          }}>UPDATE</Button>
        </div>
        <div style={{marginTop:10}}>
          <div className="red-text text-center">{this.state.error}</div>
        </div>
      </GridItem>
    </GridContainer>
  }

  renderSupplierCosting = () => {
    const props = this.props, data = props.data, subType = data.subType,
          db = data.db;
    let label = '', choose = null, options = [], target = 'smasterarray', contentType = '', currency = '',
        target_index = -1;
    if (subType === 'quantity') {
      label = 'Quantity';
      target_index = 0;
    } else if (subType === 'term') {
      label = 'Term';
      options = this.props.options[db].terms.costing_terms;
      contentType = 'select';
      target_index = 1;
    } else if (subType === 'price') {
      label = 'Price';
      contentType = 'input';
      currency = data.currency;
      target_index = 2;
    } else if (subType === 'currency') {
      label = 'Currency';
      contentType = 'select';
      options = this.props.options[db].currencies.map(c => c.symb);
      target_index = 5;
    } else if (subType === 'date') {
      label = 'Date';
      contentType = 'date';
    } else if (subType === 'moc') {
      label = 'MOC';
      contentType = 'input';
      currency = data.currency;
      target = 'specialmoc';
    }
    const content = contentType === 'select' ?
    <div className="select-adjust">
      <GINSelect
        label={label}
        options={options}
        choose={this.handleChoose}
        name={subType}
        indx=""
        desc=""
        default={this.state.value}
        open={true}
      />
    </div> :
    contentType === 'input' ?
    <div>
      {this.renderInputContent(label, target, currency)}
    </div> :
    <div>
      {this.renderDateContent()}
    </div>
    return <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        {content}
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <div style={{marginTop:20}} className="text-center">
          <Button color="success" onClick={() => {
            if (!this.state.error) {
              this.handleSelectUpdate2(target, target_index);
            }
          }}>UPDATE</Button>
        </div>
        <div style={{marginTop:10}}>
          <div className="red-text text-center">{this.state.error}</div>
        </div>
      </GridItem>
    </GridContainer>
  }

  renderTargetCost = () => {
    const props = this.props, data = props.data, subType = data.subType,
          db = data.db;
    let label = '', choose = null, options = [], target = '', contentType = '', currency = '';
    if (subType === 'quantity') {
      label = 'Quantity';
    } else if (subType === 'term') {
      label = 'Term';
      options = this.props.options[db].terms.costing_terms;
      contentType = 'select';
      target = 'cterms';
      if (data.index === -1) target = 'cterm';
    } else if (subType === 'price') {
      label = 'Price';
      contentType = 'input';
      currency = data.currency;
      target = 'cprices';
      if (data.index === -1) target = 'cprice';
    } else if (subType === 'currency') {
      label = 'Currency';
      contentType = 'select';
      options = this.props.options[db].currencies.map(c => c.symb);
      target = 'ccurrs';
      if (data.index === -1) target = 'ccur';
    } else if (subType === 'date') {
      label = 'Date';
      contentType = 'date';
      target = 'cstarts';
      if (data.index === -1) target = 'cstart';
    }
    const content = contentType === 'select' ?
    <div className="select-adjust">
      <GINSelect
        label={label}
        options={options}
        choose={this.handleChoose}
        name={subType}
        indx=""
        desc=""
        default={this.state.value}
        open={true}
      />
    </div> :
    contentType === 'input' ?
    <div>
      {this.renderInputContent(label, target, currency)}
    </div> :
    <div>
      {this.renderDateContent()}
    </div>
    return <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        {content}
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <div style={{marginTop:20}} className="text-center">
          <Button color="success" onClick={() => {
            if (!this.state.error) {
              this.handleSelectUpdate(target);
            }
          }}>UPDATE</Button>
        </div>
        <div style={{marginTop:10}}>
          <div className="red-text text-center">{this.state.error}</div>
        </div>
      </GridItem>
    </GridContainer>
  }

  renderItemIs = () => {
    const props = this.props, data = props.data, subType = data.subType,
          db = data.db;
    const content = <div className="select-adjust">
      <GINSelect
        label="Select Source"
        options={['Both', 'Produced in House', 'Outsourced', 'None']}
        choose={(v,f) => {
          this.setState({value:v}, () => {
            this.updateItemIs();
            this.props.close();
          });
        }}
        name="item_is"
        indx=""
        desc=""
        default={this.state.value}
        open={true}
      />
    </div>
    return <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        {content}
      </GridItem>
    </GridContainer>
  }

  render() {
    console.log('st and props --- review dialog edit', this.state, this.props);
    const props = this.props, data = props.data, type = data.type;
    const overflow = data.subType === 'date' ? 'overflowed' : '';
    return (
      <Dialog
        open={this.props.open}
        keepMounted
        onClose={this.props.close}
        maxWidth="sm"
        fullWidth={true}
        classes={{
          paper: overflow,
        }}
      >
        <DialogTitle>
          <div style={{textAlign:'center'}}>{data.title} <DBlabel name={data.db} /></div>
          <div className="close-btn">
            <IconButton aria-label="Close" onClick={this.props.close}>
              <Close />
            </IconButton>
          </div>
        </DialogTitle>
        <div style={{margin:20}}>
          {type === 'item_is' && <div>{this.renderItemIs()}</div>}
          {type === 'BOMItemBaseQty' && <div>{this.renderBOMItemBaseQty()}</div>}
          {type === 'rmsq' && <div>{this.renderRMSQ()}</div>}
          {type === 'rmsw' && <div>{this.renderRMSW()}</div>}
          {type === 'all-price' && <div>{this.renderAllPricing()}</div>}
          {type === 'customer-price' && <div>{this.renderCustomerPricing()}</div>}
          {type === 'supplier-cost' && <div>{this.renderSupplierCosting()}</div>}
          {type === 'target-cost' && <div>{this.renderTargetCost()}</div>}
        </div>
      </Dialog>
    );
  }
}

const mapStateToProps = state => {
  return {
    rmsq: state.gin.rmsq,
    rmsw: state.gin.rmsw,
    openedDBs: state.gin.openedDBs,
    options: state.gin.options,

    gprice: state.gin.gprice,
    gprices: state.gin.gprices,
    gterms: state.gin.gterms,
    gterm: state.gin.gterm,
    gstarts: state.gin.gstarts,
    gstart: state.gin.gstart,
    gends: state.gin.gends,
    gend: state.gin.gend,

    cprice: state.gin.cprice,
    cprices: state.gin.cprices,
    cterm: state.gin.cterm,
    cterms: state.gin.cterms,
    cstarts: state.gin.cstarts,
    cstart: state.gin.cstart,
    cends: state.gin.cends,
    cend: state.gin.cend,

    bmasterarray: state.gin.bmasterarray,
    smasterarray: state.gin.smasterarray,

    specialmoc: state.gin.specialmoc
  }
};

const mapDispatchToProps = dispatch => {
  return {
    updateGINEdit: (data) => {
      dispatch(actions.updateGINEdit(data));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GINreviewDialogEdit);
