import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';
const initialState = {
    users: [],
    directory: [],
    loading: false,
    activeCountry: null,
    activeOffice: null,
    activeDepartment: null,
    activeUser: null,
    modal: false,
    miniModal: '',
    error: null
}

const setActiveUser = (state, action) => {
    let country = null;
    let office = null;
    let department = null;
    if (action.userIndex != null) { // if active user is existing user
        country = state.users[action.userIndex].country;
        office = state.users[action.userIndex].office;
        department = state.users[action.userIndex].department;
    }
    return updateObject(state, { activeUser: action.userIndex, activeCountry: country, activeOffice: office, activeDepartment: department });
}

const setActiveCountry = (state, action) => {
    if (action.country !== state.activeCountry) {
        const newObject = { activeCountry: action.country };
        newObject.activeOffice = null;
        newObject.activeDepartment = null;
        return updateObject(state, newObject);
    }
    return state;
}

const setActiveOffice = (state, action) => {
    if (action.office !== state.activeOffice) {
        console.log('hereee');
        const newObject = { activeOffice: action.office };
        newObject.activeDepartment = null;
        return updateObject(state, newObject);
    }
    return state;
}

const setActiveDepartment = (state, action) => {
    if (state.activeDepartment !== action.department) {
        return updateObject(state, { activeDepartment: action.department });
    }
    return state;
}

const setLoading = (state) => {
    return updateObject(state, { loading: true });
}


const moldUsersWithDirectory = (users, directory) => { // set user location and department through pairing fields with the directory
    return users.map(user => {
        const newUser = {
            ...user, // user from AD
            country: null,
            office: null,
            department: null,
            activeSecurityGroup: null,
            status: user.userAccountControl == 512 || user.userAccountControl == 66048 ? 'Active' : 'Inactive'
        }
        let usedDN = user.dn;
        let country = directory.find(d => user.dn.indexOf(d.name) > -1);

        if (!country) {

            if (typeof user.memberOf === 'string') {

                usedDN = user.memberOf;
                country = directory.find(d => user.memberOf.indexOf(d.name) > -1);
            } else if (typeof user.memberOf === 'object') {
                country = directory.find(d => user.memberOf.find(mo => mo.indexOf(`OU=${d.name}`) > -1));
                if (country) {
                    usedDN = user.memberOf.find(u => u.indexOf(country.name) > -1);
                }
            }
        }
        if (country) {

            const office = country.offices.find(o => usedDN.indexOf(o.name) > -1);
            newUser.country = country.name;
            if (office) {
                newUser.office = office.name;
                let department = null;
                office.departments.forEach((offed) => {
                  if (usedDN.indexOf(offed.name) > -1) {
                    department = offed;
                  }
                })
                if (department) {
                    newUser.department = department.name;
                    newUser.activeSecurityGroup = department.groupDN;
                }
            }
        }
        return newUser;
    })
}

const fetchADSuccess = (state, action) => {
    return updateObject(state, { users: moldUsersWithDirectory(action.users, action.directory), directory: action.directory, loading: false });
}

const addADUserSuccess = (state, action) => {
    return updateObject(state, { users: moldUsersWithDirectory(action.users, state.directory), modal: false, miniModal: 'successAdded' });
}

const addADUserFail = (state, action) => {
    return updateObject(state, { miniModal: 'failAdded', modal: false });
}

const modifyADUserSuccess = (state, action) => {
    return updateObject(state, { users: moldUsersWithDirectory(action.users, state.directory), modal: false, miniModal: 'successModified' });
}

const deleteADUserSuccess = (state, action) => {
    return updateObject(state, { users: moldUsersWithDirectory(action.users, state.directory), miniModal: 'successDeleted', activeUser: null });
}

const getFailure = (state, action) => {
    return updateObject(state, { loading: false, error: action.error });
}

const setADModalState = (state, action) => {
    return updateObject(state, { modal: action.isOpen });
}

const setADMiniModalState = (state, action) => {
    return updateObject(state, { miniModal: action.modalState });
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_AD_START:

            return setLoading(state, action);
        case actionTypes.MODIFY_AD_USER_START:
        case actionTypes.ADD_AD_USER_START:
        case actionTypes.DELETE_AD_USER_START:
            return state;
        case actionTypes.FETCH_AD_SUCCESS:
            return fetchADSuccess(state, action);

        case actionTypes.FETCH_AD_FAIL:
        //case actionTypes.ADD_AD_USER_FAIL:
        case actionTypes.MODIFY_AD_USER_FAIL:
        case actionTypes.DELETE_AD_USER_FAIL:
            return getFailure(state, action);

        case actionTypes.SET_ACTIVE_COUNTRY:
            return setActiveCountry(state, action);

        case actionTypes.SET_ACTIVE_OFFICE:
            return setActiveOffice(state, action);

        case actionTypes.SET_ACTIVE_DEPARTMENT:
            return setActiveDepartment(state, action);

        case actionTypes.SET_ACTIVE_USER:
            return setActiveUser(state, action);

        case actionTypes.ADD_AD_USER_SUCCESS:
            return addADUserSuccess(state, action);
        case actionTypes.ADD_AD_USER_FAIL:
            return addADUserFail(state, action);
        case actionTypes.MODIFY_AD_USER_SUCCESS:
            return modifyADUserSuccess(state, action);
        case actionTypes.DELETE_AD_USER_SUCCESS:
            return deleteADUserSuccess(state, action);
        case actionTypes.SET_AD_MODAL_STATE:
            return setADModalState(state, action);
        case actionTypes.SET_AD_MINI_MODAL_STATE:
            return setADMiniModalState(state, action);

        default: return state;
    }
};


/*
"directory": [
        {
            "name": "NATco US",
            "offices": [
                {
                    "name": "Los Angeles",
                    "departments": [
                        {
                            "name": "Accounting",
                            "groupDN": "CN=US-LA - Accounting,OU=Accounting,OU=Los Angeles,OU=NATco US,OU=NATco Users,OU=natcoglobal.com,OU=Customers,DC=crstest,DC=local",
                            "ouDN": "OU=Accounting,OU=Los Angeles,OU=NATco US,OU=NATco Users,OU=natcoglobal.com,OU=Customers,DC=crstest,DC=local"
                        },
                    ]
                }
            ]
        }
    ]


*/
export default reducer;
