import jwt_decode from 'jwt-decode';

const isUserLoggedIn = () => {
  const token = localStorage.getItem('token');
  if (!token) {
    console.log('no token found');
    return false;
  } else if (token) {
    const decoded = jwt_decode(token);
    const currentTime = Date.now() / 1000;
    let totalSeconds = Math.round(((currentTime - decoded.exp)));
    totalSeconds = -totalSeconds > 0 ? -totalSeconds : totalSeconds;
    console.log('total seconds Left Till Expiration', totalSeconds, decoded);

    let hours = Math.floor(totalSeconds / 3600);
    totalSeconds %= 3600;
    let minutes = Math.floor(totalSeconds / 60);
    let seconds = totalSeconds % 60;

    // If you want strings with leading zeroes:
    minutes = String(minutes).padStart(2, "0");
    hours = String(hours).padStart(2, "0");
    seconds = String(seconds).padStart(2, "0");

    console.log('HH:MM:SS Left Till Expiration', hours+':'+minutes+':'+seconds);
    if (decoded.exp < currentTime) {
      localStorage.removeItem('token');
      window.location.href = '/pages/login-page';
      console.log('expired');
      return false;
    }

    let sections = [];
    console.log('permObj', decoded.permObj);
    Object.keys(decoded.permObj).forEach((p) => {
      const section = p.split(' ')[0];
      if (sections.indexOf(section) === -1) {
        sections.push(section);
      }
    });
    return sections;
  }
}

export const getUserID = () => {
  const token = localStorage.getItem('token');
  if (!token) {
    console.log('no token found');
    return false;
  } else if (token) {
    const decoded = jwt_decode(token);
    return decoded.id;
  }
}

export default isUserLoggedIn;
