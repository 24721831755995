import React from "react";
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import SweetAlert from "react-bootstrap-sweetalert";
import withStyles from "@material-ui/core/styles/withStyles";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import * as actions from '../../store/actions';
import checkPermissions from '../../shared/checkPermissions';
import checkActions from '../../shared/checkPermissionsActions';
// import querystring from 'query-string';
import { qs } from 'shared/utility.js';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Cached from "@material-ui/icons/Cached";

import GINMain from "containers/GIN/GINMain";
import GINPendingList from "containers/GIN/GINPendingList";

class GIN extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      view: null,
      action: null, // 'create' or 'approve',
      notifyAlert: false,
      pendingItemAutoOpen: null
    }
  }

  componentDidMount() {
    this.props.checkMaintenanceMode();
    console.log('this is the query string', this.props.location.search);
    let params = qs(this.props.location.search);
    console.log('params',params);
    const actions = checkActions('GIN');
    console.log('actions',actions);
    if (!actions) return;
    if (actions.hasOwnProperty('create')) {
      this.setState({
        action: 'create'
      })
    }

    if (actions.hasOwnProperty('approve')) {
      this.setState({
        action: 'approve'
      })
    }

    if (params.p && actions.hasOwnProperty('approve')) {
      console.log('params p', params.p)
      if (params.p === 'existing') {
        this.setView('Existing');
      }

      if (params.p === 'new') {
        this.setView('New');
      }

      if (params.p === 'pending') {
        this.setView('Pending');
        if (params.q) {
          this.setState({pendingItemAutoOpen:params.q})
        }
      }
    }
  }

  toggleAlertNotification = () => {
    this.setState({
      notifyAlert: !this.state.notifyAlert
    })
  }

  setTargetTab = (target) => {
    this.setState({
      targetTab: target
    })
  }

  setView = (component) => {
    this.setState({
      view: component
    })
  }

  closeView = () => {
    console.log('closeview');
    this.setState({
      view: null
    })
  }

  openAlert = (targetTab) => {
    this.setState({targetTab,notifyAlert:true})
  }

  closeAlert = () => {
    this.setState({targetTab:null,notifyAlert:false})
  }

  render() {
    if (!checkPermissions('GIN')) {
      return <Redirect to="/dashboard" />;
    }
    if (this.props.maintenanceMode === 'yes') return <Redirect to="/components/maintenance_page" />

    return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card style={{backgroundColor:'#e5e5e5',maxWidth:'1400px'}}>
          <CardHeader color="rose" icon>
            {
              (this.state.action === 'create' || this.state.action === 'approve') &&
              <CardIcon
                color={this.state.view === 'New' ? 'success' : 'rose'}
                onClick={() => {
                  if (this.props.ginNum && this.state.view !== 'New') {
                    this.openAlert('New');
                    return;
                  }
                  this.setView('New');
                }}
                className="pointed"
              >
                <strong>NEW</strong>
              </CardIcon>
            }
            {
              (this.state.action === 'create' || this.state.action === 'approve') &&
              <CardIcon
                color={this.state.view === 'Existing' ? 'success' : 'rose'}
                onClick={() => {
                  if (this.props.ginNum && this.state.view !== 'Existing') {
                    this.openAlert('Existing');
                    return;
                  }
                  this.setView('Existing');
                }}
                className="pointed"
              >
                <strong>EXISTING</strong>
              </CardIcon>
            }
            {
              this.state.action === 'approve' &&
              <CardIcon
                color={this.state.view === 'Pending' ? 'success' : 'rose'}
                className="pointed bold-on-hover"
                onClick={() => {
                  if (this.props.ginNum && this.state.view !== 'Pending') {
                    this.openAlert('Pending');
                    return;
                  }
                  this.setView('Pending');
                }}
              >
                <strong>PENDING REQUESTS</strong>
              </CardIcon>
            }
          </CardHeader>
          <CardBody>
            {this.state.view === 'New' &&
              <GINMain
                action={this.state.action}
                ginType="NEW"
                closeView={this.closeView}
                ginSource="New"
              />
            }
            {this.state.view === 'Existing' &&
              <GINMain
                action={this.state.action}
                ginType="EXISTING"
                closeView={this.closeView}
                ginSource="New"
              />
            }
            {this.state.view === 'Pending' &&
              <GINPendingList
                pendingItemAutoOpen={this.state.pendingItemAutoOpen}
              />
            }
          </CardBody>
        </Card>
      </GridItem>

      {this.state.notifyAlert && <SweetAlert
        warning
        showCancel
        confirmBtnText="Yes"
      	cancelBtnText="No"
        confirmBtnCssClass={
          this.props.classes.button + " " + this.props.classes.success
        }
        cancelBtnCssClass={
          this.props.classes.button + " " + this.props.classes.default
        }
        title="Are you sure?"
        onConfirm={() => {
          this.setView(this.state.targetTab);
          this.closeAlert();
          this.props.releaseUserLockedGIN();
        }}
        onCancel={() => {
          this.closeAlert();
        }}
        >
        Any changes made will be discarded. Do you want to proceed?
      </SweetAlert>}

    </GridContainer>
    );
  }
}

const mapStateToProps = state => {
  return {
    maintenanceMode: state.userPerms.maintenanceMode,
    ginNum: state.gin.ginNum
  }
}

const mapDispatchToProps = dispatch => {
  return {
    checkMaintenanceMode: () => {
      dispatch(actions.checkMaintenanceMode());
    },
    releaseUserLockedGIN: () => {
      dispatch(actions.releaseUserLockedGIN());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(sweetAlertStyle)(GIN));
