import { put, all, takeLatest, call } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import axios from '../axios/axios-gmp';
import axios2 from '../axios/axios-common';
import * as actions from '../actions/GMP';

export function* checkVendorCodeSaga(action) {
  yield put(actions.checkVendorCodeStart());
  try {
    console.log('checking vendor code in MagentoDB');
    const response = yield axios.get('/get_vendor_code/'+action.code+'/'+action.edi);
    console.log('checking', response.data);
    const responsed = response.data;
    yield put(actions.checkVendorCodeSuccess(responsed));
  } catch(err) {
    console.log('there was an error',err);
    yield put(actions.checkVendorCodeFail(err));
  }
}

function vcAPI(code) {
  return axios.get('/get_vendor_code/'+code);
}

export function* checkVendorCodesSaga(action) {
  yield put(actions.checkVendorCodeStart());
  try {
    console.log('checking vendor codes in MagentoDB', action.codesArr, action.edisArr);
    for (let i = 0; i < action.codesArr.length; i++) {
      const response = yield axios.get(`/get_vendor_code/${action.codesArr[i]}/${action.edisArr[i]}`);
      console.log(`checking ${i} - ${action.codesArr[i]}`, response.data);
      if (i === action.codesArr.length - 1) {
        yield put(actions.checkVendorCodesSuccess(response.data, action.edisArr[i], true));
      } else {
        yield put(actions.checkVendorCodesSuccess(response.data, action.edisArr[i], false));
      }
    }
    //yield put(actions.checkVendorCodesSuccess(data));
  } catch(err) {
    console.log('there was an error',err);
    yield put(actions.checkVendorCodesFail(err));
  }
}

export function* fetchEDIProgramsSaga() {
  try {
    console.log('starting fetch of edi programs');
    const response = yield axios.get('/edi_programs');
    const edi_programs = response.data;
    yield put(actions.fetchEDIProgramsSuccess(edi_programs));
  } catch(err) {
    console.log(err);
    yield put(actions.fetchEDIProgramsFail(err));
  }
}

export function* fetchGMPSaga() { // saga for getting data from GMP endpoint
    yield put(actions.fetchGMPStart());

    try {
        console.log('fetching GMP list of BPs');
        const response = yield axios.get();
        const fetchedGMP = response.data;
        yield put(actions.fetchGMPSuccess(fetchedGMP));
    }
    catch (err) {
        console.log(err);
        yield put(actions.fetchGMPFail(err));
    }
}

export function* fetchGMPAddressSaga(action) {
  yield put(actions.fetchGMPAddressStart());

  try {
      const response = yield axios.get('/bp_address/'+action.id);
      const fetchedAddresses = response.data;
      yield put(actions.fetchGMPAddressSuccess(fetchedAddresses));
  }
  catch (err) {
      console.log(err);
      yield put(actions.fetchGMPAddressFail(err));
  }
}

export function* fetchGMPContactSaga(action) {
  yield put(actions.fetchGMPContactStart());

  try {
      const response = yield axios.get('/bp_contacts/'+action.id);
      const fetchedContacts = response.data;
      yield put(actions.fetchGMPContactSuccess(fetchedContacts));
  }
  catch (err) {
      console.log(err);
      yield put(actions.fetchGMPContactFail(err));
  }
}

export function* fetchGMPRBOSaga(action) {
  yield put(actions.fetchGMPRBOStart());
  try {
    const response = yield axios.get('/rbo/'+action.db);
    const fetchedRBOs = response.data;
    yield put(actions.fetchGMPRBOSuccess(fetchedRBOs));
  }
  catch (err) {
      console.log(err);
      yield put(actions.fetchGMPRBOFail(err));
  }
}

export function* createGMPBPSaga(action) {
  yield put(actions.createGMPBPStart());
  try {
    const response = yield axios.post('/bp', action.bp);
    console.log(response.data);
    yield put(actions.createGMPBPSuccess(response.data));
  } catch (err) {
    console.log(err);
    yield put(actions.createGMPBPFail(err));
  }
}

export function* createGMPBPExistingSaga(action) {
  yield put(actions.createGMPBPExistingStart());
  console.log('sending...');
  try {
    const response = yield axios.post('/bp_existing', action.values);
    yield put(actions.createGMPBPExistingSuccess(response.data));
  } catch (err) {
    console.log(err);
    yield put(actions.createGMPBPExistingFail(err));
  }
}

export function* denyGMPBPSaga(action) {
  yield put(actions.denyGMPBPStart());
  console.log('denying 1 ....', action);
  try {
    const response = yield axios.post('/deny', action.values);
    yield put(actions.denyGMPBPSuccess(response.data));
    if (action.values) {
      const av = action.values;
      if (av.DB && av.company_name) {
        const lock_response = yield axios2.post('/lock_bp', {
          db: av.DB,
          bpname: av.company_name,
          action: 'UNLOCK'
        })
      }
    }
  } catch (err) {
    console.log(err);
    yield put(actions.denyGMPBPFail(err));
  }
}

export function* denyGMPBPExistingSaga(action) {
  yield put(actions.denyGMPBPExistingStart());
  console.log('denying 2 ....', action);
  try {
    const response = yield axios.post('/deny_existing', action.values);
    yield put(actions.denyGMPBPExistingSuccess(response.data));
  } catch (err) {
    console.log(err);
    yield put(actions.denyGMPBPExistingFail(err));
  }
}

export function* fetchGSTTypeSaga(action) {
  try {
    const response = yield axios.get('/gsttypes');
    yield put(actions.fetchGSTTypeSuccess(response.data));
  } catch (err) {
    console.log(err);
    yield put(actions.fetchGSTTypeFail(err));
  }
}

export function* fetchGMPSimilarsSaga(action) {
  console.log('fetching similars', action.data);
  yield put(actions.fetchGMPSimilarsStart());
  try {
    const response = yield axios.post('/similarities', action.data);
    yield put(actions.fetchGMPSimilarsSuccess(response.data));
  } catch (err) {
    console.log(err);
    yield put(actions.fetchGMPSimilarsFail(err));
  }
}

export function* fetchGMPHistorySaga(action) {
  console.log('fetching GMP History');
  yield put(actions.fetchGMPHistoryStart());
  try {
    const response = yield axios.get('/history');
    yield put(actions.fetchGMPHistorySuccess(response.data));
  } catch (err) {
    console.log(err);
    yield put(actions.fetchGMPHistoryFail());
  }
}

export function* fetchGMPHistoryItemSaga(action) {
  console.log('fetching GMP History Item', action.id, action.submission_id);
  yield put(actions.fetchGMPHistoryItemStart());
  try {
    const response = yield axios.get(`/history/bp/${action.id}/${action.submission_id}`);
    console.log('fetched history item', response.data);
    yield put(actions.fetchGMPHistoryItemSuccess(response.data));
  } catch (err) {
    console.log(err);
    yield put(actions.fetchGMPHistoryItemFail());
  }
}

export function* fetchGMPExistingSimilarsSaga(action) {
  yield put(actions.fetchGMPExistingSimilarsStart());
  yield call(delay, 1000);
  console.log('sending',action.invoice_num, action.bpname, action.email, action.db);
  try {
    const response = yield axios.get(`/similars/existing/?i=${action.invoice_num}&b=${action.bpname.toUpperCase()}&e=${action.email}&d=${action.db}`);
    console.log('received gmp ex', response.data);
    yield put(actions.fetchGMPExistingSimilarsSuccess(response.data));
  } catch (err) {
    console.log(err);
    yield put(actions.fetchGMPExistingSimilarsFail(err));
  }
}

export function* convertNewToExistingSaga(action) {
  yield put(actions.convertNewToExistingStart());
  try {
    const response = yield axios.post('/bp_convert_new_to_existing', {
      id: action.id
    });
    console.log('response', response.data);
    yield put(actions.convertNewToExistingSuccess(response.data));
    if (response.data) {
      if (response.data.newId) {
        const newId = response.data.newId;
        window.location.href = `/BP/?p=pending&id=${newId}&db=${action.db}&existing=yes`;
      }
    }
  } catch(err) {
    console.log(err);
    yield put(actions.convertNewToExistingFail(err));
  }
}

export function* convertExistingToNewSaga(action) {
  yield put(actions.convertExistingToNewStart());
  try {
    const response = yield axios.post('/bp_convert_existing_to_new', {
      new_id: action.new_id,
      old_id: action.old_id
    });
    console.log('response', response.data);
    yield put(actions.convertExistingToNewSuccess(response.data));
    if (response.data) {
      if (response.data.msg === 'OK') {
        window.location.href = `/BP/?p=pending&id=${action.old_id}&db=${action.db}&existing=no`;
      }
    }
  } catch(err) {
    console.log(err);
    yield put(actions.convertNewToExistingFail(err));
  }
}
