import React from "react";
import ReactDOM from "react-dom";
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import invariant from 'redux-immutable-state-invariant';

import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import adReducer from './store/reducers/activeDirectory';
import authReducer from './store/reducers/auth';
import gmpReducer from './store/reducers/gmp';
import ginReducer from './store/reducers/gin';
import commonReducer from './store/reducers/common';
import userPermsReducer from './store/reducers/userperms';
import rfidReducer from './store/reducers/rfid';
import soReducer from './store/reducers/so';
import settingsReducer from './store/reducers/settings';
import lockedElementsReducer from './store/reducers/locked_elements';
import translationsReducer from './store/reducers/translations';
import sapMonitorReducer from './store/reducers/sap_monitor';
import { reducer as formReducer } from 'redux-form'

import { watchAD } from './store/sagas/';
import indexRoutes from "routes/index.jsx";
import "assets/scss/material-dashboard-pro-react.css?v=1.2.0";
import devStatus from 'shared/devStatus';

if (window.location.hostname === 'stage.natcoglobal.net') window.location.href = 'https://staging.portal2.natcoglobal.com';
if (window.location.hostname === 'stage2.natcoglobal.com') window.location.href = 'https://staging.portal2.natcoglobal.com';
if (window.location.hostname === 'portal2.natcoglobal.net') window.location.href = 'https://portal2.natcoglobal.com';

let composeEnhancers = null || compose;
switch(devStatus) {
  case 'live':
  case 'stage':
    composeEnhancers = null || compose;
    break;
  case 'local_stage':
  case 'local_live':
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ;
    break;
  default:
    composeEnhancers = null || compose;
}

const rootReducer = combineReducers({
  auth: authReducer,
  ad: adReducer,
  form: formReducer,
  gmp: gmpReducer,
  common: commonReducer,
  gin: ginReducer,
  userPerms: userPermsReducer,
  rfid: rfidReducer,
  so: soReducer,
  settings: settingsReducer,
  lockedElements: lockedElementsReducer,
  translations: translationsReducer,
  sap_monitor: sapMonitorReducer
})

const sagaMiddleware = createSagaMiddleware();

const store = createStore(rootReducer, composeEnhancers(
  applyMiddleware(invariant(), sagaMiddleware)
));

sagaMiddleware.run(watchAD);

const hist = createBrowserHistory();
const app = (
  <Router history={hist}>
    <Switch>
      {indexRoutes.map((prop, key) => {
        return <Route path={prop.path} component={prop.component} key={key} />;
      })}
    </Switch>
  </Router>
);
ReactDOM.render(<Provider store={store}>
  {app}
</Provider>,
  document.getElementById("root")
);
