import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';
import dbList from '../../shared/activeDBs';
import cloneDeep from 'lodash/cloneDeep';
import activeDBs from 'shared/activeDBs';
import moment from 'moment';
const initialState = {
    lockedElementsLoading: false,
    lockedElementsData: null,
    lockedMsg: '',
}

const getBPLocksStart = (state, action) => {
  return updateObject(state, {
    lockedElementsLoading: true,
    lockedElementsData: null,
    lockedElementsError: null
  });
}

const getBPLocksSuccess = (state, action) => {
  return updateObject(state, {
    lockedElementsLoading: false,
    lockedElementsData: action.response
  });
}

const getBPLocksFail = (state, action) => {
  return updateObject(state, {
    lockedElementsLoading: false,
    lockedElementsData: null,
    lockedElementsError: 'Error Loading Locked BPs'
  });
}

const getItemLocksStart = (state, action) => {
  return updateObject(state, {
    lockedElementsLoading: true,
    lockedElementsData: null,
    lockedElementsError: null
  });
}

const getItemLocksSuccess = (state, action) => {
  return updateObject(state, {
    lockedElementsLoading: false,
    lockedElementsData: action.response
  });
}

const getItemLocksFail = (state, action) => {
  return updateObject(state, {
    lockedElementsLoading: false,
    lockedElementsData: null,
    lockedElementsError: 'Error Loading Locked Items'
  });
}


const checkItemsLockStart = (state, action) => {
  return updateObject(state, {
    lockedElementsLoading: true,
    lockedElementsData: null,
    lockedElementsError: null
  });
}

const checkItemsLockSuccess = (state, action) => {
  return updateObject(state, {
    lockedElementsLoading: false,
    lockedElementsData: action.arr,
    lockedElementsError: null
  });
}

const checkItemsLockFail = (state, action) => {
  return updateObject(state, {
    lockedElementsLoading: false,
    lockedElementsData: null,
    lockedElementsError: action.err
  });
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.GET_BP_LOCKS_SUCCESS:
        return getBPLocksSuccess(state, action);
      case actionTypes.GET_BP_LOCKS_FAIL:
        return getBPLocksFail(state, action);
      case actionTypes.GET_BP_LOCKS_START:
        return getBPLocksStart(state, action);

      case actionTypes.GET_ITEM_LOCKS_SUCCESS:
        return getItemLocksSuccess(state, action);
      case actionTypes.GET_ITEM_LOCKS_FAIL:
        return getItemLocksFail(state, action);
      case actionTypes.GET_ITEM_LOCKS_START:
        return getItemLocksStart(state, action);

      case actionTypes.CHECK_ITEMS_LOCK_START:
        return checkItemsLockStart(state, action);
      case actionTypes.CHECK_ITEMS_LOCK_SUCCESS:
        return checkItemsLockSuccess(state, action);
      case actionTypes.CHECK_ITEMS_LOCK_FAIL:
        return checkItemsLockFail(state, action);

      default: return state;
    }
};

export default reducer;
