import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Card, CardContent } from '@material-ui/core';
import Button from "../../components/CustomButtons/Button.jsx";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ReactTable from "react-table";
import GINreviewDialog from "./GINreview-dialog";
import GINreviewDialogPickValue from "./GINreview-dialog-pickvalue";
import GINreviewDialogVariants from "./GINreview-dialog-variants";
import GINreviewDialogEdit from "./GINreview-dialog-edit";
import { getDescription } from "./GINhelpers";
import { ranges, rangesObj } from "./GINranges.js";
import activeDBs from "shared/activeDBs";
import { showPicksList } from "shared/activeDBs";
import { numberWithCommas } from 'shared/utility';

const clone = (obj) => {
  return JSON.parse(JSON.stringify(obj));
}

const getRange = (qty) => {
  let name = '';
  ranges.forEach((re) => {
    if (qty < 10000000) {
      if ((re.rangeA <= qty) && (qty <= re.rangeB)) {
        name = re.name;
      }
    } else {
      name = '10M+';
    }
  });
  return name;
}

const generateColor = (db) => {
  let dbcolor = '';
  activeDBs.forEach(d => { if (d.name === db) dbcolor = d.color; })
  return dbcolor;
}

const Blank = props => {
  return <div style={{marginLeft:20}}>
    <div><strong>{props.title}</strong></div>
    <div style={{marginTop:20}}>n / a</div>
  </div>
}

const DBlabel = props => {
  // props.name
  let backgroundColor = '';
  activeDBs.forEach((db) => {
    if (db.name === props.name) {
      backgroundColor = db.color;
    }
  });

  if (backgroundColor) {
    return (
      <span style={{backgroundColor,border:'1px solid rgba(0,0,0,0)'}} className="db-pill">
        {props.name.split('_')[0]}
      </span>
    );
  }
  return null;
}

class GINreviewDetailedDB extends React.Component{
    constructor(props) {
      super(props);

      this.state = {
        currentDB: props.currentDB,
        popup: false,
        openEdit: false,
        data: null,
        pickEdit: false,
        pickData: null,
        variantEdit: false,
        variantData: null,

        pricings: null
      };

      this.dbFocus = React.createRef();
      this.baseFocus = React.createRef();
      this.variantFocus = React.createRef();
      this.pricingFocus = React.createRef();
      this.costingFocus = React.createRef();
    }

    openPopup = (data) => {
      this.setState({popup:true,data})
    }

    closePopup = () => {
      this.setState({popup:false,data:null})
    }

    openEdit = (data) => {
      if (!this.props.isHistory) {
        this.setState({data,openEdit:true});
      }
    }

    closeEdit = () => {
      this.setState({data:null,openEdit:false});
    }

    openVariantEdit = (variantData) => {
      if (!this.props.isHistory) {
        this.setState({variantEdit:true,variantData})
      }
    }

    closeVariantEdit = () => {
      this.setState({variantEdit:false,variantData:null})
    }

    openPickEdit = (pickData) => {
      this.setState({pickEdit:true,pickData})
    }

    closePickEdit = () => {
      this.setState({pickEdit:false,pickData:null})
    }

    compareMOC = (type, currency, moc, combo, db) => {
      if (type === 'bmasterarray' || type === 'smasterarray') {
        const prefix = type === 'bmasterarray' ? 'b' : 's';
        const target = this.props[`${prefix}masterarray_original`][db];
        let oldMOC = null;
        let found = false;
        if (target) {
          if (target[combo]) {
            target[combo].forEach(bm => {
              if (!parseInt(bm[0])) {
                if (bm[5] === currency) {
                  oldMOC = bm[2];
                  if (bm[2] && moc) {
                    if (parseFloat(bm[2]) === parseFloat(moc)) {
                      found = true;
                    }
                  }
                }
              }
            });
          }
        }
        if (!found && oldMOC) {
          const oldM = parseFloat(oldMOC);
          return <span className="orange-text">{` (Previously ${currency}${oldM})`}</span>
        }
        if (!found) {
          if (this.props.mocs[db]) {
            if (this.props.mocs[db][currency] && moc) {
              if (parseFloat(moc) !== parseFloat(this.props.mocs[db][currency]) ) {
                return <span className="orange-text">{` (Previously ${currency}${this.props.mocs[db][currency]})`}</span>
              }
            }
          }
        }
      }
      return '';
    }

    generateInputData = (o) => {
      const valueField = o.valueField ? o.valueField : null;
      const descField = o.descField ? o.descField : null;
      let desc = null;
      if (valueField) {
        desc = getDescription(o.value, o.options, o.valueField, o.descField);
      }
      let addText = '';
      if (o.addText) {
        addText = o.addText;
      }
      return (<span
              className="hovered hover-label"
              onClick={() => {
                  this.props.editModeOn();
                  this.props.setEdit({
                    itemName: o.name,
                    db: o.db,
                    field: o.field,
                    value: o.value,
                    desc,
                    type: o.type,
                    options: o.options,
                    valueField,
                    descField,
                    addText,
                    similars: o.similars,
                    validations: o.validations,
                    applyToAll: true
                  });
              }}
            >
              {!valueField && <span>{o.value ? o.value+addText : '- none -'}</span>}
              {(valueField && desc) && <span>{desc}</span>}
              {(valueField && !desc) && <span>n/a</span>}
            </span>);
    }

    generateRBO = (firmCode, db) => {
      if (this.props.options[db]) {
        if (this.props.options[db].rbos) {
          if (this.props.options[db].rbos.length > 0) {
            const rbos = this.props.options[db].rbos;
            for (let rbo of rbos) {
              if (rbo.FirmCode === firmCode) {
                return rbo.FirmName;
                break;
              }
            }
          }
        }
      }
      return '';
    }

    generateBrand = (brand, db) => {
      if (this.props.brands) {
        if (this.props.brands.length > 0) {
          for (let b of this.props.brands) {
            if (b.id === brand) {
              return b.name;
              break;
            }
          }
        }
      }
      return ''
    }

    generateSubgroup = (subgroup, db) => {
      if (this.props.subgroups) {
        if (this.props.subgroups.length > 0) {
          for (let s of this.props.subgroups) {
            if (s.id === subgroup) {
              return s.name;
              break;
            }
          }
        }
      }
      return ''
    }

    renderBaseItemInfo = () => {
      const db = this.state.currentDB, props = this.props;
      let special_fields = '';
      const pickedProductTypeDB = this.props.picked.product_type;
      const pickedProductType = this.props.product_type[pickedProductTypeDB];
      const hasPicks = this.props.picks[db] ? true : false;
      if (showPicksList(db, pickedProductType)) {
        special_fields = <span className="hovered hover-label" onClick={() => {
          const o = {
            name: 'Picks',
            db,
            field: 'picks',
            value: this.props.picks[db] ? this.props.picks[db].toUpperCase() : '',
            type: 'input',
            addText: '',
            similars: null,
            validations: {
              numOnly: true,
              wholeNumOnly: true,
              greaterThanOne: true,
              maxNum: 100000
            }
          }
          if (!this.props.openModal) {
            this.props.editModeOn();
            this.props.setEdit({
              itemName: o.name,
              db: o.db,
              field: o.field,
              value: o.value,
              desc: '',
              type: o.type,
              options: o.options,
              valueField: '',
              descField: '',
              addText: '',
              similars: o.similars,
              validations: o.validations
            });
          }
        }}>
                            <span style={!hasPicks ? {
                              color: 'red'
                            } : {}}>{`PICKS: ${this.props.picks[db]}`}</span>
                            <span>{this.compare(this.props.picks[db], this.props.picks_original[db])}</span>
                            <span>{this.renderBaseValueComp('picks')}</span>
                          </span>;
      }
      let nin_tax = '';
      if (db.indexOf('NIN') !== -1) {
        nin_tax = <div
                    className="hovered orange-text"
                    onClick={() => {
                      this.openPopup({
                        title: 'TAX DETAILS',
                        type: 'tax',
                        db
                      })
                    }}
                  >VIEW TAX DETAILS</div>
      }
      let special_fields_combined = <div>{special_fields}<div>{nin_tax}</div></div>;
      return <div ref={this.baseFocus} style={{marginLeft:24}} className="baseFocus">
        <div className="bold">BASE ITEM INFORMATION</div>
        <List dense={true} className="list-small">
          <ListItem>
            <span className="field-label field-label3 blue-text-span">
              <ListItemText primary="ITEM CODE:" />
            </span>
            {this.props.itemCodes[db]}
          </ListItem>
          <ListItem>
            <span className="field-label field-label3 blue-text-span">
              <ListItemText primary="TYPE:" />
            </span>
            {props.newItemList.indexOf(db) === -1 ? 'EXISTING' : 'NEW'}
          </ListItem>
          <ListItem>
            <span className="field-label field-label3 blue-text-span">
              <ListItemText primary="ITEM CODE:" />
            </span>
            {props.itemCodes[db]}
          </ListItem>
          <ListItem>
            <span className="field-label field-label3 blue-text-span">
              <ListItemText primary="SOURCE:" />
            </span>
            <span className="hovered hover-label" onClick={() => {
              this.openEdit({
                title: 'EDIT Source',
                db,
                value: this.props.item_is[db],
                type: 'item_is'
              });
            }}>
              <span>{this.props.item_is[db]}</span>
              <span>{this.compare(this.props.item_is[db], this.props.item_is_original[db])}</span>
            </span>
          </ListItem>
          <ListItem>
            <span className="field-label field-label3 blue-text-span">
              <ListItemText primary="SPECIAL FIELDS:" />
            </span>
            {special_fields_combined}
          </ListItem>
        </List>
      </div>
    }

    renderBaseValueComp = (target, isNumber) => {
      let comp = '';
      const db = this.state.currentDB, tgt = `${target}_original`, props = this.props, picked = props.picked;
      console.log('this.props', this.props);
      if (this.props[tgt][db]) {
        if (target === 'rbo') {
          if (this.props.options[db]) {
            if (this.props.options[db].rbos) {
              const value = props[target][picked[target]],
                    valueOriginal = this.props[tgt][db];
              let newVal = '', oldVal = '';
              props.options[db].rbos.forEach(rbo => {
                if (rbo.FirmCode === value) {
                  newVal = rbo.FirmName;
                }
                if (rbo.FirmCode === valueOriginal) {
                  oldVal = rbo.FirmName;
                }
              });
              comp = this.compare(newVal, oldVal, isNumber);
            }
          }
        } else if (target === 'brand' || target === 'subgroup' || target === 'ipackagingtypes') {
          const value = props[target][picked[target]];
          const valueOriginal = this.props[tgt][db];
          let field = target === 'brand' ? 'brands' : 'subgroups';
          if (target === 'ipackagingtypes') {
            field = 'packagingtypes';
          }
          let newVal = 'n/a', oldVal = 'n/a';
          props[field].forEach(b => {
            if (b.id === value) {
              newVal = b.name;
            }
            if (b.id === valueOriginal) {
              oldVal = b.name;
            }
          });
          comp = this.compare(newVal, oldVal, isNumber);
        } else {
          comp = this.compare(props[target][picked[target]], this.props[tgt][db], isNumber);
        }
      }
      return comp;
    }

    renderBaseValues1 = (hasWalmart) => {
      const db = this.state.currentDB, props = this.props, picked = props.picked, options = props.options;
      return <div>
      <div className="bold blue-text-span blue-text">VALUES</div>
        <List dense={true} className="list-small">
          <ListItem>
            <span className="field-label field-label2">
              <ListItemText primary="BASE ITEM DESCRIPTION:" />
            </span>
              {picked.base_item_description ?
              <span>
                {this.generateInputData({
                  name: 'Base Item Description',
                  db: picked.base_item_description,
                  field: 'base_item_description',
                  value: props.base_item_description[picked.base_item_description] ? props.base_item_description[picked.base_item_description].toUpperCase() : '',
                  type: 'input',
                  similars: this.props.baseItemDescriptions,
                  validations: {
                    maxLength: 75,
                    notAllowedChars: 'aCharms',
                    whitelist: true
                  }
                })}
              </span>
              : ''}
              <span>{this.renderBaseValueComp('base_item_description')}</span>
          </ListItem>
          <ListItem>
            <span className="field-label field-label2">
              <ListItemText primary="PRODUCT TYPE:" />
            </span>
            {picked.product_type ?
            <span>
            {this.generateInputData({
              name: 'Product Type',
              db: picked.product_type,
              field: 'product_type',
              value: props.product_type[picked.product_type] ? props.product_type[picked.product_type] : '',
              options: options.hasOwnProperty(picked.product_type) ? options[picked.product_type].product_types : [],
              type: 'option1',
              similars: this.props.product_type
            })}
            </span> : ''}
            <span>{this.renderBaseValueComp('product_type')}</span>
          </ListItem>
          <ListItem>
            <span className="field-label field-label2">
              <ListItemText primary="RBO NAME:" />
            </span>
            {picked.rbo ?
            <span>
              {this.generateInputData({
                name: 'RBO',
                db:picked.rbo,
                field: 'rbo',
                value: props.rbo[picked.rbo] ? props.rbo[picked.rbo] : '',
                options: options.hasOwnProperty(picked.rbo) ? options[picked.rbo].rbos : [],
                type: 'option2',
                valueField: 'FirmCode',
                descField: 'FirmName',
                similars: null
              })}
            </span> : ''}
            <span>{this.renderBaseValueComp('rbo')}</span>
          </ListItem>
          <ListItem>
            <span className="field-label field-label2">
              <ListItemText primary="BRAND:" />
            </span>
            {picked.brand ?
            <span>
              {this.generateInputData({
                name: 'Brand',
                db:picked.brand,
                field: 'brand',
                value: props.brand[picked.brand] ? props.brand[picked.brand] : '',
                options: this.props.brandNames,
                type: 'option2',
                valueField: 'id',
                descField: 'name',
                similars: null
              })}
            </span> : ''}
            <span>{this.renderBaseValueComp('brand')}</span>
          </ListItem>
          <ListItem>
            <span className="field-label field-label2">
              <ListItemText primary="SUBGROUP:" />
            </span>
            {picked.subgroup ?
            <span>
            {this.generateInputData({
              name: 'Subgroup',
              db:picked.subgroup,
              field: 'subgroup',
              value: props.subgroup[picked.subgroup] ? props.subgroup[picked.subgroup] : '',
              options: this.props.subgroupNames,
              type: 'option2',
              valueField: 'id',
              descField: 'name',
              similars: null
            })}
            </span> : ''}
            <span>{this.renderBaseValueComp('subgroup')}</span>
          </ListItem>
          {hasWalmart && <ListItem>
            <span className="field-label field-label2">
              <ListItemText primary="PACKAGING TYPE:" />
            </span>
            {picked.ipackagingtypes ?
            <span>
            {this.generateInputData({
              name: 'Packaging Type',
              db:picked.ipackagingtypes,
              field: 'ipackagingtypes',
              value: props.ipackagingtypes[picked.ipackagingtypes] ? props.ipackagingtypes[picked.ipackagingtypes] : '',
              options: this.props.packagingtypes,
              type: 'option2',
              valueField: 'id',
              descField: 'name',
              similars: null
            })}
            </span> : ''}
            <span>{this.renderBaseValueComp('ipackagingtypes')}</span>
          </ListItem>}
        </List>
      </div>
    }

    renderBaseValues2 = () => {
      const props = this.props, db = this.state.currentDB, picked = props.picked, options = props.options;
      return <div>
        <List dense={true} className="list-small">
          <ListItem>
            <span className="field-label field-label2">
              <ListItemText primary="SALES UNIT:" />
            </span>
            {picked.sales_unit ?
            <span>
            {this.generateInputData({
              name: 'Sales Unit',
              db:picked.sales_unit,
              field: 'sales_unit',
              value: props.sales_unit[picked.sales_unit] ? props.sales_unit[picked.sales_unit] : '',
              options: ['PIECE','DOZEN','GROSS','THOUSAND','METER','TARD','ROLL'],
              type: 'option1',
              similars: null
            })}
            </span> : ''}
              <span>{this.renderBaseValueComp('sales_unit')}</span>
          </ListItem>
          <ListItem>
            <span className="field-label field-label2">
              <ListItemText primary="BASIC PACKAGING UNIT:" />
            </span>
            {picked.basic_packaging_unit ?
            <span>
            {this.generateInputData({
              name: 'Basic Packaging Unit',
              db:picked.basic_packaging_unit,
              field: 'basic_packaging_unit',
              value: props.basic_packaging_unit[picked.basic_packaging_unit] ? props.basic_packaging_unit[picked.basic_packaging_unit] : '',
              options: ['TRAY','CARTON','BUNDLE','ROLL'],
              type: 'option1',
              similars: null
            })}
            </span> : ''}
            <span>{this.renderBaseValueComp('basic_packaging_unit')}</span>
          </ListItem>
          <ListItem>
            <span className="field-label field-label2">
              <ListItemText primary="QTY PER UNIT:" />
            </span>
            {picked.qty_per_unit ?
            <span>
            {this.generateInputData({
              name: 'Qty per Unit',
              db:picked.qty_per_unit,
              field: 'qty_per_unit',
              value: props.qty_per_unit[picked.qty_per_unit] ? props.qty_per_unit[picked.qty_per_unit] : '',
              type: 'input',
              similars: null,
              validations: {
                numOnly: true,
                wholeNumOnly: true,
                maxNum: 100000
              }
            })}
            </span> : ''}
            <span>{this.renderBaseValueComp('qty_per_unit', 'number')}</span>
          </ListItem>
          <ListItem>
            <span className="field-label field-label2">
              <ListItemText primary="LENGTH:" />
            </span>
            {picked.ilength ?
            <span>
            {this.generateInputData({
              name: 'Length',
              db:picked.ilength,
              field: 'ilength',
              value: props.ilength[picked.ilength] ? props.ilength[picked.ilength] : '',
              type: 'input',
              addText: ' mm',
              similars: null,
              validations: {
                numOnly: true,
                wholeNumOnly: true,
                maxNum: 100000
              }
            })}
            </span> : ''}
            <span>{this.renderBaseValueComp('ilength', 'number')}</span>
          </ListItem>
          <ListItem>
            <span className="field-label field-label2">
              <ListItemText primary="WIDTH:" />
            </span>
            {picked.width ?
            <span>
            {this.generateInputData({
              name: 'Width',
              db:picked.width,
              field: 'width',
              value: props.width[picked.width] ? props.width[picked.width] : '',
              type: 'input',
              addText: ' mm',
              similars: null,
              validations: {
                numOnly: true,
                wholeNumOnly: true,
                maxNum: 100000
              }
            })}
            </span> : ''}
            <span>{this.renderBaseValueComp('width', 'number')}</span>
          </ListItem>
          <ListItem>
            <span className="field-label field-label2">
              <ListItemText primary="WEIGHT:" />
            </span>
            {picked.weight ?
            <span>
            {this.generateInputData({
              name: 'Weight',
              db:picked.weight,
              field: 'weight',
              value: props.weight[picked.weight] ? props.weight[picked.weight] : '',
              type: 'input',
              addText: ' g',
              similars: null,
              validations: {
                numOnly: true,
                minMax3Decimals: true,
                maxNum: 100000
              }
            })}
            </span> : ''}
            <span>{this.renderBaseValueComp('weight', 'number')}</span>
          </ListItem>
        </List>
      </div>
    }

    editVD = (vc,vd) => {
      let base_item_description = '';
      if (this.props.picked.base_item_description) {
        base_item_description = this.props.base_item_description[this.props.picked.base_item_description];
      }
      this.openVariantEdit({
        title: 'EDIT VARIANT DESCRIPTION',
        variant_code: vc,
        variant_description: vd,
        base_item_description
      })
    }

    renderVariants = () => {
      let variantsData = {}, differentDesc = [];
      if (this.props.variants && this.props.variantsDesc) {
        this.props.openedDBs.forEach(db => {
          if (this.props.variants[db]) {
            this.props.variants[db].forEach((v,i) => {
              const combo = `${v[0]}_${this.props.variantsDesc[db][i]}`;
              if (db === 'NSP_LIVE') {
                console.log('db-->', db, combo);
              }
              variantsData[combo] = 0;

              if (this.props.originalVariantsDesc[db]) {
                if (this.props.originalVariantsDesc[db][i] || this.props.originalVariantsDesc[db][i] === '') {
                  if (this.props.originalVariantsDesc[db][i] !== this.props.variantsDesc[db][i]) {
                    differentDesc.push(combo);
                  }
                }
              }
            });
          }
        })
      }
      if (this.props.variantsChecked) {
        if (Object.keys(this.props.variantsChecked).length > 0) {
          Object.keys(this.props.variantsChecked).forEach(combo => {
            variantsData[combo] = 1;
          });
        }
      }
      const variants_info = Object.keys(variantsData).map(combo => {
        console.log('the variant combo', variantsData[combo], combo);
        const vc = combo.split('_')[0],
              vd = combo.split('_')[1];
        let status = 'UNCHANGED';
        if (variantsData[combo] === 0) {
          status = 'REMOVED';
        } else {
          if (this.props.variantsToAdd) {
            if (this.props.variantsToAdd.indexOf(vc) !== -1) {
              status = 'NEW';
            }
            if (differentDesc.length > 0) {
              if (differentDesc.indexOf(combo) !== -1) {
                status = 'NEW DESCRIPTION';
              }
            }
          }
          if (status === 'UNCHANGED') {
            const theCurrentDB = this.props.currentDB;
            if (this.props.variants[theCurrentDB]) {
              let found = false;
              this.props.variants[theCurrentDB].forEach((vcc) => {
                const vcc_code = vcc[0];
                console.log('comparing variants', vcc_code, vc);
                if (vcc_code === vc) {
                  found = true;
                }
              })
              if (!found) {
                status = 'NEW';
              }
              if (found) {
                if (this.props.variantsDesc[theCurrentDB]) {
                  let found2 = false;
                  this.props.variantsDesc[theCurrentDB].forEach((vcd) => {
                    if (vcd === vd) {
                      found2 = true;
                    }
                  })
                  if (!found2) {
                    status = 'NEW DESCRIPTION';
                  }
                }
              }
            }
          }
        }

        return {
          vc, vd, status
        }
      });
      const table = <div className="spec-table scrolling-table no-blue-row padded-20 blue-header-table small-header header-review review-table" style={{marginLeft:10,borderRight:'1px solid #dddbdb'}}>
      <div ref={this.variantFocus} className="variantFocus"><strong>VARIANTS</strong></div>
      <ReactTable
          data={
              variants_info.map((v, key) => {
                let statusColor = '';
                if (v.status === 'REMOVED') {
                  statusColor = 'red-text';
                } else if (v.status === 'NEW') {
                  statusColor = 'green-text';
                }
                return ({
                  id: key,
                  vc: v.vc,
                  vd: v.vd,
                  vdCell: (
                  <div>
                    {v.status !== 'REMOVED' && <span className="hovered hover-label" onClick={() => {
                        this.editVD(v.vc,v.vd);
                    }}>
                      <span>{v.vd}</span>
                    </span>}
                    {v.status === 'REMOVED' && <span>{v.vd}</span>}
                  </div>
                  ),
                  status: v.status,
                  statusColor
                })
              })
          }
          filterable
          columns={[
              {
                Header: "VARIANT CODE",
                accessor: "vc",
                sortable: true,
                filterable: false,
                style: { whiteSpace: 'unset' },
                Cell: row => {
                  return (
                    <div className={row.original.statusColor}>{row.original.vc}</div>
                  )
                },
              },
              {
                Header: "VARIANT DESCRIPTION",
                accessor: "vd",
                sortable: true,
                filterable: false,
                style: { whiteSpace: 'unset' },
                Cell: row => {
                  return (
                    <div className={row.original.statusColor}>{row.original.vdCell}</div>
                  )
                },
              },
              {
                Header: "STATUS",
                accessor: "status",
                sortable: true,
                filterable: false,
                Cell: row => {
                  return (
                    <div className={row.original.statusColor}>{row.original.status}</div>
                  )
                },
              }
          ]}
          minRows={0}
          defaultPageSize={1000}
          showPagination={false}
          className="-highlight"
      /></div>
      if (variants_info.length === 0) {
        return <div style={{
                    marginLeft:25,
                    marginTop:10,
                    height: 300,
                    borderRight: '1px solid #dddbdb'
                }}>
                <div ref={this.variantFocus}><strong>VARIANTS</strong></div>
                <div style={{marginTop:20}}>- none -</div>
              </div>
      }
      return table;
    }

    renderBOMcompared = () => {
      let comp = '';
      const db = this.state.currentDB;
      if (this.props.BOMItemBaseQty_original[db]) {
        comp = this.compare(this.props.BOMItemBaseQty[db], this.props.BOMItemBaseQty_original[db], 'number');
      }
      return comp;
    }

    renderBOM = () => {
      const db = this.state.currentDB, props = this.props;
      let existing_rmcodes = [];
      let bom_info = this.props.rms[db].map((rms,i) => {
        const desc = this.props.rmsd[db][i];
        if (rms && desc) {
          existing_rmcodes.push(rms);
          let finalObj = {
            rms: rms.split(':')[0] === 'RM' ? rms.substring(3) : rms,
            desc: desc.split(':')[0] === 'RM' ? desc.substring(3) : desc,
            q: this.props.rmsq[db][i],
            qCompared: '',
            w: this.props.rmsw[db][i],
            wCompared: '',
            status: 'UNCHANGED',
            index: i,
            db
          }
          if (props.rms_original[db]) {
            // compare
            const findSameCodeIndex = this.findCompareCode('bom', rms, db, props.rms_original[db]);
            if (findSameCodeIndex !== -1) {
              const compareObj = this.findCompareCodeAll('bom', finalObj, findSameCodeIndex);
              finalObj.status = 'UNCHANGED';
              Object.keys(compareObj).forEach(field => {
                if (compareObj[field]) {
                  finalObj.status = 'UPDATED';
                  finalObj[field] = compareObj[field];
                }
              });
            } else {
              finalObj.status = 'NEWLY ADDED'
            }
          }
          return finalObj;
        };
      });

      // add deleted
      if (props.rms_original[db]) {
        props.rms_original[db].forEach((rms,i) => {
          if (existing_rmcodes.indexOf(rms) === -1) {
            const desc = this.props.rmsd_original[db][i];
            bom_info.push({
              rms: rms.split(':')[0] === 'RM' ? rms.substring(3) : rms,
              desc: desc.split(':')[0] === 'RM' ? desc.substring(3) : desc,
              q: this.props.rmsq_original[db][i] ? parseInt(this.props.rmsq_original[db][i]) : this.props.rmsq_original[db][i],
              qCompared: '',
              w: this.props.rmsw_original[db][i],
              wCompared: '',
              status: 'REMOVED',
              index: i,
              db
            });
          }
        });
      }

      const table = <div className="spec-table scrolling-table no-blue-row padded-20 blue-header-table small-header header-review review-table" style={{marginLeft:10}}>
      <div><strong>BOM</strong> <span className="blue-text-span" style={{marginLeft:20}}>ITEM BASE QTY: </span>
      <span className="hovered hover-label" onClick={() => {
        this.openEdit({
          title: 'EDIT BOM Item Base Quantity',
          db,
          value: this.props.BOMItemBaseQty[db],
          type: 'BOMItemBaseQty'
        });
      }}>
        <span>{this.props.BOMItemBaseQty[db]}</span><span>{this.renderBOMcompared()}</span>
      </span></div>
      <ReactTable
          data={
              bom_info.map((r, key) => {
                let status = '', statusColor = '';
                status = r.status ? r.status : 'UNCHANGED';
                if (status) {
                  if (status === 'REMOVED') {
                    statusColor = 'red-text';
                  }
                  if (status === 'NEWLY ADDED') {
                    statusColor = 'green-text';
                  }
                }
                return ({
                  id: key,
                  rms: r.rms,
                  desc: r.desc,
                  q: r.q,
                  qCell: (
                    <span className={r.status !== 'REMOVED' ? `hovered hover-label` : ''} onClick={(e) => {
                      if (r.status === 'REMOVED') return;
                      e.preventDefault();
                      this.openEdit({
                        title: 'EDIT Raw Material Quantity',
                        db,
                        value: r.q,
                        type: 'rmsq',
                        index: key
                      });
                    }}>
                      <span>{r.q}</span><span>{r.qCompared}</span>
                    </span>
                  ),
                  w: r.w,
                  wCell: (
                    <span className={r.status !== 'REMOVED' ? `hovered hover-label` : ''} onClick={(e) => {
                      if (r.status === 'REMOVED') return;
                      e.preventDefault();
                      this.openEdit({
                        title: 'EDIT Raw Material Warehouse',
                        db,
                        value: r.w,
                        type: 'rmsw',
                        index: key,
                        rm_code: r.rms, rm_description: r.desc
                      });
                    }}>
                      <span>{r.w}</span><span>{r.wCompared}</span>
                    </span>
                  ),
                  status,
                  statusColor
                })
              })
          }
          filterable
          columns={[
              {
                Header: "RAW MATERIAL CODE",
                accessor: "rms",
                sortable: true,
                filterable: false,
                style: { whiteSpace: 'unset' },
                Cell: row => {
                  return (
                    <div className={row.original.statusColor}>{row.original.rms}</div>
                  )
                },
              },
              {
                Header: "RAW MATERIAL DESCRIPTION",
                accessor: "desc",
                sortable: true,
                filterable: false,
                style: { whiteSpace: 'unset' },
                Cell: row => {
                  return (
                    <div className={row.original.statusColor}>{row.original.desc}</div>
                  )
                },
              },
              {
                Header: "QTY REQUIRED",
                accessor: "q",
                sortable: true,
                filterable: false,
                style: { whiteSpace: 'unset' },
                Cell: row => {
                  return (
                    <div className={row.original.statusColor}>{row.original.qCell}</div>
                  )
                },
              },
              {
                Header: "RAW MATERIAL WAREHOUSE",
                accessor: "w",
                sortable: true,
                filterable: false,
                style: { whiteSpace: 'unset' },
                Cell: row => {
                  return (
                    <div className={row.original.statusColor}>{row.original.wCell}</div>
                  )
                },
              },
              {
                Header: "STATUS",
                accessor: "status",
                sortable: true,
                filterable: false,
                Cell: row => {
                  return (
                    <div className={row.original.statusColor}>{row.original.status}</div>
                  )
                },
              },
          ]}
          minRows={0}
          defaultPageSize={1000}
          showPagination={false}
          className="-highlight"
      /></div>
      if (this.props.item_is[db] === 'Outsourced') {
        return <Blank title="BOM" />;
      }
      return table;
    }

    compare = (newVal, oldVal, isNumber) => {
      if (oldVal === undefined) {
        return '';
      }
      console.log('comparing', newVal, oldVal, isNumber);
      let nv = newVal, ov = oldVal;
      if (typeof newVal === 'string') {
        nv = newVal.trim();
      }
      if (typeof oldVal === 'string') {
        ov = oldVal.trim();
      }
      if (isNumber) {
        nv = parseFloat(newVal);
        ov = parseFloat(oldVal);
      }
      if (nv != ov && oldVal !== undefined) {
        if (typeof oldVal !== 'string') {
          if (ov) {
            return <span className="orange-text">{`(Previously ${ov})`}</span>
          }
          return '';
        }
        if (oldVal.indexOf('undefined') !== -1) {
          return '';
        }
        if (oldVal === '' && newVal) {
          ov = 'n/a';
        }
        return <span className="orange-text">{`(Previously ${ov})`}</span>
      } else {
        return '';
      }
    }

    findCompareCode = (target, code, db, arr) => {
      if (target === 'gcodes' || target === 'ccodes') {
        const prefix = target === 'gcodes' ? 'g' : 'c';
        // ALL PRICES
        if (this.props[`${prefix}codes`][db]) {
          if (this.props[`${prefix}codes_original`][db]) {
            console.log('here prices',this.props[`${prefix}codes_original`][db])
            return this.props[`${prefix}codes_original`][db].indexOf(code);
          }
        }
      }
      if ((target === 'bmasterarray' || target === 'smasterarray') && arr) {
        const original_codes = arr.map(a => a[3]);
        return original_codes.indexOf(code);
      }
      if (target === 'bom') {
        return arr.indexOf(code);
      }
      return -1;
    }

    findCompareCodeAll = (type, obj, old_index, combo) => {
      let finalObj = {};
      const new_index = obj.index,
            db = obj.db,
            props = this.props;
      if (type === 'bom') {
        finalObj.qCompared = this.compare(props.rmsq[db][new_index], props.rmsq_original[db][old_index], 'number');
        finalObj.wCompared = this.compare(props.rmsw[db][new_index], props.rmsw_original[db][old_index]);
      }
      if (type === 'all-pricing' || type === 'target-cost') {
        const prefix = type === 'all-pricing' ? 'g' : 'c';
        if (props[`${prefix}prices_original`][db]) {
          const prices = props[`${prefix}prices`][db],
                pricesOriginal = props[`${prefix}prices_original`][db],
                currency = props[`${prefix}currs`][db],
                currencyOriginal = props[`${prefix}currs_original`][db],
                term = props[`${prefix}terms`][db],
                termOriginal = props[`${prefix}terms_original`][db],
                starts = props[`${prefix}starts`][db],
                ends = props[`${prefix}ends`][db],
                startsOriginal = props[`${prefix}starts_original`][db],
                endsOriginal = props[`${prefix}ends_original`][db];

          console.log('dd-prices', prices, pricesOriginal);
          if (prices && pricesOriginal) {
            console.log('dd-prices1', new_index, old_index);
            finalObj.priceCompared = this.compare(prices[new_index], pricesOriginal[old_index]);
          }
          if (currency && currencyOriginal) {
            finalObj.currencyCompared = this.compare(currency[new_index], currencyOriginal[old_index]);
          }
          if (term && termOriginal) {
            finalObj.termCompared = this.compare(term[new_index], termOriginal[old_index]);
          }
          if ((starts && startsOriginal) && (ends && endsOriginal)) {
            const dateSingle = `${starts[new_index]} TO ${ends[new_index]}`;
            const dateSingleOriginal = `${startsOriginal[old_index]} TO ${endsOriginal[old_index]}`;
            finalObj.dateCompared = this.compare(dateSingle, dateSingleOriginal);
          }
        }
      }

      if ((type === 'bmasterarray' || type === 'smasterarray') && combo) {
        const prefix = type === 'bmasterarray' ? 'b' : 's';
        console.log(`${prefix}masterarray ccc`, props[`${prefix}masterarray`][db], props[`${prefix}masterarray`]);
        const bmn = props[`${prefix}masterarray`][db][combo][new_index],
              bmo = props[`${prefix}masterarray_original`][db][combo][old_index];
        finalObj.priceCompared = this.compare(bmn[2], bmo[2], 'number');
        finalObj.currencyCompared = this.compare(bmn[5], bmo[5]);
        finalObj.termCompared = this.compare(bmn[1], bmo[1]);
        const dateSingle = `${bmn[6]} TO ${bmn[7]}`;
        const dateSingleOriginal = `${bmo[6]} TO ${bmo[7]}`;
        finalObj.dateCompared = this.compare(dateSingle, dateSingleOriginal);
      }
      return finalObj;
    }

    renderAllPricing = () => {
      const props = this.props,
            db = this.state.currentDB,
            mocs = props.mocs[db],
            specialmoc = props.specialmoc[db];
      let hasChanged = false;

      let singleStatus = 'UNCHANGED';

      let prices = [],
          finalMOC = mocs.hasOwnProperty(props.gcur[db]) ? numberWithCommas(mocs[props.gcur[db]]) : 0,
          finalMOCtype = 'STANDARD',
          finalMOCnum = mocs[props.gcur[db]];

      // ADD DELETED Single
      if (this.props.gcode_original[db]) {
        if (this.props.gcode[db]) {
          if (this.props.gcode_original[db] !== this.props.gcode[db]) {
            prices.push({
              qty: 1,
              price: props.gprice_original[db],
              priceCompared: '',
              currency: props.gcur_original[db],
              currencyCompared: '',
              term: props.gterm_original[db],
              termCompared: '',
              start: props.gstart_original[db],
              end: props.gend_original[db],
              datesCompared: '',
              moc: '',
              mocCompared: '',
              mocType: '',
              code: props.gcode[db],
              db,
              index: -1,
              status: 'REMOVED'
            });
            hasChanged = true;
          }
        }
      }

      // single value pricings
      if (props.gprice[db]) {
        if (specialmoc) {
          if (specialmoc.length > 0) {
            specialmoc.forEach(s => {
              if (s[0] === props.gcur[db]) {
                  finalMOC = numberWithCommas(s[1]);
                  finalMOCtype = 'SPECIAL';
                  finalMOCnum = s[1];
              }
            });
          }
        }
        if (!props.gcode[db]) {
          singleStatus = 'NEWLY ADDED';
          hasChanged = true;
        }
        const dateJoined = `${props.gstart[db]} TO ${props.gend[db]}`,
              dateJoinedOriginal = `${props.gstart[db]} TO ${props.gs}}`;
        let finalSingleObj = {
          qty: 1,
          price: props.gprice[db],
          priceCompared: '',
          currency: props.gcur[db],
          currencyCompared: '',
          term: props.gterm[db],
          termCompared: '',
          start: props.gstart[db],
          end: props.gend[db],
          dateCompared: '',
          mocNum: finalMOCnum,
          moc: `${props.gcur[db]}${finalMOC} ${finalMOCtype}`,
          mocCompared: '',
          mocType: finalMOCtype,
          code: props.gcode[db],
          db,
          index: -1,
          status: singleStatus
        }

        if (singleStatus !== 'NEWLY ADDED') {
          console.log('comparing single prices', props.gprice[db], props.gprice_original[db])
          finalSingleObj.priceCompared = this.compare(props.gprice[db], props.gprice_original[db], true);
          console.log('comparing single prices', finalSingleObj.priceCompared)
          finalSingleObj.currencyCompared = this.compare(props.gcur[db], props.gcur_original[db]);
          finalSingleObj.termCompared = this.compare(props.gterm[db], props.gterm_original[db]);
          const dateSingle = `${props.gstart[db]} TO ${props.gend[db]}`;
          const dateSingleOriginal = `${props.gstart_original[db]} TO ${props.gend_original[db]}`;
          finalSingleObj.dateCompared = this.compare(dateSingle, dateSingleOriginal);
          if (finalSingleObj.priceCompared || finalSingleObj.currencyCompared
            || finalSingleObj.termCompared || finalSingleObj.dateCompared) {
            finalSingleObj.status = 'UPDATED';
            hasChanged = true;
          }
        }

        prices.push(finalSingleObj);
      }

      // array prices
      if (props.gprices[db]) {
        if (props.gprices[db].length > 0) {
          props.gprices[db].forEach((pr,i) => {
            if (props.gqtys[db][i]) {
              if (parseInt(props.gqtys[db][i]) != 0) {
                let theMOC = props.mocs.hasOwnProperty(props.gcurrs[db][i]) ? mocs[props.gcurrs[db][i]] : 0,
                    theMOCtype = 'STANDARD';
                if (props.specialmoc[db]) {
                  props.specialmoc[db].forEach(sm => {
                    if (sm[0] ===  props.gcurrs[db][i]) {
                      theMOC = sm[1];
                      theMOCtype = 'SPECIAL';
                    }
                  })
                }
                const finalObj = {
                  qty: parseInt(props.gqtys[db][i]),
                  price: props.gprices[db][i],
                  priceCompared: '',
                  currency: props.gcurrs[db][i],
                  currencyCompared: '',
                  term: props.gterms[db][i],
                  termCompared: '',
                  start: props.gstarts[db][i],
                  end: props.gends[db][i],
                  dateCompared: '',
                  code: props.gcodes[db][i],
                  mocNum: theMOC ? parseFloat(theMOC) : '0',
                  moc: `${props.gcurrs[db][i]}${theMOC} ${theMOCtype}`,
                  mocCompared: '',
                  db: db,
                  index: i
                };
                const findSameCodeIndex = this.findCompareCode('gcodes', props.gcodes[db][i], db);
                console.log('findSameCodeIndex', findSameCodeIndex);
                if (findSameCodeIndex !== -1) {
                  // is in original
                  const compareObj = this.findCompareCodeAll('all-pricing', finalObj, findSameCodeIndex);
                  finalObj.status = 'UNCHANGED';
                  console.log('compareObj',compareObj)
                  Object.keys(compareObj).forEach(field => {
                    if (compareObj[field]) {
                      finalObj.status = 'UPDATED';
                      finalObj[field] = compareObj[field];
                      hasChanged = true;
                    }
                  });
                } else {
                  finalObj.status = 'NEWLY ADDED';
                  hasChanged = true;
                }

                prices.push(finalObj);
              }
            }
          });
        }
      }

      // find removed array values
      if (props.gcodes_original[db] && props.gcodes[db]) {
        props.gcodes_original[db].forEach((code,old_index) => {
          if (props.gcodes[db].indexOf(code) === -1) {
            const gqtys = props.gqtys_original[db],
                  gprices = props.gprices_original[db],
                  gcurrs = props.gcurrs_original[db],
                  gterms = props.gterms_original[db],
                  gstarts = props.gstarts_original[db],
                  gends = props.gends_original[db],
                  gcodes = props.gcodes[db];
            if ((((gqtys && gprices) && (gcurrs && gterms)) && (gstarts && gends)) && gcodes) {
              prices.push({
                qty: parseInt(props.gqtys_original[db][old_index]),
                price: gprices[old_index],
                priceCompared: '',
                currency: gcurrs[old_index],
                currencyCompared: '',
                term: gterms[old_index],
                termCompared: '',
                start: gstarts[old_index],
                end: gends[old_index],
                dateCompared: '',
                code: gcodes[old_index],
                mocNum: ``,
                moc: ``,
                mocCompared: '',
                db: db,
                index: old_index,
                status: 'REMOVED'
              });
              hasChanged = true;
            }
          }
        });
      }

      // array pricings
      const table = <div className="spec-table scrolling-table no-blue-row header-review" style={{marginLeft:10}}>
        <div ref={this.pricingFocus} className="pricingFocus">
          <strong>PRICING FOR ALL CUSTOMERS</strong>
        </div>
      <ReactTable
          data={
              prices.map((pr, key) => {
                console.log('pr',pr);
                let price = '';
                if (pr.price) {
                  if (parseFloat(pr.price)) {
                    price = parseFloat(pr.price);
                  }
                }
                let status = '', statusColor = '';
                status = pr.status ? pr.status : 'UNCHANGED';
                if (status) {
                  if (status === 'REMOVED') {
                    statusColor = 'red-text';
                  }
                  if (status === 'NEWLY ADDED') {
                    statusColor = 'green-text';
                  }
                }
                return ({
                  id: key,
                  qty: pr.qty,
                  qtyRange: getRange(pr.qty),
                  price: `${pr.currency}${price}`,
                  priceCell: (
                    <span className={pr.status !== 'REMOVED' ? `hovered hover-label` : ''} onClick={() => {
                      if (pr.status === 'REMOVED') return;
                      this.openEdit({
                        title: 'EDIT PRICE',
                        type: 'all-price',
                        subType: 'price',
                        db,
                        value: pr.price,
                        index: pr.index,
                        currency: pr.currency
                      });
                    }}>
                      <span>{`${pr.currency}${price}`}</span><span>{pr.priceCompared}</span>
                    </span>
                  ),
                  originalPrice: pr.price,
                  currency: pr.currency,
                  currencyCell: (
                    <span className={pr.status !== 'REMOVED' ? `hovered hover-label` : ''} onClick={() => {
                      if (pr.status === 'REMOVED') return;
                      this.openEdit({
                        title: 'EDIT CURRENCY',
                        type: 'all-price',
                        subType: 'currency',
                        db,
                        value: pr.currency,
                        index: pr.index
                      });
                    }}>
                      <span>{pr.currency}</span><span>{pr.currencyCompared}</span>
                    </span>
                  ),
                  term: pr.term,
                  termCell: (
                    <span className={pr.status !== 'REMOVED' ? `hovered hover-label` : ''} onClick={() => {
                      if (pr.status === 'REMOVED') return;
                      this.openEdit({
                        title: 'EDIT SHIPPING TERM',
                        type: 'all-price',
                        subType: 'term',
                        db,
                        value: pr.term,
                        index: pr.index
                      });
                    }}>
                      <span>{pr.term}</span><span>{pr.termCompared}</span>
                    </span>
                  ),
                  start: pr.start,
                  end: pr.end,
                  effective_dates: `${pr.start} TO ${pr.end}`,
                  effectiveDatesCell: (
                    <span className={pr.status !== 'REMOVED' ? `hovered hover-label` : ''} onClick={() => {
                      if (pr.status === 'REMOVED') return;
                      this.openEdit({
                        title: 'EDIT EFFECTIVE DATES',
                        type: 'all-price',
                        subType: 'date',
                        db,
                        value: `${pr.start} TO ${pr.end}`,
                        index: pr.index
                      });
                    }}>
                      <span>{`${pr.start} TO ${pr.end}`}</span><span>{pr.dateCompared}</span>
                    </span>
                  ),
                  moc: pr.moc,
                  mocNum: pr.mocNum ? parseFloat(pr.mocNum) : 0,
                  mocCell: (
                    <span className={pr.status !== 'REMOVED' ? `hovered hover-label` : ''} onClick={() => {
                      if (pr.status === 'REMOVED') return;
                      this.openEdit({
                        title: 'EDIT MOC',
                        type: 'all-price',
                        subType: 'moc',
                        db,
                        value: pr.mocNum ? parseFloat(pr.mocNum) : 0,
                        index: pr.index,
                        currency: pr.currency,
                      });
                    }}>
                      <span>{pr.moc}</span><span>{pr.mocCompared}</span>
                    </span>
                  ),
                  index: pr.index,
                  status,
                  statusColor
                })
              })
          }
          filterable
          columns={[
              {
                Header: "Qty",
                accessor: "qtyRange",
                sortable: true,
                filterable: false,
                maxWidth: 40,
                Cell: row => {
                  return (
                    <div className={`${row.original.statusColor}`}>{row.original.qtyRange}</div>
                  )
                },
              },
              {
                Header: "Shipping Terms",
                accessor: "term",
                sortable: true,
                filterable: false,
                Cell: row => {
                  return (
                    <div className={`${row.original.statusColor}`}>{row.original.termCell}</div>
                  )
                },
              },
              {
                Header: "Price",
                accessor: "price",
                sortable: true,
                filterable: false,
                Cell: row => {
                  return (
                    <div className={`${row.original.statusColor}`}>{row.original.priceCell}</div>
                  )
                },
              },
              {
                Header: "Effective Dates",
                accessor: "effective_dates",
                sortable: true,
                filterable: false,
                minWidth: 180,
                Cell: row => {
                  return (
                    <div className={`${row.original.statusColor}`}>{row.original.effectiveDatesCell}</div>
                  )
                },
              },
              {
                Header: "Currency",
                accessor: "currency",
                sortable: true,
                filterable: false,
                Cell: row => {
                  return (
                    <div className={`${row.original.statusColor}`}>{row.original.currencyCell}</div>
                  )
                },
              },
              {
                Header: "MOC",
                accessor: "moc",
                sortable: true,
                filterable: false,
                Cell: row => {
                  return (
                    <div className={`${row.original.statusColor}`}>{row.original.mocCell}</div>
                  )
                },
              },
              {
                Header: "STATUS",
                accessor: "status",
                sortable: true,
                filterable: false,
                Cell: row => {
                  return (
                    <div className={`${row.original.statusColor}`}>{row.original.status}</div>
                  )
                },
              },
          ]}
          minRows={0}
          defaultPageSize={1000}
          showPagination={false}
          className="-highlight"
      /></div>
      if (prices.length === 0) {
        return {
          hasChanged: null,
          table: (<div className="spec-table scrolling-table no-blue-row header-review" style={{marginLeft:10}}>
            <div ref={this.pricingFocus} className="pricingFocus">
              <strong>PRICING FOR ALL CUSTOMERS</strong>
            </div>
            <div style={{marginTop:20}}>n / a</div>
          </div>)
        };
      }
      return {
        hasChanged,
        table
      };
    }

    renderCustomerPricing = () => {
      const props = this.props, db = this.state.currentDB;
      let prices = [];
      if (props.bmasterarray[db]) {
        if (Object.keys(props.bmasterarray[db]).length > 0) {
          for (let combo in props.bmasterarray[db]) {
            const bp_name = combo.split('[.]')[1],
                  cardcode = combo.split('[.]')[0];
            let mocs = {}, pricings = [];
            if (props.bmasterarray[db][combo]) {
              if (props.bmasterarray[db][combo].length > 0) {
                props.bmasterarray[db][combo].forEach((bp,i) => {
                  if (bp[0]) {
                    if (parseInt(bp[0])) {
                      const finalObj = {
                        bp_name,
                        cardcode,
                        code: bp[3],
                        qty: parseInt(bp[0]),
                        price: bp[2],
                        priceCompared: '',
                        currency: bp[5],
                        currencyCompared: '',
                        term: bp[1],
                        termCompared: '',
                        start: bp[6],
                        end: bp[7],
                        dateCompared: '',
                        index: i,
                        status: 'UNCHANGED',
                        mocCompared: '',
                        db
                      };
                      if (props.bmasterarray_original[db]) {
                        const findSameCodeIndex = this.findCompareCode('bmasterarray', bp[3], db, props.bmasterarray_original[db][combo]);
                        console.log('found bmasterarray index', findSameCodeIndex, bp[3], i);
                        if (findSameCodeIndex === -1) {
                          finalObj.status = 'NEWLY ADDED';
                        } else {
                          // compare all
                          // is in original
                          const compareObj = this.findCompareCodeAll('bmasterarray', finalObj, findSameCodeIndex, combo);
                          console.log('compareObj', compareObj, findSameCodeIndex);
                          finalObj.status = 'UNCHANGED';
                          Object.keys(compareObj).forEach(field => {
                            if (compareObj[field]) {
                              finalObj.status = 'UPDATED';
                              finalObj[field] = compareObj[field];
                            }
                          });
                        }
                      }
                      pricings.push(finalObj);
                    } else {
                      if (bp[5] && bp[2]) {
                        mocs[bp[5]] = bp[2]
                      }
                      console.log('pushing customer MOC', mocs, mocs[bp[5]], bp[2]);
                    }
                  }
                });
              }
            }
            prices = prices.concat(pricings.map(p => {
              // concatenate MOCs
              let moctype = 'STANDARD', moc = '';
              if (mocs.hasOwnProperty(p.currency)) {
                moctype = 'SPECIAL';
                moc = mocs[p.currency] ? numberWithCommas(mocs[p.currency]) : mocs[p.currency];
              } else {
                if (this.props.mocs[db].hasOwnProperty(p.currency)) {
                  moc = this.props.mocs[db][p.currency] ? numberWithCommas(this.props.mocs[db][p.currency]) : this.props.mocs[db][p.currency];
                }
              }
              const mocNum = mocs[p.currency] ? mocs[p.currency] : 0;
              p.mocCompared = this.compareMOC('bmasterarray', p.currency, mocNum, combo, db);
              p.moc = `${p.currency}${moc} ${moctype}`;
              p.mocNum = mocNum;
              console.log('pushing customer MOC',p.moc);
              return p;
            }));
          }
        }
      }

      // add removed
      if (props.bmasterarray_original[db]) {
        if (Object.keys(props.bmasterarray_original[db]).length > 0) {
          for (let combo in props.bmasterarray_original[db]) {
            const bp_name = combo.split('[.]')[1],
                  cardcode = combo.split('[.]')[0];
            if (props.bmasterarray_original[db][combo]) {
              if (props.bmasterarray_original[db][combo].length > 0) {
                props.bmasterarray_original[db][combo].forEach(bp => {
                  if (bp[0]) {
                    if (parseInt(bp[0])) {
                      if (bp[3]) {
                        const code = bp[3];
                        if (props.bmasterarray[db][combo]) {
                          // find code
                          let new_code_collected = [];
                          props.bmasterarray[db][combo].forEach(bp2 => {
                            if (bp2[3]) {
                              new_code_collected.push(bp2[3]);
                            }
                          })
                          if (new_code_collected.indexOf(code) === -1) {
                            // found removed
                            const finalObj = {
                              bp_name,
                              cardcode,
                              code: bp[3],
                              qty: parseInt(bp[0]),
                              price: bp[2],
                              priceCompared: '',
                              currency: bp[5],
                              currencyCompared: '',
                              term: bp[1],
                              termCompared: '',
                              start: bp[6],
                              end: bp[7],
                              dateCompared: '',
                              index: -1,
                              status: 'REMOVED',
                              db,
                              moc: ''
                            };
                            prices.push(finalObj);
                          }
                        }
                      }
                    }
                  }
                });
              }
            }
          }
        }
      }

      // array pricings
      const table = <div className="spec-table scrolling-table no-blue-row header-review" style={{marginLeft:10}}>
      <div><strong>CUSTOMER SPECIFIC PRICING</strong></div>
      <ReactTable
          data={
              prices.map((pr, key) => {
                let price = '';
                if (pr.price) {
                  if (parseFloat(pr.price)) {
                    price = parseFloat(pr.price);
                  }
                }
                let status = '', statusColor = '';
                status = pr.status ? pr.status : 'UNCHANGED';
                if (status) {
                  if (status === 'REMOVED') {
                    statusColor = 'red-text';
                  }
                  if (status === 'NEWLY ADDED') {
                    statusColor = 'green-text';
                  }
                }
                return ({
                  id: key,
                  qty: pr.qty,
                  qtyRange: getRange(pr.qty),
                  price: `${pr.currency}${price}`,
                  priceCell: (
                    <span className={pr.status !== 'REMOVED' ? `hovered hover-label` : ''} onClick={() => {
                      if (pr.status === 'REMOVED') return;
                      this.openEdit({
                        title: 'EDIT PRICE',
                        type: 'customer-price',
                        subType: 'price',
                        cardcode: pr.cardcode,
                        db,
                        value: pr.price,
                        index: pr.index,
                        currency: pr.currency
                      });
                    }}>
                      <span>{`${pr.currency}${price}`}</span><span>{pr.priceCompared}</span>
                    </span>
                  ),
                  real_price: pr.price,
                  currency: pr.currency,
                  currencyCell: (
                    <span className={pr.status !== 'REMOVED' ? `hovered hover-label` : ''} onClick={() => {
                      if (pr.status === 'REMOVED') return;
                      this.openEdit({
                        title: 'EDIT CURRENCY',
                        type: 'customer-price',
                        subType: 'currency',
                        cardcode: pr.cardcode,
                        db,
                        value: pr.currency,
                        index: pr.index,
                      });
                    }}>
                      <span>{pr.currency}</span><span>{pr.currencyCompared}</span>
                    </span>
                  ),
                  term: pr.term,
                  termCell: (
                    <span className={pr.status !== 'REMOVED' ? `hovered hover-label` : ''} onClick={() => {
                      if (pr.status === 'REMOVED') return;
                      this.openEdit({
                        title: 'EDIT TERM',
                        type: 'customer-price',
                        subType: 'term',
                        cardcode: pr.cardcode,
                        db,
                        value: pr.term,
                        index: pr.index,
                      });
                    }}>
                      <span>{pr.term}</span><span>{pr.termCompared}</span>
                    </span>
                  ),
                  start: pr.start,
                  end: pr.end,
                  effective_dates: `${pr.start} TO ${pr.end}`,
                  dateCell: (
                    <span className={pr.status !== 'REMOVED' ? `hovered hover-label` : ''} onClick={() => {
                      if (pr.status === 'REMOVED') return;
                      this.openEdit({
                        title: 'EDIT DATE',
                        type: 'customer-price',
                        subType: 'date',
                        cardcode: pr.cardcode,
                        db,
                        value: `${pr.start} TO ${pr.end}`,
                        index: pr.index,
                      });
                    }}>
                      <span>{`${pr.start} TO ${pr.end}`}</span><span>{pr.dateCompared}</span>
                    </span>
                  ),
                  index: pr.index,
                  cardcode: pr.cardcode,
                  bp_name: pr.bp_name,
                  moc: pr.moc,
                  mocCell: (
                    <span className={pr.status !== 'REMOVED' ? `hovered hover-label` : ''} onClick={() => {
                      if (pr.status === 'REMOVED') return;
                      console.log('mocNum?',pr.mocNum);
                      this.openEdit({
                        title: 'EDIT MOC',
                        type: 'customer-price',
                        subType: 'moc',
                        db,
                        value: pr.mocNum ? parseFloat(pr.mocNum) : 0,
                        index: pr.index,
                        currency: pr.currency,
                        cardcode: pr.cardcode,
                        start: pr.start,
                        end: pr.end
                      });
                    }}>
                      <span>{pr.moc}</span><span>{pr.mocCompared}</span>
                    </span>
                  ),
                  status,
                  statusColor
                })
              })
          }
          filterable
          columns={[
              {
                Header: "Qty",
                accessor: "qtyRange",
                sortable: true,
                filterable: false,
                maxWidth: 40,
                Cell: row => {
                  return (
                    <div className={`${row.original.statusColor}`}>{row.original.qtyRange}</div>
                  )
                },
              },
              {
                Header: "Customer Name",
                accessor: "bp_name",
                sortable: true,
                filterable: false,
                Cell: row => {
                  return (
                    <div className={`${row.original.statusColor}`}>{row.original.bp_name}</div>
                  )
                },
                minWidth: 200
              },
              {
                Header: "Shipping Terms",
                accessor: "term",
                sortable: true,
                filterable: false,
                Cell: row => {
                  return (
                    <div className={`${row.original.statusColor}`}>{row.original.termCell}</div>
                  )
                },
              },
              {
                Header: "Price",
                accessor: "price",
                sortable: true,
                filterable: false,
                Cell: row => {
                  return (
                    <div className={`${row.original.statusColor}`}>{row.original.priceCell}</div>
                  )
                },
              },
              {
                Header: "Effective Dates",
                accessor: "effective_dates",
                sortable: true,
                filterable: false,
                minWidth: 180,
                Cell: row => {
                  return (
                    <div className={`${row.original.statusColor}`}>{row.original.dateCell}</div>
                  )
                },
              },
              {
                Header: "Currency",
                accessor: "currency",
                sortable: true,
                filterable: false,
                Cell: row => {
                  return (
                    <div className={`${row.original.statusColor}`}>{row.original.currencyCell}</div>
                  )
                },
              },
              {
                Header: "MOC",
                accessor: "moc",
                sortable: true,
                filterable: false,
                Cell: row => {
                  return (
                    <div className={`${row.original.statusColor}`}>{row.original.mocCell}</div>
                  )
                },
              },
              {
                Header: "STATUS",
                accessor: "status",
                sortable: true,
                filterable: false,
                Cell: row => {
                  return (
                    <div className={`${row.original.statusColor}`}>{row.original.status}</div>
                  )
                },
              },
          ]}
          minRows={0}
          defaultPageSize={1000}
          showPagination={false}
          className="-highlight"
      /></div>
      return table;
    }

    renderSupplierCost = () => {
      const props = this.props, db = this.state.currentDB;
      let prices = [];
      if (props.smasterarray[db]) {
        if (Object.keys(props.smasterarray[db]).length > 0) {
          for (let combo in props.smasterarray[db]) {
            const sup_name = combo.split('[.]')[1],
                  cardcode = combo.split('[.]')[0];
            let mocs = {}, pricings = [];
            if (props.smasterarray[db][combo]) {
              if (props.smasterarray[db][combo].length > 0) {
                props.smasterarray[db][combo].forEach((sup,i) => {
                  if (sup[0]) {
                    if (parseInt(sup[0])) {
                      const finalObj = {
                        sup_name,
                        cardcode,
                        code: sup[3],
                        qty: parseInt(sup[0]),
                        price: sup[2],
                        priceCompared: '',
                        currency: sup[5],
                        currencyCompared: '',
                        term: sup[1],
                        termCompared: '',
                        start: sup[6],
                        end: sup[7],
                        dateCompared: '',
                        status: 'UNCHANGED',
                        index: i,
                        db
                      }
                      if (props.smasterarray_original[db]) {
                        const findSameCodeIndex = this.findCompareCode('smasterarray', sup[3], db, props.smasterarray_original[db][combo]);
                        console.log('found smasterarray index', findSameCodeIndex, sup[3], i);
                        if (findSameCodeIndex === -1) {
                          finalObj.status = 'NEWLY ADDED';
                        } else {
                          // compare all
                          // is in original
                          const compareObj = this.findCompareCodeAll('smasterarray', finalObj, findSameCodeIndex, combo);
                          console.log('compareObj', compareObj, findSameCodeIndex);
                          finalObj.status = 'UNCHANGED';
                          Object.keys(compareObj).forEach(field => {
                            if (compareObj[field]) {
                              finalObj.status = 'UPDATED';
                              finalObj[field] = compareObj[field];
                            }
                          });
                        }
                      }
                      pricings.push(finalObj);
                    } else {
                      if (sup[5] && sup[2]) {
                        mocs[sup[5]] = sup[2];
                      }
                    }
                  }
                });
              }
            }
            prices = prices.concat(pricings.map(p => {
              // concatenate MOCs
              let moctype = 'STANDARD', moc = 0;
              if (mocs.hasOwnProperty(p.currency)) {
                moctype = 'SPECIAL';
                moc = mocs[p.currency] ? numberWithCommas(mocs[p.currency]) : mocs[p.currency];
              } else {
                // if (this.props.mocs[db].hasOwnProperty(p.currency)) {
                //   moc = this.props.mocs[db][p.currency] ? numberWithCommas(this.props.mocs[db][p.currency]) : this.props.mocs[db][p.currency];
                // }
              }
              const mocNum = mocs[p.currency] ? mocs[p.currency] : 0;
              p.mocCompared = this.compareMOC('smasterarray', p.currency, mocNum, combo, db);
              p.moc = `${p.currency}${moc} ${moctype}`;
              p.mocNum = mocNum;
              return p;
            }));
          }
        }
      }

      // add removed
      if (props.smasterarray_original[db]) {
        if (Object.keys(props.smasterarray_original[db]).length > 0) {
          for (let combo in props.smasterarray_original[db]) {
            const sup_name = combo.split('[.]')[1],
                  cardcode = combo.split('[.]')[0];
            if (props.smasterarray_original[db][combo]) {
              if (props.smasterarray_original[db][combo].length > 0) {
                props.smasterarray_original[db][combo].forEach(sup => {
                  if (sup[0]) {
                    if (parseInt(sup[0])) {
                      if (sup[3]) {
                        const code = sup[3];
                        if (props.smasterarray[db][combo]) {
                          // find code
                          let new_code_collected = [];
                          props.smasterarray[db][combo].forEach(sup2 => {
                            if (sup2[3]) {
                              new_code_collected.push(sup2[3]);
                            }
                          })
                          if (new_code_collected.indexOf(code) === -1) {
                            // found removed
                            const finalObj = {
                              sup_name,
                              cardcode,
                              code: sup[3],
                              qty: parseInt(sup[0]),
                              price: sup[2],
                              priceCompared: '',
                              currency: sup[5],
                              currencyCompared: '',
                              term: sup[1],
                              termCompared: '',
                              start: sup[6],
                              end: sup[7],
                              dateCompared: '',
                              index: -1,
                              status: 'REMOVED',
                              db,
                              moc: ''
                            };
                            prices.push(finalObj);
                          }
                        }
                      }
                    }
                  }
                });
              }
            }
          }
        }
      }

      // array pricings
      const table = <div className="spec-table scrolling-table no-blue-row header-review" style={{marginLeft:10}}>
      <div ref={this.costingFocus} className="costingFocus"><strong>VENDOR COSTING</strong></div>
      <ReactTable
          data={
              prices.map((pr, key) => {
                let price = '';
                if (pr.price) {
                  if (parseFloat(pr.price)) {
                    price = parseFloat(pr.price);
                  }
                }
                let status = '', statusColor = '';
                status = pr.status ? pr.status : 'UNCHANGED';
                if (status) {
                  if (status === 'REMOVED') {
                    statusColor = 'red-text';
                  }
                  if (status === 'NEWLY ADDED') {
                    statusColor = 'green-text';
                  }
                }
                return ({
                  id: key,
                  qty: pr.qty,
                  qtyRange: getRange(pr.qty),
                  price: `${pr.currency}${price}`,
                  priceCell: (
                    <span className={pr.status !== 'REMOVED' ? `hovered hover-label` : ''} onClick={() => {
                      if (pr.status === 'REMOVED') return;
                      this.openEdit({
                        title: 'EDIT PRICE',
                        type: 'supplier-cost',
                        subType: 'price',
                        cardcode: pr.cardcode,
                        db,
                        value: pr.price,
                        index: pr.index,
                        currency: pr.currency
                      });
                    }}>
                      <span>{`${pr.currency}${price}`}</span><span>{pr.priceCompared}</span>
                    </span>
                  ),
                  real_price: pr.price,
                  currency: pr.currency,
                  currencyCell: (
                    <span className={pr.status !== 'REMOVED' ? `hovered hover-label` : ''} onClick={() => {
                      if (pr.status === 'REMOVED') return;
                      this.openEdit({
                        title: 'EDIT CURRENCY',
                        type: 'supplier-cost',
                        subType: 'currency',
                        cardcode: pr.cardcode,
                        db,
                        value: pr.currency,
                        index: pr.index,
                      });
                    }}>
                      <span>{pr.currency}</span><span>{pr.currencyCompared}</span>
                    </span>
                  ),
                  term: pr.term,
                  termCell: (
                    <span className={pr.status !== 'REMOVED' ? `hovered hover-label` : ''} onClick={() => {
                      if (pr.status === 'REMOVED') return;
                      this.openEdit({
                        title: 'EDIT TERM',
                        type: 'supplier-cost',
                        subType: 'term',
                        cardcode: pr.cardcode,
                        db,
                        value: pr.term,
                        index: pr.index,
                      });
                    }}>
                      <span>{pr.term}</span><span>{pr.termCompared}</span>
                    </span>
                  ),
                  start: pr.start,
                  end: pr.end,
                  effective_dates: `${pr.start} TO ${pr.end}`,
                  dateCell: (
                    <span className={pr.status !== 'REMOVED' ? `hovered hover-label` : ''} onClick={() => {
                      if (pr.status === 'REMOVED') return;
                      this.openEdit({
                        title: 'EDIT DATE',
                        type: 'supplier-cost',
                        subType: 'date',
                        cardcode: pr.cardcode,
                        db,
                        value: `${pr.start} TO ${pr.end}`,
                        index: pr.index,
                      });
                    }}>
                      <span>{`${pr.start} TO ${pr.end}`}</span><span>{pr.dateCompared}</span>
                    </span>
                  ),
                  index: pr.index,
                  cardcode: pr.cardcode,
                  sup_name: pr.sup_name,
                  moc: pr.moc,
                  mocNum: pr.mocNum,
                  mocCell: (
                    <span className={pr.status !== 'REMOVED' ? `hovered hover-label` : ''} onClick={() => {
                      if (pr.status === 'REMOVED') return;
                      console.log('mocNum?',pr.mocNum);
                      this.openEdit({
                        title: 'EDIT MOC',
                        type: 'supplier-cost',
                        subType: 'moc',
                        db,
                        value: pr.mocNum ? parseFloat(pr.mocNum) : 0,
                        index: pr.index,
                        currency: pr.currency,
                        cardcode: pr.cardcode,
                        start: pr.start,
                        end: pr.end
                      });
                    }}>
                      <span>{pr.moc}</span><span>{pr.mocCompared}</span>
                    </span>
                  ),
                  status,
                  statusColor
                })
              })
          }
          filterable
          columns={[
              {
                Header: "Qty",
                accessor: "qtyRange",
                sortable: true,
                filterable: false,
                maxWidth: 40,
                Cell: row => {
                  return (
                    <div className={`${row.original.statusColor}`}>{row.original.qtyRange}</div>
                  )
                },
              },
              {
                Header: "Supplier Name",
                accessor: "sup_name",
                sortable: true,
                filterable: false,
                Cell: row => {
                  return (
                    <div className={`${row.original.statusColor}`}>{row.original.sup_name}</div>
                  )
                },
                minWidth: 200
              },
              {
                Header: "Shipping Terms",
                accessor: "term",
                sortable: true,
                filterable: false,
                Cell: row => {
                  return (
                    <div className={`${row.original.statusColor}`}>{row.original.termCell}</div>
                  )
                },
              },
              {
                Header: "Price",
                accessor: "price",
                sortable: true,
                filterable: false,
                Cell: row => {
                  return (
                    <div className={`${row.original.statusColor}`}>{row.original.priceCell}</div>
                  )
                },
              },
              {
                Header: "Effective Dates",
                accessor: "effective_dates",
                sortable: true,
                filterable: false,
                minWidth: 180,
                Cell: row => {
                  return (
                    <div className={`${row.original.statusColor}`}>{row.original.dateCell}</div>
                  )
                },
              },
              {
                Header: "Currency",
                accessor: "currency",
                sortable: true,
                filterable: false,
                Cell: row => {
                  return (
                    <div className={`${row.original.statusColor}`}>{row.original.currencyCell}</div>
                  )
                },
              },
              {
                Header: "MOC",
                accessor: "moc",
                sortable: true,
                filterable: false,
                Cell: row => {
                  return (
                    <div className={`${row.original.statusColor}`}>{row.original.mocCell}</div>
                  )
                },
              },
              {
                Header: "STATUS",
                accessor: "status",
                sortable: true,
                filterable: false,
                Cell: row => {
                  return (
                    <div className={`${row.original.statusColor}`}>{row.original.status}</div>
                  )
                },
              },
          ]}
          minRows={0}
          defaultPageSize={1000}
          showPagination={false}
          className="-highlight"
      /></div>
      if (this.props.item_is[db] === 'Produced in House' || this.props.item_is[db] === 'None') {
        return <div><Blank title="TARGET COSTING" /></div>;
      }
      return table;
    }

    renderTargetCost = () => {
      const props = this.props, db = this.state.currentDB;
      let singleStatus = 'UNCHANGED';
      let prices = [];
      // single value pricings
      if (props.cprice[db]) {
        if (!props.ccode[db]) {
          singleStatus = 'NEWLY ADDED';
        }
        let finalSingleObj = {
          qty: 1,
          price: props.cprice[db],
          priceCompared: '',
          currency: props.ccur[db],
          currencyCompared: '',
          term: props.cterm[db],
          termCompared: '',
          start: props.cstart[db],
          end: props.cend[db],
          dateCompared: '',
          index: -1,
          db,
          status: singleStatus
        }
        if (singleStatus !== 'NEWLY ADDED') {
          finalSingleObj.priceCompared = this.compare(props.cprice[db], props.cprice_original[db]);
          finalSingleObj.currencyCompared = this.compare(props.ccur[db], props.ccur_original[db]);
          finalSingleObj.termCompared = this.compare(props.cterm[db], props.cterm_original[db]);
          const dateSingle = `${props.cstart[db]} TO ${props.cend[db]}`;
          const dateSingleOriginal = `${props.cstart_original[db]} TO ${props.cend_original[db]}`;
          finalSingleObj.dateCompared = this.compare(dateSingle, dateSingleOriginal);
          if (finalSingleObj.priceCompared || finalSingleObj.currencyCompared
            || finalSingleObj.termCompared || finalSingleObj.dateCompared) {
            finalSingleObj.status = 'UPDATED';
          }
        }
        prices.push(finalSingleObj);
      }

      // ADD DELETED Single
      if (this.props.ccode_original[db]) {
        if (this.props.ccode[db]) {
          if (this.props.ccode_original[db] !== this.props.ccode[db]) {
            prices.push({
              qty: 1,
              price: props.cprice_original[db],
              priceCompared: '',
              currency: props.ccur_original[db],
              currencyCompared: '',
              term: props.cterm_original[db],
              termCompared: '',
              start: props.cstart_original[db],
              end: props.cend_original[db],
              datesCompared: '',
              moc: '',
              mocType: '',
              code: props.ccode[db],
              db,
              index: -1,
              status: 'REMOVED'
            })
          }
        }
      }

      // array prices
      if (props.cprices[db]) {
        if (props.cprices[db].length > 0) {
          props.cprices[db].forEach((pr,i) => {
            if (props.cqtys[db][i]) {
              if (parseInt(props.cqtys[db][i]) != 0) {
                let finalObj = {
                  qty: parseInt(props.cqtys[db][i]),
                  price: props.cprices[db][i],
                  priceCompared: '',
                  currency: props.ccurs[db][i],
                  currencyCompared: '',
                  term: props.cterms[db][i],
                  termCompared: '',
                  start: props.cstarts[db][i],
                  end: props.cends[db][i],
                  dateCompared: '',
                  index: i,
                  db,
                  status: 'UNCHANGED'
                }
                const findSameCodeIndex = this.findCompareCode('ccodes', props.gcodes[db][i], db);
                console.log('findSameCodeIndex', findSameCodeIndex);
                if (findSameCodeIndex !== -1) {
                  // is in original
                  const compareObj = this.findCompareCodeAll('target-cost', finalObj, findSameCodeIndex);
                  finalObj.status = 'UNCHANGED';
                  Object.keys(compareObj).forEach(field => {
                    if (compareObj[field]) {
                      finalObj.status = 'UPDATED';
                      finalObj[field] = compareObj[field];
                    }
                  });
                } else {
                  if (props.ccodes[db]) {
                    if (!props.ccodes[db][i]) {
                      console.log('not found ccodes', props.ccodes[db][i])
                      finalObj.status = 'NEWLY ADDED';
                    }
                  }
                }

                prices.push(finalObj);
              }
            }
          });
        }
      }

      // find removed array values
      if (props.ccodes_original[db] && props.ccodes[db]) {
        props.ccodes_original[db].forEach((code,old_index) => {
          if (props.ccodes[db].indexOf(code) === -1) {
            prices.push({
              qty: parseInt(props.cqtys_original[db][old_index]),
              price: props.cprices_original[db][old_index],
              priceCompared: '',
              currency: props.ccurs_original[db][old_index],
              currencyCompared: '',
              term: props.cterms_original[db][old_index],
              termCompared: '',
              start: props.cstarts_original[db][old_index],
              end: props.cends_original[db][old_index],
              dateCompared: '',
              code: props.ccodes[db][old_index],
              moc: ``,
              db: db,
              index: old_index,
              status: 'REMOVED'
            });
          }
        });
      }

      // array pricings
      const table = <div className="spec-table scrolling-table no-blue-row header-review" style={{marginLeft:10}}>
      <div><strong>TARGET COSTING</strong></div>
      <ReactTable
          data={
              prices.map((pr, key) => {
                let price = '';
                if (pr.price) {
                  if (parseFloat(pr.price)) {
                    price = parseFloat(pr.price);
                  }
                }
                let status = '', statusColor = '';
                status = pr.status ? pr.status : 'UNCHANGED';
                if (status) {
                  if (status === 'REMOVED') {
                    statusColor = 'red-text';
                  }
                  if (status === 'NEWLY ADDED') {
                    statusColor = 'green-text';
                  }
                }
                return ({
                  id: key,
                  qty: pr.qty,
                  qtyRange: getRange(pr.qty),
                  price: `${pr.currency}${price}`,
                  priceCell: (
                    <span className={pr.status !== 'REMOVED' ? `hovered hover-label` : ''} onClick={() => {
                      if (pr.status === 'REMOVED') return;
                      this.openEdit({
                        title: 'EDIT PRICE',
                        type: 'target-cost',
                        subType: 'price',
                        db,
                        value: pr.price,
                        index: pr.index,
                        currency: pr.currency
                      });
                    }}>
                      <span>{`${pr.currency}${price}`}</span>
                    </span>
                  ),
                  originalPrice: pr.price,
                  currency: pr.currency,
                  currencyCell: (
                    <span className={pr.status !== 'REMOVED' ? `hovered hover-label` : ''} onClick={() => {
                      if (pr.status === 'REMOVED') return;
                      this.openEdit({
                        title: 'EDIT CURRENCY',
                        type: 'target-cost',
                        subType: 'currency',
                        db,
                        value: pr.currency,
                        index: pr.index
                      });
                    }}>
                      <span>{pr.currency}</span>
                    </span>
                  ),
                  term: pr.term,
                  termCell: (
                    <span className={pr.status !== 'REMOVED' ? `hovered hover-label` : ''} onClick={() => {
                      if (pr.status === 'REMOVED') return;
                      this.openEdit({
                        title: 'EDIT SHIPPING TERM',
                        type: 'target-cost',
                        subType: 'term',
                        db,
                        value: pr.term,
                        index: pr.index
                      });
                    }}>
                      <span>{pr.term}</span>
                    </span>
                  ),
                  start: pr.start,
                  end: pr.end,
                  effective_dates: `${pr.start} TO ${pr.end}`,
                  effectiveDatesCell: (
                    <span className={pr.status !== 'REMOVED' ? `hovered hover-label` : ''} onClick={() => {
                      if (pr.status === 'REMOVED') return;
                      this.openEdit({
                        title: 'EDIT EFFECTIVE DATES',
                        type: 'target-cost',
                        subType: 'date',
                        db,
                        value: `${pr.start} TO ${pr.end}`,
                        index: pr.index
                      });
                    }}>
                      <span>{`${pr.start} TO ${pr.end}`}</span>
                    </span>
                  ),
                  index: pr.index,
                  status,
                  statusColor
                })
              })
          }
          filterable
          columns={[
              {
                Header: "Qty",
                accessor: "qtyRange",
                sortable: true,
                filterable: false,
                maxWidth: 40,
                Cell: row => {
                  return (
                    <div className={`${row.original.statusColor}`}>{row.original.qtyRange}</div>
                  )
                },
              },
              {
                Header: "Shipping Terms",
                accessor: "term",
                sortable: true,
                filterable: false,
                Cell: row => {
                  return (
                    <div className={`${row.original.statusColor}`}>{row.original.termCell}</div>
                  )
                },
              },
              {
                Header: "Price",
                accessor: "price",
                sortable: true,
                filterable: false,
                Cell: row => {
                  return (
                    <div className={`${row.original.statusColor}`}>{row.original.priceCell}</div>
                  )
                },
              },
              {
                Header: "Effective Dates",
                accessor: "effective_dates",
                sortable: true,
                filterable: false,
                minWidth: 180,
                style: { whiteSpace: 'unset' },
                Cell: row => {
                  return (
                    <div className={`${row.original.statusColor}`}>{row.original.effectiveDatesCell}</div>
                  )
                },
              },
              {
                Header: "Currency",
                accessor: "currency",
                sortable: true,
                filterable: false,
                Cell: row => {
                  return (
                    <div className={`${row.original.statusColor}`}>{row.original.currencyCell}</div>
                  )
                },
              },
              {
                Header: "Status",
                accessor: "status",
                sortable: true,
                filterable: false,
                Cell: row => {
                  return (
                    <div className={`${row.original.statusColor}`}>{row.original.status}</div>
                  )
                },
              },
          ]}
          minRows={0}
          defaultPageSize={1000}
          showPagination={false}
          className="-highlight"
      /></div>
      if (this.props.item_is[db] === 'Produced in House' || this.props.item_is[db] === 'None') {
        return <Blank title="VENDOR COSTING" />;
      }
      return table;
    }

    scrollTo = (target) => {
        //.current is verification that your element has rendered
        const targetElem = document.querySelector(`.${target}`);
        console.log('scrolling to', target, targetElem);
        if (targetElem) targetElem.scrollIntoView();
    }

    checkCodeStatus = (finalObj, target) => {
      const tgt = `${target}_original`,
            code = finalObj.code,
            db = finalObj.db,
            i = finalObj.index,
            props = this.props;
      if (target === 'gcodes') {
        if (this.props[tgt]) {
          if (this.props[tgt].length > 0) {
            if (code) {
              if (this.props[tgt].indexOf(code) !== -1) {
                // return for comparison
                return {
                  qty: parseInt(props.gqtys_original[db][i]),
                  price: props.gprices_original[db][i],
                  currency: props.gcurrs_original[db][i],
                  term: props.gterms_original[db][i],
                  start: props.gstarts_original[db][i],
                  end: props.gends_original[db][i],
                  code: props.gcodes_original[db][i],
                  index: i,
                  action: 'compare'
                }
              } else {
                // removed
                return {
                  action: 'REMOVED'
                }
              }
            }
          }
        }
        return { action: 'NEWLY ADDED' };
      }
    }

    renderTables = () => {
      const pricings = this.renderAllPricing();
      let dbs = clone(this.props.hasChanged);
      if (pricings.hasChanged) {
        if (dbs.indexOf(this.state.currentDB) === -1) {
          dbs.push(this.state.currentDB);
        }
      }
      this.props.checkForChanges(dbs);
      this.setState({
        pricings: pricings.table
      })
    }

    componentDidMount() {
      this.props.setScroll(this.scrollTo);
      this.renderTables();
    }

    componentDidUpdate(prevProps, prevState) {
      if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
        console.log('props updated');
        this.renderTables();
      }
    }

    render() {
      console.log('GINreview detailed dbs props', this.props);
      const props = this.props;
      const pricingsTable = this.state.pricings;
      const db = this.state.currentDB;

      let hasWalmart = false;
      const picked_rbo_db = this.props.picked ? this.props.picked.rbo ? this.props.picked.rbo : null : null ;
      if (picked_rbo_db) {
        const picked_rbo_value = this.props.rbo[picked_rbo_db];
        if (picked_rbo_value) {
          // get name
          if (this.props.rbos) {
            for (let i = 0; i < this.props.rbos.length; i++) {
              const rboo = this.props.rbos[i];
              if (rboo.id === picked_rbo_value) {
                const rbooname = rboo.name;
                if (rboo.name) {
                  if (rboo.name.indexOf('WALMART') !== -1) {
                    hasWalmart = true;
                  }
                }
              }
            }
          }
        }
      }

        return (
        <div>
          {db &&
        <div style={{marginTop:20}}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <div
                  ref={this.dbFocus}
                  style={{backgroundColor:generateColor(db)}}
                  className="text-center bold dbFocus"
                ><h4 style={{marginTop:0,paddingTop:10,paddingBottom:10}}>{db}</h4></div>

                <GridContainer>
                  <GridItem xs={12} sm={4} md={4}>
                    {this.renderBaseItemInfo()}
                  </GridItem>
                  <GridItem xs={12} sm={4} md={4}>
                    {this.renderBaseValues1(hasWalmart)}
                  </GridItem>
                  <GridItem xs={12} sm={4} md={4}>
                    {this.renderBaseValues2()}
                  </GridItem>
                </GridContainer>
                <hr className="hr-darker" />
                <GridContainer>
                  <GridItem xs={12} sm={5} md={5}>
                    {this.renderVariants()}
                  </GridItem>
                  <GridItem xs={12} sm={7} md={7}>
                    {this.renderBOM()}
                  </GridItem>
                </GridContainer>
                <hr className="hr-darker" />
                <GridContainer>
                  <GridItem xs={12} sm={5} md={12}>
                    {pricingsTable}
                  </GridItem>
                </GridContainer>
                <hr style={{marginTop:30}} className="hr-darker" />
                <GridContainer>
                  <GridItem xs={12} sm={5} md={12}>
                    {this.renderCustomerPricing()}
                  </GridItem>
                </GridContainer>
                <hr style={{marginTop:30}} className="hr-darker" />
                <div ref={this.costingFocus} className="costingFocus"></div>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    {this.renderSupplierCost()}
                  </GridItem>
                </GridContainer>
                <hr style={{marginTop:30}} className="hr-darker" />
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    {this.renderTargetCost()}
                  </GridItem>
                </GridContainer>
                <hr style={{borderTop:'1px solid rgba(0,0,0,0)'}} />
              </Card>
            </GridItem>
          </GridContainer>
        </div>}
        {
          this.state.popup && <GINreviewDialog
            open={this.state.popup}
            close={this.closePopup}
            data={this.state.data}
          />
        }
        {
          this.state.pickEdit && <GINreviewDialogPickValue
            open={this.state.pickEdit}
            close={this.closePickEdit}
            data={this.state.pickData}
          />
        }
        {
          this.state.variantEdit && <GINreviewDialogVariants
            open={this.state.variantEdit}
            close={this.closeVariantEdit}
            data={this.state.variantData}
          />
        }
        {
          this.state.openEdit && <GINreviewDialogEdit
            open={this.state.openEdit}
            close={this.closeEdit}
            data={this.state.data}
            checkDupes={this.props.checkDupes}
            checkDupesSupplierCost={this.props.checkDupesSupplierCost}
            checkDupesTargetCost={this.props.checkDupesTargetCost}
            checkDupesCustomerPrice={this.props.checkDupesCustomerPrice}
          />
        }
      </div>
      )
    }
}

const mapStateToProps = state => {
  return {
    dbList: state.gin.dbList,
    loading: state.gin.loading,
    itemCodes: state.gin.itemCodes,
    openedDBs: state.gin.openedDBs,
    editMode: state.gin.editMode,
    picked: state.gin.picked,
    newItemList: state.gin.newItemList,
    options: state.gin.options,
    brandNames: state.gin.brands,
    subgroupNames: state.gin.subgroups,
    packagingtypes: state.gin.packagingtypes,

    // IMAGE
    ginFinalCrop: state.gin.ginFinalCrop,

    // GENERAL AND MEASUREMENTS
    base_item_description: state.gin.base_item_description,
    item_is: state.gin.item_is,
    product_type: state.gin.product_type,
    rbo: state.gin.rbo,
    brand: state.gin.brand,
    subgroup: state.gin.subgroup,
    ipackagingtypes: state.gin.ipackagingtypes,
    sales_unit: state.gin.sales_unit,
    basic_packaging_unit: state.gin.basic_packaging_unit,
    qty_per_unit: state.gin.qty_per_unit,
    ilength: state.gin.ilength,
    width: state.gin.width,
    weight: state.gin.weight,
    picks: state.gin.picks,

    base_item_description_original: state.gin.base_item_description_original,
    item_is_original: state.gin.item_is_original,
    product_type_original: state.gin.product_type_original,
    rbo_original: state.gin.rbo_original,
    brand_original: state.gin.brand_original,
    subgroup_original: state.gin.sub_group_original,
    ipackagingtypes_original: state.gin.ipackagingtypes_original,
    sales_unit_original: state.gin.sales_unit_original,
    basic_packaging_unit_original: state.gin.basic_packaging_unit_original,
    qty_per_unit_original: state.gin.qty_per_unit_original,
    ilength_original: state.gin.ilength_original,
    width_original: state.gin.width_original,
    weight_original: state.gin.weight_original,
    picks_original: state.gin.picks_original,

    rbos: state.gmp.rbos, // options
    brands: state.gin.brands,// options
    subgroups: state.gin.subgroups, // options

    // SPECIAL FIELDS
    picks: state.gin.picks,
    picks_original: state.gin.picks_original,
    // NIN DB - Tax
    gst_relevant: state.gin.gst_relevant,
    material_type: state.gin.material_type,
    hsn_code: state.gin.hsn_code,
    gst_tax_category: state.gin.gst_tax_category,
    service_acct_code: state.gin.service_acct_code,

    gst_relevant_original: state.gin.gst_relevant_original,
    material_type_original: state.gin.material_type_original,
    hsn_code_original: state.gin.hsn_code_original,
    gst_tax_category_original: state.gin.gst_tax_category_original,
    service_acct_code_original: state.gin.service_acct_code_original,

    // PRICING FOR ALL
    gcur: state.gin.gcur,
    gstart: state.gin.gstart,
    gend: state.gin.gend,
    gprice: state.gin.gprice,
    gterm: state.gin.gterm,
    gterms: state.gin.gterms,
    gstarts: state.gin.gstarts,
    gends: state.gin.gends,
    gprices: state.gin.gprices,
    gcurrs: state.gin.gcurrs,
    gqtys: state.gin.gqtys,
    mocs: state.gin.mocs,
    specialmoc: state.gin.specialmoc,

    gcodes: state.gin.gcodes,
    gcode: state.gin.gcode,

    gcodes_original: state.gin.gcodes_original,
    gcode_original: state.gin.gcode_original,
    gcur_original: state.gin.gcur_original,
    gstart_original: state.gin.gstart_original,
    gend_original: state.gin.gend_original,
    gprice_original: state.gin.gprice_original,
    gterm_original: state.gin.gterm_original,
    gterms_original: state.gin.gterms_original,
    gstarts_original: state.gin.gstarts_original,
    gends_original: state.gin.gends_original,
    gprices_original: state.gin.gprices_original,
    gcurrs_original: state.gin.gcurrs_original,
    gqtys_original: state.gin.gqtys_original,

    // CUSTOMER SPECIFIC PRICING
    bmasterarray: state.gin.bmasterarray,
    bmasterarray_original: state.gin.bmasterarray_original,

    // TARGET COST
    cprice: state.gin.cprice,
    cterm: state.gin.cterm,
    ccur: state.gin.ccur,
    cstart: state.gin.cstart,
    cend: state.gin.cend,
    cqtys: state.gin.cqtys,
    cterms: state.gin.cterms,
    ccurs: state.gin.ccurs,
    cprices: state.gin.cprices,
    cstarts: state.gin.cstarts,
    cends: state.gin.cends,
    ccode: state.gin.ccode,
    ccodes: state.gin.ccodes,

    cprice_original: state.gin.cprice_original,
    cterm_original: state.gin.cterm_original,
    ccur_original: state.gin.ccur_original,
    cstart_original: state.gin.cstart_original,
    cend_original: state.gin.cend_original,
    cqtys_original: state.gin.cqtys_original,
    cterms_original: state.gin.cterms_original,
    ccurs_original: state.gin.ccurs_original,
    cprices_original: state.gin.cprices_original,
    cstarts_original: state.gin.cstarts_original,
    cends_original: state.gin.cends_original,
    ccode_original: state.gin.ccode_original,
    ccodes_original: state.gin.ccodes_original,

    // SUPPLIER COST
    smasterarray: state.gin.smasterarray,
    smasterarray_original: state.gin.smasterarray_original,

    // BOM
    rms: state.gin.rms,
    rmsd: state.gin.rmsd,
    rmsq: state.gin.rmsq,
    rmsw: state.gin.rmsw,
    BOMItemBaseQty: state.gin.BOMItemBaseQty,
    comp: state.gin.comp,

    rms_original: state.gin.rms_original,
    rmsd_original: state.gin.rmsd_original,
    rmsq_original: state.gin.rmsq_original,
    rmsw_original: state.gin.rmsw_original,
    BOMItemBaseQty_original: state.gin.BOMItemBaseQty_original,

    // VARIANTS
    variants: state.gin.variants,
    variantsDesc: state.gin.variantsDesc,
    variantsChecked: state.gin.variantsChecked,
    variantsToAdd: state.gin.variantsToAdd,
    originalVariantsDesc: state.gin.originalVariantsDesc,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    editModeOn: () => {
      dispatch(actions.editModeOn());
    },
    setEdit: (data) => {
      dispatch(actions.setGINEdit(data));
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GINreviewDetailedDB);
