import React, { Component } from "react";

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Close from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Check from '@material-ui/icons/Check';
import Button from "components/CustomButtons/Button.jsx";
import Table from "components/Table/Table.jsx";
import moment from "moment";

import BPFormReview from "containers/BP/BPFormReview";

const BPGroupModal = (props) => {
  console.log('BPGroupModal sprops', props);
  const first = props.data[0].bp;
  const bpname = first.company_name ? first.company_name : first.customer_name;
  const db = first.DB;
  let tableData = [];
  let tableData2 = [];
  const key = props.groupKey;
  const exact_name = key.split('__')[1];

  props.data.forEach((bp,i) => {
    console.log('bp', bp);
    const bpname = bp.bp.company_name ? bp.bp.company_name : bp.bp.customer_name;
    const arr = [
      bp.id,
      bpname,
      bp.bp.company_name ? 'New' : 'Existing',
      moment(bp.bp.date_modified).format("MM/DD/YYYY hh:mm a"),
      bp.match,
      <div>
        <Button
          color="white"
          style={{marginRight:10}}
          onClick={() => {
            console.log('review', bp.id);
            props.fetchAddInfoList(db);
            props.fetchLocalBP(bp.id, 'previewOnly');
            if (db.indexOf('NIN') !== -1) {
              props.fetchGSTType();
            }
          }}
        >Preview</Button>
        {props.data.length > 1 && <Button
          color="rose"
          onClick={() => {
            console.log('choose as primary', bp.id);
            props.choosePrimary({
              chosen_id:bp.id,
              other_ids:props.data.filter((b,j) => {
                if (b.id !== bp.id) return true;
              }).map((bb,jj) => {
                return bb.id;
              })
            });
            props.close();
          }}
        >Primary</Button>}
        <Button
          color="danger"
          onClick={() => {
            console.log('remove from group', bp.id);
            props.removeFromGroupModal(bp.id, props.groupKey);
            if (props.data) {
              if (props.data.length === 1) {
                props.close();
              }
            }
          }}
        ><Close /></Button>
      </div>
    ];
    if (bpname === exact_name) {
      tableData.push(arr);
    } else {
      tableData2.push(arr);
    }
  });
  const table = <Table
    tableHeaderColor="primary"
    tableHead={["#", "Name", "Type", "Date/Time", "Action"]}
    tableData={tableData}
    coloredColls={[3]}
    colorsColls={["primary"]}
  />
  const table2 = <Table
    tableHeaderColor="primary"
    tableHead={["#", "Name", "Type", "Date/Time", "Match", "Action"]}
    tableData={tableData2}
    coloredColls={[3]}
    colorsColls={["primary"]}
  />
  return (
    <Dialog
      open={props.open}
      keepMounted
      onClose={props.close}
      fullWidth={true}
      maxWidth="md"
    >
      <DialogTitle>
        <IconButton edge="end" color="inherit" onClick={props.close} aria-label="Close" style={{float:'right'}}>
          <Close />
        </IconButton>
        <div className="text-center">
          <h4><strong>{db} - {bpname} Group</strong></h4>
          <p style={{fontSize:14}}>Choose Primary or Remove from Group</p>
        </div>
      </DialogTitle>
      <DialogContent>
        <h3 className="text-center"><strong>Exact Matches</strong></h3>
        {table}
        <hr />
        <h3 className="text-center"><strong>Other Matches / Added Entries</strong></h3>
        {table2}
        {tableData2.length === 0 ? <div className="text-center"><em>None</em></div> : null}
      </DialogContent>
      {
        props.localBPreview &&
          <BPFormReview
            finalValues={props.localBPreview}
            beforeValues={props.localBPreview}
            open={props.localBPreview ? true : false}
            close={props.closeReviewModal}
            countries={props.countriesDictionary}
            states={props.statesDictionary}
            gsttypes={props.gsttypesDictionary}
            owhtopts={props.owhtoptsDictionary}
            groupcodesDictionary={props.groupcodesDictionary}
            BPaction=""
            currentDB={db}
            currentBPType="Customer"
          />
      }
    </Dialog>
  )
}

export default BPGroupModal;
