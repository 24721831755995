export const required = value => value ? undefined : 'Required'

export const email = value => {
    if (value) {
      // const itMatches = value.match(/^([\w\!\#$\%\&\'\*\+\-\/\=\?\^\`{\|\}\~]+\.)*[\w\!\#$\%\&\'\*\+\-\/\=\?\^\`{\|\}\~]+@((((([a-z0-9]{1}[a-z0-9\-]{0,62}[a-z0-9]{1})|[a-z])\.)+[a-z]{2,6})|(\d{1,3}\.){3}\d{1,3}(\:\d{1,5})?)$/i);

      const itMatches = value.match(/\S+@\S+\.\S+/);

      return value && !itMatches ? 'Invalid email address' : undefined;
    }
    return value;
}
    // value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
    //     'Invalid email address' : undefined

export const blacklist = value => {
          var myRegex = /[!#%^*+=[\]'`~;{}|_:<>?]/g;
          var blacklisted = myRegex.test(value);
          return value && blacklisted ? "!#%^*+=[]'`~;{}|_:<>? characters are not allowed" : undefined
  }

export const tel_blacklist = value => {
          var myRegex = /[!@#$%^&*=[\]'`~;,{}|_:<>?]/g;
          var blacklisted = myRegex.test(value);
          return value && blacklisted ? "!#%^*+=[]'`~;{}|_:<>? characters are not allowed" : undefined
  }

export const zipcode_blacklist = value => {
          var myRegex = /[!@#$%^&*=[\]'`~;,{}|_:<>?]/g;
          var blacklisted = myRegex.test(value);
          return value && blacklisted ? "!@#$%^&*=[]'`~;,{}|_:<>? characters are not allowed" : undefined
  }

export const alphanum_whitelist = value => {
          var myRegex = /^[a-z0-9]+$/i;
          var whitelisted = myRegex.test(value);
          return value && !whitelisted ? "Alphanumeric characters only" : undefined
  }

  export const alpha_whitelist = value => {
            var myRegex = /^[a-z \-A-Z]+$/i;
            var whitelisted = myRegex.test(value);
            return value && !whitelisted ? "Alphabet characters only, no characters accents" : undefined
    }

export const num_whitelist = value => {
  if (value || value === 0) {
    var myRegex = /^[0-9+ \-()]+$/i;
    var whitelisted = myRegex.test(value);
    return value && !whitelisted ? "No letters allowed" : undefined
  }
}

export const email_blacklist = value => {
          var myRegex = /[!#$%^&*+=[\]\\';,()/{}|:<>?]/i;
          var blacklisted = myRegex.test(value);
          return value && blacklisted ? "!#$%^&*+=[]\\';,()/{}|:<>? characters only" : undefined
  }

export const not_just_num = value => {
  return value && !isNaN(value) ? "Cannot be made up of only numbers" : undefined
}

export const no_num_start = value => {
  return value && !isNaN(value[0]) ? "Value cannot start with a number" : undefined
}

export const portal_charvalidations = {
  eChares: "!@#$%^&*+=-[]\\\';,()./{}|\:<>?",
  iChars: "!@#$%^&*+=[]\\\';,()./{}|\:<>?",
  aCharms: "!@#$%^&*+=[]'`~;,().{}|_:<>?",
  uCharts: "!@#$%^&*+=[]\\\';()./{}|\:<>?",
}
