import React, { Component } from "react";
import { connect } from 'react-redux';
import ReactDOM from "react-dom";
import PropTypes from 'prop-types';
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { required, email, blacklist, tel_blacklist } from 'shared/validation';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

import InputLabel from '@material-ui/core/InputLabel';
import * as actions from 'store/actions';
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import withStyles from "@material-ui/core/styles/withStyles";

import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
// @material-ui/icons
import Settings from "@material-ui/icons/Settings";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Clearfix from "components/Clearfix/Clearfix.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";

import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";
import { formatDate } from "shared/utility";
import { fetchGMPAddress } from "store/actions/GMP";
import GMPProfileReviewNewContact from "./GMPProfileReviewNewContact";
import charValidate from "shared/charValidation";

const upper = value => value && value.toUpperCase();

const renderTextAreaField = ({
  input,
  label,
  meta: { touched, error }
}) => (
  <div style={{marginTop:'0px'}} className="textarea-nep">
    <CustomInput
      labelText={label}
      id="notes-me"
      className="text-field-input-custom"
      style={{width:'100%'}}
      formControlProps={{
        fullWidth: true
      }}
      inputProps={{
        multiline: true,
        rows: 2,
        onChange: input.onChange,
        value: input.value
      }}
    />
    {
      error && <span className="red">{error}</span>}
  </div>
)

const renderTextField = ({
    input,
    label,
    meta: { touched, error }
}) => (
    <div>
      <CustomInput
          labelText={label}
          id={input.name}
          formControlProps={{
              fullWidth: true,
          }}
          inputProps={{
              onChange: input.onChange,
              value: input.value
          }}
          error={error ? true : false}
      />
      {error && <span className="red">{error}</span>}
    </div>
    )

const renderEmailField = ({
  input,
  label,
  meta: { touched, error }
}) =>
  (
    <div>
      <CustomInput
          labelText={label}
          id={input.name}
          formControlProps={{
              fullWidth: true,
          }}
          inputProps={{
              onChange: input.onChange,
              value: input.value
          }}
      />
      {touched &&
        ((error && <span className="red">{error}</span>))}
    </div>
  )

const style1 = {
  padding: '0 10px 10px 10px',
  border: '1px solid #f1f1f1',
  marginTop: '20px'
}

const checkChar = (value, testChar) => {
  let arrv = value.split('');
  let result = true;
  arrv.forEach((c) => {
    if (testChar.indexOf(c) !== -1) {
      return result = false;
    }
  });
  return result;
}

const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined
const maxLength50 = maxLength(50);
const maxLength100 = maxLength(100);
const maxLength20 = maxLength(20);

const validate = (values, props) => {
  const errors = {}
  const existingNames = props.sapBPcontacts.map(c => c.name);
  if (existingNames.indexOf(values.new_name) !== -1) {
    errors.new_name = 'Name must be different from existing ones';
  }

  if (values.new_tel) {
    if (!checkChar(values.new_tel, charValidate.aCharms)) {
      errors.new_tel = 'Invalid character in this field (' + charValidate.aCharms + ')';
    }
  }

  const inputChecks = ['new_name','new_position','new_email','new_tel'];
  inputChecks.forEach((inp) => {
    const whitelisted = charValidate.allInputRegex.test(values[inp]);
    if (!whitelisted) { errors[inp] = 'Only A-Z a-z 0-9 $ + . / ( ) # & , characters allowed on this field'}
  });

  return errors;
}

let GMPProfileFormNewContact = props => {
  console.log('props-', props);
  const { handleSubmit, classes, bp, cancelProfileView, denyReviewNewContact, sapBPGen, sapBPBillingAddresses, sapBPShippingAddresses, sapBPcontacts, reviewModeFormNewContact, cancelReviewNewContact, states, countries, gsttypes, reviewValuesNewContact } = props;
  const sbp = sapBPBillingAddresses[0];
  const handleDenyNewContact = () => {
    denyReviewNewContact({
      id: bp.id,
      notes: reviewValuesNewContact.notes
    });
    cancelProfileView();
  }
  let billingAddress = <div></div>;
  if (sapBPBillingAddresses.length > 0) {
    let country = '';
    countries.forEach(function(c) {
      if (c.id == sbp.country) {
        country = c.name;
        return;
      }
    });
    let stateName = '';
    if (country && states.hasOwnProperty(sbp.country)) {
      states[sbp.country].forEach(function(s) {
        if (s.id == sbp.state) {
          stateName = s.name;
          return;
        }
      });
    }

    let billRegNo = '';
    if (sbp.gstregno) {
      billRegNo = sbp.gstregno;
    }
    let billRegType = '';
    if (sbp.gstregtype && gsttypes) {
      gsttypes.forEach((g) => {
        if (sbp.gstregtype == g.id) {
          billRegType = g.type;
        }
      });
    }

    billingAddress = (<div>
      <h4>Billing Address</h4>
      <div><strong>Name:</strong> {sbp.addressname}</div>
      <div><strong>Address 1:</strong> {sbp.address1}</div>
      <div><strong>Address 2:</strong> {sbp.address2}</div>
      <div><strong>City:</strong> {sbp.city}</div>
      {stateName && <div><strong>State:</strong> {stateName}</div>}
      <div><strong>Postal Code:</strong> {sbp.zipcode}</div>
      <div><strong>Country:</strong> {country}</div>
      {billRegNo && <div><strong>GSTReg.No.:</strong> {billRegNo}</div>}
      {billRegType && <div><strong>GSTReg.Type:</strong> {billRegType}</div>}
    </div>);
  }

  let shippingAddresses = <div></div>;
  if (sapBPShippingAddresses.length > 0) {
    shippingAddresses = sapBPShippingAddresses.map((sbp, index) => {
      let country = '';
      countries.forEach(function(c) {
        if (c.id == sbp.country) {
          country = c.name;
          return;
        }
      });
      let stateName = '';
      if (country && states.hasOwnProperty(sbp.country)) {
        states[sbp.country].forEach(function(s) {
          if (s.id == sbp.state) {
            stateName = s.name;
            return;
          }
        });
      }
      let shipRegNo = '';
      if (sbp.gstregno) {
        shipRegNo = sbp.gstregno;
      }
      let shipRegType = '';
      if (sbp.gstregtype && gsttypes) {
        console.log(sbp.gstregtype, gsttypes);
        gsttypes.forEach((g) => {
          if (sbp.gstregtype == g.id) {
            shipRegType = g.type;
            console.log('shipregtype',shipRegType);
          }
        });
      }
      return (
        <div key={'sa-' + index}>
          <div><strong>Name:</strong> {sbp.addressname}</div>
          <div><strong>Address 1:</strong> {sbp.address1}</div>
          <div><strong>Address 2:</strong> {sbp.address2}</div>
          <div><strong>City:</strong> {sbp.city}</div>
          {stateName && <div><strong>State:</strong> {stateName}</div>}
          <div><strong>Postal Code:</strong> {sbp.zipcode}</div>
          <div><strong>Country:</strong> {country}</div>
          {shipRegNo && <div><strong>GSTReg.No.:</strong> {shipRegNo}</div>}
          {shipRegType && <div><strong>GSTReg.Type:</strong> {shipRegType}</div>}
        </div>);
      }
    )
  }

  let contacts = <div></div>;
  if (sapBPcontacts.length > 0) {
    contacts = sapBPcontacts.map((sbp, index) =>
      <div key={'sc-' + index} style={{marginBottom:'20px'}}>
        <div><strong>Name:</strong> {sbp.name}</div>
        <div><strong>Position:</strong> {sbp.position}</div>
        <div><strong>Telephone:</strong> {sbp.telephone}</div>
        <div><strong>Email:</strong> {sbp.email}</div>
      </div>
    )
  }

  const wt = sapBPGen.wtax_info;
  let wtaxcodes = null;
  if (wt) {
    if (wt.wtcode) {
      wtaxcodes = wt.wtcode.map((wx) => {
          let wtdescription = '';
          if (props.owhtopts) {
            props.owhtopts.forEach((wtd) => {
              if (wx === wtd.WTCode) {
                wtdescription = wtd.WTName;
              }
            });
          }
          return <div key={wtdescription}>{wtdescription}</div>;
      });
    }
  }

  let form = (<div>
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <Settings />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              GMP BP Profile : <span style={{color:'#559dcb'}}>EXISTING BP</span>
            </h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <div><strong>Card Code</strong> {sapBPGen.card_code}</div>
                <div><strong>Company Name:</strong> {sapBPGen.company_name}</div>
                <div><strong>Database:</strong> {bp.DB}</div>
                {sapBPGen.rfc_curp && <div><strong>RFC/CURP:</strong> {sapBPGen.rfc_curp}</div>}
                {sapBPGen.nit && <div><strong>NIT:</strong> {sapBPGen.nit}</div>}
                {sapBPGen.panno && <div><strong>PANNo:</strong> {sapBPGen.panno}</div>}
                {sapBPGen.tinno && <div><strong>GSTIN:</strong> {sapBPGen.tinno}</div>}
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
              <div><strong>General Telephone:</strong> {sapBPGen.gen_tel}</div>
              <div><strong>General Fax:</strong> {sapBPGen.gen_fax}</div>
              <div><strong>Invoice Number:</strong> {bp.invoice_num ? bp.invoice_num : 'n/a'}</div>
              <div><strong>Currency:</strong> {sapBPGen.currency}</div>
              </GridItem>
            </GridContainer>
            {(bp.DB === 'NIN_LIVE' || bp.DB === 'NIN_TEST') && <hr />}
            {
              ((bp.DB === 'NIN_LIVE' || bp.DB === 'NIN_TEST') && wt) &&
              <GridContainer>
                <hr />
                <GridItem xs={12} sm={12} md={6}>
                  <div><strong>Subject to Withholding Tax</strong> {wt.wtliable === 'Y' ? 'Yes' : 'No'}</div>
                  <div><strong>Certificate No.:</strong> {wt.certificate}</div>
                  <div><strong>Expiration Date:</strong> {wt.expiration}</div>
                  <div><strong>NI Number:</strong> {wt.ninumber}</div>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                <div><strong>Accrual Criteria:</strong> {wt.accrual === 1 ? 'Accrual' : 'Cash'}</div>
                <div><strong>Assessee Type:</strong> {wt.assessee === 1 ? 'OTHERS' : 'COMPANY'}</div>
                <div><strong>Threshold:</strong> {wt.threshhold === 1 ? 'YES' : 'NO'}</div>
                <div><strong>Surcharge:</strong> {wt.surcharge === 1 ? 'YES' : 'NO'}</div>
                <div><strong>WTax Codes Allowed:</strong>{wtaxcodes}</div>
                </GridItem>
              </GridContainer>
            }

            <hr />
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                {billingAddress}
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
              <h4>Shipping Address</h4>
                {shippingAddresses}
              </GridItem>
            </GridContainer>
            <hr />
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
              <h4>Contact</h4>
                {contacts}
              </GridItem>
            </GridContainer>

            <hr />

            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <div className="textarea-14" style={style1}><Field name="notes" component={renderTextAreaField} label="Notes" /></div>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}></GridItem>
            </GridContainer>

            <GridContainer>
              <div style={{marginTop:'20px'}} className="separate-btns">
              <GridItem xs={12} sm={12} md={12}>
                <Button
                  color="success"
                  type="button"
                  className={classes.updateProfileButton}>
                  SELECT THIS BP
                </Button>
                <Button
                  color="rose"
                  className={classes.updateProfileButton}
                  onClick={() => {
                    props.cancelGMPBPExistingPreview();
                  }}
                  type="button"
                >
                  CHANGE BP
                </Button>
              </GridItem>
              </div>
            </GridContainer>
            <Clearfix />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  </div>
);
  if (reviewModeFormNewContact) {
    form = <div>
      <GMPProfileReviewNewContact
        bp={props.reviewValuesNewContact}
        company_name={sapBPGen.company_name}
        wtax_info={wt}
        cancelReviewNewContact={props.cancelReviewNewContact}
        approveReviewNewContact={props.approveReviewNewContact}
        cancelProfileView={props.cancelProfileView}
      /></div>
  }
  return form;

}

GMPProfileFormNewContact = reduxForm({
  form: 'gmpProfileFormNewContact',
  enableReinitialize: true,
  validate
})(GMPProfileFormNewContact);

const mapStateToProps = (state, props) => {
  const countries = state.common.countries;
  const states = state.common.states;
  const selector = formValueSelector('gmpProfileFormNewContact');
  const values = selector(state, 'new_name', 'new_position', 'new_tel', 'new_email', 'notes');
  return {
    gsttypes: state.gmp.gsttypes,
    cardCode: state.common.sapBPGen.card_code,
    owhtopts: state.common.addinfo.owhtopts,
    initialValues: props.bp,
    reviewValuesNewContact: values,
    countries,
    states
  }
}

export default connect(mapStateToProps,null)(withStyles(userProfileStyles)(GMPProfileFormNewContact));
