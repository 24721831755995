import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import withStyles from "@material-ui/core/styles/withStyles";
import styles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from "components/CustomButtons/Button.jsx";
import GINSelect from './GINselect';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import activeDBs from "shared/activeDBs";
import Delete from '@material-ui/icons/Delete';
import Build from '@material-ui/icons/Build';
import CardIcon from "components/Card/CardIcon.jsx";
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';

import { TableSortLabel, CardContent, Card, Dialog, DialogTitle, DialogContent, ListItem, DialogActions } from '@material-ui/core';

import _ from 'lodash';
import GINask2 from './GINask2';

const DBlabel = props => {
  // props.name
  let backgroundColor = '';
  activeDBs.forEach((db) => {
    if (db.name === props.name) {
      backgroundColor = db.color;
    }
  });

  if (backgroundColor) {
    return (
      <div style={{backgroundColor}} className="db-pill">
        {props.name.split('_')[0]}
      </div>
    );
  }
  return null;
}

class GINbom extends Component {
  state = {
    showModal: false,
    deleteModal: false,
    modalError: '',
    warehouse: 'MAIN', // default
    isEditing: false,
    orderBy: 'db',
    direction: 'desc'
  }

  handleModalClose = () => this.setState({
    showModal: false,
    db: '',
    base: '',
    qty: '',
    description: '',
    warehouse: 'MAIN',
    code: ''
  });

  submit(){
    console.log('you submitted on BOM and this is the state', this.state);
    this.setState({
      modalError: ''
    })

    try{
      const db = this.state.db;
      if(!this.state.db || _.indexOf(this.props.dboptions, this.state.db) === -1){
        throw Error(`Please select valid DB.`);
      }

      // if(!this.state.code || !_.find(this.props.options[this.state.db].rms, {ItemCode: this.state.code})){
      //   throw Error('Please enter valid code.');
      // }

      if (this.state.qty > 1000000) {
        throw Error('Please enter qty. below 1,000,000');
      }

      if (this.state.base > 1000000) {
        throw Error('Please enter valid item base qty. below 1,000,000');
      }

      if(!this.state.qty || Number.isNaN(parseFloat(this.state.qty))){
        throw Error('Please enter valid qty.');
      }

      if(!this.state.warehouse || _.indexOf(this.props.options[this.state.db].rm_warehouse, this.state.warehouse) === -1){
        throw Error('Please enter valid warehouse.');
      }

      if(!this.state.description){
        throw Error('Please enter valid description.');
      }

      if(!this.state.base || Number.isNaN(parseFloat(this.state.qty))){
        throw Error('Please enter valid item base quantity.');
      }

      if (this.state.isEditing) {
        const index = _.indexOf(this.props.rms[this.state.db], this.state.code);

        if(index === -1){
          throw Error('Something went wrong. Please try again.');
        }

        console.log('editing');
        this.props.updateGINEdit({
          db: this.state.db,
          field: 'BOMItemBaseQty',
          value: this.state.base
        })

        let rmsd = _.cloneDeep(this.props.rmsd);
        rmsd[db] = _.map(this.props.rmsd[this.state.db], (v, i) => i === index ? this.state.description : v)

        let rmsq = _.cloneDeep(this.props.rmsq);
        rmsq[db] = _.map(this.props.rmsq[this.state.db], (v, i) => i === index ? this.state.qty : v)

        let rmsw = _.cloneDeep(this.props.rmsw);
        rmsw[db] = _.map(this.props.rmsw[this.state.db], (v, i) => i === index ? this.state.warehouse : v)

        this.props.updateGINMultiple({
          rmsd, rmsq, rmsw
        });

      } else {
        if(this.state.code) {
          if (this.props.rms) {
            let codeExists = false;
            if (this.props.rms[db]) {
              this.props.rms[db].forEach(code => {
                if (code === this.state.code) {
                  codeExists = true;
                }
              });
            }

            if (codeExists) {
              throw Error('RM code already exists, please choose another one.');
            }
          }
        }

        this.props.updateGINEdit({
          db: this.state.db,
          field: 'BOMItemBaseQty',
          value: this.state.base
        })

        let rms = _.cloneDeep(this.props.rms);
        rms[db] = _.concat(rms[db], [this.state.code])

        let rmsd = _.cloneDeep(this.props.rmsd);
        rmsd[db] = _.concat(rmsd[db], [this.state.description])

        let rmsq = _.cloneDeep(this.props.rmsq);
        rmsq[db] = _.concat(rmsq[db], [this.state.qty])

        let rmsw = _.cloneDeep(this.props.rmsw);
        rmsw[db] = _.concat(rmsw[db], [this.state.warehouse])

        console.log(rms, rmsd, rmsq, rmsw);
        this.props.updateGINMultiple({
          rms, rmsd, rmsq, rmsw
        });
      }

      this.setState({
        db: '',
        code: '',
        showModal: false,
        description: '',
        base: '',
        qty: '',
        warehouse: 'MAIN',
        isEditing: false
      })

    }catch(e){
      this.setState({
        modalError: e.message
      })
    }
  }

  delete(){
    try{
      const db = this.state.db;
      const index = _.indexOf(this.props.rms[this.state.db], this.state.code);

      if(index === -1){
        throw Error('Something went wrong. Please try again.');
      }

      let rms = _.cloneDeep(this.props.rms);
      rms[db] = rms[db].filter((rms,i) => {
        if (i !== index) {
          return true;
        }
      });

      let rmsd = _.cloneDeep(this.props.rmsd);
      rmsd[db] = rmsd[db].filter((rmsd,i) => {
        if (i !== index) {
          return true;
        }
      });

      let rmsq = _.cloneDeep(this.props.rmsq);
      rmsq[db] = rmsq[db].filter((rmsq,i) => {
        if (i !== index) {
          return true;
        }
      });

      let rmsw = _.cloneDeep(this.props.rmsw);
      rmsw[db] = rmsw[db].filter((rmsw,i) => {
        if (i !== index) {
          return true;
        }
      });

      this.props.updateGINMultiple({
        rms, rmsd, rmsq, rmsw
      });

      this.setState({
        db: '',
        code: '',
        showModal: false,
        description: '',
        qty: '',
        warehouse: 'MAIN',
        isEditing: false
      })
    }catch(e){
      this.setState({
        modalError: e.message
      })
    }
  }

  renderDBModal(){
    return (
      <Dialog
        onClose={this.handleModalClose}
        open={this.state.showModal}
        keepMounted
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle style={{paddingBottom: 0}} onClose={this.handleModalClose}>
          Please select DB
          <div className="close-btn">
            <IconButton aria-label="Close" onClick={this.handleModalClose}>
              <Close />
            </IconButton>
          </div>
        </DialogTitle>

        <DialogContent style={{overflowY:'unset'}}>
          <GINSelect
            label="Database"
            options={this.props.dboptions}
            choose={v => this.setState({db: v, base: this.props.BOMItemBaseQty[v] || undefined})}
            name="db"
            indx=""
            desc=""
            default={''}
            open={this.state.showModal ? true : false}
          />
        </DialogContent>
      </Dialog>
    )
  }

  renderCodeLiveSearch(){
    if(!this.state.codeSearch){
      return;
    }

    const regex = new RegExp(this.state.codeSearch, 'gi');
    const data = _.filter(this.props.options[this.state.db].rms, v => regex.test(v.ItemCode) || regex.test(v.ItemName));

    return (
      <div style={{overflowY: 'scroll', height: 300}}>
        {
          _.map(data, (v, i) => {
            if (this.props.rms[this.state.db].indexOf(v.ItemCode) === -1) {
              return (
                <ListItem
                  key={i}
                  button
                  onClick={() => {
                    this.setState({code: v.ItemCode, description: v.ItemName})
                  }}
                  >
                    <div style={{fontSize:'12px'}}><strong>{v.ItemCode.indexOf('RM:') !== -1 ? v.ItemCode.split('RM:')[1] : v.ItemCode}</strong></div>
                    <div style={{marginLeft:'15px',fontSize:'12px'}}>{v.ItemName}</div>
                </ListItem>
              )
            } else {
              return null;
            }
          })
        }
      </div>
    )
  }

  renderCodeModal(){
    return (
      <Dialog
        onClose={this.handleModalClose}
        open={this.state.showModal}
        keepMounted
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle style={{paddingBottom: 0}} onClose={this.handleModalClose}>
          Please enter Raw Material Code
          <div className="close-btn">
            <IconButton aria-label="Close" onClick={this.handleModalClose}>
              <Close />
            </IconButton>
          </div>
        </DialogTitle>

        <DialogContent>
          <TextField
            style={{width: '100%'}}
            label="Raw Material Code"
            onChange={(e) => this.setState({codeSearch: e.target.value ? e.target.value.toUpperCase() : e.target.value })}
            margin="normal"
            autoFocus={true}
          />
          {
            this.renderCodeLiveSearch()
          }
        </DialogContent>
      </Dialog>
    )
  }

  renderModal(){
    if(!this.state.db){
      return this.renderDBModal();
    }

    if(!this.state.code){
      return this.renderCodeModal();
    }

    if(this.state.deleteModal){
      return;
    }

    const code = this.state.code.indexOf('RM:') !== -1 ? this.state.code.split('RM:')[1] : this.state.code;

    return (
      <Dialog
        onClose={this.handleModalClose}
        open={this.state.showModal}
        keepMounted
        maxWidth="md"
        fullWidth={true}
      >
        <DialogTitle onClose={this.handleModalClose}>
          Add new item
          <div className="close-btn">
            <IconButton aria-label="Close" onClick={this.handleModalClose}>
              <Close />
            </IconButton>
          </div>
        </DialogTitle>

        <DialogContent>
          <GridContainer>
            <GridItem xs={6}>
              <div className="colored-disabled">
              <TextField
                style={{width: '100%'}}
                label="Database"
                margin="normal"
                value={this.state.db}
                disabled
              /></div>
            </GridItem>
            <GridItem xs={6}>
              <div className="colored-disabled">
              <TextField
                disabled
                style={{width: '100%'}}
                label="Raw Material Code"
                margin="normal"
                value={code}
              /></div>
            </GridItem>
            <GridItem xs={12}>
              <div className="colored-disabled">
                <TextField
                  style={{width: '100%'}}
                  label="Description"
                  multiline
                  rows="2"
                  margin="normal"
                  disabled
                  value={this.state.description}
                /></div>
            </GridItem>
            <GridItem xs={4}>
              <TextField
                style={{width: '100%'}}
                label="Item Base Quantity"
                margin="normal"
                value={this.state.base}
                onChange={(e) => {
                  if (!isNaN(e.target.value)) {
                    this.setState({
                      base: Math.round(e.target.value)
                    })
                  }
                }}
              />
            </GridItem>
            <GridItem xs={4}>
              <TextField
                autoFocus={true}
                style={{width: '100%'}}
                label="Qty Required to produce"
                margin="normal"
                value={this.state.qty}
                onChange={(e) => {
                  if (!isNaN(e.target.value)) {
                    this.setState({
                      qty: Math.round(e.target.value)
                    })
                  }
                }}
              />
            </GridItem>
            <GridItem xs={4}>
              <FormControl margin={'normal'} style={{width: '100%'}}>
                <InputLabel htmlFor="warehouse">Warehouse</InputLabel>
                <Select
                  value={this.state.warehouse}
                  onChange={(e) => this.setState({warehouse: e.target.value})}
                  inputProps={{
                    name: 'warehouse',
                    id: 'warehouse',
                  }}
                >
                  {
                    _.map(this.props.options[this.state.db].rm_warehouse, (v, i) => (
                      <MenuItem key={i} value={v}>{v}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </GridItem>
          </GridContainer>

          <DialogActions style={{justifyContent: 'space-between'}}>
            <div>
              {
                !!this.state.modalError && (
                  <strong style={{color: 'red'}}>{this.state.modalError}</strong>
                )
              }
            </div>
              <Button onClick={this.submit.bind(this)} color="rose">
                Submit
              </Button>

          </DialogActions>
        </DialogContent>
      </Dialog>
    )
  }

  orderBy(data){
    return _.orderBy(data, this.state.orderBy, this.state.direction);
  }

  renderModalDelete(){
    const close = () => {
      this.setState({
        db: undefined,
        code: undefined,
        description: undefined,
        qty: undefined,
        warehouse: 'MAIN',
        isEditing: false,
        deleteModal: false,
        showModal: false
      })
    }

    if(!this.state.deleteModal){
      return;
    }

    return (
      <GINask2
        content={'Do you want to delete this Raw Material?'}
        command={this.delete.bind(this)}
        close={close.bind(this)}
        title="Are you Sure?"
      />
    )
  }

  checkRequirements = () => {
    let requiredDBs = [];
    this.props.dboptions.forEach(db => {
      if (this.props.rms[db]) {
        if (this.props.rms[db].length === 0) {
          requiredDBs.push(db);
        }
      }
    });
    console.log('required DBs', requiredDBs);
    return requiredDBs;
  }

  render() {
    console.log('this is the RM data',this.props.data);
    const requiredDBs = this.checkRequirements();
    return (
      <Card style={{marginTop:10,marginBottom:10}}>
        <CardContent>
          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
              <CardIcon color="rose">
                  <Build style={{fill:'white'}} />
              </CardIcon>
              <h1>BOM</h1>
              {requiredDBs.length > 0 && <div className="red-text" style={{fontSize:10,marginTop:10,marginLeft:20}}>{requiredDBs.map((rdb,i) => {
                return <span key={`rdb${i}`} className="separate-span">{rdb} </span>
              })} missing minimum requirements</div>}
            </div>
            <div>
              <Button
                color={'rose'}
                onClick={() => this.setState({modalError: '',showModal: true, codeSearch: ''})}
              >Add RM</Button>
            </div>
          </div>
        <div className="table-hover">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{paddingRight: 4, paddingLeft: 4}}>
                  <TableSortLabel
                    active={this.state.orderBy === 'db'}
                    direction={this.state.direction}
                    onClick={() => {
                      this.setState({
                        orderBy: 'db',
                        direction: this.state.orderBy === 'db' ? this.state.direction === 'desc' ? 'asc' : 'desc' : 'desc'
                      })
                    }}
                  >
                    DB
                  </TableSortLabel>
                </TableCell>
                <TableCell style={{paddingRight: 4}} align="right">
                  <TableSortLabel
                    style={{whiteSpace: 'nowrap'}}
                    active={this.state.orderBy === 'base'}
                    direction={this.state.direction}
                    onClick={() => {
                      this.setState({
                        orderBy: 'base',
                        direction: this.state.orderBy === 'base' ? this.state.direction === 'desc' ? 'asc' : 'desc' : 'desc'
                      })
                    }}
                    >
                    Item Base Quantity
                  </TableSortLabel>
                </TableCell>
                <TableCell style={{paddingRight: 4}} align="right">
                  <TableSortLabel
                    style={{whiteSpace: 'nowrap'}}
                    active={this.state.orderBy === 'code'}
                    direction={this.state.direction}
                    onClick={() => {
                      this.setState({
                        orderBy: 'code',
                        direction: this.state.orderBy === 'code' ? this.state.direction === 'desc' ? 'asc' : 'desc' : 'desc'
                      })
                    }}
                    >
                    Raw Material Code
                  </TableSortLabel>
                </TableCell>
                <TableCell style={{paddingRight: 4}} align="right">
                  <TableSortLabel
                    style={{whiteSpace: 'nowrap'}}
                    active={this.state.orderBy === 'description'}
                    direction={this.state.direction}
                    onClick={() => {
                      this.setState({
                        orderBy: 'description',
                        direction: this.state.orderBy === 'description' ? this.state.direction === 'desc' ? 'asc' : 'desc' : 'desc'
                      })
                    }}
                  >
                    Raw Material Description
                  </TableSortLabel>
                </TableCell>
                <TableCell style={{paddingRight: 4}} align="right">
                  <TableSortLabel
                    style={{whiteSpace: 'nowrap'}}
                    active={this.state.orderBy === 'qty'}
                    direction={this.state.direction}
                    onClick={() => {
                      this.setState({
                        orderBy: 'qty',
                        direction: this.state.orderBy === 'qty' ? this.state.direction === 'desc' ? 'asc' : 'desc' : 'desc'
                      })
                    }}
                  >
                    Qty Required to produce
                  </TableSortLabel>
                </TableCell>
                <TableCell style={{paddingRight: 4}} align="right">
                  <TableSortLabel
                    style={{whiteSpace: 'nowrap'}}
                    active={this.state.orderBy === 'warehouse'}
                    direction={this.state.direction}
                    onClick={() => {
                      this.setState({
                        orderBy: 'warehouse',
                        direction: this.state.orderBy === 'warehouse' ? this.state.direction === 'desc' ? 'asc' : 'desc' : 'desc'
                      })
                    }}
                  >
                    Raw Material Warehouse
                  </TableSortLabel>
                </TableCell>
                <TableCell style={{paddingLeft: 0, paddingRight: 0}} />
              </TableRow>
            </TableHead>
            <TableBody>
              {_.map(this.orderBy(this.props.data), (v, i) => (
                <TableRow hover onClick={(e) => {
                  e.preventDefault();

                  this.setState({
                    db: v.db,
                    code: v.code,
                    description: v.description,
                    qty: v.qty,
                    base: v.BOMItemBaseQty,
                    warehouse: v.warehouse,
                    isEditing: true,
                    showModal: true
                  })
                }} key={i}>
                  <TableCell style={{paddingRight: 4, paddingLeft: 4}} component="th" scope="row">
                    <DBlabel name={v.db} />
                  </TableCell>
                  <TableCell align="right" padding="none">
                    <div className="text-center">{v.BOMItemBaseQty}</div>
                  </TableCell>
                  <TableCell align="right" padding="none">{v.code ? v.code.indexOf('RM:') !== -1 ? v.code.split('RM:')[1] : v.code : v.code}</TableCell>
                  <TableCell align="right" padding="none">{v.description}</TableCell>
                  <TableCell align="right" padding="none">
                    <div className="text-center">{v.qty}</div>
                  </TableCell>
                  <TableCell align="right" padding="none">
                    <div className="text-center">{v.warehouse}</div>
                  </TableCell>
                  <TableCell style={{paddingLeft: 0, paddingRight: 0}}>
                    <div
                      className="red-text hovered"
                      style={{paddingTop:'10px',paddingBottom:'10px', marginRight: 30}}
                      onClick={() => {
                        this.setState({
                          db: v.db,
                          code: v.code,
                          description: v.description,
                          qty: v.qty,
                          warehouse: v.warehouse,
                          deleteModal: true,
                          showModal: false
                        })
                      }}>
                      <Delete />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
          {
            this.renderModal()
          }
          {
            this.renderModalDelete()
          }
        </CardContent>
      </Card>
    )
  }
}

const mapStateToProps = state => {
  // filter dbs to only Items that have BOM enabled - Both, Produced in House
  const dboptions = state.gin.openedDBs.filter(db => {
    if (state.gin.item_is[db] === 'Both' || state.gin.item_is[db] === 'Produced in House') {
      return true;
    }
    return false;
  });
  console.log(state.gin.options);

  return {
    openedDBs: state.gin.openedDBs,
    rms: state.gin.rms, // RAW MATERIAL CODE -- dropdown special select ** looks like Add Customer Specific Pricing's Customer input/dropdown get from options[db].rms (Objects array containing ItemCode, ItemName keys)
    rmsd: state.gin.rmsd, // RAW MATERIAL DESCRIPTION -- ( NOT INCLUDED AS EDITABLE ), value is the ItemName from ItemCode
    rmsq: state.gin.rmsq, // RAW MATERIAL QUANTITY -- input Integer
    rmsw: state.gin.rmsw, // RAW MATERIAL WAREHOUSE (default MAIN) -- dropdown select from options[db].rm_warehouse (only an array)
    itemIs: state.gin.item_is,
    dboptions,
    BOMItemBaseQty: state.gin.BOMItemBaseQty, // only 1 value per DB
    options: state.gin.options,
    data: _.flatten(_.filter(_.map(dboptions, (db) => _.map(state.gin.rms[db], (v, i) => ({
      db: db,
      code: v,
      description: state.gin.rmsd[db][i],
      qty: state.gin.rmsq[db][i],
      warehouse: _.toUpper(state.gin.rmsw[db][i]),
      BOMItemBaseQty: state.gin.BOMItemBaseQty[db]
    }))), v => v && v.length !== 0))
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateGINEdit: (data) => {
      dispatch(actions.updateGINEdit(data));
    },
    updateGINMultiple: (data) => {
      dispatch(actions.updateGINMultiple(data));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GINbom);
