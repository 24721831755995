import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import * as actions from '../../store/actions';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import Button2 from "components/CustomButtons/Button.jsx";
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Typography from '@material-ui/core/Typography';
import { ClipLoader } from 'react-spinners';
import Fade from '@material-ui/core/Fade';
import { debounce } from 'lodash';
import GINbox from 'containers/GIN/GINbox';
import GINnew from 'containers/GIN/GINnew';
import SweetAlert from "react-bootstrap-sweetalert";
import { portal_charvalidations } from 'shared/validation';

const DialogTitle = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500],
  },
}))(props => {
  const { children, classes, onClose, onNewItem, onLoadItem, openedDBs, showAddBtn, showLoadBtn, loading } = props;
  const opacity = (openedDBs.length > 0 && openedDBs.indexOf(props.db) === -1 && showAddBtn) ? 1 : 0;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <span style={{fontSize:'18px'}}>Search Items in <strong>{props.db}</strong> database</span>
    <span className={opacity ? '' : 'disabled-opacity'}>
      <Button2
            disabled={loading ? true : false}
            color={loading ? 'white' : 'rose'}
            style={{marginLeft:'20px', opacity}}
            onClick={onNewItem}
          >{loading ? 'Searching for Similar Item Codes' : 'Add New Item to DB'}</Button2>
    </span>
    {showLoadBtn && <Button2
          color="rose"
          style={{marginLeft:20}}
          onClick={onLoadItem}
        >Load Item</Button2>
    }
      {onClose ? (
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit,
  },
}))(MuiDialogActions);

class GINmodal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      itemcode: '',
      loadingHover: false,
      selectedItem: '',
      clickedItem: '',
      newItem: false,
      showAddBtn: true,
      alertMsg: '',
      errors: ''
    }

    this.searchAPIDebounced = debounce(this.searchAPIDebounced, 500);
  }

  showAlert = (alertMsg) => {
    this.setState({alertMsg});
  }

  hideAlert = () => {
    this.setState({
      alertMsg: ''
    })
  }

  checkIfExists = () => {
    if (this.state.itemcode) {
      if (this.props.itemList) {
        if (this.props.itemList.length === 1) {
          console.log('thisitemlist', this.props.itemList[0]);
          if (this.props.itemList[0]) {
            if (this.props.itemList[0].label) {
              const itemcode = this.props.itemList[0].label.split(' | ')[0];
              const itemc = itemcode.split('_')[0]
              if (itemc === this.state.itemcode) {
                return true;
              }
            }
          }
        } else if (this.props.itemList.length > 1) {
          let itemsObj = {};
          this.props.itemList.forEach(item => {
            if (item.label) {
              const itemcode = item.label.split(' | ')[0];
              const itemc = itemcode.split('_')[0];
              itemsObj[itemc] = 1;
              console.log('check item',itemcode, itemc);
            }
          });
          if (itemsObj.hasOwnProperty(this.state.itemcode)) {
            return true;
          }
        }
      }
    }
  }

  checkLength = () => {
    if (this.state.itemcode) {
      if (this.state.itemcode.length > 15) {
        this.setState((prevState) => ({
          errors: prevState.errors + 'Maximum character length is 15. '
        }));
      }
    }
  }

  checkChars = () => {
    if (this.state.itemcode) {
      const forTest = portal_charvalidations.aCharms.split('');
      const valueArr = this.state.itemcode.split('');
      let hasChar = false;
      forTest.forEach((tchar) => {
        valueArr.forEach((vchar) => {
          if (tchar === vchar) {
            hasChar = true;
          }
        });
      });
      if (hasChar) {
        this.setState((prevState) => ({
          errors: prevState.errors + 'Characters '+portal_charvalidations.aCharms+' are not allowed. '
        }));
      }
    }
  }

  checkValidations = () => {
    this.checkLength();
    this.checkChars();
  }

  handleNewItem = () => {
    if (this.state.itemcode) {
      if (!this.state.errors) {
        // add new item
        let data = {
          db: this.props.db,
          itemCodes: this.state.itemcode,

          brand: 'n/a',
          subgroup: 'n/a',
          base_item_description: 'n/a',
          product_type: 'n/a',
          rbo: 'n/a',

          sales_unit: 'n/a',
          basic_packaging_unit: 'n/a',
          qty_per_unit: 'n/a',

          ilength: 'n/a',
          width: 'n/a',
          weight: 'n/a',

          bmasterarray: {},
          smasterarray: {},

          cterm: '',
          ccur: '',
          cstart: '',
          ccode: '',
          cend: '',
          cprice: '',

          cqtys: [],
          cterms: [],
          ccurs: [],
          ccodes: [],
          cprices: [],
          cstarts: [],
          cends: [],

          gterm: '',
          gstart: '',
          gend: '',
          gcur: '',
          gcode: '',
          gprice: '',

          gterms: [],
          gprices: [],
          gstarts: [],
          gends: [],
          gcurrs: [],
          gcodes: [],
          gqtys: [],
          preferredVendor: '',

          variants: [],
          variantsDesc: [],
          variantsDeactivate: [],
          deactivateds: [],

          rms: [],
          rmsd: [],
          rmsq: [],
          rmsw: [],
          specialmoc: []
        };
        this.props.submitNewItem(data);
        this.props.ginNewItemAdd(this.props.db);
      }
    } else {
      // alert
      this.setState({
        alertMsg: 'Please enter an Item Name'
      });
    }
  }

  handleClose = (db) => {
    console.log('closing ginmodal');
    this.props.closeModal();
    this.props.emptyItemByDB(db);
    this.props.removeFromOpenedDBsList(db);
    //this.props.compareAll();
  };

  handleSubmit = e => {
    e.preventDefault();
    const val = e.target.value.toUpperCase();
    this.setState({ itemcode: val });
    if (val.length > 1 && !this.state.errors) {
      this.searchAPIDebounced(val);
    }
  }

  searchAPIDebounced = (val) => {
    this.setState({
      selectedItem: ''
    }, () => {
      this.props.fetchItemList(val, this.props.db);
    });
  }

  hideButton = () => {
    this.setState({
      showAddBtn: false
    });
  }

  onLoadItem = () => {
    const dat = this.props.lockStatus;
    console.log('lock status', dat);
    if (dat) {
      if (dat.status === 'NOT LOCKED') {
        if (dat.itemcode) {
          const itemcode = dat.itemcode.split('_')[0];
          console.log('locking ', dat.db, itemcode);
          this.props.updateItemLock(dat.db, itemcode, 'LOCK');
        }
      } else if (dat.status === 'LOCKED') {
        return;
      }
    }
    this.props.closeModal();
  }

  handleItemSelect = (e) => {
    if (this.state.selectedItem == e) {
      this.onLoadItem();
    } else {
      this.setState({
        loadingHover: true,
        selectedItem: e
      });
      console.log('FETCHING ', e);
      var t = e.split(' | ');
      var metflag;
      for (var a = 0; a < t[0].length; a++) {
          if (t[0].charAt(a) == '_') {
              metflag = 1;
          }
      }
      if (metflag == 1) {
          var m = t[0].split('_');

          var vless = m[0];
          var name = t[1];
      } else {
          var vless = t[0]; //t[0] = Item Code
      }

      var itemget = t[0];
      this.props.fetchItem(vless, itemget, this.props.db);
    }
  }

  onFieldNameChange = e => {
    //const val = e.target.value.toUpperCase();
    const val = e.target.value || e.target.value === 0 ? e.target.value.replace(/[^0-9a-zA-Z-\\\/]/g, "").toUpperCase() : '';
    this.setState({
      itemcode: val,
      errors: ''
    }, () => {
      this.checkValidations();
    });
    if (e.target.value.length > 1) {
      this.searchAPIDebounced(val);
    }
  }

  componentDidMount() {
    console.log(this.textInput);
    this.setState({
      newItem: false,
      showAddBtn: true
    });
  }

  checkLock = () => {
    const lockData = this.props.lockStatus;
    if (lockData) {
      if (lockData.status === 'LOCKED') {
        if (lockData.itemcode === this.props.itemCodes[this.props.db]) {
          if (lockData.db === this.props.db) {
            this.handleClose(this.props.db);
          }
        }
      }
    }
  }

  onNewItem = () => {
    if (!this.props.loading && !this.state.errors) {
      if (!this.checkIfExists()) {
        if (this.state.itemcode) {
          this.handleClose(this.props.db);
        }
        this.handleNewItem();
      } else {
        this.setState((prevState) => ({
          errors: prevState.errors + 'Itemcode already Exists. '
        }));
      }
    }
  }

  render() {
    let list = null;
    let coloredSelect = null;
    console.log('itemlist',this.props.itemList, this.props.listedWithVariants);
    console.log('GINmodal st pr', this.state, this.props);

    let removeVariantless = [];
    if (this.props.itemList) {
      if (Array.isArray(this.props.itemList)) {
        if (this.props.itemList.length > 0) {
          this.props.itemList.forEach(il => {
            if (il.label) {
              const itemcode = il.label.split(' | ')[0];
              if (itemcode.indexOf('_') !== -1) {
                const variantless = itemcode.split('_')[0];
                if (removeVariantless.indexOf(variantless) === -1) {
                  removeVariantless.push(variantless);
                }
              }
            }
          })
          console.log('itemlist to remove', removeVariantless);
          list = this.props.itemList.map((item, key) => {
            if (item.gin) return null;
            if (this.state.selectedItem) {
              if (item.label == this.state.selectedItem) {
                coloredSelect = {backgroundColor:'gray',color:'white',fontSize:'12px !important'};
              } else {
                coloredSelect = {fontSize:'12px !important'};
              }
            }
            const itemCodeArr = item.label.split(' | ');
            const itemCode = itemCodeArr[0];
            const itemCodeDesc = itemCodeArr[1];
            return (
              <ListItem
                key={'key'+key}
                button
                onClick={event => {
                  if (!item.gin) {
                    this.handleItemSelect(item.label)}
                  }
                }
                style={coloredSelect}
                >
                <div><strong>{itemCode}</strong></div><br />
                <div style={{marginLeft:'15px'}}>{itemCodeDesc}</div>
              </ListItem>
            )
          });
        }
      }
    }

    const db = this.props.db;
    console.log('Modal Status', this.props.openModal, this.state.newItem, (!this.props.loading && this.state.newItem === true));

    let miniModalAlert = '';
    if (this.state.alertMsg) {
      miniModalAlert = (<SweetAlert
           warning
           style={{ display: "block" }}
           title="Sorry!"
           onConfirm={this.hideAlert}
           >
           {this.state.alertMsg}
           </SweetAlert>);
    }

    let lockMsg = '';
    const lockData = this.props.lockStatus;
    if (lockData) {
      if (lockData.status === 'LOCKED') {
        lockMsg = `${lockData.db} ${lockData.itemcode} is locked by ${lockData.name}`;
      }
    }

    return (
      <div id="gin-modal">
        {miniModalAlert}
        <Dialog
          onClose={() => {
            this.checkLock();
            this.props.closeModal();
          }}
          aria-labelledby="customized-dialog-title"
          open={this.props.openModal}
          fullScreen={true}
          fullWidth={true}
        >
          <DialogTitle id="customized-dialog-title"
            onClose={() => {
              this.checkLock();
              this.props.closeModal();
            }}
            db={this.props.db}
            onNewItem={this.onNewItem}
            onLoadItem={this.onLoadItem}
            loading={this.props.loading}
            openedDBs={this.props.openedDBs}
            showAddBtn={this.state.showAddBtn}
            showLoadBtn={this.state.selectedItem}
          >
          </DialogTitle>
          <div className="item-input-margin" style={{backgroundColor:'#fcfcfc'}}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={1}></GridItem>
                  {
                    this.state.newItem === false &&
                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="Enter Item"
                        id="item_name"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          onChange: this.onFieldNameChange,
                          value: this.state.itemcode,
                          autoFocus: true,
                          role: 'presentation',
                          autoComplete: 'nope'
                        }}
                      />
                      {this.state.errors && <div className="red-text" style={{marginTop:'10px'}}>{this.state.errors}</div>}
                      <div style={{height:'400px',overflowY:'scroll'}}>
                        <List component="nav">
                          {list}
                        </List>
                      </div>
                    </GridItem>
                  }
                  <GridItem xs={12} sm={12} md={6}>
                    <div style={{textAlign:'center',marginTop:'10px', height:'400px', overflowY:'scroll'}}>
                      {this.props.loading &&
                        <Card>
                          <div style={{padding:'20px'}}>
                            <div>Loading <strong>{this.state.selectedItem.split(' | ')[1]}</strong></div>
                            <div className="text-center" style={{marginTop:20}}><ClipLoader /></div>
                          </div>
                        </Card>
                      }
                      {(!this.props.loading && this.state.selectedItem) &&
                      <div>
                      <GINbox
                        lockMsg={lockMsg}
                        reviewMode={true}
                        db={{name:db}}
                        setModal={this.openModal}
                        currentItem={this.props.itemCodes[db] ? this.props.itemCodes[db] : ''}
                        baseItemDescription={this.props.baseItemDescription[db]}
                        baseItemDescriptions={this.props.baseItemDescription}
                        productType={this.props.productType[db]}
                        productTypes={this.props.productType}
                        rbo={this.props.rbo[db]}
                        rbos={this.props.rbo}
                        rboNames={this.props.rboNames}
                        brand={this.props.brand ? this.props.brand[db] : ''}
                        brandSimilars={this.props.brand}
                        subgroup={this.props.subgroup ? this.props.subgroup[db] : ''}
                        subgroupSimilars={this.props.subgroup}
                        brandNames={this.props.brandNames}
                        subgroupNames={this.props.subgroupNames}
                        ipackagingtypes={this.props.ipackagingtypes[db]}
                        ipackagingtypesSimilars={this.props.ipackagingtypes}
                        salesUnit={this.props.sales_unit[db]}
                        salesUnits={this.props.sales_unit}
                        basicPackagingUnit={this.props.basic_packaging_unit[db]}
                        basicPackagingUnits={this.props.basic_packaging_unit}
                        qtyPerUnit={this.props.qty_per_unit[db]}
                        qtyPerUnits={this.props.qty_per_unit}
                        ilength={this.props.ilength[db]}
                        ilengths={this.props.ilength}
                        width={this.props.width[db]}
                        widths={this.props.width}
                        weight={this.props.weight ? this.props.weight[db] : ''}
                        weights={this.props.weight}
                        picks={this.props.picks ? this.props.picks[db] : ''}
                        picksSimilars={this.props.picks}
                        variants={this.props.variants ? this.props.variants[db] : []}
                        variantsDesc={this.props.variantsDesc ? this.props.variantsDesc[db] : []}
                        cprice={this.props.cprice ? this.props.cprice[db] : null}
                        cterm={this.props.cterm ? this.props.cterm[db] : null}
                        ccur={this.props.ccur ? this.props.ccur[db] : null}
                        cstart={this.props.cstart ? this.props.cstart[db] : null}
                        cend={this.props.cend ? this.props.cend[db] : null}
                        cqtys={this.props.cqtys ? this.props.cqtys[db] : null}
                        cterms={this.props.cterms ? this.props.cterms[db] : null}
                        ccurs={this.props.ccurs ? this.props.ccurs[db] : null}
                        cprices={this.props.cprices ? this.props.cprices[db] : null}
                        cstarts={this.props.cstarts ? this.props.cstarts[db] : null}
                        cends={this.props.cends ? this.props.cends[db] : null}
                        bmasterarray={this.props.bmasterarray ? this.props.bmasterarray[db] : []}
                        smasterarray={this.props.smasterarray ? this.props.smasterarray[db] : []}
                        rms={this.props.rms ? this.props.rms[db] : []}
                        rmsd={this.props.rmsd ? this.props.rmsd[db] : []}
                        rmsq={this.props.rmsq ? this.props.rmsq[db] : []}
                        rmsw={this.props.rmsw ? this.props.rmsw[db] : []}
                        gprice={this.props.gprice ? this.props.gprice[db] : []}
                        gstart={this.props.gstart ? this.props.gstart[db] : []}
                        gend={this.props.gend ? this.props.gend[db] : []}
                        gcur={this.props.gcur ? this.props.gcur[db] : []}
                        gterm={this.props.gterm ? this.props.gterm[db] : []}
                        gterms={this.props.gterms ? this.props.gterms[db] : []}
                        gstarts={this.props.gstarts ? this.props.gstarts[db] : []}
                        gends={this.props.gends ? this.props.gends[db] : []}
                        gprices={this.props.gprices ? this.props.gprices[db] : []}
                        gcurrs={this.props.gcurrs ? this.props.gcurrs[db] : []}
                        gqtys={this.props.gqtys ? this.props.gqtys[db] : []}
                        mocs={this.props.mocs ? this.props.mocs[db] : []}
                        specialmoc={this.props.specialmoc ? this.props.specialmoc[db] : []}
                        BOMItemBaseQty={this.props.BOMItemBaseQty ? this.props.BOMItemBaseQty[db] : []}
                        BOMItemBaseQtys={this.props.BOMItemBaseQty}
                        comp={this.props.comp ? this.props.comp : null}
                        itemIs={this.props.itemIs ? this.props.itemIs[db] : []}
                        itemIsSimilars={this.props.itemIs}
                        openModal={true}
                        gst_relevant={this.props.gst_relevant[db]}
                        material_type={this.props.material_type[db]}
                        hsn_code={this.props.hsn_code[db]}
                        gst_tax_category={this.props.gst_tax_category[db]}
                        service_acct_code={this.props.service_acct_code[db]}
                      />
                      </div>}
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={1}></GridItem>
                </GridContainer>
          </div>
          <DialogActions>
            <Button2
              size="lg"
              disabled={this.props.loading ? true : false}
              color={this.props.loading ? 'white' : 'rose'}
              style={{marginLeft:20, opacity: (this.props.openedDBs.length > 0 && this.props.openedDBs.indexOf(this.props.db) === -1 && this.state.showAddBtn) ? 1 : 0}}
              onClick={this.onNewItem}
            >{this.props.loading ? 'Searching for Similar Item Codes' : 'Add New Item to DB'}</Button2>
            <Button onClick={() => {
              this.checkLock();
              this.props.closeModal();
            }} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.gin.errorMessage,
    itemList: state.gin.itemSearchResults,
    loading: state.gin.loading,
    itemCodes: state.gin.itemCodes,
    baseItemDescription: state.gin.base_item_description,
    productType: state.gin.product_type,
    rbo: state.gin.rbo,
    rboNames: state.gmp.rbos,
    brandNames: state.gin.brands,
    subgroupNames: state.gin.subgroups,
    brand: state.gin.brand,
    subgroup: state.gin.subgroup,
    sales_unit: state.gin.sales_unit,
    ipackagingtypes: state.gin.ipackagingtypes,
    basic_packaging_unit: state.gin.basic_packaging_unit,
    qty_per_unit: state.gin.qty_per_unit,
    ilength: state.gin.ilength,
    width: state.gin.width,
    weight: state.gin.weight,
    picks: state.gin.picks,
    variants: state.gin.variants,
    variantsDesc: state.gin.variantsDesc,
    cprice: state.gin.cprice,
    cterm: state.gin.cterm,
    ccur: state.gin.ccur,
    cstart: state.gin.cstart,
    cend: state.gin.cend,
    cqtys: state.gin.cqtys,
    cterms: state.gin.cterms,
    ccurs: state.gin.ccurs,
    cprices: state.gin.cprices,
    cstarts: state.gin.cstarts,
    cends: state.gin.cends,
    bmasterarray: state.gin.bmasterarray,
    smasterarray: state.gin.smasterarray,
    rms: state.gin.rms,
    rmsd: state.gin.rmsd,
    rmsq: state.gin.rmsq,
    rmsw: state.gin.rmsw,
    comp: state.gin.comp,
    itemIs: state.gin.item_is,
    gcur: state.gin.gcur,
    gstart: state.gin.gstart,
    gend: state.gin.gend,
    gprice: state.gin.gprice,
    gterm: state.gin.gterm,
    gcurrs: state.gin.gcurrs,
    gstarts: state.gin.gstarts,
    gends: state.gin.gends,
    gprices: state.gin.gprices,
    gterms: state.gin.gterms,
    gqtys: state.gin.gqtys,
    mocs: state.gin.mocs,
    gst_relevant: state.gin.gst_relevant,
    material_type: state.gin.material_type,
    hsn_code: state.gin.hsn_code,
    gst_tax_category: state.gin.gst_tax_category,
    service_acct_code: state.gin.service_acct_code,
    BOMItemBaseQty: state.gin.BOMItemBaseQty,
    openedDBs: state.gin.openedDBs,
    editMode: state.gin.editMode,
    lockStatus: state.gin.lockStatus
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchItem: (vless, itemcode, db) => {
      console.log(vless, itemcode, db);
      dispatch(actions.fetchItem(vless, itemcode, db));
    },
    emptyItemByDB: (db) => {
      dispatch(actions.emptyItemByDB(db));
    },
    compareAll: () => {
      dispatch(actions.fetchItemCompare());
    },
    removeFromOpenedDBsList: (db) => {
      dispatch(actions.removeFromOpenedDBsList(db));
    },
    submitNewItem: (data) => {
      dispatch(actions.addNewGINItem(data));
      dispatch(actions.addToOpenedDBsList(data.db));
    },
    ginNewItemAdd: (db) => {
      dispatch(actions.ginNewItemAdd(db));
    },
    clearGINmessage: () => {
      dispatch(actions.clearGINmessage());
    },
    updateItemLock: (db, itemcode, action) => {
      dispatch(actions.updateItemLock(db, itemcode, action))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GINmodal);
