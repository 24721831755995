import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import ReactDOM from "react-dom";
// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyleNoOverflow.jsx";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import reactStringReplace from 'react-string-replace';
import { ClipLoader } from 'react-spinners';

import * as actions from 'store/actions';
import BPFormReview from 'containers/BP/BPFormReview';

class BPmodal extends Component  {
    constructor(props) {
      super(props);

      this.state = {
        previewBP: false
      }
    }

    toggleReviewModal = () => {
      this.setState({
        previewBP: !this.state.previewBP
      });
    }

    render() {
      // id
      // code
      // name
      // value
      const props = this.props;
      const { classes,
        change,
        currentDB,
        currentBPType,
        countriesDictionary,
        statesDictionary,
        owhtoptsDictionary,
        gsttypesDictionary,
        loadingAllBP
      } = props;
      const name = 'company_name',
            label = 'Company Name';
      const error = this.state.error;

      let list = null,
          counted = 0;
      if (props.similarsContent) {
        const content = props.similarsContent.content;
        const headers = props.similarsContent.headers;

        if (content) {
          const cont = content.map((c, i) => {
            // console.log(c);
            const cardcode = c.split(',')[2];
            return (<div
              style={{paddingLeft:'20px',paddingRight:'20px'}}
              key={'oth'+i}
              className="hover-bold"
              onClick={() => {
                console.log('open',cardcode, this.props.currentDB);
                if (this.props.loadBPForReview) {
                  this.props.loadBPForReview('', this.props.currentDB, cardcode, cardcode[0], 'reviewOnly');
                  this.toggleReviewModal();
                }
              }}
            ><GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <div style={{textAlign:'left'}}>{c.split(',')[0]}</div>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <div style={{textAlign:'right'}}>{c.split(',')[1]}</div>
              </GridItem>
            </GridContainer></div>);
          });

          const fullContent = (
            <div style={{paddingLeft:'20px',paddingRight:'20px'}}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <div style={{textAlign:'left',marginLeft:'20px'}}><strong>{headers[0]}</strong></div>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <div style={{textAlign:'right',marginRight:'20px'}}><strong>{headers[1]}</strong></div>
                </GridItem>
              </GridContainer>
              <div style={{
                  overflowY:'scroll',
                  backgroundColor:'#fbfbfb',
                  marginTop:'5px',
                  paddingTop:'5px',
                  paddingBottom:'5px',
                  fontSize:'12px',
                  maxHeight:'250px'
                }}>
              {cont}
              </div>
            </div>
          );

          return (
              <div>
                  {
                    (this.state.previewBP && this.props.sapBPreview) &&
                      <BPFormReview
                        finalValues={this.props.sapBPreview}
                        beforeValues={this.props.sapBPreview}
                        open={this.state.previewBP}
                        close={this.toggleReviewModal}
                        countries={countriesDictionary}
                        states={statesDictionary}
                        gsttypes={gsttypesDictionary}
                        owhtopts={owhtoptsDictionary}
                        BPaction={this.props.BPaction}
                        currentDB={currentDB}
                        currentBPType={currentBPType}
                        enterPayTerms={this.props.enterPayTerms}
                      />
                  }
                <div className="dialog-overflow">
                  <Dialog
                      classes={{
                          root: classes.center,
                          paper: classes.modal
                      }}
                      style={{overflow:'hidden !important'}}
                      open={props.open}
                      keepMounted
                      fullWidth={true}
                      onClose={() => {
                        props.close()
                      }}
                      maxWidth={false}
                      aria-labelledby="modal-slide-title"
                      aria-describedby="modal-slide-description">
                      <DialogTitle
                          id="classic-modal-slide-title"
                          disableTypography
                          className={classes.modalHeader}>
                          <Button
                              justIcon
                              className={classes.modalCloseButton}
                              key="close"
                              aria-label="Close"
                              color="transparent"
                              onClick={() => {
                                props.close();
                              }}
                          >
                              <Close className={classes.modalClose} />
                          </Button>
                          <h2 className={classes.modalTitle} style={{textAlign:'center'}}>
                            Similar BP Values
                          </h2>
                      </DialogTitle>
                  <div>
                    <div style={{overflow:'hidden'}}>
                      <div style={{marginBottom:'20px',marginLeft:'0',marginTop:'10px'}}>
                        {fullContent}
                      </div>
                      <div style={{textAlign:'center',paddingBottom:'30px'}}>
                        <Button
                          color="success"
                          onClick={() => {
                            props.close();
                          }}
                        >OK</Button>
                      </div>
                    </div>
                  </div>
                </Dialog>
                </div>
              </div>
          );
        } else {
          return null;
        }


        if (props.similarsList.length > 1) {
            list = props.similarsList.map((s, key) => {
              let coloredSelect = {fontSize:'12px !important'};
              const sCode = s.id;
              const sName = reactStringReplace(s.name.toUpperCase(), this.props.value, (match, i) => (
                                <span key={`dd-${i}`} style={{color:'#0464e1'}}>{this.props.value}</span>
                              ))
              const bp_type = s.code[0] === 'S' ? 'Supplier' : 'Customer';

              return (
                  <ListItem
                    key={'key'+key}
                    button
                    onClick={() => {
                      console.log('clicked');
                      if (this.props.loadBPForReview) {
                        this.props.loadBPForReview('', currentDB, sCode, bp_type);
                        this.toggleReviewModal();
                      }
                    }}
                    style={coloredSelect}
                    >
                    <div><strong>{s.id}</strong></div><br />
                    <div style={{marginLeft:'15px'}}>{sName}</div>
                  </ListItem>
                )
              });
          }

    }
  }
}

const mapStateToProps = (state) => {
  return {
    similars: state.gmp.existingSimilars,
    loading: state.gmp.existingSimilarsLoading,
    sapBPreview: state.common.sapBPreview,
    dbs: state.common.dbs,
    statesDictionary: state.common.statesDictionary,
    countriesDictionary: state.common.countriesDictionary,
    gsttypesDictionary: state.gmp.gsttypesDictionary,
    owhtopts: state.common.addinfo ?
                state.common.addinfo.owhtopts ? state.common.addinfo.owhtopts : null
                : null,
    owhtoptsDictionary: state.common.owhtoptsDictionary,
    allBPs: state.common.allBPs,
    loadingAllBP: state.common.loadingAllBP
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetSAPBP: () => {
      dispatch(actions.resetSAPBP());
    }
  }
}


export default connect(mapStateToProps,mapDispatchToProps)(withStyles(modalStyle)(BPmodal));
