import * as actionTypes from '../actions/actionTypes';
import { updateObject, normalizePhone, commatize } from '../../shared/utility';
import cloneDeep from 'lodash/cloneDeep';
const initialState = {
    sendFileStatus: null,
    loading: false,
    dbs: [],
    addinfo: [],
    acctnames: [],
    acctnamesDictionary: [],
    acctnamesObj: [],
    acctnamesObjDictionary: {},
    currencies: [],
    countries: [],
    countriesDictionary: {},
    payterms: [],
    paytermsDictionary: {},
    maincurr: null,
    groupcodes: [],
    groupcodesDictionary: {},
    defaultPBP: null,
    defaultCreditLimit: 0,
    defaultSubgroup: null,
    states: {},
    statesDictionary: {},
    sapBPGen: {},
    sapBPBillingAddresses: [],
    sapBPShippingAddresses: [],
    sapBP: null,
    sapBPreview: null,
    sapBPcontacts: [],
    existingBPfound: false,
    existingBPfoundMsg: '',
    errors: {},
    allBPs: [],
    loadingAllBP: false,
    loadingBP: false,
    savedLocalBPmsg: '',
    loadingLocalBP: false,
    localBP: null,
    localBPreview: null,
    loadingSAPBP: false,
    loadingSAPBPMsg: '',
    savedSAPBPmsg: '',
    isPending: null,
    origin: null,
    chosenPrimary: null
}

const updateBPErrors = (state, action) => {
  return updateObject(state, { errors: action.errors });
}

const fetchDBListSuccess = (state, action) => {
  // const dbs = [];
  // if (action.dbs.length > 0) {
  //   action.dbs.forEach(function(d) {
  //     if (dbs.indexOf('_LIVE') === -1) {
  //       dbs.push(d.db);
  //     }
  //   });
  // }
  return updateObject(state, { dbs: action.dbs });
}

const fetchAddInfoListSuccess = (state, action) => {
  // console.log('fetched countries,states',action);
  let countries = [],
      countriesDictionary = {};
  if (action.addinfo.hasOwnProperty('countries')) {
    const countrylist = action.addinfo.countries;
    for (var key in countrylist) {
    	if (countrylist.hasOwnProperty(key)) {
        if (countrylist[key] !== '**DONOTUSE**' && countrylist[key] !== '**DO NOT USE**') {
          //countries.push({id:key, name:countrylist[key] == 'UNITED STATES OF AMERICA' ? 'UNITED STATES' : countrylist[key]});
          countries.push({id:key, name:countrylist[key]});
          countriesDictionary[key] = countrylist[key];
        }
    	}
    }
  }

  const currencies = [];
  if (action.addinfo.hasOwnProperty('currencies')) {
    action.addinfo.currencies.forEach(function(c) {
      currencies.push({id:c.CurrCode, name:c.CurrName});
    });
  }

  let statesObj = {};
  if (action.addinfo.hasOwnProperty('states')) {
    action.addinfo.states.forEach(function(s) {
      if (statesObj.hasOwnProperty(s.Country)) {
        const arr = statesObj[s.Country];
        let stateExists = false;
        arr.forEach(function(a) {
          if (a.id == s.Code) {
            stateExists = true;
          }
        });
        if (!stateExists) {
          if (s.Name !== '**DONOTUSE**' && s.Name !== '**DO NOT USE**') {
            statesObj[s.Country].push({id: s.Code ? s.Code.toString() : s.Code, name:s.Name});
          }
        }
      } else {
        if (s.Name !== '**DONOTUSE**' && s.Name !== '**DO NOT USE**') {
          statesObj[s.Country] = [{id: s.Code ? s.Code.toString() : s.Code, name:s.Name}];
        }
      }
    });
  }

  let statesDictionary = {};
  Object.keys(statesObj).forEach((state) => {
    statesDictionary[state] = {};
    statesObj[state].forEach((st) => {
      statesDictionary[state][st.id.toString()] = st.name;
    });
  });

  let payterms = [],
      paytermsDictionary = {},
      defaultPBP = null,
      defaultCreditLimit = '0',
      isNDK = false;
  if (action.addinfo.hasOwnProperty('payterms')) {
    payterms = action.addinfo.payterms;
    if (payterms.length > 0) {
      if (action.db.indexOf('NDK') !== -1) {
        isNDK = true;
      }
      payterms.forEach((pt) => {
        const name = pt.name.toUpperCase();
        if (!isNDK) {
          if (name.indexOf('PAY BEFORE PRODUCTION') !== -1) {
            defaultPBP = pt.id;
          }
        } else {
          console.log('looking for',pt.name);
          if (name.indexOf('L/C 90') !== -1) {
            defaultPBP = pt.id;
            defaultCreditLimit = '1';
          }
        }
        paytermsDictionary[pt.id] = pt.name;
      });
    }
  }
  // console.log('payterms',paytermsDictionary);

  let maincurr = null,
      groupcodesDictionary = {},
      defaultSubgroup = null,
      groupcodes = [];
  if (action.addinfo.hasOwnProperty('maincurr')) {
    if (action.addinfo.maincurr) {
      maincurr = action.addinfo.maincurr;
    }
  }
  if (action.addinfo.hasOwnProperty('groupcodes')) {
    if (action.addinfo.groupcodes) {
      groupcodes = action.addinfo.groupcodes;
      groupcodes.forEach((gc) => {
        groupcodesDictionary[gc.GroupCode] = gc.GroupName;
        if (gc.GroupName.toUpperCase().indexOf('CUSTOMER') !== -1) {
          defaultSubgroup = gc.GroupCode;
        }
      });
    }
  }

  let owhtoptsDictionary = {};
  if (action.addinfo.hasOwnProperty('owhtopts')) {
    if (action.addinfo.owhtopts) {
      if (action.addinfo.owhtopts.length > 0) {
        action.addinfo.owhtopts.forEach((o) => {
          owhtoptsDictionary[o.WTCode] = o.WTName;
        });
      }
    }
  }

  let acctnames = [], acctnamesDictionary = {}, acctnamesObj = [], acctnamesObjDictionary = {};
  if (action.addinfo.hasOwnProperty('acctnames')) {
    if (action.addinfo.acctnames) {
      if (action.addinfo.acctnames.length > 0) {
        action.addinfo.acctnames.forEach((ac,i) => {
          acctnamesDictionary[ac.name] = ac.id;
          acctnamesObjDictionary[ac.name] = ac.name;
          acctnames.push(ac.name);
          acctnamesObj.push({
            id: ac.name,
            name: ac.name
          });
        });
      }
    }
  }

  return updateObject(state, {
    addinfo: action.addinfo,
    acctnames,
    acctnamesDictionary,
    acctnamesObj,
    acctnamesObjDictionary,
    currencies,
    countries,
    countriesDictionary,
    states: statesObj,
    statesDictionary,
    payterms,
    paytermsDictionary,
    owhtoptsDictionary,
    defaultPBP,
    defaultCreditLimit,
    defaultSubgroup,
    maincurr,
    groupcodes,
    groupcodesDictionary
  });
}

const fetchBPSAPSuccess = (state, action) => {
  console.log('SAP bp fetched',action);
  const sapBP = { ...action.bp };
  let finalValues = {};
  if (sapBP.hasOwnProperty('general')) {
    finalValues.card_code = sapBP.general.card_code;
    finalValues.company_name = sapBP.general.company_name;
    finalValues.bp_type = sapBP.general.card_code[0] === 'C' ? 'Customer' : 'Supplier';
    finalValues.bp_subgroup = sapBP.general.groupcode;
    finalValues.payterms = sapBP.general.payterms;
    finalValues.credit_limit = (sapBP.general.credit_limit !== null || sapBP.general.credit_limit !== undefined) ? parseFloat(sapBP.general.credit_limit).toString() : '0';
    finalValues.acctname = sapBP.general.acctname;
    finalValues.gen_tel = sapBP.general.gen_tel;
    finalValues.gen_fax = sapBP.general.gen_fax;
    finalValues.currency = sapBP.general.currency;
    finalValues.rfc_curp = sapBP.general.rfc_curp;
    finalValues.nit = sapBP.general.nit;
    finalValues.old_code = sapBP.general.oldcode;
    finalValues.default_shipping = sapBP.general.ship_to_def;
    finalValues.panno = sapBP.general.panno;
    finalValues.tinno = sapBP.general.tinno;
    finalValues.notes = sapBP.general.notes;
    finalValues.resale_certificate_path = sapBP.general.resale_certificate_path;
    if (sapBP.general.wtax_info) {
      finalValues.withholding = sapBP.general.wtax_info.wtliable === 'Y' ? true : false;
      // console.log('I caught the wtcode', sapBP.general.wtax_info.wtcodes);
      finalValues.wtcodes = sapBP.general.wtax_info.wtcodes ? sapBP.general.wtax_info.wtcodes : [];
      finalValues.surcharge_overlook = sapBP.general.wtax_info.surcharge;
      finalValues.threshold_overlook = sapBP.general.wtax_info.threshhold;
      // console.log('I caught the thresh', sapBP.general.wtax_info.threshhold);
      finalValues.certificate_num = sapBP.general.wtax_info.certificate;
      finalValues.expiration_date = sapBP.general.wtax_info.expiration;
      finalValues.accrual_criteria = sapBP.general.wtax_info.accrual;
      finalValues.assessee_type = sapBP.general.wtax_info.assessee;
      finalValues.ni_num = sapBP.general.wtax_info.ninumber;
    }
  }

  let sapBPBillingAddresses = [], sapBPShippingAddresses = [], sapBPcontacts = [];
  if (sapBP.hasOwnProperty('address')) {
    let shippingAddresses = [];
    sapBP.address.forEach(function(a, i) {
      if (a.type == 'B') {
        finalValues.billing_addressname = a.addressname;
        finalValues.billing_oname = a.addressname;
        finalValues.billing_address1 = a.address1;
        finalValues.billing_address2 = a.address2;
        finalValues.billing_country = a.country;
        finalValues.billing_state = a.state;
        finalValues.billing_zipcode = a.zipcode;
        finalValues.billing_city = a.city;
        finalValues.billing_gstregno = a.gstregno;
        finalValues.billing_gstregtype = a.gstregtype ? a.gstregtype.toString() : '';
      } else {
        shippingAddresses.push(a);
      }
    });
    finalValues.additionalShippingAddresses = [];
    shippingAddresses.forEach((a, i) => {
      if (i === 0) {
        finalValues.shipping_addressname = a.addressname;
        finalValues.shipping_oname = a.addressname;
        finalValues.shipping_address1 = a.address1;
        finalValues.shipping_address2 = a.address2;
        finalValues.shipping_country = a.country;
        finalValues.shipping_state = a.state;
        finalValues.shipping_zipcode = a.zipcode;
        finalValues.shipping_city = a.city;
        finalValues.shipping_gstregno = a.gstregno;
        finalValues.shipping_gstregtype = a.gstregtype ? a.gstregtype.toString() : '';
      } else {
        finalValues.additionalShippingAddresses.push({
          shipname: a.addressname,
          oname: a.addressname,
          add1: a.address1,
          add2: a.address2,
          country: a.country,
          state: a.state,
          city: a.city,
          zipcode: a.zipcode,
          gstregno: a.gstregno,
          gstregtype: a.gstregtype ? a.gstregtype.toString() : ''
        });
      }
    });
  }

  finalValues.accounts_payable_name = '';
  finalValues.accounts_payable_oname = '';
  finalValues.accounts_payable_position = '';
  finalValues.accounts_payable_tel = '';
  finalValues.accounts_payable_alttel = '';
  finalValues.accounts_payable_mobile = '';
  finalValues.accounts_payable_fax = '';
  finalValues.accounts_payable_email = '';
  finalValues.accounts_payable_firstname = '';
  finalValues.accounts_payable_lastname = '';
  finalValues.accounts_payable_gmp = '';
  finalValues.purchasing_name = '';
  finalValues.purchasing_oname = '';
  finalValues.purchasing_position = '';
  finalValues.purchasing_tel = '';
  finalValues.purchasing_alttel = '';
  finalValues.purchasing_mobile = '';
  finalValues.purchasing_fax = '';
  finalValues.purchasing_email = '';
  finalValues.purchasing_firstname = '';
  finalValues.purchasing_lastname = '';
  finalValues.purchasing_gmp = '';

  if (sapBP.hasOwnProperty('contact')) {
    let notGMPcontacts = [];
    sapBP.contact.forEach(function(c,i) {
      console.log('contact gmp', i, c.gmp_id, c.name);
      if (c.name.indexOf('GMP AP -') !== -1) {
        finalValues.accounts_payable_name = c.name;
        finalValues.accounts_payable_oname = c.name;
        finalValues.accounts_payable_position = c.position;
        finalValues.accounts_payable_tel = c.telephone;
        // console.log('alt phone',c.altphone);
        finalValues.accounts_payable_alttel = c.altphone;
        finalValues.accounts_payable_mobile = c.mobile;
        finalValues.accounts_payable_fax = c.fax;
        finalValues.accounts_payable_email = c.email;
        finalValues.accounts_payable_firstname = c.firstname;
        finalValues.accounts_payable_lastname = c.lastname;
        finalValues.accounts_payable_gmp = c.gmp_id ? c.gmp_id : '';
      } else if (c.name.indexOf('GMP PURCHASING -') !== -1) {
        finalValues.purchasing_name = c.name;
        finalValues.purchasing_oname = c.name;
        finalValues.purchasing_position = c.position;
        finalValues.purchasing_tel = c.telephone;
        finalValues.purchasing_alttel = c.altphone;
        finalValues.purchasing_mobile = c.mobile;
        finalValues.purchasing_fax = c.fax;
        finalValues.purchasing_email = c.email;
        finalValues.purchasing_firstname = c.firstname;
        finalValues.purchasing_lastname = c.lastname;
        finalValues.purchasing_gmp = c.gmp_id ? c.gmp_id : '';
      } else {
        // console.log('alt phone',c.altphone);
        notGMPcontacts.push({
          name: c.name,
          oname: c.name,
          position: c.position,
          telephone: c.telephone,
          alttel: c.altphone,
          mobile: c.mobile,
          fax: c.fax,
          email: c.email,
          firstname: c.firstname,
          lastname: c.lastname,
          gmp: c.gmp_id ? c.gmp_id : ''
        });
      }
    });

    finalValues.additionalContacts = [];
    notGMPcontacts.forEach((c) => {
      if (!finalValues.accounts_payable_name) {
        // console.log('alt phone',c.altphone, c);
        finalValues.accounts_payable_name = c.name;
        finalValues.accounts_payable_oname = c.name;
        finalValues.accounts_payable_position = c.position;
        finalValues.accounts_payable_tel = c.telephone;
        finalValues.accounts_payable_alttel = c.alttel;
        finalValues.accounts_payable_mobile = c.mobile;
        finalValues.accounts_payable_fax = c.fax;
        finalValues.accounts_payable_email = c.email;
        finalValues.accounts_payable_firstname = c.firstname;
        finalValues.accounts_payable_lastname = c.lastname;
        finalValues.accounts_payable_gmp = c.gmp ? c.gmp : '';
      } else if (!finalValues.purchasing_name) {
        // console.log('alt phone',c.altphone);
        finalValues.purchasing_name = c.name;
        finalValues.purchasing_oname = c.name;
        finalValues.purchasing_position = c.position;
        finalValues.purchasing_tel = c.telephone;
        finalValues.purchasing_alttel = c.alttel;
        finalValues.purchasing_mobile = c.mobile;
        finalValues.purchasing_fax = c.fax;
        finalValues.purchasing_email = c.email;
        finalValues.purchasing_firstname = c.firstname;
        finalValues.purchasing_lastname = c.lastname;
        finalValues.purchasing_gmp = c.gmp ? c.gmp : '';
      } else {
        // console.log('alt phone',c.altphone);
        finalValues.additionalContacts.push({
          name: c.name,
          oname: c.name,
          position: c.position,
          tel: c.telephone,
          alttel: c.alttel,
          mobile: c.mobile,
          fax: c.fax,
          email: c.email,
          firstname: c.firstname,
          lastname: c.lastname,
          gmp: c.gmp ? c.gmp : ''
        });
      }
    });
  }

  finalValues.rboActive = [];
  finalValues.ediActive = [];
  if (sapBP.hasOwnProperty('rboedi')) {
    if (sapBP.rboedi) {
      if (sapBP.rboedi.length > 0) {
        sapBP.rboedi.forEach(re => {
          if (re.VendorCode && re.EDIProgramID) {
            finalValues.ediActive.push({
              a_id: re.RecordID,
              edi_program: re.EDIProgramID,
              vendor_code: re.VendorCode
            });
          }

          if (re.ActiveRBOID) {
            finalValues.rboActive.push({
              a_id: re.RecordID,
              rbo: re.ActiveRBOID
            });
          }
        });
      }
    }
  }

  console.log('these are the sap bp values fetched',finalValues);
  if (action.reviewOnly) {
    return updateObject(state, {
      sapBPreview: finalValues,
      lockedMsg: action.lockedMsg,
      existingBPfound: true,
      existingBPfoundMsg: '',
      loadingBP: false
    });
  } else {
    return updateObject(state, {
      sapBP: finalValues,
      lockedMsg: action.lockedMsg,
      existingBPfound: true,
      existingBPfoundMsg: '',
      loadingBP: false
    });
  }
}

const fetchBPSAPFail = (state, action) => {
  let existingBPfoundMsg = 'not found';
  if (action.err) {
    if (typeof action.err === 'string') {
      existingBPfoundMsg = action.err;
    } else {
      const err = action.err.data;
      if (err) {
        if (err.error) {
          existingBPfoundMsg = err.error;
        }
      }
    }
  }
  return updateObject(state, {
    existingBPfound: false,
    existingBPfoundMsg,
    loadingBP: false
  });
}

const fetchBPSAP = (state, action) => {
  return updateObject(state, {
    existingBPfound: false,
    existingBPfoundMsg: 'searching'
  });
}

const fetchBPSAPStart = (state, action) => {
  return updateObject(state, {
    loadingBP: true,
    //sapBP: null,
    existingBPfoundMsg: '',
    lockedMsg: ''
  });
}

const cancelGMPBPExistingPreview = (state, action) => {
  return updateObject(state, {
    existingBPfound: false
  });
}

const setLoadingAllBP = (state, action) => {
  return updateObject(state, {
    loadingAllBP: true
  });
};

const updateAllBPs = (state, action) => {
  return updateObject(state, {
    allBPs: action.results,
    loadingAllBP: false
  });
};

const setErrorAllBP = (state, action) => {
  // console.log('error getting BPs',action.err);
  return updateObject(state, {
    loadingAllBP: false
  });
}

const saveLocalBPStart = (state, action) => {
  return updateObject(state, {
    loadingSAPBP: !state.loadingSAPBP,
    loadingSAPBPMsg: ''
  });
}

const saveLocalBPSuccess = (state, action) => {
  return updateObject(state, {
    loadingSAPBPMsg: 'Submitted BP.',
  });
}

const saveLocalBPFail = (state, action) => {
  return updateObject(state, {
    loadingSAPBPMsg: 'Unable to submit BP, Please try again. If the issue persists please contact Tech Support.'
  });
}

const fetchLocalBPStart = (state, action) => {
  return updateObject(state, {
    loadingLocalBP: true,
    localBP: null,
    lockedMsg: '',
    origin: null
  });
}

const fetchLocalBPFail = (state, action) => {
  let lockedMsg = '';
  if (action.err) {
    if (typeof action.err === 'string') {
      if (action.err.indexOf('lock') !== -1) {
        lockedMsg = action.err;
      }
    }
  }
  return updateObject(state, {
    loadingLocalBP: false,
    localBP: null,
    lockedMsg
  });
}

const processLocalBP = (action) => {
  let finalValues = action.bp ? cloneDeep(action.bp.data) : null;
  if (finalValues) {
    finalValues.rboActive = [];
    finalValues.ediActive = [];
    if (finalValues.hasOwnProperty('rboedi')) {
      if (finalValues.rboedi) {
        if (finalValues.rboedi.length > 0) {
          finalValues.rboedi.forEach(re => {
            if (re.VendorCode && re.EDIProgramID) {
              finalValues.ediActive.push({
                a_id: re.RecordID,
                edi_program: re.EDIProgramID,
                vendor_code: re.VendorCode
              });
            }

            if (re.ActiveRBOID) {
              finalValues.rboActive.push({
                a_id: re.RecordID,
                rbo: re.ActiveRBOID
              });
            }
          });
        }
      }
    }
  }
  return finalValues;
}

const fetchLocalBPSuccess = (state, action) => {
  console.log('received', action);
  const finalValues = processLocalBP(action);
  const obj = {
    loadingLocalBP: false,
    origin: action.origin
  }
  if (action.origin === 'previewOnly') {
    obj.localBPreview = finalValues;
  } else {
    obj.localBP = finalValues;
  }
  return updateObject(state, obj);
}

const saveSAPBPStart = (state, action) => {
  return updateObject(state, {
    loadingSAPBP: !state.loadingSAPBP,
    loadingSAPBPMsg: ''
  });
}

const closeSaveSAPBPModal = (state, action) => {
  return updateObject(state, {
    loadingSAPBP: !state.loadingSAPBP
  });
}

const saveSAPBPSuccess = (state, action) => {
  return updateObject(state, {
    loadingSAPBPMsg: 'Submitted BP.',
  });
}

const saveSAPBPFail = (state, action) => {
  return updateObject(state, {
    loadingSAPBPMsg: 'Unable to submit BP to Pending, Please try again. If the issue persists please contact Tech Support.'
  });
}

const resetSAPBP = (state, action) => {
  return updateObject(state, {
    sapBP: null,
    localBP: null
  });
}

const checkPendingBPSuccess = (state, action) => {
  return updateObject(state, {
    isPending: action.response
  });
}

const checkPendingBPFail = (state, action) => {
  return updateObject(state, {
    isPendingError: action.err
  });
}

const resetCheckPendingBP = (state, action) => {
  return updateObject(state, {
    isPendingError: null
  });
}

const sendResaleCertFileStart = (state, action) => {
  return updateObject(state, {
    sendFileStatus: null,
    loading: true,
  });
}

const sendResaleCertFileSuccess = (state, action) => {
  return updateObject(state, {
    sendFileStatus: action.response,
    loading: false
  });
}

const sendResaleCertFileFail = (state, action) => {
  return updateObject(state, {
    sendFileStatus: { status: 'fail' },
    loading: false
  });
}

const getResaleCertFileSuccess = (state, action) => {
  return state;
}

const getResaleCertFileFail = (state, action) => {
  console.log(action.err);
  return state;
}

const sendResaleCertFileSAPSuccess = (state, action) => {
  console.log('SAP Resale File Upload Success',action.response);
  return state;
}

const sendResaleCertFileSAPFail = (state, action) => {
  console.log('SAP Resale File Upload Fail',action.err);
}

const setBPdata = (state, action) => {
  return updateObject(state, {
    sapBP: action.obj,
    localBP: action.obj
  });
}

const resetCommon = (state, action) => {
  return updateObject(state, {
    localBP: null,
    sapBP: null
  });
}

const emptySAPBPreview = (state, action) => {
  return updateObject(state, {
    sapBPreview: null
  });
}

const closeReviewModal = (state, action) => {
  return updateObject(state, {
    localBPreview: null
  });
}

const choosePrimaryStart = (state, action) => {
  return updateObject(state, {
    loadingLocalBP: true,
    chosenPrimary: null
  });
}

const choosePrimarySuccess = (state, action) => {
  return updateObject(state, {
    loadingLocalBP: false,
    chosenPrimary: action.data
  });
}

const choosePrimaryFail = (state, action) => {
  return updateObject(state, {
    loadingLocalBP: false,
    chosenPrimary: null
  });
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.FETCH_ADDINFO_LIST_SUCCESS:
        return fetchAddInfoListSuccess(state, action);
      case actionTypes.FETCH_DB_LIST_SUCCESS:
        return fetchDBListSuccess(state, action);
      case actionTypes.FETCH_BP_SAP:
        return fetchBPSAP(state, action);
      case actionTypes.FETCH_BP_SAP_START:
        return fetchBPSAPStart(state, action);
      case actionTypes.FETCH_BP_SAP_SUCCESS:
        return fetchBPSAPSuccess(state, action);
      case actionTypes.FETCH_BP_SAP_FAIL:
        return fetchBPSAPFail(state, action);
      case actionTypes.CANCEL_GMP_BP_EXISTING_PREVIEW:
          return cancelGMPBPExistingPreview(state, action);
      case actionTypes.UPDATE_BP_ERRORS:
        return updateBPErrors(state, action);
      case actionTypes.FETCH_ALL_BP_START:
        return setLoadingAllBP(state, action);
      case actionTypes.FETCH_ALL_BP_SUCCESS:
        return updateAllBPs(state, action);
      case actionTypes.FETCH_ALL_BP_FAIL:
        return setErrorAllBP(state, action);
      case actionTypes.SAVE_LOCAL_BP_START:
        return saveLocalBPStart(state, action);
      case actionTypes.SAVE_LOCAL_BP_SUCCESS:
        return saveLocalBPSuccess(state, action);
      case actionTypes.SAVE_LOCAL_BP_FAIL:
        return saveLocalBPFail(state, action);
      case actionTypes.FETCH_LOCAL_BP_START:
        return fetchLocalBPStart(state, action);
      case actionTypes.FETCH_LOCAL_BP_SUCCESS:
        return fetchLocalBPSuccess(state, action);
      case actionTypes.FETCH_LOCAL_BP_FAIL:
        return fetchLocalBPFail(state, action);
      case actionTypes.SAVE_SAP_BP_START:
        return saveSAPBPStart(state, action);
      case actionTypes.SAVE_SAP_BP_SUCCESS:
        return saveSAPBPSuccess(state, action);
      case actionTypes.SAVE_SAP_BP_FAIL:
        return saveSAPBPFail(state, action);
      case actionTypes.CLOSE_SAVE_SAP_BP_MODAL:
        return closeSaveSAPBPModal(state, action);
      case actionTypes.RESET_SAP_BP:
        return resetSAPBP(state, action);
      case actionTypes.CHECK_PENDING_BP_SUCCESS:
        return checkPendingBPSuccess(state, action);
      case actionTypes.CHECK_PENDING_BP_FAIL:
        return checkPendingBPFail(state, action);
      case actionTypes.RESET_CHECK_PENDING_BP:
        return resetCheckPendingBP(state, action);

      case actionTypes.SEND_RESALE_CERT_FILE_START:
        return sendResaleCertFileStart(state, action);
      case actionTypes.SEND_RESALE_CERT_FILE_SUCCESS:
        return sendResaleCertFileSuccess(state, action);
      case actionTypes.SEND_RESALE_CERT_FILE_FAIL:
        return sendResaleCertFileFail(state, action);

      case actionTypes.GET_RESALE_CERT_FILE_SUCCESS:
        return getResaleCertFileSuccess(state, action);
      case actionTypes.GET_RESALE_CERT_FILE_FAIL:
        return getResaleCertFileFail(state, action);

      case actionTypes.SEND_RESALE_CERT_FILE_SAP_SUCCESS:
        return sendResaleCertFileSAPSuccess(state, action);
      case actionTypes.SEND_RESALE_CERT_FILE_SAP_FAIL:
        return sendResaleCertFileFail(state, action);
      case actionTypes.SET_BP_DATA:
        return setBPdata(state, action);
      case actionTypes.RESET_COMMON:
        return resetCommon(state, action);
      case actionTypes.EMPTY_SAP_BP_REVIEW:
        return emptySAPBPreview(state, action);
      case actionTypes.CLOSE_REVIEW_MODAL:
        return closeReviewModal(state, action);

      case actionTypes.CHOOSE_PRIMARY_START:
        return choosePrimaryStart(state, action);
      case actionTypes.CHOOSE_PRIMARY_SUCCESS:
        return choosePrimarySuccess(state, action);
      case actionTypes.CHOOSE_PRIMARY_FAIL:
        return choosePrimaryFail(state, action);
      default: return state;
    }
};

export default reducer;
