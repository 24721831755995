import React from "react";
import { connect } from 'react-redux';
import * as actions from 'store/actions';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import Delete from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { PulseLoader } from 'react-spinners';
import Button from "components/CustomButtons/Button.jsx";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { blacklist } from 'shared/validation';
import moment from 'moment';
import BPInput from "containers/BP/fields/BPInput";
import GINask2 from "containers/GIN/GINask2";

class GINautosaveModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      list: props.autoSaveDataList,
      error: '',
      ask: null
    }
  }

  openAsk = (id) => {
    this.setState({ask:id});
  }

  closeAsk = () => {
    this.setState({ask:null});
  }

  componentDidUpdate(prevProps) {
    if (prevProps.autoSaveDataList !== this.props.autoSaveDataList) {
      this.setState({list: this.props.autoSaveDataList})
    }
  }

  componentDidMount() {
    this.props.loadAll();
  }

  render() {
    const props = this.props;
    let error = '', list = [];
    if (this.state.list) {
      if (this.state.list.length > 0) {
        const orderedList = [].concat(this.state.list.map(l => {
          return {
            gin:l.gin,
            date_modified:l.date_modified,
            id:l.id,
            date: new Date(l.date_modified).getTime()
          }
        })).sort(function(y, x){
            return x.date - y.date;
        })
        console.log('orderedList', orderedList);
        list = orderedList.map((gin,key) => {
                const date = moment(gin.date_modified);
                const datetime = date.format('MM/DD/YY hh:mm');
                const label = <span><strong>GIN# {gin.gin}</strong> - Last saved {datetime}</span>
                return (
                <div key={'keyg'+key}>
                  <IconButton
                    edge="end"
                    color="inherit"
                    onClick={() => {
                      this.openAsk(`${gin.id},${gin.gin}`);
                    }}>
                    <div className="red-text"><Delete /></div>
                  </IconButton>
                  <Button
                    size="sm"
                    color="success"
                    style={{marginRight:20}}
                    onClick={() => {
                      const filename = `${gin.id}_${gin.gin}`;
                      console.log('gin selected', gin.id, filename);
                      props.load(gin.id);
                      props.loadImage(filename);
                      props.setGIN(gin.gin);
                      setTimeout(() => {
                        props.scrollTop();
                      }, 500);
                      props.close();
                    }}
                    >
                    Load
                  </Button>
                    <span>{label}</span>
                </div>
                )
              });
      } else {
        error = 'none';
      }
    }

    return (
      <Dialog
        open={props.open}
        keepMounted
        onClose={props.close}
        fullWidth={true}
        maxWidth="sm"
      >
        {this.state.ask && <GINask2
            command={() => {
              const s = this.state.ask.split(',');
              props.delete(s[0]);
              setTimeout(() => {
                this.props.loadAll()
              }, 500);
            }}
            title="Delete"
            content={`'Do you really want to delete the GIN session for ${this.state.ask.split(',')[1]} ?'`}
            close={this.closeAsk}
          />
        }
        <DialogTitle>
          <IconButton edge="end" color="inherit" onClick={props.close} aria-label="Close" style={{float:'right'}}>
            <CloseIcon />
          </IconButton>
          <div className="text-center">
            <h4><strong>Saved GINs</strong></h4>
            <p>Select a saved session</p>
          </div>
        </DialogTitle>
        <DialogContent>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              {list}
              {error && <div style={{margin:20}} className="text-center">{error}</div>}
              <div className="text-center" style={{marginTop:20}}>
                <Button color="rose" onClick={() => { props.close(); }}>CLOSE</Button>
              </div>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    )
  }
}

const mapStateToProps = state => {
  return {
    loading: state.gin.autoSaveLoad,
    autoSaveData: state.gin.autoSaveData,
    autoSaveDataList: state.gin.autoSaveDataList
  }
}

const mapDispatchToProps = dispatch => {
  return {
    autoSave: () => {
      dispatch(actions.ginAutoSave());
    },
    loadAll: () => {
      dispatch(actions.ginAutoSaveLoadAll());
    },
    load: (id) => {
      dispatch(actions.ginAutoSaveLoad(id));
    },
    delete: (id) => {
      dispatch(actions.ginAutoSaveDelete(id));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GINautosaveModal);
