import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';
import { logic } from 'containers/RFID/RFIDlogic';
const clone = (obj) => {
  return JSON.parse(JSON.stringify(obj));
}

let initialState = {
  prefixes: null,
  company_ids: null,
  serial_combos: {},
  results: null,
  loading: false,
  loading2: false,
  queryResults: null,
  gs1_prefix: null,
  gs1_prefix_loading: false,
  filename: null,
  percent: null,
  noSerialChange: null,
  filenamesData: null,
  verifyExcels: false,
  verifyUpdatePercentage: 0,
  error: null,
  success: null,
  jcp_import_data: {
    gtin: {},
    c128: {}
  },
  allbirds_import_data: {},
  rfid_importer_data: [],
  openPreview: null,
  getAllBirdsLoading: false,
  excel_loading: false
}

const loadRFIDprefixSuccess = (state, action) => {
  const r = action.response;
  console.log('rr',r);
  if (r.results) {
    if (r.results.length > 0) {
      const obj = {};
      r.results.forEach((rr) => {
        const rrData = {
          rbo: rr.rbo,
          prefix: rr.prefix
        }
        if (!obj.hasOwnProperty(rr.company)) {
          obj[rr.company] = [rrData];
        } else {
          obj[rr.company].push(rrData);
        }
      })
      return updateObject(state, {
        prefixes: obj
      });
    }
  }
  return updateObject(state, {
    prefixes: null
  });
}

const loadRFIDprefixFail = (state, action) => {
  console.log('failed to retrieve RFID prefixes');
  return state;
}

const loadRFIDcompaniesSuccess = (state, action) => {
  let company_ids = null;
  if (action.company_ids) {
    if (action.company_ids.results) {
      let obj = {};
      action.company_ids.results.forEach((c) => {
        obj[c.name] = c.id;
      })
      company_ids = obj;
    }
  }
  return updateObject(state, {
    company_ids
  });
}

const rfidExportEPCStart = (state, action) => {
  return updateObject(state, {
    results: null,
    loading: true
  });
}

function isPromise(object){
  if(Promise && Promise.resolve){
    return Promise.resolve(object) == object;
  }else{
    throw "Promise not supported in your environment"
  }
}

const rfidExportEPCSuccess = (state, action) => {
  console.log('DES', action.data);
  const obj = clone(state.serial_combos);
  obj[action.data.upcCode] = action.data.last_serial
  let serial = action.data.last_serial;
  if (action.data.startingSerial) {
    serial = parseInt(action.data.startingSerial) - 1;
    const manufacturerID = action.data.manufacturerID ? action.data.manufacturerID.toString() : '';
    const serialID = action.data.startingSerial ? action.data.startingSerial.toString() : '';
    // serial = manufacturerID + serialID;
    console.log('mID',manufacturerID, serialID);
  }
  if (action.data.company_id === 4) {
    const resultsObj = {
      upcCode: action.data.upcCode,
      productionQty: 10,
      serial: serial ? serial + 1 : 1,
      company_id: action.data.company_id
    };
    console.log('got here 1', resultsObj)
    const results = logic.UPCtoEPC(resultsObj)
    console.log('got here 2')
    console.log('these are the results 1', results);
    console.log('these are the results 2', action.data.last_serial);
    const finalDataObj = {
      last_serial: action.data.last_serial,
      serial_combos: obj,
      results,
      queryResults: action.data.results,
      loading: false,
      noSerialChange: action.data.noSerialChange
    };
    console.log('these are the results 4', finalDataObj);
    if (!results) return state;
    return updateObject(state, finalDataObj);
  } else {
    const resultsObj = {
      upcCode: action.data.upcCode,
      productionQty: 10,
      serial: serial + 1,
      manufacturerID: action.data.manufacturerID,
      rfidType: action.data.rfidType,
      gs1_prefix: action.data.gs1_prefix,
      company_id: action.data.company_id
    };
    const results = logic.UPCtoEPC(resultsObj)
    console.log('these are the results 1', results, resultsObj);
    console.log('these are the results 2', action.data.last_serial);
    const finalDataObj = {
      last_serial: action.data.last_serial,
      serial_combos: obj,
      results,
      queryResults: action.data.results,
      loading: false,
      noSerialChange: action.data.noSerialChange
    };
    console.log('these are the results 3', finalDataObj);
    if (!results) return state;
    return updateObject(state, finalDataObj);
  }
}

const rfidClearData = (state, action) => {
  return updateObject(state, {
    last_serial: '',
    results: null,
    loading: false,
    queryResults: null,
    filename: null
  })
}

const rfidLoadHistoryStart = (state, action) => {
  return updateObject(state, {
    historyList: null,
    loading: true
  })
}

const rfidLoadHistorySuccess = (state, action) => {
  console.log('data results', action.data);
  return updateObject(state, {
    historyList: action.data.results,
    loading: false
  })
}

const rfidLoadHistoryFail = (state, action) => {
  return updateObject(state, {
    loading: false
  })
}

const loadGS1CompanyPrefixStart = (state, action) => {
  return updateObject(state, {
    gs1_prefix_loading: true
  });
}

const loadGS1CompanyPrefixSuccess = (state, action) => {
  if (action.response) {
    const dataObj = action.response.dataObj,
          gtin14 = action.response.gtin14;
    if (dataObj[0]) {
      if (dataObj[0].Prefixes) {
        if (dataObj[0].Prefixes.GS1Prefix) {
          return updateObject(state, {
            gs1_prefix: {
              prefix: dataObj[0].Prefixes.GS1Prefix,
              gtin14
            },
            gs1_api_is_working: true,
            gs1_prefix_loading: false
          })
        }
      }
    }
  }
  return updateObject(state, {
    gs1_prefix: null,
    gs1_prefix_loading: false
  })
}

const loadGS1CompanyPrefixFail = (state, action) => {
  return updateObject(state, {
    gs1_prefix: null,
    gs1_prefix_loading: false
  })
}

const resetGS1 = (state, action) => {
  return updateObject(state, {
    gs1_prefix: null
  });
}

const generateRFIDExcelSuccess = (state, action) => {
  return updateObject(state, {
    filename: action.response.filename,
    loading: false
  });
}

const generateRFIDExcelFail = (state, action) => {
  return updateObject(state, {
    filename: 0,
    loading: false
  });
}

const generateRFIDExcelStart = (state, action) => {
  return updateObject(state, {
    filename: null,
    loading: true,
    percent: action.percent ? action.percent : null
  });
}

const checkGS1APIStart = (state, action) => {
  return updateObject(state, {
    loading2: true,
    gs1_api_is_working: null
  });
}

const checkGS1APISuccess = (state, action) => {
  console.log('checking GS1 API', action.response);
  if (action.response) {
    const dataObj = action.response.dataObj;
    if (dataObj[0]) {
      if (dataObj[0].Prefixes) {
        if (dataObj[0].Prefixes.GS1Prefix) {
          return updateObject(state, {
            loading2: false,
            gs1_api_is_working: true
          });
        }
      }
    }
  }
  return updateObject(state, {
    loading2: false,
    gs1_api_is_working: false
  });
}

const checkGS1APIFail = (state, action) => {
  return updateObject(state, {
    loading2: false,
    gs1_api_is_working: false
  });
}

const verifyRFIDExcelStart = (state, action) => {
  return updateObject(state, {
    verifyExcels: true,
    filenamesData: null
  });
}

const verifyRFIDExcelUpdate = (state, action) => {
  console.log('updated percentage', action.percentDone);
  return updateObject(state, {
    verifyUpdatePercentage: action.percentDone
  });
}

const verifyRFIDExcelSuccess = (state, action) => {
  return updateObject(state, {
    verifyExcels: false,
    filenamesData: action.data
  });
}

const verifyRFIDExcelFail = (state, action) => {
  return updateObject(state, {
    verifyExcels: false,
    verifyError: action.err
  });
}

// GET_RFID_JCDATA

const getRfidJcdataStart = (state, action) => {
  return updateObject(state, {
      error: null,
      success: null
  })
}

const getRfidJcdataSuccess = (state, action) => {
  return updateObject(state, {
      success: 'Success',
      jcp_import_data: action.jcp_import_data,
      excel_id: action.history_response ? action.history_response.id : null
  })
}

const getRfidJcdataFail = (state, action) => {
  return updateObject(state, {
      error: action.err
  })
}

const setRfidJcdata = (state, action) => {
  return updateObject(state, {
    jcp_import_data: action.jcp_import_data
  })
}

// GET_RFID_ALLBIRDSDATA

const getRfidAllBirdsStart = (state, action) => {
  return updateObject(state, {
      error: null,
      success: null,
      getAllBirdsLoading: true
  })
}

const getRfidAllBirdsSuccess = (state, action) => {
  return updateObject(state, {
      success: 'Success',
      allbirds_import_data: action.allbirds_import_data,
      excel_id: action.history_response ? action.history_response.id : null
  })
}

const getRfidAllBirdsSuccessFinal = (state, action) => {
  return updateObject(state, {
      getAllBirdsLoading: false
  })
}

const getRfidAllBirdsFail = (state, action) => {
  return updateObject(state, {
      error: action.err,
      getAllBirdsLoading: false
  })
}

const setRfidAllBirds = (state, action) => {
  return updateObject(state, {
    allbirds_import_data: action.allbirds_import_data
  })
}

// GET_RFID_IMPORTER_HISTORY

const getRfidImporterHistoryStart = (state, action) => {
  return updateObject(state, {
      error: null,
      success: null,
      openPreview: null
  })
}

const getRfidImporterHistorySuccess = (state, action) => {
  if (action.openPreview) {
    console.log('rfid importer history success')
    return updateObject(state, {
      success: 'Success',
      openPreview: action.response
    })
  } else {
    return updateObject(state, {
      success: 'Success',
      rfid_importer_data: action.response
    })
  }
}

const getRfidImporterHistoryFail = (state, action) => {
  return updateObject(state, {
      error: action.err
  })
}

// GET_RFID_IMPORTER_EXCEL

const getRfidImporterExcelStart = (state, action) => {
  return updateObject(state, {
      error: null,
      success: null,
      excel_id: null,
      excel_loading: true
  })
}

const getRfidImporterExcelSuccess = (state, action) => {
  return updateObject(state, {
      success: 'Success',
      excel_id: action.response ? action.response.filename : '',
      excel_loading: false
  })
}

const getRfidImporterExcelFail = (state, action) => {
  return updateObject(state, {
      error: action.err,
      excel_loading: false
  })
}

const setGS1APIisWorking = (state, action) => {
  return updateObject(state, {
    gs1_api_is_working: action.gs1_api_is_working
  });
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOAD_RFID_PREFIX_SUCCESS:
          return loadRFIDprefixSuccess(state, action);
        case actionTypes.LOAD_RFID_PREFIX_FAIL:
          return loadRFIDprefixFail(state, action);
        case actionTypes.LOAD_RFID_COMPANIES_SUCCESS:
          return loadRFIDcompaniesSuccess(state, action);
        case actionTypes.LOAD_RFID_COMPANIES_FAIL:
          return state;
        case actionTypes.RFID_EXPORT_EPC_SUCCESS:
          return rfidExportEPCSuccess(state, action);
        case actionTypes.RFID_EXPORT_EPC_START:
          return rfidExportEPCStart(state, action);
        case actionTypes.RFID_CLEAR_DATA:
          return rfidClearData(state, action);
        case actionTypes.RFID_EXPORT_EPC_FAIL:
          return state;
        case actionTypes.RFID_LOAD_HISTORY_START:
          return rfidLoadHistoryStart(state, action);
        case actionTypes.RFID_LOAD_HISTORY_SUCCESS:
          return rfidLoadHistorySuccess(state, action);
        case actionTypes.RFID_LOAD_HISTORY_FAIL:
          return rfidLoadHistoryFail(state, action);
        case actionTypes.LOAD_GS1COMPANYPREFIX_START:
          return loadGS1CompanyPrefixStart(state, action);
        case actionTypes.LOAD_GS1COMPANYPREFIX_SUCCESS:
          return loadGS1CompanyPrefixSuccess(state, action);
        case actionTypes.LOAD_GS1COMPANYPREFIX_FAIL:
          return loadGS1CompanyPrefixFail(state, action);
        case actionTypes.RESET_GS1:
          return resetGS1(state, action);
        case actionTypes.GENERATE_RFID_EXCEL_SUCCESS:
          return generateRFIDExcelSuccess(state, action);
        case actionTypes.GENERATE_RFID_EXCEL_FAIL:
          return generateRFIDExcelFail(state, action);
        case actionTypes.GENERATE_RFID_EXCEL_START:
          return generateRFIDExcelStart(state, action);
        case actionTypes.CHECK_GS1_API_START:
          return checkGS1APIStart(state, action);
        case actionTypes.CHECK_GS1_API_SUCCESS:
          return checkGS1APISuccess(state, action);
        case actionTypes.CHECK_GS1_API_FAIL:
          return checkGS1APIFail(state, action);
        case actionTypes.VERIFY_RFID_EXCEL_START:
          return verifyRFIDExcelStart(state, action);
        case actionTypes.VERIFY_RFID_EXCEL_UPDATE:
          return verifyRFIDExcelUpdate(state, action);
        case actionTypes.VERIFY_RFID_EXCEL_SUCCESS:
          return verifyRFIDExcelSuccess(state, action);
        case actionTypes.VERIFY_RFID_EXCEL_FAIL:
          return verifyRFIDExcelFail(state, action);

        case actionTypes.GET_RFID_JCDATA_START:
            return getRfidJcdataStart(state, action);
        case actionTypes.GET_RFID_JCDATA_SUCCESS:
            return getRfidJcdataSuccess(state, action);
        case actionTypes.GET_RFID_JCDATA_FAIL:
            return getRfidJcdataFail(state, action);
          
        case actionTypes.SET_RFID_JCDATA:
            return setRfidJcdata(state, action);

        case actionTypes.GET_RFID_ALLBIRDS_START:
            return getRfidAllBirdsStart(state, action);
        case actionTypes.GET_RFID_ALLBIRDS_SUCCESS:
            return getRfidAllBirdsSuccess(state, action);
        case actionTypes.GET_RFID_ALLBIRDS_SUCCESS_FINAL:
          return getRfidAllBirdsSuccessFinal(state, action);
        case actionTypes.GET_RFID_ALLBIRDS_FAIL:
            return getRfidAllBirdsFail(state, action);
          
        case actionTypes.SET_RFID_ALLBIRDS:
            return setRfidAllBirds(state, action);

        case actionTypes.GET_RFID_IMPORTER_HISTORY_START:
            return getRfidImporterHistoryStart(state, action);
        case actionTypes.GET_RFID_IMPORTER_HISTORY_SUCCESS:
            return getRfidImporterHistorySuccess(state, action);
        case actionTypes.GET_RFID_IMPORTER_HISTORY_FAIL:
            return getRfidImporterHistoryFail(state, action);

        case actionTypes.GET_RFID_IMPORTER_EXCEL_START:
            return getRfidImporterExcelStart(state, action);
        case actionTypes.GET_RFID_IMPORTER_EXCEL_SUCCESS:
            return getRfidImporterExcelSuccess(state, action);
        case actionTypes.GET_RFID_IMPORTER_EXCEL_FAIL:
            return getRfidImporterExcelFail(state, action);

        case actionTypes.SET_GS1API_IS_WORKING:
            return setGS1APIisWorking(state, action);

        default: return state;
    }
};

export default reducer;
