import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';
import dbList from '../../shared/activeDBs';
import cloneDeep from 'lodash/cloneDeep';
import activeDBs from 'shared/activeDBs';
import moment from 'moment';

let initialState = {
    openedDBs: [],
    dbList,
    itemCodes: [],
    itemSearchResults: [],
    itemResult: [],
    exists: false,
    startImporter: false,
    finishedImporting: false,
    hasDonePicks: false,
    removeBOM: false,
    removeCost: false,
    tableCount: 0,
    currentImportDB: '',
    currentImportDB2: '',
    importerData: [],
    importerDataErr: '',
    ginExistingLoad: false,
    ginExistingData: null,
    existingImageURL: '',
    existingLoadFinal: false,
    loadPreview: false,
    loadPreviewData: null,
    loadingOptions: false,
    loadingOptionsDB: null,
    existingGINsBefore: null,
    loadingDB: '',
    loadExistingFinished: '',
    itemSentManually: false,
    pendingData: [],
    pendingError: null,
    loadPending: false,
    historyData: [],
    historyFilterErrMsg: null,
    historyStartDate: '',
    historyEndDate: '',
    ginType: 'NEW',
    ginCheckCount: null,
    chosenGINDetails: null,
    lockStatus: null,

    currentAutoSaveID: null,
    autoSaveDataList: [],
    autoSaveData: null,
    autoSaveLoad: false,
    autoSaveLoadMsg: null,
    autoSaveLoadOptions: false,
    saveData: null,

    editMode: false,
    editData: {
      itemName: null,
      db: null,
      field: null, // field to change
      value: '',
      type: '',
      options: ''
    },

    editTableMode: false, // variants and BOM
    editTableData: {
      itemName: null,
      db: null,
      rowFields: null, // field row to change
      values: [],
      type: '',
      options: ''
    },

    editTable2Mode: false, // pricing and costing
    editTable2Data: {
      itemName: null,
      db: null,
      rowFields: null, // field row to change
      values: [],
      type: '',
      options: ''
    },

    newItem: null,
    newItemList: [],

    nin_tax: {
      gstrel: [
        {id: 'N', name: 'No'},
        {id: 'Y', name: 'Yes'}
      ],
      mattype: [
        {id: '1', name: 'Raw Material'},
        {id: '2', name: 'Capital Goods'},
        {id: '3', name: 'Finished Goods'},
        {id: '0', name: 'Others'}
      ],
      gstcat: [
        {id:'R', name: 'Regular'},
        {id:'N', name: 'Nil Rated'},
        {id:'E', name: 'Exempted'}
      ]
    },
    options: {},
    ginSendItemStatus: null,
    ginSendItemLoading: false,
    ginSendItemDB: '',
    ginImage: null,
    ginCrop: null,
    ginFinalCrop: null,
    ginFinalCrop_original: null,
    ginImageExt: null,
    ginNum: '',
    gmpCheckbox: false,
    tpp_item_type: '',
    dbs: [],
    brands: [],
    subgroups: [],
    packagingtypes: [],
    loading: false,
    loadingInit: false,
    message: null,

    // fields that can be updated
    base_item_description: [],
    cterm:[],
    ccur: [],
    cstart: [],
    ccode: [],
    cend: [],
    cprice: [],
    cqtys:  [],
    cterms: [],
    ccurs: [],
    ccodes: [],
    cprices: [],
    cstarts: [],
    cends: [],
    product_type: [],
    rbo: [],
    brand: [],
    sub_group: [],
    ipackagingtypes: [],

    bpNames: {},

    // fields that can be updated
    bmasterarray: [],
    smasterarray: [],

    // fields that can be updated
    preferredVendor: {},
    item_is: [],
    sales_unit: [],
    basic_packaging_unit: [],
    qty_per_unit: [],
    ilength: [],
    width: [],
    weight: [],
    baseItem: [],
    variants: [],
    variantsDesc: [],
    deactivateds: [],

    originalVariants: [],
    originalVariantsDesc: [],
    variantsDeactivate: {},

    rms: [],
    rmsCopy: [],
    rmsd: [],
    rmsq: [],
    rmsw: [],
    BOMItemBaseQty: [],
    gterm: [],
    gstart: [],
    gend: [],
    gcur: [],
    gcode: [],
    gprice: [],
    gterms: [],
    gprices: [],
    gstarts: [],
    gends: [],
    gcurrs: [],
    gcodes: [],
    gqtys: [],
    mocs: {},
    mocarray: [],
    picks: {},
    variantStatus: false,
    variantsChecked: {},
    variantsToAdd: [],
    specialmoc: [],
    picked: {},

    // NIN Tax Info
    gst_relevant: {}, // Yes or No
    material_type: {}, // Select - get options
    hsn_code: {}, // input
    gst_tax_category: {}, // Select - get options
    service_acct_code: {}, // input

    // original for comparison
    base_item_description_original: [],
    cterm_original:[],
    ccur_original: [],
    cstart_original: [],
    ccode_original: [],
    cend_original: [],
    cprice_original: [],
    cqtys_original:  [],
    cterms_original: [],
    ccurs_original: [],
    ccodes_original: [],
    cprices_original: [],
    cstarts_original: [],
    cends_original: [],
    product_type_original: [],
    rbo_original: [],
    brand_original: [],
    ipackagingtypes_original: [],
    sub_group_original: [],
    // original for comparison
    bmasterarray_original: [],
    smasterarray_original: [],
    // original for comparison
    preferredVendor_original: {},
    item_is_original: [],
    sales_unit_original: [],
    basic_packaging_unit_original: [],
    qty_per_unit_original: [],
    ilength_original: [],
    width_original: [],
    weight_original: [],
    // original for comparison
    rms_original: [],
    rmsd_original: [],
    rmsq_original: [],
    rmsw_original: [],
    BOMItemBaseQty_original: [],
    gterm_original: [],
    gstart_original: [],
    gend_original: [],
    gcur_original: [],
    gcode_original: [],
    gprice_original: [],
    gterms_original: [],
    gprices_original: [],
    gstarts_original: [],
    gends_original: [],
    gcurrs_original: [],
    gcodes_original: [],
    gqtys_original: [],
    mocs_original: {},
    mocarray_original: [],
    picks_original: {},
    specialmoc_original: [],
    picked_original: {},
    gst_relevant_original: {}, // Yes or No
    material_type_original: {}, // Select - get options
    hsn_code_original: {}, // input
    gst_tax_category_original: {}, // Select - get options
    service_acct_code_original: {}, // input

    comp: {
      base_item_description: [],
      cterm:[],
      ccur: [],
      cstart: [],
      cend: [],
      cprice: [],
      cqtys:  [],
      cterms: [],
      ccurs: [],
      ccode: [],
      ccodes: [],
      cprices: [],
      cstarts: [],
      cends: [],
      product_type: [],
      rbo: [],
      brand: [],
      sub_group: [],
      ipackagingtypes: [],
      bmasterarray: [],
      smasterarray: [],
      preferredVendor: {},
      item_is: [],
      sales_unit: [],
      basic_packaging_unit: [],
      qty_per_unit: [],
      ilength: [],
      width: [],
      weight: [],
      variants: [],
      variantsDesc: [],
      originalVariants: [],
      originalVariantsDesc: [],
      variantsDeactivate: {},
      rms: [],
      rmsd: [],
      rmsq: [],
      rmsw: [],
      gterm: [],
      gstart: [],
      gend: [],
      gcur: [],
      gcode: [],
      gprice: [],
      gterms: [],
      gprices: [],
      gcodes: [],
      gstarts: [],
      gends: [],
      gcurrs: [],
      gqtys: [],
      specialmoc: [],
      mocs: {},
      mocarray: [],
      BOMItemBaseQty: []
    }
}

const stateList = [
  'itemCodes',
  'base_item_description',
  'product_type',
  'rbo',
  'brand',
  'sub_group',
  'ipackagingtypes',
  'sales_unit',
  'basic_packaging_unit',
  'qty_per_unit',
  'ilength',
  'width',
  'weight',
  'baseItem',
  'variants',
  'variantsDesc',
  'originalVariants',
  'originalVariantsDesc',
  'variantsDeactivate',
  'deactivateds',
  'cprice',
  'cterm',
  'ccur',
  'cprice',
  'cstart',
  'cend',
  'cqtys',
  'cterms',
  'ccurs',
  'cprices',
  'cstarts',
  'cends',
  'ccode',
  'ccodes',
  'bmasterarray',
  'smasterarray',
  'preferredVendor',
  'rms',
  'rmsCopy',
  'rmsd',
  'rmsq',
  'rmsw',
  'gprice',
  'gstart',
  'gend',
  'gcur',
  'gcode',
  'gterm',
  'gterms',
  'gprices',
  'gstarts',
  'gends',
  'gcurrs',
  'gcodes',
  'gqtys',
  'specialmoc',
  'mocs',
  'picks',
  'BOMItemBaseQty',
  'item_is',
  'gst_relevant', // Yes or No
  'material_type', // Select - get options
  'hsn_code', // input
  'gst_tax_category', // Select - get options
  'service_acct_code',
];

const originals = [
  'base_item_description_original',
  'cterm_original',
  'ccur_original',
  'cstart_original',
  'ccode_original',
  'cend_original',
  'cprice_original',
  'cqtys_original',
  'cterms_original',
  'ccurs_original',
  'ccodes_original',
  'cprices_original',
  'cstarts_original',
  'cends_original',
  'product_type_original',
  'rbo_original',
  'brand_original',
  'ipackagingtypes_original',
  'sub_group_original',
  'bmasterarray_original',
  'smasterarray_original',
  'preferredVendor_original',
  'item_is_original',
  'sales_unit_original',
  'basic_packaging_unit_original',
  'qty_per_unit_original',
  'ilength_original',
  'width_original',
  'weight_original',
  'rms_original',
  'rmsd_original',
  'rmsq_original',
  'rmsw_original',
  'BOMItemBaseQty_original',
  'gterm_original',
  'gstart_original',
  'gend_original',
  'gcur_original',
  'gcode_original',
  'gprice_original',
  'gterms_original',
  'gprices_original',
  'gstarts_original',
  'gends_original',
  'gcurrs_original',
  'gcodes_original',
  'gqtys_original',
  'mocs_original',
  'mocarray_original',
  'picks_original',
  'specialmoc_original',
  'picked_original',
  'gst_relevant_original',
  'material_type_original',
  'hsn_code_original',
  'gst_tax_category_original',
  'service_acct_code_original',
]

const combinedStateList = stateList.concat(originals);

combinedStateList.forEach((st) => {
  dbList.forEach((db) => {
    initialState[st][db.name] = null;
  });
});

const initialStateCopy = cloneDeep(initialState);

const count = (arr) => {
  return arr.reduce(function(m,e){
    m[e] = (+m[e]||0)+1; return m
  },{});
}

const returnComp = (baseObjOrig, openedDBs) => {
  const baseObj = JSON.parse(JSON.stringify(baseObjOrig));
  let arr = [],
      arr_keys = [];
    // collect keys and values e.g.
    if (baseObj) {
      Object.keys(baseObj).forEach((key, i) => {
        if (Array.isArray(openedDBs)) {
          if (openedDBs.indexOf(key) !== -1) {
            arr.push(baseObj[key]);
            arr_keys.push(key);
          }
        }
      });
    }
    let obj = count(arr);
    // if length is > 1 then not all is the same, e.g. BRSGTEST
    let existing = Object.keys(obj).length;
    let diffs = {};
    let differentDBs = [];
    if (existing > 0) {
      Object.keys(obj).forEach((item) => {
        arr.forEach((a, index) => {
          if (a == item) {
            if (diffs.hasOwnProperty(item)) {
              diffs[item].push(arr_keys[index]);
            } else {
              diffs[item] = [arr_keys[index]];
            }
          }
        });
        differentDBs.push(arr_keys[arr.indexOf(item)]);
      });
    }
    return diffs;
}

const compareArr = [
  (a,b) => {
    if (a[0] < b[0]) return -1;
    if (a[0] > b[0]) return 1;
    return 0;
  },
  (a,b) => {
    if (a[1] < b[1]) return -1;
    if (a[1] > b[1]) return 1;
    return 0;
  }
];

// [{variant:'',variantDesc:'' },{variant:'',}]
const returnCombinedRows = (state, fields, openedDBs, indexToUse) => {
  let combined = {};
  if (Array.isArray(openedDBs)) {
    openedDBs.forEach((db) => {
      let combinedArr = [];
      state[fields[0]][db].forEach((f, index) => {
        let combo = {};
        fields.forEach((field) => {
          let item = state[field][db][index];
          if (Array.isArray(item)) {
            item = JSON.stringify(item);
          };
          combo[field] = item;
        });
        combinedArr = combinedArr.concat(JSON.stringify(combo));
      });

      combined[db] = JSON.stringify(combinedArr.sort());
    });
  }
  return combined;
}

const returnCompArr = (baseObjOrig, openedDBs, indexToSortArray) => {
  const baseObj = JSON.parse(JSON.stringify(baseObjOrig));
  let arr = [];
  let arr_keys = [];
  if (Object.keys(baseObj)) {
    Object.keys(baseObj).forEach((key, i) => {
      if (Array.isArray(openedDBs)) {
        if (openedDBs.indexOf(key) !== -1) {
          arr.push(baseObj[key]);
          arr_keys.push(key);
        }
      }
    });
  }

  let sortedArr = [];
  let sortedArrKeys = [];
  if (indexToSortArray) {
    arr.forEach((a,index) => {
      if (Array.isArray(a)) {
        sortedArr.push(JSON.stringify(a.sort(compareArr[indexToSortArray])));
        sortedArrKeys.push(arr_keys[index]);
      }
    });
  } else {
    arr.forEach((a,index) => {
      if (Array.isArray(a)) {
        sortedArr.push(JSON.stringify(a.sort()));
        sortedArrKeys.push(arr_keys[index]);
      }
    });
  }

  let obj = count(sortedArr);
  // if length is > 1 then not all is the same, e.g. BRSGTEST
  let existing = Object.keys(obj).length;
  let diffs = {};
  let differentDBs = [];
  if (existing > 0) {
    Object.keys(obj).forEach((item) => {
      sortedArr.forEach((a, index) => {
        if (a == item) {
          if (diffs.hasOwnProperty(item)) {
            diffs[item] = diffs[item].concat(sortedArrKeys[index]);
          } else {
            diffs[item] = [sortedArrKeys[index]];
          }
        }
      });
      differentDBs = differentDBs.concat(sortedArrKeys[sortedArr.indexOf(item)]);
    });
  }
  return diffs;
}

const returnVariantsTable = (variants, desc, openedDBs) => {
  let newVariantObj = {};
  if (Object.keys(variants)) {
    Object.keys(variants).forEach((db, i) => {
      if (Array.isArray(openedDBs)) {
        if (openedDBs.indexOf(db) !== -1) {
          let combinedArr = [];
          if (variants[db]) {
            variants[db].forEach((v, i) => {
              combinedArr.push([v[0], desc[db][i]]);
            });
            newVariantObj[db] = combinedArr;
          }
        }
      }
    });
  }
  return newVariantObj;
}

const compareAll = (state, action) => {
  console.log('comparing all...', state, action);
  // General
  let comp_baseItemDescription = returnComp(state.base_item_description, state.openedDBs);
  let comp_productType = returnComp(state.product_type, state.openedDBs);
  let comp_rbo = returnComp(state.rbo, state.openedDBs);
  let comp_brand = returnComp(state.brand, state.openedDBs);
  let comp_subGroup = returnComp(state.subgroup, state.openedDBs);
  let comp_ipackagingtypes = returnComp(state.ipackagingtypes, state.openedDBs);
  let comp_itemIs = returnComp(state.item_is, state.openedDBs);
  const compareVariantsTable = returnVariantsTable(state.variants, state.variantsDesc, state.openedDBs);
  let comp_variants = returnCompArr(compareVariantsTable, state.openedDBs, 0);
  console.log('comp_variants',comp_variants, compareVariantsTable)
  let comp_variantsDesc = returnCompArr(state.variants, state.openedDBs, 0);

  // Measurements
  let comp_sales_unit = returnComp(state.sales_unit, state.openedDBs);
  let comp_basic_packaging_unit = returnComp(state.basic_packaging_unit, state.openedDBs);
  let comp_qty_per_unit = returnComp(state.qty_per_unit, state.openedDBs);
  let comp_ilength = returnComp(state.ilength, state.openedDBs);
  let comp_width = returnComp(state.width, state.openedDBs);
  let comp_weight = returnComp(state.weight, state.openedDBs);
  let comp_picks = returnComp(state.picks, state.openedDBs);

  // Pricing
  let comp_gterm = returnComp(state.gterm, state.openedDBs);
  let comp_gcur = returnComp(state.gcur, state.openedDBs);
  let comp_gprice = returnComp(state.gprice, state.openedDBs);
  let comp_gstart = returnComp(state.gstart, state.openedDBs);
  let comp_gend = returnComp(state.gend, state.gend);
  let comp_gcode = returnComp(state.gend, state.gcode);

  // Costing

  // BOM
  let comp_rms = returnCompArr(state.rms, state.openedDBs);
  let comp_rmsd = returnCompArr(state.rmsd, state.openedDBs);
  let comp_rmsq = returnCompArr(state.rmsq, state.openedDBs);
  let comp_rmsw = returnCompArr(state.rmsw, state.openedDBs);
  let comp_BOMItemBaseQty = returnComp(state.BOMItemBaseQty, state.openedDBs);

  let newCOMP = {...state.comp};
  newCOMP.base_item_description = comp_baseItemDescription;
  newCOMP.product_type = comp_productType;
  newCOMP.rbo = comp_rbo;
  newCOMP.brand = comp_brand;
  newCOMP.subgroup = comp_subGroup;
  newCOMP.ipackagingtypes = comp_ipackagingtypes;
  newCOMP.sales_unit = comp_sales_unit;
  newCOMP.basic_packaging_unit = comp_basic_packaging_unit;
  newCOMP.qty_per_unit = comp_qty_per_unit;
  newCOMP.ilength = comp_ilength;
  newCOMP.width = comp_width;
  newCOMP.weight = comp_weight;
  newCOMP.picks = comp_picks;
  newCOMP.item_is = comp_itemIs;
  newCOMP.variants = comp_variants;
  newCOMP.variantsDesc = comp_variantsDesc;
  newCOMP.gterm = comp_gterm;
  newCOMP.gcur = comp_gcur;
  newCOMP.gprice = comp_gprice;
  newCOMP.gstart = comp_gstart;
  newCOMP.gend = comp_gend;
  console.log('new rms', comp_rms);
  newCOMP.rms = comp_rms;
  newCOMP.rmsd = comp_rmsd;
  newCOMP.rmsq = comp_rmsq;
  newCOMP.rmsw = comp_rmsw;
  newCOMP.BOMItemBaseQty = comp_BOMItemBaseQty;

  return updateObject(state, {
    comp: newCOMP
  });
}

const setLoading = (state, action) => {
  return updateObject(state, {
    message: null,
    loading: true
  });
}

const showItemList = (state, action) => {
  return updateObject(state, {
    itemSearchResults: action.itemlist ? action.itemlist : [],
    message: 'Success',
    loading: false
  });
}

const notifyItemListFail = (state, action) => {
  return updateObject(state, {
    itemSearchResults: [],
    message: 'Failed to get search list',
    loading: false
  });
}

const showItem = (state, action) => {
  let originalObj = {};
  originals.forEach(o => {
    originalObj[o] = {...state[o]};
  });

  let currentBaseItemDescription = {...state.base_item_description};
  let currentProductType = {...state.product_type};
  let currentRBO = {...state.rbo};
  let currentBrand = {...state.brand};
  let currentSubgroup = {...state.subgroup};
  let currentipackagingtypes = {...state.ipackagingtypes};

  let currentSalesUnit = {...state.sales_unit};
  let currentBasicPackagingUnit = {...state.basic_packaging_unit};
  let currentQtyPerUnit = {...state.qty_per_unit};

  let currentLength = {...state.ilength};
  let currentWidth = {...state.width};
  let currentWeight = {...state.weight};
  let currentPicks = {...state.picks};

  let currentBaseItem = {...state.baseItem};
  console.log('currentBaseItem',currentBaseItem);
  let currentVariants = {...state.variants};
  let currentVariantsDesc = {...state.variantsDesc};
  let currentDeactivateds = {...state.deactivateds};
  let currentVariantsDeactivate = {...state.variantsDeactivate};

  let currentBmasterarray = {...state.bmasterarray};
  let currentSmasterarray = {...state.smasterarray};
  let currentPreferredVendor = {...state.preferredVendor};
  let currentCprice = {...state.cprice};
  let currentRMS = {...state.rms};
  let currentRMSCopy = {...state.rmsCopy};
  let currentRMSD = {...state.rmsd};
  let currentRMSQ = {...state.rmsq};
  let currentRMSW = {...state.rmsw};

  let currentItemIs = {...state.item_is};

  let currentGterm = {...state.gterm};
  let currentGstart = {...state.gstart};
  let currentGend = {...state.gend};
  let currentGprice = {...state.gprice};
  let currentGcur = {...state.gcur};
  let currentGcode = {...state.gcode};
  let currentMOCs = {...state.mocs};
  let currentSpecialMOCs = {...state.specialmoc};

  let currentGterms = {...state.gterms};
  let currentGstarts = {...state.gstarts};
  let currentGends = {...state.gends};
  let currentGprices = {...state.gprices};
  let currentGcurrs = {...state.gcurrs};
  let currentGcodes = {...state.gcodes};
  let currentGqtys = {...state.gqtys};

  let currentTargetCostFlat_cterm = {...state.cterm};
  let currentTargetCostFlat_ccur = {...state.ccur};
  let currentTargetCostFlat_cprice = {...state.cprice};
  let currentTargetCostFlat_cstart = {...state.cstart};
  let currentTargetCostFlat_cend = {...state.cend};
  let currentTargetCostFlat_ccode = {...state.ccode};

  let currentTargetCostAll_cqtys = {...state.cqtys};
  let currentTargetCostAll_cterms = {...state.cterms};
  let currentTargetCostAll_ccurs = {...state.ccurs};
  let currentTargetCostAll_cprices = {...state.cprices};
  let currentTargetCostAll_cstarts = {...state.cstarts};
  let currentTargetCostFlat_ccodes = {...state.ccodes};
  let currentTargetCostAll_cends = {...state.cends};

  let currentBOMItemBaseQty = {...state.BOMItemBaseQty};

  // NIN Tax Information
  let gst_relevant = {...state.gst_relevant};
  let material_type = {...state.material_type};
  let hsn_code = {...state.hsn_code};
  let gst_tax_category = {...state.gst_tax_category};
  let service_acct_code = {...state.service_acct_code};

  const db = action.companydb,
        item = action.iteminfo;

  console.log('received ITEM', item);
  currentBaseItemDescription[db] = item['iname'] ? item['iname'].substring(item['iname'].indexOf(" ") + 1).toUpperCase() : '';
  originalObj.base_item_description_original[db] = item['iname'] ? item['iname'].substring(item['iname'].indexOf(" ") + 1).toUpperCase() : '';

  currentProductType[db] = item.igc;
  originalObj.product_type_original[db] = item.igc;

  currentRBO[db] = item.man;
  originalObj.rbo_original[db] = item.man;

  currentBrand[db] = item.ibrand ? item.ibrand : 'n/a';
  originalObj.brand_original[db] = item.ibrand ? item.ibrand : 'n/a';

  currentSubgroup[db] = item.isubgroup ? item.isubgroup : 'n/a';
  originalObj.sub_group_original[db] = item.isubgroup ? item.isubgroup : 'n/a';

  currentipackagingtypes[db] = item.ipackagingtypes ? item.ipackagingtypes : 'n/a';
  originalObj.ipackagingtypes_original[db] = item.ipackagingtypes ? item.ipackagingtypes : 'n/a';

  currentSalesUnit[db] = item.salesu;
  originalObj.sales_unit_original[db] = item.salesu;

  currentBasicPackagingUnit[db] = item.salespu;
  originalObj.basic_packaging_unit_original[db] = item.salespu;

  currentQtyPerUnit[db] = item.salesqpp || item.salesqpp === 0 ? parseInt(item.salesqpp).toString() : '';
  originalObj.qty_per_unit_original[db] = item.salesqpp || item.salesqpp === 0 ? parseInt(item.salesqpp).toString() : '';

  currentLength[db] = item.sul || item.sul === 0 ? parseFloat(item.sul).toString() : '';
  originalObj.ilength_original[db] = item.sul || item.sul === 0 ? parseFloat(item.sul).toString() : '';

  currentWidth[db] = item.suw || item.suw === 0 ? parseFloat(item.suw).toString() : '';
  originalObj.width_original[db] = item.suw || item.suw === 0 ? parseFloat(item.suw).toString() : '';

  currentWeight[db] = item.sulb || item.sulb === 0 ? parseFloat(item.sulb).toString() : '';
  originalObj.weight_original[db] = item.sulb || item.sulb === 0 ? parseFloat(item.sulb).toString() : '';

  currentPicks[db] = item.picks;
  originalObj.picks_original[db] = item.picks;

  let base_item = "";
  if (item.base_item) {
    if (item.base_item.frozenFor) {
      if (item.base_item.frozenFor === 'N') {
        base_item = item.base_item.Itemcode;
      }
    }
  }
  currentBaseItem[db] = base_item;
  currentVariants[db] = item.vars;
  currentVariantsDesc[db] = item.vards;
  currentVariantsDeactivate[db] = [];
  currentDeactivateds[db] = item.deactivateds;

  let pricingCustomers = {};
  console.log('received bmasterarray', item.bmasterarray);
  item.bmasterarray.forEach((b) => {
    let asplit = b.split(' :: ');
    let cardcode = asplit[1];
    let bsplit = asplit[0].split('--');
    bsplit.forEach((bs) => {
      let row = bs.split(' | ');
      if (pricingCustomers.hasOwnProperty(cardcode)) {
        pricingCustomers[cardcode].push(row);
      } else {
        pricingCustomers[cardcode] = [row];
      }
    });
  });
  console.log('constructed pricingCustomers', pricingCustomers);

  let costingSupplier = {}, preferredSupp = '';
  item.smasterarray.forEach((s,i) => {
    let asplit = s.split(' :: ');
    let cardcode = asplit[1];
    if (i == 0 && cardcode) {
      preferredSupp = cardcode.split('[.]')[0];
    }
    let bsplit = asplit[0].split('--');
    bsplit.forEach((bs) => {
      let row = bs.split(' | ');
      if (costingSupplier.hasOwnProperty(cardcode)) {
        costingSupplier[cardcode].push(row);
      } else {
        costingSupplier[cardcode] = [row];
      }
    });
  });
  currentBmasterarray[db] = pricingCustomers;
  originalObj.bmasterarray_original[db] = pricingCustomers;

  currentSmasterarray[db] = costingSupplier;
  originalObj.smasterarray_original[db] = costingSupplier;

  currentPreferredVendor[db] = preferredSupp;
  originalObj.preferredVendor_original[db] = preferredSupp;

  currentCprice[db] = item.cprice;
  originalObj.cprice_original[db] = item.cprice;

  currentRMS[db] = item.rms;
  originalObj.rms_original[db] = item.rms;
  currentRMSCopy[db] = item.rms;

  currentRMSD[db] = item.rmsd;
  originalObj.rmsd_original[db] = item.rmsd;

  const convertedRMSQ = item.rmsq.map((qty) => {
    return parseInt(qty).toString();
  })
  currentRMSQ[db] = convertedRMSQ;
  originalObj.rmsq_original[db] = item.rmsq;

  currentRMSW[db] = item.rmsw;
  originalObj.rmsw_original[db] = item.rmsw;

  let itemIs = 'None',
      hasBOM = false, // Produced in House
      hasCost = false; // Outsourced;
  const cprice = item.cprice,
        smasterarray = item.smasterarray,
        rms = item.rms;

  if (smasterarray) {
    // if (!cprice && smasterarray.length === 0) {
    //   itemIs = 'Produced in House';
    // }
    if (cprice || smasterarray.length > 0) {
      hasCost = true;
    }
  }

  if (rms) {
    // if (!rms[0]) {
    //   itemIs = 'Outsourced';
    // }
    if (rms[0]) {
      hasBOM = true;
    }
  }

  if (hasBOM) {
    itemIs = 'Produced in House';
  }
  if (hasCost) {
    itemIs = 'Outsourced';
  }
  if (hasBOM && hasCost) {
    itemIs = 'Both';
  }

  currentItemIs[db] = itemIs;
  originalObj.item_is_original[db] = itemIs;

  currentGterm[db] = item.gterm;
  originalObj.gterm_original[db] = item.gterm;

  currentGstart[db] = item.gstart;
  originalObj.gstart_original[db] = item.gstart;

  currentGend[db] = item.gend;
  originalObj.gend_original[db] = item.gend;

  currentGprice[db] = parseFloat(item.gprice);
  originalObj.gprice_original[db] = parseFloat(item.gprice);

  currentGcur[db] = item.gcur;
  originalObj.gcur_original[db] = item.gcur;

  currentGcode[db] = item.gcode;
  originalObj.gcode_original[db] = item.gcode;

  let mocs = {};
  if (item.mocs) {
    Object.keys(item.mocs).forEach((mc) => {
      mocs[mc] = !isNaN(item.mocs[mc]) ? parseFloat(item.mocs[mc]) : item.mocs[mc];
    });
  }
  currentMOCs[db] = mocs;
  originalObj.mocs_original[db] = mocs;

  const specialmoc = item.specialmoc.map((arr) => {
    return [arr[0], !isNaN(arr[1]) ? parseFloat(arr[1]) : arr[1], arr[2]];
  });
  currentSpecialMOCs[db] = specialmoc;
  originalObj.specialmoc_original[db] = specialmoc;

  currentGterms[db] = item.gterms;
  originalObj.gterms_original[db] = item.gterms;

  const gprices = item.gprices.map((p) => {
    return p ? parseFloat(p) : 0;
  });
  currentGprices[db] = gprices;
  originalObj.gprices_original[db] = gprices;

  currentGstarts[db] = item.gstarts ? item.gstarts : [];
  originalObj.gstarts_original[db] = item.gstarts ? item.gstarts : [];

  currentGends[db] = item.gends ? item.gends : [];
  originalObj.gends_original[db] = item.gends ? item.gends : [];

  currentGcurrs[db] = item.gcurrs;
  originalObj.gcurrs_original[db] = item.gcurrs;

  currentGcodes[db] = item.gcodes;
  originalObj.gcodes_original[db] = item.gcodes;

  currentGqtys[db] = item.gqtys;
  originalObj.gqtys_original[db] = item.gqtys;

  currentTargetCostFlat_cterm[db] = item.cterm;
  originalObj.cterm_original[db] = item.cterm;

  currentTargetCostFlat_ccur[db] = item.ccur;
  originalObj.ccur_original[db] = item.ccur;

  currentTargetCostFlat_cprice[db] = parseFloat(item.cprice);
  originalObj.cprice_original[db] = parseFloat(item.cprice);

  currentTargetCostFlat_cstart[db] = item.cstart;
  originalObj.cstart_original[db] = item.cstart;

  currentTargetCostFlat_cend[db] = item.cend;
  originalObj.cend_original[db] = item.cend;

  currentTargetCostFlat_ccode[db] = item.ccode;
  originalObj.ccode_original[db] = item.ccode;

  currentTargetCostAll_cqtys[db] = item.cqtys;
  originalObj.cqtys_original[db] = item.cqtys;

  currentTargetCostAll_cterms[db] = item.cterms;
  originalObj.cterms_original[db] = item.cterms;

  currentTargetCostAll_ccurs[db] = item.ccurrs;
  originalObj.ccurs_original[db] = item.ccurrs;

  currentTargetCostFlat_ccodes[db] = item.ccodes;
  originalObj.ccodes_original[db] = item.ccodes;

  const cprices = item.cprices.map((p) => {
    return p ? parseFloat(p) : 0;
  });
  currentTargetCostAll_cprices[db] = cprices;
  originalObj.cprices_original[db] = cprices;

  const cstarts = item.cstarts ? item.cstarts : [];
  currentTargetCostAll_cstarts[db] = cstarts;
  originalObj.cstarts_original[db] = cstarts;

  const cends = item.cends ? item.cends : [];
  currentTargetCostAll_cends[db] = cends;
  originalObj.cends_original[db] = cends;

  currentBOMItemBaseQty[db] = item.bomq ? parseFloat(item.bomq).toString() : null;
  originalObj.BOMItemBaseQty_original[db] = item.bomq ? parseFloat(item.bomq).toString() : null;

  if (db.indexOf('NIN') !== -1) {
    gst_relevant[db] = item.gstrel;
    originalObj.gst_relevant_original[db] = item.gstrel;

    material_type[db] = item.mattype;
    originalObj.material_type_original[db] = item.mattype;

    hsn_code[db] = item.hsn ? item.hsn.toUpperCase() : '';
    originalObj.hsn_code_original[db] = item.hsn ? item.hsn.toUpperCase() : '';

    gst_tax_category[db] = item.gsttax;
    originalObj.gst_tax_category_original[db] = item.gsttax;

    service_acct_code[db] = item.sac ? item.sac.toUpperCase() : '';
    originalObj.service_acct_code_original[db] = item.sac ? item.sac.toUpperCase() : '';
  }

  let pickedCopy = { ...state.picked };
  Object.keys(pickedCopy).forEach(pc => {
    if (pickedCopy[pc] === db) {
      delete pickedCopy[pc];
    }
  });

  const obj = {
    itemResult: action.iteminfo,
    loading: false,
    base_item_description: currentBaseItemDescription,
    cterm: currentTargetCostFlat_cterm,
    ccur: currentTargetCostFlat_ccur,
    cprice: currentTargetCostFlat_cprice,
    cstart: currentTargetCostFlat_cstart,
    cend: currentTargetCostFlat_cend,
    ccode: currentTargetCostFlat_ccode,
    ccodes: currentTargetCostFlat_ccodes,
    cqtys: currentTargetCostAll_cqtys,
    cterms: currentTargetCostAll_cterms,
    ccurs: currentTargetCostAll_ccurs,
    cprices: currentTargetCostAll_cprices,
    cstarts: currentTargetCostAll_cstarts,
    cends: currentTargetCostAll_cends,
    product_type: currentProductType,
    rbo: currentRBO,
    brand: currentBrand,
    subgroup: currentSubgroup,
    ipackagingtypes: currentipackagingtypes,
    sales_unit: currentSalesUnit,
    basic_packaging_unit: currentBasicPackagingUnit,
    qty_per_unit: currentQtyPerUnit,
    ilength: currentLength,
    width: currentWidth,
    weight: currentWeight,
    picks: currentPicks,
    baseItem: currentBaseItem,
    variants: currentVariants,
    originalVariants: currentVariants,
    variantsDesc: currentVariantsDesc,
    originalVariantsDesc: currentVariantsDesc,
    variantsDeactivate: currentVariantsDeactivate,
    deactivateds: currentDeactivateds,
    bmasterarray: currentBmasterarray,
    smasterarray: currentSmasterarray,
    preferredVendor: currentPreferredVendor,
    cprice: currentCprice,
    rms: currentRMS,
    rmsCopy: currentRMSCopy,
    rmsd: currentRMSD,
    rmsq: currentRMSQ,
    rmsw: currentRMSW,
    item_is: currentItemIs,
    gstart: currentGstart,
    gend: currentGend,
    gprice: currentGprice,
    gcur: currentGcur,
    gcode: currentGcode,
    gterm: currentGterm,
    gterms: currentGterms,
    gstarts: currentGstarts,
    gends: currentGends,
    gprices: currentGprices,
    gcurrs: currentGcurrs,
    gcodes: currentGcodes,
    gqtys: currentGqtys,
    mocs: currentMOCs,
    specialmoc: currentSpecialMOCs,
    BOMItemBaseQty: currentBOMItemBaseQty,
    gst_relevant,
    material_type,
    hsn_code,
    gst_tax_category,
    service_acct_code,
    picked: pickedCopy, // delete the db picked if a new one is loaded
    //variantsChecked: {} // reset everytime a new one is loaded
    hasDonePicks: false,
    lockStatus: action.lockStatus,
    tpp_item_type: item.tpp_item_type
  };

  originals.forEach(o => {
    obj[o] = originalObj[o];
  });

  return updateObject(state, obj);
}

const notifyItemFail = (state, action) => {
  let currentState = {...state.itemCodes};
  let currentOpenedDBs = [...state.openedDBs];
  const itm = action.itemcode,
        finalItem = itm ? itm.indexOf('_') !== -1 ? itm.split('_')[0] : itm : itm;
  console.log('finalItem to be removed',finalItem);
  if (currentState[action.db]) {
    delete currentState[action.db];
  }
  return updateObject(state, {
    itemResult: [],
    itemCodes: currentState,
    openedDBs: currentOpenedDBs.filter(dbb => {
      if (dbb !== action.db) {
        return true;
      }
    }),
    message: action.error,
    loading: false
  });
}

const setCurrentItem = (state, action) => {
  let currentState = {...state.itemCodes};
  let currentOpenedDBs = [...state.openedDBs];
  const itm = action.itemcode,
        finalItem = itm ? itm.indexOf('_') !== -1 ? itm.split('_')[0] : itm : itm;
  console.log('finalItem',finalItem);
  currentState[action.companydb] = finalItem;
  if (state.openedDBs.indexOf(action.companydb) === -1) {
    currentOpenedDBs.push(action.companydb);
  }
  return updateObject(state, {
    itemCodes: currentState,
    openedDBs: currentOpenedDBs
  });
}

const showBrandSubgroupList = (state, action) => {
  return updateObject(state, {
    brands: action.data.brands,
    subgroups: action.data.subgroups,
    packagingtypes: action.data.packagingtypes
  });
}

const showBPNames = (state, action) => {
  let prevBPNames = {...state.bpNames};
  prevBPNames[action.result.CardCode] = action.result.CardName;
  return updateObject(state, {
    bpNames: prevBPNames
  });
}

const emptyList = (state, action) => {
  return updateObject(state, {
    itemSearchResults: [],
  });
}

const emptyItemByDB = (state, action) => {
  let newObj = {};
  let emptyDataFields = {
    base_item_description: '',
    itemCodes: '',
    brand: 'n/a',
    subgroup: 'n/a',
    brand: '',
    subgroup: '',
    basic_packaging_unit: '',
    product_type: '',
    rbo: '',
    sub_group: '',
    ipackagingtypes: '',
    item_is: '',
    sales_unit: '',
    basic_packaging_unit: '',
    qty_per_unit: '',
    ilength: '',
    width: '',
    weight: '',

    bmasterarray: {},
    smasterarray: {},
    picks: '',

    cterm: '',
    ccur: '',
    cstart: '',
    ccode: '',
    cend: '',
    cprice: '',

    cqtys: [],
    cterms: [],
    ccurs: [],
    ccodes: [],
    cprices: [],
    cstarts: [],
    cends: [],

    gterm: '',
    gstart: '',
    gend: '',
    gcur: '',
    gcode: '',
    gprice: '',

    gterms: [],
    gprices: [],
    gstarts: [],
    gends: [],
    gcurrs: [],
    gcodes: [],
    gqtys: [],
    preferredVendor: '',

    baseItem: [],
    variants: [],
    variantsDesc: [],
    originalVariants: [],
    originalVariantsDesc: [],
    variantsDeactivate: [],
    deactivateds: [],

    rms: [],
    rmsCopy: [],
    rmsd: [],
    rmsq: [],
    rmsw: [],
    specialmoc: [],
    mocs: {},
    BOMItemBaseQty: '',
  };

  if (action.db.indexOf('NIN') !== -1) {
    emptyDataFields.gst_relevant = ''; // Yes or No
    emptyDataFields.material_type = ''; // Select - get options
    emptyDataFields.hsn_code = ''; // input
    emptyDataFields.gst_tax_category = ''; // Select - get options
    emptyDataFields.service_acct_code = ''; // input
  }

  Object.keys(emptyDataFields).forEach((field) => {
    newObj[field] = state[field] ? cloneDeep(state[field]) : {};
    newObj[field][action.db] = emptyDataFields[field];

    const originalField = `${field}_original`;
    if (state.hasOwnProperty(originalField)) {
      newObj[originalField] = cloneDeep(state[originalField]);
      newObj[originalField][action.db] = emptyDataFields[field];
    }
  });

  let pickedCopy = { ...state.picked };
  Object.keys(pickedCopy).forEach(pc => {
    if (pickedCopy[pc] === action.db) {
      delete pickedCopy[pc];
    }
  });
  newObj.picked = pickedCopy;
  newObj.variantsChecked = {};

  return updateObject(state, newObj);
}

const setAddNewGinItem = (state, action) => {
  let newItem = {...state.newItem};
  newItem[action.db] = null
  return updateObject(state, {
    newItem
  });
}

const addNewGINItem = (state, action) => {
  const d = action.data;
  const itemFields = [
    'base_item_description',
    'product_type',
    'rbo',
    'brand',
    'subgroup',
    'ipackagingtypes',
    'item_is',

    'sales_unit',
    'basic_packaging_unit',
    'qty_per_unit',

    'itemCodes',

    'ilength',
    'width',
    'weight',
    'picks',
    'weight',

    'variants',
    'variantsDesc',
    'originalVariantsDesc',
    'variantsDeactivate',
    'deactivateds',

    'bmasterarray',
    'smasterarray',

    'cterm',
    'ccur',
    'cstart',
    'ccode',
    'cend',
    'cprice',

    'cqtys',
    'cterms',
    'ccurs',
    'ccodes',
    'cprices',
    'cstarts',
    'cends',

    'gterm',
    'gstart',
    'gend',
    'gcur',
    'gcode',
    'gprice',

    'gterms',
    'gprices',
    'gstarts',
    'gends',
    'gcurrs',
    'gcodes',
    'gqtys',
    'preferredVendor',

    'rms',
    'rmsCopy',
    'rmsd',
    'rmsq',
    'rmsw',
    'BOMItemBaseQty',

    'specialmoc'
  ];
  let arr = {};
  itemFields.forEach((itm) => {
    let oldObj = {...state[itm]};
    oldObj[d.db] = d[itm] ? d[itm] : '';
    arr[itm] = oldObj;
  });
  return updateObject(state, arr);
}

const addToOpenedDBsList = (state, action) => {
  const updatedObj = [...state.openedDBs, action.db];
  return updateObject(state, {
    openedDBs: updatedObj
  });
}

const removeFromOpenedDBsList = (state, action) => {
  return updateObject(state, {
    openedDBs: state.openedDBs.filter((o) => o !== action.db)
  });
}

const editModeOn = (state, action) => {
  return updateObject(state, {
    editMode: true
  });
}

const editModeOff = (state, action) => {
  return updateObject(state, {
    editMode: false
  });
}

const setGINEdit = (state, action) => {
  return updateObject(state, {
    editData: action.data
  });
}

const updateGINEdit = (state, action) => {
  if(action.data.db !== undefined){
    let newData = cloneDeep(state[action.data.field]);
    const newDataPicked = cloneDeep(state.picked) || {};
    newDataPicked.variants = true;
    newData[action.data.db] = action.data.value;
    return updateObject(state, {
      [action.data.field]: newData,
      picked: newDataPicked
    });
  }

  let newData = cloneDeep(state.picked) || {};
  newData[action.data.field] = action.data.value;
  return updateObject(state, {
    picked: newData
  });
}

const fetchGINOptionsStarts = (state, action) => {
  return updateObject(state, {
    loadingOptions: true,
    loadingOptionsDB: action.db
  });
}

const fetchGINOptionsSuccess = (state, action) => {
  console.log('options received', action.data, action.db);
  let newOptions = {...state.options};
  let data = cloneDeep(action.data);
  if (data.currencies) {
    data.currencies.forEach((d,i,dd) => {
      if (!d.curr) {
        dd[i].curr = d.symb;
      }
    });
  }
  let rmsNew = [];
  if (data.rms) {
    rmsNew = data.rms.filter((rm,i) => {
      if (rm.ItemCode) {
        //if (rm.ItemCode.substring(0,3) === 'RM:') {
          return true;
        //}
      }
    });
  }
  data.rms = rmsNew;
  newOptions[action.db] = data;
  return updateObject(state, {
    options: newOptions,
    loadingOptions: false,
    loadingOptionsDB: null
  });
}

const fetchGINOptionsFail = (state, action) => {
  return updateObject(state, {
    loadingOptions: false,
    loadingOptionsDB: null
  });
}

const editTableModeOn = (state, action) => {
  return updateObject(state, {
    editTableMode: true
  });
}

const editTableModeOff = (state, action) => {
  return updateObject(state, {
    editTableMode: false
  });
}

const setEditTable = (state, action) => {
  let newData = cloneDeep(state.editTableData);
  console.log('newData Edit', action.data.type);
  newData.type = action.data.type;
  newData.DB = action.data.DB;
  newData.itemName = action.data.itemName;
  return updateObject(state, {
    editTableData: newData
  });
}

const updateEditTable = (state, action) => {
  console.log('updating the table', action.data);
  const mode = action.mode;
  const db = action.data.db;
  if (mode === 'variants') {
    console.log('updating variants');
    let newVariants = cloneDeep(state.variants),
        newVariantsDesc = cloneDeep(state.variantsDesc);
    newVariants[db] = action.data.variants;
    newVariantsDesc[db] = action.data.variantsDesc;
    return updateObject(state, {
      variants: newVariants,
      variantsDesc: newVariantsDesc
    });
  } else if (mode === 'bom') {
    let new_rms = cloneDeep(state.rms),
        new_rmsd = cloneDeep(state.rmsd),
        new_rmsq = cloneDeep(state.rmsq),
        new_rmsw = cloneDeep(state.rmsw);
    new_rms[db] = action.data.rms;
    new_rmsd[db] = action.data.rmsd;
    new_rmsq[db] = action.data.rmsq;
    new_rmsw[db] = action.data.rmsw;
    return updateObject(state, {
      rms: new_rms,
      rmsd: new_rmsd,
      rmsq: new_rmsq,
      rmsw: new_rmsw
    });
  }
}

const editTable2ModeOn = (state, action) => {
  return updateObject(state, {
    editTable2Mode: true
  });
}

const editTable2ModeOff = (state, action) => {
  return updateObject(state, {
    editTable2Mode: false
  });
}

const setEditTable2 = (state, action) => {
  let newData = cloneDeep(state.editTable2Data);
  newData.type = action.data.type;
  newData.DB = action.data.DB;
  newData.itemName = action.data.itemName;
  newData.currentPricingCustomer = action.data.currentPricingCustomer;
  newData.allPricingCustomers = action.data.allPricingCustomers;

  return updateObject(state, {
    editTable2Data: newData
  });
}

const updateEditTable2 = (state, action) => {
  console.log('updating the table', action.data);
  const mode = action.mode;
  const db = action.data.db;
  if (mode === 'pricing_allcustomers') {
    console.log('updating pricing for all customers');
    let new_gterm = cloneDeep(state.gterm),
        new_gcur = cloneDeep(state.gcur),
        new_gprice = cloneDeep(state.gprice),
        new_gstart = cloneDeep(state.gstart),
        new_gend = cloneDeep(state.gend),

        new_gqtys = cloneDeep(state.gqtys),
        new_gterms = cloneDeep(state.gterms),
        new_gcurrs = cloneDeep(state.gcurrs),
        new_gprices = cloneDeep(state.gprices),
        new_gstarts = cloneDeep(state.gstarts),
        new_gends = cloneDeep(state.gends);

    new_gterm[db] = action.data.gterm;
    new_gcur[db] = action.data.gcur;
    new_gprice[db] = action.data.gprice;
    new_gstart[db] = action.data.gstart;
    new_gend[db] = action.data.gend;

    new_gqtys[db] = action.data.gqtys;
    new_gterms[db] = action.data.gterms;
    new_gcurrs[db] = action.data.gcurrs;
    new_gprices[db] = action.data.gprices;
    new_gstarts[db] = action.data.gstarts;
    new_gends[db] = action.data.gends;
    return updateObject(state, {
      gterm: new_gterm,
      gcur: new_gcur,
      gprice: new_gprice,
      gstart: new_gstart,
      gend: new_gend,

      gqtys: new_gqtys,
      gterms: new_gterms,
      gcurrs: new_gcurrs,
      gprices: new_gprices,
      gstarts: new_gstarts,
      gends: new_gends
    });
  }
}

const fetchItemMOCsStart = (state, action) => {
  return updateObject(state, {
    loading: true,
  });
}

const fetchItemMOCsSuccess = (state, action) => {
  console.log('action mocs', action.data.mocs);
  const mocs_result = action.data.mocs;
  const db = action.data.db,
        mocsObj = mocs_result ? Object.keys(mocs_result.mocs).length > 0 ? mocs_result.mocs : {}  : {};

  let mocs = { ...state.mocs };
  mocs[db] = mocsObj;
  return updateObject(state, {
    mocs,
    loading: false
  });
}

const fetchItemMOCsFail = (state, action) => {
  console.log('error getting MOCs',action.err);
  return updateObject(state, {
    loading: false
  });
}

const fetchItemMOCsAllStart = (state, action) => {
  return updateObject(state, {
    loading: true,
  });
}

const fetchItemMOCsAllSuccess = (state, action) => {
  console.log('action mocs all', action.mocs);
  let mocs = { ...state.mocs };
  Object.keys(action.mocs).forEach(db => {
    if (action.mocs[db]) {
      mocs[db] = action.mocs[db];
    }
  });
  return updateObject(state, {
    mocs,
    loading: false
  });
}

const fetchItemMOCsAllFail = (state, action) => {
  console.log('error getting MOCs All',action.err);
  return updateObject(state, {
    loading: false
  });
}

const setVariantStatus = (state, action) => {
  return updateObject(state, {
    variantStatus: action.status
  });
}

const setVariantsChecked = (state, action) => {
  return updateObject(state, {
    variantsChecked: action.checked
  });
}

const addVariant = (state, action) => {
  return updateObject(state, {
    variantsToAdd: state.variantsToAdd.concat([action.variant])
  });
}

const addVariantEdit = (state, action) => {
  return updateObject(state, {
    variantsToAdd: state.variantsToAdd.map((variant,i) => {
      if (i === action.index) {
        return action.variant;
      } else {
        return variant;
      }
    })
  });
}

const addVariantDelete = (state, action) => {
  return updateObject(state, {
    variantsToAdd: state.variantsToAdd.filter((variant,i) => {
      if (i === action.index) {
        return false;
      }
      return true;
    })
  });
}

const ginSetImage = (state, action) => {
  return updateObject(state, {
    ginImage: action.img
  });
}

const ginSetCrop = (state, action) => {
  return updateObject(state, {
    ginCrop: action.crop
  });
}

const ginSetFinalCrop = (state, action) => {
  return updateObject(state, {
    ginFinalCrop: action.crop
  })
}

const ginSetImageExt = (state, action) => {
  return updateObject(state, {
    ginSetImageExt: action.imgExt
  })
}

const ginSendImageSuccess = (state, action) => {
  return state;
}

const ginSendImageFail = (state, action) => {
  return state;
}

const ginSendItemStart = (state, action) => {
  return updateObject(state, {
    ginSendItemStatus: null,
    ginSendItemLoading: true,
    finishedImporting: false,
    currentImportDB: action.db,
    currentImportDB2: action.orig_db,
    tableCount: action.tableCount ? action.tableCount : 0
  });
}

const ginSetTableCount = (state, action) => {
  return updateObject(state, {
    tableCount: action.tableCount ? action.tableCount : 0
  });
}

const ginSendItemSuccess = (state, action) => {
  return updateObject(state, {
    ginSendItemStatus: action.response,
    ginSendItemLoading: false,
    ginSendItemDB: action.db,
    currentImportDB: '',
    tableCount: 0
  });
}

const ginSendItemFail = (state, action) => {
  console.log('error sending', action.err);
  return updateObject(state, {
    ginSendItemStatus: 'There was an error sending the Item Data',
    ginSendItemLoading: false,
    ginSendItemDB: action.db
  });
}

const ginDeactivateStart = (state, action) => {
  return updateObject(state, {
    ginSendItemLoading: true
  });
}

const ginDeactivateStop = (state, action) => {
  return updateObject(state, {
    ginSendItemLoading: false
  });
}

const ginReactivateStart = (state, action) => {
  return updateObject(state, {
    ginSendItemLoading: true
  });
}

const ginReactivateStop = (state, action) => {
  return updateObject(state, {
    ginSendItemLoading: false
  });
}

const ginNewItemAdd = (state, action) => {
  return updateObject(state, {
    newItemList: state.newItemList.concat([action.db])
  });
}

const ginNewItemRemove = (state, action) => {
  return updateObject(state, {
    newItemList: state.newItemList.filter(db => {
      if (db === action.db) {
        return false;
      }
      return true;
    })
  });
}

const ginSetGMP = (state, action) => {
  return updateObject(state, {
    gmpCheckbox: action.gmp
  });
}

const ginSetGINNum = (state, action) => {
  return updateObject(state, {
    ginNum: action.ginnum
  });
}

const ginCheckExistsSuccess = (state, action) => {
  return updateObject(state, {
    exists: action.count ? action.count.status > 0 ? true : false : false
  });
}

const ginCheckExistsFail = (state, action) => {
  console.log('error getting gin check');
  return state;
}

const setAllGIN = (state, action) => {
  return updateObject(state, action.dataObj);
}

const updateGINMultiple = (state, action) => {
  return updateObject(state, action.data);
}

const cancelLoading = (state, action) => {
  return updateObject(state, {
    loading: false
  });
}

const ginStartImporterStart = (state, action) => {
  return updateObject(state, {
    startImporter: true,
    ginSendItemLoading: true
  });
}

const ginStartImporterSuccess = (state, action) => {
  console.log('response from importer', action.response);
  return updateObject(state, {
    startImporter: false,
    ginSendItemLoading: false
  });
}

const ginFinishImporting = (state, action) => {
  return updateObject(state, {
    finishedImporting: true
  });
}

const ginRefresh = (state, action) => {
  return updateObject(state, initialStateCopy);
}

const clearGINmessage = (state, action) => {
  return updateObject(state, {
    message: ''
  });
}

const ginSetDonePickValues = (state, action) => {
  return updateObject(state, {
    hasDonePicks: action.bool
  });
}

const ginCheckImporterTableSuccess = (state, action) => {
  return updateObject(state, {
    tableCount: action.count['COUNT(*)']
  });
}

const ginGetFailedImportSuccess = (state, action) => {
  return updateObject(state, {
    importerData : action.data
  });
}

const ginGetFailedImportFail = (state, action) => {
  return updateObject(state, {
    importerDataErr: action.err
  });
}

const ginStartQSuccess = (state, action) => {
  console.log('successfully started GIN Q', action.response);
  return state;
}

const ginStartQFail = (state, action) => {
  console.log('failed to start GIN Q', action.err);
  return state;
}

const ginGatherAutoSaveData = (state, action) => {
  let saveData = cloneDeep(state);
  const fields = ['ginImage','ginCrop','ginFinalCrop','ginImageExt','autoSaveData','saveData'];
  fields.forEach(field => saveData[field] = null);
  ['brands','subgroups','nin_tax','autoSaveDataList','dbList','currentAutoSaveID','itemSearchResults','loadPreviewData','ginExistingData'].forEach(field => delete saveData[field]);
  saveData.options = {};
  console.log('all to be saved', saveData);
  return updateObject(state, {
    saveData: JSON.stringify(saveData)
  });
}

const ginAutoSaveStart = (state, action) => {
  return updateObject(state, {
    autoSaveLoad: true,
    autoSaveLoadMsg: null
  });
}

const ginAutoSaveEnd = (state, action) => {
  return updateObject(state, {
    autoSaveLoad: false,
    autoSaveLoadMsg: null,
    saveData: null
  });
}

const ginAutoSaveFail = (state, action) => {
  return updateObject(state, {
    autoSaveLoad: false
  });
}

const ginAutoSaveFail2 = (state, action) => {
  console.log('GIN check fail', action);
  if (action.err) {
    if (typeof action.err === 'string') {
      if (action.err.indexOf('locked') !== -1) {
        return updateObject(state, {
          autoSaveLoad: false,
          autoSaveLoadMsg: action.err
        });
      }
    }
  }
  return updateObject(state, {
    autoSaveLoad: false,
    autoSaveLoadMsg: null
  });
}

const ginAutoSaveSuccess = (state, action) => {
  console.log('autosave ID here ?', action.response);
  return updateObject(state, {
    currentAutoSaveID: action.response.id ? action.response.id : null
  });
}

const ginAutoSaveLoadSuccess = (state, action) => {
  if (action.data) {
    if (action.data[0]) {
      const converted = JSON.parse(action.data[0].data);
      let finalConversion = JSON.parse(converted);
      finalConversion.currentAutoSaveID = action.id;
      if (state.ginImage) {
        finalConversion.ginImage = state.ginImage;
        finalConversion.ginFinalCrop = state.ginFinalCrop;
        finalConversion.ginFinalCrop_original = state.ginFinalCrop_original;
      }
      console.log('converted', typeof converted, finalConversion);
      return updateObject(state, finalConversion);
    }
  }
  return state;
}

const ginAutoSaveLoadAllSuccess = (state, action) => {
  return updateObject(state, {
    autoSaveDataList: action.data
  });
}

const ginAutoSaveDeleteSuccess = (state, action) => {
  console.log('autosavedelete success', action.response);
  return state;
}

const ginAutoSaveDeleteFail = (state, action) => {
  console.log('autosavedelete fail', action.err);
  return state;
}

const ginLoadImageSuccess = (state, action) => {
  console.log('response from image', action.response);
  if (action.response.image) {
    const image = `data:image/${action.response.extension};base64,${action.response.image}`;
    return updateObject(state, {
      ginImage: image,
      ginFinalCrop: image,
      ginFinalCrop_original: image
    });
  } else {
    return state;
  }
}

const ginRemoveBOMStart = (state, action) => {
  return updateObject(state, {
    removeBOM: true
  })
}

const ginRemoveBOMSuccess = (state, action) => {
  return updateObject(state, {
    removeBOM: false
  })
}

const ginRemoveBOMFail = (state, action) => {
  return updateObject(state, {
    removeBOM: false
  })
}

const ginRemoveCostStart = (state, action) => {
  return updateObject(state, {
    removeCost: true
  })
}

const ginRemoveCostSuccess = (state, action) => {
  return updateObject(state, {
    removeCost: false
  })
}

const ginRemoveCostFail = (state, action) => {
  return updateObject(state, {
    removeCost: false
  })
}

const ginGetExistingStart = (state, action) => {
  return updateObject(state, {
    ginExistingData: null,
    ginExistingLoad: true
  });
}

const ginGetExistingSuccess = (state, action) => {
  console.log('action response', action.result);
  return updateObject(state, {
    ginExistingData: action.result,
    ginExistingLoad: false
  });
}

const ginGetExistingFail = (state, action) => {
  return updateObject(state, {
    ginExistingData: null,
    ginExistingLoad: false
  });
}

const ginLoadExistingStart = (state, action) => {
  return updateObject(state, {
    ginExistingLoad: true,
    existingLoadFinal: true,
  });
}

const ginLoadExistingSuccess = (state, action) => {
  return updateObject(state, {
    ginExistingLoad: false,
    existingLoadFinal: false,
    gmpCheckbox: action.gmp ? action.gmp : false,
    gmpCheckbox_original: action.gmp ? action.gmp : false,
    existingGINsBefore: action.existingGINsBefore
  });
}

const ginLoadExistingFail = (state, action) => {
  return updateObject(state, {
    ginExistingLoad: false,
    existingLoadFinal: false,
  });
}

const setGINloading = (state, action) => {
  return updateObject(state, {
    loading: action.bool
  });
}

const loadExistingImage = (state, action) => {
  return updateObject(state, {
    existingImageURL: action.imageURL
  });
}

const loadPreviewDataStart = (state, action) => {
  return updateObject(state, {
    loadPreview: true,
    loadPreviewData: null
  })
}

const loadPreviewDataSuccess = (state, action) => {
  console.log('collection 2', action);
  const collection = {
    itemCodes: {},
    options: {},

    // GENERAL AND MEASUREMENTS
    base_item_description: {},
    product_type: {},
    rbo: {},
    brand: {},
    subgroup: {},
    ipackagingtypes: {},
    sales_unit: {},
    basic_packaging_unit: {},
    qty_per_unit: {},
    ilength: {},
    width: {},
    weight: {},

    // SPECIAL FIELDS
    picks: {},
    // NIN DB - Tax
    gst_relevant: {},
    material_type: {},
    hsn_code: {},
    gst_tax_category: {},
    service_acct_code: {},

    // PRICING FOR ALL
    gcode: {},
    gcur: {},
    gstart: {},
    gend: {},
    gprice: {},
    gterm: {},
    gcodes: {},
    gterms: {},
    gstarts: {},
    gends: {},
    gprices: {},
    gcurrs: {},
    gqtys: {},
    mocs: {},
    specialmoc: {},

    // CUSTOMER SPECIFIC PRICING
    bmasterarray: {},

    // TARGET COST
    cprice: {},
    cterm: {},
    ccur: {},
    cstart: {},
    cend: {},
    ccode: {},
    ccodes: {},
    cqtys: {},
    cterms: {},
    ccurs: {},
    cprices: {},
    cstarts: {},
    cends: {},

    // SUPPLIER COST
    smasterarray: {},
    preferredVendor: {},

    // BOM
    rms: {},
    rmsd: {},
    rmsq: {},
    rmsw: {},
    BOMItemBaseQty: {},
    comp: {},
    item_is: {},

    // VARIANTS
    variants: {},
    variantsDesc: {},
    variantsChecked: {},
    variantsToAdd: {},
    originalVariantsDesc: {},
    deactivateds: {},

    gmp_checkbox: {}
  };
  const exceptions = ['itemCodes','options','gmp_checkbox','variantsChecked','variantsToAdd','originalVariantsDesc','deactivateds'];
  Object.keys(collection).forEach(field => {
    activeDBs.forEach(dbb => {
      collection[field][dbb.name] = '';
      if (exceptions.indexOf(field) === -1) {
        const added = `${field}_original`;
        if (!collection.hasOwnProperty(added)) {
          collection[added] = {};
        }
        collection[added][dbb.name] = '';
      }
    })
  })
  if (collection.subgroup_original) {
    collection.sub_group_original = JSON.parse(JSON.stringify(collection.subgroup_original));
  }

  collection.openedDBs = [];
  collection.newItemList = [];
  //
  const dat = action.data;
  Object.keys(dat).forEach(db => {
    const item = action.data[db];
    collection.itemCodes[db] = item.iname.split(' ')[0];
    collection.openedDBs.push(db);
    collection.options[db] = { rbos: action.rbos };

    // GENERAL
    const base_item_description = item['iname'] ? item['iname'].substring(item['iname'].indexOf(" ") + 1).toUpperCase() : '';
    collection.base_item_description[db] = base_item_description;
    collection.base_item_description_original[db] = base_item_description;

    collection.product_type[db] = item.igc;
    collection.product_type_original[db] = item.igc;

    collection.rbo[db] = item.man;
    collection.rbo_original[db] = item.man;
    collection.brand[db] = item.ibrand ? item.ibrand : 'n/a';
    collection.brand_original[db] = item.ibrand ? item.ibrand : 'n/a';
    collection.subgroup[db] =  item.isubgroup ? item.isubgroup : 'n/a';
    collection.subgroup_original[db] = item.isubgroup ? item.isubgroup : 'n/a';
    collection.ipackagingtypes[db] = item.ipackagingtypes ? item.ipackagingtypes : 'n/a';
    collection.ipackagingtypes_original[db] = item.ipackagingtypes ? item.ipackagingtypes : 'n/a';

    collection.sales_unit[db] = item.salesu;
    collection.sales_unit_original[db] = item.salesu;
    collection.basic_packaging_unit[db] = item.salespu;
    collection.basic_packaging_unit_original[db] = item.salespu;

    collection.qty_per_unit[db] = item.salesqpp || item.salesqpp === 0 ? parseInt(item.salesqpp).toString() : '';
    collection.qty_per_unit_original[db] = item.salesqpp || item.salesqpp === 0 ? parseInt(item.salesqpp).toString() : '';

    collection.ilength[db] = item.sul || item.sul === 0 ? parseFloat(item.sul).toString() : '';
    collection.ilength_original[db] = item.sul || item.sul === 0 ? parseFloat(item.sul).toString() : '';

    collection.width[db] = item.suw || item.suw === 0 ? parseFloat(item.suw).toString() : '';
    collection.width_original[db] = item.suw || item.suw === 0 ? parseFloat(item.suw).toString() : '';

    collection.weight[db] = item.sulb || item.sulb === 0 ? parseFloat(item.sulb).toString() : '';
    collection.weight_original[db] = item.sulb || item.sulb === 0 ? parseFloat(item.sulb).toString() : '';

    collection.picks[db] = item.picks;
    collection.picks_original[db] = item.picks;

    if (db.indexOf('NIN') !== -1) {
      collection.gst_relevant[db] = item.gstrel;
      collection.gst_relevant_original[db] = item.gstrel;

      collection.material_type[db] = item.mattype;
      collection.material_type_original[db] = item.mattype;

      collection.hsn_code[db] = item.hsn ? item.hsn.toUpperCase() : '';
      collection.hsn_code_original[db] = item.hsn ? item.hsn.toUpperCase() : '';

      collection.gst_tax_category[db] = item.gsttax;
      collection.gst_tax_category_original[db] = item.gsttax;

      collection.service_acct_code[db] = item.sac ? item.sac.toUpperCase() : '';
      collection.service_acct_code_original[db] = item.sac ? item.sac.toUpperCase() : '';
      console.log('entered NIN', collection);
    }

    // VARIANTS
    collection.variants[db] = item.vars;
    collection.variantsDesc[db] = item.vards;
    collection.deactivateds[db] = item.deactivateds;

    // PRICING FOR ALL
    collection.gterm[db] = item.gterm;
    collection.gterm_original[db] = item.gterm;
    collection.gstart[db] = item.gstart;
    collection.gstart_original[db] = item.gstart;
    collection.gend[db] = item.gend;
    collection.gend_original[db] = item.gend
    collection.gprice[db] = parseFloat(item.gprice);
    collection.gprice_original[db] = parseFloat(item.gprice);
    collection.gcur[db] = item.gcur;
    collection.gcur_original[db] = item.gcur;
    collection.gcode[db] = item.gcode;
    collection.gcode_original[db] = item.gcode;

    let mocs = {};
    if (item.mocs) {
      Object.keys(item.mocs).forEach((mc) => {
        mocs[mc] = !isNaN(item.mocs[mc]) ? parseFloat(item.mocs[mc]) : item.mocs[mc];
      });
    }
    collection.mocs[db] = mocs;

    collection.specialmoc[db] = item.specialmoc ? item.specialmoc.map((arr) => {
      return [arr[0], !isNaN(arr[1]) ? parseFloat(arr[1]) : arr[1], arr[2]];
    }) : '';

    collection.gterms[db] = item.gterms;
    collection.gprices[db] = item.gprices ? item.gprices.map((p) => {
      return p ? parseFloat(p) : 0;
    }) : '';
    collection.gstarts[db] = item.gstarts ? item.gstarts : [];
    collection.gends[db] = item.gends ? item.gends : [];
    collection.gcurrs[db] = item.gcurrs;
    collection.gcodes[db] = item.gcodes;
    collection.gqtys[db] = item.gqtys;

    // CUSTOMER PRICING
    let pricingCustomers = {};
    item.bmasterarray.forEach((b) => {
      let asplit = b.split(' :: ');
      let cardcode = asplit[1];
      let bsplit = asplit[0].split('--');
      bsplit.forEach((bs) => {
        let row = bs.split(' | ');
        if (pricingCustomers.hasOwnProperty(cardcode)) {
          pricingCustomers[cardcode].push(row);
        } else {
          pricingCustomers[cardcode] = [row];
        }
      });
    });
    collection.bmasterarray[db] = pricingCustomers;
    collection.bmasterarray_original[db] = pricingCustomers;

    // SUPPLIER COSTS
    let costingSupplier = {}, preferredSupp = '';
    item.smasterarray.forEach((s,i) => {
      let asplit = s.split(' :: ');
      let cardcode = asplit[1];
      if (i == 0 && cardcode) {
        preferredSupp = cardcode.split('[.]')[0];
      }
      let bsplit = asplit[0].split('--');
      bsplit.forEach((bs) => {
        let row = bs.split(' | ');
        if (costingSupplier.hasOwnProperty(cardcode)) {
          costingSupplier[cardcode].push(row);
        } else {
          costingSupplier[cardcode] = [row];
        }
      });
    });
    collection.smasterarray[db] = costingSupplier;
    collection.smasterarray_original[db] = costingSupplier;
    collection.preferredVendor[db] = preferredSupp;
    collection.preferredVendor_original[db] = preferredSupp;

    // BOM
    collection.rms[db] = item.rms;
    collection.rms_original[db] = item.rms;

    collection.rmsd[db] = item.rmsd;
    collection.rmsd_original[db] = item.rmsd;

    const rmsq = item.rmsq.map((qty) => {
      return parseInt(qty).toString();
    })
    collection.rmsq[db] = rmsq;
    collection.rmsq_original[db] = rmsq;

    collection.rmsw[db] = item.rmsw;
    collection.rmsw_original[db] = item.rmsw;

    collection.BOMItemBaseQty[db] = item.bomq ? parseFloat(item.bomq).toString() : null;
    collection.BOMItemBaseQty_original[db] = item.bomq ? parseFloat(item.bomq).toString() : null;

    // SOURCE
    let itemIs = 'None',
        hasBOM = false, // Produced in House
        hasCost = false; // Outsourced;
    const cprice = item.cprice,
          smasterarray = item.smasterarray,
          rms = item.rms;

    if (smasterarray) {
      if (cprice || smasterarray.length > 0) {
        hasCost = true;
      }
    }

    if (rms) {
      if (rms[0]) {
        hasBOM = true;
      }
    }

    if (hasBOM) itemIs = 'Produced in House';
    if (hasCost) itemIs = 'Outsourced';
    if (hasBOM && hasCost) itemIs = 'Both';

    collection.item_is[db] = itemIs;
    collection.item_is_original[db] = itemIs;

    // TARGET COST
    collection.cterm[db] = item.cterm;
    collection.cterm_original[db] = item.cterm;

    collection.ccur[db] = item.ccur;
    collection.ccur_original[db] = item.ccur;

    collection.cprice[db] = item.cprice ? parseFloat(item.cprice) : item.cprice;
    collection.cprice_original[db] = item.cprice ? parseFloat(item.cprice) : item.cprice;

    collection.cstart[db] = item.cstart;
    collection.cstart_original[db] = item.cstart;

    collection.cend[db] = item.cend;
    collection.cend_original[db] = item.cend;
    collection.ccode[db] = item.ccode;
    collection.ccode_original[db] = item.ccode;

    collection.cqtys[db] = item.cqtys;
    collection.cqtys_original[db] = item.cqtys;

    collection.cterms[db] = item.cterms;
    collection.cterms_original[db] = item.cterms;

    collection.ccurs[db] = item.ccurrs;
    collection.ccurs_original[db] = item.ccurrs;

    collection.ccodes[db] = item.ccodes;
    collection.ccodes_original[db] = item.ccodes;

    const cprices = item.cprices.map((p) => {
      return p ? parseFloat(p) : 0;
    });
    collection.cprices[db] = cprices;
    collection.cprices_original[db] = cprices;

    collection.cstarts[db] = item.cstarts ? item.cstarts : [];
    collection.cstarts_original[db] = item.cstarts ? item.cstarts : [];

    collection.cends[db] = item.cends ? item.cends : [];
    collection.cends_original[db] = item.cends ? item.cends : [];

    collection.gmp_checkbox[db] = item.gmp_checkbox;
  })

  const db = collection.openedDBs[0];
  collection.picked = {
    base_item_description: db,
    product_type: db,
    rbo: db,
    brand: db,
    subgroup: db,
    ipackagingtypes: db,

    sales_unit: db,
    basic_packaging_unit: db,
    qty_per_unit: db,
    ilength: db,
    width: db,
    weight: db,
  }

  // VARIANTS
  const variantsChecked = {};
  collection.openedDBs.forEach(dbb => {
    collection.variants[dbb].forEach((v,i) => {
      const field = `${v[0]}_${collection.variantsDesc[dbb][i]}`;
      if (!variantsChecked.hasOwnProperty(field)) {
        variantsChecked[field] = 1;
      }
    })
  })
  collection.variantsChecked = variantsChecked;
  collection.variantsToAdd = [];
  collection.gmp = collection.gmp_checkbox[db];

  console.log('collection', collection);
  return updateObject(state, {
    loadPreview: false,
    loadPreviewData: collection
  })
}

const loadPreviewDataFail = (state, action) => {
  return updateObject(state, {
    loadPreview: false
  })
}

const setLoadingGIN = (state, action) => {
  return updateObject(state, {
    loadingDB: action.db
  })
}

const setExistingImageURL = (state, action) => {
  return updateObject(state, {
    existingImageURL: action.imageURL
  })
}

const ginSendItemNoStartSuccess = (state, action) => {
  return updateObject(state, {
    itemSentManually: true
  })
}

const ginSendItemNoStartFail = (state, action) => {
  return updateObject(state, {
    itemSentManually: false
  })
}

const ginSendItemNoStartSet = (state, action) => {
  return updateObject(state, {
    itemSentManually: action.itemSentManually
  })
}

const ginSavePendingSuccess = (state, action) => {
  console.log('pending success')
  return state;
}

const ginLoadPendingStart = (state, action) => {
  return updateObject(state, {
    loadPending: true,
    pendingError: null
  })
}

const ginLoadPendingSuccess = (state, action) => {
  return updateObject(state, {
    loadPending: false,
    pendingData: action.data,
    pendingError: null
  })
}

const ginLoadPendingFail = (state, action) => {
  return updateObject(state, {
    loadPending: false,
    pendingData: [],
    pendingError: action.err
  })
}

const ginSetType = (state, action) => {
  return updateObject(state, {
    ginType: action.ginType
  })
}

const ginCheckPendingStart = (state, action) => {
  return updateObject(state, {
    ginCheckCount: null
  })
}

const ginCheckPendingSuccess = (state, action) => {
  return updateObject(state, {
    ginCheckCount: action.count
  })
}

const ginCheckPendingFail = (state, action) => {
  console.log('checkpending err', action.err)
  return updateObject(state, {
    ginCheckCount: null
  })
}

const ginSaveHistoryStart = (state, action) => {
  return updateObject(state, {
    loadPreview: true
  })
}

const ginSaveHistorySuccess = (state, action) => {
  return updateObject(state, {
    loadPreview: false
  })
}

const ginSaveHistoryFail = (state, action) => {
  console.log('error getting history', action.err);
  return updateObject(state, {
    loadPreview: false
  })
}

const ginLoadHistoryStart = (state, action) => {
  return updateObject(state, {
    loadPreview: true,
    historyData: []
  })
}

const ginLoadHistorySuccess = (state, action) => {
  return updateObject(state, {
    loadPreview: false,
    historyData: action.response
  })
}

const ginLoadHistoryFail = (state, action) => {
  console.log('error loading history', action.err)
  return updateObject(state, {
    loadPreview: false
  })
}

const ginFilterHistoryDate = (state, action) => {
  let startDate = null,
      endDate = null,
      historyFilterErrMsg = '';
  if (action.startDate) {
    const startArr = action.startDate.split('/');
    //startDate = new Date(startArr[2], parseInt(startArr[0])-1, startArr[1], 0);
    startDate = moment(action.startDate, 'MM-DD-YYYY hh:mm a').valueOf();
  }
  if (action.endDate) {
    const endArr = action.endDate.split('/');
    //endDate = new Date(endArr[2], parseInt(endArr[0])-1, endArr[1], 24);
    endDate = moment(action.endDate, 'MM-DD-YYYY hh:mm a').valueOf();
  }

  if (startDate && endDate) {
    if (startDate > endDate || startDate === endDate) {
      return updateObject(state, {
        historyFilterErrMsg: 'Start Date should not be after End Date'
      });
    }
  }

  let newHistoryList = state.historyOriginalList.filter((obj) => {
    const d = new Date(obj.timestamp).getTime();
    if (startDate && !endDate) {
      return d > startDate;
    } else if (!startDate && endDate) {
      return d < endDate;
    } else if (startDate && endDate) {
      return d > startDate && d < endDate;
    } else {
      return true;
    }
  });

  return updateObject(state, {
    historyList: newHistoryList,
    historyStartDate: action.startDate,
    historyEndDate: action.endDate
  });
}

const ginLoadHistoryEntryStart = (state, action) => {
  return updateObject(state, {
    loadPreview: true
  })
}

const ginLoadHistoryEntrySuccess = (state, action) => {
  console.log('load success', action)
  if (action.data) {
    if (action.data[0]) {
      const converted = JSON.parse(action.data[0].data);
      let finalConversion = JSON.parse(converted);
      finalConversion.currentAutoSaveID = action.id;
      console.log('converted', typeof converted, finalConversion);
      if (finalConversion.hasOwnProperty('historyData')) delete finalConversion.historyData;
      finalConversion.loadPreview = false;
      return updateObject(state, finalConversion);
    }
  }
  return state;
}

const ginLoadHistoryEntryFail = (state, action) => {
  return updateObject(state, {
    loadPreview: false
  })
}

const saveChosenGINDetails = (state, action) => {
  return updateObject(state, {
    chosenGINDetails: action.details
  })
}

const setTPPItemType = (state, action) => {
  return updateObject(state, {
    tpp_item_type: action.tpp_item_type
  })
}

const reducer = (state = initialState, action) => {
    console.log('initialState', initialState, state);
    switch (action.type) {
        case actionTypes.FETCH_ITEMLIST_START:
            return setLoading(state, action);
        case actionTypes.FETCH_ITEMLIST_SUCCESS:
            return showItemList(state, action);
        case actionTypes.FETCH_ITEMLIST_FAIL:
            return notifyItemListFail(state, action);
        case actionTypes.FETCH_ITEM:
            return setCurrentItem(state, action);
        case actionTypes.FETCH_ITEM_START:
            return setLoading(state, action);
        case actionTypes.FETCH_ITEM_SUCCESS:
            return showItem(state, action);
        case actionTypes.FETCH_ITEM_COMPARE:
            return compareAll(state, action);
        case actionTypes.FETCH_ITEM_FAIL:
            return notifyItemFail(state, action);
        case actionTypes.FETCH_BRAND_SUBGROUPLIST_SUCCESS:
            return showBrandSubgroupList(state, action);
        case actionTypes.FETCH_BPNAMEBYCODE_SUCCESS:
            return showBPNames(state, action);
        case actionTypes.EMPTY_ITEM_LIST:
            return emptyList(state, action);
        case actionTypes.EMPTY_ITEM_BY_DB:
            return emptyItemByDB(state, action);
        case actionTypes.SET_ADD_NEW_GIN_ITEM:
            return setAddNewGinItem(state, action);
        case actionTypes.ADD_NEW_GIN_ITEM:
            return addNewGINItem(state, action);
        case actionTypes.ADD_TO_OPENEDDBS_LIST:
            return addToOpenedDBsList(state, action);
        case actionTypes.REMOVE_FROM_OPENEDDBS_LIST:
            return removeFromOpenedDBsList(state, action);

        case actionTypes.EDIT_MODE_ON:
            return editModeOn(state, action);
        case actionTypes.EDIT_MODE_OFF:
            return editModeOff(state, action);
        case actionTypes.SET_GIN_EDIT:
            return setGINEdit(state, action);
        case actionTypes.UPDATE_GIN_EDIT:
            return updateGINEdit(state, action);
        case actionTypes.FETCH_GIN_OPTIONS_START:
            return fetchGINOptionsStarts(state, action);
        case actionTypes.FETCH_GIN_OPTIONS_SUCCESS:
            return fetchGINOptionsSuccess(state, action);
        case actionTypes.FETCH_GIN_OPTIONS_FAIL:
            return fetchGINOptionsFail(state, action);

        case actionTypes.EDIT_TABLE_MODE_ON:
          return editTableModeOn(state, action);
        case actionTypes.EDIT_TABLE_MODE_OFF:
          return editTableModeOff(state, action);
        case actionTypes.SET_EDIT_TABLE:
          return setEditTable(state, action);
        case actionTypes.UPDATE_EDIT_TABLE:
          return updateEditTable(state, action);

        case actionTypes.EDIT_TABLE_TWO_MODE_ON:
          return editTable2ModeOn(state, action);
        case actionTypes.EDIT_TABLE_TWO_MODE_OFF:
          return editTable2ModeOff(state, action);
        case actionTypes.SET_EDIT_TABLE_TWO:
          return setEditTable2(state, action);
        case actionTypes.UPDATE_EDIT_TABLE_TWO:
          return updateEditTable2(state, action);

        case actionTypes.FETCH_ITEM_MOCS_START:
          return fetchItemMOCsStart(state, action);
        case actionTypes.FETCH_ITEM_MOCS_SUCCESS:
          return fetchItemMOCsSuccess(state, action);
        case actionTypes.FETCH_ITEM_MOCS_FAIL:
          return fetchItemMOCsFail(state, action);

        case actionTypes.FETCH_ITEM_MOCS_ALL_START:
          return fetchItemMOCsAllStart(state, action);
        case actionTypes.FETCH_ITEM_MOCS_ALL_SUCCESS:
          return fetchItemMOCsAllSuccess(state, action);
        case actionTypes.FETCH_ITEM_MOCS_ALL_FAIL:
          return fetchItemMOCsAllFail(state, action);

        case actionTypes.SAVE_CHOSEN_GIN_DETAILS:
          return saveChosenGINDetails(state, action);

        case actionTypes.SET_VARIANT_STATUS:
          return setVariantStatus(state, action);
        case actionTypes.SET_VARIANTS_CHECKED:
          return setVariantsChecked(state, action);

        case actionTypes.ADD_VARIANT:
          return addVariant(state, action);
        case actionTypes.ADD_VARIANT_EDIT:
          return addVariantEdit(state, action);
        case actionTypes.ADD_VARIANT_DELETE:
          return addVariantDelete(state, action);

        case actionTypes.GIN_SET_IMAGE:
          return ginSetImage(state, action);
        case actionTypes.GIN_SET_CROP:
          return ginSetCrop(state, action);
        case actionTypes.GIN_SET_FINALCROP:
          return ginSetFinalCrop(state, action);
        case actionTypes.GIN_SET_IMAGE_EXT:
          return ginSetImageExt(state, action);

        case actionTypes.GIN_SEND_IMAGE_SUCCESS:
          return ginSendImageSuccess(state, action);
        case actionTypes.GIN_SEND_IMAGE_FAIL:
          return ginSendImageFail(state, action);

        case actionTypes.GIN_SEND_ITEM_START:
          return ginSendItemStart(state, action);
        case actionTypes.GIN_SEND_ITEM_SUCCESS:
          return ginSendItemSuccess(state, action);
        case actionTypes.GIN_SEND_ITEM_FAIL:
          return ginSendItemFail(state, action);

        case actionTypes.GIN_SEND_ITEM_NOSTART_SUCCESS:
          return ginSendItemNoStartSuccess(state, action);
        case actionTypes.GIN_SEND_ITEM_NOSTART_FAIL:
          return ginSendItemNoStartFail(state, action);
        case actionTypes.GIN_SEND_ITEM_NOSTART_SET:
          return ginSendItemNoStartSet(state, action);

        case actionTypes.GIN_NEW_ITEM_ADD:
          return ginNewItemAdd(state, action);
        case actionTypes.GIN_NEW_ITEM_REMOVE:
          return ginNewItemRemove(state, action);

        case actionTypes.GIN_SET_GMP:
          return ginSetGMP(state, action);
        case actionTypes.GIN_SET_GINNUM:
          return ginSetGINNum(state, action);

        case actionTypes.GIN_CHECK_EXISTS_SUCCESS:
          return ginCheckExistsSuccess(state, action);
        case actionTypes.GIN_CHECK_EXISTS_FAIL:
          return ginCheckExistsFail(state, action);
        case actionTypes.SET_ALL_GIN:
          return setAllGIN(state, action);

        case actionTypes.UPDATE_GIN_MULTIPLE:
          return updateGINMultiple(state, action);
        case actionTypes.GIN_CANCEL_LOADING:
          return cancelLoading(state, action);

        case actionTypes.GIN_START_IMPORTER_START:
          return ginStartImporterStart(state, action);
        case actionTypes.GIN_START_IMPORTER_SUCCESS:
          return ginStartImporterSuccess(state, action);

        case actionTypes.GIN_FINISH_IMPORTING:
          return ginFinishImporting(state, action);
        case actionTypes.GIN_REFRESH:
          return ginRefresh(state, action);
        case actionTypes.CLEAR_GIN_MESSAGE:
          return clearGINmessage(state, action);

        case actionTypes.GIN_SET_DONE_PICK_VALUES:
          return ginSetDonePickValues(state, action);

        case actionTypes.GIN_CHECK_IMPORTER_TABLE_SUCCESS:
          return ginCheckImporterTableSuccess(state, action);

        case actionTypes.GIN_SET_TABLE_COUNT:
          return ginSetTableCount(state, action);

        case actionTypes.GIN_GET_FAILED_IMPORT_SUCCESS:
          return ginGetFailedImportSuccess(state, action);
        case actionTypes.GIN_GET_FAILED_IMPORT_FAIL:
          return ginGetFailedImportFail(state, action);
        case actionTypes.GIN_START_Q_SUCCESS:
          return ginStartQSuccess(state, action);
        case actionTypes.GIN_START_Q_FAIL:
          return ginStartQFail(state, action);
        case actionTypes.GIN_SET_FAILED_IMPORT_SUCCESS:
          return state;
        case actionTypes.GIN_SET_FAILED_IMPORT_FAIL:
          return state;

        case actionTypes.GIN_DEACTIVATE_START:
          return ginDeactivateStart(state, action);
        case actionTypes.GIN_DEACTIVATE_SUCCESS:
        case actionTypes.GIN_DEACTIVATE_FAIL:
          return ginDeactivateStop(state, action);

        case actionTypes.GIN_REACTIVATE_START:
          return ginReactivateStart(state, action);
        case actionTypes.GIN_REACTIVATE_SUCCESS:
        case actionTypes.GIN_REACTIVATE_FAIL:
          return ginReactivateStop(state, action);

        case actionTypes.GIN_AUTOSAVE_LOAD_START:
          return ginAutoSaveStart(state, action);

        case actionTypes.GIN_AUTOSAVE_LOAD_END:
          return ginAutoSaveEnd(state, action);

        case actionTypes.GIN_AUTOSAVE_SUCCESS:
          return ginAutoSaveSuccess(state, action);
        case actionTypes.GIN_AUTOSAVE_LOAD_SUCCESS:
          return ginAutoSaveLoadSuccess(state, action);
        case actionTypes.GIN_AUTOSAVE_LOAD_ALL_SUCCESS:
          return ginAutoSaveLoadAllSuccess(state, action);

        case actionTypes.GIN_AUTOSAVE_FAIL:
        case actionTypes.GIN_AUTOSAVE_LOAD_ALL_FAIL:
          return ginAutoSaveFail(state, action);

        case actionTypes.GIN_AUTOSAVE_LOAD_FAIL:
          return ginAutoSaveFail2(state, action);

        case actionTypes.GIN_GATHER_AUTOSAVE_DATA:
          return ginGatherAutoSaveData(state, action);

        case actionTypes.GIN_AUTOSAVE_DELETE_SUCCESS:
          return ginAutoSaveDeleteSuccess(state, action);
        case actionTypes.GIN_AUTOSAVE_DELETE_FAIL:
          return ginAutoSaveDeleteFail(state, action);

        case actionTypes.GIN_LOAD_IMAGE_SUCCESS:
          return ginLoadImageSuccess(state, action);

        case actionTypes.GIN_REMOVE_BOM_START:
          return ginRemoveBOMStart(state, action);
        case actionTypes.GIN_REMOVE_BOM_SUCCESS:
          return ginRemoveBOMSuccess(state, action);
        case actionTypes.GIN_REMOVE_BOM_FAIL:
          return ginRemoveBOMFail(state, action);

        case actionTypes.GIN_REMOVE_COST_START:
          return ginRemoveCostStart(state, action);
        case actionTypes.GIN_REMOVE_COST_SUCCESS:
          return ginRemoveCostSuccess(state, action);
        case actionTypes.GIN_REMOVE_COST_FAIL:
          return ginRemoveCostFail(state, action);

        case actionTypes.GIN_GET_EXISTING_START:
          return ginGetExistingStart(state, action);
        case actionTypes.GIN_GET_EXISTING_SUCCESS:
          return ginGetExistingSuccess(state, action);
        case actionTypes.GIN_GET_EXISTING_FAIL:
          return ginGetExistingFail(state, action);

        case actionTypes.GIN_LOAD_EXISTING_START:
          return ginLoadExistingStart(state, action);
        case actionTypes.GIN_LOAD_EXISTING_SUCCESS:
          return ginLoadExistingSuccess(state, action);
        case actionTypes.GIN_LOAD_EXISTING_FAIL:
          return ginLoadExistingFail(state, action);

        case actionTypes.SET_GIN_LOADING:
          return setGINloading(state, action);

        case actionTypes.LOAD_EXISTING_IMAGE:
          return loadExistingImage(state, action);

        case actionTypes.LOAD_PREVIEW_DATA_START:
          return loadPreviewDataStart(state, action);
        case actionTypes.LOAD_PREVIEW_DATA_SUCCESS:
          return loadPreviewDataSuccess(state, action);
        case actionTypes.LOAD_PREVIEW_DATA_FAIL:
          return loadPreviewDataFail(state, action);
        case actionTypes.SET_LOADING_GIN:
          return setLoadingGIN(state, action);
        case actionTypes.SET_EXISTING_IMAGE_URL:
          return setExistingImageURL(state, action);

        case actionTypes.GIN_SAVE_PENDING_SUCCESS:
          return ginSavePendingSuccess(state, action);

        case actionTypes.GIN_LOAD_PENDING_START:
          return ginLoadPendingStart(state, action);
        case actionTypes.GIN_LOAD_PENDING_SUCCESS:
          return ginLoadPendingSuccess(state, action);
        case actionTypes.GIN_LOAD_PENDING_FAIL:
          return ginLoadPendingFail(state, action);

        case actionTypes.GIN_SET_TYPE:
          return ginSetType(state, action);

        case actionTypes.GIN_CHECK_PENDING_START:
          return ginCheckPendingStart(state, action);
        case actionTypes.GIN_CHECK_PENDING_SUCCESS:
          return ginCheckPendingSuccess(state, action);
        case actionTypes.GIN_CHECK_PENDING_FAIL:
          return ginCheckPendingFail(state, action);

        case actionTypes.GIN_SAVE_HISTORY_START:
          return ginSaveHistoryStart(state, action);
        case actionTypes.GIN_SAVE_HISTORY_SUCCESS:
          return ginSaveHistorySuccess(state, action);
        case actionTypes.GIN_SAVE_HISTORY_FAIL:
          return ginSaveHistoryFail(state, action);

        case actionTypes.GIN_LOAD_HISTORY_START:
          return ginLoadHistoryStart(state, action);
        case actionTypes.GIN_LOAD_HISTORY_SUCCESS:
          return ginLoadHistorySuccess(state, action);
        case actionTypes.GIN_LOAD_HISTORY_FAIL:
          return ginLoadHistoryFail(state, action);

        case actionTypes.GIN_FILTER_HISTORY_DATE:
          return ginFilterHistoryDate(state, action);

        case actionTypes.GIN_LOAD_HISTORY_ENTRY_START:
          return ginLoadHistoryEntryStart(state, action);
        case actionTypes.GIN_LOAD_HISTORY_ENTRY_SUCCESS:
          return ginLoadHistoryEntrySuccess(state, action);
        case actionTypes.GIN_LOAD_HISTORY_ENTRY_FAIL:
          return ginLoadHistoryEntryFail(state, action);

        case actionTypes.SET_TPP_ITEM_TYPE:
          return setTPPItemType(state, action);

        default: return state;
    }
};

export default reducer;
