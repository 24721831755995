import React, { Component } from 'react';
import ReactDOM from "react-dom";
import { connect } from 'react-redux';
import ReactTable from "react-table";
import * as actions from '../../store/actions';
import { formatDate, generateExcel2, commatize } from 'shared/utility';
import moment from 'moment';
import Datetime from 'react-datetime';
import GridContainer from "../../components/Grid/GridContainer.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
import Button from "../../components/CustomButtons/Button.jsx";
import Dvr from "@material-ui/icons/Dvr";
import { FadeLoader, PulseLoader } from 'react-spinners';
import SweetAlert from "react-bootstrap-sweetalert";
import GMPMultipleSelect from 'containers/GMP/GMPMultipleSelect';
import GMPSelect from 'containers/GMP/GMPSelect';

const compareTimestamp = (a,b) => {
  const aTime = new Date(a.timestamp).getTime(),
        bTime = new Date(b.timestamp).getTime();
  if (aTime < bTime) return -1;
  if (aTime > bTime) return 1;
  return 0;
}

class GMPHistoryGroupedList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedBP: null,
      showModal: false,
      loadingHistory: false,
      page: 0,
      pageSize: 10
    }
  }

  componentDidMount() {
    //ReactDOM.findDOMNode(this).scrollIntoView();
  }

  handleFetch = (id, submission_id) => {
    this.props.onFetchGMPHistoryItem(id, submission_id);
  }

  handleDateFilterClick = () => {
    console.log('handling...');
    this.props.openFilter();
  }

  render() {
    const override = {
        display: 'block',
        margin: '0 auto',
        borderColor: 'red'
    };
    const loading = this.props.historyLoading;
    let layout = <div style={{textAlign:'center',margin:'0 auto'}} className="centered-loader">
    <FadeLoader
        style={override}
        sizeUnit={"px"}
        size={150}
        color={'#123abc'}
        loading={true}
    /></div>

    if (!loading && !this.state.loadingHistory) {
      let dbList = this.props.dbList;
      const groupedDat = this.props.historyData;
      const table =
(<div>
    <GridContainer className="padbottom10">
      <GridItem xs={12} sm={12} md={12}>
        <div className="history-group-datefilter">
          <div
            onClick={() => {
            this.handleDateFilterClick();
          }}>
            {
              (this.props.historyStartDate || this.props.historyEndDate) ?
              <div className="datetime-box-enclosure">
                <div style={{fontSize:'11px',textAlign:'center',color:'#585757'}}>Date / Time</div>
                <div style={{fontSize:'11px',color:'#5e5e5e'}} className="datetime-box">
                  <div style={{lineHeight:'1',marginBottom:'5px'}}>
                    {this.props.historyStartDate ? this.props.historyStartDate : 'before'} -</div>
                  <div style={{lineHeight:'1',marginTop:'5px'}}>
                    {this.props.historyEndDate ? this.props.historyEndDate : 'to most recent'}
                  </div>
                </div>
              </div>
               :
              <Button
                color="white"
              >Filter by Date</Button>
            }
          </div>
        </div>
        <div className="history-download-csv-1">
          <Button
            color="warning"
            onClick={() => {
              const currentRecords = this.selectTable.getResolvedState().sortedData;
              console.log(currentRecords, this.props.historyData);
              const groupedData = this.props.historyData;
              let data = [
                ['BP Name','Type','Activity','Date/Time','User','DB']
              ];
              currentRecords.forEach((cr) => {
                const gdata = groupedData[cr._original.bp_id];
                if (gdata) {
                  gdata.data.forEach((dt) => {
                    data.push([
                      dt.company_name,
                      gdata.bp_type,
                      dt.activity,
                      moment(dt.timestamp).format("MM-DD-YYYY hh:mm a"),
                      dt.name,
                      gdata.DB
                    ]);
                  });
                }
              });
              const cols = [
                  {wch:30}, // BP name
                  {wch:10}, // type
                  {wch:10}, // activity
                  {wch:20}, // date time
                  {wch:25}, // user
                  {wch:10} // db
              ]
              generateExcel2({cols,data}, 'GMP_bp_history_group_view.xlsx');
            }}
          >
            Download Excel
          </Button>
        </div>
      </GridItem>
    </GridContainer>
    <div className="table-adjust2 table-adjust3">
      <ReactTable
          defaultSorted={[
            {
              id: "c2",
              desc: false
            }
          ]}
          onPageChange={page => this.setState({ page })}
          onPageSizeChange={(pageSize, page) =>
            this.setState({ page, pageSize })}
          minRows={0}
          ref={(r) => {
            this.selectTable = r;
          }}
          getTrProps={(state, rowInfo) => {
            if (rowInfo && rowInfo.row) {
              return {
                onClick: (e) => {
                  this.props.openHistorySubGroupModal(groupedDat[rowInfo.original.bp_id].data);
                }
              }
            } else {
              return {}
            }
          }}
          data={
              Object.keys(groupedDat).map((g, key) => { // map bp_id
                  return ({
                      bp_id: g,
                      bp_name: groupedDat[g].bp_name,//bp.customer_name ? bp.customer_name : bp.company_name,
                      last_activity: '',//bp.activity,
                      bp_type: groupedDat[g].bp_type,
                      last_submitted: groupedDat[g].last_submitted,
                      last_approved: groupedDat[g].last_approved,
                      last_date: '',//formatDate(bp.timestamp, 'datetime'),
                      last_user: groupedDat[g].last_user,//bp.name,
                      db_name: groupedDat[g].DB,
                      data_length: groupedDat[g].data.length
                  })
              })
          }
          filterable
          columns={[
              {
                  Header: "BP Name",
                  accessor: "bp_name",
                  filterMethod: (filter, row) => {
                    var filterValue = filter.value.toLowerCase();
                    if (filterValue.length > 0) {
                        return row[filter.id].toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;
                    } else {
                        return true;
                    }
                  },
                  Cell: cell => {
                    const d = cell.original;
                    return <div>{d.bp_name}<span style={{float:'right',color:'red',paddingRight:'10px'}}>{d.data_length}</span></div>
                  },
                  minWidth:200
              },
              {
                  Header: "BP Type",
                  accessor: "bp_type",
                  filterMethod: (filter, row) => {
                      const chosen = filter.value;
                      if (chosen.length > 0) {
                          return chosen.indexOf(row[filter.id]) !== -1;
                      } else {
                          return true;
                      }
                  },
                  Filter: ({filter, onChange}) => {
                    console.log('filterssss', this.props.historyFiltered);
                    let defaultValues = null;
                    this.props.historyFiltered.forEach((hF) => {
                      if (hF.id === 'bp_type') {
                        defaultValues = hF.value;
                      }
                    });
                      return <div className="gmp-filter"><GMPSelect
                          options={['Customer','Supplier']}
                          label={''}
                          choose={onChange}
                          defaultValues={defaultValues}
                      /></div>
                  },
                  Cell: cell => {
                      const d = cell.original;
                      return <div style={{textAlign:'center'}}>{d.bp_type}</div>
                  },
                  minWidth: 100
              },
              {
                  id: 'c2',
                  Header: "Last Submitted",
                  accessor: d => {
                      const md = moment(d.last_submitted);
                      if (md.isValid()) {
                        return md.format("MM/DD/YYYY hh:mm a");
                      }
                      return '';
                  },
                  sortMethod: (a, b) => {
                    a = new Date(a).getTime();
                    b = new Date(b).getTime();
                       return b > a ? 1 : -1;
                  },
                  filterMethod: (filter, row) => {
                    console.log(filter, row);
                      const dateFilter = filter.value;
                      if (dateFilter && row.c2) {
                        if (typeof dateFilter !== 'string') {
                          if (dateFilter.format("MM/DD/YYYY") === row.c2.split(' ')[0]) {
                            return true;
                          }
                        }
                      }
                  },
                  Filter: ({filter, onChange}) => {
                    return (
                    <div style={{width:'100%'}}>
                      <Datetime
                        closeOnSelect={true}
                        onChange={onChange}
                        timeFormat={false}
                      />
                    </div>);
                  },
                  maxWidth: 150
              },
              {
                  id: 'c3',
                  Header: "Last Approved",
                  accessor: d => {
                      const md = moment(d.last_approved);
                      if (md.isValid()) {
                        return md.format("MM/DD/YYYY hh:mm a");
                      }
                      return '';
                  },
                  sortMethod: (a, b) => {
                    a = new Date(a).getTime();
                    b = new Date(b).getTime();
                       return b > a ? 1 : -1;
                  },
                  filterMethod: (filter, row) => {
                    console.log(filter, row);
                      const dateFilter = filter.value;
                      if (dateFilter && row.c3) {
                        if (typeof dateFilter !== 'string') {
                          if (dateFilter.format("MM/DD/YYYY") === row.c3.split(' ')[0]) {
                            return true;
                          }
                        }
                      }
                  },
                  Filter: ({filter, onChange}) => {
                    return (
                    <div style={{width:'100%'}}>
                      <Datetime
                        closeOnSelect={true}
                        onChange={onChange}
                        timeFormat={false}
                      />
                    </div>);
                  },
                  maxWidth: 150
              },
              {
                  Header: "Last User",
                  accessor: "last_user",
                  filterMethod: (filter, row) => {
                    var filterValue = filter.value.toLowerCase();
                    if (filterValue.length > 0) {
                        return row[filter.id].toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;
                    } else {
                        return true;
                    }
                  }
              },
              {
                  Header: "DB",
                  accessor: "db_name",
                  filterMethod: (filter, row) => {
                      const chosen = filter.value;
                      if (chosen.length > 0) {
                          return chosen.indexOf(row[filter.id]) !== -1;
                      } else {
                          return true;
                      }
                  },
                  Filter: ({filter, onChange}) => {
                      console.log('filterssss', this.props.historyFiltered);
                      let defaultValues = null;
                      this.props.historyFiltered.forEach((hF) => {
                        if (hF.id === 'db_name') {
                          defaultValues = hF.value;
                        }
                      });
                      return <div className="gmp-filter"><GMPMultipleSelect
                          options={dbList}
                          label={''}
                          choose={onChange}
                          defaultValues={defaultValues}
                      /></div>
                  },
                  maxWidth: 100
              }
          ]}
          defaultPageSize={10}
          showPaginationTop
          showPaginationBottom
          className="-striped -highlight"
          filtered={this.props.historyFiltered}
          onFilteredChange={filtered => {
              console.log('this is the filtered',filtered);
              this.props.updateGMPHistoryFilter(filtered);
            }}
      >
      {(state, makeTable, instance) => {
          let recordsInfoText = "";

          const { filtered, pageRows, pageSize, sortedData, page } = state;

          if (sortedData && sortedData.length > 0) {
            let isFiltered = filtered.length > 0;

            let totalRecords = sortedData.length;

            let recordsCountFrom = page * pageSize + 1;

            let recordsCountTo = recordsCountFrom + pageRows.length - 1;

            if (isFiltered)
              recordsInfoText = `Showing ${recordsCountFrom}-${recordsCountTo} of ${totalRecords.toLocaleString()} filtered submissions`;
            else
              recordsInfoText = `Showing ${recordsCountFrom}-${recordsCountTo} of ${totalRecords.toLocaleString()} submissions`;
          } else recordsInfoText = "No activity";

          return (
            <div className="main-grid">
              {makeTable()}
              <div style={{marginTop:'30px'}}>
                <div style={{textAlign:'center'}}>{recordsInfoText}</div>
              </div>
            </div>
          );
        }}
      </ReactTable>
  </div>
</div>);

      layout = (
        <div style={{paddingLeft:'20px',paddingRight:'20px'}}>
              {!this.state.showModal && table}
      </div>);
    } else if (!loading && this.state.loadingBP) {
      layout = (
      <GridContainer justify="center" style={{
        margin: 0,
        paddingBottom: '40px',
        width: '100%',
      }}>
              <div>
                {this.state.loadingBP && <div style={{textAlign:'center'}}>Loading GMP History<PulseLoader /></div>}
              </div>
      </GridContainer>);
    }
    return layout;
  }
}

const mapStateToProps = (state) => {
  let groupedData = {};
  if (state.gmp.historyList) {
    if (state.gmp.historyList.length > 0) {
      console.log('historyList',state.gmp.historyList);
      state.gmp.historyList.forEach((u) => {
          let company_name = u.company_name ? u.company_name : u.customer_name,
                db = u.DB ? u.DB.trim() : '';

          if (company_name && db) {
            company_name = company_name.trim();
            let h = { ...u };
            h.bp_type = u.bp_type;
            const combo = `${company_name},${db},${u.bp_type}`;
            if (groupedData.hasOwnProperty(combo)) {
              groupedData[combo].data.push(h);
            } else {
              groupedData[combo] = {
                data: [h],
                bp_type: u.bp_type
              };
            }
          }
      });

      Object.keys(groupedData).forEach((combo) => {
        groupedData[combo].data = groupedData[combo].data.sort(compareTimestamp);
        const gdat = groupedData[combo].data,
              last = gdat.length - 1;
        let last_submitted = '', last_approved = '', last_user = '';
        gdat.forEach((gd) => {
          if (gd.activity.indexOf('Approve') !== -1 || gd.activity.indexOf('Direct to') !== -1) {
            last_approved = gd.timestamp;
          }
          if ((gd.activity.indexOf('Receive') !== -1 || gd.activity.indexOf('Sent to Pending') !== -1) || gd.activity.indexOf('Direct to') !== -1) {
            last_submitted = gd.timestamp;
          }
          last_user = gd.name;
        });
        groupedData[combo].last_user = last_user;
        groupedData[combo].last_approved = last_approved;
        groupedData[combo].last_submitted = last_submitted;
        groupedData[combo].bp_name = gdat[last].company_name ? gdat[last].company_name : gdat[last].customer_name;
        groupedData[combo].DB = gdat[last].DB;
        //console.log(combo, groupedData[combo]);
      });
    }
  }
  console.log('grouped data',groupedData);

  return {
    historyData: groupedData,
    historyLoading: state.gmp.historyLoading,
    historyItemData: state.gmp.historyItemData,
    historyStartDate: state.gmp.historyStartDate,
    historyEndDate: state.gmp.historyEndDate,
    historyFiltered: state.gmp.historyFiltered,
    dbList: state.gmp.historyDBList,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchGMPHistory: () => dispatch(actions.fetchGMPHistory()),
    onFetchGMPHistoryItem: (id, submission_id) => dispatch(actions.fetchGMPHistoryItem(id, submission_id)),
    filterGMPHistoryDate: (startDate, endDate) => dispatch(actions.filterGMPHistoryDate(startDate, endDate)),
    updateGMPHistoryFilter: (filtered) => dispatch(actions.updateGMPHistoryFiltered(filtered)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GMPHistoryGroupedList);
