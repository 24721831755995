import React, { Component } from 'react';
import ReactDOM from "react-dom";
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import withStyles from "@material-ui/core/styles/withStyles";
import styles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import ReactTable from "react-table";
import GridItem from "components/Grid/GridItem.jsx";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TableSortLabel, Input, InputAdornment, Icon } from '@material-ui/core';
import Button from "components/CustomButtons/Button.jsx";
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomInputSearch from "components/CustomInput/CustomInputSearch.jsx";
import { getDescription, trimData } from "./GINhelpers";

import activeDBs from "shared/activeDBs";
import GINalert from "./GINalert";
import GINask2 from "./GINask2";

import _ from 'lodash';

const checkIfEmpty = (val) => {
  if (!val) return false;
  if (val) {
    if (val === 'n/a') {
      return false;
    }
    if (val === 'N/A') {
      return false;
    }
  }
  return true;
}

const checkIfExists = (obj, fieldName) => {
  let exists = false;
  // check if fieldName exists
  const arr = Object.keys(obj);
  if (arr.indexOf(fieldName) !== -1) {
    if (obj[fieldName]) {
      exists = true;
    }
  }
  return exists;
}

const allEqual = arr => arr.every( v => v === arr[0] );

const compare = (field) => {
  return function compared( a, b ) {
    if ( a[field] < b[field] ){
      return -1;
    }
    if ( a[field] > b[field] ){
      return 1;
    }
    return 0;
  }
}

const lowerFirstString = (string) => {
    return string.charAt(0).toLowerCase() + string.slice(1);
}

const DBlabel = props => {
  // props.name
  let backgroundColor = '';
  activeDBs.forEach((db) => {
    if (db.name === props.name) {
      backgroundColor = db.color;
    }
  });

  if (backgroundColor) {
    return (
      <div style={{backgroundColor}} className="db-pill margin-center">
        {props.name.split('_')[0]}
      </div>
    );
  }
  return null;
}

const TableTab = props => {
  return (
    <div
      className={props.selected ? 'orange-bg white-text small-btn' : 'white-bg small-btn'}
      onClick={() => {
        props.setTab(props.tabName);
      }}
    >{props.label}</div>
  )
}

const CellCheckbox = props => {
  const classes = props.classes;
  console.log('label',props.label);
  let addedText = '';
  if (props.name === 'ilength' || props.name === 'width') {
    addedText = ' mm';
  } else if (props.name === 'weight') {
    addedText = ' g';
  }
  return (
    <div style={{textAlign:'left', minWidth: 'max-content'}}>
      {/*<span className={props.currentTab ? '' : 'gray-text'}>{props.label} {addedText}</span>*/}
      <span style={{opacity:props.index === 0 ? 1 : 0}}>
        <Checkbox
          tabIndex={-1}
          checkedIcon={<Check className={classes.checkedIcon} />}
          icon={<Check className={classes.uncheckedIcon} />}
          classes={{checked: classes.checked}}
          checked={props.checked}
          onClick={() => {
            props.clickCheckbox(`${props.name}Checked`, props.label);
          }}
        />
      </span>
    </div>
  )
}

const variableListA = ['salesUnit','basicPackagingUnit','qtyPerUnit','ilength','width','weight'];
const variableListB = ['SalesUnit','BasicPackagingUnit','QtyPerUnit','Ilength','Width','Weight'];
const variableListC = ['sales_unit','basic_packaging_unit','qty_per_unit','ilength','width','weight'];
const variableListD = ['sales unit','basic packaging unit','qty per unit','length','width','weight'];

class GINpickValuesMeasurements extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showSalesUnit: true,
      showBasicPackagingUnit: false,
      showQtyPerUnit: false,
      showIlength: false,
      showWidth: false,
      showWeight: false,

      salesUnitChecked: '',
      basicPackagingUnitChecked: '',
      qtyPerUnitChecked: '',
      ilengthChecked: '',
      widthChecked: '',
      weightChecked: '',

      alert: '',
      ask: false,
      filter: '',
      order: {
        active: 'db',
        direction: 'desc'
      },
      fieldsForSave: null
    }
  }

  openAsk = () => {
    this.setState({
      ask: true
    });
  }

  closeAsk = () => {
    this.setState({
      ask: false
    });
  }

  openAlert = (title) => {
    this.setState({
      alert: title
    });
  }

  closeAlert = () => {
    this.setState({
      alert: ''
    });
  }

  checkAllValues = (field) => {
    let valuesArr = [];
    console.log('checking all fields',this.props[field]);
    this.props.openedDBs.forEach(db => {
      const val = this.props[field][db];
      if (val) {
        if (valuesArr.indexOf(val) === -1) {
          valuesArr.push(val);
        }
      }
      return this.props[field][db] ? this.props[field][db] : '';
    });
    console.log('resulting arr',valuesArr);
    return valuesArr;
  }

  getTableData = () => {
    const props = this.props;
    console.log('gin props',props);
    let field = 'ilength';
    if (this.state.showWidth) {
      field = 'width';
    } else if (this.state.showWeight) {
      field = 'weight';
    } else if (this.state.showSalesUnit) {
      field = 'salesUnit';
    } else if (this.state.showBasicPackagingUnit) {
      field = 'basicPackagingUnit';
    } else if (this.state.showQtyPerUnit) {
      field = 'qtyPerUnit';
    }
    let dataObj = {};
    this.props.openedDBs.forEach(db => {
      const ilength = props.ilength[db] ? props.ilength[db] : 0,
            width = props.width[db] ? props.width[db] : 0,
            weight = props.weight[db] ? props.weight[db] : 0,
            salesUnit = props.salesUnit[db] ? props.salesUnit[db] : 0,
            basicPackagingUnit = props.basicPackagingUnit[db] ? props.basicPackagingUnit[db] : 0,
            qtyPerUnit = props.qtyPerUnit[db] ? props.qtyPerUnit[db] : 0;

      const val = props[field][db];
      let passedFilter = true;
      if (this.state.filter && val) {
        if (val.toUpperCase().indexOf(this.state.filter.toUpperCase()) === -1) {
          passedFilter = false;
        }
      }
      if (passedFilter) {
        if (dataObj.hasOwnProperty(val)) {
          dataObj[val].push(db);
        } else {
          dataObj[val] = [db];
        }
      }
    });
    console.log('grouped by similar data', dataObj);
    return dataObj;
  }

  setTab = (tabName) => {
    if(_.indexOf(variableListC, tabName) !== -1){
      tabName = variableListB[_.indexOf(variableListC, tabName)];
    }

    const toHide = variableListB.filter(tab => {
      if (tab !== tabName) {
        return true;
      }
    });
    let obj = {};
    obj[`show${tabName}`] = true;
    toHide.forEach(tab => {
      obj[`show${tab}`] = false;
    });
    this.setState(obj);
  }

  clickCheckbox = (fieldName, value) => {
    const obj = {
      [fieldName]: value
    };

    // this.setState({
    //   fieldsForSave: obj
    // }, () => {
    //   this.openAsk();
    // });

    this.setState({
      [fieldName]: value
    }, () => {
      this.save();
    });
  }

  preSave = () => {
    this.setState(this.state.fieldsForSave, () => {
      this.save();
    });
  }

  startValueCheck = () => {
    variableListA.forEach(field => {
      const valuesArr = this.checkAllValues(field);
      if (valuesArr.length === 1) {
        this.setState({
          [`${field}Checked`]: valuesArr[0]
        })
      }
    });
  }

  returnCurrentTab = (type) => {
    if (type === 'variable') {
      let currentTab;
      variableListB.forEach(tabName => {
        if (this.state[`show${tabName}`]) {
          currentTab = lowerFirstString(tabName);
        }
      });
      return currentTab;
    } else if (type === 'field') {
      let currentTab;
      variableListB.forEach((tabName,i) => {
        if (this.state[`show${tabName}`]) {
          currentTab = variableListC[i];
        }
      });
      return currentTab;
    } else {
      let currentTabName;
      variableListB.forEach((tabName,i) => {
        if (this.state[`show${tabName}`]) {
          currentTabName = variableListD[i].toUpperCase();
        }
      });
      return currentTabName;
    }
  }

  save = () => {
    variableListA.forEach((fieldName,i) => {
      console.log('checked', fieldName.toUpperCase(), this.returnCurrentTab('name').toUpperCase());
      if (fieldName.toUpperCase() === this.returnCurrentTab('variable').toUpperCase()) {
        let copy = JSON.parse(JSON.stringify(this.props[fieldName]));
        const value = this.state[`${fieldName}Checked`];
        const field = variableListC[i];
        if (value) {
          this.props.openedDBs.forEach(db => {
            if(this.props[variableListA[i]][db] === value){
              this.props.updateGINEdit({
                value: db,
                field
              });
            }
          });
        }
      }
      // this.props.reportStatus({ measurementsComplete: true });
      // this.openAlert('Saved!');
    });
  }

  checkComplete = () => {
    let isComplete = true;
    variableListA.forEach(field => {
      if (!this.state[`${field}Checked`]) {
        isComplete = false;
      }
    });
    if (!isComplete) {
      // alert
      this.openAlert('All fields need to be completed.');
    } else {
      this.openAsk();
    }
  }

  onInputChange = (e) => {
    this.setState({
      filter: e.target.value.toUpperCase()
    });
  }

  sortIt = (type) => {
    this.setState({
      order: { active: type, direction: this.state.order.direction === 'asc' ? 'desc' : 'asc' }
    });
  }

  generateInputData = (o) => {
    const valueField = o.valueField ? o.valueField : null;
    const descField = o.descField ? o.descField : null;
    let desc = null;
    if (valueField) {
      desc = getDescription(o.value, o.options, o.valueField, o.descField);
    }
    let addText = '';
    if (o.addText) {
      addText = o.addText;
    }
    return (<span
            className="hovered hover-label"
            onClick={() => {
                this.props.editModeOn();
                this.props.setEdit({
                  itemName: o.name,
                  db: o.db,
                  field: o.field,
                  value: (o.value === 'N/A' || o.value === 'n/a') ? '' : o.value,
                  desc,
                  type: o.type,
                  options: o.options,
                  valueField,
                  descField,
                  addText,
                  similars: o.similars,
                  validations: o.validations,
                  setPicked: true
                });
            }}
          >
            {!valueField && <span>{(o.value && (o.value !== 'n/a' && o.value !== 'N/A')) ? o.value+addText : '- none -'}</span>}
            {(valueField && desc) && <span>{desc}</span>}
            {(valueField && !desc) && <span>n/a</span>}
          </span>);
  }

  generateObj = (db, type) => {
    console.log('generating', db, type);
    const props = this.props;
    if (type === 'sales_unit') {
      return {
        name: 'Sales Unit',db,
        field: 'sales_unit',
        value: props.sales_unit[db] ? props.sales_unit[db] : '',
        options: ['PIECE','DOZEN','GROSS','THOUSAND','METER','TARD','ROLL'],
        type: 'option1'
      }
    } else if (type === 'basic_packaging_unit') {
      return {
        name: 'Basic Packaging Unit',db,
        field: 'basic_packaging_unit',
        value: this.props.basic_packaging_unit[db] ? this.props.basic_packaging_unit[db] : '',
        options: ['TRAY','CARTON','BUNDLE','ROLL'],
        type: 'option1'
      }
    } else if (type === 'qty_per_unit') {
      return {
        name: 'Qty per Packing UOM',db,
        field: 'qty_per_unit',
        value: this.props.qty_per_unit[db] ? this.props.qty_per_unit[db] : '',
        type: 'input',
        validations: {
          numOnly: true,
          wholeNumOnly: true,
          maxNum: 100000
        }
      }
    } else if (type === 'ilength') {
      return {
        name: 'Length',db,
        field: 'ilength',
        value: this.props.ilength[db] ? this.props.ilength[db] : '',
        type: 'input',
        addText: ' mm',
        similars: null,
        validations: {
          numOnly: true,
          wholeNumOnly: true,
          maxNum: 100000
        }
      }
    } else if (type === 'width') {
      return {
        name: 'Width',db,
        field: 'width',
        value: this.props.width[db] ? this.props.width[db] : '',
        type: 'input',
        addText: ' mm',
        validations: {
          numOnly: true,
          wholeNumOnly: true,
          maxNum: 100000
        }
      }
    } else if (type === 'weight') {
      return {
        name: 'Weight',db,
        field: 'weight',
        value: this.props.weight[db] ? this.props.weight[db] : '',
        type: 'input',
        addText: ' g',
        validations: {
          numOnly: true,
          minMax3Decimals: true,
          maxNum: 100000
        }
      }
    }
  }

  componentWillMount() {
    this.props.onRef(this);

    _.each(variableListC, (field, i) => {
      const objToUpdate = {};
      if(typeof this.props.picked[field] !== 'undefined'){
        Object.defineProperty(objToUpdate, `${variableListA[i]}Checked`, {
          writable: true,
          enumerable: true,
          value: this.props[variableListA[i]][this.props.picked[field]]
        })
      }

      this.setState(objToUpdate);
    })

    _.each(variableListA, (tab, i) => {
      let dataToGroup = _.map(_.pickBy(this.props[tab], _.identity), (v, k) => ({k, v}));

      const data = _.map(_.values(_.groupBy(dataToGroup, 'v')), v => _.map(v, g => g.k));

      if(data.length === 1){
        const obj = {};

        Object.defineProperty(obj, `${tab}Checked`, {
          writable: true,
          enumerable: true,
          value: this.props[tab][data[0][0]]
        })

        console.log(obj);

        this.setState(obj);

        this.props.updateGINEdit({
          value: data[0][0],
          field: variableListC[i]
        });
      }
    });
  }

  render() {
    console.log('state check', this.state, this.props);
    if(!this.props.active){
      return <div />;
    }

    const tableData = this.getTableData();
    const tableDataSorted = this.state.order.direction === 'asc' ?
                              Object.keys(tableData).sort() : Object.keys(tableData).sort().reverse()
    const { classes } = this.props;
    const currentTab = this.returnCurrentTab('variable'),
          currentField = this.returnCurrentTab('field'),
          currentTabName = this.returnCurrentTab('name'),
          pickedObj = this.props.picked;
    return (
    <div style={{marginTop:'20px'}}>
      {this.state.alert && <GINalert
          hide={this.closeAlert}
          content={this.state.alert}
        />
      }
      {this.state.ask && <GINask2
          command={this.save}
          title="Update to checked values?"
          content="NOTE: This action will not save to SAP yet."
          close={this.closeAsk}
        />
      }
      <GridContainer>
        <GridItem xs={12} sm={1} md={2}>
          <div className="text-center">{checkIfExists(pickedObj, 'sales_unit') ? <div className="blue-text">Completed</div> :
                                                                        <div className="red-text">Required</div>}</div>
          <div className="text-center">
            <TableTab
              selected={this.state.showSalesUnit}
              label="SALES UNIT"
              setTab={this.setTab}
              tabName="SalesUnit"
            />
          </div>
        </GridItem>
        <GridItem xs={12} sm={1} md={2}>
          <div className="text-center">{checkIfExists(pickedObj, 'basic_packaging_unit') ? <div className="blue-text">Completed</div> :
                                                                        <div className="red-text">Required</div>}</div>
          <div className="text-center">
            <TableTab
              selected={this.state.showBasicPackagingUnit}
              label="BASIC PACKAGING UNIT"
              setTab={this.setTab}
              tabName="BasicPackagingUnit"
            />
          </div>
        </GridItem>
        <GridItem xs={12} sm={1} md={2}>
          <div className="text-center">{checkIfExists(pickedObj, 'qty_per_unit') ? <div className="blue-text">Completed</div> :
                                                                        <div className="red-text">Required</div>}</div>
          <div className="text-center">
            <TableTab
              selected={this.state.showQtyPerUnit}
              label="QTY PER PACKING UOM"
              setTab={this.setTab}
              tabName="QtyPerUnit"
            />
          </div>
        </GridItem>
        <GridItem xs={12} sm={1} md={2}>
          <div className="text-center">{checkIfExists(pickedObj, 'ilength') ? <div className="blue-text">Completed</div> :
                                                                        <div className="red-text">Required</div>}</div>
          <div className="text-center">
            <TableTab
              selected={this.state.showIlength}
              label="LENGTH"
              setTab={this.setTab}
              tabName="Ilength"
            />
          </div>
        </GridItem>
        <GridItem xs={12} sm={1} md={2}>
          <div className="text-center">{checkIfExists(pickedObj, 'width') ? <div className="blue-text">Completed</div> :
                                                                      <div className="red-text">Required</div>}</div>
          <div className="text-center">
            <TableTab
              selected={this.state.showWidth}
              label="WIDTH"
              setTab={this.setTab}
              tabName="Width"
            />
          </div>
        </GridItem>
        <GridItem xs={12} sm={1} md={2}>
          <div className="text-center">{checkIfExists(pickedObj, 'weight') ? <div className="blue-text">Completed</div> :
                                                                    <div className="red-text">Required</div>}</div>
          <div className="text-center">
            <TableTab
              selected={this.state.showWeight}
              label="WEIGHT"
              setTab={this.setTab}
              tabName="Weight"
            />
          </div>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  DB
                </TableCell>
                <TableCell className="text-center" style={{whiteSpace: 'nowrap'}}>
                  <TableSortLabel
                    active={this.state.order.active === 'desc'}
                    direction={this.state.order.direction}
                    onClick={() => this.sortIt('desc')}
                  >
                  {currentTabName}</TableSortLabel>
                </TableCell>
                <TableCell className="text-center" style={{whiteSpace: 'nowrap'}} colSpan={2}>
                </TableCell>
                <TableCell>
                  <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                    <Button
                      size="sm"
                      color="success"
                      style={{opacity:0}}
                      onClick={() => this.props.onNextClick()}>NEXT</Button>
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableDataSorted.map((data,i) => {
                return (
                  <TableRow key={`tr${i}`}>
                    <TableCell align="left" style={{minWidth: 'max-content'}}>
                    {tableData[data].map((db, j) => {
                      return (
                        <div key={`oo${j}`} style={{marginBottom:'15px',marginTop:'15px'}}>
                          <DBlabel name={db} />
                        </div>
                      )
                    })}
                    </TableCell>
                    <TableCell>
                      {tableData[data].map((db, j) => {
                        console.log('currentField', currentField, currentTab);
                        const inputData = this.generateInputData(this.generateObj(db, currentField));
                        return (
                          <div key={`zz${j}`} style={{marginTop:15,marginBottom:15}}>
                            <div className="gray-text db-pill2" style={{backgroundColor:'white'}}>
                            {inputData}
                            </div>
                          </div>
                        )
                      })}
                    </TableCell>
                    <TableCell align="left" style={{minWidth: 'max-content'}}>
                    {tableData[data].map((db, j) => {
                      console.log('gvm measurements', tableData, db, data, currentTab, this.state[`${currentTab}Checked`])
                      const fieldName = this.returnCurrentTab('field');
                      return (
                        <div key={`jj${j}`}>
                          {data !== 'n/a' && <CellCheckbox
                            name={currentTab}
                            label={data}
                            classes={classes}
                            clickCheckbox={this.clickCheckbox}
                            checked={tableData[data].indexOf(this.props.picked[fieldName]) !== -1 ? true : false}
                            currentTab={true}
                            index={j}
                          />}
                        </div>
                      )
                    })}
                    </TableCell>
                    <TableCell style={{width: '100%'}} />
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </GridItem>
      </GridContainer>
    </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    salesUnit: trimData(state.gin.sales_unit),
    sales_unit: trimData(state.gin.sales_unit),
    basicPackagingUnit: trimData(state.gin.basic_packaging_unit),
    basic_packaging_unit: trimData(state.gin.basic_packaging_unit),
    qty_per_unit: trimData(state.gin.qty_per_unit),
    qtyPerUnit: trimData(state.gin.qty_per_unit),
    ilength: trimData(state.gin.ilength),
    width: trimData(state.gin.width),
    weight: trimData(state.gin.weight),
    openedDBs: state.gin.openedDBs,
    picked: state.gin.picked
  }
};

const mapDispatchToProps = dispatch => {
  return {
    updateGINEdit: (data) => {
      dispatch(actions.updateGINEdit(data));
    },
    editModeOn: () => {
      dispatch(actions.editModeOn());
    },
    setEdit: (data) => {
      dispatch(actions.setGINEdit(data));
    },
  }
}

GINpickValuesMeasurements.defaultProps = {
  onRef: () => {},
  onNextClick: () => {},
  picked: {}
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(GINpickValuesMeasurements));
