import React, { useEffect, useState } from 'react';
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ReactTable from "react-table";
import { options } from './TranslationsObj';

const sortByPreference = (a, b) => (a.preference > b.preference) ? 1 : -1;

const AddButton = (props) => {
    return <div>
        {props.update_permitted && <Button
                          color="success"
                          style={{marginTop:20}}
                          onClick={() => {
                            console.log('creating title', props.title);
                            //props.create(props.title);
                          }}
                        >ADD NEW</Button>}
    </div>
}

const TranslationsResults = (props) => {
    if (!props.data) return null;

    const generateReactTable = (data) => {
        return (
            <ReactTable
                            data={
                                data.map((d, key) => {
                                    const tableType = d.type === 'BASE' ? 'Default' : 'RBO';
                                    return ({
                                    id: key,
                                    did: key+1,
                                    type: tableType,
                                    category: options.categories_obj[d.category],
                                    english: d.ENGLISH,
                                    actionData: {
                                        category: d.category,
                                        type: d.type,
                                        lang: null,
                                        id: d.type === 'BASE' ? d.id : `${d.id}_${d.ENGLISH}`
                                    },
                                    actionsCell: <div>
                                        <Button
                                        color="rose"
                                        onClick={() => {
                                            const trobj = {
                                                category: d.category,
                                                type: d.type,
                                                lang: null,
                                                id: d.type === 'BASE' ? d.id : `${d.id}_${d.ENGLISH}`
                                            };
                                            console.log('trobj', trobj);
                                            props.readTranslation(trobj);
                                            props.openModal({
                                                type: 'EDIT',
                                                data: null
                                            });
                                        }}
                                        >LOAD</Button>
                                    </div>
                                    })
                                })
                            }
                            getTrProps={(state, rowInfo) => {
                                if (rowInfo && rowInfo.row) {
                                  return {
                                    onClick: (e) => {
                                        console.log('state', state, rowInfo)
                                        if (rowInfo.original) {
                                            if (rowInfo.original.actionData) {
                                                const trobj = rowInfo.original.actionData;
                                                props.readTranslation(trobj);
                                                props.openModal({
                                                    type: 'EDIT',
                                                    data: null
                                                });
                                            }
                                        }
                                    }
                                  }
                                } else {
                                  return {}
                                }
                              }}
                            filterable={false}
                            columns={[
                                {
                                    Header: "#",
                                    accessor: "did",
                                    sortable: true,
                                    filterable: true,
                                    maxWidth: 50
                                },
                                {
                                    Header: "Type",
                                    accessor: "type",
                                    sortable: true,
                                    filterable: true,
                                    maxWidth: 100
                                },
                                {
                                    Header: "Category",
                                    accessor: "category",
                                    sortable: true,
                                    filterable: true,
                                    filterMethod: (filter, row) => {
                                      var filterValue = filter.value ? filter.value.toLowerCase() : '';
                                      if (filterValue.length > 0) {
                                          return row[filter.id].toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;
                                      } else {
                                          return true;
                                      }
                                    },
                                    maxWidth: 200
                                },
                                {
                                    Header: "English",
                                    accessor: "english",
                                    sortable: true,
                                    filterable: true,
                                    filterMethod: (filter, row) => {
                                      var filterValue = filter.value ? filter.value.toLowerCase() : '';
                                      if (filterValue.length > 0) {
                                          return row[filter.id].toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;
                                      } else {
                                          return true;
                                      }
                                    },
                                    Cell: row => {
                                        return (
                                          <div style={{paddingTop:10,
                                            paddingBottom:10}}>{row.original.english}</div>
                                        )
                                      },
                                }
                              ]}
                            minRows={0}
                            showPagination={true}
                            className="-highlight highlight-hover"
                            defaultPageSize={10}
                        /> 
        )
    }

    if (props.data) {
        if (props.data.length === 0) {
            return <div>
                <h3><strong>Matches Search Results</strong></h3>
                <p>0 Found</p>
            </div>
        }
    }
    const searchParams = props.currentSearchParams;
    const preference = searchParams.rbo === "-1" ? 'BASE' : 'RBOSpecific';

    const type = props.data[0].type;
    const exact_results = JSON.parse(JSON.stringify(props.data)).filter(d => {
        if (d.is_exact) return true;
    }).map(d => {
        d.preference = preference !== 'BASE' ? 
                                            d.type === 'RBOSpecific' ? 
                                                                    'A' : 
                                                                    'B'
                                            :
                                            d.type === 'RBOSpecific' ? 
                                                                    'B' :
                                                                    'A';

        return d;
    }).sort(sortByPreference);
    const non_exact_results = JSON.parse(JSON.stringify(props.data)).filter(d => {
        if (!d.is_exact) return true;
    }).map(d => {
        d.preference = preference !== 'BASE' ? 
                                            d.type === 'RBOSpecific' ? 
                                                                    'A' : 
                                                                    'B'
                                            :
                                            d.type === 'RBOSpecific' ? 
                                                                    'B' :
                                                                    'A';

        return d;
    }).sort(sortByPreference);
    console.log('preference', preference);
    console.log('exact', exact_results);
    console.log('nonexact', non_exact_results);
    return (
      <div>
        <h3><strong>Search Results</strong></h3>
        <GridContainer>
            {exact_results.length === 0 && <GridItem xs={12} sm={12} md={12}>
                <h3><strong>Exact Matches ({exact_results.length})</strong></h3>
            </GridItem>}
            {exact_results.length > 0 && <GridItem xs={12} sm={12} md={12}>
                <h3><strong>Exact Matches ({exact_results.length})</strong></h3>
                {exact_results.length > 0 && <div>
                    {generateReactTable(exact_results)}
                </div>}
            </GridItem>}
            <GridItem xs={12} sm={12} md={12} style={{marginTop:20}}>
                <h3><strong>Suggested Matches ({non_exact_results.length})</strong></h3>
                {non_exact_results.length > 0 && <div>
                    {generateReactTable(non_exact_results)}
                </div>}
            </GridItem>
        </GridContainer>
      </div>
    )
  }

export default TranslationsResults;
