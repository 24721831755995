import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components
import Button from "components/CustomButtons/Button.jsx";

import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";

import ADUserForm from './ADUserForm/ADUserForm';
import { permsList, permsDict } from 'shared/permsObj';

const renderUserPerms = (props) => {
  const userPerms = props.userPerms ? props.userPerms : {};
  const dbDict = props.DBs;
  const path = props.clickedUser ? props.clickedUser.mail ? '/?q='+props.clickedUser.mail.split('@')[0] : '' : '';
  console.log('user perms',userPerms, permsDict);
  const perms = Object.keys(userPerms).map((perm_id,i) => {
    const p = permsDict[perm_id];
    if (!p) {
      return null;
    }
    let str = '';
    if (userPerms[perm_id][0] !== null) {
      userPerms[perm_id].forEach(p => str += `${p} `)
    }
    if (p.main && (p.subgroup && p.name)) {
      return (
        <div
          className="green-text"
          key={`fii${i}`}
          style={{marginBottom:'10px'}}
        >
          {p.main} - {p.subgroup} - {p.name} {str ? <div style={{fontSize:'10px'}}>`({str})`</div> : ''}
        </div>
      )
    }
  });
  return perms.length > 0 ? <div>
                              <h4><strong>Current User Permissions</strong></h4>
                              <div style={{marginTop:'10px',marginBottom:'10px'}}>
                                <Button
                                  size="sm"
                                  color="rose"
                                  onClick={() => {
                                  props.history.push(`/UserPermissions/${path}`);;
                                }}>Edit Permission</Button>
                              </div>
                              {perms}
                            </div> : '';
}

const Modal = (props) => {
    const addEdit = props.existing ? 'Edit' : 'Add';
    const { classes } = props;
    console.log('USER ADMIN PROPS', props);
    return (
        <div>

            <Dialog
                classes={{
                    root: classes.center,
                    paper: classes.modal
                }}
                style={{overflow:'hidden !important'}}
                open={props.open}
                keepMounted
                onClose={() => props.closeModal()}
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description">
                <DialogTitle
                    id="classic-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}>
                    <Button
                        justIcon
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        color="transparent"
                        onClick={() => props.closeModal()}
                    >
                        <Close className={classes.modalClose} />
                    </Button>
                    <h4 className={classes.modalTitle}>{addEdit} User</h4>
                </DialogTitle>

                    <ADUserForm passwordEdit={props.passwordEdit} existing={props.existing} classes={classes} closeModal={props.closeModal} onSubmit={props.submit} optionsObj={props.optionsObj} change={props.change}/>

                    <div style={{padding:'20px'}}>
                      <div>
                        {renderUserPerms(props)}
                      </div>
                    </div>
            </Dialog>
        </div>
    );
}

Modal.propTypes = {
    classes: PropTypes.object,
    existing: PropTypes.bool,
    open: PropTypes.bool,
    closeModal: PropTypes.func,
    change: PropTypes.func,
    optionsObj: PropTypes.object,
    submit: PropTypes.func
};

const mapStateToProps = state => {
  return {
    userPerms: state.userPerms.userPerms,
    DBs: state.userPerms.DBs
  }
}

export default connect(mapStateToProps, null)(withStyles(modalStyle)(withRouter(Modal)));
