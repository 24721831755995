import React, { Component } from 'react';
import ReactDOM from "react-dom";
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import withStyles from "@material-ui/core/styles/withStyles";
import cloneDeep from "lodash/cloneDeep";
import { convertJPG } from "./croppedImage";
import axios from "axios";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import ZoomIn from "@material-ui/icons/ZoomIn";
import ZoomOut from "@material-ui/icons/ZoomOut";
import CheckCircle from "@material-ui/icons/CheckCircle";

//import ReactCrop from 'react-image-crop'
import Nouislider from "react-nouislider";
import Cropper from 'react-easy-crop'
import getCroppedImg from './croppedImage';

import { extractImageFileExtensionFromBase64 } from './reusableForImage'

const imageMaxSize = 10000000 // bytes
const acceptedFileTypes = 'image/x-png, image/png, image/jpg, image/jpeg'
const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => {return item.trim()})

const getBase64 = (imgUrl, callback) => {
    console.log('getting img', imgUrl);
    var img = new Image();

    // onload fires when the image is fully loadded, and has width and height

  img.onload = function(){
    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    var dataURL = canvas.toDataURL("image/png"),
        dataURL = dataURL.replace(/^data:image\/(png|jpg);base64,/, "");

    callback(dataURL); // the base64 string
    console.log('the dataURL', dataURL);
  };

  img.src = imgUrl;
}

class GINimage extends Component {
    constructor(props){
        super(props)
        this.myImageRef1 = React.createRef();
        this.myImageRef2 = React.createRef();
        this.imagePreviewCanvasRef = React.createRef()
        this.fileInputRef = React.createRef()
        this.state = {
            error: '',
            dimensions: {
              height: 0,
              width: 0
            },
            crop: { x: 0, y: 0 },
            zoom: '1',
            aspect: 1/1,
            edit: false,
            zoomIt: false,
            clickContainer: 'none',
            imageURLdata: null
        }
    }

    componentDidMount() {
      this.props.setClick(this.showCroppedImage);
    }

    verifyFile = (files) => {
        if (files && files.length > 0){
            const currentFile = files[0]
            const currentFileType = currentFile.type
            const currentFileSize = currentFile.size
            if(currentFileSize > imageMaxSize) {
                this.setState({
                  error: "This file is not allowed. " + currentFileSize + " bytes is too large"
                });
                return false
            }
            if (!acceptedFileTypesArray.includes(currentFileType)){
                this.setState({
                  error: "This file is not allowed. Only images are allowed."
                });
                return false
            }
            return true
        }
    }

    handleFileSelect = event => {
        // console.log(event)
        const props = this.props;
        const files = event.target.files
        if (files && files.length > 0){
              const isVerified = this.verifyFile(files)
             if (isVerified){
                 this.props.setImage(null);
                 this.props.setCrop(null);
                 this.props.setImageExt(null);
                 this.props.setFinalCropped(null);
                 this.setState({
                     error: '',
                     dimensions: {
                       height: 0,
                       width: 0
                     },
                     crop: { x: 0, y: 0 },
                     zoom: '1',
                     aspect: 1/1,
                 });
                 // imageBase64Data
                 const currentFile = files[0]
                 const myFileItemReader = new FileReader()
                 myFileItemReader.addEventListener("load", ()=>{
                     const myResult = myFileItemReader.result
                     const ext = extractImageFileExtensionFromBase64(myResult);
                     if (ext === 'jpg' || ext === 'jpeg') {
                       let im = new Image;
                       im.src = myResult;
                       im.onload = () => {
                         this.convertJPGImage(myResult, im.width, im.height);
                       };
                     } else {
                       console.log('result image', myResult);
                       props.setImage(myResult);
                       let im = new Image;
                       im.src = myResult;
                       im.onload = () => {
                         if (im.width === 1000 && im.height === 1000) {
                           this.props.setFinalCropped(myResult);
                           if (this.props.ginFinalCrop && this.props.currentAutoSaveID) {
                             console.log('autosave image 1');
                             this.props.autoSaveImage(this.props.ginFinalCrop, `${this.props.currentAutoSaveID}_${this.props.ginNum}`);
                           }
                           this.setState({ dimensions: { width: im.width, height: im.height }, edit: false });
                         } else {
                           this.setState({ dimensions: { width: im.width, height: im.height } });
                         }
                       };
                       this.props.setImageExt(ext);
                     }

                 }, false)

                 myFileItemReader.readAsDataURL(currentFile);
             }
        }
    }

    convertJPGImage = async (myResult, width, height) => {
      // convert to png
      const converted = await convertJPG(myResult, width, height);
      this.props.setImage(converted);
      let im = new Image;
      im.src = converted;
      im.onload = () => {
        if (im.width === 1000 && im.height === 1000) {
          this.props.setFinalCropped(converted);
          if (this.props.ginFinalCrop && this.props.currentAutoSaveID) {
            console.log('autosave image 1');
            this.props.autoSaveImage(this.props.ginFinalCrop, `${this.props.currentAutoSaveID}_${this.props.ginNum}`);
          }
          this.setState({ dimensions: { width: im.width, height: im.height }, edit: false });
        } else {
          this.setState({ dimensions: { width: im.width, height: im.height } });
        }
      };
      this.props.setImageExt('png');
    }

    onCropChange = crop => {
      this.setState({ crop })
    }

    onCropComplete = (croppedArea, croppedAreaPixels) => {
      console.log('completed crop', croppedArea, croppedAreaPixels)
      this.props.setCrop(croppedAreaPixels);
      this.setState({
        dimensions: {
          height: croppedAreaPixels.height,
          width: croppedAreaPixels.width
        }
      })
    }

    onZoomChange = zoom => {
      if (this.state.zoomIt) {
        this.setState({ zoom }, () => {
          this.setState({ zoomIt: false })
        })
      }
    }

    showCroppedImage = async () => {
      const croppedImage = await getCroppedImg(
        this.props.ginImage,
        this.props.ginCrop,
        this.props.ginImageExt,
        this.state.zoom
      )
      console.log('croppedImageExt', this.props.ginImageExt);
      this.props.setFinalCropped(croppedImage);
      console.log('should I autosave Image', this.props.currentAutoSaveID, this.props.ginFinalCrop, croppedImage);
      setTimeout(() => {
        if (croppedImage && this.props.currentAutoSaveID) {
          console.log('autosave image 2');
          this.props.autoSaveImage(croppedImage, `${this.props.currentAutoSaveID}_${this.props.ginNum}`);
          this.props.setExistingImageURL('');
        }
      }, 1000)
    }

    showCroppedImage2 = async (url) => {
      try {
        const croppedImage = await getCroppedImg(
          url,
          { x: 0, y: 0, width:1000, height:1000 },
          'png',
        );
        this.props.setImage(croppedImage)
      } catch(e) {
        console.log('error setting image', e);
      }
    }

    empty = () => {
      this.props.setImage(null);
      this.props.setCrop(null);
      this.props.setImageExt(null);
      this.props.setFinalCropped(null);
      this.setState({
          error: '',
          dimensions: {
            height: 0,
            width: 0
          },
          crop: { x: 0, y: 0 },
          zoom: '1',
          aspect: 1/1,
          edit: false
      });
      // const canvas = this.imagePreviewCanvasRef.current
      //   const ctx = canvas.getContext('2d');
      //   ctx.clearRect(0, 0, canvas.width, canvas.height)

      if (this.fileInputRef.current) {
        this.fileInputRef.current.value = null
      }
    }

  setNotExistent = () => {
    console.log('file does not exist');
  }

  onLoad = () => {
    console.log('file exists');
    this.props.setFinalCropped(`https://portal.natcoglobal.com/portal/api/item/ftp_gin_image.php?gin=${this.props.ginNum}`);
  }

  getBase64Image = (img) => {
    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    var dataURL = canvas.toDataURL("image/png");
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  }

  render() {
    console.log('pointer', this.state.clickContainer);
    console.log('prpsim', this.props);
    const { imgSrc, crop } = this.state,
          width = this.state.dimensions.width,
          height = this.state.dimensions.height;

    const correctDimensions = (width === 1000 && height === 1000);

    return (
      <div>
        <Card style={{marginTop:10,marginBottom:10}}>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <div style={{padding:20}}>
                  <h3>Upload Image</h3>
                {!this.props.ginImage &&
                  <div style={{marginBottom:'20px'}}>
                    <div>Image size cannot exceed 1MB and should be 1000px x 1000px <em>(you may upload an image of a different size and edit it here to fit)</em></div>
                    <div>Only file types .jpg and .png allowed</div>
                    <div><em>Images not cropped or meeting these dimensions, will not be saved</em></div>
                  </div>
                }
                {
                  (this.props.ginImage && !this.props.ginFinalCrop) && <div style={{marginBottom:'20px'}}>
                    <div className="red-text">Your image is NOT the required size. Please Edit/Crop the image below</div>
                  </div>
                }

                {
                    this.props.ginFinalCrop && <div style={{margin:'20px auto',color:'green'}}>
                    <CheckCircle />
                  </div>
                }
                {(!this.props.ginImage && !this.props.ginFinalCrop) && <div>
                  <input
                    style={{ display: 'none' }}
                    id="raised-button-file"
                    ref={this.fileInputRef}
                    type='file'
                    accept={acceptedFileTypes}
                    multiple={false}
                    onChange={this.handleFileSelect}
                  />
                  <label htmlFor="raised-button-file">
                    <Button variant="raised" component="span" color="success" style={{marginRight:'20px'}}>
                      Upload Image
                    </Button>
                  </label>
                </div>}
                {
                  (this.props.ginImage || this.props.ginFinalCrop) && <Button color="white" style={{marginRight:'20px'}} onClick={() => {
                    this.empty();
                  }}>Clear Image
                  </Button>
                }
                {
                  ((this.props.ginImage || this.props.ginFinalCrop) && !this.state.edit) && <Button color="rose" style={{marginRight:'20px'}} onClick={() => {
                    console.log('ginImage', this.props.ginImage);
                    console.log('ginFinalCrop', this.props.ginFinalCrop);
                    const imgurl = (this.props.ginImage ? this.props.ginImage : this.props.ginFinalCrop);
                    if (imgurl.indexOf('https://') !== -1) {
                      const filename = imgurl.replace(/^.*[\\\/]/, '');
                      axios.get('https://natco-cors-anywhere.crs-consulting.com/?q='+this.props.ginNum, { crossdomain: true })
                        .then((result) => {
                          console.log('r',result)
                          this.props.setImage('data:image/png;base64,'+result.data);
                          this.props.setCrop(null);
                          this.props.setImageExt(null);
                          this.props.setFinalCropped(null);
                          this.setState({
                            edit: true
                          });
                        })
                        .catch((e) => {
                          console.log('error', e);
                        })
                    } else {
                      this.props.setImage(imgurl);
                      this.props.setCrop(null);
                      this.props.setImageExt(null);
                      this.props.setFinalCropped(null);
                      this.setState({
                        edit: true
                      });
                    }

                  }}>Edit Image
                  </Button>
                }
                {
                  (((this.props.ginImage && !this.props.ginFinalCrop) && !this.state.edit) && !this.props.showImageCropper) && <Button color="success" style={{marginRight:'20px'}} onClick={() => {
                    this.setState({
                      edit: true
                    });
                  }}>Edit and Crop Image
                  </Button>
                }
                {
                  (((this.state.edit && this.props.ginImage) && !this.props.ginFinalCrop) || this.props.showImageCropper) && <Button color="success" style={{marginRight:'20px'}} onClick={() => {
                    this.showCroppedImage();
                    this.props.setShowImageCropper(false);
                  }}>Save and Crop Image
                  </Button>
                }
                  {this.state.error && <div className="red-text" style={{marginTop:'20px'}}>{this.state.error}</div>}
                </div>
          </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <div>
            {((this.state.edit && this.props.ginImage !== null) && !this.props.ginFinalCrop) || this.props.showImageCropper ?
          <div>
            <div style={{marginTop:'20px',marginBottom:'40px'}}>
              Drag the line below to the right or left in order to enlarge or shrink your image. You can also move the image around in order to place the part of the image you want to keep inside the highlighted rectangle
            </div>
            <div style={{marginTop:'40px',marginBottom:'20px'}} className="parentable">
              <div className="zoom-out">
                <ZoomOut />
              </div>
              <div className="zoom-in">
                <ZoomIn />
              </div>
              <Nouislider
                start={this.state.zoom}
                connect={[true,false]}
                step={0.01}
                onChange={(zoom, i) => {
                  console.log('z',zoom, i);
                  this.setState({zoomIt:true}, () => {
                    this.onZoomChange(zoom[0]);
                  });
                }}
                range={{ min: 0.025, max: 3 }}
              />
            </div>
            <div
              style={{minHeight:500,maxWidth:1000,margin:'30px auto 0 auto',position:'relative'}}
              onClick={() => {
                this.setState((prevState) => {
                  return { clickContainer: prevState.clickContainer === 'auto' ? 'none' : 'auto' }
                })
              }}
            >
              <div
                className={this.state.clickContainer === 'none' ? 'crop-container' : 'crop-container shadow-box'}
                style={{pointerEvents:this.state.clickContainer}}
              >
                <Cropper
                  image={this.props.ginImage}
                  crop={this.state.crop}
                  showGrid={false}
                  zoom={this.state.zoom}
                  aspect={this.state.aspect}
                  onCropChange={this.onCropChange}
                  onCropComplete={this.onCropComplete}
                  onZoomChange={this.onZoomChange}
                  restrictPosition={false}

                />
              </div>
            </div>
            <div style={{marginTop:5,textAlign:'center'}}>( Click on image to reposition )</div>
          </div>

           :

           null

         }
          </div>
          {
            (!this.props.ginFinalCrop && (this.props.ginImage && !this.state.edit)) &&
            <div style={{marginTop:'20px'}}>
              <img src={this.props.ginImage} style={{maxWidth:'400px',height:'auto',margin:'10px auto',display:'block'}} ref={this.myImageRef1}/>
            </div>
          }
          {
            this.props.ginFinalCrop &&
            <div style={{marginTop:'20px'}} className="ubiquitous">
              <img src={this.props.ginFinalCrop} style={{maxWidth:'400px',height:'auto',margin:'10px auto',display:'block'}} ref={this.myImageRef2}/>
            </div>
          }
        {this.props.ginFinalCrop && <div style={{textAlign:'center',display:'none'}}>
          <Button color="primary" size="sm"
            onClick={() => {
              console.log(this.props.ginImageExt);
              // this.props.ginSendImage(this.props.ginFinalCrop, this.props.ginNum);
              this.props.autoSaveImage(this.props.ginFinalCrop, `${this.props.currentAutoSaveID}_${this.props.ginNum}`);
              this.props.setExistingImageURL('');
            }}
          >SEND TO FTP (TEST ONLY)</Button>
        </div>}
        <img
          src={`https://portal.natcoglobal.com/portal/api/item/ftp_gin_image.php?gin=y${this.props.ginNum}`}
          onError={this.setNotExistent}
          onLoad={this.onLoad}
          style={{opacity:0}}
        />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    ginNum: state.gin.ginNum,
    ginImage: state.gin.ginImage,
    ginImageExt: state.gin.ginImageExt,
    ginCrop: state.gin.ginCrop,
    ginFinalCrop: state.gin.ginFinalCrop,
    currentAutoSaveID: state.gin.currentAutoSaveID,
    existingImageURL: state.gin.existingImageURL
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setImage: (img) => {
      dispatch(actions.ginSetImage(img));
    },
    setImageExt: (imgExt) => {
      dispatch(actions.ginSetImageExt(imgExt));
    },
    setCrop: (crop) => {
      dispatch(actions.ginSetCrop(crop));
    },
    setFinalCropped: (crop) => {
      dispatch(actions.ginSetFinalCrop(crop));
    },
    ginSendImage: (image, ext) => {
      dispatch(actions.ginSendImage(image, ext));
    },
    autoSaveImage: (image, ext) => {
      dispatch(actions.ginAutoSaveImage(image, ext));
    },
    setExistingImageURL: (imageURL) => {
      dispatch(actions.setExistingImageURL(imageURL));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GINimage);
