import React, { Component } from 'react';
import ReactDOM from "react-dom";
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Table from "components/Table/Table.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import { commatize } from 'shared/utility';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import dbList from 'shared/activeDBs';
import NavPills from "components/NavPills/NavPills.jsx";
import Accordion from "components/Accordion/Accordion.jsx";
import Close from "@material-ui/icons/Close";
import Button2 from "components/CustomButtons/Button.jsx";
import SweetAlert from "react-bootstrap-sweetalert";

import Button from "../../components/CustomButtons/Button.jsx";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";

import GINSelect from "./GINselect";

class GINnew extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentItem: '',
      newItemCode: '',
      alertMsg: '',
      selectedVariant: '',

      base_item_description: '',
      product_type: '',
      rbo: '',
      brand: '',
      subgroup: '',
      item_is: '',

      sales_unit: '',
      basic_packaging_unit: '',
      qty_per_unit: '',

      ilength: '',
      width: '',
      weight: '',
      picks: '',

      variants: [],
      variantsDesc: []
    }

    const styles = {
      cardTitle,
      pageSubcategoriesTitle: {
        color: "#3C4858",
        textDecoration: "none",
        textAlign: "center"
      },
      cardCategory: {
        margin: "0",
        color: "#999999"
      }
    };

    const styles2 = theme => ({
      root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
      },
    });

  }

  handleSelectChange = (value, name) => {
    this.setState({
      [name]: value
    })
  }

  generateBPNames = (cardcodes, db) => {
    cardcodes.forEach((cc) => {
      this.props.fetchBPName(cc, db);
    });
  }

  hideAlert = () => {
    this.setState({
      alertMsg: ''
    })
  }

  selectTable = (name, variantsArr, db) => {
    if (name == 'variants') {
      let newDescArr = [],
          newArr = [];
      variantsArr.forEach((v) => {
        newArr.push([v[0], v[2]]);
        newDescArr.push(v[1]);
      });
      this.setState({
        variants: newArr,
        variantsDesc: newDescArr,
        selectedVariant: db
      })
    }
  }

  renderSelectTables = {
    variants: () => {
      const comp = this.props.comp;
      let tables = null;
      if (comp.variants) {
        tables = Object.keys(comp.variants).map((b, i) => {
          const dbs = comp.variants[b].map(val => val.split('_')[0]);
          const variantsArr = JSON.parse(b);
          const newVariantsArr = variantsArr.map(v => [v[0], v[1]]);
          const selectedDbs = dbs.join() === this.state.selectedVariant ? '' : dbs.join();
          let variantTable = (<div className="gray-th sm-table" key={'varianttables-'+i}>
                    <Button2
                      color={this.state.selectedVariant === dbs.join() ? 'success' : 'rose'}
                      onClick={() => {
                        this.selectTable('variants',variantsArr,selectedDbs);
                      }}
                    >
                      {dbs.join(', ')}
                    </Button2>
                    <Table
                      tableHeaderColor="primary"
                      tableHead={["Variant Code", "Change Description"]}
                      tableData={newVariantsArr}
                      coloredColls={[3]}
                      colorsColls={["primary"]}
                    />
                    <hr />
                </div>);
          if (variantsArr.length === 0) {
            variantTable = '';
          }
          return variantTable;
        });
      }
      return tables;
    }
  };

  // for values that look like this ['foo','bar']
  renderSelect = (comp, name, addedText) => {
    if (comp[name]) {
      let options = [];
      if (comp[name]) {
        options = Object.keys(comp[name]).map((b, i) => {
          const dbs = comp[name][b].map(val => val.split('_')[0]);
          return {id: b, name: b, dblist: '('+dbs.join(',')+')', addedText}
        });
      }
      return <GINSelect
                label=""
                options={options}
                choose={this.handleSelectChange}
                name={name}
              />
    } else {
      return null;
    }
  }

  // for values that look like this [ [id:0,name:'foo'], [id:1,name:'bar'], ]
  renderSelectVal = (comp, name, arr, type) => {
    if (comp[name]) {
      let options = null;
      if (comp[name]) {
        options = Object.keys(comp[name]).map((b, i) => {
          const dbs = comp[name][b].map(val => val.split('_')[0]);
          let desc = null;
          arr.forEach((a) => {
            if (a.id == b) {
              desc = a[type];
            }
          });
          return {id:b, name: desc, dblist: '('+dbs.join(',')+')'}
        });
      }
      return <GINSelect
                label=""
                options={options}
                choose={this.handleSelectChange}
                name={name}
              />
    } else {
      return null;
    }
  }

  renderInput = (name) => {

  }

  generateEmptyMsg = (msg, arr) => {
    return arr.length == 0 ? <div style={{textAlign:'center'}}><em>{msg}</em></div> : null
  }

  onFieldNameChange = (e) => {
    this.setState({
      newItemCode: e.target.value.toUpperCase()
    });
  }

  submitNewItem = () => {
    const requiredFields = [
      'newItemCode',
      'base_item_description',
      'product_type',
      'rbo',
      'item_is',
      'sales_unit',
      'basic_packaging_unit',
      'qty_per_unit',
      'ilength',
      'width',
      'weight',
    ];
    let complete = true;
    let msg = [], msgString;
    requiredFields.forEach((r) => {
      if (!this.state[r]) {
        msg.push(r);
        complete = false;
      }
    });
    if (complete) {
      const data = {
        db: this.props.db,
        itemCodes: this.state.newItemCode
      };
      const itemFields = [
        'base_item_description',
        'product_type',
        'rbo',
        'brand',
        'subgroup',
        'item_is',
        'sales_unit',
        'basic_packaging_unit',
        'qty_per_unit',
        'ilength',
        'width',
        'weight',
        'variants',
        'variantsDesc'
      ];
      itemFields.forEach((f) => {
        data[f] = this.state[f];
      });
      this.props.submitNewItem(data);
      this.props.compareAll();
      this.props.closeModal();
    } else {
      let msgs = msg.map(m => <span key={m}>{m !== 'newItemCode' ? m.toUpperCase().replace(/_/g,' ') : 'NEW ITEM CODE'}, </span>);
      msgString = <div>{msgs} are required</div>;
      this.setState({
        alertMsg: msgString
      });
    }
  }

  render() {
    let miniModalAlert = (<SweetAlert
        warning
        title="Required!"
        style={{ display: "block" }}
        onConfirm={this.hideAlert}
        >
        {this.state.alertMsg}
        </SweetAlert>);

    console.log('new state', this.state);
    let layout = null;
    const classes = {};
    const comp = this.props.comp;

    let base_item_description = this.renderSelect(this.props.comp, 'base_item_description');
    let product_type = this.renderSelect(this.props.comp, 'product_type');
    let rbo = this.renderSelectVal(this.props.comp, 'rbo', this.props.rboNames, 'name');
    let brand = this.renderSelectVal(this.props.comp, 'brand', this.props.brandNames, 'name');
    let subgroup = this.renderSelectVal(this.props.comp, 'subgroup', this.props.subgroupNames, 'name');
    let item_is = this.renderSelect(this.props.comp, 'item_is');

    let sales_unit = this.renderSelect(this.props.comp, 'sales_unit');
    let basic_packaging_unit = this.renderSelect(this.props.comp, 'basic_packaging_unit');
    let qty_per_unit = this.renderSelect(this.props.comp, 'qty_per_unit');

    let ilength = this.renderSelect(this.props.comp, 'ilength', 'mm');
    let width = this.renderSelect(this.props.comp, 'width', 'mm');
    let weight = this.renderSelect(this.props.comp, 'weight', 'g');
    let picks = this.renderSelect(this.props.comp, 'picks');

    let variants = this.renderSelectTables.variants();

    const general = (
    <div className="list-no-toppad">
      <List component="nav" dense={true}>
        <ListItem>
          <ListItemText primary="Base Item Description" style={{width:'150px'}} />
            {base_item_description}
        </ListItem>
        <ListItem>
          <ListItemText primary="Product Type" style={{width:'150px'}} />
            {product_type}
        </ListItem>
        <ListItem>
          <ListItemText primary="RBO Name" style={{width:'150px'}} />
            {rbo}
        </ListItem>
        <ListItem>
          <ListItemText primary="Brand" style={{width:'150px'}} />
            {brand}
        </ListItem>
        <ListItem>
          <ListItemText primary="Subgroup" style={{width:'150px'}} />
            {subgroup}
        </ListItem>
        <ListItem>
          <ListItemText primary="Item is" style={{width:'150px'}} />
            {item_is}
        </ListItem>
      </List>
    </div>
    );

    const units = (
    <div className="list-no-toppad">
      <List dense={true}>
        <ListItem>
          <ListItemText primary="Sales Unit" style={{width:'150px'}} />
          {sales_unit}
        </ListItem>
        <ListItem>
          <ListItemText primary="Basic Packaging Unit" style={{width:'150px'}} />
          {basic_packaging_unit}
        </ListItem>
        <ListItem>
          <ListItemText primary="Quantity Per Packaging Unit" style={{width:'150px'}} />
          {qty_per_unit}
        </ListItem>
      </List>
    </div>
    );

    const measurements = (
    <div className="list-no-toppad">
      <List dense={true}>
        <ListItem>
          <ListItemText primary="Length" style={{width:'150px'}} />
          {ilength}
        </ListItem>
        <ListItem>
          <ListItemText primary="Width" style={{width:'150px'}} />
          {width}
        </ListItem>
        <ListItem>
          <ListItemText primary="Weight" style={{width:'150px'}} />
          {weight}
        </ListItem>
        <ListItem>
          <ListItemText primary="Picks" style={{width:'150px'}} />
          {picks}
        </ListItem>
      </List>
    </div>
    );

    let pricing_allcustomersArr = [];
    const pricing_allcustomers = (
        <span>
          <p>MOC</p>
          <div className="gray-th sm-table">
            <Table
              tableHeaderColor="primary"
              tableHead={["Quantity", "Shipping Terms", "Price", "Effective Dates", "Total Prices"]}
              tableData={pricing_allcustomersArr}
              coloredColls={[2]}
              colorsColls={["primary"]}
            />
          </div>
        </span>
    );

    let specific_pricing_arr = [];

    const pricing_specific = specific_pricing_arr.map((pr,index) => {
      return (
        <div style={{marginBottom:'20px'}} key={'a'+index}>
          <strong>{pr.name}</strong>
          <p>{pr.moc}</p>
          <div className="gray-th sm-table">
            <Table
              tableHeaderColor="primary"
              tableHead={["Quantity", "Shipping Terms", "Price", "Effective Dates", "Total Prices"]}
              tableData={pr.rows}
              coloredColls={[2]}
              colorsColls={["primary"]}
            />
          </div>
          {this.generateEmptyMsg('No Pricing Specific rows',pr.rows)}
        </div>
      );
    });

    let targetCostArr = [];
    const costs_all = (
        <span>
        <div className="gray-th sm-table">
          <Table
            tableHeaderColor="primary"
            tableHead={["Quantity", "Shipping Terms", "Cost", "Effective Dates", "Total Cost"]}
            tableData={targetCostArr}
            coloredColls={[2]}
            colorsColls={["primary"]}
          />
        </div>
          {this.generateEmptyMsg('No Target Cost',targetCostArr)}
        </span>
    );

    let supplier_costing_arr = [];
    const costs_specific = supplier_costing_arr.map((cr, index) => {
      return (
        <div style={{marginBottom:'20px'}} key={'pr'+index}>
          <strong>{cr.name}</strong>
          <p>{cr.moc}</p>
          <div className="gray-th sm-table">
            <Table
              tableHeaderColor="primary"
              tableHead={["Quantity", "Shipping Terms", "Cost", "Effective Dates", "Total Cost"]}
              tableData={cr.rows}
              coloredColls={[2]}
              colorsColls={["primary"]}
            />
            {this.generateEmptyMsg('No Costing Rows',cr.rows)}
          </div>
        </div>
      );
    });

    let BOMdata = [];

    let fixedStyle = {width:'100%',paddingLeft:'3px'};
    if (this.props.openModal) {
      fixedStyle = null;
    }

    layout = (
    <div className="">
      <NavPills
        color="warning"
        tabs={[
          {
            tabButton: "General",
            tabContent: (
              <div style={fixedStyle}>
                {general}
                <Divider />
                <h4>Select a Variant Table</h4>
                {variants}
              </div>
            )
          },
          {
            tabButton: "Measurements",
            tabContent: (
              <div style={fixedStyle}>
                <h4>Units &amp; Measurements</h4>
                {units}
                <h4>Item Dimensions</h4>
                {measurements}
              </div>
            )
          },
          {
            tabButton: "Pricing",
            tabContent: (
              <div style={fixedStyle}>
                <h4>Pricing for All Customers</h4>
                {pricing_allcustomers}
                <Divider />
                <br />
                <h4>Pricing for Specific Customers</h4>
                {pricing_specific}
              </div>
            )
          },
          {
            tabButton: "Costing",
            tabContent: (
              <div style={fixedStyle}>
                <h4>Target Costs</h4>
                {costs_all}
                <Divider />
                <br />
                <h4>Costing by Supplier</h4>
                {costs_specific}
              </div>
            )
          },
          {
            tabButton: "BOM",
            tabContent: (
              <div style={fixedStyle}>
                <h4>Bill of Materials</h4>
                <strong>Item Base Quantity : </strong>
                <div className="gray-th sm-table">
                  <Table
                    tableHeaderColor="primary"
                    tableHead={["Raw Material Code", "SAP Raw Material Description", "Qty Required to Produce Base Qty", "Raw Material Warehouse"]}
                    tableData={BOMdata}
                    coloredColls={[2]}
                    colorsColls={["primary"]}
                  />
                  {this.generateEmptyMsg('No BOM data',BOMdata)}
                </div>
              </div>
            )
          }
        ]}
      />
    </div>
    );
    console.log('db is',this.props.db);
    let cardStyle = {
         display: 'block',
         width: '100%',
         transitionDuration: '0.3s',
         minHeight: '600px',
         fontSize: '12px'
    }

    return (
            <Card style={cardStyle}>
              { this.state.alertMsg && miniModalAlert }
              { !this.props.openModal &&
              <CardHeader style={{paddingBottom:'0'}}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={1}>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={7}>
                    <TextField
                        id="standard-input"
                        label="Enter New Item Code"
                        className={classes.textField}
                        margin="normal"
                        InputProps={{
                          onChange: this.onFieldNameChange,
                          value: this.state.newItemCode
                        }}
                        style={{width:'100%'}}
                        ref={this.textInput}
                      />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3} style={{backgroundColor:this.props.db.color, borderRadius:'5px'}}>
                    <Button2
                      color="success"
                      style={{marginTop:'20px'}}
                      onClick={() => {
                        this.submitNewItem();
                      }}
                    >SUBMIT</Button2>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={1}>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              }
              <CardBody style={{paddingTop:'0'}}>
                {layout}
              </CardBody>
            </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    openedDBs: state.gin.openedDBs,
    rboNames: state.gmp.rbos,
    brandNames: state.gin.brands,
    subgroupNames: state.gin.subgroups,
    data: state.gin
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchBPName: (cardcode, db) => {
      dispatch(actions.fetchBPNameByCode(cardcode, db));
    },
    emptyList: () => {
      dispatch(actions.emptyItemList());
    },
    emptyItemByDB: (db) => {
      dispatch(actions.emptyItemByDB(db));
    },
    compareAll: () => {
      dispatch(actions.fetchItemCompare());
    },
    submitNewItem: (data) => {
      dispatch(actions.addNewGINItem(data));
      dispatch(actions.addToOpenedDBsList(data.db));
    },
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(GINnew);
