import React from "react";
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import checkPermissions from '../../shared/checkPermissions';
import checkPermissionsActions from '../../shared/checkPermissionsActions';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Cached from "@material-ui/icons/Cached";
import Button from "components/CustomButtons/Button.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
import { FadeLoader } from 'react-spinners';
import { qs } from 'shared/utility.js';
import LockedBP from 'containers/LockedElements/LockedBP';
import LockedGIN from 'containers/LockedElements/LockedGIN';

class LockedElements extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentTab: null
    }
  }

  componentDidMount() {
  }

  render() {
    if (!checkPermissions('LockedElements')) {
      return <Redirect to="/dashboard" />;
    }

    return (
  <div style={{maxWidth:1200}}>
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon
                color={this.state.currentTab === 'BP' ? 'success' : 'rose'}
                onClick={() => {
                  this.setState({
                    currentTab: 'BP'
                  })
                  this.props.getBPLocks();
                }}
                className="pointed"
              >
                <strong>BP</strong>
              </CardIcon>
              <CardIcon
                  color={this.state.currentTab === 'GIN' ? 'success' : 'rose'}
                  onClick={() => {
                    this.setState({
                      currentTab: 'GIN'
                    })
                    this.props.getItemLocks();
                  }}
                  className="pointed"
                >
                  <strong>GIN</strong>
                </CardIcon>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                {!this.state.currentTab && <h4><strong>SELECT A TAB</strong></h4>}
                {this.state.currentTab && <h1>{this.state.currentTab} Locked Elements</h1>}
                {this.props.loading && <div style={{textAlign:'center',margin:'20px auto',display:'inline-block'}}>
                  <FadeLoader />
                </div>}
                {(this.props.data && this.state.currentTab === 'BP') && <div>
                  <LockedBP
                    data={this.props.data}
                  />
                </div>}
                {(this.props.data && this.state.currentTab === 'GIN') && <div>
                  <LockedGIN
                    data={this.props.data}
                  />
                </div>}
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  </div>
    );
  }
}

const mapStateToProps = state => {
  console.log('locked elements props', state.lockedElements);
  return {
    loading: state.lockedElements.lockedElementsLoading,
    data: state.lockedElements.lockedElementsData,
    error: state.lockedElements.lockedElementsError
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getBPLocks: () => {
      dispatch(actions.getBPLocks());
    },
    getItemLocks: () => {
      dispatch(actions.getItemLocks());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LockedElements);
