import jwt_decode from 'jwt-decode';

const checkActions = (section) => {
  const token = localStorage.getItem('token');
  if (token) {
    const decoded = jwt_decode(token);
    let actions = {};
    console.log('decoded permissions', decoded);
    Object.keys(decoded.permObj).forEach((p) => {
      const section_perm = p.split(' ')[0],
            action = p.split(' ')[1];

      if (section_perm === section) {
        if (!actions.hasOwnProperty(action)) {
          actions[action] = decoded.permObj[p];
        }
      }
    });
    console.log('permission actions are ', actions);
    return actions ? actions : {};
  }
}

export default checkActions;
