import React from 'react';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import withStyles from "@material-ui/core/styles/withStyles";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

const BPCheckbox = props => {
  const { classes } = props;
  const centered = props.labelCentered ? 'label-centered' : '';
  console.log('is disabled BPCheckbox?', props.disabled);
  return (
      <div className={`checkbox-label-gray ${centered}`}>
          <FormControlLabel
              control={
                  <Checkbox
                      tabIndex={-1}
                      onClick={(e) => {
                        props.onChange(e, props.name);
                      }}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                          checked: classes.checked
                      }}

                      checked={props.value}
                  />
              }
              label={props.label}
          />

      </div>
  );
}

export default withStyles(regularFormsStyle)(BPCheckbox);
