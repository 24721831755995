import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

let initialState = {
  rbo_data: null,
  error: null,
  loading: false,
  data: null,
  single_data: null,
  alertMsg: null,
  action_msg: null,
  searchResults: null,
  suggestions: null,
  missingLanguages: null
}

const loadRBODataStart = (state, action) => {
  return updateObject(state, {
    rbo_data: null,
    error: null,
    loading: true
  });
}

function compareRBO( a, b ) {
  if ( a.FirmName < b.FirmName ){
    return -1;
  }
  if ( a.FirmName > b.FirmName ){
    return 1;
  }
  return 0;
}

const loadRBODataSuccess = (state, action) => {
  console.log('rbo data', action.data);
  const rbo_data = action.data ? action.data.concat([]).sort(compareRBO) : [];
  return updateObject(state, {
    rbo_data,
    loading: false
  });
}

const loadRBODataFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.err
  });
}

const readTranslationsStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    data: null
  });
}

const readTranslationsSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    data: action.data
  })
}

const readTranslationStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    single_data: null,
    suggestions: null,
    missingLanguages: null
  });
}

const readTranslationSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    single_data: action.data
  })
}

// CREATE

const createTranslationStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    action_msg: null,
  })
}

const createTranslationSuccess = (state, action) => {
  const data = JSON.parse(JSON.stringify(state.data));
  if (data) {
    Object.keys(data).forEach((table) => {
      data[table].push(action.newData);
    })
  }
  return updateObject(state, {
    loading: false,
    action_msg: 'Successfully added translation',
    data
  })
}

const createTranslationFail = (state, action) => {
  let action_msg = 'Could not add translation, please try again. If the issue persists notify Tech Support.';
  if (action.err === 'duplicate found') {
    action_msg = 'A Duplicate has been found, your translation has not been created.';
  }
  return updateObject(state, {
    loading: false,
    action_msg
  })
}

// UPDATE

const updateTranslationStart = (state, action) => {
  return updateObject(state, {
    loading: true
  })
}

const updateTranslationSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    action_msg: 'Successfully updated translation'
  })
}

const updateTranslationFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    action_msg: 'Could not update translation, please try again. If the issue persists notify Tech Support.'
  })
}

// DELETE

const deleteTranslationStart = (state, action) => {
  return updateObject(state, {
    loading: true
  })
}

const deleteTranslationSuccess = (state, action) => {
  if (action.data) {
    const d = action.data;
    if ((d.category && d.type) && d.id) {
      const data = JSON.parse(JSON.stringify(state.data));
      if (data) {
        const primary_key = d.type === 'BASE' ? 'ENGLISH' : 'RBO';
        Object.keys(data).forEach((table) => {
          data[table] = data[table].filter(dt => {
            if (primary_key == 'ENGLISH') {
              if (dt[primary_key] === d.id) {
                return false;
              }
            } else {
              if (dt[primary_key] === d.id && dt.ENGLISH == d._english) {
                return false;
              }
            }
            
            return true;
          })
        })
      }
      return updateObject(state, {
        loading: false,
        action_msg: 'Successfully deleted translation',
        data
      })
    }
  }
}

const deleteTranslationFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    alertMsg: 'Could not delete translation, please try again. If the issue persists notify Tech Support.'
  })
}

// CLEAR

const clearTranslations = (state, action) => {
  if (action.except_rbo) {
    console.log('except rbo')
    const initialStateCopy = JSON.parse(JSON.stringify(initialState));
    initialStateCopy.rbo_data = JSON.parse(JSON.stringify(state.rbo_data));
    return updateObject(state, initialStateCopy);
  } else {
    return updateObject(state, initialState)
  }
  
}

// SEARCH 

const searchTranslationSuccess = (state, action) => {
  console.log('successful search', action.searchResults)
  if (action.searchResults.searchResults) {
    return updateObject(state, {
      loading: false,
      searchResults: action.searchResults.searchResults
    })
  }
  return state;
}

const searchTranslationStart = (state, action) => {
  console.log('searching....')
  return updateObject(state, {
    loading: true,
    searchResults: null
  })
}

const searchTranslationFail = (state, action) => {
  console.log('failed search...')
  return updateObject(state, {
    loading: true,
    searchResults: null,
  })
}

const getSuggestionsSuccess = (state, action) => {
  const suggestions = {};
  let missingLanguages = [];
  console.log('as', action);
  if (action.suggestions) {
    Object.keys(action.suggestions).forEach(table => {
      const stable = action.suggestions[table];
      if (stable) {
        stable.forEach(s => {
          if (s.term && s.results) {
            s.results.forEach((translation_row) => {
              Object.keys(translation_row).forEach((lang) => {
                if (lang !== 'ENGLISH') {
                  const tr = translation_row[lang];
                  if (tr) {
                    if (tr.trim()) {
                      if (suggestions.hasOwnProperty(lang)) {
                        // look for existing 
                        let found = false;
                        suggestions[lang].forEach(sl => {
                          if (sl.value === tr) {
                            found = true;
                          }
                        })
                        if (!found) {
                          suggestions[lang].push({
                            term: translation_row['ENGLISH'],
                            value: tr
                          })
                        }
        
                      } else {
                        suggestions[lang] = [{
                          term: translation_row['ENGLISH'],
                          value: tr
                        }];
                      }
                    }
                  }
                }

              })
            });
          }
        })
      }
    })
  }
  if (action.missingLanguages) {
    action.missingLanguages.forEach(ml => {
      if (!suggestions.hasOwnProperty(ml)) {
        missingLanguages.push(ml);
      }
    })
  }
  console.log('final suggestions', suggestions);
  return updateObject(state, {
    suggestions,
    missingLanguages
  });
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOAD_RBO_DATA_START:
          return loadRBODataStart(state, action);
        case actionTypes.LOAD_RBO_DATA_SUCCESS:
          return loadRBODataSuccess(state, action);
        case actionTypes.LOAD_RBO_DATA_FAIL:
          return loadRBODataFail(state, action);

        case actionTypes.READ_TRANSLATIONS_START:
          return readTranslationsStart(state, action);
        case actionTypes.READ_TRANSLATIONS_SUCCESS:
          return readTranslationsSuccess(state, action);

        case actionTypes.READ_TRANSLATION_START:
          return readTranslationStart(state, action);
        case actionTypes.READ_TRANSLATION_SUCCESS:
          return readTranslationSuccess(state, action);

        case actionTypes.CREATE_TRANSLATION_START:
          return createTranslationStart(state, action);
        case actionTypes.CREATE_TRANSLATION_SUCCESS:
          return createTranslationSuccess(state, action);
        case actionTypes.CREATE_TRANSLATION_FAIL:
          return createTranslationFail(state, action);

        case actionTypes.UPDATE_TRANSLATION_START:
          return updateTranslationStart(state, action);
        case actionTypes.UPDATE_TRANSLATION_SUCCESS:
          return updateTranslationSuccess(state, action);
        case actionTypes.UPDATE_TRANSLATION_FAIL:
          return updateTranslationFail(state, action);

        case actionTypes.DELETE_TRANSLATION_START:
          return deleteTranslationStart(state, action);
        case actionTypes.DELETE_TRANSLATION_SUCCESS:
          return deleteTranslationSuccess(state, action);
        case actionTypes.DELETE_TRANSLATION_FAIL:
          return deleteTranslationFail(state, action);

        case actionTypes.CLEAR_TRANSLATIONS:
          return clearTranslations(state, action);

        case actionTypes.SEARCH_TRANSLATION_START:
          return searchTranslationStart(state, action);
        case actionTypes.SEARCH_TRANSLATION_SUCCESS:
          return searchTranslationSuccess(state, action);
        case actionTypes.SEARCH_TRANSLATION_FAIL:
          return searchTranslationFail(state, action);

        case actionTypes.GET_SUGGESTIONS_SUCCESS:
          return getSuggestionsSuccess(state, action);

        default: return state;
    }
};

export default reducer;
