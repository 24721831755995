import React, { useEffect, useState } from 'react';
import ReactTable from "react-table";
import Button from "components/CustomButtons/Button.jsx";

const TranslationsTable = (props) => {
  if ((!props.data || !props.title) || !props.key_value) return null;
  console.log('table', props.data, props.title, props.key_value)
  const columns = props.type === 'BASE' ? [
    {
        Header: "ID",
        accessor: "language",
        sortable: true,
        filterable: true,
        filterMethod: (filter, row) => {
          var filterValue = filter.value ? filter.value.toLowerCase() : '';
          if (filterValue.length > 0) {
              return row[filter.id].toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;
          } else {
              return true;
          }
        },
        Cell: row => {
          return (
            <div>{row.original.languageCell}</div>
          )
        }
    },
    {
        Header: "Actions",
        accessor: "actions",
        sortable: false,
        filterable: false,
        Cell: row => {
          return (
            <div>{row.original.actionsCell}</div>
          )
        }
    }
  ] : [
    {
        Header: "ID",
        accessor: "language",
        sortable: true,
        filterable: true,
        Cell: row => {
          return (
            <div>{row.original.languageCell}</div>
          )
        },
        filterMethod: (filter, row) => {
          var filterValue = filter.value ? filter.value.toLowerCase() : '';
          if (filterValue.length > 0) {
              return row[filter.id].toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;
          } else {
              return true;
          }
        },
    },
    {
        Header: "RBO Name",
        accessor: "rbo_name",
        sortable: true,
        filterable: true,
        Cell: row => {
          return (
            <div>{row.original.rbo_name}</div>
          )
        }
    },
    {
        Header: props.lang,
        accessor: "lang",
        sortable: true,
        filterable: true,
        Cell: row => {
          return (
            <div>{row.original.lang}</div>
          )
        },
        filterMethod: (filter, row) => {
          var filterValue = filter.value ? filter.value.toLowerCase() : '';
          if (filterValue.length > 0) {
              return row[filter.id].toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;
          } else {
              return true;
          }
        },
    },
    {
        Header: "Actions",
        accessor: "actions",
        sortable: false,
        filterable: false,
        Cell: row => {
          return (
            <div>{row.original.actionsCell}</div>
          )
        }
    }
  ];
  return (
    <div>
      <h3><strong>{props.title}</strong></h3>
      {/*props.update_permitted && <Button
                          color="success"
                          onClick={() => {
                            console.log('creating title', props.title);
                            props.create(props.title);
                          }}
                        >ADD NEW</Button>*/}
      <ReactTable
          data={
              props.data.map((d, key) => {
                return ({
                  id: key,
                  language: d[props.key_value],
                  languageCell: <div className="spaced-10">{d[props.key_value]}</div>,
                  lang: d[props.lang],
                  rbo_name: props.type === 'BASE' ? null :
                        props.rbo_data ? props.rbo_data[d[props.key_value]] : null,
                  actions: 'read',
                  actionsCell: <div>
                    <Button
                      color="rose"
                      onClick={() => {
                        const trobj = {
                          category: props.category,
                          type: props.type,
                          lang: props.type === 'BASE' ? null : props.key_value,
                          id: props.type === 'BASE' ? d[props.key_value] : `${d[props.key_value]}_${d[props.lang]}`
                        };
                        console.log('trobj', trobj);
                        props.readTranslation(trobj);
                        props.openModal({
                          type: 'EDIT',
                          data: null
                        });
                      }}
                    >LOAD</Button>
                  </div>
                })
              })
          }
          filterable={false}
          columns={columns}
          minRows={0}
          showPagination={true}
          className="-highlight2"
          defaultPageSize={10}
      />
    </div>
  )
}

export default TranslationsTable;
