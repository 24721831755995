import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import GINSelect from "./GINselect";
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';

const GINaddPriceSelect = props => {

  return (
    <Dialog
      open={props.open}
      keepMounted
      onClose={props.close}
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle>
        <div style={{textAlign:'center'}}>SELECT DB</div>
        <div className="close-btn">
          <IconButton aria-label="Close" onClick={props.close}>
            <Close />
          </IconButton>
        </div>
      </DialogTitle>
      <div style={{padding:'0 40px 40px 40px'}}>
        <GINSelect
          label="Database"
          options={props.dbOptions}
          choose={props.choose}
          name="dboptions"
          indx=""
          desc=""
          default={props.value}
        />
      </div>
    </Dialog>
  );
}

export default GINaddPriceSelect
