import React from "react";
import * as actions from '../../store/actions';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import Button from "components/CustomButtons/Button.jsx";
import { logic } from "./RFIDlogic";
import { dropdownRequired } from "containers/RFID/RFIDobj";

const def = {
  epcCode: '',
  upcCode: '',
  serial: '',
  prefix: '',
  prefixInput: '',
  manufacturerID: '',
  error: {
    epc: ''
  }
}

class ExportUPC extends React.Component {
  constructor(props) {
    super(props);

    this.state = def;
  }

  clear = () => {
    this.setState(def);
    if (this.props.isModal && this.props.chooseEPCfilter) {
      this.props.chooseEPCfilter(null, null, null, null);
    }
  }

  process = () => {
    const results = logic.EPCtoUPC({
      epcCode: this.state.epcCode,
      prefix: this.state.prefixInput,
      company_id: this.props.company_ids[this.props.company]
    });
    console.log('results',results);
    if (results) {
      if (results.fullUPC && results.serial2) {
        this.setState({
          upcCode: results.fullUPC,
          serial: results.serial2,
          prefix: results.prefix
        }, () => {
          console.log('the serial set is', this.state.serial)
        });
      }
    }
  }

  handleTextInputChange = (e, target, maxLength) => {
    if (target === 'epcCode') {
      let epcError = this.state.error.epc;
      if (e.target.value.length !== 24) {
        epcError = 'Invalid Length';
      } else {
        epcError = '';
      }
      this.setState({
        [target]: e.target.value,
        error: {
          epc: epcError
        }
      })
    } else {
      if (e.target.value.length < maxLength) {
        this.setState({
          [target]: e.target.value
        })
      }
    }
  }

  componentDidMount() {
    if (this.props.epcFilter && (this.props.upcFilter && this.props.serialFilter)) {
      console.log('setting');
      this.setState({
        epcCode: this.props.epcFilter,
        upcCode: this.props.upcFilter,
        serial: this.props.serialFilter,
      })
    }
  }

  render() {
    return (
    <div>
      <GridContainer>
        {!this.state.serial && <GridItem xs={12} sm={12} md={3}>
          <div style={{maxWidth:220}}>
            <CustomInput
              labelText="24 Digit EPC"
              id="epcCode"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: (e) => {
                  this.handleTextInputChange(e, 'epcCode');
                },
                onClick: () => { },
                value: this.state.epcCode,
                autoFocus: this.props.isModal ? true : false,
                role: 'presentation',
                autoComplete: 'nope',
                disabled: false
              }}
              error={this.state.error.epc ? true : false}
            />
          </div>
          {this.state.error.epc && <div style={{color:'red'}}>{this.state.error.epc}</div>}
        </GridItem>}
        {!this.state.serial && <GridItem xs={12} sm={12} md={9}>
          <div style={{maxWidth:220}}>
            <CustomInput
              labelText="GS1 Company Prefix (optional)"
              id="prefix"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: (e) => {
                  this.handleTextInputChange(e, 'prefixInput', 14);
                },
                onClick: () => { },
                value: this.state.prefixInput,
                autoFocus: false,
                role: 'presentation',
                autoComplete: 'nope',
                disabled: false
              }}
            />
            <div style={{fontSize:10}}>
              For calculation of GS1 company prefix length<br />
              Please read <a href="https://www.gs1.org/services/epc-encoder/faqs#gcp" target="_blank">FAQ</a> on accuracy if left blank.
            </div>
          </div>
        </GridItem>}
        <GridItem xs={12} sm={12} md={12}>
          {!this.state.serial && <div style={{marginTop:20}}>
            <Button
            disabled={(!this.state.serial && (this.state.epcCode && !this.state.error.epc)) ? false : true}
            color="success"
            onClick={this.process}>PROCESS</Button>
          </div>}
        </GridItem>
        {this.state.upcCode && <GridItem xs={12} sm={12} md={12}>
          <h4><strong>Input</strong></h4>
        </GridItem>}
        {this.state.upcCode && <GridItem xs={12} sm={12} md={12}>
          <div style={{maxWidth:220}}>
            <CustomInput
              labelText="24 Digit EPC"
              id="epcCode"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: (e) => {

                },
                onClick: () => { },
                value: this.state.epcCode,
                autoFocus: false,
                role: 'presentation',
                autoComplete: 'nope',
                disabled: true
              }}
              error={this.state.error.epc ? true : false}
            />
          </div>
        </GridItem>}
        {this.state.upcCode && <GridItem xs={12} sm={12} md={12}>
          <h4><strong>Output</strong></h4>
        </GridItem>}
        {this.state.upcCode && <GridItem xs={12} sm={3} md={3}>
          <div>
            <CustomInput
              labelText="12 Digit UPC / 13 Digit EAN-13"
              id="upcCode"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: (e) => {

                },
                onClick: () => { },
                value: this.state.upcCode,
                autoFocus: true,
                role: 'presentation',
                autoComplete: 'nope',
                disabled: true
              }}
              error={this.state.error.upc ? true : false}
            />
          </div>
        </GridItem>}
        {this.state.serial && <GridItem xs={12} sm={3} md={3}>
          <div>
            <CustomInput
              labelText="Serial #"
              id="serial_num"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: (e) => {

                },
                onClick: () => { },
                value: this.state.serial,
                autoFocus: false,
                role: 'presentation',
                autoComplete: 'nope',
                disabled: true
              }}
            />
          </div>
        </GridItem>}
        {this.state.prefix && <GridItem xs={12} sm={3} md={3}>
          <div>
            <CustomInput
              labelText="Prefix"
              id="prefix"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: (e) => {

                },
                onClick: () => { },
                value: this.state.prefix,
                autoFocus: false,
                role: 'presentation',
                autoComplete: 'nope',
                disabled: true
              }}
            />
          </div>
        </GridItem>}
        {this.state.serial && <GridItem xs={12} sm={12} md={12}>
          <div style={{marginTop:20}}>
            <Button
            color="rose"
            onClick={this.clear}>CLEAR</Button>

            {this.props.isModal && <Button
            color="success"
            onClick={() => {
              this.props.chooseEPCfilter(this.state.upcCode, this.state.serial, this.state.epcCode, this.state.prefixInput);
              this.props.close();
            }}>START FILTER</Button>}
          </div>
        </GridItem>}
      </GridContainer>
    </div>
    );
  }
}

export default ExportUPC;
