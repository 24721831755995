import React, { Component } from 'react'
import { connect } from 'react-redux';
import withStyles from "@material-ui/core/styles/withStyles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import compose from 'recompose/compose';

class ADSelect extends Component {
    constructor() {
        super()
        this.state = {
            selected: '' 
        }
    }

    handleSingle = event => {
        this.setState({ selected: event.target.value });
        this.props.choose(event.target.value);
      };

    render() {
        const { classes, options, label } = this.props;
        return (
            <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="single-select"
                            className={classes.selectLabel}
                          >
                            {label}
                          </InputLabel>
                <Select
                        value={this.state.selected}
                        onChange={this.handleSingle}
                        MenuProps={{ className: classes.selectMenu }}
                        classes={{ select: classes.select }}
                        inputProps={{
                            name: "singleSelect",
                            id: "single-select"
                        }}
                    >
                            
                            {
                                options.map((name, index) => (
                                    <MenuItem
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelected
                                        }}
                                        key={index}
                                        value={name}
                                        >
                                        {name}
                                    </MenuItem>
                                ))
                            }
                            
                    </Select>
            </FormControl>
        )
    }
}

const mapStateToProps = state => {
    return {
        selected: state.selected
    }
}

export default compose(
    withStyles(extendedFormsStyle),
    connect(mapStateToProps, null)
  )(ADSelect);