import React, { useState } from 'react';
import Table from 'components/Table/Table.jsx';
import Button from '../../components/CustomButtons/Button';

const VisualizeTable = props => {
    const [show, setShow] = useState(false);
    let tableHead = [];
    let tableData = [];

    if (props.data) {
        if (typeof props.data === 'object') {
            if (props.data.length > 0) {
                tableHead = Object.keys(props.data[0]);
                props.data.forEach(pd => {
                    let dat = [];
                    tableHead.forEach(field => {
                        dat.push(pd[field]);
                    })
                    tableData.push(dat);
                })
            }
        }
    }

    return <div>
        <h3><strong>{props.title}</strong></h3>
        <h4>{props.subtitle}</h4>
        {!show && <Button color="white" onClick={() => { setShow(true); }}>SHOW</Button>}
        {show && <Button color="white" onClick={() => { setShow(false); }}>HIDE</Button>}
        {show && <div>
            <Table 
                tableHead={tableHead}
                tableData={tableData}
            />
            {tableData.length === 0 && <div style={{marginTop:20}} className="text-center">
                <em>None</em>
            </div>}
        </div>}
        <hr />
    </div>
}

export default VisualizeTable;