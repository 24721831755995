import React from "react";
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import checkPermissions from '../../shared/checkPermissions';
import checkActions from '../../shared/checkPermissionsActions';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Cached from "@material-ui/icons/Cached";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import CustomInput from "components/CustomInput/CustomInputValidate.jsx";
import Button from "components/CustomButtons/Button.jsx";
import { num_whitelist } from "shared/validation";
import { so_dbList } from 'shared/activeDBs';
import _ from 'lodash';
import { FadeLoader } from 'react-spinners';
import combineStyles from "shared/combineStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import SweetAlert from "react-bootstrap-sweetalert";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import styles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import SOLookupMain from "containers/SO/SOLookupMain";
import { domain } from "shared/devStatus";
import jwt_decode from "jwt-decode";

class SOLookup extends React.Component {
  constructor(props) {
    super(props);

    const token = localStorage.getItem('token');
    let decoded;
    if (!token) {
      console.log('no token found');
      window.location.reload();
    } else if (token) {
      decoded = jwt_decode(token);
      console.log('decoded', decoded);
    }

    this.state = {
      chosenDB: null,
      searchTerm: '',
      alertMsg: '',
      testData: '',
      decoded
    }

    this.searchAPIDebounced = _.debounce(this.searchAPIDebounced, 500);
  }

  handleSelectChange = (value, name) => {
    this.setState({
      [name]: value
    })
  }

  handleTextInputChange = (e, field) => {
    this.setState({
      [field]: e.target.value
    });
  }

  searchAPIDebounced = (chosenDB, searchTerm) => {
    console.log('searching', searchTerm, chosenDB);
    if (searchTerm && chosenDB) {
      this.props.loadSOData(chosenDB, searchTerm);
    }
  }

  showAlert = (alertMsg) => {
    this.setState({
      alertMsg
    });
  }

  hideAlert = () => {
    this.setState({ alertMsg: null });
  }

  componentDidMount() {
    this.props.clearSOData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.chosenDB !== this.state.chosenDB) {
      this.props.clearSOData();
      this.setState({
        searchTerm: ''
      });
    }

    if (prevProps.impositionsErr !== this.props.impositionsErr) {
      if (this.props.impositionsErr) {
        this.setState({alertMsg: this.props.impositionsErr});
      }
    }
    if (prevProps.impositionsData !== this.props.impositionsData) {
      if (this.props.impositionsData) {
        const dataObj = this.props.impositionsData;
        const { ups, variants_count, filename } = dataObj;
        const href = `${domain}/api/so/download_so_output/${filename}`;
        const alertMsg = (<div>{ups} UPS and {variants_count} variants processed to file
          <a href={href} download style={{display:'block'}}>{filename}</a>.
          Click to download.
        </div>);
        this.setState({alertMsg});
      }
    }
  }

  render() {
    if (!checkPermissions('SOLookup')) {
      return <Redirect to="/dashboard" />;
    }

    console.log('st pr SO', this.state, this.props);

    return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <div className="pointed"><Cached /></div>
            </CardIcon>
          </CardHeader>
          <CardBody>
            <h3><strong>Sales Order Lookup Tool</strong></h3>
            <GridContainer>
              <GridItem xs={4} sm={4} md={4}>
                <CustomSelect
                  label="Select a DB"
                  options={[''].concat(so_dbList.map(db => {
                    return db.name;
                  }))}
                  choose={this.handleSelectChange}
                  name="chosenDB"
                  indx=""
                  desc=""
                  default={null}
                />
              </GridItem>
              <GridItem xs={4} sm={4} md={4}>
                {this.state.chosenDB && <CustomInput
                  label="Search by S.O. / No entry shows all S.O.s"
                  name="searchTerm"
                  value={this.state.searchTerm}
                  handleTextInputChange={this.handleTextInputChange}
                  validate={[num_whitelist]}
                  required
                />}
                {(this.state.chosenDB && (domain.indexOf('staging') !== -1 && (this.state.decoded.id === 1 || this.state.decoded.id === 51))) && <div><CustomInput
                  label="Test Data e.g. 40-814,587,315"
                  name="testData"
                  value={this.state.testData}
                  handleTextInputChange={this.handleTextInputChange}
                  validate={[]}
                /><div><small>(separate UPS by dash and variants by comma, maximum 3) </small></div></div>}
              </GridItem>
              <GridItem xs={4} sm={4} md={4}>
                {this.state.chosenDB && <div style={{marginTop:20}}>
                  <Button
                      style={{marginRight:10}}
                      onClick={() => {
                        if (this.state.chosenDB) {
                          this.props.loadSOData(this.state.chosenDB, this.state.searchTerm);
                        }
                      }}
                      color={this.state.chosenDB ? 'success' : 'white'}
                      disabled={this.state.chosenDB ? false : true}
                  >SEARCH</Button>
                </div>}
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                {this.props.loading &&  <div className="centered-inner-div">
                  <FadeLoader />
                </div>}
                {this.props.error && <div className="centered-inner-div red-text">There was an error, please try again or contact Tech Support.</div>}
                {(this.props.data && this.state.chosenDB) && <SOLookupMain
                    data={this.props.data}
                    searchTerm={this.state.searchTerm}
                    testData={this.state.testData}
                    isTester={(this.state.decoded.id === 1 || this.state.decoded.id === 51) ? true : false}
                    startImpositions={this.props.startImpositions}
                  />}
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
      {this.state.alertMsg && <SweetAlert
          onConfirm={() => {
            this.hideAlert();
          }}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          >
          {this.state.alertMsg}
          </SweetAlert>}
    </GridContainer>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.so.loading,
    data: state.so.data,
    error: state.so.error,
    impositionsErr: state.so.impositionsErr,
    impositionsData: state.so.impositionsData
  }
}

const mapDispatchToProps = dispatch => {
  return {
    loadSOData: (dbname, searchTerm) => {
      dispatch(actions.loadSOData(dbname, searchTerm));
    },
    clearSOData: () => {
      dispatch(actions.clearSOData());
    },
    startImpositions: (filename, test_data) => {
      dispatch(actions.startImpositions(filename, test_data));
    }
  }
}

const combinedStyles = combineStyles(sweetAlertStyle, styles);
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(combinedStyles)(SOLookup));
