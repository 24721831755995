import { convertDateStr } from 'shared/utility';

const dupesCalc = (pricings) => {
  // determine if there are duplicates in the quantity ranges
  let duplicates = [];
  Object.keys(pricings).forEach((db) => {
    Object.keys(pricings[db]).forEach((rnge) => {
      if (pricings[db][rnge].length > 1) {
        let objCollect = {};
        pricings[db][rnge].forEach((prr,i) => {
          if (objCollect.hasOwnProperty(prr.currency)) {
            objCollect[prr.currency].push(prr);
          } else {
            objCollect[prr.currency] = [ prr ];
          }
        });
        console.log('first object collected by CURRENCY', objCollect);

        Object.keys(objCollect).forEach((obb) => {
          if (objCollect[obb].length > 1) {
            let dateRanges = [],
                dateRangeCollection = {};
            objCollect[obb].forEach((doo, i) => {
              const st = convertDateStr(doo.start);
              const et = convertDateStr(doo.end);
              console.log('start', st, doo.start);
              console.log('end', et, doo.end);
              dateRanges.push({ st, et });
            });

            // check for overlapping dates
            let overlaps = [];
            dateRanges.forEach((d, i) => {
              dateRanges.forEach((dd, ii) => {
                if (i !== ii) {
                  const start1 = d.st,
                        start2 = dd.st,
                        end1 = d.et,
                        end2 = dd.et;
                  if( (start1 >= start2 && start1 <= end2) || (end1 <= end2 && end1 >= start2) ) {
                    if (overlaps.indexOf(i) === -1) {
                      overlaps.push(i);
                    }
                    if (overlaps.indexOf(ii) === -1) {
                      overlaps.push(ii);
                    }
                  }
                }
              });
            });
            console.log('date overlaps -',overlaps);
            const finalObj = objCollect[obb].filter((oobb, i) => {
              if (overlaps.indexOf(i) === -1) {
                return false;
              }
              return true;
            });

            // check for similar shipping terms
            console.log('second object collected by DATE', finalObj);
            let finalObj2 = {};
            finalObj.forEach((fo) => {
              const term = fo.term;
              if (finalObj2.hasOwnProperty(term)) {
                finalObj2[term].push(fo);
              } else {
                finalObj2[term] = [fo];
              }
            });
            console.log('3rd object collected by TERMS',finalObj2);
            Object.keys(finalObj2).forEach((foo) => {
              if (finalObj2[foo].length > 1) {
                duplicates.push({
                  DB: db,
                  range: rnge,
                  obj: finalObj2[foo],
                  itemName: objCollect[obb][0].itemCode
                });
              }
            });
          }
        });

      }
    });
  });
  console.log('these are the duplicates ->',duplicates);
  return duplicates;
}

export default dupesCalc;
