import * as actionTypes from './actionTypes';

export const loadSOData = (dbname, searchTerm) => {
    return {
        type: actionTypes.LOAD_SO_DATA,
        dbname,
        searchTerm
    }
}

export const loadSODataStart = () => {
  return {
    type: actionTypes.LOAD_SO_DATA_START
  }
}

export const loadSODataSuccess = (data) => {
  return {
    type: actionTypes.LOAD_SO_DATA_SUCCESS,
    data
  }
}

export const loadSODataFail = (err) => {
  return {
    type: actionTypes.LOAD_SO_DATA_FAIL,
    err
  }
}

export const clearSOData = () => {
  return {
    type: actionTypes.CLEAR_SO_DATA
  }
}

export const startImpositions = (filename, test_data) => {
  return {
    type: actionTypes.START_IMPOSITIONS,
    filename,
    test_data
  }
}

export const startImpositionsStart = () => {
  return {
    type: actionTypes.START_IMPOSITIONS_START
  }
}

export const startImpositionsSuccess = (data, filename) => {
  return {
    type: actionTypes.START_IMPOSITIONS_SUCCESS,
    data,
    filename
  }
}

export const startImpositionsFail = (err) => {
  return {
    type: actionTypes.START_IMPOSITIONS_FAIL,
    err
  }
}
