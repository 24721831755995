import React from "react";
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import checkPermissions from '../../shared/checkPermissions';
import checkActions from '../../shared/checkPermissionsActions';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Cached from "@material-ui/icons/Cached";
import Table from "components/Table/Table.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import DialogTitle2 from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import moment from "moment";
import cloneDeep from "lodash/cloneDeep";

const compare = (a, b) => {
  if ( a.key < b.key ){
    return -1;
  }
  if ( a.key > b.key ){
    return 1;
  }
  return 0;
}

const Popup = props => {
  return (
    <Dialog
      open={props.open}
      keepMounted
      onClose={props.close}
      fullWidth={true}
      maxWidth="lg"
    >
      <DialogTitle2>
        <div className="text-center">
          <span style={{marginRight:20}}><strong>Item: </strong>{props.itemcode} {props.db}</span>
        </div>
        <div className="close-btn">
          <IconButton aria-label="Close" onClick={props.close}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle2>
      <div style={{padding:10}}>
        <CustomInput
          labelText="POST Data"
          id="notes-me"
          className="text-field-input-custom"
          style={{width:'100%'}}
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            multiline: true,
            rows: 5,
            onChange: (e) => {
              if (props.normalize) {
                e.target.value = props.normalize(e.target.value);
              }
              props.handleTextInputChange(e.target.value);
            },
            value: props.value
          }}
        />
        <Button color="rose" onClick={() => {
          props.update(props.value, props.editRow);
          props.close();
        }}>Update
        </Button>
      </div>
    </Dialog>
  );
}

class ItemAdmin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      showData: -1,
      showEdit: -1,
      editData: '',
      editDB: '',
      editItemCode: '',
      editRow: -1,
      showPopup: false
    }
  }

  componentDidMount() {
    this.props.ginCheckTable();
    this.props.ginGetFailedImport();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.importerData !== this.props.importerData) {
      this.setState({
        data: this.props.importerData ? this.props.importerData.data : []
      });
    }
  }

  update = (data, row) => {
    console.log('updating', row, data)
    this.setState({
      data: this.state.data.map((d,i) => {
        if (i === row) {
          const dat = cloneDeep(d);
          dat.post = this.state.editData;
          return dat;
        } else {
          return d;
        }
      })
    })
  }

  openPopup = (editData, editRow, editDB, editItemCode) => {
    this.setState({
      editData,
      editRow,
      editDB,
      editItemCode,
      showPopup: true
    })
  }

  closePopup = () => {
    this.setState({
      editDB: '',
      editItemCode: '',
      showPopup: false
    })
  }

  handleTextInputChange = (editData) => {
    this.setState({editData})
  }

  convertData = (post) => {
    const parsed = JSON.parse(post);
    let html = [];
    Object.keys(parsed).forEach(p => {
      html.push({
        key: p,
        value: parsed[p]
      });
    });
    return html.sort(compare);
  }

  showData = (row) => {
    this.setState({
      showData: row
    });
  }

  showEdit = (row) => {
    this.setState({
      showEdit: row
    })
  }

  editRow = (row, data) => {
    this.setState({
      data: this.state.data.map((d,i) => {
        if (i === row) {
          const dat = cloneDeep(d);
          dat.post = data;
          return dat;
        } else {
          return d;
        }
      })
    })
  }

  convertItemCode = (post) => {
    const parsed = JSON.parse(post);
    return parsed.itemcode;
  }

  startSend = (itemObj) => {
    this.props.ginSendItemNoStart(itemObj);
  }

  render() {
    if (!checkPermissions('UserAdmin')) {
      return <Redirect to="/dashboard" />;
    }

    console.log('st and props', this.state, this.props);
    const tableData = this.state.data.map((dat,i) => {
      const json = JSON.parse(dat.post);
      return [
        dat.id,
        dat.db,
        <div
          onClick={() => {
            if (this.state.showData === -1) {
              this.showData(i);
              this.showEdit(-1);
            } else {
              this.showData(-1);
            }
          }}
          className="hovered lightgray-bg">
          {
            this.state.showData === i ? this.convertData(dat.post).map((dp,j) => {
          return <div key={`kk${j}`} style={{textAlign:'left'}}><strong>{dp.key} </strong>{dp.value}</div>
            }) : <div onClick={() => {
              this.showData(i);
              this.showEdit(-1);
            }}>{this.convertItemCode(dat.post)}</div>
          }
      </div>,
        moment(dat.timestamp).subtract(7,'hours').format("MM-DD-YYYY hh:mm a"),
        <div>
          <Button color="white" onClick={() => {
            this.openPopup(dat.post, i, dat.db, json.itemcode);
            this.showData(-1);
          }}>Edit</Button>
          <Button color="rose" onClick={() => {
            this.startSend({ [`${dat.db}--0`]: json });
            //this.props.ginSetFailedImport(1, dat.id);
          }}>Resubmit</Button>
          <Button color="success" onClick={() => {
            this.props.ginSetFailedImport(1, dat.id);
            this.props.ginGetFailedImport();
          }}>Mark as Done</Button>
        </div>
      ]
    });

    return (

        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <div className="pointed"><Cached onClick={this.props.ginGetFailedImport} /></div>
            </CardIcon>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <h3>Item Importer Admin</h3>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <div>
                  <Button color="white" onClick={() => { this.props.ginCheckTable(); }}>CHECK TABLE</Button>
                  <span style={{marginLeft:10}}>TABLE COUNT : {this.props.tableCount == 0 ?
                                                          <span className="green-text">0</span> :
                                                          <span className="red-text">{this.props.tableCount}</span>}</span>
                </div>
                <Table
                  tableHeaderColor="primary"
                  tableHead={["ID", "DB", "POST", "Timestamp", "Submit"]}
                  tableData={tableData}
                  colorsColls={["primary"]}
                />
                {this.state.showPopup && <Popup
                    open={this.state.showPopup}
                    close={this.closePopup}
                    value={this.state.editData}
                    update={this.update}
                    db={this.state.editDB}
                    itemcode={this.state.editItemCode}
                    editRow={this.state.editRow}
                    handleTextInputChange={this.handleTextInputChange}
                  />}
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
    );
  }
}

const mapStateToProps = state => {
  return {
    importerData: state.gin.importerData,
    importerDataErr: state.gin.importerDataErr,
    tableCount: state.gin.tableCount
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    ginGetFailedImport: () => dispatch(actions.ginGetFailedImport()),
    ginSendItemNoStart: (itemObj) => {
      dispatch(actions.ginSendItemNoStart(itemObj));
    },
    ginSendItem: (itemObj) => {
      dispatch(actions.ginSendItem(itemObj));
    },
    ginCheckTable: () => {
      dispatch(actions.ginCheckTable());
    },
    ginSetFailedImport: (status, id) => dispatch(actions.ginSetFailedImport(status, id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ItemAdmin);
