import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
import { PulseLoader } from "react-spinners";

const GINask2 = props => {
  return (
    <SweetAlert
      warning
      showCancel
      confirmBtnText="Ok"
      cancelBtnText="Cancel"
      confirmBtnCssClass={
        props.classes.button + " " + props.classes.success
      }
      cancelBtnCssClass={
        props.classes.button + " " + props.classes.white
      }
      title={props.title}
      onConfirm={() => {
        props.command();
        props.close();
      }}
      onCancel={props.close}
      >
        <div>{props.content}</div>
    </SweetAlert>
  );
}

export default withStyles(sweetAlertStyle)(GINask2);
