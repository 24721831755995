import React from "react";
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import checkPermissions from '../../shared/checkPermissions';
import checkPermissionsActions from '../../shared/checkPermissionsActions';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Cached from "@material-ui/icons/Cached";
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import CustomInput from "components/CustomInput/CustomInput.jsx";
import walmart from "assets/img/walmart_logo.png";
import macys from "assets/img/macys_logo.png";
import standardgs1 from "assets/img/standardgs1_logo.png";
import Button from "components/CustomButtons/Button.jsx";
import { qs } from 'shared/utility.js';

import RFIDtools from "containers/RFID/RFIDtools";
import JCPImporter from "../../containers/RFID/JCPImporter/JCPImporter";
import JCPImporterHistory from "../../containers/RFID/JCPImporter/JCimporterHistory";
import AllBirdsImporter from "../../containers/RFID/AllBirdsImporter/AllBirdsImporter";
import AllBirdsImporterHistory from "../../containers/RFID/AllBirdsImporter/AllBirdsImporterHistory";

import { withStyles } from '@material-ui/core/styles';
import styles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import { Dialog } from "@material-ui/core";

const companyStyle = {
  marginRight:20,
  padding:20
}

const companyImg = {
    position: 'absolute',
    left: 213,
    top: 15
}

const companyNamesDict = {
  standardgs1: 'WAL-MART USA(135)',
  allbirds: 'ALLBIRDS',
  jcpenny1: 'JCP (UPC-A)',
  jcpenny2: 'JCP (C128)',
  walmart: 'WALMART'
};

class RFID extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openTools: false,
      openImporter: false,
      importerType: '',
      openImporterHistory: false,
      company: '',
      overrideGS1: false,
      shouldOverrideGS1Length: '',
      openOverrideBox: false,
      forceError: false
    }
  }

  renderCompanies = (companies) => {
    console.log('companies', companies);
    return companies.map((c,i) => {
      return <Button
        color="rose"
        key={`co-image-${i}`}
        style={{marginRight:20}}
        onClick={() => {
          window.location.href = '/rfid?q='+c.name;
          console.log('clicked', c.name);
          this.setState({
            openTools: true,
            company: c.name
          })
        }}>{companyNamesDict[c.name] ? companyNamesDict[c.name] : c.name}</Button>
    });
  }

  back = () => {
    this.setState({
      openTools: false,
      company: null
    });
  }

  setOverrideGS1 = (override) => {
    const actionPerms = checkPermissionsActions('RFID');
    if (actionPerms.hasOwnProperty('override')) {
      const obj = {
        overrideGS1: override ? true : false
      };
      this.setState(obj);
    }
  };

  componentDidMount() {
    this.setOverrideGS1();
    this.props.checkMaintenanceMode();
    this.props.loadRFIDprefix();
    this.props.loadRFIDcompanies();
    let params = qs(this.props.location.search);
    console.log('params',params);
    const obj = {
      forceError: params.forceError ? true : false
    }
    if (params.q) {
      obj.openTools = true;
      obj.company = params.q;
    }
    this.setState(obj);
  }

  renderOverrideBox = (actionPerms, classes) => {
    return <div>{(actionPerms.hasOwnProperty('override') && this.state.openOverrideBox) && <Dialog
      open={true}
      onClose={() => {
        if (this.state.shouldOverrideGS1Length) {
          this.setState({
            openOverrideBox: false
          })
        }
      }}
      maxWidth="md"
      fullWidth={true}
    >
      <div className="dialog-wrapper">
    <Checkbox
      tabIndex={-1}
      checkedIcon={<Check className={classes.checkedIcon} />}
      icon={<Check className={classes.uncheckedIcon} />}
      classes={{checked: classes.checked}}
      checked={this.state.overrideGS1}
      onClick={() => {
        const shouldOverrideGS1 = !this.state.overrideGS1;
        const obj = {
          overrideGS1: shouldOverrideGS1
        };
        if (!shouldOverrideGS1) {
          obj.shouldOverrideGS1Length = '';
        }
        this.setState(obj);
      }}
    />
    Override GS1 API
    <br />
    <CustomInput
      labelText="GS1 Prefix Length"
      formControlProps={{
        fullWidth: true
      }}
      inputProps={{
        onChange: (e) => {
          this.setState({
            shouldOverrideGS1Length: e.target.value
          });
        },
        onKeyPress:(event) => {
          if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
          }
        },
        value: this.state.shouldOverrideGS1Length
      }}
    />
    <br />
    {(this.state.overrideGS1 && !this.state.shouldOverrideGS1Length) && <div style={{color:'red'}}>
        Required
      </div>}
    <Button color="rose" style={{marginTop:20,marginRight:10}} onClick={() => {
      if (this.state.shouldOverrideGS1Length && this.state.overrideGS1) {
        this.setState({
          openOverrideBox: false
        })
      } else if (!this.state.shouldOverrideGS1Length && !this.state.overrideGS1) {
        this.setState({
          openOverrideBox: false
        })
      }
    }}>
      OK
    </Button>
    <Button
      color="white"
      style={{marginTop:20}}
      onClick={() => {
        this.setState({
          openOverrideBox: false
        })
      }}
    >
      CANCEL
    </Button>
    </div>
  </Dialog>}</div>
  }

  render() {
    if (!checkPermissions('RFID')) {
      return <Redirect to="/dashboard" />;
    }
    if (this.props.maintenanceMode === 'yes') return <Redirect to="/components/maintenance_page" />
    const actionPerms = checkPermissionsActions('RFID');
    console.log('actionPerms', actionPerms);
    const images = {
      macys, walmart, standardgs1
    }
    const { classes } = this.props;
    const companies = Object.keys(actionPerms).filter(f => {
      if (f === 'jcp-importer') return false;
      if (f === 'allbirds-importer') return false;
      if (f === 'override') return false;
      return true;
    }).sort().map((ac,i) => {
      return {
        name: ac,
        image: images[ac]
      }
    });

    console.log('st pr', this.state, this.props)
    let companyName = this.state.company ? this.state.company.toUpperCase() : '';
    if (companyName === 'STANDARDGS1') companyName = 'STANDARD GS1'
    return (
  <div style={{maxWidth:1200}}>
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <div className="pointed">
                <Cached onClick={this.back} />
              </div>
            </CardIcon>
          </CardHeader>
          <CardBody>
            <div style={{paddingBottom:40}}>
              <div className="parentable">
                {this.state.company && <div style={companyImg}>{companyName}</div>}
                <h1 style={{marginBottom:10}}>RFID Tools</h1>
                {(this.state.overrideGS1 && !this.state.shouldOverrideGS1Length) && 
                  <div className="warning-text underlined"
                    onClick={() => {
                      this.setState({
                        openOverrideBox: true
                      })
                    }}
                  ><strong>GS1 Prefix Override Mode is ON - No Prefix Length</strong></div>}
                {(this.state.overrideGS1 && this.state.shouldOverrideGS1Length) && 
                  <div className="warning-text underlined"
                    onClick={() => {
                      this.setState({
                        openOverrideBox: true
                      })
                    }}
                  ><strong>GS1 Prefix Override Mode is ON - Length of GS1 Prefix is {this.state.shouldOverrideGS1Length}</strong></div>}
                {this.renderOverrideBox(actionPerms, classes)}
              </div>
              {!this.state.openTools &&
                <div>
                  <p style={{marginBottom:30}}>EPC Encoders / Decoders</p>
                  {this.renderCompanies(companies)}
                </div>}
              {this.state.openTools &&
              <div>
                <RFIDtools
                  company={this.state.company}
                  company_ids={this.props.company_ids}
                  back={this.back}
                  overrideGS1={this.state.overrideGS1}
                  shouldOverrideGS1Length={this.state.shouldOverrideGS1Length}
                  setOverrideGS1={this.setOverrideGS1}
                  setOpenOverrideBox={(openOverrideBox) => { this.setState({openOverrideBox}) }}
                  forceError={this.state.forceError}
                />
              </div>}

              {!this.state.openTools && <div>
                {actionPerms.hasOwnProperty('jcp-importer') && <div>
                  <hr />
                  <h2><strong>JC PENNEY</strong></h2>
                  {(!this.state.openImporter && !this.state.openTools) && <Button style={{marginRight:20}} color="primary"
                    onClick={() => {
                      this.setState({
                        importerType: 'JCP',
                        openImporter: true,
                        openTools: false,
                        company: ''
                      })
                    }}
                  >IMPORTER</Button>}
                  {(this.state.openImporter && this.state.importerType === 'JCP') && <JCPImporter 
                    getRfidJcdata={this.props.getRfidJcdata}
                    open={this.state.openImporter}
                    close={() => {
                      this.setState({openImporter:false})
                    }}
                    overrideGS1={this.state.overrideGS1}
                  />}
                  {(!this.state.openImporterHistory && !this.state.openTools) && <Button color="primary"
                    onClick={() => {
                      this.setState({
                        importerType: 'JCP',
                        openImporterHistory: true,
                        openTools: false,
                        company: ''
                      })
                    }}
                  >IMPORTER HISTORY</Button>}
                  {(this.state.openImporterHistory && this.state.importerType === 'JCP')  && <JCPImporterHistory 
                    open={this.state.openImporterHistory}
                    close={() => {
                      this.setState({openImporterHistory:false})
                    }}
                    overrideGS1={this.state.overrideGS1}
                    forceError={this.state.forceError}
                  />}
                </div>}
                {actionPerms.hasOwnProperty('allbirds-importer') && <div>
                  <hr />
                  <h2><strong>ALLBIRDS</strong></h2>
                  {(!this.state.openImporter && !this.state.openTools) && <Button style={{marginRight:20}} color="primary"
                    onClick={() => {
                      this.setState({
                        importerType: 'ALLBIRDS',
                        openImporter: true,
                        openTools: false,
                        company: ''
                      })
                    }}
                  >IMPORTER</Button>}
                  {(this.state.openImporter && this.state.importerType === 'ALLBIRDS') && <AllBirdsImporter 
                    getRfidAllBirds={this.props.getRfidAllBirds}
                    open={this.state.openImporter}
                    close={() => {
                      this.setState({openImporter:false})
                    }}
                    overrideGS1={this.state.overrideGS1}
                    shouldOverrideGS1Length={this.state.shouldOverrideGS1Length}
                    forceError={this.state.forceError}
                    renderOverrideBox={this.renderOverrideBox}
                    setOpenOverrideBox={(openOverrideBox) => { this.setState({openOverrideBox}) }}
                    actionPerms={actionPerms}
                    classes={classes}
                  />}
                  {(!this.state.openImporterHistory && !this.state.openTools) && <Button color="primary"
                    onClick={() => {
                      this.setState({
                        importerType: 'ALLBIRDS',
                        openImporterHistory: true,
                        openTools: false,
                        company: ''
                      })
                    }}
                  >IMPORTER HISTORY</Button>}
                  {(this.state.openImporterHistory && this.state.importerType === 'ALLBIRDS')  && <AllBirdsImporterHistory 
                    open={this.state.openImporterHistory}
                    close={() => {
                      this.setState({openImporterHistory:false})
                    }}
                    overrideGS1={this.state.overrideGS1}
                  />}
                </div>}
              </div>}
              
            </div>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    company_ids: state.rfid.company_ids,
    maintenanceMode: state.userPerms.maintenanceMode
  }
}

const mapDispatchToProps = dispatch => {
  return {
    loadGS1CompanyPrefix: (gtin14) => dispatch(actions.loadGS1CompanyPrefix(gtin14)),
    loadRFIDprefix: () => dispatch(actions.loadRFIDprefix()),
    loadRFIDcompanies: () => dispatch(actions.loadRFIDcompanies()),
    checkMaintenanceMode: () => {
      dispatch(actions.checkMaintenanceMode());
    },
    getRfidJcdata: (data, config) => {
      dispatch(actions.getRfidJcdata(data, config));
    },
    getRfidAllBirds: (data, config, forceError, overrideGS1, shouldOverrideGS1Length) => {
      dispatch(actions.getRfidAllBirds(data, config, forceError, overrideGS1, shouldOverrideGS1Length));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RFID));
