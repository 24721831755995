import React, { Component } from "react";
import { connect } from 'react-redux';
import ReactDOM from "react-dom";
import { PulseLoader } from 'react-spinners';
import * as actions from 'store/actions';
import Select from 'react-select';

import withStyles from "@material-ui/core/styles/withStyles";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

// custom
import combineStyles from "shared/combineStyles";
import BPSelect from "containers/BP/fields/BPSelect";
import BPObjSelect from "containers/BP/fields/BPObjSelect";
import BPInput from "containers/BP/fields/BPInput";
import BPmodal from "containers/BP/BPmodal";
import BPCheckbox from "containers/BP/fields/BPCheckbox";
import BPCalendar from "containers/BP/fields/BPCalendar";
import BPRadio from "containers/BP/fields/BPRadio";
import BPTextArea from "containers/BP/fields/BPTextArea";
import BPFormReview from "containers/BP/BPFormReview";
import BPReview from "containers/BP/BPReview";
import BPmodalSimilars from "containers/BP/BPmodalSimilars";
import BPDialog from "./BPDialog";
import BPVendorCodeDialog from "./BPVendorCodeDialog";
import BPlist from "./BPlist";

import BPDropdown from "containers/BP/fields/BPDropdown2";
import BPInputDropdown from "containers/BP/fields/BPInputDropdown";
import BPRBOedi from "containers/BP/fields/BPRBOedi.js";

import { required, email, blacklist, tel_blacklist, zipcode_blacklist, alphanum_whitelist, alpha_whitelist, not_just_num, no_num_start, num_whitelist } from 'shared/validation';
import { normalizePhone, denormalizePhone, upperCase, formatDate, checkForDuplicates, commatize, cleanAllPhones } from "shared/utility";
import charValidate from "shared/charValidation";
import devStatus from "shared/devStatus";

// core components
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Clearfix from "components/Clearfix/Clearfix.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";
import TextField from '@material-ui/core/TextField';
import SweetAlert from "react-bootstrap-sweetalert";
import Close from "@material-ui/icons/Close";
import Delete from '@material-ui/icons/Delete';
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import Radio from "@material-ui/core/Radio";

import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";
import checkPermissionsActions from "shared/checkPermissionsActions";
import helpers from "./BPhelpers";

import GINalert from "containers/GIN/GINalert";
import cloneDeep from "lodash/cloneDeep";
const acceptedFileTypes = 'image/x-png, image/png, image/jpg, image/jpeg, application/pdf';
const imageMaxSize = 10485760; // 10 MB for image/pdf
const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => {return item.trim()})

// TABLE OF CONTENTS
// =================
// # RBO/EDI

const findKey = (value, obj) => {
  let key = null;
  Object.keys(obj).forEach(keys => {
    if (obj[keys] === value) {
      key = keys;
    }
  })
  return key;
}

const turnCheck = (objArr, type, objArr2) => {
  let obj = {}, alreadySetRBO = [], alreadySetEDI = [];
  if (objArr) {
    objArr.forEach(o => {
      const gmp_id = o.gmp_id ? o.gmp_id : 0,
            aa_id = o.aa_id ? o.aa_id : 0;
      if (type === 'rbo') {
        if (aa_id != 0 && o.id != 0) {
          obj[`${o.id}_${o.rbo}_${gmp_id}_${aa_id}`] = 0;
        } else {
          obj[`${o.id}_${o.rbo}_${gmp_id}_${aa_id}`] = 1;
        }
        alreadySetRBO.push(`${o.rbo}_${gmp_id}_${aa_id}`);
      } else {
        // type === edi
        const edi_program = o.edi_program ? o.edi_program.toUpperCase() : '';
        if (aa_id != 0 && o.id != 0) {
          obj[`${o.id}_${edi_program}_${gmp_id}_${aa_id}`] = 0;
        } else {
          obj[`${o.id}_${edi_program}_${gmp_id}_${aa_id}`] = 1;
        }
        alreadySetEDI.push(`${edi_program}_${gmp_id}_${aa_id}`);
      }
    });
  }

  if (objArr2) {
    objArr2.forEach(o => {
      const a_id = o.a_id;
      if (type === 'rbo') {
        const check = `${o.rbo}_0_${a_id}`;
        if (alreadySetRBO.indexOf(check) === -1) {
          obj[`0_${o.rbo}_0_${a_id}`] = 1;
        }
      } else {
        // type === edi
        const edi_program = o.edi_program ? o.edi_program.toUpperCase() : '';
        const check = `${o.edi_program}_0_${a_id}`;
        if (alreadySetEDI.indexOf(check) === -1) {
          obj[`0_${edi_program}_0_${a_id}`] = 1;
        }
      }
    });
  }
  return obj;
}

const turnInput = (objArr, type, objArr2) => {
  let obj = {};
  if (objArr) {
    objArr.forEach(o => {
      const edi_program = o.edi_program ? o.edi_program.toUpperCase() : '';
      const gmp_id = o.gmp_id ? o.gmp_id : 0,
            aa_id = o.aa_id ? o.aa_id : 0;
      obj[`${o.id}_${edi_program}_${gmp_id}_${aa_id}`] = o.vendor_code;
    });
  }

  if (objArr2) {
    objArr2.forEach(o => {
      const edi_program = o.edi_program ? o.edi_program.toUpperCase() : '';
      obj[`0_${edi_program}_0_${o.a_id}`] = o.vendor_code;
    });
  }
  return obj;
}

const sortObj = (obj, field) => {
  return obj.sort(( a, b ) => {
      if ( a[field] < b[field] ){
        return -1;
      }
      if ( a[field] > b[field] ){
        return 1;
      }
      return 0;
    });
}

const normalizePhoneArr = (arr) => {
  if (arr) {
    const contacts = [ ...arr ];
    let conts = [];
    contacts.forEach((c) => {
      conts.push({ ...c, tel: normalizePhone(c.tel), alttel: normalizePhone(c.alttel), mobile: normalizePhone(c.mobile), fax: normalizePhone(c.fax)});
    });
    return conts;
  }
  return [];
}

const cleanBPValues = (beforeValues, finalValues) => {
  let obj = { before: { DB:finalValues.DB, bp_type:finalValues.bp_type }, after: { DB:finalValues.DB, bp_type:finalValues.bp_type } };
  const phoneFields = ['gen_tel','gen_fax','accounts_payable_tel','accounts_payable_fax','accounts_payable_mobile','accounts_payable_alttel',
                  'purchasing_tel','purchasing_mobile','purchasing_alttel','purchasing_fax'];
  const comparableSingles = [
  "bp_type",
  "card_code",
  "company_name",
  "bp_subgroup",
  "payterms",
  "credit_limit",
  "acctname",
  "gen_tel",
  "gen_fax",
  "currency",
  "rfc_curp",
  "resale_certificate",
  "nit",
  "old_code",
  "panno",
  "tinno",
  "notes",
  "withholding",
  "surcharge_overlook",
  "threshold_overlook",
  "certificate_num",
  "expiration_date",
  "accrual_criteria",
  "assessee_type",
  "ni_num",
  "billing_addressname",
  "billing_oname",
  "billing_address1",
  "billing_address2",
  "billing_country",
  "billing_state",
  "billing_zipcode",
  "billing_city",
  "billing_gstregno",
  "billing_gstregtype",
  "shipping_addressname",
  "shipping_oname",
  "shipping_address1",
  "shipping_address2",
  "shipping_country",
  "shipping_state",
  "shipping_zipcode",
  "shipping_city",
  "shipping_gstregno",
  "shipping_gstregtype",
  "accounts_payable_name",
  "accounts_payable_oname",
  "accounts_payable_position",
  "accounts_payable_tel",
  "accounts_payable_alttel",
  "accounts_payable_mobile",
  "accounts_payable_fax",
  "accounts_payable_email",
  "accounts_payable_firstname",
  "accounts_payable_lastname",
  "purchasing_name",
  "purchasing_oname",
  "purchasing_position",
  "purchasing_tel",
  "purchasing_alttel",
  "purchasing_mobile",
  "purchasing_fax",
  "purchasing_email",
  "purchasing_firstname",
  "purchasing_lastname",
  "wtcodes"
];

  const comparableArrs = ["additionalShippingAddresses","additionalContacts"];

  // clean beforeValues and assign to before
  Object.keys(beforeValues).forEach((bfield) => {
    if (beforeValues[bfield] === undefined || beforeValues[bfield] === null || beforeValues[bfield] === '') {
      obj.before[bfield] = '';
    } else {
      if (phoneFields.indexOf(bfield) !== -1) {
        obj.before[bfield] = denormalizePhone(beforeValues[bfield].toString());
      } else {
        obj.before[bfield] = beforeValues[bfield].toString();
      }
    }
  });

  Object.keys(finalValues).forEach((bfield) => {
    if (finalValues[bfield] === undefined || finalValues[bfield] === null || finalValues[bfield] === '') {
      obj.after[bfield] = '';
    } else {
      obj.after[bfield] = finalValues[bfield].toString();
    }
  });

  comparableArrs.forEach((arr) => {
    if (arr === "additionalShippingAddresses") {
      obj.before.additionalShippingAddresses = []
      if (beforeValues.additionalShippingAddresses) {
        if (beforeValues.additionalShippingAddresses.length > 0) {
          beforeValues.additionalShippingAddresses.forEach((sa) => {
            obj.before.additionalShippingAddresses.push({
              shipname: sa.shipname ? sa.shipname : '',
              oname: sa.oname ? sa.oname : '',
              add1: sa.add1 ? sa.add1 : '',
              add2: sa.add2 ? sa.add2 : '',
              country: sa.country ? sa.country : '',
              state: sa.state ? sa.state : '',
              city: sa.city ? sa.city : '',
              zipcode: sa.zipcode ? sa.zipcode : '',
              gstregno: sa.gstregno ? sa.gstregno : '',
              gstregtype: sa.gstregtype ? sa.gstregtype : ''
            });
          });
        }
      }

      obj.after.additionalShippingAddresses = [];
      if (finalValues.additionalShippingAddresses) {
        if (finalValues.additionalShippingAddresses.length > 0) {
          finalValues.additionalShippingAddresses.forEach((sa) => {
            obj.after.additionalShippingAddresses.push({
              shipname: sa.shipname ? sa.shipname : '',
              oname: sa.oname ? sa.oname : '',
              add1: sa.add1 ? sa.add1 : '',
              add2: sa.add2 ? sa.add2 : '',
              country: sa.country ? sa.country : '',
              state: sa.state ? sa.state : '',
              city: sa.city ? sa.city : '',
              zipcode: sa.zipcode ? sa.zipcode : '',
              gstregno: sa.gstregno ? sa.gstregno : '',
              gstregtype: sa.gstregtype ? sa.gstregtype : ''
            });
          });
        }
      }
    }

    if (arr === "additionalContacts") {
      obj.before.additionalContacts = [];
      if (beforeValues.additionalContacts) {
        if (beforeValues.additionalContacts.length > 0) {
          beforeValues.additionalContacts.forEach((sa) => {
            obj.before.additionalContacts.push({
              name: sa.name ? sa.name : '',
              firstname: sa.firstname ? sa.firstname : '',
              lastname: sa.lastname ? sa.lastname : '',
              oname: sa.oname ? sa.oname : '',
              tel: sa.tel ? denormalizePhone(sa.tel) : '',
              alttel: sa.alttel ? denormalizePhone(sa.alttel) : '',
              mobile: sa.mobile ? denormalizePhone(sa.mobile) : '',
              fax: sa.fax ? denormalizePhone(sa.fax) : '',
              position: sa.position ? sa.position : '',
              email: sa.email ? sa.email : ''
            });
          });
        }
      }

      obj.after.additionalContacts = [];
      if (finalValues.additionalContacts) {
        if (finalValues.additionalContacts.length > 0) {
          finalValues.additionalContacts.forEach((sa) => {
            obj.after.additionalContacts.push({
              name: sa.name ? sa.name : '',
              firstname: sa.firstname ? sa.firstname : '',
              lastname: sa.lastname ? sa.lastname : '',
              oname: sa.oname ? sa.oname : '',
              tel: sa.tel ? sa.tel : '',
              alttel: sa.alttel ? sa.alttel : '',
              mobile: sa.mobile ? sa.mobile : '',
              fax: sa.fax ? sa.fax : '',
              position: sa.position ? sa.position : '',
              email: sa.email ? sa.email : ''
            });
          });
        }
      }
    }
  });

  // RBO EDI
  obj.before.rbosChecked = finalValues.before_rbosChecked;
  obj.before.ediProgramsChecked = finalValues.before_ediProgramsChecked;
  obj.before.vendorCodes = finalValues.before_vendorCodes;

  obj.changes = { ...finalValues };
  delete obj.changes.before_rbosChecked;
  delete obj.changes.before_ediProgramsChecked;
  delete obj.changes.before_vendorCodes;
  return obj;
}

const upper = value => value && value.toUpperCase();
const minLength = min => value =>
  value && value.length < min ? `Must be minimum of ${min} characters` : undefined
const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined
const maxLength50 = maxLength(50);
const maxLength100 = maxLength(100);
const maxLength10 = maxLength(10);
const maxLength24 = maxLength(24);
const maxLength20 = maxLength(20);
const maxLength16 = maxLength(16);
const maxLength15 = maxLength(15);
const maxLength13 = maxLength(13);
const minLength12 = minLength(12);
const minLength10 = minLength(10);
const capitalize = (words) => {
  return words.map(w => w.charAt(0).toUpperCase() + w.substr(1) );
}
const onlyNumber = value => value ? commatize(value.replace(/[^0-9.,]/g, '')).toString() : '0';
const whitelistNormalize = value => value && value.replace(/[^a-z A-Z0-9-_$+.@/()#&,']/g,'')
const whitelist = value => value && (/^[a-z 0-9-_$+.@/()#&,']*$/i).test(value) === false ?
                                    'Only A-Z,a-z 0-9 and some special characters are allowed' : undefined;
const only_number_whitelist = value => {
  if (value && value != 0) {
    const val = value.toString();
    return val && isNaN(val && val.replace(/[,]/g,'')) ? 'Only valid numbers are allowed' : undefined
  } else {
    return undefined;
  }
};

// validator list
const validations = {
  bp_type: [required],
  bp_subgroup: [required],
  company_name: [required, whitelist],
  currency: [required],
  gen_tel: [required,maxLength20,tel_blacklist, num_whitelist],
  gen_fax: [maxLength20,tel_blacklist, num_whitelist],
  payterms: [required],
  credit_limit: [required,only_number_whitelist],
  old_code: [whitelist],
  nit: [required,whitelist],
  panno: [required,maxLength10,minLength10,whitelist],
  tinno: [maxLength20],
  rfc_curp: [minLength12,maxLength13,required,alphanum_whitelist],

  // Billing Address (only 1)
  billing_addressname: [required,maxLength50,blacklist,whitelist,not_just_num],
  billing_address1: [required,maxLength100,blacklist,whitelist],
  billing_address2: [maxLength100,blacklist,whitelist],
  billing_country: [required],
  billing_state: [required],
  billing_city: [required,maxLength100,blacklist],
  billing_zipcode: [required,maxLength20,zipcode_blacklist],
  billing_gstregno: [required,maxLength15,alphanum_whitelist],
  billing_gstregtype: [required],

  // Shipping Address 1 (not required if Supplier)
  shipping_addressname: [required,maxLength50,blacklist,not_just_num],
  shipping_address1: [required,maxLength100,blacklist],
  shipping_address2: [maxLength100,blacklist],
  shipping_country: [required],
  shipping_state: [required],
  shipping_city: [required,maxLength100,blacklist],
  shipping_zipcode: [required,maxLength20,zipcode_blacklist],
  shipping_gstregno: [required,maxLength15,alphanum_whitelist],
  shipping_gstregtype: [required],

  // Supplier validations
  shipping2_addressname: [maxLength50,blacklist,not_just_num],
  shipping2_address1: [maxLength100,blacklist],
  shipping2_address2: [maxLength100,blacklist],
  shipping2_country: [],
  shipping2_state: [],
  shipping2_city: [maxLength100,blacklist],
  shipping2_zipcode: [maxLength20,zipcode_blacklist],
  shipping2_gstregno: [maxLength15,alphanum_whitelist],
  shipping2_gstregtype: [],

  // Contact 1
  accounts_payable_name: [required,maxLength50,blacklist,whitelist,no_num_start],
  accounts_payable_position: [required,maxLength50,blacklist, alpha_whitelist],
  accounts_payable_tel: [required,maxLength20,tel_blacklist,num_whitelist],
  accounts_payable_alttel: [maxLength20,tel_blacklist,num_whitelist],
  accounts_payable_mobile: [maxLength20,tel_blacklist,num_whitelist],
  accounts_payable_fax: [maxLength20,tel_blacklist],
  accounts_payable_email: [required,maxLength100,email],
  accounts_payable_firstname: [required, maxLength16, no_num_start, alpha_whitelist],
  accounts_payable_lastname: [required, maxLength16, no_num_start, alpha_whitelist],

  // Contact 2
  purchasing_name: [maxLength50,blacklist,no_num_start],
  purchasing_position: [maxLength50,blacklist, alpha_whitelist],
  purchasing_tel: [maxLength20,tel_blacklist, num_whitelist],
  purchasing_alttel: [maxLength20,tel_blacklist, num_whitelist],
  purchasing_mobile: [maxLength20,tel_blacklist, num_whitelist],
  purchasing_fax: [maxLength20,tel_blacklist, num_whitelist],
  purchasing_email: [maxLength100,email],
  purchasing_firstname: [maxLength16, no_num_start, alpha_whitelist],
  purchasing_lastname: [maxLength16, no_num_start, alpha_whitelist],

  notes: []
}

const completeValidations = {
  bp_type: [required],
  bp_subgroup: [required],
  company_name: [required, whitelist],
  currency: [required],
  gen_tel: [required,maxLength20,tel_blacklist, num_whitelist],
  gen_fax: [maxLength20,tel_blacklist, num_whitelist],
  payterms: [required],
  credit_limit: [required,only_number_whitelist],
  old_code: [whitelist],
  nit: [required,whitelist],
  panno: [required,maxLength10,minLength10,whitelist],
  tinno: [maxLength20],
  rfc_curp: [minLength12,maxLength13,required,alphanum_whitelist],

  // Billing Address (only 1)
  billing_addressname: [required,maxLength50,blacklist,whitelist,not_just_num],
  billing_address1: [required,maxLength100,blacklist,whitelist],
  billing_address2: [maxLength100,blacklist,whitelist],
  billing_country: [required],
  billing_state: [required],
  billing_city: [required,maxLength100,blacklist],
  billing_zipcode: [required,maxLength20,zipcode_blacklist],
  billing_gstregno: [required,maxLength15,alphanum_whitelist],
  billing_gstregtype: [required],

  // Shipping Address 1 (not required if Supplier)
  shipping_addressname: [required,maxLength50,blacklist,not_just_num],
  shipping_address1: [required,maxLength100,blacklist],
  shipping_address2: [maxLength100,blacklist],
  shipping_country: [required],
  shipping_state: [required],
  shipping_city: [required,maxLength100,blacklist],
  shipping_zipcode: [required,maxLength20,zipcode_blacklist],
  shipping_gstregno: [required,maxLength15,alphanum_whitelist],
  shipping_gstregtype: [required],

  // Supplier validations
  shipping2_addressname: [maxLength50,blacklist,not_just_num],
  shipping2_address1: [maxLength100,blacklist],
  shipping2_address2: [maxLength100,blacklist],
  shipping2_country: [],
  shipping2_state: [],
  shipping2_city: [maxLength100,blacklist],
  shipping2_zipcode: [maxLength20,zipcode_blacklist],
  shipping2_gstregno: [maxLength15,alphanum_whitelist],
  shipping2_gstregtype: [],

  // Contact 1
  accounts_payable_name: [required,maxLength50,blacklist,whitelist,no_num_start],
  accounts_payable_position: [required,maxLength50,blacklist,whitelist],
  accounts_payable_tel: [required,maxLength20,tel_blacklist,num_whitelist],
  accounts_payable_alttel: [maxLength20,tel_blacklist,num_whitelist],
  accounts_payable_mobile: [maxLength20,tel_blacklist,num_whitelist],
  accounts_payable_fax: [maxLength20,tel_blacklist,num_whitelist],
  accounts_payable_email: [required,maxLength100,email],
  accounts_payable_firstname: [required, maxLength16, no_num_start],
  accounts_payable_lastname: [required, maxLength16, no_num_start],

  // Contact 2
  purchasing_name: [maxLength50,blacklist,no_num_start],
  purchasing_position: [maxLength50,blacklist],
  purchasing_tel: [maxLength20,tel_blacklist,num_whitelist],
  purchasing_alttel: [maxLength20,tel_blacklist,num_whitelist],
  purchasing_mobile: [maxLength20,tel_blacklist,num_whitelist],
  purchasing_fax: [maxLength20,tel_blacklist,num_whitelist],
  purchasing_email: [maxLength100,email],
  purchasing_firstname: [maxLength16, no_num_start],
  purchasing_lastname: [maxLength16, no_num_start],

  notes: [whitelist]
}

const Addresses = props => {
  const {
    classes,
    addAddress,
    removeAddress,
    handleAddressTextInputChange,
    handleAddressSelectChange,
    normalize,
    addressArr,
    gsttypes,
    defaultShipping,
    onDefaultShippingChange,
    currentDB,
    openAskModal
  } = props;
  return (
  <GridContainer style={{marginLeft:'3px'}}>
    <GridItem xs={12} sm={12} md={12}>
      <div style={{position:'relative'}}>
        <Button
          size="sm"
          color="rose"
          type="button"
          className={classes.updateProfileButton}
          onClick={() => addAddress({
            shipname: '',
            add1: '',
            add2: '',
            country: '',
            state: '',
            city: '',
            zipcode: '',
            gstregno: '',
            gstregtype: ''
          })}
          style={{position:'absolute',top:'-22px',right:'150px'}}
        >
          ADD ADDRESS
        </Button>
      </div>
    </GridItem>
    {addressArr.map((shipadd, index) => {
        const { countries, states, addressArr, countriesDictionary, statesDictionary } = props;
        let shippingStates = null;
        if (addressArr) {
          if (addressArr.length > 0) {
            if (shipadd.country) {
              if (states.hasOwnProperty(shipadd.country)) {
                shippingStates = states[shipadd.country];
              }
            }
          }
        }
        return (
          <GridItem xs={12} sm={12} md={6} key={`grd${index}`}>
            <Card>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <h4><strong>Shipping Address {index+2}</strong></h4>
                    <div className="relative">
                      <div style={{top:'-46px',right:'12px',position:'absolute'}}>
                        <BPCheckbox
                          label="Default"
                          name={index}
                          value={defaultShipping === index ? true : false}
                          onChange={onDefaultShippingChange}
                        />
                      </div>
                    </div>
                    <a onClick={() => {
                      //removeAddress(index);
                      openAskModal(`removeAddress,${index}`);
                    }}
                    className="upper-right-a">
                      <Close />
                    </a>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <BPInput
                      label="Name"
                      name={`${index},shipname`}
                      value={shipadd.shipname}
                      handleTextInputChange={handleAddressTextInputChange}
                      normalize={normalize}
                      validate={validations.shipping_addressname}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <BPInput
                      label="Address 1"
                      name={`${index},add1`}
                      value={shipadd.add1}
                      handleTextInputChange={handleAddressTextInputChange}
                      normalize={normalize}
                      validate={validations.shipping_address1}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <BPInput
                      label="Address 2"
                      name={`${index},add2`}
                      value={shipadd.add2}
                      handleTextInputChange={handleAddressTextInputChange}
                      normalize={normalize}
                      validate={validations.shipping_address2}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <BPInput
                      label="City"
                      name={`${index},city`}
                      value={shipadd.city}
                      handleTextInputChange={handleAddressTextInputChange}
                      normalize={normalize}
                      validate={validations.shipping_city}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    {/*<BPObjSelect
                      label="Country"
                      options={countries}
                      choose={handleAddressSelectChange}
                      name={`${index},country`}
                      iterator={['id','name']}
                      default={shipadd.country}
                      validate={validations.shipping_country}
                      hasBlank={true}
                    />*/}
                    {
                      <BPInputDropdown
                        onClick={() => {
                          props.showDropdown({
                            value: shipadd.country,
                            options: countries,
                            indx: 'id',
                            desc: 'name',
                            field: `${index},country`,
                            title: 'Select a Country',
                            dict: countriesDictionary,
                            choose: handleAddressSelectChange
                          });
                        }}
                        label="Country"
                        name={`${index},country`}
                        value={shipadd.country}
                        dict={countriesDictionary}
                        validate={validations.shipping_country}
                      />
                    }
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    {/*shippingStates &&
                      <BPObjSelect
                        label="State"
                        options={shippingStates}
                        choose={handleAddressSelectChange}
                        name={`${index},state`}
                        iterator={['id','name']}
                        validate={validations.shipping_state}
                        default={shipadd.state}
                        hasBlank={true}
                      />*/}
                      {shippingStates &&
                        <BPInputDropdown
                          onClick={() => {
                            props.showDropdown({
                              value: shipadd.state,
                              options: shippingStates,
                              indx: 'id',
                              desc: 'name',
                              field: `${index},state`,
                              title: 'Select a State',
                              dict: statesDictionary[shipadd.country],
                              choose: handleAddressSelectChange
                            });
                          }}
                          label="State"
                          name={`${index},state`}
                          value={shipadd.state}
                          dict={statesDictionary[shipadd.country]}
                          validate={validations.shipping_state}
                        />
                      }
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <BPInput
                      label="Zipcode"
                      name={`${index},zipcode`}
                      value={shipadd.zipcode}
                      handleTextInputChange={handleAddressTextInputChange}
                      normalize={normalize}
                      validate={validations.shipping_zipcode}
                    />
                  </GridItem>

                  {
                    currentDB.indexOf('NIN') !== -1 &&
                  <GridItem xs={12} sm={12} md={6}>
                    <BPInput
                      label="GST Reg.No."
                      name={`${index},gstregno`}
                      value={shipadd.gstregno}
                      handleTextInputChange={handleAddressTextInputChange}
                      normalize={normalize}
                      validate={validations.shipping_gstregno}
                    />
                  </GridItem>
                  }
                  {
                    currentDB.indexOf('NIN') !== -1 &&
                  <GridItem xs={12} sm={12} md={6}>
                    <BPObjSelect
                      label="GST Reg.Type"
                      options={gsttypes}
                      choose={handleAddressSelectChange}
                      name={`${index},gstregtype`}
                      iterator={['id','type']}
                      validate={validations.shipping_gstregtype}
                      default={shipadd.gstregtype}
                    />
                  </GridItem>
                  }
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        )
    })}
  </GridContainer>)
}

const Contacts = props => {
  const {
    classes,
    addContact,
    removeContact,
    handleContactTextInputChange,
    normalize,
    normalizePhone,
    highlightContact,
    openAskModal,
    onlyGMP,
    hideGMP,
    contactArr } = props;
  let highlightIndex = -1;
  if (highlightContact) {
    if (props.highlightContact.indexOf('additionalContacts') !== -1) {
      const hcArr = props.highlightContact.split(',');
      highlightIndex = parseInt(hcArr[1]);
    }
  }
  return (<GridContainer style={{marginLeft:'3px'}}>
    {!onlyGMP && <GridItem xs={12} sm={12} md={12}>
      <div style={{position:'relative'}}>
        <Button
          size="sm"
          color="rose"
          type="button"
          className={classes.updateProfileButton}
          onClick={() => addContact()}
          style={{position:'absolute',top:'-22px',right:'7px'}}
        >
          ADD CONTACT
        </Button>
      </div>
    </GridItem>}
    {contactArr.map((cont, index) => {
      console.log('Contacts found', cont);
      if (cont.name.indexOf('GMP') === -1) {
        if (onlyGMP) {
          return null;
        }
      } else {
        if (hideGMP) {
          return null
        }
      }
      return (
        <GridItem xs={12} sm={12} md={6} key={`grd${index}`}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    {cont.name.indexOf('GMP') !== -1 ? <h4><strong>TPP
                    {cont.name.indexOf('GMP AP') !== -1 ? ' Accounts Payable ' : ''}
                    {cont.name.indexOf('GMP PURCHASING') !== -1 ? ' Purchasing ' : ''}
                    {cont.name.indexOf('GMP OTHER') !== -1 ? ' Other ' : ''}
                     Contact</strong>{cont.gmp ? <span className="blue-text"> (TPP User ID: # {cont.gmp})</span> : ''} {cont.highlight ? <span className="green-box">NEW</span> : ''}</h4> :
                     <h4><strong> Contact {index+3}</strong> {cont.gmp ? <span className="blue-text"> (TPP User ID: # {cont.gmp})</span> : ''}{cont.highlight ? <span className="green-box">NEW</span> : ''}</h4>}
                  {!cont.oname && <a onClick={() => openAskModal(`removeContact,${index}`)} className="upper-right-a">
                    <Close />
                  </a>}
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <div className="input-font-12">
                    <BPInput
                      label="First Name"
                      name={`${index},firstname`}
                      value={cont.firstname}
                      handleTextInputChange={handleContactTextInputChange}
                      normalize={normalize}
                      validate={validations.accounts_payable_firstname}
                    />
                  </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <div className="input-font-12">
                    <BPInput
                      label="Last Name"
                      name={`${index},lastname`}
                      value={cont.lastname}
                      handleTextInputChange={handleContactTextInputChange}
                      normalize={normalize}
                      validate={validations.accounts_payable_lastname}
                    />
                  </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <div className="input-font-12">
                    <BPInput
                      label="Position"
                      name={`${index},position`}
                      value={cont.position}
                      handleTextInputChange={handleContactTextInputChange}
                      normalize={normalize}
                      validate={validations.accounts_payable_position}
                    />
                  </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <div className="input-font-12">
                    <BPInput
                      label="Telephone"
                      name={`${index},tel`}
                      value={cont.tel}
                      handleTextInputChange={handleContactTextInputChange}
                      normalize={normalizePhone}
                      validate={validations.accounts_payable_tel}
                    />
                  </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <div className="input-font-12">
                    <BPInput
                      label="Alt Telephone"
                      name={`${index},alttel`}
                      value={cont.alttel}
                      handleTextInputChange={handleContactTextInputChange}
                      normalize={normalizePhone}
                      validate={validations.accounts_payable_alttel}
                    />
                  </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <div className="input-font-12">
                    <BPInput
                      label="Mobile"
                      name={`${index},mobile`}
                      value={cont.mobile}
                      handleTextInputChange={handleContactTextInputChange}
                      normalize={normalizePhone}
                      validate={validations.accounts_payable_mobile}
                    />
                  </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <div className="input-font-12">
                    <BPInput
                      label="Fax"
                      name={`${index},fax`}
                      value={cont.fax}
                      handleTextInputChange={handleContactTextInputChange}
                      normalize={normalizePhone}
                      validate={validations.accounts_payable_fax}
                    />
                  </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <div className="input-font-12">
                    <BPInput
                      label="E-mail"
                      name={`${index},email`}
                      value={cont.email}
                      handleTextInputChange={handleContactTextInputChange}
                      normalize={normalize}
                      validate={validations.accounts_payable_email}
                      readonly={cont.name.indexOf('GMP') !== -1 ? true : false}
                    />
                  </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <div className="input-font-12">
                    {((!cont.firstname || !cont.lastname) && cont.name) &&
                    <div>
                      <div style={{fontSize:'10px',color:'#838383',marginTop:'10px',opacity:0}}>Name</div>
                      <div style={{color:'red',fontSize:'14px'}}>Previous Contact Name is {cont.name}</div>
                    </div>
                    }
                  </div>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      )
    })}
  </GridContainer>
);
}

const WTCodes = (props) => {
  const { owhtopts, onChange, wtArr, assessee } = props;
  // console.log('the array of wtcodes is ', wtArr);
  return owhtopts.map((wt,i) => {
    let truthy = false;
    if (wtArr.indexOf(wt.WTCode) !== -1) {
      truthy = true;
    }
    // console.log('truthy', truthy, wt.WTCode, wt.WTName);

    if (assessee) {
      if (assessee == 1) {
        if (wt.Assessee === '2') {
          return <BPCheckbox
                  key={`wtcodes-${i}`}
                  label={wt.WTName}
                  name={wt.WTCode}
                  value={truthy}
                  onChange={onChange}
                />
        } else {
          return null
        }
      }
    }
    if (wt.Assessee === '1') {
      return <BPCheckbox
              key={`wtcodes-${i}`}
              label={wt.WTName}
              name={wt.WTCode}
              value={truthy}
              onChange={onChange}
            />
    }
  });
}

const findDefaultShipping = (defaultName, shipping1, shippingArr, bp_type) => {
  console.log('finding default shipping', defaultName, shipping1, shippingArr, bp_type);
  let ii = null;
  if (bp_type) {
    if (bp_type === 'Customer') {
      ii = '-1';
    }
  }
  if (shipping1 === defaultName) {
    return '-1';
  } else {
    shippingArr.forEach((sa, i) => {
      console.log('searching for default shipping', i, sa.shipname, defaultName);
      if (sa.shipname === defaultName) {
        console.log('found it', i);
        ii = i;
        return i;
      }
    });
  }
  return ii;
}

const errorStyle = {color:'red',textAlign:'left',marginBottom:5,marginLeft:20};

class BPForm extends Component {
  constructor(props) {
    super(props);
    console.log('constructing', props.data);
    props.clearSimilars();

    let conts = { ...props.data };
    let highlightContact = null;
    if (props.additionalContact) {
      // additional GMP existing contact
      const bp = { ...props.additionalContact };
      // console.log('contact bps', bp);
      conts.id = bp.id;
      conts.gmp_id = bp.gmp_id;
      if (bp.new_contact_email && bp.new_contact_firstname && bp.new_contact_lastname &&
        bp.new_contact_name && bp.new_contact_position && bp.new_contact_tel) {
          if (!conts.accounts_payable_position || !conts.accounts_payable_name) {
              conts.accounts_payable_name = `GMP OTHER -${bp.new_contact_firstname} ${bp.new_contact_lastname}`;
              conts.accounts_payable_oname = '';
              conts.accounts_payable_position = bp.new_contact_position;
              conts.accounts_payable_tel = normalizePhone(bp.new_contact_tel);
              conts.accounts_payable_alttel = '';
              conts.accounts_payable_mobile = '';
              conts.accounts_payable_fax = '';
              conts.accounts_payable_email = bp.new_contact_email;
              conts.accounts_payable_firstname = bp.new_contact_firstname;
              conts.accounts_payable_lastname = bp.new_contact_lastname;
              conts.accounts_payable_highlight = true;
              conts.accounts_payable_gmp = bp.new_contact_gmp;
              highlightContact = 'accounts_payable';
          } else if (!conts.purchasing_position || !conts.purchasing_name) {
              conts.purchasing_name = `GMP OTHER -${bp.new_contact_firstname} ${bp.new_contact_lastname}`;
              conts.purchasing_oname = '';
              conts.purchasing_position = bp.new_contact_position;
              conts.purchasing_tel = bp.new_contact_tel;
              conts.purchasing_alttel = '';
              conts.purchasing_mobile = '';
              conts.purchasing_fax = '';
              conts.purchasing_email = bp.new_contact_email;
              conts.purchasing_firstname = bp.new_contact_firstname;
              conts.purchasing_lastname = bp.new_contact_lastname;
              conts.purchasing_highlight = true;
              conts.purchasing_gmp = bp.new_contact_gmp;
              highlightContact = 'purchasing';
          } else {
            let additionalContacts = [ ...conts.additionalContacts ];
            additionalContacts.push({
              name: `GMP OTHER -${bp.new_contact_firstname} ${bp.new_contact_lastname}`,
              oname: '',
              position: bp.new_contact_position,
              tel: bp.new_contact_tel,
              email: bp.new_contact_email,
              firstname: bp.new_contact_firstname,
              lastname: bp.new_contact_lastname,
              gmp: bp.new_contact_gmp,
              highlight: true
            });
            conts.additionalContacts = additionalContacts;
            highlightContact = `additionalContacts,${additionalContacts.length - 1}`;
          }
        }
    }

    const rbosChecked = (props.isRegisteredUser || props.isExistingGMP) ? turnCheck(props.localBP.rbo, 'rbo', props.data.rboActive) : turnCheck(props.data.rbo, 'rbo', props.data.rboActive);

    const ediProgramsChecked = (props.isRegisteredUser || props.isExistingGMP) ? turnCheck(props.localBP.edi, 'edi', props.data.ediActive) : turnCheck(props.data.edi, 'edi', props.data.ediActive);

    const vendorCodes = (props.isRegisteredUser || props.isExistingGMP) ? turnInput(props.localBP.edi, 'edi', props.data.ediActive) : turnInput(props.data.edi, 'edi', props.data.ediActive);

    console.log('loaded GMP stuff already', rbosChecked, ediProgramsChecked, vendorCodes);
    const gmp_id = (props.BPaction === 'PENDING' || props.BPaction === 'EXISTING') ? conts.gmp_id : ''

    let finalID = (props.BPaction === 'PENDING' || props.additionalContact) ? conts.id : '';
    if (finalID === undefined) {
      finalID = props.formID;
    }

    this.state = {
      dropdownOpen: false,
      dropdownObject: {},
      rboediOpen: false,
      dropdownRBOEDI: {},
      openedTab: 'General',
      showBPlist: false,
      bps: [],
      isTPP: false,
      allowedDBsDict: [],
      allowedDBsObj: {},
      removedAddress: false,

      showVendorCodeEdit: false,
      vendorCodeEditData: {},
      currentVendorCode: '',

      showImage: false,
      showImageURL: '',
      showPDF: false,
      showPDFURL: '',
      wtcodesPresent: false,
      companyNameModalStatus: false,
      id: finalID, // local db submission id
      gmp_id: gmp_id, // if coming from GMP, if null not from GMP

      // SAP general
      DB: props.BPaction === 'PENDING' ? props.data.DB : '',
      card_code: props.data.card_code, // if existing in SAP
      bp_type: props.BPaction === 'PENDING' ? props.data.bp_type : '',
      bp_subgroup: (props.data.gmp_id && !props.data.bp_subgroup) ? props.defaultSubgroup : props.data.bp_subgroup,
      company_name: props.data.company_name,
      payterms: props.data.payterms ? props.data.payterms : props.defaultPBP,
      old_code: props.data.old_code,
      credit_limit: props.data.credit_limit ? props.data.credit_limit : props.defaultCreditLimit,
      acctname: props.data.acctname,
      currency: props.data.currency,
      gen_tel: normalizePhone(props.data.gen_tel),
      gen_fax: normalizePhone(props.data.gen_fax),
      nit: props.data.nit,
      panno: props.data.panno,
      tinno: props.data.tinno,
      rfc_curp: props.data.rfc_curp,

      resale_cert: props.data.resale_cert, // holds the file
      // holds existing filenames
      resale_certificate: props.data.resale_certificate,
      resale_cert_filename: '', // place information on filename,
      resale_certificate_path: props.data.resale_certificate_path,
      resale_certificate_entry: props.data.resale_certificate_entry,
      alert: '',

      withholding: props.data.withholding,
      ni_num: props.data.ni_num,
      certificate_num: props.data.certificate_num,
      expiration_date: props.data.expiration_date,
      assessee_type: props.data.assessee_type,
      accrual_criteria: props.data.accrual_criteria,
      threshold_overlook: props.data.threshold_overlook,
      surcharge_overlook: props.data.surcharge_overlook,
      wtcodes: props.data.wtcodes,

      // Billing Address (only 1)
      billing_addressname: helpers.removeAddressColon(props.data.billing_addressname),
      billing_oname: props.data.billing_oname ? props.data.billing_oname : '',
      billing_address1: props.data.billing_address1,
      billing_address2: props.data.billing_address2,
      billing_country: props.data.billing_country,
      billing_state: props.data.billing_state,
      billing_city: props.data.billing_city,
      billing_zipcode: props.data.billing_zipcode,
      billing_gstregno: props.data.billing_gstregno,
      billing_gstregtype: props.data.billing_gstregtype,

      // Shipping Address 1 (required if Supplier)
      shipping_addressname: helpers.removeAddressColon(props.data.shipping_addressname),
      shipping_oname: props.data.shipping_oname ? props.data.shipping_oname : '',
      shipping_address1: props.data.shipping_address1,
      shipping_address2: props.data.shipping_address2,
      shipping_country: props.data.shipping_country,
      shipping_state: props.data.shipping_state,
      shipping_city: props.data.shipping_city,
      shipping_zipcode: props.data.shipping_zipcode,
      shipping_gstregno: props.data.shipping_gstregno,
      shipping_gstregtype: props.data.shipping_gstregtype,
      default_shipping: props.submissionType === 'new' ? -1 : findDefaultShipping(props.data.default_shipping, props.data.shipping_addressname, props.data.additionalShippingAddresses, props.data.bp_type),
      additionalShippingAddresses: helpers.removeAddressColonArr(props.data.additionalShippingAddresses),

      // Contact 1
      accounts_payable_name: conts.accounts_payable_name,
      accounts_payable_oname: conts.accounts_payable_oname ? conts.accounts_payable_oname : '',
      accounts_payable_position: conts.accounts_payable_position,
      accounts_payable_tel: normalizePhone(conts.accounts_payable_tel),
      accounts_payable_alttel: normalizePhone(conts.accounts_payable_alttel),
      accounts_payable_mobile: normalizePhone(conts.accounts_payable_mobile),
      accounts_payable_fax: normalizePhone(conts.accounts_payable_fax),
      accounts_payable_email: conts.accounts_payable_email,
      accounts_payable_firstname: conts.accounts_payable_firstname,
      accounts_payable_lastname: conts.accounts_payable_lastname,
      accounts_payable_highlight: conts.accounts_payable_highlight,
      accounts_payable_gmp: conts.accounts_payable_gmp,

      // Contact 2
      purchasing_name: conts.purchasing_name,
      purchasing_oname: conts.purchasing_oname ? conts.purchasing_oname : '',
      purchasing_position: conts.purchasing_position,
      purchasing_tel: normalizePhone(conts.purchasing_tel),
      purchasing_alttel: normalizePhone(conts.purchasing_alttel),
      purchasing_mobile: normalizePhone(conts.purchasing_mobile),
      purchasing_fax: normalizePhone(conts.purchasing_fax),
      purchasing_email: conts.purchasing_email,
      purchasing_firstname: conts.purchasing_firstname,
      purchasing_lastname: conts.purchasing_lastname,
      purchasing_highlight: conts.purchasing_highlight,
      purchasing_gmp: conts.purchasing_gmp,

      // Contacts
      additionalContacts: normalizePhoneArr(conts.additionalContacts),
      highlightContact,
      notes: props.data.notes,

      rbo: this.props.isRegisteredUser ? props.localBP.rbo : props.data.rbo,
      edi: this.props.isRegisteredUser ? props.localBP.edi : props.data.edi,
      rbosChecked,
      ediProgramsChecked,
      vendorCodes,
      before_rbosChecked: rbosChecked,
      before_ediProgramsChecked: ediProgramsChecked,
      before_vendorCodes: vendorCodes,
      updateMagento: (props.updateMagento || gmp_id) ? 'Y' : 'N',

      changes: '',
      mainErrors: '',
      additionalErrors: '',
      showErrorModal: false,
      showUnlockModal: false,
      reviewModalStatus: false,
      finalCleanedValues: null,
      askModal: false,
      askRequest: '',
      similarsAlert: false,
      similarsAlertContent: null
    }

  }

  openUnlockModal = () => {
    this.setState({showUnlockModal:true});
  }

  closeUnlockModal = () => {
    this.setState({showUnlockModal:false});
  }

  openBPlist = (bps, currentVendorCode) => {
    this.setState({
      bps, showBPlist: true,
      currentVendorCode
    })
  }

  closeBPlist = () => {
    this.setState({ showBPlist: false })
  }

  setOpenedTab = (openedTab) => {
    this.setState({ openedTab });
  }

  toggleCheck = (field, combo, value) => {
    let copy = cloneDeep(this.state[field]);
    copy[combo] = value;
    this.setState({ [field]: copy });
  }

  getRequiredRBOs = () => {
    let requiredRBOs = [];
    const edi_programs = this.props.edi_programs;
    const currentEDIs = this.state.ediProgramsChecked;
    let currentEDIsArr = [];
    if (currentEDIs) {
      Object.keys(currentEDIs).forEach((e) => {
        const arr = e.split('_');
        const edi_code = arr[1];
        if (currentEDIs[e] === 1) {
          currentEDIsArr.push(edi_code);
        }
      });
    }
    console.log('the current EDIs are', currentEDIs, currentEDIsArr);
    if (edi_programs) {
      if (edi_programs.length > 0) {
        edi_programs.forEach((e) => {
          if (currentEDIsArr.indexOf(e.ID) !== -1) {
            if (requiredRBOs.indexOf(e['RBO ID']) === -1) requiredRBOs.push(e['RBO ID']);
          }
        });
      }
    }
    console.log('the current EDIs gave RBOs required', requiredRBOs);
    return requiredRBOs;
  }

  getCorrespondingRBO = (edi_code) => {
    const edi_programs = this.props.edi_programs;
    let correspondingRBO = null;
    if (edi_programs) {
      if (edi_programs.length > 0) {
        edi_programs.forEach((e) => {
          if (e.ID === edi_code) correspondingRBO = e['RBO ID'];
        });
      }
      return correspondingRBO;
    }
    alert('No EDI programs loaded, please retry');
    return null;
  }

  handleAddRBOEDI = (field, value) => {
    let copy = cloneDeep(this.state[field]);
    copy[value] = 1;
    if (field === 'rbosChecked') {
      this.setState({
        [field]: copy
      });
    } else {
      // EDI programs checked
      console.log('chose adding blank -->', value);
      let copyVendorCode = cloneDeep(this.state.vendorCodes);
      const newState = {
        [field]: copy,
        vendorCodes: copyVendorCode
      };
      // check corresponding RBO ID
      const arr = value.split('_');
      const edi_code = arr[1];
      const correspondingRBO = this.getCorrespondingRBO(edi_code);
      if (correspondingRBO) {
        // check if RBO is already existing
        const currentRBOs = this.state.rbosChecked;
        let rboFound = false;
        Object.keys(currentRBOs).forEach((crbo) => {
          const rbo_arr = crbo.split('_');
          if (rbo_arr[1] === correspondingRBO) {
            rboFound = true;
          }
        });
        if (!rboFound) {
          // add RBO
          let rbosCopy = cloneDeep(this.state.rbosChecked);
          const new_rbo_code = `0_${correspondingRBO}_0_0`;
          rbosCopy[new_rbo_code] = 1;
          newState.rbosChecked = rbosCopy;
        }
      }

      copyVendorCode[value] = '';
      this.setState(newState);
    }
  }

  openAddRBO = () => {
    this.setState({
      rboediOpen: true,
      dropdownRBOEDI: {
        value: '',
        options: this.props.rbos,
        indx: 'id',
        desc: 'name',
        field: 'rbosChecked',
        title: 'Select an RBO to add',
        existing: this.state.rbosChecked,
        dict: this.props.rbosDict,
        choose: this.handleAddRBOEDI
      }
    });
  }

  openAddEDI = () => {
    this.setState({
      rboediOpen: true,
      dropdownRBOEDI: {
        value: '',
        options: this.props.edi_programs,
        indx: 'ID',
        desc: 'Name',
        field: 'ediProgramsChecked',
        title: 'Select an EDI program to add',
        existing: this.state.ediProgramsChecked,
        dict: this.props.edi_programsDict,
        choose: this.handleAddRBOEDI
      }
    });
  }

  closeRBOEDI = () => {
    this.setState({ rboediOpen: false });
  }

  showDropdown = (dropdownObject) => {
    this.setState({
      dropdownOpen: true,
      dropdownObject
    });
  }

  closeDropdown = () => {
    this.setState({ dropdownOpen: false });
  }

  showImage = (showImageURL) => {
    this.setState({ showImage: true, showImageURL });
  }

  hideImage = () => {
    this.setState({ showImage: false, showImageURL: '' });
  }

  showPDF = (showPDFURL) => {
    this.setState({ showPDF: true, showPDFURL });
  }

  hidePDF = () => {
    this.setState({ showPDF: false, showPDFURL: '' });
  }

  deleteResaleCert = (i) => {
    this.setState((prevState) => {
      return {
        resale_certificate: prevState.resale_certificate.split(',').filter((r,ii) => i !== ii).join(',')
      }
    });
  }

  openAlert = (alert) => {
    this.setState({
      alert
    });
  }

  closeAlert = () => {
    this.setState({
      alert: ''
    });
  }

  handleFileSelect = event => {
      // console.log(event)
      const props = this.props;
      const files = event.target.files
      if (files && files.length > 0) {
        const isVerified = this.verifyFile(files)
        if (isVerified) {
           const currentFile = files[0],
                  newpath = devStatus === 'live' ? 'https://portal2.natcoglobal.com' : 'https://staging.portal2.natcoglobal.com';
           let newFilename = '', timestamp = new Date().getTime(), fileExtension = '';
           if (files[0].name) {
             console.log('filename uploaded is', files[0].name, files[0].name.indexOf('.'));
             if (files[0].name.indexOf('.') !== -1) {
               const fext = files[0].name.split('.')[1];
               if (fext.length < 5) {
                 fileExtension = fext;
               }
             }
           }
           newFilename = `RESALE_CERT_${timestamp}.${fileExtension}`;

           //this.props.sendResaleCertFileSAP(files[0]); // testing only
           //this.props.sendResaleCertFile(files[0], newFilename); // testing only
           this.setState((prevState) => {
              console.log('received file', files[0]);
              return {
                resale_cert: files[0],
                resale_certificate: prevState.resale_certificate ? `${prevState.resale_certificate},${newpath}/api/common/rsc/${newFilename}`
                        : `${newpath}/api/common/rsc/${newFilename}`,
                resale_cert_filename: `${newFilename}`
              };
            });
         }
      }
  }

  verifyFile = (files) => {
      if (files && files.length > 0){
          const currentFile = files[0]
          const currentFileType = currentFile.type
          const currentFileSize = currentFile.size
          if(currentFileSize > imageMaxSize) {
              this.setState({
                alert: "This file is not allowed. " + currentFileSize + " bytes is too large"
              });
              return false
          }
          if (!acceptedFileTypesArray.includes(currentFileType)){
              this.setState({
                alert: "This file is not allowed. Only images and pdf files are allowed."
              });
              return false
          }
          return true
      }
  }

  onDefaultShippingChange = (e, target) => {
    const additionalShippingAddresses = this.state.additionalShippingAddresses;
    const index = target == '-1' ? -1 : target;
    this.setState({
      default_shipping: index
    });
  }

  handleSelectChange = (value, field) => {
    this.setState({
      [field]: value
    });
  }

  handleTextInputChange = (e, field) => {
    this.setState({
      [field]: e.target.value
    });
  }

  handleVendorText = (value, field) => {
    let copy = cloneDeep(this.state.vendorCodes);
    copy[field] = value
    this.setState({
      vendorCodes: copy
    })
  }

  addContact = () => {
    this.setState(previousState => ({
        additionalContacts: [...previousState.additionalContacts, {
          name: '',
          oname: '',
          position: '',
          tel: '',
          alttel: '',
          mobile: '',
          fax: '',
          email: '',
          firstname: '',
          lastname: ''
        }]
    }));

  }
  removeContact = (index) => {
    this.setState({additionalContacts: this.state.additionalContacts.filter(function(cont, i) {
        return i !== parseInt(index);
    })});
  }

  handleTextInputChangeCompanyName = (e, field) => {
    this.setState({
      companyNameModalStatus: true
    });
  }

  handleContactTextInputChange = (e, field) => {
    // console.log('is changed', this.state.additionalContacts);
    const arr = field.split(',');
    const index = arr[0];
    const fieldName = arr[1];
    let contacts = JSON.parse(JSON.stringify(this.state.additionalContacts));
    contacts[index][fieldName] = e.target.value;
    this.setState({
      additionalContacts: contacts
    });
  }

  addAddress = () => {
    this.setState(previousState => ({
        additionalShippingAddresses: [...previousState.additionalShippingAddresses, {
          shipname: '',
          oname: '',
          add1: '',
          add2: '',
          country: '',
          state: '',
          zipcode: '',
          gstregno: '',
          gstregtype: ''
        }]
    }));

  }

  removeAddress = (index) => {
    console.log('removing', index);
    if (index != -1) {
      const shiparr = [ ...this.state.additionalShippingAddresses ];
      const intIndex = parseInt(index);
      const changeDefaultShipping = this.state.default_shipping === intIndex ? true : false;
      const additionalShippingAddresses = shiparr.filter(function(add, i) {
                    return i !== parseInt(index);
                  });
      const newState = { additionalShippingAddresses };
      // default to shipping address 1 if currently removed address is default
      if (changeDefaultShipping) newState.default_shipping = -1;
      this.setState(newState);
    } else {
      console.log('removing shipping address 1');
      const beforeCountry = this.state.shipping_country;
      const shiparr = [ ...this.state.additionalShippingAddresses ];
      if (shiparr.length > 0) {
        const ns = shiparr[0];
        const newShippingData = {
            shipping_address1: ns.add1,
            shipping_address2: ns.add2,
            shipping_addressname: ns.shipname,
            shipping_city: ns.city,
            shipping_country: ns.country,
            shipping_gstregno: ns.gstregno,
            shipping_gstregtype: ns.gstregtype,
            shipping_oname: ns.oname,
            shipping_state: ns.state,
            shipping_zipcode: ns.zipcode
        };
        newShippingData.additionalShippingAddresses = shiparr.filter((s,i) => {
          if (i) return true;
        });
        if (beforeCountry != newShippingData.shipping_country) {
          newShippingData.removedAddress = true;
        }
        console.log('newShippingData', newShippingData, beforeCountry);
        // if default shipping change
        if (this.state.default_shipping === 0) {
          newShippingData.default_shipping = -1;
        }
        this.setState(newShippingData);
      }
    }
  }

  handleAddressTextInputChange = (e, field) => {
    const arr = field.split(',');
    const index = arr[0];
    const fieldName = arr[1];
    let addresses = JSON.parse(JSON.stringify(this.state.additionalShippingAddresses));
    addresses[index][fieldName] = e.target.value;
    this.setState({
      additionalShippingAddresses: addresses
    });
  }

  handleAddressSelectChange = (value, field) => {
    const arr = field.split(',');
    const index = arr[0];
    const fieldName = arr[1];
    let addresses = JSON.parse(JSON.stringify(this.state.additionalShippingAddresses));
    addresses[index][fieldName] = value;
    this.setState({
      additionalShippingAddresses: addresses
    });
  }

  changeCompanyName = (company_name) => {
    this.setState({
      company_name
    });
  }

  toggleCompanyNameModal = () => {
    this.setState({
      companyNameModalStatus: !this.state.companyNameModalStatus
    });
  }

  toggleReviewModal = () => {
    ReactDOM.findDOMNode(this).scrollIntoView();
    this.setState({
      reviewModalStatus: !this.state.reviewModalStatus
    });
  }

  copyToShipping = async () => {
    // console.log('copying...');
    const stateCopy = { ...this.state };
    this.setState({
      shipping_addressname: stateCopy.billing_addressname,
      shipping_oname: stateCopy.billing_oname,
      shipping_address1: stateCopy.billing_address1,
      shipping_address2: stateCopy.billing_address2,
      shipping_country: stateCopy.billing_country,
      shipping_city: stateCopy.billing_city,
      shipping_state: stateCopy.billing_state,
      shipping_zipcode: stateCopy.billing_zipcode,
      shipping_gstregno: stateCopy.billing_gstregno,
      shipping_gstregtype: stateCopy.billing_gstregtype
    });
  }

  handleCheckboxChange = (e, field) => {
    // console.log(e, field);
    this.setState({
      [field]: e.target.checked
    });
  }

  handleWTCheckboxChange = (e, field) => {
    // console.log(e, field);
    const wtcodesCopy = [ ...this.state.wtcodes ];
    const wtcodesIndex = wtcodesCopy.indexOf(field);
    let changedWT;
    if (wtcodesIndex === -1) {
      changedWT = wtcodesCopy.concat([field]);
    } else {
      changedWT = wtcodesCopy.filter((f) => {
        return f !== field;
      });
    }
    this.setState({
      wtcodes: changedWT
    });
  }

  onCalendarChange = (expiration_date) => {
    this.setState({
      expiration_date
    });
  }

  handleRadioChange = (e, value, field) => {
    // console.log('assessee',e);
    this.setState({
      [field]: value
    });
  }

  changeWithholding = (withholding) => {
    this.setState({
      withholding
    });
  }

  concatenateName = (firstname, lastname, target) => {
    let targetCopy = { ...target };
    if (firstname && lastname) {

    }
    return target;
  }

  handleSubmitReview = () => {
    ReactDOM.findDOMNode(this).scrollIntoView();
    let validationsCopy = { ...validations };
    let additionalErrors = '';
    let errors = {};
    let errorArr = [];
    let ignoreFields = [];
    let bp = { ...this.state };
    console.log('beforebp', bp, this.props);
    const { currentDB, currentBPType } = this.props;
    const isNIN = currentDB.indexOf('NIN') !== -1 ? true : false;
    const isNMX = currentDB.indexOf('NMX') !== -1 ? true : false;
    const isNAS = currentDB.indexOf('NAS') !== -1 ? true : false;
    const isNGT = currentDB.indexOf('NGT') !== -1 ? true : false;
    const isNLA = currentDB.indexOf('NLA') !== -1 ? true : false;
    const isTPP = bp.accounts_payable_name ?
                    bp.accounts_payable_name.indexOf('GMP ') !== -1 ?
                        true : false
                    : false;
    console.log('isTPP ?', isTPP);

    bp.DB = currentDB;
    bp.bp_type = currentBPType;
    bp.submission_type = this.props.submissionType;
    bp.credit_limit = bp.credit_limit ? bp.credit_limit.toString().replace(/[^\d.-]/g, '') : '0';

    if ((isNLA && bp.billing_country === 'US') && (bp.billing_state === 'CA' && currentBPType === 'Customer')) {
      if (!bp.resale_certificate && !bp.resale_certificate_path) {
        additionalErrors += 'Resale Certificate uploads are required when the Address is California, U.S.A. '
      }
      if (bp.resale_certificate) {
        if (bp.resale_certificate.split(',').length > 1) {
          additionalErrors += 'Please choose only one Resale Certificate. '
        }
      }
    }

    // RBO and EDI
    if (bp.vendorCodes) {
      let foundEmptyVC = false;
      Object.keys(bp.vendorCodes).forEach(vc => {
        if (!bp.vendorCodes[vc]) {
          foundEmptyVC = true;
        } else {
          const vendorCodeTrimmed = bp.vendorCodes[vc].trim();
          if (!vendorCodeTrimmed) {
            foundEmptyVC = true;
          }
        }
      });
      if (foundEmptyVC) {
        additionalErrors += 'Please make sure all EDI Programs have a Vendor Code. '
      }
    }

    // makes sure AA table has RBO names
    bp.rbosCheckedNames = {};
    if (this.props.rbosDict) {
      if (Object.keys(this.props.rbosDict).length > 0) {
        if (bp.rbosChecked) {
          Object.keys(bp.rbosChecked).forEach(combo => {
            const rbo_id = combo.split('_')[1];
            bp.rbosCheckedNames[rbo_id] = this.props.rbosDict[rbo_id];
          });
        }
      }
    }

    if (this.state.vendorCodes) {
      if (Object.keys(this.state.vendorCodes).length > 0) {
        const vcs = Object.keys(this.state.vendorCodes);
        const vcc = Object.values(this.state.vendorCodes);
        let hasDuplicate = false;
        let collect = {};
        vcs.forEach(combo => {
          const comboArr = combo.split('_');
          const edi = comboArr[1];
          const vc = this.state.vendorCodes[combo];
          const comb = `${edi}_${vc}`;
          if (this.state.ediProgramsChecked[combo] === 1) {
            if (collect.hasOwnProperty(comb)) {
              collect[comb]++;
            } else {
              collect[comb] = 1;
            }
          }
        });
        console.log('collect1', collect);
        Object.keys(collect).forEach((comb) => {
          if (collect[comb] > 1) {
            hasDuplicate = true;
          }
        });
        if (hasDuplicate) {
          console.log('collect2', collect);
          additionalErrors += 'There are duplicate EDI Program - Vendor Codes. '
        }

        // trim all vendor codes
        Object.keys(bp.vendorCodes).forEach((edicombo) => {
          const edival = bp.vendorCodes[edicombo];
          if (edival) {
            bp.vendorCodes[edicombo] = edival.trim();
          }
        })
      }

      // check if all required RBOs are there
      const requiredRBOs = this.getRequiredRBOs();
      if (bp.rbosChecked) {
        let allSubmittedRBOs = [];
        Object.keys(bp.rbosChecked).forEach((rb) => {
          if (bp.rbosChecked[rb] === 1) {
            const rbo_arr = rb.split('_');
            const rbo_id = rbo_arr[1];
            allSubmittedRBOs.push(rbo_id);
          }
        });

        let missingRBOs = '';
        requiredRBOs.forEach((rbo_required) => {
          if (allSubmittedRBOs.indexOf(rbo_required) === -1) {
            if (this.props.rbosDict[rbo_required]) {
              missingRBOs += `${this.props.rbosDict[rbo_required]},`;
            }
          }
        });
        if (missingRBOs) {
          additionalErrors += `There are required RBOs (${missingRBOs})`;
        }

        console.log('missing RBOs ?', requiredRBOs, allSubmittedRBOs);
      }
    }

    if (this.props.vendorCodesResults) {
      console.log('vc check', this.props.vendorCodesResults, bp);
      if (Object.keys(this.props.vendorCodesResults).length > 0) {
        // check all vendor codes
        if (bp.vendorCodes) {
          let foundActiveDuplicate = false;
          Object.keys(bp.vendorCodes).forEach(combo => {

            // const combo = `${edi_id}_${edi.edi_program}_${gmp_id}_${a_id}`;
            const comboArr = combo.split('_'),
                  edi_id = comboArr[0],
                  gmp_id = comboArr[2],
                  a_id = comboArr[3];
            let status = '';
            if ((edi_id && edi_id != 0) && (gmp_id && gmp_id != 0)) {
              status = 'Pending';
            } else if (a_id == 0 && edi_id == 0 || (gmp_id == 0 && a_id == 0)) {
              status = 'New';
            }
            if (status === 'Pending' || status === 'New') {
              if (this.props.vendorCodesResults.hasOwnProperty(bp.vendorCodes[combo])) {
                if (this.props.vendorCodesResults[bp.vendorCodes[combo]]) {
                  if (this.props.vendorCodesResults[bp.vendorCodes[combo]].aa_results != 0) {

                  }
                }

                console.log('checking...', combo, bp.vendorCodes[combo], this.props.vendorCodesResults, this.props.vendorCodesResults.hasOwnProperty(bp.vendorCodes[combo]));
              }
            }
          });
          if (foundActiveDuplicate) {
            additionalErrors += 'There are duplicate Pending Vendor Codes. '
          }
        }
      }
    }

    if (!this.props.isFromPending) {
      if (bp.company_name && (bp.DB && bp.bp_type)) {
        this.props.checkPendingBP(bp.company_name.trim(), bp.bp_type, bp.DB);
      }
    }

    if (!this.props.isFromPending && (this.props.isPending ? this.props.isPending.status !== 'ok' ? true : false : false)) {
      // console.log('err pend', !this.props.isFromPending, (this.props.isPending ? this.props.isPending.status !== 'ok' ? true : false : false));
      additionalErrors += 'BP Name is already in Pending, please enter a different BP Name. ';
    }

    if (bp.default_shipping != -1 && (!bp.additionalShippingAddresses[bp.default_shipping] && currentBPType !== 'Supplier')) {
      console.log('this here shipping error 1');
      additionalErrors += 'Default Shipping address is required, please select one. ';
    } else {
      // console.log('ok lets check');
      if (bp.default_shipping != null) {
        // console.log('transforming default shipping');
        if (bp.default_shipping != -1 && !bp.additionalShippingAddresses[bp.default_shipping]) {
          console.log('this here shipping error 2');
          additionalErrors += 'Default Shipping address is required, please select one. ';
        } else {
          bp.default_shipping = bp.default_shipping == -1 ?
              helpers.reattachAddressColon(bp.shipping_oname, bp.shipping_addressname) :
              helpers.reattachAddressColon(
                bp.additionalShippingAddresses.filter((aad, i) => i === bp.default_shipping).shift().oname,
                bp.additionalShippingAddresses.filter((aad, i) => i === bp.default_shipping).shift().shipname
              );
        }
      } else {
        // if there's a shipping address set it to default
        if (bp.shipping_addressname && currentBPType === 'Supplier') {
          bp.default_shipping = bp.shipping_addressname;
        }
      }
      console.log('this is the default shipping ? ', bp.default_shipping);
    }

    if (!isNaN(bp.default_shipping) && currentBPType === 'Supplier') {
      if (bp.default_shipping == -1 && !bp.shipping_addressname) {
        bp.default_shipping = '';
      } else if (!bp.additionalShippingAddresses[bp.default_shipping]) {
        bp.default_shipping = '';
      }
    }

    // require account name if user has Enter-payterms privileges
    if (this.props.enterPayTerms) {
      if (bp.bp_type === 'Supplier') {
        if (this.props.groupcodesDictionary) {
          if (bp.bp_subgroup) {
            if (this.props.groupcodesDictionary[bp.bp_subgroup]) {
              if (this.props.groupcodesDictionary[bp.bp_subgroup] === 'Service') {
                if (!bp.acctname) {
                  additionalErrors += `Account Name is required. `;
                }
              }
            }
          } else {
            additionalErrors += `BP Subgroup is required. `;
          }
        }
      }
    }

    // check for shipping states if GSTIN filled up
    if (isNIN && bp.tinno) {
      // collect all billing and shipping states
      let pass = true;
      if (!bp.shipping_state) {
        pass = false;
      }
      if (bp.additionalShippingAddresses) {
        if (bp.additionalShippingAddresses.length > 0) {
          bp.additionalShippingAddresses.forEach((asa) => {
            if (!asa.state) {
              pass = false;
            }
          });
        }
      }

      if (!pass) {
        additionalErrors += `If GSTIN is filled up, all addresses must have a State (Stateless Countries are not allowed). `;
      }
    }

    // CONTACTS
    // console.log('this should have been concatenated BEFORE', bp);
    // populate names based on firstname and lastname if oname is different
    let gmp_emails = [];
    if (bp.accounts_payable_firstname && bp.accounts_payable_lastname) {
      let addedPart = '';
      let theName = bp.accounts_payable_name;
      if (bp.accounts_payable_oname) {
        theName = bp.accounts_payable_oname;
        if (theName.indexOf('GMP ') !== -1) {
          const partOf1 = theName.split(' -')[0];
          addedPart = `${partOf1} -`;
        }
      } else {
        if (theName.indexOf('GMP ') !== -1) {
          const partOf1 = theName.split(' -')[0];
          addedPart = `${partOf1} -`;
        }
      }
      const concatenated1 = `${addedPart}${bp.accounts_payable_firstname} ${bp.accounts_payable_lastname}`;
      if (concatenated1 !== bp.accounts_payable_oname) {
        bp.accounts_payable_name = concatenated1;
      }
    }

    if (bp.purchasing_firstname && bp.purchasing_lastname) {
      let addedPart2 = '';
      let theName1 = bp.purchasing_name;
      if (bp.purchasing_oname) {
        theName1 = bp.purchasing_oname;
        if (theName1.indexOf('GMP ') !== -1) {
          const partOf2 = theName1.split(' -')[0];
          addedPart2 = `${partOf2} -`;
        }
      } else {
        if (theName1.indexOf('GMP ') !== -1) {
          const partOf2 = theName1.split(' -')[0];
          addedPart2 = `${partOf2} -`;
        }
      }
      const concatenated2 = `${addedPart2}${bp.purchasing_firstname} ${bp.purchasing_lastname}`;
      // console.log('parts of', theName1,addedPart2, bp.purchasing_firstname, bp.purchasing_lastname, bp.purchasing_name);
      if (concatenated2 !== bp.purchasing_oname) {
        bp.purchasing_name = concatenated2;
      }
    }

    if (bp.additionalContacts) {
      if (bp.additionalContacts.length > 0) {
        bp.additionalContacts.forEach((ac, i, bpp) => {
          if (ac.firstname && ac.lastname) {
            let addedPart = '';
            let theNameC = ac.name;
            if (ac.oname) {
              theNameC = ac.oname;
              if (theNameC.indexOf('GMP ') !== -1) {
                const partOf = theNameC.split(' -')[0];
                addedPart = `${partOf} -`;
              }
            } else {
              if (theNameC.indexOf('GMP ') !== -1) {
                const partOf = theNameC.split(' -')[0];
                addedPart = `${partOf} -`;
              }
            }
            let concatenat = `${addedPart}${ac.firstname} ${ac.lastname}`;
            if (concatenat !== ac.oname) {
              bpp[i].name = concatenat;
            }
          }
        });
      }
    }

    if (!isNIN) {
      ignoreFields = ignoreFields.concat([
        'panno',
        'tinno',
        'withholding',
        'ni_num',
        'certificate_num',
        'expiration_date',
        'assessee_type',
        'accrual_criteria',
        'threshold_overlook',
        'surcharge_overlook',
        'wtcodes',
        'shipping_gstregno',
        'shipping_gstregtype',
        'billing_gstregno',
        'billing_gstregtype'
      ]);
    }

    if (!isNGT || bp.billing_country !== 'GT' || currentBPType !== 'Customer') {
      ignoreFields = ignoreFields.concat(['nit']);
    }

    if (!isNMX || bp.billing_country !== 'MX' || currentBPType !== 'Customer') {
      ignoreFields = ignoreFields.concat(['rfc_curp']);
    }

    // check for states existing in countries
    const statesDictionary = this.props.statesDictionary;
    if (bp.billing_country) {
      if (!statesDictionary[bp.billing_country]) {
        ignoreFields = ignoreFields.concat(['billing_state']);
      }
    }
    if (bp.shipping_country) {
      if (!statesDictionary[bp.shipping_country]) {
        ignoreFields = ignoreFields.concat(['shipping_state']);
      }
    }

    //// console.log('ignoring these fields', ignoreFields);
    const ship_val_arr = ['shipping_addressname','shipping_address1','shipping_address2','shipping_country','shipping_state',
                      'shipping_city','shipping_gstregno','shipping_gstregtype','shipping_zipcode'];
    const ship_val_dict = {
      shipping_addressname: 'shipping2_addressname',
      shipping_address1: 'shipping2_address1',
      shipping_address2: 'shipping2_address2',
      shipping_country: 'shipping2_country',
      shipping_state: 'shipping2_state',
      shipping_city: 'shipping2_city',
      shipping_gstregno: 'shipping2_gstregno',
      shipping_gstregtype: 'shipping2_gstregtype',
      shipping_zipcode: 'shipping2_zipcode'
    };
    Object.keys(validationsCopy).forEach((field) => {
      if (ignoreFields.indexOf(field) === -1) {
        const valueToCheck = bp[field];
        if (currentBPType === 'Supplier' && (ship_val_arr.indexOf(field) !== -1)) {
          field = ship_val_dict[field];
        }
        validationsCopy[field].forEach((validate) => {
          const theError = validate(valueToCheck);
          if (theError) {
            errors[field] = theError;
            errorArr.push({field, error: theError});
          }
        });
      }
    });

    const mainErrors = errorArr.map((errd,i) => {
      return <div key={`errd-${i}`} style={errorStyle}>{errd.field.toUpperCase().replace(/_/g, " ")} - {errd.error}</div>
    });

    if (bp.additionalContacts.length > 0) {
      // check each contacts
      bp.additionalContacts.forEach((cont,i) => {
        validations.purchasing_name.forEach((validate) => {
          const theError = validate(cont.name);
          if (theError) {
            errors[`contact_${i}_name`] = theError;
            additionalErrors += `CONTACT #${i+3} name ${theError}. `;
          }
        });

        validations.purchasing_position.forEach((validate) => {
          const theError = validate(cont.position);
          if (theError) {
            errors[`contact_${i}_position`] = theError;
            additionalErrors += `CONTACT #${i+3} position ${theError}. `;
          }
        });

        validations.purchasing_tel.forEach((validate) => {
          const theError = validate(cont.tel);
          if (theError) {
            errors[`contact_${i}_tel`] = theError;
            additionalErrors += `CONTACT #${i+3} telephone ${theError}. `;
          }
        });

        validations.purchasing_alttel.forEach((validate) => {
          const theError = validate(cont.alttel);
          if (theError) {
            errors[`contact_${i}_alttel`] = theError;
            additionalErrors += `CONTACT #${i+3} alt telephone ${theError}. `;
          }
        });

        validations.purchasing_mobile.forEach((validate) => {
          const theError = validate(cont.mobile);
          if (theError) {
            errors[`contact_${i}_mobile`] = theError;
            additionalErrors += `CONTACT #${i+3} mobile ${theError}. `;
          }
        });

        validations.purchasing_fax.forEach((validate) => {
          const theError = validate(cont.fax);
          if (theError) {
            errors[`contact_${i}_fax`] = theError;
            additionalErrors += `CONTACT #${i+3} fax ${theError}. `;
          }
        });

        validations.purchasing_email.forEach((validate) => {
          const theError = validate(cont.email);
          if (theError) {
            errors[`contact_${i}_email`] = theError;
            additionalErrors += `CONTACT #${i+3} email ${theError}. `;
          }
        });
      });
    }

    if (bp.additionalShippingAddresses.length > 0) {
      // check each shipping address
      bp.additionalShippingAddresses.forEach((shipadd, i) => {
        let excludeRequired = false;
        if (currentBPType === 'Supplier') {
          excludeRequired = true;
        }
        if ((shipadd.shipname || shipadd.add1 || shipadd.add2 || shipadd.country || shipadd.state || shipadd.city || shipadd.zipcode || shipadd.gstregno || shipadd.gstregtype) && currentBPType === 'Supplier') {
          excludeRequired = false;
        }
        validations.shipping_addressname.forEach((validate,i) => {
          //// console.log('is it excluded ? ', validate.toString().substr(0, 100) );
          if (excludeRequired && i === 0) return;
          const theError = validate(shipadd.shipname);
          if (theError) {
            errors[`address_${i}_shipname`] = theError;
            additionalErrors += `SHIPPING ADDRESS #${i+2} Name - ${theError}. `;
          }
        });

        validations.shipping_address1.forEach((validate) => {
          if (excludeRequired && i === 0) return;
          const theError = validate(shipadd.add1);
          if (theError) {
            errors[`address_${i}_add1`] = theError;
            additionalErrors += `SHIPPING ADDRESS #${i+2} Address 1 - ${theError}. `;
          }
        });

        validations.shipping_address2.forEach((validate) => {
          const theError = validate(shipadd.add2);
          if (theError) {
            errors[`address_${i}_add2`] = theError;
            additionalErrors += `SHIPPING ADDRESS #${i+2} Address 2 (${theError}). `;
          }
        });

        validations.shipping_country.forEach((validate) => {
          if (excludeRequired && i === 0) return;
          const theError = validate(shipadd.country);
          if (theError) {
            errors[`address_${i}_country`] = theError;
            additionalErrors += `SHIPPING ADDRESS #${i+2} Country (${theError}). `;
          }
        });

        if (this.props.states) {
          if (this.props.states.hasOwnProperty(shipadd.country)) {
            if (excludeRequired && i === 0) return;
            if (statesDictionary[shipadd.country]) {
              validations.shipping_state.forEach((validate) => {
                const theError = validate(shipadd.state);
                if (theError) {
                  errors[`address_${i}_state`] = theError;
                  additionalErrors += `SHIPPING ADDRESS #${i+3} State (${theError}). `;
                }
              });
            }
          }
        }

        validations.shipping_zipcode.forEach((validate) => {
          if (excludeRequired && i === 0) return;
          const theError = validate(shipadd.zipcode);
          if (theError) {
            errors[`address_${i}_zipcode`] = theError;
            additionalErrors += `SHIPPING ADDRESS #${i+2} Zipcode (${theError}). `;
          }
        });

        if (isNIN) {
          if (bp.tinno) {
            if (!shipadd.state) {
              errors[`address_${i}_gstin`] = `GSTIN is filled up, SHIPPING ADDRESS #${i+2} needs to have a State. `;
              additionalErrors += `GSTIN is filled up, SHIPPING ADDRESS #${i+2} needs to have a State. `;
            }
          }

          validations.shipping_gstregno.forEach((validate) => {
            if (excludeRequired && i === 0) return;
            const theError = validate(shipadd.zipcode);
            if (theError) {
              errors[`address_${i}_zipcode`] = theError;
              additionalErrors += `SHIPPING ADDRESS #${i+2} Zipcode - ${theError}. `;
            }
          });

          if (!shipadd.gstregtype && !excludeRequired) {
            errors[`address_${i}_gstregno`] = `GST Reg.Type is required for SHIPPING ADDRESS #${i+2}. `;
            additionalErrors += `GST Reg.Type is required for address #${i+3}. `;
          }
        }
      });
    }

    // check if the states are entered
    const billStateCheck = this.checkStates(bp.billing_country, bp.billing_state),
          shipStateCheck = this.checkStates(bp.shipping_country, bp.shipping_state);

    if (!billStateCheck) {
      additionalErrors += 'Billing State is required, please select a state from the Dropdown. ';
    }

    if (!shipStateCheck) {
      additionalErrors += 'Default Shipping State is required, please select a state from the Dropdown. ';
    }

    if (bp.additionalShippingAddresses) {
      if (bp.additionalShippingAddresses.length > 0) {
        bp.additionalShippingAddresses.forEach((as, i) => {
          if (!this.checkStates(as.country, as.state)) {
            additionalErrors += `Shipping ${i+2} State is required, please select a state from the Dropdown. `;
          }
        });
      }
    }

    // reattach any colons on addresses if found
    if (bp.shipping_addressname) {
      bp.shipping_addressname = helpers.reattachAddressColon(bp.shipping_oname, bp.shipping_addressname);
    }
    if (bp.billing_addressname) {
      bp.billing_addressname = helpers.reattachAddressColon(bp.billing_oname, bp.billing_addressname);
    }
    if (bp.additionalShippingAddresses) {
      if (bp.additionalShippingAddresses.length > 0) {
        bp.additionalShippingAddresses = helpers.reattachAddressesColon(bp.additionalShippingAddresses);
      }
    }

    // check if all address names are different
    let allShipAddresses = [bp.shipping_addressname];
    if (bp.additionalShippingAddresses.length > 0) {
      bp.additionalShippingAddresses.forEach((asa) => {
                              allShipAddresses.push(asa.shipname);
                            });
    }
    const checkAllShipAddressNames = checkForDuplicates(allShipAddresses);
    //// console.log('mixedAddresses',allShipAddresses, checkAllShipAddressNames);
    if (checkAllShipAddressNames) {
      if (checkAllShipAddressNames.length > 0) {
        const allDupeAddresses = checkAllShipAddressNames.join(',');
        additionalErrors += `Please make sure there are no duplicate Shipping Address Names (${allDupeAddresses}). `;
      }
    }

    // check if all contact names are different
    let allContacts = [bp.accounts_payable_name, bp.purchasing_name];
    if (bp.additionalContacts.length > 0 ) {
      bp.additionalContacts.forEach((aca) => {
        allContacts.push(aca.name);
      });
    }
    const checkAllContactNames = checkForDuplicates(allContacts);
    //// console.log('mixed contacts',allContacts, checkAllContactNames);
    if (checkAllContactNames) {
      if (checkAllContactNames.length > 0) {
        const allDupeContacts = checkAllContactNames.join(',');
        additionalErrors += `Please make sure there are no duplicate Contact Names (${allDupeContacts}). `;
      }
    }


    // check if all contacts have tel and position
    if (bp.accounts_payable_name) {
      let ap_errors = '';
      if (!bp.accounts_payable_tel) ap_errors += ' Telephone ';
      if (!bp.accounts_payable_email) ap_errors += ' Email ';
      if (!bp.accounts_payable_position) ap_errors += ' Position ';
      if (!bp.accounts_payable_firstname) ap_errors += ' Firstname ';
      if (!bp.accounts_payable_lastname) ap_errors += ' Lastname ';
      if (ap_errors) additionalErrors += `Please enter Accounts Payable Contact required fields (${ap_errors}). `;
    }

    if (bp.purchasing_name) {
      let purch_errors = '';
      if (!bp.purchasing_tel) purch_errors += ' Telephone ';
      if (!bp.purchasing_email) purch_errors += ' Email ';
      if (!bp.purchasing_position) purch_errors += ' Position ';
      if (!bp.purchasing_firstname) purch_errors += ' Firstname ';
      if (!bp.purchasing_lastname) purch_errors += ' Lastname ';
      if (purch_errors) additionalErrors += `Please check Purchasing Contact for required fields (${purch_errors}). `;
    }

    if (bp.additionalContacts) {
      if (bp.additionalContacts.length > 0) {
        let ac_errors = '';
        bp.additionalContacts.forEach((ac) => {
          if (ac.name) {
            let acc_errors = '';
            if (!ac.tel) acc_errors += ' Telephone ';
            if (!ac.email) acc_errors += ' Email ';
            if (!ac.position) acc_errors += ' Position ';
            if (!ac.firstname) acc_errors += ' Firstname ';
            if (!ac.lastname) acc_errors += ' Lastname ';
            if (acc_errors) ac_errors += `Please check ${ac.name} Contact for required fields (${acc_errors}). `;
          }
        });
        if (ac_errors) additionalErrors += ` ${ac_errors} `;
      }
    }

    if (!bp.payterms) {
      errors.payterms = 'Required';
      additionalErrors += `Payterms are required. `;
    }

    if (!bp.credit_limit && bp.credit_limit != '0') {
      errors.credit_limit = 'Required';
      additionalErrors += `Credit Limit is required. `;
    }

    //// console.log('bp wtcodes are -', bp.wtcodes);
    if (bp.wtcodes) {
      bp.wtcodes = bp.wtcodes.join(',');
    } else {
      bp.wtcodes = ''
    }

    // make sure there's no trailing spaces
    Object.keys(bp).forEach((bpfield) => {
      if (bp[bpfield] === undefined || bp[bpfield] === null) {
        bp[bpfield] = '';
      }
      if (typeof bp[bpfield] === 'string') {
        bp[bpfield] = bp[bpfield].trim();
      }
    });

    //denormalizePhone
    bp.gen_tel = denormalizePhone(bp.gen_tel);
    bp.gen_fax = denormalizePhone(bp.gen_fax);
    bp.accounts_payable_tel = denormalizePhone(bp.accounts_payable_tel);
    bp.accounts_payable_fax = denormalizePhone(bp.accounts_payable_fax);
    bp.accounts_payable_alttel = denormalizePhone(bp.accounts_payable_alttel);
    bp.accounts_payable_mobile = denormalizePhone(bp.accounts_payable_mobile);
    bp.purchasing_tel = denormalizePhone(bp.purchasing_tel);
    bp.purchasing_fax = denormalizePhone(bp.purchasing_fax);
    bp.purchasing_alttel = denormalizePhone(bp.purchasing_alttel);
    bp.purchasing_mobile = denormalizePhone(bp.purchasing_mobile);
    bp.additionalContacts.forEach((bac,i,bpp) => {
      bpp[i].tel = denormalizePhone(bac.tel);
      bpp[i].alttel = denormalizePhone(bac.alttel);
      bpp[i].mobile = denormalizePhone(bac.mobile);
      bpp[i].fax = denormalizePhone(bac.fax);
    });

    delete bp.askModal;
    delete bp.askRequest;
    delete bp.additionalErrors;
    delete bp.companyNameModalStatus;
    delete bp.finalValues;
    delete bp.highlightContact;
    delete bp.mainErrors;
    delete bp.reviewModalStatus;
    delete bp.showErrorModal;
    delete bp.wtcodesPresent;
    delete bp.finalCleanedValues;
    delete bp.dropdownObject;
    delete bp.dropdownRBOEDI;
    delete bp.dropdownOpen;
    const obj = cleanBPValues(this.props.data, bp);
    // console.log('comparison changes',obj);
    bp.changes = JSON.stringify(obj.changes);
    bp.before = JSON.stringify(obj.before);

    // check for deleted TPP addresses
    const beforeData = JSON.parse(bp.before);
    console.log('beforeData', beforeData);
    let beforeMagentoIDs = [];
    if (beforeData.shipping_addressname) {
      if (beforeData.shipping_addressname.indexOf(':') !== -1) {
        const moi = beforeData.shipping_addressname.split(':')[1];
        beforeMagentoIDs.push(moi);
      }
    }
    if (beforeData.additionalShippingAddresses) {
      if (beforeData.additionalShippingAddresses.length > 0) {
        beforeData.additionalShippingAddresses.forEach((asa) => {
          const shipname = asa.shipname;
          if (shipname.indexOf(':') !== -1) {
            const moi = shipname.split(':')[1];
            beforeMagentoIDs.push(moi);
          }
        });
      }
    }

    let beforeAddressnames = [];
    if (beforeData.shipping_oname) {
      beforeAddressnames.push(beforeData.shipping_oname);
    }
    if (beforeData.additionalShippingAddresses) {
      beforeData.additionalShippingAddresses.forEach((bas) => {
        beforeAddressnames.push(bas.oname);
      });
    }

    let afterAddressnames = [];
    if (bp.shipping_oname) {
      afterAddressnames.push(bp.shipping_oname);
    }
    if (bp.additionalShippingAddresses) {
      if (bp.additionalShippingAddresses.length > 0) {
        bp.additionalShippingAddresses.forEach((asa) => {
          afterAddressnames.push(asa.oname);
        });
      }
    }
    const deletedAddressnames = beforeAddressnames.filter((b) => {
      if (afterAddressnames.indexOf(b) === -1) {
        if (b.indexOf(':') === -1) {
          return true;
        }
      }
      return false;
    }).map((m) => {
      return {
        cardcode: bp.card_code,
        addressname: m,
        DB: bp.DB
      }
    });
    if (isTPP) {
      // only for colonless addresses
      bp.allDeletedMagentoAddressNames = deletedAddressnames;
    }

    console.log('deletedAddressnames 1', beforeAddressnames, afterAddressnames);
    console.log('deletedAddressnames 2', deletedAddressnames);


    let afterMagentoIDs = [];
    if (bp.shipping_addressname) {
      if (bp.shipping_addressname.indexOf(':') !== -1) {
        const moi = bp.shipping_addressname.split(':')[1];
        afterMagentoIDs.push(moi);
      }
    }
    if (bp.additionalShippingAddresses) {
      if (bp.additionalShippingAddresses.length > 0) {
        bp.additionalShippingAddresses.forEach((asa) => {
          const shipname = asa.shipname;
          if (shipname.indexOf(':') !== -1) {
            const moi = shipname.split(':')[1];
            afterMagentoIDs.push(moi);
          }
        });
      }
    }

    bp.allDeletedMagentoAddressIDs = beforeMagentoIDs.filter((bid) => {
      if (afterMagentoIDs.indexOf(bid) === -1) return true;
    });
    console.log('all address names', beforeMagentoIDs, afterMagentoIDs);
    console.log('all address names deleted', bp.allDeletedMagentoAddressIDs);

    //// console.log('final errors and bp', errors, bp);
    if (Object.keys(errors).length > 0 || additionalErrors) {
      this.setState({
        mainErrors,
        additionalErrors: additionalErrors.split('. ').map((ae, i) => {
          return <div
            key={`ae-${i}`}
            style={errorStyle}
          >{ae}</div>
        }),
        showErrorModal: true
      });
    } else {
      console.log('FINALIZED BP', bp, obj);
      this.setState({
        finalCleanedValues: bp,
        isTPP
      });
      this.toggleReviewModal();
    }
  }

  checkStates = (country, state) => {
    if (this.props.statesDictionary) {
      const states = this.props.statesDictionary;
      if (states.hasOwnProperty(country)) {
        if (states[country][state]) {
          return true;
        }
      } else {
        return true;
      }
    }
    return false;
  }

  closeErrorModal = () => {
    this.setState({
      showErrorModal: false,
      mainErrors: '',
      additionalErrors: ''
    });
  }

  onApprove = () => {
    console.log('thisTPP', this.state.isTPP);
    this.props.handleApprove(this.state.finalCleanedValues, this.state.resale_cert, this.state.resale_cert_filename, this.state.isTPP);
  }

  onDeny = () => {
    // console.log('deny', this.state, this.props.data);
    this.props.handleDeny(this.state, this.props.data);
  }

  openAskModal = (type) => {
    if (type === 'copyShipping') {
      this.setState({
        askModal: true,
        askRequest: 'copyShipping'
      });
    }

    if (type.indexOf('removeAddress') !== -1) {
      this.setState({
        askModal: true,
        askRequest: type
      });
    }

    if (type.indexOf('removeContact') !== -1) {
      this.setState({
        askModal: true,
        askRequest: type
      });
    }

    if (type.indexOf('denyNewGMP') !== -1) {
      this.setState({
        askModal: true,
        askRequest: type
      })
    }
  }

  closeAskModal = () => {
    this.setState({
      askModal: false,
      askRequest: ''
    });
  }

  checkIfGMP = (value) => {
    if (!value) {
      return '';
    }
    const gmps = ['GMP OTHER -', 'GMP PURCHASING -', 'GMP AP -'];
    const index = gmps.indexOf(value);
    if (index !== -1) {
      return value.split(gmps[index])[1];
    } else {
      return value;
    }
  }

  checkSimilars = () => {
    const fieldsToCheck = ['company_name','gen_tel','billing_address1','accounts_payable_name'];
    const bpObj = this.state;
    let contacts = [];
    let complete = true;
    fieldsToCheck.forEach((f) => {
      if (!bpObj[f]) {
        complete = false;
      }
    });

    if (complete) {
      const accountsPayableName = this.checkIfGMP(bpObj.accounts_payable_name);
      const purchasingName = this.checkIfGMP(bpObj.purchasing_name);
      if (accountsPayableName) {
        contacts.push(accountsPayableName);
      }
      if (purchasingName) {
        contacts.push(purchasingName);
      }
      if (bpObj.additionalContacts) {
        if (bpObj.additionalContacts.length > 0) {
          bpObj.additionalContacts.forEach((ac) => {
            const acname = this.checkIfGMP(ac.name);
            if (acname) {
              contacts.push(acname);
            }
          });
        }
      }

      // console.log('getting similars', bpObj.company_name, bpObj.gen_tel, bpObj.billing_address1, accountsPayableName, purchasingName);
      if (this.props.currentDB) {
        this.props.getSimilars({
          name: bpObj.company_name.substring(0, 5),
          tel: bpObj.gen_tel, //.substring(0, 5),
          address: bpObj.billing_address1.substring(0, 5),
          contacts,
          db: this.props.currentDB
        })
      }
    }
  }

  renderSimilars = (typeName, contactName) => {
    const similars = this.props.similars;
    const similarsStyle = {
      padding:'0px 6px',
      borderRadius:'10px',
      backgroundColor:'#ffa500',
      color:'white',
      display:'inline-block',
      fontWeight:'bold',
      color:'white',
      marginTop:'10px',
      height: '20px',
      bottom: 0,
      left: 0,
      position: 'absolute'
    }

    let alertMsg = [];
    const dict = {
      'company_name': 0,
      'contacts': 1,
      'billing_address': 2,
      'gen_tel': 3
    }
    const type = dict[typeName];
    let field = 'Street',
        headers = ['BP Name','Street'];
    if (type === 0) {
      field = 'CardName';
      headers = ['Card Code','BP Name'];
    } else if (type === 3) {
      field = 'Phone1';
      headers = ['BP Name','General Phone'];
    } else if (type === 1) {
      field = 'Name';
      headers = ['BP Name','Contact Name'];
    }

    if (similars && !contactName) {
      let counted = 0;
      similars[type].forEach((s) => {
        let fieldContent = s[field];
        if (type === 1) {
          const fieldIndex = fieldContent.indexOf(' -');
          if (fieldIndex !== -1) {
            fieldContent = fieldContent.split(' -')[1];
          }
        }

        if (this.state.card_code) {
          if (this.state.card_code === s.CardCode) {
            return;
          }
        }
        counted++;
        if (field !== 'CardName') {
          alertMsg.push(s.CardName+','+fieldContent+','+s.CardCode);
        } else {
          alertMsg.push(s.CardCode+','+fieldContent+','+s.CardCode);
        }
      });

      return counted > 0 ?
        <div style={{position:'relative'}}>
            <div
              style={similarsStyle}
              className="similars"
              onClick={() => {
                this.showSimilarsAlert(alertMsg, headers);
              }}
            >
              {counted}
            </div></div> : '';
    } else if (similars && contactName) {
      const contname = this.checkIfGMP(contactName);
      // console.log('conts->',contname);
      if (similars[type]) {
        if (similars[type].hasOwnProperty(contname)) {
          if (similars[type][contname].length > 0) {
            let counted = 0;
            similars[type][contname].forEach((s) => {
              let fieldContent = s[field];
              if (type === 1) {
                const fieldIndex = fieldContent.indexOf(' -');
                if (fieldIndex !== -1) {
                  fieldContent = fieldContent.split(' -')[1];
                }
              }

              if (this.state.card_code) {
                if (this.state.card_code === s.CardCode) {
                  return;
                }
              }
              counted++;
              if (field !== 'CardName') {
                alertMsg.push(s.CardName+','+fieldContent+','+s.CardCode);
              } else {
                alertMsg.push(s.CardCode+','+fieldContent+','+s.CardCode);
              }
            });

            // console.log('alerts',alertMsg, similars[type][contname].length);
            return counted > 0 ?
              <div style={{position:'relative'}}>
                  <div
                    style={similarsStyle}
                    className="similars"
                    onClick={() => {
                      this.showSimilarsAlert(alertMsg, headers);
                    }}
                  >
                    {counted}
                  </div></div> : '';
          }
        }
      }
    } else {
      return null;
    }
  }

  showSimilarsAlert = (content, headers) => {
    if (content) {
      const cont = content.map((c, i) => {
        // // console.log(c);
        const cardcode = c.split(',')[2];
        return (<div
          style={{paddingLeft:'20px',paddingRight:'20px'}}
          key={'oth'+i}
          className="hover-bold"
          onClick={() => {
            // console.log('open',cardcode, this.props.currentDB);
          }}
        ><GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <div style={{textAlign:'left'}}>{c.split(',')[0]}</div>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <div style={{textAlign:'right'}}>{c.split(',')[1]}</div>
          </GridItem>
        </GridContainer></div>);
      });
      const fullContent = (
        <div style={{paddingLeft:'20px',paddingRight:'20px'}}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <div style={{textAlign:'left',marginLeft:'20px'}}><strong>{headers[0]}</strong></div>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <div style={{textAlign:'right',marginRight:'20px'}}><strong>{headers[1]}</strong></div>
            </GridItem>
          </GridContainer>
          <div style={{
              height:'120px',
              overflowY:'scroll',
              overflowX:'hidden',
              backgroundColor:'#fbfbfb',
              marginTop:'5px',
              paddingTop:'5px',
              paddingBottom:'5px'
            }}>
          {cont}
          </div>
        </div>
      );

      this.setState({
        similarsAlert: true,
        // similarsAlertContent: fullContent
        similarsAlertContent: { content, headers }
      })
    }
  }

  hideSimilarsAlert = () => {
    this.setState({
      similarsAlert: false,
      similarsAlertContent: null
    });
  }

  openVendorCodeEdit = (value, field) => {
    this.setState({
      vendorCodeEditData: {
        field,
        value,
        beforeValue: this.state.before_vendorCodes ? this.state.before_vendorCodes[field] : ''  },
      showVendorCodeEdit: true,
    });
  }

  closeVendorCodeEdit = () => {
    this.setState({
      vendorCodeEditData: { field: '', value: '' },
      showVendorCodeEdit: false,
    });
  }

  deleteRBO = (combo) => {
    let copy = cloneDeep(this.state.rbosChecked);
    delete copy[combo];
    this.setState({
      rbosChecked: copy
    });
  }

  deleteEDI = (combo) => {
    let ediProgramsChecked = cloneDeep(this.state.ediProgramsChecked);
    let vendorCodes = cloneDeep(this.state.vendorCodes);
    delete ediProgramsChecked[combo];
    delete vendorCodes[combo];
    this.setState({
      ediProgramsChecked, vendorCodes
    });
  }

  checkForGMP = (bp) => {
    if (!bp) {
      bp = this.state;
    }
    let isGMP = false;
    const fields = ['accounts_payable_name','purchasing_name'];
    fields.forEach(f => {
      if (bp[f]) {
        if (bp[f].indexOf('GMP ') !== -1) {
          isGMP = true;
        }
      }
    });
    bp.additionalContacts.forEach((f,i) => {
      if (bp.additionalContacts[i].name) {
        if (bp.additionalContacts[i].name.indexOf('GMP ') !== -1) {
          isGMP = true;
        }
      }
    });
    return isGMP;
  }

  checkAllGMPVendorCodes = async () => {
    if (this.state.vendorCodes) {
      if (Object.keys(this.state.vendorCodes)) {
        if (Object.keys(this.state.vendorCodes).length > 0) {
          const codes = Object.values(this.state.vendorCodes);
          const edis = Object.keys(this.state.vendorCodes).map(combo => {
            const comboArr = combo.split('_');
            return comboArr[1];
          });
          this.props.checkVendorCodes(codes, edis);
        }
      }
    }
  }

  recalculateRequiredRBOs = () => {
    const props = this.props;
    const rbosChecked = this.state.rbosChecked;
    const rbosCheckedCopy = cloneDeep(rbosChecked);
    // add required RBOs
    const requiredRBOs = this.getRequiredRBOs();
    let currentRBOs = [];
    console.log('comparing RBOs 2', rbosChecked);
    Object.keys(rbosChecked).forEach((rbo_str) => {
      const rbo_arr = rbo_str.split('_');
      const rbo_id = rbo_arr[1];
      if (rbosChecked[rbo_str] === 1) {
        currentRBOs.push(rbo_id);
      }
    });
    requiredRBOs.forEach((rrbo) => {
      if (currentRBOs.indexOf(rrbo) === -1) {
        rbosCheckedCopy[`0_${rrbo}_0_0`] = 1;
      }
    });
    console.log('comparing RBOs', requiredRBOs, currentRBOs);
    return rbosCheckedCopy;
  }

  componentDidMount() {
    this.props.fetchGMPRBO('NLA_TEST');
    this.props.fetchEDIPrograms();
    this.props.resetCheckPendingBP();
    ReactDOM.findDOMNode(this).scrollIntoView();
    this.checkSimilars();
    this.checkAllGMPVendorCodes();
    let allowedDBsDict = [];
    let allowedDBsObj = {};
    this.props.allowedDBs.forEach((db) => {
      let name = '';
      this.props.dbs.forEach((dbrow) => {
        if (dbrow.db === db) {
          name = dbrow.company
        }
      });
      allowedDBsDict.push({
        id: db,
        name
      })
      allowedDBsObj[db] = name;
    });
    this.setState({
      allowedDBsDict,
      allowedDBsObj
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if ((prevProps.existingBPfoundMsg !== this.props.existingBPfoundMsg) && this.props.existingBPfoundMsg) {
      // open error modal on BP found on Pending
      this.setState({
        showErrorModal: true,
        mainErrors: <h3>{this.props.existingBPfoundMsg}</h3>
      });
    }

    if (prevProps.defaultPBP !== this.props.defaultPBP) {
      // if form was not seeded with a pay term
      if (!this.props.data.payterms && this.props.currentDB) {
        console.log('setting up defaults', this.props.defaultPBP, this.props.defaultCreditLimit);
        this.setState({
          payterms: this.props.defaultPBP,
          credit_limit: this.props.defaultCreditLimit
        })
      }
    }

    if (prevProps.defaultSubgroup !== this.props.defaultSubgroup) {
      console.log('defaultSubgroup has changed', this.props.defaultSubgroup)

      let subgroup = this.props.defaultSubgroup;
      const props = this.props;
      console.log('subgroup override', props.submissionType, props.submitterName, props.BPaction);
      if (props.BPaction) {
        if (props.BPaction === 'PENDING') {
          if (props.submitterName) {
            if (props.submitterName === 'GMP') {
              if (props.submissionType) {
                if (props.submissionType === 'new') {
                  if (props.currentDB === 'NMX_LIVE' || props.currentDB === 'NMX_TEST') {
                    subgroup = '100'; // Taxable Customers override
                  }
                }
              }
            }
          }
        }
      }
      // if form was not seeded with a pay term
      if (!this.props.data.bp_subgroup && this.props.data.gmp_id) {
        this.setState({
          bp_subgroup: subgroup
        })
      }
    }

    if (prevState.billing_country != this.state.billing_country) {
      if (prevState.billing_state) {
        this.setState({
          billing_state: ''
        });
      }
    }

    if (prevState.shipping_country != this.state.shipping_country) {
      if (prevState.shipping_state) {
        if (!this.state.removedAddress) {
          this.setState({
            shipping_state: ''
          });
        } else {
          this.setState({
            removedAddress: false
          });
        }
      }
    }

    if (prevState.company_name != this.state.company_name) {
      // console.log('company name changed');
      if (this.state.company_name && (this.props.currentBPType && this.props.currentDB)) {
        // console.log('checking for pending bp');
        this.props.checkPendingBP(this.state.company_name, this.props.currentBPType, this.props.currentDB);
      }
    }

    if (this.state.additionalShippingAddresses) {
      if (JSON.stringify(prevState.additionalShippingAddresses) != JSON.stringify(this.state.additionalShippingAddresses)) {
        let copyPrev = [ ...prevState.additionalShippingAddresses ];
        let copyThis = [ ...this.state.additionalShippingAddresses ];
        if (copyPrev.length === copyThis.length) {
          copyPrev.forEach((add, i) => {
            if (add.country !== copyThis[i].country) {
              // console.log('changed country', add.country, copyThis[i].country);
              if (copyPrev[i].state) {
                copyThis[i].state = '';
                this.setState({
                  additionalShippingAddresses: copyThis
                });
              }
            }
          });
        }

      }
    }

    if (prevProps.owhtopts != this.props.owhtopts) {
      this.setState({
        wtcodesPresent: true
      })
    }

    if ((prevState.assessee_type !== this.state.assessee_type) && prevState.assessee_type !== '') {
      //// console.log('empty/refresh wtaxcodes');
      this.setState({
        wtcodes: []
      })
    }

    if (prevProps.edi_programs !== this.props.edi_programs) {
      const rbosCheckedCopy = this.recalculateRequiredRBOs();
      this.setState({
        rbosChecked: rbosCheckedCopy
      });
    }

    if (prevProps.data !== this.props.data) {
      const props = this.props;
      console.log('the data changed', props.data);
      if (props.data) {
        const rbosChecked = props.isRegisteredUser ? turnCheck(props.localBP.rbo, 'rbo', props.data.rboActive) : turnCheck(props.data.rbo, 'rbo', props.data.rboActive);

        const ediProgramsChecked = props.isRegisteredUser ? turnCheck(props.localBP.edi, 'edi', props.data.ediActive) : turnCheck(props.data.edi, 'edi', props.data.ediActive);

        const vendorCodes = props.isRegisteredUser ? turnInput(props.localBP.edi, 'edi', props.data.ediActive) : turnInput(props.data.edi, 'edi', props.data.ediActive);

        this.setState({
          card_code: props.data.card_code, // if existing in SAP
          bp_subgroup: (props.data.gmp_id && !props.data.bp_subgroup) ? props.defaultSubgroup : props.data.bp_subgroup,
          company_name: props.data.company_name,
          payterms: props.data.payterms ? props.data.payterms : props.defaultPBP,
          credit_limit: props.data.credit_limit || props.data.credit_limit == 0 ? commatize(props.data.credit_limit) : props.defaultCreditLimit,
          acctname: props.data.acctname,
          currency: props.data.currency,
          gen_tel: props.data.gen_tel ? normalizePhone(props.data.gen_tel) : '',
          gen_fax: props.data.gen_fax ? normalizePhone(props.data.gen_fax) : '',
          nit: props.data.nit,
          panno: props.data.panno,
          tinno: props.data.tinno,
          rfc_curp: props.data.rfc_curp,
          old_code: props.data.old_code,

          resale_cert: props.data.resale_cert,
          resale_cert_paths: props.data.resale_cert_paths,
          resale_certificate_path: props.data.resale_certificate_path,
          resale_certificate_entry: props.data.resale_certificate_entry,

          withholding: props.data.withholding,
          ni_num: props.data.ni_num,
          certificate_num: props.data.certificate_num,
          expiration_date: props.data.expiration_date,
          assessee_type: props.data.assessee_type,
          accrual_criteria: props.data.accrual_criteria,
          threshold_overlook: props.data.threshold_overlook,
          surcharge_overlook: props.data.surcharge_overlook,
          wtcodes: props.data.wtcodes,

          // Billing Address (only 1)
          billing_addressname: helpers.removeAddressColon(props.data.billing_addressname),
          billing_oname: props.data.billing_oname,
          billing_address1: props.data.billing_address1,
          billing_address2: props.data.billing_address2,
          billing_country: props.data.billing_country,
          billing_state: props.data.billing_state,
          billing_city: props.data.billing_city,
          billing_zipcode: props.data.billing_zipcode,
          billing_gstregno: props.data.billing_gstregno,
          billing_gstregtype: props.data.billing_gstregtype,

          // Shipping Address 1 (required if Supplier)
          shipping_addressname: helpers.removeAddressColon(props.data.shipping_addressname),
          shipping_oname: props.data.shipping_oname,
          shipping_address1: props.data.shipping_address1,
          shipping_address2: props.data.shipping_address2,
          shipping_country: props.data.shipping_country,
          shipping_state: props.data.shipping_state,
          shipping_city: props.data.shipping_city,
          shipping_zipcode: props.data.shipping_zipcode,
          shipping_gstregno: props.data.shipping_gstregno,
          shipping_gstregtype: props.data.shipping_gstregtype,
          default_shipping: findDefaultShipping(props.data.default_shipping, props.data.shipping_addressname, props.data.additionalShippingAddresses, props.data.bp_type),

          additionalShippingAddresses: props.data.additionalShippingAddresses ? helpers.removeAddressColonArr(props.data.additionalShippingAddresses) : [],

          // Contact 1
          accounts_payable_name: props.data.accounts_payable_name,
          accounts_payable_oname: props.data.accounts_payable_oname,
          accounts_payable_position: props.data.accounts_payable_position,
          accounts_payable_tel: props.data.accounts_payable_tel ? normalizePhone(props.data.accounts_payable_tel) : '',
          accounts_payable_alttel: props.data.accounts_payable_alttel ? normalizePhone(props.data.accounts_payable_alttel) : '',
          accounts_payable_mobile: props.data.accounts_payable_mobile ? normalizePhone(props.data.accounts_payable_mobile) : '',
          accounts_payable_fax: props.data.accounts_payable_fax ? normalizePhone(props.data.accounts_payable_fax) : '',
          accounts_payable_email: props.data.accounts_payable_email,
          accounts_payable_firstname: props.data.accounts_payable_firstname,
          accounts_payable_lastname: props.data.accounts_payable_lastname,
          accounts_payable_gmp: props.data.accounts_payable_gmp,

          // Contact 2
          purchasing_name: props.data.purchasing_name,
          purchasing_oname: props.data.purchasing_oname,
          purchasing_position: props.data.purchasing_position,
          purchasing_tel: props.data.purchasing_tel ? normalizePhone(props.data.purchasing_tel) : '',
          purchasing_alttel: props.data.purchasing_alttel ? normalizePhone(props.data.purchasing_alttel) : '',
          purchasing_mobile: props.data.purchasing_mobile ? normalizePhone(props.data.purchasing_mobile) : '',
          purchasing_fax: props.data.purchasing_fax ? normalizePhone(props.data.purchasing_fax) : '',
          purchasing_email: props.data.purchasing_email,
          purchasing_firstname: props.data.purchasing_firstname,
          purchasing_lastname: props.data.purchasing_lastname,
          purchasing_gmp: props.data.purchasing_gmp,

          // Contacts
          additionalContacts: props.data.additionalContacts ? normalizePhoneArr(props.data.additionalContacts) : [],
          notes: props.data.notes,

          rbo: props.data.rbo,
          edi: props.data.edi,
          rbosChecked,
          ediProgramsChecked,
          vendorCodes,
          before_rbosChecked: rbosChecked,
          before_ediProgramsChecked: ediProgramsChecked,
          before_vendorCodes: vendorCodes,
          updateMagento: props.updateMagento ? 'Y' : 'N',

        }, () => {
          this.checkSimilars();
          this.checkAllGMPVendorCodes();
          const rbosCheckedCopy = this.recalculateRequiredRBOs();
          this.setState({
            rbosChecked: rbosCheckedCopy
          });
        })
      }
    }
  }

  render() {
    console.log('init....', this.state, this.props);
    const {
      classes,
      handleDBChange,
      handleBPTypeChange,
      currentDB,
      currentBPType,
      currencies,
      payterms,
      paytermsDictionary,
      countries,
      countriesDictionary,
      states,
      statesDictionary,
      gsttypes,
      gsttypesDictionary,
      dbs,
      owhtopts,
      owhtoptsDictionary,
      enterPayTerms
    } = this.props;

    let billingStates = null,
        shippingStates = null;
    if (states) {
      if (this.state.billing_country) {
        if (states.hasOwnProperty(this.state.billing_country)) {
          billingStates = states[this.state.billing_country];
        }
      }
      if (this.state.shipping_country) {
        if (states.hasOwnProperty(this.state.shipping_country)) {
          shippingStates = states[this.state.shipping_country];
        }
      }
    }

    let showRFCCURP = false,
        showNIT = false,
        isNIN = false,
        isWTCodes = false,
        stateIsRequired = false,
        isGMPExisting = false,
        hasGMP = this.checkForGMP(),
        isGMP = false,
        isNAS = false,
        isNGT = false,
        isNMX = false;

    console.log('hasGMP',hasGMP);
    if (currentDB) {
      isNIN = currentDB.indexOf('NIN') !== -1 ? true : false;
      isNMX = currentDB.indexOf('NMX') !== -1 ? true : false;
      isNAS = currentDB.indexOf('NAS') !== -1 ? true : false;
      isNGT = currentDB.indexOf('NGT') !== -1 ? true : false;

      if (isNGT && this.state.billing_country === 'GT' && currentBPType === 'Customer') {
        showNIT = true;
      }

      if ((this.state.billing_country === 'MX' && isNMX) && currentBPType === 'Customer') {
        showRFCCURP = true;
      }
    }


    if (this.state.gmp_id) {
      isGMP = true;
      if (this.props.additionalContact) {
        isGMPExisting = true;
      }
    }

    let dbAndType = false;
    if (currentDB && currentBPType) {
      dbAndType = true;
    }

    if (owhtopts) {
      if (owhtopts.length > 0) {
        isWTCodes = true;
      }
    }

    let selectedGroupCodes = null;
    if (this.props.groupcodes) {
      if (currentBPType) {
        if (currentBPType === 'Customer') {
          selectedGroupCodes = this.props.groupcodes_customer;
        } else {
          selectedGroupCodes = this.props.groupcodes_supplier;
        }
      }
    }
    console.log('selectedGroupCodes', selectedGroupCodes);

    let showAccts = false, acctnameValidation = [];
    if (currentDB) {
      if (this.props.groupcodesDictionary && this.state.bp_subgroup) {
        if (
          (currentDB.indexOf('NMX') === -1 || currentDB.indexOf('NKR') === -1)
          && currentBPType === 'Supplier'
          && this.props.groupcodesDictionary[this.state.bp_subgroup] === 'Service'
        ) {
          showAccts = true;
          acctnameValidation = [required];
        }
      }
    }

    const subHeadingStyle = {
      textAlign:'center',
      marginTop:'30px',
      color: '#11aae3'
    };

    let notReq = '2';
    if (currentBPType === 'Customer') {
      notReq = '';
      // console.log('made into a customer', validations);
    }
    if (this.state.shipping_addressname || this.state.shipping_address1 || this.state.shipping_address2 || this.state.shipping_country || this.state.shipping_state || this.state.shipping_zipcode || this.state.shipping_city || this.state.shipping_gstregno || this.state.shipping_gstregtype) {
      notReq = '';
      // console.log('made into a customer2', validations);
    };

    let shippingValidation = {
      addressname: validations[`shipping${notReq}_addressname`],
      address1: validations[`shipping${notReq}_address1`],
      address2: validations[`shipping${notReq}_address2`],
      country: validations[`shipping${notReq}_country`],
      state: validations[`shipping${notReq}_state`],
      city: validations[`shipping${notReq}_city`],
      zipcode: validations[`shipping${notReq}_zipcode`],
      gstregno: validations[`shipping${notReq}_gstregno`],
      gstregtype: validations[`shipping${notReq}_gsttype`]
    }

    // console.log('shipping validation', shippingValidation, notReq, currentBPType);

    const isRequiredPurchasingContact = (this.state.purchasing_firstname || this.state.purchasing_lastname || this.state.purchasing_tel || this.state.position || this.state.email || this.state.additionalContacts.length > 0) ? true : false;

    let changes = {};
    if (this.state.finalCleanedValues) {
      if (this.state.finalCleanedValues.changes) {
        changes.before = JSON.parse(this.state.finalCleanedValues.changes).before;
        changes.after = JSON.parse(this.state.finalCleanedValues.changes).after;
      }
    }

    const showResaleCert = this.props.currentDB ? ((this.props.currentDB.indexOf('NLA') !== -1 && this.props.currentBPType === 'Customer') && (this.state.billing_state === 'CA' && this.state.billing_country === 'US')) ? true : false : false;
    console.log('show resale cert', showResaleCert, this.props.currentDB, this.props.currentBPType, this.state.billing_state, this.state.billing_country);

    let current_resale_filepath = ''
    if (this.state.resale_certificate_path) {
      current_resale_filepath = this.state.resale_certificate_path.split('\\SAP\\B1_SHR2\\ATTACHMENTS\\RESALECERTS\\')[1];
    }

    const rbos1 = this.state.rbosChecked ? Object.keys(this.state.rbosChecked).map(rr => {
      const rro = rr.split('_');
      return {
        id: rro[0],
        rbo: rro[1],
        gmp_id: rro[2],
        a_id: rro[3],
        name: this.props.rbosDict[rro[1]] ? this.props.rbosDict[rro[1]] : ''
      }
    }) : [];

    const rbos = [].concat(rbos1)
    .sort((a, b) => (a.name > b.name) ? 1 : -1);

    const requiredRBOs = this.getRequiredRBOs();

    const edis1 = this.state.ediProgramsChecked ? Object.keys(this.state.ediProgramsChecked).map(rr => {
      const rro = rr.split('_');
      return {
        id: rro[0],
        edi_program: rro[1],
        gmp_id: rro[2],
        a_id: rro[3],
        name: this.props.edi_programsDict[rro[1]] ? this.props.edi_programsDict[rro[1]] : '',
        new_id: rro[4] ? rro[4] : ''
      }
    }) : [];

    const edis = [].concat(edis1)
    .sort((a, b) => (a.name > b.name) ? 1 : -1);

    let should_show_contact1 = true;
    if (!hasGMP) {
      should_show_contact1 = true;
    } else {
      if (this.state.accounts_payable_name.indexOf('GMP') !== -1) {
        should_show_contact1 = false;
      }
    }
    console.log('should_show_contact1', should_show_contact1);

    let should_show_contact2 = true;
    if (!hasGMP) {
      should_show_contact2 = true;
    } else {
      if (this.state.purchasing_name.indexOf('GMP') !== -1) {
        should_show_contact2 = false;
      }
    }

    console.log('should show?', should_show_contact1, should_show_contact2)

    let additionalShippingAddresses = [];
    if (this.state.additionalShippingAddresses) {
      if (this.state.additionalShippingAddresses.length > 0) {
        additionalShippingAddresses = [ ...this.state.additionalShippingAddresses ];
      }
    }

    return (
    <div>

    {/* SEPARATE COMPONENTS */}
    {
      this.state.showBPlist &&
      <BPlist
        bps={this.state.bps}
        open={this.state.showBPlist}
        close={this.closeBPlist}
        currentVendorCode={this.state.currentVendorCode}
      />
    }
    {
      this.state.showVendorCodeEdit &&
      <BPVendorCodeDialog
        open={this.state.showVendorCodeEdit}
        close={this.closeVendorCodeEdit}
        data={this.state.vendorCodeEditData}
        choose={this.handleVendorText}
        enterPayTerms={this.props.enterPayTerms}
        checkVendorCodes={this.props.checkVendorCodes}
      />
    }
    {
      this.state.showErrorModal && <SweetAlert
            error
            title={this.props.existingBPfoundMsg ? "Error" : "Form Errors"}
            style={{ display: "block" }}
            onConfirm={this.closeErrorModal}
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
            >
            <div style={{height:'200px',overflowY:'scroll'}}>
              {this.state.mainErrors}
              {this.state.additionalErrors}
            </div>
            </SweetAlert>
      }
      {
        this.state.showUnlockModal && <SweetAlert
              warning
              showCancel
              title="Exit BP, WARNING: All changes will be lost!"
              style={{ display: "block" }}
              onConfirm={() => {
                console.log('unlocking and closing',currentDB, this.state.card_code, this.state.company_name);
                if (this.props.BPaction === 'EXISTING') {
                  this.props.unlockBP(currentDB, this.state.card_code, this.state.company_name);
                }
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              }}
              onCancel={this.closeUnlockModal}
              confirmBtnCssClass={
                this.props.classes.button + " " + this.props.classes.success
              }
              cancelBtnCssClass={
                this.props.classes.button + " " + this.props.classes.rose
              }
              >

              </SweetAlert>
        }
      {
        this.state.askModal && <SweetAlert
        warning
        showCancel
        confirmBtnText="Yes"
        cancelBtnText="No"
        confirmBtnCssClass={
          this.props.classes.button + " " + this.props.classes.success
        }
        cancelBtnCssClass={
          this.props.classes.button + " " + this.props.classes.default
        }
        title="Are you sure?"
        onConfirm={() => {
          if (this.state.askRequest === 'copyShipping') {
            this.copyToShipping();
          }
          if (this.state.askRequest.indexOf('removeAddress') !== -1) {
            const ind = this.state.askRequest.split(',')[1];
            console.log(ind, this.state.askRequest);
            this.removeAddress(ind);
          }
          if (this.state.askRequest.indexOf('removeContact') !== -1) {
            const ind = this.state.askRequest.split(',')[1];
            //// console.log(ind, this.state.askRequest);
            this.removeContact(ind);
          }
          if (this.state.askRequest.indexOf('denyNewGMP') !== -1) {
            const submission_id = this.state.askRequest.split(',')[1];
            console.log('denying 0 ...', submission_id, this.state.askRequest);
            let denyMsg = 'Deny(NEW)';
            if (this.state.gmp_id) {
              denyMsg = 'Deny(NEW GMP)';
            }
            if (this.state.card_code) {
              denyMsg = 'Deny(EXISTING)';
            }
            this.props.handleDenyNewGMP({
              id: parseInt(submission_id),
              bp_type: this.props.currentBPType,
              DB: this.props.currentDB,
              company_name: this.state.company_name,
              denyMsg
            });
            this.props.cancelProfileView();
          }
          this.closeAskModal();
        }}
        onCancel={this.closeAskModal}
        >
        {this.state.askRequest === 'copyShipping' && <div>Overwrite the default Shipping Address with the Billing Address values ?</div>}
        {this.state.askRequest.indexOf('removeAddress') !== -1 && <div>Remove this Shipping Address ?</div>}
        {this.state.askRequest.indexOf('removeContact') !== -1 && <div>Remove this Contact ?</div>}
        {this.state.askRequest.indexOf('denyNewGMP') !== -1 && <div>Deny this Submission ?</div>}
      </SweetAlert>
      }
      {
        this.state.alert && <GINalert
          hide={this.closeAlert}
          content={this.state.alert}
        />
      }
      {
        this.state.dropdownOpen &&
          <BPDropdown
            dropdownObject={this.state.dropdownObject}
            open={this.state.dropdownOpen}
            close={this.closeDropdown}
          />
      }
      {
        this.state.rboediOpen &&
          <BPRBOedi
            dropdownObject={this.state.dropdownRBOEDI}
            open={this.state.rboediOpen}
            close={this.closeRBOEDI}
            vendorCodes={this.state.vendorCodes}
            ediProgramsChecked={this.state.ediProgramsChecked}
          />
      }
      {
        this.state.similarsAlert &&
            <BPmodalSimilars
              similarsContent={this.state.similarsAlertContent}
              close={this.hideSimilarsAlert}
              open={this.state.similarsAlert}
              currentDB={this.props.currentDB}
              loadBPForReview={this.props.loadBPForReview}
              beforeValues={this.props.data}
              enterPayTerms={this.props.enterPayTerms}
            />
      }
      {
        (this.state.reviewModalStatus && this.state.finalCleanedValues) &&
        <BPReview
          finalValues={JSON.parse(this.state.finalCleanedValues.changes)}
          beforeValues={JSON.parse(this.state.finalCleanedValues.before)}
          changes={JSON.parse(this.state.finalCleanedValues.changes)}
          before={JSON.parse(this.state.finalCleanedValues.before)}
          open={this.state.reviewModalStatus}
          close={this.toggleReviewModal}
          countries={countriesDictionary}
          states={statesDictionary}
          gsttypes={gsttypesDictionary}
          owhtopts={owhtoptsDictionary}
          BPaction={this.props.BPaction}
          onApprove={this.onApprove}
          onDeny={this.onDeny}
          directToSAP={this.props.directToSAP}
          enterPayTerms={this.props.enterPayTerms}
          currentDB={currentDB}
          currentBPType={currentBPType}
          isFromPending={this.props.isFromPending}
          reviewType="submission"
        />
      }
      {
        this.state.companyNameModalStatus && <BPmodal
          changeCompanyName={this.changeCompanyName}
          open={this.state.companyNameModalStatus}
          close={this.toggleCompanyNameModal}
          validate={validations.company_name}
          currentDB={currentDB}
          currentBPType={currentBPType}
          normalize={upper}
          value={this.state.company_name}
          BPaction={this.props.BPaction}
          BPtype={currentBPType}
          loadBPForReview={this.props.loadBPForReview}
          beforeValues={this.props.data}
          changeKey={this.changeKey}
          enterPayTerms={this.props.enterPayTerms}
        />
      }

      {
        hasGMP &&
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Button
              color={this.state.openedTab === 'General' ? 'success' : 'rose'}
              size="lg" style={{marginRight:20}}
              onClick={() => { this.setOpenedTab('General')}}>GENERAL</Button>
            <Button
              color={this.state.openedTab === 'GMP' ? 'success' : 'rose'}
              size="lg" style={{marginRight:20}}
              onClick={() => { this.setOpenedTab('GMP')}}>TPP</Button>
          </GridItem>
        </GridContainer>
      }

      { ((hasGMP && this.state.openedTab === 'General') || (!hasGMP)) &&
    <div className="" style={{marginTop:0}}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
        <div className="no-top-margin" style={{marginTop:20}}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={5}>
                <div className={this.props.disableDB ? 'disabled-controller' : ''}>
                  <BPObjSelect
                    label="Database"
                    options={this.state.allowedDBsDict}
                    choose={handleDBChange}
                    name="DB"
                    iterator={['id','name']}
                    default={currentDB}
                    disabled={this.props.disableDB}
                  />
                </div>
                </GridItem>
                {currentDB && <GridItem xs={12} sm={12} md={3}>
                <div className={this.props.disableDB ? 'disabled-controller' : ''}>
                  <BPSelect
                    label="BP Type"
                    options={['Customer', 'Supplier']}
                    choose={(e, n) => {
                      handleBPTypeChange(e, this.state.company_name)
                    }}
                    name="bp_type"
                    validate={validations.bp_type}
                    default={currentBPType}
                    disabled={this.props.disableBPType}
                  />
                </div>
                </GridItem> }
                <GridItem xs={12} sm={12} md={4}>
                  {(isGMP && !isGMPExisting) && <div style={subHeadingStyle}>
                                                  <h4><strong>New TPP BP</strong></h4>
                                                  {this.props.BPaction === 'PENDING' && <Button
                                                    color="rose"
                                                    onClick={() => {
                                                      this.props.convertNewToExisting(this.state.id, this.props.currentDB);
                                                    }}
                                                  >CONVERT TO EXISTING</Button>}
                                                </div>}
                  {(isGMP && isGMPExisting) && <div style={subHeadingStyle}><h4><strong>Existing TPP BP</strong></h4></div>}
                  {((!isGMP && this.props.BPaction === 'PENDING') && !this.state.card_code) && <div style={subHeadingStyle}><h4><strong>User Entered New BP</strong></h4></div>}
                  {((!isGMP && this.props.BPaction === 'PENDING') && this.state.card_code && !this.props.isRegisteredUser) && <div style={subHeadingStyle}><h4><strong>User Entered Existing BP</strong></h4></div>}
                  {((!isGMP && this.props.BPaction === 'PENDING') && this.state.card_code && this.props.isRegisteredUser) && <div style={subHeadingStyle}><h4><strong>TPP Registered User, Existing BP</strong></h4></div>}
                  {(!isGMP && this.props.BPaction === 'NEW') && <div style={subHeadingStyle}><h4><strong>New BP</strong></h4></div>}
                  {(!isGMP && this.props.BPaction === 'EXISTING') && <div style={subHeadingStyle}><h4><strong>Edit Existing BP</strong></h4></div>}
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          </div>
        </GridItem>

        {dbAndType && <GridItem xs={12} sm={12} md={8}>
          <div className="no-top-margin special-bp-box">
            <Card>
              <CardBody>
                <GridContainer>
                {this.state.company_name && <GridItem xs={12} sm={12} md={12}>
                    <h4 style={{marginBottom:0}}><strong>General Information</strong></h4>
                  </GridItem>}

                {!this.state.company_name && <GridItem xs={12} sm={12} md={8}>
                  <div>
                      <BPInput
                        onClick={() => {
                          // console.log('clicked company name modal');
                          if (this.props.BPaction === 'NEW' || this.props.BPaction === 'EXISTING' || this.props.BPaction === 'PENDING') {
                            this.toggleCompanyNameModal();
                            this.props.fetchAllBPs(currentBPType, currentDB);
                          }
                        }}
                        label="Business Partner Name"
                        name="company_name"
                        value={this.state.company_name}
                        handleTextInputChange={this.props.BPaction === 'PENDING' ? this.handleTextInputChange : this.handleTextInputChangeCompanyName}
                        validate={validations.company_name}
                      />
                      <input name="hidden-field" style={{opacity:0}} autoFocus={true} />
                    </div>
                  </GridItem> }
                </GridContainer>

              {this.state.company_name && <GridContainer>
                  <GridItem xs={12} sm={12} md={8}>
                      <BPInput
                        onClick={() => {
                          // console.log('clicked company name modal');
                          if (this.props.BPaction === 'NEW' || this.props.BPaction === 'EXISTING' || this.props.BPaction === 'PENDING') {
                            this.toggleCompanyNameModal();
                            this.props.fetchAllBPs(currentBPType, currentDB);
                          }
                        }}
                        label="Business Partner Name"
                        name="company_name"
                        value={this.state.company_name}
                        handleTextInputChange={this.props.BPaction === 'PENDING' ? this.handleTextInputChange : this.handleTextInputChangeCompanyName}
                        validate={validations.company_name}
                        similar={this.renderSimilars('company_name')}
                      />
                      {(!this.props.isFromPending && (this.props.isPending ? this.props.isPending.status !== 'ok' ? true : false : false)) && <span className="red-text">BP Name is already in Pending, please enter a different BP Name.</span>}
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4}>
                    <BPObjSelect
                      label="BP Subgroup"
                      options={selectedGroupCodes}
                      choose={this.handleSelectChange}
                      name="bp_subgroup"
                      iterator={['GroupCode','GroupName']}
                      validate={validations.bp_subgroup}
                      default={this.state.bp_subgroup}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4}>
                  <div className={this.props.currency === '##' ? 'disabled-controller' : ''}>
                    <BPObjSelect
                      label="Currency"
                      options={this.state.currency === '##' ? currencies.concat([{id:'##',name:'All Currencies'}]) : currencies}
                      choose={this.handleSelectChange}
                      name="currency"
                      iterator={['id','name']}
                      validate={validations.currency}
                      default={this.state.currency}
                      disabled={this.state.currency === '##' ? true : false}
                    />
                  </div>
                  </GridItem>

                    <GridItem xs={12} sm={12} md={4}>
                      <BPInput
                        label="General Telephone #"
                        name="gen_tel"
                        value={this.state.gen_tel}
                        handleTextInputChange={this.handleTextInputChange}
                        validate={validations.gen_tel}
                        normalize={normalizePhone}
                        similars={this.renderSimilars('gen_tel')}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <BPInput
                        label="General Fax #"
                        name="gen_fax"
                        value={this.state.gen_fax}
                        handleTextInputChange={this.handleTextInputChange}
                        validate={validations.gen_fax}
                        normalize={normalizePhone}
                      />
                    </GridItem>

                    {
                      isNAS &&
                      <GridItem xs={12} sm={12} md={4}>
                        <BPInput
                          label="Old Code"
                          name="old_code"
                          value={this.state.old_code}
                          handleTextInputChange={this.handleTextInputChange}
                          validate={validations.old_code}
                        />
                      </GridItem>
                    }

                    {
                      isNIN &&
                      <GridItem xs={12} sm={12} md={4}>
                        <BPInput
                          label="P.A.N. No."
                          name="panno"
                          value={this.state.panno}
                          handleTextInputChange={this.handleTextInputChange}
                          validate={validations.panno}
                        />
                      </GridItem>
                    }
                    {
                      isNIN &&
                      <GridItem xs={12} sm={12} md={4}>
                        <BPInput
                          label="G.S.T.I.N."
                          name="tinno"
                          value={this.state.tinno}
                          handleTextInputChange={this.handleTextInputChange}
                          validate={validations.tinno}
                        />
                      </GridItem>
                    }
                    {
                      isNIN &&
                      <GridItem xs={12} sm={12} md={4}>
                        <BPCheckbox
                          label="Subject to Withholding Tax"
                          name="withholding"
                          value={this.state.withholding}
                          onChange={this.handleCheckboxChange}
                        />
                      </GridItem>
                    }

                    {
                      (isNIN && this.state.withholding) &&
                      <GridItem xs={12} sm={12} md={4}>
                        <BPInput
                          label="Certificate No. (optional)"
                          name="certificate_num"
                          value={this.state.certificate_num}
                          handleTextInputChange={this.handleTextInputChange}
                        />
                      </GridItem>
                    }
                    {
                      (isNIN && this.state.withholding) &&
                      <GridItem xs={12} sm={12} md={4}>
                        <BPCalendar
                          name="expiration_date"
                          label="Expiration Date (optional)"
                          onChange={this.onCalendarChange}
                          value={this.state.expiration_date}
                        />
                      </GridItem>
                    }
                    {
                      /* Assessee Type */
                      (isNIN && this.state.withholding) &&
                      <GridItem xs={12} sm={12} md={4}>
                        <BPInput
                          label="N.I. Number (optional)"
                          name="ni_num"
                          value={this.state.ni_num}
                          handleTextInputChange={this.handleTextInputChange}
                        />
                      </GridItem>
                    }


                    {
                      (isNIN && this.state.withholding) &&
                      <GridItem xs={12} sm={12} md={4}>
                        <BPRadio
                          name="assessee_type"
                          label="Assessee Type,Company,Others"
                          options={['0','1']}
                          onChange={this.handleRadioChange}
                          value={(this.state.assessee_type !== undefined && this.state.assessee_type !== null) ? this.state.assessee_type.toString() : ''}
                        />
                      </GridItem>
                    }
                    {
                      (isNIN && this.state.withholding) &&
                      <GridItem xs={12} sm={12} md={4}>
                        <BPRadio
                        name="accrual_criteria"
                        label="Accrual Criteria,Accrual,Cash"
                        options={['1','0']}
                        onChange={this.handleRadioChange}
                        value={(this.state.accrual_criteria !== undefined && this.state.accrual_criteria !== null) ? this.state.accrual_criteria.toString() : ''}
                        />
                      </GridItem>

                    }
                    {
                      (isNIN && this.state.withholding) &&
                        <GridItem xs={12} sm={12} md={3}>
                          <BPRadio
                          name="threshold_overlook"
                          label="Threshold Overlook,Yes,No"
                          options={['1','0']}
                          onChange={this.handleRadioChange}
                          value={(this.state.threshold_overlook !== undefined && this.state.threshold_overlook !== null) ? this.state.threshold_overlook.toString() : ''}
                          />
                        </GridItem>
                    }
                    {
                      (isNIN && this.state.withholding) &&
                        <GridItem xs={12} sm={12} md={1}>
                        </GridItem>
                    }

                    {
                      (isNIN && this.state.withholding) &&
                      <GridItem xs={12} sm={12} md={8}>
                        <div style={{paddingLeft:'12px',paddingTop:'22px'}}>
                          <strong style={{color:'#aaa'}}>WTAX Codes Allowed :</strong>
                          <span style={{color:'#aaa'}}>(Select Assessee Type to show)</span>
                          {
                            owhtopts && <WTCodes
                              owhtopts={owhtopts}
                              wtArr={this.state.wtcodes}
                              onChange={this.handleWTCheckboxChange}
                              assessee={this.state.assessee_type}
                            />
                          }
                        </div>
                      </GridItem>
                    }
                    {
                      (isNIN && this.state.withholding) &&
                        <GridItem xs={12} sm={12} md={3}>
                          <BPRadio
                          name="surcharge_overlook"
                          label="Surcharge Overlook,Yes,No"
                          options={['1','0']}
                          onChange={this.handleRadioChange}
                          value={(this.state.surcharge_overlook !== undefined && this.state.surcharge_overlook !== null) ? this.state.surcharge_overlook.toString() : ''}
                          />
                        </GridItem>
                    }
                    {
                      /* Accrual Criteria */
                      (isNIN && this.state.withholding) &&
                      <GridItem xs={12} sm={12} md={4}>
                      </GridItem>
                    }
                  </GridContainer>}
              </CardBody>
            </Card>
            </div>
          </GridItem> }

          {(dbAndType && enterPayTerms) && <GridItem xs={12} sm={12} md={4}>
          <div className="no-top-margin" style={{display:this.state.company_name ? 'block':'none'}}>
            <Card>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <h4><strong>Accounting Information</strong></h4>
                  </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <div className={!this.props.enterPayTerms ? 'disabled-controller' : ''}>
                        <BPObjSelect
                          label="Pay Terms"
                          options={payterms}
                          choose={this.handleSelectChange}
                          name="payterms"
                          iterator={['id','name']}
                          validate={validations.payterms}
                          default={this.state.payterms}
                          disabled={!this.props.enterPayTerms}
                        />
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      {(this.state.currency && this.state.currency !== '##') && <div style={{marginTop:'31px',textAlign:'right'}}>{this.state.currency}</div>}
                      {(this.state.currency === '##') && <div style={{marginTop:'32px',textAlign:'right',fontSize:'10px'}}>All Currencies</div>}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={8}>
                      <BPInput
                        label="Credit Limit"
                        name="credit_limit"
                        value={this.state.credit_limit}
                        handleTextInputChange={this.handleTextInputChange}
                        normalize={onlyNumber}
                        validate={validations.credit_limit}
                        disabled={!this.props.enterPayTerms}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      {/*
                        showAccts &&
                        <BPSelect
                          label="Account Name"
                          options={this.props.acctnames}
                          choose={this.handleSelectChange}
                          name="acctname"
                          default={this.state.acctname}
                          disabled={!this.props.enterPayTerms}
                          validate={acctnameValidation}
                        />
                      */}
                      {showAccts &&
                        <BPInputDropdown
                          onClick={() => {
                            this.showDropdown({
                              value: this.state.acctname,
                              options: this.props.acctnamesObj,
                              indx: 'id',
                              desc: 'name',
                              field: 'acctname',
                              title: 'Select an Account Name',
                              dict: this.props.acctnamesObjDictionary,
                              choose: this.handleSelectChange
                            });
                          }}
                          label="Account Name"
                          name="acctname"
                          value={this.state.acctname}
                          dict={this.props.acctnamesObjDictionary}
                          validate={acctnameValidation}
                        />
                      }
                    </GridItem>
                </GridContainer>
              </CardBody>
            </Card></div>
          </GridItem> }

          {showResaleCert &&
<GridItem xs={12} sm={12} md={12}>
  <div className="no-top-margin">
    {
      this.state.showImage &&
      <BPDialog
        open={this.state.showImage}
        close={this.hideImage}
        type="image"
        image={this.state.showImageURL}
      />
    }
    {
      this.state.showPDF &&
      <BPDialog
        open={this.state.showPDF}
        close={this.hidePDF}
        type="pdf"
        pdf={this.state.showPDFURL}
      />
    }
    <Card>
      <CardBody>
        <GridContainer>
          <GridItem xs={12} sm={12} md={3}>
            <h4><strong>Resale Certificate</strong></h4>
            {!this.state.resale_cert && <div>
              <input
                style={{ display: 'none' }}
                id="raised-button-file"
                ref={this.fileInputRef}
                type='file'
                accept={acceptedFileTypes}
                multiple={false}
                onChange={this.handleFileSelect}
              />
              <label htmlFor="raised-button-file">
                <Button variant="raised" component="span" color="rose" style={{marginRight:'20px'}}>
                  {this.state.resale_certificate || this.state.resale_certificate_path ? 'Replace' : 'Upload'}
                </Button>
              </label>
            </div>}
            {this.state.resale_cert && <div>
              <Button
                color="white"
                style={{marginRight:'20px'}}
                onClick={() => {
                  this.setState({
                    resale_cert: null,
                    resale_certificate: ''
                  });
                }}
              >
                Clear
              </Button>
            </div>}
            <p style={{fontSize:10,lineHeight:1.5}}>* Files must have a maximum filesize of 10MB, and PDF, PNG or JPG file types only</p>
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            {this.state.resale_certificate_path && <div>
              <h4><strong>Current SAP Resale Certificate Attachment :</strong></h4>
              <div>
              <a
              href={devStatus === 'live' ? `https://portal2.natcoglobal.com/api/common/rsc/${current_resale_filepath}` : `https://staging.portal2.natcoglobal.com/api/common/rsc/${current_resale_filepath}`}
              target="_blank"
              onClick={(e) => {
                e.preventDefault();
                if (current_resale_filepath.split('.')[1] === 'pdf') {
                  const pdfURL = devStatus === 'live' ? `https://portal2.natcoglobal.com/api/common/rsc/${current_resale_filepath}` : `https://staging.portal2.natcoglobal.com/api/common/rsc/${current_resale_filepath}`;
                  this.showPDF(pdfURL);
                } else if ((current_resale_filepath.split('.')[1] === 'jpg' || current_resale_filepath.split('.')[1] === 'jpeg') || current_resale_filepath.split('.')[1] === 'png') {
                  const imageURL = devStatus === 'live' ? `https://portal2.natcoglobal.com/api/common/rsc/${current_resale_filepath}` : `https://staging.portal2.natcoglobal.com/api/common/rsc/${current_resale_filepath}`;
                  this.showImage(imageURL);
                }
              }}
              >{current_resale_filepath}</a>
              </div>
            </div>}
            {this.state.resale_certificate && <div>
              <h4><strong>New Uploaded Resale Certificate :</strong></h4>
              {
                !this.state.resale_cert && <div>
                  <a
                  href={devStatus === 'live' ? `https://portal2.natcoglobal.com/api/common/rsc/${this.state.resale_certificate.substring(this.state.resale_certificate.lastIndexOf('/')+1)}` : `https://staging.portal2.natcoglobal.com/api/common/rsc/${this.state.resale_certificate.substring(this.state.resale_certificate.lastIndexOf('/')+1)}`}
                  target="_blank"
                  onClick={(e) => {
                    e.preventDefault();
                    const filepth = this.state.resale_certificate.substring(this.state.resale_certificate.lastIndexOf('/')+1);
                    if (filepth.split('.')[1] === 'pdf') {
                      const pdfURL = devStatus === 'live' ? `https://portal2.natcoglobal.com/api/common/rsc/${filepth}` : `https://staging.portal2.natcoglobal.com/api/common/rsc/${filepth}`;
                      this.showPDF(pdfURL);
                    } else if ((filepth.split('.')[1] === 'jpg' || filepth.split('.')[1] === 'jpeg') || filepth.split('.')[1] === 'png') {
                      const imageURL = devStatus === 'live' ? `https://portal2.natcoglobal.com/api/common/rsc/${filepth}` : `https://staging.portal2.natcoglobal.com/api/common/rsc/${filepth}`;
                      this.showImage(imageURL);
                    }
                  }}
                  >{this.state.resale_certificate.substring(this.state.resale_certificate.lastIndexOf('/')+1)}</a>
                </div>
              }
              {
                this.state.resale_cert && <div>
                  {this.state.resale_cert.name} - will be renamed and uploaded as - {this.state.resale_certificate.substring(this.state.resale_certificate.lastIndexOf('/')+1)}
                </div>
              }
            </div>}
            {(!this.state.resale_certificate && !this.state.resale_certificate_path) && <div>
              <div className="red-text" style={{marginTop:20}}>* Required</div>
            </div>}
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  </div>
</GridItem>
        }

          {dbAndType && <GridItem xs={12} sm={12} md={6}>
          <div className="no-top-margin" style={{display:this.state.company_name ? 'block':'none'}}>
            <Card>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <h4><strong>Billing Address</strong></h4>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <BPInput
                      label="Name"
                      name="billing_addressname"
                      value={this.state.billing_addressname}
                      handleTextInputChange={this.handleTextInputChange}
                      normalize={upper}
                      validate={validations.billing_addressname}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <BPInput
                      label="Address 1"
                      name="billing_address1"
                      value={this.state.billing_address1}
                      handleTextInputChange={this.handleTextInputChange}
                      validate={validations.billing_address1}
                      normalize={upper}
                      similar={this.renderSimilars('billing_address')}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <BPInput
                      label="Address 2"
                      name="billing_address2"
                      value={this.state.billing_address2}
                      handleTextInputChange={this.handleTextInputChange}
                      validate={validations.billing_address2}
                      normalize={upper}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <BPInput
                      label="City"
                      name="billing_city"
                      value={this.state.billing_city}
                      handleTextInputChange={this.handleTextInputChange}
                      validate={validations.billing_city}
                      normalize={upper}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    {
                      <BPInputDropdown
                        onClick={() => {
                          this.showDropdown({
                            value: this.state.billing_country,
                            options: countries,
                            indx: 'id',
                            desc: 'name',
                            field: 'billing_country',
                            title: 'Select a Country',
                            dict: countriesDictionary,
                            choose: this.handleSelectChange
                          });
                        }}
                        label="Country"
                        name="billing_country"
                        value={this.state.billing_country}
                        dict={countriesDictionary}
                        validate={validations.billing_country}
                      />
                    }
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                      {billingStates &&
                        <BPInputDropdown
                          onClick={() => {
                            this.showDropdown({
                              value: this.state.billing_state,
                              options: billingStates,
                              indx: 'id',
                              desc: 'name',
                              field: 'billing_state',
                              title: 'Select a State',
                              dict: statesDictionary[this.state.billing_country],
                              choose: this.handleSelectChange
                            });
                          }}
                          label="State"
                          name="billing_state"
                          value={this.state.billing_state}
                          dict={statesDictionary[this.state.billing_country]}
                          validate={validations.billing_state}
                        />
                      }
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <BPInput
                      label="Zipcode"
                      name="billing_zipcode"
                      value={this.state.billing_zipcode}
                      handleTextInputChange={this.handleTextInputChange}
                      normalize={upper}
                      validate={validations.billing_zipcode}
                    />
                  </GridItem>
                  {showRFCCURP &&
                    <GridItem xs={12} sm={12} md={6}>
                      <BPInput
                        label="RFC/CURP"
                        name="rfc_curp"
                        value={this.state.rfc_curp}
                        handleTextInputChange={this.handleTextInputChange}
                        validate={validations.rfc_curp}
                        normalize={upper}
                      />
                    </GridItem>
                  }
                  {showNIT &&
                    <GridItem xs={12} sm={12} md={6}>
                      <BPInput
                        label="NIT"
                        name="nit"
                        value={this.state.nit}
                        handleTextInputChange={this.handleTextInputChange}
                        normalize={upper}
                        validate={validations.nit}
                      />
                    </GridItem>
                  }
                  {isNIN &&
                    <GridItem xs={12} sm={12} md={6}>
                      <BPInput
                        label="GST Reg. No."
                        name="billing_gstregno"
                        value={this.state.billing_gstregno}
                        handleTextInputChange={this.handleTextInputChange}
                        validate={validations.billing_gstregno}
                        normalize={upper}
                      />
                    </GridItem>}
                  {isNIN &&
                    <GridItem xs={12} sm={12} md={6}>
                      <BPObjSelect
                        label="GST Reg.Type"
                        options={gsttypes}
                        choose={this.handleSelectChange}
                        name="billing_gstregtype"
                        iterator={['id','type']}
                        validate={validations.billing_gstregtype}
                        default={this.state.billing_gstregtype}
                      />
                    </GridItem>}
                    <GridItem xs={12} sm={12} md={6}>
                    {(this.state.billing_addressname ||
                      this.state.billing_address1 ||
                      this.state.billing_address2 ||
                      this.state.billing_country ||
                      this.state.billing_state ||
                      this.state.billing_zipcode ||
                      this.state.billing_city) && <Button
                      size="sm"
                      color="rose"
                      type="button"
                      className={classes.updateProfileButton}
                      onClick={() => {
                        if (this.state.shipping_addressname ||
                          this.state.shipping_address1 ||
                          this.state.shipping_address2 ||
                          this.state.shipping_country ||
                          this.state.shipping_state ||
                          this.state.shipping_zipcode ||
                          this.state.shipping_city) {
                            this.openAskModal('copyShipping');
                          } else {
                            this.copyToShipping();
                          }
                      }}
                      style={{position:'absolute',top:'15px',right:'7px'}}
                    >COPY TO SHIPPING</Button>}
                    </GridItem>
                </GridContainer>
              </CardBody>
            </Card></div>
          </GridItem> }

          {dbAndType && <GridItem xs={12} sm={12} md={6}>
          <div className="no-top-margin" style={{display:this.state.company_name ? 'block':'none'}}>
            <Card>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <h4><strong>Shipping Address 1</strong></h4>
                    <div className="relative">
                      <div style={{top:'-46px',right:'12px',position:'absolute'}}>
                        <BPCheckbox
                          label="Default"
                          name="-1"
                          value={this.state.default_shipping == -1 ? true : false}
                          onChange={this.onDefaultShippingChange}
                        />
                      </div>
                    </div>
                    {additionalShippingAddresses.length > 0 && <a onClick={() => {
                      this.openAskModal(`removeAddress,-1`);
                    }}
                    className="upper-right-a">
                      <Close />
                    </a>}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <BPInput
                      label="Name"
                      name="shipping_addressname"
                      value={this.state.shipping_addressname}
                      handleTextInputChange={this.handleTextInputChange}
                      normalize={upper}
                      validate={shippingValidation.addressname}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <BPInput
                      label="Address 1"
                      name="shipping_address1"
                      value={this.state.shipping_address1}
                      handleTextInputChange={this.handleTextInputChange}
                      validate={shippingValidation.address1}
                      normalize={upper}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <BPInput
                      label="Address 2"
                      name="shipping_address2"
                      value={this.state.shipping_address2}
                      handleTextInputChange={this.handleTextInputChange}
                      validate={shippingValidation.address2}
                      normalize={upper}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <BPInput
                      label="City"
                      name="shipping_city"
                      value={this.state.shipping_city}
                      handleTextInputChange={this.handleTextInputChange}
                      validate={shippingValidation.city}
                      normalize={upper}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    {
                      <BPInputDropdown
                        onClick={() => {
                          this.showDropdown({
                            value: this.state.shipping_country,
                            options: countries,
                            indx: 'id',
                            desc: 'name',
                            field: 'shipping_country',
                            title: 'Select a Country',
                            dict: countriesDictionary,
                            choose: this.handleSelectChange
                          });
                        }}
                        label="Country"
                        name="shipping_country"
                        value={this.state.shipping_country}
                        dict={countriesDictionary}
                        validate={validations.shipping_country}
                      />
                    }
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                      {shippingStates &&
                        <BPInputDropdown
                          onClick={() => {
                            this.showDropdown({
                              value: this.state.shipping_state,
                              options: shippingStates,
                              indx: 'id',
                              desc: 'name',
                              field: 'shipping_state',
                              title: 'Select a State',
                              dict: statesDictionary[this.state.shipping_country],
                              choose: this.handleSelectChange
                            });
                          }}
                          label="State"
                          name="shipping_state"
                          value={this.state.shipping_state}
                          dict={statesDictionary[this.state.shipping_country]}
                          validate={validations.shipping_state}
                        />
                      }
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <BPInput
                      label="Zipcode"
                      name="shipping_zipcode"
                      value={this.state.shipping_zipcode}
                      handleTextInputChange={this.handleTextInputChange}
                      normalize={upper}
                      validate={shippingValidation.zipcode}
                    />
                  </GridItem>
                  {isNIN &&
                    <GridItem xs={12} sm={12} md={6}>
                      <BPInput
                        label="GST Reg. No."
                        name="shipping_gstregno"
                        value={this.state.shipping_gstregno}
                        handleTextInputChange={this.handleTextInputChange}
                        validate={shippingValidation.gstregno}
                        normalize={upper}
                      />
                    </GridItem>}
                  {isNIN &&
                    <GridItem xs={12} sm={12} md={6}>
                      <BPObjSelect
                        label="GST Reg.Type"
                        options={gsttypes}
                        choose={this.handleSelectChange}
                        name="shipping_gstregtype"
                        iterator={['id','type']}
                        validate={shippingValidation.gstregtype}
                        default={this.state.shipping_gstregtype}
                      />
                    </GridItem>}
                </GridContainer>
              </CardBody>
            </Card></div>
          </GridItem> }

          {(dbAndType && this.state.company_name) && <Addresses
            classes={classes}
            addAddress={this.addAddress}
            removeAddress={this.removeAddress}
            handleAddressTextInputChange={this.handleAddressTextInputChange}
            handleAddressSelectChange={this.handleAddressSelectChange}
            normalize={upper}
            addressArr={this.state.additionalShippingAddresses}
            countries={countries}
            states={states}
            countriesDictionary={countriesDictionary}
            statesDictionary={statesDictionary}
            gsttypes={gsttypes}
            currentDB={currentDB}
            openAskModal={this.openAskModal}
            defaultShipping={this.state.default_shipping}
            onDefaultShippingChange={this.onDefaultShippingChange}
            showDropdown={this.showDropdown}
          />}

          {(dbAndType && should_show_contact1) && <GridItem xs={12} sm={12} md={6}>
          <div className="special-contact-box" style={{display:this.state.company_name ? 'block':'none'}}>
            <Card>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    {this.state.accounts_payable_name.indexOf('GMP') !== -1 ? <h4><strong>TPP
                    {this.state.accounts_payable_name.indexOf('GMP AP') !== -1 ? ' Accounts Payable ' : ''}
                    {this.state.accounts_payable_name.indexOf('GMP PURCHASING') !== -1 ? ' Purchasing ' : ''}
                    {this.state.accounts_payable_name.indexOf('GMP OTHER') !== -1 ? ' Other ' : ''}
                     Contact</strong>{this.state.accounts_payable_gmp ? <span className="blue-text"> (TPP User ID: # {this.state.accounts_payable_gmp})</span> : ''}</h4> : <h4><strong>Contact 1</strong></h4>}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <div className="input-font-12">
                      <BPInput
                        label="First Name"
                        name="accounts_payable_firstname"
                        value={this.state.accounts_payable_firstname}
                        handleTextInputChange={this.handleTextInputChange}
                        normalize={upper}
                        validate={validations.accounts_payable_firstname}
                      />
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <div className="input-font-12">
                      <BPInput
                        label="Last Name"
                        name="accounts_payable_lastname"
                        value={this.state.accounts_payable_lastname}
                        handleTextInputChange={this.handleTextInputChange}
                        normalize={upper}
                        validate={validations.accounts_payable_lastname}
                      />
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <div className="input-font-12">
                      <BPInput
                        label="Position"
                        name="accounts_payable_position"
                        value={this.state.accounts_payable_position}
                        handleTextInputChange={this.handleTextInputChange}
                        normalize={upper}
                        validate={validations.accounts_payable_position}
                      />
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <div className="input-font-12">
                      <BPInput
                        label="Telephone"
                        name="accounts_payable_tel"
                        value={this.state.accounts_payable_tel}
                        handleTextInputChange={this.handleTextInputChange}
                        normalize={normalizePhone}
                        validate={validations.accounts_payable_tel}
                      />
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <div className="input-font-12">
                      <BPInput
                        label="Alt Telephone"
                        name="accounts_payable_alttel"
                        value={this.state.accounts_payable_alttel}
                        handleTextInputChange={this.handleTextInputChange}
                        normalize={normalizePhone}
                        validate={validations.accounts_payable_alttel}
                      />
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <div className="input-font-12">
                      <BPInput
                        label="Mobile"
                        name="accounts_payable_mobile"
                        value={this.state.accounts_payable_mobile}
                        handleTextInputChange={this.handleTextInputChange}
                        normalize={normalizePhone}
                        validate={validations.accounts_payable_mobile}
                      />
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <div className="input-font-12">
                      <BPInput
                        label="Fax"
                        name="accounts_payable_fax"
                        value={this.state.accounts_payable_fax}
                        handleTextInputChange={this.handleTextInputChange}
                        normalize={normalizePhone}
                        validate={validations.accounts_payable_fax}
                      />
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <div className="input-font-12">
                      <BPInput
                        label="E-mail"
                        name="accounts_payable_email"
                        value={this.state.accounts_payable_email}
                        handleTextInputChange={this.handleTextInputChange}
                        normalize={upper}
                        validate={validations.accounts_payable_email}
                      />
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <div className="input-font-12">
                      <div style={{fontSize:'10px',color:'#838383',marginTop:'10px',opacity:0}}>Name</div>
                      {((!this.state.accounts_payable_firstname || !this.state.accounts_payable_lastname) && this.state.accounts_payable_name) &&
                      <div>
                        <div style={{color:'red',fontSize:'14px'}}>Previous Contact Name is {this.state.accounts_payable_name}</div>
                      </div>
                      }
                      {this.state.accounts_payable_name &&
                        <div style={{float:'right'}}>{this.renderSimilars('contacts', this.state.accounts_payable_name)}</div>
                      }
                    </div>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card></div>
          </GridItem> }

          {(dbAndType && should_show_contact2) && <GridItem xs={12} sm={12} md={6}>
          <div className={this.state.highlightContact === 'purchasing' ? 'highlighted' : ''}>
          <div className="special-contact-box" style={{display:this.state.company_name ? 'block':'none'}}>
            <Card>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                  {this.state.purchasing_name.indexOf('GMP') !== -1 ? <h4><strong>TPP
                  {this.state.purchasing_name.indexOf('GMP AP') !== -1 ? ' Accounts Payable ' : ''}
                  {this.state.purchasing_name.indexOf('GMP PURCHASING') !== -1 ? ' Purchasing ' : ''}
                  {this.state.purchasing_name.indexOf('GMP OTHER') !== -1 ? ' Other ' : ''}
                   Contact {this.state.purchasing_highlight ? <span className="green-box">NEW</span> : ''}</strong>{this.state.purchasing_gmp ? <span className="blue-text"> (TPP User ID: # {this.state.purchasing_gmp})</span> : ''}</h4> : <h4><strong>Contact 2 {this.state.purchasing_highlight ? <span className="green-box">NEW</span> : ''}</strong></h4>}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <div className="input-font-12">
                      <BPInput
                        label="First Name"
                        name="purchasing_firstname"
                        value={this.state.purchasing_firstname}
                        handleTextInputChange={this.handleTextInputChange}
                        normalize={upper}
                        validate={!isRequiredPurchasingContact ? validations.purchasing_firstname : validations.purchasing_firstname.concat([required])}
                      />
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <div className="input-font-12">
                      <BPInput
                        label="Last Name"
                        name="purchasing_lastname"
                        value={this.state.purchasing_lastname}
                        handleTextInputChange={this.handleTextInputChange}
                        normalize={upper}
                        validate={!isRequiredPurchasingContact ? validations.purchasing_lastname : validations.purchasing_lastname.concat([required])}
                      />
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <div className="input-font-12">
                      <BPInput
                        label="Position"
                        name="purchasing_position"
                        value={this.state.purchasing_position}
                        handleTextInputChange={this.handleTextInputChange}
                        normalize={upper}
                        validate={!isRequiredPurchasingContact ? validations.purchasing_position : validations.purchasing_position.concat([required])}
                      />
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <div className="input-font-12">
                      <BPInput
                        label="Telephone"
                        name="purchasing_tel"
                        value={this.state.purchasing_tel}
                        handleTextInputChange={this.handleTextInputChange}
                        validate={!isRequiredPurchasingContact ? validations.purchasing_tel : validations.purchasing_tel.concat([required])}
                        normalize={normalizePhone}
                      />
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <div className="input-font-12">
                      <BPInput
                        label="Alt Telephone"
                        name="purchasing_alttel"
                        value={this.state.purchasing_alttel}
                        handleTextInputChange={this.handleTextInputChange}
                        validate={validations.purchasing_alttel}
                        normalize={normalizePhone}
                      />
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <div className="input-font-12">
                      <BPInput
                        label="Mobile"
                        name="purchasing_mobile"
                        value={this.state.purchasing_mobile}
                        handleTextInputChange={this.handleTextInputChange}
                        validate={validations.purchasing_mobile}
                        normalize={normalizePhone}
                      />
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <div className="input-font-12">
                      <BPInput
                        label="Fax"
                        name="purchasing_fax"
                        value={this.state.purchasing_fax}
                        handleTextInputChange={this.handleTextInputChange}
                        validate={validations.purchasing_fax}
                        normalize={normalizePhone}
                      />
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <div className="input-font-12">
                      <BPInput
                        label="E-mail"
                        name="purchasing_email"
                        value={this.state.purchasing_email}
                        handleTextInputChange={this.handleTextInputChange}
                        normalize={upper}
                        validate={!isRequiredPurchasingContact ? validations.purchasing_email : validations.purchasing_email.concat([required])}
                      />
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <div className="input-font-12">
                      {((!this.state.purchasing_firstname || !this.state.purchasing_lastname) && this.state.purchasing_name) &&
                      <div>
                        <div style={{fontSize:'10px',color:'#838383',marginTop:'10px',opacity:0}}>Name</div>
                        <div style={{color:'red',fontSize:'14px'}}>Previous Contact Name is {this.state.purchasing_name}</div>
                      </div>
                      }
                      {this.state.purchasing_name &&
                        <div style={{float:'right'}}>{this.renderSimilars('contacts', this.state.purchasing_name)}</div>
                      }
                    </div>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card></div></div>
          </GridItem> }

        {this.props.loadingBP &&
          <GridItem xs={12} sm={12} md={12}>
            <div style={{margin:'0 auto',textAlign:'center'}}>
              <div>
                <div>Loading BP</div>
                <PulseLoader />
              </div>
            </div>
          </GridItem>}


        {(dbAndType && this.state.company_name) && <Contacts
            classes={classes}
            addContact={this.addContact}
            removeContact={this.removeContact}
            handleContactTextInputChange={this.handleContactTextInputChange}
            normalize={upper}
            normalizePhone={normalizePhone}
            contactArr={this.state.additionalContacts}
            highlightContact={this.state.highlightContact}
            openAskModal={this.openAskModal}
            hideGMP={hasGMP}
          />}

        {(dbAndType && this.state.company_name) &&
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardBody>
                <BPTextArea
                  label="Notes"
                  name="notes"
                  value={this.state.notes ? this.state.notes : ''}
                  handleTextInputChange={this.handleTextInputChange}
                  normalize={whitelistNormalize}
                  validate={validations.notes}
                />
              </CardBody>
            </Card>
          </GridItem>
        }
      </GridContainer>
    </div>}

      {/* RBO/EDI */}
      { (hasGMP && this.state.openedTab === 'GMP') &&
    <div className="" style={{marginTop:0}}>
      <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
          <div className="no-top-margin" style={{marginTop:20}}>
            <Card>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <h4 style={{marginLeft:10}}><strong>RBO</strong></h4>
                    <Button onClick={this.openAddRBO} color="rose" size="sm" style={{marginLeft:20,marginBottom:14}}>ADD RBO</Button>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell className="text-center" style={{paddingLeft: 0, paddingRight: 0}}>
                            Name
                          </TableCell>
                          <TableCell className="text-center" style={{paddingLeft: 0, paddingRight: 0}}>
                            Status
                          </TableCell>
                          <TableCell className="text-center" style={{paddingLeft: 0, paddingRight: 0}}>
                            Action
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                      {rbos.length === 0 ? <TableRow>
                            <TableCell></TableCell>
                            <TableCell><em>- None -</em></TableCell>
                            <TableCell></TableCell>
                          </TableRow> : null}
                      {rbos.map((rbo,i) => {
                        const rbo_id = rbo.id ? rbo.id : 0,
                              gmp_id = rbo.gmp_id ? rbo.gmp_id : 0,
                              a_id = rbo.a_id ? rbo.a_id : 0;
                        const combo = `${rbo_id}_${rbo.rbo}_${gmp_id}_${a_id}`;
                        const value = this.state.rbosChecked[combo] ? true : false;
                        console.log(this.props.rbosDict[rbo.rbo], value, combo);
                        let status = 'Pending';
                        if (a_id && a_id != 0) {
                          status = 'Active';
                        } else if ((a_id == 0 && rbo_id == 0) && gmp_id == 0) {
                          status = 'New';
                        }
                        const rboIsRequired = requiredRBOs.indexOf(rbo.rbo) !== -1 ? true : false;
                        return <TableRow key={`rbo${i}`}>
                          <TableCell className="text-center" style={{paddingLeft: 0, paddingRight: 0}}>
                            {this.props.rbosDict[rbo.rbo]}
                          </TableCell>
                          <TableCell className="text-center" style={{paddingLeft: 0, paddingRight: 0}}>
                            <div className={status === 'Pending' ? 'blue-text' : ''}>{status}</div>
                          </TableCell>
                          <TableCell className="text-center" style={{paddingLeft: 0, paddingRight: 0}}>
                            {(status !== 'New' && !rboIsRequired) && <BPCheckbox
                              labelCentered={true}
                              label=''
                              name=''
                              value={value}
                              onChange={() => {
                                this.toggleCheck('rbosChecked', combo, value ? 0 : 1);
                              }}
                            />}
                            {(status === 'New' && !rboIsRequired) && <div className="hovered red-text" onClick={() => {
                              this.deleteRBO(combo);
                            }}>
                              <Delete />
                            </div>}
                            {rboIsRequired && <div style={{opacity:0.3,color:'white'}}><BPCheckbox
                              labelCentered={true}
                              label=''
                              name=''
                              value={true}
                              onChange={() => {}}
                            /></div>}
                          </TableCell>
                        </TableRow>
                      })}
                      </TableBody>
                    </Table>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
            </div>
          </GridItem>

          <GridItem xs={12} sm={12} md={12}>
            <div className="no-top-margin">
            <Card>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <h4 style={{marginLeft:10}}><strong>EDI / Vendor Code</strong></h4>
                    <Button onClick={this.openAddEDI} color="rose" size="sm" style={{marginLeft:20,marginBottom:14}}>ADD EDI</Button>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                  <div style={{width:'100%'}}>
                    {this.props.vendorCodeLoading && <div style={{textAlign:'center'}}>
                      Checking Vendor Codes
                      <PulseLoader /></div>}
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className="text-center" style={{paddingLeft: 0, paddingRight: 0}}>
                            Name
                          </TableCell>
                          <TableCell className="text-center" style={{paddingLeft: 0, paddingRight: 0}}>
                            Vendor Code
                          </TableCell>
                          <TableCell className="text-center" style={{paddingLeft: 0, paddingRight: 0}}>
                            Found in TPP
                          </TableCell>
                          <TableCell className="text-center" style={{paddingLeft: 0, paddingRight: 0}}>
                            BPs with Matching Vendor Codes
                          </TableCell>
                          <TableCell className="text-center" style={{paddingLeft: 0, paddingRight: 0}}>
                            Status
                          </TableCell>
                          <TableCell className="text-center" style={{paddingLeft: 0, paddingRight: 0}}>
                            Action
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                      {edis.length === 0 ? <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell><em>- None -</em></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                          </TableRow> : null}
                      {edis.map((edi,i) => {
                        if (edi.edi_program) {
                          const gmp_id = edi.gmp_id ? edi.gmp_id : 0,
                                a_id = edi.a_id ? edi.a_id : 0,
                                edi_id = edi.id ? edi.id : 0,
                                new_id = edi.new_id ? edi.new_id : '';
                          const combo = !new_id ? `${edi_id}_${edi.edi_program}_${gmp_id}_${a_id}` : `${edi_id}_${edi.edi_program}_${gmp_id}_${a_id}_${new_id}`;
                          const value = this.state.ediProgramsChecked[combo] ? true : false;
                          let status = 'Pending', bp_results = [];
                          if (a_id && a_id != 0) {
                            status = 'Active';
                          } else if ((a_id == 0 && edi_id == 0) && gmp_id == 0) {
                            status = 'New';
                          }
                          let existence = '', color = '#1c1c1c', magento_result = '', aa_result = '';
                          if (this.props.vendorCodesResults) {
                            const vcres = `${edi.edi_program}_${this.state.vendorCodes[combo]}`,
                                  vcress = this.props.vendorCodesResults[vcres];
                            console.log('vcv',vcress,this.props.vendorCodesResults, vcres);
                            if (vcress) {
                              magento_result = vcress.magento_results;
                              bp_results = vcress.aa_bps ? vcress.aa_bps.filter(bbp => {
                                if (bbp.BPCode === this.state.card_code && bbp.DBName === this.props.currentDB) {
                                  return false;
                                }
                                return true;
                              }) : [];
                              aa_result = bp_results.length;
                              if (aa_result !== 0) {
                                let sameBPfound = false;
                                if (bp_results && (this.state.card_code && this.props.currentDB)) {
                                  bp_results.forEach(bp => {
                                    if (bp.BPCode === this.state.card_code && bp.DBName === this.props.currentDB) {
                                      sameBPfound = true;
                                    }
                                  });
                                }
                                if (sameBPfound) {
                                  existence += 'Vendor Code already associated with this BP. ';
                                } else {
                                  existence += 'Vendor Code already associated with a BP. ';
                                }
                              }
                              if (magento_result !== 0) {
                                existence += 'TPP Orders Found. ';
                              }
                            }
                          }

                          return <TableRow key={`edi${i}`}>
                                  <TableCell className="text-center" style={{paddingLeft: 0, paddingRight: 0}}>
                                    {this.props.edi_programsDict[edi.edi_program.toUpperCase()]}
                                  </TableCell>
                                  <TableCell className="text-center" style={{paddingLeft: 0, paddingRight: 0}}>
                                    <div
                                      className="hovered hovered-blue"
                                      style={{paddingBottom:0,borderBottom:'1px dotted gray',display:'inline-block',color}}
                                      onClick={() => {
                                        this.openVendorCodeEdit(this.state.vendorCodes[combo], combo);
                                      }}
                                    >{this.state.vendorCodes[combo] && this.state.vendorCodes[combo] !== ' ' ? this.state.vendorCodes[combo] : <span className="red-text">- none -</span>}</div>
                                  </TableCell>
                                  <TableCell className="text-center" style={{paddingLeft: 0, paddingRight: 0}}>
                                    {magento_result === '' ? '' : magento_result == 0 ? 'No' : 'Yes'}
                                  </TableCell>
                                  <TableCell className="text-center" style={{paddingLeft: 0, paddingRight: 0}}>
                                    {aa_result || aa_result == 0 ? <div
                                      className="hovered hovered-blue"
                                      style={{paddingBottom:0,borderBottom:'1px dotted gray',display:'inline-block',color}}
                                      onClick={() => {
                                        this.openBPlist(bp_results, this.state.vendorCodes[combo]);
                                    }}>{aa_result}</div> : 0}
                                  </TableCell>
                                  <TableCell className="text-center" style={{paddingLeft: 0, paddingRight: 0}}>
                                    <div className={status === 'Pending' ? 'blue-text' : ''}>{status}</div>
                                  </TableCell>
                                  <TableCell className="text-center" style={{paddingLeft: 0, paddingRight: 0}}>
                                    {status !== 'New' && <BPCheckbox
                                      labelCentered={true}
                                      label=''
                                      name=''
                                      value={value}
                                      onChange={() => {
                                        this.toggleCheck('ediProgramsChecked', combo, value ? 0 : 1);
                                      }}
                                    />}
                                    {status === 'New' && <div className="hovered red-text" onClick={() => {
                                      this.deleteEDI(combo);
                                    }}><Delete /></div>}
                                  </TableCell>
                              </TableRow>
                        } else {
                          return null
                        }
                      })}
                      </TableBody>
                    </Table>
                    </div>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
            </div>
          </GridItem>
      </GridContainer>

      <GridContainer>
      {dbAndType && <GridItem xs={12} sm={12} md={6}>
      {this.state.accounts_payable_name.indexOf('GMP') !== -1 && <div className="special-contact-box" style={{display:this.state.company_name ? 'block':'none'}}>
        <Card>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                {this.state.accounts_payable_name.indexOf('GMP') !== -1 ? <h4><strong>TPP
                {this.state.accounts_payable_name.indexOf('GMP AP') !== -1 ? ' Accounts Payable ' : ''}
                {this.state.accounts_payable_name.indexOf('GMP PURCHASING') !== -1 ? ' Purchasing ' : ''}
                {this.state.accounts_payable_name.indexOf('GMP OTHER') !== -1 ? ' Other ' : ''}
                 Contact</strong>{this.state.accounts_payable_gmp ? <span className="blue-text"> (TPP User ID: # {this.state.accounts_payable_gmp})</span> : ''}</h4> : <h4><strong>Contact 1</strong></h4>}
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <div className="input-font-12">
                  <BPInput
                    label="First Name"
                    name="accounts_payable_firstname"
                    value={this.state.accounts_payable_firstname}
                    handleTextInputChange={this.handleTextInputChange}
                    normalize={upper}
                    validate={validations.accounts_payable_firstname}
                  />
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <div className="input-font-12">
                  <BPInput
                    label="Last Name"
                    name="accounts_payable_lastname"
                    value={this.state.accounts_payable_lastname}
                    handleTextInputChange={this.handleTextInputChange}
                    normalize={upper}
                    validate={validations.accounts_payable_lastname}
                  />
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <div className="input-font-12">
                  <BPInput
                    label="Position"
                    name="accounts_payable_position"
                    value={this.state.accounts_payable_position}
                    handleTextInputChange={this.handleTextInputChange}
                    normalize={upper}
                    validate={validations.accounts_payable_position}
                  />
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <div className="input-font-12">
                  <BPInput
                    label="Telephone"
                    name="accounts_payable_tel"
                    value={this.state.accounts_payable_tel}
                    handleTextInputChange={this.handleTextInputChange}
                    normalize={normalizePhone}
                    validate={validations.accounts_payable_tel}
                  />
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <div className="input-font-12">
                  <BPInput
                    label="Alt Telephone"
                    name="accounts_payable_alttel"
                    value={this.state.accounts_payable_alttel}
                    handleTextInputChange={this.handleTextInputChange}
                    normalize={normalizePhone}
                    validate={validations.accounts_payable_alttel}
                  />
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <div className="input-font-12">
                  <BPInput
                    label="Mobile"
                    name="accounts_payable_mobile"
                    value={this.state.accounts_payable_mobile}
                    handleTextInputChange={this.handleTextInputChange}
                    normalize={normalizePhone}
                    validate={validations.accounts_payable_mobile}
                  />
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <div className="input-font-12">
                  <BPInput
                    label="Fax"
                    name="accounts_payable_fax"
                    value={this.state.accounts_payable_fax}
                    handleTextInputChange={this.handleTextInputChange}
                    normalize={normalizePhone}
                    validate={validations.accounts_payable_fax}
                  />
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <div className="input-font-12">
                  <BPInput
                    label="E-mail"
                    name="accounts_payable_email"
                    value={this.state.accounts_payable_email}
                    handleTextInputChange={this.handleTextInputChange}
                    normalize={upper}
                    validate={validations.accounts_payable_email}
                    readonly={this.state.accounts_payable_name.indexOf('GMP') !== -1 ? true : false}
                  />
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <div className="input-font-12">
                  <div style={{fontSize:'10px',color:'#838383',marginTop:'10px',opacity:0}}>Name</div>
                  {((!this.state.accounts_payable_firstname || !this.state.accounts_payable_lastname) && this.state.accounts_payable_name) &&
                  <div>
                    <div style={{color:'red',fontSize:'14px'}}>Previous Contact Name is {this.state.accounts_payable_name}</div>
                  </div>
                  }
                  {this.state.accounts_payable_name &&
                    <div style={{float:'right'}}>{this.renderSimilars('contacts', this.state.accounts_payable_name)}</div>
                  }
                </div>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card></div>}
      </GridItem> }

      {dbAndType && <GridItem xs={12} sm={12} md={6}>
      <div className={this.state.highlightContact === 'purchasing' ? 'highlighted' : ''}>
      {this.state.purchasing_name.indexOf('GMP') !== -1 && <div className="special-contact-box" style={{display:this.state.company_name ? 'block':'none'}}>
        <Card>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
              {this.state.purchasing_name.indexOf('GMP') !== -1 ? <h4><strong>TPP
              {this.state.purchasing_name.indexOf('GMP AP') !== -1 ? ' Accounts Payable ' : ''}
              {this.state.purchasing_name.indexOf('GMP PURCHASING') !== -1 ? ' Purchasing ' : ''}
              {this.state.purchasing_name.indexOf('GMP OTHER') !== -1 ? ' Other ' : ''}
               Contact {this.state.purchasing_highlight ? <span className="green-box">NEW</span> : ''}</strong>{this.state.purchasing_gmp ? <span className="blue-text"> (TPP User ID: # {this.state.purchasing_gmp})</span> : ''}</h4> : <h4><strong>Contact 2 {this.state.purchasing_highlight ? <span className="green-box">NEW</span> : ''}</strong></h4>}
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <div className="input-font-12">
                  <BPInput
                    label="First Name"
                    name="purchasing_firstname"
                    value={this.state.purchasing_firstname}
                    handleTextInputChange={this.handleTextInputChange}
                    normalize={upper}
                    validate={!isRequiredPurchasingContact ? validations.purchasing_firstname : validations.purchasing_firstname.concat([required])}
                  />
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <div className="input-font-12">
                  <BPInput
                    label="Last Name"
                    name="purchasing_lastname"
                    value={this.state.purchasing_lastname}
                    handleTextInputChange={this.handleTextInputChange}
                    normalize={upper}
                    validate={!isRequiredPurchasingContact ? validations.purchasing_lastname : validations.purchasing_lastname.concat([required])}
                  />
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <div className="input-font-12">
                  <BPInput
                    label="Position"
                    name="purchasing_position"
                    value={this.state.purchasing_position}
                    handleTextInputChange={this.handleTextInputChange}
                    normalize={upper}
                    validate={!isRequiredPurchasingContact ? validations.purchasing_position : validations.purchasing_position.concat([required])}
                  />
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <div className="input-font-12">
                  <BPInput
                    label="Telephone"
                    name="purchasing_tel"
                    value={this.state.purchasing_tel}
                    handleTextInputChange={this.handleTextInputChange}
                    validate={!isRequiredPurchasingContact ? validations.purchasing_tel : validations.purchasing_tel.concat([required])}
                    normalize={normalizePhone}
                  />
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <div className="input-font-12">
                  <BPInput
                    label="Alt Telephone"
                    name="purchasing_alttel"
                    value={this.state.purchasing_alttel}
                    handleTextInputChange={this.handleTextInputChange}
                    validate={validations.purchasing_alttel}
                    normalize={normalizePhone}
                  />
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <div className="input-font-12">
                  <BPInput
                    label="Mobile"
                    name="purchasing_mobile"
                    value={this.state.purchasing_mobile}
                    handleTextInputChange={this.handleTextInputChange}
                    validate={validations.purchasing_mobile}
                    normalize={normalizePhone}
                  />
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <div className="input-font-12">
                  <BPInput
                    label="Fax"
                    name="purchasing_fax"
                    value={this.state.purchasing_fax}
                    handleTextInputChange={this.handleTextInputChange}
                    validate={validations.purchasing_fax}
                    normalize={normalizePhone}
                  />
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <div className="input-font-12">
                  <BPInput
                    label="E-mail"
                    name="purchasing_email"
                    value={this.state.purchasing_email}
                    handleTextInputChange={this.handleTextInputChange}
                    normalize={upper}
                    validate={!isRequiredPurchasingContact ? validations.purchasing_email : validations.purchasing_email.concat([required])}
                    readonly={this.state.purchasing_name.indexOf('GMP') !== -1 ? true : false}
                  />
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <div className="input-font-12">
                  {((!this.state.purchasing_firstname || !this.state.purchasing_lastname) && this.state.purchasing_name) &&
                  <div>
                    <div style={{fontSize:'10px',color:'#838383',marginTop:'10px',opacity:0}}>Name</div>
                    <div style={{color:'red',fontSize:'14px'}}>Previous Contact Name is {this.state.purchasing_name}</div>
                  </div>
                  }
                  {this.state.purchasing_name &&
                    <div style={{float:'right'}}>{this.renderSimilars('contacts', this.state.purchasing_name)}</div>
                  }
                </div>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card></div>}</div>
      </GridItem> }

      {/* SECOND CONTACTS */}
      {(dbAndType && this.state.company_name) && <Contacts
          classes={classes}
          addContact={this.addContact}
          removeContact={this.removeContact}
          handleContactTextInputChange={this.handleContactTextInputChange}
          normalize={upper}
          normalizePhone={normalizePhone}
          contactArr={this.state.additionalContacts}
          highlightContact={this.state.highlightContact}
          openAskModal={this.openAskModal}
          onlyGMP={true}
        />}

      </GridContainer>
    </div>}

      <GridContainer>
        {(dbAndType && this.state.company_name) &&
        <GridItem xs={12} sm={12} md={12}>
          {
            this.props.cancelProfileView &&
            <Button
              color="rose"
              onClick={() => {
                this.props.cancelProfileView();
                this.props.releaseUserLockedBPs();
              }}
              style={{marginRight:'10px'}}
            >
            BACK TO LIST
            </Button>
          }
          {
            this.props.cancelFormView &&
            <Button
              color="rose"
              onClick={() => {
                this.props.cancelFormView();
              }}
              style={{marginRight:'10px'}}
            >
            CHANGE BP
            </Button>
          }
          <Button
            color="success"
            onClick={this.handleSubmitReview}
          >
          REVIEW
          </Button>
          {this.props.handleDenyNewGMP &&
            <Button
              style={{marginLeft:'13px'}}
              color="danger"
              onClick={() => {
                this.openAskModal(`denyNewGMP,${this.state.id}`);
              }}
            >
            DENY
            </Button>
          }
          {(this.props.BPaction !== 'NEW') &&
            <Button
              style={{marginLeft:'13px'}}
              color="default"
              onClick={() => {
                this.openUnlockModal();
              }}
            >
            EXIT BP
            </Button>
          }
        </GridItem>
        }
      </GridContainer>
    </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loadingBP: state.common.loadingBP,
    acctnames: state.common.acctnames,
    acctnamesObj: state.common.acctnamesObj,
    acctnamesDictionary: state.common.acctnamesDictionary,
    acctnamesObjDictionary: state.common.acctnamesObjDictionary,
    dbs: state.common.dbs,
    currencies: state.common.currencies,
    states: state.common.states,
    statesDictionary: state.common.statesDictionary,
    countries: state.common.countries,
    countriesDictionary: state.common.countriesDictionary,
    payterms: state.common.payterms,
    paytermsDictionary: state.common.paytermsDictionary,
    defaultPBP: state.common.defaultPBP,
    defaultCreditLimit: state.common.defaultCreditLimit,
    defaultSubgroup: state.common.defaultSubgroup,
    gsttypes: state.gmp.gsttypes,
    gsttypesDictionary: state.gmp.gsttypesDictionary,
    maincurr: state.common.maincurr,
    groupcodes: state.common.groupcodes,
    groupcodes_supplier: state.common.groupcodes ? state.common.groupcodes.filter(gc => gc.GroupType === 'S') : [],
    groupcodes_customer: state.common.groupcodes ? state.common.groupcodes.filter(gc => gc.GroupType === 'C') : [],
    groupcodesDictionary: state.common.groupcodesDictionary,
    owhtopts: state.common.addinfo ?
                state.common.addinfo.owhtopts ? state.common.addinfo.owhtopts : null
                : null,
    owhtoptsDictionary: state.common.owhtoptsDictionary,
    existingBPfoundMsg: state.common.existingBPfoundMsg,
    similars: state.gmp.similars,
    isPending: state.common.isPending,
    loadingResaleCert: state.common.loading,
    sendFileStatus: state.common.sendFileStatus,
    rbos: state.gmp.rbos,
    rbosDict: state.gmp.rbosDict,
    edi_programs: state.gmp.edi_programs,
    edi_programsDict: state.gmp.edi_programsDict,
    vendorCodesResults: state.gmp.vendorCodesResults,
    vendorCodeLoading: state.gmp.vendorCodeLoading
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAllBPs: (bp_type, db) => {
      dispatch(actions.fetchAllBP(bp_type, db));
    },
    getSimilars: (data) => {
      dispatch(actions.fetchGMPSimilars(data));
    },
    clearSimilars: () => {
      dispatch(actions.clearSimilars());
    },
    checkPendingBP: (bpname, bp_type, db) => {
      dispatch(actions.checkPendingBP(bpname, bp_type, db));
    },
    resetCheckPendingBP: () => {
      dispatch(actions.resetCheckPendingBP());
    },
    sendResaleCertFileSAP: (file) => {
      dispatch(actions.sendResaleCertFileSAP(file));
    },
    sendResaleCertFile: (file, filename) => {
      dispatch(actions.sendResaleCertFile(file, filename));
    },
    fetchGMPRBO: (db) => {
      dispatch(actions.fetchGMPRBO(db));
    },
    fetchEDIPrograms: () => {
      console.log('fetching EDI programs');
      dispatch(actions.fetchEDIPrograms());
    },
    checkVendorCodes: (codesArr, edis) => {
      dispatch(actions.checkVendorCodes(codesArr, edis));
    },
    unlockBP: (db, card_code, bpname) => {
      dispatch(actions.updateBPLock(db, card_code, bpname, 'UNLOCK'));
    },
    convertNewToExisting: (id,db) => {
      dispatch(actions.convertNewToExisting(id,db));
    },
    releaseUserLockedBPs: () => {
      dispatch(actions.releaseUserLockedBPs());
    }
  }
}

const combinedStyles = combineStyles(sweetAlertStyle, userProfileStyles);
export default connect(mapStateToProps,mapDispatchToProps)(withStyles(combinedStyles)(BPForm));
