import { put, call } from 'redux-saga/effects';
import axios from '../axios/axios-sap_monitor';
import * as actions from '../actions/SAPMonitor';
import devStatus from 'shared/devStatus';
import { clone } from '../../shared/utility';
import qs from 'qs';
import sapMonitorData from 'containers/SAPMonitor/SAPMonitorLogic';
const { paymentMethods } = sapMonitorData;
const paymentMethodsDict = {};
Object.keys(paymentMethods).forEach(pm => {
    paymentMethods[pm].forEach(field => {
        paymentMethodsDict[field] = pm;
    })
})

export function* getSmMagentoOrdersSaga(action) {
    yield put(actions.getSmMagentoOrdersStart());
    try {
        console.log('getting sm magento orders', action);
        if (action.data) {
            if (action.data.id) {
                const filtersQuery = qs.stringify(action.data);
                console.log('filtersQuery', filtersQuery);
                const response = yield axios.get('/sm_magento_orders?query'+filtersQuery);
                yield put(actions.getSmMagentoOrdersSuccess(response.data));
                return;
            }
            let filtersQueryArr = [];
            Object.keys(action.data).forEach(field => {
                if (action.data[field]) {
                    filtersQueryArr.push(`${field}=${action.data[field]}`);
                }
            })
            const filtersQuery = filtersQueryArr.join('&');
            console.log('filtersQuery', filtersQuery);
            const response = yield axios.get('/sm_magento_orders?query&'+filtersQuery);
            console.log('response', response.data);
            let finalData = response.data;
            if (filtersQueryArr.indexOf('payment_method') !== -1) {
                // filter by payment method 
                finalData = finalData.filter(fd => {
                    if (fd.payment) {
                        if (fd.payment.method) {
                            if (paymentMethodsDict[fd.payment.method] === action.data.payment_method) {
                                return true;
                            }
                        }
                    }
                    return false;
                })
            }
            yield put(actions.getSmMagentoOrdersSuccess(response.data));
        }
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.getSmMagentoOrdersFail(e.response.data));
            }
          }
        yield put(actions.getSmMagentoOrdersFail(e));
    }
}

export function* getSmMagentoOrdersOthersSaga(action) {
    yield put(actions.getSmMagentoOrdersOthersStart());
    try {
        const dbname = action.data.dbname;
        const card_code = action.data.card_code;
        const response = yield axios.get('/sm_magento_orders_others?card_code='+card_code+'&db='+dbname);
        yield put(actions.getSmMagentoOrdersOthersSuccess(response.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.getSmMagentoOrdersOthersFail(e.response.data));
            }
          }
        yield put(actions.getSmMagentoOrdersOthersFail(e));
    }
}

export function* getSmMagentoCustomersSaga(action) {
    yield put(actions.getSmMagentoCustomersStart());
    try {
        const response = yield axios.get('/sm_magento_customers?id='+action.data);
        yield put(actions.getSmMagentoCustomersSuccess(response.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.getSmMagentoCustomersFail(e.response.data));
            }
          }
        yield put(actions.getSmMagentoCustomersFail(e));
    }
}

export function* getBpByNameSaga(action) {
    yield put(actions.getBpByNameStart());
    try {
        const search_term = action.data.search_term;
        const db = action.data.db;
        const response = yield axios.get(`/bp_search_results?search_term=${search_term}&db=${db}`);
        console.log('response', response.data);
        yield put(actions.getBpByNameSuccess(response.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.getBpByNameFail(e.response.data));
            }
          }
        yield put(actions.getBpByNameFail(e));
    }
}

export function* getSapOrderDetailsSaga(action) {
    yield put(actions.getSapOrderDetailsStart());
    try {
        const magento_order_number = action.data.magento_order_number;
        const dbname = action.data.dbname;
        const response = yield axios.get('/sap_order_details?magento_order_number='+magento_order_number+'&dbname='+dbname);
        yield put(actions.getSapOrderDetailsSuccess(response.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.getSapOrderDetailsFail(e.response.data));
            }
          }
        yield put(actions.getSapOrderDetailsFail(e));
    }
}

export function* getSapBpDetailsSaga(action) {
    yield put(actions.getSapBpDetailsStart());
    try {
        const dbname = action.data.dbname;
        const card_code = action.data.card_code;
        const response = yield axios.get('/sap_bp_details?card_code='+card_code+'&db='+dbname);
        yield put(actions.getSapBpDetailsSuccess(response.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.getSapBpDetailsFail(e.response.data));
            }
          }
        yield put(actions.getSapBpDetailsFail(e));
    }
}

export function* getSapBpaDetailsSaga(action) {
    yield put(actions.getSapBpaDetailsStart());
    try {
        const dbname = action.data.dbname;
        const card_code = action.data.card_code;
        const response = yield axios.get('/sap_bpa_details?card_code='+card_code+'&db='+dbname);
        yield put(actions.getSapBpaDetailsSuccess(response.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.getSapBpaDetailsFail(e.response.data));
            }
          }
        yield put(actions.getSapBpaDetailsFail(e));
    }
}

export function* getPiListFilterSaga(action) {
    yield put(actions.getPiListFilterStart());
    try {
        const dbname = action.data.dbname;
        const pi = action.data.pi;
        const response = yield axios.get('/pi_list_filter?pi='+pi+'&db='+dbname);
        yield put(actions.getPiListFilterSuccess(response.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.getPiListFilterFail(e.response.data));
            }
          }
        yield put(actions.getPiListFilterFail(e));
    }
}

export function* getSapBpaItemDetailsSaga(action) {
    yield put(actions.getSapBpaItemDetailsStart());
    try {
        const items = {} 
        console.log('data', action.data);
        for (let i = 0; i < action.data.length; i++) {
            const data = action.data[i];
            const item_code = data.item_code;
            const dbname = data.dbname;
            const response = yield axios.get('/sap_bpa_item_details?item_code='+item_code+'&db='+dbname);
            if (response.data) {
                items[item_code] = response.data;
            }
        }
        yield put(actions.getSapBpaItemDetailsSuccess(items));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.getSapBpaItemDetailsFail(e.response.data));
            }
          }
        yield put(actions.getSapBpaItemDetailsFail(e));
    }
}

export function* getMagentoBpDetailsSaga(action) {
    yield put(actions.getMagentoBpDetailsStart());
    try {
        const id = action.data.id;
        const response = yield axios.get(`/magento_bp_details?id=${id}`);
        yield put(actions.getMagentoBpDetailsSuccess(response.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.getMagentoBpDetailsFail(e.response.data));
            }
          }
        yield put(actions.getMagentoBpDetailsFail(e));
    }
}