import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import ExportUPC from './ExportUPC';

const RFIDEPCModal = props => {
  return (
    <Dialog
      open={props.open}
      keepMounted
      onClose={props.close}
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle>
        <div style={{textAlign:'center'}}>EPC CODE FILTER</div>
        <div className="close-btn">
          <IconButton aria-label="Close" onClick={props.close}>
            <Close />
          </IconButton>
        </div>
      </DialogTitle>
      <div style={{padding:'0 40px 40px 40px'}}>
        <ExportUPC
          isModal={true}
          chooseEPCfilter={props.chooseEPCfilter}
          close={props.close}
          epcFilter={props.epcFilter}
          upcFilter={props.upcFilter}
          serialFilter={props.serialFilter}
        />
      </div>
    </Dialog>
  );
}

export default RFIDEPCModal
