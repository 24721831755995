import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import * as actions from '../../store/actions';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import Button2 from "components/CustomButtons/Button.jsx";
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Typography from '@material-ui/core/Typography';
import Settings from "@material-ui/icons/Settings";
import Datetime from 'react-datetime';
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";
import combineStyles from "shared/combineStyles";
import moment from "moment";

const dialogStyles = {
  paper: {
    overflowX: 'hidden'
  }
};

const DialogTitle = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500],
  },
}))(props => {
  const { children, classes, onClose, title } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <span style={{fontSize:'18px'}}><strong>{title}</strong></span>

      <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit,
  },
}))(MuiDialogActions);

class RFIDdateModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: '',
      endDate: '',
      start: null,
      end: null,
      error: null
    }
  }

  componentDidMount() {
    this.setState({
      startDate: this.props.historyStartDate,
      endDate: this.props.historyEndDate,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.startDate !== this.state.startDate || prevState.endDate !== this.state.endDate) {
      console.log(this.state, prevState);
    }
  }

  openDatepicker = () => {
    console.log(this);
    if (this._calendar) {
      this._calendar.autofocus = true;
    }
  };

  compareDateTimes = (start, end) => {
    const startDate = moment(start, 'MM-DD-YYYY hh:mm a').valueOf();
    const endDate = moment(end, 'MM-DD-YYYY hh:mm a').valueOf();
    console.log('comparing date and time', startDate - endDate);
    if (endDate < startDate || endDate === startDate) {
      this.setState({
        error: 'Start Date/Time cannot be after or equal to End Date/Time'
      });
      return false;
    }
    return true;
  }

  render() {
    const { bp, classes } = this.props;
    const title = 'Date Filter';
    let content = (
    <GridContainer style={{width:'100%'}}>
      <GridItem xs={12} sm={12} md={6}>
        <div style={{width:'150px',margin:'0 auto'}}>
          <Datetime
            closeOnSelect={true}
            timeFormat={true}
            ref={(c) => this._calendars = c}
            inputProps={{
              placeholder: 'Start Date',
              ref: (c) => this._calendar = c
            }}
            value={moment(this.state.startDate)}
            onChange={param => {
              console.log('changed');
              this.setState({
                error: null
              });
              param = param ? param : '';
              if (typeof param !== 'string') {
                const isValid = param.isValid();
                if (isValid) {
                  this.setState({
                    startDate: param.format('MM/DD/YYYY hh:mm a'),
                    start: param.toDate().getTime(),
                    error: null
                  });
                  return;
                } else {
                  console.log(param, '?');
                  if (param) {
                    this.setState({
                      error: 'Invalid Start Date and/or Time'
                    });
                  } else {
                    this.setState({
                      error: null
                    });
                  }
                }
              }
              if (param) {
                this.setState({
                  error: 'Invalid Start Date and/or Time'
                });
              } else {
                this.setState({
                  error: null
                });
              }
            }}
            name="start_date"
          />
        </div>
      </GridItem>
      <GridItem xs={12} sm={12} md={6}>
        <div style={{width:'150px',margin:'0 auto'}}>
          <Datetime
            closeOnSelect={true}
            timeFormat={true}
            inputProps={{
              placeholder: 'End Date'
            }}
            value={moment(this.state.endDate)}
            onChange={param => {
              this.setState({
                error: null
              });
              param = param ? param : '';
              if (typeof param !== 'string') {
                const isValid = param.isValid();
                if (isValid) {
                  this.setState({
                    endDate: param.format('MM/DD/YYYY hh:mm a'),
                    end: param.endOf('day').toDate().getTime(),
                    error: null
                  });
                  return;
                } else {
                  if (param) {
                    this.setState({
                      error: 'Invalid End Date and/or Time'
                    });
                  } else {
                    this.setState({
                      error: null
                    });
                  }
                }
              }
              if (param) {
                this.setState({
                  error: 'Invalid End Date and/or Time'
                });
              } else {
                this.setState({
                  error: null
                });
              }
            }}
            name="end_date"
            className="adjustEndDateFilter"
          />
      </div>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <div className="gray-text" style={{marginTop:'50px',textAlign:'center'}}>
        <Button2
          color="info"
          onClick={() => {
            this.setState({
              startDate: '',
              endDate: '',
              start: null,
              end: null
            });
            this.props.chooseDates(null, null);
            this.props.close();
          }}
        >
          CLEAR FILTER
        </Button2>
          <Button2
            color="success"
            onClick={() => {
              if (this.state.startDate || this.state.endDate) {
                let pass = true;
                if (this.state.startDate && this.state.endDate) {
                  pass = this.compareDateTimes(this.state.startDate, this.state.endDate);
                }
                if (pass) {
                  console.log('stt date', this.state.start, this.state.end);
                  this.props.chooseDates(this.state.start, this.state.end);
                  this.props.close();
                }
              }
            }}
          >
            START FILTER
          </Button2><br />
          {
            this.state.error ?
            <div style={{marginTop:'40px'}}>
              <SnackbarContent
                message={this.state.error}
                close={this.state.error ? true : false}
                color="danger"
                closeMe={() => {
                  this.setState({
                    error: null
                  });
                }}
              />
            </div> :
            <div>
              <br />
              <em>* Click to select date from Calendar</em>
            </div>
          }
        </div>
      </GridItem>
    </GridContainer>);

    return (
      <div className="dialog-overflow">
        <Dialog
          onClose={() => {
            this.props.close();
          }}
          aria-labelledby="customized-dialog-title"
          open={this.props.open}
          fullWidth={false}
          maxWidth="sm"
          className={classes.paper}
        >
          <DialogTitle
            id="customized-dialog-title"
            title={title}
            onClose={() => {
              this.props.close();
            }}
          >
          </DialogTitle>
              <div style={{padding:'40px 20px 40px 40px',height:'430px'}}>
                  {content}
              </div>
        </Dialog>
      </div>
    );
  }
}

const combinedStyle = combineStyles(dialogStyles, userProfileStyles);
export default connect(null, null)(withStyles(combinedStyle)(RFIDdateModal));
