import React, { Component } from "react";
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { PulseLoader } from 'react-spinners';
import * as actions from 'store/actions';
import withStyles from "@material-ui/core/styles/withStyles";
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import combineStyles from "shared/combineStyles";
import moment from "moment";
// @material-ui/icons
import Settings from "@material-ui/icons/Settings";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import Table from "components/Table/Table.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Clearfix from "components/Clearfix/Clearfix.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';

import { eraseGMPAlertMsg } from "store/actions/GMP";
import { normalizePhone, commatize } from "shared/utility";
import helpers from "./BPhelpers";

const getName = (arr, id, type) => {
  let name = '';
  arr.forEach(a => {
    if (id == a.id) {
      name = a[type];
    }
  })
  return name;
}

const compareAddressName = (a,b) => {
  if (a.shipname < b.shipname)
    return -1;
  if (a.shipname > b.shipname)
    return 1;
  return 0;
}

const compareContactName = (a,b) => {
  if (a.name < b.name)
    return -1;
  if (a.name > b.name)
    return 1;
  return 0;
}

const CustomField = (props) => {
  return (
    <div style={{marginBottom:'10px'}}>
      <div style={{color:'#aaa',fontSize:'10px',fontWeight:'400'}}>{props.field}:</div>
      <div>{props.value}</div>
    </div>
  )
}

class BPFormReview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      justLoaded: true,
    }
  }

  componentDidMount() {
    if (this.props.rbosDict) {
      if (Object.keys(this.props.rbosDict).length == 0) {
        if (this.props.currentDB) {
          this.props.fetchGMPRBO(this.props.currentDB);
        }
      }
    }
    if (this.props.edi_programsDict) {
      if (Object.keys(this.props.edi_programsDict).length == 0) {
        this.props.fetchEDIPrograms();
      }
    }
    this.setState({
      justLoaded: false
    });
  }

  hideAlert = () => {
    this.props.eraseGMPAlertMsg();
    this.props.cancelReview();
  }

  hideAlertReturn = () => {
    this.props.eraseGMPAlertMsg();
    this.props.cancelProfileView();
  }

  renderDynamicAddress = (arrayB, arrayF) => {
    if (arrayB && arrayF) {
      let arrayBefore = [ ...arrayB ];
      let arrayFinal = [ ...arrayF ];
      // sort arrayBefore and arrayAfter by 1. Address name, or 2. Contact name
      let before = '';
      let final = '';
      if (arrayBefore) {
        if (arrayBefore.length > 0) {
          arrayBefore.sort(compareAddressName);
        }
      }

      if (arrayFinal) {
        if (arrayFinal.length > 0) {
          arrayFinal.sort(compareAddressName);
        }

        return arrayFinal.map((add, i) => {
          let similarIndex = -1;
          arrayBefore.forEach((beforeAdd, j) => {
            if (beforeAdd.shipname === add.shipname) {
              similarIndex = j;
            }
          });
          let shipEdited = {
            shipname: '',
            add1: '',
            add2: '',
            country: '',
            state: '',
            city: '',
            zipcode: '',
            gstregno: '',
            gstregtype: ''
          }
          if (similarIndex !== -1) {
            const bef = arrayBefore[similarIndex];
            Object.keys(shipEdited).forEach((field) => {
              if (this.props.BPaction !== 'NEW') {
                shipEdited[field] = add[field] !== bef[field] ? bef[field] : '';
              }
            });
          }

          return (
            <div key={`shadd-${i}`}>
              <hr />
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <h4><strong>Shipping Address {i+2}</strong></h4>
                  {this.renderDefaultShippingAddress(add.shipname)}
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomField
                    value={add.shipname}
                    addedText={shipEdited.shipname}
                    field="Name"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomField
                    value={add.add1}
                    addedText={shipEdited.add1}
                    field="Address 1"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomField
                    value={add.add2}
                    addedText={shipEdited.add2}
                    field="Address 2"
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={3}>
                  <CustomField
                    value={this.props.countries[add.country] ? this.props.countries[add.country] : ''}
                    addedText={shipEdited.country}
                    field="Country"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomField
                    value={this.props.states[add.country] ?
                              this.props.states[add.country][add.state] ? this.props.states[add.country][add.state] : ''
                            : ''}
                    addedText={shipEdited.state}
                    field="State"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomField
                    value={add.city}
                    addedText={shipEdited.city}
                    field="City"
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomField
                    value={add.zipcode}
                    addedText={shipEdited.zipcode}
                    field="Postal Code"
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
                  {
                    add.gstregno &&
                    <CustomField
                      value={add.gstregno}
                      addedText={shipEdited.gstregno}
                      field="GST Reg.No."
                    />
                  }
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  {
                    add.gstregtype &&
                    <CustomField
                      value={this.props.gsttypes[add.gstregtype] ? this.props.gsttypes[add.gstregtype] : ''}
                      addedText={shipEdited.gstregtype}
                      field="GST Reg. Type"
                    />
                  }
                </GridItem>
              </GridContainer>
            </div>
            )
          });
      }
    }

}

showID = (id) => {
  return id ? ` - ID# ${id}` : '';
}

renderDynamicContacts = (arrayB, arrayF) => {
  // sort arrayBefore and arrayAfter by 1. Address name, or 2. Contact name
  if (arrayB && arrayF) {
    let arrayBefore = [ ...arrayB ];
    let arrayFinal = [ ...arrayF ];
    let before = '';
    let final = '';
    if (arrayBefore.length > 0) {
      arrayBefore.sort(compareContactName);
    }

    if (arrayFinal.length > 0) {
      arrayFinal.sort(compareContactName);
    }

    return arrayFinal.map((cont, i) => {
      let similarIndex = -1;
      arrayBefore.forEach((beforeCont, j) => {
        if (beforeCont.name === cont.name) {
          similarIndex = j;
        }
      });
      let contEdited = {
        firstname: '',
        lastname: '',
        name:'',
        position:'',
        tel:'',
        alttel: '',
        mobile: '',
        fax: '',
        email:''
      }
      if (similarIndex !== -1) {
        const bef = arrayBefore[similarIndex];
        Object.keys(contEdited).forEach((co) => {
          if (this.props.BPaction !== 'NEW') {
            contEdited[co] = cont[co] === bef[co] ? bef[co] : '';
          }
        });
      }

      let contactHeader = `Contact ${i+3}`;
      if (cont.name.indexOf('GMP AP') !== -1) { contactHeader = 'TPP Accounts Payable Contact' }
      if (cont.name.indexOf('GMP PURCHASING') !== -1) { contactHeader = 'TPP Purchasing Contact' }
      if (cont.name.indexOf('GMP OTHER') !== -1) { contactHeader = 'TPP Other Contact' }

      return (
    <GridContainer key={`cconc-${i}`}>
      <GridItem xs={12} sm={12} md={12}>
        <h4><strong>{contactHeader} {this.showID(cont.gmp)}</strong></h4>
      </GridItem>
      <GridItem xs={12} sm={12} md={4}>
        <CustomField
          value={cont.firstname}
          addedText={contEdited.name}
          field="First Name"
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={2}>
        <CustomField
          value={cont.position}
          addedText={contEdited.position}
          field="Position"
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={2}>
        <CustomField
          value={cont.tel}
          addedText={contEdited.tel}
          field="Telephone"
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={2}>
        <CustomField
          value={cont.alttel}
          addedText={contEdited.alttel}
          field="Alt Telephone"
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={2}>
        <CustomField
          value={cont.mobile}
          addedText={contEdited.mobile}
          field="Mobile"
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={4}>
        <CustomField
          value={cont.lastname}
          addedText={contEdited.name}
          field="Last Name"
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={2}>
        <CustomField
          value={cont.fax}
          addedText={contEdited.fax}
          field="Fax"
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={6}>
        <CustomField
          value={cont.email}
          addedText={contEdited.email}
          field="E-mail"
        />
      </GridItem>
      {(!cont.firstname && !cont.lastname) &&
        <GridItem xs={12} sm={12} md={12}>
          <CustomField
            value={cont.name}
            addedText={contEdited.name}
            field="Name"
          />
        </GridItem>
      }
    </GridContainer>
    )
  });
  }

}

  renderLabel = (field, value, fieldName) => {
      let addedText = '',
          changes = this.props.changes,
          bpSource = this.props.finalValues;
      if (fieldName && !bpSource) {
        const initialValue = this.props.beforeValues[fieldName];
        if (initialValue !== value) {
          addedText = 'edited';
        }

        if (initialValue === null && value === 'n/a') {
          addedText = '';
        }

        if (initialValue === undefined && value === 'n/a') {
          addedText = '';
        }
      }

      if (bpSource) {
        const initialValue = this.props.beforeValues[fieldName];
        if (initialValue !== bpSource[fieldName]) {
          addedText = 'edited';
        }
      }

      if (changes) {
        if (changes.after) {
          if (changes.after.hasOwnProperty(fieldName)) {
            addedText = 'edited';
          }
        }
      }

      // if (this.props.BPaction === 'NEW') {
        addedText = '';
      // }

      return (
        <div style={{marginBottom:'10px'}}>
          <div style={{color:'#aaa',fontSize:'10px',fontWeight:'400'}}>{field}:</div>
          <div>{value} {addedText ? <span className="blue-btn">{addedText}</span> : ''}</div>
        </div>
      );
  }

  renderPhoneLabel = (field, value, fieldName) => {
      let addedText = '',
          bpSource = this.props.finalValues;
      if (fieldName && !bpSource) {
        const initialValue = this.props.beforeValues[fieldName];
        if (initialValue !== value) {
          addedText = 'edited';
        }

        if (initialValue === null && value === 'n/a') {
          addedText = '';
        }

        if (initialValue === undefined && value === 'n/a') {
          addedText = '';
        }
      }

      if (bpSource) {
        const initialValue = this.props.beforeValues[fieldName];
        if (initialValue !== bpSource[fieldName]) {
          addedText = 'edited';
        }
      }

      //if (this.props.BPaction === 'NEW') {
        addedText = '';
      //}
      return (
        <div style={{marginBottom:'10px'}}>
          <div style={{color:'#aaa',fontSize:'10px',fontWeight:'400'}}>{field}:</div>
          <div>{normalizePhone(value)} {addedText ? <span className="blue-btn">{addedText}</span> : ''}</div>
        </div>
      );
  }

  renderWTaxCodes = () => {
    console.log(this.props.owhtopts, this.props.finalValues.wtcodes);
    if (this.props.finalValues && this.props.owhtopts) {
      if (this.props.finalValues.wtcodes) {
        const owhtopts = this.props.owhtopts;
        const wtcodes = this.props.finalValues.wtcodes;
        if (wtcodes.constructor === Array) {
          return wtcodes.map((wt,i) => {
            return <div key={`wtt-${i}`}>{owhtopts[wt]}</div>
          });
        } else {
          return wtcodes.split(',').map((wt,i) => {
            return <div key={`wtt-${i}`}>{owhtopts[wt]}</div>
          });
        }
      }
    }
    return 'n/a';
  }

  renderDefaultShippingAddress = (addressname) => {
    if (this.props.finalValues) {
      if (this.props.finalValues.default_shipping) {
        if (addressname === this.props.finalValues.default_shipping) {
          return '(Default Shipping)';
        }
      }
    }
    return '';
  }

  showExistingData = (bp, props, classes) => {
    const tableData = [
      ['DB', bp.DB],
      ['Customer Name', bp.customer_name],
      ['Date Added',moment(bp.date_modified).format("MM/DD/YYYY hh:mm a")],
      ['Contact Firstname',bp.new_contact_firstname],
      ['Contact Lastname',bp.new_contact_lastname],
      ['Contact Position',bp.new_contact_position],
      ['Contact Telephone',bp.new_contact_tel],
      ['Contact Email',bp.new_contact_email]
    ];
    return (
        <Dialog
            classes={{
                root: classes.center,
                paper: classes.modal
            }}
            style={{overflow:'hidden !important'}}
            open={props.open}
            keepMounted
            fullWidth={true}
            maxWidth="md"
            onClose={() => {
              props.close()
            }}
            aria-labelledby="modal-slide-title"
            aria-describedby="modal-slide-description">
            <DialogTitle
                id="classic-modal-slide-title"
                disableTypography
                className={classes.modalHeader}>
                <Button
                    justIcon
                    className={classes.modalCloseButton}
                    key="close"
                    aria-label="Close"
                    color="transparent"
                    onClick={() => {
                      props.close();
                    }}
                    style={{float:'right'}}
                >
                    <Close className={classes.modalClose} />
                </Button>
                <h3><strong>Existing BP</strong></h3>
            </DialogTitle>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <div style={{margin:20}}>
                  <Table
                    tableHeaderColor="primary"
                    tableHead={["Field", "Value"]}
                    tableData={tableData}
                    coloredColls={[3]}
                    colorsColls={["primary"]}
                  />
                </div>
                <div style={{margin:'20px auto'}}>
                  <Button
                      className={classes.modalCloseButton}
                      key="close"
                      aria-label="Close"
                      color="rose"
                      onClick={() => {
                        props.close();
                      }}
                  >Close</Button>
                </div>
              </GridItem>
            </GridContainer>
          </Dialog>
    )
  }


  render() {
    console.log('BPFormReview',this.state,this.props);
    const finalValues = this.props.finalValues;
    let isLocked = false;
    if (this.props.lockedMsg && finalValues) {
      const lockArr = this.props.lockedMsg.split(' ');
      console.log('lockArr', lockArr);
      isLocked = true;
    }

    if (finalValues.customer_name) {
      // BP is Existing
      return this.showExistingData(finalValues, this.props, this.props.classes);
    }

    const {
      classes,
      handleApprove,
      handleDeny,

      owhtopts,
      countries,
      states,
      payterms,
      currencies,
      gsttypes,
      groupcodes,
      currentDB,
      currentBPType

    } = this.props;
    let bp = { ...this.props.finalValues };
    if (!bp) {
      return null;
    }

    const billing_country = countries[bp.billing_country] ? countries[bp.billing_country] : '';
    const shipping_country = countries[bp.shipping_country] ? countries[bp.shipping_country] : '';
    const billing_state = states[bp.billing_country] ?
                            states[bp.billing_country][bp.billing_state] ? states[bp.billing_country][bp.billing_state] : ''
                          : '';
    const shipping_state = states[bp.shipping_country] ?
                            states[bp.shipping_country][bp.shipping_state] ? states[bp.shipping_country][bp.shipping_state] : ''
                          : '';

    console.log(billing_country, shipping_country, billing_state, shipping_state, this.props);
    let billing_gstregtype = '',
        shipping_gstregtype = '';
    if (gsttypes) {
      if (gsttypes.length > 0) {
        gsttypes.forEach((g) => {
          if (g.id === bp.billing_gstregtype) {
            billing_gstregtype = g.type;
          }
          if (g.id === bp.shipping_gstregtype) {
            shipping_gstregtype = g.type;
          }
        });
      }
    }
    const props = this.props;
    const isDisabled = true;

    let isNIN = false;
    if (currentDB === 'NIN_TEST' || currentDB === 'NIN_LIVE') {
      isNIN = true;
    }

    let isNMX = false;
    if (currentDB === 'NMX_TEST' || currentDB === 'NMX_LIVE') {
      if (bp.billing_country === 'MX') {
        isNMX = true;
      }
    }

    let isNKR = false;
    if (currentDB === 'NKR_TEST' || currentDB === 'NKR_LIVE') {
      isNKR = true;
    }

    let isNGT = false;
    if (currentDB === 'NGT_TEST' || currentDB === 'NGT_LIVE') {
      if (bp.billing_country === 'GT') {
        isNGT = true;
      }
    }

    let isNAS = false;
    if (currentDB === 'NAS_TEST' || currentDB === 'NAS_LIVE') {
      isNAS = true;
    }

    let isWithhold = false;
    if (bp.withholding) {
      isWithhold = true;
    }

    let payterm = '';
    if (bp.payterms) {
      if (this.props.ptts) {
        payterm = this.props.ptts[bp.payterms];
      }
    }

    console.log('bp before core', bp);
    let accountsPayableLabel = 'First & Last Name';
    let purchasingLabel = 'First & Last Name';

    let accountsPayableHeader = 'Contact 1';
    if (bp.accounts_payable_name.indexOf('GMP AP') !== -1) { accountsPayableHeader = 'TPP Accounts Payable Contact' }
    if (bp.accounts_payable_name.indexOf('GMP PURCHASING') !== -1) { accountsPayableHeader = 'TPP Purchasing Contact' }
    if (bp.accounts_payable_name.indexOf('GMP OTHER') !== -1) { accountsPayableHeader = 'TPP Other Contact' }

    let purchasingHeader = 'Contact 2';
    if (bp.purchasing_name) {
      if (bp.purchasing_name.indexOf('GMP AP') !== -1) { purchasingHeader = 'TPP Accounts Payable Contact' }
      if (bp.purchasing_name.indexOf('GMP PURCHASING') !== -1) { purchasingHeader = 'TPP Purchasing Contact' }
      if (bp.purchasing_name.indexOf('GMP OTHER') !== -1) { purchasingHeader = 'TPP Other Contact' }
    }

    let hasRBOEDI = false, rbos = [], edis = [];
    if ((bp.rboActive || bp.ediActive) || (bp.rbo || bp.edi)) {
      hasRBOEDI = true;
      if (bp.rboActive.length || bp.ediActive.length) {
        if (bp.rboActive.length > 0) {
          rbos = bp.rboActive.map(rbo => {
            return {
              rbo: rbo.rbo,
              name: this.props.rbosDict[rbo.rbo] ? this.props.rbosDict[rbo.rbo] : ''
            }
          });
        }
        if (bp.ediActive.length > 0) {
          edis = bp.ediActive.map(edi => {
            return {
              edi_program: edi.edi_program,
              vendor_code: edi.vendor_code,
              name: this.props.edi_programsDict[edi.edi_program] ? this.props.edi_programsDict[edi.edi_program] : ''
            }
          });
        }
      } else {
        if (bp.rbo) {
          console.log('bp rbo',bp.rbo);
          rbos = bp.rbo.map(rbo => {
            return {
              rbo: rbo.rbo,
              name: this.props.rbosDict[rbo.rbo] ? this.props.rbosDict[rbo.rbo] : ''
            }
          });
        }

        if (bp.edi) {
          edis = bp.edi.map(edi => {
            const edi_program = edi.edi_program ? edi.edi_program.toUpperCase() : '';
            return {
              edi_program: edi_program,
              vendor_code: edi.vendor_code,
              name: this.props.edi_programsDict[edi_program] ? this.props.edi_programsDict[edi_program] : ''
            }
          });
        }

      }

    }

    console.log('unsorted', rbos, edis);

    const finalRBO = rbos.length > 0 ? [].concat(rbos)
    .sort((a, b) => (a.name > b.name) ? 1 : -1) : [];

    const finalEDI = edis.length > 0 ? [].concat(edis)
    .sort((a, b) => (a.name > b.name) ? 1 : -1) : [];

    console.log('sorted', finalRBO, finalEDI);
    return (
  <div style={{textAlign:'left'}} className="dialog-bgg">
    <Dialog
        classes={{
            root: classes.center,
            paper: classes.modal
        }}
        style={{overflow:'hidden !important'}}
        open={props.open}
        keepMounted
        fullWidth={true}
        maxWidth="md"
        onClose={() => {
          props.close()
        }}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description">
        <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}>
            <Button
                justIcon
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
                color="transparent"
                onClick={() => {
                  props.close();
                }}
                style={{float:'right'}}
            >
                <Close className={classes.modalClose} />
            </Button>
            {(bp.card_code && !bp.gmp_id) && <h3>Existing BP Review</h3>}
            {(bp.card_code && bp.gmp_id) && <h3>Existing TPP BP Review</h3>}
            {(!bp.card_code && !bp.gmp_id) && <h3>New BP Review</h3>}
            {(!bp.card_code && bp.gmp_id) && <h3>New TPP BP Review</h3>}
        </DialogTitle>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card style={{textAlign:'left',marginLeft:'15px',marginTop:'0',marginBottom:'10px'}}>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <h4><strong>General Information</strong></h4>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    {this.renderLabel('Company Name', bp.company_name, 'company_name')}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <div style={{marginBottom:'10px'}}>
                      <div style={{color:'#aaa',fontSize:'10px',fontWeight:'400'}}>Database:</div>
                      <div>{currentDB ? currentDB : bp.DB}</div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <div style={{marginBottom:'10px'}}>
                      <div style={{color:'#aaa',fontSize:'10px',fontWeight:'400'}}>BP Type:</div>
                      <div>{bp.card_code ?
                                          bp.card_code[0] === 'C' ? 'Customer' : 'Supplier'
                                         :
                                          currentBPType ? currentBPType : ''}</div>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    {this.renderLabel('BP Subgroup', this.props.groupcodesDictionary[bp.bp_subgroup] ? this.props.groupcodesDictionary[bp.bp_subgroup] : '', 'bp_subgroup')}
                  </GridItem>
                  <hr />
                  <GridItem xs={12} sm={12} md={3}>
                    {this.renderPhoneLabel('General Telephone',bp.gen_tel, 'gen_tel')}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    {this.renderPhoneLabel('General Fax',bp.gen_fax ? bp.gen_fax : 'n/a', 'gen_fax', bp)}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    {this.renderLabel('Currency',bp.currency, 'currency')}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    {bp.card_code && <div style={{marginBottom:'10px'}}>
                      <div style={{color:'#aaa',fontSize:'10px',fontWeight:'400'}}>Card Code:</div>
                      <div>{bp.card_code}</div>
                    </div>}
                  </GridItem>
                  <hr />
                  <GridItem xs={12} sm={12} md={3}>
                  {
                    isNIN &&
                    <div>{this.renderLabel('P.A.N. No', bp.panno ? bp.panno : 'n/a', 'panno')}</div>
                  }
                  {
                    isNIN &&
                    <div>{this.renderLabel('G.S.T.I.N.', bp.tinno ? bp.tinno : 'n/a', 'tinno')}</div>
                  }
                  {
                    (isNIN && isWithhold) &&
                    <div>{this.renderLabel('Certificate No.', bp.certificate_num ? bp.certificate_num : 'n/a', 'certificate_num', bp)}</div>
                  }
                  {
                    (isNIN && isWithhold) &&
                    <div>{this.renderLabel('N.I. no.', bp.ni_num ? bp.ni_num : 'n/a', 'ni_num')}</div>
                  }
                  {
                    (isNIN && isWithhold) &&
                    <div>{this.renderLabel('Accrual Criteria', bp.accrual_criteria == "1" ? 'Accrual' : 'Cash', 'accrual_criteria')}</div>
                  }
                  {
                    (isNIN && isWithhold) &&
                    <div>{this.renderLabel('Threshold Overlook', bp.threshold_overlook == "1" ? 'Yes' : 'No', 'threshold_overlook')}</div>
                  }
                  {
                    (isNIN && isWithhold) &&
                    <div>{this.renderLabel('Surcharge Overlook', bp.surcharge_overlook == "1" ? 'Yes' : 'No', 'surcharge_overlook')}</div>
                  }
                  </GridItem>
                  <GridItem xs={12} sm={12} md={7}>
                  {
                    isNIN &&
                    <div>{this.renderLabel('Subject to Withholding Tax', bp.withholding ? 'Yes' : 'No', 'withholding')}</div>
                  }
                  {
                    (isNIN && isWithhold) &&
                    <div>{this.renderLabel('Expiration Date', bp.expiration_date ? bp.expiration_date : 'n/a', 'expiration_date')}</div>
                  }
                  {
                    (isNIN && isWithhold) &&
                    <div>{this.renderLabel('Assessee Type', bp.assessee_type == "0" ? 'Company' : 'Others', 'assessee_type')}</div>
                  }
                  {
                    (isNIN && isWithhold) &&
                    <div>
                      <strong>WTAX Codes Allowed :</strong>
                        {this.renderWTaxCodes()}
                    </div>
                  }
                  </GridItem>
                  { isNAS &&
                    <GridItem xs={12} sm={12} md={5}>
                      {this.renderLabel('Old Code', bp.old_code ? bp.old_code : 'n/a', 'old_code')}
                    </GridItem>
                  }
                  <GridItem xs={12} sm={12} md={5}>
                  {
                    isNMX &&
                    <div>{this.renderLabel('RFC/CURP', bp.rfc_curp ? bp.rfc_curp : 'n/a', 'rfc_curp')}</div>
                  }
                  {
                    isNGT &&
                    <div>{this.renderLabel('NIT', bp.nit ? bp.nit : 'n/a', 'NIT')}</div>
                  }
                  </GridItem>
                  <GridItem xs={12} sm={12} md={7}>

                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
        {this.props.enterPayTerms && <Card style={{textAlign:'left',marginLeft:'15px',marginTop:'0',marginBottom:'10px'}}>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <h4><strong>Accounting Information</strong></h4>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
        {this.renderLabel('Pay Terms', payterm, 'payterms')}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
        {this.renderLabel('Credit Limit',`${bp.currency !== '##' ? bp.currency : ''}
        ${!isNaN(bp.credit_limit) ? parseInt(bp.credit_limit).toLocaleString() : ''}`, 'payterms')}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
        {bp.acctname && this.renderLabel('Account Name', bp.acctname, 'acctname')}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>}
            <Card style={{textAlign:'left',marginLeft:'15px',marginTop:'0',marginBottom:'10px'}}>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <h4><strong>Billing Address</strong></h4>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    {this.renderLabel('Name', helpers.removeAddressColon(bp.billing_addressname), 'billing_addressname')}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    {this.renderLabel('Address 1', bp.billing_address1, 'billing_address1')}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    {this.renderLabel('Address 2', bp.billing_address2, 'billing_address2')}
                  </GridItem>

                  <GridItem xs={12} sm={12} md={3}>
                    {this.renderLabel('Country', billing_country, 'billing_country', bp)}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    {this.renderLabel('State', billing_state, 'billing_state', bp)}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    {this.renderLabel('City', bp.billing_city, 'billing_city')}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    {this.renderLabel('Postal Code', bp.billing_zipcode, 'billing_zipcode')}
                  </GridItem>

                  <GridItem xs={12} sm={12} md={3}>
                  {
                    bp.billing_gstregno &&
                    <div>{this.renderLabel('GST Reg\'n No.', bp.billing_gstregno, 'billing_gstregno')}</div>
                  }
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                  {
                    bp.billing_gstregtype &&
                    <div>{this.renderLabel('GST Reg\'n Type.', this.props.gsttypes[bp.billing_gstregtype], 'billing_gstregtype', bp)}</div>
                  }
                  </GridItem>
                </GridContainer>
                <hr />
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <h4><strong>Shipping Address 1</strong></h4>
                    {this.renderDefaultShippingAddress(bp.shipping_addressname)}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    {this.renderLabel('Name', helpers.removeAddressColon(bp.shipping_addressname), 'shipping_addressname')}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    {this.renderLabel('Address 1', bp.shipping_address1, 'shipping_address1')}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    {this.renderLabel('Address 2', bp.shipping_address2, 'shipping_address2')}
                  </GridItem>

                  <GridItem xs={12} sm={12} md={3}>
                    {this.renderLabel('Country', shipping_country, 'shipping_country', bp)}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    {this.renderLabel('State', shipping_state, 'shipping_state', bp)}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    {this.renderLabel('City', bp.shipping_city, 'shipping_city')}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    {this.renderLabel('Postal Code', bp.shipping_zipcode, 'shipping_zipcode')}
                  </GridItem>

                  <GridItem xs={12} sm={12} md={3}>
                    {
                      bp.shipping_gstregno &&
                      <div>{this.renderLabel('GST Reg\'n No.', bp.shipping_gstregno, 'shipping_gstregno')}</div>
                    }
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    {
                      bp.shipping_gstregtype &&
                      <div>{this.renderLabel('GST Reg\'n Type', this.props.gsttypes[bp.shipping_gstregtype], 'shipping_gstregtype')}</div>
                    }
                  </GridItem>
                </GridContainer>
                {this.renderDynamicAddress(helpers.removeAddressColonArr(this.props.beforeValues.additionalShippingAddresses), helpers.removeAddressColonArr(this.props.finalValues.additionalShippingAddresses))}
              </CardBody>
            </Card>
            <Card style={{textAlign:'left',marginLeft:'15px',marginTop:'0',marginBottom:'10px'}}>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <h4><strong>{accountsPayableHeader} {this.showID(bp.accounts_payable_gmp)}</strong></h4>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    {this.renderLabel('Firstname', bp.accounts_payable_firstname, 'accounts_payable_firstname')}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    {this.renderLabel('Position', bp.accounts_payable_position, 'accounts_payable_position')}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    {this.renderPhoneLabel('Telephone', bp.accounts_payable_tel, 'accounts_payable_tel')}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    {this.renderPhoneLabel('Alt Telephone', bp.accounts_payable_alttel, 'accounts_payable_alttel')}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    {this.renderPhoneLabel('Mobile', bp.accounts_payable_mobile, 'accounts_payable_mobile')}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    {this.renderLabel('Lastname', bp.accounts_payable_lastname, 'accounts_payable_lastname')}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    {this.renderPhoneLabel('Fax', bp.accounts_payable_fax, 'accounts_payable_fax')}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    {this.renderLabel('Email', bp.accounts_payable_email, 'accounts_payable_email')}
                  </GridItem>
                  {(!bp.accounts_payable_firstname && !bp.accounts_payable_lastname) && <GridItem xs={12} sm={12} md={12}>
                    {this.renderLabel('Name', bp.accounts_payable_name, 'accounts_payable_name')}
                  </GridItem>}
            </GridContainer>
            {
              (bp.purchasing_email || bp.purchasing_name) &&
              <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <h4><strong>{purchasingHeader} {this.showID(bp.purchasing_gmp)}</strong></h4>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    {bp.purchasing_name && this.renderLabel('Firstname', bp.purchasing_firstname, 'purchasing_firstname')}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    {bp.purchasing_name && this.renderLabel('Position', bp.purchasing_position, 'purchasing_position')}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    {bp.purchasing_name && this.renderPhoneLabel('Telephone', bp.purchasing_tel, 'purchasing_tel')}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    {bp.purchasing_name && this.renderLabel('Alt. Telephone', bp.purchasing_alttel, 'purchasing_alttel')}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    {bp.purchasing_name && this.renderLabel('Mobile', bp.purchasing_mobile, 'purchasing_mobile')}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    {bp.purchasing_name && this.renderLabel('Lastname', bp.purchasing_lastname, 'purchasing_lastname')}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    {bp.purchasing_name && this.renderPhoneLabel('Fax', bp.purchasing_fax, 'purchasing_fax')}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={5}>
                    {bp.purchasing_name && this.renderLabel('Email', bp.purchasing_email, 'purchasing_email')}
                  </GridItem>
                  {(!bp.purchasing_firstname && !bp.purchasing_lastname) && <GridItem xs={12} sm={12} md={12}>
                    {this.renderLabel('Name', bp.purchasing_name, 'purchasing_name')}
                  </GridItem>}
              </GridContainer>
            }
              {this.renderDynamicContacts(this.props.beforeValues.additionalContacts, this.props.finalValues.additionalContacts)}
            </CardBody>
          </Card>
          {hasRBOEDI && <Card style={{textAlign:'left',marginLeft:'15px',marginTop:'0',marginBottom:'10px'}}>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <div>
                    <h4><strong>RBO</strong></h4>
                    {finalRBO.map((rbo,i) => {
                      let rbo_value = rbo.rbo;
                      if (this.props.rbosDict[rbo.rbo]) {
                        rbo_value = this.props.rbosDict[rbo.rbo];
                      } else {
                        rbo_value = '- rbo name not found -';
                      }
                      return <div key={`rbo-${i}`}>{rbo_value}</div>
                    })}
                  </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <div>
                    <h4><strong>EDI Program / Vendor Codes</strong></h4>
                    {finalEDI.map((edi,i) => {
                      let edi_value = edi.edi_program;
                      if (this.props.edi_programsDict[edi.edi_program]) {
                        edi_value = this.props.edi_programsDict[edi.edi_program];
                      } else {
                        edi_value = '- program name not found -';
                      }
                      let addClass = '';
                      if (this.props.currentVendorCode) {
                        if (edi.vendor_code === this.props.currentVendorCode) {
                          addClass = 'green-text';
                        }
                      }
                      return <div key={`edi-${i}`}>{edi_value} / <span className={addClass}>{edi.vendor_code} {addClass ? ' (same as selected vendor code)':''}</span></div>
                    })}
                  </div>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>}
          <Card style={{textAlign:'left',marginLeft:'15px',marginTop:'0',marginBottom:'10px'}}>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <div>
                    <h4><strong>Notes</strong></h4>
                    <p>{bp.notes ? bp.notes : 'n/a'}</p>
                  </div>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          <Card style={{textAlign:'left',marginLeft:'15px',marginTop:'0px'}}>
            <CardBody>
                <GridContainer>
                  <div style={{marginTop:'20px',marginLeft:'20px'}} className="separate-btns">
                    <GridItem xs={12} sm={12} md={12}>
                      <Button
                        color="rose"
                        onClick={() => {
                          this.props.close();
                        }}
                      >BACK</Button>
                      {
                        this.props.lockedMsg &&
                        <span style={{color:'red'}}>{this.props.lockedMsg}</span>
                      }
                      {
                        (((!this.props.onApprove && !this.props.lockedMsg) && this.props.BPaction === 'EXISTING') && !this.props.onGMPExistingLoad) &&
                        <Button
                          color="success"
                          onClick={() => {
                            if (this.props.selectedBPname) {
                              this.props.loadSelectedBP();
                            }
                            this.props.close();
                          }}
                        >LOAD THIS BP</Button>
                      }
                      {
                        this.props.onApprove &&
                          <Button
                            color="success"
                            onClick={() => {
                              this.props.onApprove();
                              this.props.close();
                              if (this.props.closeShowGMPExistingModal) {
                                this.props.closeShowGMPExistingModal();
                              }
                            }}
                          >{this.props.directToSAP ? 'APPROVE' : 'SUBMIT TO PENDING'}</Button>
                      }
                      {
                        (this.props.onGMPExistingLoad && !isLocked) &&
                          <Button
                          color="success"
                          onClick={() => {
                            this.props.onGMPExistingLoad();
                            this.props.close();
                          }}
                          >CHOOSE</Button>
                      }
                    </GridItem>
                  </div>
                </GridContainer>
                <Clearfix />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ptts: state.common.paytermsDictionary,
    groupcodesDictionary: state.common.groupcodesDictionary,
    rbosDict: state.gmp.rbosDict,
    edi_programsDict: state.gmp.edi_programsDict,
    lockedMsg: state.common.lockedMsg
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchGMPRBO: (db) => {
      dispatch(actions.fetchGMPRBO(db));
    },
    fetchEDIPrograms: () => {
      console.log('fetching EDI programs');
      dispatch(actions.fetchEDIPrograms());
    },
  }
}

const combinedStyle = combineStyles(sweetAlertStyle, userProfileStyles);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(combinedStyle)(BPFormReview));
