import React from 'react';
import GINreview from "containers/GIN/GINreview";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle2 from '@material-ui/core/DialogTitle';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CustomInput from "components/CustomInput/CustomInput.jsx";
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';
import Button from '@material-ui/core/Button';
import moment from 'moment';

const Popup = props => {
  console.log('groupData received',props);
  const isFirst = props.groupIndex === 0 ? true : false
  let isLast = null;
  if (props.groupData) {
    if (props.groupData.data) {
      isLast = props.groupData.data.length - 1 === props.groupIndex ? true : false
    }
  }
  return (
    <Dialog
      open={props.open}
      keepMounted
      onClose={props.close}
      fullWidth={true}
      maxWidth="lg"
    >
      <DialogTitle2>
        <div className="text-center">
        {(props.from === 'group' && !isFirst) && <Button
          color="white"
          onClick={() => {
            if (!isFirst) {
              const index = props.groupIndex-1;
              props.selectIndex(index)
              const gin = props.groupData.data[index];
              const date = moment(gin.timestamp).format("MM/DD/YYYY hh:mm A");
              // open Review
              console.log('opening new review modal', gin);
              props.loadHistoryEntry(gin.id);
              props.openReviewModal(gin.user, date, gin.activity);
              if (gin.data_source) {
                console.log('loading image');
                props.loadImage(`${gin.data_source}_${gin.gin}`);
              }
            }
          }}
          disabled={false}
        >
          <ArrowBack />
        </Button>}
          <span style={{marginRight:20}}>{props.ginActivity} by {props.ginUser} on {props.ginDate}</span>
          {(props.from === 'group' && !isLast) && <Button
            color="white"
            onClick={() => {
              if (!isLast) {
                const index = props.groupIndex+1;
                props.selectIndex(index)
                const gin = props.groupData.data[index];
                const date = moment(gin.timestamp).format("MM/DD/YYYY hh:mm A");
                // open Review
                console.log('opening new review modal', gin);
                props.loadHistoryEntry(gin.id);
                props.openReviewModal(gin.user, date, gin.activity);
                if (gin.data_source) {
                  props.loadImage(`${gin.data_source}_${gin.gin}`);
                }
              }
            }}
            disabled={false}
          >
            <ArrowForward />
          </Button>}
        </div>
        <div className="close-btn">
          <IconButton aria-label="Close" onClick={props.close}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle2>
      <div style={{padding:10}}>
        <GINreview
          isHistory={true}
          isPreview={true}
          action={props.action}
          autosave_id={props.currentAutoSaveID}
          ginType={props.ginType}
          autosaveData={props.autosaveData}
          ginSource={props.ginSource}
          closeView={props.closeView}
        />
      </div>
    </Dialog>
  );
}

export default Popup
