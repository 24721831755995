import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import * as actions from '../../store/actions';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import Button2 from "components/CustomButtons/Button.jsx";
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Typography from '@material-ui/core/Typography';
import { PulseLoader } from 'react-spinners';
import Select2 from 'react-select';
import Select from '@material-ui/core/Select';
import Table from "components/Table/Table.jsx";
import GINSelect from "./GINselect";
import GINReactSelect from "./GINreact-select";
import cloneDeep from "lodash/cloneDeep";

const DialogTitle = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500],
  },
}))(props => {
  const { children, classes, onClose, editData } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <span style={{fontSize:'18px'}}>Edit <strong>{editData.itemName}</strong> for <strong>{editData.DB}</strong></span>
      {onClose ? (
        <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit,
  },
}))(MuiDialogActions);


class GINtable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      type: this.props.editTableData.type,
      variantsData: this.props.variants[this.props.editTableData.DB] ? this.props.variants[this.props.editTableData.DB] : [],
      variantsDescData: this.props.variantsDesc[this.props.editTableData.DB] ? this.props.variantsDesc[this.props.editTableData.DB] : [],
      valueData: '',
      descData: '',

      currentRow: null,
      error: '',
    }
  }

  handleSelectChange = (value, name) => {
    this.setState({
      [name]: value
    })
  }

  onValueChange = e => {
    if (e.target.value) {
      if (e.target.value.length < 5) {
        this.setState({ valueData: e.target.value.toUpperCase(), error: '' });
      }
    } else {
      this.setState({ valueData: '', error: '' });
    }
  }

  onDescChange = e => {
    if (e.target.value) {
      if (e.target.value.length < 101) {
        this.setState({ descData: e.target.value.toUpperCase(), error: '' });
      }
    } else {
      this.setState({ descData: '', error: '' });
    }
  }

  onValChange = (destination, e) => {
    this.setState({ [destination]: e.target.value, error: '' });
  }

  handleChange = name => value => {
    this.setState({
      [name]: value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const newEditedData = {
      field: this.props.editData.field,
      value: this.state.valueData,
      db: this.props.editData.db
    }
    console.log('submitted edit...',this.props, newEditedData);
    this.props.updateGINEdit(newEditedData);
    this.props.editTableModeOff();
    this.props.compareAll();
  }

  handleValueSelect = (value) => {
    this.setState({
      valueData: value
    });
  }

  editRow = (row) => {
    console.log('edit row', row, this.state.type);

    if (this.state.type = 'variants') {
      this.setState({
        currentRow: row,
        valueData: this.state.variantsData[row][0],
        descData: this.state.variantsDescData[row]
      });
    }
  }

  deleteRow = (row) => {
    const data = this.props.editTableData;

    if (this.state.type === 'variants') {
      let variants = [...this.state.variantsData];
      let variantsDesc = [...this.state.variantsDescData];
      let changedVariants = variants.filter((v, i) => {
        return i !== row;
      });
      let changedVariantsDesc = variantsDesc.filter((v, i) => {
        return i !== row;
      });
      this.setState({
        variantsData: changedVariants,
        variantsDescData: changedVariantsDesc
      })
    }
  }

  handleSubmitVariant = () => {
    if (!this.state.valueData) {
      return;
    }

    let variants = [...this.state.variantsData];
    let variantsDesc = [...this.state.variantsDescData];


    if (this.state.currentRow || this.state.currentRow === 0) {
      // edit
      variants[this.state.currentRow] = [this.state.valueData,''];
      variantsDesc[this.state.currentRow] = this.state.descData;
    } else {
      // add
      variants.push([this.state.valueData,'']);
      variantsDesc.push(this.state.descData);
    }

    let codes = {}, hasDuplicate = false;
    variants.forEach(v => {
      if (codes.hasOwnProperty(v[0])) {
        codes[v[0]]++;
      } else {
        codes[v[0]] = 1;
      }
    });
    Object.keys(codes).forEach(c => {
      if (codes[c] > 1) {
        hasDuplicate = true;
      }
    });

    Object.keys(this.props.variants).forEach(db => {
      if (db !== this.props.editTableData.DB) {
        if (this.props.variants[db]) {
          this.props.variants[db].forEach(v => {
            if (v[0] === this.state.valueData) {
              hasDuplicate = true;
            }
          });
        }
      }
    });

    console.log(codes, hasDuplicate);
    if (hasDuplicate) {
      this.setState({
        error: 'No duplicate codes from all opened DBs allowed'
      })
    } else {
      this.setState({
        variantsData: variants,
        variantsDescData: variantsDesc,
        valueData: '',
        descData: '',
        currentRow: null
      });

      // let variantsChecked= cloneDeep(this.props.variantsChecked);
      // variantsChecked[this.state.valueData] = 1;
      // this.props.setVariantsChecked(variantsChecked);
    }
  }

  handleSaveVariant = () => {
    console.log(this.state.variantsData);
    this.setState({
      error: ''
    }, () => {
      let codes = {}, hasDuplicate = false;
      this.state.variantsData.forEach(v => {
        if (codes.hasOwnProperty(v[0])) {
          codes[v[0]]++;
        } else {
          codes[v[0]] = 1;
        }
      });
      Object.keys(codes).forEach(c => {
        if (codes[c] > 1) {
          hasDuplicate = true;
        }
      });
      if (hasDuplicate) {
        this.setState({
          error: 'No duplicate codes allowed'
        })
      } else {
        this.props.updateItem({
          variants: this.state.variantsData,
          variantsDesc: this.state.variantsDescData,
          db: this.props.editTableData.DB
        }, 'variants');
        this.props.editTableModeOff();
      }
    });
  }

  render() {
    console.log('VR OR BOM', this.state, this.props);

    const data = this.props.editTableData;
    const btnStyle = {marginTop:'5px',marginRight:'5px'};
    let list = null;
    let variantsArr = [];
    if (this.state.type === 'variants') {
      this.state.variantsData.forEach((v,i) => {
        variantsArr.push([
          v[0],
          this.state.variantsDescData[i],
          <div>
            <div className="blue-btn btn-sm" style={btnStyle} onClick={() => { this.editRow(i); }}>Edit</div>
            <div className="red-btn btn-sm" style={btnStyle} onClick={() => { this.deleteRow(i); }}>Delete</div>
          </div>
        ]);
      })
    }

    return (
      <div id="gin-modal">
        <Dialog
          onClose={() => {
            this.props.editTableModeOff();
          }}
          aria-labelledby="customized-dialog-title"
          open={this.props.openEdit}
        >
          <DialogTitle id="customized-dialog-title"
            onClose={() => {
              this.props.editTableModeOff();
            }}
            editData={this.props.editTableData}
          >
          </DialogTitle>
          <div className="item-input-margin" style={{backgroundColor:'#fcfcfc'}}>

          {this.state.type === 'variants' &&
            <form onSubmit={(e) => {
              e.preventDefault();
              console.log('submit variant');
              this.handleSubmitVariant();
            }}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Enter Variant Code"
                      id="variant_code"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: this.onValueChange,
                        value: this.state.valueData,
                        autoFocus: true,
                        role: 'presentation',
                        autoComplete: 'nope'
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Enter Variant Description"
                    id="variant_desc"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: this.onDescChange,
                      value: this.state.descData,
                      autoFocus: true,
                      role: 'presentation',
                      autoComplete: 'nope'
                    }}
                  />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <Button2
                      color="rose"
                      type="button"
                      onClick={() => {
                        this.handleSubmitVariant();
                      }}
                    >{this.state.currentRow || this.state.currentRow === 0 ? 'Revise' : 'Add'}</Button2>
                    {
                      this.state.currentRow || this.state.currentRow === 0 ? '' :
                      <Button2
                        color="success"
                        type="submit"
                        onClick={() => {
                          this.handleSaveVariant();
                        }}
                      >Save</Button2>
                    }
                    {this.state.error && <div className="red-text text-center" style={{marginTop:'20px'}}>{this.state.error}</div>}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <div className="gray-th sm-table">
                      <Table
                        tableHeaderColor="primary"
                        tableHead={["Variant Code", "Change Description", "Actions"]}
                        tableData={variantsArr}
                        coloredColls={[3]}
                        colorsColls={["primary"]}
                      />
                    </div>
                    {variantsArr.length > 0 ? '' : <div style={{textAlign:'center',marginTop:'20px'}}><em>No Variant Rows</em></div>}
                  </GridItem>
                </GridContainer>
              </form>
            }

          </div>
          <DialogActions>
            <Button onClick={() => {
              this.props.editTableModeOff();
            }} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    editTableData: state.gin.editTableData,
    variants: state.gin.variants,
    variantsDesc: state.gin.variantsDesc,
    variantsChecked: state.gin.variantsChecked,
    options: state.gin.options
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    editTableModeOff: () => {
      console.log('edit table mode off');
      dispatch(actions.editTableModeOff());
    },
    updateGINEdit: (data) => {
      dispatch(actions.updateGINEdit(data));
    },
    compareAll: () => {
      dispatch(actions.fetchItemCompare());
    },
    updateItem: (data, mode) => {
      console.log('updating item for table');
      dispatch(actions.updateEditTable(data, mode));
    },
    setVariantsChecked: (checked) => {
      dispatch(actions.setVariantsChecked(checked));
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GINtable);
