import zipcelx from 'zipcelx';
import XLSX from 'xlsx';
import moment from 'moment';
import jwt_decode from 'jwt-decode';
import { cloneDeep } from 'lodash';

export const updateObject = (oldObject, updatedProperties) => {
	const newObject = cloneDeep(oldObject);
	if (updatedProperties) {
		const properties = Object.keys(updatedProperties);
		for (let i = 0; i < properties.length; i++) {
			const property = properties[i];
			newObject[property] = updatedProperties[property];
		}
	}
	return newObject;
};

export const commatize = (x) => {
    if (typeof x !== 'string') {
      if (x == 0) {
        return '0';
      }
      return x ? x.toLocaleString() : '';
    }
    //return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    console.log(x);
    x = x.replace(/,/g,'');
    console.log(x);
    x = parseFloat(x).toLocaleString();
    console.log(x);
    return x;
}

export const numberWithCommas = (x) => {
  if (x) {
    if (!isNaN(x)) {
      var y = Number(parseFloat(x).toFixed(10));
      var parts = y.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return parts.join(".");
    }
    //return xx; //parseFloat(xx).toFixed(10).replace(/0+$/, "");
  }
  return x;
}

export const convertToFixed = (num) => {
  if (num) {
    if (!isNaN(num)) {
      return Number(num).toFixed(5);
    }
  }
  return num;
}

export const convertToFixed3 = (num) => {
  if (num) {
    if (!isNaN(num)) {
      return Number(num).toFixed(3);
    }
  }
  return num;
}

export const nameSort = (a, b) => {
  if (a.name < b.name)
    return -1;
  if (a.name > b.name)
    return 1;
  return 0;
}

export const checkValidity = (value, rules) => {
    let isValid = true;
    if (rules) {
        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }
        if (rules.minLength) {
            isValid = value.length >= rules.minLength && isValid;
        }
        if (rules.maxLength) {
            isValid = value.length <= rules.maxLength && isValid;
        }
        if (rules.isEmail) {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

            isValid = pattern.test(value) && isValid;
        }
        if (rules.isNumeric) {
            const pattern = /^\d+$/
            isValid = pattern.test(value) && isValid;
        }
    }
    return isValid;
}

export const upperCase = (word) => {
  if (word) {
    return word.toUpperCase();
  }
  return word;
}

export const formatTime = (date) => {
  return '';
}

export const formatDate3 = (date) => {
  return moment(date).utc().format('YYYY-MM-DD');
}

export const formatDate2 = (date) => {
  return moment(date).utc().format('MM/DD/YYYY');
}

export const formatDate = (date, timeStatus) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    let finalFormat = [month, day, year].join('/');
    if (timeStatus) {
      const h = d.getHours(),
            m = d.getMinutes(),
            s = d.getSeconds();
      const time = `${h}:${m}:${s}`
      finalFormat = [month, day, year].join('/') + ' ' + time;
    }
    return finalFormat;
}

export const getParameterByName = (name,url) => {
    if (!url) url = window.location.href;
    name = name.replace(/[\[]/,"\\\[").replace(/[\]]/,"\\\]");
    var regexS = "[\\?&]"+name+"=([^&#]*)";
    var regex = new RegExp( regexS );
    var results = regex.exec( url );
    return results == null ? null : results[1];
}

export const normalizePhone = (value) => {
  var cleaned = ('' + value).replace(/[^A-Za-z0-9]/g, '');
  // var cleaned = ('' + value).replace(/\D/g, '')
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    var intlCode = (match[1] ? '+1' : '')
    return [intlCode, '(', match[2], ')', match[3], '-', match[4]].join('')
  }
  return value;
}

export const denormalizePhone = (value) => {
  if (value) {
    // value = value.match(/\d/g);
    // if (value) {
    //   value = value.join("");
    // } else {
    //   value = '';
    // }
    if (typeof value === 'string') {
      return value.replace(/[)( -+-]/g, '');
    }
  }
  return value; // replace all leading non-digits with nothing
}

export const generateCSV = (data, file_name) => {
  // Building the CSV from the Data two-dimensional array
  // Each column is separated by ";" and new line "\n" for next row
  var csvContent = '';
  data.forEach(function(infoArray, index) {
    let dataString = infoArray.join(';');
    csvContent += index < data.length ? dataString + '\n' : dataString;
  });

  // The download function takes a CSV string, the filename and mimeType as parameters
  // Scroll/look down at the bottom of this snippet to see how download is called
  var download = function(content, fileName, mimeType) {
    var a = document.createElement('a');
    mimeType = mimeType || 'application/octet-stream';

    if (navigator.msSaveBlob) { // IE10
      navigator.msSaveBlob(new Blob([content], {
        type: mimeType
      }), fileName);
    } else if (URL && 'download' in a) { //html5 A[download]
      a.href = URL.createObjectURL(new Blob([content], {
        type: mimeType
      }));
      a.setAttribute('download', fileName);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } else {
      window.location.href = 'data:application/octet-stream,' + encodeURIComponent(content); // only this mime type is supported
    }
  }

  download(csvContent, file_name);
}

export const generateExcel = (data, filename) => {
  console.log(data);
  if (data.data) {
    let arr = [];
    data.data.forEach((d) => {
      arr.push([
        {
          value: d[0],
          type: 'string'
        },
        {
          value: d[1],
          type: 'string'
        },
        {
          value: d[2],
          type: 'string'
        },
        {
          value: d[3],
          type: 'string'
        },
        {
          value: d[4],
          type: 'string'
        },
        {
          value: d[5],
          type: 'string'
        }
      ]);
    });

    const config = {
      filename: filename,
      sheet: {
        data: arr
      }
    };

    zipcelx(config);
  }
}

export const generateExcel2 = (data, filename) => {
  let worksheet = XLSX.utils.aoa_to_sheet(data.data);
  worksheet['!cols'] = data.cols;
  let new_workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(new_workbook, worksheet, 'sheet');
  XLSX.writeFile(new_workbook, filename);
}

export const qs = (queryString) => {
    var query = {};
    var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    for (var i = 0; i < pairs.length; i++) {
        var pair = pairs[i].split('=');
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return query;
}

export const checkForDuplicates = (arr) => {
  return arr.reduce((acc, v, i, arr) => arr.indexOf(v) !== i && acc.indexOf(v) === -1 ? acc.concat(v) : acc, []);
}

export const convertDateStr = (dateStr) => {
  if (!dateStr) {
    return -1;
  }
  const months = {
    JAN: '01',
    FEB: '02',
    MAR: '03',
    APR: '04',
    MAY: '05',
    JUN: '06',
    JUL: '07',
    AUG: '08',
    SEP: '09',
    OCT: '10',
    NOV: '11',
    DEC: '12'
  }

  const da = dateStr.split(' ');
  const month = months[da[0].toUpperCase()];
  const date = `${da[2]}-${month}-${da[1]}`;
  console.log('date generated', date);
  const d = new Date(date);
  return d.getTime();
}

export const checkDateOrder = (start, end) => {
  const startTime = convertDateStr(start),
        endTime = convertDateStr(end);
  if (startTime > endTime) {
    return false;
  }
  return true;
}

export const isValidDate = (str) => {
  return moment(str, 'MMM DD YYYY',true).isValid();
}

export const dateRangeOverlaps = (startDateA, endDateA, startDateB, endDateB) => {
    if ((endDateA < startDateB) || (startDateA > endDateB)) {
        return null
    }

    var obj = {};
    obj.startDate = startDateA <= startDateB ? startDateB : startDateA;
    obj.endDate = endDateA <= endDateB ? endDateA : endDateB;

    return obj;
}

export const cleanAllPhones = (bpo) => {
  const bp = { ...bpo };
  if (bp.gen_tel) {
    bp.gen_tel = denormalizePhone(bp.gen_tel);
  }
  if (bp.gen_fax) {
    bp.gen_fax = denormalizePhone(bp.gen_fax);
  }
  ['tel','alttel','mobile','fax'].forEach((field) => {
    if (bp[`accounts_payable_${field}`]) {
      bp[`accounts_payable_${field}`] = denormalizePhone(bp[`accounts_payable_${field}`]);
    }
    if (bp[`purchasing_${field}`]) {
      bp[`purchasing_${field}`] = denormalizePhone(bp[`accounts_payable_${field}`]);
    }
  });

  if (bp.additionalContacts) {
    if (bp.additionalContacts.length > 0) {
      bp.additionalContacts.forEach((ac,i,bpp) => {
        ['tel','alttel','mobile','fax'].forEach((field) => {
          if (bpp[i][field]) {
            bpp[i][field] = denormalizePhone(bpp[i][field]);
          }
        });
      });
    }
  }

  return bp;
}

export const swapKeyValues = (json) => {
  var ret = {};
  for(var key in json){
    ret[json[key]] = key;
  }
  return ret;
}

export const arraysEqual = (_arr1, _arr2) => {
    if (!Array.isArray(_arr1) || ! Array.isArray(_arr2) || _arr1.length !== _arr2.length)
      return false;

    var arr1 = _arr1.concat().sort();
    var arr2 = _arr2.concat().sort();

    for (var i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i])
            return false;
    }
    return true;
}

export const dynamicSort = (property) => {
    var sortOrder = 1;
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a,b) {
        /* next line works with strings and numbers,
         * and you may want to customize it to your needs
         */
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
}

export const dateToday = () => {
  const today = moment(new Date()).format("MMM DD YYYY"),
        oneYear = moment(new Date()).add('years', 1).format("MMM DD YYYY");
  console.log('date today and after 1 year is', today, oneYear);
  return [today.toUpperCase(), oneYear.toUpperCase()];
}

// check if number is between 0.00001 and 100,000
export const checkMinMax = (num, decimals, allowZero) => {
  if (!isNaN(num)) {
    const parsed = parseFloat(num);
    if (parsed === 0) {
      if (!allowZero) {
        return false;
      } else {
        return true;
      }
    }

    if (decimals === 5) {
      if (parsed >= 0.00001 && parsed <= 100000) {
        return true;
      }
    }

    if (decimals === 3) {
      if (parsed >= 0.001 && parsed <= 100000) {
        return true;
      }
    }
  }
  return false;
}

export const getUsername = () => {
  const token = localStorage.getItem('token');
  let username = '';
  if (token) {
    const decoded = jwt_decode(token);
    username = decoded.name;
  }
  return username;
}

export const filterByDate = (arr, field, startD, endD) => {
  let startDate = null,
      endDate = null;
  if (startD) {
    // const startArr = startD.split('/');
    //startDate = new Date(startArr[2], parseInt(startArr[0])-1, startArr[1], 0);
    startDate = moment(startD, 'MM-DD-YYYY hh:mm a').valueOf();
  }
  if (endD) {
    // const endArr = endD.split('/');
    //endDate = new Date(endArr[2], parseInt(endArr[0])-1, endArr[1], 24);
    endDate = moment(endD, 'MM-DD-YYYY hh:mm a').valueOf();
  }

  if (startDate && endDate) {
    if (startDate > endDate || startDate === endDate) {
      return null;
    }
  }

  return arr.filter((obj) => {
    const d = new Date(obj[field]).getTime();
    if (startDate && !endDate) {
      return d > startDate;
    } else if (!startDate && endDate) {
      return d < endDate;
    } else if (startDate && endDate) {
      return d > startDate && d < endDate;
    } else {
      return true;
    }
  });
}

export const renameToTPP = (str) => {
  return str.replace(/GMP/g, 'TPP');
}

export const convertToCommas = (num, skipStrip) => {
  if (skipStrip) {
    return parseInt(num).toLocaleString();
  }
  let string = parseInt('1'+num).toLocaleString();
  return string.substring(2);
}

export const convertToSerial = (num) => {
  if (num) {
    // add leading 0's
    let strNum = parseInt(num).toString();
    const zeroes = 9 - strNum.length;
    for (let i = 0; i < zeroes; i++) {
      strNum = '0' + strNum;
    }
    console.log('this is -', strNum, 'add zeroes '+(zeroes-1));
    return strNum;
    // add
  } else {
    return num;
  }
}

export const clone = obj => {
  return cloneDeep(obj);
}

export const decConvert = (int, type) => {
  let str = int.toString(),
      num = type ? type : 9;
  if (str.length < num) {
    const zeroes = num - str.length;
    let add = '';
    for (let i = 0; i < zeroes; i++) {
      add += '0';
    }
    str = add + str;
  }
  return str;
}

export const compare = (a, b) => {
  // Use toUpperCase() to ignore character casing
  const bandA = a.start;
  const bandB = b.start;

  let comparison = 0;
  if (bandA > bandB) {
    comparison = 1;
  } else if (bandA < bandB) {
    comparison = -1;
  }
  return comparison;
}

export const parseXmlToJson = (xml) => {
  const json = {};
  for (const res of xml.matchAll(/(?:<(\w*)(?:\s[^>]*)*>)((?:(?!<\1).)*)(?:<\/\1>)|<(\w*)(?:\s*)*\/>/gm)) {
      const key = res[1] || res[3];
      const value = res[2] && parseXmlToJson(res[2]);
      json[key] = ((value && Object.keys(value).length) ? value : res[2]) || null;

  }
  return json;
}

export const unicodeToChar = (text) => {
  if (!text || typeof text !== 'string') {
    return '';
  }
  return text.replace(/\\u[\dA-F]{4}/gi, 
         function (match) {
              return String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16));
         });
}

export const mt = (string) => {
  if (string) {
    return unicodeToChar(string);
  }
  return '';
}

export const makeNum = (num) => {
  console.log('making num', num);
  if (num) {
      if (typeof num === 'string') {
          const number = parseFloat(num);
          if (!isNaN(number)) {
              return number;
          }
      } else if (typeof num === 'number') {
          return num;
      }
  }
  return 0;
}

export const compareNumbersSpecial = (number1, number2) => {
  if (number1 && number2) {
      let num1 = typeof number1 === 'string' ? parseFloat(number1) : number1;
      let num2 = typeof number2 === 'string' ? parseFloat(number2) : number2;
      const diff = Math.abs(num1 - num2);
      if (diff < 0.02) {
          return true;
      }
  }
  return number1 === number2;
}
