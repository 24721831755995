import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from 'store/actions';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import { Dialog, Button as Button2 } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { clone } from '../../../shared/utility';
import Button from '../../../components/CustomButtons/Button';
import JCImporterPreview from './JCImporterPreview';

const JCPImporter = (props) => {
    console.log('JCPImporter', props);
    const { setRfidJcdata, jcp_import_data } = props;
    const [openedPreview, setOpenedPreview] = useState(false);

    const close = () => {
        window.location.href = '/rfid';
        props.close();
    }

    const restart = () => {
        setRfidJcdata({
            gtin: {},
            c128: {}
          });
        setOpenedPreview(false);
        close();
    }

    const openFile = function(event) {
        const input = event.target;
    
        const reader = new FileReader();
        reader.onload = function(){
            const text = reader.result;
            const filename = input.files[0] ? input.files[0].name.split('.txt')[0] : '';
            const text_arr = text.split('\r\n');
            if (text_arr.length > 0) {
                const newFiles = clone(jcp_import_data);
                let arr = [];
                text_arr.forEach((t,i) => {
                    if (i > 0) {
                        const tarr = t.split('\t');
                        if (tarr.length > 0) {
                            let valid = true;
                            const tobj = {
                                filename, 
                                sub: tarr[0],
                                lot: tarr[1],
                                line: tarr[2],
                                sku1: tarr[3],
                                size: tarr[4] ? tarr[4].toUpperCase() : '',
                                pid: tarr[5],
                                c128: tarr[6],
                                gtin: tarr[7],
                                jobno: tarr[8],
                                plate_name: tarr[9],
                                repeat: tarr[10],
                                natco_repeat: tarr[11],
                                plate_details: tarr[12],
                                runs: tarr[13]
                            }
                            Object.keys(tobj).forEach(f => {
                                if (f !== 'gtin') {
                                    if (tobj[f] === null || tobj[f] === undefined || tobj[f] === '') {
                                        valid = false;
                                    }
                                }
                            })
                            if (valid) {
                                const type = tarr[7] ? 'gtin' : 'c128';
                                const upc_code = type === 'gtin' ? tarr[7] : tarr[6];
                                if (newFiles[type].hasOwnProperty(upc_code)) {
                                    newFiles[type][upc_code].push(tobj);
                                } else {
                                    newFiles[type][upc_code] = [tobj];
                                }
                                arr.push(tobj);
                            }
                        }
                    }
                })
                setRfidJcdata(clone(newFiles));
                if (arr.length === 0) {
                    alert('File is not valid');
                }
                document.getElementById('file-importer').value = null;
            }

        };
        reader.readAsText(input.files[0]);
    };

    let files = [];
    Object.keys(jcp_import_data).forEach((type) => {
        const jid = jcp_import_data[type];
        if (jid) {
            Object.keys(jid).forEach((upc) => {
                const jup = jid[upc];
                if (jup) {
                    jup.forEach(j => {
                        if (j.filename) {
                            if (files.indexOf(j.filename) === -1) {
                                files.push(j.filename);
                            }
                        }
                    })
                }
            })
        }
    })

    return (
        <Dialog
            maxWidth="md"
            fullWidth={true}
            open={props.open}
            onClose={close}
        >
            <div className="dialog-wrapper">
                <div style={{textAlign:'right'}}>
                    <Close onClick={close} className="hoverable" />
                </div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <h1>JCP Importer</h1>
                        <Button2
                            variant="contained"
                            component="label"
                            style={{marginRight:20}}
                        >
                            Upload File
                            <input type='file' hidden onChange={(e) => {
                                openFile(e);
                            }} id="file-importer" style={{marginRight:20}} />
                        </Button2>
                        {files.length !== 0 && <span>
                            <Button style={{marginRight:20}} color="primary"
                                onClick={() => {
                                    setOpenedPreview(true);
                                    props.getRfidJcdata(jcp_import_data, {
                                        filenames: JSON.stringify(files),
                                        final: false
                                    });
                                }}
                            >PREVIEW</Button>
                        </span>}
                        <hr />
                        {files.length === 0 && <div>0 Loaded files</div>}
                        {files.length !== 0 && <div>
                            {files.map((lf,i) => {
                                return <div key={`lf-${i}`} style={{marginBottom:10}}>
                                    File # {i+1} - {lf} - <span style={{color:'red'}} className="hoverable"
                                        onClick={() => {
                                            const newObj = {
                                                gtin: {},
                                                c128: {}
                                            };
                                            Object.keys(jcp_import_data).forEach((type) => {
                                                const jid = jcp_import_data[type];
                                                if (jid) {
                                                    Object.keys(jid).forEach((upc) => {
                                                        newObj[type][upc] = [];
                                                        const jup = jid[upc];
                                                        if (jup) {
                                                            jup.forEach(j => {
                                                                if (j.filename) {
                                                                    if (j.filename !== lf) {
                                                                        newObj[type][upc].push(clone(j));
                                                                    }
                                                                }
                                                            })
                                                        }
                                                        if (newObj[type][upc].length === 0) delete newObj[type][upc];
                                                    })
                                                }
                                            })
                                            setRfidJcdata(clone(newObj));
                                        }}
                                    >remove</span>
                                </div>
                            })}
                        </div>}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                            {openedPreview && <Dialog
                                open={openedPreview}
                                onClose={() => {
                                    setOpenedPreview(false);
                                }}
                                fullWidth={true}
                                maxWidth={false}
                                fullScreen={true}
                            >
                                <JCImporterPreview 
                                    importerData={jcp_import_data}
                                    close={() => {
                                        setOpenedPreview(false);
                                    }}
                                    setRfidJcdata={setRfidJcdata}
                                    getRfidJcdata={props.getRfidJcdata}
                                    excel_id={props.excel_id}
                                    files={files}
                                    restart={restart}
                                />
                            </Dialog>}
                    </GridItem>
                </GridContainer>
            </div>
        </Dialog>
    )
}

const mapStateToProps = state => {
    return {
      jcp_import_data: state.rfid.jcp_import_data ? state.rfid.jcp_import_data : {},
      excel_id: state.rfid.excel_id
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        setRfidJcdata: (jcp_import_data) => {
            dispatch(actions.setRfidJcdata(jcp_import_data));
        },
        getRfidImporterExcel: (data) => {
            dispatch(actions.getRfidImporterExcel(data));
        }
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(JCPImporter);