import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import * as actions from '../../store/actions';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Table from "components/Table/Table.jsx";
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import styles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import Datetime from "react-datetime";
import GINSelect from "./GINselect";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
import InputAdornment from '@material-ui/core/InputAdornment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import BPFormReview from 'containers/BP/BPFormReview';
import cloneDeep from "lodash/cloneDeep";

import Button from '@material-ui/core/Button';
import Button2 from "components/CustomButtons/Button.jsx";
import { PulseLoader } from 'react-spinners';
import { isValidDate, numberWithCommas, checkDateOrder, dateToday, convertToFixed, checkMinMax } from 'shared/utility';
import { ranges } from "./GINranges";
import { checkOneYearEndDate, checkHighestAll, checkExFactoryAll } from "./GINhelpers";
import activeDBs from "shared/activeDBs";

const rangesOptions = ranges.map(r => {
  return { id: r.rangeA, name: numberWithCommas(r.rangeA) }
});

let rangesDict = {};
ranges.forEach(r => {
  rangesDict[r.name] = r.rangeA;
});

const createRangeBlankObj = () => {
  let obj = {};
  ranges.forEach(r => {
    obj[r.name] = '';
  });
  return obj;
}

const rangesBlankObj = createRangeBlankObj();

const rangesBlankObjDates = (rangeName, todayIndex) => {
  let obj = {};
  ranges.forEach(r => {
    if (r.name === rangeName) {
      obj[r.name] = dateToday()[todayIndex];
    } else {
      obj[r.name] = '';
    }
  });
  return obj;
}

const checkRangeName = (qty) => {
  ranges.forEach(r => {
    if (qty >= r.rangeA && qty <= r.rangeB) {
      return r.name;
    }
  });
}

const createObj = (qty, val) => {
  const rbObj = createRangeBlankObj();
  rbObj[qty] = val;
  return rbObj;
}

const coloredSelect = {backgroundColor:'gray',color:'white',fontSize:'12px !important'};

const CustomerList = props => {
  const { customers, previewCardCode } = props;
  return customers.map((c,i) => {
    return (
        <ListItem
          key={'key'+i}
          button
          onClick={() => {
            props.choose(c.CardCode, c.CardName);
          }}
          style={c.CardCode === previewCardCode ? coloredSelect : {}}
          >
          <div><strong>{c.CardCode}</strong></div><br />
          <div style={{marginLeft:'15px'}}>{c.CardName}</div>
        </ListItem>
      )
  });
}

const SelectPop = props => {
  return (
    <Dialog
      onClose={props.onClose}
      open={props.open}
      keepMounted
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle style={{paddingBottom: 0}} onClose={props.onClose}>
        Please select {props.title}
      </DialogTitle>

      <DialogContent style={{overflowY:'unset'}}>
        <GINSelect
          open={true}
          label=""
          options={props.options}
          choose={props.choose}
          name={props.name}
          indx={props.indx}
          desc={props.desc}
          default={props.default}
        />
      </DialogContent>
    </Dialog>
  )
}

class GINaddPriceSpecificCustomer extends React.Component {
  constructor(props) {
    super(props);
    console.log('constructing price specific customer modal', props)
    const data = props.editTableData;
    console.log('props data', data);
    const qty = data.qty ? data.qtyField : 'ALL';
    let moc = {}, mocType = {};
    if (props.mocs[data.DB]) {
      Object.keys(props.mocs[data.DB]).forEach(curr => {
        moc[curr] = null;
        mocType[curr] = 'Standard'
      });
    }
    if (data.mocSpecial) {
      Object.keys(data.mocSpecial).forEach(curr => {
        if (data.mocSpecial[curr].moc === 0 || data.mocSpecial[curr].moc) {
          moc[curr] = data.mocSpecial[curr].moc
          mocType[curr] = 'Special'
        }
      });
    }

    const today = dateToday();

    this.state = {
      loading: false,
      validationMessage: '',
      singleView: true,
      previewBP: false,
      previewCardCode: '',
      previewBPname: '',
      applyToAll: false,

      customer: data.ccode ? data.ccode : '',
      customerSearch: '',
      moc,
      mocObj: props.mocs[data.DB] ? props.mocs[data.DB] : {},
      mocType: mocType ? mocType : {},
      index: data.index || data.index === 0 ? createObj(qty, data.index) : rangesBlankObj,
      qty: data.qty ? createObj(qty, data.qty) : rangesBlankObj,
      term: data.term ? createObj(qty, data.term) : rangesBlankObj,
      price: data.price ? createObj(qty, data.price) : rangesBlankObj,
      code: data.code ? createObj(qty, data.code) : rangesBlankObj,
      currency: data.currency ? createObj(qty, data.currency) : rangesBlankObj,
      start: data.start ? createObj(qty, data.start) : createObj(qty, today[0]),
      end: data.end ? createObj(qty, data.end) : createObj(qty, today[1]),

      termPop: false,
      currencyPop: false,
      popQuantity: '',
      popValue: '',
    }
  }

  getFactory = (db) => {
    let factory = '';
    activeDBs.forEach(d => {
      if (d.name === db) {
        factory = d.factory;
      }
    })
    return factory;
  }

  handleCurrencyClose = () => {
    this.setState({
      currencyPop: false
    });
  }

  handleCurrencyOpen = (qty, value) => {
    this.setState({
      currencyPop: true,
      popQuantity: qty,
      popValue: value
    });
  }

  handleTermClose = () => {
    this.setState({
      termPop: false
    });
  }

  handleTermOpen = (qty, value) => {
    this.setState({
      termPop: true,
      popQuantity: qty,
      popValue: value
    });
  }

  handleSelectChange = (value, name) => {
    this.setState({
      [name]: value
    })
  }

  handleObjectSelectChange = (value, name, key) => {
    if (this.state.applyToAll) {
      this.setState((prevState) => {
        let copy = JSON.parse(JSON.stringify(prevState[name]));
        Object.keys(rangesDict).forEach(r => {
          copy[r] = value;
        });
        return {
          [name]: copy,
          validationMessage: ''
        }
      })
    } else {
      let copy = JSON.parse(JSON.stringify(this.state[name]));
      copy[key] = value;
      this.setState({
        [name]: copy,
        validationMessage: ''
      })
    }
  }

  onIntegerChange = (target, e) => {
    if (!isNaN(e.target.value)) {
      this.setState({
        [target]: Math.round(e.target.value)
      })
    }
  }

  onFloatChange = (target, e) => {
    const re = /^[.0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
       this.setState({value: e.target.value})
    }
  }

  onFloatChangeMOC = (target, e) => {
    let moc = cloneDeep(this.state.moc);
    let mocType = cloneDeep(this.state.mocType);
    if (e.target.value) {
      if (!isNaN(e.target.value)) {
        console.log('moc', e.target.value);
        moc[target] = parseFloat(e.target.value);
        mocType[target] = 'Special';
      }
    } else {
      moc[target] = '';
      mocType[target] = 'Standard';
    }
    this.setState({
      moc,
      mocType,
      validationMessage: ''
    });
  }

  onFloatObjChange = (target, e, key) => {
    const re = /^[.0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      if (this.state.applyToAll) {
        let copy = JSON.parse(JSON.stringify(this.state[target]));
        Object.keys(rangesDict).forEach(r => {
          copy[r] = e.target.value;
        });
        this.setState({
          [target]: copy,
          validationMessage: ''
        })
      } else {
        let copy = JSON.parse(JSON.stringify(this.state[target]));
        copy[key] = e.target.value;
        this.setState({
          [target]: copy,
          validationMessage: ''
        })
      }
    }
  }

  onValueChange = e => {
    this.setState({ valueData: e.target.value.toUpperCase() });
  }

  onDescChange = e => {
    this.setState({ descData: e.target.value.toUpperCase() });
  }

  onValChange = (destination, e) => {
    this.setState({ [destination]: e.target.value });
  }

  handleChange = name => value => {
    this.setState({
      [name]: value,
    });
  };

  handleDateChange = (date, target) => {
    this.setState({
      [target]: typeof date === 'string' ? date : date.format('MMM DD YYYY').toUpperCase()
    });
  }

  handleObjDateChange = (date, target, key) => {
    let copy = JSON.parse(JSON.stringify(this.state[target]));
    if (this.state.applyToAll) {
      Object.keys(rangesDict).forEach(r => {
        copy[r] = typeof date === 'string' ? date : date.format('MMM DD YYYY').toUpperCase();
      });
    } else {
      copy[key] = typeof date === 'string' ? date : date.format('MMM DD YYYY').toUpperCase();
    }
    this.setState({
      [target]: copy,
      validationMessage: ''
    });
  }

  handleClose = () => {
    this.props.close();
  }

  check = (val) => {
    if (val || val === 0) {
      return true;
    }
    return false;
  }

  updateGIN = (collection, indexes) => {
    console.log('here is the collection', collection, indexes);
    const data = this.props.editTableData,
          db = data.DB,
          bmaster = cloneDeep(this.props.bmasterarray),
          customerList = this.props.options[db].pricing_customers,
          customerCode = this.state.customer;

    console.log('first-bmaster',bmaster);
    let customerName = '';
    customerList.forEach(c => {
      if (c.CardCode === customerCode) {
        customerName = c.CardName;
      }
    });

    const key = `${customerCode}[.]${customerName}`;
    if (!bmaster[db]) {
      bmaster[db] = {};
    }
    if (bmaster[db].hasOwnProperty(key)) {
      // edit
      collection.forEach((c,i) => {
        if (indexes[i] === 0 || indexes[i]) {
          console.log('edit existing customer price')
          bmaster[db][key][indexes[i]] = c;
        } else {
          console.log('add new price to existing customer');
          bmaster[db][key].push(c);
        }
      });

      // if there is a MOC and no MOC in bmaster add it
      // EDITS EXISTING MOCS
      let forMOCChange = [], terms = [];
      bmaster[db][key].forEach((a,i) => {
        if (parseInt(a[0]) === 0) {
          if (this.state.moc.hasOwnProperty(a[5])) {
            forMOCChange.push({
              index: i,
              moc: this.state.moc[a[5]]
            });
          }
        }

        const term = a[1];
        if (terms.indexOf(term) === -1) {
          terms.push(term);
        }
      });

      console.log('for moc change',forMOCChange);
      if (forMOCChange.length > 0) {
        forMOCChange.forEach(fm => {
          bmaster[db][key][fm.index][2] = fm.moc ? fm.moc.toString() : '0';
        });
      } else {
        let addMOC = [];
        Object.keys(this.state.moc).forEach(curr => {
          if (this.state.moc[curr] === 0 || this.state.moc[curr]) {
            addMOC.push([
              '0', // qty
              '', // term
              this.state.moc[curr], // price
              '', // code
              this.state.customer, // cardcode
              curr, // currency
              'JAN 01 2019',
              'JAN 01 2020'
            ]);
          }
        });

        bmaster[db][key] = bmaster[db][key].concat(addMOC);
      }

    } else {
      // add
      if (!bmaster[db][key]) {
        console.log('creating a collection');
        if (this.props.editTableData) {
          const data = this.props.editTableData;
          if (!data.ccode) {
            let addMOC = [];
            Object.keys(this.state.moc).forEach(curr => {
              if (this.state.moc[curr] === 0 || this.state.moc[curr]) {
                addMOC.push([
                  '0', // qty
                  '', // term
                  this.state.moc[curr], // price
                  '', // code
                  this.state.customer, // cardcode
                  curr, // currency
                  'JAN 01 2019',
                  'JAN 01 2020'
                ]);
              }
            });

            if (addMOC.length > 0) {
              // add the mocs
              collection = collection.concat(addMOC);
            }
          }
        }
        bmaster[db][key] = collection;
      } else {
        console.log('adding to collection');
        bmaster[db][key] = bmaster[db][key].concat(collection);
      }
    }
    this.props.updateGINEdit({
      db,
      field: 'bmasterarray',
      value: bmaster[db]
    });

    console.log('final bmaster', bmaster);
  }

  handleSave = () => {
    console.log('stprps', this.state, this.props);
    // check
    let isComplete = true, msg = '', withinMinMax = true, isWithinOneYear = true;
    if (!this.state.customer) {
      isComplete = false;
      msg += 'Please select a Customer. '
    }

    // check if all mocs have values and Special
    Object.keys(this.state.moc).forEach(curr => {
      if (!this.state.moc[curr] && this.state.mocType[curr] === 'Special') {
        console.log('state moc detected', this.state.moc[curr])
        if (this.state.moc[curr] !== 0) {
          msg += `If Special MOC ${curr} is checked, please enter an amount. `;
          isComplete = false;
        }
      }
      if (this.state.moc[curr]) {
        if (!checkMinMax(this.state.moc[curr], 5, 'allow zeros')) {
          withinMinMax = false;
        }
      }
    });

    let indexes = [];
    const collection = Object.keys(this.state.price).map((qty, i) => {
      const term = this.state.term[qty],
            price = convertToFixed(this.state.price[qty]),
            code = this.state.code[qty],
            currency = this.state.currency[qty],
            start = this.state.start[qty],
            end = this.state.end[qty],
            index = this.state.index[qty],
            moc = this.state.moc,
            numericQty = rangesDict[qty];

      const all = ((!term && !price) && (!currency && ( !start && !end)));
      if (start && end) {
        if (!checkOneYearEndDate(start, end)) {
          isWithinOneYear = false;
        }
      }
      if (!term || !price || !currency || !start || !end) {
        if (!all) {
            console.log('found one incomplete -----');
            isComplete = false;
        }
        return null;
      } else {
        if (!checkMinMax(price, 5)) {
          withinMinMax = false;
        }
        indexes.push(index);
        return [
          numericQty.toString(),
          term,
          price,
          code,
          this.state.customer,
          currency,
          start,
          end
        ];
      }
    }).filter(arr => {
      if (arr) return true;
    });

    if (collection) {
      if (collection.length === 0) {
        isComplete = false;
      }
    }

    console.log('collect',collection, isComplete);
    if (isComplete && (withinMinMax && isWithinOneYear)) {
      //
      const db = this.props.editTableData.DB;
      if (this.props.bmasterarray[db]) {
        let currencies = [];
        Object.keys(this.props.bmasterarray[db]).forEach(combo => {
          this.props.bmasterarray[db][combo].forEach(pr => {
            if (pr[5]) {
              if (currencies.indexOf(pr[5]) === -1) {
                currencies.push(pr[5]);
              }
            }
          })
        })
        let pricingData = cloneDeep(this.props.rangeCollection);
        const customerName = this.state.customer ? this.getCustomerName(this.state.customer, db) : ''
        Object.keys(this.state.currency).forEach(range => {
          if (this.state.currency[range]) {
            const dataObj = {
              ccode: '',
              code: '',
              currency: this.state.currency[range],
              customerName,
              db: this.props.editTableData.DB,
              term: this.state.term[range],
              start: this.state.start[range],
              end: this.state.end[range],
              price: this.state.price[range],
              qty: this.state.qty[range]
            }
            pricingData[range].push(dataObj);
          }
        })
        console.log('mixed up pricingData', pricingData, this.props.editTableData.DB);
        const pass = checkHighestAll(pricingData, this.props.editTableData.DB, customerName, currencies)
        if (!pass) {
          console.log('NOT PASSED');
          this.setState({
            validationMessage: 'Base Price must always be the highest price for its shipping term compared to other ranges.'
          });
        } else {
          const factory = this.getFactory(this.props.editTableData.DB) ? this.getFactory(this.props.editTableData.DB) : '';
          const passFactory = checkExFactoryAll(pricingData, this.props.editTableData.DB, customerName, factory, currencies, this.props);
          if (!passFactory) {
            this.setState({
              validationMessage: `Ex-Factory price ${factory} should always be the lowest per DB per range. `
            });
          } else {
            console.log('updating collection', collection, indexes)
            this.updateGIN(collection, indexes);
            this.props.close();
          }
          // this.updateGIN(collection, indexes);
          // this.props.close();
        }
      }
    } else {
      if (!withinMinMax) {
        msg += 'Price needs to be between 0.00001 and maximum of 100,000. ';
      }
      if (!isWithinOneYear) {
        msg += 'End Date cannot be more than 1 year from Start Date. '
      }
      if (!isComplete) {
        msg += 'Please complete all fields. '
      }
      this.setState({
        validationMessage: msg
      });
    }

  }

  renderElem = (type, qty, terms, currencies, index) => {
    const realQty = rangesDict[qty];
    const realPrice = this.state.price[qty] ? parseFloat(this.state.price[qty]) : 0;
    let total = (realPrice && realQty) ? numberWithCommas(realPrice * realQty) : 0;
    let moc = 0;
    let isMOC = '';
    if (this.state.moc) {
      if (this.state.moc[this.state.currency[qty]]) {
        moc = this.state.moc[this.state.currency[qty]];
      }
    }
    if (total < moc) {
      total = moc;
      isMOC = this.state.mocType[this.state.currency[qty]];
    }
    return [
      qty,
    <div className="adjust-1-input">
      <CustomInput
        labelText=""
        id="term"
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          onChange: (e) => {

          },
          onClick: () => {
            this.handleTermOpen(qty, this.state.term[qty]);
          },
          value: this.state.term[qty],
          role: 'presentation',
          autoComplete: 'nope'
        }}
      />
    </div>,
    <div className="adjust-1-input">
      <CustomInput
        labelText=""
        id="price"
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          onChange: (e) => {
            this.onFloatObjChange('price', e, qty);
          },
          value: this.state.price[qty],
          role: 'presentation',
          autoComplete: 'nope'
        }}
      />
    </div>,
    <div className="adjust-1-input">
      <CustomInput
        labelText=""
        id="currency"
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          onChange: (e) => {

          },
          onClick: () => {
            this.handleCurrencyOpen(qty, this.state.currency[qty]);
          },
          value: this.state.currency[qty],
          role: 'presentation',
          autoComplete: 'nope'
        }}
      />
    </div>,
      <div className={type === 'single' ? 'calendar-adjust adjust-1-calendar movetop-calendar2' : 'calendar-adjust adjust-1-calendar movetop-calendar'}>
        <Datetime
          inputProps={{ placeholder: "" }}
          dateFormat="MMM DD YYYY"
          timeFormat={false}
          value={this.state.start[qty]}
          onChange={(date) => {
            this.handleObjDateChange(date, 'start', qty);
            if (!this.state.end[qty]) {
              if (typeof date !== 'string') {
                let newDate = date.add('years', 1).format('MMM DD YYYY').toUpperCase();
                let copy = JSON.parse(JSON.stringify(this.state.end));
                if (this.state.applyToAll) {
                  Object.keys(rangesDict).forEach(r => {
                    copy[r] = newDate;
                  });
                } else {
                  copy[qty] = newDate;
                }
                this.setState({
                  end: copy
                });
              }
            }
          }}
          closeOnSelect={true}
        />
      </div>,
      <div className={type === 'single' ? 'calendar-adjust adjust-1-calendar movetop-calendar2' : 'calendar-adjust adjust-1-calendar movetop-calendar'}>
        <Datetime
          inputProps={{ placeholder: "" }}
          dateFormat="MMM DD YYYY"
          timeFormat={false}
          value={this.state.end[qty]}
          onChange={(date) => {
            this.handleObjDateChange(date, 'end', qty);
          }}
          closeOnSelect={true}
        />
      </div>,
      `${this.state.currency[qty]} ${total} ${isMOC}`
    ];
  }

  getOptions = () => {
    let terms = [], currencies = [];
    if (this.props.options[this.props.editTableData.DB]) {
      if (this.props.options[this.props.editTableData.DB].terms) {
        if (this.props.options[this.props.editTableData.DB].terms.pricing_terms) {
          terms = this.props.options[this.props.editTableData.DB].terms.pricing_terms;
        }
      }

      if (this.props.options[this.props.editTableData.DB].currencies) {
        currencies = this.props.options[this.props.editTableData.DB].currencies;
      }
    }
    return {
      terms, currencies
    }
  }

  renderSingleInputs = () => {
    const opts = this.getOptions();
    const terms = opts.terms,
          currencies = opts.currencies;
    const qty = this.props.editTableData.qtyField;
    return [this.renderElem('single',qty, terms, currencies)];
  }

  renderMultipleInputs = () => {
    const opts = this.getOptions(),
          terms = opts.terms,
          currencies = opts.currencies;
    return ranges.map((r,i) => {
      return this.renderElem('multiple',r.name, terms, currencies, i);
    });
  }

  toggleSingleView = () => {
    this.setState({
      singleView: !this.state.singleView
    });
  }

  changeToStandardMOC = (curr) => {
    let copyMOC = cloneDeep(this.state.moc);
    let copyMOCtype = cloneDeep(this.state.mocType);
    copyMOC[curr] = '';
    copyMOCtype[curr] = 'Standard'
    this.setState({
      moc: copyMOC,
      mocType: copyMOCtype
    });
  }

  changeToSpecialMOC = (curr) => {
    let copyMOCtype = cloneDeep(this.state.mocType);
    copyMOCtype[curr] = 'Special';
    this.setState({
      mocType: copyMOCtype
    })
  }

  closeBPFormReview = () => {
    this.setState({
      previewBP: false
    });
  }

  getCustomerName = (ccode, db) => {
    let customerName = '';
    if (this.props.options[db]) {
      this.props.options[db].pricing_customers.forEach(o => {
        if (ccode === o.CardCode) {
          customerName = o.CardName;
        }
      })
    }
    return customerName;
  }

  componentDidMount() {
    if (this.props.editTableData.DB) {
      this.props.fetchAddInfoList(this.props.editTableData.DB);
    }
  }

  render() {
    console.log('GINadd-price-specific-customers', this.props);
    console.log('st and props ---', this.state, this.props);
    let allOpenedCurrs = [];
    Object.keys(this.state.currency).forEach(ccc => {
      if (this.state.currency[ccc]) {
        if (allOpenedCurrs.indexOf(this.state.currency[ccc]) === -1) {
          allOpenedCurrs.push(this.state.currency[ccc]);
        }
      }
    });
    const { classes } = this.props;
    const data = this.props.editTableData;
    const action = data.ccode ? 'EDIT' : 'ADD';
    const customer = this.state.customer ? this.getCustomerName(this.state.customer, data.DB) : '';
    const forSearch = this.state.customerSearch.toUpperCase();
    const customers = forSearch.length > 1 ? this.props.options[data.DB].pricing_customers.filter(c => {
      if (this.state.customerSearch) {
        if (c.CardName.toUpperCase().indexOf(forSearch) !== -1) {
          return true;
        }
      }
    }) : [];
    console.log('customers',customers);
    const mocObj = cloneDeep(this.state.moc);
    let thereAreMOCs = false;
    Object.keys(mocObj).forEach(curr => {
      if (mocObj[curr]) {
        thereAreMOCs = true;
      }
    });
    let pricingCustomers = [];
    if (this.props.options) {
      if (this.props.options[data.DB]) {
        pricingCustomers = cloneDeep(this.props.options[data.DB].pricing_customers);
      }
    }

    let tterms = [], ccurrencies = [];
    if (this.props.options[this.props.editTableData.DB]) {
      if (this.props.options[this.props.editTableData.DB].terms) {
        if (this.props.options[this.props.editTableData.DB].terms.pricing_terms) {
          tterms = this.props.options[this.props.editTableData.DB].terms.pricing_terms;
        }
      }

      if (this.props.options[this.props.editTableData.DB].currencies) {
        ccurrencies = this.props.options[this.props.editTableData.DB].currencies;
      }
    }

    const singleInputs = this.renderSingleInputs(),
          multiInputs = this.renderMultipleInputs(),
          headers = ["QTY","SHIPPING TERMS", "PRICE", "CURRENCY", "EFFECTIVE DATES", "", "TOTAL PRICE"];

    console.log('SETTING @!!', this.state.previewBP, this.props.sapBPreview);
    return (
      <Dialog
        open={this.props.open}
        keepMounted
        onClose={this.handleClose}
        maxWidth="md"
        fullWidth={true}
        className="dialog-overflow2"
      >
        <DialogTitle>
          <div style={{textAlign:'center'}}>{action} {customer} PRICING ({data.DB})</div>
          <div className="close-btn">
            <IconButton aria-label="Close" onClick={this.handleClose}>
              <Close />
            </IconButton>
          </div>
        </DialogTitle>
        <div style={{padding:'0 40px 40px 40px',overflowY:'scroll'}}>
          {this.state.termPop &&
            <SelectPop
              title="Term"
              options={tterms}
              choose={(value, name) => {
                  this.handleObjectSelectChange(value, name, this.state.popQuantity);
                  this.handleTermClose();
                }}
              name="term"
              indx=""
              desc=""
              open={this.state.termPop}
              onClose={this.handleTermClose}
              default={this.state.popValue}
            />
          }
          {this.state.currencyPop &&
            <SelectPop
              title="Currency"
              options={ccurrencies}
              choose={(value, name) => {
                this.handleObjectSelectChange(value, name, this.state.popQuantity);
                this.handleCurrencyClose();
              }}
              name="currency"
              indx="symb"
              desc="symb"
              open={this.state.currencyPop}
              onClose={this.handleCurrencyClose}
              default={this.state.popValue}
            />
          }
          {(!this.state.customer && action !== 'EDIT') &&

          <Dialog
            open={(!this.state.customer && action !== 'EDIT')}
            keepMounted
            onClose={this.handleClose}
            fullWidth={true}
            maxWidth="sm"
          >
            <DialogTitle>
              <div style={{textAlign:'center'}}>SELECT A CUSTOMER</div>
            </DialogTitle>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <div className="dialog-padding">
                  <div className="select-adjust">
                    <CustomInput
                      labelText="Customer Name"
                      id="moca"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: (e) => {
                          this.setState({
                            customerSearch: e.target.value ? e.target.value.toUpperCase() : ''
                          });
                        },
                        value: this.state.customerSearch,
                        role: 'presentation',
                        autoComplete: 'nope',
                        disabled: false,
                        autoFocus: true
                      }}
                    />
                    <div style={{height:'400px',overflowY:'scroll'}}>
                      <CustomerList
                        customers={customers}
                        previewCardCode={this.state.previewCardCode}
                        choose={(cardcode, bpname) => {
                          console.log(bpname, data.DB, cardcode, 'Customer');
                          if (this.state.previewCardCode === cardcode) {
                            this.setState({
                              customer: cardcode,
                              customerSearch: bpname
                            });
                          } else {
                            this.props.loadBPForReview(bpname, data.DB, cardcode, 'Customer', true);
                            this.setState({
                              // previewBP: true,
                              previewCardCode: cardcode,
                              previewBPname: bpname
                            }, () => {
                              console.log('preview BP');
                            });
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="text-center">
                    <Button2 style={{opacity:0}} color="white" onClick={() => {}}></Button2>
                    {this.state.previewCardCode && <Button2 color="success" onClick={() => {
                      this.setState({
                        customer: this.state.previewCardCode,
                        customerSearch: this.state.previewBPname ? this.state.previewBPname : ''
                      });
                    }} style={{marginRight:10}}>SUBMIT</Button2>}
                    {this.state.previewCardCode && <Button2 color="rose" onClick={() => {
                      this.setState({
                        previewBP: true,
                      });
                    }}>REVIEW CUSTOMER</Button2>}
                  </div>
                </div>
              </GridItem>
            </GridContainer>
          </Dialog>
          }
          {this.state.customer && <GridContainer>

            <GridItem xs={12} sm={12} md={6}>
              {(action === 'EDIT' || this.state.customer) && <div>
                  <CustomInput
                    labelText="Customer Name"
                    id="moca"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: (e) => {

                      },
                      value: customer,
                      role: 'presentation',
                      autoComplete: 'nope',
                      disabled: true,
                      onClick: () => {
                      }
                    }}
                  />
                </div>
              }
              {((action === 'EDIT' || this.state.customer) && !this.state.singleView) && <div>
                <Checkbox
                  tabIndex={-1}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{checked: classes.checked}}
                  checked={this.state.applyToAll}
                  onClick={() => {
                    this.setState((prevState) => ({
                      applyToAll: !prevState.applyToAll
                    }));
                  }}
                /><span>Copy to All Quantities</span>
              </div>}
            </GridItem>
            <GridItem xs={12} sm={12} md={1}></GridItem>
            <GridItem xs={12} sm={12} md={5}>
              {
                Object.keys(this.state.mocObj).length > 0 && <div>
                  {
                    Object.keys(this.state.mocObj).map((curry,indd) => {
                      if (allOpenedCurrs.indexOf(curry) === -1) {
                        return null;
                      }
                      return <div className="select-adjust relative" style={{marginTop:'10px'}} key={`${indd}`}>
                        <div style={{position:'absolute',top:'-13px',left:'-50px'}}>
                          <Checkbox
                            tabIndex={-1}
                            checkedIcon={<Check className={classes.checkedIcon} />}
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{checked: classes.checked}}
                            checked={this.state.mocType[curry] === 'Standard' ? true : false}
                            onClick={() => {
                              console.log('you clicked standard', curry);
                              this.changeToStandardMOC(curry);
                            }}
                          />
                        </div>
                        <span className="gray-text">Standard MOC</span>
                        <span key={`a${curry}`} style={{marginLeft:'10px'}}>{curry} {numberWithCommas(this.state.mocObj[curry])}</span>
                      </div>
                    })
                  }
                </div>
              }
            {Object.keys(this.state.mocObj).map((curr,ii) => {
                if (allOpenedCurrs.indexOf(curr) === -1) {
                  return null;
                }
                return (
                <div className="select-adjust relative" style={{marginTop:'20px'}} key={`sd${ii}`}>
                  <div style={{position:'absolute',top:'-13px',left:'-50px'}}>
                    <Checkbox
                      tabIndex={-1}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{checked: classes.checked}}
                      checked={this.state.mocType[curr] === 'Special' ? true : false}
                      onClick={() => {
                        console.log('you clicked special', curr);
                        this.changeToSpecialMOC(curr);
                      }}
                    />
                  </div>
                  <span className="gray-text">Special MOC</span>
                    <CustomInput
                      key={`b${curr}`}
                      labelText=""
                      id={`bb${curr}`}
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: (e) => {
                          this.onFloatChangeMOC(curr, e);
                        },
                        value: this.state.moc[curr],
                        role: 'presentation',
                        autoComplete: 'nope',
                        startAdornment: <InputAdornment position="start">{curr}</InputAdornment>
                      }}
                    />
                  </div>
                )
              })}
            </GridItem>
          </GridContainer>}

          {this.state.customer && <GridContainer>
            {action === 'ADD' && <GridItem xs={12} sm={12} md={12}>
              <div style={{marginTop:'20px'}}>
                <div
                  className={this.state.singleView ? 'orange-bg small-btn' : 'white-bg small-btn'}
                  onClick={() => {
                    this.toggleSingleView();
                    this.setState({
                      applyToAll: false
                    });
                  }}
                  style={{marginRight:'20px',width:'151px'}}
                >SINGLE PRICE</div>
                <div
                  className={this.state.singleView ? 'white-bg small-btn' : 'orange-bg small-btn'}
                  onClick={() => {
                    this.toggleSingleView();
                  }}
                  style={{marginRight:'20px',width:'151px'}}
                >PRICING TABLE</div>
              </div>
            </GridItem>}
            <GridItem xs={12} sm={12} md={12}>
            <div style={{marginTop:'20px'}} className="small-headers">
                {this.state.singleView && (
                  <div>
                    <Table
                      tableHead={headers}
                      tableData={singleInputs}
                      coloredColls={[3]}
                      colorsColls={["primary"]}
                    />
                  </div>
                )

                }

              {!this.state.singleView &&
              <div>
                <div className="hide-table-body">
                  <Table
                    tableHeaderClasses={['table-sticky-header']}
                    tableHeaderColor="primary"
                    tableHead={headers}
                    tableData={singleInputs}
                  />
                </div>
                <div style={{height:'400px',overflowY:'scroll'}} className="dense-table">
                  <Table
                    tableData={multiInputs}
                    coloredColls={[3]}
                    colorsColls={["primary"]}
                  />
                </div>
              </div>
              }
            </div>
            </GridItem>
          </GridContainer>}

          {customer && <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              {this.state.loading && <PulseLoader />}
              <div style={{padding:'20px',textAlign:'center'}}>
                <Button2
                  color="success"
                  onClick={this.handleSave}
                >
                  SAVE
                </Button2>
                {this.state.validationMessage && <div className="red-text text-center" style={{marginTop:'20px'}}>{this.state.validationMessage}</div>}
              </div>
            </GridItem>
          </GridContainer>}
        </div>
        {
          (this.state.previewBP && this.props.sapBPreview) &&
            <BPFormReview
              finalValues={this.props.sapBPreview}
              beforeValues={this.props.sapBPreview}
              open={this.state.previewBP}
              close={this.closeBPFormReview}
              countries={this.props.countriesDictionary}
              states={this.props.statesDictionary}
              gsttypes={this.props.gsttypesDictionary}
              owhtopts={this.props.owhtoptsDictionary}
              BPaction={'PENDING'}
              currentDB={data.DB}
              currentBPType={'Customer'}
              enterPayTerms={true}
            />
        }
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    gcur: state.gin.gcur,
    gstart: state.gin.gstart,
    gend: state.gin.gend,
    gprice: state.gin.gprice,
    gterm: state.gin.gterm,
    gcode: state.gin.gcode,

    gcurrs: state.gin.gcurrs,
    gstarts: state.gin.gstarts,
    gends: state.gin.gends,
    gprices: state.gin.gprices,
    gqtys: state.gin.gqtys,
    gterms: state.gin.gterms,
    gcodes: state.gin.gcodes,

    bmasterarray: state.gin.bmasterarray,
    mocs: state.gin.mocs,
    itemCodes: state.gin.itemCodes,
    openedDBs: state.gin.openedDBs,
    options: state.gin.options,
    sapBPreview: state.common.sapBPreview,
    dbs: state.common.dbs,
    statesDictionary: state.common.statesDictionary,
    countriesDictionary: state.common.countriesDictionary,
    gsttypesDictionary: state.gmp.gsttypesDictionary,
    owhtopts: state.common.addinfo ?
                state.common.addinfo.owhtopts ? state.common.addinfo.owhtopts : null
                : null,
    owhtoptsDictionary: state.common.owhtoptsDictionary,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateGINEdit: (data) => {
      dispatch(actions.updateGINEdit(data));
    },
    loadBPForReview: (bpName, db, cardcode, bp_type, reviewOnly) => {
      dispatch(actions.fetchBPSAP(db, bpName, cardcode, 'pending', bp_type, reviewOnly));
    },
    fetchAddInfoList: (db) => {
      dispatch(actions.fetchAddInfoList(db));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GINaddPriceSpecificCustomer));
