export const returnActivity = (type, num) => {
  const activityObj = {
    1: 'Direct to SAP(EXISTING)',
    2: 'Direct to SAP(NEW)',
    3: 'Deny(EXISTING)',
    4: 'Deny(NEW)',
    5: 'Sent to Pending(EXISTING)',
    6: 'Sent to Pending(NEW)',
    7: 'Approve(EXISTING)',
    8: 'Approve(NEW)'
  }

  const news = [2,4,6,8],
        existings = [1,3,5,7];

  if (type === 'activity') {
    return activityObj[num];
  } else if (type === 'type') {
    return news.indexOf(num) === -1 ? 'EXISTING' : 'NEW';
  } else {
    return Object.values(activityObj);
  }
}
