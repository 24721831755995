import React, { Component } from "react";
import CustomInput from "components/CustomInput/CustomInput2.jsx";

const InputField = (props) => {
  const { classes } = props;
  let error = '';
  if (props.validate) {
    if (props.validate.length > 0) {
      props.validate.forEach((val) => {
        const err = val(props.value);
        if (err) {
          error = err;
        }
      });
    }
  }

  return (
  <div>
    <CustomInput
      labelText={props.label}
      id={props.name}
      formControlProps={{
        fullWidth: true
      }}
      inputProps={{
        onChange: (e) => {
          const start = e.target.selectionStart,
                end = e.target.selectionEnd;
          if (props.normalize) {
            e.target.value = props.normalize(e.target.value);
            e.target.setSelectionRange(start, end);
          }
          if (!props.readonly) {
            props.handleTextInputChange(e, props.name);
          }
        },
        onClick: () => { if (props.onClick) props.onClick(); },
        value: props.value ? props.value : '',
        autoFocus: props.autoFocus ? true : false,
        role: 'presentation',
        autoComplete: 'nope',
        disabled: props.disabled ? true : props.readonly ? true : false,
        readonly: props.readonly ? true : false
      }}
      error={error ? true : false}
    />
    {props.additionalError && <div className="red" style={{marginTop:'10px',fontSize:'10px'}}>{props.additionalError}</div>}
    {error && <div className="red" style={{marginTop:'10px',fontSize:'10px'}}>{error}</div>}
    {!error && <div className="red" style={{marginTop:'10px',opacity:0,fontSize:'10px'}}>_</div>}
    {(props.similar && !error) ? props.similar : null}
    </div>
  );
}

export default InputField;
