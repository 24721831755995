import { put, call } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import axios from '../axios/axios-gin';
import axios2 from 'axios';
import * as actions from '../actions/GIN';
import activeDBs from 'shared/activeDBs';
import devStatus from 'shared/devStatus';
import { phpPortal } from 'shared/devStatus';
import qs from 'qs';

export function* fetchItemListSaga(action) { // saga for getting data from GIN endpoint
    yield put(actions.fetchItemListStart());
    console.log('Fetching Item List Saga....');
    try {
        const response = yield axios.get('/item/'+action.itemcode+'/'+action.db);
        const itemList = response.data;
        console.log('itemlist = ', itemList);
        yield put(actions.fetchItemListSuccess(itemList));
    }
    catch (err) {
        console.log(err);
        yield put(actions.fetchItemListFail(err));
    }
}

export function* ginCheckImporterTableSaga(action) {
  console.log('Checking importer table Saga....');
  try {
      const response = yield axios.get('/check_importer_table');
      const count = response.data;
      console.log('checkimporter table = ', count);
      yield put(actions.ginCheckImporterTableSuccess(count));
  }
  catch (err) {
      console.log(err);
      yield put(actions.ginCheckImporterTableFail(err));
  }
}

export function* fetchItemSaga(action) { // saga for getting data from GIN endpoint
    yield put(actions.fetchItemStart());
    console.log('Fetching Item Saga....', action);
    let lockStatus = null;
    // check item lock
    try {
      const response = yield axios.get(`/lock_item?db=${action.companydb}&itemcode=${action.itemcode}`);
      console.log('response from item lock check', response.data);
      if (response) {
        if (response.data) {
          if (response.data.status) {
            lockStatus = response.data;
          }
        }
      }
    } catch(err) {
      console.log('error checking item lock', err);
    }
    try {
        axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
        axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
        console.log('axios timeout', axios.defaults.timeout);
        const response = yield axios2.post(phpPortal, qs.stringify({
          vless:action.vless, itemget:action.itemcode, companydb:action.companydb
        }), { timeout: 0 });
        // const response = yield axios.post('/iteminfo/', {
        //   vless:action.vless, itemget:action.itemcode, companydb:action.companydb
        // }, { timeout: 0 });
        const itemInfo = response.data;
        if (typeof itemInfo !== 'string') {
          console.log('iteminfo = ', itemInfo);
          yield put(actions.fetchItemSuccess(itemInfo, action.companydb, lockStatus));
          yield put(actions.fetchItemCompare());
        } else {
          console.log('iteminfo = error', itemInfo);
          yield put(actions.fetchItemFail(`Unable to fetch the Item ${action.itemcode} from ${action.companydb}, please try again. `, action.companydb, action.itemcode));
        }
    }
    catch (err) {
        console.log('error fetching item', err);
        yield put(actions.fetchItemFail(err, action.companydb, action.itemcode));
    }
}

export function* fetchBrandSubgroupSaga(action) {
  yield put(actions.fetchBrandSubgroupListStart());
  console.log('fetching!');
  try {
      const response = yield axios.get('/brand_subgroup/');
      const data = response.data;
      console.log(data);
      yield put(actions.fetchBrandSubgroupListSuccess(data));
  }
  catch (err) {
      console.log(err);
      yield put(actions.fetchBrandSubgroupListFail(err));
  }
}

export function* fetchBPNameByCodeSaga(action) {
  yield put(actions.fetchBPNameByCodeStart());
  console.log('fetching BP Name by code');
  try {
      const response = yield axios.get('/bp/'+action.db+'/'+action.cardcodes);
      const data = response.data;
      console.log(data);
      yield put(actions.fetchBPNameByCodeSuccess(data));
  }
  catch (err) {
      console.log(err);
      yield put(actions.fetchBPNameByCodeFail(err));
  }
}

export function* fetchGINOptionsSaga(action) {
  yield put(actions.fetchGINOptionsStart());
  try {
    const response = yield axios.get('/itemoptions/'+action.db);
    const data = response.data;
    console.log('GIN options',data, action.db);
    if (data) {
      yield put(actions.fetchGINOptionsSuccess(data, action.db));
    } else {
      console.log('Failed GIN options 1', action.db);
      yield put(actions.fetchGINOptionsFail('Failed to load data', action.db));
    }
  }
  catch (err) {
    console.log('Failed GIN options 2', action.db);
    yield put(actions.fetchGINOptionsFail(err, action.db));
  }
}

export function* fetchItemMOCsSaga(action) {
  yield put(actions.fetchItemMOCsStart());
  try {
    const response = yield axios.get('/mocs/'+action.db+'/'+action.igc);
    const data = response.data;
    console.log('Item MOC received', data);
    yield put(actions.fetchItemMOCsSuccess({
      db: action.db,
      mocs: data
    }));
  }
  catch (err) {
    yield put(actions.fetchItemMOCsFail(err));
  }
}

export function* fetchItemMOCsAllSaga(action) {
  yield put(actions.fetchItemMOCsAllStart());
  try {
    let mocs = {};
    activeDBs.forEach(db => {
      mocs[db.name] = null;
    });
    for (let i = 0; i < action.dbs.length; i++) {
      console.log('start fetch item', action.dbs[i]);
      const response = yield axios.get(encodeURI('/mocs/'+action.dbs[i]+'/'+action.igc));
      const data = response.data;
      console.log('Item MOC received', data, action.dbs[i], i);
      mocs[action.dbs[i]] = data.mocs;
      if (i === action.dbs.length - 1) {
        console.log('final mocs', mocs);
        yield put(actions.fetchItemMOCsAllSuccess(mocs));
      }
    }
  } catch (err) {
    yield put(actions.fetchItemMOCsAllFail(err));
  }
}

export function* ginSendImageSaga(action) {
  yield put(actions.ginSendImageStart());
  try {
    const response = yield axios.post('/send_image', {
      image: action.image,
      filename: action.ginnum
    });
    const res = response.data;
    console.log(res);
    yield put(actions.ginSendImageSuccess(res));
  } catch (err) {
    yield put(actions.ginSendImageFail(err));
  }
}

function syncAPI(item) {
  return axios.post('/send_item', item);
}

export function* ginCheckTableCountSaga() {
  const count = yield call(countTable);
  yield put(actions.ginSetTableCount(count.data['COUNT(*)']));
}

function saveFailedImport(itemObj, status) {
  return axios.post('/save_failed_imports', { data: itemObj, status });
}

function countTable() {
  return axios.get('/check_importer_table');
}

export function* ginStartQSaga(action) {
  try {
      const response = yield axios.post('/start_item_q', action.item_ids);
      const data = response.data;
      yield put(actions.ginStartQSuccess(data));
  } catch (err) {
    yield put(actions.ginStartQFail(err));
  }
}

export function* ginSendItemNoStartSaga(action) {
  const dbs = Object.keys(action.itemObj);
  let item_ids = [];
  for (var i = 0; i < dbs.length; i++) {
    yield delay(500);
    const dbb = dbs[i].split('--')[0];
    try {
      const item = action.itemObj[dbs[i]];
      console.log('sending item', item);
      const response = yield axios.post('/send_item', item);
      console.log('response.data', response.data);
    } catch (err) {
      console.log('error', err);
    }
  }
}

export function* ginSendItemSaga(action) {
  console.log('send item action', action);

  if (action.lockData) {
    if (action.lockData.length > 0) {
      for (let i = 0; i < action.lockData.length; i++) {
        const db = action.lockData[i].db;
        const itemcode = action.lockData[i].itemcode;
        yield axios.post(`/lock_item`, {
          db, itemcode, action: 'LOCK_PENDING'
        })
      }
    }
  }

  let gin_id = null;
  try {
    const response = yield axios.post('/new_gin_validation/', {
      data: action.emailData,
      ginNum: action.ginnum,
      submitter2_id: action.submitter_id,
      unique_history_id: action.unique_history_id
    });
    const data = response.data;
    console.log('data from new gin validation', data);
    gin_id = data.id;
  } catch(e) {
    console.log('error getting new gin validation id',e);
  }

  if (action.removeCostlist) {
    if (action.removeCostlist.length > 0) {
      try {
        yield put(actions.ginRemoveCostStart());
        const response = yield axios.post('/remove_costs/', {
          removeCostslist: action.removeCostlist,
          gin_id
        });
        const data = response.data;
        console.log('response from removing cost', data);
        yield put(actions.ginRemoveCostSuccess(data));
      } catch (err) {
        console.log('response from removing bom fail');
        yield put(actions.ginRemoveCostFail(err));
      }
    }
  }

  if (action.removeBOMlist) {
    if (action.removeBOMlist.length > 0) {
      try {
        yield put(actions.ginRemoveBOMStart());
        const response = yield axios.post('/remove_bom/', {
          removeBOMlist: action.removeBOMlist,
          gin_id
        });
        const data = response.data;
        console.log('response from removing bom', data);
        yield put(actions.ginRemoveBOMSuccess(data));
      } catch (err) {
        console.log('response from removing bom fail');
        yield put(actions.ginRemoveBOMFail(err));
      }
    }
  }

  if (action.deactivateList) {
    if (action.deactivateList.length > 0) {
      try {
        yield put(actions.ginDeactivateStart());
        const response = yield axios.post('/deactivate/', {
          deactivateList: action.deactivateList,
          gin_id
        });
        const data = response.data;
        console.log('response from failed imports', data);
        yield put(actions.ginDeactivateSuccess(data));
      } catch (err) {
        yield put(actions.ginDeactivateFail(err));
      }
    }
  }

  if (action.reactivateList) {
    if (action.reactivateList.length > 0) {
      try {
        yield put(actions.ginReactivateStart());
        for (let x = 0; x < action.reactivateList.length; x++) {
          const reactivateData = action.reactivateList[x].split(' ');
          const db = reactivateData[0];
          const itemcode = reactivateData[1];
          console.log('reactivating', db, itemcode);
          const response = yield axios.post('/reactivate/', {
            db,
            itemcode
          });
          const data = response.data;
          console.log('response from reactivate', data);
        }
        yield put(actions.ginReactivateSuccess());
      } catch (err) {
        console.log('err', err);
        yield put(actions.ginReactivateFail(err));
      }
    }
  }
  const send = yield axios.post('/send_gin_email', {
    data: action.emailData
  })

  const dbs = Object.keys(action.itemObj);
  let item_ids = [];
  for (var i = 0; i < dbs.length; i++) {
    const dbb = dbs[i].split('--')[0];
    try {
      const dataSet = action.itemObj[dbs[i]];
      dataSet.gin_id = gin_id;
      const response = yield axios.post('/save_imports', { data: dataSet, status:1 });
      console.log(response.data);
      if (response.data) {
        if (response.data.submission_id) {
          item_ids.push(response.data.submission_id);
        }
      }
      yield put(actions.ginSendItemSuccess(response.data, dbb));
      if (i === (dbs.length-1)) {
        const response2 = yield axios.post('/start_item_q', { item_ids: item_ids });
        console.log('response from starting item queue', response2);
        yield put(actions.ginFinishImporting());
        console.log('item ids gathered are', item_ids);
      }
    } catch (err) {
      yield put(actions.ginSendItemFail(err, dbb));
    }
  }

  if (action.removeGINlist) {
    if (Object.keys(action.removeGINlist).length > 0) {
      console.log('sending GIN removal', action.removeGINlist);
      try {
        const response = yield axios.post('/remove_gin/', {
          removeGINlist: action.removeGINlist,
          gin_id
        });
        const data = response.data;
        console.log('response from removing GIN', data);
      } catch (err) {
        console.log('error removing GIN', err);
      }
    }
  }

  if (action.removePricelist) {
    if (Object.keys(action.removePricelist).length > 0) {
      console.log('sending Price removal', action.removePricelist);
      try {
        const response = yield axios.post('/remove_allpricing/', {
          removePricelist: action.removePricelist,
          gin_id
        });
        const data = response.data;
        console.log('response from removing Price', data);
      } catch (err) {
        console.log('error removing Price', err);
      }
    }
  }
}

export function* ginCheckExistsSaga(action) {
  yield put(actions.ginCheckExistsStart());
  try {
      const response = yield axios.get('/check/'+action.ginnum);
      const data = response.data;
      console.log(data);
      yield put(actions.ginCheckExistsSuccess(data));
  }
  catch (err) {
      console.log(err);
      yield put(actions.ginCheckExistsFail(err));
  }
}

export function* ginGetFailedImportSaga(action) {
  try {
    const response = yield axios.get('/get_failed_imports/');
    const data = response.data;
    console.log('response from failed imports', data);
    yield put(actions.ginGetFailedImportSuccess(data));
  } catch (err) {
    yield put(actions.ginGetFailedImportFail(err));
  }
}

export function* ginSetFailedImportSaga(action) {
  try {
    const response = yield axios.post('/set_failed_imports/', { status: action.status, id: action.id });
    const data = response.data;
    console.log('response from failed imports', data);
    yield put(actions.ginSetFailedImportSuccess(data));
  } catch (err) {
    yield put(actions.ginSetFailedImportFail(err));
  }
}

export function* ginDeactivateSaga(action) {
  try {
    const response = yield axios.post('/deactivate/', { deactivateList: action.deactivateList });
    const data = response.data;
    console.log('response from failed imports', data);
    yield put(actions.ginDeactivateSuccess(data));
  } catch (err) {
    yield put(actions.ginDeactivateFail(err));
  }
}

export function* ginAutoSaveSaga(action) {
  yield put(actions.ginAutoSaveStart());
  console.log('autosavedata', action.id, action.gin);
  try {
    const response = yield axios.post('/autosave', {
      data: action.data,
      gin: action.gin,
      id: action.id
    });
    const data = response.data;
    yield put(actions.ginAutoSaveSuccess(data));
  } catch(err) {
    yield put(actions.ginAutoSaveFail(err));
  }
}

export function* ginAutoSaveLoadSaga(action) {
  yield put(actions.ginAutoSaveLoadStart());
  try {
    const response = yield axios.get('/autosave/'+action.id);
    const data = response.data;
    if (data) {
      if (data[0]) {
        if (data[0].data) {
          const converted = JSON.parse(data[0].data);
          const finalConversion = JSON.parse(converted);
          console.log('finalConversion', finalConversion);
          if (finalConversion) {
            let isLocked = false;
            const lockData = {};
            let lockMsg = '';
            // CHECK LOCK STATUS
            if (finalConversion.itemCodes) {
              const dbs = Object.keys(finalConversion.itemCodes);
              for (let i = 0; i < dbs.length; i++) {
                const db = dbs[i];
                const lock_response = yield axios.get(`/lock_item?db=${db}&itemcode=${finalConversion.itemCodes[db]}`);
                console.log('lock_response', lock_response);
                if (lock_response) {
                  if (lock_response.data) {
                    const lr = lock_response.data;
                    if (lr.status === 'LOCKED') {
                      isLocked = true;
                      lockData[db] = lr;
                      lockMsg = `${lr.itemcode} is locked by ${lr.name}. `;
                    }
                  }
                }
              }
            }

            console.log('lock status ', isLocked, lockMsg);
            if (!isLocked) {
              yield put(actions.ginAutoSaveLoadSuccess(data, action.id));

              if (finalConversion.openedDBs) {
                if (finalConversion.openedDBs.length > 0) {
                  const dbs = finalConversion.openedDBs;
                  for (let i = 0; i < dbs.length; i++) {
                    yield put(actions.fetchGINOptionsStart(dbs[i]));
                    try {
                      const response2 = yield axios.get('/itemoptions/'+dbs[i]);
                      const data2 = response2.data;
                      console.log('GIN options',data2);
                      console.log('loading GIN options --', dbs[i]);
                      yield put(actions.fetchGINOptionsSuccess(data2, dbs[i]));
                    }
                    catch (err) {
                      yield put(actions.fetchGINOptionsFail(err));
                    }
                  }
                }
              }
              // LOCK ITEMS
              if (finalConversion.itemCodes) {
                const dbs = Object.keys(finalConversion.itemCodes);
                for (let i = 0; i < dbs.length; i++) {
                  const db = dbs[i];
                  const lock_response = yield axios.post('/lock_item', {
                    db, itemcode: finalConversion.itemCodes[db], action:'LOCK'
                  });
                }
              }
            } else {
              yield put(actions.ginAutoSaveLoadFail(lockMsg));
              return;
            }

          }
        }
      }
    }
    yield put(actions.ginAutoSaveLoadEnd());
  } catch(err) {
    yield put(actions.ginAutoSaveLoadFail(err));
  }
}

export function* ginAutoSaveLoadAllSaga(action) {
  yield put(actions.ginAutoSaveLoadAllStart());
  try {
    const response = yield axios.get('/autosave_all/');
    const data = response.data;
    yield put(actions.ginAutoSaveLoadAllSuccess(data));
  } catch(err) {
    yield put(actions.ginAutoSaveLoadAllFail(err));
  }
}

export function* ginAutoSaveImageSaga(action) {
  try {
    const response = yield axios.post('/autosave_image', {
      image: action.image,
      filename: action.filename
    });
    const res = response.data;
    console.log('autosave success saving image', res);
  } catch (err) {
    console.log('autosave error saving image', err);
  }
}

export function* ginAutoSaveDeleteSaga(action) {
  try {
    const response = yield axios.post('/autosave_delete', {
      id: action.id
    });
    const res = response.data;
    yield put(actions.ginAutoSaveDeleteSuccess(res));
    console.log('autosave success deleting', res);
  } catch (err) {
    yield put(actions.ginAutoSaveDeleteFail(err));
    console.log('autosave error deleting', err);
  }
}

export function* ginLoadImageSaga(action) {
  try {
    console.log('autosave success loading '+action.filename)
    const response = yield axios.get('/autosave_image/'+action.filename);
    const res = response.data;
    console.log('autosave success loading image', res);
    if (!res.hasOwnProperty('image')) {
      console.log('no image found');
      const gin = action.filename.split('_')[1];
      res.image = `https://portal.natcoglobal.com/portal/api/item/ftp_gin_image.php?gin=${gin}`;
      // yield put(actions.ginLoadImageSuccess(res));
    } else {
      yield put(actions.ginLoadImageSuccess(res));
    }
  } catch (err) {
    console.log('autosave error loading image', err);
  }
}

export function* ginGetExistingSaga(action) {
  console.log('getting', action);
  yield put(actions.ginGetExistingStart());
  try {
    const response = yield axios.get('/all_gins/'+action.gincode+'/'+action.itemcode);
    const res = response.data;
    yield put(actions.ginGetExistingSuccess(res));
    console.log('load existing gins success', res);
  } catch (err) {
    yield put(actions.ginGetExistingFail(err));
    console.log('load existing gins error', err);
  }
}

export function* ginLoadExistingSaga(action) {
  console.log('loading existing GIN', action.data);
  yield put(actions.ginLoadExistingStart());
  yield put(actions.setGINloading(true));
  let collection = {};
  action.data.forEach(dat => {
    const arr = dat.split('[.]'),
          db = arr[0],
          itemcode = arr[1],
          vless = itemcode.split('_')[0],
          combo = `${itemcode}[.]${vless}`;
    if (!collection.hasOwnProperty(db)) {
      collection[db] = [combo];
    } else {
      collection[db].push(combo);
    }
  })
  let dbs = Object.keys(collection);
  let tpp_item_type = '';
  let gmp = false,
      existingGINsBefore = {},
      failedOptions = [],
      failedItems = [],
      retryFailedOptions = [],
      retryFailedItems = [];
  for (let i = 0; i < dbs.length; i++) {
    const arr = collection[dbs[i]];

    // fetch options
    yield put(actions.setGINloading(true));
    yield put(actions.setLoadingGIN(dbs[i]));
    yield put(actions.fetchGINOptionsStart());
    try {
      const response = yield axios.get('/itemoptions/'+dbs[i]);
      const data = response.data;
      console.log('GIN options',data);
      yield put(actions.fetchGINOptionsSuccess(data, dbs[i]));
    }
    catch (err) {
      failedOptions.push(dbs[i]);
      yield put(actions.fetchGINOptionsFail(err, dbs[i]));
    }

    // fetch item details
    yield put(actions.setGINloading(true));
    if (arr.length > 0) {
      const selected = arr[0].split('[.]');
      const vless = selected[1],
            itemcode = selected[0];
      console.log('Fetching Item Saga....', vless, itemcode, dbs[i]);
      yield put(actions.fetchItem(vless, itemcode, dbs[i]));
      // lock
      yield axios.post('/lock_item', {
        db: dbs[i],
        itemcode: vless,
        action: 'LOCK'
      });
      //yield put(actions.fetchItemStart());
      try {
          console.log('existing loading', vless, itemcode, dbs[i]);
          const response = yield axios2.post(phpPortal, qs.stringify({vless, itemget:itemcode, companydb:dbs[i]}));
          const itemInfo = response.data;
          if (typeof itemInfo !== 'string') {
            console.log('iteminfo = ', itemInfo);
            if (itemInfo.gmp_checkbox) {
              if (itemInfo.gmp_checkbox !== 'No') {
                gmp = true;
              }
            }
            if (itemInfo.tpp_item_type) {
              tpp_item_type = itemInfo.tpp_item_type;
            }

            // collect existing GIN items before data
            // for comparing at submission time, to know which one to
            // take off GIN if taken off
            existingGINsBefore[dbs[i]] = [vless];
            if (itemInfo.vars) {
              if (itemInfo.vars.length > 0) {
                itemInfo.vars.forEach(vc => {
                  existingGINsBefore[dbs[i]].push(`${vless}_${vc[0]}`)
                })
              }
            }

            yield put(actions.fetchItemSuccess(itemInfo, dbs[i]));
            yield put(actions.setGINloading(true));
          } else {
            failedItems.push(dbs[i]);
            console.log('iteminfo = error', itemInfo);
            yield put(actions.fetchItemFail(`Unable to fetch the Item ${itemcode} from ${dbs[i]}, please try again. `, dbs[i], itemcode));
          }
      }
      catch (err) {
          console.log('error fetching item', err);
          failedItems.push(dbs[i]);
          yield put(actions.fetchItemFail(err, action.companydb, action.itemcode));
      }
    }
  }
  console.log("GIN DBs that failed...", failedItems, failedOptions)
  console.log("existingGINsBefore", existingGINsBefore);

  if (failedOptions.length > 0) {
    // retry options
    dbs = failedOptions;
    for (let i = 0; i < failedOptions.length; i++) {
      const arr = collection[dbs[i]];

      // fetch options
      yield put(actions.setGINloading(true));
      yield put(actions.setLoadingGIN(dbs[i]));
      yield put(actions.fetchGINOptionsStart());
      try {
        const response = yield axios.get('/itemoptions/'+dbs[i]);
        const data = response.data;
        console.log('GIN options',data);
        yield put(actions.fetchGINOptionsSuccess(data, dbs[i]));
      }
      catch (err) {
        retryFailedOptions.push(dbs[i]);
        yield put(actions.fetchGINOptionsFail(err, dbs[i]));
      }
    }
  }

  if (failedItems.length > 0) {
    // retry items
    dbs = failedItems;
    for (let i = 0; i < failedItems.length; i++) {
      const arr = collection[dbs[i]];
      // fetch item details
      yield put(actions.setGINloading(true));
      if (arr.length > 0) {
        const selected = arr[0].split('[.]');
        const vless = selected[1],
              itemcode = selected[0];
        console.log('Re-Fetching Item Saga....', vless, itemcode, dbs[i]);
        yield put(actions.fetchItem(vless, itemcode, dbs[i]));
        //yield put(actions.fetchItemStart());
        try {
            const response = yield axios2.post(phpPortal, qs.stringify({vless, itemget:itemcode, companydb:dbs[i]}));
            const itemInfo = response.data;
            if (typeof itemInfo !== 'string') {
              console.log('iteminfo = ', itemInfo);
              if (itemInfo.gmp_checkbox) {
                if (itemInfo.gmp_checkbox !== 'No') {
                  gmp = true;
                }
              }

              // collect existing GIN items before data
              // for comparing at submission time, to know which one to
              // take off GIN if taken off
              existingGINsBefore[dbs[i]] = [vless];
              if (itemInfo.vars) {
                if (itemInfo.vars.length > 0) {
                  itemInfo.vars.forEach(vc => {
                    existingGINsBefore[dbs[i]].push(`${vless}_${vc[0]}`)
                  })
                }
              }

              yield put(actions.fetchItemSuccess(itemInfo, dbs[i]));
              yield put(actions.setGINloading(true));
            } else {
              console.log('iteminfo = error', itemInfo);
              yield put(actions.fetchItemFail(`Unable to fetch the Item ${itemcode} from ${dbs[i]}, please try again. `, dbs[i], itemcode));
            }
        }
        catch (err) {
            console.log('error fetching item', err);
            retryFailedItems.push(dbs[i]);
            yield put(actions.fetchItemFail(err, action.companydb, action.itemcode));
        }
      }
    }
  }
  yield put(actions.ginLoadExistingSuccess(gmp, existingGINsBefore));
  yield put(actions.fetchItemCompare());
  yield put(actions.setGINloading(false));
}

export function* loadPreviewDataSaga(action) {
  console.log('loading existing GIN', action.data);
  const collection = {};
  action.data.forEach(dat => {
    const datArr = dat.split('[.]'),
          db = datArr[0],
          itemcode = datArr[1],
          vless = itemcode.split('_')[0];
    if (!collection.hasOwnProperty(db)) {
      collection[db] = [itemcode];
    } else {
      collection[db].push(itemcode);
    }
  })
  console.log('collection', collection);
  yield put(actions.loadPreviewDataStart());
  const dbs = Object.keys(collection),
        dataFetched = {};
  for (let i = 0; i < dbs.length; i++) {
    const arr = collection[dbs[i]];
    if (arr.length > 0) {
      const selected = arr[0].split('[.]');
      const vless = selected[0].split('_')[0],
            itemcode = selected[0];
      console.log('Fetching Item Saga....', vless, itemcode, dbs[i]);
      try {
          const response = yield axios2.post(phpPortal, qs.stringify({vless, itemget:itemcode, companydb:dbs[i]}));
          const itemInfo = response.data;
          if (typeof itemInfo !== 'string') {
            console.log('iteminfo = ', itemInfo, dbs[i]);
            dataFetched[dbs[i]] = itemInfo;
          } else {
            console.log('iteminfo = error', itemInfo);
          }
      }
      catch (err) {
        console.log('error fetching item', err);
      }
    }
  }
  console.log('finalData', dataFetched);
  yield put(actions.loadPreviewDataSuccess(dataFetched, action.rbos));
}

export function* ginRenameImageSaga(action) {
  yield put(actions.ginRenameImageStart());
  try {
    const response = yield axios.post('/rename_image/', {
      oldName: action.oldName,
      newName: action.newName
    });
    const res = response.data;
    yield put(actions.ginRenameImageSuccess(res));
    console.log('rename image success', res);
  } catch (err) {
    yield put(actions.ginRenameImageFail(err));
    console.log('rename image fail', err);
  }
}

export function* ginSavePendingSaga(action) {
  // yield put(actions.ginSavePendingStart());
  if (action.lockData) {
    if (action.lockData.length > 0) {
      for (let i = 0; i < action.lockData.length; i++) {
        const db = action.lockData[i].db;
        const itemcode = action.lockData[i].itemcode;
        yield axios.post(`/lock_item`, {
          db, itemcode, action: 'UNLOCK_PENDING'
        })
      }
    }
  }

  console.log('gin saving');
  try {
    const response = yield axios.post('/pending/', {
      gin: action.gin,
      autosave_id: action.autosave_id,
      type: action.ginType === 'NEW' ? 1 : 2,
      subject: action.subject,
      msg: action.msg
    });
    const res = response.data;
    console.log('ginSavePending success', res);
    yield put(actions.ginSavePendingSuccess(res));
  } catch (err) {
    console.log('ginSavePending err', err);
    yield put(actions.ginSavePendingFail(err));
  }
}

export function* ginLoadPendingSaga(action) {
  console.log('load pending', action);
  yield put(actions.ginLoadPendingStart());
  try {
    const response = yield axios.get('/pending/');
    const res = response.data;
    console.log('load pending res', res);
    yield put(actions.ginLoadPendingSuccess(res));
  } catch (err) {
    console.log('ginLoadPending err', err);
    yield put(actions.ginLoadPendingFail(err));
  }
}

export function* ginDenyPendingSaga(action){
  console.log('denying pending', action);
  try {
    const dbs = Object.keys(action.itemCodes);
    for (let i = 0; i < dbs.length; i++) {
      const db = dbs[i];
      const itemcode = action.itemCodes[db];
      const lock_response = yield axios.post('lock_item', {
        db,
        itemcode,
        action: 'UNLOCK_PENDING'
      });
    }
    const response = yield axios.post('/deny_pending/',{
      id: action.id
    });
    const res = response.data;
    yield put(actions.ginDenyPendingSuccess(res));
  } catch (err) {
    console.log('ginDenyPending err', err);
    yield put(actions.ginDenyPendingFail(err));
  }
}

export function* ginApprovePendingSaga(action){
  try {
    const response = yield axios.post('/approve_pending/',{
      id: action.id
    });
    const res = response.data;
    yield put(actions.ginApprovePendingSuccess(res));
  } catch (err) {
    console.log('ginDenyPending err', err);
    yield put(actions.ginApprovePendingFail(err));
  }
}

export function* ginCheckPendingSaga(action) {
  yield put(actions.ginCheckPendingStart());
  try {
    const response = yield axios.get('/check_pending/');
    const res = response.data;
    yield put(actions.ginCheckPendingSuccess(res));
  } catch (err) {
    console.log('ginCheckPending err', err);
    yield put(actions.ginCheckPendingFail(err));
  }
}

export function* ginSaveHistorySaga(action) {
  yield put(actions.ginSaveHistoryStart());
  console.log('save history');
  try {
    const response = yield axios.post('/history/', {
      data: action.data
    });
    const res = response.data;
    yield put(actions.ginSaveHistorySuccess(res));
  } catch (err) {
    yield put(actions.ginSaveHistoryFail(err));
  }
}

export function* ginLoadHistorySaga(action) {
  yield put(actions.ginLoadHistoryStart());
  try {
    const response = yield axios.get('/history/');
    const res = response.data;
    yield put(actions.ginLoadHistorySuccess(res));
  } catch (err) {
    yield put(actions.ginLoadHistoryFail(err));
  }
}

export function* ginLoadHistoryEntrySaga(action) {
  yield put(actions.ginLoadHistoryEntryStart());
  try {
    const response = yield axios.get('/history_entry/'+action.id);
    const data = response.data;
    console.log('got data',data)
    yield put(actions.ginLoadHistoryEntrySuccess(data));
    if (data) {
      if (data[0]) {
        if (data[0].data) {
          const converted = JSON.parse(data[0].data);
          const finalConversion = JSON.parse(converted);
          if (finalConversion) {
            if (finalConversion.openedDBs) {
              if (finalConversion.openedDBs.length > 0) {
                const dbs = finalConversion.openedDBs;
                for (let i = 0; i < dbs.length; i++) {
                  yield put(actions.fetchGINOptionsStart());
                  try {
                    const response2 = yield axios.get('/itemoptions/'+dbs[i]);
                    const data2 = response2.data;
                    console.log('GIN options',data2);
                    yield put(actions.fetchGINOptionsSuccess(data2, dbs[i]));
                  }
                  catch (err) {
                    yield put(actions.fetchGINOptionsFail(err));
                  }
                }
              }
            }
          }
        }
      }
    }
  } catch (err) {
    yield put(actions.ginLoadHistoryEntryFail(err));
  }
}

export function* getItemLocksSaga(action) {
  yield put(actions.getItemLocksStart());
  try {
    const response = yield axios.get(`/locked_items`);
    const res = response.data;
    yield put(actions.getItemLocksSuccess(res));
  } catch(err) {
    yield put(actions.getItemLocksFail(err));
  }
}

export function* checkItemLockSaga(action) {
  yield put(actions.checkItemLockStart());
  try {
    const response = yield axios.get(`/lock_item?db=${action.db}&itemcode=${action.itemcode}`);
    const res = response.data;
    if (res) {
      yield put(actions.checkItemLockSuccess(res));
    }
  } catch(err) {
    yield put(actions.checkItemLockFail(err));
  }
}

export function* checkItemsLockSaga(action) {
  yield put(actions.checkItemsLockStart());
  try {
    let arr_res = [];
    const arr = action.arr;
    for (let i = 0; i < arr.length; i++) {
      const db = arr[i].db;
      const itemcode = arr[i].itemcode;
      const response = yield axios.get(`/lock_item?db=${db}&itemcode=${itemcode}`);
      const res = response.data;
      arr_res.push(res);
    }
    yield put(actions.checkItemsLockSuccess(arr_res));
  } catch(err) {
    yield put(actions.checkItemsLockFail(err));
  }
}

export function* updateItemLockSaga(action) {
  yield put(actions.updateItemLockStart());
  try {
    const response = yield axios.post(`/lock_item`, {
      db: action.db,
      itemcode: action.itemcode,
      action: action.action
    });
    const res = response.data;
    if (res) {
      yield put(actions.updateItemLockSuccess(res));
    }
  } catch(err) {
    yield put(actions.updateItemLockFail(err));
  }
}

export function* updateItemsLockSaga(action) {
  try {
    const arr = action.arr;
    for (let i = 0; i < arr.length; i++) {
      const db = arr[i].db;
      const itemcode = arr[i].itemcode;
      const action_text = action.pending ? 'UNLOCK_PENDING' : 'UNLOCK';
      if (itemcode && db) {
        const response = yield axios.post(`/lock_item`, {
          db,
          itemcode,
          action: action_text
        });
        const res = response.data;
        if (res) {
          console.log('unlocked', res);
        }
      }
    }
    window.location.reload();
  } catch(err) {
    console.log('err unlocking', err);
  }
}

export function* releaseUserLockedGINSaga(action) {
  try {
    const response = yield axios.post(`/lock_item`, {
      db: 'N/A',
      itemcode: 'N/A',
      action: 'RELEASE_USER'
    });
    const res = response.data;
    if (res) {
      console.log('unlocked and released', res);
    }
  } catch(err) {
    console.log('err unlocking', err);
  }
}
