import { arraysEqual } from 'shared/utility';
import { permsList, permsDict } from 'shared/permsObj';

const createForFilter = (filterObj, DBs) => {
  let obj = {};
  Object.keys(filterObj).forEach((fo) => {
    obj[fo] = filterObj[fo].map((dbname) => {
      const dd = DBs[dbname];
      return dd === undefined ? null : dd;
    });
  });
  return obj;
}

const calculateFilteredUserList = (filterObj, DBs, userBulkFilterPerms, userListed) => {
  console.log('received filterObj', filterObj);
  if (Object.keys(filterObj).length === 0) {
    console.log('returning userListed',userListed);
    return userListed;
  }
  const filterObjConverted = createForFilter(filterObj, DBs);
  console.log('userListed',userListed);
  console.log('filterObjConverted', filterObjConverted);
  console.log('permsList',permsList);
  // reduce list if the dbs exist
  const filteredDBs = userBulkFilterPerms.filter(ub => {
    // check if perm_id has DBs
    if (permsList.hasDBs.indexOf(ub.perm_id) !== -1) {
      // check if db_id is part of the filterObject
      if (filterObjConverted[ub.perm_id]) {
         if (filterObjConverted[ub.perm_id].indexOf(ub.db_id) !== -1) {
           return true;
         }
      }
    } else {
      // has no dbs
      return true;
    }
  });
  console.log('filtered DBs', filteredDBs); //

  // narrow down all unique user ids from filteredDBs
  let filteredObj = {},
      filteredCombos = [];

  filteredDBs.forEach((f) => {
    const elem = `${f.perm_id}_${f.db_id}`;
    if (filteredObj.hasOwnProperty(f.user_id)) {
      filteredObj[f.user_id].push(elem);
    } else {
      filteredObj[f.user_id] = [elem];
    }
  });

  Object.keys(filterObjConverted).forEach((perm_id) => {
    const f = filterObjConverted[perm_id];
    let elem;
    if (f[0] === null) {
      elem = `${perm_id}_null`;
      if (filteredCombos.indexOf(elem) === -1) {
        filteredCombos.push(elem);
      }
    } else {
      f.forEach((db_id) => {
        elem = `${perm_id}_${db_id}`;
        if (filteredCombos.indexOf(elem) === -1) {
          filteredCombos.push(elem);
        }
      });
    }
  });
  console.log('filtered Obj', filteredObj, filteredCombos);

  // let filteredList = Object.keys(filteredObj);
  let filteredList = [];
  Object.keys(filteredObj).forEach((user_id) => {
    // let isComplete = true;
    // if (filteredObj[user_id].length !== filteredCombos.length) {
    //   isComplete = false;
    // } else {
    //   if (!arraysEqual(filteredObj[user_id], filteredCombos)) {
    //     isComplete = false;
    //   }
    // }
    // if (isComplete) {
    //   filteredList.push(user_id);
    // }

    let hasCombo = true;
    filteredCombos.forEach((combo) => {
      if (hasCombo) {
        if (filteredObj[user_id].indexOf(combo) === -1) {
          hasCombo = false;
        }
      }
    });
    if (hasCombo) {
      filteredList.push(user_id);
    }
  });

  console.log('finalUserList', filteredList);

  const userList = (filteredList.length > 0 && filteredCombos.length > 0) ? userListed.filter(ub => {
    if (filteredList.indexOf(ub.id.toString()) !== -1) {
      return true;
    }
  }) : userListed;

  return userList;
}

export default calculateFilteredUserList;
