import React from "react";
import { connect } from 'react-redux';
import * as actions from 'store/actions';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { PulseLoader } from 'react-spinners';
import Button from "components/CustomButtons/Button.jsx";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { blacklist } from 'shared/validation';
import BPInput from "containers/BP/fields/BPInput";

class BPVendorCodeDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.data.value,
      error: ''
    }
  }

  handleTextInputChange = (e, field) => {
    if (e.target.value.length > 25) {
      this.setState({
        [field]: e.target.value,
        error: 'Maximum 25 characters only'
      })
    } else {
      const err = blacklist(e.target.value)
      const error = err ? err : '';
      this.setState({
        [field]: e.target.value,
        error
      })
    }
  }

  render() {
    const props = this.props;
    const edi = this.props.data ? this.props.data.field.split('_')[1] : '';
    console.log('vendor code dialog props', props, this.state);
    let allow = false,
        aa_results = '',
        magento_results = '',
        aa_colorStyle = '',
        magento_colorStyle = '',
        aaStyle = {},
        mmStyle = {};

    let bplist = '';
    if (props.vendorCodeResults) {
      if (props.vendorCodeResults.aa_bps) {
        if (props.vendorCodeResults.aa_bps.length > 0) {
          bplist = props.vendorCodeResults.aa_bps.map((bp,key) => {
            const label = <div><strong>{bp.BPCode}</strong> {bp.BPName} - {bp.DBName}</div>
            return (
              <ListItem
                key={'key'+key}
                button
                onClick={() => {
                }}
                >
                <div style={{marginLeft:'15px'}}>{label}</div>
              </ListItem>
            )
          });
        }
      }

      const vc = props.vendorCodeResults.vendor_code;
      if (vc == this.state.value) {
        if (props.vendorCodeResults.aa_results == 0) {
          aa_results = 'Vendor Code Not Present in RBO Table';
          aa_colorStyle = 'green';
        } else {
          if (props.vendorCodeResults.aa_bps) {
            let foundBP = false;
            if (props.cardcode) {
              props.vendorCodeResults.aa_bps.forEach(bp => {
                if (bp === props.cardcode) {
                  foundBP = true;
                }
              });
            }
            aa_results = foundBP ? 'Vendor Code already associated with this BP' : 'Vendor Code already associated to a BP';
            aa_colorStyle = 'red';
          }
        }

        if (props.vendorCodeResults.magento_results == 0) {
          magento_results = `No TPP Orders Found for Vendor Code ${vc}`;
          magento_colorStyle = 'green';
        } else {
          magento_results = `TPP Orders Found with Vendor code ${vc}`;
          magento_colorStyle = 'red';
        }

        if (aa_results === 'Vendor Code Not Present in RBO Table' && magento_results === `No TPP Orders Found for Vendor Code ${vc}`) {
          allow = true;
        }
      }
    }

    aaStyle = aa_colorStyle ? { color: aa_colorStyle } : {};
    mmStyle = magento_colorStyle ? { color : magento_colorStyle } : {};

    return (
      <Dialog
        open={props.open}
        keepMounted
        onClose={props.close}
        fullWidth={true}
        maxWidth="sm"
      >

        <DialogTitle>
          <IconButton edge="end" color="inherit" onClick={props.close} aria-label="Close" style={{float:'right'}}>
            <CloseIcon />
          </IconButton>
          <div className="text-center">
            <h4><strong>Edit Vendor Code</strong></h4>
          </div>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={(e) => {
            e.preventDefault();
            if (this.state.value && !this.state.error) {
              this.props.choose(this.state.value, this.props.data.field);
              this.props.checkVendorCodes([this.state.value], [edi]);
              this.props.close();
            }
          }}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <BPInput
                  label=""
                  name="value"
                  value={this.state.value}
                  handleTextInputChange={this.handleTextInputChange}
                  validate={[]}
                  autoFocus={true}
                  additionalError={this.state.error}
                />
                {this.props.data.beforeValue ? <div className="gray-text">Original Value: {this.props.data.beforeValue}</div> : ''}
                {
                  <div style={{textAlign:'center'}}>
                    <div>{magento_results}</div>
                  </div>
                }
                <div style={{marginTop:20, textAlign:'center'}}>
                  {/*this.state.value && <Button
                    onClick={() => {
                      this.props.checkVendorCode(this.state.value, edi);
                    }}
                    type="submit"
                    color="rose"
                    style={{marginRight:20}}
                  >CHECK EXISTENCE</Button>*/}
                  {this.state.value && <Button
                    onClick={() => {
                      if (this.state.value && !this.state.error) {
                        this.props.choose(this.state.value, this.props.data.field);
                        this.props.checkVendorCodes([this.state.value], [edi]);
                        this.props.close();
                      }
                    }}
                    type="submit"
                    color="success"
                  >SUBMIT</Button>}
                  <div>
                  {bplist}
                  </div>
                </div>
              </GridItem>
            </GridContainer>
          </form>
        </DialogContent>
      </Dialog>
    )
  }
}

const mapStateToProps = state => {
  return {
    vendorCodeLoading: state.gmp.vendorCodeLoading,
    vendorCodeResults: state.gmp.vendorCodeResults
  }
}

const mapDispatchToProps = dispatch => {
  return {
    checkVendorCode: (code, edi) => {
      dispatch(actions.checkVendorCode(code, edi));
    }
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(BPVendorCodeDialog);
