import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import * as actions from '../../store/actions';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import activeDBs from "shared/activeDBs";
import Button2 from "components/CustomButtons/Button.jsx";
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import cloneDeep from "lodash/cloneDeep";

const DBlabel = props => {
    // props.name
    let backgroundColor = '';
    activeDBs.forEach((db) => {
      if (db.name === props.name) {
        backgroundColor = db.color;
      }
    });

    if (backgroundColor) {
      return (
        <span style={{backgroundColor}} className="db-pill">
          {props.name.split('_')[0]}
        </span>
      );
    }
    return null;
  }

const equivalent = {
    gqty: 'qty',
    gcur: 'currency',
    gstart: 'start',
    gend: 'end',
    gprice: 'price',
    gterm: 'term',

    gcurrs: 'currency',
    gstarts: 'start',
    gends: 'end',
    gprices: 'price',
    gqtys: 'qty',
    gterms: 'term'
}

class GINcopyPrice extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: [],
            available: [],
            similarDBs: [],
            error: ''
        }
    }

    handleClose = () => {
        this.props.close();
    }

    handleSave = () => {
        const data = this.props.editTableData,
              forCopying = this.state.selected;
        let finalObj = {};
        // gather all DBs with no gcur (singles)
        let singles = [], multiples = [];
        forCopying.forEach(db => {
          if (!this.props.gprice[db]) {
            singles.push(db);
          } else {
            multiples.push(db);
          }
        });

        console.log('singles, multiples', singles, multiples);
        // change the gcur (singles)
        if (singles.length > 0) {
          ['gcur','gstart','gend','gprice','gterm','gcode'].forEach((field, i) => {
              let copy = cloneDeep(this.props[field])
              singles.forEach(db => {
                  if (field === 'gcode') {
                      copy[db] = ''
                  } else {
                      copy[db] = data[equivalent[field]]
                  }
              });
              finalObj[field] = copy;
            })
        }

        // change the gcurrs (multiples)
        if (multiples.length > 0) {
          ['gcurrs','gstarts','gends','gprices','gqtys','gterms','gcodes'].forEach((field) => {
              let copy = cloneDeep(this.props[field]);
              multiples.forEach(db => {
                  if (copy[db]) {
                      if (copy[db].constructor === Array) {
                        console.log('field',copy[db]);
                        if (field === 'gcodes') {
                          copy[db] = [ ...copy[db], '' ];
                          finalObj[field] = copy;
                        } else {
                          copy[db] = [ ...copy[db], data[equivalent[field]] ];
                          finalObj[field] = copy;
                        }

                      }
                    }
              });
            });
        }

        // moc types
        if (data.mocType === 'Special') {
          // check if target MOC's standard is the same (in $)
          let dbsPassed = [], dbsStandardDuplicates = [];
          forCopying.forEach(db => {
            if (this.props.mocs[db]) {
              if (data.moc && this.props.mocs[db]['$']) {
                if (data.moc != this.props.mocs[db]['$']) {
                  dbsPassed.push(db);
                } else {
                  dbsStandardDuplicates.push(db);
                }
              }
            }
          });
          let copy = cloneDeep(this.props.specialmoc);
          dbsPassed.forEach(db => {
            if (copy[db]) {
              if (copy[db].length === 0) {
                copy[db] = [ ...copy[db], ['$',data.moc,''] ];
                finalObj.specialmoc = copy;
              } else {
                let foundDollar = false;
                copy[db].forEach((mc,i,mcc) => {
                  if (mc[0] === '$') {
                    foundDollar = true;
                    mcc[i][1] = data.moc;
                  }
                });
                if (!foundDollar) {
                  copy[db] = [ ...copy[db], ['$',data.moc,''] ];
                }
                finalObj.specialmoc = copy;
              }
            }
          });

          // change the $ specialmoc
          dbsStandardDuplicates.forEach(db => {
            if (copy[db]) {
              if (copy[db].length > 0) {
                let foundDollarIndex = -1;
                copy[db].forEach((mc,i,mcc) => {
                  if (mc[0] === '$') {
                    foundDollarIndex = i;
                  }
                });
                copy[db] = copy[db].filter((c,i) => {
                  if (i !== foundDollarIndex) {
                    return true;
                  }
                });
                finalObj.specialmoc = copy;
              }
            }
          });
        }

        if (data.mocType === 'Standard') {
          let copy = cloneDeep(this.props.specialmoc);
          forCopying.forEach(db => {
            if (copy[db]) {
              if (copy[db].length > 0) {
                let foundDollarIndex = -1;
                copy[db].forEach((mc,i,mcc) => {
                  if (mc[0] === '$') {
                    foundDollarIndex = i;
                  }
                });
                copy[db] = copy[db].filter((c,i) => {
                  if (i !== foundDollarIndex) {
                    return true;
                  }
                });
                finalObj.specialmoc = copy;
              }
            }
          });
        }

        console.log('this is the final object to copy prices to', finalObj, finalObj.specialmoc);
        this.props.updateGINMultiple(finalObj);
        this.handleClose();
    }

    componentDidMount() {
        // get term options
        let similarDBs = [], error = '', missingTerm = [], missingCurrency = [];
        const data = this.props.editTableData;
        this.props.openedDBs.forEach(db => {
            if (this.props.options[db]) {
                if (this.props.options[db].terms) {
                    if (this.props.options[db].terms.pricing_terms) {
                        let foundTerm = false;
                        this.props.options[db].terms.pricing_terms.forEach(term => {
                            if (term === data.term) {
                                foundTerm = true;
                            }
                        });
                        if (!foundTerm) {
                            if (missingTerm.indexOf(db) === -1) {
                                missingTerm.push(db);
                            }
                        }
                    }
                }

                if (this.props.options[db].currencies) {
                    if (this.props.options[db].currencies.length > 0) {
                        let foundCurrency = false;
                        this.props.options[db].currencies.forEach(curr => {
                            if (curr.symb === data.currency) {
                                foundCurrency = true;
                            }
                        });
                        if (!foundCurrency) {
                            if (missingCurrency.indexOf(db) === -1) {
                                missingCurrency.push(db);
                            }
                        }
                    }
                }
            }
        });

        console.log('here are the missing terms and currencies' ,missingTerm, missingCurrency);
        let error1 = '';
        if (missingTerm.length > 0) {
            error1 += `The following DBs do not have ${data.term} ship term: `
            missingTerm.forEach(dbb => {
                error1 += ` ${dbb} `
            });
            error1 += `. `
        }

        let error2 = '';
        if (missingCurrency.length > 0) {
            error2 += `The following DBs do not have ${data.currency} currency: `
            missingCurrency.forEach(dbb => {
                error2 += ` ${dbb} `
            });
            error2 += `. `
        }

        if (error1 || error2) {
            error = <div><div>{error1}</div><div>{error2}</div></div>
        }

        const selected = this.props.openedDBs.filter(db => {
            if (db !== this.props.editTableData.db) {
                if (missingTerm.indexOf(db) === -1 && missingCurrency.indexOf(db) === -1) {
                    return true;
                }
            }
        })

        this.setState({
            selected,
            available: [ ...selected ],
            error
        });
    }

    render() {
        const { classes } = this.props;
        const data = this.props.editTableData;
        console.log('this is the data',data, this.state, this.props);
        return (
            <Dialog
                open={this.props.open}
                keepMounted
                onClose={this.handleClose}
                maxWidth="md"
                fullWidth={true}
                className="dialog-overflow2"
            >
                <DialogTitle>
                  <div style={{textAlign:'center'}}>COPY PRICING FROM ({data.db}) TO ALL OTHER DBs (Qty:{data.qty})</div>
                  <div className="close-btn">
                    <IconButton aria-label="Close" onClick={this.handleClose}>
                      <Close />
                    </IconButton>
                  </div>
                </DialogTitle>
                <div style={{padding:'0 40px 20px 40px'}}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <div>Source Price :</div>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            <DBlabel name={data.db} />
                                        </TableCell>
                                        <TableCell>
                                            {data.term}
                                        </TableCell>
                                        <TableCell>
                                            {data.currency}{data.price}
                                        </TableCell>
                                        <TableCell>
                                            {data.start} TO {data.end}
                                        </TableCell>
                                        <TableCell>
                                            MOC:{data.currency} {data.moc} {data.mocType}
                                        </TableCell>
                                        <TableCell>
                                            CURRENCY: USD
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={12}>
                        {this.state.available.length > 0 && <div style={{marginTop:20}}>
                            <span style={{marginRight:10}}>Apply to these DBs (click to deselect):</span>
                            {this.state.available.map(db => {
                                    if (db !== data.db) {
                                        return <span
                                            key={`a${db}`}
                                            className={this.state.selected.indexOf(db) !== -1 ? 'hovered' : 'opacity-25 hovered'}
                                            style={{marginRight:10}}
                                            onClick={() => {
                                                if (this.state.selected.indexOf(db) !== -1) {
                                                    this.setState((prevState) => {
                                                        return {
                                                            selected: prevState.selected.filter(dbsel => db !== dbsel)
                                                        }
                                                    })
                                                } else {
                                                    this.setState((prevState) => {
                                                        return {
                                                            selected: [ ...prevState.selected, db]
                                                        }
                                                    })
                                                }
                                            }}
                                        ><DBlabel name={db} /> </span>
                                    } else {
                                        return null;
                                    }
                                })}
                            </div>}

                        {this.state.available.length === 0 && <div style={{marginTop:20}}>* No available DBs to copy to.</div>}
                        </GridItem>
                        <GridItem xs={12} sm={6} md={12}>
                            <div style={{padding:'20px',textAlign:'center'}}>
                                {this.state.error && <div className="red-text text-center" style={{marginTop:20,marginBottom:20}}>{this.state.error}</div>}
                                {this.state.selected.length > 0 && <Button2
                                color="success"
                                onClick={this.handleSave}
                                >
                                SAVE
                                </Button2>}
                                {this.state.selected.length === 0 && <Button2
                                color="rose"
                                onClick={this.handleClose}
                                >
                                CLOSE
                                </Button2>}
                            </div>
                        </GridItem>
                    </GridContainer>
                </div>
            </Dialog>
        )
    }


}

const mapStateToProps = state => {
    return {
        gcur: state.gin.gcur,
        gstart: state.gin.gstart,
        gend: state.gin.gend,
        gprice: state.gin.gprice,
        gterm: state.gin.gterm,
        gcode: state.gin.gcode,

        gcurrs: state.gin.gcurrs,
        gstarts: state.gin.gstarts,
        gends: state.gin.gends,
        gprices: state.gin.gprices,
        gqtys: state.gin.gqtys,
        gterms: state.gin.gterms,
        gcodes: state.gin.gcodes,

        mocs: state.gin.mocs,
        specialmoc: state.gin.specialmoc,

        itemCodes: state.gin.itemCodes,
        openedDBs: state.gin.openedDBs,
        options: state.gin.options,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
      updateGINEdit: (data) => {
        dispatch(actions.updateGINEdit(data));
      },
      updateGINMultiple: (data) => {
        dispatch(actions.updateGINMultiple(data));
      }
    }
  }

export default connect(mapStateToProps,mapDispatchToProps)(GINcopyPrice);
