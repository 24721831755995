import axios from 'axios';
import base from './axiosBaseUrl';

const instance = axios.create({
    baseURL: base+'/api/ActiveDirectory/'
})

instance.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization =  token;
  }
  return config;
});

export default instance;
