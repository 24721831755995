import * as actionTypes from './actionTypes';

// GET_SM_MAGENTO_ORDERS 
export const getSmMagentoOrders = (data) => {
    return {
        type: actionTypes.GET_SM_MAGENTO_ORDERS,
        data
    }
}
export const getSmMagentoOrdersStart = () => {
    return {
        type: actionTypes.GET_SM_MAGENTO_ORDERS_START
    }
}
export const getSmMagentoOrdersSuccess = (response) => {
    return {
        type: actionTypes.GET_SM_MAGENTO_ORDERS_SUCCESS,
        response
    }
}
export const getSmMagentoOrdersFail = (err) => {
    return {
        type: actionTypes.GET_SM_MAGENTO_ORDERS_FAIL,
        err
    }
}

// GET_SM_MAGENTO_ORDERS_OTHERS 
export const getSmMagentoOrdersOthers = (data) => {
    return {
        type: actionTypes.GET_SM_MAGENTO_ORDERS_OTHERS,
        data
    }
}
export const getSmMagentoOrdersOthersStart = () => {
    return {
        type: actionTypes.GET_SM_MAGENTO_ORDERS_OTHERS_START
    }
}
export const getSmMagentoOrdersOthersSuccess = (response) => {
    return {
        type: actionTypes.GET_SM_MAGENTO_ORDERS_OTHERS_SUCCESS,
        response
    }
}
export const getSmMagentoOrdersOthersFail = (err) => {
    return {
        type: actionTypes.GET_SM_MAGENTO_ORDERS_OTHERS_FAIL,
        err
    }
}

// GET_SM_MAGENTO_CUSTOMERS 
export const getSmMagentoCustomers = (data) => {
    return {
        type: actionTypes.GET_SM_MAGENTO_CUSTOMERS,
        data
    }
}
export const getSmMagentoCustomersStart = () => {
    return {
        type: actionTypes.GET_SM_MAGENTO_CUSTOMERS_START
    }
}
export const getSmMagentoCustomersSuccess = (response) => {
    return {
        type: actionTypes.GET_SM_MAGENTO_CUSTOMERS_SUCCESS,
        response
    }
}
export const getSmMagentoCustomersFail = (err) => {
    return {
        type: actionTypes.GET_SM_MAGENTO_CUSTOMERS_FAIL,
        err
    }
}

// GET_BP_BY_NAME 
export const getBpByName = (data) => {
    return {
        type: actionTypes.GET_BP_BY_NAME,
        data
    }
}
export const getBpByNameStart = () => {
    return {
        type: actionTypes.GET_BP_BY_NAME_START
    }
}
export const getBpByNameSuccess = (response) => {
    return {
        type: actionTypes.GET_BP_BY_NAME_SUCCESS,
        response
    }
}
export const getBpByNameFail = (err) => {
    return {
        type: actionTypes.GET_BP_BY_NAME_FAIL,
        err
    }
}

// GET_SAP_ORDER_DETAILS 
export const getSapOrderDetails = (data) => {
    return {
        type: actionTypes.GET_SAP_ORDER_DETAILS,
        data
    }
}
export const getSapOrderDetailsStart = () => {
    return {
        type: actionTypes.GET_SAP_ORDER_DETAILS_START
    }
}
export const getSapOrderDetailsSuccess = (response) => {
    return {
        type: actionTypes.GET_SAP_ORDER_DETAILS_SUCCESS,
        response
    }
}
export const getSapOrderDetailsFail = (err) => {
    return {
        type: actionTypes.GET_SAP_ORDER_DETAILS_FAIL,
        err
    }
}

// GET_SAP_BP_DETAILS 
export const getSapBpDetails = (data) => {
    return {
        type: actionTypes.GET_SAP_BP_DETAILS,
        data
    }
}
export const getSapBpDetailsStart = () => {
    return {
        type: actionTypes.GET_SAP_BP_DETAILS_START
    }
}
export const getSapBpDetailsSuccess = (response) => {
    return {
        type: actionTypes.GET_SAP_BP_DETAILS_SUCCESS,
        response
    }
}
export const getSapBpDetailsFail = (err) => {
    return {
        type: actionTypes.GET_SAP_BP_DETAILS_FAIL,
        err
    }
}

// GET_SAP_BPA_DETAILS 
export const getSapBpaDetails = (data) => {
    return {
        type: actionTypes.GET_SAP_BPA_DETAILS,
        data
    }
}
export const getSapBpaDetailsStart = () => {
    return {
        type: actionTypes.GET_SAP_BPA_DETAILS_START
    }
}
export const getSapBpaDetailsSuccess = (response) => {
    return {
        type: actionTypes.GET_SAP_BPA_DETAILS_SUCCESS,
        response
    }
}
export const getSapBpaDetailsFail = (err) => {
    return {
        type: actionTypes.GET_SAP_BPA_DETAILS_FAIL,
        err
    }
}

// GET_PI_LIST_FILTER 
export const getPiListFilter = (data) => {
    return {
        type: actionTypes.GET_PI_LIST_FILTER,
        data
    }
}
export const getPiListFilterStart = () => {
    return {
        type: actionTypes.GET_PI_LIST_FILTER_START
    }
}
export const getPiListFilterSuccess = (response) => {
    return {
        type: actionTypes.GET_PI_LIST_FILTER_SUCCESS,
        response
    }
}
export const getPiListFilterFail = (err) => {
    return {
        type: actionTypes.GET_PI_LIST_FILTER_FAIL,
        err
    }
}

export const setPI = (val) => {
    return {
        type: actionTypes.SET_PI,
        val
    }
}

// GET_SAP_BPA_ITEM_DETAILS 
export const getSapBpaItemDetails = (data) => {
    return {
        type: actionTypes.GET_SAP_BPA_ITEM_DETAILS,
        data
    }
}
export const getSapBpaItemDetailsStart = () => {
    return {
        type: actionTypes.GET_SAP_BPA_ITEM_DETAILS_START
    }
}
export const getSapBpaItemDetailsSuccess = (response) => {
    return {
        type: actionTypes.GET_SAP_BPA_ITEM_DETAILS_SUCCESS,
        response
    }
}
export const getSapBpaItemDetailsFail = (err) => {
    return {
        type: actionTypes.GET_SAP_BPA_ITEM_DETAILS_FAIL,
        err
    }
}

// GET_MAGENTO_BP_DETAILS 
export const getMagentoBpDetails = (data) => {
    return {
        type: actionTypes.GET_MAGENTO_BP_DETAILS,
        data
    }
}
export const getMagentoBpDetailsStart = () => {
    return {
        type: actionTypes.GET_MAGENTO_BP_DETAILS_START
    }
}
export const getMagentoBpDetailsSuccess = (response) => {
    return {
        type: actionTypes.GET_MAGENTO_BP_DETAILS_SUCCESS,
        response
    }
}
export const getMagentoBpDetailsFail = (err) => {
    return {
        type: actionTypes.GET_MAGENTO_BP_DETAILS_FAIL,
        err
    }
}
