import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table';
import SAPMonitorOrderDetails from './SAPMonitorOrderDetails';
import sapMonitorData from './SAPMonitorLogic';
import moment from 'moment';
const { extractXMLData, extractSOPI, getPaymentMethod } = sapMonitorData;

const formatDate = (str) => {
    if (str) {
        const str_arr = str.split(' ')[0];
        const darr = str_arr.split('-');
        return `${darr[1]}/${darr[2]}/${darr[0]}`;
    }
    return '';
}

const SAPMonitorList = props => {
    const [openedOrderDetails, setOpenedOrderDetails] = useState(false);
    const [order, setOrder] = useState(null);
    useEffect(() => {

    })
    console.log('SAPMonitorList', props);

    if (props.data) {
        props.data.forEach(pd => {
            console.log('pd', pd.entity_id, pd.shipping_info);
        })
    }

    const getData = (data) => {
        let dats = [];
        data.forEach(dat => {
            let pass = true;
            const db = dat.store_name ? dat.store_name.split(' ')[0] : '';
            let card_codes = [];
            let items = [];
            let tii = [];
            let tit = [];
            const payment_method = getPaymentMethod(dat);
            const shippingData = extractXMLData(dat);
            const so_pi = dat.sap_so_number ? dat.sap_so_number : extractSOPI(shippingData)
            if (props.filter) {
                if (props.filter.sap_so_number) {
                    if (so_pi !== props.filter.sap_so_number) {
                        pass = false;
                    }
                }
                if (props.filter.payment_method) {
                    if (props.filter.payment_method !== payment_method) {
                        pass = false;
                    }
                }
            }
            dat.items.forEach(di => {
                if (di.extension_attributes) {
                    if (di.extension_attributes.sap_card_code) {
                        if (card_codes.indexOf(di.extension_attributes.sap_card_code) === -1) { 
                            card_codes.push(di.extension_attributes.sap_card_code);
                        }
                    }

                    if (di.extension_attributes.sap_item_code) {
                        if (items.indexOf(di.extension_attributes.sap_item_code) === -1) { 
                            items += `${di.extension_attributes.sap_item_code} `;
                        }
                    }
                }
            })
            if (pass) dats.push({
                date: moment(dat.created_at.replace(' ','T')).subtract(8, 'hours').format('MM/DD/YYYY'),
                magento_order_number: dat.increment_id ? parseInt(dat.increment_id) : 0,
                magento_customer_email: dat.customer_email,
                database: db,
                items: dat.items.map(tim => {
                    if (tim.extension_attributes) {
                        const sap_item_code = tim.extension_attributes.sap_item_code;
                        if (sap_item_code) {
                            if (tii.indexOf(sap_item_code) === -1) {
                                tii.push(sap_item_code);
                                return <div key={`${sap_item_code}`}>{sap_item_code}</div>;
                            }
                        }
                    }
                    return null;
                }),
                card_code: dat.items.map(tim => {
                    if (tim.extension_attributes) {
                        const sap_card_code = tim.extension_attributes.sap_card_code;
                        if (sap_card_code) {
                            if (tit.indexOf(sap_card_code) === -1) {
                                tit.push(sap_card_code);
                                return <div key={`${sap_card_code}`}>{sap_card_code}</div>;
                            }
                        }
                    }
                    return null;
                }),
                card_code_single: dat.items.length > 0 ? 
                    dat.items[0].extension_attributes ? 
                        dat.items[0].extension_attributes.sap_card_code 
                    : 
                        null
                :
                    null,
                status: dat.status ? dat.status.replace(/_/g, ' ').toUpperCase() : '',
                order_total_amount: dat.total_invoiced ? parseFloat(dat.total_invoiced) : 0,
                company: dat.company_name,
                orderData: dat,
                shippingData,
                so_pi,
                payment_method
            });
        })
        return dats;
    }

    const data = getData(props.data);

    const columns = [
        {
            Header: "Date",
            accessor: "date" 
        },
        {
            Header: "#",
            accessor: "magento_order_number"
        },
        {
            Header: "Email",
            accessor: "magento_customer_email"
        },
        {
            Header: "DB",
            accessor: "database"
        },
        {
            Header: "SAP SO",
            accessor: "so_pi"
        },
        // {
        //     Header: "Items",
        //     accessor: "items"
        // },
        {
            Header: "Company",
            accessor: "company"
        },
        {
            Header: "Status",
            accessor: "status"
        },
        {
            Header: "Payment method",
            accessor: "payment_method"
        }
    ]

    return <div className="table-adjust2">
            <h3>Orders</h3>
            <ReactTable 
                filterable
                className='-striped -highlight' 
                showPaginationTop 
                showPaginationBottom 
                resizable={true} 
                defaultPageSize={10} 
                pageSizeOptions={[10, 20, 30]}
                data={data} 
                columns={columns} 
                getTrProps={(state, rowInfo) => {
                    if (rowInfo && rowInfo.row) {
                    return {
                        onClick: (e) => {
                        console.log(rowInfo);
                        if (rowInfo) {
                            if (rowInfo.original) {
                                setOrder(rowInfo.original);
                                setOpenedOrderDetails(true);
                            }
                        }
                        }
                    }
                    } else {
                    return {}
                    }
                }}
            />
            {(openedOrderDetails && order) && <SAPMonitorOrderDetails 
                open={openedOrderDetails}
                close={() => {
                    setOpenedOrderDetails(false);
                }}
                order={order}
            />}
        </div>
}

export default SAPMonitorList;