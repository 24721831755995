import axios from 'axios';
import base from './axiosBaseUrl';

const instance = axios.create({
    baseURL: base+'/api/gin/'
})

console.log('instance default 1', instance.defaults);
instance.defaults.timeout = 1000 * 60 * 10;
console.log('instance default 2', instance.defaults);
instance.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization =  token;
  }
  return config;
});

instance.interceptors.response.use(function (response) {
    // Do something with response data
    return response;
  }, function (error) {
    // Do something with response error
    if (error.response) {
      if(error.response.status === 401) {
        console.log("Redirection needed ! 1");
        window.location.href = '/pages/login-page';
      }
    } else {
      console.log('error', error);
    }
    return Promise.reject(error);
});

export default instance;
