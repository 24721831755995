import React from "react";
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import checkPermissions from '../../shared/checkPermissions';
import checkActions from '../../shared/checkPermissionsActions';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import History from "@material-ui/icons/History";
import GINsingle from "containers/GINHistory/GINsingle";
import GINgroup from "containers/GINHistory/GINgroup";
import GINreviewDetailed from "containers/GIN/GINreview-detailed";
import GINHistoryFilterModal from "containers/GINHistory/GINHistoryFilterModal";
import GINMain from "containers/GIN/GINMain";
import GINreviewShort from "containers/GIN/GINreview-short";
import Popup from "containers/GINHistory/GINpopup";
import Button from "components/CustomButtons/Button.jsx";
import { filterByDate } from "shared/utility";

class GINHistory extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      view: 'single',
      historyStartDate: null,
      historyEndDate: null,
      openFilterModal: false,
      openMain: false,
      reviewData: null,
      groupData: null,
      groupIndex: null
    }
  }

  setGroupData = (groupData) => {
    this.setState({groupData});
  }

  openFilter = () => {
    console.log('open');
    this.setState({openFilterModal:true})
  }

  closeFilter = () => {
    this.setState({openFilterModal:false})
  }

  setDateFilter = (historyStartDate, historyEndDate) => {
    this.setState({historyStartDate, historyEndDate});
  }

  selectView = (view) => {
    this.setState({view})
  }

  openReviewModal = (user, date, activity) => {
    console.log('opening review');
    this.setState({
      reviewModal:true,
      openMain:true,
      reviewData: {
        user, date, activity
      }
    })
  }

  selectIndex = (groupIndex) => {
    this.setState({groupIndex})
  }

  closeReviewModal = () => {
    this.setState({
      reviewModal:false,
      openMain:false,
      reviewData: null
    })
  }

  componentDidMount() {
    this.props.loadHistory();
  }

  render() {
    if (!checkPermissions('GINHistory')) {
      return <Redirect to="/dashboard" />;
    }
    console.log('historyData',this.props.historyData);
    const historyData = this.props.historyData ? filterByDate(this.props.historyData,
      'timestamp',
      this.state.historyStartDate,
      this.state.historyEndDate) : [];

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <div className="pointed" onClick={() => {
                  this.props.loadHistory();
                }}><History /></div>
              </CardIcon>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <div className="centered-btns">
                    <Button
                      color={this.state.view === 'single' ? 'rose' : 'white'}
                      onClick={() => {
                        this.selectView('single');
                      }}
                    >Single View</Button>
                    <Button
                      color={this.state.view === 'group' ? 'rose' : 'white'}
                      onClick={() => {
                        this.selectView('group');
                      }}
                    >Group View</Button>
                  </div>
                </GridItem>
              </GridContainer>
              {this.state.view === 'single' && <GINsingle
                                              historyData={historyData}
                                              openReviewModal={this.openReviewModal}
                                              openFilter={this.openFilter}
                                              historyStartDate={this.state.historyStartDate}
                                              historyEndDate={this.state.historyEndDate}
                                              loadHistoryEntry={this.props.loadHistoryEntry}
                                            />}
              {this.state.view === 'group' && <GINgroup
                                              historyData={historyData}
                                              openReviewModal={this.openReviewModal}
                                              openFilter={this.openFilter}
                                              historyStartDate={this.state.historyStartDate}
                                              historyEndDate={this.state.historyEndDate}
                                              loadHistoryEntry={this.props.loadHistoryEntry}
                                              setGroupData={this.setGroupData}
                                              selectIndex={this.selectIndex}
                                            />}
              {this.state.openReview && <GINreviewDetailed
                checkDupes={this.checkDupes}
                checkDupesSupplierCost={this.checkDupesSupplierCost}
                checkDupesTargetCost={this.checkDupesTargetCost}
                checkDupesCustomerPrice={this.checkDupesCustomerPrice}
                scrollToDB={this.state.scrollToDB} />}
              {this.state.openFilterModal && <GINHistoryFilterModal
                closeFilter={this.closeFilter}
                open={this.state.openFilterModal}
                setDateFilter={this.setDateFilter}
                historyStartDate={this.state.historyStartDate}
                historyEndDate={this.state.historyEndDate}
                />}
              {this.state.openMain && <Popup
                open={this.state.openMain}
                close={this.closeReviewModal}
                ginUser={this.state.reviewData ? this.state.reviewData.user : ''}
                ginDate={this.state.reviewData ? this.state.reviewData.date : ''}
                ginActivity={this.state.reviewData ? this.state.reviewData.activity : ''}
                from={this.state.view}
                groupData={this.state.groupData}
                groupIndex={this.state.groupIndex}
                selectIndex={this.selectIndex}
                loadHistoryEntry={this.props.loadHistoryEntry}
                openReviewModal={this.openReviewModal}
                loadImage={this.props.loadImage}
                setFinalCropped={this.props.setFinalCropped}
              />}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.gin.loadPreview,
    historyData: state.gin.historyData
  }
}

const mapDispatchToProps = dispatch => {
  return {
    loadHistory: () => {
      dispatch(actions.ginLoadHistory());
    },
    loadHistoryEntry: (id) => {
      dispatch(actions.ginLoadHistoryEntry(id));
    },
    loadImage: (filename) => {
      dispatch(actions.ginLoadImage(filename))
    },
    setFinalCropped: (crop) => {
      dispatch(actions.ginSetFinalCrop(crop));
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GINHistory);
