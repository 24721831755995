import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';
import moment from 'moment';
const initialState = {
    userList: [],
    userBulkFilterList: [],
    userBulkFilterPerms: [],
    usersLoading: false,
    usersFail: null,
    userPerms: null,
    userPermsOriginal: null,
    loadDBs: false,
    DBs: null,
    saveUserPermsStatus: null,
    saveUserPermsLoading: false,

    syncUsers: false,
    currentUserSync: '',
    syncFail: null,
    bulkSyncResponse: '',

    bulkStatus: '',
    maintenanceMode: 'no'
}

const syncUserStart = (state, action) => {
  return updateObject(state, {
    syncUsers: true,
  });
}

const syncUserSuccess = (state, action) => {
  console.log(action.data);
  return updateObject(state, {
    currentUserSync: action.data,
    syncUsers: false
  });
}

const syncUserFail = (state, action) => {
  console.log(action.err);
  return updateObject(state, {
    syncUsers: false,
    syncFail: action.err
  });
}

const getUsersStart = (state, action) => {
  return updateObject(state, {
    usersLoading: true,
  });
}

const getUsersSuccess = (state, action) => {
  return updateObject(state, {
    usersLoading: false,
    userList: action.userList.filter(u => {
      if (u.name === 'GMP') {
        return false;
      }
      return true;
    })
  });
}

const getUsersFail = (state, action) => {
  return updateObject(state, {
    usersLoading: false,
    usersFail: 'Failed to get User'
  });
}

const getUsersFilteredStart = (state, action) => {
  return updateObject(state, {
    usersLoading: true,
  });
}

const getUsersFilteredSuccess = (state, action) => {
  let userObj = {};
  action.userList.forEach((ul) => {
    if (userObj.hasOwnProperty(ul.user_id)) {
      userObj[ul.user_id].push(ul);
    } else {
      userObj[ul.user_id] = [ul];
    }
  });
  const userBulkFilterList = state.userList.filter(u => {
    if (userObj.hasOwnProperty(u.id)) {
      return true;
    }
  });
  return updateObject(state, {
    usersLoading: false,
    userBulkFilterPerms: action.userList,
    userBulkFilterList
  });
}

const getUsersFilteredFail = (state, action) => {
  return updateObject(state, {
    usersLoading: false,
    usersFail: 'Failed to get User'
  });
}

const getUserStart = (state, action) => {
  return updateObject(state, {
    usersLoading: true,
  });
}

const getUserSuccess = (state, action) => {
  console.log('got user', action.userPerms);
  let userPerms = {};
  if (action.userPerms.length > 0) {
    action.userPerms.forEach((up) => {
      if (userPerms.hasOwnProperty(up.perm_id)) {
        if (up.hasOwnProperty('db')) {
          userPerms[up.perm_id].push(up.db);
        }
      } else {
        let db = null;
        if (up.db) {
          db = up.db
        }
        userPerms[up.perm_id] = [db];
      }
    });
  }
  console.log('userPerms', userPerms);
  return updateObject(state, {
    usersLoading: false,
    userPermsOriginal: action.userPerms,
    userPerms
  });
}

const getUserFail = (state, action) => {
  return updateObject(state, {
    usersLoading: false,
    usersFail: 'Failed to get Users',
    userPermsOriginal: [],
    userPerms: {}
  });
}

const loadDBsStart = (state, action) => {
  return updateObject(state, {
    loadDBs: true
  });
}

const loadDBsSuccess = (state, action) => {
  const mapped = {}
  action.dbs.forEach((db) => {
    mapped[db.db] = db.ID;
  });
  return updateObject(state, {
    loadDBs: false,
    DBs: mapped
  });
}

const loadDBsFail = (state, action) => {
  return updateObject(state, {
    loadDBs: false,
    err: action.err
  });
}

const saveUserPermsStart = (state, action) => {
  return updateObject(state, {
    saveUserPermsLoading: true
  });
}

const saveUserPermsSuccess = (state, action) => {
  return updateObject(state, {
    saveUserPermsStatus: action.response,
    saveUserPermsLoading: false
  });
}

const saveUserPermsFail = (state, action) => {
  return updateObject(state, {
    saveUserPermsStatus: action.err,
    saveUserPermsLoading: false
  });
}

const chooseNewPerms = (state, action) => {
  return updateObject(state, {
    userPerms: action.newPerms
  });
}

const bulkAssignStart = (state, action) => {
  return state;
}

const bulkAssignSuccess = (state, action) => {
  return updateObject(state, {
    bulkStatus: `Updated ${action.username}`
  });
}

const bulkAssignFail = (state, action) => {
  console.log(action.err);
  return state;
}

const bulkAssignEnd = (state, action) => {
  console.log('Ended BUlk');
  return updateObject(state, {
    bulkStatus: 'Finished Bulk Assign'
  });
}

const getUserByNameStart = (state, action) => {
  return updateObject(state, {
    userPerms: null,
    userPermsOriginal: null,
  });
}

const bulkSyncSuccess = (state, action) => {
  return updateObject(state, {
    bulkSyncResponse: action.response
  });
}

const bulkSyncFail = (state, action) => {
  console.log('err',action.err);
  return state;
}

const checkMaintenanceModeSuccess = (state, action) => {
  return updateObject(state, {
    maintenanceMode: action.response.value
  });
}

const maintenanceModeSuccess = (state, action) => {
  console.log(action.response,action.response.value,'value');
  return updateObject(state, {
    maintenanceMode: action.response.value
  });
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SYNC_USER_START:
            return syncUserStart(state, action);
        case actionTypes.SYNC_USER_SUCCESS:
            return syncUserSuccess(state, action);
        case actionTypes.SYNC_USER_FAIL:
            return syncUserFail(state, action);

        case actionTypes.GET_USERS_START:
            return getUsersStart(state, action);
        case actionTypes.GET_USERS_SUCCESS:
            return getUsersSuccess(state, action);
        case actionTypes.GET_USERS_FAIL:
            return getUsersFail(state, action);

        case actionTypes.GET_USERS_FILTERED_START:
            return getUsersFilteredStart(state, action);
        case actionTypes.GET_USERS_FILTERED_SUCCESS:
            return getUsersFilteredSuccess(state, action);
        case actionTypes.GET_USERS_FILTERED_FAIL:
            return getUsersFilteredFail(state, action);

        case actionTypes.GET_USER_START:
            return getUserStart(state, action);
        case actionTypes.GET_USER_SUCCESS:
            return getUserSuccess(state, action);
        case actionTypes.GET_USER_FAIL:
            return getUserFail(state, action);

        case actionTypes.GET_USER_BY_NAME_START:
            return getUserByNameStart(state, action);
        case actionTypes.GET_USER_BY_NAME_SUCCESS:
            return getUserSuccess(state, action);
        case actionTypes.GET_USER_BY_NAME_FAIL:
            return getUserFail(state, action);

        case actionTypes.LOAD_DBS_START:
            return loadDBsStart(state, action);
        case actionTypes.LOAD_DBS_SUCCESS:
            return loadDBsSuccess(state, action);
        case actionTypes.LOAD_DBS_FAIL:
            return loadDBsFail(state, action);

        case actionTypes.SAVE_USER_PERMS_START:
            return saveUserPermsStart(state, action);
        case actionTypes.SAVE_USER_PERMS_SUCCESS:
            return saveUserPermsSuccess(state, action);
        case actionTypes.SAVE_USER_PERMS_FAIL:
            return saveUserPermsFail(state, action);

        case actionTypes.BULK_ASSIGN_START:
            return bulkAssignStart(state, action);
        case actionTypes.BULK_ASSIGN_SUCCESS:
            return bulkAssignSuccess(state, action);
        case actionTypes.BULK_ASSIGN_FAIL:
            return bulkAssignFail(state, action);
        case actionTypes.BULK_ASSIGN_END:
            return bulkAssignEnd(state, action);

        case actionTypes.BULK_SYNC_START:
            return state;
        case actionTypes.BULK_SYNC_SUCCESS:
            return bulkSyncSuccess(state, action);
        case actionTypes.BULK_SYNC_FAIL:
            return bulkSyncFail(state, action);

        case actionTypes.CHOOSE_NEW_PERMS:
            return chooseNewPerms(state, action);

        case actionTypes.MAINTENANCE_MODE_SUCCESS:
            return maintenanceModeSuccess(state, action);
        case actionTypes.CHECK_MAINTENANCE_MODE_SUCCESS:
            return checkMaintenanceModeSuccess(state, action);
        default: return state;
    }
};

export default reducer;
