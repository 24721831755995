import React, { Component } from "react";
import { connect } from 'react-redux';
import ReactDOM from "react-dom";
import PropTypes from 'prop-types';
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { required, email } from 'shared/validation';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import SweetAlert from "react-bootstrap-sweetalert";
import { PulseLoader } from 'react-spinners';

import InputLabel from '@material-ui/core/InputLabel';
import * as actions from 'store/actions';
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import withStyles from "@material-ui/core/styles/withStyles";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import combineStyles from "shared/combineStyles";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
// @material-ui/icons
import Settings from "@material-ui/icons/Settings";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Clearfix from "components/Clearfix/Clearfix.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";

import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";
import { formatDate } from "shared/utility";
import { fetchGMPAddress, eraseGMPAlertMsg } from "store/actions/GMP";

const getName = (arr, id) => {
  let name = '';
  arr.forEach(a => {
    if (id == a.id) {
      name = a.name;
    }
  })
  return name;
}

class GMPProfileReviewNewContact extends Component {
  constructor(props) {
    super(props);

    this.state = {
    }

    this.hideAlert = this.hideAlert.bind(this);
  }

  hideAlert() {
    this.props.eraseGMPAlertMsg();
    this.props.cancelProfileView();
  }

  componentDidMount() {
    ReactDOM.findDOMNode(this).scrollIntoView();
  }

  handleApproveNewContact = () => {
    const bp = this.props.bp;
    let bpNew = {...bp};
    if (bp.DB === 'NIN_LIVE' || bp.DB === 'NIN_TEST') {
      bpNew = {...bp, ...this.props.wtax_info};
      console.log(bpNew);
      bpNew.wtcode = bpNew.wtcode.join();
    }
    this.props.approveReviewNewContact(bpNew);
  }

  cancelReviewNewContact = () => {
    this.props.cancelReviewNewContact();
  }

  render() {
    const props = this.props;
    const { classes, bp, loadingReview, messaged, eraseGMPAlertMsg } = props;
    let miniModalAlert = (<SweetAlert
        success
        title="Success!"
        style={{ display: "block" }}
        onConfirm={this.hideAlert}
        confirmBtnCssClass={
            classes.button + " " + classes.success
        }
        >
        {messaged}
        </SweetAlert>);

    if (messaged !== 'SUCCESSFULLY SUBMITTED NEW CONTACT FOR EXISTING BP' && messaged !== 'NEW CONTACT DENY SUBMITTED') {
    miniModalAlert = (<SweetAlert
           warning
           style={{ display: "block" }}
           title="Warning!"
           onConfirm={this.hideAlert}
           confirmBtnCssClass={
               classes.button + " " + classes.success
           }
           >
           {messaged}
           </SweetAlert>);
    }
    return (
        <GridContainer>
        {messaged && miniModalAlert}
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <Settings />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  GMP BP (Existing Profile) <span>{bp.DB} {props.company_name}</span> New Contact Review
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                  <h4>New Contact</h4>
                  <div><strong>Name:</strong> {bp.new_name}</div>
                  <div><strong>Position:</strong> {bp.new_position}</div>
                  <div><strong>Telephone:</strong> {bp.new_tel}</div>
                  <div><strong>Email:</strong> {bp.new_email}</div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                  {bp.notes && <div><h4>Notes</h4>
                  <p>{bp.notes}</p></div>}
                  </GridItem>
                </GridContainer>
                <hr />
                <GridContainer>
                  <div style={{marginTop:'20px'}} className="separate-btns">
                  <GridItem xs={12} sm={12} md={12}>
                    {!props.loadingReview && <div>
                    <Button
                      color="success"
                      type="button"
                      className={classes.updateProfileButton}
                      onClick={() => {
                        this.handleApproveNewContact();
                      }}>
                      APPROVE
                    </Button>
                    <Button
                      color="rose"
                      type="button"
                      className={classes.updateProfileButton}
                      onClick={() => {
                          this.cancelReviewNewContact();
                      }}
                    >
                      BACK TO EDIT
                    </Button>
                    </div>}

                      {props.loadingReview && <div>Please Wait <PulseLoader /></div>}
                  </GridItem>
                  </div>
                </GridContainer>
                <Clearfix />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
    );
  }
}


const mapStateToProps = (state, props) => {
  return {
    cardCode: state.common.sapBPGen.card_code,
    initialValues: props.bp,
    finalValues: state.form.gmpProfileFormNewContact.values,
    loadingReview: state.gmp.loadingReview,
    messaged: state.gmp.message
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    eraseGMPAlertMsg: () => {
      dispatch(eraseGMPAlertMsg())
    }
  }
}

const combinedStyle = combineStyles(sweetAlertStyle, userProfileStyles);
export default connect(mapStateToProps,mapDispatchToProps)(withStyles(combinedStyle)(GMPProfileReviewNewContact));
