import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import * as actionTypes from '../actions/actionTypes';

import {  fetchADSaga, addADUserSaga, modifyADUserSaga, deleteADUserSaga } from './activeDirectory';
import { loginUserSaga } from './auth';
import { fetchGMPSaga, fetchGMPAddressSaga, fetchGMPContactSaga, fetchGMPRBOSaga, createGMPBPSaga,
          createGMPBPExistingSaga, denyGMPBPSaga, denyGMPBPExistingSaga, fetchGSTTypeSaga, fetchGMPSimilarsSaga,
          fetchGMPHistorySaga, fetchGMPHistoryItemSaga, fetchGMPExistingSimilarsSaga, fetchEDIProgramsSaga,
          checkVendorCodeSaga, checkVendorCodesSaga, convertNewToExistingSaga, convertExistingToNewSaga } from './gmp';

import { fetchItemListSaga, fetchItemSaga, fetchBrandSubgroupSaga, fetchGINOptionsSaga, fetchItemMOCsSaga, ginSendImageSaga, ginSendItemSaga, ginCheckExistsSaga, fetchItemMOCsAllSaga, ginCheckImporterTableSaga, ginGetFailedImportSaga, ginCheckTableCountSaga, ginSetFailedImportSaga, ginStartQSaga, ginDeactivateSaga, ginSendItemNoStartSaga, ginDenyPendingSaga, ginCheckPendingSaga, ginSaveHistorySaga, ginLoadHistorySaga,
ginAutoSaveSaga, ginAutoSaveLoadSaga, ginAutoSaveLoadAllSaga, ginAutoSaveImageSaga, ginAutoSaveDeleteSaga,
ginLoadImageSaga, ginGetExistingSaga, ginLoadExistingSaga, loadPreviewDataSaga, ginRenameImageSaga,
ginSavePendingSaga, ginLoadPendingSaga, ginApprovePendingSaga, ginLoadHistoryEntrySaga, getItemLocksSaga, checkItemLockSaga,
updateItemLockSaga, checkItemsLockSaga, updateItemsLockSaga, releaseUserLockedGINSaga } from './gin';

import { fetchAddInfoSaga, fetchDBListSaga, fetchBPSAPSaga, fetchAllBPSaga, saveLocalBPSaga, fetchLocalBPSaga, saveSAPBPSaga, checkPendingBPSaga, sendResaleCertFileSaga, sendResaleCertFileSAPSaga, checkBPLockSaga, updateBPLockSaga, getBPLocksSaga,
releaseUserLockedBPsSaga, choosePrimarySaga } from './common';

import { getUsersSaga, getUsersFilteredSaga, getUserSaga, loadDBsSaga, saveUserPermsSaga, bulkSyncSaga, syncUsersSaga, bulkAssignSaga, getUserByNameSaga, maintenanceModeSaga, checkMaintenanceModeSaga, notifyMailSaga } from './userperms';

import { loadRFIDprefixSaga, loadRFIDcompaniesSaga, rfidExportEPCSaga, rfidLoadHistorySaga, loadGS1Saga, generateRFIDExcelSaga, verifyRFIDExcelSaga, checkGS1APISaga, getRfidJcdataSaga, getRfidImporterHistorySaga, getRfidImporterExcelSaga, getRfidAllBirdsSaga } from './rfid';

import { loadSODataSaga, startImpositionsSaga } from './so';

import { readSettingsSaga, updateSettingsSaga, tppMaintenanceSaga, tppMaintenanceCheckSaga } from './settings';

import { loadRBODataSaga, readTranslationsSaga, readTranslationSaga, createTranslationSaga, updateTranslationSaga,
deleteTranslationSaga, searchTranslationSaga } from './translations';

import { getSmMagentoOrdersSaga, getSmMagentoOrdersOthersSaga, getSmMagentoCustomersSaga, getBpByNameSaga, getSapOrderDetailsSaga, getSapBpDetailsSaga,
getSapBpaDetailsSaga, getPiListFilterSaga, getSapBpaItemDetailsSaga, getMagentoBpDetailsSaga } from './sap_monitor';

export function* watchAD() {
    // AD / USER ADMIN
    yield takeEvery(actionTypes.FETCH_AD, fetchADSaga);
    yield takeLatest(actionTypes.ADD_AD_USER, addADUserSaga);
    yield takeLatest(actionTypes.MODIFY_AD_USER, modifyADUserSaga);
    yield takeLatest(actionTypes.DELETE_AD_USER, deleteADUserSaga);

    yield takeLatest(actionTypes.AUTH_REQUEST, loginUserSaga);

    // GMP BP
    yield takeLatest(actionTypes.FETCH_GMP, fetchGMPSaga);
    yield takeLatest(actionTypes.FETCH_GMP_ADDRESS, fetchGMPAddressSaga);
    yield takeLatest(actionTypes.FETCH_GMP_CONTACT, fetchGMPContactSaga);
    yield takeLatest(actionTypes.FETCH_GMP_RBO, fetchGMPRBOSaga);
    yield takeLatest(actionTypes.CREATE_GMP_BP, createGMPBPSaga);
    yield takeLatest(actionTypes.CREATE_GMP_BP_EXISTING, createGMPBPExistingSaga);
    yield takeLatest(actionTypes.DENY_GMP_BP, denyGMPBPSaga);
    yield takeLatest(actionTypes.DENY_GMP_BP_EXISTING, denyGMPBPExistingSaga);
    yield takeLatest(actionTypes.FETCH_GMP_SIMILARS, fetchGMPSimilarsSaga);
    yield takeLatest(actionTypes.FETCH_GMP_HISTORY, fetchGMPHistorySaga);
    yield takeLatest(actionTypes.FETCH_GMP_HISTORY_ITEM, fetchGMPHistoryItemSaga);
    yield takeLatest(actionTypes.FETCH_GMP_EXISTING_SIMILARS, fetchGMPExistingSimilarsSaga);
    yield takeLatest(actionTypes.CHECK_PENDING_BP, checkPendingBPSaga);
    yield takeLatest(actionTypes.SEND_RESALE_CERT_FILE, sendResaleCertFileSaga);
    yield takeLatest(actionTypes.SEND_RESALE_CERT_FILE_SAP, sendResaleCertFileSAPSaga);
    yield takeLatest(actionTypes.FETCH_EDI_PROGRAMS, fetchEDIProgramsSaga);
    yield takeLatest(actionTypes.CHECK_VENDOR_CODE, checkVendorCodeSaga);
    yield takeLatest(actionTypes.CHECK_VENDOR_CODES, checkVendorCodesSaga);

    yield takeLatest(actionTypes.FETCH_ADDINFO_LIST, fetchAddInfoSaga);
    yield takeLatest(actionTypes.FETCH_DB_LIST, fetchDBListSaga);
    yield takeLatest(actionTypes.FETCH_BP_SAP, fetchBPSAPSaga);
    yield takeLatest(actionTypes.FETCH_ALL_BP, fetchAllBPSaga);
    yield takeLatest(actionTypes.SAVE_LOCAL_BP, saveLocalBPSaga);
    yield takeLatest(actionTypes.FETCH_LOCAL_BP, fetchLocalBPSaga);
    yield takeLatest(actionTypes.SAVE_SAP_BP, saveSAPBPSaga);

    yield takeLatest(actionTypes.CHECK_BP_LOCK, checkBPLockSaga);
    yield takeLatest(actionTypes.UPDATE_BP_LOCK, updateBPLockSaga);
    yield takeLatest(actionTypes.GET_BP_LOCKS, getBPLocksSaga);
    yield takeLatest(actionTypes.RELEASE_USER_LOCKED_BPS, releaseUserLockedBPsSaga);

    yield takeLatest(actionTypes.CONVERT_NEW_TO_EXISTING, convertNewToExistingSaga);
    yield takeLatest(actionTypes.CONVERT_EXISTING_TO_NEW, convertExistingToNewSaga);

    yield takeLatest(actionTypes.CHOOSE_PRIMARY, choosePrimarySaga);

    // GIN
    yield takeLatest(actionTypes.FETCH_ITEMLIST, fetchItemListSaga);
    yield takeLatest(actionTypes.FETCH_ITEM, fetchItemSaga);
    yield takeLatest(actionTypes.FETCH_BRAND_SUBGROUPLIST, fetchBrandSubgroupSaga);
    yield takeLatest(actionTypes.FETCH_GSTTYPE, fetchGSTTypeSaga);
    yield takeLatest(actionTypes.FETCH_GIN_OPTIONS, fetchGINOptionsSaga);
    yield takeLatest(actionTypes.FETCH_ITEM_MOCS, fetchItemMOCsSaga);
    yield takeLatest(actionTypes.FETCH_ITEM_MOCS_ALL, fetchItemMOCsAllSaga);
    yield takeLatest(actionTypes.GIN_SEND_IMAGE, ginSendImageSaga);
    yield takeLatest(actionTypes.GIN_SEND_ITEM, ginSendItemSaga);
    yield takeLatest(actionTypes.GIN_SEND_ITEM_NOSTART, ginSendItemNoStartSaga);
    yield takeLatest(actionTypes.GIN_CHECK_EXISTS, ginCheckExistsSaga);
    yield takeLatest(actionTypes.GIN_CHECK_IMPORTER_TABLE, ginCheckImporterTableSaga);
    yield takeLatest(actionTypes.GIN_GET_FAILED_IMPORT, ginGetFailedImportSaga);
    yield takeLatest(actionTypes.GIN_SET_FAILED_IMPORT, ginSetFailedImportSaga);
    yield takeLatest(actionTypes.GIN_CHECK_TABLE, ginCheckTableCountSaga);
    yield takeLatest(actionTypes.GIN_START_Q, ginStartQSaga);
    yield takeLatest(actionTypes.GIN_DEACTIVATE, ginDeactivateSaga);
    yield takeLatest(actionTypes.GIN_AUTOSAVE, ginAutoSaveSaga);
    yield takeLatest(actionTypes.GIN_AUTOSAVE_LOAD, ginAutoSaveLoadSaga);
    yield takeLatest(actionTypes.GIN_AUTOSAVE_LOAD_ALL, ginAutoSaveLoadAllSaga);
    yield takeLatest(actionTypes.GIN_AUTOSAVE_IMAGE, ginAutoSaveImageSaga);
    yield takeLatest(actionTypes.GIN_AUTOSAVE_DELETE, ginAutoSaveDeleteSaga);
    yield takeLatest(actionTypes.GIN_LOAD_IMAGE, ginLoadImageSaga);
    yield takeLatest(actionTypes.GIN_GET_EXISTING, ginGetExistingSaga);
    yield takeLatest(actionTypes.GIN_LOAD_EXISTING, ginLoadExistingSaga);
    yield takeLatest(actionTypes.GIN_RENAME_IMAGE, ginRenameImageSaga);
    yield takeLatest(actionTypes.LOAD_PREVIEW_DATA, loadPreviewDataSaga);
    yield takeLatest(actionTypes.GIN_SAVE_PENDING, ginSavePendingSaga);
    yield takeLatest(actionTypes.GIN_LOAD_PENDING, ginLoadPendingSaga);
    yield takeLatest(actionTypes.GIN_DENY_PENDING, ginDenyPendingSaga);
    yield takeLatest(actionTypes.GIN_APPROVE_PENDING, ginApprovePendingSaga);
    yield takeLatest(actionTypes.GIN_CHECK_PENDING, ginCheckPendingSaga);
    yield takeLatest(actionTypes.GIN_LOAD_HISTORY, ginLoadHistorySaga);
    yield takeLatest(actionTypes.GIN_SAVE_HISTORY, ginSaveHistorySaga);
    yield takeLatest(actionTypes.GIN_LOAD_HISTORY_ENTRY, ginLoadHistoryEntrySaga);
    yield takeLatest(actionTypes.GET_ITEM_LOCKS, getItemLocksSaga);
    yield takeLatest(actionTypes.UPDATE_ITEM_LOCK, updateItemLockSaga);
    yield takeLatest(actionTypes.CHECK_ITEM_LOCK, checkItemLockSaga);
    yield takeLatest(actionTypes.CHECK_ITEMS_LOCK, checkItemsLockSaga);
    yield takeLatest(actionTypes.UPDATE_ITEMS_LOCK, updateItemsLockSaga);
    yield takeLatest(actionTypes.RELEASE_USER_LOCKED_GIN, releaseUserLockedGINSaga);

    // USER PERMS
    yield takeLatest(actionTypes.GET_USERS, getUsersSaga);
    yield takeLatest(actionTypes.GET_USERS_FILTERED, getUsersFilteredSaga);
    yield takeLatest(actionTypes.GET_USER, getUserSaga);
    yield takeLatest(actionTypes.GET_USER_BY_NAME, getUserByNameSaga);
    yield takeLatest(actionTypes.LOAD_DBS, loadDBsSaga);
    yield takeLatest(actionTypes.SAVE_USER_PERMS, saveUserPermsSaga);
    yield takeLatest(actionTypes.USERS_SYNC, syncUsersSaga);
    yield takeLatest(actionTypes.BULK_SYNC, bulkSyncSaga);
    yield takeLatest(actionTypes.BULK_ASSIGN, bulkAssignSaga);
    yield takeLatest(actionTypes.MAINTENANCE_MODE, maintenanceModeSaga);
    yield takeLatest(actionTypes.CHECK_MAINTENANCE_MODE, checkMaintenanceModeSaga);
    yield takeLatest(actionTypes.NOTIFY_MAIL, notifyMailSaga);

    // RFID
    yield takeLatest(actionTypes.LOAD_RFID_PREFIX, loadRFIDprefixSaga);
    yield takeLatest(actionTypes.LOAD_RFID_COMPANIES, loadRFIDcompaniesSaga);
    yield takeLatest(actionTypes.RFID_EXPORT_EPC, rfidExportEPCSaga);
    yield takeLatest(actionTypes.RFID_LOAD_HISTORY, rfidLoadHistorySaga);
    yield takeLatest(actionTypes.LOAD_GS1COMPANYPREFIX, loadGS1Saga);
    yield takeLatest(actionTypes.GENERATE_RFID_EXCEL, generateRFIDExcelSaga);
    yield takeLatest(actionTypes.CHECK_GS1_API, checkGS1APISaga);
    yield takeLatest(actionTypes.VERIFY_RFID_EXCEL, verifyRFIDExcelSaga);
    yield takeLatest(actionTypes.GET_RFID_JCDATA, getRfidJcdataSaga);
    yield takeLatest(actionTypes.GET_RFID_ALLBIRDS, getRfidAllBirdsSaga);
    yield takeLatest(actionTypes.GET_RFID_IMPORTER_HISTORY, getRfidImporterHistorySaga);
    yield takeLatest(actionTypes.GET_RFID_IMPORTER_EXCEL, getRfidImporterExcelSaga);

    // SO
    yield takeLatest(actionTypes.LOAD_SO_DATA, loadSODataSaga);
    yield takeLatest(actionTypes.START_IMPOSITIONS, startImpositionsSaga);

    // SETTINGS
    yield takeLatest(actionTypes.READ_SETTINGS, readSettingsSaga);
    yield takeLatest(actionTypes.UPDATE_SETTINGS, updateSettingsSaga);
    yield takeLatest(actionTypes.TPP_MAINTENANCE, tppMaintenanceSaga);
    yield takeLatest(actionTypes.TPP_MAINTENANCE_CHECK, tppMaintenanceCheckSaga);

    // TRANSLATIONS
    yield takeLatest(actionTypes.LOAD_RBO_DATA, loadRBODataSaga);
    yield takeLatest(actionTypes.READ_TRANSLATIONS, readTranslationsSaga);
    yield takeLatest(actionTypes.READ_TRANSLATION, readTranslationSaga);
    yield takeLatest(actionTypes.CREATE_TRANSLATION, createTranslationSaga);
    yield takeLatest(actionTypes.UPDATE_TRANSLATION, updateTranslationSaga);
    yield takeLatest(actionTypes.DELETE_TRANSLATION, deleteTranslationSaga);
    yield takeLatest(actionTypes.SEARCH_TRANSLATION, searchTranslationSaga);

    // SAP MONITOR 
    yield takeLatest(actionTypes.GET_SM_MAGENTO_ORDERS, getSmMagentoOrdersSaga);
    yield takeLatest(actionTypes.GET_SM_MAGENTO_ORDERS_OTHERS, getSmMagentoOrdersOthersSaga);
    yield takeLatest(actionTypes.GET_SM_MAGENTO_CUSTOMERS, getSmMagentoCustomersSaga);
    yield takeLatest(actionTypes.GET_BP_BY_NAME, getBpByNameSaga);
    yield takeLatest(actionTypes.GET_SAP_ORDER_DETAILS, getSapOrderDetailsSaga);
    yield takeLatest(actionTypes.GET_SAP_BP_DETAILS, getSapBpDetailsSaga);
    yield takeLatest(actionTypes.GET_SAP_BPA_DETAILS, getSapBpaDetailsSaga);
    yield takeLatest(actionTypes.GET_PI_LIST_FILTER, getPiListFilterSaga);
    yield takeLatest(actionTypes.GET_SAP_BPA_ITEM_DETAILS, getSapBpaItemDetailsSaga);
    yield takeLatest(actionTypes.GET_MAGENTO_BP_DETAILS, getMagentoBpDetailsSaga);
}
