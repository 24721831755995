import React from 'react';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import InputAdornment from '@material-ui/core/InputAdornment';
import { convertToCommas, convertToSerial } from "shared/utility";
import Button from "components/CustomButtons/Button.jsx";

const InputPopup = props => {
    let target = 'productionQty',
        value = props.modalInput;
    if (props.title !== 'Production Quantity') {
      target = 'startingSerial';
      if (props.company === 'walmart') {
        value = convertToCommas(convertToSerial(props.modalInput));
      } else {
        // JCPENNY, ALLBIRDS
        value = convertToCommas(props.modalInput, true);
        console.log('value converted', value, props.modalInput)
      }
    }
    if (value === 'NaN') value = '';
    return (
      <Dialog
        onClose={props.onClose}
        open={props.open}
        keepMounted
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle style={{paddingBottom: 0}} onClose={() => props.close()}>
          Please enter {props.title}
        </DialogTitle>
  
        <DialogContent style={{overflowY:'unset'}}>
          <form onSubmit={(e) => {
            e.preventDefault();
            if (target !== 'startingSerial') {
              props.handleSet(props.modalInput, target);
              props.close();
            }
            if (target === 'startingSerial') {
              if (value != 0) {
                props.handleSet(props.modalInput, target);
                props.close();
              }
            }
          }}>
          <GridItem xs={12} sm={3} md={3}>
          </GridItem>
          <GridItem xs={12} sm={9} md={9}>
            <CustomInput
              labelText={props.title}
              id="input2"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: (e) => {
                  console.log('e',e.target.value)
                  e.target.value = e.target.value.replace(/,/g,'')
                  props.handleModalInputChange(e, target);
                },
                value,
                autoFocus: true,
                role: 'presentation',
                autoComplete: 'nope',
                startAdornment: <InputAdornment position="start">{target === 'startingSerial' ? <span style={{color:'rgb(174,174,174)'}}>{props.manufacturerID}</span> : ''}</InputAdornment>
              }}
              error={(props.modalInputError || props.modalInputError2) ? true : false}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            {target === 'startingSerial' && <div>
              <div style={{color:'rgb(174,174,174)',fontSize:9,height:12}}>Manufacturer</div>
              <div style={{color:'rgb(174,174,174)',fontSize:9}}>ID</div>
            </div>}
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            {target === 'startingSerial' && <Button
              color={value == 0 || (props.modalInputError || props.modalInputError2) ? 'white' : 'success'}
              style={{marginRight:20,marginTop:10}}
              disabled={value == 0 || (props.modalInputError || props.modalInputError2) ? true : false}
              onClick={() => {
                if (value != 0) {
                  props.handleSet(props.modalInput, target);
                  props.close();
                }
              }}
            >ENTER</Button>}
            {target !== 'startingSerial' && <Button
              color={value == 0 || (props.modalInputError || props.modalInputError2) ? 'white' : 'success'}
              style={{marginRight:20,marginTop:10}}
              disabled={value == 0 || (props.modalInputError || props.modalInputError2) ? true : false}
              onClick={() => {
                  props.handleSet(props.modalInput, target);
                  props.close();
              }}
            >ENTER</Button>}
            {target === 'startingSerial' && <Button
                type="button"
                style={{marginRight:20,marginTop:10}}
                color="warning"
                onClick={() => {
                  if (props.lastSerial) {
                    const e = {
                      target: {
                        value: (props.company === 'walmart') ? 
                          (props.lastSerial+1).toString().substring(3) 
                        :  
                          (props.lastSerial+1).toString()
                      }
                    }
                    props.handleModalInputChange(e, target);
                  }
                }}
              >
              DEFAULT STARTING SERIAL
              </Button>}
            <Button
              type="button"
              style={{marginRight:20,marginTop:10}}
              color="primary"
              onClick={() => {
                props.close();
              }}
            >CANCEL</Button>
            {props.modalInputError && <div style={{marginTop:20,marginTop:10,color:'red'}}>
              {props.modalInputError}
            </div>}
            {props.modalInputError2 && <div style={{marginTop:20,marginTop:10,color:'red'}}>
              {props.modalInputError2}
            </div>}
          </GridItem>
          </form>
        </DialogContent>
      </Dialog>
    )
  }

export default InputPopup;