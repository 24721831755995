import React, { useEffect, useState } from 'react';
import { FadeLoader, PulseLoader } from 'react-spinners';
import SweetAlert from "react-bootstrap-sweetalert";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import Button from "components/CustomButtons/Button.jsx";

const RBOTranslationsLookup = (props) => {
  const [searchTerm, setSearchTerm] = useState('');
  const handleTextInputChange = (e) => {
    setSearchTerm(e.target.value);
  }

  return (
    <div>
      <GridContainer>
        <GridItem xs={6} sm={6} md={6}>
          <CustomInput
            labelText="Search Term"
            name="startHour"
            value={searchTerm}
            handleTextInputChange={handleTextInputChange}
            required
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default RBOTranslationsLookup;
