import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

let initialState = {
  data: null,
  error: null,
  loading: false,
  impositionsLoading: false,
  impositionsData: null,
  impositionsErr: null,
}

const loadSODataStart = (state, action) => {
  return updateObject(state, {
    data: null,
    error: null,
    loading: true
  });
}

const loadSODataSuccess = (state, action) => {
  let filteredData = [],
      dupesObj = {};
  if (action.data) {
    if (action.data.length > 0) {
      filteredData = [];
      action.data.forEach(data => {
        const mo = data.MagnetoOrder,
              so = data.SO,
              itemcode = data.ItemCode,
              filename = data.TxtFile;
        if (so) {
          const key = `${mo}_${so}_${filename}_${itemcode}`;
          if (!dupesObj.hasOwnProperty(key)) {
            dupesObj[key] = 1;
            filteredData.push(data);
          }
        }
      });
    }
  }
  return updateObject(state, {
    data: filteredData,
    loading: false
  });
}

const loadSODataFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.err
  });
}

const clearSOData = (state, action) => {
  return updateObject(state, {
    data: null,
    error: null
  });
}

const startImpositionsStart = (state, action) => {
  return updateObject(state, {
    impositionsLoading: true,
    impositionsData: null,
    impositionsErr: null,
  });
}

const startImpositionsSuccess = (state, action) => {
  let impositionsData = '';
  const lines = action.data;
  if (lines[0] && lines[1]) {
    let ups_index = -1;
    lines[0].forEach((l,i) => {
      if (l === 'FIELD0') {
        ups_index = i;
      }
    })
    if (ups_index !== -1) {
      const ups = lines[1][ups_index];
      const variants_count = lines.length - 1;
      impositionsData = {
        ups, variants_count, filename: action.filename
      };
    }
  }
  return updateObject(state, {
    impositionsLoading: false,
    impositionsData,
    impositionsErr: null
  });
}

const startImpositionsFail = (state, action) => {
  return updateObject(state, {
    impositionsLoading: false,
    impositionsData: null,
    impositionsErr: action.err
  });
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOAD_SO_DATA_START:
          return loadSODataStart(state, action);
        case actionTypes.LOAD_SO_DATA_SUCCESS:
          return loadSODataSuccess(state, action);
        case actionTypes.LOAD_SO_DATA_FAIL:
          return loadSODataFail(state, action);
        case actionTypes.CLEAR_SO_DATA:
          return clearSOData(state, action);

        case actionTypes.START_IMPOSITIONS_START:
          return startImpositionsStart(state, action);
        case actionTypes.START_IMPOSITIONS_SUCCESS:
          return startImpositionsSuccess(state, action);
        case actionTypes.START_IMPOSITIONS_FAIL:
          return startImpositionsFail(state, action);

        default: return state;
    }
};

export default reducer;
