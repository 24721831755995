import React, { Component } from "react";
import CustomInput from "components/CustomInput/CustomInput.jsx";

const BPTextArea = props => {
  let error = '';
  if (props.validate) {
    if (props.validate.length > 0) {
      props.validate.forEach((val) => {
        const err = val(props.value);
        if (err) {
          error = err;
        }
      });
    }
  }
  return (
    <div style={{marginTop:'0px'}} className="textarea-nep">
      <CustomInput
        labelText={props.label}
        id="notes-me"
        className="text-field-input-custom"
        style={{width:'100%'}}
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          multiline: true,
          rows: 3,
          onChange: (e) => {
            if (props.normalize) {
              e.target.value = props.normalize(e.target.value);
            }
            props.handleTextInputChange(e, props.name);
          },
          value: props.value
        }}
        error={error ? true : false}
      />
      {
        (error && <span className="red">{error}</span>)}
    </div>
  );
}

export default BPTextArea;
