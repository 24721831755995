export const ranges = [
  { name: 'Base Price', rangeA: 1 ,rangeB: 999 },
  { name: '1K', rangeA: 1000 ,rangeB: 2499 },
  { name: '2.5K', rangeA: 2500, rangeB: 4999 },
  { name: '5K', rangeA: 5000 ,rangeB: 9999 },
  { name: '10K', rangeA: 10000 ,rangeB: 24999 },
  { name: '25K', rangeA: 25000, rangeB: 49999},
  { name: '50K', rangeA: 50000, rangeB: 99999},
  { name: '100K', rangeA: 100000, rangeB: 249999},
  { name: '250K', rangeA: 250000, rangeB: 499999},
  { name: '500K', rangeA: 500000, rangeB: 999999},
  { name: '1M', rangeA: 1000000 ,rangeB: 2499999 },
  { name: '2.5M', rangeA: 2500000 ,rangeB: 4999999 },
  { name: '5M', rangeA: 5000000, rangeB: 9999999},
  { name: '10M+', rangeA: 10000000, rangeB: 10000000000}
];

export const rangesObj = {
   "Base Price":"1-999",
   "1K":"1,000-2,499",
   "2.5K":"2,500-4,999",
   "5K":"5,000-9,999",
   "10K":"10,000-24,999",
   "25K":"25,000-49,999",
   "50K":"50,000-99,999",
   "100K":"100,000-249,999",
   "250K":"250,000-499,999",
   "500K":"500,000-999,999",
   "1M":"1,000,000-2,499,999",
   "2.5M":"2,500,000-4,999,999",
   "5M":"5,000,000-9,999,999",
   "10M+":"10,000,000+"
}
