import * as actionTypes from './actionTypes';

export const loadRBOData = () => {
    return {
        type: actionTypes.LOAD_RBO_DATA
    }
}

export const loadRBODataStart = () => {
  return {
    type: actionTypes.LOAD_RBO_DATA_START
  }
}

export const loadRBODataSuccess = (data) => {
  return {
    type: actionTypes.LOAD_RBO_DATA_SUCCESS,
    data
  }
}

export const loadRBODataFail = (err) => {
  return {
    type: actionTypes.LOAD_RBO_DATA_FAIL,
    err
  }
}

// TRANSLATIONS

export const readTranslations = (options) => {
  return {
    type: actionTypes.READ_TRANSLATIONS,
    options
  }
}

export const readTranslationsStart = () => {
  return {
    type: actionTypes.READ_TRANSLATIONS_START
  }
}

export const readTranslationsSuccess = (data) => {
  return {
    type: actionTypes.READ_TRANSLATIONS_SUCCESS,
    data
  }
}

export const readTranslationsFail = (err) => {
  return {
    type: actionTypes.READ_TRANSLATIONS_FAIL,
    err
  }
}

// READ TRANSLATION

export const readTranslation = (options) => {
  return {
    type: actionTypes.READ_TRANSLATION,
    options
  }
}

export const readTranslationStart = () => {
  return {
    type: actionTypes.READ_TRANSLATION_START
  }
}

export const readTranslationSuccess = (data) => {
  return {
    type: actionTypes.READ_TRANSLATION_SUCCESS,
    data
  }
}

export const readTranslationFail = (err) => {
  return {
    type: actionTypes.READ_TRANSLATION_FAIL,
    err
  }
}

// CREATE TRANSLATION

export const createTranslation = (data) => {
  return {
    type: actionTypes.CREATE_TRANSLATION,
    data
  }
}

export const createTranslationStart = () => {
  return {
    type: actionTypes.CREATE_TRANSLATION_START
  }
}

export const createTranslationSuccess = (msg, newData) => {
  return {
    type: actionTypes.CREATE_TRANSLATION_SUCCESS,
    msg,
    newData
  }
}

export const createTranslationFail = (err) => {
  return {
    type: actionTypes.CREATE_TRANSLATION_FAIL,
    err
  }
}

// UPDATE TRANSLATION

export const updateTranslation = (data) => {
  return {
    type: actionTypes.UPDATE_TRANSLATION,
    data
  }
}

export const updateTranslationStart = () => {
  return {
    type: actionTypes.UPDATE_TRANSLATION_START
  }
}

export const updateTranslationSuccess = (msg) => {
  return {
    type: actionTypes.UPDATE_TRANSLATION_SUCCESS,
    msg
  }
}

export const updateTranslationFail = (err) => {
  return {
    type: actionTypes.UPDATE_TRANSLATION_FAIL,
    err
  }
}

// DELETE TRANSLATION

export const deleteTranslation = (data) => {
  return {
    type: actionTypes.DELETE_TRANSLATION,
    data
  }
}

export const deleteTranslationStart = () => {
  return {
    type: actionTypes.DELETE_TRANSLATION_START
  }
}

export const deleteTranslationSuccess = (msg, data) => {
  return {
    type: actionTypes.DELETE_TRANSLATION_SUCCESS,
    msg,
    data
  }
}

export const deleteTranslationFail = (err) => {
  return {
    type: actionTypes.DELETE_TRANSLATION_FAIL,
    err
  }
}

// CLEAR

export const clearTranslations = (except_rbo) => {
  return {
    type: actionTypes.CLEAR_TRANSLATIONS,
    except_rbo
  }
}

// SEARCH

export const searchTranslation = (data) => {
  return {
    type: actionTypes.SEARCH_TRANSLATION,
    data
  }
}

export const searchTranslationStart = () => {
  return {
    type: actionTypes.SEARCH_TRANSLATION_START
  }
}

export const searchTranslationSuccess = (searchResults) => {
  return {
    type: actionTypes.SEARCH_TRANSLATION_SUCCESS,
    searchResults
  }
}

export const searchTranslationFail = (err) => {
  return {
    type: actionTypes.SEARCH_TRANSLATION_FAIL,
    err
  }
}

export const getSuggestionsSuccess = (suggestions, missingLanguages) => {
  return {
    type: actionTypes.GET_SUGGESTIONS_SUCCESS,
    suggestions,
    missingLanguages
  }
}
