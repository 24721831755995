import React, { Component } from 'react';
import ReactDOM from "react-dom";
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import GridContainer from "../../components/Grid/GridContainer.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
import Button from "../../components/CustomButtons/Button.jsx";
import Button2 from '@material-ui/core/Button';
import NavPills from "components/NavPills/NavPills.jsx";

import styles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";

import UserSubBulkFilter from "./UserSubBulkFilter";

const UserBulkFilter = props => {
  console.log('is it true', (Object.keys(props.bulkObj).length > 0 || Object.keys(props.removeObj).length > 0));
  return (
    <div>
      <div>
        <div style={{textAlign:'center'}} className="relative">
          <h4>
            <strong>BULK / FILTER</strong>
          </h4>
          <div style={{position:'absolute',top:'-60px',right:0}}>
          {(Object.keys(props.bulkObj).length > 0 || Object.keys(props.removeObj).length > 0) && <Button color="success" onClick={() => props.startBulk() }>BULK ASSIGN / REMOVE</Button>}
          </div>
        </div>
        <div className="outlined-blue">
          <div>
            <NavPills
              color="warning"
              tabs={[
                {
                  tabButton: "SAP",
                  tabContent: <UserSubBulkFilter
                                main="SAP"
                                chooseFilter={props.chooseFilter}
                                chooseBulk={props.chooseBulk}
                                chooseRemove={props.chooseRemove}
                                filterObj={props.filterObj}
                                bulkObj={props.bulkObj}
                                removeObj={props.removeObj}
                              />
                },
                {
                  tabButton: "ADMIN",
                  tabContent: <UserSubBulkFilter
                                main="Admin"
                                chooseFilter={props.chooseFilter}
                                chooseBulk={props.chooseBulk}
                                chooseRemove={props.chooseRemove}
                                filterObj={props.filterObj}
                                bulkObj={props.bulkObj}
                                removeObj={props.removeObj}
                              />
                },
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    userBulkFilterList: state.userPerms.userBulkFilterList
  }
}

export default connect(mapStateToProps,null)(UserBulkFilter);
