import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import ReactTable from "react-table";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import styles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import cloneDeep from 'lodash/cloneDeep';

import GMPMultipleSelect from "containers/GMP/GMPMultipleSelect";
import GINaddPriceSelect from "./GINadd-price-select";
import GINpickValuesVariantsModal from "./GINpickValuesVariantsModal";
import GINalert from "./GINalert";
import GINask2 from "./GINask2";
import activeDBs from "shared/activeDBs";

import _ from 'lodash';

const DBlabel = props => {
  // props.name
  let backgroundColor = '';
  activeDBs.forEach((db) => {
    if (db.name === props.name) {
      backgroundColor = db.color;
    }
  });

  if (backgroundColor) {
    return (
      <div style={{backgroundColor}} className="db-pill">
        {props.name.split('_')[0]}
      </div>
    );
  }
  return null;
}

const CheckBoxed = props => {
  const { classes, combo, data, allChecked, readOnly } = props;
  console.log('checkboxed', combo, data, allChecked);
  const {true: count} = _.countBy(_.map(data, v => v.code), v => v === _.first(_.split(combo, '_')));
  const {true: countChecked} = _.countBy(_.keys(allChecked), v => v.startsWith(_.first(_.split(combo, '_'))) && !!allChecked[v]);
  if (readOnly) {
    return (
      <div style={{color:'orange'}}>Cannot be deleted</div>
    )
  }
  return (
  <div className="relative">
    {(!props.checked && !props.shouldBeUpdated) && <div style={{
      position: 'absolute',
      top: '12px',
      left: 0,
      fontSize: '10px'
    }} className="red-text">Will be deleted</div>}

    {
      (!props.checked && props.shouldBeUpdated)
      ?
        count !== 1 && countChecked
        ? (
          <div style={{
            position: 'absolute',
            top: '12px',
            left: 0,
            fontSize: '10px'
          }} className="blue-text">Will be updated</div>
        )
        : (
          <div style={{
            position: 'absolute',
            top: '12px',
            left: 0,
            fontSize: '10px'
          }} className="red-text">Will be deleted</div>
        )
      : <div />
    }

    <div style={{float:'right'}}>
      <Checkbox
        tabIndex={-1}
        checkedIcon={<Check className={classes.checkedIcon} />}
        icon={<Check className={classes.uncheckedIcon} />}
        classes={{checked: classes.checked}}
        checked={props.checked}
        onClick={() => {
          props.clickCheckbox();
        }}
      />
    </div>
  </div>
  )
}

const EditCell = props => {
  const className = props.isChecked ?
                         props.edit ? 'hovered' : ''
                                    : 'gray-text';
  return (
    <div className={className}
      onClick={() => {
        if (props.isChecked) {
          if (props.edit) {
            props.edit();
          }
        }
      }}
    ><span className={props.isDuplicate ? 'orange-text' : ''}>{props.name}</span></div>
  )
}

class GINpickValuesVariants extends Component {
  constructor(props) {
    super(props);

    this.state = {
      variantsData: null,

      modalVariantIndex: -1,
      modalVariantDB: '',
      modalVariantCode: '',
      modalVariantDesc: '',
      modalValidationMsg: '',
      modalValidationMsg2: '',
      modalVariantItemCode: '',
      modalVariantBaseItemDescription: '',
      openVariantModal: false,
      modalMode: '',


      ask: false,
      alert: ''
    }
  }

  openAlert = (title) => {
    this.setState({
      alert: title
    });
  }

  closeAlert = () => {
    this.setState({
      alert: ''
    });
  }

  openVariantModal = (type, dataObj) => {
    if (!dataObj) { dataObj = {} };
    let obj = { openVariantModal: true, ...dataObj };
    if (type === 'new') {
      obj.modalMode = 'new';
      obj.modalIndex = -1;
    } else {
      obj.modalMode = 'edit';
    }
    this.setState(obj);
  }

  closeVariantModal = () => {
    this.setState({
      openVariantModal: false
    });
  }

  onInputChange = (target, e) => {
    const val = e.target.value ? e.target.value.toUpperCase() : '';
    let err = '';
    if (target === 'modalVariantCode') {
      if (val) {
        if (val.length > 4) {
          err += 'Maximum of 4 characters only. ';
        }

        if (val.indexOf(' ') !== -1) {
          err += 'Cannot have blank spaces. ';
        }

        let duplicate = false;
        this.props.openedDBs.forEach((db) => {
          if (this.props.variants[db]) {
            this.props.variants[db].forEach(vr => {
              if (vr[0] === val) {
                duplicate = true;
                err += `Variant Code already exists in ${db}. `;
              }
            });
          }
        });
      } else {
        err += 'Minimum 1 character is required for Variant Code. '
      }
    } else if (target === 'modalVariantDesc') {
      // check if full item description is > 100 chars
      const fullItemDesc = `${this.state.modalVariantItemCode} ${this.state.modalVariantBaseItemDescription} - ${this.state.modalVariantDesc}`;
      if (fullItemDesc.length > 100) {
        err += 'Maximum Full Item Description is 100 characters. '
        console.log('MAX FULL ITEM',fullItemDesc);
      }
      if (!val) {
        err += 'Minimum 1 character is required for Variant Description. '
      }
    }
    this.setState({
      [target]: val,
      modalValidationMsg: err
    });
  }

  onSelectChange = (value, name) => {
    this.setState({
      [name]: value
    }, () => {
      console.log('name-value', name, value);
      console.log('st and props', this.state, this.props);
    })
  }

  generateTableData = () => {
    let dataObj = {}, duplicateCodes = {}, comboCollection = {}, codeCollection = {},
        removablesObj = {};
    this.props.openedDBs.forEach(db => {
      const itemCode = this.props.itemCodes[db],
            baseItemDescription = this.props.baseItemDescription[db];

      if (this.props.variants[db]) {
        this.props.variants[db].forEach((variant, i) => {
          console.log('variantobj', variant);
          const obj = {
            code: variant[0],
            number: variant[1],
            itemCode,
            baseItemDescription,
            index: i,
            db
          }
          const combo = `${variant[0]}|-|${this.props.variantsDesc[db][i]}`;
          if (comboCollection.hasOwnProperty(combo)) {
            comboCollection[combo].push(obj);
          } else {
            comboCollection[combo] = [obj];
          }
          if (codeCollection.hasOwnProperty(variant[0])) {
            codeCollection[variant[0]].push(obj);
          } else {
            codeCollection[variant[0]] = [obj];
          }

          if (dataObj.hasOwnProperty(db)) {
            dataObj[db].push(obj)
          } else {
            dataObj[db] = [obj];
          }
        });
      }

      if (this.props.variantsDesc[db]) {
        this.props.variantsDesc[db].forEach((desc,i) => {
          if (dataObj[db][i]) {
            dataObj[db][i].desc = desc;
          }
        });
      }
    });

    let codeWithDiffs = [];
    Object.keys(codeCollection).forEach(code => {
      let codes = [];
      codeCollection[code].forEach(varr => {
        const combo = `${code}|-|${varr.desc}`,
              combo2 = `${code}_${varr.desc}`;
        if (codes.indexOf(combo) === -1) {
          codes.push(combo);
        }
      });
      if (codes.length > 1) {
        codeWithDiffs.push(codes[0].split('|-|')[0]);
      }
    });

    // check if codeWithDiffs array elements have more than 2 in this.state.variantsChecked
    console.log('variants checked',this.props.variantsChecked);
    let objCollect = {}
    Object.keys(this.props.variantsChecked).forEach(vv => {
      const arr = vv.split('_');
      const code = arr[0],
            desc = arr[1];
      if (objCollect.hasOwnProperty(code)) {
        objCollect[code]++;
      } else {
        objCollect[code] = 1;
      }
    });

    let origCodeWithDiffs = [ ...codeWithDiffs ];
    codeWithDiffs = codeWithDiffs.filter(cwd => {
      if (objCollect.hasOwnProperty(cwd)) {
        if (objCollect[cwd] === 1) {
          return false;
        }
      }
      return true;
    });

    let tableData = { duplicateCodes, comboCollection, codeWithDiffs, origCodeWithDiffs };
    tableData.data = [];
    Object.keys(dataObj).forEach(db => {
      dataObj[db].forEach(variant => {
        tableData.data.push({
          db,
          ...variant
        });
      });
    });

    let comboTableData = [];
    Object.keys(comboCollection).forEach(combo => {
      const cdat = comboCollection[combo][0];
      const dbs = comboCollection[combo].map(c => c.db);
      const itemCodes = comboCollection[combo].map(i => i.itemCode);
      const baseItemDescriptions = comboCollection[combo].map(b => b.baseItemDescription);
      const indexes = comboCollection[combo].map(ii => ii.index);
      const number = comboCollection[combo].map(i => i.number);
      const comboObj = {
        indexes,
        dbs,
        code: cdat.code,
        desc: cdat.desc,
        itemCodes,
        baseItemDescriptions,
        number
      }
      comboTableData.push(comboObj);
    });
    tableData.comboTableData = comboTableData;

    return tableData;
  }
 
  checkIfComplete = () => {
    const variantsChecked = this.props.variantsChecked;
    console.log('checking variants', variantsChecked);
    let obj_code = {}, hasDuplicate = false;
    Object.keys(variantsChecked).forEach(vc => {
      const code = vc.split('_')[0];
      if (obj_code.hasOwnProperty(code)) {
        console.log(`there is a duplicate code ${code}, only one should be checked, uncheck the rest`);
        hasDuplicate = true;
      } else {
        obj_code[code] = 1;
      }
    });
    if (hasDuplicate) {
      this.props.setVariantStatus(false);
    } else {
      this.props.setVariantStatus(true);
    }
  }

  // on checkbox click
  variantExistence = (combo) => {
    let copy = cloneDeep(this.props.variantsChecked);
    if (copy.hasOwnProperty(combo)) {
      delete copy[combo];
    } else {
      copy[combo] = 1;
    }
    this.props.setVariantsChecked(copy);
    this.checkIfComplete();

    const variants = _.map(_.keys(copy), v => {
      const variant = _.split(v, '_');

      return {
        code: variant[0],
        desc: variant[1]
      }
    });
  }

  checkVariants = (data) => {
    let obj = JSON.parse(JSON.stringify(this.props.variantsChecked));
    console.log('1st obj',obj);
    let obj_code = {}, hasDuplicate = false;
    data.forEach(vr => {
      const combo = `${vr.code}_${vr.desc}`;
      const code = vr.code;
      if (!obj_code.hasOwnProperty(code)) {
        obj_code[code] = 1;
      } else {
        obj_code[code]++;
      }
      if (!this.props.variantsChecked.hasOwnProperty(combo)) {
        obj[combo] = 1;
      }
    });

    Object.keys(obj_code).forEach(oc => {
      if (obj_code[oc] > 1) {
        console.log('there is a duplicate code, only one should be checked, uncheck the rest');
        hasDuplicate = true
      }
    });
    console.log('2nd obj',obj);
    return {
      all: obj,
      hasDuplicate
    };
  }

  editCell = (obj) => {
    this.openVariantModal('edit',obj);
  }

  handleUpdate = () => {
    // if (!this.state.modalVariantDB) {
    //   return this.setState({
    //     modalValidationMsg: 'Please select a DB. '
    //   })
    // }
    console.log('states herere');
    console.log(this.state.modalVariantIndex, this.state.modalVariantCode, this.state.modalVariantDesc, this.state.modalVariantDB);

    let err = '';
    const code = this.state.modalVariantCode,
          desc = this.state.modalVariantDesc;
    if (!this.state.modalValidationMsg && (code && desc)) {
      const indexes = this.state.modalVariantIndex,
            db = this.state.modalVariantDB,
            copy = cloneDeep(this.props.variantsChecked);

      if (indexes === -1) {
        console.log('add mode');
        // ADD NEW VARIANT
        this.props.openedDBs.forEach(dbb => {
          let copyVariants = cloneDeep(this.props.variants[dbb]),
              copyVariantsDesc = cloneDeep(this.props.variantsDesc[dbb]);

          copyVariants.push([code,0]);
          copyVariantsDesc.push(desc);

          this.props.updateGINEdit({
            field: 'variants',
            value: copyVariants,
            db: dbb
          });
          this.props.updateGINEdit({
            field: 'variantsDesc',
            value: copyVariantsDesc,
            db: dbb
          });
        });

        const combo = `${this.state.modalVariantCode}_${this.state.modalVariantDesc}`;
        copy[combo] = 1;
        this.props.addVariant(this.state.modalVariantCode);
        this.props.setVariantsChecked(copy);

      } else if (indexes.constructor === Array && db.constructor === Array) {
        console.log('edit mode ????', indexes, db);
        // EDIT SEVERAL DBs and Indexes
        db.forEach((d,ind) => {
          let copyVariants = cloneDeep(this.props.variants[d]),
              copyVariantsDesc = cloneDeep(this.props.variantsDesc[d]);
          const indx = indexes[ind];

          if (copyVariants[indx]) {
            if (code === copyVariants[indx][0] && desc === copyVariantsDesc[indx]) {
              err += 'Code / Description combination already exists. '
            } else {
              let previousCode = copyVariants[indx][0];
              let previousDesc = copyVariantsDesc[indx];
              let copyChecked = cloneDeep(this.props.variantsChecked);
              Object.keys(copyChecked).forEach(combo => {
                if (combo === `${previousCode}_${previousDesc}`) {
                  delete copyChecked[combo];
                  copyChecked[`${code}_${desc}`] = 1;
                }
              });
              console.log('new copy checked variant', copyChecked);
              this.props.setVariantsChecked(copyChecked);

              copyVariants[indx] = [code,0];
              copyVariantsDesc[indx] = desc;
              this.props.updateGINEdit({
                field: 'variants',
                value: copyVariants,
                db: d
              });
              this.props.updateGINEdit({
                field: 'variantsDesc',
                value: copyVariantsDesc,
                db: d
              });

            }
          }
        });
      }

      this.setState({
        openVariantModal: false
      }, () => {
        const variantsData = this.generateTableData(),
              check = this.checkVariants(variantsData.comboTableData);
        const variantsChecked = check.all;
        const hasDuplicate = check.hasDuplicate;
        this.setState({
          variantsData
        });
      });
    }
  }

  expand = (vr) => {
    let arr = [];
    vr.dbs.forEach((db,i) => {
      arr.push({
        db,
        index: vr.indexes[i],
        code: vr.code,
        desc: vr.desc
      });
    });
    return arr;
  }

  checkDiffDesc = () => {
    const dat = this.generateTableData(),
          codeWithDiffs = dat.codeWithDiffs,
          comboTableData = dat.comboTableData;
    let isDuplicateDiffArr = [];
    comboTableData.forEach(vr => {
      const isDuplicateDiff = codeWithDiffs.indexOf(vr.code) !== -1 ? true : false;
      if (isDuplicateDiff) {
        isDuplicateDiffArr = isDuplicateDiffArr.concat(this.expand(vr));
      }
    });
    if (isDuplicateDiffArr.length > 0) {
      let str = '', arrr = [];
      isDuplicateDiffArr.forEach((v,i) => {
        if (arrr.indexOf(v.code) === -1) {
            str += `${v.code} - `;
            arrr.push(v.code);
        }
      });
      return str;
    }
    return false;
  }

  updateProps = () => {
    // check if there are no orange text codes
    const dat = this.generateTableData();
    const tableData = dat.data,
          comboCollection = dat.comboCollection,
          comboTableData = dat.comboTableData,
          codeWithDiffs = dat.codeWithDiffs,
          origCodeWithDiffs = dat.origCodeWithDiffs,
          duplicateCodes = dat.duplicateCodes;
    // add to props for de-activating
    let shouldBeUpdatedArr = [],
        isCheckedArr = [],
        isDuplicateDiffArr = [],
        shouldBeDeletedArr = [];

    comboTableData.forEach(vr => {

      const combo = `${vr.code}_${vr.desc}`;
      const isChecked = this.props.variantsChecked.hasOwnProperty(combo);
      const isDuplicateDiff = codeWithDiffs.indexOf(vr.code) !== -1 ? true : false;
      const shouldBeUpdated = origCodeWithDiffs.indexOf(vr.code) !== -1 ? true : false;

      if (shouldBeUpdated && !isChecked) {
        shouldBeUpdatedArr = shouldBeUpdatedArr.concat(this.expand(vr));
      }

      if (!shouldBeUpdated && !isChecked) {
        shouldBeDeletedArr = shouldBeDeletedArr.concat(this.expand(vr));
      }

      if (isChecked) {
        isCheckedArr = isCheckedArr.concat(this.expand(vr));
      }

      if (isDuplicateDiff) {
        isDuplicateDiffArr = isDuplicateDiffArr.concat(this.expand(vr));
      }
    });
    let toUpdate = {};
    shouldBeUpdatedArr.forEach(vr => {
      // look for the replacement desc
      let replacementString = '';
      isCheckedArr.forEach(vv => {
        if ((vv.code === vr.code) && (vv.db !== vr.db)) {
          replacementString = vv.desc;
        }
      });

      const vrUpdated = { ...vr, desc: replacementString };
      if (toUpdate.hasOwnProperty(vr.db)) {
        toUpdate[vr.db].push(vrUpdated);
      } else {
        toUpdate[vr.db] = [vrUpdated];
      }
    });

    let toDelete = {};
    shouldBeDeletedArr.forEach(vr => {
      if (toDelete.hasOwnProperty(vr.db)) {
        toDelete[vr.db].push(vr);
      } else {
        toDelete[vr.db] = [vr];
      }
    });

    if (isDuplicateDiffArr.length > 0) {
      console.log('STOP');
      return;
      // alert Please check a Variant Description you want to use for the Variant Code
    }


    // Update DBs with similar Codes but different Descriptions
    let variantsDeactivateCopy = cloneDeep(this.props.variantsDeactivate);

    let variantsCopy = cloneDeep(this.props.variants),
        variantsDescCopy = cloneDeep(this.props.variantsDesc);

    // create dictionary of variant code - variant description
    let checkedObj = {};
    isCheckedArr.forEach(v => {
      if (!checkedObj.hasOwnProperty(v.code)) {
        checkedObj[v.code] = v.desc;
      }
    });

    // make sure all DBs have the isCheckedArr code and descriptions
    let doesntExistVariant = {};
    this.props.openedDBs.forEach(db => {
      doesntExistVariant[db] = [];

      const oldVariantCodeList = this.props.variants[db].map(v => v[0]);
      const newVariantCodeList = isCheckedArr.map(v => v.code);

      let filteredNewVariantCodes = [];
      newVariantCodeList.forEach(vc => {
        if (filteredNewVariantCodes.indexOf(vc) === -1) {
          filteredNewVariantCodes.push(vc);
        }
      });

      console.log('codes to compare', oldVariantCodeList, filteredNewVariantCodes);
      filteredNewVariantCodes.forEach((vc,i) => {
        if (oldVariantCodeList.indexOf(vc) === -1) {
          doesntExistVariant[db].push(vc);
        }
      });
    });

    Object.keys(doesntExistVariant).forEach(db => {
      const toAdd = doesntExistVariant[db];
      const toDel = toDelete[db];
      const toUpd = toUpdate[db];
      let copyVariants = cloneDeep(this.props.variants[db]);
      let copyVariantsDesc = cloneDeep(this.props.variantsDesc[db]);

      if (toDel) {
        toDel.forEach((vr,i) => {
          let index = -1;
          copyVariants.forEach((v,i) => {
            if (v[0] === vr.code) {
              index = i;
            }
          });
          if (index !== -1) {
            copyVariants.splice(index, 1);
            copyVariantsDesc.splice(index, 1);
          }
        });
      }

      if (toUpd) {
        toUpd.forEach((vr,i) => {
          let index = -1;
          copyVariants.forEach((v,i) => {
            if (v[0] === vr.code) {
              index = i;
            }
          });
          if (index !== -1) {
            copyVariantsDesc[index] = vr.desc;
          }
        });
      }

      toAdd.forEach(vr => {
        copyVariants.push([vr,0]);
        copyVariantsDesc.push(checkedObj[vr]);
      });

      variantsCopy[db] = copyVariants;
      variantsDescCopy[db] = copyVariantsDesc;

      console.log(copyVariants, copyVariantsDesc);

      this.props.updateGINEdit({
        db,
        field: 'variants',
        value: variantsCopy[db]
      });
      this.props.updateGINEdit({
        db,
        field: 'variantsDesc',
        value: variantsDescCopy[db]
      });
      this.props.updateGINEdit({
        db,
        field: 'variantsDeactivate',
        value: variantsDeactivateCopy[db]
      });
    });
  }

  setValidationMsg = (modalValidationMsg) => {
    this.setState({modalValidationMsg});
  }

  componentDidMount() {
    const variantsData = this.generateTableData(),
          data = variantsData.comboTableData;
    if (Object.keys(this.props.variantsChecked).length === 0 && this.props.ginSource !== 'Pending') {
      console.log('is the length of variants');
      let variantsChecked = {};
      data.forEach(vr => {
        const combo = `${vr.code}_${vr.desc}`;
        if (!this.props.variantsChecked.hasOwnProperty(combo)) {
          variantsChecked[combo] = 1;
        }
      });
      this.props.setVariantsChecked(variantsChecked);
    }
    this.setState({
      variantsData
    });
  }

  render() {
    console.log('GIN variants', this.state, this.props);
    if(!this.props.active){
      return <div />;
    }

    const dat = this.state.variantsData;
    const tableData = dat.data,
          comboCollection = dat.comboCollection,
          comboTableData = dat.comboTableData,
          codeWithDiffs = dat.codeWithDiffs,
          origCodeWithDiffs = dat.origCodeWithDiffs,
          duplicateCodes = dat.duplicateCodes;

    let dbsArr = [];
    tableData.forEach(td => {
      if (dbsArr.indexOf(td.db) === -1) {
        dbsArr.push(td.db);
      }
    });

    //console.log('this is the generated combotabledata', comboTableData, comboCollection);
    let codes = {}, dupe_codes = [];
    Object.keys(comboCollection).forEach(combo => {
      const code = combo.split('|-|')[0];
      if (codes.hasOwnProperty(code)) {
        codes[code]++;
      } else {
        codes[code] = 1;
      }
    });
    Object.keys(codes).forEach(c => {
      if (codes[c] > 1) {
        dupe_codes.push(c);
      }
    });
    console.log('codes -->', codes, dupe_codes);
    const table = <div className="spec-table scrolling-table sm-header-table">
                <ReactTable
        data={
            comboTableData.map((vr, key) => {
              console.log('vcombo vr', vr);
              const combo = `${vr.code}_${vr.desc}`;
              const isChecked = this.props.variantsChecked.hasOwnProperty(combo);
              const obj = {
                modalVariantIndex: vr.indexes,
                modalVariantDB: vr.dbs,
                modalVariantCode: vr.code,
                modalVariantDesc: vr.desc,
                modalVariantItemCodes: vr.itemCodes,
                modalVariantBaseItemDescriptions: vr.baseItemDescriptions,
              };
              // const isDuplicateDiff = codeWithDiffs.indexOf(vr.code) !== -1 ? true : false;
              const isDuplicateDiff = dupe_codes.indexOf(vr.code) !== -1 ? true : false;
              const shouldBeUpdated = origCodeWithDiffs.indexOf(vr.code) !== -1 ? true : false;
              const hideDBsIfNewVariant = this.props.variantsToAdd.indexOf(vr.code) !== -1 ? true : false;
              const readOnly = vr.number.indexOf('0') !== -1 ? true : false;
              return ({
                id: key,
                index: vr.index,
                db_name: vr.dbs.join(','),
                dbCell: vr.dbs.map((d,idde) => (
                <div style={hideDBsIfNewVariant ? {display:'none'} : {dispay:'block'}} key={`a${idde}`}>
                  <div style={{marginTop:'10px',marginBottom:'10px'}} key={`${d}a`}>
                    <DBlabel name={d} />
                  </div>
                </div>
                )),
                code: vr.code,
                codeCell: (
                  <EditCell
                    name={vr.code}
                    isChecked={isChecked}
                    isDuplicate={isDuplicateDiff}
                    edit={() => {
                      this.editCell(obj);
                    }}
                  />
                ),
                number: vr.number,
                itemVariantCombo: vr.itemCodes[0] + '_' + (vr.code ? vr.code : ''),
                itemVariantComboCell: vr.itemCodes.map((ic,i) => {
                  return (
                    <EditCell
                      key={`ic${i}`}
                      name={ic + '_' + (vr.code ? vr.code : '')}
                      isChecked={isChecked}
                    />
                  )
                }),
                desc: vr.desc,
                descCell: (
                  <EditCell
                    name={vr.desc}
                    isChecked={isChecked}
                    edit={() => {
                      this.editCell(obj);
                    }}
                  />
                ),
                checkbox: '',
                checkboxCell: (
                  <CheckBoxed
                    classes={this.props.classes}
                    data={comboTableData}
                    allChecked={this.props.variantsChecked}
                    checked={this.props.variantsChecked[combo] ? true : false}
                    shouldBeUpdated={shouldBeUpdated}
                    clickCheckbox={() => {
                      this.variantExistence(combo);
                    }}
                    combo={combo}
                    readOnly={readOnly}
                  />
                )
              })
            })
        }
        filterable
        columns={[
            {
                Header: "DB",
                accessor: "db_name",
                filterMethod: (filter, row) => {
                    const chosen = filter.value;
                    if (chosen.length > 0) {
                        return chosen.indexOf(row[filter.id]) !== -1;
                    } else {
                        return true;
                    }
                },
                Filter: ({filter, onChange}) => {
                    return <div className="gmp-filter"><GMPMultipleSelect
                        options={dbsArr}
                        label={''}
                        choose={onChange}
                    /></div>
                },
                Cell: row => {
                  return (
                    <div>{row.original.dbCell}</div>
                  )
                },
                maxWidth: 80
            },
            {
                Header: "VARIANT CODE",
                accessor: "code",
                filterMethod: (filter, row) => {
                  var filterValue = filter.value.toLowerCase();
                  if (filterValue.length > 0) {
                      return row[filter.id].toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;
                  } else {
                      return true;
                  }
                },
                Cell: row => {
                  return (
                    <div>{row.original.codeCell}</div>
                  )
                },
                maxWidth: 120
            },
            {
                Header: "ITEM CODE + VARIANT CODE",
                accessor: "itemVariantCombo",
                filterMethod: (filter, row) => {
                  var filterValue = filter.value.toLowerCase();
                  if (filterValue.length > 0) {
                      return row[filter.id].toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;
                  } else {
                      return true;
                  }
                },
                Cell: row => {
                  return (
                    <div>{row.original.itemVariantComboCell}</div>
                  )
                },
                style: { whiteSpace: 'unset'}
            },
            {
                Header: "NEW DESCRIPTION",
                accessor: "desc",
                filterMethod: (filter, row) => {
                  var filterValue = filter.value.toLowerCase();
                  if (filterValue.length > 0) {
                      return row[filter.id].toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;
                  } else {
                      return true;
                  }
                },
                Cell: row => {
                  return (
                    <div>{row.original.descCell}</div>
                  )
                },
                style: { whiteSpace: 'unset'}
            },
            {
                Header: "",
                accessor: "checkbox",
                sortable: false,
                Filter: ({filter, onChange}) => {
                    return <div className="yellow-text" style={{
                                fontSize:'10px'
                              }}>Uncheck to remove variant</div>
                },
                Cell: row => {
                  return (
                    <div>{row.original.checkboxCell}</div>
                  )
                },
                minWidth: 140,
                maxWidth: 140
            },
        ]}
        resizable={false}
        minRows={0}
        defaultPageSize={1000}
        showPagination={false}
        className="-highlight"
    /></div>

    return (
    <div style={{marginTop:'20px'}} className="relative">
      {this.state.alert && <GINalert
          hide={this.closeAlert}
          content={this.state.alert}
        />
      }
      {this.state.ask && <GINask2
          command={this.updateProps}
          title="Update Variants?"
          content="NOTE: This action will not save to SAP yet."
          close={this.closeAsk}
        />
      }
      <div style={{position:'absolute',top:'0',right:'120px',zIndex:'1'}}>
        <Button
          style={{opacity:0}}
          size="sm"
          color="success"
          onClick={() => {
            const checkDiffDesc = this.checkDiffDesc();
            if (checkDiffDesc) {
              let vars = _.split(_.replace(checkDiffDesc, ' - ', '|'), '|');

              vars = _.filter(vars, v => !!v);

              for(let i of vars){
                const keys = _.filter(_.map(_.keys(this.props.variantsChecked), v => _.first(_.split(v, '_'))), v => v === i);

                if(keys.length > 1){
                  this.openAlert(`All similar Codes need to have similar Descriptions, please uncheck Descriptions you want replaced. The following codes can only have ONE checked description ${checkDiffDesc}`);
                  return;
                }
              }

              this.openAsk();
            } else {
              this.openAsk();
            }
          }}
        >NEXT</Button>
      </div>
      <div style={{position:'absolute',top:'0',right:'0',zIndex:'1'}}>
        <Button
          size="sm"
          color="rose"
          onClick={() => {
            console.log('varaints', this.props);
            // count all variants checked, should be less than 101 
            let count = 0;
            if (this.props.variantsChecked) {
              Object.keys(this.props.variantsChecked).forEach((okvc) => {
                if (this.props.variantsChecked[okvc] === 1) {
                  count++;
                }
              })
            }
            if (count < 200) {
              // allow new variant add
              this.openVariantModal('new', {
                modalVariantIndex: -1,
                modalVariantCode: '',
                modalVariantDesc: '',
                modalVariantDB: ''
              });
            } else {
              this.openAlert(`Number of variants must not exceed 200`);
            }
          }}
        >Add Variant</Button>
      </div>
      {this.state.openVariantModal &&
        <GINpickValuesVariantsModal
          onInputChange={this.onInputChange}
          onSelectChange={this.onSelectChange}
          modalVariantDB={this.state.modalVariantDB}
          modalVariantIndex={this.state.modalVariantIndex}
          modalVariantCode={this.state.modalVariantCode}
          modalVariantDesc={this.state.modalVariantDesc}
          open={this.state.openVariantModal}
          close={this.closeVariantModal}
          validationMsg={this.state.modalValidationMsg}
          validationMsg2={this.state.modalValidationMsg2}
          handleUpdate={this.handleUpdate}
          mode={this.state.modalMode}
          openedDBs={this.props.openedDBs}
          baseItemDescription={this.props.baseItemDescription}
          itemCodes={this.props.itemCodes}
          variantCodes={this.props.variants}
          setValidationMsg={this.setValidationMsg}
        />
      }
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          {table}
        </GridItem>
      </GridContainer>
    </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    baseItemDescription: state.gin.base_item_description,
    variants: state.gin.variants,
    variantsDesc: state.gin.variantsDesc,
    variantsDeactivate: state.gin.variantsDeactivate,
    variantStatus: state.gin.variantStatus,
    variantsChecked: state.gin.variantsChecked,
    variantsToAdd: state.gin.variantsToAdd,
    itemCodes: state.gin.itemCodes,
    openedDBs: state.gin.openedDBs
  }
};

const mapDispatchToProps = dispatch => {
  return {
    updateGINEdit: (data) => {
      dispatch(actions.updateGINEdit(data));
    },
    setVariantStatus: (status) => {
      dispatch(actions.setVariantStatus(status));
    },
    setVariantsChecked: (checked) => {
      dispatch(actions.setVariantsChecked(checked));
    },
    addVariant: (variant) => {
      dispatch(actions.addVariant(variant));
    }
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(GINpickValuesVariants));
