import React from "react";
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import checkPermissions from '../../shared/checkPermissions';
import checkActions from '../../shared/checkPermissionsActions';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Cached from "@material-ui/icons/Cached";
import SAPMonitor from "../../containers/SAPMonitor/SAPMonitor";

class SAPMonitorPage extends React.Component {
  constructor(props) {
    super(props);

  }

  componentDidMount() {
    this.props.checkMaintenanceMode();
  }

  render() {
    if (!checkPermissions('SAPMonitor')) {
      return <Redirect to="/dashboard" />;
    }
    if (this.props.maintenanceMode === 'yes') return <Redirect to="/components/maintenance_page" />

    return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <div className="pointed"><Cached /></div>
            </CardIcon>
          </CardHeader>
          <CardBody>
            <h1>SAP Monitor</h1>
            <SAPMonitor />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
    );
  }
}

const mapStateToProps = state => {
  return {
    maintenanceMode: state.userPerms.maintenanceMode
  }
}

const mapDispatchToProps = dispatch => {
  return {
    checkMaintenanceMode: () => {
      dispatch(actions.checkMaintenanceMode());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SAPMonitorPage);
