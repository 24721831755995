import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import * as actions from '../../store/actions';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import Button2 from "components/CustomButtons/Button.jsx";
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Typography from '@material-ui/core/Typography';
import { PulseLoader } from 'react-spinners';
import Select2 from 'react-select';
import Select from '@material-ui/core/Select';
import Table from "components/Table/Table.jsx";
import InputAdornment from '@material-ui/core/InputAdornment';
import GINSelect from "./GINselect";
import GINReactSelect from "./GINreact-select";
import { checkOneYearEndDate, checkHighestPrice, checkExFactory } from "./GINhelpers";
import Datetime from "react-datetime";
import { isValidDate, checkDateOrder, dateToday, convertToFixed, checkMinMax } from 'shared/utility';
import activeDBs from "shared/activeDBs";

const DialogTitle = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500],
  },
}))(props => {
  const { children, classes, onClose, editData } = props;
  console.log('title props', editData);
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <span style={{fontSize:'18px'}}>Edit <strong>{editData.itemName}</strong> for <strong>{editData.DB}</strong></span>
      {onClose ? (
        <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogActions = withStyles(theme => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit,
  },
}))(MuiDialogActions);

class GINpriceAllEdit extends React.Component {
  constructor(props) {
    super(props);

    const dates = dateToday();

    this.state = {
      // all customers pricing - flat price
      gterm: this.props.gterm[this.props.data.DB] ? this.props.gterm[this.props.data.DB] : '',
      gcur: this.props.gcur[this.props.data.DB] ? this.props.gcur[this.props.data.DB] : '',
      gprice: this.props.gprice[this.props.data.DB] ? this.props.gprice[this.props.data.DB] : '',
      gstart: this.props.gstart[this.props.data.DB] ? this.props.gstart[this.props.data.DB] : dates[0],
      gend: this.props.gend[this.props.data.DB] ? this.props.gend[this.props.data.DB] : dates[1],

      // all customers pricing - other prices
      gqtysData: this.props.gqtys ? this.props.gqtys[this.props.data.DB] : [],
      gtermsData: this.props.gterms ? this.props.gterms[this.props.data.DB] : [],
      gcurrsData: this.props.gcurrs ? this.props.gcurrs[this.props.data.DB] : [],
      gpricesData: this.props.gprices ? this.props.gprices[this.props.data.DB] : [],
      gstartsData: this.props.gstarts ? this.props.gstarts[this.props.data.DB] : [],
      gendsData: this.props.gends ? this.props.gends[this.props.data.DB] : [],

      pricing_terms: [],
      currencies: [],

      value: this.props.data.value,
      mocType: this.props.data.mocType,

      error: '',
      dates
    }
  }

  getFactory = (db) => {
    let fac = '';
    activeDBs.forEach(d => {
      if (d.name === db) {
        fac = d.factory;
      }
    })
    return fac;
  }

  handleUpdate = (singleField, field, index) => {
    const db = this.props.data.DB;
    if (singleField) {
      if (field !== 'gprice') {
        this.props.updateGINEdit({
          field,
          value: index || index == 0 ? this.state.value.split(',')[index] : this.state.value,
          db
        });
      } else {
        this.props.updateGINEdit({
          field,
          value: index || index == 0 ? convertToFixed(this.state.value.split(',')[index]) : convertToFixed(this.state.value),
          db
        });
      }
    } else {
      let valuesCopy = [ ...this.props[field][db] ];
      console.log('initial copy', valuesCopy, this.props.data.indx, this.state.value);
      if (this.props.data.indx || this.props.data.indx == 0) {
        console.log('got here');
        if (this.props.data.indx !== -1) {
          console.log('got here2');
          if (field === 'gprice' || field === 'gprices') {
            valuesCopy[this.props.data.indx] = index || index == 0 ? convertToFixed(this.state.value.split(',')[index]) : convertToFixed(this.state.value)
          } else {
            valuesCopy[this.props.data.indx] = index || index == 0 ? this.state.value.split(',')[index] : this.state.value
          }
        }
      }
      console.log('after copy', valuesCopy, this.props.data.indx, this.state.value);
      this.props.updateGINEdit({
        field,
        value: valuesCopy,
        db
      });
    }
  }

  setError = (error) => {
    this.setState({
      error
    });
  }

  handleSave = () => {
    const data = this.props.data;
    if (data.type === 'price') {
      console.log('checking price cheapest EX FACTORY');
      const dataObj = {
        qty: data.qty,
        term: data.term,
        price: this.state.value,
        currency: data.currency,
        db: data.db,
        prices: this.props.allPrices
      }
      const factory = this.getFactory(data.db) ? this.getFactory(data.db) : '';
      const checkCheapestFactory = checkExFactory(dataObj, this.getFactory(data.db));
      let error = '';
      console.log('checking price termCollection', data, this.state.gprice);
      const checkHighest = checkHighestPrice(dataObj);
      if (!checkHighest) {
        error += `Base Price must always be the highest price for its shipping term (${data.term}) compared to other ranges. `;
      }
      if (!checkCheapestFactory) {
        error += `EX-FACTORY price ${factory} should always be the cheapest per DB per range. `
      }
      if (!checkHighest || !checkCheapestFactory) {
        return this.setError(error);
      }
    }
    // determine if qty is from 1
    let singleField = true;
    console.log('is quantity single ?', this.props.data.qty);
    if (this.props.data.indx !== -1) {
      singleField = false;
    }

    if (this.props.data.type === 'terms') {
      if (singleField) {
        this.handleUpdate(true, 'gterm');
      } else {
        this.handleUpdate(false, 'gterms');
      }
    }

    if (this.props.data.type === 'price') {
      if (this.state.value) {
        if (isNaN(this.state.value)) {
          return this.setError('Please enter a valid Price number');
        } else {
          if (!checkMinMax(this.state.value, 5)) {
            return this.setError('Price needs to be between 0.00001 and maximum of 100,000. ');
          }
          this.setError('');
        }
      }
      if (singleField) {
        this.handleUpdate(true, 'gprice');
      } else {
        this.handleUpdate(false, 'gprices');
      }
    }

    if (this.props.data.type === 'currency') {
      if (singleField) {
        this.handleUpdate(true, 'gcur');
      } else {
        this.handleUpdate(false, 'gcurrs');
      }
    }

    if (this.props.data.type === 'effective-dates') {
      // check if valid dates
      if (this.state.value) {
        console.log('value is dates', this.state.value);
        const val = this.state.value.split(',');
        if (val[0] && val[1]) {
          console.log('there are 2 values proceed', val);
          if (isValidDate(val[0]) && isValidDate(val[1])) {
            // check if dates are in order
            if (checkDateOrder(val[0], val[1])) {
              if (singleField) {
                this.handleUpdate(true, 'gstart', 0);
                this.handleUpdate(true, 'gend', 1);
              } else {
                this.handleUpdate(false, 'gstarts', 0);
                this.handleUpdate(false, 'gends', 1);
              }
            } else {
              console.log('Invalid Dates Order');
              return this.setError('Start Date must be before End Date');
            }
          } else {
            console.log('Invalid DATES!!!');
            return this.setError('Please enter valid dates using the MMM DD YYYY format, e.g. JAN 05 2019');
          }
        }
      }
    }

    if (this.props.data.type === 'moc') {
      if (isNaN(this.state.value)) {
        return this.setError('Please enter a valid Price number for MOC');
      } else {
        if (!checkMinMax(this.state.value, 5, 'allow zero')) {
          return this.setError('MOC Price needs to be maximum of 100,000. ');
        }
        const db = this.props.data.DB;
        if (this.props.mocs[db]) {
          if (this.props.mocs[db]['$']) {
            console.log('comparing mocs now', db, this.props.mocs[db], this.state.value);
            if (parseFloat(this.props.mocs[db]['$']) == parseFloat(this.state.value)) {
              return this.setError('The MOC Price you entered is the same as the Standard MOC. ');
            }
          }
        }
        this.setError('');
        this.handleUpdateMOC();
      }
    }

    if (!this.state.error) {
      this.props.editPriceAllModeOff();
    }
  }

  handleSelectTermsChange = (e) => {
    console.log(e);
    this.setState({
      value: e
    });
    console.log('current data', this.props.data);
    console.log('current terms data',this.props.gterm, this.props.gterms);
  }

  handleSelectChange = (value) => {
    this.setState({
      value
    })
  }

  onFloatChange = (target, e) => {
    const re = /^[.0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
       this.setState({value: e.target.value})
    }
  }

  handleDateChange = (date, field) => {
    const valueArr = this.state.value.split(',');
    let start = valueArr[0],
        end = valueArr[1],
        error = '';
    if (typeof date === 'string') {
      error = 'Invalid Date.';
    }
    if (field === 'gstarts') {
      start = date.format('MMM DD YYYY');
    } else {
      end = date.format('MMM DD YYYY');
    }
    if (field === 'gends') {
      const withinOneYear = checkOneYearEndDate(start, end);
      if (!withinOneYear) {
        error = 'End Date cannot be more than 1 year from Start Date';
      }
    }
    this.setState({
      value: `${start},${end}`.toUpperCase(),
      error
    });
  }

  setMOCtoDefault = () => {
    const data = this.props.data;
    let valuesCopy = [ ...this.props.specialmoc[data.DB] ];
    console.log('initial copy', valuesCopy, this.state.value);
    this.props.updateGINEdit({
      field:'specialmoc',
      value: valuesCopy.filter((specialmoc) => {
        if (specialmoc[0] === data.currency) {
          return false;
        } else {
          return true;
        }
      }),
      db: data.DB
    });
    this.props.editPriceAllModeOff();
  }

  handleUpdateMOC = () => {
    const data = this.props.data;
    let valuesCopy = this.props.specialmoc[data.DB] ? [ ...this.props.specialmoc[data.DB] ] : [];
    let found = false;
    let newValueArr = valuesCopy.length > 0 ? valuesCopy.map((specialmoc) => {
      if (specialmoc) {
        console.log('current moc',specialmoc);
        if (specialmoc[0] === data.currency) {
          let newArr = [ ...specialmoc];
          newArr[1] = convertToFixed(this.state.value);
          console.log('changed moc ?',newArr);
          return newArr;
        } else {
          return specialmoc;
        }
      }
    }) : [];
    // check if currency is in here
    let currencyExists = false;
    newValueArr.forEach((nva) => {
      if (nva[0] === data.currency) {
        currencyExists = true;
      }
    });
    if (newValueArr.length === 0 || !currencyExists) {
      newValueArr.push([data.currency, this.state.value, '']);
    }
    console.log('initial copy', valuesCopy, this.state.value, data.currency);
    console.log('after copy', newValueArr, this.state.value, data.currency);
    this.props.updateGINEdit({
      field:'specialmoc',
      value: newValueArr,
      db: data.DB
    });
    this.props.editPriceAllModeOff();
  }

  render() {
    console.log('all pricings, STATE & PROPS', this.state, this.props);
    const data = this.props.data,
          currentCurr = data.currency,
          currentTerm = data.term;
    const { allPrices } = this.props;

    let pricingTermsOptions = [];
    if (this.props.options[data.DB]) {
      if (this.props.options[data.DB].terms.pricing_terms) {
        pricingTermsOptions = this.props.options[data.DB].terms.pricing_terms.map((pt) => {
          return {
            id: pt,
            name: pt
          }
        });
      }
    }
    let currencyOptions = [];
    if (this.props.options[data.DB]) {
      if (this.props.options[data.DB].currencies) {
        currencyOptions = this.props.options[data.DB].currencies.map((c) => {
          return {
            id: c.symb,
            name: c.symb
          }
        });
      }
    }
    console.log('options', pricingTermsOptions, currencyOptions);

    let otherSimilarData = [];
    Object.keys(allPrices[data.DB]).forEach(range => {
      allPrices[data.DB][range].forEach(pr => {
        console.log('comparing', pr.currency, currentCurr, pr.term, currentTerm);
        if (pr.currency === currentCurr && pr.term === currentTerm) {
          let totalPrice = pr.total;
          if (pr.total < pr.moc) {
            totalPrice = pr.moc;
          }
          otherSimilarData.push([
            range,
            pr.term,
            `${pr.currency} ${pr.price}`,
            pr.currency,
            `${pr.start} TO ${pr.end}`,
            `${pr.currency} ${totalPrice}`
          ]);
        }
      });
    });

    return (
      <div id="gin-modal2" className="dialog-overflow">
        <Dialog
          onClose={() => {
            this.props.editPriceAllModeOff();
          }}
          aria-labelledby="customized-dialog-title"
          open={this.props.open}
          maxWidth={this.props.data.type === 'effective-dates' ? 'md' : 'sm'}
          fullWidth={true}
          className="dialog-overflow2"
        >
          <DialogTitle id="customized-dialog-title"
            onClose={() => {
              this.props.editPriceAllModeOff();
            }}
            editData={this.props.data}
          >
          </DialogTitle>
          <form className="item-input-margin" style={{backgroundColor:'#fcfcfc'}} onSubmit={(e) => {
            e.preventDefault();
            this.handleSave();
          }}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={3}></GridItem>
              {this.props.data.type === 'terms' && <GridItem xs={12} sm={12} md={6}>
                <div className="select-adjust">
                  <GINSelect
                    label="Shipping Terms"
                    options={pricingTermsOptions}
                    choose={this.handleSelectTermsChange}
                    name="gterms"
                    indx="id"
                    desc="name"
                    default={this.state.value}
                  />
                </div>
              </GridItem>}
              {this.props.data.type === 'currency' && <GridItem xs={12} sm={12} md={6}>
                <div className="select-adjust">
                  <GINSelect
                    label="Currency"
                    options={currencyOptions}
                    choose={this.handleSelectChange}
                    name="gcurrs"
                    indx="id"
                    desc="name"
                    default={this.state.value}
                  />
                </div>
              </GridItem>}
              {this.props.data.type === 'price' && <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Price"
                  id="pricing_price"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: (e) => {
                      this.onFloatChange('gprices', e);
                    },
                    value: this.state.value,
                    autoFocus: true,
                    role: 'presentation',
                    autoComplete: 'nope'
                  }}
                />
              </GridItem>}
              <GridItem xs={12} sm={12} md={3}></GridItem>
            </GridContainer>
            <GridContainer>
              {this.props.data.type === 'effective-dates' && <GridItem xs={12} sm={12} md={6}>
                <div className="calendar-adjust" style={{overflow:'visible',padding:'0px 20px 40px 40px',width:'100%'}}>
                  <Datetime
                    inputProps={{ placeholder: "Start Date" }}
                    dateFormat="MMM DD YYYY"
                    timeFormat={false}
                    value={this.state.value.split(',')[0]}
                    onChange={(date) => {
                      this.handleDateChange(date, 'gstarts')
                    }}
                    closeOnSelect={true}
                  />
                </div>
              </GridItem>}
              {this.props.data.type === 'effective-dates' && <GridItem xs={12} sm={12} md={6}>
                <div className="calendar-adjust" style={{overflow:'visible',padding:'0px 20px 40px 40px',width:'100%'}}>
                  <Datetime
                    inputProps={{ placeholder: "End Date" }}
                    dateFormat="MMM DD YYYY"
                    timeFormat={false}
                    value={this.state.value.split(',')[1]}
                    onChange={(date) => {
                      this.handleDateChange(date, 'gends')
                    }}
                    closeOnSelect={true}
                  />
                </div>
              </GridItem>}
            </GridContainer>
            <GridContainer>
              {this.props.data.type === 'moc' && <GridItem xs={12} sm={12} md={3}></GridItem>}
              {this.props.data.type === 'moc' && <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="MOC"
                  id="pricing_moc"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: (e) => {
                      this.onFloatChange('moc', e);
                    },
                    value: this.state.value,
                    autoFocus: true,
                    role: 'presentation',
                    autoComplete: 'nope',
                    startAdornment: <InputAdornment position="start">{this.state.gcur ? this.state.gcur : ''}</InputAdornment>
                  }}
                />
              </GridItem>}
              {this.props.data.type === 'moc' && <GridItem xs={12} sm={12} md={3}></GridItem>}
              <GridItem xs={12} sm={12} md={12}>
                <div style={{textAlign:'center'}}>
                  {(this.props.data.mocType === 'Special' && this.props.data.type === 'moc') && <div>
                    <Button2
                      color="rose"
                      type="button"
                      onClick={() => {
                        this.setMOCtoDefault();
                      }}
                    >SET TO STANDARD</Button2>
                  </div>}
                  <Button2
                    color="success"
                    type="button"
                    onClick={() => {
                      this.handleSave();
                    }}
                  >Save</Button2>
                  {this.state.error &&
                    <div className="red-text text-center" style={{padding:'20px'}}>{this.state.error}</div>}
                </div>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                {otherSimilarData.length > 0 && <div style={{marginTop:'20px'}}>
                  <h4 className="text-center">SIMILAR ITEMS</h4>
                  <Table
                    tableHeaderColor="primary"
                    tableHead={["QTY","SHIP TERMS", "PRICE", "CURRENCY", "EFFECTIVE DATES", "TOTAL PRICE"]}
                    tableData={otherSimilarData}
                    coloredColls={[3]}
                    colorsColls={["primary"]}
                  />
                </div>}
              </GridItem>
            </GridContainer>
          </form>
          <DialogActions>
            <Button onClick={() => {
              this.props.editPriceAllModeOff();
            }} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    gcur: state.gin.gcur,
    gstart: state.gin.gstart,
    gend: state.gin.gend,
    gprice: state.gin.gprice,
    gterm: state.gin.gterm,
    gcurrs: state.gin.gcurrs,
    gstarts: state.gin.gstarts,
    gends: state.gin.gends,
    gprices: state.gin.gprices,
    gqtys: state.gin.gqtys,
    gterms: state.gin.gterms,
    mocs: state.gin.mocs,
    specialmoc: state.gin.specialmoc,
    itemCodes: state.gin.itemCodes,
    openedDBs: state.gin.openedDBs,
    options: state.gin.options,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateItem: (data, mode) => {
      console.log('updating item for table');
      dispatch(actions.updateEditTable2(data, mode));
    },
    updateGINEdit: (data) => {
      dispatch(actions.updateGINEdit(data));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GINpriceAllEdit);
