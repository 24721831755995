import React from "react";
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import checkPermissions from '../../shared/checkPermissions';
import checkActions from '../../shared/checkPermissionsActions';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Cached from "@material-ui/icons/Cached";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import CustomInput from "components/CustomInput/CustomInputValidate.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomRadio from "components/CustomRadio/CustomRadio";
import { FadeLoader } from 'react-spinners';
import { num_whitelist } from "shared/validation";

const minute_whitelist = value => {
  if (value) {
    if (!isNaN(value)) {
      const num = parseInt(value);
      if (num > -1 && num < 60) {
        return undefined;
      } else {
        return "Must be between 0 to 59";
      }
    }
  }
}

const hour_whitelist = value => {
  if (value) {
    if (!isNaN(value)) {
      const num = parseInt(value);
      if (num > -1 && num < 60) {
        return undefined;
      } else {
        return "Must be between 0 to 23";
      }
    }
  }
}

class SettingsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      enableMaintenanceCheck: '',
      startMin: '',
      startHour: '',
      endMin: '',
      endHour: '',

      impositions1: '',
      impositions2: '',
      impositions3: '',
      impositions4: 0,
      impositions5: '',
      impositions6: '',

      alertMsg: '',

    }
  }

  handleSelectChange = (value, name) => {
    this.setState({
      [name]: value
    })
  }

  handleTextInputChange = (e, field) => {
    this.setState({
      [field]: e.target.value
    });
  }

  handleRadioChange = (e, value, field) => {
    console.log('field', value, field)
    this.setState({
      [field]: value
    });
  }

  componentDidMount() {
    this.props.readSettings();
    this.props.tppMaintenanceCheck();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data != this.props.data) {
      if (this.props.data) {
        if (this.props.data.length > 0) {
          let enableMaintenanceCheck = '';
          let startMin = '';
          let startHour = '';
          let endMin = '';
          let endHour = '';
          let impositions1 = '';
          let impositions2 = '';
          let impositions3 = '';
          let impositions4 = '';
          let impositions5 = '';
          let impositions6 = '';
          this.props.data.forEach(d => {
            if (d.id === 1) enableMaintenanceCheck = d.value;
            if (d.id === 2) startMin = d.value;
            if (d.id === 3) startHour = d.value;
            if (d.id === 4) endMin = d.value;
            if (d.id === 5) endHour = d.value;

            if (d.id === 6) impositions1 = d.value;
            if (d.id === 7) impositions2 = d.value;
            if (d.id === 8) impositions3 = d.value;
            if (d.id === 9) impositions4 = d.value ? parseInt(d.value) : 0;
            if (d.id === 10) impositions5 = d.value;
            if (d.id === 11) impositions6 = d.value;
          });
          this.setState({
            enableMaintenanceCheck,
            startMin,
            startHour,
            endMin,
            endHour,
            impositions1,
            impositions2,
            impositions3,
            impositions4,
            impositions5,
            impositions6
          })
        }
      }
    }
  }

  render() {
    console.log('the default is stt prr', this.state, this.props);
    const devPermitted = checkPermissions('Developer');
    const tppPermitted = checkPermissions('TPPmaintenance');
    const impositionsSettingsPermitted = checkPermissions('ImpositionsSettings');
    if (!devPermitted && !tppPermitted && !impositionsSettingsPermitted) {
      return <Redirect to="/dashboard" />;
    }
    return (
  <div>
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <div className="pointed"><Cached /></div>
            </CardIcon>
          </CardHeader>
          <CardBody>
            <h1>Settings</h1>

            <div style={devPermitted ? {} : {display:'none'}}>
              <h3><strong>BP/GIN TPP Enable Integration Maintenance</strong></h3>
              <p>Enable Maintenance Window ? (1 = Yes, 0 = No)</p>
              <GridContainer>
                <GridItem xs={3} sm={3} md={3}>
                  <CustomSelect
                    label="Enable Maintenance"
                    options={['1','0']}
                    choose={this.handleSelectChange}
                    name="enableMaintenanceCheck"
                    indx=""
                    desc=""
                    default={this.state.enableMaintenanceCheck}
                  />
                </GridItem>
              </GridContainer>
              <h3><strong>BP/GIN TPP Integration Maintenance Window (Pacific Standard Time)</strong></h3>
              <GridContainer>
                <GridItem xs={3} sm={3} md={3}>
                  <CustomInput
                    label="Start Hour (0-23)"
                    name="startHour"
                    value={this.state.startHour}
                    handleTextInputChange={this.handleTextInputChange}
                    validate={[num_whitelist, hour_whitelist]}
                    required
                  />
                </GridItem>
                <GridItem xs={3} sm={3} md={3}>
                  <CustomInput
                    label="Start Minute (0-59)"
                    name="startMin"
                    value={this.state.startMin}
                    handleTextInputChange={this.handleTextInputChange}
                    validate={[num_whitelist, minute_whitelist]}
                    required
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={3} sm={3} md={3}>
                  <CustomInput
                    label="End Hour (0-23)"
                    name="endHour"
                    value={this.state.endHour}
                    handleTextInputChange={this.handleTextInputChange}
                    validate={[num_whitelist, hour_whitelist]}
                    required
                  />
                </GridItem>
                <GridItem xs={3} sm={3} md={3}>
                  <CustomInput
                    label="End Minute (0-59)"
                    name="endMin"
                    value={this.state.endMin}
                    handleTextInputChange={this.handleTextInputChange}
                    validate={[num_whitelist, minute_whitelist]}
                    required
                  />
                </GridItem>
              </GridContainer>
            </div>
            <div style={impositionsSettingsPermitted ? {} : {display:'none'}}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <h3><strong>Impositions</strong></h3>
                  <h4>Plate Value</h4>
                  <p>An additional plate may only be used for the following waste amount: Enter value to use</p>
                </GridItem>
                <GridItem xs={3} sm={3} md={3}>
                  <CustomInput
                    label="X Waste (Number)"
                    name="impositions1"
                    value={this.state.impositions1}
                    handleTextInputChange={this.handleTextInputChange}
                    validate={[num_whitelist]}
                    required
                  />
                </GridItem>
                <GridItem xs={3} sm={3} md={3}>
                  <CustomSelect
                    label="Compare Parameter"
                    options={['>','<']}
                    choose={this.handleSelectChange}
                    name="impositions2"
                    indx=""
                    desc=""
                    default={this.state.impositions2}
                  />
                </GridItem>
                <GridItem xs={3} sm={3} md={3}>
                  <CustomInput
                    label="Y% Waste (Number)"
                    name="impositions3"
                    value={this.state.impositions3}
                    handleTextInputChange={this.handleTextInputChange}
                    validate={[num_whitelist]}
                    required
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <h4>Space Allocation</h4>
                  <CustomRadio
                    name="impositions4"
                    label="Remaining UPS will be allocated to the following variant based on:,Least amount of overage (number),Least amount of overage (percent)"
                    options={[0,1]}
                    onChange={this.handleRadioChange}
                    value={this.state.impositions4}
                  />
                </GridItem>
                {/*<GridItem xs={3} sm={3} md={3}>
                  {this.state.impositions4 === 0 && <CustomInput
                    label="< X % waste (Number)"
                    name="impositions5"
                    value={this.state.impositions5}
                    handleTextInputChange={this.handleTextInputChange}
                    validate={[num_whitelist]}
                    required
                  />}
                </GridItem>*/}
                {/*<GridItem xs={3} sm={3} md={3}>
                  {this.state.impositions4 === 1 && <CustomInput
                    label="< Y % waste (Percent)"
                    name="impositions6"
                    value={this.state.impositions6}
                    handleTextInputChange={this.handleTextInputChange}
                    validate={[num_whitelist]}
                    required
                  />}
                </GridItem>*/}
                <GridItem xs={3} sm={3} md={3}>
                </GridItem>
              </GridContainer>
            </div>
            <div style={impositionsSettingsPermitted || devPermitted ? {} : {display:'none'}}>
              <GridContainer>
                <GridItem xs={3} sm={3} md={12}>
                  <Button onClick={() => {
                    let complete = true;
                    // check all entries
                    let data = [];
                    const fieldObj = {
                      'enableMaintenanceCheck': 1,
                      'startMin': 2,
                      'startHour': 3,
                      'endMin': 4,
                      'endHour': 5,
                      'impositions1': 6,
                      'impositions2': 7,
                      'impositions3': 8,
                      'impositions4': 9,
                      'impositions5': 10,
                      'impositions6': 11
                    };
                    Object.keys(fieldObj).forEach((field) => {
                      if (field !== 'enableMaintenanceCheck') {
                        if (this.state[field] === null || this.state[field] === '') {
                          complete = false;
                        }
                      }
                      data.push({
                        key: fieldObj[field],
                        value: this.state[field]
                      });
                    });
                    if (complete) {
                      this.props.updateSettings(data);
                      this.setState({
                        alertMsg: 'Successfully Updated Settings'
                      });
                    } else {
                      this.setState({
                        alertMsg: 'Incomplete Settings'
                      });
                    }
                  }} color="rose">
                  UPDATE
                  </Button>
                  {this.state.alertMsg && <div style={{
                    color: this.state.alertMsg === 'Successfully Updated Settings' ? 'green' : 'red',
                    marginTop:20
                  }}>{this.state.alertMsg}</div>}
                </GridItem>
              </GridContainer>
            </div>
              <hr />
              {tppPermitted && <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <h3><strong>TPP Magento</strong> {this.props.tppMaintenanceStatus ?
                                                      this.props.tppMaintenanceStatus === 'yes' ?
                                                        '(Maintenance Status ON)' : '(Maintenance Status OFF)'
                                                      : <span><FadeLoader /></span>}</h3>
                  {this.props.tppMaintenanceStatus === 'no' && <Button
                    onClick={() => {
                      this.props.tppMaintenance(true);
                    }}
                    color="danger"
                    style={{marginRight:20}}
                  >
                    ENABLE MAINTENANCE
                  </Button>}
                  {this.props.tppMaintenanceStatus === 'yes' && <Button
                    onClick={() => {
                      this.props.tppMaintenance(false);
                    }}
                    color="success"
                  >
                    DISABLE MAINTENANCE
                  </Button>}
                </GridItem>
              </GridContainer>}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>

  </div>
    );
  }
}

const mapStateToProps = state => {
  console.log('settings', state.settings);
  return {
    loading: state.settings.loading,
    error: state.settings.error,
    data: state.settings.data,
    tppMaintenanceStatus: state.settings.tppMaintenanceStatus,
    impositionsStatus: state.settings.impositionsStatus
  }
}

const mapDispatchToProps = dispatch => {
  return {
    readSettings: () => {
      dispatch(actions.readSettings());
    },
    updateSettings: (data) => {
      dispatch(actions.updateSettings(data));
    },
    tppMaintenance: (status) => {
      dispatch(actions.tppMaintenance(status));
    },
    tppMaintenanceCheck: () => {
      console.log('checking maintenance status');
      dispatch(actions.tppMaintenanceCheck());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage);
