import React from "react";
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { getFormSyncErrors, change } from 'redux-form';
import { required, email, blacklist, tel_blacklist, zipcode_blacklist, alphanum_whitelist } from 'shared/validation';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Datetime from "react-datetime";
import InputLabel from '@material-ui/core/InputLabel';
import * as actions from 'store/actions';
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import Radio from "@material-ui/core/Radio";
import SweetAlert from "react-bootstrap-sweetalert";
import withStyles from "@material-ui/core/styles/withStyles";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import combineStyles from "shared/combineStyles";
import { PulseLoader } from 'react-spinners';
import { normalizePhone } from "shared/utility";

// @material-ui/icons
import Settings from "@material-ui/icons/Settings";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Clearfix from "components/Clearfix/Clearfix.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";
import TextField from '@material-ui/core/TextField';

import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";
import { formatDate } from "shared/utility";
import { fetchGMPAddress, eraseGMPAlertMsg} from "store/actions/GMP";
import GMPProfileReview from "./GMPProfileReview";
import charValidate from "shared/charValidation";

const upper = value => value && value.toUpperCase();
const inputLabelStyle = { color:'#aaaaaa', fontSize:'14px' };
const checkIfEmpty = obj => Object.keys(obj).length === 0 && obj.constructor === Object;

const renderDateField = ({
  input,
  meta: { touched, error },
  label,
  classes
}) => {
  return (
    <div style={{paddingTop:'23px'}}>
      <FormControl fullWidth>
        <Datetime
          closeOnSelect={true}
          timeFormat={false}
          inputProps={{
            placeholder: label
          }}
          onChange={param => {
            let dateString = '';
            if (typeof param.format === 'function') {
              dateString = param.format('MM/DD/YYYY');
            }
            if (moment(dateString, "MM/DD/YYYY", true).isValid()) {
              input.onChange(dateString);
            } else {
              input.onChange(null);
            }
          }}
          name={input.name}
        />
      </FormControl>
    </div>
  );
}

const renderRadio = ({
  input,
  meta: { touched, error },
  label,
  classes,
  options
}) => {
  return (
    <div
      className={
        classes.checkboxAndRadio +
        " " +
        classes.checkboxAndRadioHorizontal
      }
    >
      <div style={{marginBottom:'-5px',marginTop:'20px'}}>{label.split(',')[0]}</div>
      <FormControlLabel
        control={
            /*<span style={{paddingLeft:'12px'}}>{label.split(',')[0]}</span>*/
            <Radio
              checked={input.value == options[0]}
              onChange={input.onChange}
              value={options[0]}
              name={input.name}
              aria-label="A"
              icon={
                <FiberManualRecord
                  className={classes.radioUnchecked}
                />
              }
              checkedIcon={
                <FiberManualRecord
                  className={classes.radioChecked}
                />
              }
              classes={{
                checked: classes.radio
              }}
            />
        }
        classes={{
          label: classes.label
        }}
        label={label.split(',')[1]}
      />
    <FormControlLabel
      control={
        <Radio
          checked={input.value === options[1]}
          onChange={input.onChange}
          value={options[1]}
          name={input.name}
          aria-label="B"
          icon={
            <FiberManualRecord
              className={classes.radioUnchecked}
            />
          }
          checkedIcon={
            <FiberManualRecord
              className={classes.radioChecked}
            />
          }
          classes={{
            checked: classes.radio
          }}
        />
      }
      classes={{
        label: classes.label
      }}
      label={label.split(',')[2]}
    />
  </div>
  );
}

const renderCheckbox = ({
    input,
    meta: { touched, error },
    label,
    classes
}) => {
    console.log(input);
    return (
        <div className="checkbox-label-gray">
            <FormControlLabel
                control={
                    <Checkbox
                        tabIndex={-1}
                        onClick={input.onChange}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                            checked: classes.checked
                        }}

                        checked={input.value}
                    />
                }

                label={label}
            />

        </div>
    );}

const renderCheckboxWTax = ({
    input,
    meta: { touched, error },
    label,
    classes
}) => {
    console.log(input);
    return (
        <div className="checkbox-label-gray">
            <FormControlLabel
                control={
                    <Checkbox
                        tabIndex={-1}
                        onClick={input.onChange}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                            checked: classes.checked
                        }}

                        checked={input.value}
                    />
                }

                label={label}
            />

        </div>
    );}

const renderTextAreaField = ({
  input,
  label,
  meta: { touched, error }
}) => (
  <div style={{marginTop:'0px'}} className="textarea-nep">
    <CustomInput
      labelText={label}
      id="notes-me"
      className="text-field-input-custom"
      style={{width:'100%'}}
      formControlProps={{
        fullWidth: true
      }}
      inputProps={{
        multiline: true,
        rows: 2,
        onChange: input.onChange,
        value: input.value
      }}
      error={error ? true : false}
    />
    {
      (error && <span className="red">{error}</span>)}
  </div>
)

const renderTextField = ({
    show_error,
    input,
    label,
    className,
    meta: { touched, error }
}) => (
    <div>
      <CustomInput
          labelText={label}
          id={input.name}
          formControlProps={{
              fullWidth: true,
          }}
          inputProps={{
              onChange: input.onChange,
              value: input.value,
              className: className
          }}
          error={error ? true : false}
      />
      {
        (error && <span className="red">{error}</span>)}
    </div>
  )

  const renderPhoneField = ({
      show_error,
      input,
      label,
      className,
      meta: { touched, error }
  }) => (
      <div>
        <CustomInput
            labelText={label}
            id={input.name}
            formControlProps={{
                fullWidth: true,
            }}
            inputProps={{
                onChange: input.onChange,
                value: normalizePhone(input.value),
                className: className
            }}
            error={error ? true : false}
        />
        {/*(!error && <span className="gray">{normalizePhone(input.value)}</span>)*/}
        {(error && <span className="red">{error}</span>)}
      </div>
      )

const renderEmailField = ({
  input,
  label,
  meta: { touched, error }
}) =>
  (
    <div>
      <CustomInput
          labelText={label}
          id={input.name}
          formControlProps={{
              fullWidth: true,
          }}
          inputProps={{
              onChange: input.onChange,
              value: input.value
          }}
          error={true}
      />
      {
        (error && <span className="red">{error}</span>)}
    </div>
  )

  const renderSelectField = ({
      input,
      options,
      meta: { touched, error },
      label
  }) => (
      <div className="fourteen-font">
          <FormControl style={{ width: '100%', marginTop: '10px' }} error={error ? true : false}>
              <InputLabel htmlFor={input.name} style={inputLabelStyle}>{label}</InputLabel>
              <Select
                  value={input.value}
                  inputProps={{
                      onChange: input.onChange,
                      name: input.name,
                      id: input.name,
                  }}
              >
                  <MenuItem disabled value="">{input.name}</MenuItem>
                  {options.map(option => (
                      <MenuItem key={option} value={option}>{option}</MenuItem>
                  ))}

              </Select>
          </FormControl>
          {
            (error && <span className="red">{error}</span>)}
        </div>
  )

  const renderGSTSelectField = ({
      input,
      options,
      meta: { touched, error },
      label
  }) => (
      <div className="fourteen-font">
          <FormControl style={{ width: '100%', marginTop: '10px' }} error={error ? true : false}>
              <InputLabel htmlFor={input.name} style={inputLabelStyle}>{label}</InputLabel>
              <Select
                  value={input.value}
                  inputProps={{
                      onChange: input.onChange,
                      name: input.name,
                      id: input.name,
                  }}
              >
                  <MenuItem disabled value="">{label}</MenuItem>
                  {options.map(option => (
                      <MenuItem key={option.id} value={option.id}>{option.type}</MenuItem>
                  ))}

              </Select>
          </FormControl>
          {
            (error && <span className="red">{error}</span>)}
        </div>
  )

  const renderRBOSelectField = ({
      input,
      options,
      meta: { touched, error }
  }) => (
          <div className="fourteen-font">
              <FormControl style={{ width: '100%', marginTop: '10px' }} error={error ? true : false}>
                  <InputLabel htmlFor={input.name} style={inputLabelStyle}>RBO (optional)</InputLabel>
                  <Select
                      value={input.value}
                      inputProps={{
                          onChange: input.onChange,
                          name: 'rbo',
                          id: input.name,
                      }}
                  >
                      <MenuItem disabled value="">(optional)</MenuItem>
                      {options.map(option => (
                          <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                      ))}

                  </Select>
              </FormControl>
              {
                (error && <span className="red">{error}</span>)}
            </div>
      )

  const renderCountrySelectField = ({
      input,
      options,
      meta: { touched, error },
      label
  }) => (
          <div className="fourteen-font">
              <FormControl style={{ width: '100%', marginTop: '10px' }} error={error ? true : false}>
                  <InputLabel htmlFor={input.name} style={inputLabelStyle}>{label}</InputLabel>
                  <Select
                      value={input.value}
                      inputProps={{
                          onChange: input.onChange,
                          name: input.name,
                          id: input.name,
                      }}
                  >
                      <MenuItem disabled value="">(optional)</MenuItem>
                      {options.map(option => (
                          <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                      ))}

                  </Select>
              </FormControl>
              {
                (error && <span className="red">{error}</span>)}
            </div>
      )

      const renderStateSelectField = ({
          input,
          options,
          meta: { touched, error },
          label
      }) => (
              <div className="fourteen-font">
                  <FormControl style={{ width: '100%', marginTop: '10px' }} error={error ? true : false}>
                      <InputLabel htmlFor={input.name} style={inputLabelStyle}>{label}</InputLabel>
                      <Select
                          value={input.value}
                          inputProps={{
                              onChange: input.onChange,
                              name: input.name,
                              id: input.name,
                          }}
                      >
                          <MenuItem disabled value="">- select one -</MenuItem>
                          {options.map(option => (
                              <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                          ))}

                      </Select>
                  </FormControl>
                  {
                    (error && <span className="red">{error}</span>)}
                </div>
          )

          const renderCurrencySelectField = ({
              input,
              options,
              meta: { touched, error },
              label
          }) => (
                  <div className="fourteen-font">
                      <FormControl style={{ width: '100%', marginTop: '10px' }} error={error ? true : false}>
                          <InputLabel htmlFor={input.name} style={inputLabelStyle}>{label}</InputLabel>
                          <Select
                              value={input.value}
                              inputProps={{
                                  onChange: input.onChange,
                                  name: input.name,
                                  id: input.name,
                              }}
                          >
                              <MenuItem disabled value="">- select one -</MenuItem>
                              {options.map(option => (
                                  <MenuItem key={option.name} value={option.id}>{option.name}</MenuItem>
                              ))}

                          </Select>
                      </FormControl>
                      {
                        (error && <span className="red">{error}</span>)}
                    </div>
              )

const checkChar = (value, testChar) => {
  let arrv = value.split('');
  let result = true;
  arrv.forEach((c) => {
    if (testChar.indexOf(c) !== -1) {
      return result = false;
    }
  });
  return result;
}

const checkDropdown = (value, type, props, countrycode) => {
  if (type === 'currency') {
    if (props.currencies) {
      let existing = false;
      props.currencies.forEach((c) => {
        if (c.id === value) {
          existing = true;
        }
      });
      return existing;
    }
    return true;
  } else if (type === 'country') {
    if (props.countries) {
      let existing = false;
      props.countries.forEach((c) => {
        if (c.id === value) {
          existing = true;
        }
      });
      return existing;
    }
    return true;
  } else if (type.indexOf('state') !== -1) {
    console.log('checking state for', value, props, countrycode);
    if (props.states && countrycode) {
      let existing = false;
      if (props.states[countrycode]) {
        props.states[countrycode].forEach((c) => {
          if (c.id === value) {
            existing = true;
          }
        });
      }
      return existing;
    }
    return true;
  } else if (type === 'gstregtype') {
    if (props.gsttypes) {
      let existing = false;
      props.gsttypes.forEach((g) => {
        if (g.id === value) {
          existing = true;
        }
      });
      return existing;
    }
    return true;
  } else if (type === 'DB') {
    if (props.sap_dbs) {
      let existing = false;
      props.sap_dbs.forEach((d) => {
        if (d === value) {
          existing = true;
        }
      });
      return existing;
    }
    return true;
  }
}
const minLength = min => value =>
  value && value.length < min ? `Must be minimum of ${min} characters` : undefined
const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined
const maxLength50 = maxLength(50);
const maxLength100 = maxLength(100);
const maxLength10 = maxLength(10);
const maxLength20 = maxLength(20);
const maxLength15 = maxLength(15);
const maxLength13 = maxLength(13);
const minLength12 = minLength(12);
const minLength10 = minLength(10);
const capitalize = (words) => {
  return words.map(w => w.charAt(0).toUpperCase() + w.substr(1) );
}
const isPresent = value => !value ? 'please select value' : undefined;
const validate = (values, props) => {
  const errors = {}
  // aCharms = telephone numbers and business postal code
  // iChars = emails
  // oChorms = everything else
  if (values.DB) {
    console.log('checking the db', values.DB, props);
    if (!checkDropdown(values.DB, 'DB', props)) {
      errors.DB = 'Please select a DB. Original submission is '+values.DB+'.';
    }
  }
  if (values.currency) {
    if (!checkDropdown(values.currency, 'currency', props)) {
      errors.currency = 'Please select a currency. Original submission is '+values.currency+'.'
    }
  }
  if (values.billing_country) {
    if (!checkDropdown(values.billing_country, 'country', props)) {
      errors.billing_country = 'Please select a country. Original submission is '+values.billing_country+'.'
    }
  }
  if (values.shipping_country) {
    if (!checkDropdown(values.shipping_country, 'country', props)) {
      errors.shipping_country = 'Please select a country. Original submission is '+values.shipping_country+'.'
    }
  }
  if (values.billing_state) {
    if (!checkDropdown(values.billing_state, 'state', props, values.billing_country)) {
      errors.billing_state = 'Please select a state. Original submission is '+values.billing_state+'.'
    }
  }
  if (values.shipping_state) {
    if (!checkDropdown(values.shipping_state, 'state', props, values.shipping_country)) {
      errors.shipping_state = 'Please select a state. Original submission is '+values.shipping_state+'.'
    }

  }

  if (values.DB === 'NIN_LIVE' || values.DB === 'NIN_TEST') {
    if (!values.shipping_state) {
      if (props.finalValues) {
        if (props.finalValues.gstin) {
          errors.shipping_country = 'Please select a country with states (required when GSTIN exists)';
        }
      }
    }
  }

  if (values.billing_gstregtype) {
    if (!checkDropdown(values.billing_gstregtype, 'gstregtype', props)) {
      errors.billing_gstregtype = 'Please select a GST Reg.Type corresponding to '+values.billing_gstregtype+'.'
    }
  }
  if (values.shipping_gstregtype) {
    if (!checkDropdown(values.shipping_gstregtype, 'gstregtype', props)) {
      errors.shipping_gstregtype = 'Please select a GST Reg.Type corresponding to '+values.shipping_gstregtype+'.'
    }
  }
  if (values.gen_tel) {
    if (!checkChar(values.gen_tel, charValidate.aCharms)) {
      errors.gen_tel = 'Invalid character in this field (' + charValidate.aCharms + ')';
    }
  }

  if (values.gen_fax) {
    if (!checkChar(values.gen_fax, charValidate.aCharms)) {
      errors.gen_fax = 'Invalid character in this field (' + charValidate.aCharms + ')';
    }
  }

  if (values.shipping_tel) {
    if (!checkChar(values.shipping_tel, charValidate.aCharms)) {
      errors.shipping_tel = 'Invalid character in this field (' + charValidate.aCharms + ')';
    }
  }

  if (values.billing_tel) {
    if (!checkChar(values.shipping_tel, charValidate.aCharms)) {
      errors.billing_tel = 'Invalid character in this field (' + charValidate.aCharms + ')';
    }
  }

  if (values.other_tel) {
    if (!checkChar(values.other_tel, charValidate.aCharms)) {
      errors.other_tel = 'Invalid character in this field (' + charValidate.aCharms + ')';
    }
  }

  if (values.other_name || values.other_tel || values.other_email || values.other_position) {
    let allFilled = true,
        otherFields = ['name','tel','email','position'];
    otherFields.forEach((o) => {
      if (!values['other_'+o]) {
        allFilled = false;
        errors['other_'+o] = 'If one Other field is filled, All Other fields are Required';
      }
    });
  }

  if (props.hasOwnProperty('shippingStates') && !values.shipping_state) {
    if (props.shippingStates) {
      if (props.shippingStates.length != 0) {
        errors.shipping_state = 'Please select a shipping state';
      }
    }
  }

  const inputChecks = ['company_name', 'gen_tel', 'gen_fax', 'panno', 'rfc_curp', 'invoice_num', 'gstin',
        'billing_address1', 'billing_address2', 'billing_addressname','billing_city',
        'billing_email', 'billing_name','billing_position','billing_tel','billing_zipcode','billing_gstregno','billing_gstregtype',
        'shipping_address1', 'shipping_address2', 'shipping_addressname','shipping_city',
        'shipping_email', 'shipping_name','shipping_position','shipping_tel','shipping_zipcode',,'shipping_gstregno','shipping_gstregtype',
        'other_email','other_name','other_position','other_tel'];

  inputChecks.forEach((inp) => {
    const whitelisted = charValidate.allInputRegex.test(values[inp]);
    if (!whitelisted) { errors[inp] = 'Only A-Z a-z 0-9 and some special characters are allowed on this field'}
  });
  console.log('ERR PROFILE', values, errors);
  return errors;
}

const renderSimilars = (similars, type, showAlert) => {
  const similarsStyle = {
    padding:'0px 6px',
    borderRadius:'10px',
    backgroundColor:'#ffa500',
    color:'white',
    display:'inline-block',
    fontWeight:'bold',
    color:'white',
    marginTop:'10px',
    height: '20px'
  }

  let alertMsg = [];
  // 0 - Company Name
  // 3 - Gen Tel
  // 2 - Billing Address
  let field = 'Street',
      headers = ['BP Name','Street'];
  if (type === 0) {
    field = 'CardName';
    headers = ['Card Code','BP Name'];
  } else if (type === 3) {
    field = 'Phone1';
    headers = ['BP Name','General Phone'];
  } else if (type === 1) {
    field = 'Name';
    headers = ['BP Name','Contact Name'];
  }

  similars[type].forEach((s) => {
    let fieldContent = s[field];
    if (type === 1) {
      const fieldIndex = fieldContent.indexOf(' - ');
      if (fieldIndex !== -1) {
        fieldContent = fieldContent.split(' - ')[1];
      }
    }

    if (field !== 'CardName') {
      alertMsg.push(s.CardName+','+fieldContent);
    } else {
      alertMsg.push(s.CardCode+','+fieldContent);
    }
  });

  return similars[type].length > 0 ?
        <div
          style={similarsStyle}
          className="similars"
          onClick={() => {
            showAlert(alertMsg, headers);
          }}
        >
          {similars[type].length}
        </div> : '';
}

const renderOWHTPS = (opts, status, props) => {
  if (opts) {
    const renderedOpts = opts.map((o,i) => {
      if (status === "1") { // status 0 = assessee 1, status 1 = assessee 2
        if (o.Assessee === "2") {
          return <Field key={'wtx'+i} name={'wtaxcode_'+i+'_'+o.WTCode} component={renderCheckboxWTax} label={o.WTName} classes={props.classes} />;
        }
      }

      if (status === "0") {
        if (o.Assessee === "1") {
          return <Field key={'wtx'+i} name={'wtaxcode_'+i+'_'+o.WTCode} component={renderCheckboxWTax} label={o.WTName} classes={props.classes} />;
        }
      }
      return;
    });

    return renderedOpts;
  }
  return <div><em style={{color:'#aaa'}}>None selected</em></div>;
}

let GMPProfileForm = props => {
  const handleBillingCountryChange = (event) => {
    console.log('you changed the billing country', props.reviewValues.billing_state);
    //props.changeBillingCountry(event);
    props.change('billing_state', null);
  }
  const handleShippingCountryChange = (event) => {
    console.log('you changed the shipping country', props.reviewValues.shipping_state);
    //props.changeShippingCountry(event);
    props.change('shipping_state', null);
  }
  const handleDBChange = (event) => {
    props.changeDB(event);
    props.change('currency', null);
  }
  const handleChangeAssessee = (fields) => {
    console.log('all fields', fields);
    fields.forEach((f) => {
      if (f.indexOf('wtaxcode_') !== -1) {
        props.change(f, false);
        console.log(f, 'change');
      }
    });
  }
  const { handleSubmit, loadingReview, classes, cancelProfileView, bp, sap_dbs, rbos, countries, currencies, reviewMode, cancelReview, approveReview, denyReview, reviewValues, gsttypes, similars, showAlert, showDenyAlert, hideDenyAlert, submitErrors, denyAlert, owhtopts, errorModalOn, checkModalError, checkModalErrorOff } = props;
  console.log('PROPS IN PROFILE', props, reviewValues);

  let isCompleteAddressVal = false;
  if (reviewValues.billing_country && reviewValues.shipping_country) {
    isCompleteAddressVal = true;
  }

  const isDisabled = true;
  const style1 = {
    padding: '0 10px 10px 10px',
    border: '1px solid #f1f1f1',
    marginTop: '20px'
  }

  let showRFCCURP = false,
      showGroupCode = false;
  if ((reviewValues.DB === 'NMX_LIVE' || reviewValues.DB === 'NMX_TEST') && (reviewValues.billing_country === 'MX')) { // If Mexican Billing address and MEXICO DB
    showRFCCURP = true;
  }

  if (reviewValues.DB === 'NMX_LIVE' || reviewValues.DB === 'NMX_TEST') {
    showGroupCode = true;
  }

  if (reviewValues.DB === 'NKR_LIVE' || reviewValues.DB === 'NKR_TEST') {
    showGroupCode = true;
  }

  let showNIT = false;
  if ((reviewValues.DB === 'NGT_LIVE' || reviewValues.DB === 'NGT_TEST') && (reviewValues.billing_country === 'GT')) { // If Guatemalan Billing country and NGT DB
    showNIT = true;
  }

  let withhold = false;
  if (reviewValues.withholding) {
    withhold = true;
  }

  let isNIN = false;
  if (reviewValues.DB === 'NIN_LIVE' || reviewValues.DB === 'NIN_TEST') {
    isNIN = true;
  }

  let isAssessee = false;
  if (reviewValues.assessee_type === 1) {
    isAssessee = true; // Company = 0, Others = 1
  }

  const renderWTax = renderOWHTPS(owhtopts, reviewValues.assessee_type, props);

  let errorAll = false;
  let errorFields = [];
  let errorOriginal = [];
  if (bp.hasOwnProperty('origins')) {
    console.log('Original Values',bp.origins);
  }
  if (!checkIfEmpty(submitErrors)) {
    errorAll = true;
    errorFields = Object.keys(submitErrors).map((err) => {
      let errArr = err.split('_');
      return capitalize(errArr).join(' ');
    });
    Object.keys(submitErrors).forEach((err) => {
      const errA = capitalize(err.split('_'));
      const errM = errA.join(' ');
      if (bp.originals[err] != undefined) {
        errorOriginal.push(errM + ' "' + bp.originals[err] + '"');
      }
    });
  }

  let errorModal = '';
  if (errorModalOn) {
    errorModal = (<SweetAlert
        error
        title={"Form Errors and Requirements"}
        style={{ display: "block" }}
        onConfirm={() => {
          checkModalErrorOff();
        }}
        confirmBtnCssClass={
          props.classes.button + " " + props.classes.success
        }
        >
        <div>
          Please go over the form to see which fields have errors and requirements.
        </div>
        </SweetAlert>);
  }

  const confirmDeny = (<SweetAlert
    warning
    style={{ display: "block", marginTop: "-100px" }}
    title="Are you sure?"
    onConfirm={() => {
      hideDenyAlert();
      denyReview({
        id: props.bp.id,
        notes: props.reviewValues.notes
      });
      props.cancelProfileView2();

    }}
    onCancel={() => hideDenyAlert()}
    confirmBtnCssClass={
      props.classes.button + " " + props.classes.success
    }
    cancelBtnCssClass={
      props.classes.button + " " + props.classes.danger
    }
    confirmBtnText="Yes, deny it!"
    cancelBtnText="Cancel"
    showCancel
  >
    Are you sure you want to deny this submission?
  </SweetAlert>);

  let companySimilars = '',
      telSimilars = '',
      addressSimilars = '',
      contact1_similars = '',
      contact2_similars = '',
      contact3_similars = '';

  if (similars) {
    if (similars.length === 4) {
      companySimilars = renderSimilars(similars, 0, showAlert);
      telSimilars = renderSimilars(similars, 3, showAlert);
      addressSimilars = renderSimilars(similars, 2, showAlert);

      const contact1 = bp.accounts_payable_name ? bp.accounts_payable_name.substring(0,5) : '';
      const contact2 = bp.purchasing_name ? bp.purchasing_name.substring(0,5) : '';
      const contact3 = bp.other_name ? bp.other_name.substring(0,5) : '';

      let contact1Arr_A = [[]];
      const takeOffs = ['GMP AP -', 'GMP PURCHASING -', 'GMP OTHER -'];
      const c1Arr = similars[1].filter((c) => {
        let partOf = false,
            cont = c.Name;
        takeOffs.forEach((tk) => {
          if (cont.indexOf(tk) !== -1) {
            partOf = true;
          }
        });
        if (partOf) {
          cont = cont.split(' -')[1];
        }
        return cont.includes(contact1);
      });
      contact1Arr_A.push(c1Arr);
      contact1_similars = renderSimilars(contact1Arr_A, 1, showAlert);

      let contact2Arr_A = [[]];
      const c2Arr = similars[1].filter((c) => {
        let partOf = false,
            cont = c.Name;
        takeOffs.forEach((tk) => {
          if (cont.indexOf(tk) !== -1) {
            partOf = true;
          }
        });
        if (partOf) {
          cont = cont.split(' - ')[1];
        }
        return cont.includes(contact2);
      });
      contact2Arr_A.push(c2Arr);
      contact2_similars = renderSimilars(contact2Arr_A, 1, showAlert);

      let contact3Arr_A = [[]];
      const c3Arr = similars[1].filter((c) => {
        let partOf = false,
            cont = c.Name;
        takeOffs.forEach((tk) => {
          if (cont.indexOf(tk) !== -1) {
            partOf = true;
          }
        });
        if (partOf) {
          cont = cont.split(' - ')[1];
        }
        return cont.includes(contact3);
      });
      contact3Arr_A.push(c3Arr);
      contact3_similars = renderSimilars(contact3Arr_A, 1, showAlert);
    }
  }

  const billingStates = props.states[reviewValues.billing_country];
  const shippingStates = props.states[reviewValues.shipping_country];

  let form = (<form onSubmit={handleSubmit}>
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <Settings />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              GMP BP Profile : <span style={{color:'#559dcb'}}>NEW BP</span>
            </h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                {!isCompleteAddressVal && <div style={{textAlign:'center'}}>Loading Data<PulseLoader /></div>}
              </GridItem>
              <GridItem xs={12} sm={12} md={5}>
                <Field validate={[required,maxLength100,blacklist]} name="company_name" component={renderTextField} label="Company Name" normalize={upper} />
                {companySimilars}
              </GridItem>
              <GridItem xs={12} sm={12} md={5}>
                <Field
                  validate={required}
                  name="DB"
                  component={renderSelectField}
                  label="Database"
                  options={sap_dbs}
                  onChange={handleDBChange}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={2}>
                {/* CardCode */}
                {bp.customer_num && <Field validate={required} disabled={isDisabled} name="customer_num" component={renderTextField} label="BP Code" />}
                {!bp.customer_num &&
                  <div className="disable-me red-inp">
                    <CustomInput
                      labelText="BP Code"
                      id="other-to-email"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: 'NEW BP',
                        disabled: true
                      }}
                    />
                  </div>
                }
              </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem xs={12} sm={12} md={5}>
                {/* General Telephone */}
                <Field validate={[required,maxLength20,tel_blacklist]} name="gen_tel" component={renderTextField} label="General Telephone #" />
                {telSimilars}
              </GridItem>
              <GridItem xs={12} sm={12} md={5}>
                {/* General Fax */}
                <Field name="gen_fax" validate={[maxLength20,tel_blacklist]} component={renderTextField} label="General Fax # (optional)" />
              </GridItem>
              <GridItem xs={12} sm={12} md={2}>
                {/* Currency */}
                <Field
                  validate={required}
                  name="currency"
                  component={renderCurrencySelectField}
                  options={currencies}
                  label="Currency" />
              </GridItem>
            </GridContainer>

            <GridContainer>
                {
                  isNIN &&
                  <GridItem xs={12} sm={12} md={5}>
                    {/* P.A.N. */}
                    <Field name="panno" validate={[required,maxLength10,minLength10]} component={renderTextField} label="P.A.N. No." />
                  </GridItem>
                }
                {
                  isNIN &&
                  <GridItem xs={12} sm={12} md={7}>
                    <Field  name="withholding" component={renderCheckbox} label="Subject to Withholding Tax" classes={props.classes} value={true}/>
                  </GridItem>
                }
                {
                  isNIN &&
                  <GridItem xs={12} sm={12} md={5}>
                    {/* GSTIN */}
                    <Field
                      name="gstin"
                      validate={[maxLength20]}
                      component={renderTextField}
                      label="G.S.T.I.N. (optional)"
                      show_error={false}
                     />
                  </GridItem>
                }
                { isNIN && <GridItem xs={12} sm={12} md={7}></GridItem>}
                { /* NMX or NKR ONLY */
                  showGroupCode &&
                  <GridItem xs={12} sm={12} md={3}>
                    <Field
                      validate={required}
                      name="groupcode"
                      component={renderSelectField}
                      options={['Taxable Customers','Non Taxable Customer','Intercompany Cust']}
                      label="Group Code"
                    />
                  </GridItem>
                }
                {
                  (isNIN && withhold) &&
                  <GridItem xs={12} sm={12} md={5}>
                    <Field  name="certificate_num" component={renderTextField} label="Certificate No. (optional)" />
                  </GridItem>
                }
                {
                  (isNIN && withhold) &&
                  <GridItem xs={12} sm={12} md={5}>
                    <Field  name="expiration_date" component={renderDateField} label="Expiration Date (optional)" />
                  </GridItem>
                }
                {
                  (isNIN && withhold) &&
                  <GridItem xs={12} sm={12} md={5}>
                    <Field  name="ni_num" component={renderTextField} label="N.I. Number (optional)" />
                  </GridItem>
                }
                {
                  /* Assessee Type */
                  (isNIN && withhold) && <GridItem xs={12} sm={12} md={7}>
                    <Field
                      name="assessee_type"
                      component={renderRadio}
                      label="Assessee Type,Company,Others"
                      classes={props.classes}
                      options={["0","1"]}
                      onChange={() => {
                        console.log('you changed assessee type');
                        if (props.finalValues) {
                          handleChangeAssessee(Object.keys(props.finalValues));
                        }
                      }}
                    />
                  </GridItem>
                }
                {
                  /* Accrual Criteria */
                  (isNIN && withhold) && <GridItem xs={12} sm={12} md={5}>
                    <Field
                      name="accrual_criteria"
                      component={renderRadio}
                      label="Accrual Criteria,Accrual,Cash"
                      classes={props.classes}
                      options={["1","0"]}
                    />
                    <Field
                      name="threshold_overlook"
                      component={renderRadio}
                      label="Threshold Overlook,Yes,No"
                      classes={props.classes}
                      options={["1","0"]}
                    />
                    <Field
                      name="surcharge_overlook"
                      component={renderRadio}
                      label="Surcharge Overlook,Yes,No"
                      classes={props.classes}
                      options={["1","0"]}
                    />
                  </GridItem>
                }
                {
                  (isNIN && withhold) &&
                  <GridItem xs={12} sm={12} md={7}>
                    <div style={{paddingLeft:'12px',paddingTop:'22px'}}>
                      <strong style={{color:'#aaa'}}>WTAX Codes Allowed :</strong>
                      <span style={{color:'#aaa'}}>(Select Assessee Type to show)</span>
                      {renderWTax}
                    </div>
                  </GridItem>
                }
            </GridContainer>

            <hr style={{marginTop:'36px'}}/>

            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <h4><strong>Billing Address</strong></h4>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <Field validate={[required,maxLength50,blacklist]} name="billing_addressname" component={renderTextField} label="Name" normalize={upper} />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <Field validate={[required,maxLength100,blacklist]} name="billing_address1" component={renderTextField} label="Address 1" normalize={upper} />
                {addressSimilars}
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <Field validate={[maxLength100,blacklist]} name="billing_address2" component={renderTextField} label="Address 2 (optional)" normalize={upper} />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={3}>
                <Field validate={[required,maxLength100,blacklist]} name="billing_city" component={renderTextField} label="City" normalize={upper} />
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <Field
                  validate={required}
                  name="billing_country"
                  component={renderCountrySelectField}
                  options={countries}
                  onChange={handleBillingCountryChange}
                  label="Country"
                  normalize={upper}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                {billingStates && <Field
                  validate={[required, isPresent]}
                  name="billing_state"
                  component={renderStateSelectField}
                  options={billingStates}
                  label="State"
                  normalize={upper}
                  />}
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <Field validate={[required,maxLength20,zipcode_blacklist]} name="billing_zipcode" component={renderTextField} label="Zipcode" normalize={upper} />
              </GridItem>
              {showRFCCURP &&
                <GridItem xs={12} sm={12} md={3}>
                  {/* RFC/CURP */}
                  <Field name="rfc_curp" validate={[minLength12,maxLength13,required,alphanum_whitelist]} component={renderTextField} label="RFC/CURP" />
                </GridItem>
              }
              {showNIT &&
                <GridItem xs={12} sm={12} md={3}>
                  {/* NIT */}
                  <Field name="nit" validate={required} component={renderTextField} label="NIT" />
                </GridItem>
              }
            </GridContainer>

            {isNIN &&
            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <Field validate={[required,maxLength15,alphanum_whitelist]} name="billing_gstregno" component={renderTextField} label="GST Reg. No." normalize={upper} />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <Field
                  validate={required}
                  name="billing_gstregtype"
                  component={renderGSTSelectField}
                  options={gsttypes}
                  label="GST Reg.Type"
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
              </GridItem>
            </GridContainer>}

            <hr style={{marginTop:'36px'}}/>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <h4><strong>Shipping Address</strong></h4>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <Field validate={[required,maxLength50,blacklist]} name="shipping_addressname" component={renderTextField} label="Name" normalize={upper} />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <Field validate={[required,maxLength100, blacklist]} name="shipping_address1" component={renderTextField} label="Address 1" normalize={upper} />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <Field validate={[maxLength100, blacklist]} name="shipping_address2" component={renderTextField} label="Address 2 (optional)" normalize={upper} />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={3}>
                <Field validate={[required,maxLength100,blacklist]} name="shipping_city" component={renderTextField} label="City" normalize={upper} />
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <Field
                  validate={required}
                  name="shipping_country"
                  component={renderCountrySelectField}
                  options={countries}
                  onChange={handleShippingCountryChange}
                  label="Country" normalize={upper} />
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                {shippingStates && <Field
                  validate={required}
                  name="shipping_state"
                  component={renderStateSelectField}
                  options={shippingStates}
                  label="State"
                  normalize={upper}
                />}
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <Field validate={[required,maxLength20]} name="shipping_zipcode" component={renderTextField} label="Zipcode" normalize={upper} />
              </GridItem>
            </GridContainer>

            {isNIN &&
            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <Field validate={[required,maxLength15,alphanum_whitelist]} name="shipping_gstregno" component={renderTextField} label="GST Reg. No." normalize={upper} />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>

                <Field
                  validate={required}
                  name="shipping_gstregtype"
                  component={renderGSTSelectField}
                  options={gsttypes}
                  label="GST Reg.Type"
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
              </GridItem>
            </GridContainer>}

            <hr style={{marginTop:'36px'}}/>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <h4><strong>Contacts</strong></h4>
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <div className="input-font-12">
                  <Field validate={[required,maxLength50,blacklist]} name="accounts_payable_name" component={renderTextField} label="Accounts Payable Name" normalize={upper} />
                </div>
                {contact1_similars}
              </GridItem>
              <GridItem xs={12} sm={12} md={2}>
                <div className="input-font-12">
                  <Field validate={[required,maxLength50,blacklist]} name="accounts_payable_position" component={renderTextField} label="Position" normalize={upper} />
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={2}>
                <div className="input-font-12">
                  <Field validate={[required,maxLength20,tel_blacklist]} name="accounts_payable_tel" component={renderTextField} label="Telephone" normalize={upper} />
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={5}>
                <div className="input-font-12">
                  <Field validate={[required,maxLength100,email]} name="accounts_payable_email" component={renderTextField} label="AP Email" normalize={upper} />
                </div>
              </GridItem>
            </GridContainer>
            <hr className="space-hr" />
            <GridContainer>
              <GridItem xs={12} sm={12} md={3}>
                <div className="input-font-12">
                  <Field validate={[required,maxLength50,blacklist]} name="purchasing_name" component={renderTextField} label="Purchasing Name" normalize={upper} />
                </div>
                {contact2_similars}
              </GridItem>
              <GridItem xs={12} sm={12} md={2}>
                <div className="input-font-12">
                  <Field validate={[required,maxLength50,blacklist]} name="purchasing_position" component={renderTextField} label="Position" normalize={upper} />
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={2}>
                <div className="input-font-12">
                  <Field validate={[required,maxLength20,tel_blacklist]} name="purchasing_tel" component={renderTextField} label="Telephone" normalize={upper} />
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={5}>
                <div className="input-font-12">
                  <Field validate={[required,maxLength100,email]} name="purchasing_email" component={renderTextField} label="Purchasing Email" normalize={upper} />
                </div>
              </GridItem>
            </GridContainer>
            <hr className="space-hr" />
            <GridContainer>
              <GridItem xs={12} sm={12} md={3}>
                <div className="input-font-12">
                  <Field validate={[maxLength50,blacklist]} name="other_name" component={renderTextField} label="Other Name" normalize={upper} />
                </div>
                {reviewValues.other_name && contact3_similars}
              </GridItem>
              <GridItem xs={12} sm={12} md={2}>
                <div className="input-font-12">
                  <Field validate={[maxLength50,blacklist]} name="other_position" component={renderTextField} label="Position" normalize={upper} />
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={2}>
                <div className="input-font-12">
                  <Field validate={[maxLength20,tel_blacklist]} name="other_tel" component={renderTextField} label="Telephone" normalize={upper} />
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={5}>
                <div className="input-font-12">
                  <Field validate={[maxLength100,email]} name="other_email" component={renderTextField} label="Other Email" normalize={upper} />
                </div>
              </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <div className="textarea-14" style={style1}><Field name="notes" component={renderTextAreaField} label="Notes" /></div>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}></GridItem>
            </GridContainer>

            <GridContainer>
              <div style={{marginTop:'20px'}} className="separate-btns">
              <GridItem xs={12} sm={12} md={12}>
                {!loadingReview && <div><Button
                  color="success"
                  type="submit"
                  className={classes.updateProfileButton}
                  onClick={() => {
                    setTimeout(function(){
                      props.handleLoadingReview();
                    }, 200);
                    props.eraseGMPAlertMsg();
                    console.log('checking errors for the modal', errorAll);
                    checkModalError(errorAll);
                    window.scrollTo(0, 0);
                  }}
                >
                  REVIEW
                </Button>
                <Button
                  color="danger"
                  type="button"
                  className={classes.updateProfileButton}
                  onClick={() => {
                    showDenyAlert();
                  }}
                >
                  DENY
                </Button>
                <Button
                  color="rose"
                  type="button"
                  className={classes.updateProfileButton}
                  onClick={cancelProfileView}
                >
                  BACK TO LIST
                </Button></div>
              }
                {loadingReview && <div>Opening Review &amp; Checking for Errors <PulseLoader /></div>}
              </GridItem>
              </div>
              <GridItem xs={12} sm={12} md={12}>
                {errorModal}
              </GridItem>
            </GridContainer>
            <Clearfix />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
    {denyAlert && confirmDeny}
  </form>
);

// {errorAll && <p style={{color:'red'}}><br />{errorFields.join()} {errorFields.length > 1 ? 'are required fields' : 'is a required field'}.</p>}
// {errorOriginal.length > 0 && <p style={{color:'red'}}><br />{errorOriginal.join()} was not found in the available options.</p>}

  if (reviewMode) {
    form = (<GMPProfileReview
      bp={props.reviewValues}
      rbos={props.rbos}
      shippingStates={props.shippingStates}
      billingStates={props.billingStates}
      gsttypes={props.gsttypes}
      countries={props.countries}
      cancelReview={props.cancelReview}
      cancelProfileView={props.cancelProfileView}
      approveReview={props.approveReview}
      miniModalOpenHandler={props.miniModalOpenHandler}
      miniModalClosedHandler={props.miniModalClosedHandler}
      showAlertModal={props.showAlertModal}
      approveSuccess={props.approveSuccess}
      denySuccess={props.denySuccess}
      failSubmit={props.failSubmit}
      rboNames={props.rbos}
      originals={props.bp.originals}
    />);
  }
  return form;

}

GMPProfileForm = reduxForm({
  form: 'gmpProfileForm',
  enableReinitialize: true,
  validate
})(GMPProfileForm);

const mapStateToProps = (state, props) => {
  const selector = formValueSelector('gmpProfileForm');
  const values = selector(state, 'id', 'company_name', 'DB', 'rbo', 'gen_tel', 'gen_fax', 'panno', 'rfc_curp', 'invoice_num', 'currency',
                  'billing_addressname', 'billing_address1', 'billing_address2', 'billing_city', 'billing_state', 'billing_zipcode',
                  'billing_country', 'accounts_payable_name', 'accounts_payable_position', 'accounts_payable_tel', 'accounts_payable_email', 'billing_gstregno','billing_gstregtype',
                  'shipping_addressname', 'shipping_address1', 'shipping_address2', 'shipping_city', 'shipping_state','gstin', 'shipping_gstregno','shipping_gstregtype', 'shipping_zipcode','shipping_country', 'purchasing_name',
                  'purchasing_position', 'purchasing_tel', 'purchasing_email','assessee_type','wtaxcodes',
                  'other_name', 'other_position', 'other_tel','other_email','notes','withholding','certificate_num','expiration_date','ni_num');
  return {
    states: state.common.states,
    initialValues: props.bp,
    reviewValues: values,
    finalValues: state.form.gmpProfileForm ? state.form.gmpProfileForm.values : null,
    submitErrors: getFormSyncErrors('gmpProfileForm')(state)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    eraseGMPAlertMsg: () => {
      dispatch(eraseGMPAlertMsg())
    }
  }
}

const combinedStyles = combineStyles(sweetAlertStyle, regularFormsStyle);

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(combinedStyles)(GMPProfileForm));
