import React, { Component } from 'react';
import ReactDOM from "react-dom";
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import withStyles from "@material-ui/core/styles/withStyles";
import AttachMoney from "@material-ui/icons/AttachMoney";
import Delete from "@material-ui/icons/Delete";
import { numberWithCommas, convertDateStr, dateRangeOverlaps } from 'shared/utility';
import cloneDeep from "lodash/cloneDeep";
import dupesCalc from "./dupesCalc";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ReactTable from "react-table";
import GridItem from "components/Grid/GridItem.jsx";
import Table from "components/Table/Table2.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GINqtyAlertPriceCustomers from "./GINqty-alert-price-customers";
import GINpriceAllEdit from "./GINprice-all-edit";
import GINqtyAlert from "./GINqty-alert";
import GINaddPriceSpecificCustomer from "./GINadd-price-specific-customer";
import GINaddPriceSelect from "./GINadd-price-select";
import GINask from "./GINask2";
import GMPMultipleSelect from "containers/GMP/GMPMultipleSelect";
import Button from "components/CustomButtons/Button.jsx";

import { ranges, rangesObj } from "./GINranges.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import activeDBs from "shared/activeDBs";

const comparator = (a, b) => {
   if (a[0] < b[0]) return -1;
   if (a[0] > b[0]) return 1;
   return 0;
 }

 const getRangeField = (qty) => {
   let finalRange;
   ranges.forEach((r) => {
     if (qty < 10000000) {
       if (r.rangeA <= qty && r.rangeB >= qty) {
         finalRange = r.name;
       }
     }
   });
   return finalRange;
 }

const style = {
  customCardContentClass: {
    paddingLeft: "0",
    paddingRight: "0"
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const DBlabel = props => {
  // props.name
  let backgroundColor = '';
  activeDBs.forEach((db) => {
    if (db.name === props.name) {
      backgroundColor = db.color;
    }
  });

  if (backgroundColor) {
    return (
      <div style={{backgroundColor}} className="db-pill">
        {props.name.split('_')[0]}
      </div>
    );
  }
  return null;
}

const QtySelect = props => {
  // props.selected, props.name
  const backgroundColor = props.selected ? 'orange' : 'rgba(0,0,0,0)',
        color = props.selected ? 'white' : 'black';
  return (
    <div style={{backgroundColor,color,fontWeight:'bold'}} className="pilled hovered" onClick={() => {
      props.choose(props.name); }}>
      {props.name}
    </div>
  );
}

const ColoredNum = props => {
  // 0 = green
  // 1-6 = orange < 50%
  // above 6 = red > 50%

  let color;
  if (props.number === 0) {
    color = 'green-text';
  } else if (props.number > 0 && props.number <= 6 ) {
    color = 'orange-text';
  } else {
    color = 'red-text';
  }
  return (
    <div className={`${color} text-center`}><strong>{props.number}</strong></div>
  );
}

const Field = props => {
  const isCurrentCurr = true;
  const color = isCurrentCurr ? 'black' : '#c8c8c8';
  return (
    <div
      style={{color}}
      onClick={() => {
        if (props.click) {
          props.click();
        }
      }}
      className="hovered relative"
    >{props.name}
    </div>
  )
}

class GINpriceMatrixCustomerSpecific extends Component {
  constructor(props) {
    super(props);

    this.state = {
      quantityRange: 'Base Price',
      selectedDB: null,
      editData: null,
      editTableData: null,
      minRange: '',
      ginAsk: false,
      askRequest: '',
      rowData: null,
      openPriceSpecificModal: false,
      openPriceAddSelect: false,
      rowToDelete: null
    }
  }

  closePriceSpecificModal = () => {
    this.setState({
      openPriceSpecificModal: false
    });
  }

  openPriceSpecificModal = (editTableData) => {
    this.setState({
      openPriceSpecificModal: true,
      editTableData
    });
  }

  groupByQuantity = () => {
    let rangeCollection = {}, mocCollection = {};
    Object.keys(this.props.customers).forEach(db => {
      if (this.props.customers[db]) {
        console.log('customers to be grouped',this.props.customers[db]);
        Object.keys(this.props.customers[db]).forEach(customer => {
          const customerName = customer.split('[.]')[1];
          // find special moc if any
          let mocObj = {};
          this.props.customers[db][customer].forEach((pr,i) => {
            if (parseInt(pr[0]) === 0) {
              const curr = pr[5];
              mocObj[curr] = {
                term: pr[1],
                moc: pr[2] ? parseFloat(pr[2]) : 0,
                code: pr[3],
                ccode: pr[4],
                start: pr[6],
                end: pr[7],
                index: i
              }
            }
          });
          this.props.customers[db][customer].forEach((pr, indx) => {
            const qty = parseInt(pr[0]),
                  term = pr[1],
                  price = parseFloat(pr[2]),
                  code = pr[3],
                  ccode = pr[4],
                  currency = pr[5],
                  start = pr[6],
                  itemName = this.props.itemCodes[db],
                  moc = mocObj[currency] ? parseInt(mocObj[currency].moc) : this.props.mocs[db][currency] ? this.props.mocs[db][currency] : 0,
                  mocType = mocObj[currency] ? 'Special' : 'Standard',
                  end = pr[7],
                  mocSpecial = mocObj,
                  index = indx;

            const obj = {
              qty, term, price, code, ccode, currency, start, end, db, itemName, customerName, moc, mocType, mocSpecial, index
            };
            console.log('obj',obj);

            ranges.forEach(range => {
              if (qty >= range.rangeA && qty <= range.rangeB) {
                if (rangeCollection.hasOwnProperty(range.name)) {
                  rangeCollection[range.name].push(obj);
                } else {
                  rangeCollection[range.name] = [obj];
                }
              } else {
                if (!rangeCollection.hasOwnProperty(range.name)) {
                  rangeCollection[range.name] = [];
                }
              }
            });
          });
        });
      }
    });
    if (Object.keys(rangeCollection).length === 0) {
      ranges.forEach(range => {
        rangeCollection[range.name] = [];
      });
    }
    return rangeCollection;
  }

  selectQuantity = (name) => {
    this.setState({
      quantityRange: name
    });
  }

  renderRanges = () => {
    const rangeData = ranges.map((mism) => {
      return [
        <QtySelect
          name={mism.name}
          choose={this.selectQuantity}
          selected={this.state.quantityRange === mism.name}/>
      ]
    });

    return rangeData;
  }

  getMinQtyRange = (qtyRange) => {
    let min = '';
    ranges.forEach((rnge) => {
      if (rnge.name === qtyRange) {
        min = rnge.rangeA;
      }
    });
    return min;
  }

  getRangeVals = (qty) => {
    let finalRange;
    ranges.forEach((r) => {
      if (qty < 10000000) {
        if (r.rangeA <= qty && r.rangeB >= qty) {
          finalRange = [r.rangeA, r.rangeB];
        }
      } else {
        finalRange = [10000000, null];
      }
    });
    return finalRange;
  }

  openPriceAddSelect = (minRange) => {
    this.setState({
      openPriceAddSelect: true,
      minRange
    });
  }

  closePriceAddSelect = () => {
    this.setState({
      openPriceAddSelect: false
    });
  }

  chooseDB = (db) => {
    this.openPriceSpecificModal({
      qty: this.getMinQtyRange(this.state.quantityRange),
      qtyField: this.state.quantityRange,
      DB:db
    });
    this.setState({
      openPriceAddSelect: false
    });
  }

  deleteRow = (rowToDelete) => {
    this.setState({
      rowToDelete,
      ginAsk: true
    });
  }

  handleDelete = (pr) => {
    console.log(pr);
    const key = `${pr.ccode}[.]${pr.customerName}`;
    const db = pr.db;
    const index = pr.index;
    let copy = cloneDeep(this.props.customers);
    console.log('this->',copy[db][key]);
    if (copy[db][key]) {
      if (copy[db][key].length > 0) {
        copy[db][key] = copy[db][key].filter((ck,i) => {
          if (i === index) {
            return false;
          }
          return true;
        });

        let priceCount = 0;
        copy[db][key].forEach(prc => {
          console.log('prc',prc);
          if (parseInt(prc[0]) !== 0) {
            priceCount++;
          }
        });
        if (priceCount === 0) {
          console.log('the price count was 0, deleting whole key');
          delete copy[db][key];
        }
      } else {
        console.log('the length is 0, deleting whole key')
        delete copy[db][key];
      }
    }

    // check if no more prices
    this.props.updateGINEdit({
      db,
      field: 'bmasterarray',
      value: copy[db]
    });
  }

  openGINask = (request) => {
    this.setState({
      ginAsk: true
    });
  }

  closeGINask = () => {
    this.setState({
      ginAsk: false
    });
  }

  render() {
    const { classes } = this.props;
    console.log('state & props CUSTOMER-SPECIFIC', this.state, this.props);
    const rangeCollection = this.groupByQuantity();
    console.log('rangeCollection',rangeCollection);
    let dupesExist = false;
    Object.keys(this.props.customerDupes).forEach(cust => {
      if (this.props.customerDupes[cust]) {
        if (this.props.customerDupes[cust].length > 0) {
          dupesExist = true;
        }
      }
    });

    let dbsArr = [],
        currencyArr = [],
        effectiveDatesArr = [],
        customerNameArr = [],
        termsArr = [];
    if (rangeCollection[this.state.quantityRange]) {
      rangeCollection[this.state.quantityRange].forEach(pr => {
        if (dbsArr.indexOf(pr.db) === -1) {
          dbsArr.push(pr.db);
        }
        if (termsArr.indexOf(pr.term) === -1) {
          termsArr.push(pr.term);
        }
        if (currencyArr.indexOf(pr.currency) === -1) {
          currencyArr.push(pr.currency);
        }
        if (effectiveDatesArr.indexOf(`${pr.start} TO ${pr.end}`) === -1) {
          effectiveDatesArr.push(`${pr.start} TO ${pr.end}`);
        }
        if (customerNameArr.indexOf(pr.customerName) === -1) {
          customerNameArr.push(pr.customerName);
        }
      });
    }

    const table = <div className="spec-table scrolling-table"><ReactTable
        data={
            rangeCollection[this.state.quantityRange].map((pr, key) => { // map users to table rows
              const isCurrentCurr = true;
              const qty = pr.qty;
              const getRange = this.getRangeVals(qty);
              const minTotalPrice = (getRange[0] * pr.price) < pr.moc ? pr.moc : (getRange[0] * pr.price);
              const maxTotalPrice = getRange[1] ? (getRange[1] * pr.price) < pr.moc ? pr.moc : (getRange[1] * pr.price) : '';
              // const totalPriceRange = getRange[1] ? `${numberWithCommas(minTotalPrice)}-${numberWithCommas(maxTotalPrice)}` : `${numberWithCommas(minTotalPrice)}`;
              const totalPriceRange = `${numberWithCommas(minTotalPrice)}`;
              return ({
                id: key,
                db_name: pr.db,
                customerName: pr.customerName,
                dbCell: (
                    <DBlabel name={pr.db} />
                ),
                term_name: pr.term,
                termCell: (
                  <Field
                    name={pr.term}
                    isCurrentCurr={isCurrentCurr}
                    click={() => {
                      this.openPriceSpecificModal({
                        ...pr,
                        type: 'terms',
                        DB: pr.db,
                        value: pr.term,
                        qtyField: this.state.quantityRange,
                        index: pr.index
                      });
                    }} />
                ),
                price_name: `${pr.currency} ${numberWithCommas(parseFloat(pr.price))}`,
                priceCell: (
                  <Field
                    name={`${pr.currency} ${numberWithCommas(parseFloat(pr.price))}`}
                    isCurrentCurr={isCurrentCurr}
                    click={() => {
                      this.openPriceSpecificModal({
                        ...pr,
                        type: 'price',
                        DB: pr.db,
                        value: pr.price,
                        itemName: pr.itemName,
                        qtyField: this.state.quantityRange,
                        index: pr.index
                      });
                    }}
                  />
                ),
                effective_dates_name: `${pr.start} TO ${pr.end}`,
                effectiveDatesCell: (<Field
                      name={`${pr.start} TO ${pr.end}`}
                      isCurrentCurr={isCurrentCurr}
                      click={() => {
                        this.openPriceSpecificModal({
                          ...pr,
                          type: 'effective-dates',
                          DB: pr.db,
                          value: `${pr.start},${pr.end}`,
                          itemName: pr.itemName,
                          qtyField: this.state.quantityRange,
                          index: pr.index
                        });
                      }}
                    />),
                currency: pr.currency,
                currencyCell: (<Field
                      name={pr.currency}
                      isCurrentCurr={isCurrentCurr}
                      click={() => {
                        this.openPriceSpecificModal({
                          ...pr,
                          type: 'currency',
                          DB: pr.db,
                          value: pr.currency,
                          itemName: pr.itemName,
                          qtyField: this.state.quantityRange,
                          index: pr.index
                        });
                      }}
                    />),
                total_price: `${pr.currency} ${totalPriceRange}`,
                moc: pr.moc,
                mocType: pr.mocType,
                moc_name: `${pr.currency} ${numberWithCommas(parseFloat(pr.moc))} ${pr.mocType}`,
                mocCell: (
                  <Field
                     name={`${pr.currency} ${numberWithCommas(parseFloat(pr.moc))} ${pr.mocType}`}
                     isCurrentCurr={isCurrentCurr}
                     click={() => {
                       this.openPriceSpecificModal({
                         ...pr,
                         type: 'moc',
                         DB: pr.db,
                         value: pr.moc,
                         itemName: pr.itemName,
                         qtyField: this.state.quantityRange,
                         index: pr.index
                       });
                     }}
                   />
                ),
                deleteCell: (
                  <div
                    className="red-text hovered"
                    style={{paddingTop:'10px',paddingBottom:'10px'}}
                    onClick={() => {
                      if (isCurrentCurr) {
                        this.deleteRow(pr);
                      }
                    }}>
                    <Delete />
                  </div>
                ),
                itemName: pr.itemName,
                pr: pr,
              })
            })
        }
        filterable
        columns={[
            {
                Header: "DB",
                accessor: "db_name",
                filterMethod: (filter, row) => {
                    const chosen = filter.value;
                    if (chosen.length > 0) {
                        return chosen.indexOf(row[filter.id]) !== -1;
                    } else {
                        return true;
                    }
                },
                Filter: ({filter, onChange}) => {
                    return <div className="gmp-filter"><GMPMultipleSelect
                        options={dbsArr}
                        label={''}
                        choose={onChange}
                    /></div>
                },
                Cell: row => {
                  return (
                    <div>{row.original.dbCell}</div>
                  )
                },
                maxWidth: 60
            },
            {
                Header: "CUSTOMER NAME",
                accessor: "customerName",
                filterMethod: (filter, row) => {
                    const chosen = filter.value;
                    if (chosen.length > 0) {
                        return chosen.indexOf(row[filter.id]) !== -1;
                    } else {
                        return true;
                    }
                },
                Filter: ({filter, onChange}) => {
                    return <div className="gmp-filter"><GMPMultipleSelect
                        options={customerNameArr}
                        label={''}
                        choose={onChange}
                    /></div>
                },
                style: { whiteSpace: 'unset' }
            },
            {
                Header: "SHIPPING TERMS",
                accessor: "term_name",
                filterMethod: (filter, row) => {
                    const chosen = filter.value;
                    if (chosen.length > 0) {
                        return chosen.indexOf(row[filter.id]) !== -1;
                    } else {
                        return true;
                    }
                },
                Filter: ({filter, onChange}) => {
                    return <div className="gmp-filter"><GMPMultipleSelect
                        options={termsArr}
                        label={''}
                        choose={onChange}
                    /></div>
                },
                Cell: row => {
                  return (
                    <div>{row.original.termCell}</div>
                  )
                }
            },
            {
                Header: "PRICE",
                accessor: "price_name",
                Cell: row => {
                  return (
                    <div>{row.original.priceCell}</div>
                  )
                },
                sortable: true,
                filterable: false,
            },
            {
                Header: "EFFECTIVE DATES",
                accessor: "effective_dates_name",
                filterMethod: (filter, row) => {
                    const chosen = filter.value;
                    if (chosen.length > 0) {
                        return chosen.indexOf(row[filter.id]) !== -1;
                    } else {
                        return true;
                    }
                },
                Filter: ({filter, onChange}) => {
                    return <div className="gmp-filter"><GMPMultipleSelect
                        options={effectiveDatesArr}
                        label={''}
                        choose={onChange}
                    /></div>
                },
                Cell: row => {
                  return (
                    <div>{row.original.effectiveDatesCell}</div>
                  )
                },
                style: { whiteSpace: 'unset' }
            },
            {
                Header: "CURRENCY",
                accessor: "currency",
                filterMethod: (filter, row) => {
                    const chosen = filter.value;
                    if (chosen.length > 0) {
                        return chosen.indexOf(row[filter.id]) !== -1;
                    } else {
                        return true;
                    }
                },
                Filter: ({filter, onChange}) => {
                    return <div className="gmp-filter"><GMPMultipleSelect
                        options={currencyArr}
                        label={''}
                        choose={onChange}
                    /></div>
                },
                Cell: row => {
                  return (
                    <div className="text-center">{row.original.currencyCell}</div>
                  )
                },
                maxWidth: 100
            },
            {
                Header: "TOTAL PRICE",
                accessor: "total_price",
                sortable: false,
                filterable: false,
            },
            {
                Header: "MOC",
                accessor: "moc",
                Cell: row => {
                  return (
                    <div>{row.original.mocCell}</div>
                  )
                },
                sortable: false,
                filterable: false,
            },
            {
                Header: "",
                accessor: "deleteCell",
                sortable: false,
                filterable: false,
                maxWidth: 80
            },
        ]}
        minRows={0}
        defaultPageSize={1000}
        showPagination={false}
        className="-highlight"
    /></div>

    return (
      <GridContainer>
        {dupesExist &&
          <GINqtyAlertPriceCustomers
            duplicates={this.props.customerDupes}
            open={dupesExist}
          />
        }
        {
          this.state.ginAsk &&
          <GINask
            askRequest="deleteRow"
            close={this.closeGINask}
            command={() => {
              this.handleDelete(this.state.rowToDelete);
              this.closeGINask();
            }}
            content="Delete this Pricing row?"
          />
        }
        {
          this.state.openPriceAddSelect &&
            <GINaddPriceSelect
              open={this.state.openPriceAddSelect}
              close={this.closePriceAddSelect}
              choose={this.chooseDB}
              dbOptions={this.props.openedDBs}
              minRange={this.state.minRange}
            />
        }
        {
          this.state.openPriceSpecificModal && <GINaddPriceSpecificCustomer
                                                  open={this.state.openPriceSpecificModal}
                                                  close={this.closePriceSpecificModal}
                                                  editTableData={this.state.editTableData}
                                                  rangeCollection={rangeCollection}
                                                />
        }
        <GridItem xs={12} sm={12} md={1}>
          <div className="outlined-blue" style={{float:'right',maxWidth:'200px',padding:'10px 0'}}>
            <div className="tiny-table">
              <Table
                tableHeaderColor="primary"
                tableHead={["QUANTITY"]}
                tableData={this.renderRanges()}
                coloredColls={[3]}
                colorsColls={["primary"]}
              />
            </div>
          </div>
        </GridItem>
        <GridItem xs={12} sm={12} md={11}>
        <div style={{marginTop:'20px'}}>
          <div className="font-12" style={{marginTop:'30px'}}>
            <strong>DISPLAYING FOR QUANTITY: <span className="orange-text">{this.state.quantityRange}</span></strong>
          </div>
          <div className="relative">
            <div className="price-add-btn">
              <Button
                color="rose"
                size="sm"
                onClick={() => {
                  console.log('qtyField', this.state.quantityRange, this.getMinQtyRange(this.state.quantityRange));
                  this.openPriceAddSelect();
                }}
              >Add</Button>
            </div>
            {table}
          </div>
        </div>
        </GridItem>
      </GridContainer>
    )
  }
}

const mapStateToProps = (state) => {
  const dboptions = state.gin.openedDBs;
  let customerGroups = {},
      bmasterarray = state.gin.bmasterarray;

  dboptions.forEach((db) => {
    if (bmasterarray[db]) {
      Object.keys(bmasterarray[db]).forEach(customer => {
        customerGroups[customer] = {};
        if (bmasterarray[db][customer]) {
          if (bmasterarray[db][customer].constructor === Array) {
            bmasterarray[db][customer].forEach((cr,i) => {
              const qty = parseInt(cr[0]);
              const obj = {
                indx: i,
                qty,
                term: cr[1],
                price: cr[2],
                currency: cr[5],
                start: cr[6],
                end: cr[7],
                itemCode: state.gin.itemCodes[db]
              }

              if (qty) {
                const qtyField = getRangeField(qty);
                if (customerGroups[customer].hasOwnProperty(db)) {
                  if (customerGroups[customer][db].hasOwnProperty(qtyField)) {
                    customerGroups[customer][db][qtyField].push(obj);
                  } else {
                    customerGroups[customer][db][qtyField] = [obj];
                  }
                } else {
                  customerGroups[customer][db] = {
                    [qtyField]: [obj]
                  };
                }
              }
            });
          }
        }
      });
    }
  })

  let customerDupes = {};
  Object.keys(customerGroups).forEach(customer => {
    customerDupes[customer] = dupesCalc(customerGroups[customer]);
  });

  return {
    customerDupes,
    customerGroups,
    bmasterarray,
    openedDBs: state.gin.openedDBs,
    customers: state.gin.bmasterarray,
    itemCodes: state.gin.itemCodes,
    options: state.gin.options,
    mocs: state.gin.mocs
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    editModeOn: () => {
      dispatch(actions.editModeOn());
    },
    setEdit: (data) => {
      dispatch(actions.setGINEdit(data));
    },
    updateGINEdit: (data) => {
      dispatch(actions.updateGINEdit(data));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(GINpriceMatrixCustomerSpecific));
