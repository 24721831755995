import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import ReactDOM from "react-dom";
// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
import Button from "../../components/CustomButtons/Button.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyleNoOverflow.jsx";

const ADsyncModal = props => {
  const { classes } = props;
  return (
    <Dialog
        classes={{
            root: classes.center,
            paper: classes.modal
        }}
        style={{overflow:'hidden !important'}}
        open={props.open}
        keepMounted
        fullWidth={true}
        maxWidth="md"
        onClose={() => {
          props.close()
        }}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description">
        <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}>
            <span><span className="text-center" style={{fontSize:'20px',textAlign:'center'}}><strong>USER SYNC</strong></span>
            <Button
                justIcon
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
                color="transparent"
                onClick={() => {
                  props.close();
                }}
            >
                <Close className={classes.modalClose} />
            </Button></span>
        </DialogTitle>
        <div style={{padding:'20px'}}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div style={{textAlign:'center',marginTop:'20px'}}>
                <div style={{marginBottom:'30px'}}>Finished Syncing Users</div>
                <Button color="success" onClick={() => { props.close(); }}>OK</Button>
              </div>
            </GridItem>
          </GridContainer>
        </div>
    </Dialog>
  )
}

const mapStateToProps = state => {
  return {
    currentUserSync: state.userPerms.currentUserSync
  }
}

export default withStyles(modalStyle)(connect(mapStateToProps,null)(ADsyncModal));
