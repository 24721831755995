const permsObj = {
  SAP: {
    'BP': [
      { id:8, name:'Create Pending Requests', hasDBs:true, subCategory:'BP',showLabel:false},
      { id:9, name:'Approve Pending Requests & Send Direct to SAP', hasDBs:true, subCategory:'BP',showLabel:false},
      { id:11, name:'Enter Accounting Information', hasDBs:true, subCategory:'BP',showLabel:false},
      { id:1, name:'Approve', hasDBs:true, subCategory:'GMP',showLabel:true},
      { id:2, name:'Deny', hasDBs:true, subCategory:'GMP',showLabel:true},
      { id:6, name:'View History', subCategory:'BP History',showLabel:true},
      { id:19, name:'Skip Notification of Pending Submissions', subCategory:'All DBs',showLabel:true},
      { id:20, name:'TPP Magento Maintenance', subCategory:'Admin Settings', showLabel:true}
    ],
    'GIN': [
      { id:3, name:'Approve Pending Requests & Send Direct to SAP', subCategory:'GIN',showLabel:false, unCheck:15},
      { id:15, name:'Create Pending Requests', subCategory:'GIN',showLabel:false, unCheck:3},
      { id:17, name:'Skip Notification of Pending Submissions', subCategory:'GIN',showLabel:false},
      { id:16, name:'View History', subCategory:'GIN',showLabel:false},
    ],
  },
  Admin: {
    'User Admin': [
      { id:4, name:'Edit', subCategory:'User Admin',showLabel:false}
    ],
    'User Permissions': [
      { id:7, name:'Edit', subCategory:'User Permissions', showLabel:false}
    ],
    'Locked Elements': [
      { id:21, name:'Manage', subCategory:'Locked Elements', showLabel:false}
    ]
  },
  Tools: {
    'RFID': [
      //{ id:12, name:'Walmart', subCategory:'RFID', showLabel:false},
      { id:13, name:'Wal-mart USA(135)', subCategory:'RFID', showLabel:false},
      { id:12, name:'Allbirds', subCategory:'RFID', showLabel:false},
      { id:26, name:'JC Penny 1', subCategory:'RFID', showLabel:false},
      { id:27, name:'JC Penny 2 - Sub-Lot-Line-SKU', subCategory:'RFID', showLabel:false},
      { id:28, name:'JCP Importer', subCategory:'RFID', showLabel:false},
      { id:29, name:'All Birds Importer', subCategory:'RFID', showLabel:false},
      { id:32, name:'API Override', subCategory:'RFID', showLabel:false},
    ],
    'RFID history': [
      { id:14, name:'View', subCategory:'RFID history', showLabel:false}
    ],
    'SO lookup': [
      { id:18, name:'View', subCategory:'SO', showLabel:false},
      { id:22, name:'Imposition Settings', subCategory:'SO', showLabel:false}
    ],
    'Translations': [
      // { id:23, name:'View RBO Translations Lookup Tool', subCategory:'Translations', showLabel:false},
      { id:24, name:'Translations View Only', subCategory:'Translations', showLabel:false, unCheck:25},
      { id:25, name:'Translations Add/View/Update/Delete', subCategory:'Translations', showLabel:false, unCheck:24}
    ],
    'SAP Monitor': [
      { id:30, name:'View', subCategory:'SAP Monitor', showLabel:false},
    ]
  }
}

const permsDictionary = {};
Object.keys(permsObj).forEach((main) => {
  Object.keys(permsObj[main]).forEach((subgroup) => {
    permsObj[main][subgroup].forEach((perm) => {
      permsDictionary[perm.id] = {
        subgroup,
        main,
        name: perm.name,
        hasDBs: perm.hasDBs
      }
    });
  });
});

export const permsDict = permsDictionary;

export const permsList = {
  hasDBs: Object.keys(permsDictionary).filter(perm_id => permsDictionary[perm_id].hasDBs ? true : false),
  noDBs: Object.keys(permsDictionary).filter(perm_id => permsDictionary[perm_id].hasDBs ? false : true)
}

export default permsObj;
