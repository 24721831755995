import React, { Component } from 'react';
import ReactDOM from "react-dom";
import { connect } from 'react-redux';
import ReactTable from "react-table";
import * as actions from '../../store/actions';
import { formatDate, generateExcel, generateExcel2, commatize } from 'shared/utility';
import moment from 'moment';
import Datetime from 'react-datetime';
import GridContainer from "../../components/Grid/GridContainer.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
import Button from "../../components/CustomButtons/Button.jsx";
import Button2 from '@material-ui/core/Button';
import Dvr from "@material-ui/icons/Dvr";
import { FadeLoader, PulseLoader } from 'react-spinners';
import SweetAlert from "react-bootstrap-sweetalert";
import GMPMultipleSelect from 'containers/GMP/GMPMultipleSelect';
import GMPSelect from 'containers/GMP/GMPSelect';

class GMPHistoryList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: null,
      selectedBP: null,
      showModal: false,
      loadingHistory: false,
      data: props.historyData,
      startDate: null,
      endDate: null,
      page: 0,
      pageSize: 10
    }
  }

  componentDidMount() {
    console.log('History Data', this.props.historyData.length);
    // if (this.props.historyData.length === 0) {
    //   this.props.onFetchGMPHistory();
    // }
  }

  handleFetch = (id, submission_id) => {
    let s_id = 0;
    if (submission_id) {
      s_id = submission_id;
    }
    this.props.onFetchGMPHistoryItem(id, s_id);
  }

  handleDateFilterClick = () => {
    console.log('handling...');
    this.props.openFilter();
  }

  handleSharedFiltersChange = (obj_new) => {
    let obj_copy = JSON.parse(JSON.stringify(this.props.sharedFilters));
    Object.keys(obj_new).forEach((field) => {
      obj_copy[field] = obj_new[field];
    });
    this.props.updateGMPSharedFilters(obj_copy);
  }

  render() {
    const override = {
        display: 'block',
        margin: '0 auto',
        borderColor: 'red'
    };
    const loading = this.props.historyLoading;
    let layout = <div style={{textAlign:'center',margin:'0 auto'}} className="centered-loader">
    <FadeLoader
        style={override}
        sizeUnit={"px"}
        size={150}
        color={'#123abc'}
        loading={true}
    /></div>

    if (!loading && !this.state.loadingHistory) {
      let dbList = this.props.dbList,
          activityList = this.props.activityList;

      const table =
    (<div>
      <GridContainer className="padbottom10">
        <GridItem xs={12} sm={12} md={12}>
          <div className="history-download-csv-1">
            <Button
              color="warning"
              onClick={() => {
                const currentRecords = this.selectTable.getResolvedState().sortedData;
                const cols = [
          			    {wch:30}, // BP name
          			    {wch:10}, // type
          			    {wch:10}, // activity
          			    {wch:20}, // date time
          			    {wch:25}, // user
          			    {wch:10} // db
          			]
                let data = [
                  ['BP Name','Type','Activity','Date/Time','User','DB']
                ];
                currentRecords.forEach((cr) => {
                  data.push(
                    [cr.bp_name,cr.bp_type,cr.activity,moment(cr.cs1).format("MM-DD-YYYY hh:mm a"),cr.user,cr.db_name]
                  );
                });
                generateExcel2({cols,data}, 'gmp_bp_single_view.xlsx');
              }}
            >
              Download Excel
            </Button>
          </div>
        </GridItem>
      </GridContainer>
      <div className="table-adjust2 table-adjust3">
        <ReactTable
          onPageChange={page => this.setState({ page })}
          onPageSizeChange={(pageSize, page) =>
            this.setState({ page, pageSize })}
          minRows={0}
          ref={(r) => {
            this.selectTable = r;
          }}
          getTrProps={(state, rowInfo) => {
            if (rowInfo && rowInfo.row) {
              return {
                onClick: (e) => {
                  const bp = rowInfo.original;
                  console.log(bp.o_id, bp.bp_id, bp);
                  this.handleFetch(bp.o_id, bp.bp_id);
                  //if (!bp.bp_id) {
                    // open Review
                    console.log('opening new review modal');
                    this.props.openReviewModal({
                      activity: bp.activity,
                      user: bp.user,
                      date_added: bp.date_added,
                      submission_id: bp.bp_id,
                      type: bp.bp_type
                    });
                    this.props.fetchAddInfoList(bp.db_name);
                    this.setState({
                      selected: rowInfo.index
                    })
                  // } else {
                  //   // open GMP API
                  //   console.log('opening old review modal');
                  //   this.props.openHistoryModal({
                  //     activity: bp.activity,
                  //     user: bp.user,
                  //     date_added: bp.date_added
                  //   });
                  //   this.props.fetchAddInfoList(bp.db_name);
                  //   this.setState({
                  //     selected: rowInfo.index
                  //   })
                  // }
                }
              }
            } else {
              return {}
            }
          }}
          data={
              this.props.historyData.map((bp, key) => { // map users to table rows
                  return ({
                      o_id: bp.id,
                      bp_id: bp.submission_id,
                      bp_name: bp.customer_name ? bp.customer_name : bp.company_name,
                      activity: bp.activity,
                      bp_type: bp.bp_type,
                      date_added: bp.timestamp,
                      user: bp.name,
                      db_name: bp.DB
                  })
              })
          }
          filterable
          defaultSorted={[
            {
              id: "cs1",
              desc: false
            }
          ]}
          columns={[
              {
                  Header: "BP Name",
                  accessor: "bp_name",
                  filterMethod: (filter, row) => {
                    var filterValue = filter.value.toLowerCase();
                    if (filterValue.length > 0) {
                        return row[filter.id].toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;
                    } else {
                        return true;
                    }
                  }
              },
              {
                  Header: "Activity",
                  accessor: "activity",
                  filterMethod: (filter, row) => {
                      const chosen = filter.value;
                      if (chosen.length > 0) {
                          return chosen.indexOf(row[filter.id]) !== -1;
                      } else {
                          return true;
                      }
                  },
                  Filter: ({filter, onChange}) => {
                      return <div className="gmp-filter"><GMPMultipleSelect
                          options={activityList}
                          label={''}
                          choose={onChange}
                      /></div>
                  },
                  minWidth: 100
              },
              {
                  Header: "BP Type",
                  accessor: "bp_type",
                  filterMethod: (filter, row) => {
                      const chosen = filter.value;
                      if (chosen.length > 0) {
                          return chosen.indexOf(row[filter.id]) !== -1;
                      } else {
                          return true;
                      }
                  },
                  Filter: ({filter, onChange}) => {
                    console.log('filterssss', this.props.historyFiltered);
                    let defaultValues = null;
                    this.props.historyFiltered.forEach((hF) => {
                      if (hF.id === 'bp_type') {
                        defaultValues = hF.value;
                      }
                    });
                      return <div className="gmp-filter"><GMPSelect
                          options={['Customer','Supplier']}
                          label={''}
                          choose={onChange}
                          defaultValues={defaultValues}
                      /></div>
                  },
                  maxWidth: 80
              },
              {
                  id: 'cs1',
                  Header: "Date/Time",
                  accessor: "date_added",
                  Cell: cell => {
                    const d = cell.original;
                    return moment(d.date_added).format("MM/DD/YYYY hh:mm a")
                  },
                  sortMethod: (a, b) => {
                    a = new Date(a).getTime();
                    b = new Date(b).getTime();
                       return b > a ? 1 : -1;
                  },
                  filterMethod: (filter, row) => {
                      const startDate = this.state.startDate,
                            endDate = this.state.endDate;
                      if (startDate && endDate) {
                        const a = new Date(filter.value).getTime();
                        if (a > startDate && a < endDate) {
                          return true;
                        }
                      }
                  },
                  Filter: ({filter, onChange}) => {
                      return <div className="adjustDateFilterBtn">

                                <div
                                  onClick={() => {
                                  this.handleDateFilterClick();
                                }}>
                                  {
                                    (this.props.historyStartDate || this.props.historyEndDate) ?
                                    <div style={{fontSize:'11px',color:'#5e5e5e'}} className="datetime-box">
                                      <div style={{lineHeight:'1',marginBottom:'5px'}}>{this.props.historyStartDate} -</div>
                                      <div style={{lineHeight:'1',marginTop:'5px'}}>{this.props.historyEndDate}</div>
                                    </div> :
                                    <Button
                                      color="white"
                                    >Filter by Date</Button>
                                  }
                                </div>
                              </div>
                  },
                  maxWidth: 150
              },
              {
                  Header: "User",
                  accessor: "user",
                  Cell: cell => {
                    const d = cell.original;
                    if (d.user === 'GMP Magento' || d.user === 'GMP') {
                      return '';
                    }
                    return d.user;
                  },
                  filterMethod: (filter, row) => {
                    var filterValue = filter.value.toLowerCase();
                    if (filterValue.length > 0) {
                        return row[filter.id].toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;
                    } else {
                        return true;
                    }
                  }
              },
              {
                  Header: "DB",
                  accessor: "db_name",
                  filterMethod: (filter, row) => {
                      const chosen = filter.value;
                      if (chosen.length > 0) {
                          return chosen.indexOf(row[filter.id]) !== -1;
                      } else {
                          return true;
                      }
                  },
                  Filter: ({filter, onChange}) => {
                      console.log('filterssss', this.props.historyFiltered);
                      let defaultValues = null;
                      this.props.historyFiltered.forEach((hF) => {
                        if (hF.id === 'db_name') {
                          defaultValues = hF.value;
                        }
                      });
                      return <div className="gmp-filter"><GMPMultipleSelect
                          options={dbList}
                          label={''}
                          choose={onChange}
                          defaultValues={defaultValues}
                      /></div>
                  },
                  maxWidth:110
              }
          ]}
          defaultPageSize={10}
          showPaginationTop
          showPaginationBottom
          className="-striped -highlight"
          filtered={this.props.historyFiltered}
          onFilteredChange={filtered => {
              console.log('this is the filtered',filtered);
              this.props.updateGMPHistoryFilter(filtered);
            }
          }
      >
      {(state, makeTable, instance) => {
          let recordsInfoText = "";

          const { filtered, pageRows, pageSize, sortedData, page } = state;

          if (sortedData && sortedData.length > 0) {
            let isFiltered = filtered.length > 0;

            let totalRecords = sortedData.length;

            let recordsCountFrom = page * pageSize + 1;

            let recordsCountTo = recordsCountFrom + pageRows.length - 1;

            if (isFiltered)
              recordsInfoText = `Showing ${recordsCountFrom}-${recordsCountTo} of ${totalRecords.toLocaleString()} filtered activities`;
            else
              recordsInfoText = `Showing ${recordsCountFrom}-${recordsCountTo} of ${totalRecords.toLocaleString()} activities`;
          } else recordsInfoText = "No activity";

          return (
            <div className="main-grid">
              {makeTable()}
              <div style={{marginTop:'30px'}}>
                <div style={{textAlign:'center'}}>{recordsInfoText}</div>
              </div>
            </div>
          );
        }}
      </ReactTable>
  </div>
</div>);

      layout = (
        <div style={{paddingLeft:'20px',paddingRight:'20px',paddingBottom:'20px'}}>
              {!this.state.showModal && table}
        </div>);
    } else if (!loading && this.state.loadingBP) {
      layout = (
      <GridContainer justify="center" style={{
        margin: 0,
        paddingBottom: '40px',
        width: '100%',
      }}>
          <GridItem xs={12} sm={12} md={10}>
              <div>
                {this.state.loadingBP && <div style={{textAlign:'center'}}>Loading History<PulseLoader /></div>}
              </div>
          </GridItem>
      </GridContainer>);
    }
    return layout;
  }
}

const mapStateToProps = (state) => {
  return {
    historyData: state.gmp.historyList,
    historyLoading: state.gmp.historyLoading,
    historyItemData: state.gmp.historyItemData,
    historyStartDate: state.gmp.historyStartDate,
    historyEndDate: state.gmp.historyEndDate,
    historyFiltered: state.gmp.historyFiltered,
    dbList: state.gmp.historyDBList,
    activityList: state.gmp.historyActivityList
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchGMPHistory: () => dispatch(actions.fetchGMPHistory()),
    onFetchGMPHistoryItem: (id, submission_id) => dispatch(actions.fetchGMPHistoryItem(id, submission_id)),
    filterGMPHistoryDate: (startDate, endDate) => dispatch(actions.filterGMPHistoryDate(startDate, endDate)),
    fetchAddInfoList: (db) => dispatch(actions.fetchAddInfoList(db)),
    updateGMPHistoryFilter: (filtered) => dispatch(actions.updateGMPHistoryFiltered(filtered)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GMPHistoryList);
