import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import ReactDOM from "react-dom";
// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyleNoOverflow.jsx";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import reactStringReplace from 'react-string-replace';
import { ClipLoader } from 'react-spinners';

import * as actions from 'store/actions';
import BPFormReview from 'containers/BP/BPFormReview';

class BPmodal extends Component  {
    constructor(props) {
      super(props);

      this.state = {
        company_name: props.value,
        selectedItem: '',
        previewBP: false,
        exactMatch: false,
        error: '',

        selectedBPname: '',
        selectedDB: '',
        selectedBPcode: '',
        selectedBPtype: ''
      }
    }

    loadSelectedBP = () => {
      if (this.state.selectedBPname && (this.state.selectedDB && this.state.selectedBPcode)) {
        this.props.loadBPForReview(this.state.selectedBPname,
          this.state.selectedDB,
          this.state.selectedBPcode,
          this.state.selectedBPtype,
          false
        );
        this.setState({
          previewBP: false
        });
        this.props.close();
      }
    }

    handleTextInputChange = e => {
      this.setState({ company_name: e.target.value.toUpperCase() });
    }

    onSubmit = (e) => {
      e.preventDefault();
      if (this.props.BPaction === 'EXISTING' && !this.props.value) {
        return;
      }

      if (this.props.BPaction === 'NEW' && this.props.loadingAllBP) {
        return;
      }

      console.log('submitting',this.state.company_name);
      if (this.props.allBPs) {
        if (this.props.allBPs.length > 0) {
          let found = false;
          this.props.allBPs.forEach((bp) => {
            if (bp.name.toUpperCase() === this.state.company_name.toUpperCase()) {
              found = true;
            }
          });
          console.log('found', found, this.state.company_name);
          if (found) {
            this.setState({
              error: `${this.state.company_name} already exists, please enter a different one`
            });
            if (this.props.BPaction === 'NEW') {
              return;
            }
          }
        }
      }
      if (this.props.changeKey) {
        console.log('changing key to re-render');
        this.props.changeKey();
      }
      this.props.changeCompanyName(this.state.company_name);
      this.props.close();
    }

    toggleReviewModal = () => {
      this.setState({
        previewBP: !this.state.previewBP
      });
    }

    componentDidMount() {
      console.log('fetching all bps');
      //this.props.fetchAllBPs(this.props.BPtype, this.props.currentDB);
    }

    render() {
      console.log(this.props.allBPs);
      const props = this.props;
      const { classes,
        change,
        currentDB,
        currentBPType,
        countriesDictionary,
        statesDictionary,
        owhtoptsDictionary,
        gsttypesDictionary,
        loadingAllBP
      } = props;
      const name = 'company_name',
            label = 'Company Name';
      const error = this.state.error;

      let list = null,
          counted = 0;
      if (props.allBPs) {
        if (this.state.company_name.length > 1) {
           if (props.allBPs.length > 0) {
            const bpnames = props.allBPs.filter(b => b.name.toUpperCase().indexOf(this.state.company_name) !== -1);
            counted = bpnames.length;
            list = bpnames.map((bp, key) => {
              let coloredSelect = {fontSize:'12px !important'};
              if (this.state.selectedItem) {
                if (bp.name == this.state.selectedItem) {
                  coloredSelect = {backgroundColor:'gray',color:'white',fontSize:'12px !important'};
                }
              }
              const bpCode = bp.id;
              const bpName = reactStringReplace(bp.name.toUpperCase(), this.state.company_name, (match, i) => (
                                <span key={`dd-${i}`} style={{color:'#0464e1'}}>{this.state.company_name}</span>
                              ))
              const bp_type = bp.id[0] === 'S' ? 'Supplier' : 'Customer';

              return (
                  <ListItem
                    key={'key'+key}
                    button
                    onClick={() => {
                      console.log('clicked');
                      if (this.props.loadBPForReview) {
                        console.log('loading BP for review');
                        this.props.loadBPForReview(bp.name, currentDB, bpCode, bp_type, true);
                        this.toggleReviewModal();

                        if (this.props.BPaction === 'EXISTING') {
                          this.setState({
                            selectedBPname: bp.name,
                            selectedDB: currentDB,
                            selectedBPcode: bpCode,
                            selectedBPtype: bp_type
                          });
                        }
                      }
                    }}
                    style={coloredSelect}
                    >
                    <div><strong>{bpCode}</strong></div><br />
                    <div style={{marginLeft:'15px'}}>{bpName}</div>
                  </ListItem>
                )
              });
            }
          }

      return (
          <div>
              {
                (this.state.previewBP && this.props.sapBPreview) &&
                <div className="nada">
                  <BPFormReview
                    finalValues={this.props.sapBPreview}
                    beforeValues={this.props.sapBPreview}
                    open={this.state.previewBP}
                    close={this.toggleReviewModal}
                    countries={countriesDictionary}
                    states={statesDictionary}
                    gsttypes={gsttypesDictionary}
                    owhtopts={owhtoptsDictionary}
                    BPaction={this.props.BPaction}
                    currentDB={currentDB}
                    currentBPType={currentBPType}
                    enterPayTerms={this.props.enterPayTerms}
                    lockedMsg={this.props.lockedMsg}
                    selectedBPname={this.state.selectedBPname}
                    loadSelectedBP={this.loadSelectedBP}
                  />
                </div>
              }
            <div className="dialog-overflow">
              <Dialog
                  classes={{
                      root: classes.center,
                      paper: classes.modal
                  }}
                  style={{overflow:'hidden !important'}}
                  open={props.open}
                  keepMounted
                  fullWidth={true}
                  onClose={() => {
                    props.close()
                  }}
                  aria-labelledby="modal-slide-title"
                  aria-describedby="modal-slide-description">
                  <DialogTitle
                      id="classic-modal-slide-title"
                      disableTypography
                      className={classes.modalHeader}>
                      <Button
                          justIcon
                          className={classes.modalCloseButton}
                          key="close"
                          aria-label="Close"
                          color="transparent"
                          onClick={() => {
                            props.close();
                          }}
                      >
                          <Close className={classes.modalClose} />
                      </Button>
                      <h4 className={classes.modalTitle}><strong>
                        {this.props.BPaction === 'NEW' ? 'Enter New ' : ''}
                        {this.props.BPaction === 'EXISTING' ? 'Select a ' : ''}
                        {this.props.BPaction === 'PENDING' ? 'Change ' : ''}
                        Business Partner Name</strong></h4>
                  </DialogTitle>
              <form onSubmit={this.onSubmit}>
                <GridContainer style={{overflow:'hidden'}}>
                  <GridItem xs={12} sm={12} md={12}>
                      <div style={{padding:'20px 20px 10px 20px'}}>
                          <CustomInput
                            labelText={props.label}
                            id={props.name}
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              onChange:this.handleTextInputChange,
                              value: this.state.company_name,
                              autoFocus: true,
                              role: 'presentation',
                              autoComplete: 'nope',
                              ref: (thisEl) => { this.Elem = thisEl }
                            }}
                          />
                          <div style={{margin:'5px auto',fontSize:'10px'}}>{`${counted} similar BPs found`} {
                            (error && this.props.BPaction === 'NEW') && <div style={{color:'red'}}>{error}</div>
                          }</div>
                      </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                  {(this.props.BPaction === 'NEW' || this.props.BPaction === 'PENDING' || (this.props.BPaction === 'EXISTING' && this.props.value) ) &&
                    <div style={{marginTop:'0',textAlign:'right',position:'absolute',top:'141px',right:'49px'}}>
                      <Button
                        color="rose"
                        size="sm"
                        onClick={this.onSubmit}
                      >
                      {this.props.BPaction === 'NEW' ? 'Enter':''}
                      {this.props.BPaction === 'PENDING' || (this.props.BPaction === 'EXISTING' && this.props.value) ? 'Change':''}
                      </Button>
                    </div>
                  }
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    {this.props.loadingAllBP && <div style={{margin:'0 auto'}}><div style={{textAlign:'center',margin:'20px auto',color:'#3889b6'}}>
                      <ClipLoader />
                    </div></div>}
                    <div style={{height:'215px',overflowY:'scroll',overflowX:'hidden',marginBottom:'20px',marginLeft:'0',marginTop:'10px'}}>
                    {!this.props.loading  && list}
                    </div>
                  </GridItem>
                </GridContainer>
              </form>
            </Dialog>
            </div>
          </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    similars: state.gmp.existingSimilars,
    loading: state.gmp.existingSimilarsLoading,
    sapBPreview: state.common.sapBPreview,
    dbs: state.common.dbs,
    statesDictionary: state.common.statesDictionary,
    countriesDictionary: state.common.countriesDictionary,
    gsttypesDictionary: state.gmp.gsttypesDictionary,
    owhtopts: state.common.addinfo ?
                state.common.addinfo.owhtopts ? state.common.addinfo.owhtopts : null
                : null,
    owhtoptsDictionary: state.common.owhtoptsDictionary,
    allBPs: state.common.allBPs,
    loadingAllBP: state.common.loadingAllBP,
    lockedMsg: state.common.lockedMsg
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchGMPExistingSimilars: (bpname, db) => {
      //dispatch(actions.fetchGMPExistingSimilars('999999', bpname, 'nosensefiller', db));
    },
    fetchAllBPs: (bp_type, db) => {
      dispatch(actions.fetchAllBP(bp_type, db));
    },
    resetSAPBP: () => {
      dispatch(actions.resetSAPBP());
    }
  }
}


export default connect(mapStateToProps,mapDispatchToProps)(withStyles(modalStyle)(BPmodal));
