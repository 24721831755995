import React from "react";
import { connect } from 'react-redux';
import NavPills from "components/NavPills/NavPills.jsx";
import ExportUPC from "containers/RFID/ExportUPC";
import ExportEPC from "containers/RFID/ExportEPC";

class RFIDtools extends React.Component {
  constructor(props) {
    super(props);

  }

  render() {
    return (
    <div>
      <NavPills
        active={0}
        color="warning"
        tabs={[
        {
          tabButton: 'UPC to EPC',
          tabContent: <div>
            <ExportEPC
              company={this.props.company}
              overrideGS1={this.props.overrideGS1}
              shouldOverrideGS1Length={this.props.shouldOverrideGS1Length}
              setOverrideGS1={this.props.setOverrideGS1}
              setOpenOverrideBox={this.props.setOpenOverrideBox}
              forceError={this.props.forceError}
            />
          </div>
        },
        {
          tabButton: 'EPC to UPC',
          tabContent: <div>
            <ExportUPC 
              company={this.props.company}
              company_ids={this.props.company_ids}
            />
          </div>
        }
      ]}
      />
    </div>
    );
  }
}

export default connect(null, null)(RFIDtools);
