const test_data = () => {
  return [
  ["FIELD1","FIELD2","FIELD3","FIELD4","FIELD5","FIELD6","FIELD7","FIELD8","FIELD9","FIELD10","FIELD11","FIELD12","FIELD13","FIELD14","FIELD15","FIELD16","FIELD17","FIELD18","FIELD19","FIELD0","FIELD00","FIELD000","FIELD0000","FIELD00000","FIELD000000"],

  ["1006","AP6226F","Black","XS","2655630","WOMEN'S","271158","Original","","","","","","","","","","","","10","1","Ã:W_Ã0vÃ","50","271158","FN-CL-01"],

  ["1006","AP6226F","Black","S","2102009","WOMEN'S","271158","Original","","","","","","","","","","","","10","1","Ã5\"ÃÃ9EÃ","209","271158","FN-CL-01"],

  ["1006","AP6226F","Black","M","2102010","WOMEN'S","271158","Original","","","","","","","","","","","","10","1","Ã5\"!Ã0ÃÃ","1075","271158","FN-CL-01"]

  ];
}

export default test_data;

// 50,209,1075
