import { put, call } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import axios from '../axios/axios-settings';
import axios2 from 'axios';
import * as actions from '../actions/settings';
import devStatus from 'shared/devStatus';

export function* readSettingsSaga(action) {
  yield put(actions.readSettingsStart());
  try {
    const response = yield axios.get('/data/');
    yield put(actions.readSettingsSuccess(response.data));
  } catch (err) {
    yield put(actions.readSettingsFail(err));
  }
}

export function* updateSettingsSaga(action) {
  yield put(actions.updateSettingsStart());
  try {
    const response = yield axios.post('/data/', {
      data: action.data
    });
    yield put(actions.updateSettingsSuccess(response.data));
  } catch (err) {
    yield put(actions.updateSettingsFail(err));
  }
}

export function* tppMaintenanceSaga(action) {
  yield put(actions.tppMaintenanceStart());
  try {
    console.log('setting status', action.status);
    const response = yield axios.post('/tpp', {
      status: action.status
    })
    yield put(actions.tppMaintenanceSuccess(response.data));
  } catch (err) {
    yield put(actions.tppMaintenanceFail(err));
  }
}

export function* tppMaintenanceCheckSaga(action) {
  yield put(actions.tppMaintenanceCheckStart());
  try {
    console.log('getting status');
    const response = yield axios.get('/tpp');
    yield put(actions.tppMaintenanceCheckSuccess(response.data));
  } catch (err) {
    yield put(actions.tppMaintenanceCheckFail(err));
  }
}
