import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from 'store/actions';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import { Dialog, Button as Button2 } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { clone } from '../../../shared/utility';
import Button from '../../../components/CustomButtons/Button';
import AllBirdsImporterPreview from './AllBirdsImporterPreview';
import checkPermissionsActions from 'shared/checkPermissionsActions';

const AllBirdsImporter = (props) => {
    console.log('JCPImporter', props);
    const { setAllBirds, allbirds_import_data } = props;
    const [openedPreview, setOpenedPreview] = useState(false);
    const [canOverrideGS1, setCanOverrideGS1] = useState(false);
    const [error, setError] = useState(false);

    const close = () => {
        setAllBirds({});
        window.location.href = '/rfid';
        props.close();
    }

    const restart = () => {
        setAllBirds({});
        setOpenedPreview(false);
        close();
    }

    const openFile = function(event) {
        const input = event.target;
    
        const reader = new FileReader();
        reader.onload = function(){
            const text = reader.result;
            const filename = input.files[0] ? input.files[0].name.split('.')[0] : '';
            const text_arr = text.split('\r\n');
            if (text_arr.length > 0) {
                const newFiles = clone(allbirds_import_data);
                let arr = [];
                console.log('text_arr', text_arr)
                text_arr.forEach((t,i) => {
                    if (i > 0) {
                        let tarr;
                        if (t.indexOf('"') !== -1) {
                            tarr = JSON.parse(`[${t}]`);
                        } else {
                            tarr = t.split(',');
                        }
                        // const tarr = t.split('\t');
                        if (tarr.length > 0) {
                            let valid = true;
                            const tobj = {
                                filename, 
                                market: tarr[0],
                                style_category: tarr[1],
                                style_name: tarr[2],
                                sku_number: tarr[3],
                                upc: tarr[4],
                                color: tarr[5],
                                size: tarr[6],
                                order_quantity: tarr[7]
                            }
                            console.log('tobj', tobj);
                            Object.keys(tobj).forEach(f => {
                                if (tobj[f] === null || tobj[f] === undefined || tobj[f] === '') {
                                    valid = false;
                                }
                            })
                            if (valid) {
                                const upc_code = tarr[4];
                                if (newFiles.hasOwnProperty(upc_code)) {
                                    newFiles[upc_code].push(tobj);
                                } else {
                                    newFiles[upc_code] = [tobj];
                                }
                                arr.push(tobj);
                            }
                        }
                    }
                })
                setAllBirds(clone(newFiles));
                console.log('arr', arr);
                if (arr.length === 0) {
                    alert('File is not valid');
                }
                document.getElementById('file-importer').value = null;
            }

        };
        reader.readAsText(input.files[0]);
    };

    let files = [];
    Object.keys(allbirds_import_data).forEach((upc) => {
        const jup = allbirds_import_data[upc];
        if (jup) {
            jup.forEach(j => {
                if (j.filename) {
                    if (files.indexOf(j.filename) === -1) {
                        files.push(j.filename);
                    }
                }
            })
        }
    })
    console.log('theprops', props, error);

    useEffect(() => {
        console.log('checking for errors', props.allbirds_import_data);
        let hasError = false;
        if (props.allbirds_import_data) {
            if (typeof props.allbirds_import_data === 'object') {
                Object.keys(props.allbirds_import_data).forEach(upc_code => {
                    if (props.allbirds_import_data[upc_code]) {
                        if (props.allbirds_import_data[upc_code].length > 0) {
                            props.allbirds_import_data[upc_code].forEach(arr => {
                                if (arr.ending_epc) {
                                    if (arr.ending_epc === 'ERROR') {
                                        hasError = true;
                                        console.log('checking for errors - found');
                                    }
                                }
                            })
                        }
                    }
                })
            }
        }
        setError(hasError);
    }, [props.allbirds_import_data]);

    useEffect(() => {
        const actionPerms = checkPermissionsActions('RFID');
        if (actionPerms.hasOwnProperty('override')) {
            setCanOverrideGS1(true);
        }
    }, []);

    console.log('AllBirdsImporter', props);

    return (
        <Dialog
            maxWidth="md"
            fullWidth={true}
            open={props.open}
            onClose={close}
        >
            <div className="dialog-wrapper">
                <div style={{textAlign:'right'}}>
                    <Close onClick={close} className="hoverable" />
                </div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <h1>AllBirds Importer</h1>
                        {(props.overrideGS1 && !props.shouldOverrideGS1Length) && 
                        <div className="warning-text underlined"
                            onClick={() => {
                                props.setOpenOverrideBox(true);
                            }}
                        ><strong>GS1 Prefix Override Mode is ON - No Prefix Length</strong><br /></div>}
                        {(props.overrideGS1 && props.shouldOverrideGS1Length) && 
                        <div className="warning-text underlined"
                            onClick={() => {
                                props.setOpenOverrideBox(true);
                            }}
                        ><strong>GS1 Prefix Override Mode is ON - Length of GS1 Prefix is {props.shouldOverrideGS1Length}</strong><br /></div>}
                        {props.openOverrideBox && <div>
                            Override GS1
                            {props.renderOverrideBox(props.actionPerms, props.classes)}
                        </div>}
                        <hr />
                        <Button2
                            variant="contained"
                            component="label"
                            style={{marginRight:20}}
                        >
                            Upload File
                            <input type='file' hidden onChange={(e) => {
                                openFile(e);
                            }} id="file-importer" style={{marginRight:20}} />
                        </Button2>
                        {files.length !== 0 && <span>
                            <Button style={{marginRight:20}} color="primary"
                                onClick={() => {
                                    setOpenedPreview(true);
                                    props.getRfidAllBirds(allbirds_import_data, {
                                        filenames: JSON.stringify(files),
                                        final: false
                                    }, props.forceError, props.overrideGS1, props.shouldOverrideGS1Length);
                                }}
                            >PREVIEW</Button>
                        </span>}
                        <hr />
                        {files.length === 0 && <div>0 Loaded files</div>}
                        {files.length !== 0 && <div>
                            {files.map((lf,i) => {
                                return <div key={`lf-${i}`} style={{marginBottom:10}}>
                                    File # {i+1} - {lf} - <span style={{color:'red'}} className="hoverable"
                                        onClick={() => {
                                            const newObj = {};
                                            Object.keys(allbirds_import_data).forEach((upc) => {
                                                newObj[upc] = [];
                                                const jup = allbirds_import_data[upc];
                                                if (jup) {
                                                    jup.forEach(j => {
                                                        if (j.filename) {
                                                            if (j.filename !== lf) {
                                                                newObj[upc].push(clone(j));
                                                            }
                                                        }
                                                    })
                                                }
                                                if (newObj[upc].length === 0) delete newObj[upc];
                                            })
                                            setAllBirds(clone(newObj));
                                        }}
                                    >remove</span>
                                </div>
                            })}
                        </div>}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                            {openedPreview && <Dialog
                                open={openedPreview}
                                onClose={() => {
                                    setOpenedPreview(false);
                                }}
                                fullWidth={true}
                                maxWidth={false}
                                fullScreen={true}
                            >
                                <AllBirdsImporterPreview 
                                    importerData={allbirds_import_data}
                                    close={() => {
                                        setOpenedPreview(false);
                                    }}
                                    setAllBirds={setAllBirds}
                                    getRfidAllBirds={props.getRfidAllBirds}
                                    excel_id={props.excel_id}
                                    files={files}
                                    restart={restart}
                                    getAllBirdsLoading={props.getAllBirdsLoading}
                                    forceError={props.forceError}
                                    error={error}
                                    setError={setError}
                                    canOverrideGS1={canOverrideGS1}
                                    setOpenOverrideBox={props.setOpenOverrideBox}
                                />
                            </Dialog>}
                    </GridItem>
                </GridContainer>
            </div>
        </Dialog>
    )
}

const mapStateToProps = state => {
    return {
      allbirds_import_data: state.rfid.allbirds_import_data ? state.rfid.allbirds_import_data : {},
      excel_id: state.rfid.excel_id,
      getAllBirdsLoading: state.rfid.getAllBirdsLoading
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        setAllBirds: (allbirds_import_data) => {
            dispatch(actions.setRfidAllBirds(allbirds_import_data));
        },
        getRfidImporterExcel: (data) => {
            dispatch(actions.getRfidImporterExcel(data));
        }
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(AllBirdsImporter);