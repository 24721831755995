import React, { Component } from 'react';
import { connect } from 'react-redux';
import withStyles from "@material-ui/core/styles/withStyles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import compose from 'recompose/compose';

class GINSelect extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selected: props.default ? props.default : '',
            open: props.open ? true : false
        }
    }

    open = event => {
      this.setState({
        open: true
      })
    }

    close = event => {
      this.setState({
        open: false
      })
    }

    handleSingle = event => {
        this.setState({ selected: event.target.value, open: false });
        this.props.choose(event.target.value, this.props.name);
      };

    render() {
        const { classes, options, label, indx, desc } = this.props;
        let element = null;
        if (options.length > 0) {
          element = (
              <FormControl
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="single-select"
                              className={classes.selectLabel}
                            >
                              <div style={{textTransform:'none',color:'#495057 important'}}>{label}</div>
                            </InputLabel>
                  <Select
                        open={this.state.open}
                        style={this.props.customStyles}
                          value={this.state.selected}
                          onOpen={this.open}
                          onClose={this.close}
                          onChange={this.handleSingle}
                          MenuProps={{ className: classes.selectMenu }}
                          classes={{ select: classes.select }}
                          inputProps={{
                              name: "singleSelect",
                              id: "single-select"
                          }}
                      >

                              {
                                  options.map((opt, index) => {
                                    return (
                                        <MenuItem
                                            classes={{
                                                root: classes.selectMenuItem,
                                                selected: classes.selectMenuItemSelected
                                            }}
                                            key={index}
                                            value={indx ? opt[indx] : opt}
                                            style={{marginTop:'0px'}}
                                            >
                                            {desc ? opt[desc] : opt} {opt.addedText ? opt.addedText : ''} {opt.dblist ? opt.dblist : ''}
                                        </MenuItem>
                                    )
                                  })
                              }

                      </Select>
              </FormControl>
          )
        }
        return element;
    }
}

GINSelect.defaultProps = {
    customStyles: {}
}

const mapStateToProps = state => {
    return {
        selected: state.selected
    }
}

export default compose(
    withStyles(extendedFormsStyle),
    connect(mapStateToProps, null)
  )(GINSelect);
