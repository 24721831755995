import React from "react";

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Button from "components/CustomButtons/Button.jsx";
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class BPDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      numPages: null,
      pageNumber: 1,
    }
  }

  movePage = (move) => {
    this.setState((prevState) => {
      let movement = prevState.pageNumber;
      if (move === '+') {
        const plus = prevState.pageNumber + 1;
        if (this.state.numPages) {
          if (plus < this.state.numPages + 1) {
            return {
              pageNumber: plus
            }
          }
        }
      } else if (move === '-') {
        const minus = prevState.pageNumber - 1;
        if (minus > 0) {
          return {
            pageNumber: minus
          }
        }
      }
    });
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  }

  render() {
    const { pageNumber, numPages } = this.state;
    const props = this.props;
    let content = '';
    if (props.type === 'image') {
      content = <img src={props.image} style={{margin:'20px auto', width:'100%'}}/>
    } else if (props.type === 'pdf') {
      content = (
        <div>
          <p className="text-center" style={{margin:20}}>Page {pageNumber} of {numPages}</p>
          <Document
            file={this.props.pdf}
            onLoadSuccess={this.onDocumentLoadSuccess}
          >
            <Page pageNumber={pageNumber} />
          </Document>
      </div>
      )
    }
    let showButtons = false;
    if (this.state.numPages) {
      if (this.state.numPages > 1) {
        showButtons = true;
      }
    }
    return (
      <Dialog
        open={props.open}
        keepMounted
        onClose={props.close}
        fullWidth={true}
        maxWidth="md"
      >

        <DialogTitle>
          <IconButton edge="end" color="inherit" onClick={props.close} aria-label="Close" style={{float:'right'}}>
            <CloseIcon />
          </IconButton>
          <div className="text-center">
            <h4><strong>Preview</strong></h4>
          </div>
          {(props.type === 'pdf' && showButtons) && <div className="text-center">
            <Button color="white" onClick={() => { this.movePage('-') }} style={{marginRight:20}}>Previous</Button>
            <Button color="white" onClick={() => { this.movePage('+') }} >Next</Button>
          </div>}
        </DialogTitle>
        <DialogContent>
          {content}
        </DialogContent>
      </Dialog>
    )
  }
}

export default BPDialog;
