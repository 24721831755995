import React from "react";
import { connect } from 'react-redux';
import * as actions from 'store/actions';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { PulseLoader } from 'react-spinners';
import Button from "components/CustomButtons/Button.jsx";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import BPInput from "containers/BP/fields/BPInput";
import BPFormReview from 'containers/BP/BPFormReview';

class BPlist extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      previewBP: false,
      previewDB: '',
      previewCardCode: ''
    }
  }

  toggleReviewModal = () => {
    this.setState({
      previewBP: !this.state.previewBP
    });
  }

  render() {
    const props = this.props;
    const list = props.bps.map((bp,key) => {
      return <ListItem
        key={'key'+key}
        button
        onClick={() => {
          console.log('clicked', bp.BPCode);
          this.setState({
            previewBP: true,
            previewDB: bp.DBName,
            previewCardCode: bp.BPCode
          });
          this.props.loadBPForReview('', bp.DBName, bp.BPCode, 'C', 'reviewOnly');
        }}
        >
        <div><strong>{bp.BPCode}</strong></div><br />
        <div style={{marginLeft:'15px'}}>{bp.BPName}</div>
      </ListItem>
    });
    return (
    <div>
      <Dialog
        open={props.open}
        keepMounted
        onClose={props.close}
        fullWidth={true}
        maxWidth="sm"
      >

        <DialogTitle>
          <IconButton edge="end" color="inherit" onClick={props.close} aria-label="Close" style={{float:'right'}}>
            <CloseIcon />
          </IconButton>
          <div className="text-center">
            <h4><strong>BP List</strong></h4>
          </div>
        </DialogTitle>
        <DialogContent>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              {this.props.loadingBP && <div className="text-center"><PulseLoader /></div>}
              {list}
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
      {
        (this.state.previewBP && this.props.sapBPreview) &&
          <BPFormReview
            finalValues={this.props.sapBPreview}
            beforeValues={this.props.sapBPreview}
            open={this.state.previewBP}
            close={this.toggleReviewModal}
            countries={this.props.countriesDictionary}
            states={this.props.statesDictionary}
            gsttypes={this.props.gsttypesDictionary}
            owhtopts={this.props.owhtoptsDictionary}
            BPaction=""
            currentDB={this.state.previewDB}
            currentBPType="Customer"
            enterPayTerms={this.props.enterPayTerms}
            currentVendorCode={this.props.currentVendorCode}
          />
      }
    </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    vendorCodeLoading: state.gmp.vendorCodeLoading,
    vendorCodeResults: state.gmp.vendorCodeResults,
    similars: state.gmp.existingSimilars,
    loading: state.gmp.existingSimilarsLoading,
    sapBPreview: state.common.sapBPreview,
    dbs: state.common.dbs,
    statesDictionary: state.common.statesDictionary,
    countriesDictionary: state.common.countriesDictionary,
    gsttypesDictionary: state.gmp.gsttypesDictionary,
    owhtopts: state.common.addinfo ?
                state.common.addinfo.owhtopts ? state.common.addinfo.owhtopts : null
                : null,
    owhtoptsDictionary: state.common.owhtoptsDictionary,
    allBPs: state.common.allBPs,
    loadingAllBP: state.common.loadingAllBP,
    loadingBP: state.common.loadingBP
  }
}

const mapDispatchToProps = dispatch => {
  return {
    checkVendorCode: (code) => {
      dispatch(actions.checkVendorCode(code));
    },
    loadBPForReview: (bpName, db, cardcode, bp_type, reviewOnly) => {
      dispatch(actions.fetchBPSAP(db, bpName, cardcode, 'pending', bp_type, reviewOnly));
    },
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(BPlist);
