import * as actionTypes from '../actions/actionTypes';
import { updateObject, renameToTPP } from '../../shared/utility';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
const initialState = {
    gmps: [],
    rbos: [],
    rbosDict: {},
    addresses: [],
    contacts: [],
    loading: false,
    loadingReview: false,
    modal: false,
    error: null,
    activeBP: null,
    activeDB: null,
    activeBillingCountry: null,
    activeShippingCountry: null,
    activeBillingState: null,
    activeShippingState: null,
    billingAddress: null,
    shippingAddress: null,
    submitSuccessID: null,
    createdGMPBP: false,
    message: '',
    gsttypes: [],
    gsttypesDictionary: {},
    similars: null,
    historyList: [],
    historyLoading: false,
    historyItemData: null,
    historyStartDate: '',
    historyEndDate: '',
    historyOriginalList: null,
    historyFilterErrMsg: '',
    historyFiltered: [],
    historyDBList: [],
    historyActivityList: [],
    existingSimilars: null,
    existingSimilarsLoading: false,
    edi_programs: [],
    edi_programsDict: {},
    vendorCodeLoading: false,
    vendorCodeResults: null,
    vendorCodesResults: {}
}

const setLoading = (state) => {
    return updateObject(state, { loading: true });
}

const setLoadingReview = (state) => {
    return updateObject(state, { loadingReview: true });
}

const fetchGMPFail = (state, action) => {
    return updateObject(state, { miniModal: 'failAdded', modal: false, loading: false });
}

const fetchGMPSuccess = (state, action) => {
  console.log('Success...', action.gmps);
    return updateObject(state, { gmps: action.gmps, loading: false });
}

const fetchRBOSuccess = (state, action) => {
  const rbos = [], rbosDict = {};
  if (action.rbos.length > 0) {
    action.rbos.forEach(function(r) {
      if (r.FirmCode != '-1' && r.FirmCode != '1') {
        rbos.push({id:r.FirmCode, name:r.FirmName});
        rbosDict[r.FirmCode] = r.FirmName;
      }
    });
  }
  return updateObject(state, { rbos, rbosDict });
}

const fetchGMPAddressSuccess = (state, action) => {
  let activeBillingCountry = null, activeShippingCountry = null;
  if (action.addresses.length > 0) {
    action.addresses.forEach(function(a) {
      if (a.type == 'B') {
        activeBillingCountry = a.country;
      }
      if (a.type == 'S') {
        activeShippingCountry = a.country;
      }
    });
  }
  return updateObject(state, {
    addresses: action.addresses,
    activeBillingCountry,
    activeShippingCountry
  });
}

const fetchGMPContactSuccess = (state, action) => {
  return updateObject(state, { contacts: action.contacts });
}

const changeActiveDB = (state, action) => {
  return updateObject(state, { activeDB: action.activeDB });
}

const changeActiveBillingCountry = (state, action) => {
  return updateObject(state, {
    activeBillingCountry: action.country,
    activeBillingState: null
  });
}

const changeActiveShippingCountry = (state, action) => {
  return updateObject(state, {
    activeShippingCountry: action.country,
    activeShippingState: null
  });
}

const createGMPSuccess = (state, action) => {
  return updateObject(state, {
    submitSuccessID: action.id
  });
}

const notifyCreateGMPBPSuccess = (state, action) => {
  let status, msg;
  if (action.msg.hasOwnProperty('status')) {
    if (action.msg.status == 'OK') {
      status = true;
      msg = 'SUCCESSFULLY SUBMITTED';
    } else {
      status = false;
      msg = action.msg.status;
    }
  } else {
    status = false;
    msg = 'Something went wrong. Please contact Tech Support.'
  }
  return updateObject(state, {
    createdGMPBP: status,
    message: msg,
    loadingReview: true
  });
}

const notifyCreateGMPBPFail = (state, action) => {
  return updateObject(state, {
    message: 'There was a problem with your GMP BP submission. Please contact Tech Support',
    createdGMPBP: false
  });
}

const setLoadingReviewExisting = (state, action) => {
  return updateObject(state, {
    loadingReview: true
  });
}

const notifyCreateGMPBPExistingSuccess = (state, action) => {
  console.log('STATUS FOR EXISTING GMP SUBMIT', action.msg);
  let msg;
  if (action.msg.hasOwnProperty('status')) {
    if (action.msg.status == 'OK') {
      msg = 'SUCCESSFULLY SUBMITTED NEW CONTACT FOR EXISTING BP';
    } else {
      msg = 'Something went wrong. Please contact Tech Support.';
    }
  } else {
    msg = 'Something went wrong. Please contact Tech Support.';
  }
  return updateObject(state, {
    loadingReview: false,
    message: msg
  });
}

const notifyCreateGMPBPExistingFail = (state, action) => {
  console.log('ERROR CREATING CONTACT FOR GMP BP EXISTING : ', action.err)
  return updateObject(state, {
    message: 'There was a problem with your GMP BP New Contact submission. Please contact Tech Support',
  });
}

const eraseGMPAlertMsg = (state, action) => {
  return updateObject(state, {
    message: '',
    loadingReview: false
  });
}

const notifyDenyGMPBPSuccess = (state, action) => {
  return updateObject(state, {
    message: 'DENY SUBMITTED',
    loadingReview: false
  });
}

const notifyDenyGMPBPFail = (state, action) => {
  return updateObject(state, {
    message: 'DENY REQUEST DID NOT GO THROUGH',
    loadingReview: false
  });
}

const notifyDenyGMPBPExistingSuccess = (state, action) => {
  return updateObject(state, {
    message: 'NEW CONTACT DENY SUBMITTED',
    loadingReview: false
  });
}

const notifyDenyGMPBPExistingFail = (state, action) => {
  return updateObject(state, {
    message: 'DENY REQUEST DID NOT GO THROUGH',
    loadingReview: false
  });
}

const updateGSTType = (state, action) => {
  let gsttypesDictionary = {};
  let gsttypes = [];
  if (action.gsttypes) {
    if (action.gsttypes.length > 0) {
      action.gsttypes.forEach((gst) => {
        gsttypesDictionary[gst.id] = gst.type;
        gsttypes.push({
          id: gst.id.toString(),
          type: gst.type
        });
      });
    }
  }
  return updateObject(state, {
    gsttypes,
    gsttypesDictionary
  });
}

const fetchGMPSimilarsSuccess = (state, action) => {
  console.log('fetched similars success', action.data);
  return updateObject(state, {
    similars: action.data
  });
}

const fetchGMPHistoryStart = (state, action) => {
  return updateObject(state, {
    historyLoading: true
  });
}

const fetchGMPHistorySuccess = (state, action) => {
  let dbList = [],
      actionData = [],
      activityList = [];
  console.log('history data', action.data);
  action.data.forEach((act) => {
    const a = JSON.parse(JSON.stringify(act));
    a.activity = renameToTPP(a.activity);
    a.name = renameToTPP(a.name);

    if (dbList.indexOf(a.DB) === -1) {
      dbList.push(a.DB);
    }

    if (activityList.indexOf(a.activity) === -1) {
      activityList.push(a.activity);
    }

    actionData.push(a);
  });
  return updateObject(state, {
    historyOriginalList: actionData,
    historyList: actionData,
    historyStartDate: '',
    historyEndDate: '',
    historyLoading: false,
    historyDBList: dbList,
    historyActivityList: activityList
  });
}

const fetchGMPHistoryFail = (state, action) => {
  console.log(action.err);
  return updateObject(state, {
    historyList: null,
    historyLoading: false
  });
}

const fetchGMPHistoryItemStart = (state, action) => {
  console.log('gmp history item', action.data);
  // return updateObject(state, {
  //   historyItemData: null
  // });
  return state;
}

const fetchGMPHistoryItemSuccess = (state, action) => {
  console.log('gmp history item', action.data);
  return updateObject(state, {
    historyItemData: action.data,
    historyLoading: false
  });
}

const fetchGMPHistoryItemFail = (state, action) => {
  console.log(action.err);
  return updateObject(state, {
    historyItemData: null,
    historyLoading: false
  });
}

const filterDate = (state, action) => {
  let startDate = null,
      endDate = null,
      historyFilterErrMsg = '';
  if (action.startDate) {
    const startArr = action.startDate.split('/');
    //startDate = new Date(startArr[2], parseInt(startArr[0])-1, startArr[1], 0);
    startDate = moment(action.startDate, 'MM-DD-YYYY hh:mm a').valueOf();
  }
  if (action.endDate) {
    const endArr = action.endDate.split('/');
    //endDate = new Date(endArr[2], parseInt(endArr[0])-1, endArr[1], 24);
    endDate = moment(action.endDate, 'MM-DD-YYYY hh:mm a').valueOf();
  }

  if (startDate && endDate) {
    if (startDate > endDate || startDate === endDate) {
      return updateObject(state, {
        historyFilterErrMsg: 'Start Date should not be after End Date'
      });
    }
  }

  let newHistoryList = state.historyOriginalList.filter((obj) => {
    const d = new Date(obj.timestamp).getTime();
    if (startDate && !endDate) {
      return d > startDate;
    } else if (!startDate && endDate) {
      return d < endDate;
    } else if (startDate && endDate) {
      return d > startDate && d < endDate;
    } else {
      return true;
    }
  });

  return updateObject(state, {
    historyList: newHistoryList,
    historyStartDate: action.startDate,
    historyEndDate: action.endDate
  });
}

const updateHistoryFilter = (state, action) => {
  return updateObject(state, {
    historyFiltered: action.historyFiltered,
  });
}

const setLoadingGMPSimilars = (state, action) => {
  return updateObject(state, {
    existingSimilarsLoading: true
  });
}

const updateGMPExistingSimilars = (state, action) => {
  return updateObject(state, {
    existingSimilarsLoading: false,
    existingSimilars: action.data
  });
}

const updateGMPExistingSimilarsFail = (state, action) => {
  console.log(action.err);
  return state;
}

const clearSimilars = (state, action) => {
  return updateObject(state, {
    similars: null
  });
}

const fetchEDIProgramsSuccess = (state, action) => {
  let edi_programsDict = {};
  action.edi_programs.forEach(edi => {
    edi_programsDict[edi.ID] = edi.Name;
  });
  return updateObject(state, {
    edi_programs: action.edi_programs,
    edi_programsDict
  });
}

const fetchEDIProgramsFail = (state, action) => {
  return state;
}

const checkVendorCodeStart = (state, action) => {
  return updateObject(state, {
    vendorCodeLoading: true,
    vendorCodeResults: null,
  });
}

const checkVendorCodeSuccess = (state, action) => {
  return updateObject(state, {
    vendorCodeLoading: false,
    vendorCodeResults: action.response
  });
}

const checkVendorCodesSuccess = (state, action) => {
  let copy = cloneDeep(state.vendorCodesResults);
  copy[`${action.edi}_${action.data.vendor_code}`] = action.data;
  return updateObject(state, {
    vendorCodeLoading: action.last ? false : true,
    vendorCodesResults: copy
  });
}

const checkVendorCodesFail = (state, action) => {
  return updateObject(state, {
    vendorCodeLoading: false,
    vendorCodeResults: null
  });
}

const setLoadingGMPBP = (state, action) => {
  return updateObject(state, {
    loading: false
  });
}

const resetGMPBP = (state, action) => {
  return updateObject(state, {
    existingSimilars: null,
    existingSimilarsLoading: false,
    edi_programs: [],
    edi_programsDict: {},
    vendorCodeLoading: false,
    vendorCodeResults: null,
    vendorCodesResults: {}
  });
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_GMP_START:
            return setLoading(state, action);
        case actionTypes.FETCH_GMP_SUCCESS:
            return fetchGMPSuccess(state, action);
        case actionTypes.FETCH_GMP_FAIL:
            return fetchGMPFail(state, action);

        case actionTypes.FETCH_GMP_RBO_SUCCESS:
            return fetchRBOSuccess(state, action);

        case actionTypes.FETCH_GMP_ADDRESS_SUCCESS:
            return fetchGMPAddressSuccess(state, action);

        case actionTypes.FETCH_GMP_CONTACT_SUCCESS:
            return fetchGMPContactSuccess(state, action);

        case actionTypes.CREATE_GMP_SUCCESS:
            return createGMPSuccess(state, action);
        case actionTypes.CREATE_GMP_BP_START:
            return setLoadingReview(state, action);
        case actionTypes.CREATE_GMP_BP_SUCCESS:
            return notifyCreateGMPBPSuccess(state, action);
        case actionTypes.CREATE_GMP_BP_FAIL:
            return notifyCreateGMPBPFail(state, action);

        case actionTypes.CREATE_GMP_BP_EXISTING_START:
            return setLoadingReviewExisting(state, action);
        case actionTypes.CREATE_GMP_BP_EXISTING_SUCCESS:
            return notifyCreateGMPBPExistingSuccess(state, action);
        case actionTypes.CREATE_GMP_BP_EXISTING_FAIL:
            return notifyCreateGMPBPExistingFail(state, action);

        case actionTypes.DENY_GMP_BP_START:
            return setLoadingReview(state, action);
        case actionTypes.DENY_GMP_BP_SUCCESS:
            return notifyDenyGMPBPSuccess(state, action);
        case actionTypes.DENY_GMP_BP_FAIL:
            return notifyDenyGMPBPFail(state, action);

        case actionTypes.DENY_GMP_BP_EXISTING_START:
            return setLoadingReview(state, action);
        case actionTypes.DENY_GMP_BP_EXISTING_SUCCESS:
            return notifyDenyGMPBPExistingSuccess(state, action);
        case actionTypes.DENY_GMP_BP_EXISTING_FAIL:
            return notifyDenyGMPBPExistingFail(state, action);

        case actionTypes.CHANGE_ACTIVE_DB:
            return changeActiveDB(state, action);
        case actionTypes.CHANGE_ACTIVE_BILLING_COUNTRY:
            return changeActiveBillingCountry(state, action);
        case actionTypes.CHANGE_ACTIVE_SHIPPING_COUNTRY:
            return changeActiveShippingCountry(state, action);

        case actionTypes.ERASE_GMP_ALERT_MSG:
            return eraseGMPAlertMsg(state, action);
        case actionTypes.FETCH_GSTTYPE_SUCCESS:
            return updateGSTType(state, action);

        case actionTypes.FETCH_GMP_SIMILARS_SUCCESS:
            return fetchGMPSimilarsSuccess(state, action);

        case actionTypes.FETCH_GMP_HISTORY_START:
            return fetchGMPHistoryStart(state, action);
        case actionTypes.FETCH_GMP_HISTORY_SUCCESS:
            return fetchGMPHistorySuccess(state, action);
        case actionTypes.FETCH_GMP_HISTORY_FAIL:
            return fetchGMPHistoryFail(state, action);
        case actionTypes.FETCH_GMP_HISTORY_ITEM_START:
            return fetchGMPHistoryItemStart(state, action);
        case actionTypes.FETCH_GMP_HISTORY_ITEM_SUCCESS:
            return fetchGMPHistoryItemSuccess(state, action);
        case actionTypes.FETCH_GMP_HISTORY_ITEM_FAIL:
            return fetchGMPHistoryItemFail(state, action);
        case actionTypes.FILTER_GMP_HISTORY_DATE:
            return filterDate(state, action);
        case actionTypes.UPDATE_GMP_HISTORY_FILTERED:
            return updateHistoryFilter(state, action);
        case actionTypes.FETCH_GMP_EXISTING_SIMILARS_START:
            return setLoadingGMPSimilars(state, action);
        case actionTypes.FETCH_GMP_EXISTING_SIMILARS_FAIL:
            return updateGMPExistingSimilarsFail(state, action);
        case actionTypes.FETCH_GMP_EXISTING_SIMILARS_SUCCESS:
            return updateGMPExistingSimilars(state, action);
        case actionTypes.CLEAR_SIMILARS:
            return clearSimilars(state, action);
        case actionTypes.FETCH_EDI_PROGRAMS_SUCCESS:
            return fetchEDIProgramsSuccess(state, action);
        case actionTypes.FETCH_EDI_PROGRAMS_FAIL:
            return fetchEDIProgramsFail(state, action);
        case actionTypes.CHECK_VENDOR_CODE_START:
            return checkVendorCodeStart(state, action);
        case actionTypes.CHECK_VENDOR_CODE_SUCCESS:
            return checkVendorCodeSuccess(state, action);
        case actionTypes.CHECK_VENDOR_CODES_SUCCESS:
            return checkVendorCodesSuccess(state, action);
        case actionTypes.CHECK_VENDOR_CODES_FAIL:
            return checkVendorCodesFail(state, action);
        case actionTypes.SET_LOADING_GMPBP:
            return setLoadingGMPBP(state, action);
        case actionTypes.RESET_GMP_BP:
            return resetGMPBP(state, action);
        default: return state;

    }
};

export default reducer;
