import { put } from 'redux-saga/effects';
import axios from '../axios/axios-common';
import axios_gmp from '../axios/axios-gmp';
import * as actions from '../actions/common';

export function* fetchAddInfoSaga(action) { // saga for getting data from GMP endpoint
    console.log('trying to fetch addinfo for', action.db);
    try {
        const response = yield axios.get('/addinfo/'+action.db);
        const addinfo = response.data.body;
        yield put(actions.fetchAddInfoListSuccess(addinfo, action.db));
    }
    catch (err) {
        console.log(err);
        yield put(actions.fetchAddInfoListFail(err));
    }
}

export function* fetchDBListSaga(action) {
  try {
      const response = yield axios.get('/sap_dbs/');
      yield put(actions.fetchDBListSuccess(response.data));
  }
  catch (err) {
      console.log(err);
      yield put(actions.fetchDBListFail(err));
  }
}

export function* fetchBPSAPSaga(action) {
  console.log('fetchbpsap', action);
  yield put(actions.fetchBPSAPStart());
  let lockedMsg = '';
  // check if BP is locked
  try {
    const response = yield axios.get(`/lock_bp/?db=${action.db}&card_code=${action.cardcode}&bpname=${action.name}`);
    const dat = response.data;
    console.log('checking lock status of bp', dat);
    if (dat) {
      if (dat.status) {
        if (dat.status === 'LOCKED') {
          lockedMsg = `${action.db} ${action.name} is Locked ${dat.name ? 'by '+dat.name : ''}`;
        }
      }
    }
  } catch(e) {
    console.log('error checking if BP is locked', e);
  }
  try {
    console.log('fetching BP from SAP');
    const response = yield axios.post('/bp', {
      db:action.db,
      bpname:action.name,
      cardcode:action.cardcode ? action.cardcode : '',
      bp_type:action.bp_type ? action.bp_type : 'Customer',
      origin_check: action.origin_check ? action.origin_check : ''});
    console.log('response from SAP',response.data);
    const sapBP = response.data;
    yield put(actions.fetchBPSAPSuccess(sapBP, action.reviewOnly, lockedMsg));
    if (!action.reviewOnly) {
      // lock BP
      yield axios.post('/lock_bp', {
        db: action.db,
        card_code: action.cardcode,
        bpname: action.name,
        action: 'LOCK'
      })
    }
  }
  catch (err) {
    console.log(err.response);
    yield put(actions.fetchBPSAPFail(err.response));
  }
}

export function* fetchAllBPSaga(action) {
  yield put(actions.fetchAllBPStart());
  try {
    console.log('fetching All bps from sap', action.bp_type, action.db);
    const response = yield axios.get(`/get_all_bp/${action.bp_type}/${action.db}`);
    const sapBPs = response.data;
    yield put(actions.fetchAllBPSuccess(sapBPs));
  }
  catch (err) {
    console.log(err);
    yield put(actions.fetchAllBPFail(err));
  }
}

export function* saveLocalBPSaga(action) {
  yield put(actions.saveLocalBPStart());
  try {
    if (action.file && action.filename) {
      const data = new FormData();
      data.append('file', action.file, action.filename);
      const response1 = yield axios.post(`/send_resale_certificate`, data);
      const res = response1.data;
      console.log('checking resale cert sending',res);
      if (res.status !== 'OK') {
        yield put(actions.saveLocalBPFail({status:'FAIL'}));
      }
    }
    const response = yield axios.post(`/save_local_bp/`, {data: action.data});
    const res = response.data;
    yield put(actions.saveLocalBPSuccess(res));
    // Unlock BP
    const all_data = action.data;
    const response4 = yield axios.post('/lock_bp', {
      db: all_data.DB,
      card_code: all_data.card_code,
      bpname: all_data.company_name,
      action: 'UNLOCK'
    });
    console.log('res 4', response4.data);
  }
  catch (err) {
    console.log(err);
    yield put(actions.saveLocalBPFail(err));
  }
}

export function* fetchLocalBPSaga(action) {
  console.log('fetching local BP', action);
  yield put(actions.fetchLocalBPStart());
  const origin = action.origin;
  try {
    const response = yield axios.get(`/fetch_local_bp/${action.submission_id}`);
    const res = response.data;
    console.log('fetched local bp',res);
    // check if local BP is locked
    if (res.data) {
      if (res.data.DB) {
        let bpname = '';
        if (res.data.company_name) {
          bpname = res.data.company_name;
        } else if (res.data.customer_name) {
          bpname = res.data.customer_name;
        }
        if (bpname) {
          const lock_response = yield axios.get(`/lock_bp?db=${res.data.DB}&bpname=${bpname}`);
          console.log('checking lock response', lock_response);
          if (lock_response) {
            if (lock_response.data) {
              const lrd = lock_response.data;
              if (lrd.status === 'LOCKED' && origin != 'previewOnly') {
                return yield put(actions.fetchLocalBPFail(`${lrd.db} ${lrd.bpname} is locked by ${lrd.name}`));
              }
            }
          }
        }
      }
    }
    const all_data = res.data;
    console.log('all_data', all_data);
    if (all_data && origin != 'previewOnly') {
      const lockData = {
        db: all_data.DB,
        card_code: all_data.card_code,
        bpname: all_data.company_name ? all_data.company_name : all_data.customer_name,
        action: 'LOCK'
      };
      const response2 = yield axios.post('/lock_bp', lockData);
      console.log('fetch local response 2 lock', response2.data);
    }
    console.log('fetch local success');
    yield put(actions.fetchLocalBPSuccess(res, origin));
  }
  catch (err) {
    console.log(err);
    yield put(actions.fetchLocalBPFail(err));
  }
}

export function* saveSAPBPSaga(action) {
  console.log('STEP 1 - final savesap data', action.data);
  yield put(actions.saveSAPBPStart());
  try {

    // check if any TPP shipping addressnames have been deleted
    const bp = action.data;
    if (bp.allDeletedMagentoAddressIDs) {
      if (bp.allDeletedMagentoAddressIDs.length > 0) {
        for (let i = 0; i < bp.allDeletedMagentoAddressIDs.length; i++) {
          const for_deletion = bp.allDeletedMagentoAddressIDs[i];
          if (for_deletion) {
            const magento_deletion = yield axios_gmp.post(`/magento_address`, { id: for_deletion });
            console.log('magento deletion 1', magento_deletion.data);
            if (magento_deletion.data) {
              if (magento_deletion.data.msg) {
                if (magento_deletion.data.msg === 'FAIL') {
                  yield put(actions.saveSAPBPFail(`Error deleting magento address ids`));
                  return;
                }
              }
            }
          }
        }
      }
    }
    if (bp.allDeletedMagentoAddressNames) {
      if (bp.allDeletedMagentoAddressNames.length > 0) {
        for (let i = 0; i < bp.allDeletedMagentoAddressNames.length; i++) {
          const del = bp.allDeletedMagentoAddressNames[i];
          if (del.cardcode && (del.addressname && del.DB)) {
            const magento_deletion = yield axios_gmp.post(`/magento_address`, {
              cardcode: del.cardcode,
              addressname: del.addressname,
              DB: del.DB
            });
            console.log('magento deletion 2', magento_deletion.data);
            if (magento_deletion.data) {
              if (magento_deletion.data.msg) {
                if (magento_deletion.data.msg === 'FAIL') {
                  yield put(actions.saveSAPBPFail(`Error deleting magento address names`));
                  return;
                }
              }
            }
          }
        }
      }
    }
    if (action.file && action.filename) {
      console.log('STEP 2 - sending resale cert file');
      const data = new FormData();
      data.append('file', action.file, action.filename);
      const response1 = yield axios.post(`/send_resale_certificate`, data);
      const file_response = response1.data;
      console.log('response to resale cert send',file_response);
    }
    const unique_history_id = (new Date().getTime()).toString(36);
    action.data.unique_history_id = unique_history_id;

    // Send Validation Data
    const all_data = action.data;
    const response3 = yield axios_gmp.post('/enter_validation', {
      db: all_data.DB,
      cardcode: all_data.card_code,
      bp_name: all_data.company_name,
      json_data: JSON.stringify(all_data),
      isTPP: action.isTPP,
      unique_history_id
    })
    const res2 = response3.data;
    console.log('response from creating validation entry (for later validation)', res2);

    // submit to SAP
    console.log('STEP 3 - sending SAP bp data');
    const response = yield axios.post(`/save_sap_bp`, action.data);
    const res = response.data;
    console.log('response from saving SAP BP data',res);
    // send Magento delete address id if needed
    yield put(actions.saveSAPBPSuccess(res));
    // Lock BP as pending (will unlock only after validation)
    const response4Data = {
      db: all_data.DB,
      card_code: all_data.card_code,
      bpname: all_data.company_name,
      action: 'LOCK_PENDING'
    };
    console.log('res 4 unlocking',response4Data);
    const response4 = yield axios.post('/lock_bp', response4Data);
    console.log('res 4', response4.data);
  }
  catch (err) {
    console.log('sap BP fail', err);
    yield put(actions.saveSAPBPFail(err));
  }
}

export function* checkPendingBPSaga(action) {
  try {
    const response = yield axios.post(`/check_pending_bp`, {
      bpname: action.bpname,
      bp_type: action.bp_type,
      db: action.db
    });
    const res = response.data;
    console.log('checking pending bp',res);
    yield put(actions.checkPendingBPSuccess(res));
  }
  catch (err) {
    console.log(err);
    yield put(actions.checkPendingBPFail(err));
  }
}

export function* sendResaleCertFileSaga(action) {
  try {
    const data = new FormData();
    data.append('file', action.file, action.filename);
    const response = yield axios.post(`/send_resale_certificate`, data);
    const res = response.data;
    console.log('checking resale cert sending',res);
    yield put(actions.sendResaleCertFileSuccess(res));
  }
  catch (err) {
    console.log(err);
    yield put(actions.sendResaleCertFileFail(err));
  }
}

export function* sendResaleCertFileSAPSaga(action) {
  try {
    const data = new FormData();
    data.append('file', action.file, action.filename);
    const response = yield axios.post(`/send_resale_certificate_sap`, data);
    const res = response.data;
    console.log('checking resale cert sending SAP',res);
    yield put(actions.sendResaleCertFileSAPSuccess(res));
  }
  catch (err) {
    console.log(err);
    yield put(actions.sendResaleCertFileSAPFail(err));
  }
}

export function* getBPLocksSaga(action) {
  console.log('getting BP locks');
  yield put(actions.getBPLocksStart());
  try {
    const response = yield axios.get(`/get_locked_bps`);
    const res = response.data;
    yield put(actions.getBPLocksSuccess(res));
  } catch(err) {
    yield put(actions.getBPLocksFail(err));
  }
}

export function* checkBPLockSaga(action) {
  yield put(actions.checkBPLockStart());
  try {
    const response = yield axios.get(`/lock_bp?db=${action.db}&card_code=${action.card_code}`);
    const res = response.data;
    if (res) {
      yield put(actions.checkBPLockSuccess(res));
    }
  } catch(err) {
    yield put(actions.checkBPLockFail(err));
  }
}

export function* updateBPLockSaga(action) {
  yield put(actions.updateBPLockStart());
  try {
    const response = yield axios.post(`/lock_bp`, {
      db: action.db,
      card_code: action.card_code,
      bpname: action.bpname,
      action: action.action
    });
    const res = response.data;
    if (res) {
      yield put(actions.updateBPLockSuccess(res));
    }
  } catch(err) {
    yield put(actions.updateBPLockFail(err));
  }
}

export function* releaseUserLockedBPsSaga(action) {
  try {
    const response = yield axios.post(`/lock_bp`, {
      db: 'N/A',
      card_code: 'N/A',
      bpname: 'N/A',
      action: 'RELEASE_USER'
    });
    const res = response.data;
    console.log('result of releasing user locks', res);
  } catch(err) {
    console.log('result of releasing user locks err',err);
  }
}

export function* choosePrimarySaga(action) {
  yield put(actions.choosePrimaryStart());
  try {
    const response = yield axios.post(`/choose_primary`, action.data);
    const res = response.data;
    console.log('response from choosing primary', res);
    if (res.id) {
      return yield put(actions.choosePrimarySuccess(res));
    }
    yield put(actions.choosePrimaryFail('Error getting submission ID'));
  } catch(err) {
    yield put(actions.choosePrimaryFail(err));
  }
}
