import React, { Component } from 'react';
import { connect } from 'react-redux';
import withStyles from "@material-ui/core/styles/withStyles";
import { Card, CardContent } from '@material-ui/core';
import { roseColor } from '../../assets/jss/material-dashboard-pro-react';
import { checkVariantExistence, checkCode } from './GINhelpers';
import _ from 'lodash';

const fields = [
    {
      key: 'base_item_description',
      name: 'BASE ITEM DESCRIPTION',
    },
    {
      key: 'product_type',
      name: 'PRODUCT TYPE',
    },
    {
      key: 'rbo',
      name: 'RBO NAME',
    },
    {
      key: 'brand',
      name: 'BRAND',
    },
    {
      key: 'subgroup',
      name: 'SUBGROUP',
    },
    {
      key: 'ipackagingtypes',
      name: 'PACKAGING TYPE'
    },
    {
      key: 'sales_unit',
      name: 'SALES UNIT',
    },
    {
      key: 'basic_packaging_unit',
      name: 'BASIC PACKAGING UNIT',
    },
    {
      key: 'qty_per_unit',
      name: 'QTY PER UNIT',
    },
    {
      key: 'ilength',
      name: 'LENGTH',
    },
    {
      key: 'width',
      name: 'WIDTH',
    },
    {
      key: 'weight',
      name: 'WEIGHT',
    },

];

class GINrightmostSidebar extends Component {
  renderVariants(){
    const {classes} = this.props;

    if(!this.props.variantStatus) {
      return <a href="#" onClick={e => {e.preventDefault(); this.props.onChangeTab('variants')}} className={classes.invalid}>Incomplete</a>;
    }

    return (
      this.props.variants.map((v, i) => (
        <p
          key={i}
          className={`${classes.regular} hovered`}
          style={{fontsize:'10px'}}
          onClick={e => {e.preventDefault(); this.props.onChangeTab('variants')}}>
            <strong>Code:</strong> {v.code}<br />
            <strong>Description:</strong> {v.desc}
        </p>
        )
      )
    )

  }
    render() {
        const {classes} = this.props;
        console.log('GINrightmostSidebar', this.props);

        let hasWalmart = false;
        if (this.props.picked && this.props.data) {
          if (this.props.picked.rbo && this.props.data.rbo) {
            const picked_rbo_db = this.props.picked.rbo;
            console.log('c - 1');
            let rboo = this.props.data.rbo[picked_rbo_db];
            if (rboo !== 'n/a') {
              rboo = parseInt(rboo);
              if (this.props.rboDict) {
                if (this.props.rboDict[picked_rbo_db]) {
                  const rboDict = this.props.rboDict[picked_rbo_db];
                  if (rboDict[rboo]) {
                    if (rboDict[rboo].indexOf('WALMART') !== -1) {
                      hasWalmart = true;
                    }
                  }
                }
              }
            }
          }
        }

        return (
            <div style={{marginTop:'20px'}}>
            <Card className={classes.root}>
                <CardContent style={{padding: '0 15px'}}>
                    <h4 className={classes.title}>Item description</h4>

                    {
                        fields.map((v, i) => {
                            if (v.key === 'ipackagingtypes' && !hasWalmart) {
                              return null;
                            }
                            let addedText = '';
                            if (v.key === 'ilength' || v.key === 'width') {
                              addedText = ' mm';
                            } else if (v.key === 'weight') {
                              addedText = ' g';
                            }

                            let value = this.props.data[v.key][this.props.picked[v.key]];

                            if (v.key === 'rbo' && value && value !== 'n/a') {
                              value = this.props.rboDict[this.props.picked[v.key]][value];
                            } else if (v.key === 'brand' && value !== 'n/a') {
                              value = this.props.brandsDict[value];
                            } else if (v.key === 'subgroup' && value !== 'n/a') {
                              value = this.props.subgroupsDict[value];
                            } else if (v.key === 'ipackagingtypes' && value !== 'n/a') {
                              value = this.props.packagingtypesDict[value];
                            }

                            return (
                                <div key={i} className={classes.paragraph}>
                                    <h5>{v.name}:</h5>
                                    {
                                        !!value
                                        ? <a href="#"
                                            style={{fontsize:'10px'}}
                                            onClick={e => {e.preventDefault(); this.props.onChangeTab(v.key)}} className={classes.regular}>{value}{addedText}</a>
                                        : <a href="#"
                                            style={{fontsize:'10px'}}
                                            onClick={e => {e.preventDefault(); this.props.onChangeTab(v.key)}} className={classes.invalid}>Incomplete</a>
                                    }
                                </div>
                            )
                        })
                    }
                    <div className={classes.paragraph}>
                        <h5>Variants:</h5>
                        {
                          this.renderVariants()
                        }
                    </div>
                </CardContent>
            </Card>
            </div>
        )
    }
}

GINrightmostSidebar.defaultProps = {
    variants: [],
    onChangeTab: () => {},
    picked: {}
}

_.forEach(fields, (v) => {
    Object.defineProperty(GINrightmostSidebar.defaultProps, v.key, {
        writable: true,
        enumerable: true,
        value: false
    })
})

const styles = theme => ({
    root: {
      border: 'none',
      boxShadow: 'none',
      border: '2px solid',
      borderColor: roseColor
    },
    title: {
        textTransform: 'uppercase',
        fontSize: '13px',
        textAlign: 'center',
        fontWeight: 600,
        color: '#3e4756'
    },
    paragraph: {
        marginBottom: '1rem',
        fontSize: '11px',

        '& h5': {
            color: roseColor,
            fontSize: '12px',
            margin: 0,
            textTransform: 'uppercase',
            fontWeight: 600
        }
    },
    regular: {
        fontSize: '11px',
        color: '#3e4756',
        fontWeight: 500,

        '&:active, &:hover, &:focus': {
            color: '#3e4756',
            textDecoration: 'none'
        }
    },
    invalid: {
        color: 'red',
        textDecoration: 'underline',
        fontWeight: 500,

        '&:active, &:hover, &:focus': {
            color: 'red',
            textDecoration: 'underline'
        }
    }
})

const mapStateToProps = state => {
  const brands = state.gin.brands,
        subgroups = state.gin.subgroups,
        packagingtypes = state.gin.packagingtypes,
        options = state.gin.options;
  let brandsDict = {},
      subgroupsDict = {},
      packagingtypesDict = {},
      rboDict = {};
  brands.forEach(b => {
    brandsDict[b.id.toString()] = b.name;
  });
  subgroups.forEach(s => {
    subgroupsDict[s.id.toString()] = s.name;
  });
  packagingtypes.forEach(s => {
    packagingtypesDict[s.id.toString()] = s.name;
  });
  Object.keys(options).forEach(db => {
    rboDict[db] = {};
    const opts = options[db].rbos;
    opts.forEach(o => {
      rboDict[db][o.FirmCode.toString()] = o.FirmName;
    });
  });

  let variantStatus = false;
  let variants = [];
  if (checkCode(state.gin.variantsChecked)) {
    variantStatus = true;
    variants = Object.keys(state.gin.variantsChecked).map(vc => {
      return {
        code: vc.split('_')[0],
        desc: vc.split('_')[1]
      }
    });
  }

  if (!checkVariantExistence(state.gin.variants)) {
    variantStatus = true;
  }

  return {
    brandsDict,
    subgroupsDict,
    packagingtypesDict,
    rboDict,
    variants: variants,
    variantsChecked: state.gin.variantsChecked,
    variantStatus,
    data: _.pick(state.gin, _.map(fields, v => v.key)),
    picked: state.gin.picked
  }
}

export default connect(mapStateToProps, null)(withStyles(styles, { withTheme: true })(GINrightmostSidebar));
