import React, { useState, useEffect } from 'react';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import { Dialog } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { formatDate2, formatDate3 } from '../../../shared/utility';
import { connect } from 'react-redux';
import * as actions from 'store/actions';
import Button from '../../../components/CustomButtons/Button';
import ReactTable from 'react-table';
import { Tooltip } from '@material-ui/core';
import AllBirdsImporterPreview from './AllBirdsImporterPreview';

const AllBirdsImporterHistory = (props) => {
    const [openedPreview, setOpenedPreview] = useState(false);
    const [allbirds_import_data, setAllBirdsImportData] = useState({});
    const [wait, setWait] = useState(false);

    console.log('AllBirdsImporterHistory', props, allbirds_import_data);
    
    useEffect(() => {
        props.getRfidImporterHistory({
            type: 'allbirds'
        });
    }, []);

    useEffect(() => {
        if (wait) {
            if (props.allbirds_import_data) {
                setAllBirdsImportData(props.allbirds_import_data)
                setWait(false);
            }
        }
    }, [props.allbirds_import_data]);

    const close = () => {
        props.close();
    }

    const data = props.rfid_importer_data ? props.rfid_importer_data.map((l, key) => { // map users to table rows
        const created_date = formatDate2(l.date_created);
        const created_date_timestamp = formatDate3(l.date_created);
        return ({
            id: l.id,
            name: l.name,
            created_date,
            created_date_timestamp,
            filenames: l.filenames ? 
                typeof l.filenames === 'string' ?
                    JSON.parse(l.filenames).join(', ')
                :
                    l.filenames.join(', ') 
            : 
                '',
            actions: <div>
                        {
                            <Tooltip
                                title="View Entry"
                                placement="top"
                            >
                            <Button
                                justIcon
                                round
                                simple
                                onClick={() => {
                                    setOpenedPreview(true);
                                    setWait(true);
                                    props.getRfidImporterHistory(l.id);
                                }}
                                color="primary"
                                className="mr-20"
                            >
                                <i className="fas fa-eye font-22"></i>
                            </Button>
                          </Tooltip>
                        }
                        </div>
        })
    }) : [];

    const columns = [
        {
            Header: "#",
            accessor: "id",
            maxWidth: 200
        },
        {
            Header: 'Date',
            accessor: 'created_date_timestamp',
            disableFilters: true,
            Cell: row => {
                console.log('roww', row)
                return <div style={{ textAlign: "left" }}>{row.original.created_date}</div>
            },
        },
        {
            Header: "User",
            accessor: "name",
            maxWidth: 200
        },
        {
            Header: "Filenames",
            accessor: "filenames"
        },
        {
            Header: "Actions",
            accessor: "actions",
            maxWidth: 100
        }
    ]
    
    return (
        <Dialog
            maxWidth="md"
            fullScreen={true}
            fullWidth={true}
            open={props.open}
            onClose={close}
        >
            <div className="dialog-wrapper">
                <div style={{textAlign:'right'}}>
                    <Close onClick={close} className="hoverable" />
                </div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <h1>Allbirds Importer History</h1>
                        <ReactTable 
                            filterable
                            className='-striped' 
                            showPaginationTop 
                            showPaginationBottom 
                            resizable={true} 
                            defaultPageSize={10} 
                            pageSizeOptions={[10, 20, 30]}
                            data={data} 
                            columns={columns} 
                        />
                    </GridItem>
                </GridContainer>

                {openedPreview && <Dialog
                    open={openedPreview}
                    onClose={() => {
                        setOpenedPreview(false);
                    }}
                    fullWidth={true}
                    maxWidth={false}
                    fullScreen={true}
                >
                    <AllBirdsImporterPreview 
                        importerData={props.allbirds_import_data}
                        close={() => {
                            setOpenedPreview(false);
                        }}
                        readOnly={true}
                        getRfidImporterExcel={props.getRfidImporterExcel}
                        id={props.openPreview ? props.openPreview.id : null}
                        excel_id={props.excel_id}
                        excel_loading={props.excel_loading}
                    />
                </Dialog>}
            </div>
        </Dialog>
    )
}

const mapStateToProps = state => {
    return {
        rfid_importer_data: state.rfid.rfid_importer_data,
        excel_id: state.rfid.excel_id,
        allbirds_import_data: state.rfid.openPreview ? JSON.parse(state.rfid.openPreview.data) : {},
        openPreview: state.rfid.openPreview,
        excel_loading: state.rfid.excel_loading
    }
  }

  const mapDispatchToProps = dispatch => {
    return {
        getRfidImporterHistory: (data) => {
            dispatch(actions.getRfidImporterHistory(data));
        },
        setRfidAllBirds: (allbirds_import_data) => {
            dispatch(actions.setRfidAllBirds(allbirds_import_data));
        },
        getRfidImporterExcel: (data) => {
            dispatch(actions.getRfidImporterExcel(data));
        }
    }
  }

  export default connect(mapStateToProps, mapDispatchToProps)(AllBirdsImporterHistory);