import React, { Component } from 'react';
import { connect } from 'react-redux';
import withStyles from "@material-ui/core/styles/withStyles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import compose from 'recompose/compose';

const BPSelect = (props) => {
  let error = false;
  if (props.validate) {
    if (props.validate.length > 0) {
      props.validate.forEach((val) => {
        const err = val(props.default);
        if (err) {
          error = err;
        }
      });
    }
  }
  const { classes, options, label, indx, desc } = props;
  let element = null;
  if (options.length > 0) {
    element = (
      <div>
        <FormControl
                      fullWidth
                      className={classes.selectFormControl}
                      error={error ? true : false}
                    >
                      <InputLabel
                        htmlFor="single-select"
                        className={classes.selectLabel}
                      >
                        <div style={{textTransform:'none',color:'#495057 important'}}>{label}</div>
                      </InputLabel>
            <Select
                    value={props.default ? props.default : ''}
                    onChange={(e) => {
                      props.choose(e.target.value, props.name);
                    }}
                    MenuProps={{ className: classes.selectMenu }}
                    classes={{ select: classes.select }}
                    inputProps={{
                        name: "singleSelect",
                        id: "single-select"
                    }}
                    disabled={props.disabled}
                >

                    {props.hasBlank && <MenuItem
                      classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                      }}
                      value=""
                      style={{marginTop:'0px'}}
                      >- none -</MenuItem>}
                        {
                            options.map((opt, index) => {
                              return (
                                  <MenuItem
                                      classes={{
                                          root: classes.selectMenuItem,
                                          selected: classes.selectMenuItemSelected
                                      }}
                                      key={index}
                                      value={indx ? opt[indx] : opt}
                                      style={{marginTop:'0px'}}
                                      >
                                      {desc ? opt[desc] : opt} {opt.addedText ? opt.addedText : ''} {opt.dblist ? opt.dblist : ''}
                                  </MenuItem>
                              )
                            })
                        }

                </Select>
        </FormControl>
        {error && <span className="red" style={{fontSize:'10px'}}>{error}</span>}
        {!error && <span className="red" style={{fontSize:'10px',opacity:0}}>_</span>}
      </div>
    )
  }
  return element;
}

export default compose(
    withStyles(extendedFormsStyle),
    connect(null, null)
  )(BPSelect);
