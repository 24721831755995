import React, { Component } from 'react';
import ReactDOM from "react-dom";
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import withStyles from "@material-ui/core/styles/withStyles";
import AttachMoney from "@material-ui/icons/AttachMoney";
import Delete from "@material-ui/icons/Delete";
import { numberWithCommas, convertDateStr, dateRangeOverlaps } from 'shared/utility';
import cloneDeep from "lodash/cloneDeep";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import ReactTable from "react-table";
import GridItem from "components/Grid/GridItem.jsx";
import Table from "components/Table/Table2.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";

import { ranges, rangesObj } from "./GINranges.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import activeDBs from "shared/activeDBs";
import GINaddCostTarget from "containers/GIN/GINadd-cost-target";
import GINqtyAlert from "./GINqty-alert-cost";
import GINask from "./GINask2";
import GMPMultipleSelect from "containers/GMP/GMPMultipleSelect";
import GINaddPriceSelect from "./GINadd-price-select";
import GINcopyCost from "./GINcopyCost";
import ContentCopy from '@material-ui/icons/ContentCopy';
import ReactTooltip from 'react-tooltip'
import dupesCalc from "./dupesCalc";
import fieldsList from "./fieldsList";

const fieldsObj = fieldsList.cost;

const comparator = (a, b) => {
 if (a[0] < b[0]) return -1;
 if (a[0] > b[0]) return 1;
 return 0;
}

const getRangeVals = (qty) => {
 let finalRange;
 ranges.forEach((r) => {
   if (qty < 10000000) {
     if (r.rangeA <= qty && r.rangeB >= qty) {
       finalRange = [r.rangeA, r.rangeB];
     }
   }
 });
 return finalRange;
}

const getMinQtyRange = (qtyRange) => {
 let min = '';
 ranges.forEach((rnge) => {
   if (rnge.name === qtyRange) {
     min = rnge.rangeA;
   }
 });
 return min;
}

const style = {
  customCardContentClass: {
    paddingLeft: "0",
    paddingRight: "0"
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const DBlabel = props => {
  // props.name
  let backgroundColor = '';
  activeDBs.forEach((db) => {
    if (db.name === props.name) {
      backgroundColor = db.color;
    }
  });

  if (backgroundColor) {
    return (
      <div style={{backgroundColor}} className="db-pill">
        {props.name.split('_')[0]}
      </div>
    );
  }
  return null;
}

const QtySelect = props => {
  // props.selected, props.name
  const backgroundColor = props.selected ? 'orange' : 'rgba(0,0,0,0)',
        color = props.selected ? 'white' : 'black';
  return (
    <div style={{backgroundColor,color,fontWeight:'bold'}} className="pilled hovered" onClick={() => {
      props.choose(props.name); }}>
      {props.name}
    </div>
  );
}

const Field = props => {
  const color = 'black';
  return (
    <div
      style={{color}}
      onClick={() => {
        if (props.click) {
          props.click();
        }
      }}
      className="hovered relative"
    >{props.name}
    </div>
  )
}

class GINcostTarget extends Component {
  constructor(props) {
    super(props);

    this.state = {
      quantityRange: 'Base Price',
      openCostAllEdit: false,
      openPriceAddSelect: false,
      minRange: '',
      ginAsk: false,
      rowData: null,
      askRequest: null,
      editTableData: null,
      openCopyCost: false
    }
  }

  openCopyCost = (pr) => {
    this.setState({
      openCopyCost: true,
      editTableData: pr
    });
  }

  closeCopyCost = () => {
    this.setState({
      openCopyCost: false,
      editTableData: null
    });
  }

  closeAsk = () => {
    this.setState({
      ginAsk: false,
    });
  }

  openAsk = (rowData, askRequest) => {
    this.setState({
      ginAsk: true,
      rowData,
      askRequest
    });
  }

  openPriceAddSelect = (minRange) => {
    this.setState({
      openPriceAddSelect: true,
      minRange
    });
  }

  closePriceAddSelect = () => {
    this.setState({
      openPriceAddSelect: false
    });
  }

  chooseDB = (db) => {
    this.openCostAllEdit({
      qty: getMinQtyRange(this.state.quantityRange),
      qtyField: this.state.quantityRange,
      DB:db
    });
    this.setState({
      openPriceAddSelect: false
    });
  }

  groupByQuantity = () => {
    let rangeCollection = {}, mocCollection = {}, costings = this.props.costings;
    Object.keys(costings).forEach(db => {
      console.log('rangeC collecting db', db)
      if (costings[db]) {
        console.log('rangeC collecing db found', costings[db])
          // find special moc if any
          let mocObj = {};
          costings[db].forEach((pr,i) => {
            if (parseInt(pr[0]) === 0) {
              const curr = pr[5];
              mocObj[curr] = {
                term: pr[1],
                moc: pr[2] ? parseFloat(pr[2]) : 0,
                code: pr[3],
                ccode: pr[4],
                start: pr[6],
                end: pr[7],
                index: -1
              }
            }
          });
          costings[db].forEach((pr, indx) => {
            const qty = parseInt(pr[0]),
                  term = pr[1],
                  cost = parseFloat(pr[2]),
                  code = pr[3],
                  ccode = pr[4],
                  currency = pr[5],
                  start = pr[6],
                  itemName = this.props.itemCodes[db],
                  moc = mocObj[currency] ? parseInt(mocObj[currency].moc) : this.props.mocs[db][currency],
                  mocType = mocObj[currency] ? 'Special' : 'Standard',
                  end = pr[7],
                  mocSpecial = mocObj,
                  index = pr[8];

            const obj = {
              qty, term, cost, code, ccode, currency, start, end, db, itemName, moc, mocType, mocSpecial, index
            };
            console.log('obj',obj);

            ranges.forEach(range => {
              if (qty >= range.rangeA && qty <= range.rangeB) {
                if ((obj.qty && obj.term) && (obj.cost && obj.start)) {
                  if (rangeCollection.hasOwnProperty(range.name)) {
                    rangeCollection[range.name].push(obj);
                  } else {
                    rangeCollection[range.name] = [obj];
                  }
                }
              } else {
                if (!rangeCollection.hasOwnProperty(range.name)) {
                  rangeCollection[range.name] = [];
                }
              }
            });
          });

      }
    });
    return rangeCollection;
  }

  selectQuantity = (name) => {
    if (name === 'Base Cost') {
      name = 'Base Price'
    }
    this.setState({
      quantityRange: name
    });
  }

  renderRanges = () => {
    const rangeData = ranges.map((mism) => {
      return [
        <QtySelect
          name={mism.name === 'Base Price' ? 'Base Cost' : mism.name}
          choose={this.selectQuantity}
          selected={this.state.quantityRange === mism.name}/>
      ]
    });

    return rangeData;
  }

  openCostAllEdit = (editTableData) => {
    this.setState({
      openCostAllEdit: true,
      editTableData: editTableData ? editTableData : {}
    });
  }

  closeCostAllEdit = () => {
    this.setState({
      openCostAllEdit: false
    });
  }

  deleteRow = (pr) => {
    console.log(pr);
    if (pr.index === -1) {
      const db = pr.db;
      let copyObj = {};
      const singleFields = ['ccur','cstart','cend','cprice','cterm','ccode'],
            multipleFields = ['ccurrs','cstarts','cends','cprices', 'cterms', 'ccodes'];
      singleFields.forEach((field) => {
        copyObj[field] = cloneDeep(this.props[field]);
        copyObj[field][db] = null;
        console.log('deleting', field, db);
      });

      // find gcurrs, gstarts
      copyObj.ccurrs = cloneDeep(this.props.ccurrs);
      copyObj.cstarts = cloneDeep(this.props.cstarts);
      copyObj.cends = cloneDeep(this.props.cends);
      copyObj.cprices = cloneDeep(this.props.cprices);
      copyObj.cterms = cloneDeep(this.props.cterms);
      copyObj.cqtys = cloneDeep(this.props.cqtys);
      copyObj.ccodes = cloneDeep(this.props.ccodes);

      if (this.props.cqtys[db]) {
        if (this.props.cqtys[db].length > 0) {
          // select whichever has quantity = 1-999
          let foundQty = -1, foundAlready = false;
          this.props.cqtys[db].forEach((qty,index) => {
            if (!foundAlready) {
              if (parseInt(qty)) {
                if (parseInt(qty) >= 1 && parseInt(qty) < 1000) {
                  foundQty = index;
                  foundAlready = true;
                }
              }
            }
          });

          if (foundQty !== -1) {
            // copy 1st row
            singleFields.forEach((field,i) => {
              let copy = { ...this.props[multipleFields[i]] };
              console.log(field, multipleFields, copy);
              copyObj[field][db] = copy[db][foundQty];
              copyObj[multipleFields[i]][db] = copy[db].filter((cc,j) => {
                if (j === foundQty) {
                  return false;
                }
                return true;
              });
            });

            let copy = { ...this.props.cqtys };
            copyObj.cqtys[db] = copyObj.cqtys[db].filter((gq,jj) => {
              if (jj === foundQty) {
                return false;
              }
              return true;
            });
          }
        }
      }

      this.props.updateGINMultiple(copyObj);

      // fieldsObj.single.forEach((field,i) => {
      //   console.log('field deleting', field);
      //   if (field) {
      //     this.props.updateGINEdit({
      //       db: pr.db,
      //       field,
      //       value: null
      //     });
      //   }
      // });
    } else {
      fieldsObj.multiple.forEach((field,i) => {
        console.log('field deleting', field);
        if (field) {
          let copy = cloneDeep(this.props[field]);
          copy[pr.db] = copy[pr.db].filter((c,j) => {
            if (j === pr.index) {
              return false;
            }
            return true;
          });
          this.props.updateGINEdit({
            db: pr.db,
            field,
            value: copy[pr.db]
          });
        }
      });
    }
  }

  render() {
    const { classes } = this.props;
    console.log('state & props SUPPLIER-SPECIFIC', this.state, this.props);
    const rangeCollection = this.groupByQuantity();
    console.log('rangeCollection',rangeCollection);
    const costs = rangeCollection[this.state.quantityRange] ? rangeCollection[this.state.quantityRange] : [];
    const qtyRangeDupes = dupesCalc(this.props.groupedDBMultiQuantities);
    console.log('duplicates',qtyRangeDupes);

    let dbsArr = [],
        currencyArr = [],
        effectiveDatesArr = [],
        termsArr = [];
    costs.forEach(pr => {
      if (dbsArr.indexOf(pr.db) === -1) {
        dbsArr.push(pr.db);
      }
      if (termsArr.indexOf(pr.term) === -1) {
        termsArr.push(pr.term);
      }
      if (currencyArr.indexOf(pr.currency) === -1) {
        currencyArr.push(pr.currency);
      }
      if (effectiveDatesArr.indexOf(`${pr.start} TO ${pr.end}`) === -1) {
        effectiveDatesArr.push(`${pr.start} TO ${pr.end}`);
      }
    });

    const table = <div className="spec-table scrolling-table"><ReactTable
        data={
            costs.map((pr, key) => { // map users to table rows
              const qty = parseInt(pr.qty);
              const getRange = getRangeVals(qty);
              let minTotalPrice = '',
                  maxTotalPrice = '',
                  totalPriceRange = '10M+';
              if (getRange) {
                minTotalPrice = (getRange[0] * pr.cost) < pr.moc ? pr.moc : (getRange[0] * pr.cost);
                maxTotalPrice = (getRange[1] * pr.cost) < pr.moc ? pr.moc : (getRange[1] * pr.cost);
                totalPriceRange = getRange ? `${numberWithCommas(minTotalPrice)}` : `10M+`;
              }
              return ({
                id: key,
                db_name: pr.db,
                dbCell: (
                    <DBlabel name={pr.db} />
                ),
                term_name: pr.term,
                termCell: (
                  <Field
                    name={pr.term}
                    click={() => {
                      this.openCostAllEdit({
                        ...pr,
                        type: 'terms',
                        DB: pr.db,
                        value: pr.term,
                        itemName: pr.itemName,
                        allPrices: this.props.pricings,
                        qty: getMinQtyRange(this.state.quantityRange),
                        qtyField: this.state.quantityRange,
                        action: 'edit'
                      });
                    }} />
                ),
                cost_name: `${pr.currency} ${numberWithCommas(parseFloat(pr.cost))}`,
                priceCell: (
                  <Field
                    name={`${pr.currency} ${numberWithCommas(parseFloat(pr.cost))}`}
                    click={() => {
                      this.openCostAllEdit({
                        ...pr,
                        type: 'cost',
                        DB: pr.db,
                        value: pr.cost,
                        itemName: pr.itemName,
                        qty: getMinQtyRange(this.state.quantityRange),
                        qtyField: this.state.quantityRange,
                        action: 'edit'
                      });
                    }}
                  />
                ),
                effective_dates_name: `${pr.start} TO ${pr.end}`,
                effectiveDatesCell: (<Field
                      name={`${pr.start} TO ${pr.end}`}
                      click={() => {
                        this.openCostAllEdit({
                          ...pr,
                          type: 'effective-dates',
                          DB: pr.db,
                          value: `${pr.start},${pr.end}`,
                          itemName: pr.itemName,
                          qty: getMinQtyRange(this.state.quantityRange),
                          qtyField: this.state.quantityRange,
                          action: 'edit'
                        });
                      }}
                    />),
                currency: pr.currency,
                currencyCell: (<Field
                      name={pr.currency}
                      click={() => {
                        this.openCostAllEdit({
                          ...pr,
                          type: 'currency',
                          DB: pr.db,
                          value: pr.currency,
                          itemName: pr.itemName,
                          qty: getMinQtyRange(this.state.quantityRange),
                          qtyField: this.state.quantityRange,
                          action: 'edit'
                        });
                      }}
                    />),
                total_cost: `${pr.currency} ${totalPriceRange}`,
                moc: pr.moc,
                mocType: pr.mocType,
                moc_name: `${pr.currency} ${numberWithCommas(parseFloat(pr.moc))} ${pr.mocType}`,
                mocCell: (
                  <Field
                     name={`${pr.currency} ${numberWithCommas(parseFloat(pr.moc))} ${pr.mocType}`}
                     click={() => {
                       this.openCostAllEdit({
                         ...pr,
                         type: 'moc',
                         DB: pr.db,
                         value: pr.moc,
                         mocType: pr.mocType,
                         itemName: pr.itemName,
                         qty: getMinQtyRange(this.state.quantityRange),
                         qtyField: this.state.quantityRange,
                         action: 'edit'
                       });
                     }}
                   />
                ),
                deleteCell: (
                  <div
                    className="red-text hovered"
                    style={{paddingTop:'10px',paddingBottom:'10px'}}
                    onClick={() => {
                      this.openAsk(pr, 'deleteRow');
                    }}>
                    <Delete />
                  </div>
                ),
                itemName: pr.itemName,
                pr: pr,
                copyCell: (
                <div>
                  {pr.currency === '$' &&
                        <div
                          data-tip="copy cost"
                          className="green-text hovered"
                          style={{paddingTop:'10px',paddingBottom:'10px'}}
                          onClick={() => {
                            this.openCopyCost(pr);
                          }}>
                          <ContentCopy />
                        </div>
                }
                </div>
                )
              })
            })
        }
        filterable
        columns={[
            {
                Header: "DB",
                accessor: "db_name",
                filterMethod: (filter, row) => {
                    const chosen = filter.value;
                    if (chosen.length > 0) {
                        return chosen.indexOf(row[filter.id]) !== -1;
                    } else {
                        return true;
                    }
                },
                Filter: ({filter, onChange}) => {
                    return <div className="gmp-filter"><GMPMultipleSelect
                        options={dbsArr}
                        label={''}
                        choose={onChange}
                    /></div>
                },
                Cell: row => {
                  return (
                    <div>{row.original.dbCell}</div>
                  )
                },
                maxWidth: 60
            },
            {
                Header: "SHIPPING TERMS",
                accessor: "term_name",
                filterMethod: (filter, row) => {
                    const chosen = filter.value;
                    if (chosen.length > 0) {
                        return chosen.indexOf(row[filter.id]) !== -1;
                    } else {
                        return true;
                    }
                },
                Filter: ({filter, onChange}) => {
                    return <div className="gmp-filter"><GMPMultipleSelect
                        options={termsArr}
                        label={''}
                        choose={onChange}
                    /></div>
                },
                Cell: row => {
                  return (
                    <div>{row.original.termCell}</div>
                  )
                }
            },
            {
                Header: "COST",
                accessor: "cost_name",
                Cell: row => {
                  return (
                    <div>{row.original.priceCell}</div>
                  )
                },
                sortable: true,
                filterable: false,
            },
            {
                Header: "EFFECTIVE DATES",
                accessor: "effective_dates_name",
                filterMethod: (filter, row) => {
                    const chosen = filter.value;
                    if (chosen.length > 0) {
                        return chosen.indexOf(row[filter.id]) !== -1;
                    } else {
                        return true;
                    }
                },
                Filter: ({filter, onChange}) => {
                    return <div className="gmp-filter"><GMPMultipleSelect
                        options={effectiveDatesArr}
                        label={''}
                        choose={onChange}
                    /></div>
                },
                Cell: row => {
                  return (
                    <div>{row.original.effectiveDatesCell}</div>
                  )
                },
                style:{ whiteSpace: 'unset', fontSize: '12px'},
                maxWidth: 108
            },
            {
                Header: "CURRENCY",
                accessor: "currency",
                filterMethod: (filter, row) => {
                    const chosen = filter.value;
                    if (chosen.length > 0) {
                        return chosen.indexOf(row[filter.id]) !== -1;
                    } else {
                        return true;
                    }
                },
                Filter: ({filter, onChange}) => {
                    return <div className="gmp-filter"><GMPMultipleSelect
                        options={currencyArr}
                        label={''}
                        choose={onChange}
                    /></div>
                },
                Cell: row => {
                  return (
                    <div className="text-center">{row.original.currencyCell}</div>
                  )
                },
                maxWidth: 100
            },
            {
                Header: "TOTAL COST",
                accessor: "total_cost",
                sortable: false,
                filterable: false,
            },
            {
                Header: "",
                accessor: "deleteCell",
                sortable: false,
                filterable: false,
                maxWidth: 80
            },
            {
              Header: "",
              accessor: "copyCell",
              maxWidth: 100,
              sortable: false,
              filterable: false,
            }
        ]}
        minRows={0}
        defaultPageSize={1000}
        showPagination={false}
        className="-highlight"
    /></div>

    return (
      <div>
        {
         this.state.openCopyCost && <GINcopyCost open={this.state.openCopyCost} close={this.closeCopyCost} editTableData={this.state.editTableData} />
        }
        {
          this.state.openCostAllEdit &&
            <GINaddCostTarget
              open={this.state.openCostAllEdit}
              close={this.closeCostAllEdit}
              editTableData={this.state.editTableData}
            />
        }
        {
          this.state.openPriceAddSelect &&
            <GINaddPriceSelect
              open={this.state.openPriceAddSelect}
              close={this.closePriceAddSelect}
              choose={this.chooseDB}
              dbOptions={this.props.dboptions}
              minRange={this.state.minRange}
            />
        }
        {
          qtyRangeDupes.length > 0 &&
            <GINqtyAlert
              open={qtyRangeDupes.length > 0}
              close={this.closeQtyAlert}
              duplicates={qtyRangeDupes}
            />
        }
        {
          this.state.ginAsk &&
          <GINask
            askRequest="deleteRow"
            close={this.closeAsk}
            command={() => {
              this.deleteRow(this.state.rowData);
              this.closeAsk();
            }}
            content="Delete this Costing row?"
            title="Are you sure?"
          />
        }
        <GridContainer>
          <GridItem xs={12} sm={12} md={1}>
            <div className="outlined-blue" style={{float:'right',maxWidth:'200px',padding:'10px 0'}}>
              <div className="tiny-table">
                <Table
                  tableHeaderColor="primary"
                  tableHead={["QUANTITY"]}
                  tableData={this.renderRanges()}
                  coloredColls={[3]}
                  colorsColls={["primary"]}
                />
              </div>
            </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={11}>
          <div style={{marginTop:'20px'}}>
            <div className="font-12" style={{marginTop:'30px'}}>
              <strong>DISPLAYING FOR QUANTITY: <span className="orange-text">{this.state.quantityRange === 'Base Price' ? 'Base Cost' : this.state.quantityRange}</span></strong>
            </div>
            <div className="relative">
              <div className="price-add-btn">
                <Button
                  color="rose"
                  size="sm"
                  onClick={() => {
                    this.openPriceAddSelect(this.state.quantityRange);
                  }}
                >Add</Button>
              </div>
              {table}
            </div>
          </div>
          </GridItem>
        </GridContainer>
        <ReactTooltip />
      </div>
    )
  }
}

const getRangeField = (qty) => {
  let field = 0;
  ranges.forEach((r) => {
    if (qty < 10000000) {
      if (r.rangeA <= qty && r.rangeB >= qty) {
        field = r.name;
      }
    }
  });
  return field;
}

const mapStateToProps = (state) => {
  // filter dbs to only Items that have Cost enabled - Both, Outsourced
  const dboptions = state.gin.openedDBs.filter(db => {
    if (state.gin.item_is[db] === 'Both' || state.gin.item_is[db] === 'Outsourced') {
      return true;
    }
    return false;
  });

  let costings = {},
      groupedDBMultiQuantities = {}; // group by db, quantity range
  dboptions.forEach(db => {
    let costrows = [];

    if ((state.gin.cterm[db] && state.gin.cprice[db]) && (state.gin.ccur[db] && state.gin.cstart[db])) {

    }
    costrows.push([
      1,
      state.gin.cterm[db],
      state.gin.cprice[db],
      state.gin.ccode[db], // ccode ?
      '',
      state.gin.ccur[db],
      state.gin.cstart[db],
      state.gin.cend[db],
      -1
    ]);

    state.gin.cqtys[db].forEach((q, i) => {
      costrows.push([
        q,
        state.gin.cterms[db][i],
        state.gin.cprices[db][i],
        state.gin.ccodes[db][i],
        '',
        state.gin.ccurs[db][i],
        state.gin.cstarts[db][i],
        state.gin.cends[db][i],
        i
      ]);
    });

    costings[db] = costrows;
  });

  console.log('rangeC', costings);

  Object.keys(costings).forEach(db => {
    groupedDBMultiQuantities[db] = {};
    costings[db].forEach(c => {
      const qtyField = getRangeField(parseInt(c[0]));
      const obj = {
        qty: parseInt(c[0]),
        term: c[1],
        cost: c[2],
        code: c[3],
        currency: c[5],
        start: c[6],
        end: c[7],
        indx: c[8],
        itemCode: state.gin.itemCodes[db]
      }
      if (groupedDBMultiQuantities[db].hasOwnProperty(qtyField)) {
        groupedDBMultiQuantities[db][qtyField].push(obj);
      } else {
        groupedDBMultiQuantities[db][qtyField] = [obj]
      }
    });
  });

  return {
    openedDBs: state.gin.openedDBs,
    dboptions,
    costings,
    groupedDBMultiQuantities,
    ccur: state.gin.ccur,
    cprice: state.gin.cprice,
    cterm: state.gin.cterm,
    cstart: state.gin.cstart,
    cend: state.gin.cend,
    ccode: state.gin.ccode,

    ccodes: state.gin.ccodes,
    ccurs: state.gin.ccurs,
    cprices: state.gin.cprices,
    cterms: state.gin.cterms,
    cstarts: state.gin.cstarts,
    cends: state.gin.cends,
    cqtys: state.gin.cqtys,

    itemCodes: state.gin.itemCodes,
    options: state.gin.options,
    mocs: state.gin.mocs
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    editModeOn: () => {
      dispatch(actions.editModeOn());
    },
    setEdit: (data) => {
      dispatch(actions.setGINEdit(data));
    },
    updateGINEdit: (data) => {
      dispatch(actions.updateGINEdit(data));
    },
    updateGINMultiple: (data) => {
      dispatch(actions.updateGINMultiple(data));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(GINcostTarget));
