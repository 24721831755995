import React, { Component } from "react";

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Button from "components/CustomButtons/Button.jsx";
import Table from "components/Table/Table.jsx";
import moment from "moment";

import BPFormReview from "containers/BP/BPFormReview";

const BPSingleModal = (props) => {
  console.log('BPSingleModal sprops', props);
  const the_bp = props.data[0];
  const the_bpname = the_bp.company_name ? the_bp.company_name : the_bp.customer_name;
  const the_db = the_bp.DB;
  let tableData = [];
  props.data.forEach((bp,i) => {
    const db = bp.DB;
    console.log('bp', bp);
    const bpname = bp.company_name ? bp.company_name : bp.customer_name;
    tableData.push([
      bp.id,
      bpname,
      bp.company_name ? 'New' : 'Existing',
      moment(bp.date_modified).format("MM/DD/YYYY hh:mm a"),
      <div>
        <Button
          color="white"
          style={{marginRight:10}}
          onClick={() => {
            console.log('review', bp.id);
            props.fetchAddInfoList(db);
            props.fetchLocalBP(bp.id, 'previewOnly');
            if (db.indexOf('NIN') !== -1) {
              props.fetchGSTType();
            }
          }}
        >Preview</Button>
        <Button
          color="rose"
          onClick={() => {
            console.log('open', bp.id);
            props.openBP(bp);
            props.close();
          }}
        >Open</Button>
      </div>
    ]);
  });
  const table = <Table
    tableHeaderColor="primary"
    tableHead={["#", "Name", "Type", "Date/Time", "Action"]}
    tableData={tableData}
    coloredColls={[3]}
    colorsColls={["primary"]}
  />

  // GROUP DATA
  const groupData = props.groups ? Object.keys(props.groups)
  .filter(g => {
    const g_arr = g.split('__');
    const db = g_arr[0];
    if (db === the_db) return true;
  })
  .filter(g => props.groups[g].length > 1)
  .filter(g => {
    const gObj = props.groups[g];
    let existing = false;
    gObj.forEach((go) => {
      if (go.id === the_bp.id) {
        existing = true;
      }
    })
    if (!existing) return true;
  })
  .sort()
  .map((g,i) => {
    const g_arr = g.split('__');
    const gdb = g_arr[0];
    const gname = g_arr[1];
    return [
      i+1,
      gdb,
      gname,
      <Button
        color="warning"
        onClick={() => {
          console.log('add to group', the_bp.id);
          props.addToGroup(g, the_bp);
          props.close();
        }}
      >Add to Group</Button>
    ]
  }) : null;
  const table2 = props.groups ? <div><Table
    tableHeaderColor="primary"
    tableHead={["#", "DB", "Name", "Action"]}
    tableData={groupData}
    coloredColls={[3]}
    colorsColls={["primary"]}
  /></div> : null;

  // SINGLE DATA
  const singles = props.singles;
  const singleData = singles.filter(s => s.id !== the_bp.id) // filter current opened BP
    .filter(s => s.name === 'GMP' || s.name === 'TPP')
    .filter(s => s.group_id ? false : true)
    .filter(s => s.DB === the_db ? true : false)
    .sort((a, b) => {
      const a_bpname = a.company_name ? a.company_name : a.customer_name;
      const b_bpname = b.company_name ? b.company_name : b.customer_name;
      return (a_bpname > b_bpname) ? 1 : -1;
    })
    .map((m,i) => {
      const bpname = m.company_name ? m.company_name : m.customer_name;
      const type = m.company_name ? 'NEW' : 'EXISTING';
      return [
        m.id,
        bpname,
        m.DB,
        type,
        <Button
          color="rose"
          onClick={() => {
            console.log('add to group', the_bp.id);
            props.createGroup(m, the_bp);
            props.close();
          }}
        >Create Group</Button>
      ];
    });
    const table3 = props.singles.length > 1 ? <div><Table
      tableHeaderColor="primary"
      tableHead={["ID", "Name", "DB", "Type", "Action"]}
      tableData={singleData}
      coloredColls={[3]}
      colorsColls={["primary"]}
    /></div> : null;

  return (
    <Dialog
      open={props.open}
      keepMounted
      onClose={props.close}
      fullWidth={true}
      maxWidth="md"
    >
      <DialogTitle>
        <IconButton edge="end" color="inherit" onClick={props.close} aria-label="Close" style={{float:'right'}}>
          <CloseIcon />
        </IconButton>
        <div className="text-center">
          <h4><strong>{the_db} - {the_bpname} Entry</strong></h4>
        </div>
      </DialogTitle>
      <DialogContent>
        {table}
        <hr />
        <h3 className="text-center"><strong>Group Entries</strong></h3>
        {table2 ? table2 : 'none'}
        {groupData.length === 0 ? <div className="text-center">n/a</div> : null}
        <h3 className="text-center"><strong>Single Entries</strong></h3>
        {table3 ? table3 : 'none'}
        {singleData.length === 0 ? <div className="text-center">n/a</div> : null}
      </DialogContent>
      {
        props.localBPreview &&
          <BPFormReview
            finalValues={props.localBPreview}
            beforeValues={props.localBPreview}
            open={props.localBPreview ? true : false}
            close={props.closeReviewModal}
            countries={props.countriesDictionary}
            states={props.statesDictionary}
            gsttypes={props.gsttypesDictionary}
            owhtopts={props.owhtoptsDictionary}
            groupcodesDictionary={props.groupcodesDictionary}
            BPaction=""
            currentDB={the_db}
            currentBPType="Customer"
          />
      }
    </Dialog>
  )
}

export default BPSingleModal;
