import React from 'react';
import CardIcon from "components/Card/CardIcon.jsx";
import { connect } from 'react-redux';
import { getPricingsForDupesCheck, getSupplierCostDupes, getTargetCostDupes, getCustomerPriceDupes } from "./GINhelpers";
import dupesCalc from "./dupesCalc";
import { Card, CardContent } from '@material-ui/core';
import RateReview from "@material-ui/icons/RateReview";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "../../components/CustomButtons/Button.jsx";
import GINreviewShort from "./GINreview-short";
import GINreviewDetailed from "./GINreview-detailed";
import GINsubmit from "./GINsubmit";
import GINalert from "./GINalert";
import GINqtyAlert from "./GINqty-alert";
import GINqtyAlertCostSuppliers from "./GINqty-alert-cost-suppliers";
import GINqtyAlertTargetCost from "./GINqty-alert-cost";
import GINqtyAlertPriceCustomers from "./GINqty-alert-price-customers";
import { showPicksList } from "shared/activeDBs";

const checkIfEmpty = (val) => {
  if (!val) return false;
  if (val) {
    if (val === 'n/a') {
      return false;
    }
    if (val === 'N/A') {
      return false;
    }
    if (val === '0') {
      return false;
    }
    if (val === 'NAN') {
      return false;
    }
    if (val === 'NaN') {
      return false;
    }
  }
  return true;
}

const descriptionObj = {
  base_item_description: 'Base Item Description',
  basic_packaging_unit: 'Basic Packaging Unit',
  brand: 'Brand',
  ilength: 'Length',
  product_type: 'Product Type',
  qty_per_unit: 'Qty. per unit',
  rbo: 'RBO',
  sales_unit: 'Sales Unit',
  subgroup: 'Subgroup',
  weight: 'Weight',
  width: 'Width'
}

class GINreview extends React.Component{
    state = {
      currentTab: 'Short',
      scrollToDB: null,
      submitStatus: false,
      priceHasChanged: false,
      variantChangeType: '',
      alert: '',
      qtyRangeDupes: [],
      supplierDupes: null,
      qtyRangeTargetDupes: [],
      customerDupes: null
    };

    goToDetailed = (db) => {
      this.setState({currentTab:'Detailed',scrollToDB:db});
    }

    openAlert = (msg) => {
      this.setState({alert:msg})
    }

    closeAlert = () => {
      this.setState({alert:''})
    }

    closeQtyAlert = () => {
      this.setState({qtyRangeDupes:[]})
    }

    closeQtyAlertCostSuppliers = () => {
      this.setState({supplierDupes:null});
    }

    closeQtyAlertTargetCost = () => {
      this.setState({qtyRangeTargetDupes: []})
    }

    closeQtyAlertPriceCustomers = () => {
      this.setState({customerDupes:null});
    }

    checkDupes = () => {
      const pricings = getPricingsForDupesCheck(this.props.stt);
      const qtyRangeDupes = dupesCalc(pricings);
      console.log('dupes calculation',pricings, qtyRangeDupes);
      if (qtyRangeDupes.length > 0) {
        this.setState({qtyRangeDupes});
      }
    }

    checkDupesCustomerPrice = () => {
      const customerDupes = getCustomerPriceDupes(this.props.stt);
      console.log('qtyRangeDupes',customerDupes);
      let dupesExist = false;
      Object.keys(customerDupes).forEach(cust => {
        if (customerDupes[cust]) {
          if (customerDupes[cust].length > 0) {
            dupesExist = true;
          }
        }
      });
      if (dupesExist) {
        this.setState({customerDupes});
      }
    }

    checkDupesSupplierCost = () => {
      const supplierDupes = getSupplierCostDupes(this.props.stt);
      console.log('qtyRangeDupes',supplierDupes);
      let dupesExist = false;
      Object.keys(supplierDupes).forEach(supp => {
        if (supplierDupes[supp]) {
          if (supplierDupes[supp].length > 0) {
            dupesExist = true;
          }
        }
      });
      if (dupesExist) {
        this.setState({supplierDupes});
      }
    }

    checkDupesTargetCost = () => {
      const targetCostDupes = getTargetCostDupes(this.props.stt);
      const qtyRangeTargetDupes = dupesCalc(targetCostDupes);
      console.log('dupes calculation',targetCostDupes, qtyRangeTargetDupes);
      if (qtyRangeTargetDupes.length > 0) {
        this.setState({qtyRangeTargetDupes});
      }
    }

    compareData = (field1, field2, db) => {
      const stt = this.props.stt.gin;
      if (stt[field1] && stt[field2]) {
        let val1 = stt[field1][db];
        let val2 = stt[field2][db];
        if (!stt[field1][db]) val1 = '';
        if (!stt[field2][db]) val2 = '';
        if (typeof val1 !== 'string') {
          val1 = JSON.stringify(val1);
        }
        if (typeof val2 !== 'string') {
          val2 = JSON.stringify(val2);
        }
        if (val1 != val2) return true;
      }
      return false;
    }

    checkForChanges = () => {
      let hasChanged = false;
      let priceHasChanged = false;
      const stt = this.props.stt.gin;
      const dbs = stt.openedDBs;

      if (!stt.ginExistingData) {
        hasChanged = true;
      }

      const data1 = [
        // GENERAL & MEASUREMENTS
        ['base_item_description', 'base_item_description_original'],
        ['product_type', 'product_type_original'],
        ['rbo', 'rbo_original'],
        ['brand', 'brand_original'],
        ['subgroup', 'sub_group_original'],
        ['ipackagingtypes', 'ipackagingtypes_original'],
        ['sales_unit', 'sales_unit_original'],
        ['basic_packaging_unit', 'basic_packaging_unit_original'],
        ['qty_per_unit', 'qty_per_unit_original'],
        ['ilength','ilength_original'],
        ['width','width_original'],
        ['weight','weight_original'],

        // COST
        ['cprice', 'cprice_original'],
        ['cstart', 'cstart_original'],
        ['cend', 'cend_original'],
        ['ccur','ccur_original'],
        ['cterm','cterm_original'],

        ['ccurs','ccurs_original'],
        ['cqtys','cqtys_original'],
        ['cstarts', 'cstarts_original'],
        ['cends','cends_original'],
        ['cprices','cprices_original'],
        ['cterms', 'cterms_original'],

        ['smasterarray','smasterarray_original'],

        // OTHER
        ['picked','picked_original'],
        ['picks', 'picks_original'],
        ['preferredVendor', 'preferredVendor_original'],
        ['ginFinalCrop', 'ginFinalCrop_original'],

        // BOM
        ['BOMItemBaseQty','BOMItemBaseQty_original'],
        ['rms','rms_original'],
        ['rmsd','rmsd_original'],
        ['rmsq','rmsq_original'],
        ['rmsw','rmsw_original'],

        // NIN specific fields
        ['service_acct_code','service_acct_code_original'],
        ['gst_relevant','gst_relevant_original'],
        ['gst_tax_category','gst_tax_category_original'],
        ['hsn_code','hsn_code_original'],
        ['material_type','material_type_original'],

      ];

      const data2 = [
        // PRICES
        ['gprice','gprice_original'],
        ['gstart','gstart_original'],
        ['gend','gend_original'],
        ['gterm','gterm_original'],
        ['gcur','gcur_original'],

        ['gprices','gprices_original'],
        ['gqtys','gqtys_original'],
        ['gstarts','gstarts_original'],
        ['gends','gends_original'],
        ['gterms','gterms_original'],
        ['gcurrs','gcurrs_original'],
        ['bmasterarray', 'bmasterarray_original'],
        ['mocs', 'mocs_original'],
        ['specialmoc','specialmoc_original'],
      ];

      dbs.forEach((db) => {
        data1.forEach((d) => {
          if (this.compareData(d[0], d[1], db)) hasChanged = true;
        });

        data2.forEach((d) => {
          if (this.compareData(d[0], d[1], db)) {
            priceHasChanged = true;
            hasChanged = true;
          }
        });

      });

      // VARIANT CHECK
      if (stt.variantsToAdd) {
        if (stt.variantsToAdd.length > 0) {
          hasChanged = true;
        }
      }
      if (stt.variantsChecked) {
        Object.keys(stt.variantsChecked).forEach((variant_combo) => {
          if (stt.variantsChecked[variant_combo] === 0) hasChanged = true;
        });
      }

      // VARIANT CHANGE U_S1C_Integrate FLAG
      let variantChangeType = '';
      const variants = {};
      // if GIN already exists check for variant changes
      if (stt.existingGINsBefore) {
        if (stt.variantsToAdd) {
          if (stt.variantsToAdd.length > 0) {
            hasChanged = true;
            if (Object.keys(stt.variantsChecked).length === stt.variantsToAdd.length) {
              // GIN changed from 0 variants to 1+ variants
              variantChangeType = 'add';
            }
          }
        }

        if (stt.variantsChecked) {
          let variantsRemoved = [];
          let currentVariantCodes = [];
          let variantCodesRemoved = [];
          // collect all variants
          Object.keys(stt.variants).forEach((db) => {
            const vcs = stt.variants[db];
            vcs.forEach((vc_arr) => {
              if (currentVariantCodes.indexOf(vc_arr[0]) === -1) currentVariantCodes.push(vc_arr[0]);
            });
          });

          const variantsChecked = Object.keys(stt.variantsChecked);
          const variantsCheckedCodes = variantsChecked.map((vcombo) => vcombo.split('_')[0]);
          variantsChecked.forEach((variant_combo) => {
            if (stt.variantsChecked[variant_combo] === 0) {
              if (variantsRemoved.indexOf(variant_combo) === -1) {
                variantsRemoved.push(variant_combo);
              }
            }
          });
          // check if variantsChecked has one of current Variants
          currentVariantCodes.forEach((vc) => {
            if (variantsCheckedCodes.indexOf(vc) === -1) {
              variantCodesRemoved.push(vc);
            }
          });

          if (variantsCheckedCodes.length !== currentVariantCodes.length) {
            hasChanged = true;
          }

          console.log('vc compare', currentVariantCodes, variantCodesRemoved);
          if (currentVariantCodes.length > 0 && variantCodesRemoved.length > 0) {
            if (currentVariantCodes.length === variantCodesRemoved.length) {
              hasChanged = true;
              variantChangeType = 'remove';
            }
          }

          if (variantsRemoved.length > 0 && variantsChecked.length > 0) {
            hasChanged = true;
            if (variantsRemoved.length === variantsChecked.length) {
              // GIN changed from 1+ variants to 0 variants
              variantChangeType = 'remove';
            }
          }
        }

      }

      if (stt.gmpCheckbox_original && !stt.gmpCheckbox) {
        // a previously checked TPP has been unchecked, remove from Magento
        if (this.props.ginType != 'NEW') {
          variantChangeType = 'remove_product';
          hasChanged = true;
        }
      }



      return [hasChanged, variantChangeType, priceHasChanged];
    }

    submit = () => {
      let alert = '';
      console.log('errorData', this.props, this.state);
      if (this.props.gmpCheckbox) {
        if (!this.props.tpp_item_type) {
          alert += 'TPP Item Type is required. ';
        }
      }
      const changeData = this.checkForChanges();
      console.log('changeData', changeData);
      const hasChanged = changeData[0];
      const variantChangeType = changeData[1];
      const priceHasChanged = changeData[2];
      if (!hasChanged) {
        this.openAlert('No changes detected. Changes are required for submissions. If the issue persists please contact Tech Support.');
        return;
      }
      // check picked if any are n/a or 0
      const stt = this.props.stt.gin;
      // check all DBs are opened
      if (stt.openedDBs.length !== 12) {
        alert += `Missing DBs, all 12 DBs must be submitted. `;
      }
      // check if Picks are empty if Product Type = Woven
      const pickedProductTypeDB = stt.picked.product_type;
      const pickedProductType = stt.product_type[pickedProductTypeDB];
      if (pickedProductType === 'Woven Labels') {
        let missingAlert = '';
        stt.openedDBs.forEach((db) => {
          if (showPicksList(db, 'Woven Labels')) {
            if (!stt.picks[db]) {
              missingAlert += `${db} `;
            }
          }
        });
        if (missingAlert) {
          missingAlert += ` is missing Picks.`;
          console.log('missing picks');
          this.openAlert(missingAlert);
          return;
        }
      }
      const picked = stt.picked;
      if (picked) {
        let emptyPicks = [];
        Object.keys(picked).forEach((field) => {
          if ((field !== 'brand' && field !== 'ipackagingtypes') && (field !== 'subgroup' && field !== 'variants')) {
            if (stt[field]) {
              const dbSelected = picked[field];
              const value = stt[field][dbSelected];
              if (!checkIfEmpty(value)) {
                emptyPicks.push(field);
              }
            }
          }
        });
        console.log('emptyPicks', emptyPicks, picked);
        if (emptyPicks.length > 0) {
          alert += '(';
          emptyPicks.forEach((ep) => {
            alert += `
              ${descriptionObj[ep]},
            `;
          });
          alert += `) fields are empty, please pick a DB that has a valid value.`;
        }
      }
      // count all variants 
      if (stt.variantsChecked) {
        if (Object.keys(stt.variantsChecked).length > 0) {
          let countVariants = 0;
          Object.keys(stt.variantsChecked).forEach(svc => {
            if (stt.variantsChecked[svc] === 1) {
              countVariants++;
            }
          })
          if (countVariants > 200) {
            alert += `Number of variants must not exceed 200.`;
          }
        }
      }
      console.log('alerts', alert);
      if (alert) {
        this.openAlert(alert);
        return;
      }
      const err = this.props.errorData;
      const pricings = getPricingsForDupesCheck(this.props.stt);
      const qtyRangeDupes = dupesCalc(pricings);
      console.log('dupes calculation',pricings, qtyRangeDupes);
      if (qtyRangeDupes.length > 0) {
        this.setState({qtyRangeDupes});
        return;
      }

      if (err) {
        if (err.tabRequirements) {
          if (err.tabRequirements.length > 0) {
            const tabRequirements = err.tabRequirements;
            let alert = '';
            if (tabRequirements.indexOf('BOM') !== -1) {
              alert += 'BOM Required (Minimum 1 Raw Material). ';
            }
            if (tabRequirements.indexOf('Costing') !== -1) {
              alert += 'Costing Required (At least 1 Supplier Cost needed). ';
            }
            if (tabRequirements.indexOf('Pricing') !== -1) {
              alert += 'Pricing Required (At least 1 General Pricing needed). ';
            }
            this.openAlert(alert);
            return;
          }
        }

        Object.keys(err).forEach(field => {
          if (field !== 'tabRequirements') {
            if (err[field]) {
              if (typeof err[field] === 'string') {
                alert += `${err[field]} `;
              }
            }
          }
        });
        if (alert) {
          this.openAlert(alert);
          return;
        }
      }
      console.log('variantChangeType', variantChangeType);
      this.setState({
        variantChangeType,
        submitStatus: true,
        priceHasChanged
      });
    }

    componentDidMount() {
      if (this.props.setClick) this.props.setClick(this.submit);
    }

    render() {
        console.log('prps', this.props);
        return (
          <div onScroll={() => console.log('scrolling in here')}>
            <Card style={{marginTop:10,marginBottom:10}}>
                <CardContent>
                    <GridContainer>
                      <GridItem xs={12} sm={2} md={2}>
                        <div style={{margin:'22px 0 20px 10px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                            <CardIcon color="rose">
                                <RateReview style={{fill:'white'}} />
                            </CardIcon>
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={6} md={6}>
                        {!this.state.submitStatus && <div>
                          <Button
                            size="lg"
                            color={this.state.currentTab === 'Short' ? 'rose' : 'white'}
                            onClick={() => {
                              this.setState({currentTab:'Short',submitStatus:false});
                            }}
                            style={{marginRight:10}}
                          >
                            SHORT SUMMARY
                          </Button>
                          <Button
                            size="lg"
                            color={this.state.currentTab === 'Detailed' ? 'rose' : 'white'}
                            onClick={() => {
                              this.setState({currentTab:'Detailed',submitStatus:false});
                            }}
                          >
                            DETAILED SUMMARY
                          </Button>
                        </div>}
                      </GridItem>
                      <GridItem xs={12} sm={4} md={4}>
                        <div style={{marginTop:20,textAlign:'right'}}>
                          {this.props.tpp_item_type && <span style={{marginRight:40}}>
                            <strong>TPP Item Type:</strong>
                            <span className="orange-text bold"> {this.props.tpp_item_type}</span>
                          </span>}
                          <span style={{marginRight:40}}>
                            <strong>TPP:</strong>
                            <span className="orange-text bold"> {this.props.gmpCheckbox ? 'YES' : 'NO'}</span>
                          </span>
                          <span>
                            <strong>GIN:</strong>
                            <span className="orange-text bold"> {this.props.ginNum}
                            </span>
                          </span>
                        </div>
                      </GridItem>
                    </GridContainer>
                    {
                      !this.state.submitStatus && <div>
                      {this.state.currentTab === 'Short' && <GINreviewShort
                        isHistory={this.props.isHistory}
                        isPreview={this.props.isPreview}
                        ginSource={this.props.ginSource}
                        checkDupes={this.checkDupes}
                        checkDupesSupplierCost={this.checkDupesSupplierCost}
                        checkDupesTargetCost={this.checkDupesTargetCost}
                        checkDupesCustomerPrice={this.checkDupesCustomerPrice}
                        submit={this.submit}
                        goToDetailed={this.goToDetailed} />}
                      {this.state.currentTab === 'Detailed' && <GINreviewDetailed
                        isHistory={this.props.isHistory}
                        isPreview={this.props.isPreview}
                        checkDupes={this.checkDupes}
                        checkDupesSupplierCost={this.checkDupesSupplierCost}
                        checkDupesTargetCost={this.checkDupesTargetCost}
                        checkDupesCustomerPrice={this.checkDupesCustomerPrice}
                        ginSource={this.props.ginSource}
                        submit={this.submit}
                        scrollToDB={this.state.scrollToDB} />}
                      </div>
                    }

                    {
                      this.state.submitStatus && <GINsubmit
                        action={this.props.action}
                        selectTab={this.props.selectTab}
                        resetGINNum={this.props.resetGINNum}
                        autosave_id={this.props.autosave_id}
                        ginType={this.props.ginType}
                        ginSource={this.props.ginSource}
                        autosaveData={this.props.autosaveData} // if from pending
                        closeView={this.props.closeView}
                        variantChangeType={this.state.variantChangeType}
                        priceHasChanged={this.state.priceHasChanged}
                      />
                    }
                </CardContent>
            </Card>
            {
              this.state.alert && <GINalert
                  title="Required"
                  content={this.state.alert}
                  hide={this.closeAlert}
                />
            }
            {
              this.state.qtyRangeDupes.length > 0 &&
                <GINqtyAlert
                  open={this.state.qtyRangeDupes.length > 0}
                  close={this.closeQtyAlert}
                  duplicates={this.state.qtyRangeDupes}
                />
            }
            {
              this.state.supplierDupes &&
                <GINqtyAlertCostSuppliers
                  open={this.state.supplierDupes ? true : false}
                  close={this.closeQtyAlertCostSuppliers}
                  duplicates={this.state.supplierDupes}
                />
            }
            {
              this.state.qtyRangeTargetDupes.length > 0 &&
                <GINqtyAlertTargetCost
                  open={this.state.qtyRangeTargetDupes.length > 0}
                  close={this.closeQtyAlertTargetCost}
                  duplicates={this.state.qtyRangeTargetDupes}
                />
            }
            {
              this.state.customerDupes &&
                <GINqtyAlertPriceCustomers
                  open={this.state.customerDupes ? true : false}
                  close={this.closeQtyAlertPriceCustomers}
                  duplicates={this.state.customerDupes}
                />
            }
          </div>
        )
    }
}

const mapStateToProps = state => {
  return {
    ginNum: state.gin.ginNum,
    gmpCheckbox: state.gin.gmpCheckbox,
    tpp_item_type: state.gin.tpp_item_type,
    ginFinalCrop: state.gin.ginFinalCrop,
    stt: state
  }
}

export default connect(mapStateToProps, null)(GINreview);
