import React from 'react'
import { connect } from 'react-redux'

import PropTypes from 'prop-types';
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { required, email } from '../../../../shared/validation';
import CustomInput from '../../../../components/CustomInput/CustomInput';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

import InputLabel from '@material-ui/core/InputLabel';
import { getActiveUser } from '../../../../store/selectors';
import * as actions from '../../../../store/actions';
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import withStyles from "@material-ui/core/styles/withStyles";

import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import Button from "components/CustomButtons/Button.jsx";

const renderCheckbox = ({
    input,
    meta: { touched, error },
    label,
    classes
}) => {
    console.log(input);
    return (
        <div>
            <FormControlLabel
                control={
                    <Checkbox
                        tabIndex={-1}
                        onClick={input.onChange}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                            checked: classes.checked
                        }}

                        checked={input.value}
                    />
                }

                label={label}
            />

        </div>
    );}

const renderTextField = ({
    input,
    label,
    meta: { touched, error }
}) => (
    <div>
      <CustomInput
          labelText={label}
          id={input.name}
          formControlProps={{
              fullWidth: true,
          }}
          inputProps={{
              onChange: input.onChange,
              value: input.value
          }}
      />
      {touched &&
        ((error && <span className="red">{error}</span>))}
    </div>
    )

const autoEmail = (value, previousValue, allValues) => allValues['givenName']+'.'+allValues['sn']+'@natcoglobal.com';

const renderEmailField = ({
  input,
  label,
  meta: { touched, error }
}) =>
  (
    <div>
      <CustomInput
          labelText={label}
          id={input.name}
          formControlProps={{
              fullWidth: true,
          }}
          inputProps={{
              onChange: input.onChange,
              value: input.value
          }}
      />
      {touched &&
        ((error && <span className="red">{error}</span>))}
    </div>
  )

const renderPasswordField = ({
  input,
  label,
  meta: { touched, error }
}) => (
  <div>
  <CustomInput
      labelText={label}
      id={input.name}
      formControlProps={{
          fullWidth: true,
      }}
      inputProps={{
          onChange: input.onChange,
          value: input.value,
          type: 'password'
      }}
  />
  {touched &&
    ((error && <span className="red" dangerouslySetInnerHTML={{__html: error}} />))}
  </div>
)


const renderSelectField = ({
    input,
    options,
    meta: { touched, error }
}) => (
        <div>
            <FormControl style={{ width: '100%', marginTop: '10px' }}>
                <InputLabel htmlFor={input.name}>{input.name}</InputLabel>
                <Select
                    value={input.value}
                    inputProps={{
                        onChange: input.onChange,
                        name: input.name,
                        id: input.name,
                    }}
                >
                    <MenuItem disabled value="">{input.name}</MenuItem>
                    {options.map(option => (
                        <MenuItem key={option} value={option}>{option}</MenuItem>
                    ))}

                </Select>
            </FormControl>
            {touched &&
              ((error && <span className="red">{error}</span>))}
          </div>
    )

  const validate = (values, props) => {
    const errors = {}
    if (!values.givenName) {
      errors.givenName = 'Firstname Required';
    } else if (values.givenName.length > 20) {
      errors.givenName = 'Firstname Must be 20 characters or less';
    }
    if (!values.sn) {
      errors.sn = 'Lastname Required';
    } else if (values.givenName.length > 20) {
      errors.givenName = 'Lastname Must be 20 characters or less';
    }
    if (!values.mail) {
      errors.mail = 'E-mail Required';
    } else if (/\S+@\S+\.\S+/.test(values.email)) {
      errors.mail = 'Invalid email address';
    }
    if (!props.existing && !values.password) {
      errors.password = 'Password Required';
    }
    if (!props.existing && !values.password2) {
      errors.password2 = 'Retype Password Required';
    }
    if (values.password && values.password2 && values.password != values.password2) {
      errors.password2 = 'Both Password and Retype Passwords should Match';
    }
    if (values.password && values.password.length < 7) {
      errors.password = 'Minimum Password length is 7 characters<br>';
    }
    if (values.password) {
      const hasUpperCase = /[A-Z]/.test(values.password);
      const hasLowerCase = /[a-z]/.test(values.password);
      const hasNumbers = /\d/.test(values.password);
      const hasNonalphas = /\W/.test(values.password);
      const isUnicode = /[^\u0000-\u00ff]/.test(values.password);
      if (hasUpperCase + hasLowerCase + hasNumbers + hasNonalphas < 3) {
        const message = 'Password must contain characters from 3 of the following 4 categories:<br>' +
        'English uppercase characters (A through Z)<br>' +
        'English lowercase characters (a through z)<br>' +
        'Base 10 digits (0 through 9)<br>' +
        'Non-alphabetic characters (for example, !, $, #, %)<br>';

        if (errors.password) {
          errors.password += message;
        } else {
          errors.password = message;
        }
      }

      if (isUnicode) {
        if (errors.password) {
          errors.password += 'English characters only<br>';
        } else {
          errors.password = 'English characters only<br>';
        }
      }
    }

    if (values.givenName && values.sn) {
      const fullname = values.givenName + ' ' + values.sn + '@natcoglobal.com';
      const partsOfThreeLetters = fullname.match(/.{3}/g).concat(
                           fullname.substr(1).match(/.{3}/g),
                           fullname.substr(2).match(/.{3}/g) );
      if (new RegExp(partsOfThreeLetters.join("|"), "i").test(values.password)) {
        const errMsg = 'Password cannot contain the user\'s account name or parts of the user\'s full name that exceed two consecutive characters';
        if (errors.password) {
          errors.password += errMsg;
        } else {
          errors.password = errMsg;
        }
      }
    }

    if (!values.country) {
      errors.country = 'Country required';
    }
    if (!values.office) {
      errors.office = 'Office required';
    }
    if (!values.department) {
      errors.department = 'Department required';
    }
    return errors;
  }

let ADUserForm = props => {
  const { handleSubmit, classes, hasCountryValue, hasOfficeValue, hasFirstname = '', hasLastname = '' } = props;
  let { passwordEdit } = props;
  const buttonText = props.existing ? 'Edit User' : 'Add User';
  if (buttonText == 'Add User') {
    passwordEdit = false;
  }
  console.log('doesithavefullname', hasFirstname, hasLastname);
  const fullName = hasFirstname && hasLastname ? hasFirstname+'.'+hasLastname+'@natcoglobal.com' : '';
  console.log(props);
  return <form onSubmit={handleSubmit}>
      <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}>
          {!passwordEdit && <Field validate={required} name="givenName" component={renderTextField} label="First Name" />}
          <div style={{minWidth:'500px'}}></div>
          {!passwordEdit && <Field validate={required} name="sn" component={renderTextField} label="Last Name" />}
          <div>
            <div className="MuiFormControl-root-223 MuiFormControl-fullWidth-226 CustomInput-formControl-219">
              {fullName && <label className="MuiFormLabel-root-551 MuiInputLabel-root-546 MuiInputLabel-formControl-547 MuiInputLabel-animated-550 MuiInputLabel-shrink-549 CustomInput-labelRoot-214 " data-shrink="true" for="mail" style={{fontSize:'12px'}}>Email</label>}
              <div className="MuiInput-root-227 MuiInput-formControl-228 MuiInput-underline-231 CustomInput-underline-211">
                <input aria-invalid="false" aria-required="false" className="MuiInput-input-235 CustomInput-input-221" type="text" value={fullName ? fullName : 'Email'} placeholder="Email" disabled style={{borderBottom:'1px solid #d2d2d2',color:'rgb(146, 146, 146)'}}/>
                </div>
              </div>
            </div>
          {buttonText !== 'Edit User' && (
            <div>
              <Field validate={required} name="password" component={renderPasswordField} label="Password" />
              <Field validate={required} name="password2" component={renderPasswordField} label="Re-type Password" />
            </div>
          )}
          {buttonText == 'Edit User' && passwordEdit && (
            <div>
              <Field validate={required} name="password" component={renderPasswordField} label="Password" />
              <Field validate={required} name="password2" component={renderPasswordField} label="Re-type Password" />
            </div>
          )}

          {!passwordEdit && <div><Field validate={required} name="country" component={renderSelectField} options={props.optionsObj.countryOptions} onChange={props.onSetActiveCountry} /></div>}

          {!passwordEdit && hasCountryValue && <Field validate={required} name="office" component={renderSelectField} options={props.optionsObj.officeOptions} onChange={props.onSetActiveOffice} />}
          {!passwordEdit && hasOfficeValue && <Field validate={required} name="department" component={renderSelectField} options={props.optionsObj.departmentOptions} onChange={props.onSetActiveDepartment} />}
          <Field  name="status" component={renderCheckbox} label="Active" classes={props.classes} />
      </DialogContent>
      <DialogActions
          className={classes.modalFooter + "  " + classes.modalFooterCenter}>
          {buttonText !== 'Edit User' ?
          <Button
              onClick={() =>
                {
                  props.reset()
                }
                }
          >
              Clear Form
        </Button> : ''}
          <Button
              color="danger"
              onClick={() =>
                {
                  props.reset()
                  props.closeModal()
                }
                }
          >
              Cancel
        </Button>
          <Button
              disabled={props.submitting}
              type="submit"
              color="success">
              {buttonText}
        </Button>
      </DialogActions>
  </form>
}

ADUserForm.propTypes = {
    onSetActiveCountry: PropTypes.func,
    onSetActiveOffice: PropTypes.func,
    onSetActiveDepartment: PropTypes.func,
    optionsObj: PropTypes.object
}

ADUserForm = reduxForm({
  // a unique name for the form
  form: 'adUser',
  enableReinitialize: true,
  validate
})(ADUserForm);
 // form is hooked up to redux-form
const mapDispatchToProps = dispatch => {
    return {
        onSetActiveCountry: event => dispatch(actions.setActiveCountry(event.target.value)),
        onSetActiveOffice: event => dispatch(actions.setActiveOffice(event.target.value)),
        onSetActiveDepartment: event => dispatch(actions.setActiveDepartment(event.target.value))
    }
}

const mapStateToProps = state => {
  let hasCountryValue = state.ad.activeCountry;
  let hasOfficeValue = state.ad.activeOffice;
  // quick fix only
  if (state.form.hasOwnProperty('adUser')) {
    const hasFirstname = state.form.adUser.values.givenName;
    const hasLastname = state.form.adUser.values.sn;
    return {
      hasCountryValue,
      hasOfficeValue,
      hasFirstname,
      hasLastname,
      initialValues: getActiveUser(state)
    }
  } else {
    hasCountryValue = null;
    hasOfficeValue = null;
    const hasFirstname = '';
    const hasLastname = '';
    return {
      hasCountryValue,
      hasOfficeValue,
      hasFirstname,
      hasLastname,
      initialValues: getActiveUser(state)
    }
  }
}

ADUserForm = connect(mapStateToProps, mapDispatchToProps)(ADUserForm)

export default withStyles(regularFormsStyle)(ADUserForm);
