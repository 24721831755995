import devStatus from 'shared/devStatus';

let base = 'http://localhost:5000';
switch(devStatus) {
  case 'live':
  case 'local_live':
    base = 'https://portal2.natcoglobal.com';
    break;
  case 'stage':
  case 'local_stage':
    base = 'https://staging.portal2.natcoglobal.com';
    break;
  default:
    base = 'https://staging.portal2.natcoglobal.com';
}

export default base;
