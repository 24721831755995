import * as actionTypes from './actionTypes';

export const fetchItemList = (itemcode, db) => {
  console.log('Fetching Item List...', itemcode);
    return {
        type: actionTypes.FETCH_ITEMLIST,
        itemcode,
        db
    }
}

export const fetchItemListStart = () => {
    return {
        type: actionTypes.FETCH_ITEMLIST_START,
    }
}

export const fetchItemListSuccess = (itemlist) => {
    return {
        type: actionTypes.FETCH_ITEMLIST_SUCCESS,
        itemlist
    }
}

export const fetchItemListFail = (error) => {
    return {
        type: actionTypes.FETCH_ITEMLIST_FAIL,
        error
    }
}

export const fetchItem = (vless, itemcode, companydb) => {
  console.log('fetching the item itself', vless, itemcode, companydb);
  return {
    type: actionTypes.FETCH_ITEM,
    vless,
    itemcode,
    companydb
  }
}

export const fetchItemStart = () => {
  return {
    type: actionTypes.FETCH_ITEM_START
  }
}

export const fetchItemSuccess = (iteminfo, companydb, lockStatus) => {
    return {
        type: actionTypes.FETCH_ITEM_SUCCESS,
        iteminfo,
        companydb,
        lockStatus
    }
}

export const fetchItemFail = (error, db, itemcode) => {
    return {
        type: actionTypes.FETCH_ITEM_FAIL,
        error,
        db,
        itemcode
    }
}

export const fetchItemCompare = () => {
  return {
    type: actionTypes.FETCH_ITEM_COMPARE
  }
}

export const fetchBrandSubgroupList = () => {
  return {
    type: actionTypes.FETCH_BRAND_SUBGROUPLIST
  }
}

export const fetchBrandSubgroupListStart = () => {
  return {
    type: actionTypes.FETCH_BRAND_SUBGROUPLIST_START
  }
}

export const fetchBrandSubgroupListSuccess = (data) => {
  return {
    type: actionTypes.FETCH_BRAND_SUBGROUPLIST_SUCCESS,
    data
  }
}

export const fetchBrandSubgroupListFail = (err) => {
  return {
    type: actionTypes.FETCH_BRAND_SUBGROUPLIST_FAIL,
    err
  }
}

export const fetchBPNameByCode = (cardcode, db) => {
  return {
    type: actionTypes.FETCH_BPNAMEBYCODE,
    cardcode,
    db
  }
}

export const fetchBPNameByCodeStart = () => {
  return {
    type: actionTypes.FETCH_BPNAMEBYCODE_START
  }
}

export const fetchBPNameByCodeSuccess = (result) => {
  return {
    type: actionTypes.FETCH_BPNAMEBYCODE_SUCCESS,
    result
  }
}

export const fetchBPNameByCodeFail = (err) => {
  return {
    type: actionTypes.FETCH_BPNAMEBYCODE_FAIL,
    err
  }
}

export const emptyItemList = () => {
  return {
    type: actionTypes.EMPTY_ITEM_LIST
  }
}

export const emptyItemByDB = (db) => {
  return {
    type: actionTypes.EMPTY_ITEM_BY_DB,
    db
  }
}

export const setAddNewGINItem = (db) => {
  return {
    type: actionTypes.SET_ADD_NEW_GIN_ITEM,
    db
  }
}

export const addNewGINItem = (data) => {
  return {
    type: actionTypes.ADD_NEW_GIN_ITEM,
    data
  }
}

export const addToOpenedDBsList = (db) => {
  return {
    type: actionTypes.ADD_TO_OPENEDDBS_LIST,
    db
  }
}

export const removeFromOpenedDBsList = (db) => {
  return {
    type: actionTypes.REMOVE_FROM_OPENEDDBS_LIST,
    db
  }
}

export const editModeOn = () => {
  return {
    type: actionTypes.EDIT_MODE_ON
  }
}

export const editModeOff = () => {
  return {
    type: actionTypes.EDIT_MODE_OFF
  }
}

export const setGINEdit = (data) => {
  return {
    type: actionTypes.SET_GIN_EDIT,
    data
  }
}

export const updateGINEdit = (data) => {
  return {
    type: actionTypes.UPDATE_GIN_EDIT,
    data
  }
}

export const updateGINMultiple = (data) => {
  return {
    type: actionTypes.UPDATE_GIN_MULTIPLE,
    data
  }
}

export const fetchGINOptions = (db) => {
  return {
    type: actionTypes.FETCH_GIN_OPTIONS,
    db
  }
}

export const fetchGINOptionsSuccess = (data, db) => {
  return {
    type: actionTypes.FETCH_GIN_OPTIONS_SUCCESS,
    data,
    db
  }
}

export const fetchGINOptionsFail = (err, db) => {
  return {
    type: actionTypes.FETCH_GIN_OPTIONS_FAIL,
    db,
    err
  }
}

export const fetchGINOptionsStart = (db) => {
  return {
    type: actionTypes.FETCH_GIN_OPTIONS_START,
    db
  }
}

export const editTableModeOn = () => {
  return {
    type: actionTypes.EDIT_TABLE_MODE_ON
  }
}

export const editTableModeOff = () => {
  return {
    type: actionTypes.EDIT_TABLE_MODE_OFF
  }
}

export const editTable2ModeOn = () => {
  return {
    type: actionTypes.EDIT_TABLE_TWO_MODE_ON
  }
}

export const editTable2ModeOff = () => {
  return {
    type: actionTypes.EDIT_TABLE_TWO_MODE_OFF
  }
}

export const setEditTable = (data) => {
  return {
    type: actionTypes.SET_EDIT_TABLE,
    data
  }
}

export const setEditTable2 = (data) => {
  return {
    type: actionTypes.SET_EDIT_TABLE_TWO,
    data
  }
}

export const updateEditTable = (data, mode) => {
  return {
    type: actionTypes.UPDATE_EDIT_TABLE,
    mode,
    data
  }
}

export const updateEditTable2 = (data, mode) => {
  return {
    type: actionTypes.UPDATE_EDIT_TABLE_TWO,
    mode,
    data
  }
}

export const fetchItemMOCs = (igc, db) => {
  return {
    type: actionTypes.FETCH_ITEM_MOCS,
    igc,
    db
  }
}

export const fetchItemMOCsStart = () => {
  return {
    type: actionTypes.FETCH_ITEM_MOCS_START
  }
}

export const fetchItemMOCsSuccess = (data) => {
  return {
    type: actionTypes.FETCH_ITEM_MOCS_SUCCESS,
    data
  }
}

export const fetchItemMOCsFail = (err) => {
  return {
    type: actionTypes.FETCH_ITEM_MOCS_FAIL,
    err
  }
}

export const fetchItemMOCsAll = (igc, dbs) => {
  return {
    type: actionTypes.FETCH_ITEM_MOCS_ALL,
    igc,
    dbs
  }
}

export const fetchItemMOCsAllStart = () => {
  return {
    type: actionTypes.FETCH_ITEM_MOCS_ALL_START
  }
}

export const fetchItemMOCsAllSuccess = (mocs) => {
  return {
    type: actionTypes.FETCH_ITEM_MOCS_ALL_SUCCESS,
    mocs
  }
}

export const fetchItemMOCsAllFail = (err) => {
  return {
    type: actionTypes.FETCH_ITEM_MOCS_ALL_FAIL,
    err
  }
}

export const setVariantStatus = (status) => {
  return {
    type: actionTypes.SET_VARIANT_STATUS,
    status
  }
}

export const setVariantsChecked = (checked) => {
  return {
    type: actionTypes.SET_VARIANTS_CHECKED,
    checked
  }
}

export const addVariant = (variant) => {
  return {
    type: actionTypes.ADD_VARIANT,
    variant
  }
}

export const addVariantEdit = (variant, index) => {
  return {
    type: actionTypes.ADD_VARIANT_EDIT,
    variant,
    index
  }
}

export const addVariantDelete = (index) => {
  return {
    type: actionTypes.ADD_VARIANT_DELETE,
    index
  }
}

export const ginSetImage = (img) => {
  return {
    type: actionTypes.GIN_SET_IMAGE,
    img
  }
}

export const ginSetCrop = (crop) => {
  return {
    type: actionTypes.GIN_SET_CROP,
    crop
  }
}

export const ginSetFinalCrop = (crop) => {
  return {
    type: actionTypes.GIN_SET_FINALCROP,
    crop
  }
}

export const ginSetImageExt = (imgExt) => {
  return {
    type: actionTypes.GIN_SET_IMAGE_EXT,
    imgExt
  }
}

export const ginSendImage = (image, ginnum) => {
  return {
    type: actionTypes.GIN_SEND_IMAGE,
    image, ginnum
  }
}

export const ginSendImageStart = () => {
  return {
    type: actionTypes.GIN_SEND_IMAGE_START
  }
}

export const ginSendImageSuccess = (response) => {
  return {
    type: actionTypes.GIN_SEND_IMAGE_SUCCESS,
    response
  }
}

export const ginSendImageFail = (err) => {
  return {
    type: actionTypes.GIN_SEND_IMAGE_FAIL,
    err
  }
}

export const ginSetTableCount = (tableCount) => {
  return {
    type: actionTypes.GIN_SET_TABLE_COUNT,
    tableCount
  }
}

export const ginSendItemNoStart = (itemObj) => {
  return {
    type: actionTypes.GIN_SEND_ITEM_NOSTART,
    itemObj
  }
}

export const ginSendItemNoStartSuccess = (response) => {
  return {
    type: actionTypes.GIN_SEND_ITEM_NOSTART_SUCCESS,
    response
  }
}

export const ginSendItemNoStartFail = (err) => {
  return {
    type: actionTypes.GIN_SEND_ITEM_NOSTART_FAIL,
    err
  }
}

export const ginSendItemNoStartSet = (itemSentManually) => {
  return {
    type: actionTypes.GIN_SEND_ITEM_NOSTART_SET,
    itemSentManually
  }
}

export const ginSendItem = (itemObj, deactivateList, ginnum, emailData, removeBOMlist, removeCostlist, removeGINlist, removePricelist, reactivateList, submitter_id, unique_history_id, lockData) => {
  return {
    type: actionTypes.GIN_SEND_ITEM,
    itemObj,
    deactivateList,
    reactivateList,
    removeBOMlist,
    removeCostlist,
    removeGINlist,
    removePricelist,
    ginnum,
    emailData,
    submitter_id, // will be populated if coming from pending
    unique_history_id,
    lockData
  }
}

export const ginSendItemStart = (tableCount,db,orig_db) => {
  return {
    type: actionTypes.GIN_SEND_ITEM_START,
    tableCount,
    db,
    orig_db
  }
}

export const ginSendItemSuccess = (response, db) => {
  return {
    type: actionTypes.GIN_SEND_ITEM_SUCCESS,
    response,
    db
  }
}

export const ginSendItemFail = (err, db) => {
  return {
    type: actionTypes.GIN_SEND_ITEM_FAIL,
    err,
    db
  }
}

export const ginNewItemAdd = (db) => {
  return {
    type: actionTypes.GIN_NEW_ITEM_ADD,
    db
  }
}

export const ginNewItemRemove = (db) => {
  return {
    type: actionTypes.GIN_NEW_ITEM_REMOVE,
    db
  }
}

export const ginSetGMP = (gmp) => {
  return {
    type: actionTypes.GIN_SET_GMP,
    gmp
  }
}

export const ginSetGINNum = (ginnum) => {
  return {
    type: actionTypes.GIN_SET_GINNUM,
    ginnum
  }
}

export const ginCheckExists = (ginnum) => {
  return {
    type: actionTypes.GIN_CHECK_EXISTS,
    ginnum
  }
}

export const ginCheckExistsStart = () => {
  return {
    type: actionTypes.GIN_CHECK_EXISTS_START
  }
}

export const ginCheckExistsSuccess = (count) => {
  return {
    type: actionTypes.GIN_CHECK_EXISTS_SUCCESS,
    count
  }
}

export const ginCheckExistsFail = (err) => {
  return {
    type: actionTypes.GIN_CHECK_EXISTS_FAIL,
    err
  }
}

export const setAllGIN = (dataObj) => {
  return {
    type: actionTypes.SET_ALL_GIN,
    dataObj
  }
}

export const cancelLoading = () => {
  return {
    type: actionTypes.GIN_CANCEL_LOADING
  }
}

export const ginStartImporter = () => {
  return {
    type: actionTypes.GIN_START_IMPORTER
  }
}

export const ginStartImporterSuccess = (response) => {
  return {
    type: actionTypes.GIN_START_IMPORTER_SUCCESS,
    response
  }
}

export const ginStartImporterFail = (err) => {
  return {
    type: actionTypes.GIN_START_IMPORTER_FAIL
  }
}

export const ginStartImporterStart = () => {
  return {
    type: actionTypes.GIN_START_IMPORTER_START
  }
}

export const ginFinishImporting = () => {
  return {
    type: actionTypes.GIN_FINISH_IMPORTING
  }
}

export const ginRefresh = () => {
  return {
    type: actionTypes.GIN_REFRESH
  }
}

export const clearGINmessage = () => {
  return {
    type: actionTypes.CLEAR_GIN_MESSAGE
  }
}

export const ginSetErrorMessage = (content) => {
  return {
    type: actionTypes.GIN_SET_ERROR_MESSAGE
  }
}

export const ginSetDonePickValues = (bool) => {
  return {
    type: actionTypes.GIN_SET_DONE_PICK_VALUES,
    bool
  }
}

export const ginCheckImporterTable = () => {
  return {
    type: actionTypes.GIN_CHECK_IMPORTER_TABLE,
  }
}

export const ginCheckImporterTableSuccess = (count) => {
  return {
    type: actionTypes.GIN_CHECK_IMPORTER_TABLE_SUCCESS,
    count
  }
}

export const ginCheckImporterTableFail = (err) => {
  return {
    type: actionTypes.GIN_CHECK_IMPORTER_TABLE_FAIL,
    err
  }
}

export const ginSaveFailedImport = (db, post, status) => {
  return {
    type: actionTypes.GIN_SAVE_FAILED_IMPORT,
    db,
    post,
    status
  }
}

export const ginSaveFailedImportSuccess = () => {
  return {
    type: actionTypes.GIN_SAVE_FAILED_IMPORT_SUCCESS
  }
}

export const ginSaveFailedImportFail = (err) => {
  return {
    type: actionTypes.GIN_SAVE_FAILED_IMPORT_FAIL,
    err
  }
}

export const ginGetFailedImport = () => {
  return {
    type: actionTypes.GIN_GET_FAILED_IMPORT
  }
}

export const ginGetFailedImportSuccess = (data) =>  {
  return {
    type: actionTypes.GIN_GET_FAILED_IMPORT_SUCCESS,
    data
  }
}

export const ginGetFailedImportFail = (err) =>  {
  return {
    type: actionTypes.GIN_GET_FAILED_IMPORT_FAIL,
    err
  }
}

export const ginCheckTable = () => {
  return {
    type: actionTypes.GIN_CHECK_TABLE
  }
}

export const ginSetFailedImport = (status, id) => {
  return {
    type: actionTypes.GIN_SET_FAILED_IMPORT,
    status,
    id
  }
}

export const ginSetFailedImportSuccess = (result) => {
  return {
    type: actionTypes.GIN_SET_FAILED_IMPORT_SUCCESS,
    result
  }
}

export const ginSetFailedImportFail = (err) => {
  return {
    type: actionTypes.GIN_SET_FAILED_IMPORT_SUCCESS,
    err
  }
}

export const ginStartQ = (item_ids) => {
  return {
    type: actionTypes.GIN_START_Q,
    item_ids
  }
}

export const ginStartQSuccess = (response) => {
  return {
    type: actionTypes.GIN_START_Q_SUCCESS,
    response
  }
}

export const ginStartQFail = (err) => {
  return {
    type: actionTypes.GIN_START_Q_FAIL,
    err
  }
}

export const ginDeactivate = (deactivateList) => {
  return {
    type: actionTypes.GIN_DEACTIVATE,
    deactivateList
  }
}

export const ginDeactivateStart = () => {
  return {
    type: actionTypes.GIN_DEACTIVATE_START
  }
}

export const ginDeactivateSuccess = (response) => {
  return {
    type: actionTypes.GIN_DEACTIVATE_SUCCESS,
    response
  }
}

export const ginDeactivateFail = (err) => {
  return {
    type: actionTypes.GIN_DEACTIVATE_FAIL,
    err
  }
}

export const ginReactivate = (reactivateList) => {
  return {
    type: actionTypes.GIN_REACTIVATE,
    reactivateList
  }
}

export const ginReactivateStart = () => {
  return {
    type: actionTypes.GIN_REACTIVATE_START
  }
}

export const ginReactivateSuccess = (response) => {
  return {
    type: actionTypes.GIN_REACTIVATE_SUCCESS,
    response
  }
}

export const ginReactivateFail = (err) => {
  return {
    type: actionTypes.GIN_REACTIVATE_FAIL,
    err
  }
}

export const ginAutoSave = (data, id, gin) => {
  return {
    type: actionTypes.GIN_AUTOSAVE,
    data,
    id,
    gin
  }
}

export const ginAutoSaveStart = () => {
  return {
    type: actionTypes.GIN_AUTOSAVE_START
  }
}

export const ginAutoSaveSuccess = (response) => {
  return {
    type: actionTypes.GIN_AUTOSAVE_SUCCESS,
    response
  }
}

export const ginAutoSaveFail = (err) => {
  return {
    type: actionTypes.GIN_AUTOSAVE_FAIL,
    err
  }
}

export const ginAutoSaveLoad = (id, origin) => {
  return {
    type: actionTypes.GIN_AUTOSAVE_LOAD,
    id,
    origin
  }
}

export const ginAutoSaveLoadStart = () => {
  return {
    type: actionTypes.GIN_AUTOSAVE_LOAD_START
  }
}

export const ginAutoSaveLoadEnd = () => {
  return {
    type: actionTypes.GIN_AUTOSAVE_LOAD_END
  }
}

export const ginAutoSaveLoadSuccess = (data, id) => {
  return {
    type: actionTypes.GIN_AUTOSAVE_LOAD_SUCCESS,
    data,
    id
  }
}

export const ginAutoSaveLoadFail = (err) => {
  return {
    type: actionTypes.GIN_AUTOSAVE_LOAD_FAIL,
    err
  }
}

export const ginAutoSaveLoadAll = () => {
  return {
    type: actionTypes.GIN_AUTOSAVE_LOAD_ALL
  }
}

export const ginAutoSaveLoadAllStart = () => {
  return {
    type: actionTypes.GIN_AUTOSAVE_LOAD_ALL_START
  }
}

export const ginAutoSaveLoadAllSuccess = (data) => {
  return {
    type: actionTypes.GIN_AUTOSAVE_LOAD_ALL_SUCCESS,
    data
  }
}

export const ginAutoSaveLoadAllFail = (err) => {
  return {
    type: actionTypes.GIN_AUTOSAVE_LOAD_ALL_FAIL,
    err
  }
}

export const ginGatherAutoSaveData = () => {
  return {
    type: actionTypes.GIN_GATHER_AUTOSAVE_DATA
  }
}

export const ginAutoSaveImage = (image, filename) => {
  return {
    type: actionTypes.GIN_AUTOSAVE_IMAGE,
    image, filename
  }
}

export const ginAutoSaveDelete = (id) => {
  return {
    type: actionTypes.GIN_AUTOSAVE_DELETE,
    id
  }
}

export const ginAutoSaveDeleteSuccess = (response) => {
  return {
    type: actionTypes.GIN_AUTOSAVE_DELETE_SUCCESS,
    response
  }
}

export const ginAutoSaveDeleteFail = (err) => {
  return {
    type: actionTypes.GIN_AUTOSAVE_DELETE_FAIL,
    err
  }
}

export const ginLoadImage = (filename) => {
  return {
    type: actionTypes.GIN_LOAD_IMAGE,
    filename
  }
}

export const ginLoadImageSuccess = (response) => {
  return {
    type: actionTypes.GIN_LOAD_IMAGE_SUCCESS,
    response
  }
}

export const ginNewValidateEntry = () => {
  return {
    type: actionTypes.GIN_NEW_VALIDATE_ENTRY
  }
}

export const ginNewValidateEntrySuccess = (response) => {
  return {
    type: actionTypes.GIN_NEW_VALIDATE_ENTRY,
    response
  }
}

export const ginRemoveBOM = (list) => {
  return {
    type: actionTypes.GIN_REMOVE_BOM,
    list
  }
}

export const ginRemoveBOMStart = () => {
  return {
    type: actionTypes.GIN_REMOVE_BOM_START
  }
}

export const ginRemoveBOMSuccess = (response) => {
  return {
    type: actionTypes.GIN_REMOVE_BOM_SUCCESS,
    response
  }
}

export const ginRemoveBOMFail = (err) => {
  return {
    type: actionTypes.GIN_REMOVE_BOM_FAIL,
    err
  }
}

export const ginRemoveCost = (list) => {
  return {
    type: actionTypes.GIN_REMOVE_COST,
    list
  }
}

export const ginRemoveCostStart = () => {
  return {
    type: actionTypes.GIN_REMOVE_COST_START
  }
}

export const ginRemoveCostSuccess = (response) => {
  return {
    type: actionTypes.GIN_REMOVE_COST_SUCCESS,
    response
  }
}

export const ginRemoveCostFail = (err) => {
  return {
    type: actionTypes.GIN_REMOVE_COST_FAIL,
    err
  }
}

export const ginGetExisting = (gincode, itemcode) => {
  return {
    type: actionTypes.GIN_GET_EXISTING,
    gincode,
    itemcode
  }
}

export const ginGetExistingStart = () => {
  return {
    type: actionTypes.GIN_GET_EXISTING_START
  }
}

export const ginGetExistingSuccess = (result) => {
  return {
    type: actionTypes.GIN_GET_EXISTING_SUCCESS,
    result
  }
}

export const ginGetExistingFail = (err) => {
  return {
    type: actionTypes.GIN_GET_EXISTING_FAIL,
    err
  }
}

export const ginLoadExisting = (data) => {
  return {
    type: actionTypes.GIN_LOAD_EXISTING,
    data
  }
}

export const ginLoadExistingStart = () => {
  return {
    type: actionTypes.GIN_LOAD_EXISTING_START
  }
}

export const ginLoadExistingSuccess = (gmp, existingGINsBefore) => {
  return {
    type: actionTypes.GIN_LOAD_EXISTING_SUCCESS,
    gmp,
    existingGINsBefore
  }
}

export const ginLoadExistingFail = (err) => {
  return {
    type: actionTypes.GIN_LOAD_EXISTING_FAIL,
    err
  }
}

export const setGINloading = (bool) => {
  return {
    type: actionTypes.SET_GIN_LOADING,
    bool
  }
}

export const loadExistingImage = (imageURL) => {
  return {
    type: actionTypes.LOAD_EXISTING_IMAGE,
    imageURL
  }
}

export const loadExistingImageStart = () => {
  return {
    type: actionTypes.LOAD_EXISTING_IMAGE_START
  }
}

export const loadExistingImageSuccess = (data) => {
  return {
    type: actionTypes.LOAD_EXISTING_IMAGE_SUCCESS,
    data
  }
}

export const loadExistingImageFail = (err) => {
  return {
    type: actionTypes.LOAD_EXISTING_IMAGE_FAIL,
    err
  }
}

export const loadPreviewData = (data, rbos) => {
  return {
    type: actionTypes.LOAD_PREVIEW_DATA,
    data,
    rbos
  }
}

export const loadPreviewDataStart = () => {
  return {
    type: actionTypes.LOAD_PREVIEW_DATA_START
  }
}

export const loadPreviewDataSuccess = (data, rbos) => {
  return {
    type: actionTypes.LOAD_PREVIEW_DATA_SUCCESS,
    data,
    rbos
  }
}

export const loadPreviewDataFail = (err) => {
  return {
    type: actionTypes.LOAD_PREVIEW_DATA_FAIL,
    err
  }
}

export const removeGIN = (removeData) => {
  return {
    type: actionTypes.REMOVE_GIN,
    removeData
  }
}

export const removeGINSuccess = (response) => {
  return {
    type: actionTypes.REMOVE_GIN_SUCCESS,
    response
  }
}

export const removeGINFail = (err) => {
  return {
    type: actionTypes.REMOVE_GIN_FAIL,
    err
  }
}

export const setLoadingGIN = (db) => {
  return {
    type: actionTypes.SET_LOADING_GIN,
    db
  }
}

export const setExistingImageURL = (imageURL) => {
  return {
    type: actionTypes.SET_EXISTING_IMAGE_URL,
    imageURL
  }
}

export const ginRenameImage = (oldName, newName) => {
  return {
    type: actionTypes.GIN_RENAME_IMAGE,
    oldName,
    newName
  }
}

export const ginRenameImageStart = () => {
  return {
    type: actionTypes.GIN_RENAME_IMAGE_START
  }
}

export const ginRenameImageSuccess = (response) => {
  return {
    type: actionTypes.GIN_RENAME_IMAGE_SUCCESS,
    response
  }
}

export const ginRenameImageFail = (err) => {
  return {
    type: actionTypes.GIN_RENAME_IMAGE_FAIL,
    err
  }
}

export const ginSavePending = (autosave_id, gin, ginType, subject, msg, lockData) => {
  console.log('ginsavingpending');
  return {
    type: actionTypes.GIN_SAVE_PENDING,
    autosave_id,
    gin,
    ginType,
    subject,
    msg,
    lockData
  }
}

export const ginSavePendingStart = () => {
  return {
    type: actionTypes.GIN_SAVE_PENDING_START,
  }
}

export const ginSavePendingSuccess = (response) => {
  return {
    type: actionTypes.GIN_SAVE_PENDING_SUCCESS,
    response
  }
}

export const ginSavePendingFail = (err) => {
  return {
    type: actionTypes.GIN_SAVE_PENDING_FAIL,
    err
  }
}

export const ginLoadPending = () => {
  return {
    type: actionTypes.GIN_LOAD_PENDING
  }
}

export const ginLoadPendingStart = () => {
  return {
    type: actionTypes.GIN_LOAD_PENDING_START
  }
}

export const ginLoadPendingSuccess = (data) => {
  return {
    type: actionTypes.GIN_LOAD_PENDING_SUCCESS,
    data
  }
}

export const ginLoadPendingFail = (err) => {
  return {
    type: actionTypes.GIN_LOAD_PENDING_FAIL,
    err
  }
}

export const ginSetType = (ginType) => {
  return {
    type: actionTypes.GIN_SET_TYPE,
    ginType
  }
}

export const ginDenyPending = (id, itemCodes) => {
  return {
    type: actionTypes.GIN_DENY_PENDING,
    id,
    itemCodes
  }
}

export const ginDenyPendingSuccess = () => {
  return {
    type: actionTypes.GIN_DENY_PENDING_SUCCESS
  }
}

export const ginDenyPendingFail = (err) => {
  return {
    type: actionTypes.GIN_DENY_PENDING_FAIL,
    err
  }
}

export const ginApprovePending = (id) => {
  return {
    type: actionTypes.GIN_APPROVE_PENDING,
    id
  }
}

export const ginApprovePendingSuccess = () => {
  return {
    type: actionTypes.GIN_APPROVE_PENDING_SUCCESS
  }
}

export const ginApprovePendingFail = (err) => {
  return {
    type: actionTypes.GIN_APPROVE_PENDING_FAIL,
    err
  }
}

export const ginCheckPendingStart = () => {
  return {
    type: actionTypes.GIN_CHECK_PENDING_START
  }
}

export const ginCheckPendingSuccess = (count) => {
  return {
    type: actionTypes.GIN_CHECK_PENDING_SUCCESS,
    count
  }
}

export const ginCheckPendingFail = (err) => {
  return {
    type: actionTypes.GIN_CHECK_PENDING_FAIL,
    err
  }
}

export const ginCheckPending = (gin) => {
  return {
    type: actionTypes.GIN_CHECK_PENDING,
    gin
  }
}

export const ginSaveHistory = (data) => {
  return {
    type: actionTypes.GIN_SAVE_HISTORY,
    data
  }
}

export const ginSaveHistoryStart = () => {
  return {
    type: actionTypes.GIN_SAVE_HISTORY_START
  }
}

export const ginSaveHistorySuccess = (response) => {
  return {
    type: actionTypes.GIN_SAVE_HISTORY_SUCCESS,
    response
  }
}

export const ginSaveHistoryFail = (err) => {
  return {
    type: actionTypes.GIN_SAVE_HISTORY_FAIL,
    err
  }
}

export const ginLoadHistory = () => {
  return {
    type: actionTypes.GIN_LOAD_HISTORY
  }
}

export const ginLoadHistoryStart = () => {
  return {
    type: actionTypes.GIN_LOAD_HISTORY_START
  }
}

export const ginLoadHistorySuccess = (response) => {
  return {
    type: actionTypes.GIN_LOAD_HISTORY_SUCCESS,
    response
  }
}

export const ginLoadHistoryFail = (err) => {
  return {
    type: actionTypes.GIN_LOAD_HISTORY_FAIL,
    err
  }
}

export const ginFilterHistoryDate = (startDate, endDate) => {
  return {
    type: actionTypes.GIN_FILTER_HISTORY_DATE,
    startDate,
    endDate
  }
}

export const ginLoadHistoryEntry = (id) => {
  return {
    type: actionTypes.GIN_LOAD_HISTORY_ENTRY,
    id
  }
}

export const ginLoadHistoryEntryStart = () => {
  return {
    type: actionTypes.GIN_LOAD_HISTORY_ENTRY_START
  }
}

export const ginLoadHistoryEntrySuccess = (data) => {
  return {
    type: actionTypes.GIN_LOAD_HISTORY_ENTRY_SUCCESS,
    data
  }
}

export const ginLoadHistoryEntryFail = (err) => {
  return {
    type: actionTypes.GIN_LOAD_HISTORY_ENTRY_FAIL,
    err
  }
}

export const saveChosenGINDetails = (details) => {
  return {
    type: actionTypes.SAVE_CHOSEN_GIN_DETAILS,
    details
  }
}

export const getItemLocks = () => {
  return {
    type: actionTypes.GET_ITEM_LOCKS
  }
}

export const getItemLocksStart = () => {
  return {
    type: actionTypes.GET_ITEM_LOCKS_START
  }
}

export const getItemLocksSuccess = (response) => {
  return {
    type: actionTypes.GET_ITEM_LOCKS_SUCCESS,
    response
  }
}

export const getItemLocksFail = (err) => {
  return {
    type: actionTypes.GET_ITEM_LOCKS_FAIL,
    err
  }
}

export const checkItemLock = (db, itemcode) => {
  return {
    type: actionTypes.CHECK_ITEM_LOCK,
    db, itemcode
  }
}

export const checkItemLockStart = () => {
  return {
    type: actionTypes.CHECK_ITEM_LOCK_START
  }
}

export const checkItemLockSuccess = (response) => {
  return {
    type: actionTypes.CHECK_ITEM_LOCK_SUCCESS,
    response
  }
}

export const checkItemLockFail = (err) => {
  return {
    type: actionTypes.CHECK_ITEM_LOCK_FAIL,
    err
  }
}

export const updateItemLock = (db, itemcode, action) => {
  return {
    type: actionTypes.UPDATE_ITEM_LOCK,
    db, itemcode, action
  }
}

export const updateItemsLock = (arr, pending) => {
  return {
    type: actionTypes.UPDATE_ITEMS_LOCK,
    pending,
    arr
  }
}

export const updateItemLockStart = () => {
  return {
    type: actionTypes.UPDATE_ITEM_LOCK_START
  }
}

export const updateItemLockSuccess = (response) => {
  return {
    type: actionTypes.UPDATE_ITEM_LOCK_SUCCESS,
    response
  }
}

export const updateItemLockFail = (err) => {
  return {
    type: actionTypes.UPDATE_ITEM_LOCK_FAIL,
    err
  }
}

export const checkItemsLock = (arr) => {
  return {
    type: actionTypes.CHECK_ITEMS_LOCK,
    arr
  }
}

export const checkItemsLockStart = () => {
  return {
    type: actionTypes.CHECK_ITEMS_LOCK_START
  }
}

export const checkItemsLockSuccess = (arr) => {
  return {
    type: actionTypes.CHECK_ITEMS_LOCK_SUCCESS,
    arr
  }
}

export const checkItemsLockFail = (err) => {
  return {
    type: actionTypes.CHECK_ITEMS_LOCK_FAIL,
    err
  }
}

export const releaseUserLockedGIN = () => {
  return {
    type: actionTypes.RELEASE_USER_LOCKED_GIN
  }
}

export const setTPPItemType = (tpp_item_type) => {
  return {
    type: actionTypes.SET_TPP_ITEM_TYPE,
    tpp_item_type
  }
}
