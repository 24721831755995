import React, { Component } from "react";
import CustomInput from "components/CustomInput/CustomInput2.jsx";

const InputField = (props) => {
  const { classes } = props;
  let error = '';
  if (props.validate) {
    if (props.validate.length > 0) {
      props.validate.forEach((val) => {
        const err = val(props.value);
        if (err) {
          error = err;
        }
      });
    }
  }

  return (
  <div>
    <CustomInput
      labelText={props.label}
      id={props.name}
      formControlProps={{
        fullWidth: true
      }}
      inputProps={{
        onChange: (e) => {
        },
        onClick: () => { if (props.onClick) props.onClick(); },
        value: props.value ? props.dict[props.value] ? props.dict[props.value] : '' : '',
        autoFocus: false,
        role: 'presentation',
        autoComplete: 'nope',
        disabled: props.disabled ? true : false
      }}
      error={error ? true : false}
    />
    {error && <div className="red" style={{marginTop:'10px',fontSize:'10px'}}>{error}</div>}
    {!error && <div className="red" style={{marginTop:'10px',opacity:0,fontSize:'10px'}}>_</div>}
    {(props.similar && !error) ? props.similar : null}
    </div>
  );
}

export default InputField;
