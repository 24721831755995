import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import * as actions from '../../store/actions';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import ReactTable from "react-table";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import styles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import cloneDeep from "lodash/cloneDeep";

import Button from '@material-ui/core/Button';
import Button2 from "components/CustomButtons/Button.jsx";
import { PulseLoader } from 'react-spinners';

class GINqtyAlertPriceCustomers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: this.props.duplicates,
      selected: [],
      toBeDeleted: {},
      validationMessage: '',
      loading: false
    }
  }

  handleUpdate = (toBeDeleted, combo) => {
    console.log('updating for deletion', toBeDeleted, combo);
    const split = combo.split('(.)'),
          db = split[0],
          customer = split[1];

    let copy = cloneDeep(this.props.bmasterarray);
    copy[db][customer] = copy[db][customer].filter((cs, i) => {
      if (toBeDeleted.indexOf(i) !== -1) {
        return false;
      }
      return true;
    });
    console.log('updating for deletion - ', copy[db])
    this.props.updateGINEdit({
      db,
      field: 'bmasterarray',
      value: copy[db]
    });
    if (this.props.close) {
      this.props.close();
    }
  }

  handleSave = () => {
    this.setState({
      loading:true
    });
    // check if all have at least one
    console.log('dupes', this.props.duplicates);
    console.log('selected', this.state.selected);

    let selectedComplete = true;
    Object.keys(this.props.duplicates).forEach(customer => {
      if (this.props.duplicates[customer].length > 0) {
        this.props.duplicates[customer].forEach((dupe) => {
          const db = dupe.DB,
                range = dupe.range,
                currency = dupe.obj[0].currency;
          let index = null;
          this.state.selected.forEach((sel) => {
            if (sel.db === db && (sel.range === range && sel.currency === currency)) {
              index = sel.index;
            }
          });
          if (index === null) {
            selectedComplete = false;
          }
        });
      }
    });
    console.log('selectedComplete', selectedComplete);
    if (!selectedComplete) {
      this.setState({
        validationMessage: 'Please make sure all listed Ranges have at least one Price',
        loading: false
      });
      return;
    }

    // collect all indexes for each DB/customer
    let collection = {};
    // find array of indexes and get to be deleted indexes
    Object.keys(this.state.data).forEach(customer => {
      if (this.state.data[customer].length > 0) {
        collection[customer] = {};
        this.state.data[customer].forEach((dat) => {
          const db = dat.DB;
          dat.obj.forEach((dob) => {
            if (collection[customer].hasOwnProperty(db)) {
              collection[customer][db].push(dob.indx);
            } else {
              collection[customer][db] = [dob.indx];
            }
          });
        });
      }
    });

    let selecteds = {};
    this.state.selected.forEach((n) => {
      console.log(n);
      const db = n.db,
            range = n.range,
            currency = n.currency,
            customer = n.customer,
            combo = `${db}(.)${customer}`,
            indexSelect = n.index;

      if (selecteds.hasOwnProperty(combo)) {
        selecteds[combo].push(n.index);
      } else {
        selecteds[combo] = [n.index];
      }
    });

    console.log('collection is', collection);
    console.log('selecteds is', selecteds);

    let toBeDeleted = {};
    Object.keys(collection).forEach((customer) => {
      Object.keys(collection[customer]).forEach(db => {
        const combo = `${db}(.)${customer}`;
        toBeDeleted[combo] = collection[customer][db].filter((ind) => {
          if (selecteds[combo].indexOf(ind) === -1 && ind !== -1) {
            return true;
          } else {
            return false;
          }
        });
      });
    });

    console.log('these are the dynamic indexes to be deleted', toBeDeleted);

    Object.keys(toBeDeleted).forEach((combo) => {
      this.handleUpdate(toBeDeleted[combo], combo, 'multiple');
    });

    // transfer
    this.setState({
      loading: false
    })
  }

  handleClick = (event, n) => {
    let selected = [ ...this.state.selected ];
    const checkIfSelected = this.checkIfSelected(n);
    if (!checkIfSelected) {
      const copySelected = selected.filter((ss) => {
        if (ss.table === n.table) {
          return false;
        }
        return true;
      });
      const selectedNow = [ ...copySelected, {
        range: n.range,
        db: n.db,
        index: n.index,
        currency: n.currency,
        table: n.table,
        jj: n.jj,
        customer: n.customer
      }];
      this.setState({
        selected: selectedNow
      });
    } else {
      const deselected = selected.filter((ss) => {
        if (ss.table === n.table) {
          return false;
        }
        return true;
      });
      this.setState({
        selected: deselected
      });
    }
  }

  checkIfSelected = (n) => {
    const range = n.range,
          db = n.db,
          currency = n.currency,
          jj = n.jj,
          index = n.index;
    let selected = false;
    this.state.selected.forEach((ss) => {
      if ((ss.table === n.table && ss.jj === n.jj)) {
        selected = true;
      }
    });
    return selected;
  }

  renderTables = (customer) => {
    const { classes } = this.props;
    if (this.state.data[customer].length > 0) {
      console.log('this is the crux of the data', this.state.data, this.state.selected);
      return this.state.data[customer].map((dupe, i) => {
        let dupesData = [];
        let currency;
        dupe.obj.forEach((d) => {
          currency = d.currency;
          const totall = parseFloat(d.price) * parseInt(d.qty);
          dupesData.push({
            range: dupe.range,
            index: d.indx,
            db: dupe.DB,
            qty: d.qty,
            term: d.term,
            price: `${d.currency} ${parseFloat(d.price).toLocaleString()}`,
            effective_dates: `${d.start} TO ${d.end}`,
            currency: d.currency,
            total: `${d.currency} ${totall.toLocaleString()}`,
            table: i,
            customer
          });
        });
        return (
        <div key={`tableqty${i}`} style={{paddingBottom:'30px'}}>
          <h3 className="text-center">{customer ? customer.split('[.]')[1] : ''}</h3>
          <h4>Item {dupe.itemName} from <strong>{dupe.DB}</strong>, <strong>{dupe.range}</strong> Range for <strong>{currency}</strong></h4>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                </TableCell>
                <TableCell align="right">Qty</TableCell>
                <TableCell align="right">Term</TableCell>
                <TableCell align="right">Price</TableCell>
                <TableCell align="right">Effective Dates</TableCell>
                <TableCell align="right">Currency</TableCell>
                <TableCell align="right">Total Price</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dupesData.map((n, jj) => {
                  const isSelected = this.checkIfSelected({ ...n, jj });
                  return (
                    <TableRow
                      hover
                      onClick={event => this.handleClick(event, { ...n, jj })}
                      role="checkbox"
                      tabIndex={-1}
                      key={`trr${n.index}`}
                      selected={isSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          tabIndex={-1}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{checked: classes.checked}}
                          checked={isSelected}
                        />
                      </TableCell>
                      <TableCell align="right">{n.qty}</TableCell>
                      <TableCell align="right">{n.term}</TableCell>
                      <TableCell align="right">{n.price}</TableCell>
                      <TableCell align="right">{n.effective_dates}</TableCell>
                      <TableCell align="right">{n.currency}</TableCell>
                      <TableCell align="right">{n.total}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </div>
        )
      });
    }
  }

  render() {
    const { classes } = this.props;
    console.log('ginqtyalertprice st and props', this.state, this.props);
    const allTables = Object.keys(this.props.duplicates).map((customer,i) => {
      return <div key={`${i}o`}>
        {this.renderTables(customer)}
        </div>
    });

    return (
      <Dialog
        open={this.props.open}
        keepMounted
        onClose={this.handleClose}
        maxWidth="md"
        fullWidth={true}
      >
        <DialogTitle>
          <div style={{textAlign:'center',fontSize:18}}>Only 1 Quantity for each Price Range/Currency/Dates/Terms combination is allowed.</div>
          {/*this.props.close &&
        <div className="float-right">
          <IconButton aria-label="Close" onClick={this.props.close}>
            <Close />
          </IconButton></div>*/}
        </DialogTitle>
        <div style={{padding:'0 40px 20px 40px'}}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div style={{textAlign:'center'}}>Please select the Quantity you want to Keep</div>
              {allTables}
              {this.state.loading && <PulseLoader />}
              <div style={{padding:'20px'}}>
                <Button2
                  color="success"
                  onClick={this.handleSave}
                >
                  SAVE
                </Button2>
                {this.state.validationMessage && <div className="red-text text-center">{this.state.validationMessage}</div>}
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    bmasterarray: state.gin.bmasterarray,
    itemCodes: state.gin.itemCodes,
    openedDBs: state.gin.openedDBs,
    options: state.gin.options,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateGINEdit: (data) => {
      dispatch(actions.updateGINEdit(data));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GINqtyAlertPriceCustomers));
