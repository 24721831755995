import React from "react";
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import checkPermissions from '../../shared/checkPermissions';
import checkPermissionsActions from '../../shared/checkPermissionsActions';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Cached from "@material-ui/icons/Cached";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import CustomMultipleSelect from "components/CustomSelect/CustomMultipleSelect";
import Button from "components/CustomButtons/Button.jsx";
import { FadeLoader } from 'react-spinners';
import ReactTable from "react-table";
import RFIDdateModal from "containers/RFID/RFIDdateModal";
import RFIDEPCModal from "containers/RFID/RFIDEPCModal";
import RFIDdownloadPopup from "containers/RFID/RFIDdownloadPopup";
import Tooltip from "@material-ui/core/Tooltip";
import moment from "moment";
import { logic, decConvert } from "containers/RFID/RFIDlogic";
import { generateExcel2, commatize } from "shared/utility";
import { dropdownRequired, no_prefix_companies } from "containers/RFID/RFIDobj";
import download from 'downloadjs';
import { domain } from 'shared/devStatus';

const dateNow = () => {
  var d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

const DownloadPopup = props => {
  const filesArr = props.files.split(',');
  const files = filesArr.map((file,i) => {
    if (props.downloads.indexOf(file) !== -1) return null;
    return <div style={{marginBottom:10}} key={`k-${i}`} onClick={() => {
      props.addDownloads(file);
    }}><a href={`${domain}/assets/${file}`} download>{file}</a></div>
  });
  return (
    <Dialog
      onClose={props.close}
      open={props.open}
      keepMounted
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle style={{paddingBottom: 0}} onClose={() => props.close()}>
        Multiple File Downloads
      </DialogTitle>

      <DialogContent style={{overflowY:'unset'}}>
        <div style={{margin:20}}>
          {props.downloads.length !== filesArr.length && <p>Click on a file to download</p>}
          {props.downloads.length === filesArr.length && <p>No more files to download</p>}
          {files}
          <Button
            color="primary"
            onClick={() => {
              props.close();
            }}
          >CLOSE</Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

class RFIDHistory extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      epcFilterModal: false,
      epcFilter: null,
      upcFilter: null,
      serialFilter: null,
      dateFilterModal: false,
      dateFilterStart: null,
      dateFilterEnd: null,

      downloadPopup: false,
      downloads: []
    }
  }

  addDownloads = (file) => {
    this.setState({downloads:[...this.state.downloads, file]})
  }

  closeDownloadPopup = () => {
    window.location.reload();
    this.setState({downloadPopup:false,downloads:[]})
  }

  componentDidMount() {
    this.props.checkMaintenanceMode();
    this.props.rfidLoadHistory();
    this.props.loadRFIDcompanies();
  }

  renderData = (companies) => {
    console.log('companies',companies);
    let data = [];
    if (this.props.historyData) {
      if (this.props.historyData.length > 0) {
        if (!this.state.dateFilterStart && !this.state.dateFilterEnd) {
          data = this.props.historyData;
        } else {
          data = this.props.historyData.filter(hd => {
            const e = hd.timestamp.replace('T', ' ').split('.')[0];
            const t = e.split(/[- :]/);
            const d = new Date(Date.UTC(t[0], t[1]-1, t[2], t[3], t[4], t[5]));
            const timestamp = d.getTime();
            if (timestamp > this.state.dateFilterStart && timestamp < this.state.dateFilterEnd) {
              console.log('time compare TRUE', timestamp, this.state.dateFilterStart, this.state.dateFilterEnd);
              return true;
            }
            console.log('time compare FALSE', timestamp, this.state.dateFilterStart, this.state.dateFilterEnd);
            return false;
          })
        }

        if (companies) {
          data = data.filter(hd => {
            const company = this.props.companyDict[hd.company_id];
            if (companies.indexOf(company) === -1) return false;
            return true
          });
        }

        if (this.state.upcFilter && this.state.serialFilter) {
          data = data.filter(hd => {
            if (this.state.upcFilter === hd.upc) {
              const start = parseInt(hd.start),
                    end = parseInt(hd.end),
                    serial = parseInt(this.state.serialFilter);

              if (serial >= start && serial <= end) {
                return true;
              }
            }
            return false;
          })
        }
      }
    }
    return data;
  }

  handleDateFilterClick = () => {
    this.setState({
      dateFilterModal: true
    })
  }

  closeDateFilter = () => {
    this.setState({
      dateFilterModal: false
    })
  }

  clearDateFilter = () => {
    this.setState({
      dateFilterStart: null,
      dateFilterEnd: null
    })
  }

  openEPCfilter = () => {
    this.setState({epcFilterModal:true})
  }

  closeEPCfilter = () => {
    this.setState({epcFilterModal:false})
  }

  clearEPCfilter = () => {
    this.setState({upcFilter:null,serialFilter:null,epcFilter:null})
  }

  chooseEPCfilter = (upcFilter, serialFilter, epcFilter) => {
    this.setState({upcFilter, serialFilter, epcFilter})
  }

  downloadHistory = () => {
    const permissions = checkPermissionsActions('RFID');
    const dat = this.renderData(Object.keys(permissions));
    const results = dat.map((rf, key) => { // map users to table rows
        return ({
            company: this.props.companyDict[rf.company_id] ?
              this.props.companyDict[rf.company_id].toUpperCase() : '',
            upc: rf.upc,
            start: rf.prefix + rf.start,
            end: rf.prefix + rf.end,
            qty: commatize((parseInt(rf.end) - parseInt(rf.start)) + 1),
            date_added: rf.timestamp,
            user: rf.name,
            prefix: rf.prefix,
        })
    })
    const cols = [
        {wch:18}, // Company
        {wch:18}, // UPC Code
        {wch:18}, // Starting #
        {wch:18}, // Ending #
        {wch:18}, // Production Qty
        {wch:18}, // Prefix
        {wch:18}, // Date/Time
        {wch:18}, // User
    ]
    const now = dateNow();
    let data = [
      ['Company','UPC Code','Starting #','Ending #','Production Qty','Prefix','Date/Time','User'],
    ];
    results.forEach((d) => {
      data.push(
        [d.company, d.upc, d.start, d.end, d.qty, d.prefix, moment(d.date_added).format("MM/DD/YYYY hh:mm a"), d.user]
      );
    })
    generateExcel2({cols,data}, `UPC_to_EPC_${now}.xlsx`);
  }

  downloadData = (dat, upcCode, userName, company, prefix) => {
    console.log('RFID',dat, upcCode, userName, company)
    const results = logic.UPCtoEPC(dat);
    const cols = [
        {wch:18}, // UPC
        {wch:18}, // Serial #
        {wch:40}, // EPC Code
    ]
    const now = dateNow();
    let data = [
      ['DATE',now],
      ['BRAND',company],
      ['USER',userName],
      ['',''],
      ['UPC Code','Serial #','EPC Code'],
    ];
    console.log('data', results);
    results.forEach((d) => {
      data.push(
        [upcCode, d.serialNum, d.epcCode]
      );
    })
    generateExcel2({cols,data}, `EPC_${company.toUpperCase()}_${upcCode}_${now}.xlsx`);
  }

  chooseDates = (dateFilterStart, dateFilterEnd) => {
    this.setState({dateFilterStart,dateFilterEnd});
  }

  addDownloads = (file) => {
    this.setState({downloads:[...this.state.downloads, file]})
  }

  openDownloadPopup = () => {
    this.setState({downloadPopup:true})
  }

  componentDidUpdate(prevProps) {
    if (prevProps.filename !== this.props.filename) {
      if (this.props.filename) {
        const filesArr = this.props.filename.split(',');
        this.openDownloadPopup();
        this.props.verifyRFIDExcel(filesArr);
      }
    }
  }

  render() {
    if (!checkPermissions('RFIDhistory')) {
      return <Redirect to="/dashboard" />;
    }
    if (this.props.maintenanceMode === 'yes') return <Redirect to="/components/maintenance_page" />
    let companyPerms = [];
    const permissions = checkPermissionsActions('RFID');
    if (Object.keys(permissions).length > 0) {
      companyPerms = Object.keys(permissions);
    } else {
      return <Redirect to="/dashboard" />;
    }

    let companyList = [];
    if (this.props.company_ids) {
      companyList = Object.keys(this.props.company_ids).map(c => {
        if (c === 'walmart') return 'WAL-MART USA(135)';
        return c.toUpperCase()
      });
    }

    const data = this.renderData(companyPerms);
    console.log('rendered data', data)
    console.log('data hhh', this.state, this.props);
    return (
    <GridContainer>
      {this.state.downloadPopup && <RFIDdownloadPopup
            files={this.props.filename}
            close={this.closeDownloadPopup}
            open={this.state.downloadPopup}
            downloads={this.state.downloads}
            addDownloads={this.addDownloads}
            verifyExcelsStatus={this.props.verifyExcelsStatus}
            verifyUpdatePercentage={this.props.verifyUpdatePercentage}
            filenamesData={this.props.filenamesData}
          />}
      {this.state.dateFilterModal && <RFIDdateModal
        close={this.closeDateFilter}
        open={this.state.dateFilterModal}
        historyStartDate={this.state.dateFilterStart}
        historyEndDate={this.state.dateFilterEnd}
        chooseDates={this.chooseDates}
      />}
      {this.state.epcFilterModal && <RFIDEPCModal
        close={this.closeEPCfilter}
        open={this.state.epcFilterModal}
        epc={this.state.epcFilter}
        chooseEPCfilter={this.chooseEPCfilter}
        epcFilter={this.state.epcFilter}
        upcFilter={this.state.upcFilter}
        serialFilter={this.state.serialFilter}
      />}
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <div className="pointed" onClick={() => this.props.rfidLoadHistory()}><Cached /></div>
            </CardIcon>
          </CardHeader>
          <CardBody>
        {data && <div>
            {data.length === 0 && <div style={{textAlign:'center',margin:'20px auto'}}>
              <div style={{textAlign:'center',margin:'20px auto',display:'inline-block'}}>
                <FadeLoader />
              </div>
            </div>}
        </div>}
          {this.props.loading && <div style={{textAlign:'center',margin:'20px auto'}}>
              <div style={{textAlign:'center',margin:'20px auto',display:'inline-block'}}>
                <FadeLoader />
              </div>
              {this.props.percent && <div style={{marginTop:20}}>{Math.round(this.props.percent * 100)} % processing</div>}
            </div>}
          {<div style={{paddingLeft:20,paddingRight:20,maxWidth:1200}}>
            {data &&
        <div>
          <GridContainer>
            <GridItem xs={6} sm={6} md={6}>
              <Button color="white" onClick={() => {
                this.openEPCfilter();
              }}>{this.state.epcFilter ? `EPC - ${this.state.epcFilter}` : 'FILTER BY EPC'}</Button>
            </GridItem>
            <GridItem xs={6} sm={6} md={6}>
              <div style={{textAlign:'right'}}>
                <Button color="warning" onClick={() => {
                  this.downloadHistory();
                }}>DOWNLOAD HISTORY</Button>
              </div>
            </GridItem>
          </GridContainer>
          <div className="table-adjust2 table-adjust3 table-hover-gray" style={{marginTop:20}}>
              <ReactTable
                onPageChange={page => this.setState({ page })}
                onPageSizeChange={(pageSize, page) =>
                  this.setState({ page, pageSize })}
                minRows={0}
                ref={(r) => {
                  this.selectTable = r;
                }}
                getTrProps={(state, rowInfo) => {
                  if (rowInfo && rowInfo.row) {
                    return {
                      onClick: (e) => {

                      }
                    }
                  } else {
                    return {}
                  }
                }}
                data={
                    data.map((rf, key) => { // map users to table rows
                        const company = this.props.companyDict[rf.company_id] ?
                          this.props.companyDict[rf.company_id].toUpperCase() : '';
                        return ({
                            o_id: rf.id,
                            company: company === 'WALMART' ? 'WAL-MART USA(135)' : company,
                            upc: rf.upc,
                            start: rf.start,
                            end: rf.end,
                            qty: commatize((parseInt(rf.end) - parseInt(rf.start) + 1)),
                            date_added: rf.timestamp,
                            user: rf.name,
                            prefix: rf.prefix,
                            download: '',
                            gs1_prefix: rf.gs1_prefix,
                            company_id: rf.company_id
                        })
                    })
                }
                filterable
                defaultSorted={[
                  {
                    id: "timestamp",
                    desc: true
                  }
                ]}
                columns={[
                    {
                        Header: "Company",
                        accessor: "company",
                        filterMethod: (filter, row) => {
                            const chosen = filter.value;
                            if (chosen.length > 0) {
                                return chosen.indexOf(row[filter.id]) !== -1;
                            } else {
                                return true;
                            }
                        },
                        Filter: ({filter, onChange}) => {
                            return <div className="gmp-filter"><CustomMultipleSelect
                                options={companyList}
                                label={''}
                                choose={onChange}
                            /></div>
                        },
                        minWidth: 100
                    },
                    {
                        Header: "UPC Code",
                        accessor: "upc",
                        filterMethod: (filter, row) => {
                          let filterValue = filter.value.toLowerCase();
                          filterValue = filterValue ? filterValue.toString().trim() : filterValue;
                          if (filterValue.length > 0) {
                            let passed = false;
                            if (filterValue.length > row[filter.id].length) return false;
                            let val = row[filter.id].substring(0,filterValue.length);
                            console.log('comparing..', filterValue, val);
                            if (val === filterValue) return true;
                            return false;
                          } else {
                            return true;
                          }
                        }
                    },
                    {
                        Header: "Starting #",
                        accessor: "start",
                        filterMethod: (filter, row) => {
                          var filterValue = filter.value.toLowerCase();
                          if (filterValue.length > 0) {
                              return row[filter.id].toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;
                          } else {
                              return true;
                          }
                        },
                        Cell: cell => {
                          const d = cell.original;
                          return d.start;
                        },
                    },
                    {
                        Header: "Ending #",
                        accessor: "end",
                        filterMethod: (filter, row) => {
                          var filterValue = filter.value.toLowerCase();
                          if (filterValue.length > 0) {
                              return row[filter.id].toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;
                          } else {
                              return true;
                          }
                        },
                        Cell: cell => {
                          const d = cell.original;
                          return d.end;
                        },
                    },
                    {
                        Header: "Qty",
                        accessor: "qty",
                        filterMethod: (filter, row) => {
                          var filterValue = filter.value.toLowerCase();
                          if (filterValue.length > 0) {
                              return row[filter.id].toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;
                          } else {
                              return true;
                          }
                        }
                    },
                    {
                        id: 'cs1',
                        Header: "Date/Time",
                        accessor: "date_added",
                        Cell: cell => {
                          const d = cell.original;
                          return moment(d.date_added).format("MM/DD/YYYY hh:mm a")
                        },
                        sortMethod: (a, b) => {
                          a = new Date(a).getTime();
                          b = new Date(b).getTime();
                             return b > a ? 1 : -1;
                        },
                        filterMethod: (filter, row) => {
                            const startDate = this.state.startDate,
                                  endDate = this.state.endDate;
                            if (startDate && endDate) {
                              const a = new Date(filter.value).getTime();
                              if (a > startDate && a < endDate) {
                                return true;
                              }
                            }
                        },
                        Filter: ({filter, onChange}) => {
                            const start = moment(this.state.dateFilterStart).format('MM/DD/YY');
                            const end = moment(this.state.dateFilterEnd).format('MM/DD/YY');
                            return <div className="adjustDateFilterBtn">
                                      <div
                                        onClick={() => {
                                          this.handleDateFilterClick();
                                      }}>
                                        {
                                          (this.state.dateFilterStart || this.state.dateFilterEnd) ?
                                          <div style={{fontSize:'11px',color:'#5e5e5e'}} className="datetime-box">
                                            <div style={{lineHeight:'1',marginBottom:0}}>{start} -</div>
                                            <div style={{lineHeight:'1',marginTop:0}}>{end}</div>
                                          </div> :
                                          <Button
                                            style={{width:'100%',top:-6}}
                                            color="white"
                                          >Filter by Date</Button>
                                        }
                                      </div>
                                    </div>
                        },
                        maxWidth: 150
                    },
                    {
                        Header: "User",
                        accessor: "name",
                        Cell: cell => {
                          const d = cell.original;
                          return d.user;
                        },
                        filterMethod: (filter, row) => {
                          var filterValue = filter.value.toLowerCase();
                          if (filterValue.length > 0) {
                              return row[filter.id].toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;
                          } else {
                              return true;
                          }
                        }
                    },
                    {
                      Header: "Download",
                      accessor: "download",
                      maxWidth:80,
                      Cell: cell => {
                        const d = cell.original;
                        return <div>
                        {this.props.loading && <Button
                            justIcon
                            round
                            simple
                            onClick={() => {
                            }}
                            color="primary"
                            disabled
                        >
                          <ArrowDownward style={{color:'#ccc'}}/>
                        </Button>}
                        {!this.props.loading && <Button
                            justIcon
                            round
                            simple
                            onClick={() => {
                              console.log('cell.original',cell.original);
                              const dat = cell.original;
                              const productionQty = parseInt(dat.end) - parseInt(dat.start) + 1;
                              //const serial = decConvert(parseInt(dat.start) - 1);
                              const obj = {
                                upcCode: dat.upc,
                                productionQty,
                                serial: no_prefix_companies.indexOf(dat.company.toLowerCase()) === -1 ? dat.start.substring(3) : dat.start,
                                manufacturerID: dat.prefix,
                                rfidType: dropdownRequired.indexOf(dat.company.toLowerCase()) === -1 ? 'C' : 'B',
                                gs1_prefix: { prefix: dat.gs1_prefix, gtin14: decConvert(dat.upc, 14) },
                                brand: dat.company ? dat.company.toUpperCase() : '',
                                company_id: dat.company_id
                              }
                              console.log('obj2 -- ', dropdownRequired);
                              console.log('obj2',obj);
                              this.props.generateRFIDExcel(obj);

                              // this.downloadData(obj, dat.upc, dat.user ,dat.company, dat.prefix)

                              // upcCode: data.upcCode,
                              // productionQty: data.productionQty,
                              // serial: data.serial,
                              // manufacturerID: data.manufacturerID,
                              // rfidType: data.rfidType,
                              // gs1_prefix: data.gs1_prefix
                            }}
                            color="primary"
                        >
                          <ArrowDownward style={{color:'green'}}/>
                        </Button>}
                      </div>;
                      },
                    }
                ]}
                defaultPageSize={10}
                showPaginationTop
                showPaginationBottom
                className="-striped -highlight"
            >
            {(state, makeTable, instance) => {
                let recordsInfoText = "";

                const { filtered, pageRows, pageSize, sortedData, page } = state;

                if (sortedData && sortedData.length > 0) {
                  let isFiltered = filtered.length > 0;

                  let totalRecords = sortedData.length;

                  let recordsCountFrom = page * pageSize + 1;

                  let recordsCountTo = recordsCountFrom + pageRows.length - 1;

                  if (isFiltered)
                    recordsInfoText = `Showing ${recordsCountFrom}-${recordsCountTo} of ${totalRecords.toLocaleString()} filtered RFID entries`;
                  else
                    recordsInfoText = `Showing ${recordsCountFrom}-${recordsCountTo} of ${totalRecords.toLocaleString()} filtered RFID entries`;
                } else recordsInfoText = "No RFID entries";

                return (
                  <div className="main-grid">
                    {makeTable()}
                    <div style={{marginTop:'30px'}}>
                      <div style={{textAlign:'center'}}>{recordsInfoText}</div>
                    </div>
                  </div>
                );
              }}
            </ReactTable>
          </div>
        </div>}
      </div>}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
    );
  }
}

const mapStateToProps = state => {
  const companyDict = {};
  if (state.rfid.company_ids) {
    const cid = Object.keys(state.rfid.company_ids);
    if (cid.length > 0) {
      cid.forEach(c => {
        companyDict[state.rfid.company_ids[c]] = c
      })
    }
  }
  return {
    loading: state.rfid.loading,
    historyData: state.rfid.historyList,
    company_ids: state.rfid.company_ids,
    companyDict,
    maintenanceMode: state.userPerms.maintenanceMode,
    filename: state.rfid.filename,
    percent: state.rfid.percent,
    filenamesData: state.rfid.filenamesData,
    verifyExcelsStatus: state.rfid.verifyExcels,
    verifyUpdatePercentage: state.rfid.verifyUpdatePercentage
  }
}

const mapDispatchToProps = dispatch => {
  return {
    rfidLoadHistory: () => {
      dispatch(actions.rfidLoadHistory());
    },
    loadRFIDcompanies: () => dispatch(actions.loadRFIDcompanies()),
    checkMaintenanceMode: () => {
      dispatch(actions.checkMaintenanceMode());
    },
    generateRFIDExcel: (data) => {
      dispatch(actions.generateRFIDExcel(data));
    },
    verifyRFIDExcel: (filesArr) => {
      dispatch(actions.verifyRFIDExcel(filesArr));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RFIDHistory);
