import React from 'react';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button2 from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import GINselect from "./GINselect";
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';

const GINpickValuesVariantsModal = props => {
  console.log('GINvariantsmodal',props);
  const mode = props.mode === 'new' ? 'new' : 'edit';
  return (
    <Dialog
      open={props.open}
      keepMounted
      onClose={props.close}
      maxWidth="md"
      fullWidth={true}
    >
      <DialogTitle>
        <div style={{textAlign:'center'}}>{props.mode === 'new' ? 'Enter New Variant' : 'Update Variant'}</div>
        <div className="close-btn">
          <IconButton aria-label="Close" onClick={props.close}>
            <Close />
          </IconButton>
        </div>
      </DialogTitle>
      <div style={{padding:'0 40px 20px 40px'}}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={3}>
            {/*mode === 'new' && <GINselect
              label="DB"
              options={props.openedDBs}
              choose={props.onSelectChange}
              name="modalVariantDB"
              default={props.modalVariantDB}
            />*/}
            {mode !== 'new' &&
              <CustomInput
                labelText="DB"
                id="new_variant_db"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: (e) => {},
                  value: props.modalVariantDB,
                  role: 'presentation',
                  autoComplete: 'nope',
                  disabled:true
                }}
              />
            }
          </GridItem>
          {mode === 'new' && <GridItem xs={12} sm={12} md={3}>
            <CustomInput
              labelText="Variant Code"
              id="new_variant_code"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: (e) => {
                  props.onInputChange('modalVariantCode', e);
                },
                value: props.modalVariantCode,
                role: 'presentation',
                autoComplete: 'nope'
              }}
            />
          </GridItem>}
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              labelText="Variant Description"
              id="new_variant_description"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: (e) => {
                  props.onInputChange('modalVariantDesc', e);
                },
                value: props.modalVariantDesc,
                role: 'presentation',
                autoComplete: 'nope'
              }}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <div style={{padding:'20px',textAlign:'center'}}>
              <Button2
                color="primary"
                onClick={() => { props.close(); }}
                style={{marginRight:'20px'}}
              >
                CANCEL
              </Button2>
              <Button2
                color="success"
                onClick={() => {
                  if (mode === 'new') {
                    let duplicate = false;
                    Object.keys(props.variantCodes).forEach(db => {
                      if (props.variantCodes[db]) {
                        props.variantCodes[db].forEach(varr => {
                          if (varr[0] === props.modalVariantCode) {
                            duplicate = true;
                          }
                        });
                      }
                    });
                    if (!duplicate) {
                      const desc = props.modalVariantDesc ? props.modalVariantDesc.trim() ?
                                                            props.modalVariantDesc.trim() : '' : '';
                      if (!desc) {
                        props.setValidationMsg('Please enter a description');
                      } else {
                        props.handleUpdate(mode);
                      }
                    } else {
                      props.setValidationMsg('Duplicate code not allowed');
                    }
                  } else {
                    const desc = props.modalVariantDesc ? props.modalVariantDesc.trim() ?
                                                          props.modalVariantDesc.trim() : '' : '';
                    if (!desc) {
                      props.setValidationMsg('Please enter a description');
                    } else {
                      props.handleUpdate(mode);
                    }
                  }
                }}
              >
                {mode === 'new' ? 'ADD' : 'UPDATE'}
              </Button2>
              {props.validationMsg && <div className="red-text text-center" style={{marginTop:'20px'}}>{props.validationMsg}</div>}
              {props.validationMsg2 && <div className="red-text text-center" style={{marginTop:'20px'}}>{props.validationMsg2}</div>}
            </div>
          </GridItem>
        </GridContainer>
      </div>
    </Dialog>
  );
}

export default GINpickValuesVariantsModal
