import React from 'react';
import CardIcon from "components/Card/CardIcon.jsx";
import { connect } from 'react-redux';
import { Card, CardContent } from '@material-ui/core';
import * as actions from '../../store/actions';
import Build from "@material-ui/icons/Build";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import GMPMultipleSelect from "containers/GMP/GMPMultipleSelect";
import GINradio from "./GINradio";
import activeDBs from "shared/activeDBs";
import _ from 'lodash';

const DBlabel = props => {
    // props.name
    let backgroundColor = '';
    activeDBs.forEach((db) => {
      if (db.name === props.name) {
        backgroundColor = db.color;
      }
    });

    if (backgroundColor) {
      return (
        <div style={{backgroundColor}} className="db-pill">
          {props.name.split('_')[0]}
        </div>
      );
    }
    return null;
}

class GINSource extends React.Component{
    state = {
        orderBy: 'db',
        direction: 'desc',
        filters: {
            db: [],
            item_is: []
        },
        data: []
    };

    getData = () => {
      const data = _.filter(_.map(this.props.openedDBs, (db) => {
        const check = checkProduction(db,this.props.item_is[db]);
        console.log('checking', check);
        return {
            db,
            item_code: this.props.itemCodes[db],
            item_is: check.value,
            options: check.production ? ['Produced in House','Outsourced','Both','None'] : ['Outsourced','None'],
            label: check.production ? "Source,Produced in House,Outsourced,Both,None" : "Source,Outsourced,None"
        }
      }))
      return data;
    }

    componentDidMount() {
      const data = this.getData();
      data.forEach(dat => {
        if (this.props.item_is[dat.db]) {
          if (this.props.item_is[dat.db] !== dat.item_is) {
            this.props.updateGINEdit({
              db:dat.db,
              field:'item_is',
              value:dat.item_is
            });
          }
        } else {
          this.props.updateGINEdit({
            db:dat.db,
            field:'item_is',
            value:dat.item_is
          });
        }
      })
      this.setState({
        data
      })
    }

    componentDidUpdate(prevProps, prevState) {
      if (prevProps.item_is !== this.props.item_is) {
        this.setState({data:this.getData()})
      }
    }

    orderBy(data){
        if(this.state.filters.db && this.state.filters.db.length){
            data = _.filter(data, v => _.indexOf(this.state.filters.db, v.db) !== -1);
        }

        if(this.state.filters.item_is && this.state.filters.item_is.length){
            data = _.filter(data, v => _.indexOf(this.state.filters.item_is, v.item_is) !== -1);
        }

        return _.orderBy(data, this.state.orderBy, this.state.direction);
    }

    handleRadioChange = (e, value, db) => {
      if (this.props.item_is[db]) {
        if (this.props.item_is[db] !== value) {
          this.props.updateGINEdit({
            db,
            value,
            field: 'item_is'
          });
        }
      } else {
        this.props.updateGINEdit({
          db,
          value,
          field: 'item_is'
        });
      }
    }

    render(){
      console.log('st prr', this.state, this.props);

        return (
            <Card style={{marginTop:10,marginBottom:10}}>
                <CardContent>
                    <div style={{margin:'15px 0 20px 10px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                        <CardIcon color="rose">
                            <Build style={{fill:'white'}} />
                        </CardIcon>
                        <h1>Source</h1>
                    </div>
                    <div className="table-hover">
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{paddingRight: 4, paddingLeft: 4}}>
                                    <TableSortLabel
                                        active={this.state.orderBy === 'db'}
                                        direction={this.state.direction}
                                        onClick={() => {
                                        this.setState({
                                            orderBy: 'db',
                                            direction: this.state.orderBy === 'db' ? this.state.direction === 'desc' ? 'asc' : 'desc' : 'desc'
                                        })
                                        }}
                                    >
                                        DB
                                    </TableSortLabel>
                                    </TableCell>
                                    <TableCell style={{paddingRight: 4, width: 200}} align="right">
                                        <TableSortLabel
                                            style={{whiteSpace: 'nowrap'}}
                                            active={this.state.orderBy === 'item_code'}
                                            direction={this.state.direction}
                                            onClick={() => {
                                            this.setState({
                                                orderBy: 'item_code',
                                                direction: this.state.orderBy === 'item_code' ? this.state.direction === 'desc' ? 'asc' : 'desc' : 'desc'
                                            })
                                            }}
                                            >
                                            Item Code
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell style={{paddingRight: 4, width: 300}} align="right">
                                        <TableSortLabel
                                            style={{whiteSpace: 'nowrap'}}
                                            active={this.state.orderBy === 'item_is'}
                                            direction={this.state.direction}
                                            onClick={() => {
                                            this.setState({
                                                orderBy: 'item_is',
                                                direction: this.state.orderBy === 'item_is' ? this.state.direction === 'desc' ? 'asc' : 'desc' : 'desc'
                                            })
                                            }}
                                            >
                                            Source
                                        </TableSortLabel>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{paddingRight: 4, paddingLeft: 4, maxWidth: 120}}>
                                        <div className="gmp-filter" style={{maxWidth: 80}}>
                                            <GMPMultipleSelect
                                                options={_.uniq(_.map(this.state.data, v => v.db))}
                                                label={''}
                                                choose={v => {
                                                    const filters = Object.assign({}, this.state.filters);

                                                    filters['db'] = v;

                                                    this.setState({filters});
                                                }}
                                            />
                                        </div>
                                    </TableCell>
                                    <TableCell style={{width: 200}} />
                                    <TableCell style={{width: 300}}>
                                        <div className="gmp-filter">
                                            <GMPMultipleSelect
                                                options={['None', 'Produced in House', 'Outsourced', 'Both']}
                                                label={''}
                                                choose={v => {
                                                    const filters = Object.assign({}, this.state.filters);

                                                    filters['item_is'] = v;

                                                    this.setState({filters});
                                                }}
                                            />
                                        </div>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    _.map(this.orderBy(this.state.data), (v, i) => (
                                        <TableRow key={i}>
                                            <TableCell style={{paddingRight: 4, paddingLeft: 4, width: 80}} component="th" scope="row">
                                                <DBlabel name={v.db} />
                                            </TableCell>
                                            <TableCell align="right" style={{width: 300}}>{v.item_code}</TableCell>
                                            <TableCell align="right" style={{width: 300}}>
                                                <GINradio
                                                  name={v.db}
                                                  label={v.label}
                                                  options={v.options}
                                                  onChange={this.handleRadioChange}
                                                  value={v.item_is}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                        </div>
                </CardContent>
            </Card>
        )
    }
}

const checkProduction = (db, value) => {
  const obj = { value, production: true };
  activeDBs.forEach(adb => {
    if (adb.name === db) {
      if (!adb.production) {
        // outsource only
        obj.value = 'Outsourced';
        obj.production = false;
        if (value === 'None') {
          obj.value = 'None';
        }
      }

      if (value === '') {
        obj.value = 'None';
      }
    }
  })
  return obj;
}

const mapStateToProps = state => ({
    item_is: state.gin.item_is,
    openedDBs: state.gin.openedDBs,
    itemCodes: state.gin.itemCodes
})

const mapDispatchToProps = dispatch => {
    return {
        updateGINEdit: data => dispatch(actions.updateGINEdit(data)),
        editModeOn: data => dispatch(actions.editModeOn(data)),
        setEdit: data => dispatch(actions.setGINEdit(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GINSource);
