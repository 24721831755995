import React from 'react';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import Button from "components/CustomButtons/Button.jsx";
import { decConvert, commatize } from '../../shared/utility';

const SerialsPopup = props => {
    console.log('serialspopup << ', props);
    const productionQty = props.productionQty;
    const startingSerial = props.manufacturerID + decConvert(props.startingSerial);
    const finalRanges = props.extractNumbers(props.similarSerials, parseInt(startingSerial), productionQty);
    console.log('finalRanges', finalRanges);
    const serials = finalRanges.map((s,i) => {
      return (
        <div key={`${i}-serials`}>{s.start ? s.start.toLocaleString() : null} - {s.end ? s.end.toLocaleString() : null}</div>
      )
    })
    return (
      <Dialog
        onClose={props.close}
        open={props.open}
        keepMounted
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle style={{paddingBottom: 0}} onClose={() => props.close()}>
          Similar Serial Numbers for UPC # {props.upcCode}
        </DialogTitle>
  
        <DialogContent style={{overflowY:'unset'}}>
          <div style={{margin:20}}>
            <div style={{marginBottom:20}}>
              <strong>{parseInt(startingSerial).toLocaleString()} with Quantity of {commatize(props.productionQty)} produces the following duplicates.</strong>
              <hr />
              {serials}
            </div>
            <Button
              color="primary"
              onClick={() => {
                props.close();
              }}
            >CLOSE</Button>
          </div>
        </DialogContent>
      </Dialog>
    )
  }

  export default SerialsPopup;