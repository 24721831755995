import * as actionTypes from './actionTypes';

export const fetchAD = () => {
    return {
        type: actionTypes.FETCH_AD,

    }
}

export const fetchADStart = () => {
    return {
        type: actionTypes.FETCH_AD_START
    }
}

export const fetchADSuccess = (users, directory) => {
    return {
        type: actionTypes.FETCH_AD_SUCCESS,
        users,
        directory
    }
}

export const fetchADFail = (error) => {
    return {
        type: actionTypes.FETCH_AD_FAIL,
        error
    }
}

export const setActiveCountry = (country) => {
    return {
        type: actionTypes.SET_ACTIVE_COUNTRY,
        country,
    }
}

export const setActiveOffice = (office) => {
    return {
        type: actionTypes.SET_ACTIVE_OFFICE,
        office
    }
}

export const setADModalState = (isOpen) => {
    return {
        type: actionTypes.SET_AD_MODAL_STATE,
        isOpen
    };
}

export const setADMiniModalState = (modalState) => {
    return {
        type: actionTypes.SET_AD_MINI_MODAL_STATE,
        modalState
    };
}

export const setActiveDepartment = (department) => {
    return {
        type: actionTypes.SET_ACTIVE_DEPARTMENT,
        department
    }
}

export const setActiveUser = (userIndex) => {
    return {
        type: actionTypes.SET_ACTIVE_USER,
        userIndex
    }
}

export const addADUser = (user) => {
    return {
        type: actionTypes.ADD_AD_USER,
        user
    }
}

export const addADUserStart = () => {
    return {
        type: actionTypes.ADD_AD_USER_START,
    }
}

export const addADUserFail = (error) => {
    return {
        type: actionTypes.ADD_AD_USER_FAIL,
        error
    }
}

export const addADUserSuccess = (users) => {
    return {
        type: actionTypes.ADD_AD_USER_SUCCESS,
        users
    }
}

export const modifyADUser = (user) => {
    return {
        type: actionTypes.MODIFY_AD_USER,
        user
    }
}

export const modifyADUserStart = () => {
    return {
        type: actionTypes.MODIFY_AD_USER_START,
    }
}

export const modifyADUserFail = (error) => {
    return {
        type: actionTypes.MODIFY_AD_USER_FAIL,
        error
    }
}

export const modifyADUserSuccess = (users) => {
    return {
        type: actionTypes.MODIFY_AD_USER_SUCCESS,
        users
    }
}

export const deleteADUser = (userDN) => {
    return {
        type: actionTypes.DELETE_AD_USER,
        userDN
    }
}

export const deleteADUserStart = () => {
    return {
        type: actionTypes.DELETE_AD_USER_START,
    }
}

export const deleteADUserFail = (error) => {
    return {
        type: actionTypes.DELETE_AD_USER_FAIL,
        error
    }
}

export const deleteADUserSuccess = (users) => {
    return {
        type: actionTypes.DELETE_AD_USER_SUCCESS,
        users
    }
}
