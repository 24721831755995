import React, { Component } from 'react';
import ReactDOM from "react-dom";
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Table from "components/Table/Table.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import Fade from '@material-ui/core/Fade';
import { numberWithCommas, nameSort } from 'shared/utility';
import { withStyles } from '@material-ui/core/styles';
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import ContentCopy from '@material-ui/icons/ContentCopy';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import dbList from 'shared/activeDBs';
import NavPills from "components/NavPills/NavPillsColoredTabs.jsx";
import Accordion from "components/Accordion/Accordion.jsx";
import Close from "@material-ui/icons/Close";
import SweetAlert from "react-bootstrap-sweetalert";
import activeDBs from "shared/activeDBs";
import { showPicksList } from "shared/activeDBs";
import { FadeLoader } from "react-spinners";

import Button from "../../components/CustomButtons/Button.jsx";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import GINask from "./GINask2";

const changeAlls = ['baseItemDescriptions', 'qtyPerUnits', 'ilengths', 'widths', 'weights',
        'productTypes', 'itemIsSimilars', 'salesUnits', 'basicPackagingUnits', 'brandSimilars', 'subgroupSimilars', 'rbos','picks'];

const changeAllFields = ['baseItemDescription', 'qtyPerUnit', 'ilength', 'width', 'weight',
        'productType', 'itemIs', 'salesUnit', 'basicPackagingUnit', 'brand', 'subgroup', 'ipackagingtypes','rbo','picks'];

const changeAllOriginalFields = ['base_item_description', 'qty_per_unit', 'ilength', 'width', 'weight',
        'product_type', 'item_is', 'sales_unit', 'basic_packaging_unit', 'brand', 'subgroup', 'ipackagingtypes','rbo','picks'];

const generalErrors = ['base_item_description','product_type','rbo','brand','subgroup','ipackagingtypes','item_is',''];
const measurementErrors = ['sales_unit','basic_packaging_unit','qty_per_unit','ilength','width','weight','picks'];
const taxErrors = ['gst_relevant','material_type','hsn_code','gst_tax_category','service_acct_code'];

class GINbox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentItem: null,
      deleteAlert: false,
      ask: false,
      copySuccess: '',
      makeVisible: false
    }

    const styles = {
      cardTitle,
      pageSubcategoriesTitle: {
        color: "#3C4858",
        textDecoration: "none",
        textAlign: "center"
      },
      cardCategory: {
        margin: "0",
        color: "#999999"
      }
    };

    const styles2 = theme => ({
      root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
      },
    });
  }

  copyStringToClipboard = (str) => {
   var el = document.createElement('textarea');
   el.value = str;
   el.setAttribute('readonly', '');
   el.style = {position: 'absolute', left: '-9999px'};
   document.body.appendChild(el);
   el.select();
   document.execCommand('copy');
   document.body.removeChild(el);
   this.setState({
     makeVisible: true
   }, () => {
     setTimeout(() => {
       this.setState({
         makeVisible: false
       });
     }, 2000);
   });
  }

  handleCopyToAll = () => {
    const self = this;
    setTimeout(() => {
      self.copyToAll();
    }, 200);
  }

  copyToAll = () => {
    const original_db = this.props.db.name,
          original_productType = this.props.productType;
    console.log('rbo dictionary',this.props.rboDict, this.props.rboReverseDict);
    let changeObj = {},
        changedProductTypes = [];
    changeAllOriginalFields.forEach((field,j) => {
      const value = this.props[changeAllFields[j]];
      changeObj[field] = {};

      activeDBs.forEach((ad,i) => {
        const db = ad.name;
        if (field === 'product_type') {
          console.log('checking if product type changed ?', this.props.productTypes[db], db, original_db);
          if (db !== original_db && (!this.props.productTypes[db] || this.props.productTypes[db] === 'n/a' )) {
            if (this.props.openedDBs.indexOf(db) !== -1) {
              changedProductTypes.push(db);
            }
          }
        }

        if (this.props.openedDBs.indexOf(db) === -1) {
          changeObj[field][db] = null;
        } else {
          if (field === 'rbo') {
            const rbo_name = this.props.rboDict[original_db][value];
            const new_rbo_code = this.props.rboReverseDict[db][rbo_name];
            changeObj[field][db] = value;
          } else if (field === 'picks') {
            const dbList = ['NSP','NHK','NTW','NIN','NDK','NAS'];
            if (dbList.indexOf(db.split('_')[0]) === -1) {
              if (value && value !== "0") {
                changeObj[field][db] = value;
              }
            }
          } else {
            changeObj[field][db] = value;
          }
        }
      });
    });
    console.log('the final change obj is', changeObj);
    this.props.setAllGIN(changeObj);
    this.props.compareAll();
    console.log('fetching item mocs all', original_productType, changedProductTypes);
    if (changedProductTypes.length > 0) {
      this.props.fetchItemMOCsAll(original_productType, changedProductTypes);
    }
  }

  openAsk = () => {
    this.setState({
      ask: true
    });
  }

  closeAsk = () => {
    this.setState({
      ask: false
    });
  }

  generateBPNames = (cardcodes, db) => {
    cardcodes.forEach((cc) => {
      this.props.fetchBPName(cc, db);
    });
  }

  generateDiffs = (comp, name, it) => {
    let base_arr = [];
    if (comp && comp.hasOwnProperty(name)) {
      Object.keys(comp[name]).forEach((co) => {
        // co = compared item
        if (comp[name][co].indexOf(this.props.db.name) === -1) {
          if (comp[name][co].length > 1) {
            comp[name][co].forEach((ccc) => {
              base_arr.push(ccc);
            });
          } else {
            base_arr.push(comp[name][co].join());
          }
        }
      });
    }

    let dbExistsAlready = base_arr.indexOf(this.props.db.name);
    if (dbExistsAlready !== -1) {
      base_arr = base_arr.filter((value, index, arr) => {
        return value !== this.props.db.name;
      });

    }
    const diffs = base_arr.map((m,i) => {
      let bgColor = 'gray';
      dbList.forEach((db) => {
        if (m == db.name) {
          bgColor = db.color;
        }
      });

      return <div className="sm-btn" key={'m'+i} style={{backgroundColor:bgColor,color:'#000000de'}}>{m.split('_')[0]}</div>
    });

    return diffs;
  }

  generateEmptyMsg = (msg, arr) => {
    return arr.length == 0 ? <div style={{textAlign:'center',marginBottom:'20px'}}><em>{msg}</em></div> : null
  }

  getDescription = (value, options, valueField, descField) => {
    let descFound = null;
    if (options) {
      options.forEach((o) => {
        if (value === o[valueField]) {
          return descFound = o[descField]
        }
      });
    }
    return descFound;
  }

  // link to opening edit Modal
  generateInputData = (o) => {
    const valueField = o.valueField ? o.valueField : null;
    const descField = o.descField ? o.descField : null;
    let desc = null;
    if (valueField) {
      desc = this.getDescription(o.value, o.options, o.valueField, o.descField);
    }
    let addText = '';
    if (o.addText) {
      addText = o.addText;
    }
    if (o.field === 'ipackagingtypes') console.log('oinput', o);
    return (<span
            className={this.props.openModal ? "" : "hovered hover-label"}
            onClick={() => {
              if (!this.props.openModal) {
                this.props.editModeOn();
                this.props.setEdit({
                  itemName: o.name,
                  db: o.db,
                  field: o.field,
                  value: o.value,
                  desc,
                  type: o.type,
                  options: o.options,
                  valueField,
                  descField,
                  addText,
                  similars: o.similars,
                  validations: o.validations
                });
              }
            }}
          >
            {!valueField && <span>{o.value ? o.value+addText : '- none -'}</span>}
            {(valueField && desc) && <span>{desc}</span>}
            {(valueField && !desc) && <span>n/a</span>}
          </span>);
  }

  setDeleteAlert = () => {
    this.setState({
      deleteAlert: true
    });
  }

  successDelete = () => {
    this.props.emptyItemByDB(this.props.db.name);
    this.props.updateItemLock(this.props.db.name, this.props.currentItem, 'UNLOCK');
    this.props.compareAll();
    if (this.props.newItemList.indexOf(this.props.db.name) !== -1) {
      this.props.ginNewItemRemove(this.props.db.name);
    }
    this.setState({
      deleteAlert: false
    });
  }

  cancelDelete = () => {
    this.setState({
      deleteAlert: false
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.message !== this.props.message) {
      if (this.props.message) {
        setTimeout(() => {
          this.props.clearGINmessage();
        }, 3000);
      }
    }
  }

  render() {
    const db = this.props.db.name,
          { classes } = this.props,
          options = this.props.editOptions;

    console.log('GINbox', this.props);

    let required = [];
    if (this.props.required) {
      required = this.props.required;
    }

    // GENERAL
    const baseItemDescription_diffs = this.generateDiffs(this.props.comp, 'base_item_description', this.props.baseItemDescription);
    const baseItemDescription_modal = this.generateInputData({
      name: 'Base Item Description',
      db,
      field: 'base_item_description',
      value: this.props.baseItemDescription ? this.props.baseItemDescription.toUpperCase() : '',
      type: 'input',
      similars: this.props.baseItemDescriptions,
      validations: {
        maxLength: 75,
        notAllowedChars: 'aCharms',
        whitelist: true
      }
    });

    const productType_diffs = this.generateDiffs(this.props.comp, 'product_type', this.props.productType);
    const productType_modal = this.generateInputData({
      name: 'Product Type',
      db,
      field: 'product_type',
      value: this.props.productType ? this.props.productType : '',
      options: options.hasOwnProperty(db) ? options[db].product_types : [],
      type: 'option1',
      similars: this.props.productTypes
    });

    const rbo_diffs = this.generateDiffs(this.props.comp, 'rbo', this.props.rbo);
    const rbo_modal = this.generateInputData({
      name: 'RBO',
      db,
      field: 'rbo',
      value: this.props.rbo ? this.props.rbo : '',
      options: options.hasOwnProperty(db) ? options[db].rbos : [],
      type: 'option2',
      valueField: 'FirmCode',
      descField: 'FirmName',
      similars: this.props.rbos
    });

    const brand_diffs = this.generateDiffs(this.props.comp, 'brand', this.props.brand);
    const brand_modal = this.generateInputData({
      name: 'Brand',
      db,
      field: 'brand',
      value: this.props.brand ? this.props.brand : '',
      options: this.props.brandNames,
      type: 'option2',
      valueField: 'id',
      descField: 'name',
      similars: this.props.brandSimilars
    });

    const subGroup_diffs = this.generateDiffs(this.props.comp, 'subgroup', this.props.subgroup);
    const subGroup_modal = this.generateInputData({
      name: 'Subgroup',
      db,
      field: 'subgroup',
      value: this.props.subgroup ? this.props.subgroup : '',
      options: this.props.subgroupNames,
      type: 'option2',
      valueField: 'id',
      descField: 'name',
      similars: this.props.subgroupSimilars
    });

    let hasWalmart = false;
    if (this.props.rboNames) {
      if (this.props.rbo) {
        if (this.props.rbo !== 'n/a') {
          for (let j = 0; j < this.props.rboNames.length; j++) {
            const rn = this.props.rboNames[j];
            if (this.props.rbo === rn.id) {
              if (rn.name) {
                if (rn.name.indexOf('WALMART') !== -1) {
                  hasWalmart = true;
                  continue;
                }
              }
            }
          }
        }
      }
    }
    const ipackagingtypes_diffs = hasWalmart ? 
      this.generateDiffs(this.props.comp, 'ipackagingtypes', this.props.ipackagingtypes)
    : 
      null;
    console.log('theipop', this.props);
    const ipackagingtypes_modal = hasWalmart ? this.generateInputData({
        name: 'Packaging Type',
        db,
        field: 'ipackagingtypes',
        value: this.props.ipackagingtypes ? this.props.ipackagingtypes : '',
        options: this.props.packagingtypes,
        type: 'option2',
        valueField: 'id',
        descField: 'name',
        similars: this.props.ipackagingtypesSimilars
      })
    :
      null;

    const itemIs_diffs = this.generateDiffs(this.props.comp, 'item_is', this.props.itemIs);
    const itemIs_modal = this.generateInputData({
      name: 'Item Is',
      db,
      field: 'item_is',
      value: this.props.itemIs ? this.props.itemIs : '',
      options: ['Produced in House','Outsourced','Both'],
      type: 'option1',
      similars: this.props.itemIsSimilars
    });

    // MEASUREMENTS
    const salesUnit_diffs = this.generateDiffs(this.props.comp, 'sales_unit', this.props.salesUnit);
    const salesUnit_modal = this.generateInputData({
      name: 'Sales Unit',
      db,
      field: 'sales_unit',
      value: this.props.salesUnit ? this.props.salesUnit : '',
      options: ['PIECE','DOZEN','GROSS','THOUSAND','METER','TARD','ROLL'],
      type: 'option1',
      similars: this.props.salesUnits
    });

    const basicPackagingUnit_diffs = this.generateDiffs(this.props.comp, 'basic_packaging_unit', this.props.basicPackagingUnit);
    const basicPackagingUnit_modal = this.generateInputData({
      name: 'Basic Packaging Unit',
      db,
      field: 'basic_packaging_unit',
      value: this.props.basicPackagingUnit ? this.props.basicPackagingUnit : '',
      options: ['TRAY','CARTON','BUNDLE','ROLL'],
      type: 'option1',
      similars: this.props.basicPackagingUnits
    });

    const qtyPerUnit_diffs = this.generateDiffs(this.props.comp, 'qty_per_unit', this.props.qtyPerUnit);
    const qtyPerUnit_modal = this.generateInputData({
      name: 'Qty Per Packing UOM',
      db,
      field: 'qty_per_unit',
      value: this.props.qtyPerUnit ? this.props.qtyPerUnit.toUpperCase() : '',
      type: 'input',
      similars: this.props.qtyPerUnits,
      validations: {
        numOnly: true,
        wholeNumOnly: true,
        maxNum: 100000
      }
    });

    const length_diffs = this.generateDiffs(this.props.comp, 'ilength', this.props.ilength);
    const length_modal = this.generateInputData({
      name: 'Length',
      db,
      field: 'ilength',
      value: this.props.ilength ? this.props.ilength.toUpperCase() : '',
      type: 'input',
      addText: ' mm',
      similars: this.props.ilengths,
      validations: {
        numOnly: true,
        wholeNumOnly: true,
        maxNum: 100000
      }
    });

    const width_diffs = this.generateDiffs(this.props.comp, 'width', this.props.width);
    const width_modal = this.generateInputData({
      name: 'Width',
      db,
      field: 'width',
      value: this.props.width ? this.props.width.toUpperCase() : '',
      type: 'input',
      addText: ' mm',
      similars: this.props.widths,
      validations: {
        numOnly: true,
        wholeNumOnly: true,
        maxNum: 100000
      }
    });

    const weight_diffs = this.generateDiffs(this.props.comp, 'weight', this.props.weight);
    const weight_modal = this.generateInputData({
      name: 'Weight',
      db,
      field: 'weight',
      value: this.props.weight ? this.props.weight.toUpperCase() : '',
      type: 'input',
      addText: ' g',
      similars: this.props.weights,
      validations: {
        numOnly: true,
        minMax3Decimals: true,
        maxNum: 100000
      }
    });

    const picks_diffs = this.generateDiffs(this.props.comp, 'picks', this.props.picks);
    const picks_modal = this.generateInputData({
      name: 'Picks',
      db,
      field: 'picks',
      value: this.props.picks ? this.props.picks.toUpperCase() : '',
      type: 'input',
      addText: '',
      similars: this.props.picks,
      validations: {
        numOnly: true,
        wholeNumOnly: true,
        greaterThanOne: true,
        maxNum: 100000
      }
    });

    const gstrel_modal = this.generateInputData({
      name: 'GST Relevant',
      db,
      field: 'gst_relevant',
      value: this.props.gst_relevant ? this.props.gst_relevant : '',
      options: this.props.nin_tax.gstrel,
      type: 'option2',
      valueField: 'id',
      descField: 'name',
    });

    const mattype_modal = this.generateInputData({
      name: 'Material Type',
      db,
      field: 'material_type',
      value: this.props.material_type || this.props.material_type == 0 ? this.props.material_type : '',
      options: this.props.nin_tax.mattype,
      type: 'option2',
      valueField: 'id',
      descField: 'name',
    })

    const hsn_code_modal = this.generateInputData({
      name: 'HSN Code',
      db,
      field: 'hsn_code',
      value: this.props.hsn_code ? this.props.hsn_code : '',
      type: 'input'
    });

    const gst_tax_category_modal = this.generateInputData({
      name: 'GST Tax Category',
      db,
      field: 'gst_tax_category',
      value: this.props.gst_tax_category ? this.props.gst_tax_category : '',
      options: this.props.nin_tax.gstcat,
      type: 'option2',
      valueField: 'id',
      descField: 'name',
    });

    const service_acct_code_modal = this.generateInputData({
      name: 'Service Accounting Code',
      db,
      field: 'service_acct_code',
      value: this.props.service_acct_code ? this.props.service_acct_code : '',
      type: 'input'
    });

    const variants_diffs = this.generateDiffs(this.props.comp, 'variants', this.props.variants);
    const variantsDesc_diffs = this.generateDiffs(this.props.comp, 'variants', this.props.variants);

    const gcur_diffs = this.generateDiffs(this.props.comp, 'gcur', this.props.gcur);
    const gprice_diffs = this.generateDiffs(this.props.comp, 'gprice', this.props.gprice);
    const gstart_diffs = this.generateDiffs(this.props.comp, 'gstart', this.props.gstart);
    const gend_diffs = this.generateDiffs(this.props.comp, 'gend', this.props.gend);
    const gterm_diffs = this.generateDiffs(this.props.comp, 'gterm', this.props.gterm);

    const rms_diffs = this.generateDiffs(this.props.comp, 'rms', this.props.rms);
    const rmsd_diffs = this.generateDiffs(this.props.comp, 'rmsd', this.props.rmsd);
    const rmsq_diffs = this.generateDiffs(this.props.comp, 'rmsq', this.props.rmsq);
    const rmsw_diffs = this.generateDiffs(this.props.comp, 'rmsw', this.props.rmsw);
    const bomItemBaseQty_diffs = this.generateDiffs(this.props.comp, 'BOMItemBaseQty', this.props.BOMItemBaseQty);
    const bomItemBaseQty_modal = this.generateInputData({
      name: 'Base Item Qty.',
      db,
      field: 'BOMItemBaseQty',
      value: this.props.BOMItemBaseQty ? this.props.BOMItemBaseQty : '',
      type: 'input',
      similars: this.props.BOMItemBaseQtys,
      validations: {
        numOnly: true,
        wholeNumOnly: true
      }
    });

    let layout = null;
    console.log('does it have walmart ?', hasWalmart);
    const general = (
    <div className="list-no-toppad">
      <List component="nav" dense={true}>
        <ListItem>
          <span className={required.indexOf('base_item_description') !== -1 ? 'field-label red-text-label' : 'field-label'}>
            <ListItemText primary="Base Item Description" />
          </span>
          {baseItemDescription_modal}
          {!this.props.openModal && baseItemDescription_diffs}
        </ListItem>
        <ListItem>
          <span className={required.indexOf('product_type') !== -1 ? 'field-label red-text-label' : 'field-label'}>
            <ListItemText primary="Product Type" />
          </span>
          {productType_modal}
          {!this.props.openModal && productType_diffs}
        </ListItem>
        <ListItem>
          <span className={required.indexOf('rbo') !== -1 ? 'field-label red-text-label' : 'field-label'}>
            <ListItemText primary="RBO Name" />
          </span>
          {rbo_modal}
          {!this.props.openModal && rbo_diffs}
        </ListItem>
        <ListItem>
          <span className={required.indexOf('brand') !== -1 ? 'field-label red-text-label' : 'field-label'}>
            <ListItemText primary="Brand" />
          </span>
          {brand_modal}
          {!this.props.openModal && brand_diffs}
        </ListItem>
        <ListItem>
          <span className={required.indexOf('subgroup') !== -1 ? 'field-label red-text-label' : 'field-label'}>
            <ListItemText primary="Subgroup" />
          </span>
          {subGroup_modal}
          {!this.props.openModal && subGroup_diffs}
        </ListItem>
        {hasWalmart ? <ListItem>
          <span className={required.indexOf('ipackagingtypes') !== -1 ? 'field-label red-text-label' : 'field-label'}>
            <ListItemText primary="Packaging Type" />
          </span>
          {ipackagingtypes_modal}
          {!this.props.openModal && ipackagingtypes_diffs}
        </ListItem> : null}
        {this.props.reviewMode && <ListItem>
          <span className={required.indexOf('item_is') !== -1 ? 'field-label red-text-label' : 'field-label'}>
          <ListItemText primary="Item is" />
          </span>
          {itemIs_modal}
          {!this.props.openModal && itemIs_diffs}
        </ListItem>}
      </List>
    </div>
    );

    let variantsArr = [];
    if (this.props.variants && this.props.variantsDesc) {
      if (this.props.variants.length > 0) {
        const itemCodeAdd = this.props.currentItem ? this.props.currentItem.split('_')[0] : this.props.currentItem;
        this.props.variants.forEach((v, i) => {
          variantsArr.push([
            v[0],
            itemCodeAdd+'_'+v[0],
            this.props.baseItemDescription+' - '+this.props.variantsDesc[i],
            this.props.variantsDesc[i]
          ])
        })
      }
    }

    const variants = (
      <div className="gray-th sm-table">
        <Table
          tableHeaderColor="primary"
          tableHead={["Variant Code", "Item Code + Variant Code", "Full Item Description", "Variant Description"]}
          tableData={variantsArr}
          coloredColls={[3]}
          colorsColls={["primary"]}
        />
        {(!this.props.openModal && variantsDesc_diffs.length > 0) && <div><strong>Variants Table Differences</strong> : {variantsDesc_diffs}</div>}
        {variantsArr.length == 0 ? <p style={{textAlign:'center'}}><em>No Variants</em></p> : ''}

        {/*!this.props.openModal && <div
          className="blue-btn btn-sm"
          onClick={() => {

            this.props.editTableModeOn();
            this.props.setEditTable({
              type: 'variants',
              DB: db,
              itemName: this.props.currentItem
            });
          }}
        >Add/Edit Variant</div>*/}
      </div>
    );
    const units = (
    <div className="list-no-toppad">
      <List dense={true}>
        <ListItem>
          <span className={required.indexOf('sales_unit') !== -1 ? 'field-label red-text-label' : 'field-label'}>
            <ListItemText primary="Sales Unit" />
          </span>
          {salesUnit_modal}
          {!this.props.openModal && salesUnit_diffs}
        </ListItem>
        <ListItem>
          <span className={required.indexOf('basic_packaging_unit') !== -1 ? 'field-label red-text-label' : 'field-label'}>
            <ListItemText primary="Basic Packaging Unit &nbsp;" />
          </span>
          {basicPackagingUnit_modal}
          {!this.props.openModal && basicPackagingUnit_diffs}
        </ListItem>
        <ListItem>
          <span className={required.indexOf('qty_per_unit') !== -1 ? 'field-label red-text-label' : 'field-label'}>
            <ListItemText primary="Qty per packing UOM" />
          </span>
          {qtyPerUnit_modal}
          {!this.props.openModal && qtyPerUnit_diffs}
        </ListItem>
      </List>
    </div>
    );

    const shouldShowPicks = showPicksList(db, this.props.productType);
    const measurements = (
    <div className="list-no-toppad">
      <List dense={true}>
        <ListItem>
          <span className={required.indexOf('ilength') !== -1 ? 'field-label red-text-label' : 'field-label'}>
            <ListItemText primary="Length" />
          </span>
          {length_modal}
          {!this.props.openModal && length_diffs}
        </ListItem>
        <ListItem>
          <span className={required.indexOf('width') !== -1 ? 'field-label red-text-label' : 'field-label'}>
            <ListItemText primary="Width" />
          </span>
          {width_modal}
          {!this.props.openModal && width_diffs}
        </ListItem>
        <ListItem>
          <span className={required.indexOf('weight') !== -1 ? 'field-label red-text-label' : 'field-label'}>
            <ListItemText primary="Weight" />
          </span>
          {weight_modal}
          {!this.props.openModal && weight_diffs}
        </ListItem>
        {shouldShowPicks && <ListItem>
          <span className={required.indexOf('picks') !== -1 ? 'field-label red-text-label' : 'field-label'}>
            <ListItemText primary="Picks" />
          </span>
          {picks_modal}
          {!this.props.openModal && picks_diffs}
        </ListItem>}
      </List>
    </div>
    );

    const taxinfo = (
      <div className="list-no-toppad">
        <List dense={true}>
          <ListItem>
            <span className={required.indexOf('gst_relevant') !== -1 ? 'field-label red-text-label' : 'field-label'}>
              <ListItemText primary="GST Relevant" />
            </span>
            {gstrel_modal}
          </ListItem>
          <ListItem>
            <span className={required.indexOf('material_type') !== -1 ? 'field-label red-text-label' : 'field-label'}>
              <ListItemText primary="Material Type" />
            </span>
            {mattype_modal}
          </ListItem>
          <ListItem>
            <span className={required.indexOf('hsn_code') !== -1 ? 'field-label red-text-label' : 'field-label'}>
              <ListItemText primary="HSN Code" />
            </span>
            {hsn_code_modal}
          </ListItem>
          <ListItem>
            <span className={required.indexOf('gst_tax_category') !== -1 ? 'field-label red-text-label' : 'field-label'}>
              <ListItemText primary="GST Tax Category" />
            </span>
            {gst_tax_category_modal}
          </ListItem>
          <ListItem>
            <span className={required.indexOf('service_acct_code') !== -1 ? 'field-label red-text-label' : 'field-label'}>
              <ListItemText primary="Service Accounting Code" />
            </span>
            {service_acct_code_modal}
          </ListItem>
        </List>
      </div>
    );

    // PRICING
    // All Customers and Specific Customers
    // ------------------------------------
    // specialmoc - for ALL CUSTOMERS only ( [['RMB','34','123123'],['$','122','123345']] )
    // mocs - object of Standard MOCs (for ALL and Specific)
    // mocarray -

    // PRICING FOR ALL CUSTOMERS
    const gterm = this.props.gterm ? this.props.gterm : 'n/a',
          gprice = this.props.gprice ? parseFloat(this.props.gprice) : 0,
          gstart = this.props.gstart ? this.props.gstart : '',
          gend = this.props.gend ? this.props.gend: '',
          gcur = this.props.gcur ? this.props.gcur: '',
          g_effective_dates = gstart + ' to ' + gend;

    let moc = 'n/a';
    const standardMOCs = this.props.mocs ? Object.keys(this.props.mocs).length > 0 ? Object.keys(this.props.mocs).map((standmc,i) => {
      return (
        <div key={`${i}${standmc}`}>Standard MOC: {standmc} {this.props.mocs[standmc]}</div>
      )
    }) : null : null;
    if (gcur) {
      moc = this.props.mocs[gcur];

    }
    let specialmoc = false,
        specialmocs = [],
        specialmocAllCustomers = null;
    if (this.props.specialmoc) {
      if (this.props.specialmoc.length > 0) {
        specialmocs = this.props.specialmoc.map((sm, i) => {
          specialmocAllCustomers = sm[1];
          return <div key={'specialmocs'+i}>Special MOC : {sm[0]} {numberWithCommas(sm[1])}</div>
        });
        specialmoc = true;
      }
    }
    console.log('the specialmocky5', this.props.specialmoc, specialmoc)

    let pricing_allcustomersArr = [],
        total_price = gcur + ' ' + numberWithCommas(1*gprice);
    if (specialmoc) {
      this.props.specialmoc.forEach((specmoc) => {
        if (specmoc[0] === gcur) {
          if ((1*gprice) < parseFloat(specmoc[1])) {
            total_price = gcur + ' ' + numberWithCommas(parseFloat(specmoc[1]));
          }
        }
      });
    } else if (this.props.mocs) {
      if ((1*gprice) < this.props.mocs[gcur]) {
        total_price = gcur + ' ' + numberWithCommas(this.props.mocs[gcur]);
      }
    }
    if (gprice && gstart && gend) {
      pricing_allcustomersArr.push([
        1,
        gterm,
        gcur + ' ' + numberWithCommas(gprice),
        g_effective_dates,
        total_price
      ]);
    }

    // qty > 1
    if (this.props.gqtys) {
      this.props.gqtys.forEach((qty, index) => {
        const qtyNum = parseInt(qty),
              priceNum = parseFloat(this.props.gprices[index]);
        let total = qtyNum * priceNum;

        if (specialmoc) {
          this.props.specialmoc.forEach((specmoc) => {
            if (specmoc[0] === this.props.gcurrs[index]) {
              if (total < parseFloat(specmoc[1])) {
                total = parseFloat(specmoc[1]);
              }
            }
          });
        } else {
          // compare with Standard MOCs
          if (this.props.mocs[this.props.gcurrs[index]]) {
            if (total < this.props.mocs[this.props.gcurrs[index]]) {
              total = this.props.mocs[this.props.gcurrs[index]];
            }
          }
        }
        pricing_allcustomersArr.push([
          qtyNum,
          this.props.gterms[index],
          this.props.gcurrs[index] + ' ' + numberWithCommas(priceNum),
          this.props.gstarts[index] + ' - ' + this.props.gends[index],
          this.props.gcurrs[index] + ' ' + numberWithCommas(total)
        ]);
      });
    }

    const pricing_allcustomers = (
        <span>
        {specialmoc && <div>{specialmocs}</div>}
        {!specialmoc && <div>{standardMOCs}</div>}
        <div className="gray-th sm-table">
          <Table
            tableHeaderColor="primary"
            tableHead={["Quantity", "Shipping Terms", "Price", "Effective Dates", "Total Prices"]}
            tableData={pricing_allcustomersArr}
            coloredColls={[2]}
            colorsColls={["primary"]}
          />
        </div>
        {(!this.props.openModal && gterm_diffs.length > 0) && <div><strong>Flat Price Term Differences</strong> : {gterm_diffs}</div>}
        {(!this.props.openModal && gcur_diffs.length > 0) && <div><strong>Flat Price Currency Differences</strong> : {gcur_diffs}</div>}
        {(!this.props.openModal && gprice_diffs.length > 0) && <div><strong>Flat Price Pricing Differences</strong> : {gprice_diffs}</div>}
        {(!this.props.openModal && gstart_diffs.length > 0) && <div><strong>Flat Price Start Date Differences</strong> : {gprice_diffs}</div>}
        {(!this.props.openModal && gend_diffs.length > 0) && <div><strong>Flat Price End Date Differences</strong> : {gprice_diffs}</div>}
        {this.generateEmptyMsg('No Pricing rows',pricing_allcustomersArr)}
        {!this.props.openModal && <div
          className="blue-btn btn-sm"
          style={{marginBottom:'10px',display:'none'}}
          onClick={() => {
            this.props.editTable2ModeOn();
            this.props.setEditTable2({
              type: 'pricing_allcustomers',
              DB: db,
              itemName: this.props.currentItem
            });
          }}
        >Add/Edit Pricing for All Customers</div>}
        </span>
    );

    let specific_pricing_arr = [];
    if (this.props.bmasterarray) {
      console.log('the bmaster!',this.props.bmasterarray);
      const cardcodes = Object.keys(this.props.bmasterarray);
      cardcodes.forEach((cc) => {
        let arr = this.props.bmasterarray[cc];
        let rowdata = [], mocdata = [], moccurencies = [];

        // gather mocs
        arr.forEach((a) => {
          if (a[0].trim() == "0") {
            if (moccurencies.indexOf(a[5]) === -1) {
              mocdata.push({
                qty: a[0],
                term: a[1],
                dates: a[6] + ' - '+a[7],
                moc: numberWithCommas(parseFloat(a[2])),
                mocnum: parseFloat(a[2]),
                currency: a[5]
              });
              moccurencies.push(a[5]);
            }
          }
        });

        // gather table data
        arr.forEach((a) => {
          if (a[0].trim() !== "0") {
            let qty = parseInt(a[0]);
            let price = parseFloat(a[2]);
            let total = qty*price;
            let becameSpecialMOC = false;
            const currency = a[5];

            if (mocdata.length > 0) {
              mocdata.forEach((specmoc) => {
                if (specmoc.currency === currency) {
                  if (total < specmoc.mocnum) {
                    total = specmoc.mocnum;
                    becameSpecialMOC = true;
                  }
                }
              });
            }
            if (!becameSpecialMOC) {
              // compare to Standard MOC
              if (this.props.mocs[currency]) {
                if (total < this.props.mocs[currency]) {
                  total = this.props.mocs[currency];
                }
              }
            }
            rowdata.push([
              a[0],
              a[1],
              a[5] + ' ' + numberWithCommas(a[2]),
              a[6] + ' - '+a[7],
              a[5] + ' ' + numberWithCommas(total)
            ]);
          }
        });
        let carddata = {
          name: cc.split('[.]')[1],
          cardcode: cc.split('[.]')[0],
          rows: rowdata,
          moc: mocdata
        };
        specific_pricing_arr.push(carddata);
      });
      specific_pricing_arr = specific_pricing_arr.sort(nameSort);
    }

    const pricing_specific = specific_pricing_arr.map((pr,index) => {
      const specialMOCrender = pr.moc.length > 0 ? pr.moc.map(pmm => {
        return (
          <div key={pmm.currency+'oi'+index}>Special MOC: {pmm.currency} {pmm.moc}</div>
        )
      }): null;

      console.log('this is the PR',pr);
      return (
        <div style={{marginBottom:'20px'}} key={'a'+index}>
          <strong>{pr.name}</strong>
          {pr.moc.length > 0 && <div>{specialMOCrender}</div>}
          {pr.moc.length === 0 && <div>{standardMOCs}</div>}
          <div className="gray-th sm-table">
            <Table
              tableHeaderColor="primary"
              tableHead={["Quantity", "Shipping Terms", "Price", "Effective Dates", "Total Prices"]}
              tableData={pr.rows}
              coloredColls={[2]}
              colorsColls={["primary"]}
            />
          </div>
          {this.generateEmptyMsg('No Pricing Specific rows',pr.rows)}
          {!this.props.openModal && <div
            className="blue-btn btn-sm"
            style={{marginBottom:'10px',display:'none'}}
            onClick={() => {
              this.props.editTable2ModeOn();
              this.props.setEditTable2({
                type: 'pricing_specific',
                DB: db,
                itemName: this.props.currentItem,
                currentPricingCustomer: pr,
                allPricingCustomers: specific_pricing_arr
              });
            }}
          >Add/Edit Pricing</div>}
        </div>
      );
    });

    let targetCostArr = [];
    if (this.props.cprice) {
      let cpriced = numberWithCommas(this.props.cprice);
      targetCostArr.push([
        1,
        this.props.cterm,
        this.props.ccur + ' ' + cpriced,
        this.props.cstart + ' - ' + this.props.cend,
        cpriced
      ]);
    }

    if (this.props.cqtys) {
      if (this.props.cqtys.length > 0) {
        this.props.cqtys.forEach((cc, index) => {
          targetCostArr.push([
            parseInt(cc),
            this.props.cterms[index],
            this.props.ccurs[index] + ' ' + numberWithCommas(this.props.cprices[index]),
            this.props.cstarts[index] + ' - ' + this.props.cends[index],
            this.props.ccurs[index] + ' ' + numberWithCommas(cc * this.props.cprices[index])
          ]);
        });
      }
    }

    const costs_all = (
        <span>
        <div className="gray-th sm-table">
          <Table
            tableHeaderColor="primary"
            tableHead={["Quantity", "Shipping Terms", "Cost", "Effective Dates", "Total Cost"]}
            tableData={targetCostArr}
            coloredColls={[2]}
            colorsColls={["primary"]}
          />
        </div>
          {this.generateEmptyMsg('No Target Cost',targetCostArr)}
        </span>
    );

    let supplier_costing_arr = [];
    if (this.props.smasterarray) {
      const cardcodes = Object.keys(this.props.smasterarray);
      cardcodes.forEach((cc) => {
        let arr = this.props.smasterarray[cc];
        let rowdata = [], mocdata = [], moccurencies = [];

        // gather mocs
        arr.forEach((a) => {
          if (a[0] || a[0] == 0) {
            if (parseInt(a[0]) === 0) {
              if (moccurencies.indexOf(a[5]) === -1) {
                if (a[2] || a[2] == 0) {
                  mocdata.push({
                    qty: a[0],
                    term: a[1],
                    dates: a[6] + ' - '+a[7],
                    moc: numberWithCommas(parseFloat(a[2])),
                    mocnum: parseFloat(a[2]),
                    currency: a[5]
                  });
                  moccurencies.push(a[5]);
                }
              }
            }
          }
        });
        console.log('found Supplier MOCs', mocdata, moccurencies);

        arr.forEach((a) => {
          if (a[0].trim() !== "0") {
            if (parseInt(a[0]) !== 0) {
              let qty = parseInt(a[0]);
              let price = parseFloat(a[2]);
              rowdata.push([
                parseInt(a[0]),
                a[1],
                a[5]+' '+ parseFloat(a[2]).toString(),
                a[6]+' - '+a[7],
                a[5]+' '+ numberWithCommas(qty*price)
              ]);
            }
          }
        });
        let carddata = {
          name: cc.split('[.]')[1],
          cardcode: cc.split('[.]')[0],
          rows: rowdata,
          moc: mocdata
        };
        supplier_costing_arr.push(carddata);
      });
    }

    const costs_specific = supplier_costing_arr.map((cr, index) => {
      const specialMOCrender = cr.moc.length > 0 ? cr.moc.map(pmm => {
        return (
          <div key={pmm.currency+'oi'+index}>Special MOC: {pmm.currency} {pmm.moc}</div>
        )
      }): null;

      return (
        <div style={{marginBottom:'20px'}} key={'pr'+index}>
          <strong>{cr.name}</strong>
          {cr.moc.length > 0 && <div>{specialMOCrender}</div>}
          {cr.moc.length === 0 && <div>{standardMOCs}</div>}
          <div className="gray-th sm-table">
            <Table
              tableHeaderColor="primary"
              tableHead={["Quantity", "Shipping Terms", "Cost", "Effective Dates", "Total Cost"]}
              tableData={cr.rows}
              coloredColls={[2]}
              colorsColls={["primary"]}
            />
            {this.generateEmptyMsg('No Costing Rows',cr.rows)}
          </div>
        </div>
      );
    });

    let BOMdata = [];
    if (this.props.rmsd) {
      if (this.props.rmsd.length > 0) {
        const rms = this.props.rms,
              rmsd = this.props.rmsd,
              rmsq = this.props.rmsq,
              rmsw = this.props.rmsw;
        rmsd.forEach((rmItem, index) => {
          BOMdata.push([rms[index], rmsd[index], rmsq[index], rmsw[index]]);
        });
      }
    }

    let fixedStyle = {height:'300px',width:'100%',overflowY:'scroll',paddingLeft:'3px'};
    if (this.props.openModal) {
      fixedStyle = null;
    }

    let isGeneralError = false,
        isMeasurementsError = false,
        isTaxError = false;

    required.forEach(r => {
      if (generalErrors.indexOf(r) !== -1) {
        isGeneralError = true;
      }

      if (measurementErrors.indexOf(r) !== -1) {
        isMeasurementsError = true;
      }

      if (taxErrors.indexOf(r) !== -1) {
        isTaxError = true;
      }
    });

    const tabLayout = [
      {
        tabButton: "General",
        tabColor: isGeneralError ? 'danger' : null,
        tabError: isGeneralError ? 0 : -1,
        tabContent: (
          <div style={fixedStyle}>
            {general}
            <Divider />
            {variants}
          </div>
        )
      },
      {
        tabButton: "Measurements",
        tabColor: isMeasurementsError ? 'danger' : null,
        tabError: isMeasurementsError ? 1 : -1,
        tabContent: (
          <div style={fixedStyle}>
            <h4>Units &amp; Measurements</h4>
            {units}
            <h4>Item Dimensions</h4>
            {measurements}
          </div>
        )
      },
      {
        tabButton: "Pricing",
        tabError: -1,
        tabContent: (
          <div style={fixedStyle}>
            <h4>Pricing for All Customers</h4>
            {pricing_allcustomers}
            <Divider />
            <br />
            <h4>Pricing for Specific Customers</h4>
            {pricing_specific}
            {this.generateEmptyMsg('No Specific Customer Pricings',specific_pricing_arr)}
          </div>
        )
      },
      {
        tabButton: "Costing",
        tabError: -1,
        tabContent: (
          <div style={fixedStyle}>
            <h4>Target Costs</h4>
            {costs_all}
            <Divider />
            <br />
            <h4>Costing by Supplier</h4>
            {costs_specific}
            {this.generateEmptyMsg('No Supplier Specific Costs',supplier_costing_arr)}
          </div>
        )
      },
      {
        tabButton: "BOM",
        tabError: -1,
        tabContent: (
          <div style={fixedStyle}>
            <h4>Bill of Materials</h4>
            <strong>Item Base Quantity : </strong> {bomItemBaseQty_modal}
            {(!this.props.openModal && bomItemBaseQty_diffs.length > 0)
              && <div><strong>Item Base Qty. Differences</strong> : {bomItemBaseQty_diffs}</div>}
            <div className="gray-th sm-table">
              <Table
                tableHeaderColor="primary"
                tableHead={["Raw Material Code", "SAP Raw Material Description", "Qty Required to Produce Base Qty", "Raw Material Warehouse"]}
                tableData={BOMdata}
                coloredColls={[2]}
                colorsColls={["primary"]}
              />
              {this.generateEmptyMsg('No BOM data',BOMdata)}
              {(!this.props.openModal && rms_diffs.length > 0) && <div><strong>Raw Material Code Differences</strong> : {rms_diffs}</div>}
              {(!this.props.openModal && rmsd_diffs.length > 0) && <div><strong>Raw Material Description Differences</strong> : {rmsd_diffs}</div>}
              {(!this.props.openModal && rmsq_diffs.length > 0) && <div><strong>Raw Material Qty. Differences</strong> : {rmsq_diffs}</div>}
              {(!this.props.openModal && rmsw_diffs.length > 0) && <div><strong>Raw Material Warehouse Difference</strong> : {rmsw_diffs}</div>}
              {!this.props.openModal && <div
                className="blue-btn btn-sm"
                style={{display:'none'}}
                onClick={() => {
                  this.props.editTableModeOn();
                  this.props.setEditTable({
                    type: 'bom',
                    DB: db,
                    itemName: this.props.currentItem
                  });
                }}
              >Add/Edit BOM</div>}
            </div>
          </div>
        )
      },
      {
        tabButton: "Tax Info",
        tabColor: isTaxError ? 'danger' : null,
        tabError: isTaxError ? 5 : -1,
        tabContent: (
          <div style={fixedStyle}>
            <h4>Tax Information</h4>
            {taxinfo}
          </div>
        )
      },
    ].filter(tab => {
      const itemIs = this.props.itemIs;
      if (itemIs) {
        if (itemIs === 'Produced in House') {
          if (tab.tabButton === 'Costing') {
            return false;
          }
        } else if (itemIs === 'Outsourced') {
          if (tab.tabButton === 'BOM') {
            return false;
          }
        }
      }

      if (!this.props.reviewMode) {
        const noShows = ['Costing','Pricing','BOM'];
        if (noShows.indexOf(tab.tabButton) !== -1) {
          return false;
        }
      }

      if (db) {
        if (db.indexOf('NIN') !== -1 && tab.tabButton === 'Tax Info') {
          return true;
        } else if (tab.tabButton !== 'Tax Info') {
          return true;
        }
        return false;
      }
    });

    layout = (
    <div className="adjusted-navpills">
      <NavPills
        color="warning"
        tabs={tabLayout}
      />
    </div>
    );
    let cardStyle = {
      paddingLeft: '8px',
      marginTop: 10
    };
    if (this.props.currentItem && !this.props.openModal) {
      cardStyle = {
         display: 'block',
         width: '100%',
         marginTop: 10,
         transitionDuration: '0.3s',
         paddingLeft: '10px',
         paddingRight: '10px',
         // height: '500px',
         overflow: 'hidden',
         fontSize: '12px'
      }
    }

    let miniModalAlert = '';
    if (this.state.deleteAlert) {
      miniModalAlert = (<SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => this.successDelete()}
        onCancel={() => this.cancelDelete()}
        confirmBtnText="Yes, remove it!"
        cancelBtnText="Cancel"
        confirmBtnCssClass={
          classes.button + " " + classes.success
        }
        cancelBtnCssClass={
          classes.button + " " + classes.white
        }
        showCancel
      >
        Do You Want to Remove this Item {this.props.currentItem} from {this.props.db.name.split('_')[0]} DB ?
      </SweetAlert>);
    }

    let showErrorMessage = false;
    if (this.props.db) {
      if (this.props.db.name) {
        if (this.props.message) {
          if (Array.isArray(this.props.message)) {
            if (this.props.message.indexOf(this.props.db.name) !== -1) {
              showErrorMessage = true;
            }
          }
        }
      }
    }

    return (
            <Card style={cardStyle}>
              {this.state.ask && <GINask
                  command={this.handleCopyToAll}
                  title="Are you Sure?"
                  content="This will apply the General and Measurement Values (except Variants) to All DBs."
                  close={this.closeAsk}
                />
              }
              {miniModalAlert}
              { !this.props.openModal &&
              <CardHeader style={{paddingBottom:'0'}}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={3} style={{backgroundColor:this.props.db.color, borderRadius:'5px'}}>
                    <h4 className={classes.cardTitle} style={{marginTop:'10px'}}>
                      <strong>{this.props.db.name.split('_')[0]}</strong>
                    </h4>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={8}>
                  <Grid container spacing={0} alignItems="flex-end">
                    <Grid item xs={11}>
                      <TextField
                          id="standard-read-only-input"
                          label={this.props.currentItem ? 'Item Code':'Enter Item Code'}
                          className={classes.textField}
                          margin="normal"
                          InputProps={{
                            onClick: () => {
                              if (!this.props.loadingGIN) {
                                if (!this.props.editOptions[this.props.db.name]) {
                                  this.props.fetchOptions(this.props.db.name);
                                }
                                this.props.setModal(this.props.db.name);
                                this.props.emptyList();
                              }
                            },
                            readOnly: true,
                            value: this.props.currentItem,
                            disabled: this.props.loadingGIN ? true : false
                            }}
                          style={{width:'100%'}}
                          ref={this.textInput}
                        />
                      <Fade in={this.state.makeVisible}>
                        <div className="parentable">
                          <div style={{position:'absolute',bottom:-15,right:0,color:'green',fontSize:10}}>Successfully copied to clipboard!</div>
                        </div>
                      </Fade>
                    </Grid>
                    {this.props.currentItem && <Grid item xs={1}>
                      <Tooltip title="Copy item code">
                        <IconButton
                          onClick={() => this.copyStringToClipboard(this.props.currentItem)}
                        >
                          <ContentCopy />
                        </IconButton>
                      </Tooltip>
                    </Grid>}
                  </Grid>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={1}>
                    {this.props.currentItem && <a className="hovered" style={{color:'red'}} onClick={() => {
                      this.setDeleteAlert();
                    }}><Close /></a>}
                    <div className="parent">
                      {this.props.openedDBs.length > 1 && this.props.baseItemDescription &&
                    <Tooltip title="Copy to all DBs">
                      <Button
                        size="sm"
                        color="success"
                        style={{top:43,right:29,position:'absolute',fontSize:10,paddingLeft:10,paddingRight:10}}
                        onClick={() => {
                          this.openAsk();
                        }}
                      >
                        COPY
                      </Button></Tooltip>}
                    </div>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              }
              <CardBody style={{paddingTop:'0'}}>
                {this.props.lockMsg && <div style={{padding:20,textAlign:'center',color:'red'}}>{this.props.lockMsg}</div>}
                <div>
                  {this.props.currentItem && layout}
                  {/*required.length > 0 && <div style={{marginTop:'20px',marginBottom:'20px'}} className="text-center">
                    <span className="red-text">{required.map(r => <span key={r} className="separate-span">{r.replace(/_/g,' ').toUpperCase()} </span>)} required</span>
                  </div>*/}
                </div>
                {/*this.props.loadingGIN && <div style={{margin:'20px auto',textAlign:'center'}}><FadeLoader /></div>*/}
                {showErrorMessage && <div style={{margin:'40px auto',textAlign:'center',color:'red'}}>
                  {this.props.message}
                </div>}
              </CardBody>
            </Card>
    );
  }
}

const mapStateToProps = (state) => {
  let rboDict = {}, rboReverseDict = {};
  const options = state.gin.options;
  Object.keys(options).forEach(db => {
    rboDict[db] = {};
    rboReverseDict[db] = {};
    const opts = options[db].rbos;
    if (opts) {
      opts.forEach(o => {
        rboDict[db][o.FirmCode.toString()] = o.FirmName;
        rboReverseDict[db][o.FirmName] = o.FirmCode.toString();
      });
    }
  });

  return {
    rboDict,
    rboReverseDict,
    openedDBs: state.gin.openedDBs,
    editOptions: state.gin.options,
    nin_tax: state.gin.nin_tax,
    brands: state.gin.brands,
    subgroups: state.gin.subgroups,
    packagingtypes: state.gin.packagingtypes,
    newItemList: state.gin.newItemList,
    loadingGIN: state.gin.loading,
    message: state.gin.message
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchItemMOCsAll: (igc, dbs) => {
      dispatch(actions.fetchItemMOCsAll(igc, dbs));
    },
    fetchItemMOCs: (igc, db) => {
      dispatch(actions.fetchItemMOCs(igc, db));
    },
    setAllGIN: (dataObj) => {
      dispatch(actions.setAllGIN(dataObj));
    },
    updateGINEdit: (data) => {
      dispatch(actions.updateGINEdit(data));
    },
    ginNewItemRemove: (db) => {
      dispatch(actions.ginNewItemRemove(db));
    },
    fetchBPName: (cardcode, db) => {
      dispatch(actions.fetchBPNameByCode(cardcode, db));
    },
    emptyList: () => {
      dispatch(actions.emptyItemList());
    },
    emptyItemByDB: (db) => {
      dispatch(actions.emptyItemByDB(db));
      dispatch(actions.removeFromOpenedDBsList(db));
    },
    compareAll: () => {
      dispatch(actions.fetchItemCompare());
    },
    editModeOn: () => {
      dispatch(actions.editModeOn());
    },
    setEdit: (data) => {
      dispatch(actions.setGINEdit(data));
    },
    fetchOptions: (db) => {
      dispatch(actions.fetchGINOptions(db));
    },
    editTableModeOn: () => {
      dispatch(actions.editTableModeOn());
    },
    editTable2ModeOn: () => {
      dispatch(actions.editTable2ModeOn());
    },
    setEditTable: (type) => {
      dispatch(actions.setEditTable(type));
    },
    setEditTable2: (type) => {
      dispatch(actions.setEditTable2(type));
    },
    clearGINmessage: () => {
      dispatch(actions.clearGINmessage());
    },
    updateItemLock: (db, itemcode, action) => {
      dispatch(actions.updateItemLock(db, itemcode, action));
    }
  }
};

GINbox.defaultProps = {
  required: []
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(sweetAlertStyle)(GINbox));
