import cloneDeep from "lodash/cloneDeep";

const helpers = {};

helpers.reattachAddressColon = (oname, address) => {
  if (oname) {
    if (oname.indexOf(':') !== -1) {
      const oArr = oname.split(':');
      return `${address}:${oArr[1]}`;
    }
  }
  return address;
}

helpers.reattachAddressesColon = (addresses_to_be_copied) => {
  let reattached = [];
  const addresses = cloneDeep(addresses_to_be_copied);
  if (addresses) {
    if (typeof addresses === 'object') {
      if (addresses.length > 0) {
        addresses.forEach((a) => {
          a.shipname = helpers.reattachAddressColon(a.oname, a.shipname);
          reattached.push(a);
        });
      }
    }
  }
  return reattached;
}

helpers.removeAddressColonArr = (addresses_to_be_copied) => {
  let filtered = [];
  const addresses = cloneDeep(addresses_to_be_copied);
  if (addresses) {
    if (typeof addresses === 'object') {
      if (addresses.length > 0) {
        addresses.forEach((a) => {
          a.shipname = helpers.removeAddressColon(a.shipname);
          filtered.push(a);
        });
      }
    }
  }
  return filtered;
}

helpers.removeAddressColon = (address) => {
  if (address) {
    if (address.indexOf(':') !== -1) {
      const addressArr = address.split(':');
      return addressArr[0];
    }
  }
  return address;
}

export default helpers;
