import React from "react";
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import checkPermissions from '../../shared/checkPermissions';
import checkActions from '../../shared/checkPermissionsActions';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { formatDate, generateExcel, generateExcel2, commatize } from 'shared/utility';
import moment from 'moment';
import Datetime from 'react-datetime';
import Button from "../../components/CustomButtons/Button.jsx";
import { FadeLoader, PulseLoader } from 'react-spinners';
import ReactTable from "react-table";
import { returnActivity } from "./helpers";
import MultipleSelect from "components/CustomSelect/CustomMultipleSelect.jsx";
import Select from "components/CustomSelect/CustomSelect.jsx";

class GINsingle extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: null,
      selectedBP: null,
      showModal: false,
      loadingHistory: false,
      data: props.historyData,
      startDate: null,
      endDate: null,
      page: 0,
      pageSize: 10
    }
  }

  handleDateFilterClick = () => {
    this.props.openFilter();
  }

  renderTable = (currentRecords) => {
    const table =
  (<div>
    <GridContainer className="padbottom10">
      <GridItem xs={12} sm={12} md={12}>
        <div className="history-download-csv-1">
          <Button
            color="warning"
            onClick={() => {
              const currentRecords = this.selectTable.getResolvedState().sortedData;
              const cols = [
                  {wch:30}, // GIN
                  {wch:10}, // type
                  {wch:10}, // activity
                  {wch:20}, // date time
                  {wch:25}, // user
              ]
              let data = [
                ['GIN','Type','Activity','Date/Time','User']
              ];
              currentRecords.forEach((cr) => {
                console.log(cr);
                data.push(
                  [cr.gin,cr.type,cr.activity,moment(cr.cs1).format("MM-DD-YYYY hh:mm a"),cr.user]
                );
              });
              generateExcel2({cols,data}, 'gin_single_view.xlsx');
            }}
          >
            Download Excel
          </Button>
        </div>
      </GridItem>
    </GridContainer>
    <div className="table-adjust2 table-adjust3">
      <ReactTable
        onPageChange={page => this.setState({ page })}
        onPageSizeChange={(pageSize, page) =>
          this.setState({ page, pageSize })}
        minRows={0}
        ref={(r) => {
          this.selectTable = r;
        }}
        getTrProps={(state, rowInfo) => {
          if (rowInfo && rowInfo.row) {
            return {
              onClick: (e) => {
                const gin = rowInfo.original;
                const date = moment(gin.timestamp).format("MM/DD/YYYY hh:mm A");
                // open Review
                console.log('opening new review modal', gin);
                this.props.loadHistoryEntry(gin.id);
                this.props.openReviewModal(gin.user, date, gin.activity);
                if (gin.data_source) {
                  this.props.loadImage(`${gin.data_source}_${gin.gin}`);
                }
                this.setState({
                  selected: rowInfo.index
                });
              }
            }
          } else {
            return {}
          }
        }}
        data={
            this.props.historyData.map((entry, key) => { // map users to table rows
              const activity = returnActivity('activity', entry.activity);
                return ({
                    id: entry.id,
                    gin: entry.gin,
                    user: entry.name,
                    activity,
                    type: activity.indexOf('EXISTING') === -1 ? 'NEW' : 'EXISTING',
                    timestamp: entry.timestamp,
                    data_source: entry.data_source
                })
            })
        }
        filterable
        defaultSorted={[
          {
            id: "cs1",
            desc: false
          }
        ]}
        columns={[
            {
                Header: "GIN",
                accessor: "gin",
                filterMethod: (filter, row) => {
                  var filterValue = filter.value.toLowerCase();
                  if (filterValue.length > 0) {
                      return row[filter.id].toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;
                  } else {
                      return true;
                  }
                }
            },
            {
                Header: "Activity",
                accessor: "activity",
                filterMethod: (filter, row) => {
                    const chosen = filter.value;
                    if (chosen.length > 0) {
                        return chosen.indexOf(row[filter.id]) !== -1;
                    } else {
                        return true;
                    }
                },
                Filter: ({filter, onChange}) => {
                    return <div className="gmp-filter"><MultipleSelect
                        options={returnActivity('activityList')}
                        label={''}
                        choose={onChange}
                    /></div>
                },
                minWidth: 100
            },
            {
                Header: "Type",
                accessor: "type",
                filterMethod: (filter, row) => {
                    const chosen = filter.value;
                    if (chosen.length > 0) {
                        if (chosen === 'ALL') return true;
                        return chosen.indexOf(row[filter.id]) !== -1;
                    } else {
                        return true;
                    }
                },
                Filter: ({filter, onChange}) => {
                    return <div className="gmp-filter"><Select
                        options={['ALL','NEW','EXISTING']}
                        label={''}
                        choose={onChange}
                        defaultValues="ALL"
                    /></div>
                },
                maxWidth: 80
            },
            {
                id: 'cs1',
                Header: "Date/Time",
                accessor: "timestamp",
                Cell: cell => {
                  const d = cell.original;
                  return moment(d.timestamp).format("MM/DD/YYYY hh:mm a")
                },
                sortMethod: (a, b) => {
                  a = new Date(a).getTime();
                  b = new Date(b).getTime();
                     return b > a ? 1 : -1;
                },
                filterMethod: (filter, row) => {
                    const startDate = this.state.startDate,
                          endDate = this.state.endDate;
                    if (startDate && endDate) {
                      const a = new Date(filter.value).getTime();
                      if (a > startDate && a < endDate) {
                        return true;
                      }
                    }
                },
                Filter: ({filter, onChange}) => {
                    return <div className="adjustDateFilterBtn">

                              <div>
                                {
                                  (this.props.historyStartDate || this.props.historyEndDate) ?
                                  <div
                                    style={{fontSize:'11px',color:'#5e5e5e'}}
                                    className="datetime-box"
                                    onClick={() => {
                                    this.handleDateFilterClick();}}
                                  >
                                    <div style={{lineHeight:'1',marginBottom:'5px'}}>{this.props.historyStartDate} -</div>
                                    <div style={{lineHeight:'1',marginTop:'5px'}}>{this.props.historyEndDate}</div>
                                  </div> :
                                  <Button
                                    color="white"
                                    onClick={() => {
                                    this.handleDateFilterClick();}}
                                  >Filter by Date</Button>
                                }
                              </div>
                            </div>
                },
                maxWidth: 150
            },
            {
                Header: "User",
                accessor: "user",
                Cell: cell => {
                  const d = cell.original;
                  return d.user;
                },
                filterMethod: (filter, row) => {
                  var filterValue = filter.value.toLowerCase();
                  if (filterValue.length > 0) {
                      return row[filter.id].toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;
                  } else {
                      return true;
                  }
                }
            },
        ]}
        defaultPageSize={10}
        showPaginationTop
        showPaginationBottom
        className="-striped -highlight"
        filtered={this.props.historyFiltered}
        onFilteredChange={filtered => {
            console.log('this is the filtered',filtered);
            // this.props.updateGMPHistoryFilter(filtered);
          }
        }
    >
    {(state, makeTable, instance) => {
        let recordsInfoText = "";

        const { filtered, pageRows, pageSize, sortedData, page } = state;

        if (sortedData && sortedData.length > 0) {
          let isFiltered = filtered.length > 0;

          let totalRecords = sortedData.length;

          let recordsCountFrom = page * pageSize + 1;

          let recordsCountTo = recordsCountFrom + pageRows.length - 1;

          if (isFiltered)
            recordsInfoText = `Showing ${recordsCountFrom}-${recordsCountTo} of ${totalRecords.toLocaleString()} filtered activities`;
          else
            recordsInfoText = `Showing ${recordsCountFrom}-${recordsCountTo} of ${totalRecords.toLocaleString()} activities`;
        } else recordsInfoText = "No activity";

        return (
          <div className="main-grid">
            {makeTable()}
            <div style={{marginTop:'30px'}}>
              <div style={{textAlign:'center'}}>{recordsInfoText}</div>
            </div>
          </div>
        );
      }}
    </ReactTable>
</div>
</div>);

  return table;
  }

  render() {
    const override = {
        display: 'block',
        margin: '0 auto',
        borderColor: 'red'
    };
    let loader = <div style={{textAlign:'center',margin:'0 auto'}} className="centered-loader">
    <FadeLoader
        style={override}
        sizeUnit={"px"}
        size={150}
        color={'#123abc'}
        loading={true}
    /></div>

    return (
    <div>
      {this.props.historyData.length > 0 ? <div>{this.renderTable(this.props.historyData)}</div> : <div></div> }
      {this.props.loading && <div>
        {loader}
      </div>}
    </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.gin.loadPreview
  }
}

const mapDispatchToProps = dispatch => {
  return {
    loadImage: (filename) => {
      dispatch(actions.ginLoadImage(filename))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GINsingle);
