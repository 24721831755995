import React, { useState } from 'react';
import Table from 'components/Table/Table.jsx';
import { Close } from '@material-ui/icons';
import { ClipLoader } from 'react-spinners';
import Button from '../../../components/CustomButtons/Button';
import { Dialog } from '@material-ui/core';
import CustomInput from "components/CustomInput/CustomInput.jsx";
import { clone } from '../../../shared/utility';
import { useEffect } from 'react';
import download from 'downloadjs';
import { domain } from 'shared/devStatus';

const TablePart = props => {
    return <Table 
        tableHeaderColor="primary"
        tableHead={["TXT file name", "Market", "Style Category", "Style Name", 
            "SKU Number", "UPC", "Color", "Size", "Order Qty.", "Production Qty.", "Starting Serial", "Ending Serial", 
            "Starting EPC", "Ending EPC"].map(m => {
                return <strong>{m}</strong>
            })}
        tableData={props.tableArr}
        coloredColls={[3]}
        colorsColls={["primary"]}
    />
}

const checkForRange = (data) => {
    let aboveRange = false;
    if (data) {
        const upcs = Object.keys(data);
        for (let i = 0; i < upcs.length; i++) {
            const upc = upcs[i];
            const upc_data = data[upc];
            if (upc_data) {
                for (let j = 0; j < upc_data.length; j++) {
                    const row = upc_data[j];
                    if (row.ending_serial) {
                        const num = parseInt(row.ending_serial);
                        if (num > 20000000000) {
                            aboveRange = true;
                        }
                    }
                }
            }
        }
    }
    return aboveRange;
}

const AllBirdsImporterPreview = props => {
    console.log('AllBirdsImporterPreview', props);
    const [openedSerialForm, setOpenedSerialForm] = useState(false);
    const [downloadMode, setDownloadMode] = useState(false);
    const [reload, setReload] = useState(false);
    const [config, setConfig] = useState({
        position: null,
        type: null,
        upc: null
    });
    const [waitForExcel, setWaitForExcel] = useState(false);
    const [serialValue, setSerialValue] = useState('');
    const [status, setStatus] = useState(0);
    const [hasDownloadedExcelFileAlready, setHasDownloadedExcelFileAlready] = useState(false);
    const { importerData } = props;

    useEffect(() => {
        let allEntries = 0;
        let completeEntries = 0;
        if (importerData) {
            Object.keys(importerData).forEach(upc => {
                if (importerData[upc]) {
                    importerData[upc].forEach(d => {
                        allEntries++;
                        console.log('status d', d)
                        if ((d.starting_serial && d.ending_serial) && (d.starting_epc && d.ending_epc)) {
                            completeEntries++;
                        };
                    })
                }
            })
        }
        console.log('status', completeEntries, allEntries, status);
        setStatus(Math.floor((completeEntries / allEntries) * 100));
    }, [importerData]);

    const aboveRange = checkForRange(props.importerData);
                                                                  
    const validSerialValue = serialValue ?
        parseInt(serialValue) > 20000000000 || parseInt(serialValue) < 10000000001 ?
            false
        :
            true
    : 
        false;

    useEffect(() => {
        if (reload) {
            props.getRfidAllBirds(props.importerData, {
                filenames: JSON.stringify(props.files),
                final: false
            }, props.forceError, props.overrideGS1, props.shouldOverrideGS1Length);
            setReload(false);
        }
    }, [props.importerData]);

    useEffect(() => {
        if (waitForExcel) {
            console.log('download excel', props.excel_id);
            if (props.excel_id) {
                download(`${domain}/assets/${props.excel_id}`);
                // if (props.restart) {
                //     props.restart();
                // }
                setWaitForExcel(false);
            }
        }
    }, [props.excel_id]);

    useEffect(() => {
        if (props.setError) props.setError(false);
    }, []);

    const renderData = (data, upc) => {
        let dat = [];
        data.forEach((d,i) => {
            dat.push([
                d.filename,
                d.market,
                d.style_category,
                d.style_name,
                d.sku_number,
                d.upc,
                d.color,
                d.size,
                d.order_quantity,
                d.qty,
                d.starting_serial ? <div>
                    <input className="solo-input" onChange={() => {}} value={d.starting_serial} onClick={() => {
                        if (!props.readOnly) {
                            setOpenedSerialForm(true);
                            setConfig({
                                position: i,
                                type: 'allbirds',
                                upc
                            })
                            setSerialValue(d.starting_serial)
                        }
                    }} /><br />
                    {d.within_serial ? 
                        <div style={{color:'orange'}}>A serial number in the range specified has been previously used. </div> 
                    : 
                        ''}
                </div>
                : 
                    <ClipLoader />,
                d.ending_serial ? 
                    d.ending_serial
                : 
                    <ClipLoader />,
                d.starting_epc ? d.starting_epc : <ClipLoader />,
                d.ending_epc ? d.ending_epc : <ClipLoader />
            ])
        })
        return dat;
    }

    const renderTableArr = (data) => {
        let arr = [];
        Object.keys(data).map((upc,i) => {
            const upcArr = data[upc];
            const tableArr = renderData(upcArr, upc);
            tableArr.forEach(ta => {
                arr.push(ta);
            })
        })
        return arr;
    }

    const arrs = () => {
        return renderTableArr(props.importerData);
    }

    console.log('AllbirdsImporterPreview', props);

    return <div>
        <div className="dialog-wrapper" style={{paddingBottom:91,paddingTop:20}}>
                    <div style={{textAlign:'right'}} className="top-dweller">
                        <Close onClick={props.close} />
                    </div>
            <h3>Allbirds</h3>
            {props.error && <div style={{marginTop:20,marginBottom:20,color:'red'}}>
                Warning: Error found.<br />
                {props.canOverrideGS1 && <Button
                    color="rose"
                    onClick={() => {
                        props.close();
                        props.setOpenOverrideBox(true);
                    }}
                >Override GS1 API</Button>}
            </div>}
            <p>{status} % done</p>
            {(props.importerData && !props.error) && <div>
                    <TablePart 
                        tableArr={arrs()}
                        type="allbirds"
                    />
                    {Object.keys(props.importerData).length === 0 ? <center>0 entries</center> : ''}
            </div>}
        </div>
        {!props.readOnly && <div className="bottom-dweller text-center">
            {(props.getAllBirdsLoading || props.excel_loading) && <div className="text-center" style={{marginTop:20,marginBottom:20}}>
                Please wait...
                <ClipLoader />    
            </div>}
            {aboveRange && <div style={{color:'red'}}>Number must be between 10,000,000,001 to 20,000,000,000.</div>}
            {!props.getAllBirdsLoading && <Button style={{marginRight:20}} color="success"
                                disabled={(aboveRange || status !== 100) ? true : false}
                                onClick={() => {
                                    setWaitForExcel(true);
                                    setDownloadMode(true);
                                    props.getRfidAllBirds(props.importerData, {
                                        filenames: JSON.stringify(props.files),
                                        final: true
                                    }, false, props.overrideGS1, props.shouldOverrideGS1Length);
                                }}
            >CONVERT AND PROCESS</Button>}
        </div>}
        {((props.readOnly || downloadMode) && !props.getAllBirdsLoading) && <div className="bottom-dweller text-center">
            {!waitForExcel && <Button style={{marginRight:20}} color="success"
                                onClick={() => {
                                    setWaitForExcel(true);
                                    if (props.id) props.getRfidImporterExcel(props.id)
                                }}
            >DOWNLOAD EXCEL</Button>}
            {waitForExcel && <div className="text-center" style={{marginTop:20,marginBottom:20}}>
                Please wait...
                <ClipLoader />    
            </div>}
        </div>}

        {openedSerialForm && <Dialog
            open={openedSerialForm}
            onClose={() => {
                setOpenedSerialForm(false);
            }}
            fullWidth={true}
            maxWidth="sm"
        >
            <div className="dialog-wrapper">
                <CustomInput
                    labelText="Starting Serial"
                    formControlProps={{
                        fullWidth: true
                    }}
                    inputProps={{
                        onChange: (e) => {
                            setSerialValue(e.target.value);
                        },
                        value: serialValue,
                        type: 'number'
                    }}
                    error={(!serialValue || !validSerialValue) ? true : false}
                />
                {!validSerialValue && <div style={{color:'red'}}>Serial Number must be between 10,000,000,001 to 20,000,000,000</div>}
                <hr />
                <Button color="primary" disabled={!validSerialValue} style={{marginRight:20}} onClick={() => {
                    const newDat = clone(props.importerData);
                    newDat[config.upc][config.position].starting_serial = serialValue;
                    setReload(true);
                    props.setRfidAllBirds(newDat);
                    setOpenedSerialForm(false);
                }}>UPDATE</Button>
                <Button color="white"
                    onClick={() => {
                        setOpenedSerialForm(false);
                    }}
                >CANCEL</Button>
            </div>
        </Dialog>}
    </div>
}

export default AllBirdsImporterPreview;