import React, { useState, useEffect } from 'react';
import { Dialog } from '@material-ui/core';
import SAPMonitorOrderDetailsTable from './SAPMonitorOrderDetailsTable';
import SAPMonitorBPDetailsTable from './SAPMonitorBPDetailsTable';
import SAPMonitorScenario from './SAPMonitorScenario';
import VisualizeTable from './VisualizeTable';
import Button from '../../components/CustomButtons/Button';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Close, Check } from '@material-ui/icons';
import { connect } from "react-redux";
import * as actions from "store/actions";
import { updateObject, mt } from 'shared/utility';
import sapMonitorData from 'containers/SAPMonitor/SAPMonitorLogic';
import { PulseLoader } from 'react-spinners';
import { commatize, makeNum, unicodeToChar } from '../../shared/utility';
import { db_extension } from 'shared/activeDBs';
const { paymentMethods, defaultDetails, getPaymentMethod, getShipping, getCustomerRefNumber,
    getOCPR, getGMPID, getAccountBalance, getRBO, getAddress, getBillingAddressName, getEDIVendorCodes,
    getMagentoRBOVendorCodes, getSO, getShippingAddress, getBillingAddress, getBillingPhone, getShippingZipcode,
    getCustomAttribute, getShippingLocationNames
} = sapMonitorData;
const paymentMethodsDict = {};
Object.keys(paymentMethods).forEach(pm => {
    paymentMethods[pm].forEach(field => {
        paymentMethodsDict[field] = pm;
    })
})
const { checkBPequals } = sapMonitorData;
const checkConsistency = (cie) => {
    let consistent = true;
    if (cie) {
        cie.forEach(c => {
            if (!c[3]) {
                consistent = false;
            }
        })
    }
    return consistent;
}

const makeDate = (str) => {
    const arr1 = str.split(' ')[0];
    const arr2 = arr1.split('-');
    return `${arr2[1]}/${arr2[2]}/${arr2[0]}`;
}

const round2 = (total) => {
     return Math.round(total * 100) / 100;
}

const addUp = (arr, field) => {
    let total  = 0;
    if (arr) {
        if (typeof arr === 'object') {
            if (field === 'U_ITEMTOT') {
            
                arr.forEach(a => {
                    const qty = a.Quantity;
                    const price = a.Price;
                    if (qty && price) total += parseFloat(qty) * parseFloat(price)
                })
            } else {
                arr.forEach(a => {
                    if (a[field]) total += parseFloat(a[field])
                })
            }
            
        }
    }   
    console.log('the total', total);
    return round2(total);
}

const SAPMonitorOrderDetails = props => {
    const [viewBP, setViewBP] = useState(false);
    const [viewOrder, setViewOrder] = useState(false);
    const [details, setDetails] = useState({});
    const [magentoDetails, setMagentoDetails] = useState({});
    const [theScenario, setTheScenario] = useState(0);
    const [detailsMagento, setDetailsMagento] = useState({});
    const [showBPAtables, setShowBPAtables] = useState(false);
    const { sap_order_details, sapBpDetails, sapBpaDetails, loading_getSapBpDetails, loading_order_details,
        loading_getSapBpaDetails, magentoOrderOther, getSapBpaItemDetails, sap_bpa_item_details,
        magento_bp_data
    } = props;

    const AA_EDI_Programs_Staging = props.sapBpaDetails ? props.sapBpaDetails.AA_EDI_Programs_Staging : null;
    const AA_MOC = props.sapBpaDetails ? props.sapBpaDetails.AA_MOC : null;
    const AA_RBO_Vendor_Staging = props.sapBpaDetails ? props.sapBpaDetails.AA_RBO_Vendor_Staging : null;
    const ERP_CreateSO_DPI_IP_Log = props.sapBpaDetails ? props.sapBpaDetails.ERP_CreateSO_DPI_IP_Log : null;
    const active_rbo = props.sapBpaDetails ? props.sapBpaDetails.active_rbo : null;
    const eCom_Customer_Addresses = props.sapBpaDetails ? props.sapBpaDetails.eCom_Customer_Addresses : null;
    const eCom_OrderHeaderStaging = props.sapBpaDetails ? props.sapBpaDetails.eCom_OrderHeaderStaging : null;
    const ERP_CustomerStaging = props.sapBpaDetails ? props.sapBpaDetails.ERP_CustomerStaging : null;

    const orderData = props.order;
    const order = orderData.orderData;
    const ord = order;

    const dbname = orderData ? orderData.database : '';
    const magento_customer_email = orderData ? orderData.magento_customer_email : '';
    const activeRBOs = active_rbo;

    console.log('SAPMonitorOrderDetails', props);

    const makeList = (narr, field, val_only) => {
        if (!narr) return null;
        let arr = [];
        let carr = [];
        if (narr) {
            console.log('narr', narr);
            if (typeof narr === 'object') {
                narr.forEach(n => {
                    let nn = n[field];
                    if (field === 'sap_item_code') {
                        nn = n.extension_attributes;
                        if (nn) {
                            nn = n.extension_attributes.sap_item_code;
                            if (carr.indexOf(nn) === -1) {
                                arr.push(n)
                                carr.push(nn)
                            }
                        }
                    } else {
                        if (carr.indexOf(nn) === -1) {
                            arr.push(n)
                            carr.push(nn)
                        }
                    }
                })
            }
        }
        console.log('arrs', arr, carr, field);
        if (val_only) return carr.join(', ');
        const final_arr = arr.map((a,i) => {
            if (field === 'sap_item_code') {
                return <div key={`${field}-${i}`}>
                    {a.extension_attributes.sap_item_code ? a.extension_attributes.sap_item_code : ''}
                </div>
            } else {
                return <div key={`${field}-${i}`}>
                    {a[field] ? a[field] : ''}
                </div>
            }
        }).filter(b => {
            if (b) return true;
        })

        
        return final_arr;
    }

    const getItemsPrice = (items) => {
        if (!items) return '';
        return items.map((it,i) => {
            return <div key={`qtyp-${i}`}>
                {it.sku ? it.sku.split(':')[1] : ''} - {it.price ? commatize(it.price) : ''}
            </div>
        })
    }

    const the_database = orderData.database ? orderData.database + db_extension : '';

    useEffect(() => {
        // determine scenario 
        console.log('determining scenario', orderData);
        let scenario = 0;
        const payment_status = getPaymentMethod(orderData.orderData);
        if (payment_status === 'Payment on Credit') {
            scenario = 3;
        } else if (payment_status === 'PBP-Payment by Check or Wire') {
            scenario = 2;
        } else {
            scenario = 1;
        }
        if (scenario) {
            const card_code_obj = {
                card_code: orderData.card_code_single,
                dbname: `${orderData.database}${db_extension}`
            };
            props.getMagentoBpDetails({
                id: orderData.orderData.customer_id
            });
            props.getSmMagentoOrdersOthers(card_code_obj);

            props.getSapOrderDetails({
                magento_order_number: orderData.magento_order_number,
                dbname: `${orderData.database}${db_extension}`
            });
            if (orderData.card_code_single) {
                props.getSapBpDetails(card_code_obj);
                props.getSapBpaDetails(card_code_obj);
            }
            if (orderData.orderData) {
                const ord = orderData.orderData;
                if (ord.items) {
                    if (ord.items.length > 0) {
                        let item_codes = [];
                        let item_codes_obj = [];
                        ord.items.forEach(ic => {
                            if (ic.extension_attributes) {
                                if (ic.extension_attributes.sap_item_code) {
                                    if (item_codes.indexOf(ic.extension_attributes.sap_item_code) === -1) {
                                        item_codes_obj.push({
                                            item_code: ic.extension_attributes.sap_item_code,
                                            dbname: `${orderData.database}${db_extension}`
                                        });
                                        item_codes.push(ic.extension_attributes.sap_item_code);
                                    }
                                }
                            }
                        })
                        console.log('item_codes', item_codes);
                        getSapBpaItemDetails(item_codes_obj);
                    }
                }
            }
            setTheScenario(scenario);
        }

        // set order Magento details 
        if (orderData) {
            const ord = orderData.orderData
            const obj = {
                database: orderData.database,
                company_name: orderData.company,
                bp_code: orderData.card_code_single,
                email: orderData.magento_customer_email,
                contact_first_name: ord.customer_firstname,
                mag_order_number: orderData.magento_order_number,
                mag_user_id: ord.customer_id,
                order_date: orderData.date,
                order_status: orderData.status,
                bill_to_address: getBillingAddress(ord),
                bill_to_address_zip: ord.billing_address ? ord.billing_address.postcode : '',
                shipping_method: getShipping(ord),
                ship_to_address_zip: getShippingZipcode(ord),
                ship_to_address: getShippingAddress(ord),
                //payment_method: getPaymentMethod(ord),
                items_ordered: makeList(ord.items, 'sap_item_code'),
                items_ordered_val: makeList(ord.items, 'sap_item_code', true),
                items_qty: addUp(ord.items, 'qty_ordered'),
                items_unit_price: addUp(ord.items, 'price'),
                total_by_items: addUp(ord.items, 'row_total'),
                subtotal: commatize(ord.subtotal),
                discount: commatize(ord.discount_amount),
                shipping_charge_amount: '',
                order_total_amount: commatize(ord.total_invoiced),
                sap_so_number: getSO(orderData),
                customer_ref_number: getCustomerRefNumber(ord),
                sap_pi_number: ''
            }
            setDetailsMagento(obj);
            // const magentoBPDetails = {
            //     database: orderData.database,
            //     company_name: orderData.company,
            //     bp_code: orderData.card_code_single,
            //     mag_user_id: ord.customer_id,
            //     phone: getBillingPhone(ord),
            //     fax: '',
            //     email: ord.customer_email,
            //     contact_first_name: ord.customer_firstname,
            //     contact_last_name: ord.customer_lastname,
            //     contact_info: '',
            //     billing_address_name: '',
            //     billing_address: getBillingAddress(ord),
            //     shipping_location_names: '',
            //     ship_to_address: getShippingAddress(ord),
            //     bp_currency: ord.base_currency_code,
            //     tpp_assigned_rbo: getMagentoRBOVendorCodes(magentoOrderOther, orderData.card_code_single, the_database),
            //     tpp_assigned_edi_program: getEDIVendorCodes(magentoOrderOther, orderData.card_code_single, the_database, 'EDI'),
            //     tpp_assigned_edit_vendor_code: getEDIVendorCodes(magentoOrderOther, orderData.card_code_single, the_database, 'VENDOR'),
            //     payment_term: getCustomAttribute('sap_payment_terms', magento_bp_data),
            //     credit_limit: getCustomAttribute('sap_credit_limit', magento_bp_data),
            //     account_balance: getCustomAttribute('sap_balance', magento_bp_data),
            //     open_order_amount: '',
            // }
            // setMagentoDetails(magentoBPDetails);
        }
    }, []);

    const payment_term_definitions = sapBpDetails.payment_term_definitions ? sapBpDetails.payment_term_definitions : {};
    const bp_details_magento = magentoOrderOther ?  
        {
            database: orderData.database,
            company_name: orderData.company,
            bp_code: orderData.card_code_single,
            mag_user_id: ord.customer_id,
            phone: getBillingPhone(ord),
            email: ord.customer_email,
            contact_first_name: ord.customer_firstname,
            contact_last_name: ord.customer_lastname,
            billing_address_name: getBillingAddressName(ord),
            billing_address: getBillingAddress(ord),
            shipping_location_names: getShippingLocationNames(ord),
            ship_to_address: getShippingAddress(ord),
            bp_currency: ord.base_currency_code,
            tpp_assigned_rbo: getMagentoRBOVendorCodes(magentoOrderOther, orderData.card_code_single, the_database),
            tpp_assigned_edi_program: getEDIVendorCodes(magentoOrderOther, orderData.card_code_single, the_database, 'EDI'),
            tpp_assigned_edit_vendor_code: getEDIVendorCodes(magentoOrderOther, orderData.card_code_single, the_database, 'RBO'),
            payment_term: getCustomAttribute('sap_payment_terms', magento_bp_data) ? payment_term_definitions[getCustomAttribute('sap_payment_terms', magento_bp_data)] : '',
            credit_limit: getCustomAttribute('sap_credit_limit', magento_bp_data) ? commatize(getCustomAttribute('sap_credit_limit', magento_bp_data)) : '',
            account_balance: getCustomAttribute('sap_balance', magento_bp_data) ? commatize(getCustomAttribute('sap_balance', magento_bp_data)) : '',
            open_order_amount: getCustomAttribute('sap_balance', magento_bp_data) ? commatize(getCustomAttribute('sap_balance', magento_bp_data)) : '',
        }
    :
        {};

    const OCRD = sapBpDetails.bp_info ? sapBpDetails.bp_info : {};
    const contacts = sapBpDetails.contacts;
    const OCPR = getOCPR(contacts, magento_customer_email);
    const addresses = sapBpDetails.addresses;
    const billing_CRD1 = getAddress(addresses, 'B');
    const shipping_CRD1 = getAddress(addresses, 'S');

    const AA_RBO_VENDOR = sapBpDetails.aa_rbo_vendor;
    const rbo = getRBO(AA_RBO_VENDOR, 'rbo', activeRBOs);
    const edi = getRBO(AA_RBO_VENDOR, 'edi', activeRBOs);
    const vc = getRBO(AA_RBO_VENDOR, 'vc', activeRBOs);

    const PTERMS = sapBpDetails.payment_terms ? sapBpDetails.payment_terms : [];
    const payment_term = PTERMS[0] ? PTERMS[0].payment_term : '';

    const account_balance = getAccountBalance(details);
    const open_order_amount = OCRD.OrdersBal ? parseFloat(OCRD.OrdersBal) : ''; // details.open_order_amount ? makeNum(details.open_order_amount.value) : '';

    console.log('BBB', billing_CRD1);
    console.log('SSS', shipping_CRD1);

    let bp_details_sap_shipping = '';
    let bp_details_sap_shipping_name = '';
    if (sap_order_details) {
        if (sap_order_details.RDR12) {
            if (sap_order_details.RDR12[0]) {
                const RDR12 = sap_order_details.RDR12[0];
                bp_details_sap_shipping = `${mt(RDR12.StreetS)} ${mt(RDR12.CityS)} ${mt(RDR12.CountryS)} ${mt(RDR12.ZipCodeS)}`;
                if (addresses) {
                    if (addresses.length > 0) {
                        addresses.forEach(a => {
                            if (a.Street === RDR12.StreetS) {
                                bp_details_sap_shipping_name = a.Address;
                            }
                        })
                    }
                }
            }
        }
    }

    const bp_details_sap = {
        database: dbname,
        company_name: OCRD.CardName,
        bp_code: OCRD.CardCode,
        mag_user_id: getGMPID(contacts, magento_customer_email),
        phone: OCPR.Tel1,
        email: OCPR.E_MailL,
        contact_first_name: OCPR.FirstName,
        contact_last_name: OCPR.LastName,
        billing_address_name: billing_CRD1.Address,
        billing_address: `${mt(billing_CRD1.Street)} ${mt(billing_CRD1.Block)} ${mt(billing_CRD1.City)}`+
            ` ${mt(billing_CRD1.State)} ${mt(billing_CRD1.Country)} ${mt(billing_CRD1.ZipCode)}`,
        shipping_location_names: bp_details_sap_shipping_name,
        ship_to_address: bp_details_sap_shipping,
        bp_currency: OCRD.Currency,
        tpp_assigned_rbo: rbo,
        tpp_assigned_edi_program: edi,
        tpp_assigned_edit_vendor_code: vc,
        payment_term,
        credit_limit: commatize(OCRD.CreditLine),
        account_balance,
        open_order_amount,
    }

    useEffect(() => {
        if (sap_order_details) {
            const obj = {
                database: orderData.database,
                company_name: orderData.company,
                bp_code: orderData.card_code_single,
                email: orderData.magento_customer_email,
                contact_first_name: orderData.orderData ? orderData.orderData.customer_firstname : '' 
            }
            obj.mag_user_id = '';
            if (sapBpDetails) {
                if (sapBpDetails.contacts) {
                    if (sapBpDetails.contacts.length > 0) {
                        sapBpDetails.contacts.forEach(cc => {
                            if (cc.E_MailL) {
                                if (cc.E_MailL == orderData.magento_customer_email && cc.U_GMPID) {
                                    obj.mag_user_id = cc.U_GMPID;
                                }
                            }
                        })
                    }
                }
            }
            if (sap_order_details.ORDR) {
                const ORDR = sap_order_details.ORDR;
                obj.mag_order_number = ORDR.U_S1C_Mag_OrderID;
                obj.order_date = ORDR.DocDate ? makeDate(ORDR.DocDate) : '';
                obj.order_status = ORDR.DocStatus;
                obj.shipping_method = ORDR.TrnspName ? ORDR.TrnspName : '';
                obj.order_total_amount = ORDR.DocTotal ? parseFloat(ORDR.DocTotal) : '';
                obj.sap_so_number = ORDR.DocNum;
                obj.customer_ref_number = ORDR.NumAtCard;
            }
            if (sap_order_details.RDR1) {
                const RDR1 = sap_order_details.RDR1;
                obj.items_ordered = makeList(RDR1, 'ItemCode');
                obj.items_ordered_val = makeList(RDR1, 'ItemCode', true);
                obj.items_qty = addUp(RDR1, 'Quantity');
                obj.items_unit_price = addUp(RDR1, 'PriceBefDi');
                obj.total_by_items = addUp(RDR1, 'U_ITEMTOT');
                const subtotal_added = addUp(RDR1, 'LineTotal')
                obj.subtotal = subtotal_added ? commatize(subtotal_added) : '';
                obj.discount = sap_order_details.ORDR ? commatize(sap_order_details.ORDR.DiscPrcnt) : ''; // addUp(RDR1, 'DiscPrcnt');
            }
            if (sap_order_details.RDR12) {
                const RDR12 = sap_order_details.RDR12 ? sap_order_details.RDR12.length > 0 ? sap_order_details.RDR12[0] : {} : {};
                // obj.bill_to_address = `${mt(RDR12.StreetB)} ${mt(RDR12.CountryB)}, ${mt(RDR12.ipCodeB)}, ${mt(RDR12.ZipCodeB)}, ${mt(RDR12.BlockB)}, ${mt(RDR12.StateB)}`;
                // obj.ship_to_address = `${mt(RDR12.StreetS)} ${mt(RDR12.CountryS)}, ${mt(RDR12.ipCodeS)}, ${mt(RDR12.ZipCodeS)}, ${mt(RDR12.Blocks)}, ${mt(RDR12.StateS)}`;
                obj.bill_to_address = `${mt(RDR12.StreetB)} ${mt(RDR12.CityB)} ${mt(RDR12.CountryB)} ${mt(RDR12.ZipCodeB)}`;
                obj.bill_to_address_zip = RDR12.ZipCodeB;
                obj.ship_to_address = `${mt(RDR12.StreetS)} ${mt(RDR12.CityS)} ${mt(RDR12.CountryS)} ${mt(RDR12.ZipCodeS)}`;
                obj.ship_to_address_zip = RDR12.ZipCodeS;
            }
            if (sap_order_details.PI) {
                if (sap_order_details.PI.length > 0) {
                    const PI = sap_order_details.PI[0];
                    if (typeof PI === 'object') {
                        obj.sap_pi_number = PI.U_SAP_PI;
                    }
                }
            }
            if (!obj.sap_pi_number) obj.sap_pi_number = '';
            const newObject = updateObject(defaultDetails.order_info, obj);
            setDetails(newObject);
        }
    }, [sap_order_details]);

    const checkIfEquals = checkBPequals({bp_details_magento, bp_details_sap});
    const is_consistent = checkConsistency(checkIfEquals);

    let temp_ardpi = '';
    let new_ardpi = '';
    if (sap_order_details) {
        if (sap_order_details.PI) {
            const PI = sap_order_details.PI;
            if (typeof PI === 'object') {
                if (PI.length > 0) {
                    const PPI = PI[0];
                    new_ardpi = PPI.U_SAP_PI;
                    temp_ardpi = PPI.U_SAP_ARDPI_ORIG;
                }
            }
        }
    }

    return <div>
        <Dialog
            open={props.open}
            onClose={() => {
                props.close();
            }}
            maxWidth={false}
            fullWidth={true}
        >
            <div className="dialog-wrapper">
                <div className="hoverable" onClick={() => { props.close(); }} style={{float:'right'}}>
                    <Close />
                </div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <h3><strong>ORDER # {order.increment_id}</strong></h3>
                    </GridItem>
                    <GridItem xs={12} sm={4} md={4}>
                        <h3><strong>BP Information</strong></h3>
                        <div className="uppercase"><strong>General Information</strong></div>
                        <div>
                            <strong>Database</strong>: {orderData.database}
                        </div>
                        <div>
                            <strong>Company Name</strong>: {orderData.company}
                        </div>
                        <div>
                            <strong>GMP User</strong>: {orderData.magento_customer_email}
                        </div>
                        {!loading_getSapBpDetails && <Button
                            color="white"
                            onClick={() => {
                                setViewBP(true);
                            }}
                        >View Full BP / User Info</Button>} {!loading_getSapBpDetails && <span style={{position:'relative'}}>
                            {is_consistent ? 
                            <span style={{color: 'green',
                            position: 'absolute',
                            top: 0,
                            left: 7}}><Check /></span> 
                        : 
                            <span style={{color: 'red',
                            position: 'absolute',
                            top: 0,
                            left: 7}}><Close /></span>}
                        </span>}
                        {temp_ardpi && <div style={{marginTop:20,marginBottom:20}}>
                            <strong>TEMP ARDPI:</strong> {temp_ardpi}
                        </div>}
                        {new_ardpi && <div style={{marginTop:20,marginBottom:20}}>
                            <strong>NEW ARDPI:</strong> {new_ardpi}
                        </div>}
                        {/* <Button
                            color="white"
                            onClick={() => {
                                setViewOrder(true);
                            }}
                        >View Order Info</Button> */}
                    </GridItem>
                    <GridItem xs={12} sm={4} md={4}>
                        <h3><strong>Order Information</strong></h3>
                        <div className="uppercase"><strong>General Information</strong></div>
                        <div>
                            <strong>Payment Method</strong>: {orderData.payment_method}
                        </div>
                        <div>
                            <strong>Magento Order Number</strong>: {orderData.magento_order_number}
                        </div>
                        <div>
                            <strong>Order Date</strong>: {orderData.date}
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={4} md={4}>   
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        {loading_order_details && <div><PulseLoader /></div>}
                        {loading_getSapBpDetails && <div>Loading...</div>}

                        {(!loading_getSapBpDetails && viewOrder) && <SAPMonitorOrderDetailsTable 
                            order_details_sap={details}
                            order_details_magento={detailsMagento}
                            close={() => {
                                setViewOrder(false);
                            }}
                        />}
                        {(viewBP && sapBpDetails) && <SAPMonitorBPDetailsTable 
                            bp_details_sap={bp_details_sap}
                            bp_details_magento={bp_details_magento}
                            close={() => {
                                setViewBP(false);
                            }}
                        />}
                        {theScenario && <SAPMonitorScenario 
                            orderData={orderData}
                            sapBpDetails={sapBpDetails}
                            orderDetails={details}
                            
                            bp_details_sap={bp_details_sap}
                            bp_details_magento={bp_details_magento}
                            scenario={theScenario}

                            order_details_sap={details}
                            order_details_magento={detailsMagento}
                            
                            sap_order_details={sap_order_details}
                            sapBpaDetails={sapBpaDetails}
                            sap_bpa_item_details={sap_bpa_item_details}

                            openOrder={() => {
                                setViewOrder(true);
                            }}
                        />}
                        <hr />
                        <div style={{textAlign:'right'}}>
                            <small className="hoverable" onClick={() => {
                                setShowBPAtables(!showBPAtables);
                            }}>show integration tables</small>
                        </div>
                        {showBPAtables && <div>
                            {eCom_OrderHeaderStaging && <VisualizeTable
                                title="Order Import" 
                                subtitle="eCom_OrderHeaderStaging"
                                data={eCom_OrderHeaderStaging}
                            />}
                            {eCom_Customer_Addresses && <VisualizeTable
                                title="Magento to SAP Customer" 
                                subtitle="eCom_Customer_Addresses"
                                data={eCom_Customer_Addresses} 
                            />}
                            {ERP_CustomerStaging && <VisualizeTable 
                                title="SAP to Magento Customer"
                                subtitle="ERP_CustomerStaging"
                                data={ERP_CustomerStaging}
                            />}
                            {ERP_CreateSO_DPI_IP_Log && <VisualizeTable 
                                title="Process Paid Down Payments"
                                subtitle="ERP_CreateSO_DPI_IP_Log"
                                data={ERP_CreateSO_DPI_IP_Log}
                            />}
                            {AA_MOC && <VisualizeTable 
                                title="MOC"
                                subtitle="AA_MOC"
                                data={AA_MOC}
                            />}
                            {AA_EDI_Programs_Staging && <VisualizeTable 
                                title="EDI Programs"
                                subtitle="AA_EDI_Programs_Staging"
                                data={AA_EDI_Programs_Staging}
                            />}
                            {active_rbo && <VisualizeTable 
                                title="Active RBO"
                                subtitle="active_rbo"
                                data={active_rbo}
                            />}
                        </div>}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className="text-center">
                            <Button
                                color="primary"
                                onClick={() => {
                                    props.close();
                                }}
                            >CLOSE</Button>
                        </div>
                    </GridItem>
                </GridContainer>
            </div>
        </Dialog>
    </div>
}

const mapStateToProps = (state) => {
	return {
		success: state.sap_monitor.success,
		error: state.sap_monitor.error,
        sap_order_details: state.sap_monitor.sap_order_details,
        loading_order_details: state.sap_monitor.loading_order_details,
        sapBpDetails: state.sap_monitor.sapBpDetails ? state.sap_monitor.sapBpDetails : {},
        loading_getSapBpDetails: state.sap_monitor.loading_getSapBpDetails,
        sapBpaDetails: state.sap_monitor.sapBpaDetails,
        loading_getSapBpaDetails: state.sap_monitor.loading_getSapBpaDetails,
        magentoOrderOther: state.sap_monitor.magentoOrderOther,
        pi_list: state.sap_monitor.pi_list,
        sap_bpa_item_details: state.sap_monitor.bpa_item_details,
        magento_bp_data: state.sap_monitor.magento_bp_data ? state.sap_monitor.magento_bp_data : {}
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getSapOrderDetails: (data) => {
			dispatch(actions.getSapOrderDetails(data));
		},
        getSapBpDetails: (data) => {
            dispatch(actions.getSapBpDetails(data));
        },
        getSapBpaDetails: (data) => {
            dispatch(actions.getSapBpaDetails(data));
        },
        getSapBpaItemDetails: (data) => {
            dispatch(actions.getSapBpaItemDetails(data));
        },
        getSmMagentoOrdersOthers: (magento_order_number) => {
            dispatch(actions.getSmMagentoOrdersOthers(magento_order_number));
        },
        getPiListFilter: (dbname, item_code) => {
            dispatch(actions.getPiListFilter({
                dbname,
                item_code
            }))
        },
        getMagentoBpDetails: (data) => {
            dispatch(actions.getMagentoBpDetails(data));
        }
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SAPMonitorOrderDetails);