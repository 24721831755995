export const options = {
  categories: [
    'CAREINSTRUCTIONS',
    'COMPONENTS',
    'COUNTRYOFORIGIN',
    'DISCLAIMERS',
    'FIBERCONTENT',
    'ITEMDESCRIPTION',
    'SIZES'
  ],
  categories_obj: {
    'CAREINSTRUCTIONS': 'CARE INSTRUCTIONS',
    'COMPONENTS': 'COMPONENTS',
    'COUNTRYOFORIGIN': 'COUNTRY OF ORIGIN',
    'DISCLAIMERS': 'DISCLAIMERS',
    'FIBERCONTENT': 'FIBER CONTENT',
    'ITEMDESCRIPTION': 'ITEM DESCRIPTION',
    'SIZES': 'SIZES'
  },
  categories_obj_choices: [
    {id:'CAREINSTRUCTIONS', desc:'CARE INSTRUCTIONS'},
    {id:'COMPONENTS', desc:'COMPONENTS'},
    {id:'COUNTRYOFORIGIN', desc:'COUNTRY OF ORIGIN'},
    {id:'DISCLAIMERS', desc:'DISCLAIMERS'},
    {id:'FIBERCONTENT', desc:'FIBER CONTENT'},
    {id:'ITEMDESCRIPTION', desc:'ITEM DESCRIPTION'},
    {id:'SIZES', desc:'SIZES'}
  ],
  types: [
    'BASE',
    'RBOSpecific'
  ],
  types_choices: [
    {id:'BASE',desc:'Default'},
    {id:'RBOSpecific',desc:'RBO'}
  ],
  lang:[
    'ENGLISH',
    'FRENCH (CANADIAN)',
    'ITALIAN',
    'SPANISH',
    'PORTUGUESE (EU)',
    'GERMAN',
    'DUTCH',
    'DANISH',
    'RUSSIAN',
    'GREEK',
    'TURKISH',
    'CZECH',
    'BULGARIAN',
    'NORWEGIAN',
    'SWEDISH',
    'JAPANESE',
    'SIMPLIFIED CHINESE',
    'TRADITIONAL CHINESE',
    'KOREAN',
    'VIETNAMESE',
    'HUNGARIAN',
    'SLOVAK',
    'BRAZILIAN PORTUGUESE',
    'SPANISH (MX)'
  ]
}

export const findTitle = (dataObj) => {
  const titles = Object.keys(dataObj);
  if (titles.length > 0) return titles[0];
  return null;
}

export const findData = (dataObj) => {
  if (dataObj) {
    if (Object.keys(dataObj).length) {
      return dataObj[Object.keys(dataObj)[0]];
    }
  }
  return null;
}
