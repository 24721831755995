import { put, call } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import axios from '../axios/axios-translations';
import * as actions from '../actions/translations';
import devStatus from 'shared/devStatus';

export function* loadRBODataSaga(action) {
  yield put(actions.loadRBODataStart());
  try {
    const response = yield axios.get('/rbo_list/');
    console.log('RBO Data success',response.data);
    if (response.data.list) {
      yield put(actions.loadRBODataSuccess(response.data.list));
    } else {
      yield put(actions.loadRBODataFail('error fetching rbo data'));
    }

  } catch (err) {
    console.log('RBO Data error',err);
    yield put(actions.loadRBODataFail(err));
  }
}

export function* readTranslationsSaga(action) {
  yield put(actions.readTranslationsStart());
  try {
    let params = '';
    const o = action.options;
    if (o.category) params += '&category='+o.category;
    if (o.type) params += '&type='+o.type;
    if (o.lang) params += '&lang='+o.lang;
    const response = yield axios.get('/translations/?q=1'+params);
    console.log('RBO Data success',response.data);
    if (response.data) {
      yield put(actions.readTranslationsSuccess(response.data));
    } else {
      yield put(actions.readTranslationsFail('error fetching rbo data'));
    }

  } catch (err) {
    console.log('Translations data fetch error',err);
    yield put(actions.readTranslationsFail('error fetching rbo data'));
  }
}

export function* readTranslationSaga(action) {
  yield put(actions.readTranslationStart());
  try {
    let params = '';
    const o = action.options;
    if (o.category) params += '&category='+o.category;
    if (o.type) params += '&type='+o.type;
    if (o.lang) params += '&lang='+o.lang;
    if (o.id) params += '&id='+o.id;
    const url = '/get_translation/';
    console.log('url', url);
    const response = yield axios.post(url, {
      category: o.category,
      type: o.type,
      lang: o.lang,
      id: o.id
    });
    console.log('Read translation success',response.data);
    if (response.data) {
      yield put(actions.readTranslationSuccess(response.data));
      const response_data_arr = Object.keys(response.data);
      for (let i = 0; i < response_data_arr.length; i++) {
        const table = response_data_arr[i];
        const dat = response.data[table];
        if (dat) {
          if (dat.length) {
            const data = dat[0];
            let missingLanguages = [];
            let searchTerm = data.ENGLISH;
            Object.keys(data).forEach(language => {
              if (data[language]) {
                if (!data[language].trim()) {
                  missingLanguages.push(language);
                }
              } else {
                missingLanguages.push(language);
              }
            });
            console.log('missing languages', missingLanguages, searchTerm, data);
            if (missingLanguages.length) {
              const s_url = '/get_suggestions/';
              console.log('s_url', s_url);
              const suggestion_response = yield axios.post(s_url, {
                data: { searchTerm, missingLanguages }
              });
              if (suggestion_response) {
                if (suggestion_response.data) {
                  console.log('suggestion_response', suggestion_response.data);
                  if (suggestion_response.data.suggestions) {
                    yield put(actions.getSuggestionsSuccess(suggestion_response.data.suggestions, missingLanguages));
                  }
                }
              }
            }
          }
        }
      }
    } else {
      yield put(actions.readTranslationFail('error fetching rbo data'));
    }

  } catch (err) {
    console.log('Translations data fetch error',err);
    yield put(actions.readTranslationFail('error fetching rbo data'));
  }
}

export function* createTranslationSaga(action) {
  yield put(actions.createTranslationStart());
  try {
    const url = '/translation';
    const data = action.data;
    if (data) {
      const response = yield axios.post(url, {
        category: data.category,
        type: data.type,
        data: data.data
      });
      if (response.data) {
        if (response.data.msg === 'OK') {
          yield put(actions.createTranslationSuccess(response.data, data.data));
          return;
        } else if (response.data.msg === 'duplicate') {
          yield put(actions.createTranslationFail('duplicate found'));
          return;
        }
      }
    }
    yield put(actions.createTranslationFail('create translation data error'));
  } catch (err) {
    console.log('Translations data create error',err);
    yield put(actions.createTranslationFail('create translation data error'));
  }
}

export function* updateTranslationSaga(action) {
  yield put(actions.updateTranslationStart());
  try {
    const url = '/translation/';
    const data = action.data;
    if (data) {
      const response = yield axios.put(url, {
        category: data.category,
        type: data.type,
        data: data.data,
        id: data.id
      });
      if (response.data) {
        if (response.data.msg === 'OK') {
          yield put(actions.updateTranslationSuccess(response.data));
          return;
        }
      }
    }
    yield put(actions.updateTranslationFail('update translation data error'));
  } catch (err) {
    console.log('Translations data update error',err);
    yield put(actions.updateTranslationFail('update translation data error'));
  }
}

export function* deleteTranslationSaga(action) {
  yield put(actions.deleteTranslationStart());
  try {
    const url = '/translation/';
    const response = yield axios.delete(url, {
      data: action.data
    });
    if (response.data) {
      console.log('delete response', response.data)
      if (response.data.msg === 'OK') {
        console.log('deleted')
        return yield put(actions.deleteTranslationSuccess(response.data, action.data));
      }
    }
    yield put(actions.deleteTranslationFail('delete translation data error'));
  } catch (err) {
    console.log('Translations data delete error',err);
    yield put(actions.deleteTranslationFail('delete translation data error'));
  }
}

export function* searchTranslationSaga(action) {
  yield put(actions.searchTranslationStart());
  try {
    let params = '';
    const o = action.data;
    console.log('odata', o)
    if (o.category) params += '&category='+o.category;
    if (o.rbo) params += '&rbo='+o.rbo;
    if (o.searchTerm) params += '&searchTerm='+(o.searchTerm.trim());
    const url = '/search_translation/?q=1'+params;
    console.log('url', url);
    const response = yield axios.get(url);
    console.log('Search term success',response.data);
    if (response.data) {
      return yield put(actions.searchTranslationSuccess(response.data));
    }
    return yield put(actions.searchTranslationFail('error fetching rbo data'));
  } catch (err) {
    console.log('Translations data fetch error',err);
    yield put(actions.searchTranslationFail('error fetching rbo data'));
  }
}
