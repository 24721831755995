import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import * as actions from '../../store/actions';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import Button2 from "components/CustomButtons/Button.jsx";
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Typography from '@material-ui/core/Typography';
import { PulseLoader } from 'react-spinners';
import Select2 from 'react-select';
import Select from '@material-ui/core/Select';
import Table from "components/Table/Table.jsx";
import GINSelect from "./GINselect";
import GINReactSelect from "./GINreact-select";
import Datetime from "react-datetime";

const DialogTitle = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500],
  },
}))(props => {
  const { children, classes, onClose, editData } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <span style={{fontSize:'18px'}}>Edit <strong>{editData.itemName}</strong> for <strong>{editData.DB}</strong></span>
      {onClose ? (
        <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit,
  },
}))(MuiDialogActions);

// pricing for all customers
// FLAT PRICE
// gterm -
// gcur -
// gprice -
// gstart -
// gend -
//
// gqtys -
// gterms -
// gcurrs -
// gprices -
// gstarts -
// gends -

class GINtable2 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

      // all customers pricing - flat price
      gterm: this.props.gterm[this.props.editTableData.DB] ? this.props.gterm[this.props.editTableData.DB] : '',
      gcur: this.props.gcur[this.props.editTableData.DB] ? this.props.gcur[this.props.editTableData.DB] : '',
      gprice: this.props.gprice[this.props.editTableData.DB] ? this.props.gprice[this.props.editTableData.DB] : '',
      gstart: this.props.gstart[this.props.editTableData.DB] ? this.props.gstart[this.props.editTableData.DB] : '',
      gend: this.props.gend[this.props.editTableData.DB] ? this.props.gend[this.props.editTableData.DB] : '',

      // all customers pricing - other prices
      gqtysData: this.props.gqtys ? this.props.gqtys[this.props.editTableData.DB] : [],
      gtermsData: this.props.gterms ? this.props.gterms[this.props.editTableData.DB] : [],
      gcurrsData: this.props.gcurrs ? this.props.gcurrs[this.props.editTableData.DB] : [],
      gpricesData: this.props.gprices ? this.props.prices[this.props.editTableData.DB] : [],
      gstartsData: this.props.gstarts ? this.props.gstarts[this.props.editTableData.DB] : [],
      gendsData: this.props.gends ? this.props.gends[this.props.editTableData.DB] : [],

      // all customers pricing, specific customers - input holders
      gqtys: '',
      gterms: '',
      gcurrs: '',
      gprices: '',
      gstarts: '',
      gends: '',

      // specific customers - other input holder
      pricingCustomerData: this.props.editTableData.currentPricingCustomer,
      // holds cardcode, moc, rows (array with array that has 5 elements each)
      allPricingCustomers: this.props.editTableData.allPricingCustomers, // for comparing existent

      pricing_terms: this.props.options[this.props.editTableData.DB] ? this.props.options[this.props.editTableData.DB].terms.pricing_terms : [],
      costing_terms: this.props.options[this.props.editTableData.DB] ? this.props.options[this.props.editTableData.DB].terms.costing_terms : [],
      currencies: this.props.options[this.props.editTableData.DB] ? this.props.options[this.props.editTableData.DB].currencies : [],

      currentRow: null
    }
  }

  handleSelectChange = (value, name) => {
    this.setState({
      [name]: value
    })
  }

  onIntegerChange = (target, e) => {
    if (!isNaN(e.target.value)) {
      this.setState({
        [target]: Math.round(e.target.value)
      })
    }
  }

  onFloatChange = (target, e) => {
    if (!isNaN(e.target.value)) {
      this.setState({
        [target]: e.target.value
      })
    }
  }

  onValueChange = e => {
    this.setState({ valueData: e.target.value.toUpperCase() });
  }

  onDescChange = e => {
    this.setState({ descData: e.target.value.toUpperCase() });
  }

  onValChange = (destination, e) => {
    this.setState({ [destination]: e.target.value });
  }

  handleChange = name => value => {
    this.setState({
      [name]: value,
    });
  };

  handleDateChange = (date, target) => {
    this.setState({
      [target]: (typeof date === 'string') ? date : date.format('MMM DD YYYY').toUpperCase()
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const newEditedData = {
      field: this.props.editData.field,
      value: this.state.valueData,
      db: this.props.editData.db
    }
    console.log('submitted edit...',this.props, newEditedData);
    this.props.updateGINEdit(newEditedData);
    this.props.editTableModeOff();
    this.props.compareAll();
  }

  handleValueSelect = (value) => {
    this.setState({
      valueData: value
    });
  }

  editRow = (row) => {
    const data = this.props.editTableData;
    console.log('edit row', row, data.type);

    if (data.type === 'pricing_allcustomers' && row !== -1) {
      this.setState({
        currentRow: row,
        gqtys: this.state.gqtysData[row],
        gterms: this.state.gtermsData[row],
        gcurrs: this.state.gcurrsData[row],
        gprices: this.state.gpricesData[row],
        gstarts: this.state.gstartsData[row],
        gends: this.state.gendsData[row],
      });
      return;
    }

    if (data.type === 'pricing_allcustomers' && row === -1) {
      this.setState({
        currentRow: row,
        gqtys: 1,
        gterms: this.state.gterm,
        gcurrs: this.state.gcurrs,
        gprices: this.state.gprice,
        gstarts: this.state.gstart,
        gends: this.state.gend,
      });
      return;
    }
  }

  deleteRow = (row) => {
    const data = this.props.editTableData;

    if (data.type === 'pricing_allcustomers' && row !== -1) {
      let gqtys = [...this.state.gqtysData];
      let gterms = [...this.state.gtermsData];
      let gcurrs = [...this.state.gcurrsData];
      let gprices = [...this.state.gpricesData];
      let gstarts = [...this.state.gstartsData];
      let gends = [...this.state.gendsData];

      let gqtysChanged = gqtys.filter((v, i) => {
        return i !== row;
      });
      let gtermsChanged = gterms.filter((v, i) => {
        return i !== row;
      });
      let gcurrsChanged = gcurrs.filter((v, i) => {
        return i !== row;
      });
      let gpricesChanged = gprices.filter((v, i) => {
        return i !== row;
      });
      let gstartsChanged = gstarts.filter((v, i) => {
        return i !== row;
      });
      let gendsChanged = gends.filter((v, i) => {
        return i !== row;
      });
      this.setState({
        gqtysData: gqtysChanged,
        gtermsData: gtermsChanged,
        gcurrsData: gcurrsChanged,
        gpricesData: gpricesChanged,
        gstartsData: gstartsChanged,
        gendsData: gendsChanged
      })
    }

    if (data.type === 'pricing_allcustomers' && row === -1) {
      this.setState({
        gterm: '',
        gcur: '',
        gprice: '',
        gstart: '',
        gend: ''
      });
    }

  }

  handleSubmitPricingAll = () => {
    let gqtys = [...this.state.gqtysData];
    let gterms = [...this.state.gtermsData];
    let gcurrs = [...this.state.gcurrsData];
    let gprices = [...this.state.gpricesData];
    let gstarts = [...this.state.gstartsData];
    let gends = [...this.state.gendsData];
    const row = this.state.currentRow;

    if (!this.state.gqtys || !this.state.gterms || !this.state.gcurrs
      || !this.state.gprices || !this.state.gstarts || !this.state.gends) { // required fields
      return;
    }

    if (row === -1) {
      this.setState({
        gterm: this.state.gterms,
        gcur: this.state.gcurrs,
        gprice: this.state.gprice,
        gstart: this.state.gstarts,
        gend: this.state.gends,
        currentRow: null,
        gqtys: '',
        gterms: '',
        gprices: '',
        gcurrs: '',
        gstarts: '',
        gends: ''
      });
      return;
    }

    if (row || row === 0) {
      // edit
      gqtys[row] = this.state.gqtys;
      gterms[row] = this.state.gterms;
      gcurrs[row] = this.state.gcurrs;
      gprices[row] = this.state.gprices;
      gstarts[row] = this.state.gstarts;
      gends[row] = this.state.gends;
    } else {
      // add
      gqtys.push(this.state.gqtys);
      gterms.push(this.state.gterms);
      gcurrs.push(this.state.gcurrs);
      gprices.push(this.state.gprices);
      gstarts.push(this.state.gstarts);
      gends.push(this.state.gends);
    }
    this.setState({
      gqtysData: gqtys,
      gtermsData: gterms,
      gcurrsData: gcurrs,
      gpricesData: gprices,
      gstartsData: gstarts,
      gendsData: gends,
      currentRow: null,
      gqtys: '',
      gterms: '',
      gprices: '',
      gcurrs: '',
      gstarts: '',
      gends: ''
    });
  }

  handleSavePricingAll = () => {
    this.props.updateItem({
      gterm: this.state.gterm,
      gcur: this.state.gcur,
      gprice: this.state.gprice,
      gstart: this.state.gstart,
      gend: this.state.gend,

      gqtys: this.state.gqtysData,
      gterms: this.state.gtermsData,
      gcurrs: this.state.gcurrsData,
      gprices: this.state.gpricesData,
      gstarts: this.state.gstartsData,
      gends: this.state.gendsData,
      db: this.props.editTableData.DB
    }, 'pricing_allcustomers');
    this.props.editTableModeOff();
  }

  render() {
    console.log('state of table2', this.state, this.props);
    const data = this.props.editTableData;
    const pr = this.state.pricingCustomerData;
    const btnStyle = {marginTop:'5px',marginRight:'5px'};
    let list = null;
    let pricingAllArr = [];

    if (data.type === 'pricing_allcustomers') {
      pricingAllArr.push([
        1,
        this.state.gterm,
        this.state.gcur + ' ' + this.state.gprice,
        this.state.gstart + ' to ' + this.state.gend,
        this.state.gcur + '' + this.state.gprice,
        <div>
          <div className="blue-btn btn-sm" style={btnStyle} onClick={() => { this.editRow(-1); }}>Edit</div>
          <div className="red-btn btn-sm" style={btnStyle} onClick={() => { this.deleteRow(-1); }}>Delete</div>
        </div>
      ]);
      this.state.gqtysData.forEach((v,i) => {
        pricingAllArr.push([
          v,
          this.state.gtermsData[i],
          this.state.gcurrsData[i] + ' ' + this.state.gpricesData[i],
          this.state.gstartsData[i] + ' to ' + this.state.gendsData[i],
          this.state.gcurrsData[i] + ' ' + (v * this.state.gpricesData[i]),
          <div>
            <div className="blue-btn btn-sm" style={btnStyle} onClick={() => { this.editRow(i); }}>Edit</div>
            <div className="red-btn btn-sm" style={btnStyle} onClick={() => { this.deleteRow(i); }}>Delete</div>
          </div>
        ]);
      });
    }

    if (data.type === 'pricing_specific') {

    }

    let pricingTermsOptions = [];
    if (this.props.options[data.DB]) {
      pricingTermsOptions = pricingTermsOptions.concat(this.state.pricing_terms);
    }
    let currencyOptions = [];
    if (this.props.options[data.DB]) {
      currencyOptions = currencyOptions.concat(this.state.currencies);
    }

    console.log('options', pricingTermsOptions, currencyOptions);

    return (
      <div id="gin-modal">
        <Dialog
          onClose={() => {
            this.props.editTableModeOff();
          }}
          aria-labelledby="customized-dialog-title"
          open={this.props.openEdit}
          maxWidth="md"
        >
          <DialogTitle id="customized-dialog-title"
            onClose={() => {
              this.props.editTableModeOff();
            }}
            editData={this.props.editTableData}
          >
          </DialogTitle>
          <div className="item-input-margin" style={{backgroundColor:'#fcfcfc',minHeight:'400px'}}>

          {
            data.type === 'pricing_allcustomers' &&
            <GridContainer>
              <GridItem xs={12} sm={12} md={1}>
                <CustomInput
                  labelText="Quantity"
                  id="pricing_quantity"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: (e) => {
                      this.onIntegerChange('gqtys', e)
                    },
                    value: this.state.gqtys,
                    autoFocus: true,
                    role: 'presentation',
                    autoComplete: 'nope'
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <div className="select-adjust">
                  <GINSelect
                    label="Shipping Terms"
                    options={pricingTermsOptions}
                    choose={this.handleSelectChange}
                    name="gterms"
                    indx=""
                    desc=""
                    default={this.state.gterms}
                  />
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={2}>
                <div className="select-adjust">
                  <GINSelect
                    label="Currency"
                    options={currencyOptions}
                    choose={this.handleSelectChange}
                    name="gcurrs"
                    indx="symb"
                    desc="curr"
                  />
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={2}>
                <CustomInput
                  labelText="Price"
                  id="pricing_price"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: (e) => {
                      this.onFloatChange('gprices', e);
                    },
                    value: this.state.gprices,
                    autoFocus: true,
                    role: 'presentation',
                    autoComplete: 'nope'
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={2}>
                <div className="calendar-adjust">
                  <Datetime
                    inputProps={{ placeholder: "Start Date" }}
                    dateFormat="MMM DD YYYY"
                    timeFormat={false}
                    value={this.state.gstarts}
                    onChange={(date) => {
                      this.handleDateChange(date, 'gstarts')
                    }}
                  />
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={2}>
                <div className="calendar-adjust">
                  <Datetime
                    inputProps={{ placeholder: "End Date" }}
                    dateFormat="MMM DD YYYY"
                    timeFormat={false}
                    value={this.state.gends}
                    onChange={(date) => {
                      this.handleDateChange(date, 'gends')
                    }}
                  />
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Button2
                  color="rose"
                  type="button"
                  onClick={() => {
                    this.handleSubmitPricingAll();
                  }}
                >{this.state.currentRow || this.state.currentRow === 0 ? 'Revise' : 'Add'}</Button2>
                {
                  this.state.currentRow || this.state.currentRow === 0 ? '' :
                  <Button2
                    color="success"
                    type="submit"
                    onClick={() => {
                      this.handleSavePricingAll();
                    }}
                  >Save</Button2>
                }
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <div className="gray-th sm-table">
                  <Table
                    tableHeaderColor="primary"
                    tableHead={["Quantity", "Shipping Terms", "Price", "Effective Dates", "Total Prices"]}
                    tableData={pricingAllArr}
                    coloredColls={[2]}
                    colorsColls={["primary"]}
                  />
                </div>
              </GridItem>
            </GridContainer>
          }

          {
            data.type === 'pricing_specific' &&
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <strong>{pr.name}</strong>
                <p>{pr.moc}</p>
                <div className="gray-th sm-table">
                  <Table
                    tableHeaderColor="primary"
                    tableHead={["Quantity", "Shipping Terms", "Price", "Effective Dates", "Total Prices"]}
                    tableData={pr.rows}
                    coloredColls={[2]}
                    colorsColls={["primary"]}
                  />
                </div>
              </GridItem>
            </GridContainer>
          }

          </div>
          <DialogActions>
            <Button onClick={() => {
              this.props.editTableModeOff();
            }} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    editTableData: state.gin.editTable2Data,
    options: state.gin.options,
    gqtysData: state.gin.gqtys,
    gtermsData: state.gin.gterms,
    gcurrsData: state.gin.gcurrs,
    gpricesData: state.gin.gprices,
    gstartsData: state.gin.gstarts,
    gendsData: state.gin.gends,

    gterm: state.gin.gterm,
    gcur: state.gin.gcur,
    gprice: state.gin.gprice,
    gstart: state.gin.gstart,
    gend: state.gin.gend,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    editTableModeOff: () => {
      console.log('edit table mode off');
      dispatch(actions.editTable2ModeOff());
    },
    updateGINEdit: (data) => {
      dispatch(actions.updateGINEdit(data));
    },
    compareAll: () => {
      dispatch(actions.fetchItemCompare());
    },
    updateItem: (data, mode) => {
      console.log('updating item for table');
      dispatch(actions.updateEditTable2(data, mode));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GINtable2);
