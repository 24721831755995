import React, { useEffect, useState } from 'react';
import { FadeLoader, PulseLoader } from 'react-spinners';
import SweetAlert from "react-bootstrap-sweetalert";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import Button from "components/CustomButtons/Button.jsx";
import { options, findTitle, findData } from "./TranslationsObj";
import TranslationsTable from "./TranslationsTable";

const Translations = (props) => {
  console.log('props', props)
  const [type, setType] = useState(null);
  const [category, setCategory] = useState(null);
  const [lang, setLang] = useState('ENGLISH');

  const title = props.data ? findTitle(props.data) : null;
  const data = props.data ? findData(props.data) : null;

  const the_title = (category && type) ? `AA_TranslationChart_${category}_${type}` : null;

  useEffect(() => {
    props.clearTranslations('except rbo');
  }, [type, category, lang]);

  return (
  <div>
    {props.rbo_data && <div>
      <GridContainer>
        <GridItem xs={6} sm={6} md={2}>
          <CustomSelect
            label="Translation Type"
            options={options.types_choices}
            indx="id"
            desc="desc"
            choose={(e, n) => {
              console.log('e', e);
              setType(e);
            }}
            default={type}
            error={!type ? 'Required' : null}
          />
        </GridItem>
        <GridItem xs={6} sm={6} md={2}>
          <CustomSelect
            label="Translation Category"
            options={options.categories_obj_choices}
            indx="id"
            desc="desc"
            choose={(e, n) => {
              console.log('e', e);
              setCategory(e);
            }}
            default={category}
            error={!category ? 'Required' : null}
          />
        </GridItem>
        {/*<GridItem xs={6} sm={6} md={3}>
          <CustomSelect
            label="Translation Language Key"
            options={options.lang}
            choose={(e, n) => {
              console.log('e', e);
              setLang(e);
            }}
            default={lang}
          />
          </GridItem>*/}
        <GridItem xs={6} sm={6} md={8}>
          {(category && type) && <Button
            color="rose"
            style={{marginRight:20}}
            onClick={() => {
              props.readTranslations({
                category,
                type,
                lang
              });
            }}
          >VIEW ALL TRANSLATIONS</Button>}<br />
          {(props.update_permitted && the_title) && <Button
                          color="success"
                          onClick={() => {
                            console.log('the_title', the_title)
                            props.create(the_title);
                          }}
                        >ADD NEW</Button>}
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={6} sm={6} md={12}>
          <TranslationsTable
            data={data}
            title={title}
            key_value={type === 'BASE' ? lang : 'RBO'}
            rbo_data={props.rbo_data}
            readTranslation={props.readTranslation}
            openModal={props.openModal}
            lang={lang}
            category={category}
            type={type}
            update_permitted={props.update_permitted}
            create={props.create}
          />
        </GridItem>
      </GridContainer>
    </div>}
  </div>
  );
}

export default Translations;
