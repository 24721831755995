import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import { Card, CardContent } from '@material-ui/core';
import { PulseLoader } from 'react-spinners';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import activeDBs from 'shared/activeDBs';
import Table from "components/Table/Table3.jsx";
import GINalert from "./GINalert";
import GINask2 from './GINask2';
import { getUserID } from "shared/authValidation";
import emailTemplate from "./GINemailTemplate";
import { checkIfRBOHasWalmart } from "./GINhelpers";

const stringMe = (n) => {
  if (n || n == 0) {
    return n.toString();
  }
  return n;
}

const checkIfExisted = (v) => {
  if (v) {
    if (typeof v === 'string') {
      return true;
    }
  }
  return false;
}

const comparedQty = (a,b) => {
  if ((a[0] || a[0] == 0) && (b[0] || b[0] == 0)) {
    if (!isNaN(a[0]) && !isNaN(b[0])) {
      const numA = parseFloat(a[0]),
            numB = parseFloat(b[0]);

      if ( numA < numB ){
        return -1;
      }
      if ( numA > numB ){
        return 1;
      }
    }
  }
  return 0;
}

const DBlabel = props => {
  // props.name
  let backgroundColor = '';
  activeDBs.forEach((db) => {
    if (db.name === props.name) {
      backgroundColor = db.color;
    }
  });

  if (backgroundColor) {
    return (
      <div className="db-pill" style={{backgroundColor,margin:5,display:'inline-block'}}>
        {props.name.split('_')[0]}
      </div>
    );
  }
  return null;
}

class GINsubmit extends Component {
  constructor(props) {
    super(props);

    let itemObj = {}, dbStatus = {}, itemPos = {};
    props.openedDBs.forEach(db => {
      itemPos[db] = [];
      itemObj[db] = {};
      dbStatus[db] = {
        percentage: 0,
        status: ''
      }
    });

    this.state = {
      emailData: {},
      itemObj,
      itemPos,
      variantsForDeactivate: [],
      variantsForReactivate: [],
      bomForRemoval: [],
      costForRemoval: [],
      priceForRemoval: [],
      forGINremoval: {},
      alert: '',
      ask: false,
      sentDB: [],
      dbStatus,
    }
  }

  openAsk = () => {
    this.setState({
      ask: true
    });
  }

  closeAsk = () => {
    this.setState({
      ask: false
    });
  }

  openAlert = (title) => {
    this.setState({
      alert: title
    });
  }

  closeAlert = () => {
    this.setState({
      alert: ''
    });
  }

  getValue = (field, realDB) => {
    const { picked } = this.props;
    console.log('picking 0', field, realDB);
    console.log('picking 1', picked[field], field, this.props[field][picked[field]]);
    if (picked[field]) {
      if (field !== 'rbo') {
        const db = picked[field],
              value = this.props[field][db];
        if (value && (value !== 'n/a' && value !== 'N/A')) {
          return value;
        } else {
          return '';
        }
      } else {
        // look up rbo value
        const db = picked.rbo,
              value = this.props.rbo[db];
        if (value && this.props.rboDict[db]) {
          const chosenRBOname = this.props.rboDict[db][value];
          // get RBO FirmCode based on RBO name from chosen value;
          // realDB = the db you are copying to
          //
          console.log('rbo search is this',realDB, db, value, this.props.rboReverseDict[realDB]);
          console.log('chosen RBO name for', realDB, chosenRBOname);
          const realRBOcode = this.props.rboReverseDict[realDB][chosenRBOname];
          if (realRBOcode) {
            return realRBOcode;
          } else {
            // quick fix return random RBO
            const first = Object.keys(this.props.rboReverseDict[realDB])[5];
            const randomRBOcode = this.props.rboReverseDict[realDB][first];
            console.log('rbo chosen random is', randomRBOcode, first);
            return randomRBOcode;
          }
        } else {
          // quick fix return random RBO
          const first = Object.keys(this.props.rboReverseDict[realDB])[5];
          const randomRBOcode = this.props.rboReverseDict[realDB][first];
          console.log('rbo chosen random is', randomRBOcode, first);
          return randomRBOcode;
        }
      }
    }
  }

  returnCurrCode = (curr, db) => {
    let currcode = '';
    if (this.props.options[db]) {
      this.props.options[db].currencies.forEach(c => {
        if (c.symb === curr) {
          currcode = c.curr;
        }
      });
    }
    return currcode;
  }

  returnBOMDesc = (rmcode,db) => {
    let desc = '';
    if (this.props.options[db].rms) {
      this.props.options[db].rms.forEach(rm => {
        if (rm.ItemCode === rmcode) {
          desc = rm.ItemName;
        }
      });
    }
    return desc;
  }

  gatherEmailData = () => {
    const firstDB = this.props.openedDBs[0];
    console.log('got value', this.getValue('rbo', firstDB), firstDB, this.getValue('brand'), this.getValue('subgroup'));
    let emailData = {
      ginNum: this.props.ginNum,
      gmp: this.props.gmpCheckbox ? 'YES' : 'NO',
      base_item_description: this.getValue('base_item_description'), // base item description
      product_type: this.getValue('product_type'), // product type
      rboName: this.props.rboDict[firstDB][this.getValue('rbo', firstDB)], // RBO code
      brandName: this.getValue('brand') ? this.props.brandsDict[this.getValue('brand')] : 'n/a', // brand id
      subgroupName: this.getValue('subgroup') ? this.props.subgroupsDict[this.getValue('subgroup')] : 'n/a', // subgroup id
      itemCodes: this.props.itemCodes,
      ginType: this.props.ginType
    }
    const ipackagingtypes = this.getValue('ipackagingtypes');
    if (ipackagingtypes) {
      emailData.ipackagingtypesName = this.props.packagingtypesDict[ipackagingtypes];
    }
    return emailData;
  }

  gather = (db, variantsChecked, variantsOriginal) => {
    console.log('creating Item Object for saving', db);
    const props = this.props;
    const { picked } = this.props;
    // check if walmart rbo 
    const rbo_value = this.getValue('rbo', db);
    let ipackagingtypes = this.getValue('ipackagingtypes');
    const hasWalmart = checkIfRBOHasWalmart(this.props.rboNames, rbo_value);
    console.log('gather', hasWalmart);
    if (!hasWalmart) {
      ipackagingtypes = '';
    }
    let itemObj = {
      ginNum: this.props.ginNum,
      gmpCheckbox: this.props.gmpCheckbox ? 1 : 0,
      tpp_item_type: this.props.gmpCheckbox ? this.props.tpp_item_type : '',
      itemTypes: checkIfExisted(this.props.baseItem[db]) ? 'Existing' : '',
      company: db,
      edit: 'edit',
      itemname: this.getValue('base_item_description'), // base item description
      igc: this.getValue('product_type'), // product type
      man: rbo_value, // RBO code
      ibrand: this.getValue('brand'), // brand id
      isubgroup: this.getValue('subgroup'), // subgroup id
      ipackagingtypes, // packaging type id
      product: this.props.item_is[db] === 'Produced in House' ? 'Produced In-House' : this.props.item_is[db],// item is

      su: this.getValue('sales_unit'), // sales unit
      spu: this.getValue('basic_packaging_unit'), // sales packaging unit
      sqpp: this.getValue('qty_per_unit'), // quantity per packaging unit
      sul: this.getValue('ilength'), // ilength
      suw: this.getValue('width'), // width
      sulb: this.getValue('weight'), // weight

      variantChangeType: this.props.variantChangeType,
      priceHasChanged: this.props.priceHasChanged
    }

    if (this.props.picks[db]) {
      if (this.props.picks[db] != "0") {
        itemObj.pick = this.props.picks[db];
      }
    }
    if (this.props.itemCodes[db]) {
      if (this.props.itemCodes[db].indexOf('_') !== -1) {
        itemObj.itemcode = this.props.itemCodes[db].split('_')[0];
      } else {
        itemObj.itemcode = this.props.itemCodes[db];
      }
      itemObj.base_item = itemObj.itemcode;
    }
    if (this.props.newItemList) {
      if (this.props.newItemList.length > 0) {
        if (this.props.newItemList.indexOf(db) !== -1) {
          itemObj.itemTypes = 'New';
          itemObj.action = 'createItem';
        }
      }
    }

    if (db.indexOf('NIN') !== -1) {
      itemObj.gstrel = this.props.gst_relevant[db];
      itemObj.mattype = this.props.material_type[db];
      itemObj.HSNCode = this.props.hsn_code[db];
      itemObj.gstcat = this.props.gst_tax_category[db];
      itemObj.ServiceAccountingCode = this.props.service_acct_code[db];
    }

    // add variants
    itemObj.variants_unchecked = [];
    itemObj.forReactivation = [];
    if (this.props.variants) {
      if (this.props.variants[db]) {
        if (this.props.variants[db].length > 0) {
          if (this.props.variantsChecked) {
            if (Object.keys(this.props.variantsChecked).length > 0) {
              // if (this.props.baseItem[db]) {
              //   itemObj.base_item = this.props.baseItem[db];
              // } else {
              //   if (this.props.itemCodes[db].indexOf('_') !== -1) {
              //     itemObj.base_item = this.props.itemCodes[db].split('_')[0];
              //   } else {
              //     itemObj.base_item = this.props.itemCodes[db];
              //   }
              // }
              let itemcodes_checked = [];
              let variants_checked = Object.keys(this.props.variantsChecked).map(vc => {
                const vcc = vc.split('_')[0];
                const vu = `${itemObj.itemcode}_${vcc}`;
                itemcodes_checked.push(vu);
                return vcc;
              });
              this.props.variants[db].forEach(vv => {
                if (variants_checked.indexOf(vv[0]) === -1) {
                  const vu = `${itemObj.itemcode}_${vv[0]}`;
                  itemObj.variants_unchecked.push(vu);
                }
              });

            } else {
              // push every variant for deactivation
              this.props.variants[db].forEach(vv => {
                const vu = `${itemObj.itemcode}_${vv[0]}`;
                itemObj.variants_unchecked.push(vu);
              });
            }
          }
        }
      }
    }

    if (this.props.variantsChecked) {
      if (Object.keys(this.props.variantsChecked).length > 0) {
        let itemcodes_checked = [];
        let variants_checked = Object.keys(this.props.variantsChecked).map(vc => {
          const vcc = vc.split('_')[0];
          const vu = `${itemObj.itemcode}_${vcc}`;
          itemcodes_checked.push(vu);
          return vcc;
        });
        console.log('itemcodes_checked', itemcodes_checked);
        // this.props.openedDBs.forEach((db) => {
          if (this.props.deactivateds[db]) {
            this.props.deactivateds[db].forEach(deac => {
              console.log('itemcodes_checked 2', itemcodes_checked, deac.Itemcode);
              if (itemcodes_checked.indexOf(deac.Itemcode) !== -1) {
                itemObj.forReactivation.push(deac.Itemcode);
              }
            })
          }
        // });
      }
    }

    let variantsOriginalArr = [];
    if (variantsChecked) {
      Object.keys(variantsOriginal).forEach((vcombo,i) => {
        variantsOriginalArr.push(vcombo);
      });
      itemObj.original_variants = variantsOriginalArr;
      let varcount = 0;
      Object.keys(variantsChecked).forEach((vcombo,i) => {
        const code = vcombo.split('_')[0],
              desc = vcombo.split('_')[1];
        if (itemObj.variants_unchecked.indexOf(code) === -1) {
          varcount++;
          itemObj[`vc${varcount}`] = code;
          itemObj[`vd${varcount}`] = desc;
        }
      });
    }
    itemObj.original_variants = JSON.stringify(variantsOriginalArr);
    itemObj.vamounts = variantsChecked ? Object.keys(variantsChecked).length+1 : 1;

    // NOTES :
    // To Be Deleted variant - just not included
    // To Be Updated variant - just update variantsChecked

    // ADD PRICES
    // ----------
    // add General Pricing
    const mainCurrency = this.props.options[db].main_currency;
    const arr = ['gterm','gcur','gprice','gstart','gend'];
    let completeGeneralPricing = true;
    arr.forEach(field_p => {
      console.log('checky', props[field_p][db]);
      if (!props[field_p][db]) {
        completeGeneralPricing = false;
      }
    })
    let generalPricing = {
      pamounts: 1,
      price_count: 1,
      removePricelist: []
    };
    if (!completeGeneralPricing) {
      generalPricing.price_count = 0;
      let oldPricingCodes = [];
      if (props.gcode_original[db]) {
        oldPricingCodes.push(props.gcode_original[db]);
      }
      if (props.gcodes_original[db]) {
        if (props.gcodes_original[db].length > 0) {
          oldPricingCodes = oldPricingCodes.concat(props.gcodes_original[db]);
        }
      }
      if (props.specialmoc_original[db]) {
        if (props.specialmoc_original[db].length > 0) {
          props.specialmoc_original[db].forEach(smoc => {
            if (smoc[2]) {
              oldPricingCodes.push(smoc[2]);
            }
          })
        }
      }
      if (oldPricingCodes.length > 0) {
        generalPricing.removePricelist = oldPricingCodes;
      }
    } else {
      generalPricing.pshipt = props.gterm[db]; // General Pricing - Terms
      generalPricing.usd = props.gcur[db]; // General Pricing - Currency
      generalPricing.addpp = `${props.gcur[db]}${props.gprice[db]}`; // e.g. "RMB21.0000" - added currency to price
      generalPricing.start = props.gstart[db]; // General Pricing - Start
      generalPricing.end = props.gend[db]; // General Pricing - End
      generalPricing.gcode = props.gcode[db]; // Pricing Code
      let mocspec = 'nitemspec', moc = '', mock = '';
      if (this.props.specialmoc[db]) {
        let mocs_collection = [];
        this.props.specialmoc[db].forEach(sm => {
          if (sm[0] && (sm[1] || sm[1] == 0)) {
            mocs_collection.push({
              currency: this.returnCurrCode(sm[0], db),
              symbol: sm[0],
              moc_price: sm[1]
            });
            if (sm[0] === mainCurrency) {
              moc = `${sm[0]}${sm[1]}`; // currency + price concatenated
              mocspec = 'itemspec';
              mock = sm[1];
              if (mock || mock == "0") {
                generalPricing.moc = moc;
                generalPricing.mock = stringMe(mock);
                generalPricing.mocspec = mocspec;
              }
            } else {
              generalPricing[`${this.returnCurrCode(sm[0], db)}moc`] = `${sm[0]}${sm[1]}`;
              generalPricing[`${this.returnCurrCode(sm[0], db)}mocspec`] = 'itemspec';
              generalPricing[`mock${this.returnCurrCode(sm[0], db)}`] = stringMe(sm[1]);
            }
          }
        });
        if (mocs_collection.length > 0) {
          generalPricing.mocs_collection = JSON.stringify(mocs_collection);
        }
      }

      // General Pricing after Flat Pricing quantity
      // ---------------
      if (this.props.gcurrs[db]) {
        this.props.gcurrs[db].forEach((curr,i) => {
          const g = this.props.gcurrs[db],
                num = i+1;
          generalPricing[`pline${num}`] = this.props.gqtys[db][i];  // Qty
          generalPricing[`pshipt${num}`] = this.props.gterms[db][i]; // Terms
          generalPricing[`usd${num}`] = curr; // Currency
          generalPricing[`addpp${num}`] = `${curr}${this.props.gprices[db][i]}` // Price
          generalPricing[`start${num}`] = this.props.gstarts[db][i] // Start
          generalPricing[`end${num}`] = this.props.gends[db][i] // End
          generalPricing[`gcodes${num}`] = this.props.gcodes[db][i] // Code
        });
        generalPricing.pamounts = this.props.gcurrs[db].length + 1;
      }
    }

    // SPECIFIC CUSTOMER PRICING
    let customerPricing = {};
    if (this.props.bmasterarray[db]) {
      if (Object.keys(this.props.bmasterarray[db]).length > 0) {
        Object.keys(this.props.bmasterarray[db]).forEach((customerCombo,j) => {
          const ccode = customerCombo.split('[.]')[0],
                cname = customerCombo.split('[.]')[1];

          let customer = JSON.parse(JSON.stringify(this.props.bmasterarray[db][customerCombo]));
          // customers have prepended numbers
          const i = j === 0 ? '' : j;
          let counter = 0;

          console.log('unsorted customer', customer);
          customer.sort(comparedQty);
          console.log('sorted customer', customer);
          customer.forEach((cc,ii) => {
            if (parseInt(cc[0]) === 0) {
              // MOC
              if (cc[2] || cc[2] === 0) {
                if (cc[5] === mainCurrency) {
                  customerPricing[`bmoc${i}`] = `${cc[5]}${cc[2]}`;
                  customerPricing[`bmock${i}`] = stringMe(cc[2]);
                  customerPricing[`bmocspec${i}`] = 'itemspec';
                } else {
                  customerPricing[`b${this.returnCurrCode(cc[5],db)}moc${i}`] = `${cc[5]}${cc[2]}`;
                  customerPricing[`b${this.returnCurrCode(cc[5],db)}mocspec${i}`] = `itemspec`;
                  customerPricing[`bmock${this.returnCurrCode(cc[5],db)}${i}`] = stringMe(cc[2]);
                }
              }
            } else {
              if (counter === 0) {
                customerPricing[`busparts${i}`] = ccode;
                customerPricing[`${i}bpshipt`] = cc[1]; // terms
                customerPricing[`${i}busd`] = cc[5]; // currency
                customerPricing[`${i}baddpp`] = `${cc[5]}${cc[2]}`; // currency + price
                customerPricing[`${i}bstart`] = cc[6];
                customerPricing[`${i}bend`] = cc[7];
                customerPricing[`${i}bcode`] = cc[3];
              } else {
                console.log(`${i}bpline${counter}`, cc[0], db);
                customerPricing[`${i}bpline${counter}`] = cc[0]; // qty
                customerPricing[`${i}bpshipt${counter}`] = cc[1]; // term
                customerPricing[`${i}busd${counter}`] = cc[5]; // currency
                customerPricing[`${i}baddpp${counter}`] = `${cc[5]}${cc[2]}`; // currency + price
                customerPricing[`${i}bstart${counter}`] = cc[6];
                customerPricing[`${i}bend${counter}`] = cc[7];
                customerPricing[`${i}bcodes${counter}`] = cc[3];
              }
              counter++;
            }
          });
          customerPricing[`bpamounts${i}`] = counter;
        })
      }
      customerPricing.bpcount = Object.keys(this.props.bmasterarray[db]).length;
    }

    // TARGET COST
    let targetCost = { removeCostlist: [] };

    if (this.props.item_is[db] === 'Both' || this.props.item_is[db] === 'Outsourced') {
      if (props.cterm[db]) {
        targetCost.cpamounts = 1;
        targetCost.cpshipt = props.cterm[db] ? props.cterm[db] : ''; // Terms
        targetCost.cusd = props.ccur[db] ? props.ccur[db] : ''; // Currency
        targetCost.caddpp =`${props.ccur[db]}${props.cprice[db]}`; // e.g. "RMB21.0000" - added currency to price
        targetCost.cstart = props.cstart[db] ? props.cstart[db] : ''; // General Pricing - Start
        targetCost.cend = props.cend[db] ? props.cend[db] : ''; // General Pricing - End
        targetCost.ccode = props.ccode[db] ? props.ccode[db] : ''; // Pricing Code
      }

      // Target Cost after Flat Pricing quantity
      // ---------------
      if (this.props.ccurs[db]) {
        if (this.props.ccurs[db].length > 0) {
          this.props.ccurs[db].forEach((curr,i) => {
            const g = this.props.ccurs[db],
                  num = i+1;
            targetCost[`cpline${num}`] = this.props.cqtys[db][i];  // Qty
            targetCost[`cpshipt${num}`] = this.props.cterms[db][i]; // Terms
            targetCost[`cusd${num}`] = curr; // Currency
            targetCost[`caddpp${num}`] = `${curr}${this.props.cprices[db][i]}` // Price
            targetCost[`cstart${num}`] = this.props.cstarts[db][i] // Start
            targetCost[`cend${num}`] = this.props.cends[db][i] // End
            targetCost[`ccodes${num}`] = this.props.ccodes[db][i] // Code
          });
          targetCost.cpamounts = this.props.ccurs[db].length + 1;
        }
      }
    } else if (this.props.item_is[db] === 'Produced in House') {
      let found = false;
      if (this.props.cterm[db] && this.props.cprice[db]) {
        found = true;
      }

      if (this.props.cterms[db]) {
        if (this.props.cterms[db].length > 0) {
          found = true;
        }
      }

      if (found) targetCost.removeCostlist.push(itemObj.base_item);
    }


    // SUPPLIER SPECIFIC COST
    let supplierCost = { MS: '' };

    if (this.props.item_is[db] === 'Both' || this.props.item_is[db] === 'Outsourced') {
      supplierCost.MS = this.props.preferredVendor[db] ? this.props.preferredVendor[db] : '';
      if (!supplierCost.MS) {
        if (this.props.smasterarray[db]) {
          if (this.props.smasterarray[db][0]) {
            supplierCost.MS = Object.keys(this.props.smasterarray[db])[0].split('[.]')[0]
          }
        }
      }

      if (this.props.smasterarray[db]) {
        if (Object.keys(this.props.smasterarray[db]).length > 0) {
          Object.keys(this.props.smasterarray[db]).forEach((supplierCombo,j) => {
            const ccode = supplierCombo.split('[.]')[0],
                  cname = supplierCombo.split('[.]')[1];

            let supplier = JSON.parse(JSON.stringify(this.props.smasterarray[db][supplierCombo]));

            const i = j === 0 ? '' : j;
            let counter = 0;

            supplier.sort(comparedQty);
            supplier.forEach((cc,ii) => {
              if (parseInt(cc[0]) === 0) {
                if (cc[2] == 0 || cc[2]) {
                  if (cc[5] === mainCurrency) {
                    supplierCost[`cbmoc${i}`] = `${cc[5]}${cc[2]}`;
                    supplierCost[`cbmocspec${i}`] = 'itemspec';
                    supplierCost[`cbmock${i}`] = cc[2];
                  } else {
                    supplierCost[`cb${this.returnCurrCode(cc[5],db)}moc${i}`] = `${cc[5]}${cc[2]}`;
                    supplierCost[`cb${this.returnCurrCode(cc[5],db)}mocspec${i}`] = `itemspec`;
                    supplierCost[`cbmock${this.returnCurrCode(cc[5],db)}${i}`] = cc[2];
                  }
                }
              } else {
                if (counter === 0) {
                  supplierCost[`supparts${i}`] = ccode;
                  supplierCost[`${i}cbpshipt`] = cc[1]; // terms
                  supplierCost[`${i}cbusd`] = cc[5]; // currency
                  supplierCost[`${i}cbaddpp`] = `${cc[5]}${cc[2]}`; // currency + price
                  supplierCost[`${i}cbstart`] = cc[6];
                  supplierCost[`${i}cbend`] = cc[7];
                  supplierCost[`${i}cbcode`] = cc[3];
                } else {
                  supplierCost[`${i}cbpline${counter}`] = cc[0]; // qty
                  supplierCost[`${i}cbpshipt${counter}`] = cc[1]; // term
                  supplierCost[`${i}cbusd${counter}`] = cc[5]; // currency
                  supplierCost[`${i}cbaddpp${counter}`] = `${cc[5]}${cc[2]}`; // currency + price
                  supplierCost[`${i}cbstart${counter}`] = cc[6];
                  supplierCost[`${i}cbend${counter}`] = cc[7];
                  supplierCost[`${i}cbcodes${counter}`] = cc[3];
                }
                counter++;
              }
            });
            supplierCost[`cbpamounts${i}`] = counter;
          });
        }
        supplierCost.supcount = Object.keys(this.props.smasterarray[db]).length;
      }
    } else if (this.props.item_is[db] === 'Produced in House' || this.props.item_is[db] === 'None') {
      let found = false;
      if (this.props.smasterarray[db]) {
        if (Object.keys(this.props.smasterarray[db]).length > 0) {
          Object.keys(this.props.smasterarray[db]).forEach(supp => {
            if (this.props.smasterarray[db][supp].length > 0) {
              found = true;
            }
          })
        }
      }
      if (found) targetCost.removeCostlist.push(itemObj.base_item);
    }

    // BOM
    let bomObj = { removeBOMlist: [] };
    if (this.props.item_is[db] === 'Both' || this.props.item_is[db] === 'Produced in House') {
      bomObj.bomq = this.props.BOMItemBaseQty[db] ? this.props.BOMItemBaseQty[db] : '';
      if (this.props.rms[db]) {
        this.props.rms[db].forEach((code,i) => {
          const append = i === 0 ? '' : i,
                append2 = i === 0 ? '' : `-${i}`;

          let rmsCopy = '';
          if (this.props.rmsCopy[db]) {
            this.props.rmsCopy[db].forEach(rmsc => {
              if (rmsc === code) {
                rmsCopy = rmsc;
              }
            });
          }
          bomObj[`rm${append2}`] = code;
          if (rmsCopy) {
            bomObj[`orm${append}`] = rmsCopy; // create original RM code in redux
          }
          bomObj[`rawnum${append}`] = this.props.rmsq[db][i];
          bomObj[`wareopts${append}`] = this.props.rmsw[db][i];
          bomObj[`bomdesk${append}`] = this.returnBOMDesc(code,db);
        });
      }
      bomObj.ramounts = this.props.rms[db] ? this.props.rms[db].length : 1;
    } else {
      if (this.props.variants[db]) {
        if (this.props.variants[db].length > 0) {
          if (this.props.item_is[db] === 'Outsourced' || this.props.item_is[db] === 'None') {
            if (this.props.rms[db]) {
              if (this.props.rms[db].length > 0) {
                this.props.variants[db].forEach(v => {
                  if (v[0]) {
                    const combo = `${itemObj.itemcode}_${v[0]}`
                    bomObj.removeBOMlist.push(combo);
                    if (itemObj.base_item) {
                      bomObj.removeBOMlist.push(itemObj.base_item);
                    }
                  }
                })
              }
            }
          }
        }
      }
    }

    const finalItemObj = Object.assign({}, itemObj, generalPricing, customerPricing, targetCost, supplierCost, bomObj);
    console.log(`created Item Object for ${finalItemObj.company}`, finalItemObj);
    return finalItemObj;
  }

  autosaveDo = (autosave_id) => {
    if (this.props.ginNum) {
      this.props.gatherAutoSaveData();
      setTimeout(() => {
        console.log('autosave ID', autosave_id);
        this.props.autoSave(this.props.saveData, autosave_id, this.props.ginNum);
      }, 100);
    }
  }

  startSending = () => {
    let lockData = [];
    if (this.state.itemObj) {
      Object.keys(this.state.itemObj).forEach((dbs) => {
        const db = dbs.split('--')[0];
        lockData.push({
          db,
          itemcode:this.state.itemObj[dbs].itemcode
        })
      });
    }

    if (this.props.action === 'approve') {
      // approver or direct to SAP
      let activity = this.props.ginType === 'NEW' ? 2 : 1,
          pending_id = null;
      if (this.props.ginSource === 'Pending') {
        activity = this.props.ginType === 'NEW' ? 8 : 7;
        pending_id = this.props.autosaveData.pending_id;
        console.log('approvePending',pending_id)
        this.props.approvePending(pending_id);
      }

      console.log('autosaveData', this.props.autosaveData, this.props.autosave_id)
      const autosave_id = this.props.autosave_id ? this.props.autosave_id : this.props.autosaveData.id;
      const unique_history_id = (new Date().getTime()).toString(36);
      console.log('unique history id', unique_history_id);
      this.props.ginSaveHistory({
        gin: this.props.ginNum,
        autosave_id: autosave_id,
        activity,
        unique_history_id
      });
      this.autosaveDo(autosave_id);

      this.startSendingImage();
      console.log('emailData',this.state.emailData);
      // updates the lock to pending status
      this.props.ginSendItem(this.state.itemObj,
          this.state.variantsForDeactivate,
          this.props.ginNum,
          this.state.emailData,
          this.state.bomForRemoval,
          this.state.costForRemoval,
          this.state.forGINremoval,
          this.state.priceForRemoval,
          this.state.variantsForReactivate,
          this.props.autosaveData ? this.props.autosaveData.submitter_id : null,
          unique_history_id,
          lockData
        );
    } else {
      // Send to Pending, only a submitter
      console.log('ginSavePending 1', this.state);
      const autosave_id = this.props.autosave_id ? this.props.autosave_id : this.props.autosaveData.id
      this.autosaveDo(autosave_id);

      const emailData = JSON.parse(JSON.stringify(this.state.emailData));
      emailData.type = 'submitter_pending';
      const email = emailTemplate.GIN_submit(emailData);
      console.log('email created is',email);
      this.props.notifyMail(getUserID(), email.subject, email.msg);

      const emailData2 = JSON.parse(JSON.stringify(this.state.emailData));
      emailData2.type = 'approver_pending';
      const emailApprovers = emailTemplate.GIN_submit(emailData2);
      console.log('email created is',emailApprovers);

      setTimeout(() => {
        const pendingObj = {
          gin: this.props.ginNum,
          autosave_id,
          type: this.props.ginType,
          subject: emailApprovers.subject,
          msg: emailApprovers.msg,
          lockData
        };
        console.log('saving now', pendingObj);
        this.props.ginSavePending(pendingObj);
      });
    }
  }

  startSendEach = (db) => {
    const itemObj = this.state.itemObj[db];
    this.props.ginSendItem({[db]:itemObj}, null, this.props.ginNum);
  }

  startSendingImage = () => {
    if (this.props.ginFinalCrop) {
      if (this.props.ginFinalCrop.length > 120) {
        this.props.ginSendImage(this.props.ginFinalCrop, this.props.ginNum);
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.ginSendItemDB !== this.props.ginSendItemDB) {
      this.setState((prevState) => {
        return { sentDB: prevState.sentDB.concat([this.props.ginSendItemDB]) }
      });
    }
  }

  componentDidMount() {
    let itemObj = {}, batchNum = 20, itemPos = {}, forGINremoval = {};
    const emailData = this.gatherEmailData();
    if (emailData) {
      if (emailData.itemCodes) {
        const existingGINsAfter = {},
              existingGINsBefore = this.props.existingGINsBefore;
        if (existingGINsBefore) {
          if (Object.keys(existingGINsBefore).length > 0) {
            Object.keys(emailData.itemCodes).forEach(db => {
              const itemcode = emailData.itemCodes[db];
              const vless = itemcode.indexOf('_') !== -1 ? itemcode.split('_')[0] : itemcode;
              existingGINsAfter[db] = [vless];
              if (existingGINsBefore[db]) {
                if (vless !== existingGINsBefore[db][0]) {
                  // remove GIN from this itemcode and its variants
                  forGINremoval[db] = existingGINsBefore[db];
                 }
              }
              if (this.props.variantsChecked) {
                if (Object.keys(this.props.variantsChecked).length > 0) {
                  Object.keys(this.props.variantsChecked).forEach(vc => {
                    existingGINsAfter[db].push(`${itemcode}_${vc}`);
                  })
                }
              }
            })
            console.log('existingGINsBefore', existingGINsBefore);
            console.log('existingGINsAfter', existingGINsAfter);
          }
        }
      }
    }

    if (Object.keys(this.props.variantsChecked).length < (batchNum + 1)) {
      this.props.openedDBs.forEach((db,i) => {
        itemObj[`${db}--${i}`] = this.gather(db, this.props.variantsChecked, this.props.variantsChecked);
      });
    } else {
      // do batches
      const variantsLength = Object.keys(this.props.variantsChecked).length,
            variants = Object.keys(this.props.variantsChecked);

      console.log('groupedVariants Length', variantsLength, variants);
      const countedD = Math.floor(variantsLength / batchNum) + (variantsLength % batchNum);
      let groupedVariants = {}, lastPage = 0;
      for (var i = 0; i < countedD; i++) {
        let vGroup = {};
        for (var x = lastPage; x < (batchNum+lastPage); x++) {
          if (variants[x]) {
            vGroup[variants[x]] = 1;
          }
        }
        lastPage = lastPage + batchNum;
        if (Object.keys(vGroup).length > 0) {
          groupedVariants[i] = vGroup;
        }
      }

      console.log('these are the groupedVariants',groupedVariants);
      this.props.openedDBs.forEach((db,j) => {
        for (var i = 0; i < Object.keys(groupedVariants).length; i++) {
          itemObj[`${db}--${i}`] = this.gather(db, groupedVariants[i], variants);
        }
      });
    }

    let variantsForDeactivate = [],
        variantsForReactivate = [];
    let bomForRemoval = [],
        costForRemoval = [],
        priceForRemoval = [];
    Object.keys(itemObj).forEach(io => {
      if (io) {
        const db = io.split('--')[0],
              pos = io.split('--')[1];
        if (itemPos.hasOwnProperty(db)) {
          itemPos[db].push(pos);
        } else {
          itemPos[db] = [pos];
        }

        if (itemObj[io].forReactivation) {
          itemObj[io].forReactivation.forEach(vu => {
            const combovu = `${db} ${vu}`;
            if (variantsForReactivate.indexOf(combovu) === -1) {
              variantsForReactivate.push(combovu);
            }
          });
        }

        if (itemObj[io].variants_unchecked) {
          itemObj[io].variants_unchecked.forEach(vu => {
            const combovu = `${db} ${vu}`;
            if (variantsForDeactivate.indexOf(combovu) === -1) {
              variantsForDeactivate.push(combovu);
            }
          });
        }

        if (itemObj[io].removeBOMlist) {
          itemObj[io].removeBOMlist.forEach(rb => {
            const comboRB = `${db} ${rb}`;
            if (bomForRemoval.indexOf(comboRB) === -1) {
              bomForRemoval.push(comboRB);
            }
          });
        }

        if (itemObj[io].removeCostlist) {
          itemObj[io].removeCostlist.forEach(rb => {
            const comboRB = `${db} ${rb}`;
            if (costForRemoval.indexOf(comboRB) === -1) {
              costForRemoval.push(comboRB);
            }
          });
        }

        if (itemObj[io].removePricelist) {
          itemObj[io].removePricelist.forEach(rb => {
            const comboRB = `${db} ${rb}`;
            if (priceForRemoval.indexOf(comboRB) === -1) {
              priceForRemoval.push(comboRB);
            }
          });
        }
      }
    });

    let priceRemovalObj = {};
    priceForRemoval.forEach(pr => {
      const arr = pr.split(' ');
      const db = arr[0],
            price_code = arr[1];
      if (!priceRemovalObj.hasOwnProperty(db)) {
        priceRemovalObj[db] = [price_code];
      } else {
        priceRemovalObj[db].push(price_code);
      }
    })

    this.setState({
      emailData,
      itemObj,
      itemPos,
      variantsForDeactivate,
      variantsForReactivate,
      bomForRemoval,
      costForRemoval,
      priceForRemoval: priceRemovalObj,
      forGINremoval,
    }, () => {
      //this.openAsk();
      this.startSending();
    });
  }

  render() {
    console.log('stt prr', this.state, this.props, this.props.ginSendItemStatus);
    console.log('impp-',this.props.tableCount, this.props.currentImportDB, this.props.currentImportDB2);
    const currentDBposition = this.props.currentImportDB2.split('--')[1];
    const currentDB = this.props.currentImportDB;
    const length = this.state.itemPos[currentDB] ? this.state.itemPos[currentDB].length : 1;
    const indexx = this.state.itemPos[currentDB] ? this.state.itemPos[currentDB].indexOf(currentDBposition) + 1 : 0;

    let tableData = [];
    let percentage = 0;
    this.props.openedDBs.forEach(db => {
      if (this.state.itemObj) {
        if (this.state.itemObj[db]) {
          if (this.state.itemObj[db].vamounts && this.props.currentImportDB === db) {
            if (this.props.tableCount) {
              if (!isNaN(this.props.tableCount) && !isNaN(this.state.itemObj[db].vamounts)) {
                const total = this.state.itemObj[db].vamounts - 1;
                const first_percentage = Math.floor((this.props.tableCount / total) * 100);
                percentage = Math.abs(first_percentage - 100);
              }
            }
          }
        }
      }
      const dbb = db.indexOf('--') !== -1 ? db.split('--')[0] : db;
      const percent = Math.floor(( indexx / length ) * 100);
      let percentage = '100%',
          final_status = 'SUBMITTED FOR APPROVAL';
      if (this.props.action !== 'create') {
        percentage = this.props.currentImportDB === db ? `${percent}%` : this.state.sentDB.indexOf(db) !== -1 ? '100%' : '0%';
        final_status = this.props.currentImportDB === db ? 'SENDING' : this.state.sentDB.indexOf(db) !== -1 ? 'PENDING VALIDATION' : '';
      }
      tableData.push([
        <DBlabel name={dbb} />,
        this.props.itemCodes[db],
        percentage,
        final_status
      ]);
    });

    return (
      <div style={{marginTop:'20px'}}>
        {this.state.alert && <GINalert
            hide={this.closeAlert}
            content={this.state.alert}
            command={() => {
              window.location.reload();
            }}
          />
        }
        {this.state.ask && <GINask2
            command={this.startSending}
            title="Send Items to SAP"
            content="Do You Want to Continue?"
            close={this.closeAsk}
          />
        }
        <Card style={{marginTop:10,marginBottom:10}}>
          <CardContent style={{padding: '20px 15px'}}>
            <GridContainer>
              <GridItem xs={12} md={12} lg={12}>
                <div className="text-center">
                  {!this.props.ginSendItemLoading && <h1>Submit</h1>}
                  {this.props.ginSendItemLoading && <h1>Submitting</h1>}
                  {this.props.startImporter && <h3>Starting SAP Importer</h3>}
                  {this.props.removeCost && <div>
                    <div>Removing Cost</div>
                    <PulseLoader />
                  </div>}
                  {this.props.removeBOM && <div>
                    <div>Removing BOM</div>
                    <PulseLoader />
                  </div>}
                  {this.props.ginSendItemLoading && <div>
                    <div>Processing Variants</div>
                    <PulseLoader />
                  </div>}
                </div>
                <div>
                  <div className="gray-th">
                    <Table
                      tableHeaderColor="primary"
                      tableHead={["DB", "Item Code", "Variants", "Status"]}
                      tableData={tableData}
                      coloredColls={[3]}
                      colorsColls={["primary"]}
                    />
                  </div>
                  {(!this.props.ginSendItemLoading && this.props.finishedImporting) &&
                    <div style={{marginTop:20,marginBottom:20}} className="text-center">
                      <div style={{marginTop:20,marginBottom:20}} className="text-center">
                        <h4>Your items have been submitted and are waiting validation.</h4>
                        <p>You will be notified via email once they are validated in SAP and ready to use.</p>
                      </div>
                      {this.props.ginSource !== "Pending" && <Button
                        color="rose"
                        onClick={() => {
                          window.location.reload();
                        }}
                      >NEW GIN
                      </Button>}
                      {this.props.ginSource === "Pending" && <Button
                        color="rose"
                        onClick={() => {
                          this.props.selectTab('Item Select');
                          this.props.ginRefresh();
                          this.props.resetGINNum();
                          this.props.fetchRBOs('NLA_LIVE');
                          this.props.fetchBrandSubgroupList();
                          this.props.closeView();
                        }}
                      >BACK TO PENDING LIST
                      </Button>}
                    </div>}
                    {this.props.action === 'create' &&
                      <div style={{marginTop:20,marginBottom:20}} className="text-center">
                        <div style={{marginTop:20,marginBottom:20}} className="text-center">
                          <h4>Your items have been submitted and are waiting for review.</h4>
                          <p>You will be notified via email once they are approved and sent to SAP and ready to use.</p>
                        </div>
                        <Button
                          color="rose"
                          onClick={() => {
                            window.location.reload();
                          }}
                        >NEW GIN
                        </Button>
                      </div>}
                </div>
              </GridItem>
            </GridContainer>
          </CardContent>
        </Card>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const brands = state.gin.brands,
        subgroups = state.gin.subgroups,
        packagingtypes = state.gin.packagingtypes;
  let brandsDict = {},
      subgroupsDict = {},
      packagingtypesDict = {};
  brands.forEach(b => {
    brandsDict[b.id.toString()] = b.name;
  });
  subgroups.forEach(s => {
    subgroupsDict[s.id.toString()] = s.name;
  });
  packagingtypes.forEach(s => {
    packagingtypesDict[s.id.toString()] = s.name;
  });
  const options = state.gin.options;
  let rboReverseDict = {}, rboDict = {};
  Object.keys(options).forEach(db => {
    rboReverseDict[db] = {};
    rboDict[db] = {};
    const opts = options[db].rbos;
    opts.forEach(o => {
      rboReverseDict[db][o.FirmName] = o.FirmCode;
      rboDict[db][o.FirmCode.toString()] = o.FirmName;
    });
  });
  return {
    brandsDict,
    subgroupsDict,
    packagingtypesDict,
    rboReverseDict,
    rboDict,
    newItemList: state.gin.newItemList,
    ginSendItemStatus: state.gin.ginSendItemStatus,
    ginSendItemLoading: state.gin.ginSendItemLoading,
    ginSendItemDB: state.gin.ginSendItemDB,
    ginFinalCrop: state.gin.ginFinalCrop,
    dbList: state.gin.dbList,
    loading: state.gin.loading,
    itemCodes: state.gin.itemCodes,
    base_item_description: state.gin.base_item_description,
    product_type: state.gin.product_type,
    rbo: state.gin.rbo,
    rboNames: state.gmp.rbos,
    brandNames: state.gin.brands,
    subgroupNames: state.gin.subgroups,
    brand: state.gin.brand,
    ipackagingtypes: state.gin.ipackagingtypes,
    subgroup: state.gin.subgroup,
    sales_unit: state.gin.sales_unit,
    basic_packaging_unit: state.gin.basic_packaging_unit,
    qty_per_unit: state.gin.qty_per_unit,
    ilength: state.gin.ilength,
    width: state.gin.width,
    weight: state.gin.weight,
    picks: state.gin.picks,
    baseItem: state.gin.baseItem,
    variants: state.gin.variants,
    variantsDesc: state.gin.variantsDesc,
    originalVariants: state.gin.originalVariants,
    originalVariantsDesc: state.gin.originalVariantsDesc,
    variantsChecked: state.gin.variantsChecked,
    deactivateds: state.gin.deactivateds,

    cprice: state.gin.cprice,
    cterm: state.gin.cterm,
    ccur: state.gin.ccur,
    cstart: state.gin.cstart,
    cend: state.gin.cend,
    ccode: state.gin.ccode,

    cqtys: state.gin.cqtys,
    cterms: state.gin.cterms,
    ccurs: state.gin.ccurs,
    cprices: state.gin.cprices,
    cstarts: state.gin.cstarts,
    cends: state.gin.cends,
    ccodes: state.gin.ccodes,

    bmasterarray: state.gin.bmasterarray,
    smasterarray: state.gin.smasterarray,
    preferredVendor: state.gin.preferredVendor,

    comp: state.gin.comp,
    item_is: state.gin.item_is,

    // general pricing
    gcur: state.gin.gcur,
    gstart: state.gin.gstart,
    gend: state.gin.gend,
    gprice: state.gin.gprice,
    gterm: state.gin.gterm,
    gcode: state.gin.gcode,
    gcode_original: state.gin.gcode_original,

    gterms: state.gin.gterms,
    gstarts: state.gin.gstarts,
    gends: state.gin.gends,
    gprices: state.gin.gprices,
    gcurrs: state.gin.gcurrs,
    gqtys: state.gin.gqtys,
    gcodes: state.gin.gcodes,
    gcodes_original: state.gin.gcodes_original,

    mocs: state.gin.mocs,
    specialmoc: state.gin.specialmoc,
    specialmoc_original: state.gin.specialmoc_original,

    BOMItemBaseQty: state.gin.BOMItemBaseQty,
    rms: state.gin.rms,
    rmsCopy: state.gin.rmsCopy,
    rmsd: state.gin.rmsd,
    rmsq: state.gin.rmsq,
    rmsw: state.gin.rmsw,

    gst_relevant: state.gin.gst_relevant,
    material_type: state.gin.material_type,
    hsn_code: state.gin.hsn_code,
    gst_tax_category: state.gin.gst_tax_category,
    service_acct_code: state.gin.service_acct_code,

    ginNum: state.gin.ginNum,
    gmpCheckbox: state.gin.gmpCheckbox,
    tpp_item_type: state.gin.tpp_item_type,

    openedDBs: state.gin.openedDBs,
    options: state.gin.options,
    editMode: state.gin.editMode,
    editTableMode: state.gin.editTableMode,
    editData: state.gin.editData,
    editTable2Mode: state.gin.editTable2Mode,
    editData2: state.gin.editTable2Data,
    picked: state.gin.picked || {},

    startImporter: state.gin.startImporter,
    finishedImporting: state.gin.finishedImporting,
    tableCount: state.gin.tableCount,
    currentImportDB: state.gin.currentImportDB ? state.gin.currentImportDB.indexOf('--') !== -1 ?
                              state.gin.currentImportDB.split('--')[0] : state.gin.currentImportDB : state.gin.currentImportDB,
    currentImportDB2: state.gin.currentImportDB2,
    removeBOM: state.gin.removeBOM,
    removeCost: state.gin.removeCost,
    existingGINsBefore: state.gin.existingGINsBefore,
    saveData: state.gin.saveData,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ginSendItem: (itemObj, deactivateList, ginnum, emailData, removeBOMlist, removeCostlist, removeGINlist, removePricelist, reactivateList, submitter_id, unique_history_id, lockData) => {
      dispatch(actions.ginSendItem(itemObj, deactivateList, ginnum, emailData, removeBOMlist, removeCostlist, removeGINlist, removePricelist, reactivateList, submitter_id, unique_history_id, lockData));
    },
    ginSendImage: (finalCrop, ginNum) => {
      dispatch(actions.ginSendImage(finalCrop, ginNum));
    },
    ginRefresh: () => {
      dispatch(actions.ginRefresh());
    },
    fetchRBOs: (db) => {
      dispatch(actions.fetchGMPRBO(db));
    },
    fetchBrandSubgroupList: () => {
      dispatch(actions.fetchBrandSubgroupList());
    },
    ginDeactivate: (list) => {
      dispatch(actions.ginDeactivate(list));
    },
    ginRenameImage: (oldName, newName) => {
      dispatch(actions.ginRenameImage(oldName, newName));
    },
    ginSavePending: (data) => {
      console.log('saving data',data);
      dispatch(actions.ginSavePending(data.autosave_id, data.gin, data.type, data.subject, data.msg, data.lockData));
    },
    notifyMail: (to, subject, message) => {
      dispatch(actions.notifyMail(to,subject,message));
    },
    ginSaveHistory: (data) => {
      dispatch(actions.ginSaveHistory(data));
    },
    approvePending: (id) => {
      dispatch(actions.ginApprovePending(id));
    },
    gatherAutoSaveData: () => {
      dispatch(actions.ginGatherAutoSaveData());
    },
    autoSave: (data, id, gin) => {
      dispatch(actions.ginAutoSave(data, id, gin));
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GINsubmit);
