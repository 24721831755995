import React from 'react';
import Table from 'components/Table/Table.jsx';
import { Dialog } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { Check } from '@material-ui/icons';
import { compareSpecial } from './SAPMonitorLogic';
import { compareNumbersSpecial } from '../../shared/utility';

const trim = (str) => {
    if (str) {
        if (typeof str === 'string') {
            return str.trim();
        }
    }
    return str;
}

const SAPMonitorOrderDetailsTable = props => {
    console.log('SAPMonitorOrderDetailsTable', props);
    const { order_details_magento, order_details_sap } = props;
    const tableHeadBlue = [
        <div className="tablehead-blue">Description</div>,
        <div className="tablehead-blue">SAP Value</div>,
        <div className="tablehead-blue">Magento Value</div>,
        <div className="tablehead-blue">Check</div>
    ];
    const tableData = Object.keys(order_details_magento).filter(f => {
        if (f === 'items_ordered_val') return false;
        if (f === 'order_status') return false;
        return true;
    }).map(fieldy => {
        let sap_val = trim(order_details_sap[fieldy]);
        let mag_val = order_details_magento[fieldy] ? trim(order_details_magento[fieldy]) : ''
        if (fieldy === 'items_ordered') {
            sap_val = trim(order_details_sap.items_ordered_val);
            mag_val = trim(order_details_magento.items_ordered_val);
        }
        let check = null;
        if (compareSpecial.indexOf(fieldy) !== -1) {
            check = compareNumbersSpecial(sap_val, mag_val) ? <div
                        style={{color:'green'}}
                    ><Check /></div> 
                : 
                    <div
                        style={{color:'red'}}
                    ><Close /></div>

        } else {    
            check = sap_val == mag_val ? <div
                    style={{color:'green'}}
                ><Check /></div> 
            : 
                <div
                    style={{color:'red'}}
                ><Close /></div>
        }
        return [
            fieldy.toUpperCase().replace(/_/g,' '), 
            sap_val,
            mag_val,
            check
        ]
        }).concat([[
            <div className="tablehead-blue">Description</div>,
            <div className="tablehead-blue">SAP Value</div>,
            <div className="tablehead-blue">Magento Value</div>,
            <div className="tablehead-blue">Check</div>
        ]])
    console.log('tableData', tableData);
    return <div>
    <Dialog 
            open={true}
            onClose={() => {
                props.close();
            }}
            fullWidth={true}
            maxWidth="md"
        >
            <div className="dialog-wrapper">
                <div className="dialog-closer" onClick={() => { props.close() }}>
                    <Close />
                </div>
        <h3><strong>Order Details</strong></h3>
        
        <Table 
            tableHead={tableHeadBlue}
            tableData={tableData}
        />
        </div>
        </Dialog>
    </div>
}

export default SAPMonitorOrderDetailsTable;