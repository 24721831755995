import React, { Component } from 'react';
import ReactDOM from "react-dom";
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import ReactTable from "react-table";
import GridItem from "components/Grid/GridItem.jsx";

import GINpickValuesGeneral from "./GINpickValuesGeneral";
import GINpickValuesMeasurements from "./GINpickValuesMeasurements";
import GINpickValuesVariants from "./GINpickValuesVariants";
import GINrightmostSidebar from './GINrightmost-sidebar';
import GINalert from './GINalert';
import { checkVariantExistence, fieldList, fieldMainTab, checkCode } from './GINhelpers';

import _ from 'lodash';

class GINpickValues extends Component {
  constructor(props) {
    super(props);

    this.state = {
      generalComplete: false,
      measurementsComplete: false,
      variantsComplete: false,

      showGeneral: true,
      showMeasurements: false,
      showVariants: false,

      selectedFields: {}, // for the sidebar,
      invalidNext: false,
      pendingVariants: undefined // for the sidebar
    }
  }

  reportStatus = (obj) => {
    console.log('reporting status');
    this.setState(obj, () => {
      if (obj.generalComplete) {
        this.setState({ showGeneral: false, showMeasurements: true, showVariants: false });
      }

      if (obj.measurementsComplete) {
        this.setState({ showGeneral: false, showMeasurements: false, showVariants: true })
      }
    });
  }

  selectTab = (tabName) => {
    const toHide = ['General','Measurements','Variants'].filter(field => {
      if (tabName !== field) {
        return true;
      }
    });
    let obj = {};
    obj[`show${tabName}`] = true;
    toHide.forEach(tab => { obj[`show${tab}`] = false });
    this.setState(obj);
  }

  onUpdateField(field, value){
    const {selectedFields} = this.state;

    selectedFields[field] = value;

    this.setState({selectedFields});
  }

  checkSimilarities = () => {
    let obj = {};
    fieldList.forEach(field => {
      if (this.props[field]) {
        let collection = {};
        Object.keys(this.props[field]).forEach(db => {
          const val = this.props[field][db];
          if (val) {
            if (!collection.hasOwnProperty(val)) {
              collection[val] = 1;
            } else {
              collection[val]++;
            }
          }
        });
        if (Object.keys(collection).length === 1) {
          obj[field] = Object.keys(collection)[0];
        }
      }
    });
    console.log('this is the collected similarities', obj);
    return obj;
  }

  checkIfComplete = (obj, type) => {
    let isComplete = true;
    fieldMainTab[type].forEach(field => {
      if (!obj.hasOwnProperty(field)) {
        isComplete = false;
      }
    });
    return isComplete;
  }

  checkIfCompleteVariants = () => {
    let isComplete = true;
    let variants = {}, variantsDesc = {};
    Object.keys(this.props.variants).forEach(db => {
      if (this.props.variants[db]) {
        this.props.variants[db].forEach(vr => {
          if (variants.hasOwnProperty(vr[0])) {
            variants[vr[0]]++;
          } else {
            variants[vr[0]] = 1;
          }
        });
      }
    });

    Object.keys(this.props.variantsDesc).forEach(db => {
      if (this.props.variantsDesc[db]) {
        this.props.variantsDesc[db].forEach(vr => {
          if (variantsDesc.hasOwnProperty(vr)) {
            variantsDesc[vr]++;
          } else {
            variantsDesc[vr] = 1;
          }
        });
      }
    });

    console.log('checking if all variants are equal', variants, variantsDesc);

    let data = [];
    Object.keys(variants).forEach(vr => {
      data.push({
        code: vr
      });
      if (variants[vr] !== this.props.openedDBs.length) {
        isComplete = false;
      }
    });

    Object.keys(variantsDesc).forEach((vr,i) => {
      if (data[i]) {
        data[i].desc = vr;
      }
      if (variantsDesc[vr] !== this.props.openedDBs.length) {
        isComplete = false;
      }
    });
    return {
      status: isComplete,
      data
    };
  }

  onNextClick(){
    for(let field of _.concat(fieldList, ['variants'])){
      if(field === 'variants' && !this.state.variantActivity){
        this.setState({invalidNext: field});
        return;
      }

      if(this.props.picked[field] === undefined){
        this.setState({invalidNext: field});
        return;
      }
    }

    this.props.onSelectTab('PricingMatrix');
  }

  onChangeTab(tab){
    if(_.indexOf(fieldMainTab.general, tab) !== -1){
      this.selectTab('General');
      this._generalTab.setState({activeTab: tab});
    } else if (_.indexOf(fieldMainTab.measurements, tab) !== -1){
      this.selectTab('Measurements');
      this._measurementsTab.setTab(tab);
    } else if (_.indexOf(fieldMainTab.variants, tab) !== -1){
      this.selectTab('Variants');
    }
  }

  checkIfComplete = () => {
    const generalComplete = _.every(fieldMainTab.general, v => this.props.picked[v] !== undefined) ?
                            true : false,
          measurementsComplete = _.every(fieldMainTab.measurements, v => this.props.picked[v] !== undefined) ?
                            true : false,
          variantsComplete = this.props.variantStatus ? true : false;
    console.log('looking for completedness', generalComplete, measurementsComplete, variantsComplete);
    if ((generalComplete && measurementsComplete) && variantsComplete) {
      return true;
    }
    return false;
  }

  componentDidMount() {
    // const similarObj = this.checkSimilarities(),
    //       generalComplete = this.checkIfComplete(similarObj, 'general'),
    //       measurementsComplete = this.checkIfComplete(similarObj, 'measurements');
    //
    // console.log('looking up general complete',generalComplete, measurementsComplete);
    // this.setState({
    //   generalComplete,
    //   measurementsComplete
    // })
    const complete = this.checkIfComplete();
  }

  componentDidUpdate() {
    if (this.props.fromNextTab) {
      const complete = this.checkIfComplete();
      if (complete) {
        this.props.moveTab();
        this.props.setFromNextTab(false);
      }
    }
  }

  render() {
    console.log('v props',this.props);
    console.log('v state', this.state);
    // const similarObj = this.checkSimilarities(),
    //       generalComplete = this.checkIfComplete(similarObj, 'general'),
    //       measurementsComplete = this.checkIfComplete(similarObj, 'measurements');
          //variantsComplete = this.checkIfCompleteVariants();

    const generalComplete = this.state.generalComplete,
          measurementsComplete = this.state.measurementsComplete;

    return (
      <Card style={{marginTop:10,marginBottom:10}}>
        {
          !!this.state.invalidNext && <GINalert
            hide={() => {
              this.onChangeTab(this.state.invalidNext);
              this.setState({invalidNext: false});
            }}
            title="All fields need to be completed."
            close={() => {
              this.onChangeTab(this.state.invalidNext);
              this.setState({invalidNext: false});
            }}
          />
        }
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={10} md={10}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <div className="text-center">
                    {
                      _.every(fieldMainTab.general, v => this.props.picked[v] !== undefined)
                      ? <div className="blue-text">Completed</div>
                      : <div className="red-text">Incomplete</div>
                    }
                  </div>
                  <div className="text-center">
                    <div
                      className={this.state.showGeneral ? "big-btn blue-bg" : "big-btn white-bg"}
                      onClick={() => {
                        this.selectTab('General');
                      }}
                    >
                      GENERAL INFORMATION
                    </div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <div className="text-center">
                    {
                      _.every(fieldMainTab.measurements, (v) => {
                        console.log('v',v, this.props.picked[v]);
                        return this.props.picked[v] !== undefined;
                      })
                      ? <div className="blue-text">Completed</div>
                      : <div className="red-text">Incomplete</div>
                    }
                  </div>
                  <div className="text-center">
                    <div
                      className={this.state.showMeasurements ? "big-btn blue-bg" : "big-btn white-bg"}
                      onClick={() => {
                        this.selectTab('Measurements');
                      }}
                    >
                      <span>MEASUREMENTS</span>
                    </div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <div className="text-center">
                    {/*
                      !this.state.variantActivity && _.every(fieldMainTab.variants, v => this.props.picked[v] !== undefined)
                      ? <div className="blue-text">Completed</div>
                      : <div className="red-text">Incomplete</div>
                    */}
                    {
                      this.props.variantStatus
                      ? <div className="blue-text">Completed</div>
                      : <div className="red-text">Incomplete</div>
                    }
                  </div>
                  <div className="text-center">
                    <div
                      className={this.state.showVariants ? "big-btn blue-bg" : "big-btn white-bg"}
                      onClick={() => this.selectTab('Variants')}
                    >
                      <span>VARIANTS</span>
                    </div>
                  </div>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <GINpickValuesGeneral
                    active={this.state.showGeneral}
                    onRef={ref => this._generalTab = ref}
                    onUpdateField={this.onUpdateField.bind(this)}
                    onNextClick={this.onNextClick.bind(this)}
                    nextButtonCompleted={_.every(_.concat(fieldList, ['variants']), v => this.props.picked[v] !== undefined)}
                    reportStatus={this.reportStatus}
                    selectedFields={this.state.selectedFields}
                  />
                  <GINpickValuesMeasurements
                    active={this.state.showMeasurements}
                    onRef={ref => this._measurementsTab = ref}
                    onNextClick={this.onNextClick.bind(this)}
                    nextButtonCompleted={_.every(_.concat(fieldList, ['variants']), v => this.props.picked[v] !== undefined)}
                    reportStatus={this.reportStatus}
                    selectedFields={this.state.selectedFields}
                  />
                  <GINpickValuesVariants
                    ginSource={this.props.ginSource}
                    active={this.state.showVariants}
                    selectedFields={this.state.selectedFields}
                    onRef={ref => this._variantsTab = ref}
                    onChangeVariants={(pendingVariants) => this.setState({pendingVariants: pendingVariants})}
                    />
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12} sm={2} md={2}>
              <GINrightmostSidebar
                pendingVariants={this.state.pendingVariants}
                onChangeTab={this.onChangeTab.bind(this)} />
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    )
  }
}

GINpickValues.defaultProps = {
  onChangeVariant: () => {}
}

const mapStateToProps = state => {
  let variantStatus = false;
  if (checkCode(state.gin.variantsChecked)) {
    console.log('checking vc', checkCode(state.gin.variantsChecked), state.gin.variantsChecked);
    variantStatus = true;
  }

  // if (!checkVariantExistence(state.gin.variants)) {
  //   console.log('checking vc2', checkVariantExistence(state.gin.variants));
  //   variantStatus = true;
  // }

  return {
    base_item_description: state.gin.base_item_description,
    product_type: state.gin.product_type,
    rbo: state.gin.rbo,
    brand: state.gin.brand,
    subgroup: state.gin.subgroup,
    ipackagingtypes: state.gin.ipackagingtypes,
    sales_unit: state.gin.sales_unit,
    basic_packaging_unit: state.gin.basic_packaging_unit,
    qty_per_unit: state.gin.qty_per_unit,
    ilength: state.gin.ilength,
    width: state.gin.width,
    weight: state.gin.weight,
    variants: state.gin.variants,
    variantsDesc: state.gin.variantsDesc,
    variantStatus,
    variantsChecked: state.gin.variantsChecked,
    openedDBs: state.gin.openedDBs,
    picked: state.gin.picked
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateGINEdit: (data) => {
      dispatch(actions.updateGINEdit(data));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GINpickValues);
