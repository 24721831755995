import { put, call } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import axios from '../axios/axios-rfid';
import axios2 from 'axios';
import * as actions from '../actions/RFID';
import devStatus from 'shared/devStatus';
import { clone } from '../../shared/utility';
import { logic, checkIfWithinSerials, decConvert } from 'containers/RFID/RFIDlogic';
import checkPermissionsActions from 'shared/checkPermissionsActions';

const checkOverrideGS1Permissions = () => {
  const actionPerms = checkPermissionsActions('RFID');
  if (actionPerms.hasOwnProperty('override')) {
    return true;
  } else {
    return false;
  }
}

export function* loadRFIDprefixSaga(action) {
  try {
    const response = yield axios.get('/prefixes');
    console.log(response.data);
    yield put(actions.loadRFIDprefixSuccess(response.data));
  } catch (err) {
    console.log(err);
    yield put(actions.loadRFIDprefixFail(err));
  }
}

export function* loadRFIDcompaniesSaga(action) {
  try {
    const response = yield axios.get('/companies');
    console.log(response.data);
    yield put(actions.loadRFIDcompaniesSuccess(response.data));
  } catch (err) {
    console.log(err);
    yield put(actions.loadRFIDcompaniesFail(err));
  }
}

export function* rfidExportEPCSaga(action) {
  try {
    const response = yield axios.post('/recent_serial', action.data);
    console.log('response from serial',action.data, response.data);
    const dat = response.data;
    dat.gs1_prefix = action.data.gs1_prefix;
    dat.noSerialChange = action.data.noSerialChange;
    dat.company_id = action.data.company_id;
    yield put(actions.rfidExportEPCSuccess(dat));
  } catch (err) {
    console.log(err);
    yield put(actions.rfidExportEPCFail(err));
  }
}

export function* rfidLoadHistorySaga(action) {
  try {
    const response = yield axios.get('/history');
    console.log('history fetch', response.data);
    yield put(actions.rfidLoadHistorySuccess(response.data));
  } catch (err) {
    console.log(err);
    yield put(actions.rfidLoadHistoryFail(err));
  }
}

const simulateGS1API = (gtin14, length) => {
  const gs1_prefix = logic.retrieveGS1Prefix({
    length,
    gtin14
  });
  const dataObj = [
    {
        "Source": "NATco override",
        "EntityGLN": "",
        "CompanyName": "",
        "StreetAddress1": "",
        "StreetAddress2": "",
        "StreetAddress3": "",
        "City": "",
        "StateProvince": "",
        "ZipCode": "",
        "Country": "",
        "GSRN": "",
        "ModifiedDate": "",
        "Prefixes": {
            "UPCPrefix": "",
            "GS1Prefix": gs1_prefix,
            "PrefixStatus": "",
            "ModifiedDate": ""
        }
    }
  ];
  const response = {
    data: {
      dataObj,
      gtin14
    }
  }
  return response;
}

function checkForGS1PrefixExistence(response) {
  let gs1_prefix_found = false;
  if (response.data) {
    if (response.data.dataObj) {
      if (response.data.dataObj.length > 0) {
        const dobj = response.data.dataObj[0];
        if (dobj.Prefixes) {
          if (dobj.Prefixes.GS1Prefix) {
            let prefix = dobj.Prefixes.GS1Prefix;
            if (prefix) {
              prefix = prefix.trim();
              if (prefix) {
                if (!isNaN(prefix)) {
                  gs1_prefix_found = true;
                }
              }
            }
          }
        }
      }
    }
  }
  return gs1_prefix_found;
}

export function* loadGS1Saga(action) {
  yield put(actions.loadGS1CompanyPrefixStart());
  try {
    if (action.forceError) {
      throw "Force error";
    }
    const overridePermitted = checkOverrideGS1Permissions();
    if (action.length && overridePermitted) {
      console.log('overriding GS1 API');
      const actioned = clone(action);
      const response = simulateGS1API(actioned.gtin14, actioned.length);
      yield put(actions.loadGS1CompanyPrefixSuccess(response.data));
    } else {
      const gs1URL = '/gs1/'+action.gtin14;
      const response = yield axios.get(gs1URL);
      console.log('response for gs1 ->',response,response.data);
      const gs1_prefix_found = checkForGS1PrefixExistence(response);
      if (!gs1_prefix_found) {
        const responseNotification = yield axios.post('/send_helpdesk_notification', {
          upc: action.gtin14
        });
        if (responseNotification.data) {
          console.log('responseNotification', responseNotification.data);
        }
      }
      yield put(actions.loadGS1CompanyPrefixSuccess(response.data));
    }
  } catch (err) {
    console.log(err);
    yield put(actions.loadGS1CompanyPrefixFail(err));
  }
}

export function* checkGS1APISaga(action) {
  yield put(actions.checkGS1APIStart());
  try {
    if (action.forceError) {
      throw "Force error";
    }
    const response = yield axios.get('/gs1/884514038205');
    console.log('response for gs1 api check',response,response.data);
    const gs1_prefix_found = checkForGS1PrefixExistence(response);
    if (!gs1_prefix_found) {
      const responseNotification = yield axios.post('/send_helpdesk_notification', {
        upc: '884514038205',
        gs1_api_check: true
      });
      if (responseNotification.data) {
        console.log('responseNotification', responseNotification.data);
      }
    }
    yield put(actions.checkGS1APISuccess(response.data));
  } catch (err) {
    console.log(err);
    yield axios.post('/send_helpdesk_notification', {
      upc: '884514038205',
      gs1_api_check: true
    });
    yield put(actions.checkGS1APIFail(err));
  }
}

export function* generateRFIDExcelSaga(action) {
  yield put(actions.generateRFIDExcelStart());
  const dat = action.data;
  let arr = [];
  if (dat.productionQty) {
    let productionQ = parseInt(dat.productionQty);
    const maxCutoff = 10000;
    if (productionQ > maxCutoff) {
      // divide
      const divisions = Math.ceil(productionQ / maxCutoff);
      let remaining = productionQ;
      let startingSerial = parseInt(dat.serial);
      for (let i = 0; i < divisions; i++) {
        let quantity = JSON.parse(JSON.stringify(maxCutoff));
        if (i === divisions - 1) {
          quantity = remaining;
        } else {
          remaining = remaining - maxCutoff;
        }
        if (i !== 0) {
          if (i === divisions - 1 ) {
            startingSerial += maxCutoff;
          } else {
            startingSerial += quantity;
          }
          console.log('-startingSerial', startingSerial, quantity);
        }
        arr.push({
          upcCode: dat.upcCode,
          productionQty: quantity,
          serial: startingSerial,
          manufacturerID: dat.manufacturerID,
          rfidType: dat.rfidType,
          gs1_prefix: dat.gs1_prefix,
          brand: dat.brand
        })
      }
    }
  }
  if (arr.length > 0) {
    let fileDownloads = [];
    for (let i = 0; i < arr.length; i++) {
      yield put(actions.generateRFIDExcelStart(((i+1)/arr.length)));
      try {
        const response = yield axios.post('/generate_excel/', {data:arr[i]});
        console.log('response excelfile',response,response.data);
        fileDownloads.push(response.data.filename);
        if (i === arr.length - 1) {
          yield put(actions.generateRFIDExcelSuccess({
            filename:fileDownloads.join(',')
          }));
        }
      } catch (err) {
        console.log(err);
        yield put(actions.generateRFIDExcelFail(err));
      }
    }
  } else {
    try {
      const response = yield axios.post('/generate_excel/', {data:action.data});
      yield put(actions.generateRFIDExcelSuccess(response.data));
    } catch (err) {
      console.log(err);
      yield put(actions.generateRFIDExcelFail(err));
    }
  }
}

export function* verifyRFIDExcelSaga(action) {
  yield put(actions.verifyRFIDExcelStart());
  var data = [];
  console.log('downloading array');
  const arr = action.filenames;
  for (let i = 0; i < arr.length; i++) {
    try {
      const response = yield axios.get('/verify_excel/'+arr[i]);
      data.push(response.data);
      const percent = (i+1) / arr.length;
      yield put(actions.verifyRFIDExcelUpdate(percent));
      if (i === arr.length - 1) {
        yield put(actions.verifyRFIDExcelSuccess(data));
      }
    } catch (err) {
      console.log(err);
      yield put(actions.verifyRFIDExcelFail(err));
    }
  }
}

export function* getRfidJcdataSaga(action) {
  yield put(actions.getRfidJcdataStart());
  const config = action.config;
  const data = clone(action.data);
  const { filenames, final } = config;
  const collected = {
    c128: {},
    gtin: {}
  }

  const types = ['c128','gtin'];
  for (let x = 0; x < types.length; x++) {
    const type = types[x];
    const dc = data[type];
    const upcs = Object.keys(dc);
    for (let i = 0; i < upcs.length; i++) {
      const upc = upcs[i];
      const dat = dc[upc];
      for (let j = 0; j < dat.length; j++) {
        const row = dat[j];
        console.log('response from serial rows', row)
        try {
          const runs = row.runs ? parseInt(row.runs) : 1;
          const two_percent = 0.02 * runs;
          const run_plus_five = runs + 5;
          const production_qty = two_percent > run_plus_five ?
              two_percent  
          :
              run_plus_five;
          const company_id = type === 'c128' ? 4 : 3;
          const upc_code = company_id === 3 ? decConvert(row[type], 14) : row[type];
          const obj = {
            upc_code,
            production_qty,
            company_id,
            startingSerial: 
              row.starting_serial ? 
                row.starting_serial 
              : 
                null,
            final,
          };

          const the_type = row.gtin ? 'gtin' : 'c128';

          let theStartingSerial;
          let theEndingSerial;
          let results;
          
          const row_upc = row[type];
          const used_upcs = collected[the_type].hasOwnProperty(row_upc);
          if (!used_upcs) {
            const response = yield axios.post('/recent_serial', obj);
            const dat = response.data;
            results = dat.results;
            console.log('response from serial', obj, dat);

            theStartingSerial = row.starting_serial ? 
              row.starting_serial
            : 
              dat.last_serial ? 
                (dat.last_serial + 1)
              :
                25000000000;
            theEndingSerial = parseInt(theStartingSerial) + (production_qty - 1);
            dat.startingSerial = theStartingSerial;
            data[type][upc][j].starting_serial = theStartingSerial;
            data[type][upc][j].ending_serial = theEndingSerial;
            data[type][upc][j].qty = production_qty;
            data[type][upc][j].within_serial = checkIfWithinSerials(dat.results, {
              startingSerial: theStartingSerial,
              productionQty: production_qty,
              manufacturerID: ''
            })

            collected[the_type][upc] = {
              serial: [theEndingSerial],
              results: [dat.results]
            };
          } else {
            const lastEntryIndex = collected[the_type][upc].serial.length - 1;
            const the_latest_serial = collected[the_type][upc].serial[lastEntryIndex];
            const the_latest_results = collected[the_type][upc].results[lastEntryIndex];
            
            theStartingSerial = the_latest_serial + 1;
            theEndingSerial = parseInt(theStartingSerial) + (production_qty - 1);
            collected[the_type][upc].serial.push(theEndingSerial);
            collected[the_type][upc].results.push(results);
            dat.startingSerial = theStartingSerial;
            data[type][upc][j].starting_serial = theStartingSerial;
            data[type][upc][j].ending_serial = theEndingSerial;
            data[type][upc][j].qty = production_qty;
            data[type][upc][j].within_serial = checkIfWithinSerials(the_latest_results, {
              startingSerial: theStartingSerial,
              productionQty: production_qty,
              manufacturerID: ''
            })
          }

          

          if (type === 'c128') {
            // START EPC
            const resultsObj1 = {
              upcCode: upc_code,
              productionQty: 1,
              serial: theStartingSerial,
              company_id
            };
            console.log('generating results 1', resultsObj1)
            const results_start = logic.UPCtoEPC(resultsObj1)
            console.log('generating results 2', results_start);
            if (results_start.length > 0) {
              data.c128[upc][j].starting_epc = results_start[0].epcCode;
            }
            // END EPC 
            const resultsObj2 = {
              upcCode: upc_code,
              productionQty: 1,
              serial: theEndingSerial,
              company_id
            };
            console.log('generating results 1', resultsObj2)
            const results_end = logic.UPCtoEPC(resultsObj2)
            console.log('generating results 2', results_end);
            if (results_end.length > 0) {
              data.c128[upc][j].ending_epc = results_end[0].epcCode;
            }
          } else if (type === 'gtin') {
            const the_gtin = decConvert(data.gtin[upc][j].gtin, 14);
            const original_gtin = data.gtin[upc][j].gtin;
            let gs1_response;
            const overrideGS1 = localStorage.getItem('overrideGS1');
            const shouldOverrideGS1Length = localStorage.getItem('overrideGS1Length');
            const overridePermitted = checkOverrideGS1Permissions();
            if (overrideGS1 && (shouldOverrideGS1Length && overridePermitted)) {
              gs1_response = simulateGS1API(the_gtin, shouldOverrideGS1Length);
            } else {
              gs1_response = yield axios.get('/gs1/'+the_gtin);
            }
            console.log('response for gs1', gs1_response.data);
            const d = gs1_response.data;
            if (d.dataObj) {
              if (d.dataObj[0]) {
                if (d.dataObj[0].Prefixes) {
                  if (d.dataObj[0].Prefixes.GS1Prefix) {
                    const gs1_prefix = d.dataObj[0].Prefixes.GS1Prefix;
                    const the_gs1_prefix = {
                      prefix: gs1_prefix,
                      gtin14: the_gtin
                    };
                    console.log('the_gs1_prefix', the_gs1_prefix);
                    // START EPC
                    const resultsObjA1 = {
                      upcCode: original_gtin,
                      productionQty: 1,
                      company_id,
                      serial: theStartingSerial,
                      gs1_prefix: the_gs1_prefix,
                      rfidType: 'C',
                      final,
                    };
                    console.log('generating results 1', resultsObjA1)
                    const results_start = logic.UPCtoEPC(resultsObjA1)
                    console.log('generating results 2', results_start);
                    if (results_start.length > 0) {
                      data.gtin[upc][j].starting_epc = results_start[0].epcCode;
                      data.gtin[upc][j].gs1_prefix = the_gs1_prefix;
                      console.log('got here', data);
                    }
                    if (!final) {
                      // END EPC 
                      const resultsObjA2 = {
                        upcCode: original_gtin,
                        productionQty: 1,
                        company_id,
                        serial: theEndingSerial,
                        gs1_prefix: {
                          prefix: gs1_prefix,
                          gtin14: the_gtin
                        },
                        rfidType: 'C',
                        final,
                      };
                      console.log('generating results 1', resultsObjA2)
                      const results_end = logic.UPCtoEPC(resultsObjA2)
                      console.log('generating results 2', results_end);
                      if (results_end.length > 0) {
                        data.gtin[upc][j].ending_epc = results_end[0].epcCode;
                      }
                    }
                    
                  }
                }
              }
            }
          }
          
          yield put(actions.getRfidJcdataSuccess(clone(data)));
        } catch (e) {
            if (e.response) {
                if (e.response.data) {
                  console.log('e', e.response.data, e.response);
                }
            }
        }
      }
    }
  }
  if (final) {
    // record in history
    const history_response = yield axios.post('/importer_history', {
      filenames,
      data: JSON.stringify(data),
      type: 'jc'
    });
    console.log('history_response', history_response);
    const response_excel = yield axios.get('/importer_excel?id='+history_response.data.id);
    yield put(actions.getRfidImporterExcelSuccess(response_excel.data));
    yield put(actions.getRfidJcdataSuccess(clone(data), true));
  }
  console.log('final data', data);
}

export function* getRfidAllBirdsSaga(action) {
  yield put(actions.getRfidAllBirdsStart());
  const config = action.config;
  const data = clone(action.data);
  console.log('data', data);
  const the_type = 'allbirds';
  const { filenames, final } = config;
  const collected = {
    allbirds: {}
  }
  const type = 'allbirds';
    const dc = data;
    const upcs = Object.keys(dc);
    let stop = false;
    for (let i = 0; i < upcs.length; i++) {
      const upc = upcs[i];
      const dat = dc[upc];
      for (let j = 0; j < dat.length; j++) {
        const row = dat[j];
        try {
          const qty = row.order_quantity ? parseInt(row.order_quantity) : 0;
          const three_percent = Math.floor(qty * 0.03);
          const qty_plus_five = qty + 5;
          const production_qty = (three_percent > 5) ?
            three_percent + qty
          :
            qty_plus_five;
          const company_id = 1;
          const upc_code = row.upc;
          const obj = {
              upc_code,
              production_qty,
              company_id,
              startingSerial: 
                row.starting_serial ? 
                  row.starting_serial 
                : 
                  null,
              final,
            };

            let theStartingSerial;
            let theEndingSerial;
            let results;
  
            const used_upcs = collected[type].hasOwnProperty(upc_code);
            if (!used_upcs) {
              const response = yield axios.post('/recent_serial', obj);
              const dat = response.data;
              console.log('response from serial', obj, dat);
              results = dat.results;
  
              theStartingSerial = row.starting_serial ? 
                row.starting_serial
              : 
                dat.last_serial ? 
                  (dat.last_serial + 1)
                :
                  10000000000;
              theEndingSerial = parseInt(theStartingSerial) + (production_qty - 1);
              dat.startingSerial = theStartingSerial;
              data[upc][j].starting_serial = theStartingSerial;
              data[upc][j].ending_serial = theEndingSerial;
              data[upc][j].qty = production_qty;
              data[upc][j].within_serial = checkIfWithinSerials(dat.results, {
                startingSerial: theStartingSerial,
                productionQty: production_qty,
                manufacturerID: ''
              })
              collected[the_type][upc] = {
                serial: [theEndingSerial],
                results: [dat.results]
              };
            } else {
              const lastEntryIndex = collected[the_type][upc].serial.length - 1;
              const the_latest_serial = collected[the_type][upc].serial[lastEntryIndex];
              const the_latest_results = collected[the_type][upc].results[lastEntryIndex];
              theStartingSerial = the_latest_serial + 1;
              theEndingSerial = parseInt(theStartingSerial) + (production_qty - 1);
              collected[the_type][upc].serial.push(theEndingSerial);
              collected[the_type][upc].results.push(results);
              dat.startingSerial = theStartingSerial;
              data[upc][j].starting_serial = theStartingSerial;
              data[upc][j].ending_serial = theEndingSerial;
              data[upc][j].qty = production_qty;
              data[upc][j].within_serial = checkIfWithinSerials(the_latest_results, {
                startingSerial: theStartingSerial,
                productionQty: production_qty,
                manufacturerID: ''
              })
              console.log('collected', collected);
            }

            const the_gtin = decConvert(upc_code, 14);
            const original_gtin = upc_code;
            let gs1_response;
            const overrideGS1 = action.overrideGS1 ? true : false;
            const shouldOverrideGS1Length = action.shouldOverrideGS1Length ? action.shouldOverrideGS1Length : '';
            const overridePermitted = checkOverrideGS1Permissions();
            if (overrideGS1 && (shouldOverrideGS1Length && overridePermitted)) {
              gs1_response = simulateGS1API(the_gtin, shouldOverrideGS1Length);
              console.log('processed -> overriding', gs1_response);
            } else {
              gs1_response = yield axios.get('/gs1/'+the_gtin);
            }
            if (action.forceError && (!shouldOverrideGS1Length && !overrideGS1)) {
              console.log('response got here');
              gs1_response = { 
                data: { dataObj: null }
              };
            }
            console.log('response for gs1', gs1_response.data);
            const d = gs1_response.data;
            let gs1prefixIsGood = false;
            if (d.dataObj) {
              if (d.dataObj[0]) {
                if (d.dataObj[0].Prefixes) {
                  if (d.dataObj[0].Prefixes.GS1Prefix) {
                    const gs1_prefix = d.dataObj[0].Prefixes.GS1Prefix;
                    console.log('processed -> gs1_prefix', gs1_prefix)
                    if (!isNaN(gs1_prefix)) {
                      gs1prefixIsGood = true;
                    }
                    const the_gs1_prefix = {
                      prefix: gs1_prefix,
                      gtin14: the_gtin
                    };
                    console.log('the_gs1_prefix', the_gs1_prefix);
                    // START EPC
                    const resultsObjA1 = {
                      upcCode: original_gtin,
                      productionQty: 1,
                      company_id,
                      serial: theStartingSerial,
                      gs1_prefix: the_gs1_prefix,
                      rfidType: 'C',
                      final,
                    };
                    console.log('generating results 1', resultsObjA1)
                    const results_start = logic.UPCtoEPC(resultsObjA1)
                    console.log('generating results 2', results_start);
                    if (results_start.length > 0) {
                      data[upc][j].starting_epc = results_start[0].epcCode;
                      data[upc][j].gs1_prefix = the_gs1_prefix;
                      console.log('got here', data);
                    }
                    if (!final) {
                      // END EPC 
                      const resultsObjA2 = {
                        upcCode: original_gtin,
                        productionQty: 1,
                        company_id,
                        serial: theEndingSerial,
                        gs1_prefix: {
                          prefix: gs1_prefix,
                          gtin14: the_gtin
                        },
                        rfidType: 'C',
                        final,
                      };
                      console.log('generating results 1', resultsObjA2)
                      const results_end = logic.UPCtoEPC(resultsObjA2)
                      console.log('generating results 2', results_end);
                      if (results_end.length > 0) {
                        data[upc][j].ending_epc = results_end[0].epcCode;
                      }
                    }
                    
                  }
                }
              }
            }

            console.log('processed -> ', gs1prefixIsGood, action)
            if ((action.forceError && !action.overrideGS1) || !gs1prefixIsGood) {
              data[upc][j].starting_epc = 'ERROR';
              data[upc][j].gs1_prefix = 'ERROR';
              data[upc][j].ending_epc= 'ERROR';
              stop = true;
            }

            console.log('got here the data->', data);
            yield put(actions.getRfidAllBirdsSuccess(clone(data)));
        } catch(e) {
          console.log('error', e)
        }
      }
      if (stop) break;
    }
  if (!final) yield put(actions.getRfidAllBirdsSuccessFinal());
  if (final) {
    // record in history
    const history_response = yield axios.post('/importer_history', {
      filenames,
      data: JSON.stringify(data),
      type: 'allbirds'
    });
    console.log('history_response', history_response);
    const response_excel = yield axios.get('/importer_excel?id='+history_response.data.id+'&type=allbirds');
    yield put(actions.getRfidImporterExcelSuccess(response_excel.data));
    yield put(actions.getRfidAllBirdsSuccessFinal());
    yield put(actions.getRfidAllBirdsSuccess(clone(data), true));
  }
  console.log('final data', data);
}

export function* getRfidImporterHistorySaga(action) {
  yield put(actions.getRfidImporterHistoryStart());
  try {
    if (action.data) {
      if (typeof action.data !== 'object') {
        const response = yield axios.get('/importer_history?id='+action.data);
        yield put(actions.getRfidImporterHistorySuccess(response.data, true));
      } else {
        const response = yield axios.get('/importer_history?type='+action.data.type);
        yield put(actions.getRfidImporterHistorySuccess(response.data));
      }
    } else {
      const response = yield axios.get('/importer_history');
      yield put(actions.getRfidImporterHistorySuccess(response.data));
    }
  } catch (e) {
      if (e.response) {
          if (e.response.data) {
            return yield put(actions.getRfidImporterHistoryFail(e.response.data));
          }
        }
      yield put(actions.getRfidImporterHistoryFail(e));
  }
}

export function* getRfidImporterExcelSaga(action) {
  yield put(actions.getRfidImporterExcelStart());
  try {
      const response = yield axios.get('/importer_excel?id='+action.data);
      yield put(actions.getRfidImporterExcelSuccess(response.data));
  } catch (e) {
      if (e.response) {
          if (e.response.data) {
            return yield put(actions.getRfidImporterExcelFail(e.response.data));
          }
        }
      yield put(actions.getRfidImporterExcelFail(e));
  }
}
