import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Card, CardContent } from '@material-ui/core';
import Button from "../../components/CustomButtons/Button.jsx";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ReactTable from "react-table";
import GINreviewDialog from "./GINreview-dialog";
import GINreviewDetailedDB from "./GINreview-detailed-db";
import GINreviewDialogPickValue from "./GINreview-dialog-pickvalue";
import GINreviewDialogVariants from "./GINreview-dialog-variants";
import GINreviewDialogEdit from "./GINreview-dialog-edit";
import { ranges, rangesObj } from "./GINranges.js";
import activeDBs from "shared/activeDBs";
import { showPicksList } from "shared/activeDBs";
import { numberWithCommas } from 'shared/utility';

const getRange = (qty) => {
  let name = '';
  ranges.forEach((re) => {
    if (qty < 10000000) {
      if ((re.rangeA <= qty) && (qty <= re.rangeB)) {
        name = re.name;
      }
    } else {
      name = '10M+';
    }
  });
  return name;
}

const generateColor = (db) => {
  let dbcolor = '';
  activeDBs.forEach(d => { if (d.name === db) dbcolor = d.color; })
  return dbcolor;
}

const Blank = props => {
  return <div style={{marginLeft:20}}>
    <div><strong>{props.title}</strong></div>
    <div style={{marginTop:20}}>n / a</div>
  </div>
}

const DBlabel = props => {
  // props.name
  let backgroundColor = '';
  activeDBs.forEach((db) => {
    if (db.name === props.name) {
      backgroundColor = db.color;
    }
  });

  if (backgroundColor) {
    return (
      <span style={{backgroundColor,border:'1px solid rgba(0,0,0,0)'}} className="db-pill">
        {props.name.split('_')[0]}
      </span>
    );
  }
  return null;
}

class GINreviewDetailed extends React.Component{
    constructor(props) {
      super(props);

      this.state = {
        currentDB: props.openedDBs[0],
        hasChanged: []
      };

      this.content = React.createRef();
    }

    selectDB = (currentDB) => {
      this.setState({currentDB});
    }

    componentDidMount() {
      if (this.props.scrollToDB) {
        //this[`scrollTo${this.props.scrollToDB}`]('dbFocus');
        this.setState({currentDB:this.props.scrollToDB})
      }
    }

    renderNav = (key) => {
      const props = this.props;
      const internal = (
        <div style={{padding:5}}>
          <GridContainer>
            <GridItem xs={12} sm={1} md={1}>
              <div className="text-center" style={{marginTop:10,fontSize:10}}><strong>JUMP TO SECTION</strong></div>
              <div>
                {props.ginFinalCrop && <img src={props.ginFinalCrop} className="img-responsive" />}
              </div>
            </GridItem>
            <GridItem xs={12} sm={9} md={9}>
                <GridContainer className="denser-grid">
                  {
                    activeDBs.map(db => {
                      const currentDB = this.state.currentDB === db.name ? 'cover-blue' : '';
                      console.log('currentDB', currentDB);
                      const isActive = props.openedDBs.indexOf(db.name) === -1 ? 'disabled-opacity' : '';
                      return <GridItem xs={12} sm={1} md={1} key={`db-${db.name}-${key}`}>
                      <div className={`${isActive}`}>
                        <div className={`mbt-10 hovered hover-span-blue ${currentDB}`} onClick={() => {
                          //this[`scrollTo${db.name}`]('dbFocus');
                          this.selectDB(db.name);
                        }}>
                          <DBlabel name={db.name} />
                        </div>
                        <div
                          className="hovered hover-black blue-text bold small-text mbt-10" onClick={() => {
                            //this[`scrollTo${db.name}`]('baseFocus');
                            this.selectDB(db.name);
                            setTimeout(() => { this.scrollTo('baseFocus'); }, 500);
                        }}>Base Item Info</div>
                        <div
                          className="hovered hover-black blue-text bold small-text mbt-10" onClick={() => {
                            //this[`scrollTo${db.name}`]('variantFocus');
                            this.selectDB(db.name);
                            setTimeout(() => { this.scrollTo('variantFocus'); }, 500);
                        }}>Variants/BOM</div>
                        <div
                          className="hovered hover-black blue-text bold small-text mbt-10" onClick={() => {
                            //this[`scrollTo${db.name}`]('pricingFocus');
                            this.selectDB(db.name);
                            setTimeout(() => { this.scrollTo('pricingFocus'); }, 500);
                        }}>Pricing</div>
                        <div
                          className="hovered hover-black blue-text bold small-text mbt-10" onClick={() => {
                            //this[`scrollTo${db.name}`]('costingFocus');
                            this.selectDB(db.name);
                            setTimeout(() => { this.scrollTo('costingFocus'); }, 500);
                        }}>Costing</div>
                      </div>
                      </GridItem>
                    })
                  }
                </GridContainer>
            </GridItem>
            <GridItem xs={12} sm={2} md={2}>
              <div className="vertical-center">
                <div className="text-center vertical-center-tgt">
                  {!this.props.isHistory && <Button color="success" onClick={() => {
                    this.props.submit();
                  }}>SUBMIT TO SAP</Button>}
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      )
      return (
        <Card>
          {internal}
        </Card>
      )
    }

    checkForChanges = (hasChanged) => {
      console.log('hasChanged', hasChanged);
      this.setState({hasChanged});
    }

    render() {
      console.log('st ppr', this.state, this.props)
      const props = this.props;
        return (
      <div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div ref={this.content}>{this.renderNav('1')}</div>
            </GridItem>
          </GridContainer>
          {/*
            <GINreviewDetailedDB
              currentDB={db}
              setScroll={scrollTo => this[`scrollTo${db}`] = scrollTo}
            />
          */}
          {
            props.openedDBs.map((db,key) => {
              if (this.state.currentDB === db) {
                return <div key={`dbopened-${key}`}>
                <GINreviewDetailedDB
                  isHistory={this.props.isHistory}
                  currentDB={db}
                  key={`db${key}`}
                  setScroll={scrollTo => {
                    this.scrollTo = scrollTo
                  }}
                  checkDupes={this.props.checkDupes}
                  checkDupesSupplierCost={this.props.checkDupesSupplierCost}
                  checkDupesTargetCost={this.props.checkDupesTargetCost}
                  checkDupesCustomerPrice={this.props.checkDupesCustomerPrice}
                  checkForChanges={this.checkForChanges}
                  hasChanged={this.state.hasChanged}
                />
              </div>
              }
            //   return <div style={{display: db === this.state.currentDB ? 'block' : 'none'}} key={`dbopened-${key}`}>
            //   <GINreviewDetailedDB
            //     isHistory={this.props.isHistory}
            //     currentDB={db}
            //     key={`db${key}`}
            //     setScroll={scrollTo => {
            //       this.scrollTo = scrollTo
            //     }}
            //     checkDupes={this.props.checkDupes}
            //     checkDupesSupplierCost={this.props.checkDupesSupplierCost}
            //     checkDupesTargetCost={this.props.checkDupesTargetCost}
            //     checkDupesCustomerPrice={this.props.checkDupesCustomerPrice}
            //     checkForChanges={this.checkForChanges}
            //     hasChanged={this.state.hasChanged}
            //   />
            // </div>
            })
          }
      </div>
      )
    }
}

const mapStateToProps = state => {
  return {
    dbList: state.gin.dbList,
    loading: state.gin.loading,
    itemCodes: state.gin.itemCodes,
    openedDBs: state.gin.openedDBs,
    editMode: state.gin.editMode,
    picked: state.gin.picked,
    newItemList: state.gin.newItemList,
    options: state.gin.options,

    // IMAGE
    ginFinalCrop: state.gin.ginFinalCrop,
  }
}

export default connect(mapStateToProps)(GINreviewDetailed);
