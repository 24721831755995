import React from 'react';
import { PulseLoader } from 'react-spinners';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Table from "components/Table/TableSmall.jsx";
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import Button from "components/CustomButtons/Button.jsx";
import download from 'downloadjs';
import { domain } from 'shared/devStatus';

const RFIDdownloadPopup = props => {
  console.log('props',props);
  const filesArr = props.files.split(',');
  const downloads = props.filenamesData ? props.filenamesData : null;
  let totalProductionQty = 0;

  const downloadableFiles = downloads ? downloads.map((dat,i) => {
    console.log('calculating downloads data', dat)
    totalProductionQty += (dat.productionQty ? parseInt(dat.productionQty) : 0);
    if (props.downloads.indexOf(dat.filename) !== -1) return null;
    let tableData = [];
    tableData.push(['Filename', dat.filename]);
    tableData.push(['Download Link', <a href={`${domain}/assets/${dat.filename}`}
      download
      onClick={() => {
        props.addDownloads(dat.filename);
      }}>Click to Download File #{i+1}</a>]);
    tableData.push(['UPC', dat.upc]);
    const startingSerial = (props.isOverriden && i === 0) ?
        <div>{dat.startingSerial} <span style={{color:'#ff9800',fontWeight:'bold'}}>- OVERRIDEN</span></div> :
        <div>{dat.startingSerial}</div>;
    if (i === 0 && props.showOverride) {
      if (props.lastSerial) {
        tableData.push(['Previous Ending Serial', props.lastSerial]);
      }
    }
    tableData.push(['Starting Serial', startingSerial]);
    tableData.push(['Ending Serial', dat.endingSerial]);
    tableData.push(['Production Quantity', dat.productionQty ? dat.productionQty.toLocaleString() : dat.productionQty]);
    if (i === 0 && props.startingEPC) {
      tableData.push(['Starting EPC Verified', dat.startingEPC === props.startingEPC ? <div style={{color:'green'}}>{dat.startingEPC}</div> :
        <div style={{color:'red'}}>ERROR: EPC does not match, DO NOT USE, please try again or contact Tech Support</div>
      ]);
    }

    return <div style={{border:'1px solid #ccc',padding:5,marginBottom:20}} key={`tb-${i}`}><Table
      tableHeaderColor="primary"
      tableData={tableData}
    /></div>;
  }) : null;

  return (
    <Dialog
      onClose={props.close}
      open={props.open}
      keepMounted
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle style={{paddingBottom: 0}} onClose={() => props.close()}>
        {downloads ? <span>{downloads.length}</span> : null} Excel File Downloads {totalProductionQty ? <span>- Total Production Quantity {totalProductionQty.toLocaleString()}</span> : null}
      </DialogTitle>

      <DialogContent style={{overflowY:'unset'}}>
        <div style={{margin:20}}>
          {downloads && <div>
            {props.downloads.length !== filesArr.length && <p style={{color:'green'}}>Please verify the details of your Excel file contents before downloading</p>}
            {props.downloads.length === filesArr.length && <p>No more files to download</p>}
          </div>}
          {downloadableFiles}
          {props.verifyExcelsStatus && <div style={{color:'black',margin:20}}>
              Verifying excel data
              <div>{props.verifyUpdatePercentage ? (props.verifyUpdatePercentage * 100).toFixed() : 0} % done. Please wait...</div>
              <div style={{marginTop:10}}>
                <PulseLoader />
              </div>
          </div>}
          <Button
            color="primary"
            onClick={() => {
              props.close();
            }}
          >CLOSE</Button>

          {(props.downloads.length !== filesArr.length && !props.verifyExcelsStatus) && <Button
            style={{marginLeft:20}}
            color="success"
            onClick={() => {
              let time = 0;
              for (let i = 0; i < downloads.length; i++) {
                setTimeout(() => {
                  console.log('downloading...',i);
                  download(`${domain}/assets/${downloads[i].filename}`);
                  props.addDownloads(downloads[i].filename);
                }, i * 1200);
              }
              //props.close();
            }}
          >EVERYTHING IS CORRECT, DOWNLOAD ALL</Button>}
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default RFIDdownloadPopup
