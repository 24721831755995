import React, { Component } from 'react';
import ReactDOM from "react-dom";
import { connect } from 'react-redux';
import ReactTable from "react-table";
import * as actions from '../../store/actions';
import Datetime from 'react-datetime';
import { formatDate2, cleanAllPhones, formatTime } from 'shared/utility';
import { qs } from 'shared/utility.js';
import checkPermissionsActions from "shared/checkPermissionsActions";
import withStyles from "@material-ui/core/styles/withStyles";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import { Redirect } from 'react-router-dom';
import GridContainer from "../../components/Grid/GridContainer.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Cached from "@material-ui/icons/Cached";
import Button from "../../components/CustomButtons/Button.jsx";
import Dvr from "@material-ui/icons/Dvr";
import { FadeLoader, PulseLoader } from 'react-spinners';
import SweetAlert from "react-bootstrap-sweetalert";

import GMPMultipleSelect from 'containers/GMP/GMPMultipleSelect';
import BPExistingGMP from 'containers/BP/BPExistingGMP';
import BPForm from 'containers/BP/BPForm';
import BPGroupModal from 'containers/BP/BPGroupModal';
import BPSingleModal from 'containers/BP/BPSingleModal';
import moment from 'moment';

// import Modal from './GMPModal';

const getCombinations = (s) => {
	let combs = [];
	if (s.length >= 5) {
  	for (let i = 0; i < s.length; i++) {
    	if (s[i+4]) {
      	combs.push(`${s[i]}${s[i+1]}${s[i+2]}${s[i+3]}${s[i+4]}`);
      }
    }
  }
  return combs;
}

const filterProcess = (bpList) => {
  let bps = [];
  const bpObj = {}
  const bpObj2 = {};
  bpList.forEach(bp => {
    if (bp.name === 'GMP' || bp.name === 'TPP') {
      const timestamp = new Date(bp.date_modified).getTime();
      const bpname = bp.company_name ?
                                        bp.company_name.trim()
                                     :
                                        bp.customer_name ?
                                                          bp.customer_name.trim()
                                                         :
                                                          bp.customer_name;
      const db = bp.DB;
      const id = bp.id;

      const combo = `${db}__${bpname}`;
      if (bpObj.hasOwnProperty(combo)) {
        bpObj[combo].push(id);
        bpObj2[combo].push({id, bp});
      } else {
        bpObj[combo] = [id];
        bpObj2[combo] = [ {id, bp} ];
      }
    } else {
      bps.push(bp);
    }
  });
  // 5 consecutive characters search of existing group BP names
  Object.keys(bpObj).forEach((key) => {
    if (bpObj[key].length > 1) {
      const arr = key.split('__');
      const db = arr[0];
      const name = arr[1];
      if (name.length >= 5) {
        console.log('looking for', name);
        // get all possible consecutive 5 characters
        const possibles = getCombinations(name);
        console.log('looking for possibles', possibles);
        // search bps
        const bps_similar = bps.filter(bp => {
          if (bp.DB === db) {
            const bpname = bp.company_name ? bp.company_name : bp.customer_name;
            if (bpname === key) return false;
            console.log('looking for possibles bpname compare', bpname);
            if (bpname) {
              let pass = false;
              possibles.forEach((possible) => {
                if (bpname.indexOf(possible) !== -1) {
                  console.log('found bpname', possible, bpname);
                  pass = true;
                }
              })
              if (pass) return true;
            }
          }
        }).map(b => {
          return {
            id: b.id,
            bp: b,
            match: 'BP name'
          }
        });
        bpObj2[key] = bpObj2[key].concat(bps_similar);
      }
    }
  });

  Object.keys(bpObj2).forEach((key) => {
    const orig = bpObj2[key];
    const db = key.split('__')[0];
    orig.forEach((obp) => {
      const bp = obp.bp;
      // Find Gen Tel
      if (bp.gen_tel) {
        const gen_tel_matches = bpList.filter(b => {
          if (b.DB === db) {
            if (b.gen_tel && (b.name === 'TPP' || b.name === 'GMP')) {
              if (b.gen_tel === bp.gen_tel) {
                console.log('found gentel', b.gen_tel, bp.gen_tel);
                return true;
              }
            }
          }
        }).map(b => {
          return {
            id: b.id,
            bp: b,
            match: 'Gen. Tel.'
          }
        });
        if (gen_tel_matches.length > 0) {
          bpObj2[key] = bpObj2[key].concat(gen_tel_matches);
        }
       }
      // Find Billing Name
      if (bp.billing_name) {
        const billing_name_matches = bpList.filter(b => {
          if (b.DB === db) {
            if (b.billing_name && (b.name === 'TPP' || b.name === 'GMP')) {
              const possibles = getCombinations(bp.billing_name);
              let pass = false;
              if (b.billing_name === bp.billing_name) {
                console.log('found same billing name', b.billing_name, bp.billing_name);
                pass = true;
              }
              if (!pass) {
                // get possible 5 characters from bp
                for (let j = 0; j < possibles.length; j++) {
                  const possible = possibles[j];
                  if (b.billing_name.indexOf(possible) !== -1) {
                    console.log('found same billing name', possible, b.billing_name);
                    pass = true;
                    break;
                  }
                }
              }
              if (pass) return true;
            }
          }
        }).map(b => {
          return {
            id: b.id,
            bp: b,
            match: 'Billing name'
          }
        });
        if (billing_name_matches.length > 0) {
          bpObj2[key] = bpObj2[key].concat(billing_name_matches);
        }
       }

       // Find Billing Address1
       if (bp.billing_address1) {
         const billing_address1_matches = bpList.filter(b => {
           if (b.DB === db) {
             if (b.billing_address1 && (b.name === 'TPP' || b.name === 'GMP')) {
               const possibles = getCombinations(bp.billing_address1);
               let pass = false;
               if (b.billing_address1 === bp.billing_address1) {
                 console.log('found same billing address', b.billing_address1, bp.billing_address1);
                 pass = true;
               }
               if (!pass) {
                 // get possible 5 characters from bp
                 for (let j = 0; j < possibles.length; j++) {
                   const possible = possibles[j];
                   if (b.billing_address1.indexOf(possible) !== -1) {
                     pass = true;
                     break;
                   }
                 }
               }
               if (pass) return true;
             }
           }
         }).map(b => {
           return {
             id: b.id,
             bp: b,
             match: 'Billing Address 1'
           }
         });
         if (billing_address1_matches.length > 0) {
           bpObj2[key] = bpObj2[key].concat(billing_address1_matches);
         }
        }
    });
  });

  // filter duplicates
  Object.keys(bpObj2).forEach((bo2) => {
    const o = bpObj2[bo2];
    if (o.length > 1) {
      let newarr = [];
      let ids = [];
      o.forEach((oo) => {
        if (ids.indexOf(oo.id) === -1) {
          ids.push(oo.id);
          newarr.push(oo);
        }
      })
      bpObj2[bo2] = newarr;
    }
  })

  // exact matches
  Object.keys(bpObj2).forEach((key) => {
    if (bpObj2[key].length > 1) {
      const first = bpObj2[key][0];
      first.bp.group_id = key;
      // group them and create a single Group entry
      bps.push(first.bp);
    } else {
      bps.push(bpObj2[key][0].bp);
    }
  });

  console.log('GMP filter object 1', bpObj);
  console.log('GMP filter object 2', bpObj2);

  return { bps_filtered: bps, bp_groups: bpObj2 };
}

class BPPendingList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedBP: null,
      showModal: false,
      showGMPExistingModal: false,
      showGMPExistingForm: false,
      loadingBP: false,
      currentDB: '',
      currentBPType: '',
      load: false,
      newGMPModal: false,
      loadingModal: false,
      lockedMsgModal: false,
      lockedMsg: '',
      showRegisteredUser: null,
      submitter: null,
      groupModal: false,
      groupData: null,
      groupKey: null,
      singleData: null,
      singleModal: false,
      previewBP: false,
      permsData: {
        allowedDBs: [],
        allowedTPPDBs: [],
        directToSAP: null,
        directToSAPTPP: null
      },
      bp_groups: [],
      bps_filtered: []
    }
  }

  fetchChosenExistingBP = (bpname, db, cardcode) => {
    this.props.fetchSAPBP(bpname, db, cardcode);
  }

  closeShowGMPExistingModal = () => {
    this.props.onFetchGMP();
    this.setState({
      showGMPExistingModal: false,
      showGMPExistingForm: false
    });
    ReactDOM.findDOMNode(this).scrollIntoView();
  }

  toggleNewGMPModal = () => {
    this.setState({
      newGMPModal: !this.state.newGMPmodal
    });
  }

  cancelProfileView = (e) => {
    console.log('exiting and cancelling profile view');
    ReactDOM.findDOMNode(this).scrollIntoView();
    this.props.onFetchGMP();
    this.props.setBPdata(null);
    this.props.setLoadingGMPBP(false);
    this.props.releaseUserLockedBPs();
    this.setState({
      showModal:false,
      showGMPExistingForm: false,
      showGMPExistingModal: false,
      showRegisteredUser: false,
      submitter: null
    });
  }

  cancelGMPExistingProfileView = () => {
    this.props.releaseUserLockedBPs();
    ReactDOM.findDOMNode(this).scrollIntoView();
    this.props.onFetchGMP();
    this.setState({ showGMPExistingModal:false, submitter: null });
  }

  startLoadingBP = (bp, isGMPExisting, isRegisteredUser) => {
    if (!isGMPExisting && !isRegisteredUser) {
      this.props.fetchLocalBP(bp.id, null);
      this.props.fetchAddInfoList(bp.DB);
      if (bp.DB.indexOf('NIN') !== -1) {
        this.props.fetchGSTType();
      }
      setTimeout(() => {
        this.setState({
          selectedBP: bp,
          showModal: true,
          showRegisteredUser: null,
          loadingBP: false
        })
      }, 100);
    } else if (isGMPExisting && !isRegisteredUser) {
      this.props.fetchLocalBP(bp.id, 'gmpExisting');
      this.props.fetchAddInfoList(bp.DB);
      if (bp.DB.indexOf('NIN') !== -1) {
        this.props.fetchGSTType();
      }
      setTimeout(() => {
        this.setState({
          selectedBP: bp,
          showRegisteredUser: null,
          loadingBP: false
        })
      }, 100);
    } else if (isRegisteredUser) {
      console.log('time to fetch the bps');
      this.props.fetchLocalBP(bp.id);
      this.fetchChosenExistingBP(bp.company_name, bp.DB, bp.card_code);
      this.props.fetchAddInfoList(bp.DB);
      if (bp.DB.indexOf('NIN') !== -1) {
        this.props.fetchGSTType();
      }
      setTimeout(() => {
        this.setState({
          selectedBP: bp,
          showRegisteredUser: bp.DB,
          loadingBP: false
        })
      }, 100);
    }
  }

  loadExistingGMPBP = (bpname, db) => {
    this.handleDBChange(db);
    this.props.loadBPForReview(bpname, db);
    this.setState({
      showGMPExistingModal: false,
      showGMPExistingForm: true
    })
  }

  handleDBChange = (db) => {
    console.log('fetching info from ', db);
    this.setState({
      currentDB: db,
      load: false
    });
    setTimeout(() => {
        this.setState({
          load : true
        })
      },100);
    this.props.fetchAddInfoList(db);
    if (db.indexOf('NIN') !== -1) {
      this.props.fetchGSTType();
    }
  }

  handleDenyNewGMP = (values) => {
    console.log('DENYING.... ->', values);
    if (values.id) {
      this.props.denyGMPBP(values);
    }
  }

  handleDenyExistingGMP = (values) => {
    if (values.id) {
      this.props.denyGMPBP(values);
    }
  }

  handleDenySAPBP = (values) => {
    if (values.id) {
      this.props.denySAPBP(values);
    }
  }

  handleApprove = (finalValues, file, filename, isTPP) => {
    const submittedDB = finalValues.DB;
    const actionPerms = checkPermissionsActions('BP');
    let directToSAP = false;
    if (actionPerms.hasOwnProperty('approve')) {
      actionPerms.approve.forEach((db) => {
        if (db === submittedDB) {
          directToSAP = true;
        }
      });
    }

    this.setState({
      currentDB: '',
      currentBPType: '',
      loadingModal: true
    });
    if (finalValues.card_code) {
      finalValues.submission_type = 'existing';
    }
    finalValues.origin = 'pending';
    console.log('SAP-->' ,finalValues, file, filename);
    if (directToSAP) {
      console.log('submitting directly to SAP', finalValues, file, filename);
      this.props.saveSAPBP(finalValues, file, filename, isTPP);
    } else {
      console.log('submitting locally', finalValues);
      this.props.saveLocalBP(finalValues, file, filename);
    }
  }

  closeAllChildComponents = () => {
    this.setState({
      selectedBP: null,
      showModal: false,
      showGMPExistingModal: false,
      showGMPExistingForm: false,
      loadingBP: false,
      currentDB: '',
      currentBPType: '',
      load: false,
      newGMPModal: false,
      loadingModal: false,
      permsData: {
        allowedDBs: [],
        allowedTPPDBs: [],
        directToSAP: [],
        directToSAPTPP: []
      },
      bp_groups: [],
      bps_filtered: []
    });
  }

  toggleLoadingModal = () => {
    this.setState({
      loadingModal: !this.state.loadingModal
    });
  }

  openLockedMsg = (lockedMsg) => {
    console.log('lockedMsg',this.state.showRegisteredUser,this.state.showModal,this.state.showGMPExistingModal,this.state.showGMPExistingForm);
    this.setState({lockedMsgModal:true, lockedMsg, loadingBP: false, showModal:false });
  }

  closeLockedMsg = () => {
    console.log('pendingBPs close');
    const obj = {lockedMsgModal:false, lockedMsg: ''};
    this.setState(obj);
  }

  openGroupModal = (groupData, groupKey) => {
    this.setState({
      groupModal: true,
      groupData,
      groupKey
    });
  }

  closeGroupModal = () => {
    this.setState({
      groupModal: false,
      groupData: null
    });
  }

  openSingleModal = (bp) => {
    this.setState({
      singleModal: true,
      singleData: [bp]
    });
  }

  closeSingleModal = () => {
    this.setState({
      singleModal: false,
      singleData: []
    })
  }

  addToGroup = (groupkey, bp) => {
    // add to group
    const bp_groups = JSON.parse(JSON.stringify(this.state.bp_groups));
    bp_groups[groupkey] = [ ...bp_groups[groupkey], { id:bp.id, bp } ];
    // remove from main list
    const bps_filtered = this.state.bps_filtered.filter(f => bp.id !== f.id);
    this.setState({
      bp_groups,
      bps_filtered
    })
  }

  createGroup = (second_bp, first_bp) => {
    const bp_groups = JSON.parse(JSON.stringify(this.state.bp_groups));
    const bpname = first_bp.company_name ? first_bp.company_name : first_bp.customer_name;
    const db = first_bp.DB;
    bp_groups[`${db}__${bpname}`] = [{ id:first_bp.id, bp:first_bp } , { id:second_bp.id, bp:second_bp } ];
    const bps_filtered = JSON.parse(JSON.stringify(this.state.bps_filtered)).map(m => {
      if (m.id === first_bp.id) {
        const bpname = first_bp.company_name ? first_bp.company_name : first_bp.customer_name;
        m.group_id = `${first_bp.DB}__${bpname}`;
        return m;
      } else {
        return m;
      }
    });
    this.setState({
      bp_groups,
      bps_filtered
    });
  }

  openBP = (bp) => {
    const bpname = bp.company_name ? bp.company_name : bp.customer_name;
    this.props.lockBP(bp.DB, bp.card_code, bpname);
    this.setState({
      loadingBP: true,
      currentDB: bp.DB,
      submitter: bp.name === 'GMP' ? 'TPP' : bp.name
    });
    const isGMPExisting = (bp.customer_num && bp.invoice_num) ? true : false;
    const isRegisteredUser = (bp.card_code && !bp.currency)  ? true : false;
    this.startLoadingBP(bp, isGMPExisting, isRegisteredUser);
  }

  removeFromGroupModal = (id, key) => {
    const before_copy = JSON.parse(JSON.stringify(this.state.bp_groups[key]));
    const bp_groups_copy = JSON.parse(JSON.stringify(this.state.bp_groups));
    const bps_filtered_copy = JSON.parse(JSON.stringify(this.state.bps_filtered));

    console.log('removing', id, 'from', this.state.bp_groups[key]);
    if (!bp_groups_copy.hasOwnProperty(key)) {
      // make sure bps_filtered doesnt have group on the id.
      const bps_filtered_copy = bps_filtered_copy.map(m => {
        if (m.id === id) {
          m.group_id = null;
        }
        return m;
      });

      return;
    }

    let bp_group_copy;
    bp_groups_copy[key] = before_copy.filter(m => {
      if (m.id !== id) return true;
      if (m.id === id) bp_group_copy = JSON.parse(JSON.stringify(m));
    });
    let found = false;
    for (let i = 0; i < bps_filtered_copy.length; i++) {
      if (!bps_filtered_copy[i].group_id) {
        if (bps_filtered_copy[i].id === id) {
          found = true;
          break;
        }
      }
    }
    if (bp_groups_copy[key].length === 1) {
      // remove group_id;
      delete bp_groups_copy[key];

    }
    const obj = {
      groupData: before_copy.filter((gd) => {
        if (gd.id !== id) return true;
      }),
      bp_groups: bp_groups_copy
    }
    if (!found) {
      // if not found add BP to singles list
      obj.bps_filtered = bps_filtered_copy.concat([bp_group_copy.bp]);
    }
    // if found, check if is remove group status
    const exact_matches = before_copy.filter(m => m.match ? false : true).map(m => m.id);
    console.log('exact_matches', exact_matches, id);
    if (exact_matches.length === 1) {
      if (exact_matches[0] === id) {
        obj.bps_filtered = bps_filtered_copy.map(m => {
          if (m.id === id) {
            m.group_id = null;
          }
          return m;
        });
        this.closeGroupModal();
      }
    }
    this.setState(obj);
  }

  openLocalBPreview = () => {
    this.setState({ previewBP: true });
  }

  closeLocalBPreview = () => {
    this.setState({ previewBP: false });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.lockedMsg != this.props.lockedMsg) {
      if (this.props.lockedMsg) {
        this.openLockedMsg(this.props.lockedMsg);
      }
    }
    if (prevProps.origin != this.props.origin) {
      if (this.props.origin === 'gmpExisting') {
        this.setState({showGMPExistingModal: true,});
      }
    }

    if (prevProps.chosenPrimary != this.props.chosenPrimary) {
      if (this.props.chosenPrimary) {
        this.openBP(this.props.chosenPrimary);
      }
    }

    if (prevProps.gmps != this.props.gmps) {
      const { allowedDBs, allowedTPPDBs } = this.state.permsData;
      const gmps_filtered1 = this.props.gmps ? this.props.gmps.filter(bp => {
        // only show allowed DB BPs
        if (allowedDBs.indexOf(bp.DB) !== -1 && bp.submitter_id !== 4) {
          return true;
        }
        if (allowedTPPDBs.indexOf(bp.DB) !== -1 && bp.submitter_id === 4) {
          return true;
        }
        return false;
      }) : [];

      const { bps_filtered, bp_groups } = filterProcess(gmps_filtered1);
      this.setState({
        bp_groups,
        bps_filtered
      });
    }
  }

  componentDidMount() {
    this.props.reportCurrentDB('NSP_TEST'); // just to trigger alert notification
    if (ReactDOM.findDOMNode(this)) {
      ReactDOM.findDOMNode(this).scrollIntoView();
    }
    this.props.onFetchGMP();
    this.props.fetchDBList();
    this.props.fetchGSTType();
    this.props.releaseUserLockedBPs();
    console.log('releasing user locked BPs');
    let params = qs(this.props.location.search);
    console.log(params.id, params.db, params.existing !== 'yes');
    console.log(params.id, params.db, params.existing === 'yes');
    if (params.id && (params.db && params.existing !== 'yes')) {
      this.startLoadingBP({
        id: params.id,
        DB: params.db
      });
    } else if (params.id && (params.db && params.existing === 'yes')) {
      this.startLoadingBP({
        id: params.id,
        DB: params.db
      }, true);
    }

    // Permissions Data
    let allowedDBs = [];
    let allowedTPPDBs = [];
    const actionPerms = checkPermissionsActions('BP');
    if (actionPerms.hasOwnProperty('approve')) {
      actionPerms.approve.forEach((db) => {
        if (allowedDBs.indexOf(db) === -1) {
          allowedDBs.push(db);
        }
      });
    }
    allowedDBs.sort();

    const tppActionPerms = checkPermissionsActions('GMP');
    if (tppActionPerms.hasOwnProperty('approve')) {
      tppActionPerms.approve.forEach((db) => {
        if (allowedTPPDBs.indexOf(db) === -1) {
          allowedTPPDBs.push(db);
        }
      });
    }

    let directToSAP = [];
    if (actionPerms.hasOwnProperty('approve')) {
      actionPerms.approve.forEach((db) => {
        directToSAP.push(db);
      });
    }

    let directToSAPTPP = [];
    if (tppActionPerms.hasOwnProperty('approve')) {
      tppActionPerms.approve.forEach((db) => {
        directToSAPTPP.push(db);
      });
    }

    this.setState({
      permsData: {
        allowedDBs, allowedTPPDBs, directToSAP, directToSAPTPP
      }
    });
  }

  render() {
    const actionPerms = checkPermissionsActions('BP');
    console.log('pendingBPs', this.state, this.props);
    const { allowedDBs, allowedTPPDBs, directToSAP, directToSAPTPP } = this.state.permsData;
    const bps_filtered = this.state.bps_filtered;
    const bp_groups = this.state.bp_groups;

    if (directToSAP === null && directToSAPTPP === null) return <div></div>;

    if (directToSAP.length === 0 && directToSAPTPP.length === 0) {
      return <Redirect to="/dashboard" />;
    }

    let enterPayTerms = false;
    if (actionPerms.hasOwnProperty('enter-accounting')) {
      enterPayTerms = true;
    }

    console.log('loading status',this.state.loadingBP, this.props.loading, this.state.showModal);
    const { loading } = this.props;
    const override = {
        display: 'block',
        margin: '0 auto',
        borderColor: 'red'
    };
    let layout = <div style={{textAlign:'center',margin:'0 auto'}} className="centered-loader">
    <FadeLoader
        style={override}
        sizeUnit={"px"}
        size={150}
        color={'#123abc'}
        loading={true}
    /></div>

    if (!loading && !this.state.loadingBP && !this.props.loadingLocalBP) {
      console.log('+++++++++++++++++++++', bps_filtered);
      let dbList = [];
      const table = <div className="table-adjust2"><ReactTable
          data={
              bps_filtered.map((bp, key) => { // map users to table rows
                  if (dbList.indexOf(bp.DB) == -1) {
                    dbList.push(bp.DB);
                  }
                  let bpName = bp.company_name ? bp.company_name.toUpperCase() : 'n/a';
                  if (bp.customer_name) {
                    bpName = bp.customer_name ? bp.customer_name.toUpperCase() : 'n/a';
                  }
                  let type = (bp.customer_name || bp.card_code) ? 'Existing' : 'New';
                  type = bp.group_id ? `Group` : type;
                  const date_added = moment(bp.date_modified).format("MM/DD/YYYY hh:mm a");
                  const getTime = new Date(bp.date_modified).getTime();
                  return ({
                      id: key,
                      bp_id: bp.id,
                      bp_name: bpName,
                      bp_type: bp.bp_type ? bp.bp_type : 'Customer',
                      // bp_code: bp.customer_num ? bp.customer_num : 'n/a',
                      db_name: bp.DB,
                      date_added,
                      timestamp: formatTime(bp.date_added),
                      type,
                      submitter: bp.name === 'GMP' ? 'TPP' : bp.name,
                      customer_num: bp.customer_num,
                      invoice_num: bp.invoice_num,
                      currency: bp.currency,
                      customer_name: bp.customer_name,
                      original_bp: bp,
                      group_id: bp.group_id ? bp.group_id : null,
                      actions: (
                          <div className="actions-right">
                              <Button
                                  justIcon
                                  round
                                  simple
                                  onClick={() => {
                                    ReactDOM.findDOMNode(this).scrollIntoView();
                                  }}
                                  color="warning"
                              >
                                  <Dvr />
                              </Button>
                          </div>
                      )
                  })
              })
          }
          filterable
          getTrProps={(state, rowInfo) => {
            if (rowInfo && rowInfo.row) {
              return {
                onClick: (e) => {
                  // this.props.resetGMPBP();
                  // this.props.resetCommon();
                  const bp = rowInfo.original;
                  const bp_data = bp.original_bp;
                  console.log('bp and rowInfo', bp, rowInfo);
                  if (bp.group_id) {
                    this.openGroupModal(bp_groups[bp.group_id], bp.group_id);
                    return;
                  } else {
                    if (bp.submitter === 'TPP') {
                      this.openSingleModal(bp_data);
                      return;
                    }
                  }

                  // if (bp.type !== 'EXISTING' && bp.submitter !== 'TPP') {
                    this.props.lockBP(bp.db_name, bp_data.card_code, bp.bp_name);
                  // }
                  this.setState({
                    loadingBP: true,
                    currentDB: bp.db_name,
                    submitter: bp.submitter
                  });
                  const isGMPExisting = (bp.customer_num && bp.invoice_num) ? true : false;
                  const isRegisteredUser = (bp.original_bp.card_code && !bp.currency)  ? true : false;
                  console.log('clicked a row', bp, bp.original_bp, isGMPExisting, isRegisteredUser);
                  this.startLoadingBP(bp.original_bp, isGMPExisting, isRegisteredUser);
                }
              }
            } else {
              return {}
            }
          }}
          columns={[
              {
                  Header: "ID",
                  accessor: "bp_id",
              },
              {
                  Header: "Type",
                  accessor: "type",
                  filterMethod: (filter, row) => {
                      const chosen = filter.value;
                      if (chosen.length > 0) {
                          return chosen.indexOf(row[filter.id]) !== -1;
                      } else {
                          return true;
                      }
                  },
                  Filter: ({filter, onChange}) => {
                      return <div className="gmp-filter"><GMPMultipleSelect
                          options={['New','Existing','Group']}
                          label={''}
                          choose={onChange}
                      /></div>
                  },
                  maxWidth: 100
              },
              {
                  Header: "BP Type",
                  accessor: "bp_type",
                  filterMethod: (filter, row) => {
                      const chosen = filter.value;
                      if (chosen.length > 0) {
                          return chosen.indexOf(row[filter.id]) !== -1;
                      } else {
                          return true;
                      }
                  },
                  Filter: ({filter, onChange}) => {
                      return <div className="gmp-filter"><GMPMultipleSelect
                          options={['Customer','Supplier']}
                          label={''}
                          choose={onChange}
                      /></div>
                  },
                  maxWidth: 100
              },
              {
                  Header: "BP Name",
                  accessor: "bp_name",
                  filterMethod: (filter, row) => {
                    var filterValue = filter.value.toLowerCase();
                    if (filterValue.length > 0) {
                        return row[filter.id].toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;
                    } else {
                        return true;
                    }
                  },
                  minWidth: 150
              },
              {
                  Header: "DB Name",
                  accessor: "db_name",
                  filterMethod: (filter, row) => {
                      const chosen = filter.value;
                      if (chosen.length > 0) {
                          return chosen.indexOf(row[filter.id]) !== -1;
                      } else {
                          return true;
                      }
                  },
                  Filter: ({filter, onChange}) => {
                      return <div className="gmp-filter"><GMPMultipleSelect
                          options={dbList}
                          label={''}
                          choose={onChange}
                      /></div>
                  }
              },
              {
                  Header: "Date Added",
                  accessor: "date_added",
                  sortMethod: (a, b) => {
                    a = new Date(a).getTime();
                    b = new Date(b).getTime();
                       return b > a ? 1 : -1;
                  },
                  filterMethod: (filter, row) => {
                      const dateFilter = filter.value;
                      if (dateFilter && row.date_added) {
                        if (typeof dateFilter !== 'string') {
                          console.log('date filter', dateFilter, row.date_added)
                          if (row.date_added.indexOf(dateFilter.format("MM/DD/YYYY")) !== -1) {
                            return true;
                          }
                        }
                      }
                  },
                  Filter: ({filter, onChange}) => {
                    return (
                    <div style={{width:'100%'}}>
                      <Datetime
                        closeOnSelect={true}
                        onChange={onChange}
                        timeFormat={false}
                      />
                    </div>);
                  },
                  minWidth: 150
              },
              {
                  Header: "Submitter",
                  accessor: "submitter",
                  filterMethod: (filter, row) => {
                    var filterValue = filter.value.toLowerCase();
                    if (filterValue.length > 0) {
                        return row[filter.id].toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;
                    } else {
                        return true;
                    }
                  }
              },
          ]}
          defaultSorted={[
            {
              id: "date_added",
              desc: false
            }
          ]}
          minRows={0}
          defaultPageSize={10}
          showPaginationTop
          showPaginationBottom={false}
          className="-striped -highlight"
      /></div>
      layout = (
<div>
  {(!this.state.showRegisteredUser && !this.state.showModal && !this.state.showGMPExistingModal && !this.state.showGMPExistingForm) &&
    <Card>
      <CardHeader color="rose" icon>
        <CardIcon color="rose">
          <div className="pointed">
            <Cached onClick={() => {
              this.props.onFetchGMP();
            }} />
          </div>
        </CardIcon>
      </CardHeader>
      <CardBody>
        <GridContainer justify="center" style={{
          margin: 0,
          paddingBottom: '40px',
          width: '100%',
        }}>
            <GridItem xs={12} sm={12} md={10}>
                {table}
            </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  }
  {
    (this.props.dbs.length > 0 && (this.state.showGMPExistingModal && this.props.localBP)) &&
    <BPExistingGMP
      bp={this.props.localBP}
      loadExistingGMPBP={this.loadExistingGMPBP}
      cancelProfileView={this.cancelGMPExistingProfileView}
      directToSAP={directToSAP}
      allowedDBs={allowedDBs}
      enterPayTerms={enterPayTerms}
      handleDenyExistingGMP={this.handleDenyExistingGMP}
      closeShowGMPExistingModal={this.closeShowGMPExistingModal}
    />
  }
  {
    (this.props.dbs.length > 0 && (this.state.showGMPExistingForm && this.props.localBP)) &&
    <BPForm
      formType={1}
      data={this.props.sapBP}
      localData={this.props.localBP}
      handleDBChange={this.handleDBChange}
      handleBPTypeChange={this.handleBPTypeChange}
      disabledDB={true}
      disabledBPType={true}
      allowedDBs={allowedDBs}
      currentDB={this.state.currentDB}
      currentBPtype={this.state.currentBPType}
      enterPayTerms={enterPayTerms}
      BPaction="EXISTING"
      submissionType="existing"
      handleApprove={this.handleApprove}
      handleDenyExistingGMP={this.handleDenyExistingGMP}
      directToSAP={directToSAP}
      loadExistingGMPBP={this.loadExistingGMPBP}
      cancelProfileView={this.cancelGMPExistingProfileView}
      isFromPending={true}
      submitterName={this.state.submitter}
    />
  }
  {
    (this.props.dbs.length > 0 && (this.state.showModal && (this.props.localBP && !this.state.showRegisteredUser))) &&
    <BPForm
      formType={2}
      onSubmit={this.onSubmit}
      handleDBChange={this.handleDBChange}
      allowedDBs={allowedDBs}
      currentDB={this.props.localBP ? this.props.localBP.DB : null}
      currentBPType={this.props.localBP ? this.props.localBP.bp_type : ''}
      disableDB={true}
      disableBPType={true}
      enterPayTerms={enterPayTerms}
      data={this.props.localBP}
      BPaction="PENDING"
      submissionType="new"
      handleApprove={this.handleApprove}
      handleDenyNewGMP={this.handleDenyNewGMP}
      directToSAP={directToSAP}
      loadBPForReview={this.props.loadBPForReview}
      cancelProfileView={this.cancelProfileView}
      toggleNewGMPModal={this.toggleNewGMPModal}
      isFromPending={true}
      submitterName={this.state.submitter}
    />
  }
  {
    (this.props.dbs.length > 0 && (this.state.showRegisteredUser && (this.props.sapBP && (this.props.localBP)))) &&
    <BPForm
      formType={3}
      formID={this.state.selectedBP ? this.state.selectedBP.id : ''}
      isRegisteredUser={true}
      onSubmit={this.onSubmit}
      handleDBChange={this.handleDBChange}
      allowedDBs={allowedDBs}
      currentDB={this.state.showRegisteredUser}
      currentBPType={this.props.sapBP ? this.props.sapBP.bp_type : ''}
      disableDB={true}
      disableBPType={true}
      enterPayTerms={enterPayTerms}
      data={this.props.sapBP}
      localBP={this.props.localBP}
      BPaction="PENDING"
      submissionType="new"
      handleApprove={this.handleApprove}
      handleDenyNewGMP={this.handleDenyNewGMP}
      directToSAP={directToSAP}
      loadBPForReview={this.props.loadBPForReview}
      cancelProfileView={this.cancelProfileView}
      toggleNewGMPModal={this.toggleNewGMPModal}
      isFromPending={true}
      submitterName={this.state.submitter}
    />
  }
  {
    this.state.newGMPModal &&
    <SweetAlert
        title={"New GMP BP"}
        style={{ display: "block" }}
        onConfirm={() => {
          this.toggleNewGMPModal();
        }}
        confirmBtnCssClass={
          this.props.classes.button + " " + this.props.classes.success
        }
        >
        <div style={{fontSize:'14px'}}>
          {this.props.loadingReview && <div>Denying, Please Wait<PulseLoader /></div>}
          {!this.props.loadingReview && <div>Deny Submitted</div>}
        </div>
      </SweetAlert>
  }
  {
    this.state.loadingModal &&
    <SweetAlert
        title={"BP Submission"}
        style={{ display: "block" }}
        onConfirm={() => {
          ReactDOM.findDOMNode(this).scrollIntoView();
          if (this.props.loadingSAPBPMsg === 'Submitted BP.') {
            this.closeAllChildComponents();
            this.props.onFetchGMP();
          } else {
            this.toggleLoadingModal();
          }
          this.cancelProfileView();
          ReactDOM.findDOMNode(this).scrollIntoView();
        }}
        confirmBtnCssClass={
          this.props.classes.button + " " + this.props.classes.success
        }
        showConfirm={!this.props.loadingSAPBPMsg ? false : true}
        >
        <div style={{fontSize:'14px'}}>
          {!this.props.loadingSAPBPMsg && <div className="min-box">Submitting, Please Wait<PulseLoader /></div>}
          {this.props.loadingSAPBPMsg}
        </div>
      </SweetAlert>
  }
  {(this.state.loading || this.props.loadingLocalBP) &&
    <Card>
      <CardBody>
      <GridContainer justify="center" style={{
        margin: 0,
        paddingBottom: '40px',
        width: '100%',
      }}>
          <GridItem xs={12} sm={12} md={10}>
              <div>
                <div style={{textAlign:'center',marginTop:20}}>Loading<PulseLoader /></div>
              </div>
          </GridItem>
      </GridContainer>
      </CardBody>
      </Card>
  }
  {
    this.state.lockedMsgModal &&
    <SweetAlert
        title="Locked BP"
        style={{ display: "block" }}
        onConfirm={() => {
          this.closeLockedMsg();
        }}
        confirmBtnCssClass={
          this.props.classes.button + " " + this.props.classes.success
        }
        >
        <div style={{fontSize:'14px',color:'red'}}>
          {this.state.lockedMsg}
        </div>
      </SweetAlert>
  }
  {
    this.state.groupModal &&
      <BPGroupModal
        open={this.state.groupModal}
        close={this.closeGroupModal}
        data={this.state.groupData}
        groupKey={this.state.groupKey}
        removeFromGroupModal={this.removeFromGroupModal}
        localBPreview={this.props.localBPreview}
        fetchAddInfoList={this.props.fetchAddInfoList}
        fetchGSTType={this.props.fetchGSTType}
        fetchLocalBP={this.props.fetchLocalBP}
        closeReviewModal={this.props.closeReviewModal}
        previewBP={this.state.previewBP}
        sapBPreview={this.props.sapBPreview}
        statesDictionary={this.props.statesDictionary}
        countriesDictionary={this.props.countriesDictionary}
        gsttypesDictionary={this.props.gsttypesDictionary}
        owhtopts={this.props.owhtopts}
        owhtoptsDictionary={this.props.owhtoptsDictionary}
        groupcodesDictionary={this.props.groupcodesDictionary}
        choosePrimary={this.props.choosePrimary}
      />
  }
  {
    this.state.singleModal &&
      <BPSingleModal
        open={this.state.singleModal}
        close={this.closeSingleModal}
        data={this.state.singleData}
        groups={this.state.bp_groups}
        singles={this.state.bps_filtered}
        addToGroup={this.addToGroup}
        localBPreview={this.props.localBPreview}
        fetchAddInfoList={this.props.fetchAddInfoList}
        fetchGSTType={this.props.fetchGSTType}
        fetchLocalBP={this.props.fetchLocalBP}
        closeReviewModal={this.props.closeReviewModal}
        previewBP={this.state.previewBP}
        sapBPreview={this.props.sapBPreview}
        statesDictionary={this.props.statesDictionary}
        countriesDictionary={this.props.countriesDictionary}
        gsttypesDictionary={this.props.gsttypesDictionary}
        owhtopts={this.props.owhtopts}
        owhtoptsDictionary={this.props.owhtoptsDictionary}
        groupcodesDictionary={this.props.groupcodesDictionary}
        openBP={this.openBP}
        createGroup={this.createGroup}
      />
  }
</div>
  );
} else if (!loading && (this.state.loadingBP || this.props.loadingLocalBP || this.props.loadingBP)) {
      layout = (
    <Card>
      <CardBody>
      <GridContainer justify="center" style={{
        margin: 0,
        paddingBottom: '40px',
        width: '100%',
      }}>
          <GridItem xs={12} sm={12} md={10}>
              <div>
                <div style={{textAlign:'center',marginTop:20}}>Loading<PulseLoader /></div>
              </div>
          </GridItem>
      </GridContainer>
      </CardBody>
      </Card>
    );
    }
    return layout;
  }
}

const mapStateToProps = (state) => {
  return {
    dbs: state.common.dbs,
    gmps: state.gmp.gmps,
    loading: state.gmp.loading,
    loadingLocalBP: state.common.loadingLocalBP,
    loadingBP: state.common.loadingBP,
    localBP: state.common.localBP,
    localBPreview: state.common.localBPreview,
    sapBP: state.common.sapBP,
    sapBPreview: state.common.sapBPreview,
    loadingReview: state.gmp.loadingReview,
    loadingSAPBP: state.common.loadingSAPBP,
    loadingSAPBPMsg: state.common.loadingSAPBPMsg,
    lockedMsg: state.common.lockedMsg,
    origin: state.common.origin,

    statesDictionary: state.common.statesDictionary,
    countriesDictionary: state.common.countriesDictionary,
    gsttypesDictionary: state.gmp.gsttypesDictionary,
    groupcodesDictionary: state.common.groupcodesDictionary,
    owhtopts: state.common.addinfo ?
                state.common.addinfo.owhtopts ? state.common.addinfo.owhtopts : null
                : null,
    owhtoptsDictionary: state.common.owhtoptsDictionary,
    chosenPrimary: state.common.chosenPrimary
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    sendResaleCertFile: (file, filename) => {
      dispatch(actions.sendResaleCertFile(file, filename));
    },
    onFetchGMP: () => dispatch(actions.fetchGMP()),
    fetchDBList: () => {
      dispatch(actions.fetchDBList());
    },
    fetchAddInfoList: (db) => {
      dispatch(actions.fetchAddInfoList(db));
    },
    fetchGSTType: () => {
      dispatch(actions.fetchGSTType());
    },
    loadBPForReview: (bpName, db, cardcode, bp_type, reviewOnly) => {
      dispatch(actions.fetchBPSAP(db, bpName, cardcode, 'pending', bp_type, reviewOnly));
    },
    saveLocalBP: (data, file, filename) => {
      dispatch(actions.saveLocalBP(data, file, filename));
    },
    fetchLocalBP: (submission_id, origin) => {
      dispatch(actions.fetchLocalBP(submission_id, origin));
    },
    closeReviewModal: () => {
      dispatch(actions.closeReviewModal());
    },
    saveSAPBP: (values, file, filename, isTPP) => {
      dispatch(actions.saveSAPBP(values, file, filename, isTPP));
    },
    denyGMPBP: (values) => {
      console.log('denying gmp bp', values);
      dispatch(actions.denyGMPBP(values));
    },
    denyGMPBPExisting: (values) => {
      dispatch(actions.denyGMPBPExisting(values));
    },
    fetchSAPBP: (bpName, db, cardcode) => {
      console.log('fetching cardcode', bpName, db, cardcode);
      dispatch(actions.fetchBPSAP(db, bpName, cardcode, 'pending', 'Customer'));
    },
    setBPdata: (obj) => {
      dispatch(actions.setBPdata(obj));
    },
    setLoadingGMPBP: (bool) => {
      dispatch(actions.setLoadingGMPBP(bool));
    },
    resetGMPBP: () => {
      dispatch(actions.resetGMPBP());
    },
    resetCommon: () => {
      dispatch(actions.resetCommon());
    },
    lockBP: (db, card_code, bpname) => {
      dispatch(actions.updateBPLock(db, card_code, bpname, 'LOCK'));
    },
    releaseUserLockedBPs: () => {
      dispatch(actions.releaseUserLockedBPs());
    },
    choosePrimary: (data) => {
      //console.log('chosen primary', data);
      dispatch(actions.choosePrimary(data));
    }
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(sweetAlertStyle)(BPPendingList));
