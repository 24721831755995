import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import SweetAlert from "react-bootstrap-sweetalert";

const GINalert = props => {
  return (
    <SweetAlert
      style={{ display: "block", marginTop: "-100px" }}
      title={props.title}
      onConfirm={() => {
        props.hide()
        if (props.command) {
          props.command();
        }
      }}
      onCancel={() => props.hide()}
      confirmBtnCssClass={
        props.classes.button + " " + props.classes.success
      }
    >{props.content}</SweetAlert>
  );
}

export default withStyles(sweetAlertStyle)(GINalert);
