import React from "react";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import Datetime from "react-datetime";
import moment from "moment";

const Element = (props) => {
	const { label, type, value, onChange, options, indx, desc, onClick } = props;
	if (type === 'select') {
		return <div>
			<CustomSelect
				options={options}
				value={value}
				choose={onChange}
				label={label}
				indx={indx}
				desc={desc}
				default={value}
			/>
		</div>
	} else if (type === 'input') {
		return <CustomInput 
			labelText={label}
			formControlProps={{
				fullWidth: true
			  }}
			inputProps={{
				value,
				onChange,
				onClick
			}}
			autoFocus={true}
		/>
	} else if (type === 'date') {
		const date_is_valid = moment(value, 'MM/DD/YYYY', true).isValid();
        const date = <div className={`mt-25 custom relative`}>
            {value && <div className="tiny-label">{label}</div>}
            <div className="hoverable gray-text date-clear">
                {value && <small onClick={() => {
                    onChange('');
                }}>clear</small>}
            </div>
            <Datetime
                inputProps={{
                    placeholder: label
                }}
                closeOnSelect={true}
                onChange={(e) => {
                    if (typeof e === 'object') {
                        const stringDate = e.format('MM/DD/YYYY');
                        return onChange(stringDate);;
                    }
                    onChange(e);
                }}
                timeFormat={false}
                value={value ? value : ''}
            />
            {(!date_is_valid && value) && <div className="red-text mt-10">Invalid Date, please format to MM/DD/YYYY</div>}
        </div>
		return date;
	}
    return null;
}

export default Element;