import React from 'react';
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import withStyles from "@material-ui/core/styles/withStyles";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

const BPRadio = (props) => {
  const { classes, options, label } = props;
  return (
    <div
      className={
        classes.checkboxAndRadio +
        " " +
        classes.checkboxAndRadioHorizontal
      }
    >
      <div style={{marginBottom:'-5px',marginTop:'20px'}}>{label.split(',')[0]}</div>
      <FormControlLabel
        control={
            /*<span style={{paddingLeft:'12px'}}>{label.split(',')[0]}</span>*/
            <Radio
              checked={props.value == options[0]}
              onChange={(e) => {
                let checke = options[0];
                if (!isNaN(options[0])) {
                  checke = parseInt(options[0]);
                }
                props.onChange(e, checke, props.name);
              }}
              value={options[0]}
              name={props.name}
              aria-label="A"
              icon={
                <FiberManualRecord
                  className={classes.radioUnchecked}
                />
              }
              checkedIcon={
                <FiberManualRecord
                  className={classes.radioChecked}
                />
              }
              classes={{
                checked: classes.radio
              }}
            />
        }
        classes={{
          label: classes.label
        }}
        label={props.label.split(',')[1]}
      />
    <FormControlLabel
      control={
        <Radio
          checked={props.value === options[1]}
          onChange={(e) => {
            let checke = options[1];
            if (!isNaN(options[1])) {
              checke = parseInt(options[1]);
            }
            props.onChange(e, checke, props.name);
          }}
          value={options[1]}
          name={props.name}
          aria-label="B"
          icon={
            <FiberManualRecord
              className={classes.radioUnchecked}
            />
          }
          checkedIcon={
            <FiberManualRecord
              className={classes.radioChecked}
            />
          }
          classes={{
            checked: classes.radio
          }}
        />
      }
      classes={{
        label: classes.label
      }}
      label={props.label.split(',')[2]}
    />
  </div>
  );
}

export default withStyles(regularFormsStyle)(BPRadio);
