import * as actionTypes from './actionTypes';

export const loadRFIDprefix = () => {
    return {
        type: actionTypes.LOAD_RFID_PREFIX,
    }
}

export const loadRFIDprefixSuccess = (response) => {
    return {
        type: actionTypes.LOAD_RFID_PREFIX_SUCCESS,
        response
    }
}

export const loadRFIDprefixFail = (err) => {
    return {
        type: actionTypes.LOAD_RFID_PREFIX_FAIL,
        err
    }
}

export const loadRFIDcompanies = () => {
  return {
    type: actionTypes.LOAD_RFID_COMPANIES
  }
}

export const loadRFIDcompaniesSuccess = (company_ids) => {
  return {
    type: actionTypes.LOAD_RFID_COMPANIES_SUCCESS,
    company_ids
  }
}

export const loadRFIDcompaniesFail = (err) => {
  return {
    type: actionTypes.LOAD_RFID_COMPANIES_FAIL,
    err
  }
}

export const rfidExportEPC = (data) => {
  return {
    type: actionTypes.RFID_EXPORT_EPC,
    data
  }
}

export const rfidExportEPCStart = () => {
  return {
    type: actionTypes.RFID_EXPORT_EPC_START
  }
}

export const rfidExportEPCSuccess = (data) => {
  return {
    type: actionTypes.RFID_EXPORT_EPC_SUCCESS,
    data
  }
}

export const rfidExportEPCFail = (err) => {
  return {
    type: actionTypes.RFID_EXPORT_EPC_FAIL,
    err
  }
}

export const rfidClearData = () => {
  return {
    type: actionTypes.RFID_CLEAR_DATA
  }
}

export const rfidLoadHistory = () => {
  return {
    type: actionTypes.RFID_LOAD_HISTORY
  }
}

export const rfidLoadHistoryStart = () => {
  return {
    type: actionTypes.RFID_LOAD_HISTORY_START
  }
}

export const rfidLoadHistorySuccess = (data) => {
  return {
    type: actionTypes.RFID_LOAD_HISTORY_SUCCESS,
    data
  }
}

export const rfidLoadHistoryFail = (err) => {
  return {
    type: actionTypes.RFID_LOAD_HISTORY,
    err
  }
}

export const setGS1APIisWorking = (gs1_api_is_working) => {
  return {
    type: actionTypes.SET_GS1API_IS_WORKING,
    gs1_api_is_working
  }
}

export const loadGS1CompanyPrefix = (gtin14, length, forceError) => {
  return {
    type: actionTypes.LOAD_GS1COMPANYPREFIX,
    gtin14,
    length,
    forceError
  }
}

export const loadGS1CompanyPrefixStart = () => {
  return {
    type: actionTypes.LOAD_GS1COMPANYPREFIX_START
  }
}

export const loadGS1CompanyPrefixSuccess = (response) => {
  return {
    type: actionTypes.LOAD_GS1COMPANYPREFIX_SUCCESS,
    response
  }
}

export const loadGS1CompanyPrefixFail = (err) => {
  return {
    type: actionTypes.LOAD_GS1COMPANYPREFIX_FAIL,
    err
  }
}

export const resetGS1 = () => {
  return {
    type: actionTypes.RESET_GS1
  }
}

export const generateRFIDExcel = (data) => {
  return {
    type: actionTypes.GENERATE_RFID_EXCEL,
    data
  }
}

export const generateRFIDExcelStart = (percent) => {
  return {
    type: actionTypes.GENERATE_RFID_EXCEL_START,
    percent
  }
}

export const generateRFIDExcelSuccess = (response) => {
  return {
    type: actionTypes.GENERATE_RFID_EXCEL_SUCCESS,
    response
  }
}

export const generateRFIDExcelFail = (err) => {
  return {
    type: actionTypes.GENERATE_RFID_EXCEL_FAIL,
    err
  }
}

export const checkGS1API = (forceError) => {
  return {
    type: actionTypes.CHECK_GS1_API,
    forceError
  }
}

export const checkGS1APIStart = () => {
  return {
    type: actionTypes.CHECK_GS1_API_START
  }
}

export const checkGS1APISuccess = (response) => {
  return {
    type: actionTypes.CHECK_GS1_API_SUCCESS,
    response
  }
}

export const checkGS1APIFail = (err) => {
  return {
    type: actionTypes.CHECK_GS1_API_FAIL,
    err
  }
}

export const verifyRFIDExcel = (filenames) => {
  return {
    type: actionTypes.VERIFY_RFID_EXCEL,
    filenames
  }
}

export const verifyRFIDExcelStart = () => {
  return {
    type: actionTypes.VERIFY_RFID_EXCEL_START,
  }
}

export const verifyRFIDExcelUpdate = (percentDone) => {
  return {
    type: actionTypes.VERIFY_RFID_EXCEL_UPDATE,
    percentDone
  }
}

export const verifyRFIDExcelSuccess = (data) => {
  return {
    type: actionTypes.VERIFY_RFID_EXCEL_SUCCESS,
    data
  }
}

export const verifyRFIDExcelFail = (err) => {
  return {
    type: actionTypes.VERIFY_RFID_EXCEL_FAIL,
    err
  }
}

export const setRfidJcdata = (jcp_import_data) => {
  return {
    type: actionTypes.SET_RFID_JCDATA,
    jcp_import_data
  }
}

export const getRfidJcdata = (data, config) => {
  return {
      type: actionTypes.GET_RFID_JCDATA,
      data,
      config
  }
}

export const getRfidJcdataStart = () => {
  return {
      type: actionTypes.GET_RFID_JCDATA_START
  }
}

export const getRfidJcdataSuccess = (jcp_import_data, history_response) => {
  return {
      type: actionTypes.GET_RFID_JCDATA_SUCCESS,
      jcp_import_data,
      history_response
  }
}

export const getRfidJcdataFail = (err) => {
  return {
      type: actionTypes.GET_RFID_JCDATA_FAIL,
      err
  }
}

// ALLBIRDS IMPORTER 

export const setRfidAllBirds = (allbirds_import_data) => {
  return {
    type: actionTypes.SET_RFID_ALLBIRDS,
    allbirds_import_data
  }
}

export const getRfidAllBirds = (data, config, forceError, overrideGS1, shouldOverrideGS1Length) => {
  return {
      type: actionTypes.GET_RFID_ALLBIRDS,
      data,
      config,
      forceError,
      overrideGS1, 
      shouldOverrideGS1Length
  }
}

export const getRfidAllBirdsStart = () => {
  return {
      type: actionTypes.GET_RFID_ALLBIRDS_START
  }
}

export const getRfidAllBirdsSuccess = (allbirds_import_data, history_response) => {
  return {
      type: actionTypes.GET_RFID_ALLBIRDS_SUCCESS,
      allbirds_import_data,
      history_response
  }
}

export const getRfidAllBirdsSuccessFinal = () => {
  return {
      type: actionTypes.GET_RFID_ALLBIRDS_SUCCESS_FINAL,
  }
}

export const getRfidAllBirdsFail = (err) => {
  return {
      type: actionTypes.GET_RFID_ALLBIRDS_FAIL,
      err
  }
}

// GET_RFID_IMPORTER_HISTORY 

export const getRfidImporterHistory = (data, openPreview) => {
  return {
      type: actionTypes.GET_RFID_IMPORTER_HISTORY,
      data,
      openPreview
  }
}

export const getRfidImporterHistoryStart = () => {
  return {
      type: actionTypes.GET_RFID_IMPORTER_HISTORY_START
  }
}

export const getRfidImporterHistorySuccess = (response, openPreview) => {
  return {
      type: actionTypes.GET_RFID_IMPORTER_HISTORY_SUCCESS,
      response,
      openPreview
  }
}

export const getRfidImporterHistoryFail = (err) => {
  return {
      type: actionTypes.GET_RFID_IMPORTER_HISTORY_FAIL,
      err
  }
}

// GET_RFID_IMPORTER_EXCEL 

export const getRfidImporterExcel = (data) => {
  return {
      type: actionTypes.GET_RFID_IMPORTER_EXCEL,
      data
  }
}

export const getRfidImporterExcelStart = () => {
  return {
      type: actionTypes.GET_RFID_IMPORTER_EXCEL_START
  }
}

export const getRfidImporterExcelSuccess = (response) => {
  return {
      type: actionTypes.GET_RFID_IMPORTER_EXCEL_SUCCESS,
      response
  }
}

export const getRfidImporterExcelFail = (err) => {
  return {
      type: actionTypes.GET_RFID_IMPORTER_EXCEL_FAIL,
      err
  }
}

